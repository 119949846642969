import { gql } from '../../../__generated__';

const projectsQuery = gql(`query projects{
  projects(order: {id: ASC}) {
    items {
      displayName
      id
    }
  }
}`);

export default projectsQuery;
