import * as Yup from 'yup';

const REQUIRED_MESSAGE = 'Required';

export const CreateNCYupSchema = Yup.object().shape({
  sourceId: Yup.number().min(1, REQUIRED_MESSAGE).required(REQUIRED_MESSAGE),
  originId: Yup.number().min(1, REQUIRED_MESSAGE).required(REQUIRED_MESSAGE),
  projectId: Yup.number().required(REQUIRED_MESSAGE).min(1, REQUIRED_MESSAGE),
  specId: Yup.number().required(REQUIRED_MESSAGE).min(1, REQUIRED_MESSAGE),
  title: Yup.string().max(100).required(REQUIRED_MESSAGE),
});

export const CommonNCYupSchema = Yup.object().shape({
  correctivePreventiveAction: Yup.string(),
  creationDate: Yup.object().nullable(),
  description: Yup.string(),
  id: Yup.number(),
  itemId: Yup.number().nullable(),
  purchaseOrderId: Yup.number()
    .transform((val) =>
      Number.isNaN(val) || val === null || val === undefined ? undefined : val
    )
    .nullable(),
  qualityFollowUp: Yup.string(),
  quantity: Yup.number().nullable(),
  quantityRejected: Yup.number().nullable(),
  recommendedInterim: Yup.string(),
  released: Yup.object().nullable(),
  resolved: Yup.bool(),
  rootCause: Yup.string(),
  custom1: Yup.string().when('requireCustom1', {
    is: true,
    then: Yup.string().required(REQUIRED_MESSAGE),
  }),
  custom2: Yup.string().when('requireCustom2', {
    is: true,
    then: Yup.bool().required(REQUIRED_MESSAGE),
  }),
  custom3: Yup.number()
    .nullable()
    .when('requireCustom3', {
      is: true,
      then: Yup.number().required(REQUIRED_MESSAGE),
    }),
  custom4: Yup.number()
    .nullable()
    .when('requireCustom4', {
      is: true,
      then: Yup.number().required(REQUIRED_MESSAGE),
    }),
  custom5: Yup.date()
    .nullable()
    .default(null)
    .when('requireCustom5', {
      is: true,
      then: Yup.date().nullable().default(null).required(REQUIRED_MESSAGE),
    }),
  custom6: Yup.date()
    .nullable()
    .default(null)
    .when('requireCustom6', {
      is: true,
      then: Yup.date().nullable().default(null).required(REQUIRED_MESSAGE),
    }),
  custom7: Yup.bool().when('requireCustom7', {
    is: true,
    then: Yup.bool().required(REQUIRED_MESSAGE),
  }),
  custom8: Yup.bool().when('requireCustom8', {
    is: true,
    then: Yup.bool().required(REQUIRED_MESSAGE),
  }),
});

export const TaskAssignmentYupSchema = Yup.object().shape({});
