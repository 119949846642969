import { MessageContext } from '@teto/react-component-library-v2';
import React, { useContext, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import PartsHistoryWorkOrder from '../../../views/PartsHistoryWorkOrderView.yaml';
import MainTetoGridGraphQL from '../../TETOGridGraphQL/TETOMainGridGraphQL';
import useGrid from '../../TETOGridGraphQL/hooks/useGrid';
import { useGridBuilderFromView } from '../../TETOGridGraphQL/hooks/useGridBuilder';
import { TetoGridGraphqlRefProps } from '../../TETOGridGraphQL/types/TetoGridGraphqlProps';
import useExportData from '../helpers/useExportData';
import CommonTabProps from './CommonTabProps';

const PersistenceName = 'ph-PartsHistoryWorkOrder';
const RootQueryPath = 'workOrderDetails';

const WorkOrdersTab: React.FC<CommonTabProps> = (props) => {
  const {
    date,
    part,
    configureInspectorOpen,
    shouldExportData,
    setShouldExportData,
    setConfigureInspector,
  } = props;
  const { t, ready } = useTranslation();
  const messageContext = useContext(MessageContext);
  const gridRef = useRef<TetoGridGraphqlRefProps | undefined>();

  const { gridBuilder, builderReady, error, hasError } = useGridBuilderFromView(
    PartsHistoryWorkOrder,
    RootQueryPath,
    (gb) =>
      gb
        .updateDefinition('specAuto.project.displayName', {
          order: 0,
        })
        .updateDefinition('destInventoryLoc.name', {
          title: t('entities:WorkOrderDetail.InventoryLocation'),
        })
  );

  const mandatoryFilters = useMemo(
    () => [
      {
        name: 'itemId',
        operator: 'equal',
        value: part.id,
        type: 'number' as const,
      },
      {
        name: 'specAuto.date',
        operator: 'greaterThanEqualTo',
        type: 'datetime' as const,
        value: date.format('YYYY-MM-DD'),
      },
    ],
    [part.id, date]
  );

  const alwaysProjectColumns = useMemo(
    () => [
      'specAuto.project.displayName',
      'specAuto.displayName',
      'specAuto.date',
    ],
    []
  );

  const { gridProps } = useGrid(
    PersistenceName,
    `${RootQueryPath}.items`,
    (e) => messageContext.setError(e.message ?? e),
    t,
    alwaysProjectColumns,
    gridBuilder,
    {
      filterAndSortMode: 'serverSide',
      mandatoryFilter: mandatoryFilters,
    }
  );

  useExportData(shouldExportData, setShouldExportData, gridRef);

  return (
    <MainTetoGridGraphQL
      builderReady={builderReady}
      configureInspector={configureInspectorOpen}
      error={error}
      externalQueryProps={[]}
      gridProps={gridProps}
      hasError={hasError}
      header={{
        hidden: true,
      }}
      mobileGridOptions
      ready={ready}
      ref={gridRef}
      setConfigureInspector={setConfigureInspector}
      showEmptyRows
      showGroupSummaryRow={false}
      t={t}
      tableIdentifier={PersistenceName}
      {...gridProps}
    />
  );
};

export default WorkOrdersTab;
