/* eslint-disable object-curly-newline */
import React from 'react';
import { EditHandlers } from './types/editorTypes';

type T = { [key: string]: unknown };

const getDisabledValue = (
  data: T,
  // eslint-disable-next-line no-unused-vars
  disabledValue?: boolean | ((value: T) => boolean)
) => {
  if (disabledValue instanceof Function && data)
    return Boolean(disabledValue(data));
  if (typeof disabledValue === 'boolean') return disabledValue;
  return false;
};

const onFocus = (e: React.FocusEvent<HTMLInputElement>) => e.target.select();

const onKeyDown = (
  e: React.KeyboardEvent<HTMLInputElement | HTMLDivElement>,
  handlers: EditHandlers
) => {
  switch (e.key) {
    case 'Escape':
      handlers.onCancel?.(e);
      e.stopPropagation();
      break;
    case 'Enter':
      handlers.onComplete?.(e);
      e.stopPropagation();
      break;
    case 'Tab':
      // e.preventDefault(); // do not enable this, it breaks tab navigation for the add row, verified this doesnt break anything
      handlers.onTabNavigation?.(true, e.shiftKey ? -1 : 1);
      break;
    default:
      break;
  }
};

export { getDisabledValue, onFocus, onKeyDown };
