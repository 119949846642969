import Settings from '@mui/icons-material/Settings';
import { capitalize } from '@mui/material';
import {
  LoadingPage,
  MessageContext,
  PageWrapper,
  RouteMenuItem,
  SidebarMenu,
} from '@teto/react-component-library-v2';
import React, {
  lazy,
  useContext,
  useEffect,
  useLayoutEffect,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import {
  Route,
  Routes,
  matchPath,
  useLocation,
  useNavigate,
} from 'react-router-dom';
import { Permission } from 'teto-client-api';
import { getGraphQLClient } from './api/graphQL/graphQLClient';
import mainMenu from './authMenu/menu.json';
import useMenuBuilder from './authMenu/useMenuBuilder';
import ClockInModalNotification from './components/ClockInModalNotification/ClockInModalNotification';
import NavHelpLink from './components/HelpLinks/NavHelpLink/NavHelpLink';
import getPageArticleNumbers from './components/HelpLinks/helpers/getPageArticleNumber';
import MFASetupDialog from './components/MFASetupDialog/MFASetupDialog';
import NavLicense from './components/NavLicense/NavLicense';
import ReportViewer from './components/ReportViewer/ReportViewer';
import NavTimer from './components/TimeSheets/NavTimer/NavTimer';
import TimerInspectors from './components/TimeSheets/NavTimer/TimerInspectors';
import TimecardMigrationDialog from './components/TimeSheets/TimecardMigrationDialog/TimecardMigrationDialog';
import AuthContext, { AuthenticatedUser } from './contexts/AuthContext';
import ClockInTimerContext from './contexts/ClockInContext';
import SettingsContext from './contexts/SettingsContext';
import CompanyPage from './pages/CompanyPage/CompanyPage';
import AdminPermissionErrorPage from './pages/ErrorPages/AdminPermissionErrorPage';
import RoutingErrorPage from './pages/ErrorPages/RoutingErrorPage';
import HomePage from './pages/HomePage/HomePage';
import LinkDetailsPage from './pages/LinkDetailsPage/LinkDetailsPage';
import ProcessSchedulesPage from './pages/ProcessSchedulesPage/ProcessSchedulesPage';

const ProcurementPage = lazy(
  () => import('./pages/ProcurementPage/ProcurementPage')
);
const PurchaseOrdersPage = lazy(
  () => import('./pages/PurchaseOrdersPage/PurchaseOrdersPage')
);
const RFQPage = lazy(() => import('./pages/RFQPage/RFQPage'));
const InventoryPage = lazy(() => import('./pages/InventoryPage/InventoryPage'));
const ClockInsPage = lazy(() => import('./pages/ClockInsPage/ClockInsPage'));
const ContactsPage = lazy(() => import('./pages/ContactsPage/ContactsPage'));
const GeolocationPage = lazy(
  () => import('./pages/GeolocationPage/GeolocationPage')
);
const InHouseTasksPage = lazy(
  () => import('./pages/InHouseTasksPage/InHouseTasksPage')
);
const ManageApproversPage = lazy(
  () => import('./pages/ManageApproversPage/ManageApproversPage')
);
const MyTimeTrackerPage = lazy(
  () => import('./pages/MyTimeTrackerPage/MyTimeTrackerPage')
);
const NonConformancesPage = lazy(
  () => import('./pages/NonConformancesPage/NonConformancesPage')
);
const OverdueTimesheetsPage = lazy(
  () => import('./pages/OverdueTimesheetsPage/OverdueTimesheetsPage')
);
const PartsHistoryPage = lazy(
  () => import('./pages/PartsHistoryPage/PartsHistoryPage')
);
const PayPeriodsPage = lazy(
  () => import('./pages/PayPeriodsPage/PayPeriodsPage')
);
const PunchInsPage = lazy(() => import('./pages/PunchInsPage/PunchInsPage'));
const ReceivingPage = lazy(() => import('./pages/ReceivingPage/ReceivingPage'));
const SettingsPage = lazy(() => import('./pages/SettingsPage/SettingsPage'));
const TimeCardsPage = lazy(() => import('./pages/TimeCardsPage/TimeCardsPage'));
const TimesheetsApprovalsPage = lazy(
  () => import('./pages/Timesheets/ApprovalsPage/TimesheetsApprovalsPage')
);
const TimesheetDetailsPage = lazy(
  () => import('./pages/Timesheets/TimesheetsDetailsPage/TimesheetsDetailsPage')
);
const TimesheetsPage = lazy(
  () => import('./pages/Timesheets/TimesheetsPage/TimesheetsPage')
);

interface RouteDef {
  displayName?: string;
  route?: string;
  index?: boolean;
  component?: React.ReactElement;
  noWrapper?: boolean;
  routes?: RouteDef[];
  helpId?: string | number;
  type?: 'section' | 'article';
}

const URL = '';

const isRouteActive = (route: string, pathname: string) =>
  !!matchPath(`${URL}${route}`, pathname) || pathname.includes(route);

const AuthenticatedRoutes = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation();
  const authContext = useContext(AuthContext);
  const settingsContext = useContext(SettingsContext);
  const clockInContext = useContext(ClockInTimerContext);
  const messageContext = useContext(MessageContext);

  const anchorRef = React.useRef<HTMLElement>(null);
  const [isClockInNotificationVisible, setIsClockInNotificationVisible] =
    useState(false);
  const [keepClockInNotificationHidden, setKeepClockInNotificationHidden] =
    useState(false);
  const hasRecentlyExpired = authContext?.licenseExpiration?.hasRecentlyExpired;
  const hasSoonExpiring = authContext?.licenseExpiration?.hasSoonExpiring;
  const menuBuilder = useMenuBuilder(mainMenu);
  const navMenuItems = [
    ...(authContext.hasEnterpriseLicense()
      ? [{ component: <NavTimer />, priority: 3 }]
      : []),
  ];

  const [mFASetupDialog, setMFASetupDialog] = useState<boolean>(false);

  const showSidebarMenu = !location.pathname.startsWith('/reportViewer');

  useLayoutEffect(() => {
    if (keepClockInNotificationHidden) return;
    setIsClockInNotificationVisible(false);
    const clockInPopup = setTimeout(() => {
      if (clockInContext.status !== 'active')
        setIsClockInNotificationVisible(true);
    }, 850);
    return () => clearTimeout(clockInPopup);
  }, [clockInContext.status, keepClockInNotificationHidden, location.pathname]);

  const _checkMFASetupStatus = () => {
    if (authContext.user?.needsMFASetup) {
      setMFASetupDialog(true);
    }
  };

  useEffect(() => {
    if (authContext.user?.needsMFASetup) _checkMFASetupStatus();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authContext.user?.needsMFASetup, location.pathname]);

  const logoutMutation = ` mutation logout(){
    logout(){
      success
    }
  }
  `;

  const _logout = React.useCallback(() => {
    getGraphQLClient()
      .performMutation(
        logoutMutation,
        {},
        (err) => {
          messageContext.setError(err.messages[0]);
        },
        (err) => {
          messageContext.setError(err.messages[0]);
        }
      )
      .then(() => {
        window.location.reload();
      })
      .catch((e) => {
        messageContext.setError(e.message);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [logoutMutation]);

  const _selectedRoute = (menuItem: RouteMenuItem) =>
    navigate(`${URL}${menuItem.route}`);

  const routes: RouteDef[] = [
    {
      route: '/',
      displayName: 'Home',
      component: <HomePage />,
      helpId: '5658553066516-Getting-Started-with-Time-Tracker',
    },
    {
      route: '/reportViewer',
      routes: [
        {
          index: true,
          displayName: '',
          component: <ReportViewer />,
          noWrapper: true,
        },
      ],
    },
    {
      route: '/timeTracking/timesheets',
      routes: [
        {
          index: true,
          displayName: t('pages.timesheets.title'),
          component: <TimesheetsPage />,
          helpId: '5659157371668#view-and-edit-employee-time-0-2',
        },
        {
          route: 'week',
          displayName: t('pages.timesheets.title'),
          component: <TimesheetsPage />,
          helpId: '5659157371668#view-and-edit-employee-time-0-2',
        },
        {
          route: 'week/:startDate/:endDate',
          displayName: t('pages.timesheets.title'),
          component: <TimesheetsPage />,
          helpId: '5659157371668#view-and-edit-employee-time-0-2',
        },
        {
          route: ':id',
          displayName: t('pages.timesheetsDetails.title'),
          component: <TimesheetDetailsPage />,
          helpId: '5659157371668#view-and-edit-employee-time-0-2',
        },
        {
          route: 'month',
          displayName: t('pages.timesheets.title'),
          component: <TimesheetsPage />,
        },
        {
          route: 'month/:startDate/:endDate',
          displayName: t('pages.timesheets.title'),
          component: <TimesheetsPage />,
        },
      ],
    },
    {
      route: '/timeTracking/overduetimesheets',
      routes: [
        {
          index: true,
          displayName: t('pages.overdueTimesheets.title'),
          component: <OverdueTimesheetsPage />,
          helpId: '11777211820820-Overdue-Timesheets',
        },
        {
          route: ':startDate/:endDate',
          displayName: t('pages.overdueTimesheets.title'),
          component: <OverdueTimesheetsPage />,
          helpId: '11777211820820-Overdue-Timesheets',
        },
      ],
    },
    {
      route: '/timeTracking/timeSheetApprovals',
      routes: [
        {
          index: true,
          displayName: t('pages.timesheetsApprovals.title'),
          component: <TimesheetsApprovalsPage />,
          helpId: '11812033741332-Approve-and-Reject-Timesheets',
        },
        {
          route: 'week',
          displayName: t('pages.timesheetsApprovals.title'),
          component: <TimesheetsApprovalsPage />,
          helpId: '11812033741332-Approve-and-Reject-Timesheets',
        },
        {
          route: 'week/:startDate/:endDate',
          displayName: t('pages.timesheetsApprovals.title'),
          component: <TimesheetsApprovalsPage />,
          helpId: '11812033741332-Approve-and-Reject-Timesheets',
        },
        {
          route: ':id',
          displayName: t('pages.timesheetsDetails.title'),
          component: <TimesheetDetailsPage />,
          helpId: '11812033741332-Approve-and-Reject-Timesheets',
        },
      ],
    },
    {
      route: '/myTimeTracker',
      routes: [
        {
          index: true,
          displayName: t('pages.timeTracker.title'),
          component: <MyTimeTrackerPage />,
          helpId: '5552067995924',
        },
        {
          route: 'timesheet/weekly/:weekStart/:weekEnd',
          displayName: t('pages.timeTracker.title'),
          component: <MyTimeTrackerPage />,
          helpId: '5552067995924',
        },
        {
          route: 'timesheet/weekly',
          displayName: t('pages.timeTracker.title'),
          component: <MyTimeTrackerPage />,
          helpId: '5552067995924',
        },
        {
          route: 'timesheet/monthly',
          displayName: t('pages.timeTracker.title'),
          component: <MyTimeTrackerPage />,
          helpId:
            '5552067995924-Enter-and-Manage-Your-Time#h_01G3VT2NZ48NC6EX0TT5DB1PGC',
        },
        {
          route: 'timesheet/monthly/:month/weekly/:weekStart/:weekEnd',
          displayName: t('pages.timeTracker.title'),
          component: <MyTimeTrackerPage />,
          helpId:
            '5552067995924-Enter-and-Manage-Your-Time#h_01G3VT2NZ48NC6EX0TT5DB1PGC',
        },
        {
          route: 'timesheet/monthly/:month',
          displayName: t('pages.timeTracker.title'),
          component: <MyTimeTrackerPage />,
          helpId:
            '5552067995924-Enter-and-Manage-Your-Time#h_01G3VT2NZ48NC6EX0TT5DB1PGC',
        },
        {
          route: 'clockIns',
          displayName: t('pages.timeTracker.title'),
          component: <MyTimeTrackerPage />,
          helpId: '5552067995924',
        },
        {
          route: 'clockIns/:startDate/:endDate',
          displayName: t('pages.timeTracker.title'),
          component: <MyTimeTrackerPage />,
          helpId: '5552067995924',
        },
      ],
    },
    {
      route: '/timeTracking/timecards',
      routes: [
        {
          index: true,
          displayName: t('pages.timecards.title'),
          component: <TimeCardsPage />,
          helpId:
            '5659157371668-Time-Tracker-Administration#view-and-edit-employee-time-0-2',
        },
        {
          route: ':startDate/:endDate',
          displayName: t('pages.timecards.title'),
          component: <TimeCardsPage />,
          helpId:
            '5659157371668-Time-Tracker-Administration#view-and-edit-employee-time-0-2',
        },
      ],
    },
    {
      route: '/usersAndPermissions/manageApprovers/',
      routes: [
        {
          index: true,
          displayName: t('pages.manageApprovers.title'),
          component: <ManageApproversPage />,
          helpId: '10713693950740',
        },
        {
          route: ':approverTab',
          displayName: t('pages.manageApprovers.title'),
          component: <ManageApproversPage />,
          helpId: '10713693950740',
        },
      ],
    },
    {
      route: '/timeTracking/punch-ins',
      routes: [
        {
          index: true,
          displayName: t('pages.punchIns.title'),
          component: <PunchInsPage />,
          helpId:
            '5659157371668-Time-Tracker-Administration#view-and-edit-employee-time-0-2',
        },
      ],
    },
    {
      route: '/timeTracking/clock-ins',
      routes: [
        {
          index: true,
          displayName: t('pages.clockIns.title'),
          component: <ClockInsPage />,
          helpId:
            '5659157371668-Manage-Employee-Time-Records#h_01HDPNFQZKBMYGF07PGF55QNWM',
        },
        {
          route: ':startDate/:endDate',
          displayName: t('pages.clockIns.title'),
          component: <ClockInsPage />,
          helpId:
            '5659157371668-Manage-Employee-Time-Records#h_01HDPNFQZKBMYGF07PGF55QNWM',
        },
      ],
    },
    {
      route: '/accounting/managePayPeriods',
      routes: [
        {
          index: true,
          displayName: t('pages.managePayPeriods.title'),
          component: <PayPeriodsPage />,
          helpId: '10516477473684',
        },
        {
          route: ':startDate/:endDate',
          displayName: t('pages.managePayPeriods.title'),
          component: <PayPeriodsPage />,
          helpId: '10516477473684',
        },
      ],
    },
    {
      route: '/timeTracking/geolocation',
      routes: [
        {
          index: true,
          displayName: t('pages.location.title'),
          component: <GeolocationPage />,
          helpId:
            '11779069974036-View-Location-Information#view-location-information-0-0',
        },
        {
          route: 'active',
          displayName: t('pages.location.title'),
          component: <GeolocationPage />,
          helpId:
            '11779069974036-View-Location-Information#view-location-information-0-0',
        },
        {
          route: 'historical',
          displayName: t('pages.location.title'),
          component: <GeolocationPage />,
          helpId:
            '11779069974036-View-Location-Information#view-location-information-0-0',
        },
        {
          route: 'historical/:startDate/:endDate',
          displayName: t('pages.location.title'),
          component: <GeolocationPage />,
          helpId:
            '11779069974036-View-Location-Information#view-location-information-0-0',
        },
      ],
    },
    {
      route: '/receiving',
      routes: [
        {
          index: true,
          displayName: t('pages.receiving.title'),
          component: <ReceivingPage />,
          helpId: '10771839580052-Receiving-Guides',
          type: 'section',
        },
      ],
    },
    {
      route: '/manufacturing/processSchedules/',
      routes: [
        {
          index: true,
          displayName: t('pages.processSchedule.title'),
          component: <ProcessSchedulesPage />,
          helpId: '25252870806164-Process-Schedules',
          type: 'section',
        },
        {
          route: ':processScheduleTab',
          displayName: t('pages.processSchedule.title'),
          component: <ProcessSchedulesPage />,
          helpId: '25252870806164-Process-Schedules',
          type: 'article',
        },
      ],
    },
    {
      route: '/manufacturing/inHouseTasks',
      displayName: t('pages.inHouseTasks.title'),
      component: <InHouseTasksPage />,
      helpId: '14792274913428',
      type: 'article',
    },
    {
      route: '/manufacturing/nonConformances',
      displayName: t('pages.nonConformance.title'),
      component: <NonConformancesPage />,
      helpId: '14941405788692-Manage-Non-Conformances',
      type: 'article',
    },
    {
      route: '/engineering/partsHistory',
      routes: [
        {
          index: true,
          displayName: t('pages.partsHistory.title'),
          component: <PartsHistoryPage />,
          helpId: '14620756868244-View-a-Part-History',
        },
      ],
    },
    {
      route: '/manufacturing/inventory',
      routes: [
        {
          index: true,
          displayName: t('pages.inventory.title'),
          component: <InventoryPage />,
          helpId: '15734683906068',
          type: 'section',
        },
        {
          route: ':inventoryTab',
          displayName: t('pages.inventory.title'),
          component: <InventoryPage />,
          helpId: '15734683906068',
          type: 'section',
        },
      ],
    },
    {
      route: '/CRM/companies',
      routes: [
        {
          index: true,
          displayName: t('pages.companies.title'),
          component: <CompanyPage />,
          helpId: '19404370217108',
        },
      ],
    },
    {
      route: '/CRM/contacts',
      routes: [
        {
          index: true,
          displayName: t('pages.contacts.title'),
          component: <ContactsPage />,
          type: 'article',
          helpId: '19404399376276-Contacts',
        },
        {
          route: ':contactsTabs',
          displayName: t('pages.contacts.title'),
          component: <ContactsPage />,
          type: 'article',
          helpId: '19404399376276-Contacts',
        },
      ],
    },
    {
      route: '/procurement',
      routes: [
        {
          index: true,
          displayName: t('pages.procurement.title'),
          component: <ProcurementPage />,
          route: 'procurement/procurement',
          helpId: '20148311175828',
        },
        {
          route: ':procurementTab',
          displayName: t('pages.procurement.title'),
          component: <ProcurementPage />,
          helpId: '20148331298964',
        },
        {
          route: 'purchaseOrders',
          displayName: t('pages.purchaseOrders.title'),
          component: <PurchaseOrdersPage />,
          helpId: '20148333432212',
        },
        {
          route: 'rfq',
          displayName: t('pages.rFQ.title'),
          component: <RFQPage />,
          helpId: '21516299041940',
        },
      ],
    },
    {
      route: '/details',
      displayName: '',
      routes: [
        {
          index: true,
          displayName: '',
          component: <LinkDetailsPage />,
          route: ':inspectorKey/:id',
        },
      ],
    },
    {
      route: '/settings',
      routes: [
        {
          index: true,
          displayName: t('pages.settings.title'),
          component: <SettingsPage />,
          helpId: getPageArticleNumbers(location.pathname),
        },
        {
          route: 'account',
          displayName: t('pages.settings.title'),
          component: <SettingsPage />,
          helpId: getPageArticleNumbers(location.pathname),
        },
        {
          route: 'user',
          displayName: t('pages.settings.title'),
          component: <SettingsPage />,
          helpId: getPageArticleNumbers(location.pathname),
        },
        {
          route: 'system',
          displayName: t('pages.settings.title'),
          component: <SettingsPage />,
          helpId: getPageArticleNumbers(location.pathname),
        },
        {
          route: 'kiosk',
          displayName: t('pages.settings.title'),
          component: <SettingsPage />,
          helpId: getPageArticleNumbers(location.pathname),
        },
        {
          route: 'fileSystemLocation',
          displayName: t('pages.settings.title'),
          component: <SettingsPage />,
          helpId: getPageArticleNumbers(location.pathname),
        },
      ],
    },
    {
      route: '/access-denied',
      displayName: t('pages.accessDenied.title'),
      component: <AdminPermissionErrorPage />,
    },
    {
      route: '*',
      displayName: '',
      component: <RoutingErrorPage />,
    },
  ];

  if (!settingsContext.settingsLoaded) {
    return <LoadingPage />;
  }

  const capitalizeAll = (words: string, delimiter = ' ') =>
    words
      .split(delimiter)
      .map((i) => capitalize(i))
      .join(delimiter);
  const addSpaceToString = (pathname: string) => {
    const updatedString = pathname.replace(/([a-z])([A-Z])/g, '$1 $2');
    return updatedString;
  };

  document.title =
    location.pathname === '/'
      ? 'TotalETO'
      : `TotalETO - ${capitalizeAll(
          addSpaceToString(location.pathname.replaceAll('/', ' '))
        )}`;

  const _renderRoutes = (routesToRender: RouteDef[], root: boolean) =>
    routesToRender.map((r, i) => {
      const rightMenu = [
        ...navMenuItems,
        ...(hasRecentlyExpired || hasSoonExpiring
          ? [
              {
                component: <NavLicense />,
                priority: 1,
              },
            ]
          : []),

        ...(r.helpId
          ? [
              {
                component: (
                  <NavHelpLink
                    helpId={r.helpId}
                    title={r.displayName ?? ''}
                    type={r.type}
                  />
                ),
                priority: 2,
              },
            ]
          : []),
      ];

      if (r.noWrapper) {
        return (
          <Route
            element={r.component ?? undefined}
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            index={r.index as any}
            key={document.location.href}
            path={root ? `${URL}${r.route}` : r.route}
          >
            {r.routes && _renderRoutes(r.routes, false)}
          </Route>
        );
      }

      return (
        <Route
          element={
            r.component ? (
              <PageWrapper
                hasEnterpriseLicense={authContext.hasEnterpriseLicense()}
                navBarRef={anchorRef}
                onLogOut={_logout}
                pageTitle={r.displayName ?? ''}
                rightMenuItems={rightMenu}
                user={authContext.user as AuthenticatedUser}
              >
                {r.component}
              </PageWrapper>
            ) : undefined
          }
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          index={r.index as any}
          // eslint-disable-next-line react/no-array-index-key
          key={`${r.displayName} - ${i}`}
          path={root ? `${URL}${r.route}` : r.route}
        >
          {r.routes && _renderRoutes(r.routes, false)}
        </Route>
      );
    });

  return (
    <>
      {menuBuilder.isReady && showSidebarMenu && (
        <SidebarMenu
          app={t('appName')}
          footer={[
            {
              title: t('generic.settings'),
              route: '/settings',
              leftIcon: <Settings />,
              isActive: isRouteActive('/settings', location.pathname),
            },
          ]}
          groupedMenus={[menuBuilder.menu]}
          helpCenter
          onBrandingClicked={() => navigate(`${URL}/`)}
          onRouteSelected={_selectedRoute}
        />
      )}
      <React.Suspense fallback={<LoadingPage />}>
        <Routes>{_renderRoutes(routes, true)}</Routes>
      </React.Suspense>
      <TimecardMigrationDialog />
      <TimerInspectors />

      <MFASetupDialog
        mFASetupDialog={mFASetupDialog}
        setMFASetupDialog={setMFASetupDialog}
      />
      {!authContext.disableClockIn &&
        authContext.hasEnterpriseLicense() &&
        authContext.hasAnyPermission([
          Permission.Add_Time_Client_Punchcards,
          Permission.Add_Time_Client_Clockins,
        ]) && (
          <ClockInModalNotification
            anchorEl={anchorRef.current}
            onNo={() => {
              setIsClockInNotificationVisible(false);
              setKeepClockInNotificationHidden(true);
            }}
            onYes={() => {
              clockInContext.startMyClockIn();
              setIsClockInNotificationVisible(false);
            }}
            open={isClockInNotificationVisible}
          />
        )}
    </>
  );
};

export default AuthenticatedRoutes;
