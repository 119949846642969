import { Theme } from '@mui/material';

const headerSx = (theme: Theme) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  p: 1.25,
  height: theme.spacing(8),
  borderBottom: `1px solid ${theme.palette.divider}`,
  backgroundColor: theme.palette.background.paper,
});

const wrapperSx = (theme: Theme) => ({
  height: '100vh',
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  position: 'relative',
  backgroundColor: theme.palette.background.default,
});

const dialogPaperSx = (theme: Theme) => ({
  width: '100%',
  height: `calc(100% - ${theme.spacing(8)})`,
  '.demo-editor': {
    borderRadius: theme.spacing(0.5),
    border: `1px solid ${theme.palette.divider}`,
    minHeight: `${theme.spacing(37)}`,
    padding: `${theme.spacing(1)} ${theme.spacing(1.75)}`,
    marginBottom: `${theme.spacing(3)}`,
  },
  '.error-editor': {
    borderRadius: theme.spacing(0.5),
    border: `1px solid ${theme.palette.error.main}`,
    minHeight: `${theme.spacing(37)}`,
    padding: `${theme.spacing(1)} ${theme.spacing(1.75)}`,
    marginBottom: `${theme.spacing(3)}`,
  },
  '.dropzone': {
    alignItems: 'center',
    backgroundColor: theme.palette.background.default,
    border: `2px dashed ${theme.palette.grey[500]}`,
    borderRadius: theme.spacing(0.5),
    color: theme.palette.grey[400],
    display: 'flex',
    flex: 1,
    justifyContent: 'center',
    outline: 'none',
    padding: theme.spacing(2),
    '&:focus': {
      borderColor: theme.palette.primary.light,
    },
  },
});

const footerSx = () => ({
  display: 'flex',
  width: '100%',
  justifyContent: 'flex-end',
});

const formSx = {
  flexGrow: 1,
  overflowY: 'auto',
  height: '100%',
};

const noPDFStylesSx = {
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  width: '100%',
  justifyContent: 'center',
  alignItems: 'center',
};

const editorErrorSx = (theme: Theme) => ({
  position: 'absolute',
  bottom: `-${theme.spacing(2.5)}`,
  color: theme.palette.error.main,
  fontSize: '0.75rem',
  margin: 0,
  marginLeft: `${theme.spacing(1.75)}`,
});

const listSx = (theme: Theme) => ({
  display: 'flex',
  columnGap: `${theme.spacing(1)}`,
  rowGap: `${theme.spacing(1)}`,
  flexWrap: 'wrap',
});

const listItemSx = (theme: Theme) => ({
  backgroundColor: theme.palette.background.default,
  borderRadius: theme.spacing(1),
  display: 'flex',
  alignItems: 'center',
  width: 'fit-content',
  maxHeight: theme.spacing(6),
});

const attachInfoWrapperSx = { display: 'flex', flexDirection: 'column' };

const attachInfoSx = (theme: Theme) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  [theme.breakpoints.down('md')]: {
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
});

const iFrameWrapperSx = (theme: Theme) => ({
  flexGrow: 1,
  padding: theme.spacing(2),
  backgroundColor: theme.palette.background.paper,
});

const logoSx = {
  cursor: 'pointer',
  height: '100%',
};

const actionButtonSx = { display: 'flex', alignItems: 'center', columnGap: 2 };

export {
  actionButtonSx,
  iFrameWrapperSx,
  listSx,
  listItemSx,
  formSx,
  attachInfoSx,
  footerSx,
  dialogPaperSx,
  wrapperSx,
  headerSx,
  noPDFStylesSx,
  logoSx,
  editorErrorSx,
  attachInfoWrapperSx,
};
