import { ETOTextField, MessageContext } from '@teto/react-component-library-v2';
import { FormikProps } from 'formik';
import React, { PropsWithChildren, useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import useBuildQuery from '../../../../api/graphQL/useBuildQuery';
import PartSearchInput from '../../../../components/CommonInputFields/PartSearchInput';

import InvPartNumberSearchInput from '../../components/InvPartNumberSearchInput';
import inventoryQtyAvailableQuery from '../../queries/inventoryQtyAvailableQuery';
import reservePartListQuery from '../../queries/reservePartListQuery';
import ReasonRequiredDateForm from './ReasonRequiredDateForm';

interface CommonPartFormProps {
  formik: FormikProps<Record<string, unknown>>;
  inspectorIdentifier?: string;
  inventoryLocation?: number;
}

const commonQuery = `
query engItemMasters($id: Int!) {
  engItemMasters(
    where: {
      or: [
        { id: { eq: $id } }
      ]
    }
  ) {
    items {
      id
      itemCompanyId
      description
      uOMId
      uOM {
        uomtype
      }
    }
  }
}`;

const CommonPartForm: React.FC<PropsWithChildren<CommonPartFormProps>> = (
  props
) => {
  const { children, formik, inspectorIdentifier, inventoryLocation } = props;
  const { t } = useTranslation();
  const messageContext = useContext(MessageContext);

  const useEngInput = useMemo(() => {
    switch (inspectorIdentifier) {
      case 'requisitionInspector':
      case 'returnInspector':
        return true;

      default:
        return false;
    }
  }, [inspectorIdentifier]);

  useBuildQuery(['commonInvForm', formik.values.id], {
    queryString: useEngInput ? commonQuery : inventoryQtyAvailableQuery,
    variables: useEngInput
      ? { id: formik.values.id }
      : {
          itemId: formik.values.itemId ?? formik.values.id,
          inventoryLocationId: inventoryLocation,
        },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    callback: (data: any) => {
      if (data) {
        const key = useEngInput ? 'engItemMasters' : 'inventories';
        const { items } = data[key];
        const _handleNoPart = () => {
          messageContext.setError(
            `${t('pages.inventory.details.partNotInInventory')}`
          );
          formik.resetForm();
          formik.setErrors({
            itemId: `${t('pages.inventory.details.partNotInInventory')}`,
          });
        };

        if (!useEngInput) {
          if (items.length > 0) {
            const { available } = items[0].otherQuantities;
            const { description, itemCompanyId, id } = items[0].item;
            if (available >= 0) {
              formik.setValues({
                ...formik.values,
                description,
                availableQty: available,
                itemCompanyId,
                itemId: id,
                id,
              });
            }
          } else {
            _handleNoPart();
          }
        }

        if (useEngInput) {
          if (items.length > 0) {
            formik.setFieldValue('itemCompanyId', items[0]?.itemCompanyId);
            formik.setFieldValue('description', items[0]?.description);
            formik.setFieldValue('uOMId', items[0]?.uOMId);
            formik.setFieldValue('uomtype', items[0]?.uOM?.uomtype);
          } else {
            _handleNoPart();
          }
        }
      }
    },
    options: {
      enabled: Boolean(formik.values.id),
    },
  });

  return (
    <>
      {useEngInput ? (
        <PartSearchInput
          autoFocus
          error={formik.errors.itemId}
          formik={formik}
          handleChange={(v) => {
            formik.setFieldValue('id', v);
            return formik.setFieldValue('itemId', v);
          }}
          name="itemId"
          value={formik.values.id}
        />
      ) : (
        <InvPartNumberSearchInput
          autoFocus
          error={formik.errors.itemId}
          handleChange={(v) => {
            formik.setValues({
              ...formik.values,
              availableQty: v?.otherQuantities?.available,
              id: v?.item.id,
              itemId: v?.item.id,
              description: v?.item?.description,
              itemCompanyId: v?.item?.itemCompanyId,
            });
          }}
          id={(formik.values.id as number) || (formik.values.itemId as number)}
          location={inventoryLocation as number}
          name="itemId"
          queryOverride={{
            // check stock transfer form as well
            callbackResolver: (data) =>
              data.filter((i) => {
                if (
                  formik.values.id === i.item.id &&
                  i.otherQuantities?.available <= 0
                ) {
                  messageContext.setError(
                    `The ${t('entities:Part.ItemCompanyId')}: ${
                      i?.item?.itemCompanyId
                    } Total ${t(
                      'entities:InventoryOtherQuantities.Available'
                    )} Quantity is ${i.otherQuantities?.available}`
                  );
                }
                return i.otherQuantities?.available > 0;
              }),
            queryString: reservePartListQuery,
          }}
        />
      )}

      {formik.values.itemId && (
        <>
          <ETOTextField
            disabled
            error={formik.errors.description}
            handleChange={formik.handleChange}
            label={t('generic.description')}
            multiline
            name="description"
            size="small"
            value={formik.values.description ?? ''}
          />

          {children}
          <ReasonRequiredDateForm formik={formik} />
        </>
      )}
    </>
  );
};

export default CommonPartForm;
