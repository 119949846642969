import { useEffect, useState } from 'react';
import useIsMobile from '../../../hooks/useIsMobile';

const DEFAULT_GRID_HEIGHT = 250;

function useDynamicGridHeight(
  itemsLengthInGrid: number,
  rowHeight: number | null,
  customGridHeight?: string | number
) {
  const [gridHeight, setGridHeight] = useState<string | number>('100%');
  const isMobile = useIsMobile();

  useEffect(() => {
    if (itemsLengthInGrid > 0 && !isMobile) {
      // ------------------ gridProps?.dataSource?.length
      const itemsInGrid = itemsLengthInGrid;

      if (itemsInGrid && rowHeight) {
        const totalHeight =
          itemsInGrid * rowHeight + DEFAULT_GRID_HEIGHT ?? customGridHeight;
        setGridHeight(totalHeight > 800 ? 800 : totalHeight);
      }
    }

    if (isMobile) {
      setGridHeight('calc(100vh - 20vh)');
    }
  }, [itemsLengthInGrid, isMobile, rowHeight, customGridHeight]);

  return gridHeight;
}

export default useDynamicGridHeight;
