import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import LanguageIcon from '@mui/icons-material/Language';
import { Link, Typography } from '@mui/material';
import { MessageContext } from '@teto/react-component-library-v2';
import { FormikHelpers } from 'formik';
import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import buildMutation from '../../../../../api/graphQL/buildMutation';
import useBuildQuery from '../../../../../api/graphQL/useBuildQuery';
import { InspectorActions } from '../../../types/InspectorActionTypes';
import { InspectorState } from '../../../types/InspectorState';
import { TAccordionListItem } from '../../AccordionList/AccordionList';
import AddEditList from '../AddEditList/AddEditList';
import { INotesForm } from '../AddEditNotes/NotesForm';
import { onAdd, onUpdate } from '../helpers/mutationHelpers';
import { Drawing } from './DrawingType';
import DrawingsForm, { IDrawingsForm } from './DrawingsForm';
import DrawingsValidationSchema from './validationSchema';

export type TDrawingsInspectorProps = Pick<
  InspectorActions<IDrawingsForm>,
  'setEditingState' | 'setHasASaveOccurred'
> &
  Pick<InspectorState<IDrawingsForm>, 'editingState'>;

interface DrawingsProps extends TDrawingsInspectorProps {
  /* eslint-disable no-unused-vars */
  canAdd: boolean;
  canDelete: boolean;
  canModify: boolean;
  canView: boolean;
  drawingsDownloadUrl: string;
  deleteMutationKey: string;
  mutationVariables: (value: Drawing, mode: 'add' | 'edit') => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onAddMutation: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onDeleteMutation: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onUpdateMutation: any;
  queryKey: string;
  queryVariables: Record<string, unknown>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  standardQuery: any;
  formatOnAdd: (value: unknown) => Drawing[] | undefined;
  formatOnUpdate: (value: unknown) => Drawing[] | undefined;
  /* eslint-enable no-unused-vars */
}

const _formatFileSize = (sizeInBytes: number) => {
  if (sizeInBytes < 1024) return `${sizeInBytes.toFixed(2)} Bytes`;
  if (sizeInBytes < 1024 * 1024)
    return `${(sizeInBytes / 1024).toFixed(2)} Kilobytes`;
  return `${(sizeInBytes / 1024 / 1024).toFixed(2)} Megabytes`;
};

const FileTypeIcon: React.FC<{ file: Drawing }> = (props) => {
  const { file } = props;
  return (
    <div
      style={{
        position: 'relative',
        display: 'inline-flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      {file.pathType === 'LOCAL' && (
        <>
          <InsertDriveFileIcon style={{ fontSize: '2.5em' }} />
          <Typography
            component="span"
            sx={{
              position: 'absolute',
              lineHeight: 1,
              color: (theme) =>
                theme.palette.getContrastText(theme.palette.text.primary),
              top: '1.5em',
              fontSize: '0.8em',
            }}
          >
            {file.fileExtension?.substring(1, 5)}
          </Typography>
        </>
      )}
      {file.pathType === 'WEB' && (
        <LanguageIcon style={{ fontSize: '2.5em' }} />
      )}
    </div>
  );
};

const AddEditDrawings = (props: DrawingsProps) => {
  const {
    canAdd,
    canDelete,
    canModify,
    canView,
    deleteMutationKey,
    drawingsDownloadUrl,
    editingState,
    formatOnAdd,
    formatOnUpdate,
    mutationVariables,
    onAddMutation,
    onDeleteMutation,
    onUpdateMutation,
    queryKey,
    queryVariables,
    setEditingState,
    setHasASaveOccurred,
    standardQuery,
  } = props;

  const messageContext = useContext(MessageContext);
  const [drawingsCollection, setDrawingsCollection] = useState<Drawing[]>([]);
  const [selectedItem, setSelectedItem] = useState<Drawing>();
  const [isNewItemOpen, setIsNewItemOpen] = useState(false);

  const { t } = useTranslation();

  const formikInitialValues: Partial<IDrawingsForm> = {
    description: '',
    drawing: '',
  };

  useBuildQuery(['AddEditDrawings'], {
    queryString: standardQuery,
    variables: queryVariables,
    callback: (data) => {
      if (data && typeof data === 'object' && queryKey in data) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        setDrawingsCollection((data as any)[queryKey].items);
      }
    },
    options: { enabled: canView, refetchOnWindowFocus: false, retry: false },
  });

  const _onAdd = (
    value: Partial<Drawing>,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    helpers: FormikHelpers<any>
  ) => {
    onAdd(
      value,
      {
        input: mutationVariables(value as Drawing, 'add'),
      },
      onAddMutation,
      formatOnAdd,
      setDrawingsCollection,
      setHasASaveOccurred,
      setSelectedItem,
      setEditingState,
      messageContext,
      (err) => {
        if (err.hasValidationErrors()) {
          err.handleValidationErrors(helpers.setErrors);
        }
        if (err.hasSystemErrors()) {
          err.showAllSystemErrors(messageContext.setError);
        }
      },
      setIsNewItemOpen
    );
  };
  const _onUpdate = (
    value: Drawing,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    helpers: FormikHelpers<any>
  ) => {
    onUpdate(
      value,
      {
        input: mutationVariables(value, 'edit'),
      },
      onUpdateMutation,
      formatOnUpdate,
      setDrawingsCollection,
      setHasASaveOccurred,
      selectedItem,
      setSelectedItem,
      setEditingState,
      drawingsCollection,
      messageContext,
      (err) => {
        if (err.hasValidationErrors()) {
          err.handleValidationErrors(helpers.setErrors);
        }
        if (err.hasSystemErrors()) {
          err.showAllSystemErrors(messageContext.setError);
        }
      }
    );
  };

  const handleDeleteMutation = (value: INotesForm) => {
    buildMutation({
      messageContext,
      queryString: onDeleteMutation,
      variables: { id: value.id },
      callback: (result: unknown) => {
        if (
          result &&
          typeof result === 'object' &&
          deleteMutationKey in result
        ) {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          setDrawingsCollection((result as any)[deleteMutationKey]);
        }

        messageContext.setSuccess(
          `${t('entities:Part.Drawing')} ${String(
            t('generic.delete')
          ).toLowerCase()}d successfully`
        );
      },
      errorCallback: (err) => {
        if (err.hasError()) err.handleAllErrors(messageContext.setError);
      },
    });
  };

  const formatListItems = (
    a: Record<string, unknown>
  ): TAccordionListItem<Drawing> => {
    const record: Drawing = a as unknown as Drawing;

    const docSplit = record.drawing.split('#');
    return {
      data: { ...record, description: docSplit[0], drawing: docSplit[1] },
      id: record.id,
      title: docSplit[0],
      icon: <FileTypeIcon file={record} />,
      subTitles: [
        <>
          {(a.pathType === 'LOCAL' || a.pathType === 'PDM') && (
            <>
              <b>{t('generic.download')} : </b>
              <Link
                href={`${drawingsDownloadUrl}${a.id}`}
                sx={{ textOverflow: 'ellipsis' }}
                target="_blank"
                title={docSplit[0]}
              >
                {record.fileName ?? docSplit[1]?.substring(0, 50)}
              </Link>
              {record.fileSize > 0 && (
                <Typography component="span">
                  &nbsp;[{_formatFileSize(record.fileSize)}]
                </Typography>
              )}
            </>
          )}
          {record.pathType === 'WEB' && (
            <>
              <b>{t('generic.open')}: </b>
              <Link
                href={
                  !docSplit[1]?.startsWith('http')
                    ? `https://${docSplit[1]}`
                    : docSplit[1]
                }
                sx={{ textOverflow: 'ellipsis' }}
                target="_blank"
                title={docSplit[0]}
              >
                {docSplit[1]?.substring(0, 50)}
              </Link>
            </>
          )}
        </>,
      ],
    };
  };

  return (
    <>
      <AddEditList
        accordionListItems={drawingsCollection}
        autoSave
        canAddItem={canAdd}
        canDeleteItem={canDelete}
        editingState={editingState}
        formatToAccordionListItem={formatListItems}
        formComponent={
          <>
            <DrawingsForm
              canModifyDrawing={canModify}
              setEditingState={setEditingState}
            />
          </>
        }
        formikInitialValues={
          formikInitialValues as unknown as Record<string, unknown>
        }
        formikSchema={DrawingsValidationSchema}
        icon={<InsertDriveFileIcon />}
        isNewItemOpen={isNewItemOpen}
        onAdd={_onAdd}
        onDelete={handleDeleteMutation}
        onUpdate={_onUpdate}
        selectedItem={selectedItem}
        setEditingState={setEditingState}
        setIsNewItemOpen={setIsNewItemOpen}
        setSelectedItem={setSelectedItem}
      />
    </>
  );
};

export default AddEditDrawings;
