import { Box, Grid, SxProps, Theme } from '@mui/material';
import {
  ETOCheckBox,
  ETOSelectField,
  ETOTextField,
  MessageContext,
} from '@teto/react-component-library-v2';
import { FormikHelpers, useFormik } from 'formik';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { NumericFormat, NumericFormatProps } from 'react-number-format';
import { useQuery } from 'react-query';
import { Licenses, Permission, getGraphQLClient } from 'teto-client-api';
import * as Yup from 'yup';
import {
  ApTaxCategory,
  Company,
  CurrencyRate,
  ReportAlternate,
  Shipper,
  Supplier,
  SupplierCategory,
  Term,
} from '../../../../../__generated__/graphql';
import { getGraphQLClient as OGetGraphQLClient } from '../../../../../api/graphQL/graphQLClient';
import { MAX_50 } from '../../../../../constants/numbers/numbers';
import { REQUIRED_MESSAGE } from '../../../../../constants/strings/strings';
import AuthContext from '../../../../../contexts/AuthContext';
import SettingsContext from '../../../../../contexts/SettingsContext';
import formatCharCount from '../../../../../helpers/formatCharCount';
import ChipList from '../../../../ChipList/ChipList';
import FobSelectField from '../../../../Modals/PurchaseOrderModal/panels/PurchaseOrderDetailsPanel/PurchaseOrderHeaderForm/InputsFields/FobSelectField';
import AutoSave from '../../../components/AddEditInspector/AutoSave/AutoSave';
import { InspectorActions } from '../../../types/InspectorActionTypes';
import handleNumberInputChange from '../../helpers/companyHelpers';
import getAPTaxCategoriesQuery from '../../queries/getAPTaxCategoriesQuery';
import getCompanySupplierCategoriesQuery from '../../queries/getCompanySupplierCategoriesQuery';
import getCompanySupplierQuery from '../../queries/getCompanySupplierQuery';
import getCompanyTermsQuery from '../../queries/getCompanyTermsQuery';
import getCurrencyQuery from '../../queries/getCurrencyQuery';
import getShippersQuery from '../../queries/getShippersQuery';
import {
  getSupplierAlternateReportQuery,
  getSupplierDefaultReportQuery,
} from '../../queries/getSupplierPoReportsQuery';
import updateCompanySupplierMutation from '../../queries/updateCompanySupplierMutation';
import {
  addSupplierCategoryMutation,
  categoryListQuery,
  deleteSupplierCategoryMutation,
} from './CompanySupplierInfoQueries';

const getSupplierInfoInitialValues = (data: Company & Partial<Supplier>) => ({
  aptaxCategoryId: data?.aptaxCategoryId ?? 0,
  defaultCurrNameId: data?.defaultCurrNameId ?? null,
  fobnotes: data?.fobnotes ?? '',
  id: data?.id ?? undefined,
  poreportAlternateId: data?.poreportAlternateId ?? 0,
  port: data?.port ?? '',
  supFob: data?.supFob ?? null,
  supNetTerms: data?.supNetTerms ?? null,
  supQaapproved: data?.supQaapproved ?? false,
  supSpecial: data?.supSpecial ?? undefined,
  supVia: data?.supVia ?? undefined,
});

interface CompanySupplierInfoPanelProps
  extends Pick<InspectorActions<Company>, 'setHasASaveOccurred'> {
  initialValues: Company;
  // eslint-disable-next-line no-unused-vars
  setPanelEditingState: (value: {
    isValid: boolean;
    isEditing: boolean;
  }) => void;
  companyId: number;
}

interface UpdateSupplierInput {
  id: number;
  supNetTerms: string;
  supFob: string;
  supVia: string;
  supSpecial: number;
  supQaapproved: boolean;
  port: string;
  fobnotes: string;
  poreportAlternateId: number;
  defaultCurrNameId: string;
  aptaxCategoryId: number;
}

type POReport = {
  id: number;
  reportDisplayName: string;
};

const containerSx = (theme: Theme) => ({
  paddingTop: theme.spacing(1),
  overflowY: 'auto',
  width: '100%',
  height: '100%',
});

const contentSx: SxProps<Theme> = {
  flexDirection: { xs: 'column', md: 'row' },
  flexWrap: { xs: 'nowrap' },
  height: '100%',
};

const supplierSchema = Yup.object().shape({
  aptaxCategoryId: Yup.number().required(REQUIRED_MESSAGE),
  defaultCurrNameId: Yup.string().nullable().max(3),
  fobnotes: Yup.string().nullable().max(MAX_50),
  poreportAlternateId: Yup.number().nullable(),
  port: Yup.string().nullable().max(MAX_50),
  supFob: Yup.string().nullable(),
  supNetTerms: Yup.string().nullable(),
  supQaapproved: Yup.boolean().required(REQUIRED_MESSAGE),
  supSpecial: Yup.number().nullable(),
  supVia: Yup.string().nullable(),
});

interface CustomProps {
  // eslint-disable-next-line no-unused-vars
  onChange: (event: { target: { name: string; value: string } }) => void;
  name: string;
}

interface ChipSupplierCategory extends SupplierCategory {
  id: number;
  categoryDescription: string;
}

const NumericFormatCustom = React.forwardRef<NumericFormatProps, CustomProps>(
  (props, ref) => {
    const { onChange, ...other } = props;

    return (
      <NumericFormat
        {...other}
        decimalScale={props.name === 'accCustomerDisc' ? 2 : 0}
        getInputRef={ref}
        onValueChange={(values) => {
          onChange({
            target: {
              name: props.name,
              value: values.value,
            },
          });
        }}
        suffix={props.name === 'accCustomerDisc' ? '%' : ''}
        valueIsNumericString
      />
    );
  }
);

const CompanySupplierInfoPanel = (props: CompanySupplierInfoPanelProps) => {
  const {
    initialValues,
    setPanelEditingState,
    setHasASaveOccurred,
    companyId,
  } = props;

  const { t } = useTranslation();

  const authContext = useContext(AuthContext);
  const messageContext = useContext(MessageContext);
  const settingsContext = useContext(SettingsContext);

  const [supplierInfo, setSupplierInfo] = useState<Partial<Supplier>>({
    id: companyId as number,
  });

  const [companyCategories, setCompanyCategories] = useState<
    ChipSupplierCategory[]
  >([]);
  const [pOReports, setPOReports] = useState<POReport[]>([]);
  const [refreshCategories, setRefreshCategories] = useState<boolean>(false);

  const canUpdateSupplierInfoPermission =
    authContext.hasLicense(Licenses.TotalETOProfessional) &&
    authContext.hasPermission(
      Permission.Modify_Sales_CompanyInformation_SupplierInformation
    );

  const { canDelete, canModify, canView } = {
    canView:
      authContext.hasLicense(Licenses.ReadOnlyProfessional) &&
      authContext.hasAnyPermission([
        Permission.Add_Sales_CompanyInformation_SupplierInformation_SupplierCategories,
        Permission.View_Sales_CompanyInformation_SupplierInformation_SupplierCategories,
        Permission.Delete_Sales_CompanyInformation_SupplierInformation_SupplierCategories,
        Permission.Modify_Sales_CompanyInformation_SupplierInformation_SupplierCategories,
      ]),
    canModify:
      authContext.hasLicense(Licenses.TotalETOProfessional) &&
      authContext.hasPermission(
        Permission.Modify_Sales_CompanyInformation_SupplierInformation_SupplierCategories
      ),
    canDelete:
      authContext.hasLicense(Licenses.TotalETOProfessional) &&
      authContext.hasPermission(
        Permission.Delete_Sales_CompanyInformation_SupplierInformation_SupplierCategories
      ),
  };

  const formik = useFormik<Partial<Supplier>>({
    enableReinitialize: true,
    initialValues: getSupplierInfoInitialValues(
      supplierInfo as Company & Supplier
    ) as Company & Supplier,
    validateOnMount: false,
    validationSchema: supplierSchema,
    onSubmit: (values, helpers) => {
      _handleSubmit(values, helpers);
      helpers?.setSubmitting(false);
    },
  });

  const _handleSubmit = useCallback(
    (data: Partial<Supplier>, helper: FormikHelpers<Partial<Supplier>>) => {
      getGraphQLClient()
        .performMutation(updateCompanySupplierMutation, {
          input: {
            ...data,
            id: companyId,
            aptaxCategoryId: !data.aptaxCategoryId
              ? undefined
              : data.aptaxCategoryId,
            poreportAlternateId:
              data?.poreportAlternateId !== 0
                ? data?.poreportAlternateId
                : null,
          } as unknown as UpdateSupplierInput,
        })
        .then((d) => {
          if (d.hasError()) {
            d.showAllSystemErrors(messageContext.setError);
            if (d.hasValidationErrors()) {
              const { input } = d.validationErrors;
              messageContext.setError(Object.values(input)[0] as string);
            }
            return;
          }
          if (typeof d === 'object' && d.data.updateSupplier) {
            setSupplierInfo(d.data.updateSupplier);
            setHasASaveOccurred(true);
            setPanelEditingState({
              isEditing: false,
              isValid: true,
            });

            messageContext.setSuccess(
              t('generic.updateSuccess', {
                record: t('entities:Company.Supplier'),
              })
            );
          }
        })
        .finally(() => helper.setSubmitting(false));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [setHasASaveOccurred, setPanelEditingState, formik, t]
  );

  useEffect(() => {
    if (supplierInfo?.id) {
      getGraphQLClient()
        .performQuery(getCompanySupplierQuery, { id: supplierInfo?.id })
        .then((d) => {
          if (d.hasError()) {
            d.showAllSystemErrors(messageContext.setError);
            if (d.hasValidationErrors()) {
              const { input } = d.validationErrors;
              messageContext.setError(Object.values(input)[0] as string);
            }
            return;
          }
          setSupplierInfo(d.data.supplier);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [supplierInfo?.id]);

  useEffect(() => {
    if (supplierInfo?.id || refreshCategories) {
      getGraphQLClient()
        .performQuery(getCompanySupplierCategoriesQuery, {
          filter: {
            companyId: { eq: supplierInfo?.id },
          },
        })
        .then((d) => {
          if (d.hasError()) {
            d.showAllSystemErrors(messageContext.setError);
            if (d.hasValidationErrors()) {
              const { input } = d.validationErrors;
              messageContext.setError(Object.values(input)[0] as string);
            }
            return;
          }
          const mappedItems = d.data.supplierCategories.items.map(
            (i: SupplierCategory) => ({
              ...i,
              id: i.itemCategoryId,
              categoryDescription: i.itemCategory?.categoryDescription,
            })
          );

          setCompanyCategories(mappedItems);
          setRefreshCategories(false);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [supplierInfo?.id, refreshCategories]);

  useEffect(() => {
    setPanelEditingState({
      isEditing: formik.dirty,
      isValid: formik.isValid,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.dirty, formik.isValid]);

  const shippers = useQuery<Shipper[]>(
    ['shippers'],
    () =>
      getGraphQLClient()
        .performQuery(getShippersQuery, {})
        .then((d) => {
          if (d.hasError()) {
            d.showAllSystemErrors(messageContext.setError);
            if (d.hasValidationErrors()) {
              const { input } = d.validationErrors;
              messageContext.setError(Object.values(input)[0] as string);
            }
            return;
          }
          return d.data.shippers.items;
        }),
    {
      enabled: Boolean(supplierInfo?.id),
      refetchOnWindowFocus: false,
    }
  );

  const terms = useQuery<Term[]>(
    ['terms'],
    () =>
      getGraphQLClient()
        .performQuery(getCompanyTermsQuery, {})
        .then((d) => {
          if (d.hasError()) {
            d.showAllSystemErrors(messageContext.setError);
            if (d.hasValidationErrors()) {
              const { input } = d.validationErrors;
              messageContext.setError(Object.values(input)[0] as string);
            }
            return;
          }
          return d.data.terms.items;
        }),
    {
      enabled: Boolean(supplierInfo?.id),
      refetchOnWindowFocus: false,
    }
  );

  const aPTaxCategories = useQuery<ApTaxCategory[]>(
    ['aPTaxCategories'],
    () =>
      getGraphQLClient()
        .performQuery(getAPTaxCategoriesQuery, {})
        .then((d) => {
          if (d.hasError()) {
            d.showAllSystemErrors(messageContext.setError);
            if (d.hasValidationErrors()) {
              const { input } = d.validationErrors;
              messageContext.setError(Object.values(input)[0] as string);
            }
            return;
          }
          return d.data.aPTaxCategories.items;
        }),
    {
      enabled: Boolean(supplierInfo?.id),
      refetchOnWindowFocus: false,
    }
  );

  const currency = useQuery<CurrencyRate[]>(
    ['currency-rate'],
    () =>
      getGraphQLClient()
        .performQuery(getCurrencyQuery, {})
        .then((d) => {
          if (d.hasError()) {
            d.showAllSystemErrors(messageContext.setError);
            if (d.hasValidationErrors()) {
              const { input } = d.validationErrors;
              messageContext.setError(Object.values(input)[0] as string);
            }
            return;
          }
          return d.data.currencyRates.items;
        }),
    {
      enabled: Boolean(supplierInfo?.id),
      refetchOnWindowFocus: false,
    }
  );

  useEffect(() => {
    const defaultReportQuery = getGraphQLClient().performQuery(
      getSupplierDefaultReportQuery,
      {}
    );

    const alternateReportsQuery = getGraphQLClient().performQuery(
      getSupplierAlternateReportQuery,
      {}
    );

    Promise.all([defaultReportQuery, alternateReportsQuery]).then(
      ([infoResult, typeResult]) => {
        if (infoResult.hasError()) {
          infoResult.showAllSystemErrors(messageContext.setError);
          if (infoResult.hasValidationErrors()) {
            const { input } = infoResult.validationErrors;
            messageContext.setError(Object.values(input)[0] as string);
          }
          return;
        }
        if (typeResult.hasError()) {
          typeResult.showAllSystemErrors(messageContext.setError);
          if (typeResult.hasValidationErrors()) {
            const { input } = typeResult.validationErrors;
            messageContext.setError(Object.values(input)[0] as string);
          }
          return;
        }
        const result: POReport[] = [
          {
            id: infoResult?.data.employeeReport.alternateId,
            reportDisplayName:
              infoResult?.data.employeeReport.reportDisplayName,
          },
        ];

        typeResult.data.reportAlternates.items.forEach((i: ReportAlternate) =>
          result.push({ id: i.id, reportDisplayName: i.reportDisplayName })
        );
        setPOReports(result);
      }
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const _handleDeleteClick = (category: ChipSupplierCategory) => {
    getGraphQLClient()
      .performMutation(deleteSupplierCategoryMutation, {
        input: {
          companyId,
          itemCategoryId: category.itemCategoryId,
        },
      })
      .then((d) => {
        if (d.hasError()) {
          d.showAllSystemErrors(messageContext.setError);
          if (d.hasValidationErrors()) {
            const { input } = d.validationErrors;
            messageContext.setError(Object.values(input)[0] as string);
          }
          return;
        }
        if (d.data.deleteSupplierCategory?.success) {
          setRefreshCategories(true);
          setHasASaveOccurred(true);
        }
      });
  };
  const _handleAddClick = useCallback(
    (item: ChipSupplierCategory) => {
      OGetGraphQLClient()
        .performMutation(
          addSupplierCategoryMutation,
          {
            input: {
              companyId,
              itemCategoryId: item?.itemCategory,
            },
          },
          (err) => messageContext.setError(err.messages[0]),
          (err) => {
            const { input } = err;

            messageContext.setError(Object.values(input)[0] as string);
          }
        )
        .then((d) => {
          if (d.addSupplierCategory.itemCategoryId >= 0) {
            setRefreshCategories(true);
            setHasASaveOccurred(true);
          }
        });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [initialValues.id, setHasASaveOccurred]
  );

  return (
    <Box sx={containerSx}>
      <AutoSave debounceMs={3000} formik={formik} />
      {canView && (
        <ChipList<ChipSupplierCategory>
          addButtonLabel={`${t('generic.add')} ${t('generic.category')}`}
          canAddItems={canModify}
          canDeleteItems={canDelete}
          chipListItemsQuery={categoryListQuery}
          handleAddClick={(item) => _handleAddClick(item)}
          handleDeleteClick={(item) => _handleDeleteClick(item)}
          idProperty="itemCategory"
          itemLabelSelector={(item) => item.categoryDescription}
          listItems={companyCategories}
          queryKey="itemMasterCategories"
        />
      )}
      <Grid container mb={2} spacing={2} sx={contentSx}>
        <Grid item xs={12}>
          <Grid container spacing={2}>
            <Grid item md={6} xs={12}>
              <ETOSelectField
                disabled={!canUpdateSupplierInfoPermission}
                error={formik.errors.supNetTerms}
                freeSolo={!settingsContext.settings.limitAccountingTermsToList}
                handleChange={formik.handleChange}
                itemNameSelector={(item) => {
                  if (typeof item === 'string') return item;
                  return item.description;
                }}
                items={terms.data ?? []}
                itemValueSelector={(item) => {
                  if (typeof item === 'string') return item;
                  return item.description;
                }}
                label={t('entities:Supplier.SupNetTerms')}
                name="supNetTerms"
                onBlur={(e) => {
                  if (settingsContext.settings.limitAccountingTermsToList)
                    return;
                  return formik.setFieldValue('supNetTerms', e.target?.value);
                }}
                size="small"
                value={formik.values.supNetTerms ?? null}
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <FobSelectField
                disabled={!canUpdateSupplierInfoPermission}
                error={formik.errors.supFob}
                handleChange={(e) =>
                  formik.setFieldValue('supFob', e.target.value)
                }
                value={formik.values.supFob ?? null}
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <ETOTextField
                disabled={!canUpdateSupplierInfoPermission}
                error={formik.errors.fobnotes}
                handleChange={formik.handleChange}
                helperText={formatCharCount(
                  'fobnotes',
                  formik.values.fobnotes,
                  MAX_50
                )}
                label={t('entities:Supplier.Fobnotes')}
                name="fobnotes"
                size="small"
                value={formik.values.fobnotes}
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <ETOTextField
                disabled={!canUpdateSupplierInfoPermission}
                error={formik.errors.port}
                handleChange={formik.handleChange}
                helperText={formatCharCount('port', formik.values.port, MAX_50)}
                label={t('entities:Supplier.Port')}
                name="port"
                size="small"
                value={formik.values.port ?? null}
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <ETOSelectField
                disabled={!canUpdateSupplierInfoPermission}
                error={formik.errors.supVia}
                handleChange={formik.handleChange}
                itemNameSelector={(item: Shipper) => item.shipperName}
                items={shippers.data ?? []}
                itemValueSelector={(item) => item.shipperName}
                label={t('entities:Supplier.SupVia')}
                name="supVia"
                size="small"
                value={formik.values.supVia ?? null}
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <ETOSelectField
                disabled={!canUpdateSupplierInfoPermission}
                error={formik.errors.poreportAlternateId}
                handleChange={formik.handleChange}
                itemNameSelector={(i) => i.reportDisplayName}
                items={pOReports ?? []}
                itemValueSelector={(i) => i.id}
                label={t('entities:Supplier.PoreportAlternate')}
                name="poreportAlternateId"
                size="small"
                value={formik.values.poreportAlternateId ?? null}
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <ETOTextField
                disabled={!canUpdateSupplierInfoPermission}
                error={formik.errors.supSpecial}
                handleChange={({ target: { name, value } }) =>
                  formik.setFieldValue(
                    name,
                    handleNumberInputChange({ value, type: 'int' })
                  )
                }
                InputProps={{
                  // eslint-disable-next-line @typescript-eslint/no-explicit-any
                  inputComponent: NumericFormatCustom as any,
                }}
                label={t('entities:Supplier.SupSpecial')}
                name="supSpecial"
                size="small"
                value={formik.values.supSpecial ?? ''}
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <ETOSelectField
                disabled={!canUpdateSupplierInfoPermission}
                error={formik.errors.defaultCurrNameId}
                handleChange={formik.handleChange}
                itemNameSelector={(i) => i?.name as string}
                items={currency.data ?? []}
                itemValueSelector={(i) => i.name}
                label={t('entities:Supplier.DefaultCurrName')}
                name="defaultCurrNameId"
                size="small"
                value={formik.values.defaultCurrNameId ?? null}
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <ETOSelectField
                disabled={!canUpdateSupplierInfoPermission}
                error={formik.errors.aptaxCategoryId}
                handleChange={({ target: { name, value } }) =>
                  formik.setFieldValue(
                    name,
                    handleNumberInputChange({ value, type: 'int' })
                  )
                }
                itemNameSelector={(item) => item.name}
                items={aPTaxCategories.data ?? []}
                itemValueSelector={(item) => item.id}
                label={t('entities:APTaxCategory.APTaxCategory')}
                name="aptaxCategoryId"
                size="small"
                value={formik.values.aptaxCategoryId ?? null}
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <ETOCheckBox
                disabled={!canUpdateSupplierInfoPermission}
                handleChange={({ target: { name, checked } }) =>
                  formik.setFieldValue(name, checked)
                }
                label={t('entities:Supplier.SupQaapproved')}
                name="supQaapproved"
                size="small"
                value={formik.values.supQaapproved ?? false}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default CompanySupplierInfoPanel;
