import { gql } from '../../../../__generated__';

const deleteCompanyContactMutation =
  gql(`mutation deleteCompanyContactMutation ($id: Int!){
  deleteContact (id: $id) {
    success
  }
}`);

export default deleteCompanyContactMutation;
