import { SxProps, Theme } from '@mui/material';
import React, { useCallback, useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { DefaultFob } from '../../../../../../../__generated__/graphql';
import SettingsContext from '../../../../../../../contexts/SettingsContext';
import ETOGridSelectField from '../../../../../../InputFields/ETOGridSelectField/ETOGridSelectField';
import { getDefaultFOBs } from '../../../../../../TETOForms/selects/selects';

interface FobSelectFieldProps {
  disabled?: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  value: any;
  error?: string;
  sx?: SxProps<Theme>;
  // eslint-disable-next-line no-unused-vars, @typescript-eslint/no-explicit-any
  handleChange: (e: Partial<React.ChangeEvent<any>>) => void;
  // eslint-disable-next-line no-unused-vars
  handleDisplayValue?: (v?: string, d?: DefaultFob[]) => void;
}

const FobSelectField = (props: FobSelectFieldProps) => {
  const { value, error, disabled, handleChange, sx, handleDisplayValue } =
    props;

  const { t } = useTranslation();
  const settingsContext = useContext(SettingsContext);

  const columns = useMemo(
    () => [
      {
        name: 'fob',
        header: t('entities:DefaultFob.Fob'),
        defaultFlex: 1,
        sortable: true,
        showColumnMenuTool: false,
      },
      {
        name: 'standsFor',
        header: t('entities:DefaultFob.StandsFor'),
        defaultFlex: 2,
        sortable: true,
        showColumnMenuTool: false,
      },
    ],
    [t]
  );

  const _handleChange = (item: unknown) => {
    if (item && typeof item === 'object' && 'fob' in item) {
      return handleChange({
        target: {
          name: 'fob',
          value: item.fob,
        },
      });
    }
    if (item && typeof item === 'string') {
      return handleChange({
        target: {
          name: 'fob',
          value: item,
        },
      });
    }
    return handleChange({
      target: {
        name: 'fob',
        value: undefined,
      },
    });
  };
  const _matchValue = useCallback(
    (text: string | undefined, item: DefaultFob) =>
      item.fob.toUpperCase() === text?.toUpperCase(),
    []
  );

  const _handleDisplayValue = useCallback(
    (fobValue?: string, data?: DefaultFob[]) => {
      if (handleDisplayValue) return handleDisplayValue(fobValue, data);

      if (fobValue && data) {
        const display = data?.find((i: DefaultFob) => i.fob === fobValue);
        if (display) {
          return display?.fob;
        }
        if (fobValue !== '' && fobValue) {
          return fobValue;
        }
      }

      return '';
    },
    [handleDisplayValue]
  );

  return (
    <ETOGridSelectField
      customSx={sx}
      disabled={disabled}
      error={error}
      fieldName="purchaseFob"
      formatInputValue={(text) => text?.toUpperCase()}
      gridProps={{
        columns,
      }}
      handleChange={_handleChange}
      handleRenderValue={(v, d) =>
        _handleDisplayValue(v as string, d as DefaultFob[]) as string
      }
      label={t('entities:DefaultFob.Fob')}
      search={{
        textValueMatcher: (text, item) => _matchValue(text, item as DefaultFob),
        inputMustMatchSearch: settingsContext.settings.limitFOBToList,
      }}
      source={{
        name: getDefaultFOBs.labelField as string,
        value: getDefaultFOBs.valueField as string,
        gqlSource: {
          query: getDefaultFOBs.query,
        },
      }}
      testId="purchaseFob-grid-input"
      value={value}
    />
  );
};
export default FobSelectField;
