const formatCharCount = (
  fieldName: string,
  currentValue?: string | null,
  maxChars = 100
): string => {
  const count = currentValue ? currentValue.length : 0;
  return `${count.toString()} / ${maxChars}`;
};

export default formatCharCount;
