import { Box, SxProps, Theme, Typography } from '@mui/material';
import {
  ETOSelectField,
  YesNoConfirmDialog,
} from '@teto/react-component-library-v2';
import React from 'react';
import { useTranslation } from 'react-i18next';
import useGQLQuery from '../../../../../../../../api/graphQL/useGQLQuery';
import { ReportAlternate } from '../../../../../../../../__generated__/graphql';
import getProcessScheduleHeaderReports from '../../../../../../../TETOForms/selects/getProcessScheduleHeaderReports';

const contextSx: SxProps<Theme> = {
  display: 'flex',
  flexDirection: 'column',
  rowGap: 2,
};

interface PrintProcessScheduleModalProps {
  open: boolean;
  noOfItems: number;
  handleNo: () => void;
  handleYes: () => void;
  alternateId: number;
  setAlternateId: React.Dispatch<React.SetStateAction<number>>;
}

const PrintProcessScheduleModal = (props: PrintProcessScheduleModalProps) => {
  const { open, noOfItems, handleNo, handleYes, alternateId, setAlternateId } =
    props;

  const { t } = useTranslation();

  const processScheduleHeaderReports = useGQLQuery<{
    reportAlternateList: { items: ReportAlternate[] };
  }>(['processScheduleHeaders'], {
    queryString: getProcessScheduleHeaderReports.query,
    variables: { reportId: 230 },
    options: {
      enabled: true,
      refetchOnWindowFocus: false,
    },
  });

  return (
    <YesNoConfirmDialog
      content={
        <Box sx={contextSx}>
          <Typography variant="body1">
            {t('pages.procurement.printProcessScheduleModal.content', {
              noOfItems,
            })}
          </Typography>
          <ETOSelectField
            handleChange={(e) =>
              setAlternateId(e.target.value ? parseInt(e.target.value, 10) : 0)
            }
            itemNameSelector={(item) => item.reportDisplayName}
            items={
              processScheduleHeaderReports.data?.reportAlternateList?.items ??
              []
            }
            itemValueSelector={(item) => item.reportAlternateId}
            label={t('generic.reports')}
            name="report"
            value={alternateId}
          />
        </Box>
      }
      onNo={() => handleNo()}
      onYes={() => handleYes()}
      open={open}
      title={t('pages.procurement.printProcessScheduleModal.title')}
    />
  );
};

export default PrintProcessScheduleModal;
