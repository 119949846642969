import { gql } from '../../../../__generated__';

const updateRFQDetailDates = gql(
  `  mutation updateRFQDetailDates($input: UpdateRFQDetailDatesInput) {
    updateRFQDetailDates(input: $input) {
      items {
        id
      }
    }
  }`
);
export default updateRFQDetailDates;
