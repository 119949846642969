import { MessageContext } from '@teto/react-component-library-v2';
import { useContext, useEffect, useState } from 'react';
import { getGraphQLClient } from 'teto-client-api';
import { UomDescription } from '../../../../../__generated__/graphql';
import uOMDescriptionQuery from '../../../queries/uOMDescriptionQuery';

interface Value {
  pattern: RegExp;
  step: number;
}

const integerPattern = /^d+$/;
const decimalPattern = /^d+(.d+)?$/;

const usePartQuantityPattern = (itemCompanyId: string) => {
  const [uOM, setUOM] = useState<Value>({ pattern: decimalPattern, step: 0.1 });
  const [id, setId] = useState<string>(itemCompanyId);

  const messageContext = useContext(MessageContext);

  useEffect(() => {
    if (id) {
      getGraphQLClient()
        .performQuery(uOMDescriptionQuery, {
          filter: {
            engItemMasters: {
              some: { itemCompanyId: { eq: id } },
            },
          },
        })
        .then((d) => {
          if (d.hasError()) {
            d.showAllSystemErrors(messageContext.setError);
            if (d.hasValidationErrors()) {
              const { input } = d.validationErrors;
              messageContext.setError(Object.values(input)[0] as string);
            }
            return;
          }
          let uomId: UomDescription | undefined | null;
          if (d?.data.uOMDescriptions?.items) {
            uomId = d?.data.uOMDescriptions?.items[0];
          }
          if (uomId && uomId.itemUom && uomId.itemUom === 2)
            setUOM({ pattern: integerPattern, step: 1 });
          else setUOM({ pattern: decimalPattern, step: 0.1 });
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  return { uOM, setId };
};

export default usePartQuantityPattern;
