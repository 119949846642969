import { gql } from '../../../../__generated__';

const currenciesQuery = gql(`
  query poHeadersCurrencies {
    currencyRates{
      items{
        symbol
        rate
        format
        name
      }
    }
  }`);

export default currenciesQuery;
