import { Autocomplete, debounce, MenuItem, TextField } from '@mui/material';
import { MessageContext } from '@teto/react-component-library-v2';
import { FormikProps } from 'formik';
import React, { useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { getGraphQLClient } from '../../../../../../api/graphQL/graphQLClient';
import { INonConformance } from '../../../interfaces/NonConformanceAssignment';
import { purchaseOrderQuery } from '../queries/InfoPanelQueries';

type InfoValues = Partial<INonConformance> & {
  customer?: string;
  partNumber?: string;
  supplier?: string;
  item: {
    id: number;
    itemCompanyId: string;
  };
};

interface PurchaseOrderSearchInputProps {
  formik: FormikProps<InfoValues>;
  disabled?: boolean;
}

const PurchaseOrderSearchInput = (props: PurchaseOrderSearchInputProps) => {
  const { formik, disabled } = props;

  const { t } = useTranslation();

  const messageContext = useContext(MessageContext);

  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [pOValue, setPOValue] = React.useState<any[] | null>(null);
  const [inputValue, setInputValue] = React.useState('');
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [pONumbers, setPONumbers] = React.useState<any[]>([]);

  const debouncedSubmit = useMemo(
    () =>
      debounce(
        () =>
          getGraphQLClient()
            .performQuery(
              purchaseOrderQuery,
              {
                searchTerm: inputValue,
              },
              (err) => messageContext.setError(err.messages[0]),
              (err) => {
                const { input } = err;

                messageContext.setError(Object.values(input)[0] as string);
              }
            )
            .then((d) => {
              if (d.searchPurchaseOrderHeadersByPONumber) {
                if (d.searchPurchaseOrderHeadersByPONumber.items.length > 0) {
                  setPONumbers(d.searchPurchaseOrderHeadersByPONumber.items);
                  formik.setFieldError('purchaseOrderId', undefined);
                } else {
                  formik.setFieldError(
                    'purchaseOrderId',
                    'Invalid Purchase Order'
                  );
                }
                setIsLoading(false);
              }
            }),
        1000
      ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [inputValue, purchaseOrderQuery]
  );

  React.useEffect(() => {
    if (inputValue === '') {
      setPONumbers(pOValue ? [pOValue] : []);
    } else {
      setIsLoading(true);
      debouncedSubmit();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pOValue, inputValue]);

  return (
    <Autocomplete
      autoComplete
      data-testid="nci-purchase-order"
      disabled={disabled}
      filterOptions={(x) => x}
      filterSelectedOptions
      forcePopupIcon
      freeSolo
      fullWidth
      getOptionLabel={(purchaseOrder) =>
        purchaseOrder.id ? purchaseOrder.id.toString() : ''
      }
      id="purchaseOrderId"
      includeInputInList
      isOptionEqualToValue={(option, value) =>
        value === undefined || value === '' || option.id === value.id
      }
      loading={isLoading}
      noOptionsText={`${t('generic.no')} ${t('generic.results')}`}
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      onChange={(event: any, newValue: any | null) => {
        setPONumbers(newValue ? [newValue, ...pONumbers] : pONumbers);
        setPOValue(newValue);
        if (newValue === null) {
          formik.setFieldValue('purchaseOrderId', null);
        } else {
          formik.setFieldValue('purchaseOrderId', newValue.id);
        }
      }}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue);
      }}
      options={pONumbers}
      renderInput={(params) => (
        <TextField
          {...params}
          error={Boolean(formik.errors.purchaseOrderId)}
          fullWidth
          helperText={formik.errors.purchaseOrderId}
          inputProps={{ ...params.inputProps, min: 0 }}
          label={t('entities:PurchaseOrderHeader.PurchaseOrderHeader')}
          name="purchaseOrderId"
          size="small"
          type="number"
        />
      )}
      renderOption={(prp, option) => (
        <MenuItem {...prp} key={option.id} value={option.id}>
          {option.id} - {option.purchaseSupplier.name}
        </MenuItem>
      )}
      size="small"
      value={formik.values.purchaseOrder ?? undefined}
    />
  );
};

export default PurchaseOrderSearchInput;
