import { TypeFnParam } from '@inovua/reactdatagrid-community/types';
import ReactDataGrid from '@inovua/reactdatagrid-enterprise';
import dayjs from 'dayjs';

const emptyType = {
  name: 'empty',
  disableFilterEditor: true,
  valueOnOperatorSelect: 'Empty',
  fn: (props: TypeFnParam) => props.value === null,
  emptyValue: '',
};
const dateEmptyType = {
  ...emptyType,
  valueOnOperatorSelect: dayjs().toString(),
};
const boolEmptyType = {
  ...emptyType,
  valueOnOperatorSelect: null,
  fn: (props: TypeFnParam) => props.value === null,
};
const numberEmptyType = {
  ...emptyType,
  valueOnOperatorSelect: null,
  fn: (props: TypeFnParam) => props.value === null,
};

const notEmptyType = {
  name: 'notEmpty',
  disableFilterEditor: true,
  valueOnOperatorSelect: 'Not Empty',
  fn: (props: TypeFnParam) => props.value !== null,
  emptyValue: '',
};

const dateOperators =
  ReactDataGrid.defaultProps.filterTypes.date.operators.filter(
    (filter) => filter.name !== 'notinrange'
  );

const numberOperators =
  ReactDataGrid.defaultProps.filterTypes.number.operators.filter(
    (filter) => filter.name !== 'notinrange'
  );

const stringOperators =
  ReactDataGrid.defaultProps.filterTypes.string.operators.map((filter) => {
    if (filter.name === 'empty') {
      return emptyType;
    }
    if (filter.name === 'notEmpty') {
      return notEmptyType;
    }
    return filter;
  });

const filterTypes = {
  ...ReactDataGrid.defaultProps.filterTypes,
  string: {
    ...ReactDataGrid.defaultProps.filterTypes.string,
    operators: stringOperators,
    emptyValue: '',
  },
  boolean: {
    ...ReactDataGrid.defaultProps.filterTypes.boolean,
    operators: [
      ...ReactDataGrid.defaultProps.filterTypes.boolean.operators,
      boolEmptyType,
    ],
    emptyValue: '',
  },
  number: {
    ...ReactDataGrid.defaultProps.filterTypes.number,
    operators: [...numberOperators, numberEmptyType],
    emptyValue: '',
  },
  datetime: {
    ...ReactDataGrid.defaultProps.filterTypes.date,
    name: 'datetime',
    operators: [...dateOperators, dateEmptyType],
    emptyValue: '',
  },
  date: {
    ...ReactDataGrid.defaultProps.filterTypes.date,
    operators: [...dateOperators, dateEmptyType],
    emptyValue: '',
  },
};

export default filterTypes;
