import { SxProps } from '@mui/material';
import { Dayjs } from 'dayjs';
import { isEmpty } from 'lodash';
import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { EngItemMaster } from '../../../__generated__/graphql';
import { buildCellFormatter } from '../../TETOGridGraphQL/Formatters/cellFormatterHelper';
import ETOGridSelectField from '../ETOGridSelectField/ETOGridSelectField';

interface RawMaterialSelectFieldProps {
  disabled?: boolean;
  // eslint-disable-next-line no-unused-vars, @typescript-eslint/no-explicit-any
  handleChange: (e: Partial<React.ChangeEvent<any>>) => void;
  error: string | undefined;
  value: number | undefined;
  sx?: SxProps;
  label?: string;
  handleInputClear?: () => void;
}

const rawMaterialQuery = `query engItemMastersQuery($filter: EngItemMasterFilterInput) {
  engItemMasters(
    order: { rawMaterialItemId: DESC }
    where: $filter
    take: 250
  ) {
    items {
      id
      itemCompanyId
      description
      category {
        categoryDescription
      }
      uOM {
        uomtype
      }
    }
  }
}

`;

const FIELD_NAME = 'rawMaterialItem';

const RawMaterialSelectField = (props: RawMaterialSelectFieldProps) => {
  const { disabled, handleChange, error, value, sx, label, handleInputClear } =
    props;
  const { t } = useTranslation();

  const columns = useMemo(
    () => [
      {
        id: 'itemCompanyId',
        name: 'itemCompanyId',
        header: t('entities:Part.ItemCompanyId'),
        title: t('entities:Part.ItemCompanyId'),
        defaultFlex: 1,
        sortable: true,
        order: 1,
        align: 'start' as const,
        type: 'string',
        fixed: 'none' as const,
        filterType: 'string',
      },
      {
        id: 'description',
        name: 'description',
        header: t('generic.description'),
        title: t('generic.description'),
        defaultFlex: 2,
        sortable: true,
        order: 2,
        align: 'start' as const,
        type: 'string',
        fixed: 'none' as const,
        filterType: 'string',
      },
      {
        id: 'uOM.uomtype',
        name: 'uOM.uomtype',
        header: t('entities:UOMDescription.Uomtype'),
        title: t('entities:UOMDescription.Uomtype'),
        defaultFlex: 1,
        sortable: true,
        order: 2,
        align: 'start' as const,
        type: 'string',
        fixed: 'none' as const,
        filterType: 'string',
      },
      {
        id: 'category.categoryDescription',
        name: 'category.categoryDescription',
        header: t('entities:ItemMasterCategory.CategoryDescription'),
        title: t('entities:ItemMasterCategory.CategoryDescription'),
        defaultFlex: 1,
        sortable: true,
        order: 2,
        align: 'start' as const,
        type: 'string',
        fixed: 'none' as const,
        filterType: 'string',
      },
    ],
    [t]
  );

  const _handleRenderValue = useCallback(
    (
      select: string | number | true | Dayjs,
      data: Partial<EngItemMaster>[]
    ): string => {
      if (select === -1) return '';

      const option = data.find((o) => o.id === select);
      return option?.itemCompanyId as string;
    },
    []
  );

  const _handleValueChange = useCallback(
    (d: Partial<EngItemMaster>) => {
      if (typeof d === 'object' && 'id' in d) {
        handleChange({
          target: {
            name: FIELD_NAME,
            value: {
              id: d?.id ?? '',
            },
          },
        });
      }
    },
    [handleChange]
  );

  const searchObj = useMemo(
    () => ({
      textValueMatcher: (text: string, item: Partial<EngItemMaster>) =>
        item?.itemCompanyId?.toLowerCase() === text.toLowerCase(),
      filterColumn: {
        name: 'itemCompanyId',
        operator: 'contains',
        type: 'string',
        value: '',
      },
    }),
    []
  );

  const convertedToRDGColumns = useMemo(
    () =>
      columns.map((c) => ({
        ...c,
        render: buildCellFormatter(c),
      })),
    [columns]
  );

  const modifyFilterVariables = useCallback(
    (filter: Record<string, unknown>) => {
      if (isEmpty(filter)) {
        if (value) {
          return {
            and: [{ id: { gte: value } }, { uOMId: { gte: 1 } }],
          };
        }
        return {
          uOMId: { gte: 1 },
        };
      }
      return {
        and: [{ uOMId: { gte: 1 } }, { ...filter }],
      };
    },
    [value]
  );

  return (
    <ETOGridSelectField
      customSx={sx}
      disabled={disabled}
      error={error}
      fieldName={FIELD_NAME}
      gridProps={{
        columns: convertedToRDGColumns,
      }}
      handleChange={(d) => _handleValueChange(d as Partial<EngItemMaster>)}
      handleInputClear={handleInputClear}
      handleRenderValue={(v, d) => _handleRenderValue(v, d)}
      label={label ?? t('entities:Part.RawMaterialItem')}
      search={searchObj}
      source={{
        name: 'itemCompanyId',
        value: 'id',
        gqlSource: {
          useRemoteFiltering: true,
          query: rawMaterialQuery,
          modifyFilterVariables,
        },
      }}
      value={value ?? ''}
    />
  );
};

export default RawMaterialSelectField;
