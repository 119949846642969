import { gql } from '../../../../__generated__';

const convertPODetailPrice = gql(`
  mutation convertPODetailPrice($purchaseOrderId: Int!, $oldRate: Decimal!, $newRate: Decimal!) {
    convertPurchaseOrderDetailPrice(purchaseOrderId: $purchaseOrderId, oldRate: $oldRate, newRate: $newRate) {
      items {
        id
        purchasePrice
        extendedPrice
        purchaseOrder {
          purchaseCurrRate
        }
      }
    }
  }
`);

export default convertPODetailPrice;
