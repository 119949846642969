import { ETOSelectField } from '@teto/react-component-library-v2';
import { useFormikContext } from 'formik';
import React, { ChangeEvent, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { companyLettersIn } from './companyReportConstants';

const PhoneBookNameForm = () => {
  const formik = useFormikContext<{ [companyLettersIn]: string }>();
  const { t } = useTranslation();

  const alphabets = useMemo(() => {
    const alphabet = [];
    for (let i = 65; i <= 90; i += 1) {
      alphabet.push(String.fromCharCode(i));
    }
    return alphabet;
  }, []);

  const localValue = useMemo(() => {
    const value = formik.values[companyLettersIn] ?? [];
    return typeof value === 'string' ? value.split(',') : value;
  }, [formik.values]);

  const _handleChange = useCallback(
    (e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
      const { name, value } = e.target;
      const newValue = (value as unknown as string[]).join(',');
      formik.setFieldValue(name, newValue === '' ? null : newValue);
    },
    [formik]
  );

  return (
    <ETOSelectField
      defaultValue={[]}
      error={formik.errors[companyLettersIn]}
      handleChange={_handleChange}
      itemNameSelector={(i) => i}
      items={alphabets ?? []}
      itemValueSelector={(i) => i}
      label={t('generic.letters')}
      multiple
      name={companyLettersIn}
      size="small"
      value={localValue ?? []}
    />
  );
};

export default PhoneBookNameForm;
