import { Box, SxProps, Theme } from '@mui/material';
import { MessageContext } from '@teto/react-component-library-v2';
import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { GridResponsiveSettingsBuilder } from '../../../TETOGridGraphQL/GridResponsiveSettingsBuilder';
import ViewDefinition from '../../../../views/ViewDefinitions';
import useGrid from '../../../TETOGridGraphQL/hooks/useGrid';
import { useGridBuilderFromView } from '../../../TETOGridGraphQL/hooks/useGridBuilder';
import MainTetoGridGraphQL from '../../../TETOGridGraphQL/TETOMainGridGraphQL';

const rootSx: SxProps<Theme> = {
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  justifyContent: 'flex-start',
};

interface DistributionGridProps {
  viewName: ViewDefinition;
  rootQueryName: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  queryVariables: { [key: string]: any };
}

const PartBreakdownGrid = (props: DistributionGridProps) => {
  const { viewName, rootQueryName, queryVariables } = props;
  const [configureInspector, setConfigureInspector] = useState(false);

  const messageContext = useContext(MessageContext);
  const { t, ready } = useTranslation();

  const { gridBuilder, builderReady, error, hasError } = useGridBuilderFromView(
    viewName,
    rootQueryName
  );

  const { gridProps } = useGrid(
    `dist-${typeof viewName === 'string' ? viewName : viewName.view?.name}`,
    rootQueryName,
    (err) => messageContext.setError(err?.message ?? err.toString()),
    t,
    [],
    gridBuilder,
    undefined,
    queryVariables
  );

  const defaultGridResponsiveSettings = GridResponsiveSettingsBuilder.up({
    xs: {
      disableGroupByToolbar: true,
      disableGroupColumn: false,
      disableInlineEdit: true,
      disableMobileCols: false,
      enableFiltering: false,
      inputVariant: 'filled',
      renderAggregatesInGroupLabel: false,
      rowHeight: null,
      showEmptyRows: false,
      showHoverRows: false,
    },
    md: {
      disableGroupByToolbar: false,
      disableGroupColumn: false,
      disableInlineEdit: false,
      disableMobileCols: true,
      enableFiltering: true,
      inputVariant: 'standard',
      renderAggregatesInGroupLabel: false,
      rowHeight: 35,
      showEmptyRows: true,
      showHoverRows: true,
    },
  });

  return (
    <Box sx={rootSx}>
      <MainTetoGridGraphQL
        builderReady={builderReady}
        configureInspector={configureInspector}
        customGridWrapSx={{
          height: '100%',
          display: 'block',
          '& .InovuaReactDataGrid': {
            height: '100%',
          },
        }}
        error={error}
        externalQueryProps={{}}
        gridProps={gridProps}
        hasError={hasError}
        ready={ready}
        responsiveSettings={defaultGridResponsiveSettings}
        setConfigureInspector={setConfigureInspector}
        t={t}
        tableIdentifier={viewName.view?.name ?? ''}
      />
    </Box>
  );
};

export default PartBreakdownGrid;
