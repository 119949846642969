export default `query inventories($inventoryLocationId: Int!) {
  inventories(
    order: {item : {itemCompanyId: ASC }}
    where: {
      and: [
        { inventoryLocationId: { eq: $inventoryLocationId } }
        { qtyOnHand: { gt: 0 } }
      ]
    }
  ) {
    items {
      item {
        id
        itemCompanyId 
        description
      }
      otherQuantities {
        available
      }
    }
  }
}
`;
