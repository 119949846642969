import { Button } from '@mui/material';
import { MessageContext } from '@teto/react-component-library-v2';
import { useSetAtom } from 'jotai';
import React, { useContext } from 'react';
import { Permission, getGraphQLClient } from 'teto-client-api';
import { gql } from '../../__generated__';
import { Company, RfqDetail } from '../../__generated__/graphql';
import getErrors from '../../api/graphQL/getErrors';
import type { TypedDocumentNode } from '../../api/graphQL/types/TypeDocumentNode';
import AuthContext from '../../contexts/AuthContext';
import { nonConformanceQuery } from '../Inspectors/NonConformanceInspector/NCPanels/InfoPanel/queries/InfoPanelQueries';
import useRFQModalToggle from '../Modals/SelectRFQModal/useRFQModalToggle';
import { companyDetailSharedState } from './StateContainers/CompanyState';
import { ItemMasterSharedState } from './StateContainers/ItemMasterState';
import { ncSharedState } from './StateContainers/NonConformanceState';
import { ProcBreakdownSharedState } from './StateContainers/ProcBreakdownState';
import { ProcessScheduleSharedState } from './StateContainers/ProcessScheduleState';
import { PurchaseOrderSharedState } from './StateContainers/PurchaseOrderState';
import { InspectorKeys } from './types/InspectorKeys';
import { RFQSharedState } from './StateContainers/RFQState';

const URL = 'details';

interface SharedStateLinkProps {
  children: React.ReactNode;
  inspectorKey: InspectorKeys | undefined;
  value: unknown;
}

const itemCompanyIdQuery = gql(`query getItemCompanyId($id: Int!) {
  engItemMaster(
    id: $id
  ) {
      id
      itemCompanyId
  }
}`);

const companyQuery = gql(`
query company($id: Int!){
  company(id: $id){
    id
    name
  }
}`);

const purchaseSummaryQuery = gql(`
query purchasingSummary ($id: Int!){
  purchasingSummary(id: $id) {
    id
    childId
    child {
      itemCompanyId
    }
    destInventoryLoc
    projectId
    specId
  }
}
`);

const psHeaderQuery = gql(`query psHeader($id: Int!) {
  processScheduleHeader(id: $id) {
    id
    createdDate
    number
  }
}`);

const rFQDetailQuery = gql(`query rFQDetails($id: Int!) {
  rFQDetails(where: { rFQId: {eq: $id}}, take: 1) {
    items {
      projectId
      specId
      itemId
      id
      itemId
    }
  }
}`);

const buttonStylesSx = {
  textTransform: 'inherit',
  p: 0,
  minWidth: 0,
  width: '100%',
  textAlign: 'inherit',
  display: 'inline-block',
  userSelect: 'text',
};

export const useCheckSharedStatePermissions = (
  inspectorKey: InspectorKeys | undefined
) => {
  const authContext = useContext(AuthContext);

  let hasPermission = true;

  if (!inspectorKey) {
    hasPermission = false;
    return hasPermission;
  }

  switch (inspectorKey) {
    case 'Company':
      if (
        !authContext.hasPermission(Permission.View_Sales_CompanyInformation)
      ) {
        hasPermission = false;
      }
      break;
    case 'NonConformance':
      if (
        !authContext.hasPermission(
          Permission.View_Manufacturing_NonConformances
        )
      ) {
        hasPermission = false;
      }
      break;
    case 'EngItemMaster':
      if (!authContext.hasPermission(Permission.View_Engineering_ItemMaster)) {
        hasPermission = false;
      }
      break;
    case 'PurchasingSummary':
      if (
        !authContext.hasPermission(
          Permission.View_Procurement_ProcurementBreakdown
        )
      ) {
        hasPermission = false;
      }
      break;
    case 'PurchaseOrderHeader':
    case 'PurchaseOrderDetail':
      if (
        !authContext.hasPermission(
          Permission.View_Procurement_PurchaseOrders_PurchaseOrderHeader
        )
      ) {
        hasPermission = false;
      }
      break;
    case 'ProcessScheduleHeader':
    case 'ProcessScheduleDetail':
      if (
        !authContext.hasPermission(Permission.View_Procurement_ProcessSchedules)
      ) {
        hasPermission = false;
      }
      break;
    case 'RFQHeader':
      if (
        !authContext.hasPermission(Permission.View_Procurement_RFQs_RFQHeader)
      ) {
        hasPermission = false;
      }
      break;

    default:
      hasPermission = true;
      break;
  }
  return hasPermission;
};

export const useHandleSharedState = (
  inspectorKey: InspectorKeys | undefined,
  value: unknown | undefined
) => {
  const messageContext = useContext(MessageContext);

  const setNonConformance = useSetAtom(ncSharedState);
  const setItemMaster = useSetAtom(ItemMasterSharedState);
  const setCompany = useSetAtom(companyDetailSharedState);
  const setProcBreakdown = useSetAtom(ProcBreakdownSharedState);
  const setPurchaseOrder = useSetAtom(PurchaseOrderSharedState);
  const setProcessSchedule = useSetAtom(ProcessScheduleSharedState);
  const setRFQ = useSetAtom(RFQSharedState);

  const { rFQModalToggle } = useRFQModalToggle();

  let open = true;

  if (!useCheckSharedStatePermissions(inspectorKey)) {
    return;
  }

  // You may not want the state to be open by default
  return (overrideOpen?: boolean) => {
    if (overrideOpen !== undefined) {
      open = overrideOpen;
    }
    if (inspectorKey && value) {
      switch (inspectorKey) {
        case 'Company':
          if (typeof value === 'number') {
            getGraphQLClient()
              .performQuery(companyQuery as TypedDocumentNode, {
                id: value,
              })
              .then((res) => {
                if (res.hasError()) {
                  if (res.hasValidationErrors()) {
                    messageContext.setError(getErrors(res.validationErrors));
                  }
                  if (res.hasSystemErrors()) {
                    messageContext.setError(getErrors(res.systemErrors));
                  }
                  return;
                }

                setCompany({
                  initialValues: {
                    id: res?.data?.company?.id,
                    name: res?.data?.company?.name,
                  } as Company,
                  open,
                });
              });
          }
          break;
        case 'NonConformance':
          if (typeof value === 'number') {
            getGraphQLClient()
              .performQuery(nonConformanceQuery as TypedDocumentNode, {
                id: value,
              })
              .then((res) => {
                if (res.hasError()) {
                  if (res.hasValidationErrors()) {
                    messageContext.setError(getErrors(res.validationErrors));
                  }
                  if (res.hasSystemErrors()) {
                    messageContext.setError(getErrors(res.systemErrors));
                  }
                  return;
                }
                setNonConformance({
                  initialValues: {
                    id: value,
                    creationDate: res?.data?.nonConformance?.creationDate,
                  },
                  open,
                });
              });
          }
          break;
        case 'PurchasingSummary':
          if (typeof value === 'number') {
            getGraphQLClient()
              .performQuery(purchaseSummaryQuery as TypedDocumentNode, {
                id: value,
              })
              .then((res) => {
                if (res.hasError()) {
                  if (res.hasValidationErrors()) {
                    messageContext.setError(getErrors(res.validationErrors));
                  }
                  if (res.hasSystemErrors()) {
                    messageContext.setError(getErrors(res.systemErrors));
                  }
                  return;
                }

                setProcBreakdown({
                  detail: {
                    child: {
                      itemCompanyId:
                        res.data.purchasingSummary.child.itemCompanyId,
                    },
                    inventoryLocationId:
                      res.data.purchasingSummary.destInventoryLoc,
                    destinationLocationId:
                      res.data.purchasingSummary.desInventoryLoc,
                    itemId: res.data.purchasingSummary.childId,
                    projectId: res.data.purchasingSummary.projectId,
                    specId: res.data.purchasingSummary.specId,
                  },
                  open,
                  ignoreLocation: false,
                });
              });
          }
          break;
        case 'PurchaseOrderHeader':
        case 'PurchaseOrderDetail':
          if (typeof value === 'number') {
            setPurchaseOrder({
              initialValues: { id: value },
              open,
              hasSavedOccurred: false,
            });
          }
          break;
        case 'ProcessScheduleHeader':
        case 'ProcessScheduleDetail':
          if (typeof value === 'number') {
            getGraphQLClient()
              .performQuery(psHeaderQuery as TypedDocumentNode, {
                id: value,
              })
              .then((res) => {
                if (res.hasError()) {
                  if (res.hasValidationErrors()) {
                    messageContext.setError(getErrors(res.validationErrors));
                  }
                  if (res.hasSystemErrors()) {
                    messageContext.setError(getErrors(res.systemErrors));
                  }
                  return;
                }

                if (res.hasData()) {
                  setProcessSchedule((d) => ({
                    ...d,
                    initialValues: {
                      id: res?.data?.processScheduleHeader?.id,
                      createdDate:
                        res?.data?.processScheduleHeader?.createdDate,
                      number: res?.data?.processScheduleHeader?.number,
                    },
                    open,
                    hasSavedOccurred: false,
                  }));
                }
              });
          }
          break;
        case 'RFQHeader':
          if (typeof value === 'number') {
            getGraphQLClient()
              .performQuery(rFQDetailQuery, { id: value })
              .then((d) => {
                if (d.hasError()) {
                  if (d.hasSystemErrors()) {
                    messageContext.setError(getErrors(d.systemErrors));
                  }
                  if (d.hasValidationErrors()) {
                    messageContext.setError(getErrors(d.validationErrors));
                  }
                  return;
                }
                rFQModalToggle(d.data?.rFQDetails?.items?.[0] as RfqDetail);
              });
          }
          break;
        case 'RFQDetail':
          if (typeof value === 'number') {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            setRFQ((prev: any) => ({
              ...prev,
              open: true,
              initialValues: { id: value },
            }));
          }
          break;
        case 'EngItemMaster':
          if (typeof value === 'number') {
            getGraphQLClient()
              .performQuery(itemCompanyIdQuery as TypedDocumentNode, {
                id: value,
              })
              .then((res) => {
                if (res.hasError()) {
                  if (res.hasValidationErrors()) {
                    messageContext.setError(getErrors(res.validationErrors));
                  }
                  if (res.hasSystemErrors()) {
                    messageContext.setError(getErrors(res.systemErrors));
                  }
                  return;
                }

                setItemMaster({
                  initialValues: {
                    id: res?.data?.engItemMaster?.id,
                    itemCompanyId: res?.data?.engItemMaster?.itemCompanyId,
                  },
                  open,
                  hasSavedOccurred: false,
                });
              });
          }
          break;
        default:
          break;
      }
    }
  };
};

const SharedStateLink = (props: SharedStateLinkProps) => {
  const { children, inspectorKey, value } = props;

  const handleLinkClick = useHandleSharedState(inspectorKey, value);
  const hasPermission = useCheckSharedStatePermissions(inspectorKey);

  if (!hasPermission) {
    return <>{children}</>;
  }

  // we dont want to check for falsy because we want to allow 0 and false as values
  const shouldReturnChildren =
    !inspectorKey ||
    typeof value === 'undefined' ||
    value === null ||
    value === '';

  if (shouldReturnChildren) {
    return <>{children}</>;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleClick = (e: React.MouseEvent<any, MouseEvent>) => {
    if (!e.ctrlKey) {
      e.preventDefault();
    }

    let open = true;

    if (e.ctrlKey) {
      open = false;
    }
    handleLinkClick?.(open);
  };

  return (
    <Button
      color="primary"
      data-testid={`shared-inspector-link-${inspectorKey}-value-${value}`}
      disableFocusRipple
      href={`/${URL}/:${inspectorKey}/:${value}`}
      onClick={handleClick}
      sx={buttonStylesSx}
      target="_blank"
    >
      {children}
    </Button>
  );
};

export default SharedStateLink;
