import { MessageContext } from '@teto/react-component-library-v2';
import { useSetAtom } from 'jotai';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { getGraphQLClient } from 'teto-client-api';
import type { TypedDocumentNode } from '@graphql-typed-document-node/core';
import { gql } from '../../../__generated__';
import { RfqDetail } from '../../../__generated__/graphql';
import { BreadCrumbSharedState } from '../../SharedStateComponents/StateContainers/BreadCrumbModalState';
import { RFQSharedState } from '../../SharedStateComponents/StateContainers/RFQState';
import SelectRFQModal from './SelectRFQModal';

interface Item {
  childId?: number;
  itemId?: number;
  specId?: number;
  projectId?: number;
}

const getRFQDetails =
  gql(`query rfqDetails($itemId: Int!, $projectId: Int!, $specId: Float!) {
    rFQDetails(where: {
      and: [
        { projectId: {eq: $projectId}},
        { specId: {eq: $specId}},
        { itemId: {eq: $itemId}}
      ]
      }) {
      items {
        rFQId
        rFQ {
          date
          companies {
            company {
              name,
              phone,
              city,
              state
            }
          }
        }
      }
    }
  }`);

const useRFQModalToggle = () => {
  const setRFQ = useSetAtom(RFQSharedState);
  const setLevels = useSetAtom(BreadCrumbSharedState);
  const messageContext = useContext(MessageContext);
  const { t } = useTranslation();

  const _openRFQModal = (id: number) =>
    setRFQ({
      open: true,
      initialValues: {
        id,
      },
    });

  const _hasDistinctRFQs = (rFQList: RfqDetail[]) => {
    const map: { [key: number]: boolean } = {};
    map[rFQList[0]?.rFQId as number] = true;
    for (let i = 1; i < rFQList.length; i += 1) {
      const id = rFQList[i]?.rFQId as number;
      if (!map[id]) {
        return true;
      }
    }
    return false;
  };

  const rFQModalToggle = (item: Item) => {
    const { itemId, specId, projectId } = item;
    getGraphQLClient()
      .performQuery(getRFQDetails as TypedDocumentNode, {
        itemId: item?.childId ?? itemId,
        specId,
        projectId,
      })
      .then((d) => {
        if (d.hasError()) {
          d.showAllSystemErrors(messageContext.setError);
          return;
        }
        const { data } = d;
        const rFQDetailsLength = data?.rFQDetails?.items?.length;
        if (!data?.rFQDetails?.items || !rFQDetailsLength) {
          messageContext.setError(`${t('generic.message.rFQSelectFailed')}`);
          return;
        }

        const rFQList = data?.rFQDetails?.items;
        if (rFQDetailsLength === 1) {
          _openRFQModal(data?.rFQDetails?.items[0]?.rFQId);
        } else if (rFQDetailsLength > 1 && _hasDistinctRFQs(rFQList)) {
          setLevels((prev) => [
            ...prev,
            {
              name: `${t('generic.select')} ${t('entities:RFQ:RFQ')}`,
              component: <SelectRFQModal rFQList={rFQList} />,
            },
          ]);
          return;
        }
        _openRFQModal(rFQList[0]?.rFQId);
      });
  };
  return { rFQModalToggle };
};

export default useRFQModalToggle;
