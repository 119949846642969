import { SxProps, Theme } from '@mui/material';
import { ETOSelectField } from '@teto/react-component-library-v2';
import React, { ChangeEvent } from 'react';
import { useTranslation } from 'react-i18next';
import useBuildQuery from '../../../../../../../api/graphQL/useBuildQuery';

interface ItemCategorySelectFieldProps {
  // formik: FormikProps<Partial<TEngItemMaster>>;
  disabled?: boolean;
  error?: string;
  // eslint-disable-next-line no-unused-vars
  handleChange?: (
    // eslint-disable-next-line no-unused-vars
    e?: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => void;
  sx?: SxProps<Theme>;
  value?: number;
}
const categoryQuery = `query itemMasterCategories {
  itemMasterCategories {
    items {
      categoryDescription
      itemCategory
    }
  }
}`;

type ItemMasterCategories = {
  categoryDescription: string;
  itemCategory: number;
};

type CategoriesApiResponse = {
  itemMasterCategories: {
    items: ItemMasterCategories[];
  };
};

const ItemCategorySelectField = (props: ItemCategorySelectFieldProps) => {
  const { disabled, error, handleChange, sx, value } = props;
  const { t } = useTranslation();
  const categories = useBuildQuery<CategoriesApiResponse>(
    ['partItemCategories'],
    {
      queryString: categoryQuery,
      variables: {},
      options: { refetchOnMount: false, refetchOnWindowFocus: false },
    }
  );

  return (
    <ETOSelectField
      autoCompleteSx={sx}
      disabled={disabled}
      error={error}
      handleChange={handleChange}
      inputLabelProps={{ shrink: true }}
      itemNameSelector={(item) => item.categoryDescription}
      items={categories?.data?.itemMasterCategories?.items ?? []}
      itemValueSelector={(item) => item.itemCategory}
      label={`${t('entities:ItemMasterCategory.ItemMasterCategory')}`}
      name="itemCategory"
      size="small"
      value={value ?? null}
    />
  );
};

export default ItemCategorySelectField;
