import { Box, Popover, PopoverActions, SxProps, Theme } from '@mui/material';
import React, { useEffect, useRef } from 'react';
import EditingState from '../../../types/EditingState';

const rootSx: SxProps<Theme> = {
  alignItems: 'center',
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  justifyContent: 'flex-start',
  width: '100%',
  overflowY: 'unset',
};

const newItemSx: SxProps<Theme> = {
  display: 'flex',
  justifyContent: 'flex-end',
  width: '100%',

  position: 'sticky',
  zIndex: 12,
  overflowY: 'unset',
  mb: 1,
};

const defaultListContainerSx: SxProps<Theme> = {
  flexGrow: 1,
  height: '100%',
  overflowY: 'auto',
  width: '100%',
};

const paperSx: SxProps<Theme> = {
  maxWidth: (theme) => theme.spacing(60),
  width: { xs: '80%', md: '100%' },
};

interface CommonPanelProps {
  addButtonRef?: React.MutableRefObject<HTMLButtonElement | null>;
  addItemButton?: React.ReactNode;
  addItemSx?: SxProps<Theme>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  children: any;
  editingState: EditingState;
  isNewItemOpen: boolean;
  listContainerSx?: SxProps<Theme>;
  newItemComponent: React.ReactNode;
  verticalPosition?: number;
  reposition?: boolean;
  setConfirmAbandonForm: React.Dispatch<React.SetStateAction<boolean>>;
  // eslint-disable-next-line no-unused-vars
  setIsNewItemOpen: (val: boolean) => void;
}

const PanelWithNewItemPopover = (props: CommonPanelProps) => {
  const {
    addButtonRef,
    addItemButton,
    addItemSx,
    children,
    editingState,
    isNewItemOpen,
    listContainerSx,
    newItemComponent,
    setConfirmAbandonForm,
    setIsNewItemOpen,
    verticalPosition,
    reposition,
  } = props;

  const addLocationRef = useRef<Element>();
  const actionRef = useRef<PopoverActions>(null);

  useEffect(() => {
    if (reposition) {
      actionRef.current?.updatePosition();
    }
  }, [reposition]);

  const _handleOnClose = () => {
    if (editingState.tabError || editingState.isEditing) {
      setConfirmAbandonForm(true);
    } else {
      setIsNewItemOpen(false);
    }
  };

  return (
    <Box sx={rootSx}>
      <Box ref={addLocationRef} sx={{ ...newItemSx, ...addItemSx }}>
        {addItemButton}
      </Box>

      <Popover
        action={actionRef}
        anchorEl={addLocationRef?.current ?? addButtonRef?.current}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        onClose={() => _handleOnClose()}
        open={isNewItemOpen}
        slotProps={{
          paper: {
            sx: paperSx,
          },
        }}
        transformOrigin={{
          vertical: verticalPosition ?? 220,
          horizontal: 'right',
        }}
      >
        {newItemComponent}
      </Popover>
      <Box
        sx={{
          ...defaultListContainerSx,
          ...listContainerSx,
        }}
      >
        {children}
      </Box>
    </Box>
  );
};

export default PanelWithNewItemPopover;
