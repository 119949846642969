import { gql } from '../../../../__generated__';

const getPODTotals =
  gql(`query getPurchaseOrderDetailTotals($purchaseOrderId: Int) {
purchaseOrderHeaders(where: { purchaseOrderDetails: { some: { purchaseOrderId: { eq: $purchaseOrderId }}} }) {
  items {      
    purchaseCurr {
      name
      format
      symbol
    }
    purchaseTax1
    purchaseTax2
    purchaseTax1Name
    purchaseTax2Name
    totals {
      subtotal
      detailTotal
      purchaseOrderHeaderId
    }
    taxes {
      tax1Amount
      tax2Amount
    }
    id
  }
}
}`);

export default getPODTotals;
