import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import {
  UomDescription,
  UomDescriptionsCollectionSegment,
} from '../../../../../../../__generated__/graphql';
import useGQLQuery from '../../../../../../../api/graphQL/useGQLQuery';
import ETOGridSelectField from '../../../../../../InputFields/ETOGridSelectField/ETOGridSelectField';

interface UOMSelectFieldProps {
  disabled?: boolean;
  error?:
    | {
        itemUom?: string;
      }
    | string;
  value?: number;
  // eslint-disable-next-line no-unused-vars, @typescript-eslint/no-explicit-any
  handleChange: (data: any) => void;
}
const uOMQuery = `
query uOMDescriptions {
  uOMDescriptions {
    items {
      description
      itemUom
      uomtype
    }
  }
}
  `;

type UOMApiResponse = {
  uOMDescriptions: UomDescriptionsCollectionSegment;
};

const UOMSelectField = (props: UOMSelectFieldProps) => {
  const { disabled, error, handleChange, value } = props;
  const { t } = useTranslation();

  const uOM = useGQLQuery<UOMApiResponse>(['partUOM'], {
    queryString: uOMQuery,
    variables: {},
    options: { refetchOnWindowFocus: false },
  });

  const itemUomError = useMemo(
    () =>
      typeof error === 'object' && 'itemUom' in error
        ? (error?.itemUom as unknown as string)
        : error,
    [error]
  );

  const uColumns = useMemo(
    () => [
      {
        name: 'uomtype',
        type: 'string',
        header: t('generic.type'),
        defaultFlex: 1,
      },
      {
        name: 'description',
        header: t('generic.description'),
        defaultFlex: 2,
      },
    ],
    [t]
  );

  const dataSource = useMemo(
    () => uOM.data?.uOMDescriptions.items ?? [],
    [uOM.data?.uOMDescriptions.items]
  );

  const _handleRenderValue = useCallback(
    (select: number, data: UomDescription[]): string => {
      if (!select) return '';

      const option = data.find((o) => o.itemUom === select);
      return option?.description as string;
    },
    []
  );

  const _matchValue = useCallback(
    (text: string | undefined, item: UomDescription) =>
      item.uomtype.toUpperCase() === text?.toUpperCase(),
    []
  );

  return (
    <ETOGridSelectField
      customSx={{ width: '100%' }}
      disabled={disabled}
      error={itemUomError as string}
      fieldName={`${t('entities:UOM.UOM')}`}
      gridProps={{
        columns: uColumns,
      }}
      handleChange={handleChange}
      handleRenderValue={(v, d) => _handleRenderValue(v as number, d)}
      label={t('entities:UOM.UOM')}
      search={{
        textValueMatcher: (search: string, item: UomDescription) =>
          _matchValue(search, item),
        filterColumn: {
          name: 'uomtype',
          operator: 'contains',
          type: 'string',
          value: '',
        },
      }}
      source={{
        items: dataSource as UomDescription[],
        name: 'uomtype',
        value: 'itemUom',
      }}
      testVirtualized={process.env.NODE_ENV !== 'test'}
      value={value ?? null}
    />
  );
};

export default UOMSelectField;
