import { Divider, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import { SxProps, Theme } from '@mui/material/styles';
import {
  ETOSelectField,
  MessageContext,
  OkCancelConfirmDialog,
} from '@teto/react-component-library-v2';
import React, { useCallback, useContext, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { gql } from '../../../../__generated__';
import { PurchaseOrderDetail } from '../../../../__generated__/graphql';
import buildMutation from '../../../../api/graphQL/buildMutation';
import useGQLQuery from '../../../../api/graphQL/useGQLQuery';
import pODetailSplitQuery from '../queries/pODetailSplitQuery';

export interface SplitPODialogProps {
  initialValue?: {
    id: number;
  };
  open: boolean;
  // eslint-disable-next-line no-unused-vars
  onClose: (action?: 'Refresh') => void;
}

const contentSx: SxProps<Theme> = {
  display: 'flex',
  flexDirection: 'column',
  pt: 0.5,
  rowGap: 2,
  flexGrow: 1,
  minWidth: (theme) => theme.spacing(50),
};

const topDisplaySx: SxProps<Theme> = {
  display: 'flex',
  flexDirection: 'row',
  columnGap: 2,
};

const bottomDisplaySx: SxProps<Theme> = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
};

const secondarySx: SxProps<Theme> = {
  color: 'text.secondary',
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
};

const inputSx = {
  mt: 1,
  mb: 2,
};

const splitPODetailMutation =
  gql(`mutation splitPoItem($input: SplitPurchaseOrderDetailInput){
  splitPurchaseOrderDetail(input: $input) {
    success
  }
}`);

const splitCount = Array.from({ length: 9 }, (_, index) =>
  (index + 2).toString()
);

const DisplayContent = (props: {
  title: string;
  value: number | string;
  sxProps: SxProps<Theme>;
}) => {
  const { sxProps, title, value } = props;
  return (
    <Box data-testid={title} sx={sxProps}>
      <Typography sx={{ color: 'text.primary' }}>{title}</Typography>
      <Typography sx={secondarySx}>{value}</Typography>
    </Box>
  );
};

const SplitPODialog = (props: SplitPODialogProps) => {
  const { open, initialValue, onClose } = props;
  const { t } = useTranslation();
  const messageContext = useContext(MessageContext);

  const [splitByValue, setSplitByValue] = useState(2);

  const pODetail = useGQLQuery(['po-split-details'], {
    queryString: pODetailSplitQuery,
    variables: {
      id: initialValue?.id,
    },
    callback: (d) =>
      (d as { data: { purchaseOrderDetail: PurchaseOrderDetail } })?.data
        ?.purchaseOrderDetail,
  });

  const displayData = useMemo(() => {
    if (pODetail.data) {
      const { purchaseOrderDetail } = pODetail?.data as {
        purchaseOrderDetail: PurchaseOrderDetail;
      };

      return [
        {
          name: 'projectName',
          title: t('entities:Project.Project'),
          value: purchaseOrderDetail?.spec?.project?.displayName ?? '',
        },
        {
          name: 'specName',
          title: t('entities:Machine.Job'),
          value: purchaseOrderDetail?.spec?.displayName ?? '',
        },
        {
          name: 'itemCompanyId',
          title: t('entities:Part.Part'),
          value: purchaseOrderDetail?.item?.itemCompanyId ?? '',
        },
        {
          name: 'poQty',
          title: t('PO Quantity'),
          value: purchaseOrderDetail?.receivingTotals?.purchaseQuantity,
        },
        {
          name: 'totalReceived',
          title: t('Total Received'),
          value: purchaseOrderDetail?.receivingTotals?.received,
        },
        {
          name: 'totalUnreceived',
          title: t('Total Unreceived'),
          value: purchaseOrderDetail?.receivingTotals?.unreceived,
        },
      ];
    }
    return [];
  }, [pODetail, t]);

  const _handleSave = useCallback(() => {
    const { purchaseOrderDetail } = pODetail?.data as {
      purchaseOrderDetail: PurchaseOrderDetail;
    };
    buildMutation({
      queryString: splitPODetailMutation,
      variables: {
        input: {
          purchaseOrderDetailId: purchaseOrderDetail.id, // purchaseOrderId, // will throw an error resolve with #76527
          splitOverCount: splitByValue,
          totalUnreceived: purchaseOrderDetail?.receivingTotals?.unreceived,
        },
      },
      callback: (d) => {
        if (d.splitPurchaseOrderDetail.success) {
          messageContext.setSuccess(
            t('generic.updateSuccess', {
              record: t('entities:PurchaseOrderDetail.PurchaseOrderDetail'),
            })
          );
          onClose('Refresh');
        }
      },
      messageContext,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onClose, pODetail?.data, splitByValue]);

  return (
    <OkCancelConfirmDialog
      content={
        <Box sx={contentSx}>
          <Box>
            {displayData?.slice(0, 3).map((i) => (
              <DisplayContent
                key={i.name}
                sxProps={topDisplaySx}
                title={`${i.title}: `}
                value={i.value}
              />
            ))}
          </Box>
          <Divider />
          <Box sx={[topDisplaySx, { justifyContent: 'space-between' }]}>
            {displayData?.slice(3).map((i) => (
              <DisplayContent
                key={i.name}
                sxProps={bottomDisplaySx}
                title={`${i.title} `}
                value={i.value}
              />
            ))}
          </Box>

          <ETOSelectField
            customSx={inputSx}
            defaultValue={2}
            disableClearable
            handleChange={(e) => setSplitByValue(parseInt(e.target.value, 10))}
            itemNameSelector={(i) => i}
            items={splitCount ?? []}
            itemValueSelector={(i) => i}
            label={t(
              'pages.purchaseOrders.purchaseOrderModal.splitUnreceivedQty'
            )}
            name="splitCount"
            size="small"
            value={splitByValue ?? null}
          />
        </Box>
      }
      data-testid="split-po-modal"
      okText={t('generic.save')}
      onCancel={() => onClose()}
      onOk={() => _handleSave()}
      open={open}
      title={t('pages.purchaseOrders.purchaseOrderModal.splitPOLineItem')}
    />
  );
};

export default SplitPODialog;
