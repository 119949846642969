export default `query getSupplierCategories($filter: SupplierCategoryFilterInput!) {
  supplierCategories(where: $filter) {
    items {
      companyId
      itemCategoryId
      itemCategory {
        categoryDescription
      }
    }
  }
}`;
