import { gql } from '../../../../__generated__';

const updateContactMutation =
  gql(`mutation updateContact($input: UpdateContactInput!) {
    contact: updateContact(input: $input) {
      active
      carPhone
      cellPhone
      company {
        id
        name
      }
      companyId
      custom1
      custom2
      custom3
      custom4
      custom5
      custom6
      custom7
      custom8
      date
      email
      ext
      fanRating {
        id
        description
      }
      fax
      firstName
      homeFax
      homePhone
      id
      lastName
      pager
      phone
      title
    }
  }
  `);

export default updateContactMutation;
