import React, { useCallback, useContext, useEffect } from 'react';

import PrivacyTipRoundedIcon from '@mui/icons-material/PrivacyTipRounded';
import {
  Box,
  Button,
  Paper,
  Popper,
  SxProps,
  Theme,
  Tooltip,
  Typography,
} from '@mui/material';

import dayjs, { Dayjs } from 'dayjs';
import utc from 'dayjs/plugin/utc';
import useLocalStorage from 'use-local-storage';
import { useTranslation } from 'react-i18next';
import AuthContext from '../../contexts/AuthContext';

dayjs.extend(utc);

const containerSx: SxProps<Theme> = {
  width: (theme) => theme.spacing(40),
  padding: 2,
};

const buttonSx: SxProps<Theme> = (theme: Theme) => ({
  minWidth: theme.spacing(3),
});

const messageSx: SxProps<Theme> = {
  pb: 2,
};

const NavLicense = () => {
  const authContext = useContext(AuthContext);
  const { t } = useTranslation();
  const [open, setOpen] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [dismissedTime, setDismissedTime] = useLocalStorage<Dayjs | undefined>(
    'dismissedDay',
    undefined
  );

  const hasRecentlyExpired = authContext?.licenseExpiration?.hasRecentlyExpired;
  const hasSoonExpiring = authContext?.licenseExpiration?.hasSoonExpiring;
  const canBeOpen = open && Boolean(anchorEl);
  const id = canBeOpen ? 'license-popper' : undefined;
  const time = dayjs();
  const timestamp = dayjs(dismissedTime);

  const handleOpen = useCallback((event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
    setOpen(true);
  }, []);

  const handleDismiss = useCallback(() => {
    setOpen(false);
    setDismissedTime(time);
  }, [setDismissedTime, time]);

  const dayDifference = time.diff(timestamp, 'day');

  useEffect(() => {
    if (hasRecentlyExpired || hasSoonExpiring) {
      if (dayDifference > 0) {
        setOpen(true);
      }
    }
  }, [dayDifference, hasRecentlyExpired, hasSoonExpiring, time, timestamp]);

  return (
    <>
      {(!hasRecentlyExpired || !hasSoonExpiring) && (
        <>
          <Tooltip title="License">
            <Button onClick={handleOpen} size="small" sx={buttonSx}>
              <PrivacyTipRoundedIcon color="warning" />
            </Button>
          </Tooltip>
          <Popper
            anchorEl={anchorEl}
            id={id}
            open={open}
            placement="bottom-end"
            sx={{
              zIndex: (theme) => theme.zIndex.modal,
            }}
          >
            <Paper>
              <Box sx={containerSx}>
                {hasRecentlyExpired && (
                  <Typography sx={messageSx} variant="body1">
                    {authContext?.licenseExpiration?.recentlyExpiredMessage ??
                      t('generic.message.licenseExpiredRecently')}
                  </Typography>
                )}

                {hasSoonExpiring && (
                  <Typography sx={messageSx} variant="body1">
                    {authContext?.licenseExpiration?.expiringSoonMessage ??
                      t('generic.message.licenseExpiringSoon')}
                  </Typography>
                )}
                <Box sx={{ textAlign: 'right' }}>
                  <Button
                    onClick={handleDismiss}
                    size="small"
                    variant="contained"
                  >
                    {t('generic.dismiss')}
                  </Button>
                </Box>
              </Box>
            </Paper>
          </Popper>
        </>
      )}
    </>
  );
};

export default NavLicense;
