import ViewDefinition from '../../views/ViewDefinitions';
import { getGraphQLClient } from '../graphQL/graphQLClient';
import { getMetadata } from './metadataManager';

interface IViewColumnResult {
  fields: IViewColumn[];
  kind: string;
  type: IViewColumnResult | null;
  ofType?: {
    name?: string;
  };
}

interface IViewColumn extends IViewColumnResult {
  name: string;
  fieldType?: string | null;
  title?: string | null;
  isPrimaryKey?: boolean | null;
  defaultWidth: number;
  groupingAggregate?: 'sum' | 'min' | 'max' | 'avg' | null;
  isCollection: boolean;
  isDisabled: boolean;
  disableSort: boolean;
  disableFilter: boolean;
  linkTo?: {
    type: string;
    column: string;
  };
}

export interface MetadataColumn {
  id: string;
  name: string;
  path: string;
  fieldType?: string | null;
  title?: string | null;
  isPrimaryKey?: boolean | null;
  defaultWidth: number;
  groupingAggregate?: 'sum' | 'min' | 'max' | 'avg' | null;
  isCollection: boolean;
  isDisabled: boolean;
  disableSort: boolean;
  disableFilter: boolean;
  needsPostResolve?: string;
  parentName?: string | null;
  parentPath?: string | null;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  type: any;
  linkTo?: {
    type: string;
    column: string;
  };
}

export interface MetadataView {
  name: string;
  columns: MetadataColumn[];
  requiredColumns: string[];
  defaultColumns: string[];
  defaultSort: string[];
  defaultGrouping: string[];
  baseType: string;
  overrideTitles?: { fieldName: string; value: string }[];
}

function filterColumnTree(
  results: MetadataColumn[],
  records: IViewColumn[],
  excludedTypes: { [key: string]: boolean },
  path: string,
  parentName?: string | null
) {
  records
    .filter((a) => !a.isCollection && !a.isDisabled)
    .forEach((record) => {
      if (!record.title) {
        // this has no metadata, just get out
        return;
      }
      const newPath = path === '' ? record.name : `${path}.${record.name}`;
      //
      if (
        record.type &&
        Object.prototype.hasOwnProperty.call(record.type, 'fields') &&
        record.type.fields !== null
      ) {
        // eslint-disable-next-line no-param-reassign
        excludedTypes[record.name] = true;

        results.push({
          ...record,
          parentName,
          parentPath: path,
          path: newPath,
          id: newPath,
          isPrimaryKey: Boolean(record.isPrimaryKey),
          linkTo: record.linkTo
            ? {
                column:
                  path === ''
                    ? record.linkTo.column.charAt(0).toLowerCase() +
                      record.linkTo.column.slice(1)
                    : `${path}.${
                        record.linkTo.column.charAt(0).toLowerCase() +
                        record.linkTo.column.slice(1)
                      }`,
                type: record.linkTo.type,
              }
            : undefined,
        });
        filterColumnTree(
          results,
          record.type.fields,
          excludedTypes,
          newPath,
          record.title
        );
      } else if (record.type && record.type?.ofType?.name) {
        results.push({
          needsPostResolve: record.type.ofType.name,
          ...record,
          parentName,
          parentPath: path,
          path: newPath,
          id: newPath,
          isPrimaryKey: Boolean(record.isPrimaryKey),
          linkTo: record.linkTo
            ? {
                column:
                  path === ''
                    ? record.linkTo.column.charAt(0).toLowerCase() +
                      record.linkTo.column.slice(1)
                    : `${path}.${
                        record.linkTo.column.charAt(0).toLowerCase() +
                        record.linkTo.column.slice(1)
                      }`,
                type: record.linkTo.type,
              }
            : undefined,
        });
      } else if (!record.type || record.type?.kind !== 'OBJECT') {
        results.push({
          ...record,
          parentName,
          parentPath: path,
          path: newPath,
          id: newPath,
          isPrimaryKey: Boolean(record.isPrimaryKey),
          linkTo: record.linkTo
            ? {
                column:
                  path === ''
                    ? record.linkTo.column.charAt(0).toLowerCase() +
                      record.linkTo.column.slice(1)
                    : `${path}.${
                        record.linkTo.column.charAt(0).toLowerCase() +
                        record.linkTo.column.slice(1)
                      }`,
                type: record.linkTo.type,
              }
            : undefined,
        });
      }
    });
}

export function parseColumns(metaData: IViewColumn[]) {
  const columnResults: MetadataColumn[] = [];
  filterColumnTree(columnResults, metaData, {}, '', '');

  const typesToIgnore = [
    'String',
    'Int',
    'Boolean',
    'Decimal',
    'DateTime',
    'Float',
    'Byte',
  ];
  const recordsNeedingAdditionalWork = columnResults.filter(
    (a) => a.needsPostResolve && typesToIgnore.indexOf(a.needsPostResolve) < 0
  );

  recordsNeedingAdditionalWork.forEach((f) => {
    const matching = columnResults.find(
      (a) => a.type?.name === f.needsPostResolve
    );

    if (matching) {
      const parent = f;

      filterColumnTree(
        columnResults,
        matching.type.fields,
        {},
        parent.path,
        parent.name
      );
    }
  });

  // eslint-disable-next-line no-param-reassign
  return columnResults;
}

export const getMetadataForView = (viewDefinition: ViewDefinition) => {
  if (!viewDefinition.view) return Promise.resolve([]);
  const { view } = viewDefinition;

  return getMetadata(view.rootQuery).then((r) => {
    if (!r.__type) {
      return Promise.reject(
        new Error(`Failed to locate metadata for type ${view.rootQuery}`)
      );
    }
    const results = parseColumns(r.__type.fields);
    return results;
  });
};

const getView = (
  viewName: string,
  includeMetadata = true
): Promise<MetadataView> => {
  const query = `
    query getView($viewName: String!) {
      view(viewName: $viewName) {
        baseType
        defaultColumns
        requiredColumns
        defaultSort
        defaultGrouping
        overrideTitles {
          fieldName
          value
        }
      }
    }
  `;

  const client = getGraphQLClient();
  return client
    .performQuery(query, {
      viewName,
    })
    .then((d) => {
      if (!d?.view?.baseType) {
        return Promise.reject(
          new Error(`Failed to locate base type for ${viewName}`)
        );
      }

      const view = {
        ...d.view,
      } as MetadataView;

      if (includeMetadata) {
        if (d?.view?.baseType) {
          return getMetadata(d.view.baseType).then((r) => {
            if (!r.__type) {
              return Promise.reject(
                new Error(
                  `Failed to locate metadata for type ${d.view.baseType}`
                )
              );
            }
            const results = parseColumns(r.__type.fields);
            view.columns = results;
            return view;
          });
        }
      }

      return view;
    });
};

export default getView;
