import { gql } from '../../../../__generated__';

export default gql(`
  query getRFQJobProject($rFQId: Int) {
    rFQDetails(where: {rFQId: {eq: $rFQId}}) {
      items{
        id
        projectId
        specId
      }
    }
  }
`);
