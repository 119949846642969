import RefreshIcon from '@mui/icons-material/Refresh';
import SaveAltIcon from '@mui/icons-material/SaveAlt';
import SettingsRoundedIcon from '@mui/icons-material/SettingsRounded';
import UnfoldLessRoundedIcon from '@mui/icons-material/UnfoldLessRounded';
import UnfoldMoreRoundedIcon from '@mui/icons-material/UnfoldMoreRounded';
import { useMediaQuery, useTheme } from '@mui/material';
import { MessageContext } from '@teto/react-component-library-v2';
import { useAtomValue } from 'jotai';
import React, { MutableRefObject, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { CustomRightActionType } from '../../../ActionBar/ActionBar';
import { DataSourceAtom } from '../../../SharedStateComponents/StateContainers/DataSourceState';
import TETOGridRefType from '../../types/TETOGridRefType';

interface GridCommonMobileButtonsProps {
  setConfigureInspector: () => void;
  onExportClick?: () => void;
  refreshGrid: () => void;
  gridRef: MutableRefObject<TETOGridRefType | null | undefined>;
}

const GridCommonMobileButtons = (props: GridCommonMobileButtonsProps) => {
  const { t } = useTranslation();
  const { setConfigureInspector, refreshGrid, gridRef, onExportClick } = props;
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const messageContext = useContext(MessageContext);
  const isDataSourceEmpty = useAtomValue(DataSourceAtom);
  if (!isMobile) {
    return [];
  }

  const btns: CustomRightActionType[] = [
    {
      title: t('generic.refresh'),
      icon: <RefreshIcon />,
      onclick: () => refreshGrid?.(),
    },
    {
      title: t('generic.configure'),
      icon: <SettingsRoundedIcon />,
      onclick: () => setConfigureInspector?.(),
    },
    {
      title: t('generic.export'),
      icon: <SaveAltIcon />,
      disabled: isDataSourceEmpty,
      onclick: () => {
        const gr = gridRef.current;
        // eslint-disable-next-line no-unused-expressions
        gr
          ? gr.exportData()
          : messageContext.setError(t('generic.message.failExportGrid'));
        // eslint-disable-next-line no-unused-expressions
        onExportClick?.();
      },
    },
  ];

  if ((gridRef?.current?.groupBy?.length ?? 0) > 0) {
    btns.push(
      {
        title: t('generic.collapseAll'),
        icon: <UnfoldLessRoundedIcon />,
        onclick: () => gridRef?.current?.collapseAllGroups?.(),
      },
      {
        title: t('generic.expandAll'),
        icon: <UnfoldMoreRoundedIcon />,
        onclick: () => gridRef?.current?.expandAllGroups?.(),
      }
    );
  }
  return btns;
};

export default GridCommonMobileButtons;
