import { gql } from '../../../__generated__';

export const myHourFactorAllowedQuery =
  gql(`query myHourFactorAllowed($hourFactor: Decimal!) {
    hourFactorAllowed: myHourFactorAllowed(hourFactor: $hourFactor) {
        isSupported
        canOverride
    }
}`);

export const adminHourFactorAllowedQuery =
  gql(`query hourFactorAllowed($employeeId: Int!, $hourFactor: Decimal!) {
    hourFactorAllowed(employeeId: $employeeId, hourFactor: $hourFactor) {
        isSupported
        canOverride
    }
}`);
