import { Box, Grid } from '@mui/material';
import {
  ButtonStrip,
  ConfirmDialogs,
  ETODateField,
  ETOSelectField,
  ETOTextField,
  MessageContext,
  OkCancelConfirmDialog,
} from '@teto/react-component-library-v2';
import dayjs, { Dayjs } from 'dayjs';
import { useFormik } from 'formik';
import React, { useCallback, useContext, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import {
  Employee,
  Permission,
  getGraphQLClient as getNewGraphQLClient,
} from 'teto-client-api';
import * as Yup from 'yup';
import {
  AddNonConformanceNoteMutation,
  UpdateNonConformanceNoteMutation,
} from '../../../../../__generated__/graphql';
import { employeesQuery } from '../../../../../api/commonQueries';
import getErrors from '../../../../../api/graphQL/getErrors';
import { getGraphQLClient } from '../../../../../api/graphQL/graphQLClient';
import AuthContext from '../../../../../contexts/AuthContext';
import SettingsContext from '../../../../../contexts/SettingsContext';
import AutoSave from '../../../components/AddEditInspector/AutoSave/AutoSave';
import { NonConformanceNote } from '../../interfaces/NonConformanceNote';
import { contentSx, rootSx } from '../components/commonPanelStyles';
import { addNonConformanceNoteMutation } from './queries/addNonConformanceNoteMutation';
import { deleteNonConformanceNoteMutation } from './queries/deleteNonConformanceNoteMutation';
import { updateNonConformanceNoteMutation } from './queries/updateNonConformanceNoteMutation';

const REQUIRED_MESSAGE = 'Required';

const paddingFixSx = { pt: 2 };

const NotesValidationSchema = Yup.object().shape({
  nonConformanceId: Yup.number().required(REQUIRED_MESSAGE),
  id: Yup.number(),
  employeeId: Yup.number().required(REQUIRED_MESSAGE),
  date: Yup.date().required(REQUIRED_MESSAGE),
  note: Yup.string().optional(),
});

interface NotesListItemProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  record?: any;
  nonConformanceId: number;
  onSaveSuccess: () => void;
  onRecordCountChanged: () => void;
  // eslint-disable-next-line no-unused-vars
  handleEditingState: (value: { isValid: boolean; isEditing: boolean }) => void;
  confirmAbandonForm: boolean;
  setConfirmAbandonForm: React.Dispatch<React.SetStateAction<boolean>>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setSelectedNoteItem: React.Dispatch<any>;
  setIsNewNoteOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const AddEditNotes = (props: NotesListItemProps) => {
  const {
    confirmAbandonForm,
    nonConformanceId,
    onRecordCountChanged,
    onSaveSuccess,
    record,
    setConfirmAbandonForm,
    setIsNewNoteOpen,
    handleEditingState,
    setSelectedNoteItem,
  } = props;
  const authContext = useContext(AuthContext);
  const messageContext = useContext(MessageContext);
  const settingsContext = useContext(SettingsContext);

  const { t } = useTranslation();
  const mode = record ? 'edit' : 'add';

  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState<boolean>(false);

  const employees = useQuery(
    ['employees'],
    () =>
      getGraphQLClient()
        .performQuery(
          employeesQuery,
          {},
          (err) => {
            messageContext.setError(err.messages[0]);
          },
          (_err) => {
            messageContext.setError(_err.employees);
          }
        )
        .then((e) => e.employees.items),
    {
      enabled: true,
      refetchOnWindowFocus: false,
    }
  );

  const formik = useFormik<NonConformanceNote>({
    enableReinitialize: true,
    validationSchema: NotesValidationSchema,
    initialValues: {
      date: record?.date ?? dayjs().format(settingsContext.settings.dateFormat),
      employeeId: record?.employeeId ?? authContext.user?.id,
      id: record?.id ?? 0,
      nonConformanceId: nonConformanceId ?? 0,
      note: record?.note ?? '',
    },
    onSubmit: (values, actions) => {
      _submitForm(values, actions);
    },
  });

  const mutationParams = useMemo(
    () =>
      mode === 'add'
        ? {
            input: {
              date: formik.values.date,
              employeeId: formik.values.employeeId,
              nonConformanceId: formik.values.nonConformanceId,
              note: formik.values.note,
            },
          }
        : {
            input: {
              date: formik.values.date,
              employeeId: formik.values.employeeId,
              id: formik.values.id,
              note: formik.values.note,
            },
          },
    [
      formik.values.date,
      formik.values.employeeId,
      formik.values.id,
      formik.values.nonConformanceId,
      formik.values.note,
      mode,
    ]
  );

  const _submitForm = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (values: NonConformanceNote, actions: any) => {
      const res =
        mode === 'edit'
          ? getNewGraphQLClient().performMutation(
              updateNonConformanceNoteMutation,
              {
                input: {
                  date: formik.values.date,
                  employeeId: formik.values.employeeId as number,
                  id: formik.values.id as number,
                  note: formik.values.note,
                },
              }
            )
          : getNewGraphQLClient().performMutation(
              addNonConformanceNoteMutation,
              {
                input: {
                  date: formik.values.date,
                  employeeId: formik.values.employeeId as number,
                  nonConformanceId: formik.values.nonConformanceId,
                  note: formik.values.note,
                },
              }
            );

      res
        .then((e) => {
          if (e.hasError()) {
            e.showAllSystemErrors(messageContext.setError);
            if (e.hasValidationErrors()) {
              const fieldErr = getErrors(e.validationErrors);
              messageContext.setError(fieldErr);
            }
            return;
          }
          if (mode === 'edit') {
            if (
              (e.data as UpdateNonConformanceNoteMutation)
                .updateNonConformanceNote?.success
            ) {
              onSaveSuccess();
              setSelectedNoteItem(values);
            }
          } else if (
            (e.data as AddNonConformanceNoteMutation).addNonConformanceNote
              ?.success
          ) {
            messageContext.setSuccess(
              t('generic.createdSuccess', {
                record: t('generic.note'),
              })
            );
            actions.resetForm();
            onSaveSuccess();
            onRecordCountChanged();
          }
        })
        .finally(() => actions.setSubmitting(false));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [mode, mutationParams, formik, onRecordCountChanged, onSaveSuccess]
  );

  const canModifyNote = useMemo(
    () =>
      authContext.hasPermission(
        Permission.Modify_Manufacturing_NonConformances_Notes
      ),
    [authContext]
  );

  const canDeleteNote = useMemo(
    () =>
      authContext.hasPermission(
        Permission.Delete_Manufacturing_NonConformances_Notes
      ),
    [authContext]
  );

  const _onDeleteClicked = () => {
    getNewGraphQLClient()
      .performMutation(deleteNonConformanceNoteMutation, {
        id: formik.values.id as number,
      })
      .then((e) => {
        if (e.hasError()) {
          e.showAllSystemErrors(messageContext.setError);
          if (e.hasValidationErrors()) {
            const fieldErr = getErrors(e.validationErrors);
            messageContext.setError(fieldErr);
          }
          return;
        }
        if (e.data.deleteNonConformanceNote?.success) {
          messageContext.setSuccess(
            t('generic.deletedSuccess', { record: 'Note' })
          );
          setIsDeleteDialogOpen(false);
          onRecordCountChanged();
        }
      });
  };

  const _handleDateChange = (val: Dayjs | null | undefined) => {
    if (val === null) {
      formik.setFieldValue('date', dayjs());
    } else {
      formik.setFieldValue('date', val);
    }
  };

  React.useEffect(() => {
    handleEditingState({
      isEditing: formik.dirty,
      isValid: formik.isValid,
    });
  }, [formik.dirty, formik.isValid, handleEditingState]);

  return (
    <Box sx={rootSx}>
      {mode === 'edit' && <AutoSave debounceMs={1500} formik={formik} />}
      <Box sx={contentSx}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Box m={0} mb={0}>
              <ETODateField
                disabled={!canModifyNote}
                handleChange={(v) => _handleDateChange(v)}
                inputFormat={settingsContext.settings.dateFormat}
                label={t('generic.date')}
                name="date"
                size="small"
                value={formik.values.date}
              />
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Box m={0} mb={0}>
              <ETOSelectField
                disableClearable
                disabled={!canModifyNote}
                error={formik.errors.employeeId}
                handleChange={formik.handleChange}
                itemDisabledSelector={(item: Partial<Employee>) => !item.active}
                itemNameSelector={(item: Partial<Employee>) =>
                  `${!item.active ? '(inactive) ' : ''}${
                    item.employeeNumber
                  } - ${item.firstName} ${item.lastName}`
                }
                items={employees.data ?? []}
                itemValueSelector={(item: Partial<Employee>) => item.id}
                label={t('entities:Employee.Employee')}
                name="employeeId"
                size="small"
                value={formik.values.employeeId}
              />
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Box m={0} mb={0}>
              <ETOTextField
                disabled={!canModifyNote}
                error={formik.errors.note}
                handleChange={formik.handleChange}
                label={t('generic.note')}
                multiline
                name="note"
                size="small"
                value={formik.values.note}
              />
            </Box>
          </Grid>
        </Grid>

        <Box sx={paddingFixSx}>
          {mode === 'edit' && (
            <ButtonStrip
              rightButton={{
                text: t('generic.delete'),
                color: 'error',
                onClick: () => setIsDeleteDialogOpen(true),
                disabled: !canDeleteNote,
              }}
              size="medium"
            />
          )}
          {mode === 'add' && (
            <ButtonStrip
              leftButton={{
                text: t('generic.reset'),
                onClick: () => formik.resetForm(),
                color: 'secondary',
              }}
              rightButton={{
                text: t('generic.add'),
                onClick: () => formik.submitForm(),
                color: 'primary',
              }}
              size="medium"
            />
          )}
        </Box>
      </Box>
      {isDeleteDialogOpen && (
        <ConfirmDialogs
          content={t('dialogs.deleteGenericItem.content')}
          leftButton={{
            onClick: () => setIsDeleteDialogOpen(false),
            label: t('generic.no'),
          }}
          open={isDeleteDialogOpen}
          rightButton={{
            onClick: () => _onDeleteClicked(),
            label: t('generic.yes'),
          }}
          title={t('dialogs.deleteGenericItem.title')}
        />
      )}
      {confirmAbandonForm && (
        <OkCancelConfirmDialog
          content={t('dialogs.closeUnsavedForm.content')}
          onCancel={() => {
            setConfirmAbandonForm(false);
          }}
          onOk={() => {
            setConfirmAbandonForm(false);
            if (mode === 'add') {
              formik.resetForm();
              setIsNewNoteOpen(false);
            }
            setSelectedNoteItem(undefined);
          }}
          open={confirmAbandonForm}
          title={t('dialogs.closeUnsavedForm.title')}
        />
      )}
    </Box>
  );
};

export default AddEditNotes;
