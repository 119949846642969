import {
  ETOSelectField,
  MessageContext,
} from '@teto/react-component-library-v2';
import { FormikProps } from 'formik';
import React, { useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { getGraphQLClient } from 'teto-client-api';
import getErrors from '../../../../api/graphQL/getErrors';
import { jobsQuery, warningJobsQuery } from '../../queries/jobsQuery';
import projectsQuery from '../../queries/projectsQuery';
import { JobProject } from '../../types/JobProject';

interface InventoryPullFormProps {
  disableInputs?: {
    projectId?: boolean;
    specId?: boolean;
  };
  projectName?: string;
  specName?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  formik: FormikProps<any>;
  // eslint-disable-next-line no-unused-vars
  handleChange?: (e: JobProject) => void;
  shouldWarnAboutClosedJobs?: boolean;
}

interface SelectOption {
  description?: string;
  displayName?: string;
  id: number;
}

const InventoryPullForm = (props: InventoryPullFormProps) => {
  const {
    disableInputs,
    formik,
    handleChange,
    projectName,
    specName,
    shouldWarnAboutClosedJobs,
  } = props;
  const messageContext = useContext(MessageContext);
  const { t } = useTranslation();

  const projectReplacement = useMemo(
    () => projectName || 'projectId',
    [projectName]
  );

  const specReplacement = useMemo(() => specName || 'specId', [specName]);

  const jobsQueryToUse = useMemo(
    () => (shouldWarnAboutClosedJobs ? warningJobsQuery : jobsQuery),
    [shouldWarnAboutClosedJobs]
  );
  const projects = useQuery(
    ['projects'],
    () =>
      getGraphQLClient()
        .performQuery(projectsQuery, {})
        .then((d) => {
          if (d.hasError()) {
            d.showAllSystemErrors(messageContext.setError);
            if (d.hasValidationErrors()) {
              const errors = getErrors(d.validationErrors);
              messageContext.setError(errors);
            }
            return;
          }
          return d.data.projects?.items as unknown as SelectOption[];
        }),
    {
      enabled: true,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
    }
  );

  const jobs = useQuery(
    ['jobs', formik.values?.[projectReplacement]],
    () =>
      getGraphQLClient()
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        .performQuery(jobsQueryToUse as any, {
          projectId: formik.values?.[projectReplacement],
        })
        .then((d) => {
          if (d.hasError()) {
            d.showAllSystemErrors(messageContext.setError);
            if (d.hasValidationErrors()) {
              const errors = getErrors(d.validationErrors);
              messageContext.setError(errors);
            }
            return;
          }
          return d.data.specs?.items as unknown as SelectOption[];
        }),
    {
      enabled: (formik?.values?.[projectReplacement] as number) > 0,
      refetchOnWindowFocus: false,
    }
  );

  return (
    <>
      <ETOSelectField
        data-testid="nci-project"
        disabled={
          disableInputs?.[
            projectReplacement as keyof InventoryPullFormProps['disableInputs']
          ]
        }
        error={formik.errors?.[projectReplacement] as string}
        handleChange={(e) => {
          const value = parseInt(e.target.value, 10);
          const update = {
            [projectReplacement]: (Number.isNaN(value)
              ? undefined
              : value) as number,
            [specReplacement]: undefined as unknown as number,
          };
          formik.setValues({
            ...formik.values,
            ...update,
          });
          handleChange?.(update);
        }}
        itemNameSelector={(item: SelectOption) =>
          item.displayName ?? item.id.toString()
        }
        items={(projects.data as []) ?? []}
        itemValueSelector={(item: SelectOption) => item.id}
        label={t('entities:NonConformance.Project')}
        name={projectReplacement}
        size="small"
        value={formik.values?.[projectReplacement] ?? null}
      />

      <ETOSelectField
        data-testid="nci-job"
        disabled={
          disableInputs?.[
            specReplacement as keyof InventoryPullFormProps['disableInputs']
          ]
        }
        error={formik.errors?.[specReplacement] as string}
        handleChange={(e: { target: { value: string } }) => {
          const value = parseFloat(e.target.value);
          formik.setFieldValue(specReplacement, value || undefined);

          handleChange?.({
            [projectReplacement]: formik.values?.[projectReplacement],
            [specReplacement]: value,
          });
        }}
        itemNameSelector={(item: SelectOption) => item.displayName ?? ''}
        items={(jobs.data as []) ?? []}
        itemValueSelector={(item: SelectOption) => item.id}
        label={t('entities:NonConformance.SpecId')}
        name={specReplacement}
        size="small"
        value={formik.values?.[specReplacement] ?? null}
      />
    </>
  );
};

export default InventoryPullForm;
