import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { SxProps, Theme } from '@mui/material/styles';
import {
  GenericDialog,
  InspectorProps,
  MessageContext,
} from '@teto/react-component-library-v2';

import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { IGraphQLClient, getGraphQLClient } from 'teto-client-api';
import * as Yup from 'yup';
import { Button, DialogActions, Divider } from '@mui/material';
import { Dayjs } from 'dayjs';
import { flushSync } from 'react-dom';
import { TypedDocumentNode } from '../../../api/graphQL/types/TypeDocumentNode';
import SettingsContext from '../../../contexts/SettingsContext';
import {
  RequisitionHeader,
  StockReorder,
} from '../../../pages/InventoryPage/ItemsToPullTab/Inspectors/types/RequisitionTypes';
import InventoryInspector from '../../../pages/InventoryPage/components/InventoryInspector';
import handleInspectorBarcodeScan from '../../../pages/InventoryPage/components/helpers/handleInspectorBarcodeScan';
import {
  MAXREASON,
  MIN_ONE_MESSAGE,
  REQUIRED_MESSAGE,
} from '../../../pages/InventoryPage/components/helpers/inventoryConstants';
import { Confirmation } from '../../../pages/InventoryPage/components/types/InventoryInspectorType';
import inventoryStockReOrderQuery from '../../../pages/InventoryPage/queries/inventoryStockReOrderQuery';
import { Editing } from '../../../pages/InventoryPage/types/Editing';
import NewRequisitionHeaderForm from './RequisitionHeaderForm';
import NewRequisitionPartForm from './RequisitionPartForm';
import { Item } from '../../../pages/InventoryPage/components/helpers/InventoryInspectorHelper';

export interface RequisitionInspectorProps
  extends Pick<InspectorProps, 'open' | 'onClose'> {
  mutationProps: {
    responseKey: string;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    mutationString: TypedDocumentNode<any, any>;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any, no-unused-vars
    formatVariables: (v: any, headerValues: RequisitionHeader) => any;
    successMessage?: string;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any, no-unused-vars
    onSuccess?: (d: any) => Promise<any>;
  };
  requireReasonNDate?: boolean;
  saveConfirmation?: Confirmation;
  shouldWarnAboutClosedJobs?: boolean;
}

const subtitleSx: SxProps<Theme> = {
  display: 'block',
  color: 'secondary',
  width: '100%',
  textOverflow: 'ellipsis',
};

const partSchema = Yup.object().shape({
  description: Yup.string(),
  uOMId: Yup.number(),
  itemId: Yup.number().required(REQUIRED_MESSAGE).min(0, REQUIRED_MESSAGE),
  assemblyPurchase: Yup.string().when('uOMId', {
    is: (value: unknown) => value === 1,
    then: Yup.string().required(),
  }),
  purchaseQty: Yup.number()
    .required(REQUIRED_MESSAGE)
    .min(0.1, `Quantity ${MIN_ONE_MESSAGE}`),
  addReason: Yup.string().max(MAXREASON),
  requiredDate: Yup.date(),
});

const requireReasonAndDateSchema = Yup.object().shape({
  addReason: Yup.string().max(MAXREASON).required(REQUIRED_MESSAGE),
  requiredDate: Yup.date().required(REQUIRED_MESSAGE),
});
interface Part {
  description: string;
  itemId: number | undefined;
  assemblyPurchase: string | undefined;
  purchaseQty: number | undefined;
  addReason: string;
  requiredDate: Date | undefined | string;
  uOMId: number | undefined;
}

// Define a type for the hook's parameters to improve type safety and readability
type UseStockReOrderParams = {
  client: IGraphQLClient;
  headerValues: {
    destInventoryLocationId?: number;
    addReason?: string;
    requiredDate?: string;
  };
  inventoryOrderLevel: string;
  inventoryReOrderCriteria: string;
  /* eslint-disable no-unused-vars */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setReorderList: (value: React.SetStateAction<any[] | undefined>) => void;
  setInitialPart: (value: React.SetStateAction<Part>) => void;
  setDisableReorder: (value: React.SetStateAction<boolean>) => void;
  /* eslint-enable */
};

export const useStockReOrder = ({
  client,
  headerValues,
  inventoryOrderLevel,
  inventoryReOrderCriteria,
  setReorderList,
  setInitialPart,
  setDisableReorder,
}: UseStockReOrderParams) => {
  const messageContext = useContext(MessageContext);
  const [isStockReOrder, setIsStockReOrder] = useState(false);

  const handleStockReOrder = useCallback(() => {
    setDisableReorder(true);
    const criteria =
      inventoryReOrderCriteria === 'Minimum'
        ? { qtyMinRequired: { gt: 0 } }
        : { recommendedQuantity: { gt: 0 } };
    const orderLevel =
      inventoryOrderLevel === 'Maximum'
        ? { maximumQuantity: { gt: 0 } }
        : { recommendedQuantity: { gt: 0 } };

    client
      .performQuery(inventoryStockReOrderQuery, {
        filter: {
          inventoryLocationId: { eq: headerValues?.destInventoryLocationId },
          ...criteria,
          ...orderLevel,
        },
      })
      .then((d) => {
        if (d.hasError()) {
          d.handleAllErrors(messageContext.setError);
          setDisableReorder(false);
          return;
        }
        try {
          const data = d.data.inventories?.items as StockReorder[];
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          const resultList: any[] = [];
          let filteredLevelRecords: StockReorder[] = [];

          filteredLevelRecords = data.filter(
            (i) =>
              ((inventoryReOrderCriteria === 'Minimum'
                ? i.qtyMinRequired
                : i.recommendedQuantity) ?? 0) -
                ((i.otherQuantities?.available ?? 0) +
                  (i.onCalculations?.onOrder ?? 0) +
                  (i.onCalculations?.onPurchasingScreen ?? 0) +
                  (i.onCalculations?.onOpenWOP ?? 0)) >
              0
          );

          filteredLevelRecords = filteredLevelRecords?.filter(
            (i) =>
              ((inventoryOrderLevel === 'Maximum'
                ? i.maximumQuantity
                : i.recommendedQuantity) ?? 0) -
                ((i.otherQuantities?.available ?? 0) +
                  (i.onCalculations?.onOrder ?? 0) +
                  (i.onCalculations?.onPurchasingScreen ?? 0) +
                  (i.onCalculations?.onOpenWOP ?? 0)) >
              0
          );

          filteredLevelRecords?.forEach((i) => {
            const updatedRecord = {
              addReason: headerValues?.addReason ?? '',
              requiredDate: headerValues?.requiredDate,
              assemblyPurchase: undefined,
              id: i.item?.id,
              itemId: i.item?.id,
              description: i.item?.description,
              itemCompanyId: i.item?.itemCompanyId,
              uOMId: i.item?.uOMId,
              uomtype: i.item?.uOM?.uomtype,
              purchaseQty:
                ((inventoryOrderLevel === 'Maximum'
                  ? i.maximumQuantity
                  : i.recommendedQuantity) ?? 0) -
                ((i.otherQuantities?.available ?? 0) +
                  (i.onCalculations?.onOrder ?? 0) +
                  (i.onCalculations?.onPurchasingScreen ?? 0)),
            };
            setInitialPart(updatedRecord);
            resultList.push(updatedRecord);
          });

          // Process data...
          setIsStockReOrder(true);
          setReorderList([...resultList]);
        } catch (e) {
          if (e instanceof Error) {
            messageContext.setError(e.message);
          }
        }
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    setDisableReorder,
    inventoryReOrderCriteria,
    inventoryOrderLevel,
    client,
    headerValues?.destInventoryLocationId,
    headerValues?.addReason,
    headerValues?.requiredDate,
    setReorderList,
    setInitialPart,
  ]);

  return { handleStockReOrder, isStockReOrder };
};

const RequisitionInspector = (props: RequisitionInspectorProps) => {
  const {
    open,
    onClose,
    mutationProps,
    requireReasonNDate,
    saveConfirmation,
    shouldWarnAboutClosedJobs,
  } = props;
  const { t } = useTranslation();
  const {
    settings: { inventoryReOrderCriteria, inventoryOrderLevel, dateFormat },
  } = useContext(SettingsContext);

  const [isOptionChanged, setIsOptionChanged] = useState<boolean>(false);
  const [dateConfirmationDialogOpen, setDateConfirmationDialogOpen] = useState<{
    open: boolean;
    // eslint-disable-next-line no-unused-vars
    baseSubmitFunc?: (parts: Item[]) => void;
    parts?: Item[];
    // eslint-disable-next-line no-unused-vars
    setter?: (newValues: Item[]) => void;
  }>({
    open: false,
  });
  const [expandForm, setExpandForm] = useState<boolean>(false);
  const [disableReorder, setDisableReorder] = useState(false);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [reorderList, setReorderList] = useState<any[] | undefined>(undefined);
  const [initialPart, setInitialPart] = useState<Part>({
    addReason: '',
    description: '',
    itemId: undefined,
    assemblyPurchase: undefined,
    purchaseQty: undefined,
    requiredDate: undefined,
    uOMId: undefined,
  });
  const [headerValues, setHeaderValues] = useState<
    RequisitionHeader | undefined
  >(undefined);
  const [headerEditState, setheaderEditState] = useState<Editing>({
    isValid: false,
    isEditing: true,
  });

  const { handleStockReOrder, isStockReOrder } = useStockReOrder({
    client: getGraphQLClient(),
    headerValues: headerValues as RequisitionHeader,
    inventoryOrderLevel,
    inventoryReOrderCriteria,
    setReorderList,
    setInitialPart,
    setDisableReorder,
  });

  const disableStockReOrder = useMemo(
    () =>
      disableReorder ||
      headerValues?.requisitionTo === 'project' ||
      !headerEditState.isValid,
    [headerEditState.isValid, headerValues?.requisitionTo, disableReorder]
  );

  const formatParts = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (a: any) => ({
      ...a,
      id: a.id,
      title:
        `${t(
          'entities:NonConformance.PartNumber'
        )}: ${a.itemCompanyId.toString()}` ?? '-',
      subTitles: [
        <Box component="span">
          <Typography component="span" sx={subtitleSx}>
            {`${t('entities:Part.Description')}: ${a.description}`}
          </Typography>
          <Typography component="span" sx={subtitleSx}>
            {`${t('generic.quantity')}: ${a.purchaseQty}`}
          </Typography>
        </Box>,
      ],
      data: a,
    }),
    [t]
  );

  useEffect(() => {
    if (headerValues?.addReason) {
      setInitialPart((prev) => ({
        ...prev,
        addReason: headerValues?.addReason as string,
      }));
    }
    if (headerValues?.requiredDate) {
      setInitialPart((prev) => ({
        ...prev,
        requiredDate: headerValues?.requiredDate,
      }));
    }
  }, [headerValues?.addReason, headerValues?.requiredDate]);

  const _hasPotentialDateCorrects = useCallback(
    (reqDate: string | Dayjs | undefined, items: Item[]) => {
      if (!reqDate) return false;

      return Boolean(items.find((a) => a.requiredDate !== reqDate));
    },
    []
  );

  const _fixMissingDatesAndSave = useCallback(() => {
    if (!dateConfirmationDialogOpen.baseSubmitFunc) return;

    if (!dateConfirmationDialogOpen.parts || !dateConfirmationDialogOpen.setter)
      dateConfirmationDialogOpen.baseSubmitFunc(
        dateConfirmationDialogOpen?.parts ?? []
      );

    const newData = [
      ...(dateConfirmationDialogOpen?.parts ?? []).map((i) => ({
        ...i,
        requiredDate: !i.requiredDate
          ? headerValues?.requiredDate
          : i.requiredDate,
      })),
    ];

    flushSync(() => {
      dateConfirmationDialogOpen.setter?.(newData);
    });

    dateConfirmationDialogOpen.baseSubmitFunc(newData);
  }, [dateConfirmationDialogOpen, headerValues?.requiredDate]);

  const _fixAllDatesAndSave = useCallback(() => {
    if (!dateConfirmationDialogOpen.baseSubmitFunc) return;

    if (!dateConfirmationDialogOpen.parts || !dateConfirmationDialogOpen.setter)
      dateConfirmationDialogOpen.baseSubmitFunc(
        dateConfirmationDialogOpen?.parts ?? []
      );

    const newData = [
      ...(dateConfirmationDialogOpen?.parts ?? []).map((i) => ({
        ...i,
        requiredDate: headerValues?.requiredDate,
      })),
    ];

    flushSync(() => {
      dateConfirmationDialogOpen.setter?.(newData);
    });

    dateConfirmationDialogOpen.baseSubmitFunc(newData);
  }, [dateConfirmationDialogOpen, headerValues?.requiredDate]);

  return (
    <>
      <InventoryInspector
        barcodeScanCb={(prefix, value, quantity) =>
          handleInspectorBarcodeScan(
            prefix,
            value,
            quantity,
            'purchaseQty',
            setInitialPart
          )
        }
        deleteList={{
          value: isOptionChanged,
          setValue: (e) => setIsOptionChanged(e),
        }}
        formatPartListItem={(d) => formatParts(d)}
        formikInitialValues={initialPart}
        inspectorIdentifier="requisitionInspector"
        listFormComponent={{
          formComponent: (
            <NewRequisitionPartForm
              destInventoryLocationId={headerValues?.destInventoryLocationId}
              setExpandForm={setExpandForm}
            />
          ),
          formikSchema: !requireReasonNDate
            ? partSchema
            : partSchema.concat(requireReasonAndDateSchema),
          expandForm,
        }}
        menuOptions={[
          {
            disabled: disableStockReOrder,
            children: t('pages.inventory.itemsToPull.stockReOrder'),
            onClick: () => handleStockReOrder(),
          },
        ]}
        mutationProps={{
          ...mutationProps,
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          formatVariables: (v: any) =>
            mutationProps?.formatVariables(
              v,
              headerValues as RequisitionHeader
            ),
        }}
        onClose={onClose}
        open={open}
        overrideSubmit={(items, baseSubmit, setter) => {
          if (_hasPotentialDateCorrects(headerValues?.requiredDate, items)) {
            setDateConfirmationDialogOpen({
              open: true,
              baseSubmitFunc: baseSubmit,
              parts: items,
              setter,
            });
          } else if (baseSubmit) {
            baseSubmit(items);
          }
        }}
        partsToAddToList={reorderList}
        reducerKey="purchaseQty"
        saveConfirmation={saveConfirmation}
        setPartsToAddToList={() => setReorderList(undefined)}
        title={{
          text: t('pages.inventory.itemsToPull.requisitionInspector'),
          subText: undefined,
          substitute: undefined,
        }}
        topFormComponent={
          <NewRequisitionHeaderForm
            disableReorder={disableReorder}
            hasOptionChange={(e) => setIsOptionChanged(e as boolean)}
            isStockReOrder={isStockReOrder}
            requireReasonNDate={requireReasonNDate}
            setDisableReorder={setDisableReorder}
            setHeadFormValues={(v) => setHeaderValues(v)}
            setPanelEditingState={setheaderEditState}
            shouldWarnAboutClosedJobs={shouldWarnAboutClosedJobs}
          />
        }
        topFormEditState={headerEditState}
      />
      <>
        {dateConfirmationDialogOpen.open && (
          <GenericDialog
            isOpen={dateConfirmationDialogOpen.open}
            onClose={() => setDateConfirmationDialogOpen({ open: false })}
            title="Required Dates Missing"
          >
            <Typography>
              One or more {t('entities:Part.Part')}s have different required
              dates than specified for the Requisition. Would you like to
              automatically set these to&nbsp;
              {(headerValues?.requiredDate as unknown as Dayjs)?.format(
                dateFormat
              )}
              ?<br />
              <br />
            </Typography>
            <Divider />
            <DialogActions>
              <Button
                color="secondary"
                onClick={() => {
                  setDateConfirmationDialogOpen({ open: false });
                  dateConfirmationDialogOpen.baseSubmitFunc?.(
                    dateConfirmationDialogOpen.parts ?? []
                  );
                }}
                size="medium"
                variant="contained"
              >
                No
              </Button>
              <Button
                color="primary"
                onClick={() => {
                  _fixMissingDatesAndSave();
                  setDateConfirmationDialogOpen({ open: false });
                }}
                size="medium"
                variant="contained"
              >
                Only Empty Required Dates
              </Button>
              <Button
                color="primary"
                onClick={() => {
                  _fixAllDatesAndSave();
                  setDateConfirmationDialogOpen({ open: false });
                }}
                size="medium"
                variant="contained"
              >
                All Required Dates
              </Button>
            </DialogActions>
          </GenericDialog>
        )}
      </>
    </>
  );
};

export default RequisitionInspector;
