import IndeterminateCheckBoxRounded from '@mui/icons-material/IndeterminateCheckBoxRounded';
import { Box, Checkbox } from '@mui/material';
import React from 'react';
import deepPropertyHelper from './deepPropertyHelpers';
import FormatterErrorWrapper from './FormatterErrorWrapper';
import FormatterProps from './FormatterProps';

const boolSx = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  height: '100%',
  width: '100%',
  '& .MuiCheckbox-root': {
    padding: '0px !important',
  },
};

const BoolFormatter = (props: FormatterProps) => {
  const {
    value,
    column: { name },
    data,
  } = props;

  const formattedVal = value?.value ?? deepPropertyHelper(name, data);

  if (data?.__parentGroup?.__group) return <>&nbsp;</>;

  return (
    <Box sx={boolSx}>
      <FormatterErrorWrapper value={value}>
        <Checkbox
          checked={formattedVal ?? false}
          disabled
          indeterminate={formattedVal === undefined}
          indeterminateIcon={<IndeterminateCheckBoxRounded />}
          size="small"
        />
      </FormatterErrorWrapper>
    </Box>
  );
};

export default BoolFormatter;
