import { TypeSingleSortInfo } from '@inovua/reactdatagrid-community/packages/sorty';
import { TypeSortInfo } from '@inovua/reactdatagrid-community/types';
import React from 'react';
import { TFunction } from 'react-i18next';
import {
  MetadataColumn,
  MetadataView,
} from '../../../api/metadata/viewManager';
import ViewDefinition from '../../../views/ViewDefinitions';
import FormatterProps from '../../TetoGrid/Formatters/FormatterProps';
import {
  averageGroupSummary,
  maxGroupSummary,
  minGroupSummary,
  sumGroupSummary,
} from '../../TetoGrid/Grouping/GroupAggregateFunctions';
import TypeSummaryReducer from '../../TetoGrid/Grouping/TypeSummaryReducer';
import ColumnTypes from '../../TetoGrid/types/ColumnTypes';
import TableColumnDefinition from '../../TetoGrid/types/TableColumnDefinition';
import { AddButtonDefinition } from './types/AddButtonDefinition';
import { AddButtonPosition } from './types/AddButtonPosition';
import { AddColumn } from './types/AddColumn';
import { AddColumnPosition } from './types/AddColumnPosition';
// eslint-disable-next-line import/no-cycle
import IGridBuilderExtension from './types/IGridBuilderExtension';
import { TETOButtonColumn } from './types/TETOButtonColumn';
import { TETODataColumn } from './types/TETODataColumn';
import { TETODataColumnType } from './types/TETODataColumnType';

// eslint-disable-next-line no-unused-vars
function getEmptyDisplay(
  processor: GridBuilder,
  columnType: string
): string | undefined {
  if (Object.prototype.hasOwnProperty.call(processor.emptyDisplay, columnType))
    return processor.emptyDisplay[columnType];
  return processor.emptyDisplay['*'];
}

const getFilterType = (type: ColumnTypes): string => {
  switch (type) {
    case 'boolean':
    case 'number':
    case 'string':
      return type;
    case 'hours':
      return 'number';
    case 'foreignKey':
      return 'string';
    case 'date':
      return 'date';
    case 'datetime':
      return 'datetime';
    case 'time':
      return 'time';
    default:
      return 'string';
  }
};

// these are not really used anymore, the filter options unless specified are default from rdg filter types
const getFilterOptions = (
  type: string,
  filterOptions: 'simple' | 'standard' | string[] | undefined
): string[] | undefined => {
  if (Array.isArray(filterOptions)) {
    return filterOptions;
  }
  if (filterOptions === 'simple') {
    switch (type) {
      case 'boolean':
      case 'number':
        return ['equal'];
      case 'string':
      case 'foreignKey':
        return ['contains'];
      default:
        return undefined;
    }
  } else if (filterOptions === 'standard') {
    switch (type) {
      case 'boolean':
        return ['equal'];
      case 'number':
        return ['equal', 'noEqual', 'greaterThan', 'lessThan'];
      case 'string':
      case 'foreignKey':
        return ['startsWith', 'endsWidth', 'contains', 'equal', 'notEqual'];
      case 'date':
      case 'time':
      case 'datetime':
        return [
          'eq',
          'gt',
          'gte',
          'in',
          'lt',
          'lte',
          'neq',
          'ngt',
          'ngte',
          'nin',
          'nlt',
          'nlte',
        ];
      default:
        return undefined;
    }
  } else {
    switch (type) {
      case 'boolean':
        return ['equal'];
      case 'number':
        return ['equal', 'noEqual', 'greaterThan', 'lessThan'];
      case 'string':
      case 'foreignKey':
        return ['startsWith', 'endsWidth', 'contains', 'equal', 'notEqual'];
      default:
        return undefined;
    }
  }
};

// eslint-disable-next-line no-unused-vars
const getGroupSummaryReducer = (
  val?: 'sum' | 'min' | 'max' | 'avg' | null
): TypeSummaryReducer | undefined => {
  switch (val) {
    case 'avg':
      return averageGroupSummary;
    case 'min':
      return minGroupSummary;
    case 'max':
      return maxGroupSummary;
    case 'sum':
      return sumGroupSummary;
    case null:
    default:
      return undefined;
  }
};

const calculateAlign = (type: ColumnTypes) => {
  if (
    type === 'number' ||
    type === 'time' ||
    type === 'date' ||
    type === 'currency' ||
    type === 'hours'
  )
    return 'end';
  return 'start';
};

export class GridBuilder {
  emptyDisplay: { [key: string]: string } = { '*': ' - ' };

  columns: TETODataColumn[] = [];

  leftButtons: TETOButtonColumn[] = [];

  rightButtons: TETOButtonColumn[] = [];

  defaultVisible: string[] = [];

  defaultSort: TypeSortInfo = [];

  defaultGrouping: string[] = [];

  rootQueryPath = '';

  customFormatters: {
    // eslint-disable-next-line no-unused-vars
    [key: string]: (item: FormatterProps) => string | React.ReactElement;
  } = {};

  setEmptyDisplay(columnName: string, placeHolder: string): GridBuilder {
    this.emptyDisplay[columnName] = placeHolder;
    return this;
  }

  removeProperty(
    name: string,
    conditionalRenderFunc?: () => boolean
  ): GridBuilder {
    if (conditionalRenderFunc && !conditionalRenderFunc()) {
      return this;
    }

    const colIndex = this.columns.findIndex((v) => v.name === name);
    if (colIndex >= 0) this.columns.splice(colIndex, 1);

    return this;
  }

  addCustomFormatterForColumn(
    colName: string,
    // eslint-disable-next-line no-unused-vars
    renderer: (item: FormatterProps) => string | React.ReactElement
  ) {
    this.customFormatters[colName] = renderer;

    return this;
  }

  addColumn(
    col: AddColumn,
    position?: AddColumnPosition,
    columnType: TETODataColumnType = 'custom'
  ): this {
    let pos = this.columns.length;

    if (typeof position === 'number') {
      pos = position;
    } else if (position === 'left') {
      pos = 0;
    }

    const newCol: TETODataColumn = {
      ...col,
      id: col.name,
      header: col.header ?? col.title,
      order: pos,
      required: col.required,
      isPrimaryKey: Boolean(col.isPrimaryKey),
      rendersInlineEditor: Boolean(col.rendersInlineEditor),
      parentName: col.parentName,
      parentPath: col.parentPath,
      includeInMobile: Boolean(col.includeInMobile),
      columnType,
      align:
        col.align ?? calculateAlign(col.type?.toLowerCase() as ColumnTypes),
    };

    let insertPos = -1;

    this.columns = this.columns.map((c) => {
      if (c.order >= pos) {
        if (insertPos === -1) {
          insertPos = this.columns.indexOf(c);
        }

        return {
          ...c,
          order: c.order + 1,
        };
      }

      return c;
    });

    if (insertPos === -1) {
      this.columns.push(newCol);
    } else {
      this.columns.splice(insertPos, 0, newCol);
    }

    return this;
  }

  extension(
    extension: IGridBuilderExtension,
    t: TFunction<'translation', undefined>
  ) {
    extension.execute(this, t);
    return this;
  }

  addButton(
    buttonDef: AddButtonDefinition,
    // eslint-disable-next-line no-unused-vars
    conditionalRenderFunc?: (item: unknown) => boolean,
    position: AddButtonPosition = 'right'
  ): GridBuilder {
    const renderFunc = conditionalRenderFunc
      ? (item: unknown) => {
          if (conditionalRenderFunc(item)) {
            return buttonDef.render(item);
          }

          // eslint-disable-next-line react/jsx-no-useless-fragment
          return <></>;
        }
      : buttonDef.render;

    const button = {
      ...buttonDef,
      renderFunc,
      columnType: 'button' as TETODataColumnType,
    };

    if (position === 'left') {
      this.leftButtons.push(button);
    } else if (position === 'right') {
      this.rightButtons.push(button);
    }
    return this;
  }

  updateDefinition(columnName: string, data: Partial<TableColumnDefinition>) {
    const columnIndex = this.columns.findIndex((a) => a.name === columnName);
    if (columnIndex >= 0) {
      this.columns[columnIndex] = { ...this.columns[columnIndex], ...data };
    }
    return this;
  }

  updateDefinitions(
    columnNames: string[],
    data: Partial<TableColumnDefinition>
  ) {
    columnNames.forEach((c) => this.updateDefinition(c, data));

    return this;
  }
}

export const createViewFromMetadataView = (view: MetadataView) => {
  const gridBuilder = new GridBuilder();
  gridBuilder.defaultVisible = view.defaultColumns;
  gridBuilder.defaultGrouping = view.defaultGrouping;

  gridBuilder.defaultSort = [];
  view.defaultSort.forEach((ds) => {
    const isDesc = ds.startsWith('!');
    const name = isDesc ? ds.substring(1) : ds;
    (gridBuilder.defaultSort as TypeSingleSortInfo[]).push({
      name,
      dir: isDesc ? -1 : 1,
    });
  });

  view.columns
    .sort(
      (a, b) =>
        view.defaultColumns.indexOf(a.path) -
        view.defaultColumns.indexOf(b.path)
    )
    .forEach((c, i) => {
      gridBuilder.addColumn(
        {
          ...c,
          name: c.path ?? c.name,
          title:
            view.overrideTitles?.find((a) => a.fieldName === c.path)?.value ??
            c.title ??
            '',
          sortable: !c.disableSort,
          type: c.fieldType ?? 'string',
          align: calculateAlign(c.fieldType?.toLowerCase() as ColumnTypes),
          showByDefault: view.defaultColumns.indexOf(c.path) >= 0,
          fixed: 'none',
          isPrimaryKey: Boolean(c.isPrimaryKey),
          editable: false,
          required: view.requiredColumns.indexOf(c.path) >= 0,
          defaultFlex: i === 0 ? 1 : undefined,
          minWidth: c.defaultWidth * 0.6,
          emptyDisplay: getEmptyDisplay(
            gridBuilder,
            c.fieldType?.toLowerCase() ?? 'string'
          ),
          ...(c.disableFilter
            ? {
                filterType: 'none',
                filterable: false,
                filterOptions: undefined,
              }
            : {
                filterType: getFilterType(
                  c.fieldType?.toLowerCase() as ColumnTypes
                ),
                filterable: true,
                filterOptions: getFilterOptions(
                  c.fieldType?.toLowerCase() as ColumnTypes,
                  'standard'
                ),
              }),
          groupSummaryReducer: getGroupSummaryReducer(c.groupingAggregate),
        },
        i,
        'metadata'
      );
    });

  return gridBuilder;
};

export const createViewFromViewDefinition = (
  viewDefinition: ViewDefinition,
  columns: MetadataColumn[]
) => {
  const { view } = viewDefinition;

  const gridBuilder = new GridBuilder();
  gridBuilder.defaultVisible =
    view?.columns.map((a) => (typeof a === 'string' ? a : a.name)) ?? [];
  gridBuilder.defaultGrouping = view?.defaultGrouping ?? [];

  if (!view) return gridBuilder;

  gridBuilder.defaultSort = [];
  (view.defaultSort ?? []).forEach((ds) => {
    const isDesc = typeof ds === 'string' ? false : ds.desc;
    const name = typeof ds === 'string' ? ds : ds.name;
    (gridBuilder.defaultSort as TypeSingleSortInfo[]).push({
      name,
      dir: isDesc ? -1 : 1,
    });
  });

  const requiredColumns = view.columns
    .filter((a) => typeof a !== 'string' && a.required)
    .map((a) => (typeof a === 'object' ? a.name : '')); // empty quote wont actually ever be seen it is disqualified above

  const overriddenTitles = view.columns
    .filter((a) => typeof a !== 'string' && a.title)
    .map((a) =>
      typeof a === 'object'
        ? { name: a.name, title: a.title }
        : { name: '', title: '' }
    ); // empty quote wont actually ever be seen it is disqualified above

  columns
    .sort((a, b) => {
      const aIndex = view.columns.findIndex((c) =>
        typeof c === 'string' ? c === a.path : c.name === a.path
      );
      const bIndex = view.columns.findIndex((c) =>
        typeof c === 'string' ? c === b.path : c.name === b.path
      );

      return aIndex - bIndex;
    })
    .forEach((c, i) => {
      gridBuilder.addColumn(
        {
          ...c,
          name: c.path ?? c.name,
          title:
            overriddenTitles.find((a) => a.name === c.path)?.title ??
            c.title ??
            '',
          sortable: !c.disableSort,
          nullable: c?.type?.kind !== 'NON_NULL',
          type: c.fieldType ?? 'string',
          align: calculateAlign(c.fieldType?.toLowerCase() as ColumnTypes),
          showByDefault:
            view.columns.findIndex(
              (a) => (typeof a === 'string' ? a : a.name) === c.path
            ) >= 0,
          fixed: 'none',
          isPrimaryKey: Boolean(c.isPrimaryKey),
          editable: false,
          required: requiredColumns.indexOf(c.path) >= 0,
          defaultFlex: i === 0 ? 1 : undefined,
          minWidth: c.defaultWidth * 0.6,
          emptyDisplay: getEmptyDisplay(
            gridBuilder,
            c.fieldType?.toLowerCase() ?? 'string'
          ),
          ...(c.disableFilter
            ? {
                filterType: 'none',
                filterable: true,
                filterOptions: undefined,
              }
            : {
                filterType: getFilterType(
                  c.fieldType?.toLowerCase() as ColumnTypes
                ),
                filterable: true,
                filterOptions: getFilterOptions(
                  c.fieldType?.toLowerCase() as ColumnTypes,
                  'standard'
                ),
              }),
          groupSummaryReducer: getGroupSummaryReducer(c.groupingAggregate),
        },
        i,
        'metadata'
      );
    });

  return gridBuilder;
};

export const createColumns = function _create() {
  return new GridBuilder();
};
