import { AppearanceContext } from '@teto/react-component-library-v2';
import { useContext } from 'react';

const useCurrentTheme = () => {
  const { themeVariant } = useContext(AppearanceContext);

  return themeVariant === 'dark' ? 'default-dark' : 'default-light';
};

export default useCurrentTheme;
