import { atom } from 'jotai';
import { createScope, molecule } from 'jotai-molecules';
import { atomWithReset } from 'jotai/utils';
import { Company } from '../../../__generated__/graphql';
import { CompanyDetailInspectorProps } from '../../Inspectors/CompanyDetailInspector/CompanyDetailInspectorProps';

type CompanyState = Omit<CompanyDetailInspectorProps, 'onClose'> & {
  hasSavedOccurred?: boolean;
};
export const companyDetailSharedState = atomWithReset<CompanyState>({
  initialValues: undefined as unknown as Company,
  open: false,
  hasSavedOccurred: false,
});

// eslint-disable-next-line no-unused-vars
const _defaultOnClose = atom(null, (get, set, newVal?: boolean) =>
  set(companyDetailSharedState, {
    initialValues: undefined as unknown as Company,
    open: false,
    renderAsInspector: false,
    hasSavedOccurred: false,
  })
);

// this sets up the scope for the onClose molecule, that can be modified on a per scope basis
export const initialCompanyDetailOnCloseScope = createScope(_defaultOnClose);

// this defaults to the initialOnCloseScope, but when within a different scope, it will use that scope instead
export const onCompanyDetailClose = molecule((getMol, getScope) => {
  const initialOnClose = getScope(initialCompanyDetailOnCloseScope);
  return initialOnClose;
});
