import ExpandLessRounded from '@mui/icons-material/ExpandLessRounded';
import ExpandMoreRounded from '@mui/icons-material/ExpandMoreRounded';
import {
  Box,
  ButtonBase,
  Grid,
  Popover,
  SxProps,
  Theme,
  Typography,
} from '@mui/material';
import React from 'react';
import PartsHistorySearchInput from './PartsHistorySearchInput';

interface MobileTitleBarProps {
  part?: {
    id: number;
    label: string;
  };
  timeFrame: number;
  includeRelPS: boolean;
  // eslint-disable-next-line no-unused-vars
  onPartChanged: (part?: { id: number; label: string }) => void;
  // eslint-disable-next-line no-unused-vars
  onTimeframeChanged: (timeFrame: number) => void;
  // eslint-disable-next-line no-unused-vars
  onIncludeRelPSChanged: (includeRelPS: boolean) => void;
}
const infoContainerSx: SxProps<Theme> = {
  alignItems: 'center',
  display: 'flex',
  justifyContent: 'flex-end',
};

const textSx: SxProps<Theme> = {
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  width: '100%',
  fontWeight: '500',
};

const textContainerSx: SxProps<Theme> = {
  display: 'flex',
  flexDirection: 'column',
  flexGrow: 1,
  overflow: 'hidden',
  color: 'primary.main',
};

const buttonSx: SxProps<Theme> = {
  display: 'flex',
  columnGap: 2,
  p: 1,
  width: '100%',
};

const iconSx: SxProps<Theme> = {
  flexGrow: 0,
  flexShrink: 0,
  color: 'primary.main',
  borderRadius: 1,
};

const partSearchSx: SxProps<Theme> = { p: 2, rowGap: 3, mb: 0 };

const MobileTitleBar: React.FC<MobileTitleBarProps> = (props) => {
  const {
    includeRelPS,
    onIncludeRelPSChanged,
    onPartChanged,
    onTimeframeChanged,
    part,
    timeFrame,
  } = props;

  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );

  const open = Boolean(anchorEl);

  return (
    <Grid
      container
      sx={{
        minHeight: 2,
      }}
    >
      <Grid item sx={infoContainerSx} xs={7}>
        <ButtonBase onClick={(e) => setAnchorEl(e.currentTarget)} sx={buttonSx}>
          {open ? (
            <ExpandLessRounded sx={iconSx} />
          ) : (
            <ExpandMoreRounded sx={iconSx} />
          )}
          <Box sx={textContainerSx}>
            <Typography align="left" noWrap sx={textSx} variant="subtitle1">
              {part?.label ?? 'No Part Selected'}
            </Typography>
            <Typography align="left" noWrap sx={textSx} variant="caption">
              {timeFrame} days {includeRelPS ? '- Incl. PS' : ''}{' '}
            </Typography>
          </Box>
        </ButtonBase>
      </Grid>
      <Popover
        anchorEl={anchorEl}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        onClose={() => setAnchorEl(null)}
        open={open}
      >
        <PartsHistorySearchInput
          includeRelPS={includeRelPS}
          mobile
          onIncludeRelPSChanged={onIncludeRelPSChanged}
          onPartChanged={onPartChanged}
          onTimeframeChanged={onTimeframeChanged}
          part={part}
          setPopoverOpen={setAnchorEl}
          sx={partSearchSx}
          timeFrame={timeFrame}
        />
      </Popover>
    </Grid>
  );
};

export default MobileTitleBar;
