import Box from '@mui/material/Box';
import { SxProps, Theme } from '@mui/material/styles';
import React, { ReactNode, useCallback, useRef, useState } from 'react';
import { ConfirmationDialogRaw } from '../../TETOGridGraphQL/ConfigureInspector/Panels/FilterColumnsPanel/FilterField';
import FilterIcon from '../../TETOGridGraphQL/ConfigureInspector/Panels/FilterColumnsPanel/FilterIcon';
import FilterValue from './filterValue';

interface DefaultFilterWrapperProps {
  children: ReactNode;
  filterProps: FilterValue;
  name: string;
  // eslint-disable-next-line no-unused-vars
  setOperator: (value: string, name: string) => void;
}

const containerSx: SxProps<Theme> = {
  display: 'flex',
  columnGap: 1,
};

const DefaultFilterWrapper = (props: DefaultFilterWrapperProps) => {
  const { children, filterProps, name, setOperator } = props;

  const filterRef = useRef(null);
  const [dialogProps, setDialogProps] = useState<{
    open: boolean;
    value?: FilterValue;
  }>({
    open: false,
    value: filterProps,
  });

  const _handleFilterClick = useCallback(() => {
    setDialogProps({ value: filterProps, open: true });
  }, [filterProps]);

  return (
    <Box ref={filterRef} sx={containerSx}>
      {children}
      <FilterIcon onClick={_handleFilterClick} title="Filter" />
      <ConfirmationDialogRaw
        anchorEl={filterRef.current}
        id="filter-operator-menu"
        onClose={(d) => {
          // setDialogProps(undefined);
          setDialogProps((i) => ({ ...i, open: false }));
          setOperator(
            d ? (d as string) : dialogProps.value?.operator ?? '',
            name
          );
        }}
        open={dialogProps.open}
        options={dialogProps?.value?.options ?? []}
        value={dialogProps?.value?.operator as string}
      />
    </Box>
  );
};

export default DefaultFilterWrapper;
