/* eslint-disable react/jsx-sort-props */
import {
  ETOSelectField,
  ETOSelectFieldProps,
} from '@teto/react-component-library-v2';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { getGraphQLClient } from 'teto-client-api';
import { getEnum } from '../../api/metadata/metadataManager';
import deepPropertyHelper from '../TETOGridGraphQL/Formatters/deepPropertyHelpers';
import TETODataField from './FormBuilder/TETODataField';
import FormDefinition from './FormDefinition';
import {
  SelectSourceFromArray,
  SelectSourceFromEnum,
  SelectSourceFromGraphQL,
  SelectSourceFromPromise,
  SelectSourceFromSavedGraphQL,
} from './SelectSourceType';
import SelectDefinition from './selects/SelectDefinition';
import * as savedQueries from './selects/selects';

export interface SelectWithDataWrapperProps<T> {
  form: FormDefinition;
  field: TETODataField;
  selectProps: Omit<
    ETOSelectFieldProps<T>,
    'items' | 'itemNameSelector' | 'itemValueSelector'
  >;
}

const SelectWithDataWrapper = <T extends string | object>(
  props: SelectWithDataWrapperProps<T>
) => {
  const { field, selectProps, form } = props;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const prevData = useRef<any>(form.values);
  const initialRender = useRef<boolean>(true);

  const [dataSource, setDataSource] = useState<T[]>([]);
  const [nameGetter, setNameGetter] = useState<string>(
    field.selectSource?.label ?? ''
  );
  const [valueGetter, setValueGetter] = useState<string>(
    field.selectSource?.value ?? ''
  );
  const [error, setError] = useState<string>();

  const _updateDataSource = useCallback(() => {
    setError(undefined);

    if (!field || !field.selectSource) {
      setDataSource([]);
      return;
    }

    setNameGetter(field.selectSource.label);
    setValueGetter(field.selectSource.value);

    if (Object.prototype.hasOwnProperty.call(field.selectSource, 'fromArray')) {
      // array
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      setDataSource((field.selectSource as SelectSourceFromArray).fromArray);
    } else if (
      Object.prototype.hasOwnProperty.call(field.selectSource, 'fromPromise')
    ) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (field.selectSource as SelectSourceFromPromise)
        .fromPromise(form.values)
        .then((d) => setDataSource(d))
        .catch((e: Error) => setError(e?.message ?? e.toString()));
    } else if (
      Object.prototype.hasOwnProperty.call(field.selectSource, 'fromEnum')
    ) {
      getEnum((field.selectSource as SelectSourceFromEnum).fromEnum)
        .then((d) => {
          if (!d?.data || !d?.data.__type) return setDataSource([]);

          setDataSource(
            d.data.__type.enumValues.map(
              (c) =>
                ({
                  value: c,
                  label: `enums.${d.data.__type?.name}.${c}`,
                } as T)
            )
          );
        })
        .catch((e: Error) => setError(e?.message ?? e.toString()));
    } else if (
      Object.prototype.hasOwnProperty.call(
        field.selectSource,
        'fromGraphQLQuery'
      )
    ) {
      // graphql query
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      getGraphQLClient()
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        .performQuery(
          (field.selectSource as SelectSourceFromGraphQL).fromGraphQLQuery,
          form.values
        )
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        .then((d: any) => {
          const { data } = d;
          if (!data) return setDataSource([]);

          const propNames = Object.getOwnPropertyNames(data);
          if (propNames.length === 0) {
            return setDataSource([]);
          }

          const queryKey = propNames[0];

          if (
            field.selectSource &&
            typeof field.selectSource === 'object' &&
            'items' in data[queryKey]
          ) {
            setDataSource(data?.[queryKey].items);
          } else {
            // eslint-disable-next-line no-console
            console.error('invalid items in data');
            setDataSource([]);
          }
        })
        .catch((e: Error) => setError(e?.message ?? e.toString()));
    } else if (
      Object.prototype.hasOwnProperty.call(
        field.selectSource,
        'fromSavedGraphQLQuery'
      )
    ) {
      const src = (field.selectSource as SelectSourceFromSavedGraphQL)
        .fromSavedGraphQLQuery;

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const gqlQuery = (savedQueries as any)[src] as
        | SelectDefinition
        | undefined;

      if (!gqlQuery) throw new Error(`Saved query ${src} not found`);

      setNameGetter(gqlQuery.labelField);
      setValueGetter(gqlQuery.valueField);

      // graphql query
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      getGraphQLClient()
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        .performQuery(gqlQuery.query, form.values)
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        .then((d: any) => {
          const { data } = d;
          if (!data) return setDataSource([]);

          const propNames = Object.getOwnPropertyNames(data);
          if (propNames.length === 0) {
            return setDataSource([]);
          }

          const queryKey = propNames[0];

          if (
            field.selectSource &&
            typeof field.selectSource === 'object' &&
            'items' in data[queryKey]
          ) {
            setDataSource(data?.[queryKey].items);
          } else {
            // eslint-disable-next-line no-console
            console.error('invalid items in data');
            setDataSource([]);
          }
        })
        .catch((e: Error) => setError(e?.message ?? e.toString()));
    }
  }, [field, form.values]);

  useEffect(() => {
    // Check for the initial render
    if (initialRender?.current) {
      // Ensure all dependent fields are available for query
      if (field && field.dependsOn && field.dependsOn.length > 0) {
        if (
          prevData?.current &&
          field.dependsOn?.every((i) => prevData.current?.[i])
        )
          _updateDataSource();

        // Query for non-dependent field
      } else _updateDataSource();

      initialRender.current = false;
    } else if (field && field.dependsOn && field.dependsOn.length > 0) {
      // if the dependant fields change
      if (
        field.dependsOn?.some(
          (d) => form.values?.[d] && form.values?.[d] !== prevData.current?.[d]
        ) &&
        field.dependsOn?.every((i) => form.values?.[i])
      )
        _updateDataSource();
    }

    prevData.current = form.values;
  }, [_updateDataSource, form.values, field]);

  const _getName = useCallback(
    (d: T) => {
      if (!nameGetter) return 'Unknown';

      return deepPropertyHelper(nameGetter, d);
    },
    [nameGetter]
  );

  const _getValue = useCallback(
    (d: T) => {
      if (!valueGetter) return '';
      if (typeof d === 'string') return d;

      return deepPropertyHelper(valueGetter, d);
    },
    [valueGetter]
  );

  return (
    <ETOSelectField
      freeSolo={field.selectSource?.allowUnknownEntry}
      inputLabelProps={{ shrink: true }}
      {...(error
        ? {
            disabled: true,
            error,
          }
        : {})}
      {...selectProps}
      itemNameSelector={_getName}
      items={dataSource ?? []}
      itemValueSelector={_getValue}
      // handles the case where the user selects a value from the list
      handleChange={(v) => {
        form.updateField(field.name, v.target.value);
        if (field.onFieldChange) field?.onFieldChange(form);
      }}
      // handles the case where the user types in a value that is not in the list
      onBlur={(e) => {
        if (!field.selectSource?.allowUnknownEntry) return;
        return form.updateField(field.name, e.target?.value);
      }}
      size="small"
      value={form?.values[field.name] ?? ''}
    />
  );
};

export default SelectWithDataWrapper;
