import { Box, SxProps, Theme, Tooltip } from '@mui/material';
import React from 'react';

const tooltipSx: SxProps<Theme> = {
  '& .MuiTooltip-tooltip': {
    fontSize: '0.9rem',
    marginTop: '0 !important',
  },
};

interface TooltipWrapperProps {
  children: React.ReactNode;
  error?: string;
}

const TooltipWrapper = (props: TooltipWrapperProps) => {
  const { children, error } = props;
  return (
    <Tooltip
      arrow
      enterDelay={100}
      open={Boolean(error)}
      placement="bottom-start"
      PopperProps={{
        disablePortal: true,
        sx: { tooltipSx },
      }}
      title={error}
    >
      <Box>{children}</Box>
    </Tooltip>
  );
};

export default TooltipWrapper;
