/* eslint-disable func-names */
/* eslint-disable import/prefer-default-export */
/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import TableColumnDefinition from '../../TetoGrid/types/TableColumnDefinition';
import { EditedValue } from '../types/EditedValue';
import BitwiseFormatter from './BitwiseFormatter';
import BoolFormatter from './BoolFormatter';
import CustomFormatter from './CustomFormatter';
import DateFormatter from './DateFormatter';
import DateTimeFormatter from './DateTimeFormatter';
import FormatterProps from './FormatterProps';
import NumberFormatter from './NumberFormatter';
import StringFormatter from './StringFormatter';
import TimeFormatter from './TimeFormatter';

export const buildCellFormatter = (
  col: TableColumnDefinition,
  editingItem?: EditedValue
) => {
  switch (col.type) {
    case 'boolean':
      return function (d: FormatterProps) {
        return <BoolFormatter {...d} column={col} value={editingItem} />;
      };
    case 'custom':
      return function (d: FormatterProps) {
        return <CustomFormatter {...d} column={col} value={editingItem} />;
      };
    case 'button':
      return function (d: FormatterProps) {
        return (
          <CustomFormatter
            disableInGroup
            {...d}
            column={col}
            value={editingItem}
          />
        );
      };
    case 'date':
      return function (d: FormatterProps) {
        return <DateFormatter {...d} column={col} value={editingItem} />;
      };
    case 'time':
      return function (d: FormatterProps) {
        return <TimeFormatter {...d} column={col} value={editingItem} />;
      };
    case 'datetime':
      return function (d: FormatterProps) {
        return <DateTimeFormatter {...d} column={col} value={editingItem} />;
      };
    case 'hours':
      return function (d: FormatterProps) {
        return (
          <NumberFormatter
            numberFormat="hours"
            {...d}
            column={col}
            value={editingItem}
          />
        );
      };
    case 'bitwise':
      return function (d: FormatterProps) {
        return <BitwiseFormatter {...d} column={col} value={editingItem} />;
      };
    case 'number':
      return function (d: FormatterProps) {
        return (
          <NumberFormatter
            numberFormat="number"
            {...d}
            column={col}
            value={editingItem}
          />
        );
      };
    case 'currency':
      return function (d: FormatterProps) {
        return (
          <NumberFormatter
            numberFormat="currency"
            {...d}
            column={col}
            value={editingItem}
          />
        );
      };
    case 'foreignKey':
    case 'string':
    default:
      return function (d: FormatterProps) {
        return <StringFormatter {...d} column={col} value={editingItem} />;
      };
  }
};
