import { gql } from '../../../__generated__';
import SelectDefinition from './SelectDefinition';

export default {
  query: gql(`query getProcessSchedules {
    processScheduleHeaders(order: { number: DESC }) {
      items {
        id
        number
        ownerEmployee {
          name
        }
      }
    }
  }
  `),
  valueField: 'id',
  labelField: 'number',
} as SelectDefinition;
