import { IconButton } from '@mui/material';
import Button from '@mui/material/Button';

import React, { useCallback, useMemo, useState } from 'react';

type LinkButtonWithHoverIconProps = {
  align: string;
  icon: React.ReactNode;
  value: string;
  onClick: () => void;
};
const styleSx = {
  width: '100%',
  display: 'flex',
  justifyContent: 'space-between',
  height: 'inherit',
  '&:hover': { backgroundColor: 'transparent' },
  pl: 0,
};

const LinkButtonWithHoverIcon: React.FC<LinkButtonWithHoverIconProps> = (
  props
) => {
  const { value, onClick, icon, align } = props;

  const [hover, setHover] = useState(false);

  const _onClick = useCallback(
    (e: React.MouseEvent<HTMLButtonElement>) => {
      e.stopPropagation();
      if (onClick) {
        onClick();
      }
    },
    [onClick]
  );

  const _align = useMemo(() => {
    switch (align) {
      case 'start':
        return 'flex-start';
      case 'end':
        return 'flex-end';
      default:
        return 'center';
    }
  }, [align]);
  return (
    <Button
      color="primary"
      disableElevation
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      sx={{
        ...styleSx,
        justifyContent: hover ? 'space-between' : _align,
      }}
    >
      {hover && (
        <IconButton color="primary" onClick={_onClick}>
          {icon}
        </IconButton>
      )}
      {value}
    </Button>
  );
};
export default LinkButtonWithHoverIcon;
