import { useAtom, useSetAtom } from 'jotai';
import { useMolecule } from 'jotai-molecules';
import React, { useEffect } from 'react';

import { useTranslation } from 'react-i18next';
import EngItemMasterInspector from '../../Inspectors/EngItemMasterInspector/EngItemMasterInspector';
import { BreadCrumbSharedState } from '../StateContainers/BreadCrumbModalState';
import {
  ItemMasterSharedState,
  onItemMasterClose,
} from '../StateContainers/ItemMasterState';

const useSharedItemMaster = () => {
  const { t } = useTranslation();
  const [state] = useAtom(ItemMasterSharedState);
  const onClose = useMolecule(onItemMasterClose);
  const defaultOnClose = useSetAtom(onClose);
  const setLevels = useSetAtom(BreadCrumbSharedState);

  useEffect(() => {
    if (state.open) {
      setLevels((prev) => [
        ...prev,
        {
          name: state.initialValues?.itemCompanyId
            ? `${t('inspectors.engItemMasterInspector.title')}: ${String(
                state?.initialValues?.itemCompanyId
              )}`
            : t('inspectors.engItemMasterInspector.title'),
          data: state?.initialValues,
          component: (
            <EngItemMasterInspector
              initialValues={state?.initialValues}
              onClose={defaultOnClose}
              open={state?.open}
              renderAsInspector={false}
            />
          ),
        },
      ]);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    // setLevels, // don't add this to the dependency array, it will cause an infinite loop bug #75462
    state.initialValues,
    state.open,
  ]);
};

export default useSharedItemMaster;
