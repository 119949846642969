import AccessTimeRoundedIcon from '@mui/icons-material/AccessTimeRounded';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import EngineeringIcon from '@mui/icons-material/Engineering';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import MoreTimeIcon from '@mui/icons-material/MoreTime';
import MoveToInboxIcon from '@mui/icons-material/MoveToInbox';
import PrecisionManufacturingIcon from '@mui/icons-material/PrecisionManufacturing';
import Settings from '@mui/icons-material/Settings';
import React, { ReactElement } from 'react';
import CRMIcon from '../components/Custom Icons/CRMIcon/CRMIcon';
import ProcurementIcon from '../components/Custom Icons/ProcurementIcon/ProcurementIcon';

const menuIcons: Record<string, ReactElement> = {
  accounting: <AccountBalanceIcon />,
  crm: <CRMIcon />,
  engineering: <EngineeringIcon />,
  manufacturing: <PrecisionManufacturingIcon />,
  my_time_tracker: <AccessTimeRoundedIcon />,
  procurement_main: <ProcurementIcon />,
  receiving: <MoveToInboxIcon />,
  settings: <Settings />,
  time: <MoreTimeIcon />,
  users_and_permissions: <GroupAddIcon />,
};

export default menuIcons;
