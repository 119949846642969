/* eslint-disable no-unused-vars */
import { Box, TextField, Theme } from '@mui/material';
import { useFormik } from 'formik';
import React, { useContext } from 'react';
import * as yup from 'yup';
import LockIcon from '@mui/icons-material/Lock';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { ETOButton, MessageContext } from '@teto/react-component-library-v2';
import { useTranslation } from 'react-i18next';

type ChangePasswordValues = {
  userName: string;
  oldPassword: string;
  newPassword: string;
  newPasswordConfirmation: string;
  canChangeUserName: boolean;
};

const formSx = (theme: Theme) => ({
  alignItems: 'center',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  position: 'relative',
  width: '100%',
  [theme.breakpoints.down('md')]: {
    width: theme.spacing(36),
  },
  [theme.breakpoints.up('sm')]: {
    width: theme.spacing(40),
  },
});

const textInputSx = (theme: Theme) => ({
  marginTop: theme.spacing(2),
  marginBottom: theme.spacing(3),
  position: 'relative',
  '& .MuiOutlinedInput-input': {
    paddingLeft: theme.spacing(1),
  },
});

const helperTextSx = (theme: Theme) => ({
  position: 'absolute',
  top: theme.spacing(7),
});

const validationSchema = yup.object({
  userName: yup.string().when('canChangeUserName', {
    is: true,
    then: yup.string().required('Required'),
  }),
  oldPassword: yup.string().required('Required'),
  newPassword: yup.string().required('Required'),
  newPasswordConfirmation: yup
    .string()
    .oneOf([yup.ref('newPassword'), null], 'Does not match')
    .required('Required'),
});

interface ChangePasswordFormProps {
  // eslint-disable-next-line no-unused-vars
  onChangePassword: (
    oldPassword: string,
    newPassword: string,
    newPasswordConfirmation: string,
    userName?: string
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ) => Promise<any>;
  showUserName: boolean;
  userName?: string;
  captions?: {
    userNameLabel?: string;
    oldPasswordLabel?: string;
    newPasswordLabel?: string;
    newPasswordConfirmLabel?: string;
    changePassword?: string;
  };
  onPasswordChanged: () => void;
}

const ChangePasswordForm: React.FC<ChangePasswordFormProps> = (props) => {
  const {
    onChangePassword,
    onPasswordChanged,
    captions,
    userName,
    showUserName,
  } = props;

  const { t } = useTranslation();

  const messageContext = useContext(MessageContext);

  const formik = useFormik<ChangePasswordValues>({
    initialValues: {
      canChangeUserName: Boolean(showUserName),
      userName: userName ?? '',
      oldPassword: '',
      newPassword: '',
      newPasswordConfirmation: '',
    },
    validationSchema,
    onSubmit: (values, actions) =>
      onChangePassword(
        values.oldPassword,
        values.newPassword,
        values.newPasswordConfirmation,
        values.userName
      )
        .then((res) => {
          const { changeExpiredPassword } = res.data;
          if (changeExpiredPassword) {
            messageContext.setSuccess(t('forms.changePassword.successMessage'));
            onPasswordChanged();
          }
          if (Object.prototype.hasOwnProperty.call(res, 'errors')) {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const errs = res.errors.map((r: any) => ({
              [r.extensions.field]: r.message,
            }));
            actions.setErrors(errs[0]);
          }
          actions.setSubmitting(false);
        })
        .catch((e) => {
          actions.setSubmitting(false);

          if (Object.prototype.hasOwnProperty.call(e, 'errors')) {
            actions.setErrors(e.errors);
          } else {
            throw e;
          }
        }),
  });

  return (
    <Box component="form" onSubmit={formik.handleSubmit} sx={formSx}>
      {showUserName && (
        <TextField
          disabled
          error={formik.touched.userName && Boolean(formik.errors.userName)}
          FormHelperTextProps={{
            sx: { helperTextSx },
          }}
          fullWidth
          helperText={formik.touched.userName && formik.errors.userName}
          id="changePassword-userName"
          InputProps={{
            startAdornment: (
              <AccountCircleIcon
                color={`${
                  formik.touched.userName && Boolean(formik.errors.userName)
                    ? 'error'
                    : 'primary'
                }`}
              />
            ),
          }}
          label={captions?.userNameLabel ?? 'Username'}
          name="userName"
          onChange={formik.handleChange}
          sx={textInputSx}
          value={formik.values.userName}
          variant="outlined"
        />
      )}
      <TextField
        error={formik.touched.oldPassword && Boolean(formik.errors.oldPassword)}
        FormHelperTextProps={{
          sx: { helperTextSx },
        }}
        fullWidth
        helperText={formik.touched.oldPassword && formik.errors.oldPassword}
        id="changePassword-oldPassword"
        InputProps={{
          startAdornment: (
            <LockIcon
              color={`${
                formik.touched.oldPassword && Boolean(formik.errors.oldPassword)
                  ? 'error'
                  : 'primary'
              }`}
            />
          ),
        }}
        label={captions?.oldPasswordLabel ?? 'Old Password'}
        name="oldPassword"
        onChange={formik.handleChange}
        sx={textInputSx}
        type="password"
        value={formik.values.oldPassword}
        variant="outlined"
      />

      <TextField
        error={formik.touched.newPassword && Boolean(formik.errors.newPassword)}
        FormHelperTextProps={{
          sx: { helperTextSx },
        }}
        fullWidth
        helperText={formik.touched.newPassword && formik.errors.newPassword}
        id="changePassword-newPassword"
        InputProps={{
          startAdornment: (
            <LockIcon
              color={`${
                formik.touched.newPassword && Boolean(formik.errors.newPassword)
                  ? 'error'
                  : 'primary'
              }`}
            />
          ),
        }}
        label={captions?.newPasswordLabel ?? 'New Password'}
        name="newPassword"
        onChange={formik.handleChange}
        sx={textInputSx}
        type="password"
        value={formik.values.newPassword}
        variant="outlined"
      />

      <TextField
        error={
          formik.touched.newPasswordConfirmation &&
          Boolean(formik.errors.newPasswordConfirmation)
        }
        FormHelperTextProps={{
          sx: { helperTextSx },
        }}
        fullWidth
        helperText={
          formik.touched.newPasswordConfirmation &&
          formik.errors.newPasswordConfirmation
        }
        id="changePassword-newPasswordConfirmation"
        InputProps={{
          startAdornment: (
            <LockIcon
              color={`${
                formik.touched.newPasswordConfirmation &&
                Boolean(formik.errors.newPasswordConfirmation)
                  ? 'error'
                  : 'primary'
              }`}
            />
          ),
        }}
        label={captions?.newPasswordConfirmLabel ?? 'Confirm New Password'}
        name="newPasswordConfirmation"
        onChange={formik.handleChange}
        sx={textInputSx}
        type="password"
        value={formik.values.newPasswordConfirmation}
        variant="outlined"
      />

      <ETOButton
        buttonProps={{
          variant: 'contained',
          fullWidth: true,
        }}
        color="primary"
        loading={formik.isSubmitting}
        size="medium"
        type="submit"
      >
        {captions?.changePassword ?? 'Change Password'}
      </ETOButton>
    </Box>
  );
};

export default ChangePasswordForm;
