import { gql } from '../../../__generated__';
import SelectDefinition from './SelectDefinition';

export default {
  query: gql(`
    query getViaShippers {
      shippers {
        items {
          id
          shipperName
        }
      }
    }
  `),
  valueField: 'shipperName',
  labelField: 'shipperName',
} as SelectDefinition;
