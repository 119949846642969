import { Box, SxProps, Theme } from '@mui/material';
import { Inspector } from '@teto/react-component-library-v2';
import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import useLocalStorage from 'use-local-storage';
import Tab from '../../CustomTabs/interfaces/Tab';

import BOMWarning from './component/BOMWarning/BOMWarning';
import PartSummary from '../components/PartSummary/PartSummary';
import PartDetails from '../components/PartDetails/PartDetails';
import PartBreakdownGrid from '../components/PartBreakdownGrid/PartBreakdownGrid';
import InpectorSelectTab from '../components/InspectorSelectTab/InpectorSelectTab';
import InspectorTabPanel from './component/InspectorTabPanel/InspectorTabPanel';
import { ProcBreakdownInspectorProps } from './ProcBreakdownInspectorProps';
import ReservedPulledInventoryDistribution from '../../../views/ReservedPulledInventoryDistributionView.yaml';
import IssuedProcessSchedulesDistribution from '../../../views/IssuedProcessSchedulesDistributionView.yaml';
import BOMPartsOrderReleasesDistribution from '../../../views/BOMPartsOrderReleasesDistributionView.yaml';
import PurchaseDistribution from '../../../views/PurchaseDistributionView.yaml';
import NonBOMDistribution from '../../../views/NonBOMDistributionView.yaml';
import BOMDistribution from '../../../views/BOMDistributionView.yaml';

const containerSx: SxProps<Theme> = {
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  justifyContent: 'flex-start',
  m: 0,
  p: 0,
  rowGap: 1,
  width: '100%',
};

const ProcBreakdownInspector = (props: ProcBreakdownInspectorProps) => {
  const { renderAsInspector, open, onClose, detail, ignoreLocation } = props;
  const { t } = useTranslation();
  const [selectedTabIndex, setSelectedTabIndex] = useLocalStorage(
    'proc-breakdown-selected-tab',
    0
  );

  const isOpen = useMemo(() => open && detail !== undefined, [detail, open]);

  const destInventoryLocationId = useMemo(() => {
    if (detail?.destInventoryLocId) {
      return detail?.destInventoryLocId;
    }
    if (detail?.destInventoryLocation?.id) {
      return detail?.destInventoryLocation?.id;
    }
    return 0;
  }, [detail]);

  const variables = useMemo(
    () => ({
      projectId: {
        value: detail?.projectId,
        type: 'Int!',
      },
      specId: {
        value: detail?.specId,
        type: 'Float!',
      },
      itemId: {
        value: detail?.itemId,
        type: 'Int!',
      },
      inventoryLocationId: {
        value: ignoreLocation ? 0 : detail?.inventoryLocationId,
        type: 'Int!',
      },
      processScheduleId: {
        value: detail?.processScheduleId,
        type: 'Int',
      },
    }),
    [
      detail?.projectId,
      detail?.specId,
      detail?.itemId,
      detail?.inventoryLocationId,
      detail?.processScheduleId,
      ignoreLocation,
    ]
  );

  const tabs: Tab[] = useMemo(
    () => [
      {
        index: 0,
        name: t('pages.procurementBreakDown.tabs.summary'),
        component: (
          <PartSummary detail={detail} ignoreLocation={ignoreLocation} />
        ),
      },
      {
        index: 1,
        name: t('pages.procurementBreakDown.tabs.partDetails'),
        component: (
          <PartDetails detail={detail} ignoreLocation={ignoreLocation} />
        ),
      },
      {
        index: 2,
        name: t('pages.procurementBreakDown.tabs.bomDistribution'),
        component: !ignoreLocation ? (
          <BOMWarning />
        ) : (
          <PartBreakdownGrid
            queryVariables={variables}
            rootQueryName="engItemMasterDistributionByItem.items"
            viewName={BOMDistribution}
          />
        ),
      },
      {
        index: 3,
        name: t('pages.procurementBreakDown.tabs.nonBOM'),
        component: (
          <PartBreakdownGrid
            queryVariables={variables}
            rootQueryName="purchasingNonBOMsByItem.items"
            viewName={NonBOMDistribution}
          />
        ),
      },
      {
        index: 4,
        name: t('pages.procurementBreakDown.tabs.purchased'),
        component: (
          <PartBreakdownGrid
            queryVariables={variables}
            rootQueryName="purchasingItemDetailsPurchased.items"
            viewName={PurchaseDistribution}
          />
        ),
      },
      {
        index: 5,
        name: t('pages.procurementBreakDown.tabs.reservedPulledInventory'),
        component: (
          <PartBreakdownGrid
            queryVariables={{
              ...variables,
              inventoryLocationId: {
                value: 0,
                type: 'Int!',
              },
            }}
            rootQueryName="purchasingItemDetailsInventory.items"
            viewName={ReservedPulledInventoryDistribution}
          />
        ),
      },
      {
        index: 6,
        name: t('pages.procurementBreakDown.tabs.issuedProcessSchedules'),
        component: (
          <PartBreakdownGrid
            queryVariables={variables}
            rootQueryName="purchasingItemDetailsProcessSchedules.items"
            viewName={IssuedProcessSchedulesDistribution}
          />
        ),
      },
      {
        index: 7,
        name: t('pages.procurementBreakDown.tabs.bomPartsOrderRelease'),
        component: (
          <PartBreakdownGrid
            queryVariables={variables}
            rootQueryName="bOMReleaseHistoryByItem.items"
            viewName={BOMPartsOrderReleasesDistribution}
          />
        ),
      },
    ],
    [t, detail, ignoreLocation, destInventoryLocationId, variables]
  );

  const _renderChildComponent = useMemo(
    () => (
      <Box sx={containerSx}>
        <InpectorSelectTab
          currentTab={selectedTabIndex}
          onTabChanged={(v) => setSelectedTabIndex(v)}
          tabs={tabs}
        />
        {tabs.map((v) => (
          <InspectorTabPanel
            index={v.index}
            key={v.name}
            value={selectedTabIndex}
          >
            {v.component}
          </InspectorTabPanel>
        ))}
      </Box>
    ),

    [selectedTabIndex, setSelectedTabIndex, tabs]
  );

  const getItemCompanyId = useCallback(() => {
    if (detail?.itemCompanyId) {
      return detail?.itemCompanyId ?? '';
    }

    if (detail?.child) {
      return detail?.child?.itemCompanyId ?? '';
    }

    if (detail?.item) {
      return detail?.item?.itemCompanyId ?? '';
    }

    return '';
  }, [detail]);

  const getSpecID = useMemo(
    () => `${t('entities:Machine.Job')}: ${detail?.specId ?? ''}`,
    [detail?.specId, t]
  );

  const getPart = useMemo(
    () => `${t('entities:Part.Part')}: ${getItemCompanyId()}`,
    [getItemCompanyId, t]
  );

  const getProjID = useMemo(
    () => `${t('entities:Project.Project')}: ${detail?.projectId ?? ''}`,
    [detail?.projectId, t]
  );

  const getDestInvLocation = useMemo(
    () =>
      `${t('entities:ProcurementPurchasingSummaryDetail.DestInventoryLoc')}: ${
        detail?.destInventoryLoc?.name ?? ''
      }`,
    [detail?.destInventoryLoc?.name, t]
  );

  const getProcessScheduleID = useMemo(
    () =>
      detail?.processScheduleId
        ? `${t('entities:Process.Number')}: ${detail?.processScheduleId}`
        : '',
    [detail?.processScheduleId, t]
  );

  const renderTitle = useMemo(() => {
    if (detail?.destInventoryLocId) {
      return `${getDestInvLocation} ${getPart}`;
    }

    if (detail?.processScheduleId) {
      return `${getProjID} ${getSpecID} ${getProcessScheduleID} ${getPart}`;
    }

    return `${getProjID} ${getSpecID} ${getProcessScheduleID} ${getPart}`;
  }, [
    detail?.destInventoryLocId,
    detail?.processScheduleId,
    getDestInvLocation,
    getPart,
    getProcessScheduleID,
    getProjID,
    getSpecID,
  ]);

  return (
    <>
      {isOpen && (
        <>
          {renderAsInspector && (
            <Inspector
              onClose={onClose}
              open={isOpen}
              title={{ text: renderTitle }}
            >
              {_renderChildComponent}
            </Inspector>
          )}
          {!renderAsInspector && _renderChildComponent}
        </>
      )}
    </>
  );
};

export default ProcBreakdownInspector;
