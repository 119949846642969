import { gql } from '../../../../__generated__';

const getPurchasingItemDetailsSummaryByItemQuery =
  gql(`query getPurchasingItemDetailsSummaryByItemQuery(
        $projectId: Int!
        $specId: Float!
        $itemId: Int!
        $inventoryLocationId: Int!
        $destinationLocationId: Int
        $processScheduleId: Int
  ) {
      purchasingItemDetailsSummaryByItem(
        projectId: $projectId
        specId: $specId
        itemId: $itemId
        inventoryLocationId: $inventoryLocationId
        destinationLocationId: $destinationLocationId
        processScheduleId: $processScheduleId
        skip: 0
        take: 10
        order: { itemId: DESC }
  ) {
    items {
      availableQty
      bOM
      completedPSQty
      countryOfOrigin
      destInventoryLoc
      drawing
      estimatedLeadTime
      hasDrawing
      inventory
      itemCategory
      itemCategoryDescription
      itemCertifiedPrints
      itemCompanyId
      itemDescription
      itemId
      itemLastCost
      itemListCost
      itemMaintenance
      itemRelub
      itemRepairable
      itemReserved
      itemRevNumber
      itemUOM
      itemWeight
      lastSupplierCompanyId
      locationName
      manufacturer
      manufacturerPartNumber
      mfgBegin
      multipleDrawing
      multipleDrawingAddress
      nonBOM
      obsolete
      onHand
      openQty
      openWorkOrders
      orderToInventory
      outstandingQty
      partCustom1
      partCustom2
      partCustom3
      partCustom4
      partCustom5
      partCustom6
      partCustom7
      partCustom8
      preferredSupplier
      preferredSupplierCompanyId
      processId
      processSchedule
      processScheduled
      processScheduleTemplateId
      procured
      projectId
      pulledQty
      purchaseUom
      qtyRequiredForAllProjects
      rawMaterialCutLength
      rawMaterialItemID
      receivedQty
      required
      reservedQty
      rFQs
      specId
      supplierPartNumber
      tariffCode
      unreceivedQty
      uOMDescription
    }
  }
}
`);

export default getPurchasingItemDetailsSummaryByItemQuery;
