import _ from 'lodash';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const deepPropertyHelper = (name: string, val: any) => {
  if (typeof val === 'object') {
    const res = _?.get(val, name);
    if (typeof res === 'object') {
      return undefined;
    }
    return res;
  }

  return val;
};

export default deepPropertyHelper;
