import { Dayjs } from 'dayjs';
import { NonConformance } from 'teto-client-api';

type CustomFields =
  | 'custom1'
  | 'custom2'
  | 'custom3'
  | 'custom4'
  | 'custom5'
  | 'custom6'
  | 'custom7'
  | 'custom8';

const defaultNCCustomFieldCaptions = {
  custom1: 'NonConformance Custom 1',
  custom2: 'NonConformance Custom 2',
  custom3: 'NonConformance Custom 3',
  custom4: 'NonConformance Custom 4',
  custom5: 'NonConformance Custom 5',
  custom6: 'NonConformance Custom 6',
  custom7: 'NonConformance Custom 7',
  custom8: 'NonConformance Custom 8',
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const getFormikInitialValues = (data: any) => ({
  id: data?.id ?? undefined,
  title: data?.title ?? '',
  sourceId: data?.sourceId ?? undefined,
  originId: data?.originId ?? undefined,
  projectId: data?.projectId ?? null,
  specId: data?.specId ?? undefined,
  customer: data?.customer ?? '',
  quantity: data?.quantity ?? undefined,
  quantityRejected: data?.quantityRejected ?? undefined,
  purchaseOrder: {
    id: data?.purchaseOrder?.id ?? undefined,
    purchaseSupplier: {
      name: data?.purchaseOrder?.purchaseSupplier?.name ?? '',
    },
  },
  purchaseOrderId: data?.purchaseOrderId ?? undefined,
  itemId: data?.itemId ?? undefined,
  partNumber: data?.viewDetails?.partNumber ?? '',
  item: {
    id: data?.item?.id ?? undefined,
    itemCompanyId: data?.item?.itemCompanyId ?? '',
  },
  description: data?.description ?? '',
  rootCause: data?.rootCause ?? '',
  custom1: data?.custom1 ?? '',
  custom2: data?.custom2 ?? '',
  custom3: data?.custom3 ?? undefined,
  custom4: data?.custom4 ?? undefined,
  custom5: data?.custom5 ?? undefined,
  custom6: data?.custom6 ?? undefined,
  custom7: data?.custom7 ?? false,
  custom8: data?.custom8 ?? false,
  resolved: data?.resolved ?? false,
});

const getPropsInitialValues = (
  data: Partial<NonConformance> & {
    supplier: string;
    customer: string;
    purchaseOrder?: {
      id: number;
      purchaseSupplier: {
        id: number;
        name: string;
      };
    };
    viewDetails: { partNumber: string };
    item: {
      id?: number;
      itemCompanyId?: number;
    };
    creationDate?: Dayjs;
  }
) => ({
  id: data?.id ?? undefined,
  title: data?.title ?? '',
  creationDate: data?.creationDate ?? undefined,
  sourceId: data?.sourceId ?? undefined,
  originId: data?.originId ?? undefined,
  projectId: data?.projectId ?? -1,
  specId: data?.specId ?? -1,
  customer: data?.customer ?? '',
  quantity: data?.quantity ?? undefined,
  quantityRejected: data?.quantityRejected ?? undefined,
  purchaseOrderId: data?.purchaseOrderId ?? undefined,
  itemId: data?.itemId ?? undefined,
  partNumber: data?.viewDetails?.partNumber ?? '',
  item: data?.item
    ? {
        id: data?.item?.id ?? undefined,
        itemCompanyId: data?.item?.itemCompanyId ?? '',
      }
    : undefined,
  purchaseOrder: data?.purchaseOrder
    ? {
        id: data?.purchaseOrder?.id ?? undefined,
        purchaseSupplier: {
          name: data?.purchaseOrder?.purchaseSupplier?.name ?? '',
        },
      }
    : undefined,
  description: data?.description ?? '',
  correctivePreventiveAction: data?.correctivePreventiveAction ?? '',
  qualityFollowUp: data?.qualityFollowUp ?? '',
  recommendedInterim: data?.recommendedInterim ?? '',
  rootCause: data?.rootCause ?? '',
  custom1: data?.custom1 ?? '',
  custom2: data?.custom2 ?? '',
  custom3: data?.custom3 ?? undefined,
  custom4: data?.custom4 ?? undefined,
  custom5: data?.custom5 ?? undefined,
  custom6: data?.custom6 ?? undefined,
  custom7: data?.custom7 ?? false,
  custom8: data?.custom8 ?? false,
  resolved: data?.resolved ?? false,
  supplier: data?.supplier ?? '',
});

const formatNCUpdateMutation = (data: Record<string, unknown>) => ({
  correctivePreventiveAction: data?.correctivePreventiveAction ?? '',
  custom1: data?.custom1 ?? '',
  custom2: data?.custom2 ?? '',
  custom3: data?.custom3 ?? undefined,
  custom4: data?.custom4 ?? undefined,
  custom5: data?.custom5 ?? undefined,
  custom6: data?.custom6 ?? undefined,
  custom7: data?.custom7 ?? false,
  custom8: data?.custom8 ?? false,
  description: data?.description ?? '',
  id: data?.id ?? undefined,
  itemId: data?.itemId ?? undefined,
  originId: data?.originId ?? undefined,
  purchaseOrderId: data?.purchaseOrderId ?? undefined,
  quantity: data?.quantity ?? undefined,
  qualityFollowUp: data?.qualityFollowUp ?? '',
  quantityRejected: data?.quantityRejected ?? undefined,
  recommendedInterim: data?.recommendedInterim ?? '',
  resolved: data?.resolved ?? false,
  released: data?.released ?? undefined,
  rootCause: data?.rootCause ?? '',
  sourceId: data?.sourceId ?? undefined,
  title: data?.title ?? '',
});

const formatNCCreateValues = (data: Record<string, unknown>) => ({
  ...formatNCUpdateMutation(data),
  projectId: data?.projectId ?? -1,
  specId: data?.specId ?? -1,
});

const isCustomCaption = (field: CustomFields, caption: string) =>
  defaultNCCustomFieldCaptions[field] !== caption;

export {
  getFormikInitialValues,
  getPropsInitialValues,
  formatNCUpdateMutation,
  formatNCCreateValues,
  isCustomCaption,
};
