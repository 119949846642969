import React, { forwardRef, useRef } from 'react';
import {
  Autocomplete,
  TextField,
  Chip,
  SxProps,
  Theme,
  InputLabelProps,
  AutocompleteProps,
  AutocompleteChangeReason,
} from '@mui/material';
import { v4 as uuid } from 'uuid';
import { uniqueId } from 'lodash';

export interface ETOEmailFieldProps<T>
  extends Omit<
    AutocompleteProps<
      T,
      boolean | undefined,
      boolean | undefined,
      boolean | undefined
    >,
    'onFocus' | 'onBlur' | 'renderInput' | 'options'
  > {
  autoCompleteSx?: SxProps<Theme>;
  autoFocus?: boolean;
  customSx?: SxProps<Theme>;
  defaultValue?: string[];
  error?: string;
  helperText?: string;
  inputLabelProps?: InputLabelProps;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  inputRef?: React.Ref<any>;
  items: string[];
  label?: string;
  loading?: boolean;
  name: string;
  value?: string[];
  variant?: 'filled' | 'standard';
  // eslint-disable-next-line no-unused-vars
  handleChange: (value: string[]) => void;
  onFocus?: React.FocusEventHandler<HTMLTextAreaElement | HTMLInputElement>;
  onBlur?: React.FocusEventHandler<HTMLTextAreaElement | HTMLInputElement>;
}

// eslint-disable-next-line react/display-name, @typescript-eslint/no-explicit-any
const ETOEmailField = forwardRef<any, ETOEmailFieldProps<any>>(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  <T extends object | string>(props: ETOEmailFieldProps<T>, ref: any) => {
    const {
      autoCompleteSx,
      autoFocus,
      customSx,
      defaultValue,
      disableClearable,
      disabled,
      error,
      freeSolo,
      handleChange,
      helperText,
      id,
      inputLabelProps,
      inputRef,
      isOptionEqualToValue,
      items,
      label,
      loading,
      name,
      onBlur,
      onFocus,
      onInputChange,
      onKeyDown,
      PaperComponent,
      placeholder = '',
      popupIcon,
      renderOption,
      size = 'small',
      value,
      variant,
      ...otherProps
    } = props;

    const isValidEmail = (email: string) => {
      const emailRegex = /^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/;
      return emailRegex.test(email.trim());
    };

    const handleAddEmail = (
      event: React.SyntheticEvent<Element, Event>,
      newValue: (string | T)[],
      reason: AutocompleteChangeReason
    ) => {
      if (reason === 'selectOption' || reason === 'createOption') {
        const newEmail = newValue[newValue.length - 1] as string;

        if (
          isValidEmail(newEmail) &&
          value !== null &&
          value !== undefined &&
          !value.includes(newEmail)
        ) {
          handleChange([...value, newEmail]);
        }
      } else if (reason === 'removeOption') {
        handleChange(newValue as string[]);
      }
    };

    const idRef = useRef(id ?? uuid());

    return (
      <Autocomplete
        {...otherProps}
        defaultValue={defaultValue ?? []}
        disableClearable={disableClearable}
        disabled={disabled}
        disabledItemsFocusable={false}
        freeSolo
        fullWidth
        id={idRef.current}
        multiple
        onChange={handleAddEmail}
        options={[]}
        ref={ref}
        renderInput={(params) => (
          <TextField
            {...params}
            autoFocus={autoFocus}
            disabled={disabled}
            error={Boolean(error)}
            helperText={error || helperText}
            InputLabelProps={{ shrink: true }}
            label={label}
            name={name}
            placeholder={placeholder}
            size={size}
            variant={variant ?? 'outlined'}
          />
        )}
        renderTags={(vals: (string | T)[], getTagProps) =>
          vals.map((option: string | T, index: number) => {
            if (typeof option === 'string') {
              return (
                <Chip
                  {...getTagProps({ index })}
                  label={option}
                  size="small"
                  variant="outlined"
                />
              );
            }
            // eslint-disable-next-line react/jsx-key
            return <div key={uniqueId()} style={{ display: 'none' }} />;
          })
        }
        value={value}
      />
    );
  }
);

export default ETOEmailField;
