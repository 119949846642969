import React, { useCallback, useContext, useEffect, useState } from 'react';
import AttachFileRoundedIcon from '@mui/icons-material/AttachFileRounded';
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  IconButton,
  Link,
  List,
  ListItem,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded';
import {
  ETOButton,
  Logo,
  MessageContext,
} from '@teto/react-component-library-v2';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  AuthorizationError,
  ValidationError,
  getGraphQLClient,
} from 'teto-client-api';
import ReactDataGrid from '@inovua/reactdatagrid-enterprise';
import { useTranslation } from 'react-i18next';
import { stateToHTML } from 'draft-js-export-html';
import { EditorState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import { useDropzone } from 'react-dropzone';
import { TypeRowSelection } from '@inovua/reactdatagrid-community/types';
import EmailReportForm from '../../forms/EmailReportForm.yaml';
import { useFormBuilder } from '../TETOForms/hooks/useFormBuilder';
import useForm from '../TETOForms/hooks/useForm';
import TETOForm from '../TETOForms/TETOForm';
import poExportReportQuery from '../Modals/PurchaseOrderModal/queries/poExportReportQuery';
import useGridStyles from '../TetoGrid/GridStyles';
import { reactDataGridLicenseKey } from '../TetoGrid/Licensing';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import AuthContext from '../../contexts/AuthContext';
import pODQuery from './queries/pODQuery';
import drawingsQuery from './queries/drawingsQuery';
import {
  actionButtonSx,
  attachInfoSx,
  attachInfoWrapperSx,
  editorErrorSx,
  footerSx,
  formSx,
  headerSx,
  iFrameWrapperSx,
  listItemSx,
  listSx,
  logoSx,
  noPDFStylesSx,
  wrapperSx,
} from './reportViewerStyles';
import rFQDQuery from './queries/rFQDQuery';
import exportReportQuery from '../Inspectors/ReportInspector/exportReportQuery';
import FormDefinition from '../TETOForms/FormDefinition';
import IEngItemMasterDrawing from './interfaces/IEngItemMasterDrawing';
import FileTypeIcon from './components/FileTypeIcon';
import { EngItemMasterDrawing } from '../../__generated__/graphql';
import SQLError from './classes/SQLError';
import NotFoundError from './classes/NotFoundError';
import emailRoutes from './constants/emailRoutes';
import { parseServerResponse } from '../../helpers/validationHelperREST';
import EmailDrawingDialog from './components/EmailDrawingDialog';
import getReportEmailDefaults from './queries/getReportEmailDefaults';
import { gql } from '../../__generated__/gql';

const gridStyle = { minHeight: 550 };

const getEntityType = (reportType: string) => {
  switch (reportType) {
    case 'purchaseOrder':
      return 'PO';
    case 'rFQ':
      return 'RFQ';
    default:
      return '';
  }
};

interface ViewContent {
  title: string;
  content: React.ReactNode;
  actions: React.ReactNode;
}

interface ViewType {
  [key: string]: ViewContent;
}

const PurchaseOrderPrintedMutation =
  gql(`mutation markPOAsPrinted($purchaseOrderId: Int!) {
  markPOAsPrinted(input: { purchaseOrderId: $purchaseOrderId }) {
    success
  }
}`);

const ReportViewer = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const { t, ready } = useTranslation();

  const { acceptedFiles, getRootProps, getInputProps, open } = useDropzone({
    onDrop: (filesToAccept) => {
      setFiles((prevFiles) => [...prevFiles, ...filesToAccept]);
      messageContext.setSuccess(t('generic.message.fileUploadSuccess'));
    },
  });

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const authContext = useContext(AuthContext);
  const messageContext = useContext(MessageContext);

  document.title = 'TotalETO - Report Viewer';

  const searchParams = new URLSearchParams(location.search);

  const hasItems = searchParams.get('hasItems');
  const includeCompanyInformation = searchParams.get('companyDetails');
  const pdfPath = searchParams.get('url');
  const reportId = searchParams.get('reportId');
  const entityId = searchParams.get('entityId');
  const alternateId = searchParams.get('alternateId');
  const reportType = searchParams.get('type');
  const reportParams = searchParams.get('reportParams');

  const decodedReportParams = reportParams
    ? JSON.parse(decodeURIComponent(reportParams))
    : null;

  const disableEmail = !authContext.user?.email;
  const showEmailWithDrawings =
    reportType === 'purchaseOrder' || reportType === 'rFQ' || hasItems;

  const [checked, setChecked] = useState<boolean>(true);
  const [dataSource, setDataSource] = useState<IEngItemMasterDrawing[]>([]);
  const [dialogView, setDialogView] = useState({
    name: 'emailView',
    showBack: false,
  });
  const [isEmailSending, setIsEmailSending] = useState<boolean>(false);
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [items, setItems] = useState<number[]>([]);
  const [openEmailDrawing, setOpenEmailDrawing] = useState(false);
  const [selected, setSelected] = useState<TypeRowSelection>({});
  const [files, setFiles] = useState<File[]>([]);
  const [showPDF, setShowPDF] = useState<boolean>(true);
  const [iFramePDFPath, setIFramePDFPath] = useState(pdfPath);

  const _handleDownloadFile = async (fileName: string, path: string) => {
    const url = '/api/SharedFiles/getFile';
    try {
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(path),
      });

      if (response.ok) {
        const blob = await response.blob();
        const downloadUrl = window.URL.createObjectURL(blob);
        const link = document.createElement('a');

        link.href = downloadUrl;
        link.setAttribute('download', fileName);
        document.body.appendChild(link);
        link.click();
        link.parentNode?.removeChild(link);
        window.URL.revokeObjectURL(downloadUrl);
      } else {
        if (response.status === 400) {
          throw new Error(await response.text());
        }
        if (response.status === 500) {
          throw new Error(await response.text());
        }
      }

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      parseServerResponse(
        e,
        (errors) => messageContext.setError(Object.values(errors)[0]),
        (error) => {
          messageContext.setError(error.message ?? error);
        }
      );
    }
  };

  const onSelectionChange =
    // eslint-disable-next-line no-shadow, @typescript-eslint/no-explicit-any
    ({ selected }: { selected: any }) => {
      setSelected(selected);
    };

  // eslint-disable-next-line no-shadow
  const handleDefaultEmail = (defaultEmail: string | null) => {
    if (!defaultEmail) {
      return [];
    }

    const emails = defaultEmail?.split(',').map((e) => e.trim());
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const validEmails = emails.filter((e) => emailRegex.test(e));
    return validEmails;
  };

  const emailPDFFormBuilder = useFormBuilder(
    'email-pdf-form',
    (fb) =>
      fb
        .addEmail({
          name: 'sendTo',
          title: t('generic.to'),
          required: true,
        })
        .addString({
          name: 'subject',
          title: t('generic.subject'),
          required: true,
        })
        .addEmail({
          name: 'cc',
          title: 'CC',
          defaultValue: [],
        })
        .addEmail({
          name: 'bCC',
          title: 'BCC',
          defaultValue: authContext?.user?.email
            ? [authContext?.user?.email]
            : [],
        })
        .addField({
          name: 'message',
          title: 'Message',
          type: 'string',
          hidden: true,
          required: true,
        }),
    {}
  );

  const getPurchaseOrderDetails = () =>
    getGraphQLClient()
      .performQuery(pODQuery, { id: parseInt(entityId ?? '', 10) })
      .then((d) => {
        if (d.hasError()) {
          d.showAllSystemErrors(messageContext.setError);
          if (d.hasValidationErrors()) {
            const { input } = d.validationErrors;
            messageContext.setError(Object.values(input)[0] as string);
          }
          return;
        }

        if (d.data.purchaseOrderDetails?.items) {
          const itemIds = d.data.purchaseOrderDetails.items
            .map((item) => item?.itemId)
            .filter((itemId): itemId is number => itemId !== undefined);
          setItems(itemIds);
        }
      });

  const getRFQDetails = () =>
    getGraphQLClient()
      .performQuery(rFQDQuery, { id: parseInt(entityId ?? '', 10) })
      .then((d) => {
        if (d.hasError()) {
          d.showAllSystemErrors(messageContext.setError);
          if (d.hasValidationErrors()) {
            const { input } = d.validationErrors;
            messageContext.setError(Object.values(input)[0] as string);
          }
          return;
        }

        if (d.data.rFQDetails?.items) {
          const itemIds = d.data.rFQDetails.items
            .map((item) => item?.itemId)
            .filter((itemId): itemId is number => itemId !== undefined);
          setItems(itemIds);
        }
      });

  useEffect(() => {
    if (items && items.length > 0) {
      getGraphQLClient()
        .performQuery(drawingsQuery, { drawingIds: items })
        .then((d) => {
          if (d.hasError()) {
            d.showAllSystemErrors(messageContext.setError);
            if (d.hasValidationErrors()) {
              const { input } = d.validationErrors;
              messageContext.setError(Object.values(input)[0] as string);
            }
            return;
          }

          if (d.data.engItemMasterDrawings?.items) {
            const mappedData = d.data.engItemMasterDrawings.items
              .filter((item): item is EngItemMasterDrawing => item !== null)
              .map((it: EngItemMasterDrawing) => ({
                ...it,
                path: it?.drawing.split('#')[1],
                itemCompanyId: it?.item.itemCompanyId,
                title: it?.drawing.split('#')[0],
              }));
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const allSelected = mappedData.reduce((acc: any, row: any) => {
              if (row.id) {
                acc[row.id] = true;
              }
              return acc;
            }, {});
            setDataSource(mappedData);
            setSelected(allSelected);
            setOpenEmailDrawing(true);
            setDialogView({ name: 'drawingsView', showBack: true });
          }
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [items]);

  const emailPDFForm: FormDefinition = useForm(
    emailPDFFormBuilder,
    {
      // eslint-disable-next-line no-unused-vars
      onSubmitting: (fb, fd, data) => _handleSendEmail(data),
    },
    {}
  );

  useEffect(() => {
    if (entityId && reportType && openEmailDrawing) {
      getGraphQLClient()
        .performQuery(getReportEmailDefaults, {
          entityId: parseInt(entityId || '', 10),
          entityType: getEntityType(reportType ?? ''),
        })
        .then((d) => {
          if (d.hasError()) {
            d.showAllSystemErrors(messageContext.setError);
            if (d.hasValidationErrors()) {
              const { input } = d.validationErrors;
              messageContext.setError(Object.values(input)[0] as string);
            }
            return;
          }
          if (d.hasData()) {
            emailPDFForm.updateField(
              'subject',
              d.data.reportEmailDefaults?.emailSubject
            );
            emailPDFForm.updateField(
              'sendTo',
              handleDefaultEmail(d.data.reportEmailDefaults?.emailAddress)
            );
          }
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [entityId, reportType, openEmailDrawing]);

  useEffect(() => {
    setAttachments((prev) => [...prev, ...acceptedFiles]);
  }, [acceptedFiles]);

  const onEditorStateChange = useCallback(
    // eslint-disable-next-line no-shadow
    (editorState: EditorState) => {
      setEditorState(editorState);
      const htmlContent = stateToHTML(editorState.getCurrentContent());
      if (emailPDFForm) {
        emailPDFForm.updateField('message', htmlContent);
      }
    },
    [emailPDFForm]
  );

  const _refreshPDF = useCallback(() => {
    if (reportType === 'purchaseOrder') {
      getGraphQLClient()
        .performQuery(poExportReportQuery, {
          id: parseInt(reportId ?? '', 10),
          companyDetails: true,
          input: {
            alternateId: alternateId ? parseInt(alternateId, 10) : null,
            parameters: {
              intPurchaseOrderID: entityId,
            },
          },
        })
        .then((d) => {
          if (d.hasError()) {
            d.showAllSystemErrors(messageContext.setError);
            if (d.hasValidationErrors()) {
              const { input } = d.validationErrors;
              messageContext.setError(Object.values(input)[0] as string);
            }
            return;
          }

          if (d.data.exportReport.path) {
            setIFramePDFPath(d.data.exportReport.path);
            setShowPDF(true);
          }
        });
    }
    if (reportType === 'rFQ') {
      getGraphQLClient()
        .performQuery(poExportReportQuery, {
          id: parseInt(reportId ?? '', 10),
          companyDetails: true,
          input: {
            alternateId: alternateId ? parseInt(alternateId, 10) : null,
            parameters: {
              intRFQID: entityId,
            },
          },
        })
        .then((d) => {
          if (d.hasError()) {
            d.showAllSystemErrors(messageContext.setError);
            if (d.hasValidationErrors()) {
              const { input } = d.validationErrors;
              messageContext.setError(Object.values(input)[0] as string);
            }
            return;
          }

          if (d.data.exportReport.path) {
            setIFramePDFPath(d.data.exportReport.path);
            setShowPDF(true);
          }
        });
    }
    if (reportType === 'generic') {
      getGraphQLClient()
        .performQuery(exportReportQuery, {
          id: parseInt(reportId ?? '', 10),
          companyDetails: includeCompanyInformation === 'true',
          input: {
            alternateId: alternateId ? parseInt(alternateId, 10) : null,
            parameters: decodedReportParams,
          },
        })
        .then((d) => {
          if (d.hasError()) {
            d.showAllSystemErrors(messageContext.setError);
            if (d.hasValidationErrors()) {
              const { input } = d.validationErrors;
              messageContext.setError(Object.values(input)[0] as string);
            }
            return;
          }

          if (d.data.exportReport.path) {
            setIFramePDFPath(d.data.exportReport.path);
            setShowPDF(true);
          }
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    alternateId,
    entityId,
    includeCompanyInformation,
    reportId,
    decodedReportParams,
    reportType,
  ]);

  const _printPDF = useCallback(() => {
    const iframe = document.getElementById('pdf-i-frame');
    if (iframe && iframe instanceof HTMLIFrameElement) {
      iframe.focus();
      iframe.contentWindow?.print();
      if (
        reportType === 'purchaseOrder' &&
        entityId &&
        !Number.isNaN(parseInt(entityId, 10))
      ) {
        const purchaseOrderId = parseInt(entityId, 10);
        getGraphQLClient()
          .performMutation(PurchaseOrderPrintedMutation, {
            purchaseOrderId,
          })
          .then((r) => {
            if (r.hasError()) r.handleAllErrors(messageContext.setError);
          });
      }
    }
  }, [entityId, messageContext.setError, reportType]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChecked(event.target.checked);
  };

  const gridStyles = useGridStyles(theme);

  const _handleEmailOpen = () => {
    setOpenEmailDrawing(true);
    setDialogView({ name: 'emailView', showBack: false });
  };

  const handleClose = () => {
    setOpenEmailDrawing(false);
    setAttachments([]);
    emailPDFForm.reset();
    setEditorState(EditorState.createEmpty());
    setDialogView({ name: 'emailView', showBack: false });
  };

  const switchView = (view: { name: string; showBack: boolean }) => {
    setDialogView(view);
  };

  const [attachments, setAttachments] = useState<
    (IEngItemMasterDrawing | File)[]
  >([...dataSource]);

  const _handleRemoveAttachment = (
    indexToRemove: number,
    attachment: File | IEngItemMasterDrawing
  ) => {
    setAttachments(attachments.filter((_, index) => index !== indexToRemove));

    setFiles((prev) =>
      prev.filter((f) => f !== attachment && attachment instanceof File)
    );
    if ('id' in attachment && typeof selected === 'object') {
      const updatedSelected = { ...selected };
      if (
        Object.prototype.hasOwnProperty.call(updatedSelected, attachment.id)
      ) {
        delete updatedSelected[attachment.id];
        setSelected(updatedSelected);
      }
    }
  };

  const _handleSendEmail = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    async (data: any) => {
      setIsEmailSending(true);
      const url = `${
        emailRoutes[(reportType ?? 'generic') as keyof typeof emailRoutes]
      }${reportType === 'generic' ? reportId : entityId}`;

      const formData = new FormData();

      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < data.sendTo.length; i++) {
        formData.append('sendTo[]', data.sendTo[i]);
      }
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < data.cc.length; i++) {
        formData.append('cc[]', data.cc[i]);
      }
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < data.bCC.length; i++) {
        formData.append('bcc[]', data.bCC[i]);
      }

      formData.append('subject', data.subject);
      formData.append('body', data.message);

      if (reportType !== 'generic') {
        if (selected && Object.keys(selected).length > 0) {
          Object.keys(selected ?? {}).forEach((d) => {
            formData.append('drawingSelection[]', d);
          });
        } else {
          formData.append('drawingSelection[]', -1 as unknown as string);
        }
      }

      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < files.length; i++) {
        formData.append('uploadedFiles', files[i]);
      }

      formData.append('reportAlternateId', alternateId ?? '0');
      formData.append('compressAttachments', checked.toString());

      if (reportType === 'generic') {
        if (decodedReportParams) {
          Object.entries(decodedReportParams).forEach(([key, value]) => {
            formData.append(`reportParameters[${key}]`, value as string);
          });
        } else {
          formData.append('reportParameters', JSON.stringify({}));
        }
        if (includeCompanyInformation !== null) {
          formData.append(
            'includeCompanyInformation',
            includeCompanyInformation
          );
        }
      }

      try {
        const response = await fetch(url, {
          method: 'POST',
          body: formData,
        });

        if (response.ok) {
          handleClose();
          messageContext.setSuccess('Email sent successfully');
        } else {
          const responseData = await response.json();
          if (response.status === 400) {
            throw new ValidationError(responseData);
          }
          if (response.status === 401) {
            const { reason } = responseData;
            throw new AuthorizationError(reason);
          }
          if (response.status === 404) {
            if (responseData.value === 'SQL_ERROR') {
              throw new NotFoundError();
            }
          }
          if (response.status === 500) {
            if (responseData.value === 'SQL_ERROR') {
              throw new SQLError(responseData);
            }
          }
        }

        setIsEmailSending(false);
      } catch (e) {
        setIsEmailSending(false);
        parseServerResponse(
          e,
          (errors) => messageContext.setError(Object.values(errors)[0]),
          (error) => {
            messageContext.setError(error.message ?? error);
          }
        );
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      reportType,
      entityId,
      reportId,
      alternateId,
      checked,
      selected,
      files,
      decodedReportParams,
      includeCompanyInformation,
    ]
  );

  const columns = [
    {
      name: 'id',
      header: 'Id',
      defaultVisible: false,
      defaultWidth: 60,
      type: 'number',
    },
    { name: 'itemCompanyId', header: 'Part Number', defaultFlex: 1 },
    { name: 'title', header: 'Title', defaultFlex: 1 },
    {
      name: 'fileName',
      header: 'File Name',
      defaultWidth: 130,
      render: ({ value }: { value: string }) => value ?? '-',
    },
    {
      name: 'fileExtension',
      header: 'File Type',
      defaultWidth: 100,
      textAlign: 'center' as const,
      render: ({
        // eslint-disable-next-line no-unused-vars
        value,
        data,
      }: {
        value: string;
        data: IEngItemMasterDrawing;
      }) => <FileTypeIcon file={data} />,
    },
    {
      name: 'path',
      header: 'Path',
      defaultFlex: 1,
      render: ({
        value,
        data,
      }: {
        value: string;
        data: IEngItemMasterDrawing;
      }) => {
        if (data.pathType === 'WEB') {
          return (
            <Link href={value} target="_blank">
              {value}
            </Link>
          );
        }
        return (
          // eslint-disable-next-line jsx-a11y/anchor-is-valid
          <Link
            component="button"
            onClick={() =>
              _handleDownloadFile(data?.fileName ?? 'unnamed', data.drawing)
            }
          >
            {value}
          </Link>
        );
      },
    },
  ];

  const dialogContent: ViewType = {
    drawingsView: {
      title: t('pages.reportViewer.dialogs.selectDrawings.title'),
      content: (
        <>
          <Box sx={[gridStyles, { height: '100%' }]}>
            <ReactDataGrid
              checkboxColumn
              columns={columns}
              dataSource={dataSource}
              groupBy={['itemCompanyId']}
              idProperty="id"
              licenseKey={reactDataGridLicenseKey}
              onSelectionChange={onSelectionChange}
              selected={selected}
              showEmptyRows
              style={gridStyle}
            />
          </Box>
        </>
      ),
      actions: (
        <>
          <Box
            px={2}
            py={1}
            sx={{ display: 'flex', justifyContent: 'flex-end' }}
          >
            <ETOButton
              color="primary"
              onClick={() => switchView({ name: 'emailView', showBack: true })}
              size="small"
            >
              {t('generic.next')}
            </ETOButton>
          </Box>
        </>
      ),
    },
    emailView: {
      title: `${t('generic.new')} ${t('generic.email')}`,
      content: (
        <>
          <Box sx={formSx}>
            <TETOForm form={emailPDFForm} formLayout={EmailReportForm} />
          </Box>
          <Box sx={{ position: 'relative' }}>
            <Editor
              editorClassName={
                emailPDFForm.validationErrors?.message
                  ? 'error-editor'
                  : 'demo-editor'
              }
              editorState={editorState}
              onEditorStateChange={onEditorStateChange}
              wrapperClassName="demo-wrapper"
            />
            <Typography component="p" sx={editorErrorSx}>
              {emailPDFForm.validationErrors?.message === 'generic.required'
                ? t('generic.required')
                : (emailPDFForm.validationErrors?.message as string)}
            </Typography>
          </Box>
          {isMobile && (
            <Box my={2}>
              <input {...getInputProps()} />
              <ETOButton
                color="primary"
                icon={<AttachFileRoundedIcon />}
                onClick={open}
                size="small"
              >
                {t('pages.reportViewer.attachFiles')}
              </ETOButton>
            </Box>
          )}

          {!isMobile && (
            <section className="container">
              <div {...getRootProps({ className: 'dropzone' })}>
                <input {...getInputProps()} />
                <p>{t('pages.reportViewer.dialogs.email.dragFiles')}</p>
              </div>
            </section>
          )}
          {attachments.length > 0 && (
            <Box sx={attachInfoWrapperSx}>
              <Box sx={attachInfoSx}>
                <Typography variant="subtitle1">
                  {t('generic.attachments')}
                </Typography>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={checked}
                      inputProps={{ 'aria-label': 'controlled' }}
                      onChange={handleChange}
                    />
                  }
                  label={t(
                    'pages.reportViewer.dialogs.selectDrawings.compressAttachments'
                  )}
                />
              </Box>
              <List sx={listSx}>
                {attachments.map((attachment, index) => {
                  const newAttachment = {
                    ...attachment,
                    title: () => {
                      if ('drawing' in attachment) {
                        const drawingName = attachment.drawing?.split('#')[0];
                        if (drawingName !== '') return drawingName;
                        if ('fileName' in attachment)
                          return attachment.fileName;
                      }
                      return 'name' in attachment ? attachment.name : undefined;
                    },
                  };
                  return (
                    <ListItem
                      key={
                        'drawing' in attachment
                          ? attachment.drawing
                          : attachment.name
                      }
                      secondaryAction={
                        <IconButton
                          aria-label="delete"
                          color="error"
                          edge="end"
                          onClick={() =>
                            _handleRemoveAttachment(index, attachment)
                          }
                        >
                          <DeleteRoundedIcon
                            sx={{ color: theme.palette.error.main }}
                          />
                        </IconButton>
                      }
                      sx={listItemSx}
                    >
                      <Typography variant="caption">
                        {newAttachment.title()}
                      </Typography>
                    </ListItem>
                  );
                })}
              </List>
            </Box>
          )}
        </>
      ),
      actions: (
        <>
          <Box px={2} py={1} sx={footerSx}>
            <Box>
              {dialogView.showBack && (
                <Button
                  onClick={() =>
                    switchView({ name: 'drawingsView', showBack: true })
                  }
                  sx={{ textTransform: 'capitalize' }}
                >
                  {t('generic.back')}
                </Button>
              )}
              <ETOButton
                color="primary"
                loading={isEmailSending}
                onClick={() => emailPDFForm.submit()}
                size="small"
              >
                {t('generic.send')}
              </ETOButton>
            </Box>
          </Box>
        </>
      ),
    },
  };

  useEffect(() => {
    const drawingIds = Object.keys(selected ?? {});
    const selectedDrawings = dataSource.filter((obj) =>
      drawingIds.some((id) => id === obj.id.toString())
    );
    setAttachments(selectedDrawings);
  }, [dataSource, selected]);

  const _handleGetReportItems = () => {
    if (reportType === 'purchaseOrder') {
      getPurchaseOrderDetails();
    }
    if (reportType === 'rFQ') {
      getRFQDetails();
    }
  };

  return (
    <>
      {pdfPath && ready && (
        <Box sx={wrapperSx}>
          <Box component="header" sx={headerSx}>
            <Box onClick={() => navigate('/')} role="navigation" sx={logoSx}>
              <Logo
                logoType="full-logo"
                styles={{
                  fill: theme.palette.text.primary,
                }}
              />
            </Box>

            <Box sx={actionButtonSx}>
              <ETOButton
                color="primary"
                disabled={disableEmail}
                onClick={() => _handleEmailOpen()}
                size="medium"
              >
                {t('generic.new')} {t('generic.email')}
              </ETOButton>
              {showEmailWithDrawings && (
                <ETOButton
                  color="primary"
                  disabled={disableEmail}
                  onClick={() => _handleGetReportItems()}
                  size="medium"
                >
                  {t('pages.reportViewer.newEmailWithDrawings')}
                </ETOButton>
              )}
              <ETOButton
                color="primary"
                onClick={() => _refreshPDF()}
                size="medium"
              >
                {t('generic.refresh')}
              </ETOButton>
              <ETOButton
                color="primary"
                onClick={() => _printPDF()}
                size="medium"
              >
                {t('generic.print')}
              </ETOButton>
            </Box>
          </Box>

          <Box sx={iFrameWrapperSx}>
            {showPDF && (
              <iframe
                allowFullScreen
                id="pdf-i-frame"
                src={`${iFramePDFPath}`}
                style={{ width: '100%', height: '100%', border: 'none' }}
                title="PDF Viewer"
              />
            )}
            {!showPDF && (
              <Box sx={noPDFStylesSx}>
                <Typography variant="h3">
                  {t('pages.reportViewer.documentUnavailable')}.
                </Typography>
                <Typography variant="subtitle1">
                  {t('pages.reportViewer.regenerateReport')}
                </Typography>
              </Box>
            )}
          </Box>
          <EmailDrawingDialog
            actions={dialogContent[dialogView.name].actions}
            content={dialogContent[dialogView.name].content}
            handleClose={handleClose}
            open={openEmailDrawing}
            title={dialogContent[dialogView.name].title}
          />
        </Box>
      )}
    </>
  );
};

export default ReportViewer;
