/* eslint-disable react/destructuring-assignment */
import IndeterminateCheckBoxRounded from '@mui/icons-material/IndeterminateCheckBoxRounded';
import {
  Box,
  Checkbox,
  Popper,
  SxProps,
  Theme,
  Typography,
} from '@mui/material';
import React, { useMemo, useRef } from 'react';
import useIsMobile from '../../../hooks/useIsMobile';
import { onKeyDown } from '../../TetoGrid/InlineEditing/editingHelpers';
import { CommonEditorProps } from './EditorProps';

const popperSx: SxProps = {
  bgColor: 'none',
};

// These styles should only apply at non-Mobile level
const wrapperSx: SxProps<Theme> = (theme: Theme) => ({
  [theme.breakpoints.up('md')]: {
    zIndex: 10000,
    top: 0,
    left: 0,
    position: 'absolute',
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '& .MuiCheckbox-root, .MuiButtonBase-root-MuiCheckbox-root, .MuiCheckbox-colorPrimary':
      {
        padding: '0px !important',
      },
  },
});

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const ETOBooleanEditor: React.FC<CommonEditorProps> = (props) => {
  const {
    cellProps,
    onChange,
    onComplete,
    onFocus,
    value,
    onCancel,
    onTabNavigation,
    onKeyDown: onKeyDownProp,
    errors,
    editorProps,
  } = props;

  const isMobile = useIsMobile();

  const error = errors && errors.length > 0 ? errors[0] : undefined;

  const columnName = cellProps?.name as string;
  const boxRef = useRef();

  const isDisabled = useMemo(() => {
    if (!editorProps?.disabled) return false;
    if (typeof editorProps?.disabled === 'function')
      return editorProps?.disabled(cellProps?.data);
    return editorProps?.disabled;
  }, [cellProps?.data, editorProps]);

  const checked = !editorProps?.allowUnset
    ? value ?? false
    : value ?? undefined;

  const editHandlers = {
    onCancel,
    onComplete,
    onTabNavigation,
  };

  const checkBox = (
    <Box ref={boxRef} sx={wrapperSx}>
      <Checkbox
        autoFocus
        checked={Boolean(checked)}
        color="primary"
        disabled={isDisabled}
        id={cellProps?.name as string}
        indeterminate={!editorProps?.allowUnset ? false : checked === undefined}
        indeterminateIcon={<IndeterminateCheckBoxRounded />}
        inputProps={{
          onFocus,
          onKeyDown: (e: React.KeyboardEvent<HTMLInputElement>) => {
            if (onKeyDownProp) onKeyDownProp?.(e);
            else onKeyDown(e, editHandlers);
          },
          style: {
            minWidth: Math.max(0, (cellProps?.computedWidth as number) - 30),
          },
          tabIndex: 0,
        }}
        name={columnName}
        onBlur={onComplete}
        onChange={(c, i) => {
          onChange(i);
        }}
        size="small"
      />
    </Box>
  );

  return cellProps.data !== null ? (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {columnName && (
        <>
          {!isMobile && !isDisabled && checkBox}
          {isMobile && checkBox}
          {error && (
            <Popper
              anchorEl={boxRef?.current}
              onResize={undefined}
              onResizeCapture={undefined}
              open
              placement="bottom-start"
              sx={popperSx}
            >
              <Typography color="error" variant="caption">
                {error}
              </Typography>
            </Popper>
          )}
        </>
      )}
    </>
  ) : null;
};

export default ETOBooleanEditor;
