import AccessTimeRounded from '@mui/icons-material/AccessTimeRounded';
import SupervisorAccountRounded from '@mui/icons-material/SupervisorAccountRounded';
import {
  Box,
  Card,
  CardContent,
  Divider,
  Paper,
  SxProps,
  Theme,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

interface HomeCardProps {
  children?: React.ReactNode;
  header?: React.ReactNode;
  icon: React.ReactNode;
}

const homePageSx: SxProps<Theme> = {
  alignItems: 'center',
  display: 'flex',
  flexDirection: 'column',
  gap: 4,
  height: '100%',
  justifyContent: {
    xs: 'space-between',
    sm: 'space-evenly',
  },
  overflowY: 'auto',
  p: 2,
  pb: 3,
  width: '100%',
};

const cardGroupSx: SxProps<Theme> = {
  display: 'flex',
  flexWrap: 'wrap',
  gap: 4,
  justifyContent: 'center',
};

const cardSx: SxProps<Theme> = {
  backgroundImage: 'none',
  width: '80%',
  boxShadow: (theme) => theme.shadows[0],
  maxWidth: (theme) => ({
    sm: theme.spacing(43),
  }),
};

const cardHeaderSx: SxProps<Theme> = {
  alignItems: 'center',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  width: '100%',

  '& .MuiSvgIcon-root': {
    width: (theme) => ({
      sx: theme.spacing(4),
      sm: theme.spacing(6),
    }),
    height: (theme) => ({
      sx: theme.spacing(4),
      sm: theme.spacing(6),
    }),
  },
};

const homeIconSx: SxProps<Theme> = {
  color: (theme) => theme.palette.grey[500],
};

const HomeCard = (props: HomeCardProps) => {
  const { children, header, icon } = props;
  return (
    <Card sx={cardSx}>
      <CardContent sx={cardHeaderSx}>
        {icon}
        <Divider variant="middle" />
        <Typography color="textSecondary" variant="h6">
          {header}
        </Typography>
      </CardContent>
      <CardContent>{children}</CardContent>
    </Card>
  );
};

const HomePage = () => {
  const theme = useTheme();
  const { t } = useTranslation();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <Paper elevation={0} sx={homePageSx}>
      <Typography
        align="center"
        color="primary"
        variant={isMobile ? 'h4' : 'h3'}
      >
        Welcome to Total ETO
      </Typography>

      <Typography align="center" component="p" variant="h6">
        {t('pages.homepage.topQuote')}
        <br />
        {t('pages.homepage.bottomQuote')}
      </Typography>

      <Box sx={cardGroupSx}>
        <HomeCard
          header={t('pages.homepage.card1.header')}
          icon={<AccessTimeRounded sx={homeIconSx} />}
        >
          <Typography align="center">
            {t('pages.homepage.card1.content')}
          </Typography>
        </HomeCard>

        <HomeCard
          header={t('pages.homepage.card2.header')}
          icon={<SupervisorAccountRounded sx={homeIconSx} />}
        >
          <Typography align="center">
            {t('pages.homepage.card2.content')}
          </Typography>
        </HomeCard>
      </Box>
    </Paper>
  );
};

export default HomePage;
