import { LoadingPage } from '@teto/react-component-library-v2';
import { useAtom, useSetAtom } from 'jotai';
import { useMolecule } from 'jotai-molecules';
import React, { Suspense, lazy, useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import useSharedCompanyDetail from './SharedStateHooks/useSharedCompanyDetail';
import useSharedContact from './SharedStateHooks/useSharedContact';
import useSharedItemMaster from './SharedStateHooks/useSharedItemMaster';
import useSharedNonConformance from './SharedStateHooks/useSharedNonConformance';
import useSharedPartHistory from './SharedStateHooks/useSharedPartHistory';
import useSharedProcBreakdown from './SharedStateHooks/useSharedProcBreakdown';
import useSharedProcessSchedule from './SharedStateHooks/useSharedProcessSchedule';
import useSharedPurchaseOrder from './SharedStateHooks/useSharedPurchaseOrder';
import useSharedRFQ from './SharedStateHooks/useSharedRFQ';
import { BreadCrumbSharedState } from './StateContainers/BreadCrumbModalState';
import { onCompanyDetailClose } from './StateContainers/CompanyState';
import { onContactClose } from './StateContainers/ContactState';
import { onItemMasterClose } from './StateContainers/ItemMasterState';
import { onNCClose } from './StateContainers/NonConformanceState';
import { onPartHistoryClose } from './StateContainers/PartHistoryState';
import { onProcBreakdownClose } from './StateContainers/ProcBreakdownState';
import { onProcessScheduleClose } from './StateContainers/ProcessScheduleState';
import { onPurchaseOrderClose } from './StateContainers/PurchaseOrderState';
import { onRFQClose } from './StateContainers/RFQState';

const BreadCrumbModal = lazy(
  () => import('../BreadCrumbModal/BreadCrumbModal')
);

const REGEX = /\/details\/:[^\/]+\/:[^\/]+/;

const SharedBreadCrumbModal = () => {
  const location = useLocation();

  const [levels, setLevels] = useAtom(BreadCrumbSharedState);

  const _onCompanyDetailClose = useMolecule(onCompanyDetailClose);
  const _handleCompanyDetailClose = useSetAtom(_onCompanyDetailClose);

  const _onNCClose = useMolecule(onNCClose);
  const _handleNCClose = useSetAtom(_onNCClose);

  const _onITMClose = useMolecule(onItemMasterClose);
  const _handleITMClose = useSetAtom(_onITMClose);

  const _onProcBreakdownClose = useMolecule(onProcBreakdownClose);
  const _handleProcBreakdownClose = useSetAtom(_onProcBreakdownClose);

  const _onContactClose = useMolecule(onContactClose);
  const _handleContactClose = useSetAtom(_onContactClose);

  const _onPurchaseOrderClose = useMolecule(onPurchaseOrderClose);
  const _handlePurchaseOrderClose = useSetAtom(_onPurchaseOrderClose);

  const _onProcessScheduleClose = useMolecule(onProcessScheduleClose);
  const _handleProcessScheduleClose = useSetAtom(_onProcessScheduleClose);

  const _onRFQClose = useMolecule(onRFQClose);
  const _handleRFQClose = useSetAtom(_onRFQClose);

  const _onPartHistoryClose = useMolecule(onPartHistoryClose);
  const _handlePartHistoryClose = useSetAtom(_onPartHistoryClose);

  useSharedItemMaster();
  useSharedNonConformance();
  useSharedProcBreakdown();
  useSharedCompanyDetail();
  useSharedContact();
  useSharedPurchaseOrder();
  useSharedPartHistory();
  useSharedRFQ();
  useSharedProcessSchedule();

  const _handleClose = useCallback(() => {
    setLevels([]);
    _handlePartHistoryClose();
    _handleNCClose();
    _handleITMClose();
    _handleCompanyDetailClose();
    _handleContactClose();
    _handleProcBreakdownClose();
    _handlePurchaseOrderClose();
    _handleRFQClose();
    _handleProcessScheduleClose();

    if (location.pathname.match(REGEX)) {
      window.close();
    }
  }, [
    setLevels,
    _handlePartHistoryClose,
    _handleNCClose,
    _handleITMClose,
    _handleCompanyDetailClose,
    _handleContactClose,
    _handleProcBreakdownClose,
    _handlePurchaseOrderClose,
    _handleRFQClose,
    _handleProcessScheduleClose,
    location.pathname,
  ]);

  return (
    <>
      <Suspense fallback={<LoadingPage />}>
        <BreadCrumbModal
          levels={levels}
          onClose={_handleClose}
          setLevels={setLevels}
        />
      </Suspense>
    </>
  );
};

export default SharedBreadCrumbModal;
