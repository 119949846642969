import { gql } from '../../../../__generated__';

const addEngItemPart = gql(`
mutation addEngItemPart($values: AddEngItemPartInput) {
  addEngItemPart(input: $values) {
    category {
      itemCategory
    }
    countryOfOrigin {
      name
    }
    description
    estimatedLeadTime
    hasDrawing
    id
    itemCertifiedPrints
    itemCompanyId
    itemLastCost
    itemListCost
    itemMaintenance
    itemRelub
    itemRepairable
    itemReserved
    itemRevNumber
    itemWeight
    lastSupplierCompanyId
    manufacturer
    manufacturerPartNumber
    obsolete
    preferredSupplierCompanyId
    processScheduleTemplateId
    rawMaterialCutLength
    supplierPartNumber
    tariffCode
    rawMaterialItem {
      id
    }
    uOMId
    uOM {
      description
      itemUom
    }
    custom1
    custom2
    custom3
    custom4
    custom5
    custom6
    custom7
    custom8
    custom9
    custom10
    custom11
    custom12
    custom13
    custom14
    custom15
    custom16
    custom17
    custom18
  }
}
`);
const updateEngItemPart =
  gql(`mutation updateEngItemPart($values: UpdateEngItemPartInput) {
  updateEngItemPart(input: $values) {
    category {
      itemCategory
    }
    countryOfOrigin {
      name
    }
    description
    estimatedLeadTime
    hasDrawing
    id
    itemCertifiedPrints
    itemCompanyId
    itemLastCost
    itemListCost
    itemMaintenance
    itemRelub
    itemRepairable
    itemReserved
    itemRevNumber
    itemWeight
    lastSupplierCompanyId
    manufacturer
    manufacturerPartNumber
    obsolete
    preferredSupplierCompanyId
    processScheduleTemplateId
    rawMaterialCutLength
    supplierPartNumber
    tariffCode
    rawMaterialItem {
      id
    }
    uOMId
    uOM {
      description
      itemUom
    }
    custom1
    custom2
    custom3
    custom4
    custom5
    custom6
    custom7
    custom8
    custom9
    custom10
    custom11
    custom12
    custom13
    custom14
    custom15
    custom16
    custom17
    custom18
  }
}
`);

export { addEngItemPart, updateEngItemPart };
