import { gql } from '../../../../../../../../__generated__';

const specialNotesLookupQuery = gql(`query specialNotesLookup {
  purchasingNotes (order: { purchaseNoteDesc: ASC }){
    items {
      id
      purchaseNoteDesc
      purchaseNote
    }
  }
}`);

const addSpecialNote =
  gql(`mutation addSpecialNote($input:AddPurchasingNoteInput) {
  addPurchasingNote(input: $input){
    items
    {
      id
      purchaseNoteDesc
      purchaseNote
    }
  }
}`);
const deleteSpecialNote = gql(`mutation deleteSpecialNote($id: Int!) {
  deletePurchasingNote(id: $id) {
    success
  }
}
`);
const updateSpecialNote = gql(`
mutation updateSpecialNote($input: UpdatePurchasingNoteInput) {
  updatePurchasingNote(input: $input) {
    items {
      id
      purchaseNoteDesc
      purchaseNote
    }
  }
}`);

export {
  addSpecialNote,
  deleteSpecialNote,
  specialNotesLookupQuery,
  updateSpecialNote,
};
