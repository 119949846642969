/* eslint-disable react/jsx-sort-props */
import { useTheme } from '@mui/material';
import { ETOSelectField } from '@teto/react-component-library-v2';
import React, { useMemo } from 'react';
import useGQLQuery from '../../../api/graphQL/useGQLQuery';
import useIsMobile from '../../../hooks/useIsMobile';
import { onKeyDown } from '../../TetoGrid/InlineEditing/editingHelpers';
import { CommonEditorProps } from './EditorProps';
import EditorWrapper from './EditorWrapper';
import TooltipWrapper from './TooltipWrapper';

const ETOSelectEditor: React.FC<CommonEditorProps> = (props) => {
  const {
    cellProps,
    editorProps,
    errors,
    inputVariant,
    onCancel,
    onChange,
    onComplete,
    onFocus,
    onTabNavigation,
    value,
  } = props;

  const isGridInMobile = useIsMobile();
  const theme = useTheme();

  const editHandlers = {
    onCancel,
    onComplete,
    onTabNavigation,
  };

  const error = useMemo(
    () => (errors && errors.length > 0 ? errors[0] : undefined),
    [errors]
  );

  const isDisabled = useMemo(() => {
    if (!editorProps?.disabled) return false;
    if (typeof editorProps?.disabled === 'function')
      return editorProps?.disabled(cellProps?.data);
    return editorProps?.disabled;
  }, [cellProps?.data, editorProps]);

  const variables = useMemo(() => {
    if (!editorProps?.variables) return {};
    return editorProps?.variables(cellProps?.data);
  }, [cellProps?.data, editorProps]);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const query = useGQLQuery<any>([editorProps?.queryString], {
    queryString: editorProps?.queryString,
    variables,
    options: {
      refetchOnWindowFocus: false,
      enabled: Boolean(editorProps?.queryString) && cellProps?.data !== null,
    },
  });

  const { _id, _value } = useMemo(() => {
    if (cellProps?.brandingName) {
      return {
        _id: cellProps?.brandingName,
        _value: cellProps?.data?.[cellProps?.brandingName] ?? null,
      };
    }

    return { _id: cellProps?.name, _value: value };
  }, [cellProps?.brandingName, cellProps?.data, cellProps?.name, value]);

  const editor = (
    <ETOSelectField
      autoFocus
      error={error}
      disabled={isDisabled}
      freeSolo={editorProps?.freeSolo}
      handleChange={onChange}
      id={_id}
      items={
        query?.data?.[editorProps?.queryResultPath as string]?.items ??
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        ([] as any[])
      }
      // eslint-disable-next-line @typescript-eslint/no-explicit-any, no-unused-vars
      itemValueSelector={editorProps?.itemValueSelector as (item: any) => any}
      // eslint-disable-next-line @typescript-eslint/no-explicit-any, no-unused-vars
      itemNameSelector={editorProps?.itemNameSelector as (item: any) => any}
      name={_id as string}
      onBlur={onComplete}
      onFocus={onFocus}
      onKeyDown={(e) => onKeyDown(e, editHandlers)}
      size="small"
      value={_value}
      variant={inputVariant}
      placeholder=""
      tabIndex={0}
      autoCompleteSx={{
        '& .MuiInputBase-root': {
          maxHeight: cellProps.rowHeight
            ? (cellProps?.rowHeight as number) - 2
            : undefined,
        },
      }}
      customSx={{
        '& .MuiInputBase-root': {
          paddingRight: `${theme.spacing(4)}px !important`, // #78532
        },
      }}
    />
  );

  return cellProps?.data !== null ? (
    <EditorWrapper>
      {isGridInMobile && (
        <TooltipWrapper error={error}>{editor}</TooltipWrapper>
      )}
      {!isGridInMobile && !isDisabled && editor}
    </EditorWrapper>
  ) : null;
};

export default ETOSelectEditor;
