import {
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Popover,
  useTheme,
} from '@mui/material';
import SaveAltRoundedIcon from '@mui/icons-material/SaveAltRounded';
import SettingsIcon from '@mui/icons-material/Settings';
import React from 'react';
import { useTranslation } from 'react-i18next';

interface TabOptionsPopoverProps {
  anchorEl: HTMLButtonElement | null;
  setAnchorEl: React.Dispatch<React.SetStateAction<HTMLButtonElement | null>>;
  onExportClicked: () => void;
  onSettingsClicked: () => void;
}

const TabOptionsPopover = (props: TabOptionsPopoverProps) => {
  const { anchorEl, setAnchorEl, onExportClicked, onSettingsClicked } = props;
  const { t } = useTranslation();
  const theme = useTheme();
  return (
    <Popover
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      onClose={() => setAnchorEl(null)}
      open={Boolean(anchorEl)}
      PaperProps={{
        elevation: 1,
      }}
    >
      <List>
        <ListItemButton
          onClick={() => {
            setAnchorEl(null);
            onExportClicked();
          }}
          sx={{
            height: theme.spacing(5),
          }}
        >
          <ListItemIcon>
            <SaveAltRoundedIcon color="primary" />
          </ListItemIcon>
          <ListItemText primary={t('generic.export')} />
        </ListItemButton>
        <ListItemButton
          onClick={() => {
            setAnchorEl(null);
            onSettingsClicked();
          }}
          sx={{
            height: theme.spacing(5),
          }}
        >
          <ListItemIcon>
            <SettingsIcon color="primary" />
          </ListItemIcon>
          <ListItemText primary={t('generic.configure')} />
        </ListItemButton>
      </List>
    </Popover>
  );
};

export default TabOptionsPopover;
