import { atom } from 'jotai';
import { createScope, molecule } from 'jotai-molecules';
import { atomWithReset } from 'jotai/utils';
import { ProcessScheduleModalProps } from '../../Modals/ProcessScheduleModal/types/ProcessScheduleModalProps';

type ProcessScheduleState = Omit<ProcessScheduleModalProps, 'onClose'> & {
  hasSavedOccurred?: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  itemsToAdd?: any[];
  openFrom: 'other' | 'engBOM' | 'machinePartOrder';
};

export const ProcessScheduleSharedState = atomWithReset<ProcessScheduleState>({
  hasSavedOccurred: false,
  initialValues: undefined,
  open: false,
  openFrom: 'other',
});

const _defaultOnClose = atom(null, (get, set) =>
  set(ProcessScheduleSharedState, {
    hasSavedOccurred: false,
    initialValues: undefined,
    itemsToAdd: undefined,
    open: false,
    openFrom: 'other',
  })
);

// Scope
export const initialProcessScheduleStateOnCloseScope =
  createScope(_defaultOnClose);

export const onProcessScheduleClose = molecule((getMol, getScope) => {
  const initialOnClose = getScope(initialProcessScheduleStateOnCloseScope);
  return initialOnClose;
});
