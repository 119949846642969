/* eslint-disable import/no-cycle */
import {
  MessageContext,
  OkCancelConfirmDialog,
} from '@teto/react-component-library-v2';
import dayjs from 'dayjs';
import { useFormik } from 'formik';
import { useSetAtom } from 'jotai';
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { Permission } from 'teto-client-api';
import * as Yup from 'yup';
import buildMutation from '../../../../../api/graphQL/buildMutation';
import { getGraphQLClient } from '../../../../../api/graphQL/graphQLClient';
import AuthContext from '../../../../../contexts/AuthContext';
import isEmptyOrWhitespace from '../../../../../helpers/isEmptyOrWhitespace';
import { ItemMasterSharedState } from '../../../../SharedStateComponents/StateContainers/ItemMasterState';
import AutoSave from '../../../components/AddEditInspector/AutoSave/AutoSave';
import AddEditDetailsPanel from '../../../components/AddEditInspector/components/AddEditDetailsPanel';
import EditingState from '../../../types/EditingState';
import { InspectorActions } from '../../../types/InspectorActionTypes';
import { PanelMode, PanelState } from '../../../types/PanelState';
import {
  addEngItemPart,
  updateEngItemPart,
} from '../../queries/engItemMasterMutations';
import {
  TAddEngItemMasterApiResponse,
  TEngItemMaster,
  TUpdateEngItemMasterApiResponse,
} from '../../types/TEngItemMaster';
import {
  IAddEngItemPartInput,
  IUpdateEngItemPartInput,
} from '../../types/mutationTypes';
import PartForm from './PartForm/PartForm';
import itemMasterUsageInformationQuery from './queries/itemMasterUsageInformationQuery';

const REQUIRED_MESSAGE = 'Required';

type IPanelActions = InspectorActions<TEngItemMaster> & {
  setPanelState: React.Dispatch<React.SetStateAction<PanelState>>;
};

interface IPartDetailsPanelProps extends IPanelActions {
  panelMode: PanelMode;
  editingState: EditingState;
  initialValues: Partial<TEngItemMaster> | undefined;
}

interface Warning {
  open: boolean;
  value?: {
    name: string;
    value: unknown;
  };
}

export const AddEngItemMasterSchema = Yup.object().shape({
  category: Yup.object().shape({
    itemCategory: Yup.number().required(REQUIRED_MESSAGE),
  }),
  countryOfOrigin: Yup.object()
    .shape({
      name: Yup.string().nullable(),
    })
    .nullable(),
  description: Yup.string().required(REQUIRED_MESSAGE).max(1000),
  estimatedLeadTime: Yup.number().nullable(),
  itemCertifiedPrints: Yup.boolean(),
  itemCompanyId: Yup.string().required(REQUIRED_MESSAGE).max(100),
  itemLastCost: Yup.number().nullable(),
  itemListCost: Yup.number().nullable(),
  itemMaintenance: Yup.boolean(),
  itemRelub: Yup.boolean(),
  itemRepairable: Yup.boolean(),
  itemReserved: Yup.boolean(),
  itemRevNumber: Yup.string().nullable().max(25),
  itemWeight: Yup.number().nullable(),
  manufacturer: Yup.string().nullable().max(255),
  manufacturerPartNumber: Yup.string().nullable().max(100),
  obsolete: Yup.boolean().required(REQUIRED_MESSAGE),
  preferredSupplierCompanyId: Yup.number().nullable(),
  processScheduleTemplateId: Yup.number().nullable(),
  rawMaterialItem: Yup.object()
    .shape({
      id: Yup.number(),
    })
    .nullable(),
  rawMaterialCutLength: Yup.number()
    .test(
      'fits-decimal',
      'Value must be max 20 digits including 6 after decimal point',
      (value) =>
        value ? /^\d{1,12}(.\d{1,6})?$/.test(value?.toString() as string) : true
    )
    .when('rawMaterialItem', (rawMaterialItem, schema) =>
      rawMaterialItem.id
        ? schema.required(
            'Raw Material Cut Length is required when Raw Material is present'
          )
        : schema.nullable()
    ),
  supplierPartNumber: Yup.string().max(100).nullable().max(100),
  tariffCode: Yup.string().nullable().max(25),
  uOM: Yup.object().shape({
    itemUom: Yup.number().required(REQUIRED_MESSAGE),
  }),
  custom1: Yup.string().nullable(),
  custom2: Yup.string().nullable(),
  custom3: Yup.number().nullable(),
  custom4: Yup.number().nullable(),
  custom5: Yup.date().nullable(),
  custom6: Yup.date().nullable(),
  custom7: Yup.boolean().required(REQUIRED_MESSAGE),
  custom8: Yup.boolean().required(REQUIRED_MESSAGE),
  custom9: Yup.string().nullable(),
  custom10: Yup.string().nullable(),
  custom11: Yup.string().nullable(),
  custom12: Yup.string().nullable(),
  custom13: Yup.string().nullable(),
  custom14: Yup.boolean().required(REQUIRED_MESSAGE),
  custom15: Yup.boolean().required(REQUIRED_MESSAGE),
  custom16: Yup.boolean().required(REQUIRED_MESSAGE),
  custom17: Yup.boolean().required(REQUIRED_MESSAGE),
  custom18: Yup.boolean().required(REQUIRED_MESSAGE),
});

function createValidEngItem(
  values: Partial<TEngItemMaster> | undefined
): Partial<TEngItemMaster> {
  const requiredBooleanFields: Partial<TEngItemMaster> = {
    itemRepairable: values?.itemRepairable || false,
    itemRelub: values?.itemRelub || false,
    itemMaintenance: values?.itemMaintenance || false,
    itemCertifiedPrints: values?.itemCertifiedPrints || false,
    itemReserved: values?.itemReserved || false,
    itemWeight: values?.itemWeight,
    itemLastCost: values?.itemLastCost,
    itemListCost: values?.itemListCost,
    estimatedLeadTime: values?.estimatedLeadTime,
    rawMaterialCutLength: values?.rawMaterialCutLength,
    obsolete: values?.obsolete || false,
    custom7: values?.custom7 || false,
    custom8: values?.custom8 || false,
    custom14: values?.custom14 || false,
    custom15: values?.custom15 || false,
    custom16: values?.custom16 || false,
    custom17: values?.custom17 || false,
    custom18: values?.custom18 || false,
  };

  const validValues = { ...values, ...requiredBooleanFields };

  return validValues;
}

function formatEngItem(item: Partial<TEngItemMaster>): IAddEngItemPartInput {
  if (item.uOM?.itemUom === undefined) {
    // shouldn't be possible to get here because of validation
    throw new Error('item.uOm is undefined');
  }

  const standardFields: IAddEngItemPartInput = {
    categoryId: item.category?.itemCategory ?? 0,
    countryOfOriginId: item.countryOfOrigin?.name ?? undefined,
    description: item.description ?? '',
    estimatedLeadTime: item.estimatedLeadTime
      ? Number(item.estimatedLeadTime)
      : undefined,
    itemCertifiedPrints: item.itemCertifiedPrints ?? false,
    itemCompanyId: item.itemCompanyId ?? '',
    itemLastCost: item.itemLastCost ? Number(item.itemLastCost) : undefined,
    itemListCost: item.itemListCost ? Number(item.itemListCost) : undefined,
    itemMaintenance: item.itemMaintenance ?? false,
    itemRelub: item.itemRelub ?? false,
    itemRepairable: item.itemRepairable ?? false,
    itemReserved: item.itemReserved ?? false,
    itemRevNumber: item.itemRevNumber,
    itemWeight: item.itemWeight ? Number(item.itemWeight) : undefined,
    manufacturer: item.manufacturer,
    manufacturerPartNumber: item.manufacturerPartNumber,
    obsolete: item.obsolete ?? false,
    preferredSupplierCompanyId: item.preferredSupplierCompanyId
      ? Number(item.preferredSupplierCompanyId)
      : undefined,
    processScheduleTemplateId: item.processScheduleTemplateId
      ? parseFloat(item.processScheduleTemplateId.toString())
      : undefined,
    rawMaterialCutLength: item.rawMaterialCutLength
      ? parseFloat(item.rawMaterialCutLength.toString())
      : undefined,
    rawMaterialItemId:
      item.rawMaterialItem &&
      !isEmptyOrWhitespace(item.rawMaterialItem?.id.toString())
        ? Number(item.rawMaterialItem.id)
        : undefined,
    supplierPartNumber: item.supplierPartNumber,
    tariffCode: item.tariffCode ?? undefined,
    uOMId: Number(item.uOM.itemUom),
    custom1: item.custom1,
    custom2: item.custom2,
    custom3: item.custom3 ? Number(item.custom3) : undefined,
    custom4: item.custom4 ? Number(item.custom4) : undefined,
    custom5: item.custom5 ? dayjs(item.custom5).toDate() : undefined,
    custom6: item.custom6 ? dayjs(item.custom6).toDate() : undefined,
    custom7: item.custom7 ?? false,
    custom8: item.custom8 ?? false,
    custom9: item.custom9,
    custom10: item.custom10,
    custom11: item.custom11,
    custom12: item.custom12,
    custom13: item.custom13,
    custom14: item.custom14 ?? false,
    custom15: item.custom15 ?? false,
    custom16: item.custom16 ?? false,
    custom17: item.custom17 ?? false,
    custom18: item.custom18 ?? false,
  };
  return standardFields;
}
const formatOnUpdate = (item: Partial<TEngItemMaster>) => {
  const standardFields = formatEngItem(item);

  if (
    standardFields.rawMaterialItemId === 0 ||
    !standardFields.rawMaterialItemId
  ) {
    standardFields.rawMaterialCutLength = undefined;
  }

  const editFields: IUpdateEngItemPartInput = {
    ...standardFields,
    id: item.id as number,
    lastSupplierCompanyId: item.lastSupplierCompanyId,
  };

  return editFields;
};
export const UpdateEngItemMasterSchema = Yup.object().shape({
  id: Yup.number().required(REQUIRED_MESSAGE),
  ...AddEngItemMasterSchema.fields,
});

const PartDetailsPanel = (props: IPartDetailsPanelProps) => {
  const {
    editingState,
    initialValues,
    panelMode,
    // eslint-disable-next-line no-unused-vars
    setCurrentValues,
    setEditingState,
    // eslint-disable-next-line no-unused-vars
    setHasASaveOccurred,
    setIsCreatingNewItem,
    // eslint-disable-next-line no-unused-vars
    setPanelState,
  } = props;

  const { t } = useTranslation();

  const [editWarning, setEditWarning] = useState<Warning>({
    open: false,
    value: undefined,
  });
  const [isOkayToEdit, setIsOkayToEdit] = useState(false);
  const [bOMInstances, setBOMInstances] = useState<number>(0);

  const setCreatedPart = useSetAtom(ItemMasterSharedState);

  const messageContext = useContext(MessageContext);
  const authContext = useContext(AuthContext);

  const formik = useFormik({
    initialValues: createValidEngItem(initialValues),
    enableReinitialize: true,
    validateOnChange: true,
    validateOnMount: panelMode === 'create' && !initialValues?.id,
    validationSchema:
      panelMode === 'create'
        ? AddEngItemMasterSchema
        : UpdateEngItemMasterSchema,
    onSubmit: (values) => {
      if (panelMode === 'create') {
        buildMutation({
          queryString: addEngItemPart,
          variables: {
            values: formatEngItem(values),
          },
          callback: (
            resp: TAddEngItemMasterApiResponse | TUpdateEngItemMasterApiResponse
          ) => {
            if (typeof resp === 'object') {
              if ('addEngItemPart' in resp) {
                setCreatedPart((current) => ({
                  ...current,
                  open: false,
                  createdPart: { ...resp.addEngItemPart },
                }));
                setCurrentValues(resp.addEngItemPart);

                setEditingState({
                  hasEdited: false,
                  isEditing: false,
                  tabError: false,
                });

                setPanelState({
                  id: resp.addEngItemPart.id,
                  mode: 'edit',
                });
                if (panelMode === 'create') {
                  messageContext.setSuccess('Record created successfully');
                }
              }
            }
          },
          messageContext,
          formik,
        });
      } else {
        buildMutation({
          queryString: updateEngItemPart,
          variables: {
            values: formatOnUpdate(values),
          },
          callback: (
            resp: TAddEngItemMasterApiResponse | TUpdateEngItemMasterApiResponse
          ) => {
            if (typeof resp === 'object') {
              if ('updateEngItemPart' in resp) {
                setHasASaveOccurred(true);
                setCurrentValues(resp.updateEngItemPart);
                setPanelState({
                  id: resp.updateEngItemPart.id,
                  mode: 'edit',
                });

                if (panelMode === 'edit') {
                  messageContext.setSuccess('Record edited successfully');
                }
              }
            }
          },
          messageContext,
          formik,
        });
      }
    },
  });

  const readOnly = authContext.hasReadOnlyLicense();

  const canModify = authContext.hasPermission(
    Permission.Modify_Engineering_ItemMaster_ItemDetails
  );

  const canAdd = authContext.hasPermission(
    Permission.Add_Engineering_ItemMaster_ItemDetails
  );

  const modifyPerm = useMemo(() => {
    if (panelMode === 'create') {
      return canAdd;
    }
    if (readOnly) {
      return false;
    }
    if (panelMode === 'edit') {
      return canModify;
    }
    return true;
  }, [canAdd, canModify, panelMode, readOnly]);

  useEffect(() => {
    if (initialValues?.id) {
      getGraphQLClient()
        .performQuery(
          itemMasterUsageInformationQuery,
          {
            itemId: initialValues?.id,
          },
          (err) => messageContext.setError(err.messages[0]),
          (err) => {
            const { input } = err;
            messageContext.setError(Object.values(input)[0] as string);
          }
        )
        .then((d) => {
          if (d.itemMasterUsageInformation) {
            setBOMInstances(d.itemMasterUsageInformation.bOMInstances);
          }
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialValues?.id]);

  const warningMessage = useMemo(() => {
    if (bOMInstances > 0) {
      return t(
        'inspectors.engItemMasterInspector.dialogs.bOMInstancesWarning.content',
        { quantity: bOMInstances }
      );
    }
    return t('inspectors.engItemMasterInspector.dialogs.editWarning.content');
  }, [bOMInstances, t]);

  const _handleChange = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (e: Partial<React.ChangeEvent<any>>) => {
      if (!editingState.hasEdited && panelMode === 'edit' && !isOkayToEdit) {
        setEditWarning({
          open: true,
          value: {
            name: e.target.name,
            value: e.target.value,
          },
        });
      } else {
        formik.handleChange(e);
      }
    },
    [editingState.hasEdited, formik, isOkayToEdit, panelMode]
  );

  return (
    <AddEditDetailsPanel
      canAdd={canAdd}
      disableAutoSave
      formik={formik}
      panelMode={panelMode}
      setEditingState={setEditingState}
      setIsCreatingNewItem={setIsCreatingNewItem}
    >
      {panelMode === 'edit' && isOkayToEdit && (
        <AutoSave debounceMs={3000} formik={formik} top={6} />
      )}
      <PartForm
        canModify={modifyPerm}
        formik={formik}
        handleChange={_handleChange}
        panelMode={panelMode}
      />

      {editWarning.open && (
        <OkCancelConfirmDialog
          content={warningMessage}
          onCancel={() => {
            formik.resetForm();
            setEditWarning({
              open: false,
              value: undefined,
            });
            setIsOkayToEdit(false);
          }}
          onOk={() => {
            const { name, value } = editWarning?.value || {};
            formik.setFieldValue(name as string, value);
            setIsOkayToEdit(true);
            setEditWarning({
              open: false,
              value: undefined,
            });
          }}
          open={editWarning.open}
          title={t(
            'inspectors.engItemMasterInspector.dialogs.editWarning.title'
          )}
        />
      )}
    </AddEditDetailsPanel>
  );
};

export default PartDetailsPanel;
