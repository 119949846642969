export interface Item {
  itemId: string;
  itemCompanyId: string;
  description: string;
  [key: string]: unknown;
}

const reduceSimilarPart = (arr: Item[], qtyKey: string): Item[] => {
  const groups: { [id: string]: Item[] } = {};
  const results: Item[] = [];

  arr.forEach((item: Item) => {
    const id = item.itemId;
    if (!groups[id]) {
      groups[id] = [];
    }
    groups[id].push(item);
  });

  Object.keys(groups).forEach((key) => {
    const group = groups[key];

    const reduced = group.reduce(
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (acc: any, item: Item) => ({
        itemId: key,
        total: acc.total + item[qtyKey],
      }),
      { itemId: key, total: 0 }
    );

    results.push({
      ...group[0],
      [qtyKey]: reduced.total,
    });
  });

  return results;
};

export default reduceSimilarPart;
