/* eslint-disable import/no-cycle */
import { MessageContext } from '@teto/react-component-library-v2';
import React, { forwardRef, useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import MainTetoGridGraphQL from '../../../components/TETOGridGraphQL/TETOMainGridGraphQL';
import useGrid from '../../../components/TETOGridGraphQL/hooks/useGrid';
import { useGridBuilderFromView } from '../../../components/TETOGridGraphQL/hooks/useGridBuilder';
import { MandatoryFilters } from '../../../components/TETOGridGraphQL/types/MandatoryFilterTypes';
import TETOGridRefType from '../../../components/TETOGridGraphQL/types/TETOGridRefType';
import { TetoGridGraphqlProps } from '../../../components/TETOGridGraphQL/types/TetoGridGraphqlProps';
import InventoryDetailView from '../../../views/InventoryDetailViewView.yaml';

interface InventoryDetailsGridProps
  extends Pick<
    TetoGridGraphqlProps,
    'setConfigureInspector' | 'configureInspector' | 'responsiveSettings'
  > {
  filters: MandatoryFilters;
  ref: React.Ref<TETOGridRefType> | undefined | null;
  persistanceName: string;
}

const RootQueryPath = 'inventories';

const InventoryDetailsGrid = (
  props: InventoryDetailsGridProps,
  ref: React.Ref<TETOGridRefType> | undefined | null
) => {
  const {
    configureInspector,
    filters,
    persistanceName,
    responsiveSettings,
    setConfigureInspector,
  } = props;
  const { ready, t } = useTranslation();
  const messageContext = useContext(MessageContext);
  const { gridBuilder, builderReady, error, hasError } = useGridBuilderFromView(
    InventoryDetailView,
    RootQueryPath
  );

  const mandatoryFilters = useMemo(() => {
    const _filters: MandatoryFilters = [...filters];

    return _filters;
  }, [filters]);

  const alwaysProjectColumns = useMemo(
    () => [
      'item.itemCompanyId',
      'qtyMinRequired',
      'maximumQuantity',
      'recommendedQuantity',
      'qtyOnHand',
      'binLabel',
    ],
    []
  );

  const { gridProps } = useGrid(
    persistanceName,
    `${RootQueryPath}.items`,
    (e) => messageContext.setError(e.message ?? e),
    t,
    alwaysProjectColumns,
    gridBuilder,
    {
      filterAndSortMode: 'serverSide',
      mandatoryFilter: mandatoryFilters,
    }
  );
  return (
    <>
      <MainTetoGridGraphQL
        builderReady={builderReady}
        error={error}
        hasError={hasError}
        ready={ready}
        {...gridProps}
        alignRefreshIconToRight
        configureInspector={configureInspector}
        customGridWrapSx={{ flexGrow: 1 }}
        externalQueryProps={[]}
        gridProps={gridProps}
        header={{
          hidden: true,
        }}
        ref={ref}
        responsiveSettings={responsiveSettings}
        setConfigureInspector={setConfigureInspector}
        showEmptyRows
        showGroupSummaryRow={false}
        t={t}
        tableIdentifier={persistanceName}
      />
    </>
  );
};

export default forwardRef(InventoryDetailsGrid);
