import { SvgIcon } from '@mui/material';
import React from 'react';

const RequestIcon = () => (
  <SvgIcon>
    <svg
      height="24"
      viewBox="0 0 24 24"
      width="24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15 5h2a2 2 0 0 1 2 2v8.17a3.001 3.001 0 1 1-2 0V7h-2v3l-4.5-4L15 2v3ZM5 8.83a3.001 3.001 0 1 1 2 0v6.34a3.001 3.001 0 1 1-2 0V8.83Z"
        fill="currentColor"
      />
    </svg>
  </SvgIcon>
);

export default RequestIcon;
