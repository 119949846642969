import { Box, Link, SxProps, Theme, Typography, useTheme } from '@mui/material';
import { Logo } from '@teto/react-component-library-v2';
import React, { useCallback, useContext } from 'react';
import LoginForm from './components/LoginForm/LoginForm';
import AuthContext from '../../contexts/AuthContext';
import SAMLForm from './components/SAMLForm/SAMLForm';

const container = (theme: Theme) => ({
  alignItems: 'center',
  display: 'flex',
  flexDirection: 'column',
  height: '100vh',
  justifyContent: 'center',
  overflowY: 'auto',
  width: '100vw',
  [theme.breakpoints.down('sm')]: {
    backgroundColor: theme.palette.background.paper,
  },
  [theme.breakpoints.up('sm')]: {
    backgroundColor: theme.palette.background.default,
  },
  [`${theme.breakpoints.down('sm')} and (orientation: landscape)`]: {
    height: 'auto',
  },
});
const outerContainer = (theme: Theme) => ({
  alignItems: 'center',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  maxWidth: theme.spacing(52),
  width: '100%',
  [theme.breakpoints.down('sm')]: {
    height: '100%',
  },
});

const logoContainer = (theme: Theme) => ({
  marginBottom: theme.spacing(2),
  [theme.breakpoints.down('sm')]: {
    height: theme.spacing(6),
  },
  [theme.breakpoints.up('sm')]: {
    height: theme.spacing(7),
  },
});

const innerContainerSx: SxProps<Theme> = {
  alignItems: 'center',
  display: 'flex',
  flexDirection: 'column',
  mt: { sm: 10 },
  width: '100%',
};

const appTitleStyles = {
  fontWeight: 600,
};

const link = (theme: Theme) => ({
  color: theme.palette.action.disabled,
  textDecoration: 'none',
});

export interface LoginPageProps {
  appTitle?: string;
  links?: { title: string; path: string }[];
}

const LoginPage: React.FC<React.PropsWithChildren<LoginPageProps>> = (
  props
) => {
  const theme = useTheme();
  const authContext = useContext(AuthContext);

  const { appTitle, links } = props;

  const { authenticationType } = authContext;

  const renderLoginForm = useCallback(() => {
    switch (authenticationType) {
      case 'SAML2':
        return <SAMLForm />;
      default:
        return <LoginForm />;
    }
  }, [authenticationType]);

  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      <Box sx={container}>
        <Box sx={outerContainer}>
          <Box sx={logoContainer}>
            <Logo
              logoType="caption-logo"
              styles={{
                fill: theme.palette.text.primary,
                height: 56,
              }}
            />
          </Box>

          <Box sx={innerContainerSx}>
            {appTitle && (
              <Typography color="primary" sx={appTitleStyles} variant="h6">
                {appTitle}
              </Typography>
            )}
            {authContext.isLoaded && <>{renderLoginForm()}</>}
          </Box>

          <Typography color="textSecondary" variant="caption">
            {links && (
              <>
                {links.map((item) => (
                  <Link href={item.path} key={item.path} sx={link}>
                    {item.title}.&nbsp;
                  </Link>
                ))}
              </>
            )}
          </Typography>
        </Box>
      </Box>
    </>
  );
};

export default LoginPage;
