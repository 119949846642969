import { TETODataColumn } from '../../GridBuilder/types/TETODataColumn';
import ColumnEditorNodeClass from './IColumnEditorNodeClass';

class SelectColumnEditorNodeClass implements ColumnEditorNodeClass {
  columns: TETODataColumn[];

  column: TETODataColumn;

  constructor(columns: TETODataColumn[], column: TETODataColumn) {
    this.columns = columns;
    this.column = column;
  }

  isBranch() {
    return Boolean(this.column.type === 'object');
  }

  getParent(): ColumnEditorNodeClass | undefined {
    if (!this.column.parentPath) return undefined;

    const result = this.columns.find((a) => a.name === this.column.parentPath);
    if (!result) return undefined;

    return new SelectColumnEditorNodeClass(
      this.columns,
      result
    ) as ColumnEditorNodeClass;
  }

  getChildren(): ColumnEditorNodeClass[] {
    return this.columns
      .filter(
        (a) =>
          this.column.type === 'object' && a.parentPath === this.column.name
      )
      .map(
        (c) =>
          new SelectColumnEditorNodeClass(
            this.columns,
            c
          ) as ColumnEditorNodeClass
      );
  }
}

export default SelectColumnEditorNodeClass;
