import { gql } from '../../../../__generated__';

const loginMutation =
  gql(`mutation loginMutation ($userName: String!, $password: String!, $rememberMe: Boolean) {
  login (input: { userName: $userName, password: $password, rememberMe: $rememberMe }) {
    failReason
    isSuccess
    user {
      effectiveLicense
      email
      firstName
      fullName
      id
      lastName
      license
      permissions
      subDepartment
      userId
      isAdmin
      otherPrivileges
    }
  }
}
`);

export default loginMutation;
