import React from 'react';
import { Box, Theme, Typography } from '@mui/material';
import InsertDriveFileRoundedIcon from '@mui/icons-material/InsertDriveFileRounded';
import LanguageRoundedIcon from '@mui/icons-material/LanguageRounded';
import IEngItemMasterDrawing from '../interfaces/IEngItemMasterDrawing';

const iconSx = {
  position: 'relative',
  display: 'inline-flex',
  justifyContent: 'center',
  alignItems: 'center',
};

const textSx = (theme: Theme) => ({
  position: 'absolute',
  lineHeight: 1,
  color: theme.palette.getContrastText(theme.palette.text.primary),
  top: '1.5em',
  fontSize: '0.8em',
});

const FileTypeIcon: React.FC<{ file: IEngItemMasterDrawing }> = (props) => {
  const { file } = props;
  return (
    <Box sx={iconSx}>
      {(file.pathType === 'LOCAL' || file.pathType === 'PDM') && (
        <>
          <InsertDriveFileRoundedIcon style={{ fontSize: '2.5em' }} />
          <Typography component="span" sx={textSx}>
            {file.fileExtension?.substring(1, 5)}
          </Typography>
        </>
      )}
      {file.pathType === 'WEB' && (
        <LanguageRoundedIcon style={{ fontSize: '2.5em' }} />
      )}
    </Box>
  );
};

export default FileTypeIcon;
