import { ETODateField, ETOTextField } from '@teto/react-component-library-v2';
import { Dayjs } from 'dayjs';
import { FormikProps } from 'formik';
import React, { useCallback, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import SettingsContext from '../../../../contexts/SettingsContext';
import { MAXREASON } from '../../components/helpers/inventoryConstants';

interface ReasonRequiredDateFormProps {
  formik: FormikProps<Record<string, unknown>>;
  // eslint-disable-next-line no-unused-vars
  handleChange?: (v: Record<string, unknown>) => void;
}

const ReasonRequiredDateForm = (props: ReasonRequiredDateFormProps) => {
  const { formik, handleChange } = props;
  const { t } = useTranslation();
  const {
    settings: { dateFormat },
  } = useContext(SettingsContext);

  const _handleChange = useCallback(
    (name: string, value?: Dayjs | string | null) => {
      formik.setFieldValue(name, value);
      handleChange?.({ [name]: value });
    },
    [formik, handleChange]
  );

  return (
    <>
      <ETODateField
        clearable
        error={formik.errors.requiredDate}
        // eslint-disable-next-line no-unused-vars
        handleChange={(v, a) => _handleChange('requiredDate', v)}
        inputFormat={dateFormat}
        inputLabelProps={{ shrink: true }}
        inputProps={{
          'aria-autocomplete': 'none',
          'data-testid': 'requiredDate',
        }}
        label={t('pages.inventory.itemsToPull.requiredDate')}
        name="requiredDate"
        size="small"
        value={formik.values.requiredDate}
      />

      <ETOTextField
        error={formik.errors.addReason}
        handleChange={(e) => _handleChange('addReason', e.target.value)}
        helperText={`${
          (formik.values.addReason as string)?.length
        }/${MAXREASON}`}
        label={t('pages.inventory.itemsToPull.addReason')}
        minRows={2}
        multiline
        name="addReason"
        size="small"
        value={formik.values.addReason}
      />
    </>
  );
};

export default ReasonRequiredDateForm;
