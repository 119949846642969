/* eslint-disable no-unused-vars */

import { atom } from 'jotai';
import { createScope, molecule } from 'jotai-molecules';
import { atomWithReset } from 'jotai/utils';
import { ProcBreakdownInspectorProps } from '../../Inspectors/ProcBreakdownInspector/ProcBreakdownInspectorProps';

type TProcBreakdownGlobalState = Omit<ProcBreakdownInspectorProps, 'onClose'>;

// State
export const ProcBreakdownSharedState =
  atomWithReset<TProcBreakdownGlobalState>({
    detail: undefined,
    open: false,
    renderAsInspector: false,
    ignoreLocation: false,
  });

const _defaultOnClose = atom(null, (get, set) =>
  set(ProcBreakdownSharedState, {
    detail: undefined,
    open: false,
    renderAsInspector: false,
    ignoreLocation: false,
  })
);

// Scope
export const initialProcBreakdownOnCloseScope = createScope(_defaultOnClose);

export const onProcBreakdownClose = molecule((getMol, getScope) => {
  const initialNCOnClose = getScope(initialProcBreakdownOnCloseScope);
  return initialNCOnClose;
});
