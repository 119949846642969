import { SxProps, Theme } from '@mui/material/styles';
import {
  ETOAccordion,
  ETOAccordionProps,
  ETOCheckBox,
  ETOCheckBoxProps,
} from '@teto/react-component-library-v2';
import React, { useLayoutEffect, useState } from 'react';
import AddressForm, { AddressFormProps } from './AddressForm';

export interface AddressAccordionProps {
  sameAsMailing: ETOCheckBoxProps;
  accordionProps: Omit<
    ETOAccordionProps,
    'ExtendedSummary' | 'expanded' | 'children' | 'onChange'
  >;
  addressFormProps: AddressFormProps;
}

const checkboxSx: SxProps<Theme> = {
  pb: 1,
};

const containerSx: SxProps<Theme> = {
  width: '100%',
  background: 'none',
  boxShadow: 0,
  '& .MuiAccordionSummary-root, .MuiAccordionDetails-root': {
    padding: 0,
  },
};

const AddressAccordion = (props: AddressAccordionProps) => {
  const { accordionProps, addressFormProps, sameAsMailing } = props;
  const [expand, setExpand] = useState<boolean>(true);

  useLayoutEffect(() => {
    if (!sameAsMailing.value) setExpand(true);
  }, [sameAsMailing.value]);

  return (
    <ETOAccordion
      {...accordionProps}
      expanded={sameAsMailing.value ? false : expand}
      extendedSummary={
        <ETOCheckBox
          {...sameAsMailing}
          sxProps={checkboxSx as SxProps}
          value={sameAsMailing.value ?? false}
        />
      }
      hideExpandIcon
      onChange={(e, exp) => setExpand(exp)}
      sx={{ ...accordionProps.sx, ...containerSx } as SxProps<Theme>}
    >
      <AddressForm {...addressFormProps} />
    </ETOAccordion>
  );
};

export default AddressAccordion;
