import { gql } from '../../../__generated__';
import SelectDefinition from './SelectDefinition';

export default {
  query: gql(`query getActiveEmployees {
    employees(where: { active: { eq: true } }, order: { firstName: ASC, lastName: ASC }) {
      items {
        id
        name
      }
    }
  }`),
  valueField: 'id',
  labelField: 'name',
} as SelectDefinition;
