/* eslint-disable import/prefer-default-export */
import dayjs from 'dayjs';
import { v4 } from 'uuid';
import * as Yup from 'yup';
import Lazy from 'yup/lib/Lazy';
import FormBuilder from './FormBuilder/FormBuilder';
import TETODataField from './FormBuilder/TETODataField';
import { ValidationTypes } from './clientSideValidators';

const _extractValidationDetailsFromString = (
  valString: string
): { values: string[]; message?: string } => {
  if (!valString) return { values: [] };
  const withMessage = valString.split(';;;');
  let hasMessage = false;
  if (withMessage.length > 1 && withMessage[1] !== '') hasMessage = true;

  return {
    message: hasMessage ? withMessage[1] : undefined,
    values: withMessage[0].split(':::'),
  };
};

const _getArrayPrimativeTypeMapping = (
  field: TETODataField
): Yup.BaseSchema | undefined => {
  switch (field.subType) {
    case 'string':
    case 'password':
      return Yup.string();
    case 'integer':
    case 'decimal':
    case 'currency':
      return Yup.number();
    case 'date':
    case 'datetime':
    case 'time':
      return Yup.date();
    case 'boolean':
      return Yup.bool();
    default:
      return undefined;
  }
};

const _buildListValidationChain = (field: TETODataField) => {
  let fieldSchema;

  if (!field.subFields && field.subType) {
    // this isform a primative list
    fieldSchema = Yup.array().of(
      _getArrayPrimativeTypeMapping(field) ?? Yup.string()
    );
  } else if (field.subFields && field.subFields.length > 0) {
    // this is an object list
    const { subFields } = field;
    const schemaObject = Object.fromEntries(
      subFields
        .map((innerField: TETODataField) => [
          innerField.name,
          _buildPropertyDefinition(innerField),
        ])
        .filter((a) => a[1] !== undefined)
    );

    fieldSchema = Yup.array().of(Yup.object().shape(schemaObject));
  } else {
    // this is also an object list but no information about the object has been provided to us
    fieldSchema = Yup.array().of(Yup.object());
  }

  fieldSchema = fieldSchema.nullable();

  if (field.required) {
    fieldSchema = fieldSchema.required('generic.required');
  }

  return fieldSchema;
};

const _buildObjectValidationChain = (field: TETODataField) => {
  let fieldSchema;

  if (field.subFields && field.subFields.length > 0) {
    const { subFields } = field;
    const schemaObject = Object.fromEntries(
      subFields
        .map((innerField: TETODataField) => [
          innerField.name,
          _buildPropertyDefinition(innerField),
        ])
        .filter((a) => a[1] !== undefined)
    );

    fieldSchema = Yup.object().shape(schemaObject);
  } else {
    fieldSchema = Yup.object();
  }

  fieldSchema = fieldSchema.nullable();

  if (field.required) {
    fieldSchema = fieldSchema.required('generic.required');
  }

  return fieldSchema;
};

const _handleValidationFunctions = <ST extends Yup.BaseSchema>(
  schema: ST,
  field: TETODataField,
  executor: // eslint-disable-next-line no-unused-vars, @typescript-eslint/no-explicit-any
  | ((values: any) => string | undefined)
    // eslint-disable-next-line no-unused-vars, @typescript-eslint/no-explicit-any
    | ((values: any) => Promise<string | undefined>)
) =>
  schema.test(`${field.name}-${v4()}`, '', async (_value, testContext) => {
    const possiblePromise = executor(testContext.parent);
    if (possiblePromise instanceof Promise) {
      const response = await possiblePromise;

      if (!response) return true;

      return testContext.createError({
        message: response,
        path: field.name,
      });
    }

    if (!possiblePromise) return true;

    return testContext.createError({
      message: possiblePromise,
      path: field.name,
    });
  });

const _buildStringValidationChain = (field: TETODataField) => {
  let rootSchema = Yup.string().nullable();

  if (field.required) rootSchema = rootSchema.required('generic.required');

  field.validators?.forEach((v) => {
    if (typeof v === 'function') {
      rootSchema = _handleValidationFunctions(rootSchema, field, v);
      return;
    }

    if (v.startsWith(ValidationTypes.LENGTH)) {
      const vals = _extractValidationDetailsFromString(v);
      if (vals.values.length === 2) {
        rootSchema = rootSchema.max(
          parseInt(vals.values[1], 10),
          vals.message ?? `Max ${vals.values[1]} characters`
        );
      }

      if (vals.values.length === 3) {
        rootSchema = rootSchema
          .min(
            parseInt(vals.values[2], 10),
            vals.message ?? `Min ${vals.values[2]} characters`
          )
          .max(
            parseInt(vals.values[1], 10),
            vals.message ?? `Max ${vals.values[1]} characters`
          );
      }
    } else if (v.startsWith(ValidationTypes.REGEX)) {
      const vals = _extractValidationDetailsFromString(v);
      if (vals.values.length === 2) {
        rootSchema = rootSchema.matches(
          new RegExp(vals.values[1]),
          vals.message ?? 'generic.invalid'
        );
      }
    }
  });

  return rootSchema;
};

const _buildNumberValidationChain = (field: TETODataField) => {
  let rootSchema = Yup.number().nullable();

  if (field.required) rootSchema = rootSchema.required('generic.required');

  field.validators?.forEach((v) => {
    if (typeof v === 'function') {
      rootSchema = _handleValidationFunctions(rootSchema, field, v);
      return;
    }

    if (v.startsWith(ValidationTypes.RANGE)) {
      const vals = _extractValidationDetailsFromString(v);
      if (vals.values.length === 3) {
        rootSchema = rootSchema
          .min(
            parseFloat(vals.values[1]),
            vals.message ?? `${'generic.minIs'} ${vals.values[1]}`
          )
          .max(
            parseFloat(vals.values[2]),
            vals.message ?? `${'generic.maxIs'} ${vals.values[2]}`
          );
      } else if (vals.values.length === 2) {
        rootSchema = rootSchema.min(
          parseFloat(vals.values[1]),
          vals.message ?? `${'generic.minIs'} ${vals.values[1]}`
        );
      }
    }
  });

  return rootSchema;
};

const _buildDateValidationChain = (field: TETODataField) => {
  let rootSchema = Yup.date().nullable();

  if (field.required) rootSchema = rootSchema.required('generic.required');

  field.validators?.forEach((v) => {
    if (typeof v === 'function') {
      rootSchema = _handleValidationFunctions(rootSchema, field, v);
      return;
    }

    if (v.startsWith(ValidationTypes.RANGE)) {
      const vals = _extractValidationDetailsFromString(v);
      if (vals.values.length === 3) {
        rootSchema = rootSchema
          .min(dayjs(vals.values[1]).toDate())
          .max(
            dayjs(vals.values[2]).toDate(),
            vals.message ?? `${'generic.maxIs'} ${vals.values[2]}`
          );
      } else if (vals.values.length === 2) {
        rootSchema = rootSchema.min(
          dayjs(vals.values[1]).toDate(),
          vals.message ?? `${'generic.minIs'} ${vals.values[1]}`
        );
      }
    }
  });

  return rootSchema;
};

const _buildBooleanValidationChain = (field: TETODataField) => {
  let rootSchema = Yup.bool().nullable();

  if (field.required) rootSchema = rootSchema.required('generic.required');

  field.validators?.forEach((v) => {
    if (typeof v === 'function') {
      rootSchema = _handleValidationFunctions(rootSchema, field, v);
    }
  });

  return rootSchema;
};

const _buildPropertyDefinition = (
  field: TETODataField
): Yup.BaseSchema | undefined | Lazy<Yup.BaseSchema> => {
  switch (field.type) {
    case 'string':
    case 'password':
      return _buildStringValidationChain(field);
    case 'integer':
    case 'decimal':
    case 'currency':
      return _buildNumberValidationChain(field);
    case 'date':
    case 'datetime':
    case 'time':
      return _buildDateValidationChain(field);
    case 'boolean':
      return _buildBooleanValidationChain(field);
    case 'object':
      return _buildObjectValidationChain(field);
    case 'list':
      return _buildListValidationChain(field);
    default:
      return undefined;
  }
};

export const buildValidationSchema = <FT>(formBuilder: FormBuilder<FT>) => {
  const { fields } = formBuilder;
  const SchemaObject = Object.fromEntries(
    fields
      .map((field: TETODataField) => [
        field.name,
        _buildPropertyDefinition(field),
      ])
      .filter((a) => a[1] !== undefined)
  );

  const schema = Yup.object().shape(SchemaObject);
  return schema;
};
