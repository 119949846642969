import React, { useContext, useMemo } from 'react';
import { Permission } from 'teto-client-api';
import AuthContext from '../../../../../contexts/AuthContext';
import CommonDocumentsPanel, {
  DocumentsTabPanelProps,
} from '../../../components/CommonDocumentsPanel/CommonDocumentsPanel';

import { addNonConformanceDocumentMutation } from './queries/addNonConformanceDocumentMutation';
import { deleteNonConformanceDocumentMutation } from './queries/deleteNonConformanceDocumentMutation';
import { nonConformanceDocumentsQuery } from './queries/nonConformanceDocumentsQuery';
import { updateNonConformanceDocumentsMutation } from './queries/updateNonConformanceDocumentMutation';

const DocumentsPanel = (props: DocumentsTabPanelProps) => {
  const authContext = useContext(AuthContext);

  const documentsPermissions = useMemo(
    () => ({
      canAdd: authContext.hasPermission(
        Permission.Add_Manufacturing_NonConformances_Documents
      ),
      canDelete: authContext.hasPermission(
        Permission.Delete_Manufacturing_NonConformances_Documents
      ),
      canEdit: authContext.hasPermission(
        Permission.Modify_Manufacturing_NonConformances_Documents
      ),
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [authContext]
  );

  return (
    <CommonDocumentsPanel
      addNewMutation={addNonConformanceDocumentMutation}
      deleteMutation={deleteNonConformanceDocumentMutation}
      entityName="NonConformance"
      entityQuery={nonConformanceDocumentsQuery}
      includeEntityIdInBatchMutation
      permissions={{
        canAddDocuments: documentsPermissions.canAdd,
        canDeleteDocuments: documentsPermissions.canDelete,
        canModifyDocuments: documentsPermissions.canEdit,
      }}
      shouldSupportBatch
      shouldSupportMultipleLocations
      updateMutation={updateNonConformanceDocumentsMutation}
      {...props}
    />
  );
};

export default DocumentsPanel;
