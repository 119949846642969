/* eslint-disable no-unused-vars */

import { atom } from 'jotai';
import { createScope, molecule } from 'jotai-molecules';
import { atomWithReset } from 'jotai/utils';
import {
  BreadCrumbModalProps,
  IBreadCrumbComponent,
} from '../../BreadCrumbModal/BreadCrumbModal';

type BreadCrumbGlobalState = IBreadCrumbComponent[];

// State
// eslint-disable-next-line import/prefer-default-export
export const BreadCrumbSharedState = atomWithReset<BreadCrumbGlobalState>([]);
