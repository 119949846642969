import {
  MessageContext,
  OkCancelConfirmDialog,
} from '@teto/react-component-library-v2';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  getEmployees,
  getHourTypesForTimecard,
  getProjects,
  getNonConformances,
  stopPunchIn,
  getGraphQLClient as getNewGraphQLClient,
} from 'teto-client-api';
import useLocalStorage from 'use-local-storage';
import dayjs from 'dayjs';
import getActiveJobs from '../../getActiveJobs';
import PunchInGQL from '../../../../pages/PunchInsPage/types/punchIn';
import AuthContext from '../../../../contexts/AuthContext';
import PunchInTimerContext from '../../../../contexts/PunchInTimerContext';
import TimeCardInspector from '../../TimeCardInspector/TimeCardInspector';
import StopTimerForm from '../../TimeSheetForms/Timer/StopTimerForm';

import getErrors from '../../../../api/graphQL/getErrors';
import punchInQuery from '../../../../pages/PunchInsPage/queries/punchInQuery';
import myPunchInQuery from '../../../../pages/PunchInsPage/queries/myPunchInQuery';
import { getProcessScheduleDetailsQuery } from '../../TimeSheetForms/queries/getProcessScheduleDetailsQuery';
import { getGraphQLClient } from '../../../../api/graphQL/graphQLClient';
import { ProcessScheduleDetail } from '../../../../__generated__/graphql';

interface StopTimeInspectorProps {
  id: number;
  onClose: () => void;
  onUpdate: () => void;
  // eslint-disable-next-line no-unused-vars
  onCancel: () => void;
  limitToSelf?: boolean;
  canUseTimer: boolean;
}

const StopTimeInspector = (props: StopTimeInspectorProps) => {
  const authContext = useContext(AuthContext);
  const { t } = useTranslation();
  const { id, onClose, onUpdate, onCancel, canUseTimer, limitToSelf } = props;

  const [timerRecord, setTimerRecord] = useState<PunchInGQL | undefined>();
  const messageContext = useContext(MessageContext);
  const punchInTimerContext = useContext(PunchInTimerContext);
  const [formHasChanges, setFormHasChanges] = useState(false);
  const [needsCloseConfirmation, setNeedsCloseConfirmation] = useState(false);
  const { geoInfo } = useContext(PunchInTimerContext);

  const [barcodeEnabled, setBarcodeEnabled] = useLocalStorage(
    'barcode-scanner-enabled',
    false
  );

  useEffect(() => {
    const valid =
      !limitToSelf ||
      (punchInTimerContext.status === 'active' &&
        punchInTimerContext.activeTimerId);

    if (valid) {
      const punchInMethod = limitToSelf ? myPunchInQuery : punchInQuery;

      getGraphQLClient()
        .performQuery(
          punchInMethod,
          { id },
          (err) => messageContext.setError(err.messages[0]),
          (err) => {
            const errors = getErrors(err);
            messageContext.setError(errors);
          }
        )
        .then((e) => {
          setTimerRecord(limitToSelf ? e.myPunchIn : e.punchIn);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    id,
    messageContext,
    punchInTimerContext.activeTimerId,
    punchInTimerContext.status,
    limitToSelf,
  ]);

  const _onError = (err: string | Error) => {
    messageContext.setMessage(
      typeof err === 'string' ? err : err.message,
      'error'
    );
  };

  const stopAPIMethod = limitToSelf
    ? punchInTimerContext.stopMyPunchIn
    : stopPunchIn;
  const getJobs = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (projectId: number): Promise<any> =>
      getNewGraphQLClient()
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        .performQuery(getActiveJobs as any, { projectId })
        .then((d) => {
          if (d.hasError()) {
            d.handleAllErrors(messageContext.setError);
            return;
          }
          if (d.hasData() && d.data?.specs) {
            return d.data.specs.items ?? [];
          }
        }),

    [messageContext.setError]
  );

  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {authContext.user && (
        <TimeCardInspector
          barcodeEnabled={barcodeEnabled && !geoInfo.geoError}
          data={[
            {
              employeeFullName:
                timerRecord?.employee.name ??
                `${authContext.user.firstName} ${authContext.user?.lastName}`,
              hourTypeName: timerRecord?.hourType.displayName ?? '',
              projectName: timerRecord?.spec.project.displayName ?? '',
              specName: timerRecord?.spec.displayName ?? '',
              punchInCoords: timerRecord?.punchInCoords ?? geoInfo.location,
              id: timerRecord?.id ?? authContext.user.id,
            },
          ]}
          lockedInspector={geoInfo.geoError}
          onClose={() => {
            if (formHasChanges) {
              setNeedsCloseConfirmation(true);
            } else {
              onClose();
            }
          }}
          open={Boolean(id)}
          title={t('forms.stopTimer')}
          toggleBarcode={() => setBarcodeEnabled((v) => !v)}
        >
          {id && (
            <StopTimerForm
              allowEmployeeSelection={!limitToSelf}
              buttons={{
                right: {
                  title: 'Start Timer',
                },
              }}
              canUseTimer={canUseTimer && !geoInfo.geoError}
              employee={authContext.user}
              fieldCaptions={{
                employeeId: t('entities:Employee.Employee'),
                comments: t('forms.fields.comments'),
                custom1: t('entities:Timecard.TimecardCustom1'),
                custom2: t('entities:Timecard.TimecardCustom2'),
                custom3: t('entities:Timecard.TimecardCustom3'),
                custom4: t('entities:Timecard.TimecardCustom4'),
                custom5: t('entities:Timecard.TimecardCustom5'),
                custom6: t('entities:Timecard.TimecardCustom6'),
                custom7: t('entities:Timecard.TimecardCustom7'),
                custom8: t('entities:Timecard.TimecardCustom8'),
                hourFactor: t('entities:Timecard.HourFactor'),
                hourRate: t('entities:Timecard.HourRate'),
                hourTypeId: t('entities:HourType.HourType'),
                hour: t('forms.fields.hour'),
                specId: t('entities:Machine.Job'),
                nonConformanceId: t('entities:NonConformance.NonConformance'),
                processScheduleDetailId: t(
                  'entities:ProcessScheduleDetail.ProcessScheduleDetail'
                ),
                projectId: t('entities:Project.Project'),
                quantity: t(
                  'entities:ProcessScheduleInHouseTransaction.Quantity'
                ),
                pSCCustom1: t(
                  'entities:ProcessScheduleInHouseTransaction.CompletedLogCustom1'
                ),
                pSCCustom2: t(
                  'entities:ProcessScheduleInHouseTransaction.CompletedLogCustom2'
                ),
                pSCCustom3: t(
                  'entities:ProcessScheduleInHouseTransaction.CompletedLogCustom3'
                ),
                pSCCustom4: t(
                  'entities:ProcessScheduleInHouseTransaction.CompletedLogCustom4'
                ),
                pSCCustom5: t(
                  'entities:ProcessScheduleInHouseTransaction.CompletedLogCustom5'
                ),
                pSCCustom6: t(
                  'entities:ProcessScheduleInHouseTransaction.CompletedLogCustom6'
                ),
                pSCCustom7: t(
                  'entities:ProcessScheduleInHouseTransaction.CompletedLogCustom7'
                ),
                pSCCustom8: t(
                  'entities:ProcessScheduleInHouseTransaction.CompletedLogCustom8'
                ),
              }}
              fieldsDisableEditable={
                geoInfo.geoError || {
                  employeeId: typeof getEmployees === 'function',
                  nonConformanceId: false,
                  processScheduleDetailId: false,
                  quantity: false,
                  comments: false,
                  custom1: false,
                  custom2: false,
                  custom3: false,
                  custom4: false,
                  custom5: false,
                  custom6: false,
                  custom7: false,
                  custom8: false,
                  hourTypeId: false,
                  hour: false,
                  specId: false,
                  projectId: false,
                  pSCCustom1: false,
                  pSCCustom2: false,
                  pSCCustom3: false,
                  pSCCustom4: false,
                  pSCCustom5: false,
                  pSCCustom6: false,
                  pSCCustom7: false,
                  pSCCustom8: false,
                }
              }
              getHourTypes={(
                projectId,
                specId,
                processScheduleDetailId,
                nonConformanceId,
                employeeId
              ) =>
                getHourTypesForTimecard({
                  disablePaging: true,
                  filter: {
                    active: true,
                    projectId: projectId ?? null,
                    specId: specId ?? null,
                    processScheduleDetailId: processScheduleDetailId ?? null,
                    nonConformanceId: nonConformanceId ?? null,
                    employeeId: employeeId ?? null,
                  },
                }).then((e) => e.records)
              }
              getJobs={(projectId: number) => getJobs(projectId)}
              getNonConformances={(projectId: number, specId: number) =>
                getNonConformances({
                  disablePaging: true,
                  filter: { projectId, specId },
                  orderBy: { creationDate: true },
                }).then((e) => e.records)
              }
              getProcessSchedules={(projectId: number, specId: number) =>
                getNewGraphQLClient()
                  .performQuery(getProcessScheduleDetailsQuery, {
                    date: dayjs(),
                    projectId,
                    specId,
                  })
                  .then((d) => {
                    if (d.hasError()) {
                      if (d.hasValidationErrors()) {
                        messageContext.setError(getErrors(d.validationErrors));
                      }

                      if (d.hasSystemErrors()) {
                        messageContext.setError(getErrors(d.systemErrors));
                      }
                    }
                    if (d.hasData()) {
                      return d.data.timecardProcessScheduleDetails?.items;
                    }
                    return [];
                  }) as Promise<ProcessScheduleDetail[]>
              }
              getProjects={() =>
                getProjects({
                  disablePaging: true,
                  filter: {
                    active: true,
                  },
                  orderBy: {
                    id: true,
                  },
                }).then((e) => e.records)
              }
              id={id}
              initialValues={{
                ...timerRecord,
                employeeId: timerRecord?.employee.id || -2,
                projectId: timerRecord?.spec.project.id,
                specId: timerRecord?.spec.id,
                hourTypeId: timerRecord?.hourType.id,
                pSCCustom1: timerRecord?.processScheduleDetail?.custom1,
                pSCCustom2: timerRecord?.processScheduleDetail?.custom2,
                pSCCustom3: timerRecord?.processScheduleDetail?.custom3,
                pSCCustom4: timerRecord?.processScheduleDetail?.custom4,
                pSCCustom5: timerRecord?.processScheduleDetail?.custom5,
                pSCCustom6: timerRecord?.processScheduleDetail?.custom6,
                pSCCustom7: timerRecord?.processScheduleDetail?.custom7,
                pSCCustom8: timerRecord?.processScheduleDetail?.custom8,
              }}
              onCancel={onCancel}
              onError={_onError}
              onSubmit={(request) =>
                stopAPIMethod(id, {
                  location: geoInfo.location,
                  nonConformanceId: request.nonConformanceId
                    ? request.nonConformanceId
                    : undefined,
                  processScheduleDetailId: request.processScheduleDetailId
                    ? request.processScheduleDetailId
                    : undefined,
                  projectId: request.projectId,
                  specId: request.specId,
                  comments: request.comments,
                  quantity: request.quantity,
                  hourTypeId: request.hourTypeId,
                  custom1: request.custom1,
                  custom2: request.custom2,
                  custom3: request.custom3,
                  custom4: request.custom4,
                  custom5: request.custom5,
                  custom6: request.custom6,
                  custom7: request.custom7,
                  custom8: request.custom8,
                  pSCCustom1: request.pSCCustom1,
                  pSCCustom2: request.pSCCustom2,
                  pSCCustom3: request.pSCCustom3,
                  pSCCustom4: request.pSCCustom4,
                  pSCCustom5: request.pSCCustom5,
                  pSCCustom6: request.pSCCustom6,
                  pSCCustom7: request.pSCCustom7,
                  pSCCustom8: request.pSCCustom8,
                  hourFactor: request.hourFactor,
                  hourRate: request.hourRate,
                  // eslint-disable-next-line @typescript-eslint/no-explicit-any
                } as any)
                  .then(() => {
                    onUpdate();
                  })
                  .catch((e) => {
                    if (Object.prototype.hasOwnProperty.call(e, 'errors')) {
                      // TODO : work-item - 73802
                      if (!e.errors.errors) {
                        // eslint-disable-next-line @typescript-eslint/no-explicit-any
                        const error = Object.values(e.errors)[0] as any;
                        if (typeof error[0] === 'string') {
                          messageContext.setError(error[0]);
                        }
                        return;
                      }
                      if (
                        e.errors.errors.hours &&
                        typeof e.errors.errors.hours[0] === 'string'
                      ) {
                        messageContext.setError(e.errors.errors.hours[0]);
                      }
                    } else {
                      _onError(e);
                    }
                  })
              }
              qrEnabled={barcodeEnabled && !geoInfo.geoError}
              setFormDirty={(e) => setFormHasChanges(e)}
            />
          )}
          <OkCancelConfirmDialog
            content={t('dialogs.closeUnsavedForm.content')}
            onCancel={() => {
              setNeedsCloseConfirmation(false);
            }}
            onOk={() => {
              setNeedsCloseConfirmation(false);
              onClose();
            }}
            open={needsCloseConfirmation}
            title={t('dialogs.closeUnsavedForm.title')}
          />
        </TimeCardInspector>
      )}
    </>
  );
};

export default StopTimeInspector;
