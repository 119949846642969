/* eslint-disable import/no-unresolved */
/* eslint-disable consistent-return */
/* eslint-disable array-callback-return */

import KeyboardArrowDownRoundedIcon from '@mui/icons-material/KeyboardArrowDownRounded';
import {
  Box,
  Button,
  ListItemIcon,
  Menu,
  MenuItem,
  SxProps,
  Theme,
  alpha,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import {
  ETOButton,
  OkCancelConfirmDialogProps,
  YesNoConfirmDialogProps,
} from '@teto/react-component-library-v2';
import {
  ActionItemType,
  CustomConfirmDialogProps,
} from '@teto/react-component-library-v2/dist/components/ActionBar/ActionBar';
import { uniqueId } from 'lodash';
import React, { useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import FAB from '../FAB/FAB';

export interface ActionBarActionItemType extends ActionItemType {
  color?: 'grey' | 'primary' | 'secondary' | 'warning' | 'error' | 'success';
  count?: number;
}

export interface CustomRightActionType
  extends Partial<ActionBarActionItemType> {
  customComponent?: React.ReactNode;
  cssStyles?: string;
  ref?: React.MutableRefObject<HTMLButtonElement | null>;
  excludeFromFab?: boolean;
}
// TODO : work item - 73784
export type RightMenuActionItems = (boolean | CustomRightActionType)[];

export interface ActionBarProps {
  withBottomNav?: boolean;
  headerStyles?: SxProps;
  leftChildren?: React.ReactNode;
  rightChildren?:
    | RightMenuActionItems
    | (RightMenuActionItems | boolean | CustomRightActionType)[];
  bottomChildren?: React.ReactNode;
  FABPosition?: SxProps<Theme>;
  anchorRef?: React.RefObject<HTMLElement>;
  shouldCollapse?: boolean;
  excludeBtnFromCollapsedMenu?: string[];
  // eslint-disable-next-line no-unused-vars
  setIsActionBarFABOpen?: (isOpen: boolean) => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any, no-unused-vars
  updateMenuButtonRef?: (ref: any) => void;
}

const actionBarSx = (theme: Theme) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  marginBottom: theme.spacing(1),
  marginTop: theme.spacing(0.5),
});

const leftChildrenSx = (theme: Theme) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-start',
  alignItems: 'center',
  columnGap: theme.spacing(1),
  [theme.breakpoints.down('md')]: {
    columnGap: theme.spacing(1),
    flexGrow: 1,
    display: 'grid',
    gridAutoFlow: 'column',
    justifyContent: 'space-between',
  },
});

const actionButtonsSx = (theme: Theme) => ({
  float: 'right',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-end',
  alignItems: 'center',
  columnGap: theme.spacing(1),
  [theme.breakpoints.down('md')]: {
    columnGap: theme.spacing(1),
  },
  '& .MuiButton-root': {
    minWidth: theme.spacing(2),
    fontSize: '0.9rem',
    '& .MuiButton-startIcon': {
      display: 'none',
    },
  },
  '& button': {
    [theme.breakpoints.down('md')]: {
      '& .MuiButton-startIcon': {
        marginLeft: 0,
        marginRight: 0,
      },
    },
  },
});

const menuSx = (theme: Theme) => ({
  '& .MuiPaper-root': {
    borderRadius: 1,
    marginTop: theme.spacing(1),
    minWidth: 180,
    boxShadow:
      'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
    '& .MuiMenu-list': {
      padding: '4px 0',
    },
    '& .MuiMenuItem-root': {
      '& .MuiSvgIcon-root': {
        fontSize: 18,
        color: theme.palette.primary.main,
        marginRight: theme.spacing(1.5),
      },
      '&:hover': {
        color: theme.palette.getContrastText(
          alpha(
            theme.palette.primary.main,
            theme.palette.action.selectedOpacity
          )
        ),
        '& .MuiSvgIcon-root': {
          color: theme.palette.getContrastText(
            alpha(
              theme.palette.primary.main,
              theme.palette.action.selectedOpacity
            )
          ),
        },
      },
    },
  },
});

const ActionBar = (props: ActionBarProps) => {
  const {
    anchorRef,
    bottomChildren,
    excludeBtnFromCollapsedMenu,
    FABPosition,
    headerStyles,
    leftChildren,
    rightChildren,
    shouldCollapse,
    withBottomNav,
    updateMenuButtonRef,
    setIsActionBarFABOpen,
  } = props;

  const theme = useTheme();

  const { t } = useTranslation();

  const [menuAnchorEl, setMenuAnchorEl] = React.useState<null | HTMLElement>(
    null
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const rightButtons = [] as CustomRightActionType[];
  if (rightChildren && Array.isArray(rightChildren)) {
    rightChildren?.forEach((obj) => {
      if (Array.isArray(obj)) {
        obj.forEach((item) => {
          if (item) {
            if (Array.isArray(item)) {
              item.forEach((subItem) => {
                rightButtons.push(subItem as CustomRightActionType);
              });
            } else {
              rightButtons.push(item as CustomRightActionType);
            }
          }
        });
      }
      if (obj && !Array.isArray(obj)) {
        rightButtons.push(obj as CustomRightActionType);
      }
    });
  }

  const filterComponentsForFab = rightButtons
    ? rightButtons
        .filter((item) => !item.disabled && !item.excludeFromFab)
        .map((comp) => {
          const { customComponent, ...rest } = comp;
          if (comp.confirm) {
            return {
              ...rest,
              confirm: {
                type: comp.confirm?.type
                  ? comp.confirm?.type
                  : ('okCancel' as const),
                title: comp.confirm.title,
                content: comp.confirm.content,
              },
            };
          }
          return { ...rest };
        })
    : [];

  const FabActions = [...filterComponentsForFab];

  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const shouldActionBarCollapse = shouldCollapse;
  const _handleMenuClick = (e: React.MouseEvent<HTMLElement>) => {
    setMenuAnchorEl(e.currentTarget);
  };
  const _handleMenuClose = () => {
    setMenuAnchorEl(null);
  };

  const optionsButtonRef = useRef<HTMLButtonElement | null>(null);

  const excludedBtns = useMemo(
    () =>
      rightButtons.filter(
        (btn) =>
          excludeBtnFromCollapsedMenu &&
          excludeBtnFromCollapsedMenu.indexOf(btn.title as string) > -1
      ),
    [excludeBtnFromCollapsedMenu, rightButtons]
  );

  return (
    <Box
      data-testid="action-bar"
      ref={anchorRef}
      sx={[
        actionBarSx,
        ...(Array.isArray(headerStyles) ? headerStyles : [headerStyles]),
      ]}
    >
      <Box sx={leftChildrenSx}>{leftChildren}</Box>
      {!isMobile && (
        <Box sx={actionButtonsSx}>
          {shouldActionBarCollapse && (
            <>
              {excludedBtns.map((child) =>
                child.customComponent ? (
                  child.customComponent
                ) : (
                  <ETOButton
                    color={child.color ?? 'primary'}
                    // @ts-expect-error: type does exist? // TODO : work item - 73784
                    confirm={
                      child.confirm
                        ? ({
                            ...child.confirm,
                            type: child.confirm?.type ?? ('okCancel' as const),
                            title: child.confirm.title,
                            content: child.confirm.content,
                          } as unknown as
                            | CustomConfirmDialogProps
                            | YesNoConfirmDialogProps
                            | OkCancelConfirmDialogProps
                            | undefined)
                        : undefined
                    }
                    count={child.count ?? undefined}
                    disabled={child.disabled}
                    icon={child.icon}
                    key={uniqueId(child.title)}
                    onClick={child.onclick}
                    size="medium"
                  >
                    {child.title}
                  </ETOButton>
                )
              )}
              <Button
                data-testid="action-bar-options"
                disableElevation
                endIcon={<KeyboardArrowDownRoundedIcon />}
                onClick={_handleMenuClick}
                ref={optionsButtonRef}
                sx={{ textTransform: 'capitalize' }}
                variant="contained"
              >
                {t('generic.option', { count: 2 })}
              </Button>
              <Menu
                anchorEl={menuAnchorEl}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'right',
                }}
                elevation={0}
                keepMounted
                onClose={_handleMenuClose}
                open={Boolean(menuAnchorEl)}
                sx={menuSx}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
              >
                {rightButtons.map((button) => {
                  if (
                    excludeBtnFromCollapsedMenu &&
                    excludeBtnFromCollapsedMenu.indexOf(
                      button.title as string
                    ) !== -1
                  ) {
                    return undefined;
                  }
                  return (
                    <MenuItem
                      color={button.color ?? 'primary'}
                      disabled={button.disabled}
                      disableRipple
                      key={uniqueId(button.title)}
                      onClick={(e) => {
                        if (updateMenuButtonRef) {
                          updateMenuButtonRef(optionsButtonRef.current);
                        }
                        if (button.onclick) {
                          button.onclick(e);
                        }
                        _handleMenuClose();
                      }}
                    >
                      <ListItemIcon color="primary">{button.icon}</ListItemIcon>
                      {button.title}
                    </MenuItem>
                  );
                })}
              </Menu>
            </>
          )}
          {!shouldActionBarCollapse &&
            rightButtons?.map((child) =>
              child.customComponent ? (
                <span className={child.cssStyles} key={child.title}>
                  {child.customComponent}
                </span>
              ) : (
                <ETOButton
                  color={child.color ?? 'primary'}
                  // @ts-expect-error: type does exist? // TODO : work item - 73784
                  confirm={
                    child.confirm
                      ? ({
                          ...child.confirm,
                          type: child.confirm?.type ?? ('okCancel' as const),
                          title: child.confirm.title,
                          content: child.confirm.content,
                        } as unknown as
                          | CustomConfirmDialogProps
                          | YesNoConfirmDialogProps
                          | OkCancelConfirmDialogProps
                          | undefined)
                      : undefined
                  }
                  count={child.count ?? undefined}
                  disabled={child.disabled}
                  icon={child.icon}
                  key={uniqueId(child.title)}
                  onClick={child.onclick}
                  size="medium"
                >
                  {child.title}
                </ETOButton>
              )
            )}
        </Box>
      )}
      {bottomChildren && <Box>{bottomChildren}</Box>}
      {FabActions && (
        <FAB
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          FabActions={FabActions as unknown as any}
          FABPosition={FABPosition}
          onFABClose={
            setIsActionBarFABOpen && (() => setIsActionBarFABOpen(false))
          }
          onFABOpen={
            setIsActionBarFABOpen && (() => setIsActionBarFABOpen(true))
          }
          withBottomNav={withBottomNav}
        />
      )}
    </Box>
  );
};

export default ActionBar;
