import { gql } from '../../../../../__generated__';

const stopMyClockIn = gql(`
mutation stopMyClockIn {
  stopMyClockIn 
  {
    success
    id
    date
    employeeId
    startTime
    endTime
  }
}`);
export default stopMyClockIn;
