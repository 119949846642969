export default `
query getSupplier ($id: Int!){
  supplier(id: $id){
    aptaxCategoryId
    defaultCurrNameId
    fobnotes
    id
    poreportAlternateId
    port
    supNetTerms
    supFob
    supQaapproved
    supSpecial
    supVia
  }
}
`;
