import { gql } from '../../../../../__generated__';

const getMyCurrentClockInStatus = gql(`
query myCurrentClockInStatus {
  myCurrentClockInStatus{
    employeeId,                       
    clockedIn,
    startTime,
    endTime,
    timeElapsedInMinutes
  }
}
`);
export default getMyCurrentClockInStatus;
