import { Box, Typography, useMediaQuery, useTheme } from '@mui/material';
import {
  ETOSelectField,
  MessageContext,
} from '@teto/react-component-library-v2';
import { useAtom, useSetAtom } from 'jotai';

import React, {
  Dispatch,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { Permission, getGraphQLClient } from 'teto-client-api';
import { uniqueId } from 'lodash';
import { BreadCrumbSharedState } from '../../../../../../SharedStateComponents/StateContainers/BreadCrumbModalState';
import exportReportQuery from '../../../../queries/exportReportQuery';
import SettingsContext from '../../../../../../../contexts/SettingsContext';
import {
  ProcessScheduleTemplateOptions,
  ProcessScheduleHeader,
  ProcureRawMaterialOptions,
  Spec,
} from '../../../../../../../__generated__/graphql';
import useGQLQuery from '../../../../../../../api/graphQL/useGQLQuery';
import AuthContext from '../../../../../../../contexts/AuthContext';
import CreatePSForm from '../../../../../../../forms/CreatePSForm.yaml';
import EditingState from '../../../../../../Inspectors/types/EditingState';
import { PanelState } from '../../../../../../Inspectors/types/PanelState';
import { ProcessScheduleSharedState } from '../../../../../../SharedStateComponents/StateContainers/ProcessScheduleState';
import EditableCustomFieldFormBuilderExtension from '../../../../../../TETOForms/FormBuilder/EditableCustomFieldFormBuilderExtension';
import TETOForm from '../../../../../../TETOForms/TETOForm';
import useForm from '../../../../../../TETOForms/hooks/useForm';
import useFormBuilderFromMutation from '../../../../../../TETOForms/hooks/useFormBuilderFromMutation';
import {
  getProjects,
  getSpecsByProject,
} from '../../../../../../TETOForms/selects/selects';
import ProcessScheduleCreateButtonStrip from '../../../../components/ProcessScheduleCreateButtonStrip';
import initializePSMutation from '../../../../queries/initializePSMutation';
import ProcureRawMaterialRadioGroup from './components/ProcureRawMaterialRadioGroup';
import TemplateRadioGroup from './components/TemplateRadioGroup';
import PrintProcessScheduleModal from './components/PrintProcessScheduleModal';

interface ProcessScheduleCreateHeaderFormProps {
  lastPSNumber: string;
  // eslint-disable-next-line no-unused-vars
  setEditingState: (editingState: EditingState) => void;
  setPanelState: Dispatch<React.SetStateAction<PanelState>>;
  // eslint-disable-next-line no-unused-vars
  setHasASaveOccurred: (val: boolean) => void;
}

const projectAndJobContainerSx = { display: 'grid', gap: 3, p: 1.75 };

const ProcessScheduleCreateHeaderForm = (
  props: ProcessScheduleCreateHeaderFormProps
) => {
  const { lastPSNumber, setEditingState, setHasASaveOccurred, setPanelState } =
    props;

  const { t } = useTranslation();

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('lg'));

  const authContext = useContext(AuthContext);
  const settingsContext = useContext(SettingsContext);
  const messageContext = useContext(MessageContext);
  const [processSchedule] = useAtom(ProcessScheduleSharedState);
  const setLevels = useSetAtom(BreadCrumbSharedState);
  const setPS = useSetAtom(ProcessScheduleSharedState);
  const multipleToAdd =
    processSchedule.itemsToAdd && processSchedule.itemsToAdd.length > 1;

  const [allItemsHaveTemplates, setAllItemsHaveTemplates] = useState(false);
  const [initializePS, setInitializePS] = useState(false);
  const [printPSModal, setPrintPSModal] = useState<number>(0);
  const [alternateId, setAlternateId] = useState(0);
  const [createdPSHeaders, setCreatedPSHeaders] = useState<
    ProcessScheduleHeader[]
  >([]);

  const formContainerSx = () => ({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    height: isMobile ? 'auto' : '100%',
    overflow: 'hidden',
  });

  const formSx = () => ({
    overflowY: 'auto',
    height: isMobile ? '80%' : '100%',
    pb: isMobile ? theme.spacing(2) : 0,
  });

  const buttonStripContainerSx = () => ({
    padding: theme.spacing(1),
  });

  const { canAdd } = useMemo(
    () => ({
      canAdd: authContext.hasPermission(
        Permission.Add_Procurement_ProcessSchedules
      ),

      canAddCustomProcessNumber:
        settingsContext.settings.canAddCustomProcessNumber,
    }),
    [authContext, settingsContext]
  );

  const projects = useGQLQuery<{
    projects: { items: { id: string; displayName: string }[] };
  }>(['createPSProjects'], {
    queryString: getProjects.query,
    options: {
      refetchOnWindowFocus: false,
      enabled: Boolean(
        processSchedule.itemsToAdd && processSchedule.itemsToAdd.length === 1
      ),
    },
  });

  const specsQuery = useGQLQuery<{
    specs: { items: Spec[] };
  }>(['createPSSpecsByProject'], {
    queryString: getSpecsByProject.query,
    variables: {
      projectId: processSchedule.itemsToAdd?.[0]?.purchasingSummary?.projectId,
    },
    options: {
      enabled: Boolean(
        processSchedule.itemsToAdd?.[0]?.purchasingSummary?.projectId &&
          processSchedule.itemsToAdd.length === 1
      ),
      refetchOnWindowFocus: false,
    },
  });

  const idForItemsReplacer = useMemo(() => {
    if (multipleToAdd) return uniqueId();
  }, [multipleToAdd]);

  const itemsReplacer = useMemo(() => {
    if (idForItemsReplacer) {
      return [
        {
          id: idForItemsReplacer,
          displayName: 'Multiple',
        },
      ];
    }
  }, [idForItemsReplacer]);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const formBuilderPS: any = useFormBuilderFromMutation(
    'addProcessScheduleHeaderBatch',
    'AddProcessScheduleHeaderBatchInput',
    {
      items: {
        id: true,
      },
    },
    (fb) =>
      fb
        .extension(
          new EditableCustomFieldFormBuilderExtension(
            'ProcessSchedule',
            'forms:AddProcessScheduleHeaderBatchInput.Custom',
            'PS Header Custom',
            !canAdd
          ),
          t
        )
        .updateField('number', {
          title: `${t('entities:ProcessScheduleHeader.ProcessSchedule')} No.`,
          required: !canAdd,
          defaultValue: lastPSNumber,
          disabled:
            processSchedule?.itemsToAdd &&
            processSchedule?.itemsToAdd?.length > 1,
        })
        .updateField('addProcessScheduleTemplateOptions', {
          render: (form, field, options) => (
            <>
              <TemplateRadioGroup form={form} options={options} />
              {form.validationErrors?.addProcessScheduleTemplateOptions && (
                <Typography color="error">
                  {
                    form.validationErrors
                      ?.addProcessScheduleTemplateOptions as string
                  }
                </Typography>
              )}
            </>
          ),
        })
        .updateField('procureRawMaterialOption', {
          render: (form, field, options) => (
            <ProcureRawMaterialRadioGroup form={form} options={options} />
          ),
        })
        .updateField('materialFromFirstSupplier', {
          dependsOn: ['procureMaterial'],
        })
  );

  const createPSForm = useForm<{
    addProcessScheduleTemplateOptions: ProcessScheduleTemplateOptions;
    procureRawMaterialOption: ProcureRawMaterialOptions;
    procureMaterial: boolean;
    materialFromFirstSupplier: boolean;
    optionsGroup: string;
  }>(
    formBuilderPS,
    {
      onSubmitted: async (resp) => {
        if (resp.success) {
          if (resp.output?.addProcessScheduleHeaderBatch?.items) {
            setCreatedPSHeaders(
              resp.output?.addProcessScheduleHeaderBatch?.items
            );
          }

          const _handleSuccess = () => {
            if (!multipleToAdd) {
              setHasASaveOccurred(true);
              setEditingState({
                hasEdited: false,
                isEditing: false,
                tabError: false,
              });
              setPanelState({
                mode: 'edit',
                id: resp.output?.addProcessScheduleHeaderBatch?.items?.[0]?.id,
              });
            }

            if (multipleToAdd) {
              setPrintPSModal(
                resp.output?.addProcessScheduleHeaderBatch?.items.length ?? 0
              );
            }

            messageContext.setSuccess(
              t('generic.createdSuccess', {
                record: t('entities:ProcessSchedule.ProcessSchedule'),
              })
            );
          };

          if (initializePS) {
            getGraphQLClient()
              .performMutation(initializePSMutation, {
                input: {
                  items: resp.output?.addProcessScheduleHeaderBatch?.items?.map(
                    (a: { id: number }) => ({ processScheduleHeaderId: a.id })
                  ),
                },
              })
              .then((res) => {
                if (res.hasError()) {
                  res.handleAllErrors(messageContext.setError);
                  setEditingState({
                    hasEdited: true,
                    isEditing: false,
                    tabError: true,
                  });
                  return;
                }

                if (
                  res.hasData() &&
                  res?.data?.initializeProcessSchedule?.success
                ) {
                  _handleSuccess();
                }
              });
          } else {
            _handleSuccess();
          }
        }

        return Promise.resolve();
      },
    },
    {
      items: processSchedule.itemsToAdd?.map(
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (item: any) => ({
          projectId: item.purchasingSummary?.projectId,
          specId: item.purchasingSummary?.specId,
          itemId: item.purchasingSummary?.childId,
          quantity: item.purchasingSummary?.toPurchase,
        })
      ),
      number:
        processSchedule?.itemsToAdd && processSchedule?.itemsToAdd?.length > 1
          ? t('generic.auto')
          : lastPSNumber,
      addProcessScheduleTemplateOptions:
        ProcessScheduleTemplateOptions.UseParts,
      procureMaterial: false,
    }
  );

  useEffect(() => {
    if (
      createPSForm.values.addProcessScheduleTemplateOptions !== 'USE_PARTS' ||
      !processSchedule?.itemsToAdd ||
      processSchedule?.itemsToAdd.length === 0 ||
      !processSchedule?.itemsToAdd.find(
        (a) => !a.item.processScheduleTemplateId
      )
    ) {
      setAllItemsHaveTemplates(true);
      createPSForm.setValidationErrors({});
    } else {
      setAllItemsHaveTemplates(false);
      createPSForm.setValidationErrors({
        addProcessScheduleTemplateOptions: `Not all ${t(
          'entities:Part.Part'
        )}s have templates`,
      });
    }
  }, [processSchedule?.itemsToAdd, createPSForm, t]);

  const _handleTriggerEditMode = useCallback(() => {
    if (!createdPSHeaders[0]?.id) return;
    if (multipleToAdd) {
      setPrintPSModal(0);
      setLevels([]);
      return setPS({
        openFrom: 'other',
        open: false,
        hasSavedOccurred: false,
        initialValues: undefined,
        itemsToAdd: undefined,
      });
    }

    setHasASaveOccurred(true);
    setEditingState({
      hasEdited: false,
      isEditing: false,
      tabError: false,
    });
    setPanelState({
      mode: 'edit',
      id: createdPSHeaders[0]?.id,
    });
  }, [
    createdPSHeaders,
    multipleToAdd,
    setEditingState,
    setHasASaveOccurred,
    setLevels,
    setPS,
    setPanelState,
  ]);

  const _handleSubmit = (init: boolean) => {
    if (init) {
      setInitializePS(init);
    }
    createPSForm.submit();
  };

  useEffect(() => {
    if (
      createPSForm.values.addProcessScheduleTemplateOptions !==
        ProcessScheduleTemplateOptions.CopyFromExistingProcessSchedule &&
      createPSForm.values.procureRawMaterialOption ===
        ProcureRawMaterialOptions.CopyRawMaterialFromExistingPs
    ) {
      if (createPSForm.values.procureMaterial === true) {
        createPSForm.updateField(
          'procureRawMaterialOption',
          ProcureRawMaterialOptions.ProcurePartAsRawMaterial
        );
      }
    }
  }, [createPSForm]);

  const _handlePrintPSHeaders = useCallback(async () => {
    getGraphQLClient()
      .performQuery(exportReportQuery, {
        id: 230,
        input: {
          alternateId,
          parameters: {
            nvcProcessScheduleID: `|${createdPSHeaders
              .map((a) => a.id)
              .join('|')}|`,
            MachineCaption: 'Spec',
          },
        },
        companyDetails: true,
      })
      .then((d) => {
        if (d.hasError()) {
          d.showAllSystemErrors(messageContext.setError);
          if (d.hasValidationErrors()) {
            const { input } = d.validationErrors;
            messageContext.setError(Object.values(input)[0] as string);
          }
          return;
        }

        if (d.hasData()) {
          if (d.data?.exportReport?.path) {
            const data = {
              companyDetails: true.toString(),
              alternateId: alternateId.toString(),
              entityId: `|${createdPSHeaders.map((a) => a.id).join('|')}|`,
              reportId: d.data.exportReport.reportId.toString(),
              type: 'generic',
            };
            const convertParamsToObj = d.data?.exportReport?.parameters?.reduce(
              (acc, current) => {
                acc[current.key] = current.value;
                return acc;
              },
              {}
            );

            const reportParams = encodeURIComponent(
              JSON.stringify(convertParamsToObj)
            );
            const queryString = new URLSearchParams(data).toString();
            window.open(
              `/reportViewer?url=${encodeURIComponent(
                d.data.exportReport.path
              )}&${queryString}&reportParams=${reportParams}`,
              '_blank'
            );
          }
        }
      });
    _handleTriggerEditMode();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [_handleTriggerEditMode, alternateId, createdPSHeaders]);

  return (
    <Box sx={formContainerSx}>
      <Box sx={formSx}>
        <Box sx={projectAndJobContainerSx}>
          <ETOSelectField
            disabled
            itemNameSelector={(item) => item[getProjects.labelField]}
            items={
              multipleToAdd
                ? itemsReplacer ?? []
                : projects.data?.projects?.items ?? []
            }
            itemValueSelector={(item) => item[getProjects.valueField]}
            label={t('entities:Project.Project')}
            name="projects"
            size="small"
            value={
              !multipleToAdd
                ? processSchedule.itemsToAdd?.[0]?.purchasingSummary?.projectId
                : idForItemsReplacer
            }
          />
          <ETOSelectField
            disabled
            itemNameSelector={(item) => item[getSpecsByProject.labelField]}
            items={
              multipleToAdd
                ? itemsReplacer ?? []
                : specsQuery.data?.specs?.items ?? []
            }
            itemValueSelector={(item) => item[getSpecsByProject.valueField]}
            label={t('entities:Machine.Job')}
            name="specs"
            size="small"
            value={
              !multipleToAdd
                ? processSchedule.itemsToAdd?.[0]?.purchasingSummary?.specId
                : idForItemsReplacer
            }
          />
        </Box>
        <TETOForm form={createPSForm} formLayout={CreatePSForm} />
      </Box>
      <Box sx={buttonStripContainerSx}>
        <ProcessScheduleCreateButtonStrip
          disableInitialize={!allItemsHaveTemplates}
          handleSubmit={_handleSubmit}
        />
      </Box>
      <PrintProcessScheduleModal
        alternateId={alternateId}
        handleNo={() => _handleTriggerEditMode()}
        handleYes={() => _handlePrintPSHeaders()}
        noOfItems={printPSModal}
        open={printPSModal > 0}
        setAlternateId={setAlternateId}
      />
    </Box>
  );
};

export default ProcessScheduleCreateHeaderForm;
