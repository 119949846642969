/* eslint-disable @typescript-eslint/no-explicit-any */
import Box from '@mui/material/Box';
import { SxProps, Theme } from '@mui/material/styles';
import {
  ButtonStrip,
  ButtonStripProps,
  OkCancelConfirmDialog,
  YesNoConfirmDialog,
} from '@teto/react-component-library-v2';
import { Formik, FormikHelpers, FormikProps, FormikValues } from 'formik';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ObjectSchema } from 'yup';
import { ObjectShape } from 'yup/lib/object';
import { rootSx } from '../../../NonConformanceInspector/NCPanels/components/commonPanelStyles';
import EditingState from '../../../types/EditingState';
import AutoSave from '../AutoSave/AutoSave';

export type AddEditFormMode = 'add' | 'edit';
export type AddEditFormikSchema<S extends ObjectShape> = ObjectSchema<S>;
export type AddEditFormComponent = React.ReactNode;

export interface CommonAddEditContainerProps<T> {
  autoSave?: boolean;
  canDeleteItem?: boolean;
  confirmAbandonForm: boolean;
  formComponent: AddEditFormComponent;
  formikSchema: AddEditFormikSchema<ObjectShape>;
  expandForm?: boolean;
  formikProps?: Partial<FormikProps<T>>;
  initialValues: any;
  mode: AddEditFormMode;
  onDelete?: () => void;
  onSubmit?: (
    /* eslint-disable no-unused-vars */
    values: any,
    addEditFormMode: AddEditFormMode,
    actions: FormikHelpers<any>
  ) => void;
  setConfirmAbandonForm: (v: boolean) => void;
  setIsNewItemOpen?: (v: boolean) => void;
  setSelectedItem: (d?: any) => void;
  setEditingState: (value: EditingState) => void;
  /* eslint-enable */
}

const contentSx: SxProps<Theme> = {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  rowGap: 2,
  width: '100%',
};

export type TAddEditGenericType = Record<string, unknown>;

const CommonAddEditContainer = <T extends FormikValues>(
  props: CommonAddEditContainerProps<T>
) => {
  const {
    autoSave,
    canDeleteItem,
    confirmAbandonForm,
    formComponent,
    formikSchema,
    formikProps,
    initialValues,
    mode,
    onDelete,
    onSubmit,
    setConfirmAbandonForm,
    setIsNewItemOpen,
    setSelectedItem,
    setEditingState,
  } = props;

  const { t } = useTranslation();
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState<boolean>(false);
  const fieldRef = useRef<HTMLElement | undefined>(undefined);
  useEffect(() => {
    if (
      mode === 'edit' &&
      fieldRef.current &&
      fieldRef.current.scrollIntoView
    ) {
      fieldRef.current?.scrollIntoView();
    }
  }, [mode]);

  const getButtonStripProps = useCallback(
    // eslint-disable-next-line no-shadow
    (formikProps: FormikProps<Record<string, unknown>>): ButtonStripProps => {
      const { isValid, resetForm, submitForm } = formikProps;

      const size = 'medium' as const;

      const deleteButtonProps = {
        text: t('generic.delete'),
        color: 'error' as const,
        onClick: () => setIsDeleteDialogOpen(true),
        disabled: !canDeleteItem,
      };

      if (autoSave && mode === 'edit') {
        return {
          size,
          rightButton: deleteButtonProps,
        };
      }

      const buttonStripProps = {
        size,
        leftButton:
          mode === 'edit'
            ? deleteButtonProps
            : {
                text: t('generic.reset'),
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                onClick: resetForm as unknown as any,
                color: 'secondary' as const,
              },
        rightButton:
          mode === 'edit'
            ? {
                text: t('generic.save'),
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                onClick: submitForm as unknown as any,
                color: 'primary' as const,
                disabled: !isValid,
              }
            : {
                text: t('generic.add'),
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                onClick: submitForm as unknown as any,
                color: 'primary' as const,
                disabled: !isValid,
              },
      };

      return buttonStripProps;
    },
    [autoSave, canDeleteItem, mode, t]
  );
  const _onDeleteClicked = useCallback(() => {
    setIsDeleteDialogOpen(false);
    onDelete?.();
  }, [onDelete]);

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={(values, actions) => onSubmit?.(values, mode, actions)}
      validateOnChange
      validationSchema={formikSchema}
      {...formikProps}
    >
      {(formik) => (
        <>
          <Box sx={{ ...rootSx, p: 2, rowGap: 1 }}>
            <Box ref={fieldRef} sx={contentSx}>
              {mode === 'edit' && autoSave && (
                <AutoSave debounceMs={1500} formik={formik} />
              )}
              {formComponent}
            </Box>
            <Box sx={{ mt: 1 }}>
              <ButtonStrip
                {...(getButtonStripProps(formik) as ButtonStripProps)}
              />
            </Box>
          </Box>
          {isDeleteDialogOpen && (
            <YesNoConfirmDialog
              content={t('dialogs.deleteGenericItem.content')}
              onNo={() => setIsDeleteDialogOpen(false)}
              onYes={_onDeleteClicked}
              open={isDeleteDialogOpen}
              title={t('dialogs.deleteGenericItem.title')}
            />
          )}
          {confirmAbandonForm && (
            <OkCancelConfirmDialog
              content={t('dialogs.closeUnsavedForm.content')}
              onCancel={() => setConfirmAbandonForm(false)}
              onOk={() => {
                formik.resetForm();

                setEditingState({
                  isEditing: false,
                  hasEdited: false,
                  tabError: false,
                });
                setSelectedItem(undefined);
                setConfirmAbandonForm(false);
                if (mode === 'add') {
                  formik.resetForm();
                  setIsNewItemOpen?.(false);
                }
              }}
              open={confirmAbandonForm}
              title={t('dialogs.closeUnsavedForm.title')}
            />
          )}
        </>
      )}
    </Formik>
  );
};

export default CommonAddEditContainer;
