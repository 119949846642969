import { Grid, Typography, useTheme } from '@mui/material';
import {
  ConfirmDialogs,
  ETOTextField,
  MessageContext,
} from '@teto/react-component-library-v2';
import dayjs from 'dayjs';
import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getGraphQLClient } from '../../api/graphQL/graphQLClient';
import { INonConformance } from '../Inspectors/NonConformanceInspector/interfaces/NonConformanceAssignment';
import { updateNonConformanceMutation } from './queries/updateNonConformanceQuery';

interface ReleaseNCModalProps {
  nonConformance?: INonConformance;
  open: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onClose: React.SetStateAction<any | undefined>;
  // eslint-disable-next-line no-unused-vars
  onInspectorClose?: (dataUpdate: boolean) => void;
}

const ReleaseNCModal = (props: ReleaseNCModalProps) => {
  const { nonConformance, open, onClose, onInspectorClose } = props;

  const { t } = useTranslation();

  const theme = useTheme();

  const messageContext = useContext(MessageContext);

  const [qualityFollowUp, setQualityFollowUp] = useState<string>(
    nonConformance?.qualityFollowUp ?? ''
  );

  const _onRelease = () => {
    getGraphQLClient()
      .performMutation(
        updateNonConformanceMutation,
        {
          input: {
            ...nonConformance,
            qualityFollowUp,
            released: dayjs(),
          },
        },
        (err) => messageContext.setError(err.messages[0]),
        (err) => {
          const { input } = err;
          const fieldErr = Object.values(input)[0] as string;
          messageContext.setError(fieldErr);
        }
      )
      .then((e) => {
        if (e.updateNonConformance !== null) {
          messageContext.setSuccess(
            `${t(
              'entities:NonConformance.NonConformance'
            )} released successfully`
          );
          if (onInspectorClose) onInspectorClose(true);
          onClose();
        }
      });
  };

  return (
    <ConfirmDialogs
      content={
        <Grid container>
          <Grid
            item
            sx={{ display: 'flex', justifyContent: 'space-between' }}
            xs={12}
          >
            <Typography sx={{ color: 'text.primary' }}>
              {t('entities:NonConformance.NonConformance')} Id:
            </Typography>
            <Typography sx={{ color: 'text.primary' }}>
              {nonConformance?.id}
            </Typography>
          </Grid>
          <Grid
            item
            sx={{ display: 'flex', justifyContent: 'space-between' }}
            xs={12}
          >
            <Typography sx={{ color: 'text.primary' }}>
              {t('generic.title')}:
            </Typography>
            <Typography sx={{ color: 'text.primary' }}>
              {nonConformance?.title}
            </Typography>
          </Grid>
          <Grid
            item
            sx={{
              marginTop: theme.spacing(2),
            }}
            xs={12}
          >
            <ETOTextField
              handleChange={(e) => setQualityFollowUp(e.target.value)}
              label="Quality Follow Up"
              minRows="4"
              multiline
              name="qualityFollowUp"
              value={qualityFollowUp}
            />
          </Grid>
        </Grid>
      }
      leftButton={{
        label: t('generic.cancel'),
        onClick: () => {
          onClose();
        },
      }}
      open={open}
      rightButton={{
        label: t('pages.nonConformance.release'),
        onClick: () => {
          _onRelease();
        },
      }}
      title={`${t('pages.nonConformance.release')} ${t(
        'entities:NonConformance.NonConformance'
      )} `}
    />
  );
};

export default ReleaseNCModal;
