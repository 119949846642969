/* eslint-disable @typescript-eslint/no-explicit-any */
import { Alert, Snackbar } from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import {
  AppearanceProvider,
  LoadingPage,
  MessageContext,
  MessageProvider,
  NavProvider,
} from '@teto/react-component-library-v2';
import React, { useCallback, useContext } from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { BrowserRouter } from 'react-router-dom';
import { Permission } from 'teto-client-api';
import AuthenticatedRoutes from './AuthenticatedRoutes';
import SessionTimeout from './components/SessionTimeout/SessionTimeout';
import AuthContext, { AuthProvider } from './contexts/AuthContext';
import { ClockInTimerProvider } from './contexts/ClockInContext';
import { PunchInTimerProvider } from './contexts/PunchInTimerContext';
import { SettingsProvider } from './contexts/SettingsContext';
import AdminPermissionErrorPage from './pages/ErrorPages/AdminPermissionErrorPage';
import LoginPage from './pages/LoginPage/LoginPage';
// do not remove, ensures css is always available whenever a grid is used
import '@inovua/reactdatagrid-enterprise/base.css';
import '@inovua/reactdatagrid-enterprise/theme/default-dark.css';
import '@inovua/reactdatagrid-enterprise/theme/default-light.css';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const adminPermissions = Object.keys(Permission)
  .filter((a) => a.indexOf('_Time_Client_') < 0)
  .map((a) => (Permission as any)[a]);
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const clientPermissions = Object.keys(Permission)
  .filter((a) => a.indexOf('_Time_Client_') >= 0)
  .map((a) => (Permission as any)[a]);

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: 1,
      refetchOnWindowFocus: false,
      retryDelay: 4000,
    },
  },
});

const AppWrapper = () => (
  <BrowserRouter>
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <AppearanceProvider>
        <MessageProvider>
          <AuthProvider>
            <NavProvider>
              <QueryClientProvider client={queryClient}>
                <App />
              </QueryClientProvider>
            </NavProvider>
          </AuthProvider>
        </MessageProvider>
      </AppearanceProvider>
    </LocalizationProvider>
  </BrowserRouter>
);

const App = () => {
  const authContext = useContext(AuthContext);
  const messageContext = useContext(MessageContext);
  const renderView = useCallback(() => {
    if (!authContext.isLoaded) {
      return <LoadingPage />;
    }

    if (!authContext.authenticated) {
      return <LoginPage />;
    }

    if (
      !(
        authContext.hasAnyPermission(adminPermissions) ||
        authContext.hasAnyPermission(clientPermissions)
      )
    ) {
      return <AdminPermissionErrorPage />;
    }

    return (
      <SettingsProvider>
        <ClockInTimerProvider>
          <PunchInTimerProvider>
            <AuthenticatedRoutes />
          </PunchInTimerProvider>
        </ClockInTimerProvider>
      </SettingsProvider>
    );
  }, [authContext]);

  return (
    <>
      {renderView()}
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        autoHideDuration={3500}
        onClose={() => messageContext.clearMessage()}
        open={Boolean(messageContext.message)}
      >
        {messageContext.message ? (
          <Alert
            data-testid="message-display"
            onClose={() => messageContext.clearMessage()}
            severity={messageContext.messageType}
            sx={{ fontFamily: 'inherit', whiteSpace: 'pre-line' }}
            variant="filled"
          >
            {messageContext.message}
          </Alert>
        ) : undefined}
      </Snackbar>
      <SessionTimeout open={authContext.sessionTimeout} />
    </>
  );
};

export default AppWrapper;
