import KeyboardArrowDownRoundedIcon from '@mui/icons-material/KeyboardArrowDownRounded';

import { Box, ListItem, Paper, Typography, useTheme } from '@mui/material';
import { ETOSelectField } from '@teto/react-component-library-v2';
import React from 'react';
import Tab from '../interfaces/Tab';

interface MobileTabsProps {
  tabs: Tab[];
  currentTab: number;
  // eslint-disable-next-line no-unused-vars
  onTabChanged: (index: number) => void;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const CustomPaper = (props: any) => {
  const theme = useTheme();
  return (
    <Paper
      elevation={2}
      sx={{
        backgroundColor: theme.palette.background.paper,
        [theme.breakpoints.down('md')]: {
          '& .MuiAutocomplete-option[aria-selected="true"].Mui-focused': {
            backgroundColor: `${theme.palette.background.default} !important`,
          },
        },
      }}
      {...props}
    />
  );
};

const MobileTabs = (props: MobileTabsProps) => {
  const { tabs, currentTab, onTabChanged } = props;

  const theme = useTheme();

  if (tabs.length === 0) return <Typography>&nbsp;</Typography>;

  return (
    <Box sx={{ display: 'flex', columnGap: 1 }}>
      <ETOSelectField
        customSx={{
          flex: 1,
          flexGrow: 1,
        }}
        disableClearable
        handleChange={(e) => onTabChanged(parseInt(e.target.value, 10))}
        itemNameSelector={(a) => a.name}
        items={tabs}
        itemValueSelector={(a) => a.index}
        name="currentTab"
        PaperComponent={CustomPaper}
        popupIcon={<KeyboardArrowDownRoundedIcon />}
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        renderOption={(prps, option, { selected }: any) => (
          <ListItem
            {...prps}
            sx={{
              height: theme.spacing(5),
              width: '100%',
              padding: '0px !important',
              backgroundColor: theme.palette.background.paper,
              '& .Mui-focused': {
                backgroundColor: theme.palette.background.default,
                color: theme.palette.primary.main,
              },
            }}
          >
            <Typography
              sx={{
                display: 'flex',
                alignItems: 'center',
                color: theme.palette.text.secondary,
                width: '100%',
                height: theme.spacing(5),
                padding: `0px ${theme.spacing(2)}`,
                fontWeight: 600,
                ...(selected && { color: theme.palette.primary.main }),
              }}
              variant="subtitle2"
            >
              {option.name}
            </Typography>
          </ListItem>
        )}
        size="small"
        value={currentTab}
      />
    </Box>
  );
};

export default MobileTabs;
