import { gql } from '../../../__generated__';

export default gql(`query reportParameters($id: Int!){
  employeeReportParameters(id: $id){
   defaultValue
   parameter {
     custom
     controlTypeId
     dataTypeId
     displayName
     id
     implyWildCard
     name
     storedProcedure
   } 
   parameterID
   reportID
   reportParameterID
   required
   sequence
   vertical
   visible
   
  }
}`);
