import Box from '@mui/material/Box';
import { SxProps, Theme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import dayjs, { Dayjs } from 'dayjs';
import React, { useContext } from 'react';
import SettingsContext from '../../../../../contexts/SettingsContext';

interface NonConformanceInspectorTitleProps {
  title: string;
  titleExtension?: string | number;
  subtitle?: Dayjs;
}

const titleSx: SxProps<Theme> = {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-start',
  alignItems: 'flex-end',
  columnGap: (theme) => theme.spacing(2),
};

const NonConformanceInspectorTitle = (
  props: NonConformanceInspectorTitleProps
) => {
  const { subtitle, title, titleExtension } = props;
  const {
    settings: { dateFormat },
  } = useContext(SettingsContext);

  const subtitleElement = subtitle
    ? `Created on ${dayjs(subtitle).format(dateFormat)}`
    : '';

  return (
    <>
      <Box sx={titleSx}>
        <Typography data-testid="title" variant="h6">
          {title}
        </Typography>
        <Typography
          sx={{ color: 'text.secondary', lineHeight: 1.9 }}
          variant="body1"
        >
          {titleExtension}
        </Typography>
      </Box>
      <Typography
        data-testid="subtitle"
        fontStyle="italic"
        sx={{ color: 'text.secondary' }}
        variant="caption"
      >
        {subtitleElement}
      </Typography>
    </>
  );
};

export default NonConformanceInspectorTitle;
