import Box from '@mui/material/Box';
import { SxProps, Theme } from '@mui/material/styles';
import {
  ButtonStrip,
  ETOCheckBox,
  Inspector,
  InspectorProps,
  MessageContext,
} from '@teto/react-component-library-v2';
import { Formik } from 'formik';
import React, { ReactElement, useCallback, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import useLocalStorage from 'use-local-storage';
import { getGraphQLClient } from '../../../api/graphQL/graphQLClient';
import exportReportQuery from './exportReportQuery';

export interface ReportInspectorProps<T> extends Omit<InspectorProps, 'title'> {
  report: {
    reportId: number;
    reportForm: ReactElement;
    localStorageKey: string;
    defaultValues?: T;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    schema: any;
  };
  title: string;
  // eslint-disable-next-line no-unused-vars
  formatParameters: (data: Record<string, unknown>) => Record<string, unknown>;
}

const formRootSx: SxProps<Theme> = {
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  justifyContent: 'start',
  pt: 1,
  rowGap: 2,
};

const buttonStripSx: SxProps<Theme> = {
  pt: 0,
  border: 0,
};

const stickySx: SxProps<Theme> = {
  pl: 2,
};

const ReportInspector = <T extends object>(props: ReportInspectorProps<T>) => {
  const {
    formatParameters,
    onClose,
    open,
    report: { defaultValues, reportForm, reportId, schema },
    title,
  } = props;
  const { t } = useTranslation();
  const messageContext = useContext(MessageContext);

  // const [storedValues, setStoredValues] = useLocalStorage(
  //   localStorageKey,
  //   defaultValues
  // );
  const [useStickyValues, setUseStickyValues] = useLocalStorage(
    'useStickyValues',
    false
  );

  const _handleSubmit = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    async (values: any, actions: any) => {
      const parameters = await formatParameters(values);

      getGraphQLClient()
        .performQuery(
          exportReportQuery,
          {
            id: reportId,
            input: {
              parameters,
            },
          },
          (err) => messageContext.setError(err.messages[0]),
          (err) => {
            const errorMessage: string[] = [];
            let formikError: { [key: string]: unknown } = {};

            if ('input' in err) {
              const reportKeys = Object.keys(values);

              Object.entries(err.input).forEach((i) => {
                if (reportKeys.includes(i[0])) {
                  formikError = { ...formikError, [i[0]]: i[1] };
                } else {
                  errorMessage.push(`${i[0]}: ${i[1]}`);
                }
              });
            }
            if (errorMessage.length > 0) {
              messageContext.setError(errorMessage.join('\n'));
            }
            actions.setErrors(formikError);
          }
        )
        .then((d) => {
          if (d.exportReport.path) {
            const data = {
              companyDetails: false.toString(),
              entityId: d.exportReport.reportId.toString(),
              reportId: d.exportReport.reportId.toString(),
              type: 'generic',
            };

            let reportParams = '';

            if (
              d.exportReport.parameters &&
              d.exportReport.parameters.length > 0
            ) {
              const convertParamsToObj = d.exportReport.parameters.reduce(
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                (acc: any, current: any) => {
                  acc[current.key] = current.value;
                  return acc;
                },
                {}
              );
              reportParams = encodeURIComponent(
                JSON.stringify(convertParamsToObj)
              );
            }

            const queryString = new URLSearchParams(data).toString();
            const url = `/reportViewer?url=${encodeURIComponent(
              d.exportReport.path
            )}&${queryString}${
              reportParams ? `&reportParams=${reportParams}` : ''
            }`;

            window.open(url, '_blank');
            onClose();
          }
        })
        .catch((err) =>
          messageContext.setError(err?.messages ?? err.toString())
        )
        .finally(() => {
          actions.setSubmitting(false);
        });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [formatParameters, onClose, reportId]
  );

  return (
    <Formik
      enableReinitialize
      initialValues={defaultValues as T}
      onSubmit={(values, actions) => _handleSubmit(values, actions)}
      validateOnMount
      validationSchema={schema}
    >
      {(fProps) => (
        <Inspector
          buttonStrip={
            <ButtonStrip
              leftButton={{
                text: t('generic.cancel'),
                color: 'secondary',
                onClick: () => onClose(),
              }}
              rightButton={{
                color: 'primary',
                disabled:
                  fProps.errors && Object.keys(fProps.errors).length > 0,
                onClick: () => fProps.submitForm(),
                text: t('generic.ok'),
                loading: fProps.isSubmitting,
              }}
              size="medium"
            />
          }
          customButtonStripSx={buttonStripSx}
          onClose={onClose}
          open={open}
          title={{
            text: title,
            subText: undefined,
            substitute: undefined,
          }}
        >
          <Box sx={formRootSx}>
            <Box sx={formRootSx}>{reportForm}</Box>
            <Box sx={stickySx}>
              <ETOCheckBox
                color="primary"
                handleChange={(e) => setUseStickyValues(e.target.checked)}
                label={t('pages.inventory.useStickyValues')}
                name="useStickyValues"
                size="small"
                value={useStickyValues}
              />
            </Box>
          </Box>
        </Inspector>
      )}
    </Formik>
  );
};

export default ReportInspector;
