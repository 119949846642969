import { Box } from '@mui/material';
import { ButtonStrip, Inspector } from '@teto/react-component-library-v2';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import EditableCustomFieldFormBuilderExtension from '../../../../../TETOForms/FormBuilder/EditableCustomFieldFormBuilderExtension';
import { simpleLayout } from '../../../../../TETOForms/FormLayoutHelpers';
import TETOForm from '../../../../../TETOForms/TETOForm';
import useForm from '../../../../../TETOForms/hooks/useForm';
import useFormBuilderFromMutation from '../../../../../TETOForms/hooks/useFormBuilderFromMutation';
import { getProcesses } from '../../../../../TETOForms/selects/selects';

interface AddDetailItemFormProps {
  // eslint-disable-next-line no-unused-vars
  setIsAddPSItemDialogOpen: (value: boolean) => void;
  isAddPSItemDialogOpen: boolean;
  canAddDetails: boolean;
  pSHId: number;
}

const formSx = { flexGrow: 1, overflowY: 'auto', height: '100%' };

const AddDetailItem = (props: AddDetailItemFormProps) => {
  const {
    isAddPSItemDialogOpen,
    setIsAddPSItemDialogOpen,
    canAddDetails,
    pSHId,
  } = props;

  const { t } = useTranslation();

  const addItemFormBuilder = useFormBuilderFromMutation(
    'addProcessScheduleDetail',
    'AddProcessScheduleDetailRequestInput',
    ['processId'],
    (fb) =>
      fb
        .removeField('processId')
        .updateField('processScheduleId', {
          render: () => <div />,
          defaultValue: pSHId,
        })
        .updateField('quantityIssued', {
          disabled: true,
        })
        .updateField('quantityOrdered', {
          disabled: true,
        })
        .updateField('quantityReceived', {
          disabled: true,
        })
        .updateField('estimateHours', {
          disabled: true,
        })
        .updateField('totalHours', {
          disabled: true,
        })
        .updateField('setupHours', {
          disabled: true,
        })
        .updateField('unitEstimate', {
          disabled: true,
        })
        .updateField('lastWorkedOnDate', {
          disabled: true,
        })
        .updateField('lastReceivedCompletedDate', {
          disabled: true,
        })
        .updateField('inHouseRate', {
          disabled: true,
        })
        .addInteger({
          name: 'processId',
          title: t('entities:Process.Process'),
          selectSource: {
            fromGraphQLQuery: getProcesses.query,
            label: 'name',
            value: 'id',
          },
        })
        .updateField('ordinal', {
          render: () => <div />,
        })
        .extension(
          new EditableCustomFieldFormBuilderExtension(
            'ProcessScheduleDetail',
            'forms:AddProcessScheduleDetailRequest.Custom',
            'PS Schedule Detail Custom',
            !canAddDetails
          ),
          t
        ),
    {}
  );

  const addItemForm = useForm(addItemFormBuilder, {}, {});

  const formLayout = useMemo(
    () => simpleLayout(addItemFormBuilder.formBuilder, 2),
    [addItemFormBuilder.formBuilder]
  );
  return (
    <Inspector
      buttonStrip={
        <ButtonStrip
          leftButton={{
            text: t('generic.cancel'),
            color: 'secondary',
            onClick: () => setIsAddPSItemDialogOpen(false),
          }}
          rightButton={{
            color: 'primary',

            onClick: () => addItemForm.submit(),
            text: t('generic.add'),
            loading: addItemForm.isSubmitting,
          }}
          size="medium"
        />
      }
      onClose={() => setIsAddPSItemDialogOpen(false)}
      open={isAddPSItemDialogOpen}
      title={{
        text: `${t('generic.add')} ${t('generic.item')} to ${t(
          'generic.grid'
        )}`,
      }}
    >
      <Box sx={formSx}>
        <TETOForm form={addItemForm} formLayout={formLayout} />
      </Box>
    </Inspector>
  );
};

export default AddDetailItem;
