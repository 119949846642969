import { Box, SxProps, Theme } from '@mui/material';
import React from 'react';

const containerSx: SxProps<Theme> = {
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  justifyContent: 'flex-start',
  flexGrow: 1,
  overflowY: 'auto',
  flexWrap: 'nowrap',
  rowGap: 2,
  pt: 2,
};

interface ITabPanelWithButtonStripProps {
  children?: React.ReactNode;
  sx?: SxProps<Theme>;
  buttonStrip?: React.ReactNode;
}
const TabPanelWithButtonStrip = (props: ITabPanelWithButtonStripProps) => {
  const { children, sx, buttonStrip } = props;
  return (
    <Box
      role="tabpanel"
      sx={{ display: 'flex', flexDirection: 'column', flex: 1 }}
    >
      <>
        <Box sx={[containerSx, ...(Array.isArray(sx) ? sx : [sx])]}>
          {children}
        </Box>
        {buttonStrip && <>{buttonStrip}</>}
      </>
    </Box>
  );
};
export default TabPanelWithButtonStrip;
