import { gql } from '../../../__generated__/gql';
import SelectDefinition from './SelectDefinition';

export default {
  query: gql(`query getProcessScheduleTemplates {
    processScheduleTemplates {
      items {
        id
        name
        processScheduleTemplateDetails {
          sequence
          process {
            name
            id
          }
          inHouse
        }
      }
    }
  }`),
  valueField: 'id',
  labelField: 'name',
} as SelectDefinition;
