import { MessageContext } from '@teto/react-component-library-v2';
import { useContext } from 'react';
import { QueryKey, UseQueryOptions, useQuery } from 'react-query';
import { getGraphQLClient } from 'teto-client-api';

import getErrors from './getErrors';

const useGQLQuery = <
  T,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  TData = any,
  TVariables = { [key: string]: unknown }
>(
  queryKey: unknown[],
  query: {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    queryString: any;
    variables?: TVariables;
    // eslint-disable-next-line no-unused-vars
    callback?: (d: TData) => void;
    options?: UseQueryOptions<T, unknown, T, QueryKey>;
    finallyCallback?: () => void; // Add finallyCallback prop
  }
) => {
  const messageContext = useContext(MessageContext);
  const variables = query;

  return useQuery<T>(
    [queryKey, variables],
    () =>
      getGraphQLClient()
        .performQuery(query.queryString, query.variables)
        .then((d) => {
          if (d.hasError()) {
            if (d.hasValidationErrors()) {
              messageContext.setError(getErrors(d.validationErrors));
            }

            if (d.hasSystemErrors()) {
              messageContext.setError(getErrors(d.systemErrors));
            }
          }
          if (typeof d === 'object' && 'data' in d) {
            const data = d?.data as unknown as TData;
            if (query.callback) {
              query.callback(data);
            }
            return data;
          }
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          return d as any;
        })
        .finally(() => {
          if (query.finallyCallback) {
            query.finallyCallback();
          }
        }), // Add finally callback
    query?.options
  );
};

export default useGQLQuery;
