import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { Box, Button, SxProps, Theme, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { MessageContext } from '@teto/react-component-library-v2';
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { Permission } from 'teto-client-api';
import useLocalStorage from 'use-local-storage';
import { EngItemMaster } from '../../__generated__/graphql';
import AuthContext from '../../contexts/AuthContext';
import useInventoryLocation from '../../pages/InventoryPage/useInventoryLocation';
import PartSearch from '../../views/PartSearchView.yaml';
import GridCustomCheckbox from '../GridCustomCheckbox/GridCustomCheckbox';
import ActionButtonGridBuilderExtension from '../TETOGridGraphQL/GridBuilder/ActionButtonGridBuilderExtension';
import { defaultGridResponsiveSettings } from '../TETOGridGraphQL/TETOGridGraphQL';
import MainTetoGridGraphQL from '../TETOGridGraphQL/TETOMainGridGraphQL';
import useDynamicGridHeight from '../TETOGridGraphQL/hooks/useDynamicGridHeight';
import useGrid from '../TETOGridGraphQL/hooks/useGrid';
import { useGridBuilderFromView } from '../TETOGridGraphQL/hooks/useGridBuilder';
import { MandatoryFilters } from '../TETOGridGraphQL/types/MandatoryFilterTypes';
import useGridStyles from '../TetoGrid/GridStyles';
// eslint-disable-next-line import/no-cycle
import PartsSearchGridActionBar from './components/PartsSearchGridActionBar';
import { Filters } from './components/partSearchGridActionBarProps';
import { PartsSearchGridProps } from './partSearchGridProps';

const ALWAYS_PROJECT_COLUMNS = ['obsolete'];

const noSearchSx = {
  flexGrow: 1,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '100%',
  height: '100%',
};

const headerSx: SxProps<Theme> = {
  display: 'flex',
  flexDirection: 'column',
  pb: 2,
};

const RootQueryPath = 'engItemMastersByCriteria';
const PersistenceName = 'PartSearch';

export const gridResponsiveSettings = {
  ...defaultGridResponsiveSettings,
  breakpoints: {
    xs: { ...defaultGridResponsiveSettings.breakpoints.xs },
    md: {
      ...defaultGridResponsiveSettings.breakpoints.md,
      filterRowHeight: 0,
      enableFiltering: false,
    },
  },
};

const PartsSearchGrid = (props: PartsSearchGridProps) => {
  const {
    disableCheckBoxColumn,
    onPick,
    addMandatoryFilters,
    alwaysProjectFields,
  } = props;

  const authContext = useContext(AuthContext);
  const messageContext = useContext(MessageContext);

  const { ready, t } = useTranslation();

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [selectedRows, setSelectedRows] = useState<any>();
  const [hideFilterOptions, setHideFilterOptions] = useState<boolean>(false);
  const [configureInspector, setConfigureInspector] = useState(false);
  const { data: locations } = useInventoryLocation(0);
  const [location, setInventoryLocation] = useLocalStorage(
    'currentInventoryLocation',
    -1
  );

  const initialAdvanceSearchState = useMemo(
    () => ({
      inventoryLocation: {
        name: 'inventoryLocation',
        operator: 'equalTo',
        type: 'Int',
        value: location,
      },
      projectId: {
        name: 'projectId',
        operator: 'equalTo',
        type: 'Int',
        value: '',
      },
      specId: {
        name: 'specId',
        operator: 'equalTo',
        type: 'Float',
        value: '',
      },
    }),
    [location]
  );
  const [advanceSearch, setAdvanceSearch] = useState<Filters>(
    initialAdvanceSearchState
  );

  useEffect(() => {
    if (location === -1 && locations && locations.length > 0) {
      setInventoryLocation(locations[0]?.id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location, locations]);
  const [renderRecords, setRenderRecords] = useState<boolean>(false);

  const partsSearchPermissions = useMemo(
    () => ({
      canView: authContext.hasPermission(
        Permission.View_Engineering_ItemMaster
      ),
    }),
    [authContext]
  );

  const { gridBuilder, builderReady, error, hasError } = useGridBuilderFromView(
    PartSearch,
    RootQueryPath,
    (gb) =>
      gb
        .extension(
          new ActionButtonGridBuilderExtension([
            {
              componentName: t('pages.inventory.details.deletePart'),
              icon: <CheckCircleIcon color="primary" />,
              title: t('generic.add'),
              onClick: ({ data }) => {
                onPick([data]);
              },
            },
          ]),
          t
        )
        .updateDefinition('id', {
          filterable: false,
        })
        .updateDefinition('preferredSupplierCompany.name', {
          title: 'entities:Part.PreferredSupplierCompany',
        })
        .updateDefinition('lastSupplierCompany.name', {
          title: 'entities:Part.LastSupplierCompany',
        })
  );

  const mandatoryFilters = useMemo(() => {
    const filters: MandatoryFilters = [...(addMandatoryFilters ?? [])];

    return filters;
  }, [addMandatoryFilters]);

  const queryVariables = useMemo(() => {
    let results = {};
    Object.keys(advanceSearch).forEach((i) => {
      if (advanceSearch[i].value) {
        results = {
          ...results,
          [i]: { type: advanceSearch[i].type, value: advanceSearch[i].value },
        };
      }
    });
    return results;
  }, [advanceSearch]);

  const { gridProps } = useGrid(
    PersistenceName,
    `${RootQueryPath}.items`,
    (e) => messageContext.setError(e.message ?? e),
    t,
    alwaysProjectFields
      ? [...ALWAYS_PROJECT_COLUMNS, ...alwaysProjectFields]
      : ALWAYS_PROJECT_COLUMNS,
    gridBuilder,
    {
      filterAndSortMode: 'serverSide',
      mandatoryFilter: mandatoryFilters,
      usePersistentFilters: true,
    },
    {
      ...queryVariables,
    },
    !renderRecords,
    !renderRecords
  );

  const gridHeight = useDynamicGridHeight(
    gridProps?.dataSource?.length,
    gridResponsiveSettings?.breakpoints?.md?.rowHeight
  );

  const { filters: _filters, setFilters, allColumns, dataSource } = gridProps;

  const columns = useMemo(() => {
    const filterOutCols = allColumns.filter((col) => {
      if (col.name === 'grid.teto.action') {
        return;
      }
      if (col.name === 'id') {
        return;
      }
      return col;
    });
    return filterOutCols;
  }, [allColumns]);

  const filters = useMemo(() => {
    const filterOutFilters = _filters?.filter((col) => {
      if (col.name === 'grid.teto.action') {
        return;
      }
      // #77626
      if (col.name === 'id') {
        return;
      }
      return col;
    });
    if (!filterOutFilters) return [];
    return filterOutFilters;
  }, [_filters]);

  const onClearAllFilters = () => {
    setFilters([]);
    setAdvanceSearch(initialAdvanceSearchState);
    setInventoryLocation(-1);
  };

  const _handleRowClassName = useCallback(
    ({ data }: { data: Record<string, unknown> }) => {
      if (!data) return '';
      if ((data as EngItemMaster)?.obsolete) {
        return 'row-strike-through';
      }
      return '';
    },
    []
  );

  return (
    <>
      {partsSearchPermissions.canView && (
        <Box
          sx={[
            useGridStyles,
            {
              height: '100% !important',
              display: 'flex',
              flexDirection: 'column',
              '.InovuaReactDataGrid__column-header__filter-wrapper': {
                display: 'none',
              },
              overflow: 'auto',
              pr: isMobile ? 0 : 1,
              '&::-webkit-scrollbar': {
                display: isMobile ? 'none' : 'unset',
              },
            },
          ]}
        >
          {!hideFilterOptions && (
            <Box sx={headerSx}>
              <PartsSearchGridActionBar
                advanceSearch={advanceSearch}
                columns={columns}
                data={dataSource}
                disableGridDependentButtons={
                  !(ready && builderReady && !error) || !renderRecords
                }
                filters={filters}
                onClearAllFilters={onClearAllFilters}
                onPick={(shouldClose) => {
                  const formattedItems = Object.values(selectedRows);
                  onPick(
                    formattedItems as Partial<EngItemMaster>[],
                    shouldClose
                  );
                }}
                setAdvanceSearch={setAdvanceSearch}
                setConfigureInspector={setConfigureInspector}
                setFilters={setFilters}
                setRenderRecords={setRenderRecords}
              />
            </Box>
          )}

          {isMobile && (
            <Button
              onClick={() => setHideFilterOptions((d) => !d)}
              sx={{ textTransform: 'capitalize' }}
              variant="text"
            >
              {`${
                hideFilterOptions
                  ? `${t('generic.expand')} ${t('generic.filter', {
                      count: 2,
                    })}`
                  : `${t('generic.hide')} ${t('generic.filter', {
                      count: 2,
                    })}`
              }`}
            </Button>
          )}

          {!renderRecords && (
            <Box sx={noSearchSx}>
              <Typography
                sx={{ color: 'textSecondary' }}
                textAlign="center"
                variant="body1"
              >
                {t('generic.message.renderRecords')}
              </Typography>
            </Box>
          )}
          {renderRecords && (
            <MainTetoGridGraphQL
              builderReady={builderReady}
              error={error}
              hasError={hasError}
              ready={ready}
              rowClassName={_handleRowClassName}
              {...gridProps}
              checkboxColumn={
                isMobile || disableCheckBoxColumn ? undefined : true
              }
              configureInspector={configureInspector}
              configureInspectorSx={{ zIndex: 1300 }}
              CustomCheckbox={GridCustomCheckbox}
              customGridWrapSx={{
                minHeight: gridHeight,
              }}
              externalQueryProps={[]}
              gridProps={gridProps}
              header={{ hidden: true }}
              mobileGridOptions
              pagination
              responsiveSettings={gridResponsiveSettings}
              selectedRows={!isMobile ? selectedRows : undefined}
              setConfigureInspector={setConfigureInspector}
              setSelectedRows={
                !isMobile
                  ? // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    (d: Record<string, any>) => setSelectedRows(d)
                  : undefined
              }
              t={t}
              tableIdentifier={PersistenceName}
            />
          )}
        </Box>
      )}
    </>
  );
};

export default PartsSearchGrid;
