import { gql } from '../../../../__generated__';

const unInitializeMutation =
  gql(`mutation unInitializeMutation($input: UnInitializeProcessScheduleInput) {
    unInitializeProcessSchedule(input: $input) {
        success
    }
}`);

export default unInitializeMutation;
