import { Grid } from '@mui/material';
import Box from '@mui/material/Box';
import { SxProps, Theme } from '@mui/material/styles';
import {
  ETOButton,
  ETOCheckBox,
  ETODateField,
  ETOSelectField,
  ETOTextField,
  MessageContext,
} from '@teto/react-component-library-v2';
import { FormikHelpers, FormikProps, useFormik } from 'formik';
import { useAtom, useSetAtom } from 'jotai';
import React, { useCallback, useContext, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Permission } from 'teto-client-api';
import SaveStatusState from '../../../../SharedStateComponents/StateContainers/SaveStatusState';
import { getGraphQLClient } from '../../../../../api/graphQL/graphQLClient';
import useBuildQuery from '../../../../../api/graphQL/useBuildQuery';
import AuthContext from '../../../../../contexts/AuthContext';
import SettingsContext from '../../../../../contexts/SettingsContext';
import AutoSave from '../../../components/AddEditInspector/AutoSave/AutoSave';
import { PanelMode } from '../../../types/PanelState';

import { BreadCrumbSharedState } from '../../../../SharedStateComponents/StateContainers/BreadCrumbModalState';
import { INonConformance } from '../../interfaces/NonConformanceAssignment';
import { containerSx } from '../PanelCommonStyles';
import {
  formatNCCreateValues,
  formatNCUpdateMutation,
  getFormikInitialValues,
  getPropsInitialValues,
  isCustomCaption,
} from './InfoPanelHelpers';
import { CommonNCYupSchema, CreateNCYupSchema } from './InfoValidation';
import { TNCPanelState } from './TNCPanelState';
import PartNumberSearchInput from './components/PartNumberSearchInput';
import PurchaseOrderSearchInput from './components/PurchaseOrderSearchInput';
import {
  jobsQuery,
  nonConformanceOriginsQuery,
  nonConformanceSourcesQuery,
  projectsQuery,
} from './queries/InfoPanelQueries';
import addNonConformanceMutation from './queries/addNonConformanceMutation';
import updateNonConformanceMutation from './updateNonConformanceMutation';

type InfoValues = Partial<INonConformance> & {
  customer?: string;
  partNumber?: string;
  supplier?: string;
  purchaseOrder?: {
    id: number;
    purchaseSupplier: {
      name: string;
    };
  };
  item: {
    id: number;
    itemCompanyId: string;
  };
};

interface InfoPanelProps {
  initialValues?: Record<string, unknown>;
  panelMode: PanelMode;
  // eslint-disable-next-line no-unused-vars
  handleEditingState: (value: { isValid: boolean; isEditing: boolean }) => void;
  // eslint-disable-next-line no-unused-vars
  setPanelMode: (value: TNCPanelState) => void;
  // eslint-disable-next-line no-unused-vars
  setNonConformance: (data: Record<string, unknown>) => void;
  setHasASaveOccurred: React.Dispatch<React.SetStateAction<boolean>>;
}

interface Item {
  displayName?: string;
  description?: string;
  id: number;
}

const groupSx: SxProps<Theme> = {
  columnGap: 2,
  display: 'flex',
  justifyContent: 'space-between',
};

const createSx: SxProps<Theme> = {
  borderTop: (theme) => `2px solid ${theme.palette.divider}`,
  display: 'flex',
  flexGrow: 0,
  flexShrink: 0,
  justifyContent: 'flex-end',
  paddingTop: (theme) => theme.spacing(2),
  width: '100%',
};

const InfoPanel = (props: InfoPanelProps) => {
  const {
    initialValues,
    panelMode,
    handleEditingState,
    setPanelMode,
    setNonConformance,
    setHasASaveOccurred,
  } = props;
  const { ready, t } = useTranslation();

  const authContext = useContext(AuthContext);
  const messageContext = useContext(MessageContext);
  const {
    settings: { dateFormat },
  } = useContext(SettingsContext);
  const setSaveState = useSetAtom(SaveStatusState);

  const canModifyNC = authContext.hasPermission(
    Permission.Modify_Manufacturing_NonConformances
  );

  const levels = useAtom(BreadCrumbSharedState);
  const setLevels = useSetAtom(BreadCrumbSharedState);

  const formik = useFormik<InfoValues>({
    initialValues: getFormikInitialValues(initialValues ?? {}),
    enableReinitialize: true,
    validateOnMount: panelMode === 'create',
    validationSchema:
      panelMode === 'create'
        ? CreateNCYupSchema
        : CreateNCYupSchema.concat(CommonNCYupSchema),
    onSubmit: (values, formikHelpers) => {
      if (panelMode === 'create')
        _buildMutation(values, formikHelpers, {
          queryString: addNonConformanceMutation,
          inputVariables: {
            input: {
              ...formatNCCreateValues({ ...values }),
            },
          },
          callback: (d) => _create(d),
        });
      else
        _buildMutation(values, formikHelpers, {
          queryString: updateNonConformanceMutation,
          inputVariables: {
            input: {
              ...formatNCUpdateMutation({ ...initialValues, ...values }),
            },
          },
          callback: (d) => {
            if (d.updateNonConformance.id) {
              _updatePanelMode(d.updateNonConformance);
              const result = getPropsInitialValues({
                ...d.updateNonConformance,
                item: d.updateNonConformance.item ?? undefined,
                purchaseOrderId:
                  d.updateNonConformance?.purchaseOrderId ?? undefined,
                quantity: d.updateNonConformance?.quantity ?? undefined,
                quantityRejected:
                  d.updateNonConformance?.quantityRejected ?? undefined,
                customer:
                  d.updateNonConformance.project.company.name ?? undefined,
                supplier: d.updateNonConformance.purchaseOrder
                  ? d.updateNonConformance.purchaseOrder.purchaseSupplier.name
                  : undefined,
              });
              setNonConformance(result);
              setHasASaveOccurred(true);
              setSaveState((prev) => ({
                ...prev,
                hasSavedOccurred: true,
              }));
            }
          },
        });
    },
  });

  const _updatePanelMode = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (data: { [key: string]: any }) =>
      setPanelMode({
        id: data.id,
        creationDate: data.creationDate,
        mode: 'edit',
      }),
    [setPanelMode]
  );

  const _buildMutation = useCallback(
    (
      values: InfoValues,
      helpers: FormikHelpers<InfoValues>,
      query: {
        queryString: string;
        inputVariables: {
          input: { [key: string]: unknown };
        };
        // eslint-disable-next-line no-unused-vars, @typescript-eslint/no-explicit-any
        callback: (data: any) => void;
      }
    ) => {
      getGraphQLClient()
        .performMutation(
          query.queryString,
          query.inputVariables,
          (err) => {
            messageContext.setError(err.messages[0]);
            helpers.setSubmitting(false);
          },
          (err) => {
            const { input } = err;
            helpers.setSubmitting(false);
            formik.setErrors(input);
          }
        )
        .then((d) => {
          query.callback(d);
        })
        .catch((e) => messageContext.setError(e?.message ?? e.toString()));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const _create = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (d: any) => {
      if (d.addNonConformance.id) {
        const result = getPropsInitialValues(d.addNonConformance);
        setNonConformance(result);
        setPanelMode({
          id: result.id,
          creationDate: result.creationDate,
          mode: 'edit',
        });
        if (levels[0][0].name === 'New Non-Conformance') {
          setLevels((prev) => [
            {
              ...prev[0],
              name: `Non-Conformance: ${d.addNonConformance.title}`,
              data: d.addNonConformance,
            },
          ]);
        }
        setSaveState((prev) => ({
          ...prev,
          hasSavedOccurred: true,
        }));
        messageContext.setSuccess('Record created successfully');
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [setLevels, setNonConformance, setPanelMode]
  );

  const projects = useBuildQuery(['projects'], {
    queryString: projectsQuery,
    variables: {},
    callback: (d) => (d as { projects: { items: unknown[] } }).projects.items,
    options: { refetchOnMount: false, refetchOnWindowFocus: false },
    errorField: 'projectId',
  });

  const jobs = useBuildQuery(['jobs', formik.values.projectId], {
    queryString: jobsQuery,
    variables: { projectId: formik.values.projectId },
    callback: (d) => (d as { specs: { items: unknown[] } }).specs.items,
    options: {
      enabled: (formik?.values?.projectId as number) > 0,
      refetchOnWindowFocus: false,
    },
    errorField: 'specId',
  });

  const sources = useBuildQuery(['sources'], {
    queryString: nonConformanceSourcesQuery,
    variables: {},
    callback: (d) =>
      (d as { nonConformanceSources: { items: unknown[] } })
        .nonConformanceSources.items,
    options: { refetchOnMount: false, refetchOnWindowFocus: false },
    errorField: 'sourceId',
  });

  const origins = useBuildQuery(['nonConformanceOrigins'], {
    queryString: nonConformanceOriginsQuery,
    variables: {},
    callback: (d) =>
      (d as { nonConformanceOrigins: { items: unknown[] } })
        .nonConformanceOrigins.items,
    options: { refetchOnMount: false, refetchOnWindowFocus: false },
    errorField: 'originId',
  });

  useEffect(() => {
    handleEditingState({
      isEditing: formik.dirty,
      isValid: formik.isValid,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.dirty, formik.isValid]);

  const _handleNumberChange = (
    fieldName: string,
    value: string,
    type: string
  ) => {
    let val;
    switch (type) {
      case 'float':
        val = parseFloat(value) || null;
        break;
      default:
        val = parseInt(value, 10) || undefined;
    }
    formik.setFieldValue(fieldName, val);
  };

  const infoContainerSx = useMemo(
    () => ({
      ...containerSx,
      ...{ flexGrow: 1, flexShrink: 1 },
    }),
    []
  );

  return (
    <Box sx={infoContainerSx}>
      <Box sx={{ ...infoContainerSx, ...{ pt: 1 } }}>
        {panelMode === 'edit' && formik.values?.id && (
          <AutoSave debounceMs={3000} formik={formik} />
        )}
        {ready && (
          <Grid container spacing={2}>
            <Grid item md={4} xs={12}>
              <ETOTextField
                data-testid="nci-title"
                disabled={!canModifyNC}
                error={formik.errors.title}
                handleChange={formik.handleChange}
                helperText={`${formik.values.title?.length.toString()} \/ 100`}
                inputProps={{ maxLength: 100 }}
                label={t('entities:NonConformance.Title')}
                name="title"
                size="small"
                value={formik.values.title ?? ''}
              />
            </Grid>
            {panelMode === 'edit' && (
              <Grid item md={4} xs={12}>
                <ETOTextField
                  data-testid="nci-description"
                  disabled={!canModifyNC}
                  error={formik.errors.description}
                  handleChange={formik.handleChange}
                  helperText={`${formik.values.description?.length.toString()} \/ 4000`}
                  label={t('entities:NonConformance.Description')}
                  maxRows={3}
                  multiline
                  name="description"
                  size="small"
                  value={formik.values.description ?? ''}
                />
              </Grid>
            )}
            <Grid item md={4} xs={12}>
              <ETOSelectField
                data-testid="nci-source"
                disabled={!canModifyNC}
                error={formik.errors.sourceId}
                handleChange={formik.handleChange}
                itemNameSelector={(item: Item) =>
                  item.description ?? item?.id?.toString()
                }
                items={(sources.data as []) ?? []}
                itemValueSelector={(item: Item) => item.id}
                label={t('entities:NonConformanceSource.NonConformanceSource')}
                name="sourceId"
                size="small"
                value={formik.values.sourceId ?? null}
              />
            </Grid>
            <Grid item md={4} xs={12}>
              {' '}
              <ETOSelectField
                data-testid="nci-origin"
                disabled={!canModifyNC}
                error={formik.errors.originId}
                handleChange={formik.handleChange}
                itemNameSelector={(item: Item) =>
                  item.description ?? item?.id?.toString()
                }
                items={(origins.data as []) ?? []}
                itemValueSelector={(item: Item) => item.id}
                label={t('entities:NonConformanceOrigin.NonConformanceOrigin')}
                name="originId"
                size="small"
                value={formik.values.originId ?? null}
              />
            </Grid>

            <Grid item md={4} xs={12}>
              <ETOSelectField
                data-testid="nci-project"
                disabled={panelMode === 'edit' || !canModifyNC}
                error={formik.errors.projectId}
                handleChange={(e) => {
                  const value = parseInt(e.target.value, 10);
                  formik.setValues({
                    ...formik.values,
                    projectId: Number.isNaN(value) ? undefined : value,
                  });
                }}
                isOptionEqualToValue={(opt, val) => opt.id === val.id}
                itemNameSelector={(item: Item) =>
                  item.displayName ?? item?.id.toString()
                }
                items={(projects.data as []) ?? []}
                itemValueSelector={(item: Item) => item.id}
                label={t('entities:NonConformance.Project')}
                name="projectId"
                size="small"
                value={formik.values.projectId ?? null}
              />
            </Grid>
            <Grid item md={4} xs={12}>
              <ETOSelectField
                data-testid="nci-job"
                disabled={panelMode === 'edit'}
                error={formik.errors.specId}
                handleChange={(e: { target: { value: string } }) => {
                  const value = parseFloat(e.target.value);
                  formik.setFieldValue('specId', value || undefined);
                }}
                itemNameSelector={(item: Item) => item.displayName ?? ''}
                items={(jobs.data as []) ?? []}
                itemValueSelector={(item: Item) => item.id}
                label={t('entities:NonConformance.SpecId')}
                name="specId"
                size="small"
                value={formik.values.specId ?? null}
              />
            </Grid>

            {panelMode === 'edit' && (
              <>
                <Grid item md={4} xs={12}>
                  <ETOTextField
                    data-testid="nci-customer"
                    disabled
                    handleChange={(e) => {
                      e.stopPropagation();
                    }}
                    label={t('entities:NonConformance.Customer')}
                    name="customer"
                    size="small"
                    value={initialValues?.customer ?? ''}
                  />
                </Grid>
                <Grid item md={4} xs={12}>
                  <PartNumberSearchInput
                    disabled={!canModifyNC}
                    formik={
                      formik as unknown as FormikProps<Record<string, unknown>>
                    }
                    placeholder={t('pages.nonConformance.searchPartNumber')}
                  />
                </Grid>

                <Grid item md={4} xs={12}>
                  <PurchaseOrderSearchInput
                    disabled={!canModifyNC}
                    formik={formik}
                  />
                </Grid>

                <Grid item md={4} xs={12}>
                  <ETOTextField
                    data-testid="nci-supplier"
                    disabled
                    label={t('entities:NonConformance.Supplier')}
                    name="supplier"
                    size="small"
                    value={initialValues?.supplier ?? ''}
                  />
                </Grid>

                <Grid item md={8} xs={12}>
                  <Box sx={groupSx}>
                    <ETOTextField
                      data-testid="nci-quantity"
                      disabled={!canModifyNC}
                      error={formik.errors.quantity}
                      handleChange={(e: { target: { value: string } }) => {
                        _handleNumberChange(
                          'quantity',
                          e.target.value,
                          'float'
                        );
                      }}
                      label={t('entities:NonConformance.Quantity')}
                      name="quantity"
                      size="small"
                      type="number"
                      value={formik.values.quantity ?? ''}
                    />

                    <ETOTextField
                      data-testid="nci-quantity-rejected"
                      disabled={!canModifyNC}
                      error={formik.errors.quantityRejected}
                      handleChange={(e: { target: { value: string } }) =>
                        _handleNumberChange(
                          'quantityRejected',
                          e.target.value,
                          'float'
                        )
                      }
                      label={t('entities:NonConformance.QuantityRejected')}
                      name="quantityRejected"
                      size="small"
                      type="number"
                      value={formik.values.quantityRejected ?? ''}
                    />
                  </Box>
                </Grid>

                <Grid item md={4} xs={12}>
                  <ETOTextField
                    data-testid="nci-root-cause"
                    disabled={!canModifyNC}
                    error={formik.errors.rootCause}
                    handleChange={formik.handleChange}
                    label={t('entities:NonConformance.RootCause')}
                    maxRows={3}
                    multiline
                    name="rootCause"
                    size="small"
                    value={formik.values.rootCause ?? ''}
                  />
                </Grid>

                {isCustomCaption(
                  'custom1',
                  t('entities:NonConformance.NonConformanceCustom1')
                ) && (
                  <Grid item md={4} xs={12}>
                    <ETOTextField
                      data-testid="nci-custom1"
                      disabled={!canModifyNC}
                      error={formik.errors.custom1}
                      handleChange={formik.handleChange}
                      helperText={`${formik.values.custom1?.length.toString()} \/ 4000`}
                      label={t('entities:NonConformance.NonConformanceCustom1')}
                      maxRows={3}
                      multiline
                      name="custom1"
                      size="small"
                      value={formik.values.custom1 ?? ''}
                    />
                  </Grid>
                )}
                {isCustomCaption(
                  'custom2',
                  t('entities:NonConformance.NonConformanceCustom2')
                ) && (
                  <Grid item md={4} xs={12}>
                    <ETOTextField
                      data-testid="nci-custom2"
                      disabled={!canModifyNC}
                      error={formik.errors.custom2}
                      handleChange={formik.handleChange}
                      helperText={`${formik.values.custom2?.length.toString()} \/ 4000`}
                      label={t('entities:NonConformance.NonConformanceCustom2')}
                      maxRows={3}
                      multiline
                      name="custom2"
                      size="small"
                      value={formik.values.custom2 ?? ''}
                    />
                  </Grid>
                )}
                {isCustomCaption(
                  'custom3',
                  t('entities:NonConformance.NonConformanceCustom3')
                ) && (
                  <Grid item md={4} xs={12}>
                    <ETOTextField
                      data-testid="nci-custom3"
                      disabled={!canModifyNC}
                      error={formik.errors.custom3}
                      handleChange={(e) =>
                        formik.setFieldValue(
                          'custom3',
                          parseInt(e.target.value, 10)
                        )
                      }
                      label={t('entities:NonConformance.NonConformanceCustom3')}
                      name="custom3"
                      size="small"
                      type="number"
                      value={formik.values.custom3 ?? ''}
                    />
                  </Grid>
                )}
                {isCustomCaption(
                  'custom4',
                  t('entities:NonConformance.NonConformanceCustom4')
                ) && (
                  <Grid item md={4} xs={12}>
                    <ETOTextField
                      data-testid="nci-custom4"
                      disabled={!canModifyNC}
                      error={formik.errors.custom4}
                      handleChange={(e: { target: { value: string } }) =>
                        formik.setFieldValue(
                          'custom4',
                          parseInt(e.target.value, 10)
                        )
                      }
                      label={t('entities:NonConformance.NonConformanceCustom4')}
                      name="custom4"
                      size="small"
                      type="number"
                      value={formik.values.custom4 ?? ''}
                    />
                  </Grid>
                )}
                {isCustomCaption(
                  'custom5',
                  t('entities:NonConformance.NonConformanceCustom5')
                ) && (
                  <Grid item md={4} xs={12}>
                    <ETODateField
                      clearable
                      data-testid="nci-custom5"
                      disabled={!canModifyNC}
                      error={formik.errors.custom5}
                      handleChange={(v) => formik.setFieldValue('custom5', v)}
                      inputFormat={dateFormat}
                      label={t('entities:NonConformance.NonConformanceCustom5')}
                      name="custom5"
                      size="small"
                      value={formik.values.custom5 ?? ''}
                    />
                  </Grid>
                )}
                {isCustomCaption(
                  'custom6',
                  t('entities:NonConformance.NonConformanceCustom6')
                ) && (
                  <Grid item md={4} xs={12}>
                    <ETODateField
                      clearable
                      data-testid="nci-custom6"
                      disabled={!canModifyNC}
                      error={formik.errors.custom6}
                      handleChange={(v) => formik.setFieldValue('custom6', v)}
                      inputFormat={dateFormat}
                      label={t('entities:NonConformance.NonConformanceCustom6')}
                      name="custom6"
                      size="small"
                      value={formik.values.custom6 ?? ''}
                    />
                  </Grid>
                )}
                {isCustomCaption(
                  'custom7',
                  t('entities:NonConformance.NonConformanceCustom7')
                ) && (
                  <Grid item md={4} xs={12}>
                    <ETOCheckBox
                      data-testid="nci-custom7"
                      disabled={!canModifyNC}
                      handleChange={formik.handleChange}
                      label={t('entities:NonConformance.NonConformanceCustom7')}
                      name="custom7"
                      size="small"
                      value={
                        (formik.values.custom7 as unknown as boolean) ?? ''
                      }
                    />
                  </Grid>
                )}
                {isCustomCaption(
                  'custom8',
                  t('entities:NonConformance.NonConformanceCustom8')
                ) && (
                  <Grid item md={4} xs={12}>
                    <ETOCheckBox
                      data-testid="nci-custom8"
                      disabled={!canModifyNC}
                      handleChange={formik.handleChange}
                      label={t('entities:NonConformance.NonConformanceCustom8')}
                      name="custom8"
                      size="small"
                      value={
                        (formik.values.custom8 as unknown as boolean) ?? ''
                      }
                    />
                  </Grid>
                )}
              </>
            )}
          </Grid>
        )}
      </Box>
      {panelMode === 'create' && (
        <Box data-testid="create-btn-strip" sx={createSx}>
          <ETOButton
            color="primary"
            disabled={!formik.isValid}
            onClick={() =>
              formik.validateForm().then(() => formik.submitForm())
            }
            size="medium"
          >
            {t('generic.save')}
          </ETOButton>
        </Box>
      )}
    </Box>
  );
};

export default InfoPanel;
