import { gql } from '../../../__generated__';

export default gql(`query uOMDescriptions($filter: UomDescriptionFilterInput) {
  uOMDescriptions (where: $filter){
      items {
          description
          itemUom
          uomtype
      }
  }
}`);
