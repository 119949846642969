const getCompanyDocumentsQuery = `query companyDocuments($companyId: Int!) {
  companyDocuments(where: { companyId: {eq: $companyId}}) {
    items {
      id
      documentLocation
      pathType
      fileName
      fileExtension
      fileSize
      fileExists
      companyId
    }
  }
}`;

export default getCompanyDocumentsQuery;
