import { gql } from '../../../../__generated__';

const updatePODetailMutation = gql(`
  mutation updatePODetailGrid($input: UpdatePurchaseOrderDetailInput) {
    updatePurchaseOrderDetail(input: $input) {
      id
      itemId
      externalUOMId
      externalUOM{
        uomtype
        itemUom
        description
      }
      purchaseQty
      externalQty
      orderNumber
      extendedPrice
      purchasePrice
      dateRequired
      dateRevised
      custom1
      custom2
      custom3
      custom4
      custom7
      custom8
    }
  }`);

export default updatePODetailMutation;
