import { ETODateTimeField } from '@teto/react-component-library-v2';
import React, { useContext, useMemo } from 'react';
import SettingsContext from '../../../contexts/SettingsContext';
import { onKeyDown } from '../../TetoGrid/InlineEditing/editingHelpers';
import { CommonEditorProps } from './EditorProps';
import EditorWrapper from './EditorWrapper';
import TooltipWrapper from './TooltipWrapper';
import useIsMobile from '../../../hooks/useIsMobile';

const ETODateTimeEditor: React.FC<CommonEditorProps> = (props) => {
  const settings = useContext(SettingsContext);
  const {
    cellProps,
    editorProps,
    onChange,
    onComplete,
    onFocus,
    value,
    onCancel,
    onTabNavigation,
    onKeyDown: onKeyDownProp,
    errors,
    inputVariant,
  } = props;

  const isMobile = useIsMobile();

  const editHandlers = {
    onCancel,
    onComplete,
    onTabNavigation,
  };

  const error = useMemo(
    () => (errors && errors.length > 0 ? errors[0] : undefined),
    [errors]
  );

  const isDisabled = useMemo(() => {
    if (!editorProps?.disabled) return false;
    if (typeof editorProps?.disabled === 'function')
      return editorProps?.disabled(cellProps?.data);
    return editorProps?.disabled;
  }, [cellProps?.data, editorProps]);

  const editor = (
    <ETODateTimeField
      autoFocus
      disabled={isDisabled}
      error={error}
      handleChange={(e, i) => {
        // onBlur is not triggered if the button is used to
        // select a date however if will send back a empty i parameter
        // if i is undefined we are going to treat this as a onBlur
        onChange(e);
        if (i === undefined && e) {
          setTimeout(() => {
            onComplete(e);
          }, 100);
        }
      }}
      id={cellProps?.name as string}
      inputFormat={settings.settings.dateTimeFormat}
      InputProps={{
        onFocus,
        onKeyDown: (e: React.KeyboardEvent<HTMLInputElement>) => {
          if (onKeyDownProp) onKeyDownProp?.(e);
          else onKeyDown(e, editHandlers);
        },
        style: {
          minWidth: Math.max(0, (cellProps?.computedWidth as number) - 30),
        },
        tabIndex: 0,
      }}
      name={cellProps?.name as string}
      onBlur={(e) => {
        // check if button is triggered
        if (e) return;
        onComplete(e);
      }}
      onFocus={(e) => e.currentTarget.focus()}
      onKeyDown={(e) =>
        onKeyDown(e as React.KeyboardEvent<HTMLInputElement>, editHandlers)
      }
      size="small"
      value={value}
      variant={inputVariant}
    />
  );

  return cellProps.data !== null ? (
    <EditorWrapper
      sx={{
        '& .MuiInputBase-root': {
          boxSizing: 'border-box !important',
          maxHeight: cellProps.rowHeight
            ? (cellProps?.rowHeight as number) - 2
            : 'unset',
        },
      }}
    >
      {isMobile && <TooltipWrapper error={error}>{editor}</TooltipWrapper>}
      {!isMobile && !isDisabled && editor}
    </EditorWrapper>
  ) : null;
};

export default ETODateTimeEditor;
