import { gql } from '../../../../../__generated__';

const deleteSupplierCategoryMutation = gql(`
  mutation deleteSupplierCategory($input: DeleteSupplierCategoryInput!) {
    deleteSupplierCategory(input: $input) {
      success
    }
  }
`);

const categoryListQuery = gql(`
query getItemMasterCategories {
  itemMasterCategories {
    items {
      categoryDescription
      itemCategory
    }
  }
}`);

const addSupplierCategoryMutation = `
  mutation ($input: AddSupplierCategoryInput) {
  addSupplierCategory(input: $input) {
    companyId
    itemCategoryId
  }
}`;

export {
  deleteSupplierCategoryMutation,
  addSupplierCategoryMutation,
  categoryListQuery,
};
