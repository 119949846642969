import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import {
  Box,
  ClickAwayListener,
  FormControl,
  FormHelperText,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Popover,
  SxProps,
  TextField,
  Theme,
} from '@mui/material';
import {
  ETOButton,
  ETOIconButton,
  useDebounce,
} from '@teto/react-component-library-v2';

import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { Permission } from 'teto-client-api';

import useGQLQuery from '../../../../../../../api/graphQL/useGQLQuery';
import AuthContext from '../../../../../../../contexts/AuthContext';
import isEmptyOrWhitespace from '../../../../../../../helpers/isEmptyOrWhitespace';
import {
  SpecialNote,
  SpecialNotesLookupQueryResponse,
} from './SpecialNotesLookup/SpecialNoteLookupTypes';
import SpecialNotesLookup from './SpecialNotesLookup/SpecialNotesLookup';
import { specialNotesLookupQuery } from './SpecialNotesLookup/SpecialNotesLookupQueries';

export interface SpecialNotesInputProps {
  disabled: boolean;
  // eslint-disable-next-line no-unused-vars, @typescript-eslint/no-explicit-any
  onChange: (e: Partial<React.ChangeEvent<any>>) => void;
  // eslint-disable-next-line no-unused-vars, @typescript-eslint/no-explicit-any
  onBlur: (e: any) => void;
  value?: string;
  error?: string;
}

const moveToEndSx: SxProps<Theme> = {
  display: 'flex',
  justifyContent: 'flex-end',
  margin: 1,
  pt: 1,
  borderTop: (theme) => `1px solid ${theme.palette.divider}`,
};
const cursorSx = { cursor: 'pointer' };
const listSx: SxProps<Theme> = {
  width: '100%',
  bgcolor: 'background.paper',
  maxHeight: (theme) => theme.spacing(40),
  overflow: 'auto',
};

const dropDownSx: SxProps<Theme> = {
  width: (theme) => theme.spacing(3.25),
  height: (theme) => theme.spacing(3.25),
};
const textFieldHeightSx: SxProps<Theme> = {
  '& textarea': {
    minHeight: '69px',
    maxHeight: '69px',
    overflow: 'auto !important',
  },
};
const anchorOrigin = {
  vertical: 'bottom' as const,
  horizontal: 'left' as const,
};
const commonProps = {
  size: 'small' as const,
  multiline: true,
};

/* eslint-disable no-unused-vars */
interface SpecialNoteListItemsProps {
  anchorEl: (EventTarget & HTMLDivElement) | null;
  inputRef: React.RefObject<HTMLInputElement>;
  isSpecialNotesLookupOpen: boolean;
  onClick: (specialNote: string) => void;
  removeNote: (note: string) => void;
  refreshToken: boolean;
  setAnchorEl: (anchorEl: (EventTarget & HTMLDivElement) | null) => void;
  setIsSpecialNoteLookupOpen: (isOpen: boolean) => void;
  setRefreshToken: (refreshToken: boolean) => void;
}

interface DropDownIconProps {
  anchorEl: (EventTarget & HTMLDivElement) | null;
  disabled: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onClick: (e: any) => void;
}
/* eslint-enable no-unused-vars */
const DropDownIcon = (props: DropDownIconProps) => {
  const { anchorEl, disabled, onClick } = props;
  return (
    <ETOIconButton
      color="primary"
      customSx={{
        ...dropDownSx,
        color: (theme) => {
          if (disabled) return theme.palette.text.disabled;
        },
      }}
      onClick={onClick}
      size="large"
    >
      {anchorEl ? (
        <ArrowDropUpIcon sx={cursorSx} />
      ) : (
        <ArrowDropDownIcon sx={cursorSx} />
      )}
    </ETOIconButton>
  );
};

const SpecialNoteListItems = (props: SpecialNoteListItemsProps) => {
  const {
    anchorEl,
    inputRef,
    isSpecialNotesLookupOpen,
    onClick,
    refreshToken,
    removeNote,
    setAnchorEl,
    setIsSpecialNoteLookupOpen,
    setRefreshToken,
  } = props;

  const { t } = useTranslation();

  const authContext = useContext(AuthContext);

  const [notes, setNotes] = useState<SpecialNote[]>([]);
  const [selectedRows, setSelectedRows] = useState<number[]>([]);

  const isSpecialNotesLookupButtonDisabled = useMemo(
    () =>
      !authContext.hasAnyPermission([
        Permission.View_Admin_Lookups_SpecialNotes,
        Permission.Modify_Admin_Lookups_SpecialNotes,
        Permission.Delete_Admin_Lookups_SpecialNotes,
        Permission.Add_Admin_Lookups_SpecialNotes,
      ]),
    [authContext]
  );

  const shouldHighlightRow = useCallback(
    (id: number) => {
      const idIsSelected = selectedRows.includes(id);

      return idIsSelected
        ? {
            backgroundColor: (theme: Theme) => theme.palette.primary.dark,
          }
        : undefined;
    },
    [selectedRows]
  );
  const shouldChangeTextColor = useCallback(
    (id: number) => {
      const idIsSelected = selectedRows.includes(id);

      return idIsSelected
        ? {
            color: (theme: Theme) =>
              theme.palette.getContrastText(theme.palette.primary.dark),
          }
        : undefined;
    },
    [selectedRows]
  );

  useGQLQuery<SpecialNotesLookupQueryResponse>(
    ['specialNotesLookup', refreshToken],
    {
      queryString: specialNotesLookupQuery,
      callback: (d) => {
        const results = [...d.purchasingNotes.items];
        setNotes(results);
        setRefreshToken(false);
      },
      options: {
        refetchOnWindowFocus: false,
        enabled: Boolean(
          authContext.hasPermission(Permission.View_Admin_Lookups_SpecialNotes)
        ),
      },
    }
  );

  const _onClick = (specialNote: string, id: number) => {
    const isAlreadySelected = selectedRows.includes(id);

    if (isAlreadySelected) {
      setSelectedRows((prev) => prev.filter((row) => row !== id));
      removeNote(specialNote);
      return;
    }
    onClick(specialNote);
    setSelectedRows((prev) => [...prev, id]);
  };
  return (
    <>
      <ClickAwayListener
        mouseEvent="onMouseDown"
        onClickAway={() => setAnchorEl(null)}
        touchEvent="onTouchEnd"
      >
        <Popover
          anchorEl={anchorEl}
          anchorOrigin={anchorOrigin}
          onClose={() => setAnchorEl(null)}
          open={Boolean(anchorEl)}
          slotProps={{
            paper: {
              sx: {
                width: inputRef?.current?.clientWidth,
              },
            },
          }}
        >
          <List data-testId="special-notes-list" sx={listSx}>
            {notes.map((note) => {
              const labelId = `label-${note.id}`;

              return (
                <ListItem
                  disablePadding
                  key={note?.id}
                  sx={shouldHighlightRow(note.id)}
                >
                  <ListItemButton
                    dense
                    onClick={() => _onClick(note?.purchaseNote, note.id)}
                  >
                    <ListItemText
                      id={labelId}
                      primary={note?.purchaseNoteDesc}
                      sx={shouldChangeTextColor(note.id)}
                    />
                  </ListItemButton>
                </ListItem>
              );
            })}
          </List>
          <Box sx={moveToEndSx}>
            <ETOButton
              buttonProps={{
                onMouseDown: (e) => e.preventDefault(),
              }}
              color="primary"
              disabled={isSpecialNotesLookupButtonDisabled}
              onClick={() =>
                setIsSpecialNoteLookupOpen(!isSpecialNotesLookupOpen)
              }
              size="small"
            >
              {t('pages.purchaseOrders.purchaseOrderModal.specialNotesLookup')}
            </ETOButton>
          </Box>
        </Popover>
      </ClickAwayListener>
    </>
  );
};

const SpecialNotesInput = (props: SpecialNotesInputProps) => {
  const { disabled, value, onChange, error, onBlur } = props;

  const { t } = useTranslation();
  const inputRef = useRef<HTMLInputElement>(null);

  const [isSpecialNoteLookupOpen, setIsSpecialNoteLookupOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState<
    (EventTarget & HTMLDivElement) | null
  >(null);
  const [refreshToken, setRefreshToken] = useState(false);
  const [textBoxText, setTextBoxText] = useState(value ?? '');
  const [isDirty, setIsDirty] = useState(false);
  const debouncedNote = useDebounce(textBoxText, 300);

  const _onClick = (specialNote: string) => {
    setIsDirty(true);
    setTextBoxText((prev) => {
      const newTextBoxValue = !isEmptyOrWhitespace(prev)
        ? `${prev}\n${specialNote}`
        : specialNote;
      return newTextBoxValue;
    });
  };

  const _removeNote = (note: string) => {
    // only splits at \n when not followed by \r
    const notes = textBoxText.split(/(?<!\r)\n/);
    const newNotes = notes.filter((n) => n !== note);
    setTextBoxText(newNotes.join('\n'));
  };

  useEffect(() => {
    if (value) {
      setTextBoxText(value);
    }
  }, [value]);

  useEffect(() => {
    if (isDirty) {
      onChange({
        target: { value: debouncedNote || null },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedNote]);

  return (
    <>
      <FormControl error={Boolean(error)} fullWidth sx={textFieldHeightSx}>
        <TextField
          disabled={disabled}
          error={Boolean(error)}
          fullWidth
          InputLabelProps={{
            shrink: true,
          }}
          InputProps={{
            endAdornment: (
              <DropDownIcon
                anchorEl={anchorEl}
                disabled={false}
                onClick={(e) =>
                  anchorEl ? setAnchorEl(null) : setAnchorEl(e.currentTarget)
                }
              />
            ),
          }}
          label={t('pages.purchaseOrders.purchaseOrderModal.specialNotes')}
          name="specialNotes"
          onBlur={onBlur}
          onChange={(e) => {
            setIsDirty(true);
            setTextBoxText(e.target.value);
          }}
          ref={inputRef}
          value={textBoxText}
          {...commonProps}
        />
        {Boolean(error) && <FormHelperText>{error}</FormHelperText>}
      </FormControl>
      <SpecialNoteListItems
        anchorEl={anchorEl}
        inputRef={inputRef}
        isSpecialNotesLookupOpen={isSpecialNoteLookupOpen}
        onClick={_onClick}
        refreshToken={refreshToken}
        removeNote={_removeNote}
        setAnchorEl={setAnchorEl}
        setIsSpecialNoteLookupOpen={setIsSpecialNoteLookupOpen}
        setRefreshToken={setRefreshToken}
      />

      <SpecialNotesLookup
        isOpen={isSpecialNoteLookupOpen}
        onClose={(needsRefresh) => setRefreshToken(needsRefresh)}
        setIsOpen={setIsSpecialNoteLookupOpen}
      />
    </>
  );
};

export default SpecialNotesInput;
