import { gql } from '../../../../__generated__';

export default gql(`
  query getRFQHeader($id: Int!) {
    rFQHeader(id: $id) {
      bottomNotes
      contactName
      custom1
      custom2
      custom3
      custom4
      custom5
      custom6
      custom7
      custom8
      date
      details {
        id
        item {
          description
        }
        rFQSupplierDescription
      }
      due
      fOB
      id
      requestId
      shipToId
      shipTo {
        defaultEmailAddress
      }
      specialNotes
      terms
      topNotes
      via
    }
  }
`);
