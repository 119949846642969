import { TypeLockedRow } from '@inovua/reactdatagrid-community/types';
import {
  Box,
  Checkbox,
  FormControlLabel,
  SxProps,
  Theme,
  Typography,
} from '@mui/material';
import { OkCancelConfirmDialog } from '@teto/react-component-library-v2';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { UseQueryOptions } from 'react-query';
import useLocalStorage from 'use-local-storage';
import { Company } from '../../../__generated__/graphql';
import { TypedDocumentNode } from '../../../api/graphQL/types/TypeDocumentNode';
import useGQLQuery from '../../../api/graphQL/useGQLQuery';
import getActiveSuppliers from '../../TETOForms/selects/getActiveSuppliers';
import { CustomPaginationProps } from '../../TETOGridGraphQL/types/TetoGridGraphqlProps';
import ETOGridSelectField from '../ETOGridSelectField/ETOGridSelectField';
import { GridColumn } from '../ETOGridSelectField/ETOGridSelectFieldProps';
import {
  formSupplierDisplayValue,
  formatSupplierGridColumns,
} from './commonGridSupplierHelpers';

export interface CommonSupplierSelectFieldProps {
  error?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  value?: any;
  fieldName: string;
  label: string;
  disabled?: boolean;
  nameKey?: keyof Company | string;
  valueKey?: keyof Company | string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  customQuery?: TypedDocumentNode<any, any> | string;
  sx?: SxProps<Theme>;
  // eslint-disable-next-line no-unused-vars, @typescript-eslint/no-explicit-any
  handleChange: (e: Partial<React.ChangeEvent<any>>) => void;
  shouldFilterApproved?: boolean;
  shouldHaveWarning: boolean;
  variables?: { [key: string]: unknown };
  // eslint-disable-next-line no-unused-vars, @typescript-eslint/no-explicit-any
  customFilter?: (data: any) => Company[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  options?: UseQueryOptions<any, any, any, any>;
  hideOptionsOnClear?: boolean;
}

type SupplierApiResponse = {
  companies: {
    items: Partial<Company>[];
  };
};

const gridFieldSx: SxProps<Theme> = { width: '100% !important' };
const filterRoxSx: SxProps<Theme> = {
  display: 'flex',
  justifyContent: 'flex-end',
  alignItems: 'center',
  height: (theme) => theme.spacing(3.5),
  borderTop: (theme) => `1px solid ${theme.palette.divider}`,
  width: '100%',
};
const checkboxSx = {
  p: 0,
  pb: '2px',
};
const formControlSx = {
  m: 1.5,
};
interface FilterRowProps extends Partial<CustomPaginationProps> {
  shouldFilter: boolean;
  // eslint-disable-next-line no-unused-vars
  setShouldFilter: (value: boolean) => void;
}

export const FilterRow = (props: FilterRowProps) => {
  const { t } = useTranslation();
  const { shouldFilter, setShouldFilter } = props;

  return (
    <Box sx={filterRoxSx}>
      <Typography component="span">{`${t('generic.filterBy')} ${t(
        'generic.approved'
      )}`}</Typography>

      <FormControlLabel
        control={
          <Checkbox
            checked={shouldFilter}
            color="primary"
            data-testid="filterApprovedSuppliers"
            onMouseDown={(e) => {
              // Do not remove, this prevents the click event from bubbling up to the select field.
              // Otherwise, the select field will close.
              e.preventDefault();
              return setShouldFilter(!shouldFilter);
            }}
            sx={checkboxSx}
          />
        }
        label=""
        sx={formControlSx}
      />
    </Box>
  );
};

const CommonGridSupplierSelectField = (
  props: CommonSupplierSelectFieldProps
) => {
  const {
    customFilter,
    customQuery,
    disabled,
    error,
    fieldName,
    handleChange,
    label,
    nameKey,
    options,
    shouldFilterApproved,
    shouldHaveWarning,
    sx,
    value,
    valueKey,
    variables,
    hideOptionsOnClear,
  } = props;
  const { t } = useTranslation();

  const [shouldFilter, setShouldFilter] = useLocalStorage(
    'shouldFilterApprovedSuppliers',
    false
  );
  const [suppliers, setSuppliers] = useState<Company[]>([]);
  const [supplierConfirmation, setSupplierConfirmation] = useState(false);
  const [currSupplier, setCurrSupplier] = useState<Company>();
  const [dataSource, setDataSource] = useState<Company[]>([]);

  useGQLQuery<SupplierApiResponse>(
    [`activeCommonSuppliers-${fieldName}`, variables],
    {
      queryString: customQuery ?? getActiveSuppliers.query,
      variables,
      callback: (d) => {
        if (!customFilter) {
          setDataSource(d.companies.items ?? []);
          return;
        }
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        setDataSource(customFilter(d));
      },
      options: { refetchOnWindowFocus: false, ...options },
    }
  );

  const _handleChange = useCallback(
    (e?: Company) => {
      if (e) {
        const supplierVal = suppliers.find(
          (item) => item?.supplier?.id === e.supplier?.id
        );

        if (
          shouldHaveWarning &&
          supplierVal?.supplier?.supQaapproved !== true
        ) {
          setSupplierConfirmation(true);
          setCurrSupplier(supplierVal);
        } else {
          setCurrSupplier(supplierVal);
          handleChange({
            target: {
              name: fieldName,
              value: e.supplier?.id ?? null,
              otherInfo: { company: e },
            },
          });
        }
      } else {
        setCurrSupplier(undefined);
        handleChange({
          target: {
            name: fieldName,
            value: null,
            otherInfo: { company: e },
          },
        });
      }
    },
    [fieldName, handleChange, shouldHaveWarning, suppliers]
  );

  useEffect(() => {
    if (shouldFilter) {
      setSuppliers(
        dataSource.filter((i) => i.supplier?.supQaapproved ?? false)
      );

      return;
    }
    if (!shouldFilter) {
      setSuppliers(dataSource);
    }
  }, [dataSource, shouldFilter, value]);

  useEffect(() => {
    if (shouldFilterApproved) {
      setShouldFilter(true);
    }
  }, [setShouldFilter, shouldFilterApproved]);

  const lockedRows: TypeLockedRow[] = [
    {
      position: 'end' as const,
      render: {
        // eslint-disable-next-line react/no-unstable-nested-components
        name: () => (
          <FilterRow
            setShouldFilter={setShouldFilter}
            shouldFilter={shouldFilter}
          />
        ),
      },
      colspan: {
        // @ts-expect-error - this is a bug in the ReactDataGrid library types, this is their example https://reactdatagrid.io/docs/locked-rows
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        name: (_: any, computedProps: { visibleColumns: string | any[] }) =>
          computedProps.visibleColumns.length,
      },
    },
  ];

  return (
    <>
      <ETOGridSelectField
        customSx={{ ...gridFieldSx, ...sx }}
        disabled={disabled}
        error={error}
        fieldName={fieldName}
        gridProps={{
          lockedRows,
          columns: formatSupplierGridColumns(t) as unknown as GridColumn[],
        }}
        handleChange={(e) => _handleChange(e as Company)}
        handleRenderValue={(v, d) => formSupplierDisplayValue(v as number, d)}
        hideOptionsOnClear={hideOptionsOnClear}
        label={label}
        search={{
          inputMustMatchSearch: true,
        }}
        source={{
          items: suppliers,
          name: nameKey ?? (getActiveSuppliers.labelField as string),
          value: valueKey ?? (getActiveSuppliers.valueField as string),
        }}
        testVirtualized={process.env.NODE_ENV !== 'test'}
        value={value}
      />

      <OkCancelConfirmDialog
        content={t(
          'inspectors.engItemMasterInspector.dialogs.preferredSupplier.content'
        )}
        onCancel={() => setSupplierConfirmation(false)}
        onOk={(e) => {
          setSupplierConfirmation(false);
          handleChange({
            target: {
              name: fieldName,
              value: currSupplier?.supplier?.id ?? null,
              otherInfo: { company: e },
            },
          });
        }}
        open={supplierConfirmation}
        title={t(
          'inspectors.engItemMasterInspector.dialogs.preferredSupplier.title'
        )}
      />
    </>
  );
};

export default CommonGridSupplierSelectField;
