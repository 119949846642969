import {
  Box,
  FormControlLabel,
  Radio,
  RadioGroup,
  SxProps,
  Theme,
  Typography,
} from '@mui/material';
import {
  ButtonStrip,
  GenericDialog,
  GenericDialogProps,
  MessageContext,
} from '@teto/react-component-library-v2';
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { getGraphQLClient } from 'teto-client-api';
import useLocalStorage from 'use-local-storage';
import { gql } from '../../../../../../__generated__';
import {
  AddRfqDetailBatchInput,
  AddRfqDetailBatchItemInput,
  EngItemMaster,
} from '../../../../../../__generated__/graphql';
import getErrors from '../../../../../../api/graphQL/getErrors';
import SettingsContext from '../../../../../../contexts/SettingsContext';
import PartsSearchGrid from '../../../../../PartsSearch/PartsSearchGrid';
import FormDefinition from '../../../../../TETOForms/FormDefinition';
import { simpleLayout } from '../../../../../TETOForms/FormLayoutHelpers';
import TETOForm from '../../../../../TETOForms/TETOForm';
import useForm from '../../../../../TETOForms/hooks/useForm';
import { useFormBuilder } from '../../../../../TETOForms/hooks/useFormBuilder';
import {
  getInventoryLocations,
  getProjects,
  getSpecsByProject,
} from '../../../../../TETOForms/selects/selects';

interface PickPartsModalProps
  extends Omit<GenericDialogProps, 'title' | 'children' | 'onClose'> {
  rFQId: number;
  // eslint-disable-next-line no-unused-vars
  onClose: (refreshDataSource?: boolean) => void;
}
const fieldSetSx: SxProps<Theme> = {
  display: 'flex',
  borderColor: 'divider',
  borderRadius: 1,
  borderWidth: 'thin',
  width: '100%',
  rowGap: 2,
  flexDirection: 'column',
  mt: 2,
  mb: 2,
  flexGrow: 1,
};

const paperPropsSx = {
  height: '100%',
  width: '100%',
  overflowY: 'hidden',
  maxWidth: 'unset',
};

const contentSx = {
  display: 'flex',
  justifyContent: 'space-between',
  flexDirection: 'column',
};

const radioSx = {
  flexDirection: 'row',
  justifyContent: 'space-between',
  gap: 2,
  mb: 1,
};
export const pickPartsMutation =
  gql(`mutation addRFQDetailBatch($input: AddRFQDetailBatchInput) {
  addRFQDetailBatch(input: $input) {
    items {
      id
    }
  }
}
`);

const inventoryLocationQuery = gql(`
query getPickInventoryLocations {
  inventoryLocations(order: { id: ASC }, where: {id: {gt: 0}}) {
    items {
      id
      name
    }
  }
}
`);

const PickPartsModal = (props: PickPartsModalProps) => {
  const { onClose, rFQId, ...dialogProps } = props;
  const { t } = useTranslation();

  const settingContext = useContext(SettingsContext);
  const messageContext = useContext(MessageContext);

  const [isPickPartsOpen, setIsPickPartsOpen] = useState(false);
  const [prevProject, setPrevProject] = useLocalStorage('pickParts-project', 0);
  const [prevSpec, setPrevSpec] = useLocalStorage('pickParts-spec', 0);
  const [prevLocation, setPrevLocation] = useLocalStorage(
    'pickParts-location',
    0
  );
  const [hasPickedPart, setHasPickedPart] = useState<boolean>(false);
  const [shouldClose, setShouldClose] = useState(false);
  const [mutationVariables, setMutationVariables] =
    useState<AddRfqDetailBatchInput>();

  const formBuilder = useFormBuilder('pickParts-rfq-header', (fb) => {
    fb.addString({
      name: 'radioGroup',
      title: 'radioGroup',
      defaultValue: 'projects',
      render: (form, field, options) => (
        <RadioGroup
          name="radioGroup"
          onChange={(e) => {
            form.updateField('radioGroup', e.target.value);
          }}
          sx={radioSx}
          value={options.value}
        >
          <FormControlLabel
            control={<Radio />}
            label={`${t('entities:Project.Project')}s`}
            sx={{ flexGrow: 1 }}
            value="projects"
          />

          <FormControlLabel
            control={<Radio />}
            label={`${t('entities:Inventory.Inventory')}`}
            sx={{ flexGrow: 1 }}
            value="inventory"
          />
        </RadioGroup>
      ),
    })
      .addString({
        name: 'projectId',
        title: t('entities:Project.Project'),
        required: true,
        defaultValue: prevProject,
        selectSource: {
          fromGraphQLQuery: getProjects.query,
          label: getProjects.labelField as string,
          value: getProjects.valueField as string,
        },
        onFieldChange: (form: FormDefinition) => {
          form.updateField('specId', 0);
        },
      })
      .addInteger({
        dependsOn: ['projectId'],
        title: 'entities:Machine.Job',
        name: 'specId',
        required: true,
        defaultValue: prevSpec,
        selectSource: {
          fromGraphQLQuery: getSpecsByProject.query,
          label: getSpecsByProject.labelField as string,
          value: getSpecsByProject.valueField as string,
        },
      })
      .addString({
        name: 'inventoryLocation',
        title: t('entities:Inventory.InventoryLocation'),
        defaultValue: prevLocation,
        selectSource: {
          fromGraphQLQuery: inventoryLocationQuery,
          label: getInventoryLocations.labelField as string,
          value: getInventoryLocations.valueField as string,
        },
      });
  });

  const _handleClose = useCallback(() => {
    if (hasPickedPart) onClose(true);
    else onClose();
    setIsPickPartsOpen(false);
  }, [hasPickedPart, onClose]);

  const _handleSubmit = useCallback(
    (
      // eslint-disable-next-line no-shadow, @typescript-eslint/no-explicit-any
      fb: any,
      fd: FormDefinition,
      data: AddRfqDetailBatchInput
    ) =>
      getGraphQLClient().performMutation(pickPartsMutation, {
        input: data,
      }),
    []
  );
  const form = useForm(formBuilder, {
    onErrors: (err) => {
      messageContext.setError(getErrors(err));
    },
    onFormatRequest: () => mutationVariables,
    onSubmitting: (fb, fd, data) => _handleSubmit(fb, fd, data),
    onSubmitted: (submitContext) => {
      if (submitContext.success && 'data' in submitContext.output) {
        if (!hasPickedPart) setHasPickedPart(true);
        messageContext.setSuccess(
          t('generic.addedSuccess', { record: t('entities:Part.Part') })
        );
        if (shouldClose) onClose(true);
      } else {
        submitContext.output?.handleAllErrors(
          messageContext.setError,
          form.setErrors
        );
      }

      return Promise.resolve();
    },
  });
  const formLayout = useMemo(
    () => simpleLayout(formBuilder.formBuilder, 1),
    [formBuilder]
  );
  useEffect(() => {
    if (form.values.radioGroup === 'inventory') {
      form.enableField('inventoryLocation');
      form.updateField('projectId', settingContext.settings.inventoryProject);
      form.updateField('specId', settingContext.settings.inventorySpecID);
      form.disableField('projectId');
      form.disableField('specId');
    }
    if (form.values.radioGroup === 'projects') {
      form.updateField('inventoryLocation', undefined);
      form.disableField('inventoryLocation');
      form.enableField('projectId');
      form.enableField('specId');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    form.values.radioGroup,
    settingContext.settings.inventoryProject,
    settingContext.settings.inventorySpecID,
  ]);

  const onPartsPicked = useCallback(
    // eslint-disable-next-line no-unused-vars
    (parts: Partial<EngItemMaster>[] | undefined, close = false) => {
      if (!parts) return;
      const formattedParts: AddRfqDetailBatchItemInput[] = parts.map(
        (part) => ({
          itemId: part.id,
          itemQty: 1,
          projectId: form.values.projectId,
          specId: form.values.specId,
          destinationInventoryLocationId: form.values.inventoryLocation,
          externalUOMId: part.uOM?.itemUom ?? 0,
          partDescription: part.description,
          partNumber: part.itemCompanyId,
        })
      );
      const variables: AddRfqDetailBatchInput = {
        rFQId,
        items: formattedParts,
      };
      setMutationVariables(variables);
      setPrevProject(form.values.projectId);
      setPrevSpec(form.values.specId);
      setPrevLocation(form.values.inventoryLocation);
      setShouldClose(close);
      form.submit();
    },
    [form, rFQId, setPrevLocation, setPrevProject, setPrevSpec]
  );

  const isDisabled = useMemo(() => {
    if (form.values.radioGroup === 'inventory') {
      return !form.values.inventoryLocation;
    }
    return !form.values.projectId || !form.values.specId;
  }, [
    form.values.inventoryLocation,
    form.values.projectId,
    form.values.radioGroup,
    form.values.specId,
  ]);

  return (
    <GenericDialog
      title={t('entities:RFQDetail.RFQDetails')}
      {...dialogProps}
      contentSx={contentSx}
      fullScreen
      onClose={_handleClose}
      PaperProps={{ sx: paperPropsSx }}
    >
      {!isPickPartsOpen && (
        <>
          <Typography>
            {t('pages.rFQ.rFQModal.pickPartsModalContent')}
          </Typography>
          <Box component="fieldset" sx={fieldSetSx}>
            <Box component="legend">
              {t('pages.rFQ.rFQModal.pickPartFormHeader')}
            </Box>
            <TETOForm form={form} formLayout={formLayout} />
          </Box>
        </>
      )}
      {isPickPartsOpen && (
        <PartsSearchGrid
          alwaysProjectFields={['uOM.itemUom', 'id']}
          onPick={onPartsPicked}
        />
      )}
      <ButtonStrip
        leftButton={{
          text: isPickPartsOpen ? t('generic.back') : t('generic.cancel'),
          onClick: () => {
            if (isPickPartsOpen) {
              setIsPickPartsOpen(false);
            }
            if (!isPickPartsOpen) {
              _handleClose();
            }
          },
          color: 'secondary',
        }}
        rightButton={
          !isPickPartsOpen
            ? {
                text: `${t('generic.pick')} ${t('entities:Part.Part_other')}`,
                onClick: () => setIsPickPartsOpen(true),
                color: 'primary',
                disabled: isDisabled,
              }
            : undefined
        }
        size="small"
      />
    </GenericDialog>
  );
};

export default PickPartsModal;
