import { Box, Grid, SxProps, Theme } from '@mui/material';
import {
  ETOButton,
  ETOCheckBox,
  ETOTextField,
  MessageContext,
} from '@teto/react-component-library-v2';
import dayjs from 'dayjs';
import { t } from 'i18next';
import * as React from 'react';
import { useContext, useMemo, useState } from 'react';
import { useQuery } from 'react-query';
import type { TypedDocumentNode } from '@graphql-typed-document-node/core';
import { getGraphQLClient } from 'teto-client-api';
import getRFQDetailsQuery from '../../ProcBreakdownInspector/queries/getRFQDetailsQuery';
import getPurchasingItemDetailsSummaryByItemQuery from '../../ProcBreakdownInspector/queries/getPurchasingItemDetailsSummaryByItemQuery';
import SettingsContext from '../../../../contexts/SettingsContext';
import getErrors from '../../../../api/graphQL/getErrors';

import {
  PurchasingItemDetailSummary,
  PurchasingSummary,
  RfqDetail,
} from '../../../../__generated__/graphql';
import useRFQModalToggle from '../../../Modals/SelectRFQModal/useRFQModalToggle';

interface PartDetailsProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  detail?: { [key: string]: unknown | any };
  ignoreLocation: boolean;
}

const container: SxProps<Theme> = {
  display: 'flex',
  flexDirection: 'column',
  flexGrow: 1,
  flexShrink: 1,
  height: '100%',
  justifyContent: 'flex-start',
  overflow: 'auto',
  pt: 1,
  rowGap: 2,
};

const PartDetails = (props: PartDetailsProps) => {
  const { detail, ignoreLocation } = props;

  const messageContext = React.useContext(MessageContext);
  const {
    settings: { dateFormat },
  } = useContext(SettingsContext);

  const [purchasingItemsDetails, setPurchasingItemsDetails] =
    useState<PurchasingItemDetailSummary>();
  const [rfqDetails, setRFQDetails] = useState<RfqDetail>();
  const { rFQModalToggle } = useRFQModalToggle();

  const requiredVariables = useMemo(
    () => ({
      projectId: detail?.projectId,
      specId: detail?.specId,
      itemId: detail?.itemId,
    }),
    [detail]
  );

  const getInventoryLocationId = useMemo(() => {
    if (typeof detail?.inventoryLocationId === 'number') {
      return detail?.inventoryLocationId;
    }

    if (typeof detail?.inventoryLocationId === 'object') {
      return detail?.inventoryLocationId?.id;
    }
  }, [detail?.inventoryLocationId]);

  const variables = useMemo(
    () => ({
      itemId: detail?.itemId,
      inventoryLocationId: ignoreLocation ? 0 : getInventoryLocationId,
      destinationLocationId: detail?.destInventoryLocId,
      processScheduleId: detail?.processScheduleId,
    }),
    [
      detail?.destInventoryLocId,
      detail?.itemId,
      detail?.processScheduleId,
      getInventoryLocationId,
      ignoreLocation,
    ]
  );

  const purchasingItemDetailsSummaryByItemQuery = async () => {
    await getGraphQLClient()
      .performQuery(
        getPurchasingItemDetailsSummaryByItemQuery as TypedDocumentNode,
        { ...requiredVariables, ...variables }
      )
      .then((d) => {
        if (d.hasError()) {
          if (d.hasSystemErrors()) {
            messageContext.setError(getErrors(d.systemErrors));
          }
          if (d.hasValidationErrors()) {
            messageContext.setError(getErrors(d.validationErrors));
          }
          return;
        }

        return setPurchasingItemsDetails(
          d?.data?.purchasingItemDetailsSummaryByItem?.items?.[0]
        );
      });
  };

  const rfqDetailsQuery = async () => {
    await getGraphQLClient()
      .performQuery(getRFQDetailsQuery as TypedDocumentNode, requiredVariables)
      .then((d) => {
        if (d.hasError()) {
          if (d.hasSystemErrors()) {
            messageContext.setError(getErrors(d.systemErrors));
          }
          if (d.hasValidationErrors()) {
            messageContext.setError(getErrors(d.validationErrors));
          }
          return;
        }

        return setRFQDetails(d?.data?.rFQDetails.items?.length ?? 0);
      });
  };

  useQuery(
    'purchasing-items-details',
    async () => purchasingItemDetailsSummaryByItemQuery(),
    {
      enabled: true,
    }
  );

  useQuery('rfq-details', async () => rfqDetailsQuery(), {
    enabled: true,
  });

  const spanFullWidth = useMemo(() => ({ gridColumn: 'span 2' }), []);

  const bottomCheckboxes = useMemo(
    () => [
      {
        label: 'ItemMaintenance',
        name: 'maintenance',
        value: purchasingItemsDetails?.itemMaintenance,
      },
      {
        label: 'ItemCertifiedPrints',
        name: 'certified-print',
        value: purchasingItemsDetails?.itemCertifiedPrints,
      },
      {
        label: 'ItemRelub',
        name: 'relubricatable',
        value: purchasingItemsDetails?.itemRelub,
      },
      {
        label: 'Obsolete',
        name: 'obsolete',
        value: purchasingItemsDetails?.obsolete,
      },
      {
        label: 'ItemReserved',
        name: 'locked',
        value: purchasingItemsDetails?.itemReserved,
      },
      {
        label: 'ItemRepairable',
        name: 'default-spare',
        value: purchasingItemsDetails?.itemRepairable,
      },
    ],
    [purchasingItemsDetails]
  );

  const textFields = useMemo(
    () => [
      {
        label: 'ItemUOM',
        name: 'uom',
        value: purchasingItemsDetails?.uOMDescription ?? '',
      },
      {
        label: 'ItemCategory',
        name: 'category',
        value: purchasingItemsDetails?.itemCategoryDescription ?? '',
      },
      {
        label: 'ItemLastCost',
        name: 'last-cost',
        value: purchasingItemsDetails?.itemLastCost ?? '',
      },
      {
        label: 'ItemListCost',
        name: 'list-cost',
        value: purchasingItemsDetails?.itemListCost ?? '',
      },
      {
        label: 'PreferredSupplier',
        name: 'preferred-supplier',
        value: purchasingItemsDetails?.preferredSupplier ?? '',
      },
      {
        label: 'MfgBegin',
        name: 'mfg-begin-date',
        value: purchasingItemsDetails?.mfgBegin
          ? dayjs(purchasingItemsDetails?.mfgBegin).format(dateFormat)
          : '',
      },
      {
        label: 'SupplierPartNumber',
        name: 'supplier-part-number',
        value: purchasingItemsDetails?.supplierPartNumber ?? '',
        sxProps: spanFullWidth,
      },
      {
        label: 'Manufacturer',
        name: 'manufacturer',
        value: purchasingItemsDetails?.manufacturer ?? '',
        sxProps: spanFullWidth,
      },
      {
        label: 'ManufacturerPartNumber',
        name: 'manufacturer-part-number',
        value: purchasingItemsDetails?.manufacturerPartNumber ?? '',
        sxProps: spanFullWidth,
      },
      {
        label: 'ItemWeight',
        name: 'weight',
        value: purchasingItemsDetails?.itemWeight ?? '',
      },
      {
        label: 'EstimatedLeadTime',
        name: 'estimated-lead-time',
        value: purchasingItemsDetails?.estimatedLeadTime ?? '',
      },
      {
        label: 'CountryOfOrigin',
        name: 'country-of-origin',
        value: purchasingItemsDetails?.countryOfOrigin ?? '',
      },
      {
        label: 'TariffCode',
        name: 'tariff-code',
        value: purchasingItemsDetails?.tariffCode ?? '',
      },
      {
        label: 'RawMaterialItemID',
        name: 'raw-material',
        value: purchasingItemsDetails?.rawMaterialItemID ?? '',
      },
      {
        label: 'RawMaterialCutLength',
        name: 'raw-mat-amount',
        value: purchasingItemsDetails?.rawMaterialCutLength ?? '',
      },
      {
        label: 'Drawing',
        name: 'drawing',
        value: purchasingItemsDetails?.drawing ?? '',
      },
      {
        label: 'RFQs',
        name: 'rFQs',
        value: rfqDetails,
      },
    ],
    [dateFormat, purchasingItemsDetails, rfqDetails, spanFullWidth]
  );

  return (
    <Box component="form" sx={container}>
      <Grid container spacing={2}>
        <Grid item md={4} xs={12}>
          <ETOTextField
            disabled
            inputLabelProps={{ shrink: true }}
            label={t('entities:PurchaseOrderDetail.PurchaseSupplierItem')}
            multiline
            name="part-number"
            size="small"
            value={purchasingItemsDetails?.itemCompanyId ?? ''}
          />
        </Grid>
        <Grid item md={4} xs={12}>
          <ETOTextField
            disabled
            inputLabelProps={{ shrink: true }}
            label={t('entities:PurchasingItemDetailSummary.ItemDescription')}
            multiline
            name="part-description"
            size="small"
            value={purchasingItemsDetails?.itemDescription ?? ''}
          />
        </Grid>
        {textFields.length > 0 &&
          textFields.map((input) => (
            <Grid item key={input.label} md={4} xs={12}>
              <ETOTextField
                disabled
                inputLabelProps={{ shrink: true }}
                key={input.name}
                label={t(`entities:PurchasingItemDetailSummary.${input.label}`)}
                name={input.name}
                size="small"
                sxProps={input.sxProps}
                value={input.value}
              />
            </Grid>
          ))}
        {rfqDetails ? (
          <Grid item md={4} xs={12}>
            <ETOButton
              color="primary"
              customSx={{ mt: 0.5 }}
              onClick={() => rFQModalToggle(detail as PurchasingSummary)}
              size="small"
            >
              {`${t('entities:PurchasingItemDetailSummary.RFQs')}`}
            </ETOButton>
          </Grid>
        ) : null}
      </Grid>

      <Grid container spacing={2}>
        {bottomCheckboxes.length > 0 &&
          bottomCheckboxes.map((checkbox) => (
            <Grid item key={checkbox.label} md={4} xs={6}>
              <Box key={checkbox.name}>
                <ETOCheckBox
                  disabled
                  label={t(
                    `entities:PurchasingItemDetailSummary.${checkbox.label}`
                  )}
                  name={checkbox.name}
                  size="small"
                  value={checkbox.value ?? false}
                />
              </Box>
            </Grid>
          ))}
      </Grid>
    </Box>
  );
};

export default PartDetails;
