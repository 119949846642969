import { gql } from '../../../__generated__';

export default gql(`mutation addStandardAssembly ($input: AddStandardAssemblyInput) {
  addStandardAssembly (input: $input){
    id
    item {
      itemCompanyId
    }
    itemCutLength
    itemFabrication
    itemId
    itemQty
    note
    orEquals
    sparePart
    standardAssemblyItemId
  }
}`);
