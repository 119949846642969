import { TypeRowProps } from '@inovua/reactdatagrid-community/types';
import { TypeOnSelectionChangeArg } from '@inovua/reactdatagrid-community/types/TypeDataGridProps';
import ReactDataGrid from '@inovua/reactdatagrid-enterprise';
import { Box, Theme, Typography, useMediaQuery, useTheme } from '@mui/material';
import { ButtonStrip } from '@teto/react-component-library-v2';
import { useAtom } from 'jotai';
import { uniqueId } from 'lodash';
import React, { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { gql } from '../../../__generated__';
import useCurrentTheme from '../../../hooks/useCurrentTheme';
import formatRFQGridColumns from '../../InputFields/CommonGridRFQSelectField/commonGridRFQHelpers';
import { RFQSharedState } from '../../SharedStateComponents/StateContainers/RFQState';
import useStyles from '../../TetoGrid/GridStyles';
import { reactDataGridLicenseKey } from '../../TetoGrid/Licensing';

export const getRFQDetails =
  gql(`query rfqDetails($itemId: Int!, $projectId: Int!, $specId: Float!) {
    rFQDetails(where: {
      and: [
        { projectId: {eq: $projectId}},
        { specId: {eq: $specId}},
        { itemId: {eq: $itemId}}
      ]
      }) {
      items {
        rFQId
        rFQ {
          date
          companies {
            company {
              name,
              phone,
              city,
              state
            }
          }
        }
      }
    }
  }`);

const modalSx = (theme: Theme) => ({
  pt: theme.spacing(2),
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
});

const textSx = (theme: Theme) => ({
  pb: theme.spacing(2),
});

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type rfqListType = Omit<any, 'companyId'>[];

interface SelectRFQModalProps {
  rFQList: rfqListType;
}

const SelectRFQModal = (props: SelectRFQModalProps) => {
  const { rFQList } = props;
  const theme = useTheme();
  const currentTheme = useCurrentTheme();
  const gridStyles = useStyles(theme);
  const { t } = useTranslation();
  const columns = useMemo(() => formatRFQGridColumns(t), [t]);
  const dataSource = useMemo(
    () => rFQList.map((r) => ({ ...r, id: uniqueId() })),
    [rFQList]
  );
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [selected, setSelected] = useState<Omit<any, 'companyId'> | undefined>(
    dataSource[0]
  );
  const [, setRFQ] = useAtom(RFQSharedState);
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const _handleRowSelection = (row: TypeOnSelectionChangeArg) => {
    if (!row.selected || typeof row.selected === 'object') {
      setSelected(undefined);
      return;
    }
    setSelected(row.data);
  };

  const _handleMobileRowSelection = useCallback(
    (row: TypeRowProps) => {
      if (!isMobile) return;
      setSelected(row.data);
    },
    [isMobile]
  );

  const _handleConfirmSelection = () => {
    setRFQ((prev) => ({
      ...prev,
      open: true,
      initialValues: {
        id: selected?.rFQId,
      },
    }));
  };

  return (
    <Box sx={modalSx}>
      <Typography sx={textSx}>
        {t('pages.rFQ.rFQModal.dialogs.selectRFQ')}
      </Typography>
      <Box sx={gridStyles && { height: '100%' }}>
        <ReactDataGrid
          checkboxColumn={!isMobile}
          checkboxOnlyRowSelect={false}
          columns={columns}
          dataSource={dataSource}
          defaultGroupBy={['rFQId']}
          defaultSelected={dataSource[0].id}
          idProperty="id"
          licenseKey={reactDataGridLicenseKey}
          multiSelect={false}
          onRowClick={(row) => _handleMobileRowSelection(row)}
          onSelectionChange={(r) => {
            _handleRowSelection(r);
          }}
          scrollProps={{
            scrollThumbStyle: {
              backgroundColor: theme.palette.primary.main,
            },
          }}
          theme={currentTheme}
          virtualized={false}
        />
      </Box>
      <Box sx={{ mt: theme.spacing(2) }}>
        <ButtonStrip
          leftButton={{
            text: t('generic.cancel'),
            color: 'secondary',
          }}
          rightButton={{
            text: t('generic.ok'),
            color: 'primary',
            onClick: () => _handleConfirmSelection(),
            disabled: !selected,
          }}
          size="medium"
        />
      </Box>
    </Box>
  );
};

export default SelectRFQModal;
