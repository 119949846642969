import { FormikErrors, FormikProps } from 'formik';
import { getGraphQLClient } from 'teto-client-api';
import { TypedDocumentNode } from './types/TypeDocumentNode';

export type QueryStringType<
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  TData = any,
  TVariables = { [key: string]: unknown }
> = TypedDocumentNode<TData, TVariables>;

const buildMutation = async <
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  TData = any,
  TVariables = { [key: string]: unknown }
>(query: {
  queryString: QueryStringType<TData, TVariables>;
  variables: TVariables;
  errorField?: string;
  // eslint-disable-next-line no-unused-vars, @typescript-eslint/no-explicit-any
  callback?: (data: any) => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  messageContext: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  formik?: FormikProps<any>;
  // eslint-disable-next-line no-unused-vars, @typescript-eslint/no-explicit-any
  errorCallback?: (error?: any) => void;
}) => {
  getGraphQLClient()
    .performMutation(query.queryString, query.variables)
    .then((d) => {
      if (d.hasError()) {
        if (d.hasValidationErrors()) {
          const { input } = d.validationErrors;
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          query.formik?.setErrors(input as unknown as FormikErrors<any>);
        }
        if (d.hasSystemErrors()) {
          const err = d.systemErrors
            .map((message, index) => {
              const isLastKey = index === d.systemErrors.length - 1;
              return isLastKey ? message : `${message}\n \n`;
            })
            .join('');
          query.messageContext.setError(err);
        }
        // d.handleAllErrors(query.messageContext, query.formik?.setErrors);
        if (query.errorCallback) query.errorCallback(d);
        return;
      }
      if (query.callback) {
        if (typeof d === 'object' && 'data' in d && d.data) {
          const { data } = d;
          query.callback(data);
        } else {
          query.callback(d);
        }
      }
    })
    .catch((e) => {
      if (query.errorCallback) query.errorCallback(e);
    })
    .finally(() => {
      if (query.formik) {
        query.formik.setSubmitting(false);
      }
    });
};

export default buildMutation;
