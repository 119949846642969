// Default
// eslint-disable-next-line import/no-named-as-default
import TreeSelect from './TreeSelect';

export {
  DefaultOption,
  PathIcon,
  TreeSelect,
  getDefaultOptionProps,
} from './TreeSelect';
export type {
  BaseDefaultOptionsProps,
  DownBranchDefaultOptionsProps,
  IndividualTooltipProps,
  RenderOption,
  TreeSelectProps,
  TreeSelectRenderOptionState,
  UpBranchDefaultOptionsProps,
} from './TreeSelect';
export { FreeSoloNode } from './useTreeSelect';
export type {
  PathDirection,
  SyncOrAsync,
  TreeSelectFreeSoloValueMapping,
  TreeSelectValue,
} from './useTreeSelect';

export default TreeSelect;
