import { TypeSingleFilterValue } from '@inovua/reactdatagrid-community/types';
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import ClearRoundedIcon from '@mui/icons-material/ClearRounded';
import PlaylistAddRoundedIcon from '@mui/icons-material/PlaylistAddRounded';
import SearchRoundedIcon from '@mui/icons-material/SearchRounded';
import SettingsRoundedIcon from '@mui/icons-material/SettingsRounded';
import {
  Box,
  Divider,
  SxProps,
  Theme,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import {
  ETOButton,
  ETOTextField,
  GenericDialog,
} from '@teto/react-component-library-v2';
import { useAtom, useSetAtom } from 'jotai';
import { uniqueId } from 'lodash';
import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import useLocalStorage from 'use-local-storage';
import { useResetAtom } from 'jotai/utils';
import FAB from '../../FAB/FAB';
import ItemCategorySelectField from '../../Inspectors/EngItemMasterInspector/panels/PartDetailsPanel/PartForm/InputFields/ItemCategorySelectField';
import UOMSelectField from '../../Inspectors/EngItemMasterInspector/panels/PartDetailsPanel/PartForm/InputFields/UOMSelectField';

import InventoryLocationInput from '../../InputFields/InventorySelectField/InventoryLocationInput';
import JobInputField from '../../InputFields/JobInputField/JobInputField';
import ProjectInputField from '../../InputFields/ProjectInputField/ProjectInputField';
import { ItemMasterSharedState } from '../../SharedStateComponents/StateContainers/ItemMasterState';
import ReloadState from '../../SharedStateComponents/StateContainers/ReloadState';
import FilterColumnsPanel from '../../TETOGridGraphQL/ConfigureInspector/Panels/FilterColumnsPanel/FilterColumnsPanel';
import { getOperatorTypes } from '../../TetoGrid/Filtering/filteringHelpers';
import DefaultFilterWrapper from './DefaultFilterWrapper';
import defaultSearchFields from './defaultSearchFields';
import FilterValue from './filterValue';
import { PartsSearchGridActionBarProps } from './partSearchGridActionBarProps';
// eslint-disable-next-line import/no-cycle
import EngItemMasterInspector from '../../Inspectors/EngItemMasterInspector/EngItemMasterInspector';

const customListDisplaySx = (theme: Theme) => ({
  display: 'grid',
  gridTemplateColumns: '1fr 1fr 1fr',
  gap: theme.spacing(2), // should only be applied to top and bottom
  [theme.breakpoints.down('md')]: {
    gridTemplateColumns: '1fr',
  },
});

const customListSx = {
  pr: 0,
  mt: 2,
  mb: 2,
  overflowY: 'hidden',
  flexGrow: '0 !important',
};

const dividerSx: SxProps<Theme> = {
  width: '97%',
  position: 'absolute',
};

const titleSx = {
  color: 'primary.main',
  fontSize: (theme: Theme) => theme.typography.subtitle1,
  p: 0,
  pl: 1,
  pb: 0.5,
};

const contentSx = { p: 0, pl: 1, pr: 1 };

const inputPaddingFixSx = { pl: 0 };
type JSX = JSX.Element;

const PartsSearchGridActionBar = (props: PartsSearchGridActionBarProps) => {
  const {
    advanceSearch,
    columns,
    data,
    disableGridDependentButtons,
    filters,
    onClearAllFilters,
    onPick,
    setAdvanceSearch,
    setConfigureInspector,
    setFilters,
    setRenderRecords,
  } = props;

  const theme = useTheme();

  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const isLessThanLarge = useMediaQuery(theme.breakpoints.down('lg'));
  const [isCreatePartOpen, setIsCreatePartOpen] = useState(false);

  const setReload = useSetAtom(ReloadState);

  const { t } = useTranslation();

  const [searchFilters, setSearchFilters] = useState<
    TypeSingleFilterValue[] | null
  >(filters);
  const [inventoryLocation, setCurrentInventoryLocation] = useLocalStorage(
    'currentInventoryLocation',
    -1
  );
  const [showLoading, setShowLoading] = useState<boolean>(false);
  const [resetShownFilters, setResetShownFilters] = useState<boolean>(false);

  useEffect(() => {
    setSearchFilters(filters);
  }, [filters]);

  useEffect(() => {
    if (showLoading) {
      setReload(true);
      const timer = setTimeout(() => {
        setShowLoading(false);
      }, 100);

      return () => clearTimeout(timer);
    }
    setReload(false);
  }, [setReload, showLoading]);

  const resetItemMaster = useResetAtom(ItemMasterSharedState);
  const [itemMaster, setItemMaster] = useAtom(ItemMasterSharedState);

  const _handleClearAllFilters = useCallback(() => {
    onClearAllFilters();
    setSearchFilters([]);
    setResetShownFilters(true);
    setCurrentInventoryLocation(-1);
  }, [onClearAllFilters]);

  const SearchButton = useMemo(
    () => ({
      title: t('generic.search'),
      icon: <SearchRoundedIcon />,
      onclick: () => {
        // after the filters are set (handled by the filterColumnsPanel),
        // Then once the user clicks the search button, we want to set the filters
        if (setRenderRecords) setRenderRecords(true);
        if (searchFilters) setFilters(searchFilters);

        if (data && data.length > 0) {
          setShowLoading(true);
        }
      },
      disabled: false,
    }),
    [data, searchFilters, setFilters, setRenderRecords, t]
  );

  const CreateButton = useMemo(
    () => ({
      icon: <AddRoundedIcon />,
      title: t('pages.inventory.details.createPart'),
      onclick: () => setIsCreatePartOpen(true),
      disabled: false,
    }),
    [t]
  );

  const ClearButton = useMemo(
    () => ({
      icon: <ClearRoundedIcon />,
      title: t('generic.clear'),
      onclick: () => _handleClearAllFilters(),
      disabled: false,
    }),
    [_handleClearAllFilters, t]
  );

  const PickButton = useMemo(
    () => ({
      icon: <PlaylistAddRoundedIcon />,
      title: `${t('generic.pick')} ${t('generic.selected')}`,
      onclick: () => onPick(true),
      disabled: disableGridDependentButtons,
    }),
    [disableGridDependentButtons, onPick, t]
  );

  const ConfigureBtn = useMemo(
    () => ({
      title: t('generic.configure'),
      icon: <SettingsRoundedIcon />,
      onclick: () => setConfigureInspector(true),
      disabled: disableGridDependentButtons,
    }),
    [disableGridDependentButtons, setConfigureInspector, t]
  );

  const rightButtons = useMemo(
    () =>
      isMobile
        ? [CreateButton, ClearButton, ConfigureBtn]
        : [SearchButton, CreateButton, ClearButton, PickButton],

    [
      isMobile,
      CreateButton,
      ClearButton,
      ConfigureBtn,
      SearchButton,
      PickButton,
    ]
  );

  const _handleSearchValueUpdate = useCallback(
    (val: number | string, col: string) => {
      const currColumn = searchFilters?.map((i) =>
        i.name === col ? { ...i, value: val } : i
      );
      setSearchFilters(currColumn as TypeSingleFilterValue[]);
    },
    [searchFilters]
  );

  const defaultFieldValues = useMemo(() => {
    if (searchFilters?.length === 0) return undefined;
    const result: Record<string, FilterValue> = {};
    searchFilters
      ?.filter((i) => defaultSearchFields.indexOf(i.name) >= 0)
      .forEach((i) => {
        result[i.name] = {
          name: i.name,
          operator: i.operator || 'equal',
          options:
            i.type === 'string'
              ? getOperatorTypes(i.type)
              : ['equal', 'notEqual'],
          value: i.value ?? '',
        };
      });
    return result;
  }, [searchFilters]);

  const _handleOperatorUpdate = useCallback(
    (val: string, col: string) => {
      const currColumn = searchFilters?.map((i) =>
        i.name === col ? { ...i, operator: val } : i
      );
      setSearchFilters(currColumn as TypeSingleFilterValue[]);
    },
    [searchFilters]
  );

  const filterColumns = useMemo(
    () => columns.filter((i) => !(defaultSearchFields.indexOf(i.name) >= 0)),
    [columns]
  );

  const handleAdvanceSearch = useCallback(
    (name: string, value: unknown) => {
      setAdvanceSearch((d) => ({
        ...d,
        [name]: {
          ...d[name],
          value,
        },
      }));
    },
    [setAdvanceSearch]
  );

  const customSelectColumnEditorSx = useMemo((): SxProps<Theme> | undefined => {
    if (isMobile) {
      return { pt: 0 };
    }
    if (isLessThanLarge) {
      return { width: '225px', pt: 0 };
    }
    return { width: '300px', pt: 0 };
  }, [isLessThanLarge, isMobile]);

  const buttonSx = useMemo(() => {
    if (isLessThanLarge) {
      return { ml: 1 };
    }
    return { ml: 2 };
  }, [isLessThanLarge]);

  useLayoutEffect(() => {
    if (itemMaster.createdPart) {
      setIsCreatePartOpen(false);
      _handleSearchValueUpdate(
        itemMaster?.createdPart?.itemCompanyId ?? '',
        'itemCompanyId'
      );
      resetItemMaster();
    }
  }, [
    _handleSearchValueUpdate,
    itemMaster.createdPart,
    resetItemMaster,
    setItemMaster,
  ]);

  return (
    <>
      <FilterColumnsPanel
        columns={filterColumns}
        customListDisplaySx={customListDisplaySx}
        customListItemSx={inputPaddingFixSx}
        customListSx={customListSx}
        customSelectColumnEditorSx={customSelectColumnEditorSx}
        disableAltColors={!isMobile}
        filters={searchFilters}
        resetShownFilters={resetShownFilters}
        selectColumnEditorRightChild={
          <>
            {isMobile && (
              <ETOButton
                color="primary"
                customSx={buttonSx}
                disabled={SearchButton?.disabled}
                icon={SearchButton?.icon}
                onClick={SearchButton.onclick}
                size="medium"
              >
                {SearchButton.title}
              </ETOButton>
            )}
            {!isMobile &&
              rightButtons?.map((button) => (
                <ETOButton
                  color="primary"
                  customSx={buttonSx}
                  disabled={button?.disabled}
                  icon={button?.icon}
                  key={uniqueId(button.title)}
                  onClick={button.onclick}
                  size="medium"
                >
                  {button.title}
                </ETOButton>
              ))}
          </>
        }
        setFilters={setSearchFilters}
        setResetShownFilters={setResetShownFilters}
      />

      <Box sx={customListDisplaySx}>
        <DefaultFilterWrapper
          filterProps={defaultFieldValues?.itemCompanyId as FilterValue}
          name="id"
          setOperator={(v, n) => _handleOperatorUpdate(v, n)}
        >
          <ETOTextField
            handleChange={(e) =>
              _handleSearchValueUpdate(e.target.value, 'itemCompanyId')
            }
            inputLabelProps={{ shrink: true }}
            label={t('entities:Part.ItemCompanyId')}
            name="itemCompanyId"
            size="small"
            value={defaultFieldValues?.itemCompanyId?.value || ''}
          />
        </DefaultFilterWrapper>

        <DefaultFilterWrapper
          filterProps={defaultFieldValues?.description as FilterValue}
          name="description"
          setOperator={(v, n) => _handleOperatorUpdate(v, n)}
        >
          <ETOTextField
            handleChange={(e) =>
              _handleSearchValueUpdate(e.target.value, 'description')
            }
            inputLabelProps={{ shrink: true }}
            label={t('entities:Part.Description')}
            name="description"
            size="small"
            value={defaultFieldValues?.description?.value || ''}
          />
        </DefaultFilterWrapper>

        <DefaultFilterWrapper
          filterProps={defaultFieldValues?.['uOM.itemUom'] as FilterValue}
          name="uOM.itemUom"
          setOperator={(v, n) => _handleOperatorUpdate(v, n)}
        >
          <UOMSelectField
            handleChange={(value) => {
              const newValue = value ? value.itemUom : null;
              _handleSearchValueUpdate(newValue, 'uOM.itemUom');
            }}
            value={defaultFieldValues?.['uOM.itemUom']?.value as number}
          />
        </DefaultFilterWrapper>

        <DefaultFilterWrapper
          filterProps={
            defaultFieldValues?.['category.itemCategory'] as FilterValue
          }
          name="category.itemCategory"
          setOperator={(v, n) => _handleOperatorUpdate(v, n)}
        >
          <ItemCategorySelectField
            handleChange={(e) => {
              const parseValue = parseInt(e?.target?.value as string, 10);
              const newValue = !Number.isNaN(parseValue)
                ? parseValue
                : undefined;
              _handleSearchValueUpdate(
                newValue as number,
                'category.itemCategory'
              );
            }}
            value={
              defaultFieldValues?.['category.itemCategory']?.value as number
            }
          />
        </DefaultFilterWrapper>
        <ProjectInputField
          customSx={{ pr: 4 }}
          handleChange={(e) => {
            const parseValue = parseInt(e.target.value as string, 10);
            const newValue = !Number.isNaN(parseValue) ? parseValue : undefined;
            handleAdvanceSearch('projectId', newValue);
          }}
          value={advanceSearch.projectId.value}
        />
        <JobInputField
          customSx={{ pr: 4 }}
          handleChange={(e) => {
            const parseValue = parseFloat(e.target.value);
            const newValue = !Number.isNaN(parseValue) ? parseValue : undefined;
            handleAdvanceSearch('specId', newValue);
          }}
          projectId={advanceSearch.projectId.value}
          value={advanceSearch.specId.value}
        />
        <InventoryLocationInput
          customSx={{ pr: 4 }}
          defaultValue={inventoryLocation}
          disableClearable
          excludeLocationId={-1}
          handleChange={({ target: { value } }) => {
            const parseValue = parseInt(value as string, 10);
            const newValue = !Number.isNaN(parseValue) ? parseValue : undefined;
            setAdvanceSearch((d) => ({
              ...d,
              inventoryLocation: {
                ...d.inventoryLocation,
                value: newValue,
              },
            }));
          }}
          queryKey="partSearch-location"
          value={advanceSearch.inventoryLocation.value ?? inventoryLocation}
        />
      </Box>
      <FAB FabActions={rightButtons} />
      <GenericDialog
        contentSx={contentSx}
        fullScreen
        isOpen={isCreatePartOpen}
        onClose={() => setIsCreatePartOpen(false)}
        title={t('inspectors.engItemMasterInspector.title')}
        titleSx={titleSx}
      >
        <Divider sx={dividerSx} />
        <EngItemMasterInspector
          initialValues={undefined}
          onClose={() => setIsCreatePartOpen(false)}
          open={isCreatePartOpen}
        />
      </GenericDialog>
    </>
  );
};

export default PartsSearchGridActionBar;
