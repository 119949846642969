type NumberChange = { value: string; type: 'decimal' | 'int' };

const handleNumberInputChange = (data: NumberChange) => {
  const parsedValue =
    data.type === 'decimal' ? parseFloat(data.value) : parseInt(data.value, 10);

  return Number.isNaN(parsedValue) ? undefined : parsedValue;
};

export default handleNumberInputChange;
