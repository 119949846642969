const addCompanyDocumentMutation = `mutation addCompanyDocumentMutation ($input: AddCompanyDocumentsInput!){
  addCompanyDocuments (input: $input) {
    items {
      id
      documentLocation
    }
  }
}
`;

export default addCompanyDocumentMutation;
