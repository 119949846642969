import { Box, Grid, Theme } from '@mui/material';
import {
  ETOSelectField,
  ETOTextField,
  MessageContext,
} from '@teto/react-component-library-v2';
import { FormikProps } from 'formik';
import React, { useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { Licenses, Permission } from 'teto-client-api';
import { ArTaxCategory, Company } from '../../../../../__generated__/graphql';
import { getGraphQLClient } from '../../../../../api/graphQL/graphQLClient';
import { MAX_50 } from '../../../../../constants/numbers/numbers';
import AuthContext from '../../../../../contexts/AuthContext';
import formatCharCount from '../../../../../helpers/formatCharCount';
import { InspectorActions } from '../../../types/InspectorActionTypes';
import handleNumberInputChange from '../../helpers/companyHelpers';
import getARTaxCategoriesQuery from '../../queries/getARTaxCategoriesQuery';
import DiscountInput from './DiscountInput';

interface CompanyAccountingPanelProps
  extends Pick<InspectorActions<Company>, 'setHasASaveOccurred'> {
  currentValues: Company;
  formik: FormikProps<Company>;
}

const containerSx = (theme: Theme) => ({
  paddingTop: theme.spacing(1),
  overflowY: 'auto',
  width: '100%',
});

const CompanyAccountingPanel = (props: CompanyAccountingPanelProps) => {
  const { formik, currentValues } = props;

  const { t } = useTranslation();

  const authContext = useContext(AuthContext);
  const messageContext = useContext(MessageContext);

  const canModify = useMemo(
    () =>
      authContext.hasLicense(Licenses.TotalETOProfessional) &&
      authContext.hasPermission(
        Permission.Modify_Sales_CompanyInformation_AccountingInformation
      ) &&
      currentValues.id !== 1,
    [authContext, currentValues.id]
  );

  const aRTaxCategories = useQuery<ArTaxCategory[]>(
    ['aRTaxCategories'],
    () =>
      getGraphQLClient()
        .performQuery(
          getARTaxCategoriesQuery,
          {},
          (err) => messageContext.setError(err.messages[0]),
          (err) => {
            const { input } = err;
            messageContext.setError(Object.values(input)[0] as string);
          }
        )
        .then((d) => d.aRTaxCategories.items),
    {
      enabled: Boolean(currentValues?.id),
    }
  );

  return (
    <Box sx={containerSx}>
      <Grid container mb={2} spacing={2}>
        <Grid item md={6} xs={12}>
          <ETOTextField
            disabled={!canModify}
            error={formik.errors?.accVendorId}
            handleChange={formik.handleChange}
            helperText={formatCharCount(
              'accVendorId',
              formik.values?.accVendorId,
              MAX_50
            )}
            label={t('entities:Company.AccVendorId')}
            name="accVendorId"
            size="small"
            value={formik.values?.accVendorId}
          />
        </Grid>
        <Grid item md={6} xs={12}>
          <ETOTextField
            disabled={!canModify}
            error={formik.errors?.accCustomerId}
            handleChange={formik.handleChange}
            helperText={formatCharCount(
              'accCustomerId',
              formik.values?.accCustomerId,
              MAX_50
            )}
            label={t('entities:Company.AccCustomerId')}
            name="accCustomerId"
            size="small"
            value={formik.values?.accCustomerId}
          />
        </Grid>
        <Grid item md={6} xs={12}>
          <ETOTextField
            disabled={!canModify}
            error={formik.errors?.accCustomerGroupCode}
            handleChange={formik.handleChange}
            helperText={formatCharCount(
              'accCustomerGroupCode',
              formik.values?.accCustomerGroupCode,
              MAX_50
            )}
            label={t('entities:Company.AccCustomerGroupCode')}
            name="accCustomerGroupCode"
            size="small"
            value={formik.values?.accCustomerGroupCode}
          />
        </Grid>
        <Grid item md={6} xs={12}>
          <ETOTextField
            disabled={!canModify}
            error={formik.errors?.accVendorTaxClass1}
            handleChange={({ target: { name, value } }) =>
              formik.setFieldValue(
                name,
                handleNumberInputChange({ value, type: 'int' }) ?? 0
              )
            }
            inputLabelProps={{ shrink: true }}
            label={t('entities:Company.AccVendorTaxClass1')}
            name="accVendorTaxClass1"
            size="small"
            type="number"
            value={formik.values?.accVendorTaxClass1 ?? null}
          />
        </Grid>
        <Grid item md={6} xs={12}>
          <DiscountInput
            disabled={!canModify}
            error={formik.errors?.accCustomerDisc as string}
            handleChange={(n, v) => formik.setFieldValue(n, v)}
            name="accCustomerDisc"
            title={t('entities:Company.AccCustomerDisc')}
            value={formik.values?.accCustomerDisc}
          />
        </Grid>
        <Grid item md={6} xs={12}>
          <ETOSelectField
            disabled={!canModify}
            error={formik.errors?.aRTaxCategoryId}
            handleChange={({ target: { name, value } }) => {
              const newValue = parseInt(value, 10);
              formik.setFieldValue(name, Number.isNaN(newValue) ? 1 : newValue);
            }}
            itemNameSelector={(item) => item.name}
            items={aRTaxCategories.data ?? []}
            itemValueSelector={(item) => item.id}
            label={t('entities:Company.ARTaxCategory')}
            name="aRTaxCategoryId"
            size="small"
            value={formik.values?.aRTaxCategoryId ?? null}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default CompanyAccountingPanel;
