import {
  Box,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
  useTheme,
} from '@mui/material';
import { ETOButton } from '@teto/react-component-library-v2';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import AuthContext from '../../contexts/AuthContext';

interface SessionTimeoutProps {
  open: boolean;
}

const SessionTimeout = (props: SessionTimeoutProps) => {
  const { open } = props;
  const theme = useTheme();
  const authContext = useContext(AuthContext);
  const navigate = useNavigate();

  const { t } = useTranslation();

  const _logout = async () => {
    await authContext
      .logout()
      .then(() => {
        navigate('/', { replace: true });
      })
      .catch((err) => {
        throw new Error(err);
      });
  };
  return (
    <Dialog open={open}>
      <DialogTitle>{t('dialogs.sessionTimeout.title')}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          <Typography variant="subtitle1">
            {t('dialogs.sessionTimeout.content')}
          </Typography>
        </DialogContentText>
      </DialogContent>
      <Box
        sx={{
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
          marginBottom: theme.spacing(2),
        }}
      >
        <ETOButton color="primary" onClick={() => _logout()} size="medium">
          Login
        </ETOButton>
      </Box>
    </Dialog>
  );
};

export default SessionTimeout;
