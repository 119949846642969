import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { SxProps, Theme } from '@mui/material/styles';
import React from 'react';

interface SADetailTitleProps {
  itemCompanyId: string;
  description: string;
}

const titleSx: SxProps<Theme> = {
  pl: 2,
};

const titleRowSx: SxProps<Theme> = {
  display: 'flex',
  columnGap: 1.5,
};

const SADetailTitle = ({ description, itemCompanyId }: SADetailTitleProps) => (
  <Box sx={titleSx}>
    <Box sx={titleRowSx}>
      <Typography component="span" fontWeight="medium">
        Part No:
      </Typography>
      <Typography component="span">{itemCompanyId}</Typography>
    </Box>
    <Box sx={titleRowSx}>
      <Typography component="span" fontWeight="medium">
        Description:
      </Typography>
      <Typography component="span">{description}</Typography>
    </Box>
  </Box>
);

export default SADetailTitle;
