import { ETOSelectField } from '@teto/react-component-library-v2';
import { FormikProps } from 'formik';
import React from 'react';
import { useTranslation } from 'react-i18next';
import useBuildQuery from '../../../../../../../api/graphQL/useBuildQuery';
import { TEngItemMaster } from '../../../../types/TEngItemMaster';

interface CountryOfOriginSelectFieldProps {
  formik: FormikProps<Partial<TEngItemMaster>>;
  disabled?: boolean;
  // eslint-disable-next-line no-unused-vars, @typescript-eslint/no-explicit-any
  handleChange: (e: Partial<React.ChangeEvent<any>>) => void;
}

const countriesQuery = `query countries {
  countries {
    items {
      name
    }
  }
}
`;
type Country = {
  name: string;
};
type CountryListApiResponse = {
  countries: {
    items: Country[];
  };
};

const CountryOfOriginSelectField = (props: CountryOfOriginSelectFieldProps) => {
  const { formik, disabled, handleChange } = props;
  const { t } = useTranslation();
  const countries = useBuildQuery<CountryListApiResponse>(['partCountries'], {
    queryString: countriesQuery,
    variables: {},
    options: { refetchOnMount: false, refetchOnWindowFocus: false },
  });
  const formikError = formik.errors.countryOfOrigin;
  return (
    <ETOSelectField
      disabled={disabled}
      error={formikError}
      handleChange={(e) =>
        handleChange({
          target: { name: 'countryOfOrigin', value: { name: e.target.value } },
        })
      }
      inputLabelProps={{ shrink: true }}
      itemNameSelector={(item) => item.name}
      items={countries.data?.countries.items ?? []}
      itemValueSelector={(item) => item.name}
      label={`${t('entities:Part.CountryOfOrigin')}`}
      name="countryOfOrigin"
      size="small"
      value={formik.values.countryOfOrigin?.name ?? null}
    />
  );
};

export default CountryOfOriginSelectField;
