import { useAtom, useSetAtom } from 'jotai';
import { useMolecule } from 'jotai-molecules';
import React, { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import dayjs from 'dayjs';
import NonConformanceInspector from '../../Inspectors/NonConformanceInspector/NonConformanceInspector';
import { BreadCrumbSharedState } from '../StateContainers/BreadCrumbModalState';
import {
  ncSharedState,
  onNCClose,
} from '../StateContainers/NonConformanceState';
import SettingsContext from '../../../contexts/SettingsContext';

const useSharedNonConformance = () => {
  const { t } = useTranslation();
  const [nonConformance] = useAtom(ncSharedState);
  const onCloseFn = useMolecule(onNCClose);
  const defaultOnClose = useSetAtom(onCloseFn);
  const setLevels = useSetAtom(BreadCrumbSharedState);

  const settingsContext = useContext(SettingsContext);

  const formattedDate = dayjs(
    nonConformance?.initialValues?.creationDate
  ).format(settingsContext.settings.dateTimeFormat);

  useEffect(() => {
    if (nonConformance.open) {
      setLevels((prev) => [
        ...prev,
        {
          name: nonConformance?.initialValues?.id
            ? `${t('entities:NonConformance.NonConformance')}: ${String(
                nonConformance?.initialValues?.id
              )}`
            : 'New Non-Conformance',
          data: nonConformance?.initialValues,
          component: (
            <NonConformanceInspector
              initialValues={nonConformance.initialValues}
              onClose={defaultOnClose}
              open={nonConformance.open}
              renderAsInspector={false}
            />
          ),
          subHeader: `${t(
            'entities:NonConformance.CreationDate'
          )}: ${formattedDate}`,
        },
      ]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    // defaultOnClose,
    // setLevels, // don't add this to the dependency array, it will cause an infinite loop bug #75462
    nonConformance?.initialValues,
    nonConformance.open,
  ]);
};

export default useSharedNonConformance;
