import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { SxProps, Theme } from '@mui/material/styles';
import { ETOButton } from '@teto/react-component-library-v2';
import { useAtom, useSetAtom } from 'jotai';
import React from 'react';
import { useTranslation } from 'react-i18next';
import hasErrorAtom from './atoms/hasErrorAtom';
import shouldSubmitAtom from './atoms/shouldSubmitAtom';

const containerSx: SxProps<Theme> = {
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
  '& .InovuaReactDataGrid__locked-end-wrapper InovuaReactDataGrid__locked-end-wrapper--direction-ltr':
    {
      backgroundColor: 'inherit !important',
      zIndex: 10100,
    },
};

const SubmitNewDataBtn = () => {
  const { t } = useTranslation();

  const setShouldSubmit = useSetAtom(shouldSubmitAtom);
  const [hasError] = useAtom(hasErrorAtom);

  return (
    <Box sx={containerSx}>
      <ETOButton
        color="primary"
        disabled={hasError}
        onClick={() => setShouldSubmit(true)}
        size="small"
        type="button"
      >
        <Typography variant="body2">{`${t('generic.add')} ${t(
          'generic.item'
        )}`}</Typography>
      </ETOButton>
    </Box>
  );
};

export default SubmitNewDataBtn;
