import { useMediaQuery, useTheme } from '@mui/material';
import Box from '@mui/material/Box';
import { SxProps, Theme } from '@mui/material/styles';
import {
  ETOSelectField,
  ETOTextField,
  MessageContext,
} from '@teto/react-component-library-v2';
import { FormikProps, useFormikContext } from 'formik';
import React, { useContext, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { getGraphQLClient } from 'teto-client-api';
import useBuildQuery from '../../../api/graphQL/useBuildQuery';
import CommonPartForm from '../../../pages/InventoryPage/ItemsToPullTab/Inspectors/CommonPartForm';
import usePartQuantityPattern from '../../../pages/InventoryPage/ItemsToPullTab/Inspectors/ReturnInspector/usePartQuantityPattern';
import {
  BOMAssembly,
  RequisitionPart,
  RequisitionPartFormProps,
} from '../../../pages/InventoryPage/ItemsToPullTab/Inspectors/types/RequisitionTypes';
import requisitionBOMAssemblyQuery from '../../../pages/InventoryPage/queries/requisitionBOMAssemblyQuery';
import requisitionInventoryQuery from '../../../pages/InventoryPage/queries/requisitionInventoryQuery';

const sharedRowSx: SxProps<Theme> = {
  columnGap: 2,
  display: 'flex',
  flexWrap: 'nowrap',
  justifyContent: 'space-between',
};

const RequisitionPartForm = (props: RequisitionPartFormProps) => {
  const { destInventoryLocationId, setExpandForm } = props;

  const { t } = useTranslation();
  const messageContext = useContext(MessageContext);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const formik = useFormikContext<RequisitionPart>();
  const { uOM, setId } = usePartQuantityPattern(
    formik.values.itemCompanyId as string
  );

  useEffect(() => {
    if (setExpandForm) {
      if (formik.values.itemCompanyId) setExpandForm(true);
      else setExpandForm(false);
    }
  }, [formik.values.itemCompanyId, setExpandForm]);

  const locationId = useMemo(
    () =>
      destInventoryLocationId
        ? { inventoryLocationId: { eq: destInventoryLocationId } }
        : {},
    [destInventoryLocationId]
  );

  useQuery(
    ['requisition-query'],

    () =>
      getGraphQLClient()
        .performQuery(requisitionInventoryQuery, {
          filter: {
            itemId: { eq: formik.values.itemId },
            ...locationId,
          },
        })
        .then((d) => {
          if (d.hasError()) {
            d.showAllSystemErrors(messageContext.setError);
            if (d.hasValidationErrors()) {
              messageContext.setError(d.validationErrors.description);
            }
            return;
          }
          const items = d.data.inventoryPullDetails?.items;
          if (items && items.length > 0) {
            setId(items[0]?.item?.itemCompanyId as string);
            formik.setValues({
              ...formik.values,
              description: items[0]?.item?.description,
              itemCompanyId: items[0]?.item?.itemCompanyId as string,
              itemId: items[0]?.itemId as number,
              uomtype: items[0]?.item?.uOM?.uomtype as string,
              uOMId: items[0]?.item?.uOMId,
              assemblyPurchase: items[0]?.item?.uOMId === 1 ? 1 : undefined,
            });
          }
        }),
    {
      refetchOnWindowFocus: false,
      enabled: formik.values.itemId >= 0,
    }
  );

  const assemblyPurchase = useBuildQuery(
    ['requisition-uom-query', formik.values.uOMId],
    {
      queryString: requisitionBOMAssemblyQuery,
      variables: {
        uOMId: formik.values.uOMId,
      },
      callback: (d: { bOMAssemblyReleases: { items: BOMAssembly[] } }) =>
        d.bOMAssemblyReleases.items,
      options: {
        refetchOnWindowFocus: false,
        enabled:
          (formik.values.uOMId && Math.abs(formik.values.uOMId) === 1) || false,
      },
    }
  );

  return (
    <CommonPartForm
      formik={formik as unknown as FormikProps<Record<string, unknown>>}
      inspectorIdentifier="requisitionInspector"
    >
      <>
        <Box sx={sharedRowSx}>
          <ETOTextField
            disabled
            error={formik.errors.uomtype}
            handleChange={formik.handleChange}
            inputLabelProps={{
              shrink: Math.abs(formik?.values?.uOMId as number) >= 0,
            }}
            label={t('pages.inventory.itemsToPull.uomtype')}
            name="uomtype"
            size="small"
            value={formik.values.uomtype}
          />

          <ETOTextField
            dataTestId="purchaseQty"
            error={formik.errors.purchaseQty}
            handleChange={formik.handleChange}
            inputProps={{ inputMode: 'numeric', ...uOM }}
            label={`${t('generic.quantity')}`}
            name="purchaseQty"
            size="small"
            sxProps={{ height: isMobile ? 95 : 75 }}
            type="number"
            value={formik.values.purchaseQty}
          />
        </Box>
        {Math.abs(formik?.values?.uOMId as number) === 1 && (
          <ETOSelectField
            error={formik.errors.assemblyPurchase}
            handleChange={formik.handleChange}
            itemNameSelector={(i: BOMAssembly) => i?.description ?? ''}
            items={(assemblyPurchase.data as unknown as BOMAssembly[]) ?? []}
            itemValueSelector={(i: BOMAssembly) => i?.id || ''}
            label={t('pages.inventory.itemsToPull.assemblyPurchase')}
            name="assemblyPurchase"
            size="small"
            value={formik.values.assemblyPurchase}
          />
        )}
      </>
    </CommonPartForm>
  );
};

export default RequisitionPartForm;
