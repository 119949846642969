import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import { Box } from '@mui/material';
import React from 'react';

const boxSx = {
  position: 'relative',
  display: 'inline-flex',
  justifyContent: 'center',
  alignItems: 'end',
  mr: 1,
};

const iconSx = { fontSize: '1.5em' };

const FileTypeIcon = () => (
  <Box sx={boxSx}>
    <InsertDriveFileIcon color="action" style={iconSx} />
  </Box>
);

export default FileTypeIcon;
