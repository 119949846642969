import { PanelState } from '../../../types/PanelState';

const getInitialState = <GenericValue extends Omit<PanelState, 'mode'>>(
  value: GenericValue | undefined
): PanelState =>
  value && Object.prototype.hasOwnProperty.call(value, 'id') && value.id
    ? {
        id: value?.id,
        mode: 'edit',
      }
    : {
        id: undefined,
        mode: 'create',
      };

export default getInitialState;
