import { SxProps, Theme } from '@mui/material';
import { FormikProps } from 'formik';
import React from 'react';
import { useTranslation } from 'react-i18next';
import CommonSupplierSelectField from '../../../../../../InputFields/CommonSupplierSelectField/CommonSupplierSelectField';
import { TEngItemMaster } from '../../../../types/TEngItemMaster';

interface LastSupplierSelectFieldProps {
  formik: FormikProps<Partial<TEngItemMaster>>;
  disabled?: boolean;
  sx?: SxProps<Theme>;
  // eslint-disable-next-line no-unused-vars, @typescript-eslint/no-explicit-any
  handleChange: (e: Partial<React.ChangeEvent<any>>) => void;
  shouldHaveWarning: boolean;
}

const LastSupplierSelectField = (props: LastSupplierSelectFieldProps) => {
  const { formik, disabled, sx, handleChange, shouldHaveWarning } = props;
  const { t } = useTranslation();
  return (
    <CommonSupplierSelectField
      disabled={disabled}
      fieldName="lastSupplierCompanyId"
      formik={formik}
      handleChange={handleChange}
      label={t('entities:Part.LastSupplierCompanyId')}
      shouldHaveWarning={shouldHaveWarning}
      sx={sx}
    />
  );
};

export default LastSupplierSelectField;
