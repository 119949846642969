import Box from '@mui/material/Box';
import { SxProps, Theme } from '@mui/material/styles';
import {
  ButtonStrip,
  GenericDialog,
  MessageContext,
} from '@teto/react-component-library-v2';
import React, { FC, useCallback, useContext, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { PurchaseOrderHeader } from '../../../../__generated__/graphql';
import getErrors from '../../../../api/graphQL/getErrors';
import useGQLQuery from '../../../../api/graphQL/useGQLQuery';
import AuthContext from '../../../../contexts/AuthContext';
import SettingsContext from '../../../../contexts/SettingsContext';
import FormBuilder from '../../../TETOForms/FormBuilder/FormBuilder';
import FormDefinition from '../../../TETOForms/FormDefinition';
import TETOForm from '../../../TETOForms/TETOForm';
import { STRING_LENGTH } from '../../../TETOForms/clientSideValidators';
import useForm from '../../../TETOForms/hooks/useForm';
import { useFormBuilder } from '../../../TETOForms/hooks/useFormBuilder';
import getGLAccountQuery from '../queries/pOGLAccountsQuery';
import getTaxSettingsQuery from '../queries/taxSettingsQuery';
import { TaxSettingsUpdate } from '../types/TaxSettingsUpdate';

export interface POTaxSettingsProps {
  open: boolean;
  purchaseOrderId: number;
  // eslint-disable-next-line no-unused-vars
  onClose: (update?: TaxSettingsUpdate) => void;
}

const formContainerSx: SxProps<Theme> = {
  width: '100%',
};
const containerSx: SxProps<Theme> = {
  width: '100%',
  '& .css-1k85uti-MuiPaper-root-MuiDialog-paper': {
    maxWidth: { xs: '95%', md: 'unset !important' },
  },
};

const FIELD_LAYOUT = [
  'purchaseTax1Name',
  'purchaseTax1',
  'tax1Amount',
  'purchaseTax1GLAccountId',
  'purchaseTax2Name',
  'purchaseTax2',
  'tax2Amount',
  'purchaseTax2GLAccountId',
  'compoundTax',
];

const FIELD_LAYOUT_SET = [
  {
    name: 'purchaseTax1Name',
    units: {
      xs: 10,
      md: 2,
    },
  },
  {
    name: 'purchaseTax1',
    units: {
      xs: 5,
      md: 2,
    },
  },
  {
    name: 'tax1Amount',
    units: {
      xs: 5,
      md: 2,
    },
  },
  {
    name: 'purchaseTax1GLAccountId',
    units: {
      xs: 10,
      md: 4,
    },
  },
  {
    name: 'purchaseTax2Name',
    units: {
      xs: 10,
      md: 2,
    },
  },
  {
    name: 'purchaseTax2',
    units: {
      xs: 5,
      md: 2,
    },
  },
  {
    name: 'tax2Amount',
    units: {
      xs: 5,
      md: 2,
    },
  },
  {
    name: 'purchaseTax2GLAccountId',
    units: {
      xs: 10,
      md: 4,
    },
  },
  {
    name: 'compoundTax',
    units: 4,
  },
];

const POTaxSettings: FC<React.PropsWithChildren<POTaxSettingsProps>> = (
  props
) => {
  const { open, onClose, purchaseOrderId } = props;
  const { t } = useTranslation();
  const messageContext = useContext(MessageContext);
  const { user } = useContext(AuthContext);
  const {
    settings: { baseCurrencyFormat, baseCurrencySymbol },
  } = useContext(SettingsContext);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const query = useGQLQuery<any>(['po-tax-settings', open], {
    queryString: getTaxSettingsQuery,
    variables: { id: purchaseOrderId },
    options: {
      refetchOnMount: true,
    },
  });

  const poTaxes = useMemo(() => query.data?.purchaseOrderHeader, [query.data]);

  const formBuilder = useFormBuilder('tax-settings', (fb) =>
    fb
      .addString({
        title: `${t('Name')} 1`,
        name: 'purchaseTax1Name',
        validators: [STRING_LENGTH(100)],
      })
      .addString({
        title: `${t('Name')} 2`,
        name: 'purchaseTax2Name',
        validators: [STRING_LENGTH(100)],
      })
      .addDecimal({
        name: 'purchaseTax1',
        title: 'Rate 1',
        hints: ['PERCENTAGE'],
        required: true,
        defaultValue: 0,
      })
      .addDecimal({
        name: 'purchaseTax2',
        title: 'Rate 2',
        hints: ['PERCENTAGE'],
        required: true,
        defaultValue: 0,
      })
      .addCurrency({
        currency: {
          format: baseCurrencyFormat as string,
          symbol: baseCurrencySymbol as string,
        },
        defaultValue: 0,
        disabled: true,
        name: 'tax1Amount',
        title: 'Value 1',
      })
      .addCurrency({
        currency: {
          format: baseCurrencyFormat as string,
          symbol: baseCurrencySymbol as string,
        },
        defaultValue: 0,
        disabled: true,
        name: 'tax2Amount',
        title: 'Value 2',
      })
      .addInteger({
        name: 'purchaseTax1GLAccountId',
        title: t('entities:PartsOrderHeader.Tax1GlAccount'),
        selectSource: {
          fromGraphQLQuery: getGLAccountQuery,
          label: 'displayName',
          value: 'id',
        },
      })
      .addInteger({
        name: 'purchaseTax2GLAccountId',
        title: t('entities:PartsOrderHeader.Tax2GlAccount'),
        selectSource: {
          fromGraphQLQuery: getGLAccountQuery,
          label: 'displayName',
          value: 'id',
        },
      })
      .addBoolean({
        name: 'compoundTax',
        title: t('entities:PurchaseOrderHeader.CompoundTax'),
        required: true,
        defaultValue: false,
      })
  );
  const _handleRequest = useCallback(
    (
      fb: FormBuilder<Record<string, unknown>>,
      fd: FormDefinition,
      data: Record<string, unknown>
    ) => ({
      ...poTaxes,
      buyerId: user?.id,
      compoundTax: data.compoundTax,
      purchaseTax1: data.purchaseTax1,
      purchaseTax1GLAccountId: data.purchaseTax1GLAccountId,
      tax1Name: data.purchaseTax1Name,
      purchaseTax2: data.purchaseTax2,
      purchaseTax2GLAccountId: data.purchaseTax2GLAccountId,
      tax2Name: data.purchaseTax2Name,
    }),
    [poTaxes, user?.id]
  );

  const _handleResponse = useCallback(
    (
      fb: FormBuilder<PurchaseOrderHeader>,
      fd: FormDefinition,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      response: any
    ) => {
      if (response.hasError()) {
        if (response.hasSystemErrors())
          messageContext.setError(getErrors(response.systemErrors));
        if (response.hasValidationErrors()) {
          fd.setValidationErrors(response?.validationErrors.input);
        }
        return;
      }
      if (response.hasData()) {
        messageContext.setSuccess(
          t('generic.updateSuccess', {
            record: t('pages.purchaseOrders.purchaseOrderModal.taxSettings'),
          })
        );
        onClose({
          compoundTax: fd.values?.compoundTax,
          purchaseTax1: fd.values?.purchaseTax1,
          purchaseTax2: fd.values?.purchaseTax2,
          purchaseTax1Name: fd.values?.purchaseTax1Name,
          purchaseTax2Name: fd.values?.purchaseTax2Name,
          purchaseTax1GLAccountId: fd.values?.purchaseTax1GLAccountId,
          purchaseTax2GLAccountId: fd.values?.purchaseTax2GLAccountId,
        } as TaxSettingsUpdate);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [onClose, t]
  );

  const _handleSubmit = useCallback(
    (
      fb: FormBuilder<PurchaseOrderHeader>,
      fd: FormDefinition,
      data: TaxSettingsUpdate
    ) => {
      onClose(data);
      return Promise.resolve();
    },
    []
  );

  const taxForm = useForm(
    formBuilder,
    {
      onFormatRequest: (fb, fd, data) => _handleRequest(fb, fd, data),
      onFormatResponse: (fb, fd, res) => _handleResponse(fb, fd, res),
      onSubmitting: (fb, fd, data) => _handleSubmit(fb, fd, data),
    },
    {
      purchaseTax1GLAccountId: '',
      purchaseTax2GLAccountId: '',
    }
  );

  useEffect(() => {
    if (poTaxes) {
      FIELD_LAYOUT.forEach((i) => {
        if (i === 'tax1Amount' || i === 'tax2Amount') {
          taxForm.updateField(i, poTaxes?.taxes?.[i]);
        } else
          taxForm.updateField(i, poTaxes?.[i as keyof PurchaseOrderHeader]);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [poTaxes]);

  return (
    <GenericDialog
      isOpen={open}
      onClose={() => onClose()}
      sx={containerSx}
      title={t('pages.purchaseOrders.purchaseOrderModal.taxSettings')}
    >
      <Box sx={formContainerSx}>
        <TETOForm
          form={taxForm}
          formLayout={{
            form: {
              columns: 10,
              fields: FIELD_LAYOUT_SET,
            },
          }}
        />
        <ButtonStrip
          leftButton={{
            color: 'secondary',
            text: t('generic.cancel'),
            onClick: () => onClose(),
            confirm: {
              content: t('dialogs.closeUnsavedForm.content'),
              onNo: () => undefined,
              title: t('dialogs.closeUnsavedForm.title'),
              type: 'yesNo',
            },
          }}
          rightButton={{
            color: 'primary',
            disabled: !taxForm.dirty || taxForm.isSubmitting,
            text: t('generic.save'),
            onClick: () => taxForm.submit(),
          }}
          size="medium"
        />
      </Box>
    </GenericDialog>
  );
};

export default POTaxSettings;
