/* eslint-disable @typescript-eslint/no-explicit-any */
const isRowChangeValid = (
  changes: Record<string, any>,
  rowId: number | string
) => {
  const errorRow = Object.keys(changes);
  let isValidRow = true;

  if (errorRow.includes(`${rowId}`)) {
    const rowChanges = Object.values(changes?.[rowId]);

    for (let i = 0; i < rowChanges.length; i += 1) {
      if ((rowChanges[i] as { isValid: boolean })?.isValid === false) {
        isValidRow = false;
        break;
      }
    }
  }
  return isValidRow;
};

export default isRowChangeValid;
