/* eslint-disable comma-dangle */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable radix */
/* eslint-disable react/jsx-props-no-spreading */
import {
  Alert,
  Box,
  Divider,
  Grid,
  Snackbar,
  SxProps,
  Theme,
  Typography,
} from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import {
  ButtonStrip,
  ButtonStripProps,
  ETOCheckBox,
  ETODateField,
  ETOSelectField,
  ETOTextField,
  MessageContext,
  YesNoConfirmDialog,
  useBarcodeScannerListener,
} from '@teto/react-component-library-v2';
import dayjs from 'dayjs';
import { FormikErrors } from 'formik';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import {
  AuthenticatedUser,
  Employee,
  HourType,
  NonConformance,
  Permission,
  Project,
  getGraphQLClient,
  getJob,
  getNonConformance,
  getProcessScheduleHeader,
} from 'teto-client-api';
import { ProcessScheduleDetail } from '../../../__generated__/graphql';
import getErrors from '../../../api/graphQL/getErrors';
import AuthContext from '../../../contexts/AuthContext';
import SettingsContext from '../../../contexts/SettingsContext';
import BarcodeReader from '../../BarcodeReader/BarcodeReader';
import CommonFormValues from './CommonFormValues';
import ETOHourField from './ETOHourField';
import { CommonFields, CustomFields, OptionalFields } from './Fields';
import FieldsDisableEditableType from './FieldsDisableEditableType';
import {
  adminHourFactorAllowedQuery,
  myHourFactorAllowedQuery,
} from './HourFactorQueries';
import isDisabled from './isDisabled';
import { getProcessScheduleDetailByIdQuery } from './queries/getProcessScheduleDetailsQuery';

const wrapperSx = {
  overflow: 'hidden',
  height: '100%',
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
};

const contentSx = {
  overflowY: 'auto',
  display: 'flex',
  flexDirection: 'column',
  flexWrap: 'nowrap',
  justifyContent: 'flex-start',
  flexGrow: 1,
  flexShrink: 1,
};

const buttonRowSx = (theme: Theme) => ({
  flex: `0 0 ${theme.spacing(7.5)}`,
});

const buttonStripSx = {
  borderTop: 0,
  flex: '0 0 auto',
  paddingBottom: 2,
};

const quantityWrapSx = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-end',
};

const commonSx: SxProps<Theme> = {
  flexBasis: 'unset !important',
};

const borderSx: SxProps<Theme> = {
  borderTop: (theme) => `2px solid ${theme.palette.divider} !important`,
  mt: 2,
};

export type CustomFieldCaptions = Record<CustomFields, string>;

export const DefaultCustomFieldCaptions: CustomFieldCaptions = {
  custom1: 'Timecard Custom 1',
  custom2: 'Timecard Custom 2',
  custom3: 'Timecard Custom 3',
  custom4: 'Timecard Custom 4',
  custom5: 'Timecard Custom 5',
  custom6: 'Timecard Custom 6',
  custom7: 'Timecard Custom 7',
  custom8: 'Timecard Custom 8',
  pSCCustom1: 'Process Schedule In House Transaction Custom 1',
  pSCCustom2: 'Process Schedule In House Transaction Custom 2',
  pSCCustom3: 'Process Schedule In House Transaction Custom 3',
  pSCCustom4: 'Process Schedule In House Transaction Custom 4',
  pSCCustom5: 'Process Schedule In House Transaction Custom 5',
  pSCCustom6: 'Process Schedule In House Transaction Custom 6',
  pSCCustom7: 'Process Schedule In House Transaction Custom 7',
  pSCCustom8: 'Process Schedule In House Transaction Custom 8',
};

export const isCustomCaption = (field: CustomFields, caption: string) =>
  DefaultCustomFieldCaptions[field] !== caption;

interface ExtendedProcessScheduleDetail extends ProcessScheduleDetail {
  processDefaultHourType?: number;
}

interface TimeCardFormProps {
  disableAutoFocus: boolean;
  isEditMode: boolean;
  qrEnabled: boolean;
  disableOverlay?: boolean;
  isSubmitting: boolean;
  canAffect: boolean;
  values: CommonFormValues & { hour?: number };
  origValues: CommonFormValues & { hour?: number };
  errors: FormikErrors<CommonFormValues>;
  // eslint-disable-next-line no-unused-vars
  setFieldError: (field: string, value: string | undefined) => void;
  submitForm?: () => void;

  fieldCaptions: {
    // eslint-disable-next-line no-unused-vars
    [key in CommonFields | OptionalFields | CustomFields]: string;
  };
  fieldsDisableEditable: boolean | Partial<FieldsDisableEditableType>;
  getEmployees: (() => Promise<Employee[]>) | AuthenticatedUser;
  totalHours?: (() => number) | (() => string);

  getProjects: () => Promise<Project[]>;
  // eslint-disable-next-line no-unused-vars
  getJobs: (projectId: number) => Promise<any[]>;
  getNonConformances: (
    // eslint-disable-next-line no-unused-vars
    projectId: number,
    // eslint-disable-next-line no-unused-vars
    specId: number
  ) => Promise<NonConformance[]>;
  getProcessSchedules: (
    // eslint-disable-next-line no-unused-vars
    projectId: number,
    // eslint-disable-next-line no-unused-vars
    specId: number
  ) => Promise<ProcessScheduleDetail[]>;
  getHourTypes: (
    // eslint-disable-next-line no-unused-vars
    projectId?: number,
    // eslint-disable-next-line no-unused-vars
    specId?: number,
    // eslint-disable-next-line no-unused-vars
    processScheduleDetailId?: number | null,
    // eslint-disable-next-line no-unused-vars
    nonConformanceId?: number | null,
    // eslint-disable-next-line no-unused-vars
    employeeId?: number
  ) => Promise<HourType[]>;

  setFieldValue: (
    // eslint-disable-next-line no-unused-vars
    field: string,
    // eslint-disable-next-line no-unused-vars
    value: any,
    // eslint-disable-next-line no-unused-vars
    shouldValidate?: boolean | undefined
  ) => Promise<FormikErrors<CommonFormValues>> | Promise<void>;
  handleChange: {
    // eslint-disable-next-line no-unused-vars
    (e: React.ChangeEvent<any>): void;
    // eslint-disable-next-line no-unused-vars
    <T_1 = string | React.ChangeEvent<any>>(
      // eslint-disable-next-line no-unused-vars
      field: T_1
    ): T_1 extends React.ChangeEvent<any>
      ? void
      : // eslint-disable-next-line no-unused-vars
        (e: string | React.ChangeEvent<any>) => void;
  };
  buttonStrip?: ButtonStripProps;
  children?: React.ReactNode;
}

const TimeCardForm = (props: TimeCardFormProps) => {
  const {
    totalHours,
    handleChange,
    origValues,
    values,
    getProjects,
    getJobs,
    getNonConformances,
    getHourTypes,
    getProcessSchedules,
    fieldsDisableEditable,
    fieldCaptions,
    errors,
    buttonStrip,
    setFieldValue,
    submitForm,
    isSubmitting,
    canAffect,
    getEmployees,
    qrEnabled,
    disableOverlay,
    children,
    disableAutoFocus,
    setFieldError,
    // eslint-disable-next-line no-unused-vars
    isEditMode,
  } = props;

  const {
    specId,
    projectId,
    nonConformanceId,
    employeeId,
    processScheduleDetailId,
    hourFactor,
  } = values;

  const [error, setError] = useState('');
  const [pending, setPendingSpec] = useState<{
    specId: number | undefined;
  }>();

  const projects = useQuery('projects', getProjects);

  const hourTypes = useQuery(
    [
      'hourTypes',
      projectId,
      specId,
      nonConformanceId,
      employeeId,
      processScheduleDetailId,
    ],
    () =>
      getHourTypes(
        values.projectId,
        values.specId,
        values.processScheduleDetailId,
        values.nonConformanceId,
        values.employeeId
      )
  );
  const { t } = useTranslation();
  const authContext = useContext(AuthContext);
  const settingsContext = useContext(SettingsContext);
  const messageContext = useContext(MessageContext);

  const [isHourFactorDialogOpen, setIsHourFactorDialogOpen] =
    useState<boolean>(false);
  // #79999
  const [_isSubmitting, _setIsSubmitting] = useState<boolean>(false);

  const hourFactorControl = useMemo(
    () => settingsContext.settings.controlTimecardHourFactor,
    [settingsContext.settings.controlTimecardHourFactor]
  );

  const jobs = useQuery(
    ['jobs', projectId],
    () => getJobs(parseInt(projectId as unknown as string)),
    {
      enabled: parseInt(projectId as unknown as string) > 0,
    }
  );

  const _handleErrorClose = () => {
    setError('');
  };

  const nonConformances = useQuery(
    ['nonConformances', projectId, specId],
    () =>
      getNonConformances(
        parseInt(projectId as unknown as string),
        parseFloat(specId as unknown as string)
      ),
    {
      enabled:
        parseInt(projectId as unknown as string) > 0 &&
        parseFloat(specId as unknown as string) > 0,
    }
  );

  const processScheduleDetails = useQuery(
    ['processScheduleDetails', projectId, specId],
    () =>
      getProcessSchedules(
        parseInt(projectId as unknown as string),
        parseFloat(specId as unknown as string)
      ),
    {
      enabled:
        parseInt(projectId as unknown as string) > 0 &&
        parseFloat(specId as unknown as string) > 0,
    }
  );

  const employees = useQuery(
    ['timecard-employees'],
    () =>
      typeof getEmployees === 'function' ? getEmployees() : Promise.resolve([]),
    {
      enabled: typeof getEmployees === 'function',
    }
  );

  const _showError = (e: Error) => {
    setError(e.message);
  };

  const _getAndSetJob = (autoId: number) =>
    getJob(autoId)
      .then((j) => {
        setFieldValue('projectId', j.projectId);
        setPendingSpec({
          specId: j.id,
        });
      })

      .catch(() => {
        _showError(new Error('Failed to find scanned record'));
        setFieldValue('specId', '');
      });

  const _getAndSetProcessScheduleDetail = (id: number) => {
    getGraphQLClient()
      .performQuery(getProcessScheduleDetailByIdQuery, {
        date: dayjs(),
        id,
      })
      .then((d) => {
        if (d.hasError()) {
          _showError(new Error('Failed to find scanned record'));
          setFieldValue('processScheduleDetailId', '');
        }
        if (d.hasData()) {
          const items = d.data.timecardProcessScheduleDetails?.items;

          if (Array.isArray(items)) {
            if (items.length > 0) {
              setFieldValue(
                'projectId',
                items[0]?.processSchedule?.spec?.projectId
              );

              setPendingSpec({
                specId: items[0]?.processSchedule?.specId,
              });

              setFieldValue('processScheduleDetailId', items[0]?.id);
            } else {
              _showError(new Error('Failed to find scanned record'));
              setFieldValue('processScheduleDetailId', '');
            }
          }
        }
      });
  };

  const _getAndSetProcessScheduleHeader = (id: number) =>
    getProcessScheduleHeader(id)
      .then((psh: { projectId: number; specId: number }) => {
        setFieldValue('projectId', psh.projectId);
        setPendingSpec({
          specId: psh.specId,
        });
      })
      .catch(() => {
        _showError(new Error('Failed to find scanned record'));
        setFieldValue('processScheduleDetailId', '');
      });

  const _getAndSetNonConformance = (id: number) =>
    getNonConformance(id)
      .then((nc) => {
        setFieldValue('projectId', nc.projectId);
        setPendingSpec({
          specId: nc.specId,
        });
        setFieldValue('nonConformanceId', nc.id);
      })
      .catch(() => {
        _showError(new Error('Failed to find scanned record'));
        setFieldValue('nonConformanceId', '');
      });

  useEffect(() => {
    if (values.projectId !== origValues.projectId) {
      setFieldValue('specId', '');
      setFieldValue('nonConformanceId', '');
      setFieldValue('processScheduleDetailId', '');
    }
  }, [values.projectId, setFieldValue, origValues.projectId]);

  useEffect(() => {
    if (values.specId !== origValues.specId) {
      setFieldValue('nonConformanceId', '');
      setFieldValue('processScheduleDetailId', '');
    }
  }, [values.specId, setFieldValue, origValues.specId]);

  useEffect(() => {
    if (values.processScheduleDetailId !== origValues.processScheduleDetailId) {
      setFieldValue('quantity', '0');
    }
  }, [
    values.processScheduleDetailId,
    setFieldValue,
    origValues.processScheduleDetailId,
  ]);

  const prefixes = [
    'EMP',
    'PSD',
    'HRT',
    'NCO',
    'PRJ',
    'JOB',
    'COM',
    'CLO',
    'PSH',
  ];
  // eslint-disable-next-line no-unused-vars
  const [_qrCode, setScan] = useBarcodeScannerListener(
    prefixes,
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    async (prefix, val, input) => {
      const prefixUpperCase = prefix.toUpperCase();
      switch (prefixUpperCase) {
        case 'PRJ':
          setFieldValue('projectId', parseInt(val, 10));
          return Boolean(input && input.name !== 'projectId');
        case 'HRT':
          setFieldValue('hourTypeId', parseInt(val, 10));
          return Boolean(input && input.name !== 'hourTypeId');
        case 'NCO':
          _getAndSetNonConformance(parseInt(val, 10));
          return Boolean(input && input.name !== 'nonConformanceId');
        case 'JOB':
          await _getAndSetJob(parseInt(val, 10));
          return Boolean(input && input.name !== 'specId');
        case 'PSD':
          _getAndSetProcessScheduleDetail(parseInt(val, 10));
          return Boolean(input && input.name !== 'processScheduleDetailId');
        case 'PSH':
          _getAndSetProcessScheduleHeader(parseInt(val, 10));
          return true;
        case 'EMP':
          setFieldValue('employeeId', parseInt(val, 10));
          return Boolean(input && input.name !== 'employeeId');
        case 'COM':
        case 'CLO':
          if (submitForm) {
            submitForm();
          }
          return true;
        default:
          return false;
      }
    }
  );

  const processSchedule = useMemo(() => {
    if (!values.processScheduleDetailId || !processScheduleDetails)
      return undefined;
    const pS = (processScheduleDetails.data ?? []).find(
      // eslint-disable-next-line eqeqeq
      (a) => a.id == values.processScheduleDetailId
    ) as ExtendedProcessScheduleDetail;

    if (!pS) return undefined;

    const defaultHourType = pS?.process?.hourTypeId;
    if (defaultHourType) {
      pS.processDefaultHourType = defaultHourType;
    }
    return pS;
  }, [values.processScheduleDetailId, processScheduleDetails]);

  useEffect(() => {
    if (processSchedule && processSchedule.processDefaultHourType) {
      setFieldValue('hourTypeId', processSchedule.processDefaultHourType);
    }
  }, [processSchedule, setFieldValue]);

  const hasEmployeeSelection = typeof getEmployees === 'function';
  const hasProcessSchedule = Boolean(processSchedule);

  const _handleSubmit = () => {
    if (submitForm) submitForm();
    _setIsSubmitting(false);
  };

  const _handleHourFactorWarning = () => {
    _setIsSubmitting(true);
    if (Object.entries(errors).length !== 0) return;
    if (hourFactorControl === 'Always') {
      _handleSubmit();
      return;
    }
    const hourFactorQuery =
      hasEmployeeSelection && authContext.isAdmin
        ? getGraphQLClient().performQuery(adminHourFactorAllowedQuery, {
            employeeId,
            hourFactor:
              typeof hourFactor === 'string'
                ? parseFloat(hourFactor)
                : hourFactor,
          })
        : getGraphQLClient().performQuery(myHourFactorAllowedQuery, {
            hourFactor:
              typeof hourFactor === 'string'
                ? parseFloat(hourFactor)
                : hourFactor,
          });
    hourFactorQuery.then((d) => {
      if (d.hasError()) {
        d.showAllSystemErrors(messageContext.setError);
        if (d.hasValidationErrors()) {
          messageContext.setError(getErrors(d.validationErrors));
        }
        return;
      }
      const { isSupported, canOverride } = d.data.hourFactorAllowed!;
      if (!isSupported && (!canOverride || hourFactorControl === 'Prevent')) {
        setFieldError(
          'hourFactor',
          `${t('generic.message.invalidHourFactor')}`
        );
      } else if (!isSupported && canOverride && hourFactorControl === 'Warn') {
        setIsHourFactorDialogOpen(true);
      } else if (isSupported) {
        _handleSubmit();
      }
    });
  };

  const isValidInput = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const input = e.currentTarget.value;
    const regex = /^$|^(\d*\.)?(\d+)?$/;
    return regex.test(input);
  };

  useEffect(() => {
    if (pending) {
      if (projects.isFetching || !jobs.isSuccess) {
        return;
      }
      setFieldValue('specId', pending.specId);
      setPendingSpec(undefined);
    }
  }, [
    jobs.isFetching,
    jobs.isSuccess,
    pending,
    processScheduleDetails.isFetching,
    projects.isFetching,
    setFieldValue,
  ]);

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Box sx={wrapperSx}>
        {children}
        <Grid container spacing={0} sx={contentSx}>
          {hasEmployeeSelection && (
            <Grid item sx={commonSx} xs={12}>
              <Box m={2} mb={0}>
                <ETOSelectField
                  autoFocus={!disableAutoFocus}
                  disabled={isDisabled(
                    canAffect,
                    'employeeId',
                    fieldsDisableEditable
                  )}
                  error={errors.employeeId}
                  handleChange={handleChange}
                  itemDisabledSelector={(item) => !item.active}
                  itemNameSelector={(item) => {
                    if (Object.keys(item).length > 0) {
                      return `${!item.active ? '(inactive) ' : ''}${
                        item?.employeeNumber ?? ''
                      } - ${item?.firstName ?? ''} ${item?.lastName ?? ''}`;
                    }
                    return '';
                  }}
                  items={employees?.data ?? []}
                  itemValueSelector={(item) => item?.id}
                  label={fieldCaptions?.employeeId}
                  name="employeeId"
                  value={values?.employeeId ?? ''}
                />
              </Box>
            </Grid>
          )}
          <Grid item sx={commonSx} xs={12}>
            <Box m={2} mb={0}>
              <ETOSelectField
                autoFocus={!disableAutoFocus}
                defaultValue={-1}
                disabled={isDisabled(
                  canAffect,
                  'projectId',
                  fieldsDisableEditable
                )}
                error={errors.projectId}
                handleChange={handleChange}
                itemNameSelector={(item) => {
                  if (Object.keys(item).length > 0) {
                    return item?.displayName ?? '';
                  }

                  return '';
                }}
                items={projects?.data ?? []}
                itemValueSelector={(item) => item?.id}
                label={fieldCaptions?.projectId}
                name="projectId"
                value={values?.projectId ?? ''}
              />
            </Box>
          </Grid>
          <Grid item sx={commonSx} xs={12}>
            <Box m={2} mb={0}>
              <ETOSelectField
                defaultValue={-1}
                disabled={isDisabled(
                  canAffect,
                  'specId',
                  fieldsDisableEditable
                )}
                error={errors.specId}
                handleChange={handleChange}
                itemNameSelector={(item) => {
                  if (Object.keys(item).length > 0) {
                    return item?.displayName ?? '';
                  }

                  return '';
                }}
                items={jobs.data ?? []}
                itemValueSelector={(item) => item?.id}
                label={fieldCaptions?.specId}
                name="specId"
                value={values?.specId ?? ''}
              />
            </Box>
          </Grid>
          <Box sx={{ display: 'flex', width: '100%', ...commonSx }}>
            <Grid item sm={8} xs={8}>
              <Box m={2} mb={1}>
                <ETOSelectField
                  defaultValue={-1}
                  disabled={isDisabled(
                    canAffect,
                    'hourTypeId',
                    fieldsDisableEditable
                  )}
                  error={errors.hourTypeId}
                  handleChange={handleChange}
                  itemNameSelector={(item) => {
                    if (Object.keys(item).length > 0) {
                      return item?.displayName ?? '';
                    }
                    return '';
                  }}
                  items={hourTypes?.data ?? []}
                  itemValueSelector={(item) => item?.id}
                  label={fieldCaptions?.hourTypeId}
                  name="hourTypeId"
                  value={values?.hourTypeId ?? ''}
                />
              </Box>
            </Grid>
            <Grid item sm={4} xs={4}>
              <Box m={2} mb={0}>
                <ETOHourField
                  disabled={
                    Boolean(totalHours) ||
                    isDisabled(canAffect, 'hour', fieldsDisableEditable)
                  }
                  handleChange={handleChange}
                  label={fieldCaptions.hour}
                  name="hour"
                  value={totalHours ? totalHours() : values.hour}
                />
              </Box>
            </Grid>
          </Box>
          {authContext.authenticated &&
            ((hasEmployeeSelection &&
              authContext.hasAnyPermission([
                Permission.Modify_Time_Admin_Data_HourInformationDetails,
                Permission.View_Time_Admin_Data_HourInformationDetails,
              ])) ||
              hasEmployeeSelection ||
              settingsContext.settings.enableHourFactorInMyTimecards) && (
              <Box sx={{ display: 'flex', width: '100%', ...commonSx }}>
                <Grid item xs={isEditMode ? 6 : 12}>
                  <Box m={2} mb={0}>
                    <ETOTextField
                      disabled={
                        isDisabled(
                          canAffect,
                          'hourFactor',
                          fieldsDisableEditable
                        ) ||
                        !authContext.hasPermission(
                          Permission.Modify_Time_Admin_Data_HourInformationDetails
                        )
                      }
                      error={errors.hourFactor}
                      handleChange={(e) => {
                        if (isValidInput(e)) handleChange(e);
                      }}
                      inputLabelProps={{ shrink: true }}
                      inputProps={{
                        inputMode: 'numeric',
                        min: 0,
                        step: '0.01',
                      }}
                      label={fieldCaptions.hourFactor}
                      name="hourFactor"
                      value={values.hourFactor}
                    />
                  </Box>
                </Grid>
                {isEditMode &&
                  hasEmployeeSelection &&
                  authContext.hasAnyPermission([
                    Permission.Modify_Time_Admin_Data_HourInformationDetails,
                    Permission.View_Time_Admin_Data_HourInformationDetails,
                  ]) && (
                    <Grid item xs={6}>
                      <Box m={2} mb={0}>
                        <ETOTextField
                          disabled={
                            isDisabled(
                              canAffect,
                              'hourRate',
                              fieldsDisableEditable
                            ) ||
                            !authContext.hasPermission(
                              Permission.Modify_Time_Admin_Data_HourInformationDetails
                            )
                          }
                          error={errors.hourRate}
                          handleChange={(e) => {
                            if (isValidInput(e)) handleChange(e);
                          }}
                          inputLabelProps={{ shrink: true }}
                          inputProps={{
                            inputMode: 'numeric',
                            min: 0,
                            step: '0.01',
                          }}
                          label={fieldCaptions.hourRate}
                          name="hourRate"
                          value={values.hourRate}
                        />
                      </Box>
                    </Grid>
                  )}
              </Box>
            )}
          <Grid item sx={commonSx} xs={12}>
            <Box m={2} mb={0}>
              <ETOTextField
                disabled={isDisabled(
                  canAffect,
                  'comments',
                  fieldsDisableEditable
                )}
                error={errors.comments}
                handleChange={handleChange}
                label={fieldCaptions.comments}
                multiline
                name="comments"
                value={values.comments}
              />
            </Box>
          </Grid>
          <Grid item sx={commonSx} xs={12}>
            <Box m={2} mb={0}>
              <ETOSelectField
                disabled={isDisabled(
                  canAffect,
                  'nonConformanceId',
                  fieldsDisableEditable
                )}
                error={errors.nonConformanceId}
                handleChange={handleChange}
                itemNameSelector={(item) => `${item?.id} - ${item?.title}`}
                items={nonConformances.data ?? []}
                itemValueSelector={(item) => item.id}
                label={fieldCaptions.nonConformanceId}
                name="nonConformanceId"
                value={values.nonConformanceId ?? null}
              />
            </Box>
          </Grid>
          {isCustomCaption('custom1', fieldCaptions.custom1) && (
            <Grid item sx={commonSx} xs={12}>
              <Box m={2} mb={0}>
                <ETOTextField
                  disabled={isDisabled(
                    canAffect,
                    'custom1',
                    fieldsDisableEditable
                  )}
                  error={errors.custom1}
                  handleChange={handleChange}
                  label={fieldCaptions.custom1}
                  name="custom1"
                  value={values.custom1}
                />
              </Box>
            </Grid>
          )}
          {isCustomCaption('custom2', fieldCaptions.custom2) && (
            <Grid item sx={commonSx} xs={12}>
              <Box m={2} mb={0}>
                <ETOTextField
                  disabled={isDisabled(
                    canAffect,
                    'custom2',
                    fieldsDisableEditable
                  )}
                  error={errors.custom2}
                  handleChange={handleChange}
                  label={fieldCaptions.custom2}
                  name="custom2"
                  value={values.custom2}
                />
              </Box>
            </Grid>
          )}
          {isCustomCaption('custom3', fieldCaptions.custom3) && (
            <Grid item sx={commonSx} xs={12}>
              <Box m={2} mb={0}>
                <ETOTextField
                  disabled={isDisabled(
                    canAffect,
                    'custom3',
                    fieldsDisableEditable
                  )}
                  error={errors.custom3}
                  handleChange={handleChange}
                  label={fieldCaptions.custom3}
                  name="custom3"
                  type="number"
                  value={values.custom3}
                />
              </Box>
            </Grid>
          )}
          {isCustomCaption('custom4', fieldCaptions.custom4) && (
            <Grid item sx={commonSx} xs={12}>
              <Box m={2} mb={0}>
                <ETOTextField
                  disabled={isDisabled(
                    canAffect,
                    'custom4',
                    fieldsDisableEditable
                  )}
                  error={errors.custom4}
                  handleChange={handleChange}
                  label={fieldCaptions.custom4}
                  name="custom4"
                  type="number"
                  value={values.custom4}
                />
              </Box>
            </Grid>
          )}
          {isCustomCaption('custom5', fieldCaptions.custom5) && (
            <Grid item sx={commonSx} xs={12}>
              <Box m={2} mb={0}>
                <ETODateField
                  clearable
                  data-testid="custom5"
                  disabled={isDisabled(
                    canAffect,
                    'custom5',
                    fieldsDisableEditable
                  )}
                  error={errors.custom5}
                  handleChange={(v) => setFieldValue('custom5', v)}
                  inputFormat={settingsContext.settings.dateFormat}
                  label={fieldCaptions.custom5}
                  name="custom5"
                  value={values.custom5 ?? undefined}
                />
              </Box>
            </Grid>
          )}
          {isCustomCaption('custom6', fieldCaptions.custom6) && (
            <Grid item sx={commonSx} xs={12}>
              <Box m={2} mb={0}>
                <ETODateField
                  clearable
                  data-testid="custom6"
                  disabled={isDisabled(
                    canAffect,
                    'custom6',
                    fieldsDisableEditable
                  )}
                  error={errors.custom6}
                  handleChange={(v) => setFieldValue('custom6', v)}
                  inputFormat={settingsContext.settings.dateFormat}
                  label={fieldCaptions.custom6}
                  name="custom6"
                  value={values.custom6 ?? undefined}
                />
              </Box>
            </Grid>
          )}
          {isCustomCaption('custom7', fieldCaptions.custom7) && (
            <Grid item sx={commonSx} xs={12}>
              <Box m={2} mb={0}>
                <ETOCheckBox
                  disabled={isDisabled(
                    canAffect,
                    'custom7',
                    fieldsDisableEditable
                  )}
                  handleChange={handleChange}
                  label={fieldCaptions.custom7}
                  name="custom7"
                  value={values.custom7}
                />
              </Box>
            </Grid>
          )}
          {isCustomCaption('custom8', fieldCaptions.custom8) && (
            <Grid item sx={commonSx} xs={12}>
              <Box m={2} mb={0}>
                <ETOCheckBox
                  disabled={isDisabled(
                    canAffect,
                    'custom8',
                    fieldsDisableEditable
                  )}
                  handleChange={handleChange}
                  label={fieldCaptions.custom8}
                  name="custom8"
                  value={values.custom8}
                />
              </Box>
            </Grid>
          )}

          <Box sx={{ display: 'flex', width: '100%' }}>
            <Grid item md={8} xs={12}>
              <Box m={2} mb={0}>
                <ETOSelectField
                  defaultValue={-1}
                  disabled={isDisabled(
                    canAffect,
                    'processScheduleDetailId',
                    fieldsDisableEditable
                  )}
                  error={errors.processScheduleDetailId}
                  handleChange={handleChange}
                  itemNameSelector={(item) =>
                    `${item.processSchedule.number} - ${item.sequence} - ${item.processSchedule.item.itemCompanyId} - ${item.process.name}`
                  }
                  items={processScheduleDetails.data ?? []}
                  itemValueSelector={(item) => item.id}
                  label={fieldCaptions.processScheduleDetailId}
                  name="processScheduleDetailId"
                  value={values.processScheduleDetailId ?? null}
                />
              </Box>
            </Grid>
            <Grid item md={4} xs={12}>
              <Box m={2} mb={0}>
                <ETOTextField
                  disabled={
                    isDisabled(canAffect, 'quantity', fieldsDisableEditable) ||
                    !hasProcessSchedule
                  }
                  error={errors.quantity}
                  handleChange={handleChange}
                  inputProps={{ min: 0, step: '0.01' }}
                  label={fieldCaptions.quantity}
                  name="quantity"
                  type="number"
                  value={values.quantity}
                />
              </Box>
            </Grid>
          </Box>

          {hasProcessSchedule && (
            <Grid item md={12} sx={commonSx} xs={12}>
              <Box m={2} mb={0} sx={quantityWrapSx}>
                <Typography variant="subtitle1">
                  {t(
                    'entities:ProcessScheduleInHouseTransaction.QuantityIssued'
                  )}
                  : {processSchedule?.quantityIssued}
                </Typography>
                <Typography variant="subtitle1">
                  {t(
                    'entities:ProcessScheduleInHouseTransaction.QuantityOutstanding'
                  )}
                  : {processSchedule?.quantityIssuedMinusQuantityReceived}
                </Typography>
              </Box>
            </Grid>
          )}
          {hasProcessSchedule &&
            isCustomCaption('pSCCustom1', fieldCaptions.pSCCustom1) && (
              <Grid item sx={commonSx} xs={12}>
                <Box m={2} mb={0}>
                  <ETOTextField
                    disabled={isDisabled(
                      canAffect,
                      'pSCCustom1',
                      fieldsDisableEditable
                    )}
                    error={errors.pSCCustom1}
                    handleChange={handleChange}
                    label={fieldCaptions.pSCCustom1}
                    name="pSCCustom1"
                    value={values.pSCCustom1}
                  />
                </Box>
              </Grid>
            )}
          {hasProcessSchedule &&
            isCustomCaption('pSCCustom2', fieldCaptions.pSCCustom2) && (
              <Grid item sx={commonSx} xs={12}>
                <Box m={2} mb={0}>
                  <ETOTextField
                    disabled={isDisabled(
                      canAffect,
                      'pSCCustom2',
                      fieldsDisableEditable
                    )}
                    error={errors.pSCCustom2}
                    handleChange={handleChange}
                    label={fieldCaptions.pSCCustom2}
                    name="pSCCustom2"
                    value={values.pSCCustom2}
                  />
                </Box>
              </Grid>
            )}
          {hasProcessSchedule &&
            isCustomCaption('pSCCustom3', fieldCaptions.pSCCustom3) && (
              <Grid item sx={commonSx} xs={12}>
                <Box m={2} mb={0}>
                  <ETOTextField
                    disabled={isDisabled(
                      canAffect,
                      'pSCCustom3',
                      fieldsDisableEditable
                    )}
                    error={errors.pSCCustom3}
                    handleChange={handleChange}
                    label={fieldCaptions.pSCCustom3}
                    name="pSCCustom3"
                    type="number"
                    value={values.pSCCustom3}
                  />
                </Box>
              </Grid>
            )}
          {hasProcessSchedule &&
            isCustomCaption('pSCCustom4', fieldCaptions.pSCCustom4) && (
              <Grid item sx={commonSx} xs={12}>
                <Box m={2} mb={0}>
                  <ETOTextField
                    disabled={isDisabled(
                      canAffect,
                      'pSCCustom4',
                      fieldsDisableEditable
                    )}
                    error={errors.pSCCustom4}
                    handleChange={handleChange}
                    label={fieldCaptions.pSCCustom4}
                    name="pSCCustom4"
                    type="number"
                    value={values.pSCCustom4}
                  />
                </Box>
              </Grid>
            )}
          {hasProcessSchedule &&
            isCustomCaption('pSCCustom5', fieldCaptions.pSCCustom5) && (
              <Grid item sx={commonSx} xs={12}>
                <Box m={2} mb={0}>
                  <ETODateField
                    disabled={isDisabled(
                      canAffect,
                      'pSCCustom5',
                      fieldsDisableEditable
                    )}
                    error={errors.pSCCustom5}
                    handleChange={(v) => setFieldValue('pSCCustom5', v)}
                    inputFormat={settingsContext.settings.dateTimeFormat}
                    label={fieldCaptions.pSCCustom5}
                    name="pSCCustom5"
                    value={values.pSCCustom5}
                  />
                </Box>
              </Grid>
            )}
          {hasProcessSchedule &&
            isCustomCaption('pSCCustom6', fieldCaptions.pSCCustom6) && (
              <Grid item sx={commonSx} xs={12}>
                <Box m={2} mb={0}>
                  <ETODateField
                    disabled={isDisabled(
                      canAffect,
                      'pSCCustom6',
                      fieldsDisableEditable
                    )}
                    error={errors.pSCCustom6}
                    handleChange={(v) => setFieldValue('pSCCustom6', v)}
                    inputFormat={settingsContext.settings.dateTimeFormat}
                    label={fieldCaptions.pSCCustom6}
                    name="pSCCustom6"
                    value={values.custom6}
                  />
                </Box>
              </Grid>
            )}
          {hasProcessSchedule &&
            isCustomCaption('pSCCustom7', fieldCaptions.pSCCustom7) && (
              <Grid item sx={commonSx} xs={12}>
                <Box m={2} mb={0}>
                  <ETOCheckBox
                    disabled={isDisabled(
                      canAffect,
                      'pSCCustom7',
                      fieldsDisableEditable
                    )}
                    handleChange={handleChange}
                    label={fieldCaptions.pSCCustom7}
                    name="pSCCustom7"
                    value={values.pSCCustom7}
                  />
                </Box>
              </Grid>
            )}
          {hasProcessSchedule &&
            isCustomCaption('pSCCustom8', fieldCaptions.pSCCustom8) && (
              <Grid item sx={commonSx} xs={12}>
                <Box m={2} mb={0}>
                  <ETOCheckBox
                    disabled={isDisabled(
                      canAffect,
                      'pSCCustom8',
                      fieldsDisableEditable
                    )}
                    handleChange={handleChange}
                    label={fieldCaptions.pSCCustom8}
                    name="pSCCustom8"
                    value={values.pSCCustom8}
                  />
                </Box>
              </Grid>
            )}
        </Grid>
        {buttonStrip && (
          <Box
            sx={{
              ...buttonRowSx,
              ...(!qrEnabled && borderSx),
            }}
          >
            <ButtonStrip
              className={buttonStripSx}
              leftButton={
                buttonStrip.leftButton
                  ? {
                      ...buttonStrip.leftButton,
                      disabled:
                        _isSubmitting ||
                        buttonStrip.leftButton.disabled ||
                        isSubmitting ||
                        !canAffect,
                    }
                  : undefined
              }
              rightButton={
                buttonStrip?.rightButton
                  ? {
                      ...buttonStrip?.rightButton,
                      disabled:
                        _isSubmitting ||
                        buttonStrip.rightButton?.disabled ||
                        isSubmitting ||
                        !canAffect,
                      type: 'button',
                      onClick: _handleHourFactorWarning,
                    }
                  : undefined
              }
              size={buttonStrip.size}
            />
          </Box>
        )}
        {qrEnabled && <Divider sx={{ pt: 2 }} />}
        <BarcodeReader
          barcodeId="timecard-form-barcode-reader"
          disableOverlay={disableOverlay}
          enabled={qrEnabled}
          onRead={(e) => setScan(e)}
        />
      </Box>
      <Snackbar
        autoHideDuration={6000}
        onClose={() => _handleErrorClose()}
        open={Boolean(error)}
      >
        <Alert
          data-testid="error-display"
          onClose={() => _handleErrorClose()}
          severity="error"
        >
          {error}
        </Alert>
      </Snackbar>
      <YesNoConfirmDialog
        content={`${t('dialogs.hourFactorDialog.content')}`}
        onNo={() => {
          setIsHourFactorDialogOpen(false);
        }}
        onYes={() => {
          setIsHourFactorDialogOpen(false);
          _handleSubmit();
        }}
        open={isHourFactorDialogOpen}
        title={`${t('dialogs.hourFactorDialog.title')}`}
      />
    </LocalizationProvider>
  );
};

export default TimeCardForm;
