import { AddRounded } from '@mui/icons-material';
import Edit from '@mui/icons-material/Edit';
import InsertChartRoundedIcon from '@mui/icons-material/InsertChartRounded';
import VisibilityRoundedIcon from '@mui/icons-material/VisibilityRounded';
import {
  ETOButton,
  ETOMenu,
  MenuOption,
  MessageContext,
} from '@teto/react-component-library-v2';
import { atom, useAtom } from 'jotai';
import { ScopeProvider } from 'jotai-molecules';
import { useResetAtom } from 'jotai/utils';
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import {
  Licenses,
  Permission,
  getGraphQLClient as getNewGraphQLClient,
} from 'teto-client-api';
import { Company } from '../../__generated__/graphql';
import { getGraphQLClient } from '../../api/graphQL/graphQLClient';
import ActionBar from '../../components/ActionBar/ActionBar';
import ReportInspector, {
  ReportInspectorProps,
} from '../../components/Inspectors/ReportInspector/ReportInspector';
import SharedBreadCrumbModal from '../../components/SharedStateComponents/SharedBreadCrumbModal';
import {
  companyDetailSharedState,
  initialCompanyDetailOnCloseScope,
} from '../../components/SharedStateComponents/StateContainers/CompanyState';
import ActionButtonGridBuilderExtension from '../../components/TETOGridGraphQL/GridBuilder/ActionButtonGridBuilderExtension';
import { GridResponsiveSettingsBuilder } from '../../components/TETOGridGraphQL/GridResponsiveSettingsBuilder';
import MainTetoGridGraphQL from '../../components/TETOGridGraphQL/TETOMainGridGraphQL';
import GridCommonMobileButtons from '../../components/TETOGridGraphQL/components/GridCommonMobileButtons/GridCommonMobileButtons';
import useGrid from '../../components/TETOGridGraphQL/hooks/useGrid';
import { useGridBuilderFromView } from '../../components/TETOGridGraphQL/hooks/useGridBuilder';
import { GridResponsiveSettings } from '../../components/TETOGridGraphQL/types/GridResponsiveSettings';
import { MandatoryFilters } from '../../components/TETOGridGraphQL/types/MandatoryFilterTypes';
import TETOGridRefType from '../../components/TETOGridGraphQL/types/TETOGridRefType';
import TetoContainer from '../../components/TetoGrid/TetoContainer';
import RDGSelectedType from '../../components/TetoGrid/types/RDGSelectedType';
import AuthContext from '../../contexts/AuthContext';
import CompanySearchResults from '../../views/CompanySearchResultsView.yaml';
import reportParametersQuery from '../InventoryPage/queries/reportParametersQuery';
import EmployeeReport from '../InventoryPage/types/employeeReport';
import EmployeeReportParameter from '../InventoryPage/types/reportParameters';
import companyReportListQuery from './queries/companyReportListQuery';
import companyReportObjects from './queries/companyReportObjects';

type ReportProps = Pick<
  ReportInspectorProps<{ [key: string]: unknown }>,
  'report' | 'title'
>;
interface ReportOption {
  label: string;
  disabled: boolean;
  onClick: () => void;
}

const RootQueryPath = 'companies';
const PersistenceName = 'companies';

const defaultCompany = {
  id: undefined,
  name: undefined,
};

const ALWAYS_PROJECT_COLUMNS: Array<string> = ['id', 'name'];
const responsiveSettings = {
  xs: {
    disableGroupByToolbar: true,
    disableGroupColumn: false,
    disableInlineEdit: true,
    disableMobileCols: false,
    enableFiltering: false,
    inputVariant: 'filled' as 'filled' | 'standard',
    renderAggregatesInGroupLabel: false,
    rowHeight: null,
    showEmptyRows: false,
    showHoverRows: false,
  },
  md: {
    disableGroupByToolbar: false,
    disableGroupColumn: false,
    disableInlineEdit: false,
    disableMobileCols: true,
    enableFiltering: true,
    inputVariant: 'standard' as 'filled' | 'standard',
    renderAggregatesInGroupLabel: false,
    rowHeight: 35,
    showEmptyRows: true,
    showHoverRows: true,
  },
};

const CompanyPage = () => {
  const { t, ready } = useTranslation();

  const authContext = useContext(AuthContext);
  const messageContext = useContext(MessageContext);
  const navigate = useNavigate();

  const gridRef = useRef<TETOGridRefType | undefined>();
  const reportButtonRef = useRef<HTMLButtonElement | null>(null);

  const [configureInspectorOpen, setConfigureInspectorOpen] =
    useState<boolean>(false);
  const [report, setReport] = useState<ReportProps | undefined>(undefined);
  const [reportMenu, setReportMenu] = useState<HTMLButtonElement | null>();
  const [reportOptionList, setReportOptionList] = useState<
    ReportOption[] | undefined
  >(undefined);

  const resetCompany = useResetAtom(companyDetailSharedState);
  const [companyDetail, setCompanyDetail] = useAtom(companyDetailSharedState);

  const { canAccessCompany, canAddCompany, canModifyCompany } = useMemo(
    () => ({
      canAddCompany:
        authContext.hasLicense(Licenses.TotalETOProfessional) &&
        authContext.hasPermission(
          Permission.Add_Sales_CompanyInformation_NewCompany
        ),
      canAccessCompany:
        authContext.hasLicense(Licenses.ReadOnlyProfessional) &&
        authContext.hasAnyPermission([
          Permission.Add_Sales_CompanyInformation,
          Permission.Modify_Sales_CompanyInformation,
          Permission.View_Sales_CompanyInformation,
        ]),
      canModifyCompany:
        authContext.hasLicense(Licenses.TotalETOProfessional) &&
        authContext.hasPermission(Permission.Modify_Sales_CompanyInformation),
    }),
    [authContext]
  );

  const customOnClose = atom(null, () => {
    if (companyDetail.hasSavedOccurred) {
      gridProps.refreshDataSource();
    }
    resetCompany();
  });

  useEffect(() => {
    if (!canAccessCompany) navigate('/access-denied', { replace: true });
  }, [authContext, canAccessCompany, navigate]);

  const { gridBuilder, builderReady, error, hasError } = useGridBuilderFromView(
    CompanySearchResults,
    RootQueryPath,
    (gb) =>
      gb
        .extension(
          new ActionButtonGridBuilderExtension([
            {
              componentName: canModifyCompany
                ? t('pages.companies.editCompany')
                : t('generic.viewDetail'),
              icon: canModifyCompany ? <Edit /> : <VisibilityRoundedIcon />,
              title: canModifyCompany
                ? t('pages.companies.editCompany')
                : t('generic.viewDetail'),
              onClick: ({ data }) => _handleCompanyDetail(data),
              color: 'primary',
            },
          ]),
          t
        )
        .updateDefinition('name', {
          parentPath: 'id',
        })
  );

  const mandatoryFilters = useMemo(() => {
    const filters: MandatoryFilters = [];

    return filters;
  }, []);

  const { gridProps } = useGrid(
    PersistenceName,
    `${RootQueryPath}.items`,
    (e) => messageContext.setError(e.message ?? e),
    t,
    ALWAYS_PROJECT_COLUMNS,
    gridBuilder,
    {
      filterAndSortMode: 'serverSide',
      mandatoryFilter: mandatoryFilters,
    }
  );

  const _handleCompanyDetail = useCallback(
    (data: RDGSelectedType) => {
      const formattedCDvalue = {
        id: data.id,
        name: data.name,
      } as Company;

      setCompanyDetail({
        initialValues: formattedCDvalue,
        open: true,
        hasSavedOccurred: false,
      });
    },
    [setCompanyDetail]
  );

  const formatReportForm = useCallback((reportId: number, title: string) => {
    const { localStorageKey, reportForm, schema } =
      companyReportObjects[reportId];

    getNewGraphQLClient()
      .performQuery(reportParametersQuery, { id: reportId })
      .then((d) => {
        if (d.hasError()) {
          d.showAllSystemErrors(messageContext.setError);
          if (d.hasValidationErrors()) {
            const { input } = d.validationErrors;
            messageContext.setError(Object.values(input)[0] as string);
          }
          return;
        }
        if (d.data.employeeReportParameters) {
          const defaultValues: { [key: string]: unknown } = {};
          (
            d.data.employeeReportParameters as EmployeeReportParameter[]
          ).forEach((i: EmployeeReportParameter) => {
            if (i.defaultValue === null) defaultValues[i.parameter.name] = '';
            else defaultValues[i.parameter.name] = i.defaultValue;
          });

          setReport({
            report: {
              defaultValues,
              localStorageKey,
              reportForm,
              reportId,
              schema,
            },
            title,
          });
        }
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const _handleReportButtonClick = useCallback(() => {
    if (reportOptionList) setReportMenu(reportButtonRef?.current);
    else {
      getGraphQLClient()
        .performQuery(companyReportListQuery, {})
        .then((d) => {
          const reports = (
            (d as { companyReports: EmployeeReport[] })?.companyReports ?? []
          ).map((r) => ({
            label: r.reportDisplayName,
            disabled: !r.allowView,
            onClick: () => formatReportForm(r.reportID, r.reportDisplayName),
          }));

          setReportOptionList(reports);
          setReportMenu(reportButtonRef?.current);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formatReportForm, reportOptionList]);

  const formatParameters = useCallback((data: { [key: string]: unknown }) => {
    const results: { [key: string]: unknown } = {};
    Object.keys(data).forEach((i) => {
      if (/^@bit*/.test(i)) results[i] = data[i] !== '0';
      else if (['@nvcCompanyLettersIn', '@nvcCompanyTypesIn'].indexOf(i) >= 0) {
        results[i] = `|${(data[i] as string).replace(/\,/g, '|')}|`;
      } else results[i] = data[i];
    });
    return results;
  }, []);

  const reportBtn = useMemo(
    () => ({
      title: t('generic.reports'),
      icon: <InsertChartRoundedIcon />,
      onclick: () => _handleReportButtonClick(),
      customComponent: (
        <ETOButton
          buttonProps={{ ref: reportButtonRef }}
          color="primary"
          icon={<InsertChartRoundedIcon />}
          key={t('generic.reports')}
          onClick={_handleReportButtonClick}
          size="medium"
        >
          {t('generic.reports')}
        </ETOButton>
      ),
    }),
    [_handleReportButtonClick, t]
  );

  const addCompanyBtn = useMemo(
    () => ({
      title: t('pages.companies.addCompany'),
      icon: <AddRounded />,
      disabled: !canAddCompany,
      onclick: () =>
        setCompanyDetail({
          initialValues: defaultCompany as unknown as Company,
          open: true,
          hasSavedOccurred: false,
        }),
    }),
    [t, canAddCompany, setCompanyDetail]
  );

  const GridCommonBtns = GridCommonMobileButtons({
    refreshGrid: () => gridProps.refreshDataSource(),
    gridRef,
    setConfigureInspector: () => setConfigureInspectorOpen(true),
  });

  const rightChildren = useMemo(
    () => [addCompanyBtn, reportBtn, GridCommonBtns],
    [GridCommonBtns, addCompanyBtn, reportBtn]
  );

  const defaultGridResponsiveSettings = GridResponsiveSettingsBuilder.up(
    responsiveSettings
  ) as GridResponsiveSettings;

  return (
    <ScopeProvider
      scope={initialCompanyDetailOnCloseScope}
      value={customOnClose}
    >
      <TetoContainer>
        <ActionBar rightChildren={rightChildren} />
        <MainTetoGridGraphQL
          builderReady={builderReady}
          configureInspector={configureInspectorOpen}
          customGridWrapSx={{ gridTemplateRows: 'unset' }}
          error={error}
          externalQueryProps={[]}
          gridProps={gridProps}
          hasError={hasError}
          header={{ hidden: true }}
          mobileGridOptions
          pagination
          ready={ready}
          ref={gridRef}
          responsiveSettings={defaultGridResponsiveSettings}
          setConfigureInspector={setConfigureInspectorOpen}
          showEmptyRows
          showGroupSummaryRow={false}
          t={t}
          tableIdentifier={PersistenceName}
        />
        {report && (
          <ReportInspector
            {...report}
            formatParameters={(d) => formatParameters(d)}
            onClose={() => setReport(undefined)}
            open={Boolean(report)}
          />
        )}
        {reportMenu && reportOptionList && (
          <ETOMenu
            anchorEl={reportMenu}
            menuOptions={reportOptionList as MenuOption[]}
            open={Boolean(reportMenu)}
            setClose={() => setReportMenu(null)}
            sx={{
              zIndex: 2,
            }}
          />
        )}
        <SharedBreadCrumbModal />
      </TetoContainer>
    </ScopeProvider>
  );
};

export default CompanyPage;
