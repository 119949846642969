import {
  Box,
  Button,
  SxProps,
  Theme,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import React, { useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import SettingsContext from '../../../../contexts/SettingsContext';
import GroupingTotalsMobilePopover from '../../../TetoGrid/Grouping/GroupingTotalsMobilePopover/GroupingTotalsMobilePopover';
import { groupNameFormatter } from '../../helpers/groupingHelpers';

interface MobileGroupTitleProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  val: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  rowProps: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  groupingColumns: any;
}

const containerSx: SxProps<Theme> = {
  display: 'flex',
  gap: 1,
  width: '95%',
};

const valueSx: SxProps<Theme> = {
  flex: 1,
  flexGrow: 1,
  overflow: 'hidden',
  lineHeight: '32px',
};

const MobileGroupTitle = (props: MobileGroupTitleProps) => {
  const { val, rowProps, groupingColumns } = props;
  const settingsContext = useContext(SettingsContext);

  const { t } = useTranslation();

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const [totalBtnAnchorEl, setTotalBtnAnchorEl] =
    React.useState<HTMLButtonElement | null>(null);

  const formattedValue = useMemo(() => {
    const { depth, fieldPath } = rowProps?.data || {};
    if (groupingColumns[fieldPath[depth - 1]]?.renderGroupTitle)
      return groupingColumns[fieldPath[depth - 1]].renderGroupTitle(val);
    return groupNameFormatter(
      {
        dateFormat: settingsContext.settings.dateFormat,
        dateTimeFormat: settingsContext.settings.dateTimeFormat,
        timeFormat: settingsContext.settings.timeFormat,
        currencyFormatter: settingsContext.settings.baseCurrencyFormatter,
      },
      t,
      groupingColumns[fieldPath[depth - 1]],
      val,
      {}
    );
  }, [
    groupingColumns,
    rowProps.data,
    settingsContext.settings.baseCurrencyFormatter,
    settingsContext.settings.dateFormat,
    settingsContext.settings.dateTimeFormat,
    settingsContext.settings.timeFormat,
    t,
    val,
  ]);

  const renderGroupTitle = () => {
    if (
      !rowProps?.data?.groupColumnSummary ||
      Object.values(rowProps?.data?.groupColumnSummary || {}).every((d) => !d)
    ) {
      return false;
    }

    return isMobile;
  };

  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {renderGroupTitle() ? (
        <>
          <Box sx={containerSx}>
            <Box sx={valueSx}>{formattedValue}</Box>
            <Box sx={{ width: 64 }}>
              <Button
                color="primary"
                data-testid={`${rowProps.rowIndex}-${val}`}
                onClick={(e) => setTotalBtnAnchorEl(e?.currentTarget)}
                size="small"
              >
                {t('generic.total')}
              </Button>
            </Box>
          </Box>
          <GroupingTotalsMobilePopover
            anchorEl={totalBtnAnchorEl}
            isMobile={isMobile}
            rowProps={rowProps}
            setAnchorEl={setTotalBtnAnchorEl}
          />
        </>
      ) : (
        <span>{formattedValue}</span>
      )}
    </>
  );
};

export default MobileGroupTitle;
