import { Box, SxProps, Theme } from '@mui/material';
import React from 'react';

const MOBILE_CELL_HEIGHT = 36;
const MOBILE_CELL_WIDTH = 144;

const wrapperSx: SxProps<Theme> = (theme: Theme) => ({
  //  this code below causes this bug #76038
  // '& .MuiInputBase-input': {
  //   p: `${theme.spacing(0.5)} ${theme.spacing(1.75)} !important`,
  // },
  [theme.breakpoints.down('md')]: {
    backgroundColor: theme.palette.background.paper,
    borderRadius: theme.spacing(0.5),
    maxHeight: `calc(${MOBILE_CELL_HEIGHT}px - 3px)`,
    '& .MuiTooltip-tooltip': {
      marginTop: '0 !important',
      maxWidth: MOBILE_CELL_WIDTH + 14,
      lineHeight: 1.5,
      whiteSpace: 'pre-line',
      padding: `${theme.spacing(0.5)} ${theme.spacing(1)}`,
      fontSize: '0.7rem',
    },
    '& .MuiInputBase-input': {
      height: '100%',
    },
  },
  [theme.breakpoints.up('md')]: {
    width: '100%',
    position: 'absolute',
    left: 0,
    top: 0,
    '& .MuiInputBase-input': {
      fontSize: '0.875em',
      height: '100%',
      '&:hover': {
        // #77472
        background: 'unset !important',
      },
    },
  },
});

const EditorWrapper = (props: { children: React.ReactNode; sx?: SxProps }) => {
  const { children, sx } = props;
  return (
    <Box sx={[wrapperSx, ...(Array.isArray(sx) ? sx : [sx])]}>{children}</Box>
  );
};

export default EditorWrapper;
