import { gql } from '../../../../__generated__';

const processScheduleHeaderQuery = gql(`query processScheduleHeader($id: Int!) {
    processScheduleHeader(id: $id) {
      completionDate
      createdDate
      custom1
      custom2
      custom3
      custom4
      custom5
      custom6
      custom7
      custom8
      number
      itemId
      item{
        itemCompanyId
      }
      project{
        displayName
      }
      spec{
        displayName
      }
      quantity
      ownerEmployeeId
      ownerEmployee{
        name
      }
      startDate
      completionDate
      finalRequiredDate
      materialItemId
      materialAmount
      materialTotalEstimate
      active
      procureMaterial
      materialFromFirstSupplier
      id
      status {
        description
      }
    }
  }
  `);

export default processScheduleHeaderQuery;
