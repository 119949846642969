import { gql } from '../../../../__generated__';

const applyTemplateMutation =
  gql(`mutation applyProcessScheduleTemplate($input: ApplyProcessScheduleTemplateInput) {
    applyProcessScheduleTemplate(input: $input) {
      id
    }
  }`);

export default applyTemplateMutation;
