import { useMediaQuery, useTheme } from '@mui/material';
import React from 'react';
import Tab from '../../../CustomTabs/interfaces/Tab';
import MobileTabs from '../../../CustomTabs/MobileTabs/MobileTabs';
import DesktopTabs from '../../../CustomTabs/DesktopTabs/DesktopTabs';

interface InpectorSelectTabProps {
  tabs: Tab[];
  currentTab: number;
  // eslint-disable-next-line no-unused-vars
  onTabChanged: (index: number) => void;
}

const InpectorSelectTab = (props: InpectorSelectTabProps) => {
  const { tabs, currentTab, onTabChanged } = props;

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  return isMobile ? (
    <MobileTabs
      currentTab={currentTab}
      onTabChanged={onTabChanged}
      tabs={tabs}
    />
  ) : (
    <DesktopTabs
      currentTab={currentTab}
      onTabChanged={onTabChanged}
      tabs={tabs}
    />
  );
};

export default InpectorSelectTab;
