import { MenuItem } from '@teto/react-component-library-v2';
import { TFunction } from 'i18next';
import { ReactElement } from 'react';
import { Location, matchPath } from 'react-router-dom';
import { Permission } from 'teto-client-api';
import { AuthContextState } from '../contexts/AuthContext';
import { Constraint, AuthMenu } from './AuthMenu';
import menuIcons from './menuIcons';

const LICENSES: Record<string, number> = {
  None: 0,
  TimeProfessional: 1,
  TimeEnterprise: 2,
  ReadOnlyProfessional: 4,
  ReadOnlyEnterprise: 8,
  TotalETOProfessional: 16,
  TotalETOEnterprise: 32,
};

class MenuBuilder {
  private url = '';

  private applicationMenu: MenuItem[] = [];

  private menuIcons = menuIcons;

  private translation: TFunction;

  private authContext: AuthContextState;

  private permission: Record<string, string> = Permission;

  private location: Location;

  private errors: { [key: string]: string[] } = {};

  constructor(
    jsonMenu: string,
    translate: TFunction,
    authContext: AuthContextState,
    location: Location
  ) {
    this.translation = translate;
    this.authContext = authContext;
    this.location = location;
    this.initializeMenu(JSON.parse(jsonMenu));
  }

  private initializeMenu(menu: AuthMenu[]) {
    this.applicationMenu = menu.map((i) => this.buildMenuItem(i));
    return this;
  }

  private buildMenuItem(menu: AuthMenu, isChild?: boolean): MenuItem {
    const menuItems = [];
    if (menu.menuItems && menu.menuItems.length !== 0) {
      let i = 0;
      while (i < menu.menuItems.length) {
        menuItems.push(this.buildMenuItem(menu.menuItems[i], true));
        i += 1;
      }
    }
    return {
      ...menu,
      title: this.setTitle(menu.id, menu.title) as string,
      leftIcon: isChild
        ? undefined
        : (this.setIcon(menu.id, menu.leftIcon!) as ReactElement),
      isActive: this.isActive(menu.id, menu.route),
      hidden:
        menu.license && menu.permissions
          ? this.isHidden(
              menu.id,
              menu.license,
              menu.permissions,
              menu.approver
            )
          : undefined,
      menuItems,
    };
  }

  private hasValidPermissions(
    id: string,
    permissions: string[],
    options: 'any' | 'all'
  ) {
    try {
      if (!Array.isArray(permissions)) {
        this.addError('id', 'Invalid permission shape');
        return false;
      }
      const translatedPermissions = this.translatePermissions(id, permissions);
      if (options === 'any') {
        return this.authContext.hasAnyPermission(translatedPermissions);
      }
      if (options === 'all')
        return this.authContext.hasAllPermissions(translatedPermissions);
    } catch (error) {
      this.addError(id, (error as Error).message);
      return false;
    }
  }

  private translatePermissions(id: string, permissions: string[]) {
    return permissions.map((i) => {
      const p = this.permission[i as keyof Permissions];
      if (!p) this.addError(id, 'Invalid permission');
      return p;
    });
  }

  private isHidden(
    id: string,
    license: string,
    permissions: Constraint,
    approver?: boolean
  ) {
    if (!('any' in permissions) && !('all' in permissions)) {
      this.addError(id, 'Invalid permission shape');
      return true;
    }

    const validLicense =
      license === 'any'
        ? this.authContext.hasAnyLicense()
        : this.authContext.hasLicense(LICENSES[license]);

    const validPermissions = permissions.any
      ? this.hasValidPermissions(id, permissions.any, 'any')
      : this.hasValidPermissions(id, permissions.all as string[], 'all');

    return approver
      ? !validLicense ||
          !(validPermissions || this.authContext.user?.isApprover)
      : !validLicense || !validPermissions;
  }

  private setTitle(id: string, title: string) {
    if (!title) this.addError(id, 'Missing title');
    return this.translation(title ?? '');
  }

  private setIcon(id: string, icon: string) {
    if (!icon) this.addError(id, 'Missing left icon');
    return this.menuIcons[icon];
  }

  // eslint-disable-next-line class-methods-use-this
  private isRouteActive(route: string, pathname: string) {
    return (
      !!matchPath(`${this.url}${route}`, pathname) || pathname.includes(route)
    );
  }

  private isActive(id: string, route: string) {
    if (!route) this.addError(id, 'Missing route');
    switch (route) {
      case '/procurement/procurement':
        return (
          this.isRouteActive('procurement', this.location.pathname) &&
          !this.isRouteActive(
            '/procurement/purchaseOrders',
            this.location.pathname
          ) &&
          !this.isRouteActive('/procurement/rfq', this.location.pathname)
        );
      default:
        return this.isRouteActive(route ?? '', this.location.pathname);
    }
  }

  private addError(key: string, errorMessage: string) {
    if (this.errors[key]) {
      this.errors[key].push(errorMessage);
    } else {
      this.errors[key] = [errorMessage];
    }
  }

  public getMenu = () => this.applicationMenu;

  public getErrors = () => this.errors;
}

export default MenuBuilder;
