import { gql } from '../../../__generated__';
import SelectDefinition from './SelectDefinition';

export default {
  query: gql(`
    query getRFQCompanies($where: RFQCompanyFilterInput) {
      rFQCompanies(where: $where){
        items {
          company{
            city
            supplier{
              supQaapproved
              id
            }
            id
            name
            city
            phone
            zip
            state
          }
          companyId
          rFQId
        }
      }
    }
  `),
  valueField: 'rFQId',
  labelField: 'company.name',
} as SelectDefinition;
