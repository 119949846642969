import { useAtom, useSetAtom } from 'jotai';
import { useMolecule } from 'jotai-molecules';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import PurchaseOrderModal from '../../Modals/PurchaseOrderModal/PurchaseOrderModal';
import { BreadCrumbSharedState } from '../StateContainers/BreadCrumbModalState';
import {
  PurchaseOrderSharedState,
  onPurchaseOrderClose,
} from '../StateContainers/PurchaseOrderState';

const useSharedPurchaseOrder = () => {
  const { t } = useTranslation();
  const [purchaseOrder] = useAtom(PurchaseOrderSharedState);
  const onCloseFn = useMolecule(onPurchaseOrderClose);
  const defaultOnClose = useSetAtom(onCloseFn);
  const setLevels = useSetAtom(BreadCrumbSharedState);

  useEffect(() => {
    if (purchaseOrder.open) {
      setLevels((prev) => [
        ...prev,
        {
          name: purchaseOrder?.initialValues?.id
            ? `${t('entities:PurchaseOrder.PurchaseOrder')}: ${String(
                purchaseOrder?.initialValues?.id
              )}`
            : t('entities:PurchaseOrder.PurchaseOrder'),
          data: purchaseOrder?.initialValues,
          component: (
            <PurchaseOrderModal
              initialValues={purchaseOrder.initialValues}
              onClose={defaultOnClose}
              open={purchaseOrder.open}
            />
          ),
        },
      ]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    // defaultOnClose,
    // setLevels, // don't add this to the dependency array, it will cause an infinite loop bug #75462
    purchaseOrder,
  ]);
};

export default useSharedPurchaseOrder;
