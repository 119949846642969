import { Autocomplete, debounce, MenuItem, TextField } from '@mui/material';
import { MessageContext } from '@teto/react-component-library-v2';
import { FormikProps } from 'formik';
import React, { useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { getGraphQLClient } from '../../../../../../api/graphQL/graphQLClient';
import { partsNumberQuery } from '../queries/InfoPanelQueries';

interface PartNumberSearchInputProps {
  disabled?: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  formik: FormikProps<any>; // add Record<string, unknown>
  label?: string;
  placeholder?: string;
}

const PartNumberSearchInput = (props: PartNumberSearchInputProps) => {
  const { disabled, formik, label, placeholder } = props;

  const { t } = useTranslation();

  const messageContext = useContext(MessageContext);

  const [isPartsLoading, setIsPartsLoading] = React.useState<boolean>(false);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [partsValue, setPartsValue] = React.useState<any[] | null>(null);
  const [inputValue, setInputValue] = React.useState(
    formik.values.itemCompanyId || ''
  );
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [partNumbers, setPartNumbers] = React.useState<any[]>([]);

  const debouncedSubmit = useMemo(
    () =>
      debounce(
        () =>
          getGraphQLClient()
            .performQuery(
              partsNumberQuery,
              {
                itemCompanyId: inputValue,
              },
              (err) => messageContext.setError(err.messages[0]),
              (err) => {
                const { input } = err;

                messageContext.setError(Object.values(input)[0] as string);
              }
            )
            .then((d) => {
              if (d.engItemMasters) {
                if (d.engItemMasters.items.length > 0) {
                  setPartNumbers(d.engItemMasters.items);
                  formik.setFieldError('itemId', undefined);
                } else {
                  formik.setFieldError('itemId', 'Invalid Part No.');
                }
                setIsPartsLoading(false);
              }
            }),
        1000
      ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [inputValue, partsNumberQuery]
  );

  React.useEffect(() => {
    if (inputValue === '') {
      setPartNumbers(partsValue ? [partsValue] : []);
    } else {
      setIsPartsLoading(true);
      debouncedSubmit();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [partsValue, inputValue, formik.values.itemId]);

  return (
    <Autocomplete
      autoComplete
      // data-testid="nci-part-number"
      disabled={disabled}
      filterOptions={(x) => x}
      filterSelectedOptions
      forcePopupIcon
      freeSolo
      fullWidth
      getOptionLabel={(item) => item.itemCompanyId}
      id="itemId"
      includeInputInList
      isOptionEqualToValue={(option, value) =>
        value === undefined || value === '' || option.id === value.id
      }
      loading={isPartsLoading}
      noOptionsText={`${t('generic.no')} ${t('generic.results')}`}
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      onChange={(event: any, newValue: any | null) => {
        setPartNumbers(newValue ? [newValue, ...partNumbers] : partNumbers);
        setPartsValue(newValue);
        if (newValue === null) {
          formik.setFieldValue('itemId', null);
        } else {
          formik.setFieldValue('itemId', newValue.id);
        }
      }}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue);
      }}
      options={partNumbers}
      renderInput={(params) => (
        <TextField
          error={Boolean(formik.errors.itemId)}
          {...params}
          fullWidth
          helperText={formik.errors.itemId as string}
          label={label || t('entities:NonConformance.PartNumber')}
          name="itemId"
          placeholder={placeholder}
        />
      )}
      renderOption={(prp, option) => (
        <MenuItem {...prp} key={option.id} value={option.id}>
          {option.itemCompanyId}
        </MenuItem>
      )}
      size="small"
      value={formik.values.item ?? undefined}
    />
  );
};

export default PartNumberSearchInput;
