/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import ETOBooleanEditor from './Editors/ETOBooleanEditor';
import ETODateEditor from './Editors/ETODateEditor';
import ETODateTimeEditor from './Editors/ETODateTimeEditor';
import ETONumberEditor from './Editors/ETONumberEditor';
import ETOSelectEditor from './Editors/ETOSelectEditor';
import ETOStringEditor from './Editors/ETOStringEditor';
import { EditedValue } from './types/EditedValue';
import ETOHourEditor from './Editors/ETOHourEditor';
import EditorType from './types/EditorTypes';

const getEditor = (
  columnType: string,
  editorType?: EditorType,
  inputVariant: 'standard' | 'filled' = 'standard'
) =>
  // eslint-disable-next-line func-names
  function (props: any, editVal?: EditedValue) {
    const { validationMessages } = editVal || {};

    const { value } = props;

    const error =
      validationMessages && validationMessages.length > 0
        ? validationMessages[0]
        : undefined;

    const val = value ?? '';

    // look for a specified editor type, if there isn't one than use the column type
    const actualType = editorType ?? columnType;

    switch (actualType) {
      case 'boolean':
        return <ETOBooleanEditor {...props} error={error} value={val} />;
      case 'date':
        return (
          <ETODateEditor
            {...props}
            errors={validationMessages}
            value={val}
            variant={inputVariant}
          />
        );
      case 'datetime':
      case 'time':
        return (
          <ETODateTimeEditor
            {...props}
            errors={validationMessages}
            value={val}
            variant={inputVariant}
          />
        );
      case 'number':
      case 'currency':
        return (
          <ETONumberEditor
            {...props}
            errors={validationMessages}
            value={val}
            variant={inputVariant}
          />
        );
      case 'select':
        return (
          <ETOSelectEditor
            {...props}
            errors={validationMessages}
            value={val}
            variant={inputVariant}
          />
        );
      case 'hours':
        return (
          <ETOHourEditor
            {...props}
            errors={validationMessages}
            value={val || 0}
            variant={inputVariant}
          />
        );

      case 'string':
      default:
        return (
          <ETOStringEditor
            {...props}
            errors={validationMessages}
            value={val}
            variant={inputVariant}
          />
        );
    }
  };

export default getEditor;
