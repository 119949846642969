import { TypeRowSelection } from '@inovua/reactdatagrid-community/types';
import {
  Box,
  FormControl,
  FormControlLabel,
  FormLabel,
  RadioGroup,
  SxProps,
  Theme,
} from '@mui/material';
import Radio from '@mui/material/Radio';
import {
  ButtonStrip,
  GenericDialog,
  MessageContext,
} from '@teto/react-component-library-v2';
import React, { FC, useCallback, useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { getGraphQLClient } from 'teto-client-api';
import { gql } from '../../__generated__';
import getErrors from '../../api/graphQL/getErrors';
import FormBuilder from '../TETOForms/FormBuilder/FormBuilder';
import FormDefinition from '../TETOForms/FormDefinition';
import TETOForm from '../TETOForms/TETOForm';
import useForm from '../TETOForms/hooks/useForm';
import { useFormBuilder } from '../TETOForms/hooks/useFormBuilder';

export interface POTaxSettingsProps {
  open: boolean;
  entityItems?: {
    id: number;
    itemMasterDescription: string;
    entityDescription?: string | null;
  }[];
  entityName: string;
  // eslint-disable-next-line no-unused-vars, @typescript-eslint/no-explicit-any
  onClose: (update?: any) => void;
  selected?: TypeRowSelection | undefined;
}

const formContainerSx: SxProps<Theme> = {
  width: '100%',
};
const containerSx: SxProps<Theme> = {
  width: '100%',
  '& .css-1k85uti-MuiPaper-root-MuiDialog-paper': {
    maxWidth: { xs: '95%', md: 'unset !important' },
  },
};

const replacePODetailPartMutation = gql(`
  mutation replacePODetailPart($input: ReplacePurchaseOrderDetailPartNumbersInput) {
    replacePurchaseOrderDetailPartNumbers (input: $input) {
      items {
        id
      }
    }
  }
`);

const replaceRFQDetailPartMutation = gql(`
  mutation replaceRFQDetailPart($input: ReplaceRFQDetailPartNumbersInput) {
    replaceRFQDetailPartNumbers (input: $input) {
      items {
        id
      }
    }
  }
`);

const partNumberEnums = {
  SUPPLIER: 'SUPPLIER_PART_NUMBER',
  MANUFACTURER: 'MANUFACTURER_PART_NUMBER',
  ITEMMASTER: 'ITEM_COMPANY_ID',
};
const FIELD_LAYOUT_SET = [
  {
    name: 'replaceWhich',
    units: {
      xs: 1,
    },
  },
  {
    name: 'replaceWith',
    units: {
      xs: 1,
    },
  },
];

const ReplacePartNumbersModal: FC<
  React.PropsWithChildren<POTaxSettingsProps>
> = (props) => {
  const { open, onClose, entityItems, selected, entityName } = props;

  const { t } = useTranslation();

  const messageContext = useContext(MessageContext);

  const haveItemsBeenSelected = useMemo(
    () => selected && Object.keys(selected).length > 0,
    [selected]
  );

  const formBuilder = useFormBuilder(
    `${entityName}-replace-part-numbers-form`,
    (fb) =>
      fb
        .addString({
          title: t('generic.replace'),
          name: 'replaceWhich',
          defaultValue: haveItemsBeenSelected ? 'selected' : '',
          required: true,
          render: (form, field, options) => (
            <FormControl>
              <FormLabel>{t('generic.replace')}</FormLabel>
              <RadioGroup
                defaultValue={haveItemsBeenSelected ? 'selected' : ''}
                onChange={(e) => {
                  form.updateField('replaceWhich', e.target.value);
                }}
                value={options.value}
              >
                <FormControlLabel
                  control={<Radio />}
                  disabled={!haveItemsBeenSelected}
                  label="Selected Parts"
                  value="selected"
                />
                <FormControlLabel
                  control={<Radio />}
                  label="All Parts"
                  value="all"
                />
              </RadioGroup>
            </FormControl>
          ),
        })
        .addString({
          title: t('generic.with'),
          name: 'replaceWith',
          required: true,
          render: (form, field, options) => (
            <FormControl>
              <FormLabel>{t('generic.with')}</FormLabel>

              <RadioGroup
                onChange={(e) => {
                  form.updateField('replaceWith', e.target.value);
                }}
                value={options.value}
              >
                <FormControlLabel
                  control={<Radio />}
                  label="Supplier Part Number"
                  value={partNumberEnums.SUPPLIER}
                />
                <FormControlLabel
                  control={<Radio />}
                  label="Manufacturer Part Number"
                  value={partNumberEnums.MANUFACTURER}
                />
                <FormControlLabel
                  control={<Radio />}
                  label="Item Master Part Number"
                  value={partNumberEnums.ITEMMASTER}
                />
              </RadioGroup>
            </FormControl>
          ),
        })
  );
  const _handleRequest = useCallback(
    (
      fb: FormBuilder<Record<string, unknown>>,
      fd: FormDefinition,
      data: Record<string, unknown>
    ) => {
      const allRows = entityItems?.map((i) => ({
        id: i.id,
        updateDescription: i.itemMasterDescription === i.entityDescription,
      }));

      const selectedRowsIds = Object.keys(selected ?? {}).map((d) =>
        parseInt(d, 10)
      );

      const selectedItemsToReplace = allRows?.filter(
        (d) => selectedRowsIds.indexOf(d.id) > -1
      );
      const detailsToReplace =
        data.replaceWhich === 'all' ? allRows : selectedItemsToReplace;

      return {
        replaceWith: data.replaceWith,
        details: detailsToReplace,
      };
    },
    [entityItems, selected]
  );

  const _handleResponse = useCallback(
    (
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      fb: FormBuilder<any>,
      fd: FormDefinition,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      response: any
    ) => {
      if (response.hasError()) {
        if (response.hasSystemErrors())
          messageContext.setError(getErrors(response.systemErrors));
        if (response.hasValidationErrors()) {
          fd.setValidationErrors(response?.validationErrors.input);
        }
        return;
      }
      if (response.hasData()) {
        messageContext.setSuccess(
          t('generic.message.replacePartNumbersSuccess') as string
        );
        onClose(true);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [onClose, t]
  );

  const _handleSubmit = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (fb: FormBuilder<any>, fd: FormDefinition, data: any) =>
      getGraphQLClient().performMutation(
        entityName === 'PurchaseOrder'
          ? replacePODetailPartMutation
          : replaceRFQDetailPartMutation,
        {
          input: data,
        }
      ),
    [entityName]
  );

  const replacePartNumberForm = useForm(
    formBuilder,
    {
      onFormatRequest: (fb, fd, data) => _handleRequest(fb, fd, data),
      onFormatResponse: (fb, fd, res) => _handleResponse(fb, fd, res),
      onSubmitting: (fb, fd, data) => _handleSubmit(fb, fd, data),
    },
    {}
  );

  return (
    <GenericDialog
      isOpen={open}
      onClose={() => onClose()}
      sx={containerSx}
      title={t('pages.procurement.replacePartNumbers')}
    >
      <Box sx={formContainerSx}>
        <TETOForm
          form={replacePartNumberForm}
          formLayout={{
            form: {
              columns: 2,
              fields: FIELD_LAYOUT_SET,
            },
          }}
        />
        <ButtonStrip
          leftButton={{
            color: 'secondary',
            text: t('generic.cancel'),
            onClick: () => onClose(),
          }}
          rightButton={{
            color: 'primary',
            disabled:
              !replacePartNumberForm.dirty ||
              replacePartNumberForm.isSubmitting,
            text: t('generic.save'),
            onClick: () => replacePartNumberForm.submit(),
          }}
          size="medium"
        />
      </Box>
    </GenericDialog>
  );
};

export default ReplacePartNumbersModal;
