import { MessageContext } from '@teto/react-component-library-v2';
import React, { useContext, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import PartsHistoryInventory from '../../../views/PartsHistoryInventoryView.yaml';
import MainTetoGridGraphQL from '../../TETOGridGraphQL/TETOMainGridGraphQL';
import useGrid from '../../TETOGridGraphQL/hooks/useGrid';
import { useGridBuilderFromView } from '../../TETOGridGraphQL/hooks/useGridBuilder';
import { TetoGridGraphqlRefProps } from '../../TETOGridGraphQL/types/TetoGridGraphqlProps';
import useExportData from '../helpers/useExportData';
import CommonTabProps from './CommonTabProps';

const PersistenceName = 'ph-PartsHistoryInventory';
const RootQueryPath = 'inventoryPullDetails';

const InventoryPullsReturnsTab: React.FC<CommonTabProps> = (props) => {
  const {
    configureInspectorOpen,
    date,
    includeRelPS,
    part,
    setConfigureInspector,
    setShouldExportData,
    shouldExportData,
  } = props;
  const { t, ready } = useTranslation();
  const messageContext = useContext(MessageContext);
  const gridRef = useRef<TetoGridGraphqlRefProps | undefined>();

  const { gridBuilder, builderReady, error, hasError } = useGridBuilderFromView(
    PartsHistoryInventory,
    RootQueryPath,
    (gb) =>
      gb.updateDefinition('destInventoryLoc.name', {
        title: `Dest. ${t('entities:InventoryLocation.InventoryLocation')}`,
      })
  );

  const mandatoryFilters = useMemo(() => {
    const filters = [
      {
        name: 'fulfilledStatus',
        operator: 'equal',
        value: true,
        type: 'string' as const,
      },
      {
        name: 'fulfilledDate',
        operator: 'greaterThanEqualTo',
        type: 'datetime' as const,
        value: date.format('YYYY-MM-DD'),
      },
      {
        name: 'or.and[0].processScheduleId',
        operator: 'equalTo',
        type: 'number' as const,
        value: null,
      },
      {
        name: 'or.and[0].itemId',
        operator: 'equalTo',
        type: 'number' as const,
        value: part.id,
      },
    ];

    if (includeRelPS) {
      filters.push({
        name: 'or.and[1].processScheduleId',
        operator: 'notEqual',
        type: 'number' as const,
        value: null,
      });
      filters.push({
        name: 'or.and[1].processSchedule.materialItemId',
        operator: 'equal',
        type: 'number' as const,
        value: part.id,
      });
    }

    return filters;
  }, [date, includeRelPS, part.id]);

  const { gridProps } = useGrid(
    PersistenceName,
    `${RootQueryPath}.items`,
    (e) => messageContext.setError(e.message ?? e),
    t,
    [],
    gridBuilder,
    {
      filterAndSortMode: 'serverSide',
      mandatoryFilter: mandatoryFilters,
    }
  );

  useExportData(shouldExportData, setShouldExportData, gridRef);

  return (
    <>
      <MainTetoGridGraphQL
        builderReady={builderReady}
        configureInspector={configureInspectorOpen}
        error={error}
        externalQueryProps={[]}
        gridProps={gridProps}
        hasError={hasError}
        header={{
          hidden: true,
        }}
        mobileGridOptions
        ready={ready}
        ref={gridRef}
        setConfigureInspector={setConfigureInspector}
        showEmptyRows
        showGroupSummaryRow={false}
        t={t}
        tableIdentifier={PersistenceName}
        {...gridProps}
      />
    </>
  );
};

export default InventoryPullsReturnsTab;
