import { Grid } from '@mui/material';
import React from 'react';

interface Props {
  children: React.ReactNode;
  dataTestId?: string;
  mobileRowHeight: number;
}

const MobileCellWrapper = (props: Props) => {
  const { children, dataTestId, mobileRowHeight } = props;
  return (
    <Grid
      data-testid={dataTestId}
      sx={{
        height: mobileRowHeight,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        pt: 1,
      }}
    >
      {children}
    </Grid>
  );
};

export default MobileCellWrapper;
