import React from 'react';
import { useTranslation } from 'react-i18next';
import TetoGridDialog from '../TetoGrid/components/TetoGridDialog';

interface DiscardConfirmDialogProps {
  open: boolean;
  cancelChanges: () => void;
  cancelDialog: () => void;
}

const DiscardConfirmDialog: React.FC<DiscardConfirmDialogProps> = (props) => {
  const { t } = useTranslation();
  const { open, cancelDialog, cancelChanges } = props;

  return (
    <TetoGridDialog
      content={t('dialogs.closeUnsavedForm.content')}
      handleClose={() => cancelDialog()}
      leftButton={{
        label: t('generic.no'),
        onClick: () => cancelDialog(),
      }}
      open={open}
      rightButton={{
        label: t('generic.yes'),
        onClick: () => cancelChanges(),
      }}
      title={t('dialogs.closeUnsavedForm.title')}
    />
  );
};

export default DiscardConfirmDialog;
