import Box from '@mui/material/Box';
import {
  ETOTextField,
  ETOTextFieldProps,
  MessageContext,
} from '@teto/react-component-library-v2';
import { FormikErrors, FormikHelpers, useFormik } from 'formik';
import React, { useCallback, useContext, useEffect, useMemo } from 'react';
import { TFunction, useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { NonConformance, Permission } from 'teto-client-api';
import * as Yup from 'yup';
import { getGraphQLClient } from '../../../../../api/graphQL/graphQLClient';
import AuthContext from '../../../../../contexts/AuthContext';
import AutoSave from '../../../components/AddEditInspector/AutoSave/AutoSave';
import {
  formatNCUpdateMutation,
  getPropsInitialValues,
} from '../InfoPanel/InfoPanelHelpers';
import updateNonConformanceMutation from '../InfoPanel/updateNonConformanceMutation';
import { containerSx } from '../PanelCommonStyles';

interface ActionInputs {
  recommendedInterim?: string;
  correctivePreventiveAction?: string;
  qualityFollowUp?: string;
}

export interface ActionPanelProps {
  initialValues?: Record<string, unknown> & ActionInputs;
  // eslint-disable-next-line no-unused-vars
  handleEditingState: (value: { isValid: boolean; isEditing: boolean }) => void;
  // eslint-disable-next-line no-unused-vars
  setNonConformance: (data: Record<string, unknown>) => void;
  setHasASaveOccurred: React.Dispatch<React.SetStateAction<boolean>>;
}

const TEXTMAX = 4000;

const actionPanelSchema = Yup.object().shape({
  recommendedInterim: Yup.string(),
  correctivePreventiveAction: Yup.string(),
  qualityFollowUp: Yup.string().max(4000),
});

const getInputFields = (
  formikErrors: FormikErrors<ActionInputs>,
  formikValues: ActionInputs,
  translate: TFunction<'translation', undefined>
) => [
  {
    error: formikErrors?.recommendedInterim,
    id: 'recommendedInterim',
    label: translate('entities:NonConformance.RecommendedInterim'),
    name: 'recommendedInterim',
    value: formikValues.recommendedInterim ?? '',
  },
  {
    error: formikErrors?.correctivePreventiveAction,
    id: 'correctivePreventiveAction',
    label: translate('entities:NonConformance.CorrectivePreventiveAction'),
    value: formikValues.correctivePreventiveAction,
    name: 'correctivePreventiveAction',
  },
  {
    error: formikErrors?.qualityFollowUp,
    id: 'qualityFollowUp',
    label: translate('entities:NonConformance.QualityFollowUp'),
    name: 'qualityFollowUp',
    value: formikValues.qualityFollowUp,
  },
];

export const useBuildQuery = (
  queryKey: unknown[],
  query: {
    queryString: string;
    variables: { [key: string]: unknown };
    // eslint-disable-next-line no-unused-vars
    callback: (d: unknown) => unknown;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    extend: any;
    errorField: string;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    mContext: any;
  }
) =>
  useQuery(
    queryKey,
    () =>
      getGraphQLClient()
        .performQuery(
          query.queryString,
          query.variables,
          (err) => {
            query.mContext.setError(err.messages[0]);
          },
          (_err) => {
            query.mContext.setError(_err[query.errorField]);
          }
        )
        .then((d) => query.callback(d)),
    query.extend
  );

const ActionPanel = (props: ActionPanelProps) => {
  const {
    initialValues,
    handleEditingState,
    setNonConformance,
    setHasASaveOccurred,
  } = props;

  const { t } = useTranslation();

  const authContext = useContext(AuthContext);
  const messageContext = useContext(MessageContext);

  const canModifyNC = authContext.hasPermission(
    Permission.Modify_Manufacturing_NonConformances
  );

  const formik = useFormik<ActionInputs>({
    initialValues: {
      correctivePreventiveAction:
        initialValues?.correctivePreventiveAction ?? '',
      qualityFollowUp: initialValues?.qualityFollowUp ?? '',
      recommendedInterim: initialValues?.recommendedInterim ?? '',
    },
    enableReinitialize: true,
    validateOnMount: true,
    validationSchema: actionPanelSchema,
    onSubmit: (values, formikHelpers) =>
      _updateNonConformance(values, formikHelpers),
  });

  const inputFields: ETOTextFieldProps[] = useMemo(
    () =>
      getInputFields(
        formik.errors,
        formik.values as unknown as NonConformance,
        t
      ),
    [formik.errors, t, formik.values]
  );

  const _updateNonConformance = useCallback(
    (values: ActionInputs, helpers: FormikHelpers<ActionInputs>) => {
      getGraphQLClient()
        .performMutation(
          updateNonConformanceMutation,
          {
            input: {
              ...formatNCUpdateMutation({ ...initialValues, ...values }),
            },
          },
          (err) => messageContext.setError(err.messages[0]),
          (err) => {
            const { input } = err;

            messageContext.setError(Object.values(input)[0] as string);
          }
        )
        .then((d) => {
          if (d.updateNonConformance.id) {
            const result = getPropsInitialValues({
              ...d.updateNonConformance,
              item: d.updateNonConformance.item ?? undefined,
              purchaseOrderId:
                d.updateNonConformance?.purchaseOrderId ?? undefined,
              quantity: d.updateNonConformance?.quantity ?? undefined,
              quantityRejected:
                d.updateNonConformance?.quantityRejected ?? undefined,
              customer:
                d.updateNonConformance.project.company.name ?? undefined,
              supplier: d.updateNonConformance.purchaseOrder
                ? d.updateNonConformance.purchaseOrder.purchaseSupplier.name
                : undefined,
            });
            setNonConformance(result);
            setHasASaveOccurred(true);
          }
          helpers.setSubmitting(false);
        });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [initialValues]
  );

  useEffect(() => {
    handleEditingState({
      isEditing: formik.dirty,
      isValid: formik.isValid,
    });
  }, [formik.dirty, formik.isValid, handleEditingState]);

  return (
    <Box sx={{ ...containerSx, pt: 1, justifyContent: 'flex-start' }}>
      <AutoSave debounceMs={1500} formik={formik} />
      {inputFields.map((f) => (
        <ETOTextField
          data-testid={`ap-${f.name}`}
          disabled={!canModifyNC}
          error={f.error}
          handleChange={formik.handleChange}
          helperText={`${f.value.length.toString()} \/ ${TEXTMAX}`}
          key={f.label}
          label={f.label}
          multiline
          name={f.name}
          onBlur={formik.handleBlur}
          size="small"
          value={f.value}
        />
      ))}
    </Box>
  );
};

export default ActionPanel;
