import { useMediaQuery, useTheme } from '@mui/material';
import { useMemo } from 'react';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const useShouldActionBarCollapse = (rightChildren: any[]) => {
  const theme = useTheme();

  const isLessThanXLarge = useMediaQuery(theme.breakpoints.down('xl'));
  const isLessThanLarge = useMediaQuery(theme.breakpoints.down('lg'));

  const shouldActionBarCollapse = useMemo(
    () =>
      (isLessThanXLarge && rightChildren.length > 6) ||
      (isLessThanLarge && rightChildren.length > 4),
    [isLessThanLarge, isLessThanXLarge, rightChildren.length]
  );
  return shouldActionBarCollapse;
};

export default useShouldActionBarCollapse;
