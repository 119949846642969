import { capitalize } from '@mui/material';
import Box from '@mui/material/Box';
import { SxProps, Theme } from '@mui/material/styles';
import { FormikProps } from 'formik';
import React, { ChangeEvent, useCallback, useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Licenses, Permission } from 'teto-client-api';
import { Company } from '../../../../../__generated__/graphql';
import AuthContext from '../../../../../contexts/AuthContext';
import AddressAccordion, {
  AddressAccordionProps,
} from '../../component/AddressAccordion';
import AddressForm from '../../component/AddressForm';

export interface AddressPanelProps {
  companyId: number;
  // eslint-disable-next-line no-unused-vars
  setHasASaveOccurred: (payload: boolean) => void;
  formik: FormikProps<Company>;
}

const containerSx: SxProps<Theme> = {
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  overflowY: 'auto',
  pt: 2,
  width: '100%',
  '& MuiPaper-root-MuiAccordion-root': {
    m: '0px !important',
    backgroundColor: 'green',
  },
};

const mailingSx: SxProps<Theme> = {
  width: '100%',
};

const booleanField = [
  'shippingAddressSameAsMailing',
  'businessAddressSameAsMailing',
];

const AddressPanel = (props: AddressPanelProps) => {
  const { formik } = props;
  const { t } = useTranslation();

  const authContext = useContext(AuthContext);

  const canUpdateCompanyInfoPermission =
    authContext.hasLicense(Licenses.TotalETOProfessional) &&
    authContext.hasPermission(
      Permission.Modify_Sales_CompanyInformation_CompanyTypeInformation
    ) &&
    formik.values.id !== 1;

  const _formatInputField = useCallback(
    (key: string) => ({
      disabled: !canUpdateCompanyInfoPermission || formik.values.id === 1,
      name: key,
      value: formik.values?.[key as keyof Company],
      handleChange: (e: ChangeEvent<HTMLInputElement>) => {
        if (booleanField.indexOf(key) >= 0)
          formik.setFieldValue(key, e.target.checked);
        else formik.handleChange(e);
      },
      error: formik.errors?.[key as keyof Company] as string,
      label: t(
        `entities:Company.${
          /id/gi.test(key) ? capitalize(key.slice(0, -2)) : capitalize(key)
        }`
      ),
    }),
    [canUpdateCompanyInfoPermission, formik, t]
  );

  const mailingAddress = useMemo(
    () => ({
      address1Props: _formatInputField('address1'),
      address2Props: _formatInputField('address2'),
      cityProps: _formatInputField('city'),
      countryProps: _formatInputField('countryId'),
      stateProps: _formatInputField('state'),
      zipProps: _formatInputField('zip'),
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      _formatInputField,
      formik.values?.address1,
      formik.values?.address2,
      formik.values?.city,
      formik.values?.countryId,
      formik.values?.state,
      formik.values?.zip,
      formik.errors,
    ]
  );

  const billingAddress = useMemo(
    () => ({
      address1Props: _formatInputField('businessAddress1'),
      address2Props: _formatInputField('businessAddress2'),
      cityProps: _formatInputField('businessCity'),
      countryProps: _formatInputField('businessCountryId'),
      stateProps: _formatInputField('businessState'),
      zipProps: _formatInputField('businessZip'),
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      _formatInputField,
      formik.values?.businessAddress1,
      formik.values?.businessAddress2,
      formik.values?.businessCity,
      formik.values?.businessCountryId,
      formik.values?.businessState,
      formik.values?.businessZip,
      formik.errors,
    ]
  );

  const shippingAddress = useMemo(
    () => ({
      address1Props: _formatInputField('shippingAddress1'),
      address2Props: _formatInputField('shippingAddress2'),
      cityProps: _formatInputField('shippingCity'),
      countryProps: _formatInputField('shippingCountryId'),
      stateProps: _formatInputField('shippingState'),
      zipProps: _formatInputField('shippingZip'),
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      _formatInputField,
      formik.values?.shippingAddress1,
      formik.values?.shippingAddress2,
      formik.values?.shippingCity,
      formik.values?.shippingCountryId,
      formik.values?.shippingState,
      formik.values?.shippingZip,
      formik.errors,
    ]
  );

  return (
    <Box sx={containerSx}>
      {/* default Address */}
      <Box sx={mailingSx}>
        <AddressForm {...mailingAddress} />
      </Box>
      {/* Billing Address */}
      <AddressAccordion
        accordionProps={{ defaultSummary: 'Billing' }}
        addressFormProps={{ ...billingAddress }}
        sameAsMailing={
          _formatInputField(
            'businessAddressSameAsMailing'
          ) as AddressAccordionProps['sameAsMailing']
        }
      />

      {/* Shipping Address */}
      <AddressAccordion
        accordionProps={{ defaultSummary: 'Shipping' }}
        addressFormProps={shippingAddress}
        sameAsMailing={
          _formatInputField(
            'shippingAddressSameAsMailing'
          ) as AddressAccordionProps['sameAsMailing']
        }
      />
    </Box>
  );
};

export default AddressPanel;
