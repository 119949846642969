import { IconButton, Tooltip } from '@mui/material';
import { Logo, MessageContext } from '@teto/react-component-library-v2';
import { Theme, useTheme } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import * as React from 'react';
import ArrowCircleLeftRoundedIcon from '@mui/icons-material/ArrowCircleLeftRounded';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import ChangePasswordForm from './components/ChangePasswordForm';
import { getGraphQLClient } from '../../api/graphQL/graphQLClient';

const formTitleSx = {
  display: 'flex',
  alignItems: 'center',
};

const useStyles = (theme: Theme) => ({
  root: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    height: '100vh',
    justifyContent: 'center',
    overflowY: 'auto',
    width: '100vw',
    [theme.breakpoints.down('md')]: {
      backgroundColor: theme.palette.background.paper,
    },
    [theme.breakpoints.up('sm')]: {
      backgroundColor: theme.palette.background.default,
    },
    [`${theme.breakpoints.down('md')} and (orientation: landscape)`]: {
      height: 'auto',
    },
  },
  outerContainer: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    maxWidth: theme.spacing(52),
    width: '100%',
    [theme.breakpoints.down('md')]: {
      height: '100%',
    },
  },
  logoContainer: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(2),
    [theme.breakpoints.down('md')]: {
      height: theme.spacing(6),
    },
    [theme.breakpoints.up('sm')]: {
      height: theme.spacing(7),
    },
  },
  innerContainer: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.up('sm')]: {
      marginTop: theme.spacing(10),
    },
  },
  appTitle: {
    fontWeight: 600,
  },
  loginContainer: {
    alignItems: 'center',
    backgroundColor: theme.palette.background.paper,
    borderRadius: theme.spacing(1.5),
    display: 'flex',
    flexDirection: 'column',
    marginBottom: theme.spacing(6),
    padding: theme.spacing(4),
    paddingBottom: theme.spacing(5),
    paddingTop: theme.spacing(3),
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      marginBottom: 0,
      paddingBottom: 0,
    },
    [theme.breakpoints.down('md')]: {
      maxWidth: theme.spacing(40),
      boxShadow: theme.shadows[0],
    },
    [theme.breakpoints.up('sm')]: {
      maxWidth: theme.spacing(52),
      boxShadow: theme.shadows[4],
    },
  },
  link: {
    color: theme.palette.text.disabled,
  },
});

interface ChangePasswordPageProps {
  userName: string;
  onPasswordChanged: () => void;
}

const ChangePasswordPage = (props: ChangePasswordPageProps) => {
  const { t } = useTranslation();
  const { userName, onPasswordChanged } = props;
  const theme = useTheme();
  const classes = useStyles(theme);
  const messageContext = React.useContext(MessageContext);

  const changeExpiredPasswordMutation = `mutation changeExpiredPassword($oldPassword: String!, $userName: String!, $newPassword: String!, $newPasswordConfirmation: String!) {
    changeExpiredPassword(input: {oldPassword: $oldPassword, userName: $userName, newPassword: $newPassword, newPasswordConfirmation: $newPasswordConfirmation}){
      success
    }
  }`;

  const _onPasswordChange = React.useCallback(
    (
      oldPassword: string,
      newPassword: string,
      newPasswordConfirmation: string
    ) =>
      getGraphQLClient()
        .performMutation(
          changeExpiredPasswordMutation,
          {
            oldPassword,
            userName,
            newPassword,
            newPasswordConfirmation,
          },
          (err) => {
            messageContext.setError(err.messages[0]);
          },
          (err) => {
            const { input } = err;
            const fieldErr = Object.values(input)[0] as string;
            messageContext.setError(fieldErr);
          }
        )
        .then(() => {
          messageContext.setSuccess(t('forms.changePassword.successMessage'));
          onPasswordChanged();
        }),
    [
      changeExpiredPasswordMutation,
      messageContext,
      onPasswordChanged,
      t,
      userName,
    ]
  );
  return (
    <Box sx={classes.root}>
      <Box sx={classes.outerContainer}>
        <Box sx={classes.logoContainer}>
          <Logo
            logoType="caption-logo"
            styles={{
              fill: theme.palette.text.primary,
              height: theme.spacing(7),
            }}
          />
        </Box>

        <Box sx={classes.innerContainer}>
          <Grid alignContent="center" container sx={classes.loginContainer}>
            <Grid
              item
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
              xs={12}
            >
              <Box sx={formTitleSx}>
                <Tooltip title="Back to login">
                  <IconButton onClick={() => onPasswordChanged()}>
                    <ArrowCircleLeftRoundedIcon color="primary" />
                  </IconButton>
                </Tooltip>
                <Typography align="center" color="textSecondary" variant="h5">
                  {t('forms.changePassword.changePassword')}
                </Typography>
              </Box>
              <Box m={2}>
                <Typography
                  align="center"
                  color="textSecondary"
                  variant="body2"
                >
                  {t('forms.changePassword.expiredDescription')}
                </Typography>
              </Box>
              <ChangePasswordForm
                captions={{
                  oldPasswordLabel: t('forms.changePassword.oldPassword'),
                  changePassword: t('forms.changePassword.changePassword'),
                  newPasswordConfirmLabel: t(
                    'forms.changePassword.newPasswordConfirmation'
                  ),
                  newPasswordLabel: t('forms.changePassword.newPassword'),
                  userNameLabel: t('forms.changePassword.userName'),
                }}
                onChangePassword={_onPasswordChange}
                onPasswordChanged={onPasswordChanged}
                showUserName
                userName={userName}
              />
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Box>
  );
};

export default ChangePasswordPage;
