import { gql } from '../../../__generated__';

const drawingsQuery = gql(`query drawings ($drawingIds: [Int]) {
  engItemMasterDrawings(where: { itemId: { in: $drawingIds } }) {
    items {
      id
      drawing
      fileName
      fileExists
      fileSize
      itemId
      mapToLegacy
      fileExtension
      pathType
      item {
        id
        itemCompanyId
      }
    }
  }
}`);

export default drawingsQuery;
