import { gql } from '../../../__generated__';
import SelectDefinition from './SelectDefinition';

export default {
  query: gql(`query getSpecsByProject($projectId: Int!) {
                specs(where: {projectId: {eq: $projectId}, active: {eq:true}}, order: {displayName:ASC}) {
                  items {
                    displayName
                    id
                  }
                }
              }`),
  valueField: 'id',
  labelField: 'displayName',
} as SelectDefinition;
