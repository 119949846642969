import { Theme } from '@mui/material';

const radioSx = {
  flexDirection: 'row',
  justifyContent: 'space-between',
  gap: 2,
  mb: 1,
  alignItems: 'start',
  '& > *': {
    flex: 1,
  },
};
const radioContainerSx = {
  border: '1px solid',
  borderColor: (theme: Theme) => theme.palette.divider,
  p: 1,
  pt: 2,
  borderRadius: 1,
};
export { radioContainerSx, radioSx };
