import type { TypedDocumentNode } from '@graphql-typed-document-node/core';
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import DeleteIcon from '@mui/icons-material/Delete';
import Edit from '@mui/icons-material/Edit';
import VisibilityRoundedIcon from '@mui/icons-material/VisibilityRounded';
import { Box } from '@mui/material';
import { MessageContext } from '@teto/react-component-library-v2';
import dayjs from 'dayjs';
import { useSetAtom } from 'jotai';
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { Licenses, Permission, getGraphQLClient } from 'teto-client-api';
import * as Yup from 'yup';
import { Company, FanRating } from '../../../../../__generated__/graphql';
import buildMutation from '../../../../../api/graphQL/buildMutation';
import getErrors from '../../../../../api/graphQL/getErrors';
import AuthContext from '../../../../../contexts/AuthContext';
import CompanyContacts from '../../../../../views/CompanyContactsView.yaml';
import ActionBar from '../../../../ActionBar/ActionBar';
import { ContactSharedState } from '../../../../SharedStateComponents/StateContainers/ContactState';
import { getFanRatings } from '../../../../TETOForms/selects/selects';
import { AddNewRowValue } from '../../../../TETOGridGraphQL/Editors/AddNewDataRowEditor/AddNewDataRowEditor';
import useSubmitNewRow from '../../../../TETOGridGraphQL/Editors/AddNewDataRowEditor/useSubmitNewRow';
import ActionButtonGridBuilderExtension from '../../../../TETOGridGraphQL/GridBuilder/ActionButtonGridBuilderExtension';
import { TETODataColumn } from '../../../../TETOGridGraphQL/GridBuilder/types/TETODataColumn';
import { GridResponsiveSettingsBuilder } from '../../../../TETOGridGraphQL/GridResponsiveSettingsBuilder';
import MainTetoGridGraphQL from '../../../../TETOGridGraphQL/TETOMainGridGraphQL';
import GridCommonMobileButtons from '../../../../TETOGridGraphQL/components/GridCommonMobileButtons/GridCommonMobileButtons';
import useGrid from '../../../../TETOGridGraphQL/hooks/useGrid';
import { useGridBuilderFromView } from '../../../../TETOGridGraphQL/hooks/useGridBuilder';
import { CellChangingArgs } from '../../../../TETOGridGraphQL/types/CellChangingArgs';
import TETOGridRefType from '../../../../TETOGridGraphQL/types/TETOGridRefType';
import {
  addItemMutation,
  formatContactOnAdd,
} from '../../../AddEditContactInspector/panels/ContactsInfoPanel/ContactsInfoPanel';
import deleteCompanyContactMutation from '../../queries/deleteCompanyContactMutation';
import getContactQuery from '../../queries/getContactQuery';
import updateContactMutation from '../../queries/updateContactMutation';

const RootQueryPath = 'contacts';
const PersistenceName = 'companyContacts';

const ALWAYS_PROJECT_COLUMNS: string[] = [
  'active',
  'company.id',
  'email',
  'ext',
  'fax',
  'firstName',
  'id',
  'lastName',
  'phone',
  'title',
];

const EDITABLE_COLUMNS = [
  'active',
  'carPhone',
  'cellPhone',
  'custom1',
  'custom2',
  'custom3',
  'custom4',
  'custom5',
  'custom6',
  'custom7',
  'custom8',
  'email',
  'ext',
  'fanRating.description',
  'fax',
  'firstName',
  'homeFax',
  'homePhone',
  'lastName',
  'pager',
  'phone',
  'title',
];

const ADD_ROW_RESTRICT_FIELDS = [
  'carPhone',
  'cellPhone',
  'email',
  'ext',
  'fax',
  'firstName',
  'homeFax',
  'homePhone',
  'lastName',
  'pager',
  'phone',
  'title',
];

const companyContactSchema = Yup.object().shape({
  active: Yup.boolean().required(),
  carPhone: Yup.string().nullable().max(50),
  cellPhone: Yup.string().nullable().max(50),
  custom1: Yup.string().nullable(),
  custom2: Yup.string().nullable(),
  custom3: Yup.number()
    .nullable()
    .transform((_, val) => (val ? Number(val) : null)),
  custom4: Yup.number()
    .nullable()
    .transform((_, val) => (val ? Number(val) : null)),
  custom5: Yup.date().nullable(),
  custom6: Yup.date().nullable(),
  custom7: Yup.boolean().nullable(),
  custom8: Yup.boolean().nullable(),
  email: Yup.string().email().nullable().max(50),
  ext: Yup.number()
    .nullable()
    .transform((_, val) => (val ? Number(val) : null)),
  fanRating: Yup.object().shape({
    id: Yup.number()
      .min(1)
      .max(10)
      .nullable()
      .transform((_, val) => (val ? Number(val) : null)),
    description: Yup.string().nullable(),
  }),
  fanRatingId: Yup.number()
    .min(1)
    .max(10)
    .nullable()
    .transform((_, val) => (val ? Number(val) : null)),
  fax: Yup.string().nullable().max(50),
  firstName: Yup.string().nullable().max(50),
  homeFax: Yup.string().nullable().max(50),
  homePhone: Yup.string().nullable().max(50),
  lastName: Yup.string().nullable().max(50),
  pager: Yup.string().nullable().max(50),
  phone: Yup.string().nullable().max(50),
  title: Yup.string().nullable().max(50),
});

const containerSx = {
  display: 'flex',
  flexDirection: 'column',
  overflowY: 'auto',
  width: '100%',
};

interface CompanyContactsPanelProps {
  companyId: number | undefined;
}

const CompanyContactsPanel = (props: CompanyContactsPanelProps) => {
  const { companyId } = props;
  const { t, ready } = useTranslation();

  const messageContext = useContext(MessageContext);
  const authContext = useContext(AuthContext);

  const gridRef = useRef<TETOGridRefType | undefined>();

  const [newRowData, setNewRowData] = useState({ companyId });

  const [configureInspectorOpen, setConfigureInspectorOpen] = useState(false);
  const setContactInspector = useSetAtom(ContactSharedState);

  const { canAdd, canModify, canView, canDelete } = useMemo(
    () => ({
      canAdd:
        authContext.hasLicense(Licenses.TotalETOProfessional) &&
        authContext.hasPermission(
          Permission.Add_Sales_CompanyInformation_Contacts
        ),
      canModify:
        authContext.hasLicense(Licenses.TotalETOProfessional) &&
        authContext.hasPermission(
          Permission.Modify_Sales_CompanyInformation_Contacts
        ),
      canView:
        authContext.hasLicense(Licenses.ReadOnlyProfessional) &&
        authContext.hasPermission(
          Permission.View_Sales_CompanyInformation_Contacts
        ),
      canDelete:
        authContext.hasLicense(Licenses.TotalETOProfessional) &&
        authContext.hasPermission(
          Permission.Delete_Sales_CompanyInformation_Contacts
        ),
    }),
    [authContext]
  );

  const { gridBuilder, builderReady, error, hasError } = useGridBuilderFromView(
    CompanyContacts,
    RootQueryPath,
    (gb) =>
      gb
        .updateDefinition('active', {
          editable: canModify,
          resizable: true,
        })
        .updateDefinition('carPhone', {
          editable: canModify,
          resizable: true,
        })
        .updateDefinition('cellPhone', {
          editable: canModify,
          resizable: true,
        })
        .updateDefinition('custom1', {
          editable: canModify,
          resizable: true,
          type: 'string',
        })
        .updateDefinition('custom2', {
          editable: canModify,
          resizable: true,
          type: 'string',
        })
        .updateDefinition('custom3', {
          editable: canModify,
          resizable: true,
          type: 'number',
        })
        .updateDefinition('custom4', {
          editable: canModify,
          resizable: true,
          type: 'number',
        })
        .updateDefinition('custom5', {
          editable: canModify,
          resizable: true,
          type: 'date',
        })
        .updateDefinition('custom6', {
          editable: canModify,
          resizable: true,
          type: 'date',
        })
        .updateDefinition('custom7', {
          editable: canModify,
          resizable: true,
          type: 'boolean',
        })
        .updateDefinition('custom8', {
          editable: canModify,
          resizable: true,
          type: 'boolean',
        })
        .updateDefinition('email', {
          editable: canModify,
          resizable: true,
        })
        .updateDefinition('ext', {
          editable: canModify,
          resizable: true,
        })

        .updateDefinition('fax', {
          editable: canModify,
          resizable: true,
        })
        .updateDefinition('firstName', {
          editable: canModify,
          resizable: true,
        })
        .updateDefinition('homeFax', {
          editable: canModify,
          resizable: true,
        })
        .updateDefinition('homePhone', {
          editable: canModify,
          resizable: true,
        })
        .updateDefinition('lastName', {
          editable: canModify,
          resizable: true,
        })
        .updateDefinition('pager', {
          editable: canModify,
          resizable: true,
        })
        .updateDefinition('phone', {
          editable: canModify,
          resizable: true,
        })
        .updateDefinition('title', {
          editable: canModify,
          resizable: true,
        })
        .updateDefinition('fanRating.description', {
          editable: canModify,
          resizable: true,
          type: 'select',
          editorProps: {
            itemNameSelector: (rating: FanRating) =>
              rating[getFanRatings.labelField as keyof FanRating],
            itemValueSelector: (rating: FanRating) =>
              rating[getFanRatings.valueField as keyof FanRating] ?? null,
            queryString: getFanRatings.query,
            queryResultPath: 'fanRatings',
          },
        })
        .extension(
          new ActionButtonGridBuilderExtension([
            {
              disabled: !canModify && !canView,
              componentName: canModify
                ? t('pages.contacts.editContact')
                : t('generic.viewDetail'),
              icon: canModify ? <Edit /> : <VisibilityRoundedIcon />,
              title: canModify
                ? t('pages.contacts.editContact')
                : t('generic.viewDetail'),
              onClick: ({ data }) =>
                setContactInspector({
                  open: true,
                  initialValues: {
                    id: data.id,
                    firstName: data.firstName,
                    lastName: data.lastName,
                  },
                }),
              color: 'primary',
            },
            {
              disabled: !canDelete,
              componentName: t('pages.contacts.deleteContact'),
              icon: <DeleteIcon />,
              title: t('pages.contacts.deleteContact'),
              onClick: ({ data }) => _handleDeleteContact(data.id),
              color: 'error',
            },
          ]),
          t
        )
  );

  const mandatoryFilters = useMemo(
    () => [
      {
        name: 'companyId',
        operator: 'equal',
        value: companyId,
        type: 'number' as const,
      },
    ],
    [companyId]
  );

  const { gridProps } = useGrid(
    PersistenceName,
    `${RootQueryPath}.items`,
    (e) => messageContext.setError(e.message ?? e),
    t,
    ALWAYS_PROJECT_COLUMNS,
    gridBuilder,
    {
      filterAndSortMode: 'serverSide',
      mandatoryFilter: mandatoryFilters,
    }
  );
  const mutationProps = useMemo(() => {
    const vitalDataExist = ADD_ROW_RESTRICT_FIELDS.some(
      (i) =>
        (newRowData as { [key: string]: unknown })?.[i] &&
        (newRowData as { [key: string]: unknown })?.[i] !== ''
    );

    const data = vitalDataExist ? newRowData : {};
    const variables = { values: formatContactOnAdd(newRowData) };

    return { data, variables };
  }, [newRowData]);

  const { shouldResetAddNewRowData, validationErrors } = useSubmitNewRow(
    mutationProps?.data,
    gridProps,
    addItemMutation,
    mutationProps?.variables
  );
  const _handleDeleteContact = useCallback((id: number) => {
    buildMutation({
      queryString: deleteCompanyContactMutation,
      variables: { id },
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      callback: (d: any) => {
        if (!d.deleteContact.success) {
          messageContext.setError(t('generic.message.contactDeleteFailed'));
          return;
        }

        messageContext.setSuccess(
          t('generic.deletedSuccess', { record: t('entities:Contact.Contact') })
        );
        gridProps.refreshDataSource();
      },
      messageContext,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const GridCommonBtns = GridCommonMobileButtons({
    refreshGrid: () => gridProps.refreshDataSource(),
    gridRef,
    setConfigureInspector: () => setConfigureInspectorOpen(true),
  });

  const addContactBtn = useMemo(
    () => ({
      disabled: !canAdd,
      title: `${t('generic.add')} ${t('entities:Contact.Contact')}`,
      icon: <AddRoundedIcon />,
      onclick: () => {
        if (!companyId) {
          return messageContext.setError(
            `${t('entities:Company.Company')} Id ${t('generic.isMissing')}`
          );
        }
        return setContactInspector({
          open: true,
          initialValues: {
            company: {
              id: companyId,
              name: '',
            } as Company,
          },
        });
      },
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [companyId, canAdd, setContactInspector, t]
  );

  const rightChildren = useMemo(
    () => [addContactBtn, GridCommonBtns],
    [addContactBtn, GridCommonBtns]
  );

  const defaultGridResponsiveSettings = GridResponsiveSettingsBuilder.up({
    xs: {
      disableGroupByToolbar: true,
      disableGroupColumn: false,
      disableInlineEdit: true,
      disableMobileCols: false,
      enableFiltering: false,
      inputVariant: 'filled',
      renderAggregatesInGroupLabel: false,
      rowHeight: null,
      showEmptyRows: false,
      showHoverRows: false,
    },
    md: {
      disableGroupByToolbar: false,
      disableGroupColumn: false,
      disableInlineEdit: false,
      disableMobileCols: true,
      enableFiltering: true,
      inputVariant: 'standard',
      renderAggregatesInGroupLabel: false,
      rowHeight: 35,
      showEmptyRows: true,
      showHoverRows: true,
    },
  });

  useEffect(() => {
    if (shouldResetAddNewRowData) {
      setNewRowData({ companyId });
    }
  }, [companyId, shouldResetAddNewRowData]);

  const handleAddNewDataRow = useCallback(
    (val: AddNewRowValue, col: TETODataColumn) => {
      let _val = val;
      switch (col.type) {
        case 'number': {
          const _v = parseInt(val as string, 10);
          _val = Number.isNaN(_v) ? 0 : _v;
          break;
        }
        default:
          break;
      }

      setNewRowData({
        ...newRowData,
        [(col?.path === 'fanRating.description'
          ? 'fanRatingId'
          : col?.path) as string]: _val,
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [newRowData]
  );

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const valueFormatter = (name: string, value: any) => {
    switch (name) {
      case 'custom5':
      case 'custom6':
        return dayjs(value).toDate();
      case 'active':
      case 'custom7':
      case 'custom8':
        return Boolean(value);
      case 'custom3':
      case 'custom4':
      case 'ext':
        return Number.isNaN(parseInt(value, 10)) ? null : parseInt(value, 10);
      case 'fanRating.description':
        return Number.isNaN(parseInt(value, 10))
          ? null
          : Math.abs(parseInt(value, 10));
      default:
        return value || null;
    }
  };

  const _handleCellChanging = (cellChange: CellChangingArgs) => {
    const {
      column: { name },
      changeContext: { cancelChange, setColumnValue },
      editValue: { value, originalValue },
      newData,
    } = cellChange;

    if (!newData?.id) {
      return;
    }

    getGraphQLClient()
      .performQuery(getContactQuery as TypedDocumentNode, {
        id: newData?.id,
      })
      .then((res) => {
        if (res.hasError()) {
          if (res.hasValidationErrors()) {
            messageContext.setError(getErrors(res.validationErrors));
          }

          if (res.hasSystemErrors()) {
            const err = res.systemErrors
              .map((message, index) => {
                const isLast = index === res.systemErrors.length - 1;
                return isLast ? message : `${message}\n \n`;
              })
              .join('');

            messageContext.setError(err);
          }

          cancelChange();
          setColumnValue(name as string, originalValue);

          return;
        }

        if (res.hasData()) {
          const input = {
            ...res.data.contact,
            [`${name === 'fanRating.description' ? 'fanRatingId' : name}`]:
              valueFormatter(name as string, value),
          };

          getGraphQLClient()
            .performMutation(updateContactMutation as TypedDocumentNode, {
              input,
            })
            .then((r) => {
              if (r.hasError()) {
                if (r.hasValidationErrors()) {
                  cellChange.changeContext.setValidationError(
                    r.validationErrors?.input
                  );
                }

                if (r.hasSystemErrors()) {
                  const err = r.systemErrors
                    .map((message, index) => {
                      const isLast = index === r.systemErrors.length - 1;
                      return isLast ? message : `${message}\n \n`;
                    })
                    .join('');

                  messageContext.setError(err);
                }

                cancelChange();
                setColumnValue(name as string, originalValue);

                return;
              }

              if (r.hasData()) {
                setColumnValue(name as string, value);
                messageContext.setSuccess(
                  t('generic.message.contactUpdateSuccess')
                );
                gridProps.refreshDataSource();
              }
            });
        }
      });
  };

  return (
    <Box sx={containerSx}>
      <ActionBar rightChildren={rightChildren} />
      <MainTetoGridGraphQL
        builderReady={builderReady}
        configureInspector={configureInspectorOpen}
        customGridWrapSx={{ gridTemplateRows: 'unset' }}
        editMode={{
          editMode: 'byRow',
          validationSchema: companyContactSchema,
          onCellChanging: (c) => {
            if (EDITABLE_COLUMNS.indexOf(c.column.name as string) >= 0) {
              _handleCellChanging(c);
            }
          },
        }}
        error={error}
        externalQueryProps={[]}
        gridProps={gridProps}
        handleAddNewDataRow={handleAddNewDataRow}
        hasAddNewDataRow
        hasError={hasError}
        header={{ hidden: true }}
        mobileGridOptions
        pagination
        ready={ready}
        ref={gridRef}
        resetAddNewDataRow={shouldResetAddNewRowData}
        responsiveSettings={defaultGridResponsiveSettings}
        setConfigureInspector={setConfigureInspectorOpen}
        showEmptyRows
        showGroupSummaryRow={false}
        t={t}
        tableIdentifier={PersistenceName}
        validationErrors={validationErrors}
      />
    </Box>
  );
};

export default CompanyContactsPanel;
