import { PrintRounded } from '@mui/icons-material';
import { CircularProgress } from '@mui/material';
import Box from '@mui/material/Box';
import { SxProps, Theme } from '@mui/material/styles';
import {
  ETOIconButton,
  ETOTab,
  ETOTabs,
  Inspector,
  InspectorButtonOptionsI,
  MessageContext,
  OkCancelConfirmDialog,
} from '@teto/react-component-library-v2';

import { useSetAtom } from 'jotai';
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { Permission, getGraphQLClient } from 'teto-client-api';
import AuthContext from '../../../contexts/AuthContext';
import nonConformanceReportQuery from '../../../pages/NonConformancesPage/queries/NonConformanceReport';
import { ncSharedState } from '../../SharedStateComponents/StateContainers/NonConformanceState';
import TabPanel from '../components/AddEditInspector/components/InspectorTabPanel';
import EditingState from '../types/EditingState';
import ActionPanel from './NCPanels/ActionPanel/ActionPanel';
import DocumentsPanel from './NCPanels/DocumentsPanel/DocumentsPanel';
import InfoPanel from './NCPanels/InfoPanel/InfoPanel';
import {
  getFormikInitialValues,
  getPropsInitialValues,
} from './NCPanels/InfoPanel/InfoPanelHelpers';
import { TNCPanelState } from './NCPanels/InfoPanel/TNCPanelState';
import { nonConformanceQuery } from './NCPanels/InfoPanel/queries/InfoPanelQueries';
import NotesPanel from './NCPanels/NotesPanel/NotesPanel';
import TaskAssignmentsPanel from './NCPanels/TaskAssignmentsPanel/TaskAssignmentsPanel';
import { NonConformanceInspectorProps } from './NonConformanceProps';
import NonConformanceButtonStrip from './components/NonConformanceButtonStrip/NonConformanceButtonStrip';
import NonConformanceInspectorTitle from './components/NonConformanceInspectorTitle/NonConformanceInspectorTitle';
import { INonConformance } from './interfaces/NonConformanceAssignment';

const initialEditingState = {
  isEditing: false,
  hasEdited: false,
  tabError: false,
};

const containerSx: SxProps<Theme> = {
  display: 'flex',
  flexDirection: 'column',
  flexShrink: 0,
  height: '100%',
  width: '100%',
  '& .MuiTabs-root': {
    flexShrink: 0,
  },
  '& .MuiTabs-scrollButtons': {
    color: 'primary.main',
    '&:hover': {
      background: (theme) =>
        theme.palette.mode === 'light'
          ? 'rgba(0,0,0,0.04)'
          : 'rgba(255,255,255, 0.08)',
    },
    '& 	.MuiSvgIcon-root': {
      fill: 'currentcolor',
      fontSize: '1.5rem',
    },
  },
};

const buttonSx: SxProps<Theme> = {
  alignSelf: 'flex-end',
  flexGrow: 0,
};

const customSx: SxProps<Theme> = {
  '& .MuiModal-root-MuiDrawer-root .MuiDrawer-paperAnchorRight': { rowGap: 0 },
  height: '100%',
};

const getInitialState = (value: INonConformance): TNCPanelState =>
  value && Object.prototype.hasOwnProperty.call(value, 'id')
    ? {
        creationDate: value.creationDate,
        id: value.id,
        mode: 'edit',
      }
    : {
        creationDate: undefined,
        id: undefined,
        mode: 'create',
      };

const NonConformanceInspector = (props: NonConformanceInspectorProps) => {
  const { renderAsInspector, initialValues, open, onClose } = props;
  const { ready, t } = useTranslation();

  const authContext = useContext(AuthContext);
  const messageContext = useContext(MessageContext);
  const setNcAtom = useSetAtom(ncSharedState);

  const [activePanelIndex, setActivePanelIndex] = useState<number>(0);

  const [inspectorState, setInspectorState] = useState<TNCPanelState>(() =>
    getInitialState(initialValues)
  );
  useEffect(() => {
    setInspectorState(getInitialState(initialValues));
  }, [initialValues]);

  const [currentNonConformance, setCurrentNonConformance] =
    useState<Record<string, unknown>>();
  const [hasASaveOccurred, setHasASaveOccurred] = useState<boolean>(false);
  const [isCreatingNewItem, setIsCreatingNewItem] = useState<boolean>(false);

  const [needsCloseConfirmation, setNeedsCloseConfirmation] =
    useState<boolean>(false);

  const [editingState, setEditingState] =
    useState<EditingState>(initialEditingState);

  const [isPrintingLoading, setIsPrintingLoading] = useState<boolean>(false);

  // if a set of initial values is passed in, we are in edit mode, query the data
  useEffect(() => {
    if (inspectorState.id && inspectorState.mode === 'edit') {
      getGraphQLClient()
        .performQuery(nonConformanceQuery, { id: inspectorState.id })
        .then((d) => {
          if (d.hasError()) {
            d.showAllSystemErrors(messageContext.setError);
            if (d.hasValidationErrors()) {
              const { input } = d.validationErrors;
              messageContext.setError(Object.values(input)[0] as string);
            }
            return;
          }
          const result = getPropsInitialValues({
            ...d.data.nonConformance,
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            item: (d.data.nonConformance?.item as any) ?? undefined,
            purchaseOrder:
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              (d.data.nonConformance?.purchaseOrder as any) ?? undefined,
            purchaseOrderId:
              d.data.nonConformance?.purchaseOrderId ?? undefined,
            quantity: d.data.nonConformance?.quantity ?? undefined,
            quantityRejected:
              d.data.nonConformance?.quantityRejected ?? undefined,
            customer:
              (d.data.nonConformance?.project?.company?.name as string) ??
              undefined,
            supplier: d.data.nonConformance?.purchaseOrder
              ? (d.data.nonConformance?.purchaseOrder?.purchaseSupplier
                  ?.name as string)
              : undefined,
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
          } as any);
          setCurrentNonConformance(result);
          setInspectorState({
            creationDate: result.creationDate,
            id: result.id,
            mode: 'edit',
          });
        });
    }
  }, [
    activePanelIndex,
    inspectorState.id,
    inspectorState.mode,
    messageContext,
  ]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const _onPrintClicked = () => {
    setIsPrintingLoading(true);
    queryNCReport(inspectorState.id);
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const queryNCReport = async (ncNumber: number | undefined) => {
    await getGraphQLClient()
      .performQuery(nonConformanceReportQuery, {
        input: {
          parameters: {
            __intNonConformanceID: ncNumber,
            MachineCaption: `${t('entities:Machine.Job')}`,
          },
        },
        reportId: 106,
      })
      .then((data) => {
        if (data.hasError()) {
          data.showAllSystemErrors(messageContext.setError);
          if (data.hasValidationErrors()) {
            const { input } = data.validationErrors;
            const fieldErr = Object.values(input)[0] as string;
            messageContext.setError(fieldErr);
          }
          return;
        }
        if (data?.data != null) {
          const reportData = {
            reportId: data?.data?.exportReport?.reportId?.toString(),
            entityId: data?.data?.exportReport?.parameters?.find(
              (r: { key: string }) => r.key === '__intNonConformanceID'
            ).value,
            type: 'generic',
          };
          const convertParamsToObj =
            data?.data?.exportReport?.parameters?.reduce(
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              (acc: any, current: any) => {
                acc[current.key] = current.value;
                return acc;
              },
              {}
            );

          const reportParams = encodeURIComponent(
            JSON.stringify(convertParamsToObj)
          );
          const queryString = new URLSearchParams(reportData).toString();
          window.open(
            `/reportViewer?url=${
              data?.data?.exportReport?.path &&
              encodeURIComponent(data?.data?.exportReport?.path)
            }&${queryString}&reportParams=${reportParams}`,
            '_blank'
          );
          setIsPrintingLoading(false);
        }
      });
  };

  const headerOptions: InspectorButtonOptionsI[] = useMemo(
    () => [
      ...(inspectorState.mode === 'edit'
        ? [
            {
              name: t('generic.print'),
              component: (
                // eslint-disable-next-line react/jsx-no-useless-fragment
                <>
                  {isPrintingLoading ? (
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        flexGrow: 1,
                        paddingRight: (theme) => theme.spacing(1),
                        paddingTop: (theme) => theme.spacing(1),
                      }}
                    >
                      <CircularProgress color="primary" size={20} />
                    </Box>
                  ) : (
                    <ETOIconButton
                      color="primary"
                      customSx={buttonSx}
                      onClick={() => _onPrintClicked()}
                      size="medium"
                      tooltipProps={{
                        title: t('generic.print'),
                      }}
                    >
                      <PrintRounded sx={{ color: 'primary.main' }} />
                    </ETOIconButton>
                  )}
                </>
              ),
              // eslint-disable-next-line react/jsx-no-useless-fragment
              icon: <></>,
              onClick: () => _onPrintClicked(),
              tooltipProps: {
                title: t('generic.print'),
              },
            },
          ]
        : []),
    ],
    [_onPrintClicked, inspectorState.mode, isPrintingLoading, t]
  );

  const _handleEditingState = useCallback(
    (e: { isValid: boolean; isEditing: boolean }) => {
      setEditingState((d) => ({
        isEditing: e.isEditing,
        hasEdited: !d.hasEdited && e.isEditing ? true : d.hasEdited,
        tabError: !e.isValid,
      }));
    },
    []
  );

  const tabs = useMemo(
    () => [
      {
        tabName: 'info',
        tabLabel: `${t('pages.nonConformance.info')}`,
        tabIndex: 0,
        tabPanel: (
          <InfoPanel
            handleEditingState={_handleEditingState}
            initialValues={
              inspectorState.mode === 'create'
                ? getFormikInitialValues(initialValues)
                : currentNonConformance
            }
            panelMode={inspectorState.mode}
            setHasASaveOccurred={setHasASaveOccurred}
            setNonConformance={setCurrentNonConformance}
            setPanelMode={setInspectorState}
          />
        ),
        tabTitle: `Error In ${t('pages.nonConformance.info')} Form`,
      },
      ...(inspectorState.mode === 'edit'
        ? [
            {
              tabName: 'actions',
              tabLabel: `${t('pages.nonConformance.actions')}`,
              tabIndex: 1,
              tabPanel: (
                <ActionPanel
                  handleEditingState={_handleEditingState}
                  initialValues={currentNonConformance}
                  setHasASaveOccurred={setHasASaveOccurred}
                  setNonConformance={setCurrentNonConformance}
                />
              ),
              tabTitle: `Error In ${t('pages.nonConformance.actions')} Form`,
            },
            ...(authContext.hasAnyPermission([
              Permission.View_Manufacturing_NonConformances_TaskAssignments,
              Permission.Add_Manufacturing_NonConformances_TaskAssignments,
              Permission.Modify_Manufacturing_NonConformances_TaskAssignments,
              Permission.Delete_Manufacturing_NonConformances_TaskAssignments,
            ])
              ? [
                  {
                    tabName: 'tasks',
                    tabLabel: `${t('pages.nonConformance.taskAssignment')}`,
                    tabIndex: 2,
                    tabPanel: (
                      <TaskAssignmentsPanel
                        editingState={editingState}
                        handleEditingState={_handleEditingState}
                        nonConformanceId={inspectorState?.id}
                        setHasASaveOccurred={setHasASaveOccurred}
                        setIsCreatingNewItem={setIsCreatingNewItem}
                      />
                    ),
                    tabTitle: `Error In ${t(
                      'pages.nonConformance.taskAssignment'
                    )} Form`,
                  },
                ]
              : []),
            ...(authContext.hasAnyPermission([
              Permission.View_Manufacturing_NonConformances_Notes,
              Permission.Add_Manufacturing_NonConformances_Notes,
              Permission.Modify_Manufacturing_NonConformances_Notes,
              Permission.Delete_Manufacturing_NonConformances_Notes,
            ])
              ? [
                  {
                    tabName: 'notes',
                    tabLabel: `${t('pages.nonConformance.notes')}`,
                    tabIndex: 3,
                    tabPanel: (
                      <NotesPanel
                        editingState={editingState}
                        handleEditingState={_handleEditingState}
                        nonConformanceId={inspectorState?.id}
                        setHasASaveOccurred={setHasASaveOccurred}
                        setIsCreatingNewItem={setIsCreatingNewItem}
                      />
                    ),
                    tabTitle: `Error In ${t(
                      'pages.nonConformance.notes'
                    )} Form`,
                  },
                ]
              : []),
            ...(authContext.hasAnyPermission([
              Permission.View_Manufacturing_NonConformances_Documents,
              Permission.Add_Manufacturing_NonConformances_Documents,
              Permission.Modify_Manufacturing_NonConformances_Documents,
              Permission.Delete_Manufacturing_NonConformances_Documents,
            ])
              ? [
                  {
                    tabName: 'documents',
                    tabLabel: `${t('pages.nonConformance.document')}`,
                    tabIndex: 4,
                    tabPanel: (
                      <DocumentsPanel
                        editingState={editingState}
                        entityIdValue={inspectorState?.id as number}
                        setEditingState={setEditingState}
                        setHasASaveOccurred={setHasASaveOccurred}
                        setIsCreatingNewItem={setIsCreatingNewItem}
                      />
                    ),
                    tabTitle: `Error In ${t(
                      'pages.nonConformance.document'
                    )} Form`,
                  },
                ]
              : []),
          ]
        : []),
    ],
    [
      _handleEditingState,
      authContext,
      currentNonConformance,
      editingState,
      initialValues,
      inspectorState?.id,
      inspectorState.mode,
      t,
    ]
  );

  const _handleTabsChange = useCallback(
    (e: React.ChangeEvent<unknown>, newValue: number) => {
      if (newValue === activePanelIndex) return;
      if (editingState.tabError) {
        return setNeedsCloseConfirmation(true);
      }
      setActivePanelIndex(newValue);
    },
    [activePanelIndex, editingState.tabError]
  );

  useEffect(() => {
    if (hasASaveOccurred && !editingState.tabError) {
      setNcAtom((prev) => ({ ...prev, hasASaveOccurred: true }));
      const timeoutFunc = setTimeout(() => {
        setHasASaveOccurred(false);
      }, 4000);
      return () => clearTimeout(timeoutFunc);
    }
  }, [editingState.tabError, hasASaveOccurred, setNcAtom]);

  const _renderChildComponent = useMemo(
    () => (
      <>
        <Box sx={containerSx}>
          <ETOTabs
            error={editingState.tabError}
            onChange={_handleTabsChange}
            TabIndicatorProps={{
              ...(editingState.tabError && {
                sx: {
                  display: 'none',
                },
              }),
            }}
            value={activePanelIndex}
          >
            {tabs?.map((tb) => (
              <ETOTab
                error={editingState.tabError}
                key={`${tb.tabLabel}-${tb.tabIndex}`}
                label={tb.tabLabel}
                toolTipProps={{
                  title: tb.tabTitle,
                }}
              />
            ))}
          </ETOTabs>

          {tabs?.map((tb) => (
            <TabPanel
              customSx={{
                borderTop: (theme) => `1px solid ${theme.palette.divider}`,
                pt: 1,
              }}
              index={tb.tabIndex}
              key={`${tb.tabLabel}-${tb.tabIndex}`}
              value={activePanelIndex}
            >
              {tb.tabPanel}
            </TabPanel>
          ))}

          {inspectorState.mode === 'edit' && (
            <NonConformanceButtonStrip
              initialValues={currentNonConformance}
              onInspectorClose={onClose}
              setHasASaveOccurred={setHasASaveOccurred}
              setNonConformance={setCurrentNonConformance}
              setPanelEditingState={_handleEditingState}
            />
          )}
          {needsCloseConfirmation && (
            <OkCancelConfirmDialog
              content={t('dialogs.closeUnsavedForm.content')}
              onCancel={() => {
                setNeedsCloseConfirmation(false);
              }}
              onOk={() => {
                setNeedsCloseConfirmation(false);
                setEditingState({
                  isEditing: false,
                  hasEdited: false,
                  tabError: false,
                });
                onClose(editingState.hasEdited);
              }}
              open={needsCloseConfirmation}
              title={t('dialogs.closeUnsavedForm.title')}
            />
          )}
        </Box>
      </>
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      _handleEditingState,
      _handleTabsChange,
      activePanelIndex,
      currentNonConformance,
      editingState.hasEdited,
      editingState.tabError,
      hasASaveOccurred,
      inspectorState.mode,
      needsCloseConfirmation,
      onClose,
      tabs,
    ]
  );

  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {ready && (
        <Box sx={customSx}>
          {renderAsInspector ? (
            <Inspector
              containerSx={{
                '& .MuiDrawer-paperAnchorRight': {
                  rowGap: '0px',
                },
              }}
              dataTestid="NonConformanceInspector"
              onClose={() => {
                if (
                  editingState.isEditing ||
                  editingState.tabError ||
                  isCreatingNewItem
                ) {
                  setNeedsCloseConfirmation(true);
                } else {
                  onClose(editingState.hasEdited);
                }
              }}
              open={open}
              options={headerOptions}
              title={{
                substitute: (
                  <NonConformanceInspectorTitle
                    subtitle={inspectorState.creationDate}
                    title={t('entities:NonConformance.NonConformance')}
                    titleExtension={`${
                      inspectorState?.id && inspectorState.creationDate
                        ? `#${inspectorState?.id}`
                        : ''
                    }`}
                  />
                ),
              }}
            >
              {_renderChildComponent}
            </Inspector>
          ) : (
            _renderChildComponent
          )}
        </Box>
      )}
    </>
  );
};

export default NonConformanceInspector;
