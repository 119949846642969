import { SxProps, Theme } from '@mui/material/styles';

const commonSx: SxProps<Theme> = {
  flexBasis: 'unset !important',
};

const containerSx: SxProps<Theme> = {
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  justifyContent: 'flex-start',
  overflowY: 'auto',
  flexWrap: 'nowrap',
  rowGap: 2,
};

export { commonSx, containerSx };
