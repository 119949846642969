/* eslint-disable no-unused-vars */

import { atom } from 'jotai';
import { createScope, molecule } from 'jotai-molecules';
import { atomWithReset } from 'jotai/utils';
import TItemMasterGlobalState from './ItemMasterGlobalState';

// State
export const ItemMasterSharedState = atomWithReset<TItemMasterGlobalState>({
  initialValues: undefined,
  open: false,
  createdPart: undefined,
  renderAsInspector: false,
  hasSavedOccurred: false,
});

const _defaultOnClose = atom(null, (get, set) =>
  set(ItemMasterSharedState, {
    initialValues: undefined,
    open: false,
    hasSavedOccurred: false,
    renderAsInspector: false,
  })
);

// Scope
export const initialItemMasterOnCloseScope = createScope(_defaultOnClose);

export const onItemMasterClose = molecule((getMol, getScope) => {
  const initialNCOnClose = getScope(initialItemMasterOnCloseScope);
  return initialNCOnClose;
});
