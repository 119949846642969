import IndeterminateCheckBoxRounded from '@mui/icons-material/IndeterminateCheckBoxRounded';
import {
  Box,
  Checkbox,
  SxProps,
  Theme,
  Tooltip,
  capitalize,
} from '@mui/material';
import React from 'react';
import useIsMobile from '../../../hooks/useIsMobile';
import deepPropertyHelper from './deepPropertyHelpers';
import FormatterErrorWrapper from './FormatterErrorWrapper';
import FormatterProps from './FormatterProps';

// eslint-disable-next-line consistent-return
export const hasAnyBitOn = (options: number[], value: number) => {
  if (value === 0) return false;

  // eslint-disable-next-line no-unreachable-loop
  for (let i = 0; i < options.length; i += 1) {
    // eslint-disable-next-line no-bitwise
    if ((value & i) === i) return true;
  }

  return false;
};

// eslint-disable-next-line consistent-return
const hasBitOn = (option: number, value: number) => {
  if (value === 0 || option === 0) return false;
  // eslint-disable-next-line no-bitwise
  if ((value & option) === option) return true;
};

const reasonSx: SxProps<Theme> = {
  overflow: 'hidden',
  textOverflow: 'ellipsis',
};

// eslint-disable-next-line react/function-component-definition
const BitwiseFormatter: React.ComponentType<FormatterProps> = (
  props: FormatterProps
) => {
  const { value, column, data } = props;
  const isMobile = useIsMobile();

  const formattedVal = value?.value ?? deepPropertyHelper(column.name, data);

  const options = column.bitwiseOptions ?? {};

  // Bad, must get rid of type: anys in future iterations

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const numberOptions: any[] = Object.values(options).filter(
    (o) => typeof o === 'number'
  );

  const isLocked = hasAnyBitOn(numberOptions, formattedVal);

  const reasons = numberOptions
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    .filter((o: any) => hasBitOn(o, formattedVal))
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    .map((val: any) => options[val])
    .join('\r\n');

  const formattedReasons = capitalize(String(reasons))
    .split(/(?=[A-Z])/)
    .join(' ');

  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {isLocked ? (
        <Tooltip arrow title={formattedReasons}>
          <Box>
            {isMobile ? (
              <Box sx={reasonSx}>{formattedReasons}</Box>
            ) : (
              <Checkbox
                checked={isLocked ?? false}
                disabled
                indeterminate={value === undefined}
                indeterminateIcon={<IndeterminateCheckBoxRounded />}
                size="small"
                style={{ padding: 0, display: 'block', margin: '0 auto' }}
              />
            )}
          </Box>
        </Tooltip>
      ) : (
        <FormatterErrorWrapper value={value}>
          {isMobile ? (
            <Box sx={reasonSx}>{formattedReasons}</Box>
          ) : (
            <Checkbox
              checked={isLocked ?? false}
              disabled
              indeterminate={value === undefined}
              indeterminateIcon={<IndeterminateCheckBoxRounded />}
              size="small"
              style={{ padding: 0, display: 'block', margin: '0 auto' }}
            />
          )}
        </FormatterErrorWrapper>
      )}
    </>
  );
};

export default BitwiseFormatter;
