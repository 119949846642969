import { List, SxProps, Theme } from '@mui/material';
import React, { useEffect } from 'react';
import EditingState from '../../types/EditingState';
import AccordionListItem from './AccordionListItem';

export type TAccordionListItem<T> = {
  id: number | string;
  title: string;
  icon?: React.ReactNode;
  subTitles?: (string | string[] | React.ReactNode | React.ReactNode[])[];
  data?: T;
};

// export type TAddEditListItemData<T> = TAccordionListItem<T>['data'];
export interface AccordionListProps<T> {
  customListSx?: SxProps<Theme>;
  icon?: React.ReactNode;
  listItems: TAccordionListItem<T>[];
  expandedItem?: TAccordionListItem<T>;
  onItemExpandStateChanged?: (
    // eslint-disable-next-line no-unused-vars
    item?: TAccordionListItem<T>
  ) => void;
  // eslint-disable-next-line react/no-unused-prop-types
  expandedComponent?: React.ReactNode;
  setConfirmAbandonForm: React.Dispatch<React.SetStateAction<boolean>>;
  editingState: EditingState;
}

export const listSx: SxProps<Theme> = {
  display: 'flex',
  flexDirection: 'column',
  paddingTop: 0,
  rowGap: 0,
  width: '100%',
};

const altColorSx: SxProps<Theme> = {
  backgroundColor: (theme) => theme.palette.background.default,
  color: (theme) =>
    theme.palette.getContrastText(theme.palette.background.default),
};

const AccordionList = <T,>(props: AccordionListProps<T>) => {
  const {
    customListSx,
    editingState,
    expandedComponent,
    expandedItem,
    icon,
    listItems,
    onItemExpandStateChanged,
    setConfirmAbandonForm,
  } = props;

  useEffect(() => {
    const uniqueIds = new Set(listItems.map((a) => a.id));
    // if this is true there must be a duplicate id
    if (uniqueIds.size < listItems.length) {
      throw new Error(
        'Duplicate ids were passed into the list. Each listItem must have a unique id property'
      );
    }
  }, [listItems]);

  return (
    <List dense sx={{ ...listSx, ...customListSx }}>
      {listItems.length > 0 &&
        listItems.map((c, i) => (
          <AccordionListItem
            dataTestId={`${c?.title ?? ''} ${c.id}`}
            editingState={editingState}
            expanded={c.id === expandedItem?.id}
            expandedComponent={expandedComponent}
            icon={c.icon ?? icon}
            key={c.id}
            onExpandStateChanged={(isExpanded) => {
              // eslint-disable-next-line no-unused-expressions
              onItemExpandStateChanged &&
                onItemExpandStateChanged(isExpanded ? c : undefined);
            }}
            setConfirmAbandonForm={setConfirmAbandonForm}
            subTitles={c.subTitles}
            sx={i % 2 === 0 ? altColorSx : undefined}
            title={c?.title ?? ''}
          />
        ))}
    </List>
  );
};

export default AccordionList;
