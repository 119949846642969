/* eslint-disable react/jsx-props-no-spreading */
import { Box, Checkbox, SxProps, Theme, Typography } from '@mui/material';
import {
  ConfirmDialogs,
  ETOButton,
  MessageContext,
} from '@teto/react-component-library-v2';
import { useFormik } from 'formik';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Permission,
  getGraphQLClient as getNewGraphQLClient,
} from 'teto-client-api';
import * as Yup from 'yup';
import { getGraphQLClient } from '../../../../../api/graphQL/graphQLClient';

import { gql } from '../../../../../__generated__';
import AuthContext from '../../../../../contexts/AuthContext';
import ReleaseNCModal from '../../../../ReleaseNCModal/ReleaseNCModal';
import AutoSave from '../../../components/AddEditInspector/AutoSave/AutoSave';
import {
  formatNCUpdateMutation,
  getPropsInitialValues,
} from '../../NCPanels/InfoPanel/InfoPanelHelpers';
import updateNonConformanceMutation from '../../NCPanels/InfoPanel/updateNonConformanceMutation';

export interface NonConformanceButtonStripProps {
  initialValues?: Record<string, unknown>;
  // eslint-disable-next-line no-unused-vars
  onInspectorClose?: (dataUpdated: boolean) => void;
  // eslint-disable-next-line no-unused-vars
  setPanelEditingState: (value: {
    isValid: boolean;
    isEditing: boolean;
  }) => void;
  // eslint-disable-next-line no-unused-vars
  setNonConformance: (data: Record<string, unknown>) => void;
  setHasASaveOccurred: React.Dispatch<React.SetStateAction<boolean>>;
}

const releaseSchema = Yup.object().shape({
  resolved: Yup.bool(),
});

const rootSx: SxProps<Theme> = {
  borderTop: (theme) => `2px solid ${theme.palette.divider}`,
  display: 'flex',
  flexGrow: 0,
  flexShrink: 0,
  justifyContent: 'space-between',
  paddingTop: (theme) => theme.spacing(2),
  width: '100%',
};

const leftSideSx: SxProps<Theme> = {
  alignItems: 'center',
  display: 'flex',
  justifyContent: 'flex-start',
};

const assignmentQuery = gql(`query nonConformanceAssignments($id: Int!) {
  nonConformanceAssignments(id: $id, where: { complete: {eq: false}}) {
    items {
      complete
    }
  }
}`);

const initialModalValues = { isOpen: false, data: undefined };

const NonConformanceButtonStrip = (props: NonConformanceButtonStripProps) => {
  const {
    initialValues,
    onInspectorClose,
    setNonConformance,
    setPanelEditingState,
    setHasASaveOccurred,
  } = props;

  const { ready, t } = useTranslation();

  const authContext = useContext(AuthContext);
  const messageContext = useContext(MessageContext);

  const canModifyNC = authContext.hasPermission(
    Permission.Modify_Manufacturing_NonConformances
  );

  const [isReleaseModalOpen, setReleaseModalOpen] = useState<{
    isOpen: boolean;
    data?: Record<string, unknown>;
  }>(initialModalValues);

  const [openResolveDialog, setOpenResolveDialog] = useState<boolean>(false);

  const formik = useFormik({
    initialValues: { resolved: initialValues?.resolved ?? false },
    enableReinitialize: true,
    validationSchema: releaseSchema,
    onSubmit: (values, formikHelpers) => {
      getGraphQLClient()
        .performMutation(
          updateNonConformanceMutation,
          {
            input: {
              ...formatNCUpdateMutation({ ...initialValues, ...values }),
            },
          },
          (err) => {
            messageContext.setError(err.messages[0]);
          },
          (err) => {
            const { input } = err;
            messageContext.setError(Object.values(input)[0] as string);
          }
        )
        .then((d) => {
          if (d.updateNonConformance.id) {
            const result = getPropsInitialValues({
              ...d.updateNonConformance,
              item: d.updateNonConformance.item ?? undefined,
              purchaseOrderId:
                d.updateNonConformance?.purchaseOrderId ?? undefined,
              quantity: d.updateNonConformance?.quantity ?? undefined,
              quantityRejected:
                d.updateNonConformance?.quantityRejected ?? undefined,
              customer:
                d.updateNonConformance.project.company.name ?? undefined,
              supplier: d.updateNonConformance.purchaseOrder
                ? d.updateNonConformance.purchaseOrder.purchaseSupplier.name
                : undefined,
            });
            setNonConformance(result);
            setHasASaveOccurred(true);
          }
        });
      formikHelpers.setSubmitting(false);
    },
  });

  const _onResolveChange = (checked: boolean) => {
    getNewGraphQLClient()
      .performQuery(assignmentQuery, { id: initialValues?.id as number })
      .then((d) => {
        if (d.hasError()) {
          d.showAllSystemErrors(messageContext.setError);
          if (d.hasValidationErrors()) {
            const { input } = d.validationErrors;
            messageContext.setError(Object.values(input)[0] as string);
          }
          return;
        }
        const { data } = d;
        const taskCompleted = !(
          data.nonConformanceAssignments?.items &&
          data.nonConformanceAssignments?.items.length > 0
        );

        if (taskCompleted || formik.values.resolved) {
          formik.setValues({ resolved: checked });
        } else setOpenResolveDialog(true);
      });
  };

  const _onReleaseBtnClicked = () => {
    setReleaseModalOpen({
      isOpen: true,
      data: {
        ...formatNCUpdateMutation({ ...initialValues, ...formik.values }),
      },
    });
  };

  useEffect(() => {
    setPanelEditingState({
      isEditing: formik.dirty,
      isValid: formik.isValid,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.dirty, formik.isValid]);

  return (
    <>
      <Box data-testid="nc-resolve-release-bstrip" sx={rootSx}>
        <AutoSave debounceMs={2000} formik={formik} />
        {ready && (
          <>
            <Box data-testid="nci-resolved" sx={leftSideSx}>
              <Typography>{t('pages.nonConformance.resolved')}</Typography>
              <Checkbox
                checked={formik.values.resolved as boolean}
                disabled={!canModifyNC}
                name="resolved"
                onChange={(e, checked) => _onResolveChange(checked)}
              />
            </Box>
            <ETOButton
              btnTestId="btn-stp-release"
              color="primary"
              disabled={!canModifyNC || !formik.values.resolved}
              onClick={() => _onReleaseBtnClicked()}
              size="medium"
            >
              {t('pages.nonConformance.release')}
            </ETOButton>
          </>
        )}
      </Box>
      <ConfirmDialogs
        content={t(
          'pages.nonConformance.dialogs.resolveNonConformance.content'
        )}
        leftButton={{
          label: t('generic.no'),
          onClick: () => {
            setOpenResolveDialog(false);
          },
        }}
        open={openResolveDialog}
        rightButton={{
          label: t('generic.yes'),

          onClick: () => {
            setOpenResolveDialog(false);
            formik.setFieldValue('resolved', true);
          },
        }}
        title={t('pages.nonConformance.dialogs.resolveNonConformance.title')}
      />
      {isReleaseModalOpen.isOpen && (
        <ReleaseNCModal
          nonConformance={isReleaseModalOpen.data}
          onClose={() => setReleaseModalOpen(initialModalValues)}
          onInspectorClose={onInspectorClose}
          open={isReleaseModalOpen.isOpen}
        />
      )}
    </>
  );
};

export default NonConformanceButtonStrip;
