import CategoryIcon from '@mui/icons-material/Category';
import FindReplaceRoundedIcon from '@mui/icons-material/FindReplaceRounded';
import FormatListNumberedRoundedIcon from '@mui/icons-material/FormatListNumberedRounded';
import InsertChartRoundedIcon from '@mui/icons-material/InsertChartRounded';
import NoteAddRoundedIcon from '@mui/icons-material/NoteAddRounded';
import { Box, SxProps, Theme, Typography } from '@mui/material';
import {
  ButtonStrip,
  ETOButton,
  ETOMenu,
  GenericDialog,
  MenuOption,
  MessageContext,
} from '@teto/react-component-library-v2';
import { useAtom } from 'jotai';
import React, {
  RefObject,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { Permission, getGraphQLClient } from 'teto-client-api';
import { RfqDetail } from '../../../../../../__generated__/graphql';
import buildMutation from '../../../../../../api/graphQL/buildMutation';
import getErrors from '../../../../../../api/graphQL/getErrors';
import AuthContext from '../../../../../../contexts/AuthContext';
import SettingsContext from '../../../../../../contexts/SettingsContext';
import ActionBar from '../../../../../ActionBar/ActionBar';
import ReplacePartNumbersModal from '../../../../../ReplacePartNumbersModal/ReplacePartNumbersModal';
import { PurchaseOrderSharedState } from '../../../../../SharedStateComponents/StateContainers/PurchaseOrderState';
import GridCommonMobileButtons from '../../../../../TETOGridGraphQL/components/GridCommonMobileButtons/GridCommonMobileButtons';
import TETOGridRefType from '../../../../../TETOGridGraphQL/types/TETOGridRefType';
import RDGSelectedType from '../../../../../TetoGrid/types/RDGSelectedType';
import poExportReportQuery from '../../../../PurchaseOrderModal/queries/poExportReportQuery';
import getRFQDetails from '../../../queries/getRFQDetails';
import getRFQReports from '../../../queries/getRFQReportsQuery';
import getSalesReleaseQuery from '../../../queries/getSalesReleaseQuery';
import updateRFQOrderMutation from '../../../queries/updateRFQOrderMutation';
import PickPartsModal from './PickPartsModal';

interface RFQActionBarProps {
  gridRef: RefObject<TETOGridRefType>;
  rFQId: number;
  hasItems?: boolean;
  selectedRows: RDGSelectedType | undefined;
  rFQDetails?: {
    id: number;
    itemMasterDescription: string;
    entityDescription?: string | null;
  }[];
  supplierCount: number;
  dataLoading: boolean;
  dataLength: number;
  // eslint-disable-next-line no-unused-vars
  setConfigureInspectorOpen: (val: boolean) => void;
  setRefreshGrid: React.Dispatch<React.SetStateAction<boolean>>;
  setRefreshQuery?: React.Dispatch<React.SetStateAction<boolean>>;
}

const containerSx: SxProps<Theme> = {
  width: '100%',
  '& .css-1k85uti-MuiPaper-root-MuiDialog-paper': {
    maxWidth: { xs: '95%', md: 'unset !important' },
  },
};

const RFQActionBar = (props: RFQActionBarProps) => {
  const {
    gridRef,
    rFQId,
    hasItems,
    rFQDetails,
    selectedRows,
    supplierCount,
    dataLoading,
    dataLength,
    setConfigureInspectorOpen,
    setRefreshGrid,
    setRefreshQuery,
  } = props;

  const { t } = useTranslation();
  const messageContext = useContext(MessageContext);
  const authContext = useContext(AuthContext);
  const {
    settings: { addItemsPriorToSalesRelease, pOItemsForClosedMachines },
  } = useContext(SettingsContext);

  const reportBtnRef = useRef(null);
  const [reportMenu, setReportMenu] = useState<MenuOption[]>();

  const [isPickPartsOpen, setIsPickPartsOpen] = useState(false);
  const [isSalesReleased, setIsSalesReleased] = useState<boolean>(false);
  const [isJobClosed, setIsJobClosed] = useState<boolean>(false);
  const [isClosedJobWarningOpen, setIsClosedJobWarningOpen] =
    useState<boolean>(false);
  const [isReplacePartNumbersOpen, setIsReplacePartNumbersOpen] =
    useState<boolean>(false);
  const [, setPurchaseOrder] = useAtom(PurchaseOrderSharedState);

  const { canViewReport, canAddPO, canPickParts, canPreserve } = useMemo(
    () => ({
      canPickParts: authContext.hasPermission(
        Permission.Add_Procurement_RFQs_RFQHeader_RFQDetails
      ),
      canViewReport: authContext.hasAnyPermission([
        Permission.View_Legacy_Reports_RFQ_RFQ_Main,
        Permission.View_Legacy_Reports_RFQ_Blank_RFQ_List,
      ]),
      canAddPO: authContext.hasPermission(
        Permission.Add_Procurement_PurchaseOrders_AddPOItem
      ),
      canPreserve: authContext.hasPermission(
        Permission.Modify_Procurement_RFQs_RFQHeader_RFQDetails
      ),
    }),
    [authContext]
  );

  const _refreshGrid = useCallback(() => {
    gridRef?.current?.refreshDataSource();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gridRef]);

  useEffect(() => {
    if (rFQId) {
      getGraphQLClient()
        .performQuery(getRFQDetails, {
          rFQId,
        })
        .then((d) => {
          if (d.hasError() && d.hasSystemErrors()) {
            d.showAllSystemErrors(messageContext.setError);
            return;
          }
          if (d.hasData()) {
            const dataSourceX = d.data?.rFQDetails?.items;
            const variableList = (
              (dataSourceX ?? []) as Array<RfqDetail>
            ).reduce((acc, cv) => {
              const uniqueList = acc.some(
                (i: Partial<RfqDetail>) =>
                  i.projectId === cv.projectId && i.specId === cv.specId
              );
              if (!uniqueList)
                acc.push({ projectId: cv.projectId, specId: cv.specId });
              return acc;
            }, [] as Array<Partial<RfqDetail>>);

            if (variableList.length > 0) {
              const queryVariables = variableList.map((i) => ({
                projectId: { eq: i?.projectId },
                specId: { eq: i?.specId },
              }));
              getGraphQLClient()
                .performQuery(getSalesReleaseQuery, {
                  where: { or: queryVariables },
                })
                .then((res) => {
                  if (res.hasError() && res.hasSystemErrors()) {
                    res.showAllSystemErrors(messageContext.setError);
                    return;
                  }
                  if (res.hasData()) {
                    let salesRelease = 0;
                    let closedJobs = 0;
                    (res.data?.masterQueue?.items ?? []).forEach((i) => {
                      if (i?.salesReleaseDate) salesRelease += 1;
                      if (i?.closingReleaseDate) closedJobs += 1;
                    });

                    setIsSalesReleased(salesRelease === queryVariables.length);
                    setIsJobClosed(closedJobs > 0);
                  }
                });
            }
          }
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rFQId, dataLength]);

  useEffect(() => {
    setPurchaseOrder({ open: false });
  }, [setPurchaseOrder]);

  const _handleReportExport = useCallback(
    (reportId: number, alternateId: number) =>
      getGraphQLClient()
        .performQuery(poExportReportQuery, {
          id: reportId,
          companyDetails: true,
          input: {
            alternateId,
            parameters: {
              intRFQID: rFQId,
            },
          },
        })
        .then((d) => {
          if (d.hasError()) {
            d.showAllSystemErrors(messageContext.setError);
            if (d.hasValidationErrors()) {
              const { input } = d.validationErrors;
              messageContext.setError(Object.values(input)[0] as string);
            }
            return;
          }
          if (d.data?.exportReport?.path) {
            const data = {
              alternateId: alternateId.toString(),
              reportId: d.data?.exportReport?.reportId?.toString(),
              entityId: d.data?.exportReport?.parameters?.find(
                (r) => r.key === 'intRFQID'
              ).value,
              type: 'rFQ',
              hasItems: hasItems ? 'true' : 'false',
            };
            const queryString = new URLSearchParams(data).toString();
            window.open(
              `/reportViewer?url=${encodeURIComponent(
                d.data.exportReport.path
              )}&${queryString}`,
              '_blank'
            );
          }
        }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [rFQId]
  );

  const _handleReportButtonClick = useCallback(() => {
    getGraphQLClient()
      .performQuery(getRFQReports, {})
      .then((d) => {
        if (d.hasError()) {
          if (d.systemErrors)
            messageContext.setError(getErrors(d.systemErrors));
          if (d.validationErrors)
            messageContext.setError(getErrors(d.validationErrors));
          return;
        }
        if (d.hasData() && d.data?.rFQReports) {
          const reports = d.data.rFQReports.map((r) => ({
            label:
              r?.alternateId === 0
                ? `${r?.reportDisplayName} (Default)`
                : r?.reportDisplayName,
            disabled: !r?.allowView,
            onClick: () => _handleReportExport(r?.reportID, r?.alternateId),
          }));

          setReportMenu(reports);
        }
        return d;
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [_handleReportExport]);

  const _handlePreservePO = useCallback(() => {
    const data = gridRef?.current?.getGrid();
    const dataSource = data?.dataSource ?? [];
    const newOrder = (dataSource as RfqDetail[])?.map((d, i) => ({
      rFQDetailId: d?.id,
      orderNumber: i + 1,
    }));

    buildMutation({
      queryString: updateRFQOrderMutation,
      variables: {
        input: {
          rFQId,
          items: newOrder,
        },
      },
      callback: (d) => {
        if (d?.updateRFQDetailOrder?.items) {
          setRefreshGrid(true);
        }
      },
      messageContext,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gridRef, rFQId]);

  const _handleOpenCreatePOModal = useCallback(() => {
    const data = gridRef?.current?.getGrid();
    const dataSource = data?.dataSource ?? [];
    setPurchaseOrder(() => ({
      open: true,
      itemsToAdd: dataSource,
      fromRFQ: true,
    }));
  }, [gridRef, setPurchaseOrder]);

  const _handleCreatePOBtnClick = useCallback(() => {
    if (isJobClosed && !pOItemsForClosedMachines) {
      setIsClosedJobWarningOpen(true);
    } else {
      _handleOpenCreatePOModal();
    }
  }, [_handleOpenCreatePOModal, isJobClosed, pOItemsForClosedMachines]);

  const POBtnDisabled = useMemo(
    () =>
      !canAddPO ||
      (!addItemsPriorToSalesRelease && !isSalesReleased) ||
      !supplierCount ||
      dataLoading ||
      !dataLength,
    [
      addItemsPriorToSalesRelease,
      canAddPO,
      dataLength,
      dataLoading,
      isSalesReleased,
      supplierCount,
    ]
  );

  const createPOBtn = useMemo(
    () => ({
      disabled: POBtnDisabled,
      icon: <NoteAddRoundedIcon />,
      onclick: () => _handleCreatePOBtnClick(),
      title: t('pages.rFQ.createPO'),
      customComponent: (
        <ETOButton
          color="primary"
          disabled={POBtnDisabled}
          icon={<NoteAddRoundedIcon />}
          key={t('pages.rFQ.createPO')}
          onClick={() => _handleCreatePOBtnClick()}
          size="medium"
        >
          {t('pages.rFQ.createPO')}
        </ETOButton>
      ),
    }),
    [POBtnDisabled, t, _handleCreatePOBtnClick]
  );

  const preserveBtn = useMemo(
    () => ({
      title: t('pages.purchaseOrders.purchaseOrderModal.preserveOrder'),
      icon: <FormatListNumberedRoundedIcon />,
      onclick: () => _handlePreservePO(),
      disabled: dataLoading || !dataLength || !canPreserve,
    }),
    [_handlePreservePO, dataLength, dataLoading, canPreserve, t]
  );

  const pickPartsBtn = useMemo(
    () => ({
      title: t('pages.rFQ.pickParts'),
      icon: <CategoryIcon />,
      onclick: () => setIsPickPartsOpen(true),
      disabled: !canPickParts,
    }),
    [canPickParts, t]
  );

  const ReplacePartNumbersBtn = useMemo(
    () => ({
      title: t('pages.procurement.replacePartNumbers'),
      icon: <FindReplaceRoundedIcon />,
      onclick: () => setIsReplacePartNumbersOpen(true),
      disabled: !rFQDetails || rFQDetails.length === 0 || !dataLength,
    }),
    [dataLength, rFQDetails, t]
  );

  const reportsBtn = useMemo(
    () => ({
      title: t('generic.reports'),
      icon: <InsertChartRoundedIcon />,
      onclick: () => _handleReportButtonClick(),
      disabled: !canViewReport,
      customComponent: (
        <ETOButton
          buttonProps={{
            ref: reportBtnRef,
          }}
          color="primary"
          disabled={!canViewReport}
          icon={<InsertChartRoundedIcon />}
          key={t('generic.reports')}
          onClick={() => _handleReportButtonClick()}
          size="medium"
        >
          {t('generic.reports')}
        </ETOButton>
      ),
    }),
    [_handleReportButtonClick, canViewReport, t]
  );

  const GridCommonBtns = GridCommonMobileButtons({
    refreshGrid: () => setRefreshGrid(true),
    gridRef,
    setConfigureInspector: () => setConfigureInspectorOpen(true),
  });

  const rightChildren = useMemo(
    () => [
      GridCommonBtns,
      createPOBtn,
      preserveBtn,
      ReplacePartNumbersBtn,
      pickPartsBtn,
      reportsBtn,
    ],
    [
      createPOBtn,
      preserveBtn,
      pickPartsBtn,
      ReplacePartNumbersBtn,
      reportsBtn,
      GridCommonBtns,
    ]
  );

  return (
    <>
      <ActionBar rightChildren={rightChildren} />
      <PickPartsModal
        isOpen={isPickPartsOpen}
        onClose={(d) => {
          if (d) gridRef.current?.refreshDataSource();
          if (setRefreshQuery) setRefreshQuery(true);
          setIsPickPartsOpen(false);
        }}
        rFQId={rFQId}
      />
      {isReplacePartNumbersOpen && (
        <ReplacePartNumbersModal
          entityItems={rFQDetails}
          entityName="RFQ"
          onClose={(update) => {
            if (update) {
              _refreshGrid();
              gridRef.current?.deselectAll();
            }
            setIsReplacePartNumbersOpen(false);
          }}
          open={isReplacePartNumbersOpen}
          selected={selectedRows}
        />
      )}
      {reportMenu && (
        <ETOMenu
          anchorEl={reportBtnRef?.current}
          menuOptions={reportMenu as MenuOption[]}
          open={Boolean(reportMenu)}
          setClose={() => setReportMenu(undefined)}
          sx={{
            zIndex: 2,
          }}
        />
      )}
      {isClosedJobWarningOpen && (
        <GenericDialog
          isOpen={isClosedJobWarningOpen}
          onClose={() => setIsClosedJobWarningOpen(false)}
          sx={containerSx}
          title={t('pages.rFQ.rFQModal.dialogs.closeJobTitle')}
        >
          <Box sx={{ width: '100%' }}>
            <Typography sx={{ width: '100%' }}>
              {t('pages.rFQ.rFQModal.dialogs.closeJobContent')}
            </Typography>
            <ButtonStrip
              rightButton={{
                color: 'primary',
                onClick: () => setIsClosedJobWarningOpen(false),
                text: t('generic.ok'),
              }}
              size="medium"
            />
          </Box>
        </GenericDialog>
      )}
    </>
  );
};

export default RFQActionBar;
