import AppsRoundedIcon from '@mui/icons-material/AppsRounded';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import { Box, SxProps, Theme, useMediaQuery, useTheme } from '@mui/material';
import {
  ActionItemType,
  FloatingActionButton,
} from '@teto/react-component-library-v2';
import React from 'react';

const defaultFABPositionSx = (theme: Theme) => ({
  bottom: theme.spacing(2),
  position: 'fixed',
  right: theme.spacing(2),
  zIndex: 999,
});

const withBottomNavFabPositionSx = (theme: Theme) => ({
  bottom: theme.spacing(6.5),
  position: 'fixed',
  right: theme.spacing(2),
  zIndex: 999,
});

const speedDialOpenSx = (theme: Theme) => ({
  backgroundColor: theme.palette.primary.main,
});

interface FABProps {
  withBottomNav?: boolean;
  FABPosition?: SxProps<Theme>;
  FabActions: ActionItemType[];
  icon?: React.JSX.Element;
  hideFAB?: boolean;
  onFABOpen?: () => void;
  onFABClose?: () => void;
}
const FAB = (props: FABProps) => {
  const {
    FABPosition,
    FabActions,
    withBottomNav,
    icon,
    hideFAB,
    onFABOpen,
    onFABClose,
  } = props;
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  return (
    <>
      {isMobile && !hideFAB && (
        <Box
          sx={[
            ...(Array.isArray(FABPosition) ? FABPosition : [FABPosition]),
            !FABPosition && defaultFABPositionSx,
            withBottomNav && withBottomNavFabPositionSx,
            !withBottomNav && defaultFABPositionSx,
          ]}
        >
          <FloatingActionButton
            actionList={FabActions}
            closeIcon={<CloseRoundedIcon />}
            customSpeedDialOpenSx={speedDialOpenSx as SxProps}
            onFABClose={onFABClose}
            onFABOpen={onFABOpen}
            openIcon={icon || <AppsRoundedIcon />}
            speedDialProps={{
              sx: {
                ...(withBottomNav === true
                  ? { bottom: theme.spacing(6.5) }
                  : {}),
              },
            }}
          />
        </Box>
      )}
    </>
  );
};

export default FAB;
