import { gql } from '../../../../__generated__/gql';

const addPurchaseOrderMutation =
  gql(`mutation createNewPOMutation($input: AddPurchaseOrderHeaderAndDetailInput) {
    purchaseOrder: addPurchaseOrderHeaderAndDetail(input: $input) {
      id
      purchaseTerms
      buyerId
      buyer {
        name
      }
      purchaseShip
      purchaseRev
      purchaseCurr {
        name
      }
      purchaseDate
      purchaseCurrRate
      purchaseDateRevised
      purchaseDateRequired
      purchaseShipTo {
        id
      }      
      fobnotes
      purchaseFob
      compoundTax
      port
      purchaseTax1
      purchaseTax2
      purchaseTax1Name
      purchaseTax2Name
      purchasePrinted
      purchaseEmailed
      purchaseMisc
      purchaseNotes
      purchaseNotes2
      purchaseNotes3
      purchaseSupplierId
      purchaseSupplier {
        name
        supplier {
          defaultCurrNameId
          defaultCurrName {
            name
            rate
          }
        }
      }
      totals{
        detailTotal
      }
    }
  }  
`);

export default addPurchaseOrderMutation;
