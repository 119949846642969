import { TypeSingleSortInfo } from '@inovua/reactdatagrid-enterprise/types';
import DeleteIcon from '@mui/icons-material/Delete';
import NorthIconRounded from '@mui/icons-material/NorthRounded';
import SouthIconRounded from '@mui/icons-material/SouthRounded';
import {
  Box,
  IconButton,
  List,
  SxProps,
  Theme,
  Typography,
} from '@mui/material';
import { isArray } from 'lodash';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Container } from 'react-smooth-dnd';
import DragItem from '../../../ListItems/DragItem';
import DraggableListItem from '../../../ListItems/DraggableListItem';
import ListItem from '../../../ListItems/ListItem';
import { TETODataColumn } from '../../GridBuilder/types/TETODataColumn';
import SelectColumnEditor from '../SelectColumnEditor/SelectColumnEditor';

const assignedListSx = (theme: Theme) => ({
  flexGrow: 1,
  flexShrink: 1,
  overflowY: 'auto',
  paddingRight: 0.5,
  fontSize: '0.9rem',
  '& .smooth-dnd-draggable-wrapper': {
    borderRadius: `${theme.shape.borderRadius}px`,
  },
  '& .smooth-dnd-draggable-wrapper:nth-of-type(odd)': {
    backgroundColor: theme.palette.background.default,
  },
});

const altColorSx: SxProps<Theme> = {
  backgroundColor: (theme) => theme.palette.background.default,
  color: (theme) =>
    theme.palette.getContrastText(theme.palette.background.default),
};

interface SortColumnsPanelProps {
  sort: TypeSingleSortInfo[];
  columns: TETODataColumn[];
  // eslint-disable-next-line no-unused-vars
  addSort: (columnName: string, dir: 0 | 1 | -1) => void;
  // eslint-disable-next-line no-unused-vars
  removeSort: (columnName: string) => void;
  // eslint-disable-next-line no-unused-vars
  changeSortPosition: (oldIndex: number, newIndex: number) => void;
  // eslint-disable-next-line no-unused-vars
  changeSortDirection: (columnName: string, dir: 0 | 1 | -1) => void;
}

const SortColumnsPanel: React.FC<SortColumnsPanelProps> = (props) => {
  const {
    sort,
    addSort,
    removeSort,
    changeSortDirection,
    changeSortPosition,
    columns,
  } = props;

  // eslint-disable-next-line no-nested-ternary
  const sortArray = !sort
    ? []
    : isArray(sort)
    ? sort
    : [...(sort as unknown as [])];

  const { t } = useTranslation();

  const sortableColumns = useMemo(
    () => columns.filter((col) => col.sortable !== false),
    [columns]
  );

  return (
    <List disablePadding sx={assignedListSx}>
      <Container
        data-testid="configureInspectorListItemContainer"
        dragHandleSelector=".drag-handle"
        lockAxis="y"
        onDrop={(e: {
          removedIndex: number | null;
          addedIndex: number | null;
        }) => {
          if (e.removedIndex != null && e.addedIndex != null)
            // -1 due to the column selector being part of the list
            changeSortPosition(e.removedIndex - 1, e.addedIndex - 1);
        }}
      >
        <ListItem
          centerChild={
            <SelectColumnEditor
              columns={sortableColumns}
              isValueDisabled={(node) =>
                Boolean(sort.find((a) => a.name === node.column.name))
              }
              onValueChanged={(value) => addSort(value.column.name, 1)}
            />
          }
          customSx={(theme) => ({
            p: 0,
            pb: theme.spacing(1),
          })}
          size="medium"
        />
        {sortArray.map((col, ind) => (
          <DraggableListItem
            centerChild={
              <Typography
                color="textPrimary"
                noWrap
                textOverflow="ellipsis"
                variant="body1"
                width="100%"
              >
                {t(columns.find((a) => a.name === col.name)?.title ?? '')}
              </Typography>
            }
            customSx={{
              ...(ind % 2 === 0 ? altColorSx : {}),
              ...{
                cursor: 'pointer',
              },
            }}
            key={col.name}
            leftChild={
              <DragItem size="large" title={t('generic.changeOrder')} />
            }
            rightChild={
              <Box
                sx={{
                  display: 'flex',
                  flexWrap: 'nowarp',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                {col.dir === 1 && (
                  <IconButton onClick={() => changeSortDirection(col.name, -1)}>
                    <NorthIconRounded color="primary" fontSize="small" />
                  </IconButton>
                )}
                {col.dir === -1 && (
                  <IconButton onClick={() => changeSortDirection(col.name, 1)}>
                    <SouthIconRounded color="primary" fontSize="small" />
                  </IconButton>
                )}
                <IconButton onClick={() => removeSort(col.name)}>
                  <DeleteIcon color="error" />
                </IconButton>
              </Box>
            }
            size="small"
            testId="configureInspectorListItem"
          />
        ))}
      </Container>
    </List>
  );
};

export default SortColumnsPanel;
