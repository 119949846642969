import { gql } from '../../../__generated__';
import SelectDefinition from './SelectDefinition';

const getNonConformances = {
  query: gql(`query getNonConformances($where: NonConformanceFilterInput) {
    nonConformances(where: $where){
      items{
        id
        description
        displayName
        title
      }
    }
  }`),
  valueField: 'id',
  labelField: 'displayName',
} as SelectDefinition;

export default getNonConformances;
