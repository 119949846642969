import { useAtom, useSetAtom } from 'jotai';
import { useMolecule } from 'jotai-molecules';
import React, { useEffect, useMemo } from 'react';

import { useTranslation } from 'react-i18next';

import isEmptyOrWhitespace from '../../../helpers/isEmptyOrWhitespace';
import AddEditContactInspector from '../../Inspectors/AddEditContactInspector/AddEditContactInspector';
import { BreadCrumbSharedState } from '../StateContainers/BreadCrumbModalState';
import {
  ContactSharedState,
  onContactClose,
} from '../StateContainers/ContactState';

const useSharedContact = () => {
  const { t } = useTranslation();
  const [state] = useAtom(ContactSharedState);
  const onClose = useMolecule(onContactClose);
  const defaultOnClose = useSetAtom(onClose);
  const setLevels = useSetAtom(BreadCrumbSharedState);

  const name = useMemo(() => {
    if (!state.initialValues) return '';
    const firstName = state.initialValues?.firstName;
    const lastName = state.initialValues?.lastName;

    if (!firstName && !lastName) return '';
    if (firstName && !lastName) return firstName;
    if (!firstName && lastName) return lastName;
    if (firstName && lastName) return `${firstName} ${lastName}`;
  }, [state.initialValues]);

  const contactAsNameOrUseName = useMemo(() => {
    if (!isEmptyOrWhitespace(name)) {
      return `${t('inspectors.contactInspector.title')}: ${name as string}`;
    }

    return `${t('inspectors.contactInspector.title')}`;
  }, [name, t]);

  useEffect(() => {
    if (state.open) {
      setLevels((prev) => [
        ...prev,
        {
          name: contactAsNameOrUseName,
          data: state?.initialValues,
          component: (
            <AddEditContactInspector
              initialValues={state?.initialValues}
              onClose={defaultOnClose}
              open={state?.open}
            />
          ),
        },
      ]);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.initialValues, state.open]);
};

export default useSharedContact;
