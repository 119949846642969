import { Box } from '@mui/material';
import { MessageContext } from '@teto/react-component-library-v2';
import React, { useContext, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import CompanyProjects from '../../../../../views/CompanyProjectsView.yaml';
import ActionBar from '../../../../ActionBar/ActionBar';
import MainTetoGridGraphQL from '../../../../TETOGridGraphQL/TETOMainGridGraphQL';
import GridCommonMobileButtons from '../../../../TETOGridGraphQL/components/GridCommonMobileButtons/GridCommonMobileButtons';
import useGrid from '../../../../TETOGridGraphQL/hooks/useGrid';
import { useGridBuilderFromView } from '../../../../TETOGridGraphQL/hooks/useGridBuilder';
import TETOGridRefType from '../../../../TETOGridGraphQL/types/TETOGridRefType';

const containerSx = {
  display: 'flex',
  flexDirection: 'column',
  overflowY: 'auto',
  width: '100%',
};

interface CompanyProjectsPanelProps {
  companyId?: number;
}

const RootQueryPath = 'projects';
const PersistenceName = 'projects';

const ALWAYS_PROJECT_COLUMNS: Array<string> = ['id'];

const CompanyProjectsPanel = (props: CompanyProjectsPanelProps) => {
  const { companyId } = props;
  const { t, ready } = useTranslation();
  const messageContext = useContext(MessageContext);

  const gridRef = useRef<TETOGridRefType | undefined>();

  const [configureInspectorOpen, setConfigureInspectorOpen] =
    useState<boolean>(false);

  const { gridBuilder, builderReady, error, hasError } = useGridBuilderFromView(
    CompanyProjects,
    RootQueryPath,
    (gb) => gb.updateDefinition('id', { maxWidth: 200 })
  );

  const mandatoryFilters = useMemo(
    () => [
      {
        name: 'companyId',
        operator: 'equal',
        value: companyId,
        type: 'number' as const,
      },
    ],
    [companyId]
  );

  const { gridProps } = useGrid(
    PersistenceName,
    `${RootQueryPath}.items`,
    (e) => messageContext.setError(e.message ?? e),
    t,
    ALWAYS_PROJECT_COLUMNS,
    gridBuilder,
    {
      filterAndSortMode: 'serverSide',
      mandatoryFilter: mandatoryFilters,
    }
  );

  const GridCommonBtns = GridCommonMobileButtons({
    refreshGrid: () => gridProps.refreshDataSource(),
    gridRef,
    setConfigureInspector: () => setConfigureInspectorOpen(true),
  });

  const rightChildren = useMemo(() => GridCommonBtns, [GridCommonBtns]);

  return (
    <Box sx={containerSx}>
      <ActionBar rightChildren={rightChildren} />
      <MainTetoGridGraphQL
        builderReady={builderReady}
        configureInspector={configureInspectorOpen}
        customGridWrapSx={{ gridTemplateRows: 'unset' }}
        error={error}
        externalQueryProps={[]}
        gridProps={gridProps}
        hasError={hasError}
        header={{ hidden: true }}
        mobileGridOptions
        pagination
        ready={ready}
        ref={gridRef}
        setConfigureInspector={setConfigureInspectorOpen}
        showEmptyRows
        showGroupSummaryRow={false}
        t={t}
        tableIdentifier={PersistenceName}
      />
    </Box>
  );
};

export default CompanyProjectsPanel;
