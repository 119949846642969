import { gql } from '../../../__generated__';
import SelectDefinition from './SelectDefinition';

export default {
  query: gql(`query getAllSuppliers {
  companies(
    order: { active: DESC, name: ASC }
    where: {
      or: [{ supplier: { id: { gte: 0 } } }, { supplier: { id: { lt: 0 } } }]
    }
  ) {
    items {
      id
      name
      phone
      city
      state
      zip
      active
      supplier {
        supQaapproved
        id
        defaultCurrNameId
        poreportAlternateId
      }
    }
  }
}
`),
  valueField: 'supplier.id',
  labelField: 'name',
} as SelectDefinition;
