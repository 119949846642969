const getARTaxCategoriesQuery = `
query getARTaxCategoriesQuery {
  aRTaxCategories {
    items {
      id
      name
    }
  }
}
`;

export default getARTaxCategoriesQuery;
