import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Theme,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import React from 'react';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import { dialogPaperSx } from '../reportViewerStyles';

const iconButtonSx = (theme: Theme) => ({
  position: 'absolute',
  right: 8,
  top: 8,
  color: theme.palette.grey[500],
});

interface EmailDrawingDialogProps {
  handleClose: () => void;
  open: boolean;
  title: string;
  content: React.ReactNode;
  actions: React.ReactNode;
}

const EmailDrawingDialog = (props: EmailDrawingDialogProps) => {
  const { handleClose, open, title, content, actions } = props;
  const theme = useTheme();
  const fullScreenDialog = useMediaQuery(theme.breakpoints.down('md'));
  return (
    <Dialog
      fullScreen={fullScreenDialog}
      maxWidth={false}
      onClose={handleClose}
      open={open}
      PaperProps={{
        sx: dialogPaperSx,
      }}
    >
      <DialogTitle>{title}</DialogTitle>
      <IconButton aria-label="close" onClick={handleClose} sx={iconButtonSx}>
        <CloseRoundedIcon />
      </IconButton>
      <DialogContent dividers>
        <Box>{content}</Box>
      </DialogContent>
      <DialogActions>{actions}</DialogActions>
    </Dialog>
  );
};

export default EmailDrawingDialog;
