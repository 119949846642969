import { Box, Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

const NoRecordsFound = () => {
  const { t } = useTranslation();

  return (
    <Box
      sx={{
        height: '100%',
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Typography variant="h6">
        {t('generic.message.noRecordsFound')}
      </Typography>
    </Box>
  );
};

export default NoRecordsFound;
