import { ETOSelectField } from '@teto/react-component-library-v2';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import useGQLQuery from '../../../../../../../../api/graphQL/useGQLQuery';
import getProcessSchedules from '../../../../../../../TETOForms/selects/getProcessSchedules';

interface ExistingProcessScheduleSelectFieldProps {
  handleChange: React.ChangeEventHandler<
    HTMLTextAreaElement | HTMLInputElement
  >;
  error: string;
}

const ExistingProcessScheduleSelectField = (
  props: ExistingProcessScheduleSelectFieldProps
) => {
  const { error, handleChange } = props;
  const { t } = useTranslation();

  const [processSchedules, setProcessSchedules] = useState([]);

  useGQLQuery(['existingPsCreateForm'], {
    queryString: getProcessSchedules.query,
    callback: (data) => {
      setProcessSchedules(data?.processScheduleHeaders?.items ?? []);
    },
  });

  return (
    <ETOSelectField
      error={error}
      handleChange={handleChange}
      itemNameSelector={(item) => item[getProcessSchedules.labelField]}
      items={processSchedules}
      itemValueSelector={(item) => item[getProcessSchedules.valueField]}
      label={t('entities:ProcessSchedule.ProcessSchedule')}
      name="existingPS"
    />
  );
};

export default ExistingProcessScheduleSelectField;
