import { MessageContext } from '@teto/react-component-library-v2';
import { useAtom, useSetAtom } from 'jotai';
import { isEmpty } from 'lodash';
import { useCallback, useContext, useEffect, useState } from 'react';
import { QueryValidationError, getGraphQLClient } from 'teto-client-api';
import getErrors from '../../../../api/graphQL/getErrors';
import { GridProps } from '../../types/TetoGridGraphqlProps';
import hasErrorAtom from './atoms/hasErrorAtom';

import shouldResetAtom from './atoms/resetAtom';
import shouldSubmitAtom from './atoms/shouldSubmitAtom';

const useSubmitNewRow = <T,>(
  newRowData: T,
  gridProps: GridProps,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  mutationQuery: any,
  mutationVariables: Record<string, unknown>,
  formatValidationErrors?: (
    // eslint-disable-next-line no-unused-vars
    validationErrors: QueryValidationError
  ) => QueryValidationError
) => {
  const [shouldResetAddNewRowData, setShouldResetAddNewRowData] =
    useAtom(shouldResetAtom);

  const [validationErrors, setValidationErrors] =
    useState<QueryValidationError>();
  const [shouldSubmit, setShouldSubmit] = useAtom(shouldSubmitAtom);

  const messageContext = useContext(MessageContext);
  const setHasError = useSetAtom(hasErrorAtom);

  const _handleRowSubmit = useCallback(async () => {
    await getGraphQLClient()
      .performMutation(mutationQuery, mutationVariables)
      .then((d) => {
        if (d.hasError()) {
          setHasError(true);
          if (d.hasValidationErrors()) {
            const err = formatValidationErrors
              ? formatValidationErrors(d.validationErrors)
              : d.validationErrors;
            return setValidationErrors(err);
          }
          if (d.hasSystemErrors()) {
            setHasError(true);
            const err = getErrors(d.systemErrors);
            return messageContext.setError(err);
          }
        }
        setHasError(false);
        gridProps.refreshDataSource();
        setShouldResetAddNewRowData(true);
      });
  }, [
    formatValidationErrors,
    gridProps,
    messageContext,
    mutationQuery,
    mutationVariables,
    setHasError,
    setShouldResetAddNewRowData,
  ]);

  useEffect(() => {
    if (shouldSubmit && !isEmpty(newRowData)) {
      setShouldSubmit(false);
      _handleRowSubmit();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldSubmit]);

  return { shouldResetAddNewRowData, validationErrors };
};

export default useSubmitNewRow;
