import { gql } from '../../../../__generated__';

export const getProcessScheduleDetailsQuery =
  gql(`query timecardPSDs($date: DateTime!, $projectId: Int, $specId: Float) {
  timecardProcessScheduleDetails(
    dateInPayPeriod: $date
    order: { processScheduleId: DESC, lastWorkedOnDate: DESC }
    where: {
      and: [
        { processSchedule: { specId: { eq: $specId } } }
        { processSchedule: { projectId: { eq: $projectId } } }
      ]
    }
  ) {
    items {
      id
      sequence
      process {
        name
      }
      quantityReceived
      quantityIssued
      quantityIssuedMinusQuantityReceived
      processSchedule {
        number
        id
        specId
        spec {
          projectId
          displayName
        }
        
        project {
          displayName
        }
        number
        item {
          itemCompanyId
        }
      }
    }
  }
}

`);

export const getProcessScheduleDetailByIdQuery =
  gql(`query timecardPSD($date: DateTime!, $id: Int) {
  timecardProcessScheduleDetails(
    dateInPayPeriod: $date
    order: { processScheduleId: DESC, lastWorkedOnDate: DESC }
    where: {
      and: [
        {id: { eq: $id } }
      ]
    }
  ) {
    items {
      id
      sequence
      process {
        name
        hourTypeId
      }
      quantityReceived
      quantityIssued
      quantityIssuedMinusQuantityReceived
      processSchedule {
        number
        id
        specId
        spec {
          projectId
          displayName
        }
        
        project {
          displayName
        }
        number
        item {
          itemCompanyId
        }
      }
    }
  }
}

`);
