const getCompanyTermsQuery = `query getCompanyTerms{
  terms {
    items {
      id
      description
    }
  }
}`;

export default getCompanyTermsQuery;
