import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ClearRoundedIcon from '@mui/icons-material/ClearRounded';
import InputAdornment from '@mui/material/InputAdornment';
import { SxProps, Theme } from '@mui/material/styles';
import { ETOIconButton } from '@teto/react-component-library-v2';
import React from 'react';

export interface GridInputAdornmentProps {
  disabled?: boolean;
  expanded?: boolean;
  onClear: () => void;
  clearable: boolean;
  isFocused: boolean;
  hideOptionsOnClear?: boolean;
}

const cursorSx = { cursor: 'pointer' };
const clearSx: SxProps<Theme> = {
  cursor: 'pointer',
  fill: (theme) => theme.palette.text.disabled,
  mr: 0.5,
};

const styleSx: SxProps<Theme> = {
  height: (theme) => theme.spacing(2.25),
};

const GridInputAdornment = (props: GridInputAdornmentProps) => {
  const {
    disabled,
    expanded,
    clearable,
    onClear,
    isFocused,
    hideOptionsOnClear,
  } = props;

  return (
    <InputAdornment
      position="end"
      sx={{
        ...styleSx,
        color: (theme) => (disabled ? theme.palette.text.disabled : 'inherit'),
      }}
    >
      {clearable && isFocused && (
        <ETOIconButton
          color="primary"
          onClick={(e) => {
            onClear();
            if (hideOptionsOnClear) e?.stopPropagation();
          }}
          size="medium"
        >
          <ClearRoundedIcon fontSize="small" sx={clearSx} />
        </ETOIconButton>
      )}
      {expanded ? (
        <ArrowDropUpIcon sx={cursorSx} />
      ) : (
        <ArrowDropDownIcon sx={cursorSx} />
      )}
    </InputAdornment>
  );
};

export default GridInputAdornment;
