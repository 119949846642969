import { ETOSelectField, ETOTextField } from '@teto/react-component-library-v2';
import { debounce } from 'lodash';
import React, { useCallback, useContext, useState } from 'react';
import dayjs, { Dayjs } from 'dayjs';
import { TETODataColumn } from '../../../GridBuilder/types/TETODataColumn';
import FilterCheckbox from './FilterCheckbox';
import FilterIcon from './FilterIcon';
import SettingsContext from '../../../../../contexts/SettingsContext';
import TETODateTimeFilter from '../../../Filtering/TETODateTimeFilter';
import DateFilterRange from '../../../Filtering/DateFilterRange';
import TETONumberFilter from '../../../Filtering/TETONumberFilter';

interface RenderFilterFieldTypeProps {
  /* eslint-disable no-unused-vars */
  col: TETODataColumn;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  initialValue: any;
  handleClickListItem: () => void;
  handleChange: (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    curCol: TETODataColumn
  ) => void | undefined | null;
  /* eslint-enable */
  operatorValue: string;
}

const RenderFilterFieldType = (props: RenderFilterFieldTypeProps) => {
  const {
    col,
    initialValue,
    handleChange,
    handleClickListItem,
    operatorValue,
  } = props;

  const settingsContext = useContext(SettingsContext);

  const commonSize = 'small';

  const [fieldValue, setFieldValue] = useState(initialValue);

  const debouncedHandleChange = debounce((e) => handleChange(e, col), 500);
  const _handleChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      setFieldValue(e.target.value);
      debouncedHandleChange(e);
    },
    [debouncedHandleChange]
  );

  const _handleNumberChange = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (val: any) => {
      setFieldValue(val);
      if (operatorValue !== 'inrange') {
        debouncedHandleChange(parseFloat(val));
      }
      if (operatorValue === 'inrange' && 'start' in val && 'end' in val) {
        debouncedHandleChange({
          start: val?.start ? parseFloat(val.start) : '',
          end: val?.end ? parseFloat(val.end) : '',
        });
      }
    },
    [debouncedHandleChange, operatorValue]
  );

  const _handleDateChange = (date: Dayjs | null | DateFilterRange) => {
    setFieldValue(date);
    if (dayjs.isDayjs(date)) {
      debouncedHandleChange(
        dayjs(date).format(settingsContext.settings.dateFormat)
      );
    }

    if (
      operatorValue === 'inrange' &&
      date &&
      'start' in date &&
      'end' in date
    ) {
      debouncedHandleChange({
        start: date?.start
          ? dayjs(date.start).format(settingsContext.settings.dateFormat)
          : '',
        end: date?.end
          ? dayjs(date.end).format(settingsContext.settings.dateFormat)
          : '',
      });
    }
  };
  switch (col.filterType) {
    case 'select':
      return (
        <>
          <ETOSelectField
            handleChange={_handleChange}
            id={col.id}
            itemNameSelector={(i: { name: string }) =>
              (i as { name: string }).name
            }
            items={col.filterEditorProps.dataSource}
            itemValueSelector={(i: { name: string }) =>
              (i as { name: string }).name
            }
            label={col.title}
            name={col.name}
            size={commonSize}
            sx={{ pl: 0 }}
            value={fieldValue}
          />
          <FilterIcon onClick={handleClickListItem} title="Filter" />
        </>
      );
    case 'custom':
      return (
        <>
          <ETOTextField
            handleChange={_handleChange}
            id={col.id}
            label={col.title}
            name={col.name}
            size={commonSize}
            sxProps={{ mr: 1, pl: 0 }}
            type={col.type === 'number' ? 'number' : undefined}
            value={fieldValue}
          />
          <FilterIcon onClick={handleClickListItem} title="Filter" />
        </>
      );
    case 'string':
      return (
        <>
          <ETOTextField
            handleChange={_handleChange}
            id={col.id}
            label={col.title}
            name={col.name}
            size={commonSize}
            sxProps={{ mr: 1, pl: 0 }}
            type={col.type === 'number' ? 'number' : undefined}
            value={fieldValue}
          />
          <FilterIcon onClick={handleClickListItem} title="Filter" />
        </>
      );

    case 'number':
    case 'hours':
    case 'currency':
      return (
        <>
          <TETONumberFilter
            col={col}
            fieldValue={fieldValue}
            handleChange={_handleNumberChange}
            operatorValue={operatorValue}
          />
          <FilterIcon onClick={handleClickListItem} title="Filter" />
        </>
      );
    case 'bool':
    case 'boolean':
    case 'bitwise':
      return (
        <>
          <FilterCheckbox
            column={col}
            handleChange={_handleChange}
            value={fieldValue}
          />
          <FilterIcon onClick={handleClickListItem} title="Filter" />
        </>
      );

    case 'date':
    case 'datetime':
    case 'time':
      return (
        <>
          <TETODateTimeFilter
            col={col}
            fieldValue={fieldValue}
            handleChange={_handleDateChange}
            operatorValue={operatorValue}
          />
          <FilterIcon onClick={handleClickListItem} title="Filter" />
        </>
      );

    default:
      return (
        <>
          <ETOTextField
            handleChange={_handleChange}
            id={col.id}
            label={col.title}
            name={col.name}
            size={commonSize}
            sxProps={{ mr: 1, pl: 0 }}
            type={col.type === 'number' ? 'number' : undefined}
            value={fieldValue}
          />
          <FilterIcon onClick={handleClickListItem} title="Filter" />
        </>
      );
  }
};

export default RenderFilterFieldType;
