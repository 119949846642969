import { gql } from '../../../__generated__';
import SelectDefinition from './SelectDefinition';

export default {
  query:
    gql(`query getProcessScheduleDetails($projectId: Int!, $specId: Float!) {
    processScheduleDetails(
      where: { quantityIssued: { gt: 0}, inHouse: {eq: true}, processSchedule: { projectId: { eq: $projectId }, specId: { eq: $specId} } }
    ) {
      items {
        id
        name
        sequence
        process {
          name
        }
        quantityIssued
        quantityIssuedMinusQuantityReceived
        processSchedule {
          specId
          spec {
            projectId
          }
          number
          item {
            itemCompanyId
          }
        }
      }
    }
  }`),
  valueField: 'id',
  labelField: 'name',
} as SelectDefinition;
