import { gql } from '../../../../__generated__';

const getContactQuery = gql(`query getContact($id: Int!) {
  contact(id: $id) {
    active
    carPhone
    cellPhone
    companyId
    custom1
    custom2
    custom3
    custom4
    custom5
    custom6
    custom7
    custom8
    date
    email
    ext
    fanRatingId
    fax
    firstName
    homeFax
    homePhone
    id
    lastName
    pager
    phone
    title
  }
}
`);

export default getContactQuery;
