import Box from '@mui/material/Box';
import ListItem from '@mui/material/ListItem';
import { SxProps, Theme } from '@mui/material/styles';
import React, { ReactNode, useMemo } from 'react';

export type Size = 'small' | 'medium' | 'large';
export interface ListItemProps {
  centerChild?: string | ReactNode;
  leftChild?: ReactNode;
  rightChild?: ReactNode;
  size?: Size | number;
  customSx?: SxProps<Theme>;
  testId?: string;
  onClick?: () => void;
}

const itemSx: SxProps<Theme> = {
  width: '100%',
  display: 'flex',
  p: 1,
  columnGap: 1,
  alignItems: 'center',
  textTransform: 'capitalize',
  borderRadius: (theme) => `${theme.shape.borderRadius}px`,
  cursor: 'pointer',
};
const centerSx: SxProps<Theme> = {
  flexGrow: 1,
  flexShrink: 1,
  overflow: 'hidden',
};

const TETOListItem = (props: ListItemProps) => {
  const {
    centerChild,
    customSx,
    leftChild,
    onClick,
    rightChild,
    size,
    testId,
  } = props;
  const componentHeight = useMemo(() => {
    if (size) {
      if (typeof size === 'number') return size;
      switch (size) {
        case 'small':
          return 6;
        case 'medium':
          return 7;
        case 'large':
        default:
          return 8;
      }
    }
    return 8;
  }, [size]);

  return (
    <ListItem
      data-testid={testId}
      onClick={() => onClick?.()}
      sx={[
        itemSx,
        { height: (theme) => theme.spacing(componentHeight) },
        ...(Array.isArray(customSx) ? customSx : [customSx]),
      ]}
    >
      {leftChild && <Box>{leftChild}</Box>}
      {centerChild && <Box sx={centerSx}>{centerChild}</Box>}
      {rightChild && <Box>{rightChild}</Box>}
    </ListItem>
  );
};

export default TETOListItem;
