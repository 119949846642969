import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import ArrowCircleLeftRoundedIcon from '@mui/icons-material/ArrowCircleLeftRounded';
import LockIcon from '@mui/icons-material/Lock';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import {
  Box,
  Checkbox,
  Divider,
  FormControlLabel,
  Grid,
  IconButton,
  SxProps,
  TextField,
  Theme,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import {
  ETOButton,
  ETOIconButton,
  MessageContext,
  OkCancelConfirmDialog,
} from '@teto/react-component-library-v2';
import { useFormik } from 'formik';
import React, { useCallback, useContext, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { getGraphQLClient } from 'teto-client-api';
import useLocalStorage from 'use-local-storage';
import * as yup from 'yup';
import { gql } from '../../../../__generated__';
import ChangeExpiredPasswordPage from '../../../ChangeExpiredPasswordPage/ChangeExpiredPasswordPage';
import MFADialog from '../../../SettingsPage/Tabs/AccountTab/components/MFADialog';
import loginMutation from './loginMutation';
import publicSystemSettingsQuery from './publicSystemSettingsQuery';

type InitialLoginValues = {
  password: string;
  rememberMe?: boolean;
  twoFactorToken: string;
  userName: string;
};

const formSx: SxProps<Theme> = {
  alignItems: 'center',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  position: 'relative',
  width: (theme) => ({
    xs: theme.spacing(36),
    sm: theme.spacing(40),
  }),
};

const textInputSx: SxProps<Theme> = {
  mt: 2,
  mb: 3,
  position: 'relative',
  '& .MuiOutlinedInput-input': {
    pl: 1,
  },
};
const helperTextSx: SxProps<Theme> = {
  position: 'absolute',
  top: (theme) => theme.spacing(7),
};

const loginContainerSx: SxProps<Theme> = {
  alignItems: 'center',
  backgroundColor: 'background.paper',
  borderRadius: (theme) => theme.spacing(1.5),
  boxShadow: (theme) => ({
    xs: theme.shadows[0],
    sm: theme.shadows[4],
  }),
  display: 'flex',
  flexDirection: 'column',
  mb: 6,
  maxWidth: (theme) => ({
    xs: theme.spacing(40),
    sm: theme.spacing(52),
  }),
  pb: 5,
  pt: 3,
  width: '100%',
};

const rememberMeSx: SxProps<Theme> = {
  alignItems: 'center',
  display: 'flex',
  justifyContent: 'flex-start',
  mb: 1,
  width: '100%',
};

const rootSx: SxProps<Theme> = {
  alignItems: 'center',
  backgroundColor: 'background.paper',
  boxShadow: (theme) => ({
    xs: theme.shadows[0],
    sm: theme.shadows[4],
  }),
  borderRadius: (theme) => theme.spacing(1.5),
  display: 'flex',
  flexDirection: 'column',
  mb: 5,
  maxWidth: (theme) => ({
    xs: theme.spacing(40),
    sm: theme.spacing(62),
  }),
  p: 3,
};

const headerSx: SxProps<Theme> = {
  alignItems: 'center',
  display: 'flex',
  justifyContent: 'center',
  width: '100%',
};

const inputSx: SxProps<Theme> = {
  backgroundColor: 'transparent',
  border: 'none',
  bottom: 0,
  fontSize: (theme) => theme.spacing(4),
  outline: 'none',
  position: 'absolute',
  textAlign: 'center',
  top: 0,
  width: (theme) => ({
    xs: theme.spacing(5),
    md: theme.spacing(8),
  }),
  '& :last-child': {
    mr: 0,
  },
};

const tokenContainerSx: SxProps<Theme> = {
  display: 'flex',
  mb: 3,
  mt: 2,
  position: 'relative',
  '& div:last-child': {
    mr: 0,
  },
};

const dividerSx: SxProps<Theme> = { mb: 1, width: '100%' };

const inputWrapSx: SxProps<Theme> = {
  alignItems: 'center',
  border: (theme) => `1px solid ${theme.palette.grey[400]}`,
  borderRadius: (theme) => theme.spacing(0.5),
  display: 'flex',
  fontSize: (theme) => ({
    xs: theme.spacing(4),
    md: theme.spacing(6),
  }),
  height: (theme) => ({
    xs: theme.spacing(6),
    md: theme.spacing(9),
  }),
  justifyContent: 'center',
  mr: 1.5,
  width: (theme) => ({
    xs: theme.spacing(5),
    md: theme.spacing(8),
  }),
};

const initialValues = {
  userName: '',
  password: '',
  twoFactorToken: '',
};

const validationSchema = yup.object({
  userName: yup.string().required('Username is required'),
  password: yup.string().required('Password is required'),
  stayLoggedIn: yup.boolean(),
});

const CODE_LENGTH = new Array(6).fill(0);
const inputRegex = /^[0-9\b]+$/;

export type LoginFailReason =
  | 'AUTHENTICATION_FAILED'
  | 'EMPLOYEE_INACTIVE'
  | 'NO_LICENSE'
  | 'PASSWORD_CHANGE_REQUIRED'
  | 'TOO_MANY_ATTEMPTS'
  | 'TWO_FACTOR_CHALLENGE'
  | 'TWO_FACTOR_SETUP_REQUIRED';

const LoginForm: React.FC<React.PropsWithChildren> = () => {
  const { t } = useTranslation();

  const theme = useTheme();
  const mobileSize = useMediaQuery(theme.breakpoints.down('md'));

  const messageContext = useContext(MessageContext);
  const [mfaSetupWarningDialog, setMFASetupWarningDialog] = useState(false);

  const [passwordChangeRequired, setPasswordChangeRequired] = useState(false);
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [lastUserName, setLastUserName] = useState('');
  const [mfaCodeRequired, setMFACodeRequired] = useState<boolean>(false);
  const [employeeDuration, setEmployeeDuration] = useState<number | undefined>(
    0
  );

  const [mfaDialog, setMFADialog] = useState<boolean>(false);
  const [rememberMeSetting, setRememberMeSetting] = useLocalStorage<boolean>(
    'remember-user',
    false
  );

  const publicSystemSettings = useQuery(
    ['public-system-settings'],
    () =>
      getGraphQLClient()
        .performQuery(publicSystemSettingsQuery, {})
        .then((d) => {
          if (d.hasError()) {
            d.showAllSystemErrors(messageContext.setError);
            if (d.hasValidationErrors()) {
              const { input } = d.validationErrors;
              messageContext.setError(Object.values(input)[0] as string);
            }
            return;
          }

          setEmployeeDuration(
            d.data.publicSystemSettings?.employeeStayLoggedInDuration
          );

          return d.data.publicSystemSettings?.employeeStayLoggedInDuration;
        }),
    {
      enabled: true,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
    }
  );

  const _handleMFAError = (failReason: string) => {
    if (
      failReason === 'TWO_FACTOR_CHALLENGE' ||
      failReason === 'AUTHENTICATION_FAILED'
    ) {
      messageContext.setError(t('auth.authError6'));
    }
  };

  const loginMFAMutation =
    gql(`mutation loginMFA($userName: String!, $password: String!, $mfa: String, $rememberMe: Boolean){
    login(input: {userName: $userName, password: $password, twoFactorToken: $mfa, rememberMe: $rememberMe}){
      isSuccess
      failReason
    }
  }`);

  const _handleLoginError = useCallback(
    (userName: string, failReason: LoginFailReason) => {
      switch (failReason) {
        case 'TWO_FACTOR_SETUP_REQUIRED':
          setMFASetupWarningDialog(true);
          break;
        case 'TOO_MANY_ATTEMPTS':
          messageContext.setError(t('auth.authError4'));
          break;
        case 'NO_LICENSE':
          messageContext.setError(t('auth.authError3'));
          break;
        case 'EMPLOYEE_INACTIVE':
          messageContext.setError(t('auth.authError2'));
          break;
        case 'PASSWORD_CHANGE_REQUIRED':
          setLastUserName(userName);
          setPasswordChangeRequired(true);
          break;
        case 'AUTHENTICATION_FAILED':
          messageContext.setError(t('auth.authError0'));
          break;
        case 'TWO_FACTOR_CHALLENGE':
          setMFACodeRequired(true);
          break;
        default:
          messageContext.setError(t('auth.authError0'));
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [t]
  );

  const _doLogin = useCallback(
    (userName: string, password: string, rememberMe?: boolean) =>
      getGraphQLClient()
        .performMutation(loginMutation, {
          userName,
          password,
          rememberMe: rememberMe ?? undefined,
        })
        .then((res) => {
          formik.setSubmitting(false);

          if (res.hasError()) {
            res.showAllSystemErrors(messageContext.setError);
            if (res.hasValidationErrors()) {
              const { input } = res.validationErrors;
              messageContext.setError(Object.values(input)[0] as string);
            }
            return;
          }
          if (res.data?.login?.isSuccess) {
            window.location.reload();
          }
          if (!res.data?.login?.isSuccess && res.data?.login?.failReason) {
            _handleLoginError(
              userName,
              res.data?.login?.failReason as LoginFailReason
            );
          }
        }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [_handleLoginError, loginMutation]
  );

  const formik = useFormik<InitialLoginValues>({
    initialValues: {
      userName: initialValues?.userName ?? '',
      password: initialValues?.password ?? '',
      rememberMe: rememberMeSetting,
      twoFactorToken: initialValues?.twoFactorToken ?? '',
    },
    validationSchema,
    onSubmit: (values, actions) => {
      if (mfaCodeRequired) {
        getGraphQLClient()
          .performMutation(loginMFAMutation, {
            userName: values.userName,
            password: values.password,
            mfa: codeValue,
            rememberMe: values.rememberMe,
          })
          .then((res) => {
            if (res.hasError()) {
              res.showAllSystemErrors(messageContext.setError);
              if (res.hasValidationErrors()) {
                const { input } = res.validationErrors;
                formik.setErrors(input);
              }
              return;
            }
            if (res.data.login?.isSuccess) {
              window.location.reload();
            }
            if (!res.data.login?.isSuccess && res.data.login?.failReason) {
              _handleMFAError(res.data.login.failReason);
            }
            actions.setSubmitting(false);
            setCodeValue('');
          })
          .catch(() => {
            actions.setSubmitting(false);
          });
      } else {
        _doLogin(values.userName, values.password, values.rememberMe)
          .then(() => {
            actions.setSubmitting(false);
          })
          .catch((e) => {
            actions.setSubmitting(false);

            if (Object.prototype.hasOwnProperty.call(e, 'errors')) {
              actions.setErrors(e.errors);
            } else {
              throw e;
            }
          });
      }
    },
  });

  const [codeValue, setCodeValue] = useState<string>('');
  const [focused, setFocused] = useState<boolean>(false);
  const values = codeValue.split('');

  const inputRef = useRef<HTMLDivElement | null>(null);

  const handleClick = () => {
    if (inputRef.current && !mobileSize) {
      inputRef?.current.focus();
    }
  };

  const handleFocus = () => {
    if (!mobileSize) setFocused(true);
  };

  const handleBlur = () => {
    if (!mobileSize) setFocused(false);
  };

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { value } = e.target;

    if (value === '' || inputRegex.test(value)) {
      setCodeValue((cValue) => {
        if (cValue.length >= CODE_LENGTH.length) return cValue;
        return (cValue + value).slice(0, CODE_LENGTH.length);
      });
    }
  };

  const selectedIndex =
    values.length < CODE_LENGTH.length ? values.length : CODE_LENGTH.length - 1;

  const handleKeyUp = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.key === 'Backspace') {
      setCodeValue((cValue) => cValue.slice(0, cValue.length - 1));
    }
  };

  const hideInput = !(values.length < CODE_LENGTH.length);

  const employeeDurationToDays = (employeeDuration as number) / 24;

  if (!publicSystemSettings.isSuccess) return <div>&nbsp;</div>;

  if (passwordChangeRequired) {
    return (
      <ChangeExpiredPasswordPage
        onPasswordChanged={() => setPasswordChangeRequired(false)}
        userName={lastUserName}
      />
    );
  }

  return mfaCodeRequired ? (
    <Box component="form" onSubmit={formik.handleSubmit} sx={rootSx}>
      <Box sx={headerSx}>
        <IconButton onClick={() => setMFACodeRequired(false)}>
          <ArrowCircleLeftRoundedIcon color="primary" />
        </IconButton>

        <Typography variant={mobileSize ? 'subtitle1' : 'h5'}>
          {t('dialogs.twoFactorAuthentication.title')}
        </Typography>
      </Box>

      <Divider sx={dividerSx} />

      <Typography variant="subtitle1">
        {t('dialogs.twoFactorAuthentication.content')}
      </Typography>

      <Box
        onClick={() => handleClick()}
        onTouchStart={handleClick}
        sx={tokenContainerSx}
      >
        <TextField
          autoFocus
          InputProps={{
            sx: {
              fontSize: {
                xs: theme.spacing(1.35),
                md: theme.spacing(3.5),
              },
            },
          }}
          name="twoFactorToken"
          onBlur={handleBlur}
          onChange={(e) => handleChange(e)}
          onFocus={handleFocus}
          onKeyUp={(e) => handleKeyUp(e)}
          ref={inputRef}
          sx={[
            inputSx,
            {
              left: {
                xs: `${selectedIndex * 52}px`,
                md: `${selectedIndex * 76}px`,
              },
              opacity: hideInput ? 0 : 1,
            },
          ]}
          value={formik.values.twoFactorToken}
        />
        {CODE_LENGTH.map((v, index) => {
          const selected = values.length === index;
          const filled =
            values.length === CODE_LENGTH.length &&
            index === CODE_LENGTH.length - 1;
          return (
            <Box
              onClick={() => handleClick()}
              sx={[
                inputWrapSx,
                {
                  '& div:last-child': {
                    marginRight: 0,
                  },
                },
              ]}
            >
              {values[index]}
              {(selected || filled) && focused && (
                <Box
                  sx={{
                    position: 'absolute',
                    left: 0,
                    top: 0,
                    bottom: 0,
                    right: 0,
                    marginRight: theme.spacing(1),
                  }}
                />
              )}
            </Box>
          );
        })}
      </Box>

      <ETOButton
        buttonProps={{
          variant: 'contained',
          fullWidth: true,
        }}
        color="primary"
        disabled={!(values.length === 6)}
        loading={formik.isSubmitting}
        size="medium"
        type="submit"
      >
        {t('generic.verify')}
      </ETOButton>
    </Box>
  ) : (
    <Grid alignContent="center" container sx={loginContainerSx}>
      <Grid item xs={12}>
        <Typography align="center" color="textSecondary" variant="h5">
          {t('generic.login')}
        </Typography>
        <Box component="form" onSubmit={formik.handleSubmit} sx={formSx}>
          <TextField
            error={formik.touched.userName && Boolean(formik.errors.userName)}
            FormHelperTextProps={{ classes: helperTextSx }}
            fullWidth
            helperText={formik.touched.userName && formik.errors.userName}
            id="login-userName"
            InputProps={{
              startAdornment: (
                <AccountCircleIcon
                  color={`${
                    formik.touched.userName && Boolean(formik.errors.userName)
                      ? 'error'
                      : 'primary'
                  }`}
                />
              ),
            }}
            label="Username"
            name="userName"
            onChange={formik.handleChange}
            sx={textInputSx}
            value={formik.values.userName}
            variant="outlined"
          />

          <TextField
            error={formik.touched.password && Boolean(formik.errors.password)}
            FormHelperTextProps={{ classes: helperTextSx }}
            fullWidth
            helperText={formik.touched.password && formik.errors.password}
            id="login-password"
            InputProps={{
              startAdornment: (
                <LockIcon
                  color={`${
                    formik.touched.password && Boolean(formik.errors.password)
                      ? 'error'
                      : 'primary'
                  }`}
                />
              ),
              endAdornment: (
                <ETOIconButton
                  color="primary"
                  onClick={() => setShowPassword((prev) => !prev)}
                  size="medium"
                >
                  {!showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
                </ETOIconButton>
              ),
            }}
            label="Password"
            name="password"
            onChange={formik.handleChange}
            sx={[textInputSx, { mb: 2 }]}
            type={showPassword ? 'text' : 'password'}
            value={formik.values.password}
            variant="outlined"
          />

          {employeeDurationToDays > 0 && (
            <Box sx={rememberMeSx}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={formik.values.rememberMe}
                    name="rememberMe"
                    onChange={(e) => {
                      setRememberMeSetting(e.target.checked);
                      formik.handleChange(e);
                    }}
                    size="small"
                    value={formik.values.rememberMe}
                  />
                }
                label={`Stay logged in for ${employeeDurationToDays} ${
                  employeeDurationToDays === 1 ? 'day' : 'days'
                }`}
                sx={{ mr: 1 }}
              />
            </Box>
          )}

          <ETOButton
            buttonProps={{
              variant: 'contained',
              fullWidth: true,
            }}
            color="primary"
            loading={formik.isSubmitting}
            size="medium"
            type="submit"
          >
            Sign In
          </ETOButton>
        </Box>
      </Grid>
      <OkCancelConfirmDialog
        content="Your administrator has enforced multi-factor authentication is required.  To continue please have your phone present and your authenticator app ready.  Clicking 'Ok' without having this available may cause you to be locked out of your account"
        onCancel={() => setMFASetupWarningDialog(false)}
        onOk={() => {
          setMFADialog(true);
          setMFASetupWarningDialog(false);
        }}
        open={mfaSetupWarningDialog}
        title="MFA Setup is required"
      />
      {mfaDialog && (
        <MFADialog
          mode="enable-public"
          onSuccess={() => {
            formik.submitForm();
          }}
          password={formik.values.password}
          userName={formik.values.userName}
        />
      )}
    </Grid>
  );
};

export default LoginForm;
