import { TypeComputedProps } from '@inovua/reactdatagrid-community/types';
import { uniqueId } from 'lodash';
import { MutableRefObject } from 'react';
import { IGraphQLClient } from 'teto-client-api';
import getErrors from '../../api/graphQL/getErrors';
import { browse } from './queries';
import { FileType } from './types';

export const loadNodes = async (
  id: number | undefined,
  folderPath: string | undefined,
  client: IGraphQLClient,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  messageContext: any
): Promise<
  { folders: FileType[]; files: FileType[]; combined: FileType[] } | undefined
> => {
  const result = await getFolderInformation(
    id,
    folderPath,
    client,
    messageContext
  )
    .then((resp) => {
      if (resp) {
        const folders = resp
          .filter((item: { entryType: string }) => item.entryType === 'FOLDER')
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          .map((i: any) => ({ ...i, nodes: null, uniqueId: uniqueId() }));

        const files = resp
          .filter((item: { entryType: string }) => item.entryType === 'FILE')
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          .map((i: any) => ({ ...i, uniqueId: uniqueId() }));

        const combined = [...folders, ...files];
        return {
          folders,
          files,
          combined,
        };
      }
    })
    .catch((e) => messageContext.setError(getErrors(e)));
  return result || undefined;
};

const getFolderInformation = async (
  id: number | undefined,
  folderPath: string | undefined,
  client: IGraphQLClient,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  messageContext: any
) => {
  if (!id) return;

  const result = await client
    .performQuery(browse, {
      id,
      path: folderPath,
    })
    .then((resp) => {
      if (resp.hasData()) {
        return resp.data.browseFileSystemLocation.entries;
      }
      if (resp.hasError()) {
        resp.handleAllErrors(messageContext.setError);
      }
    });
  return result;
};

export const loadNodeAsync = async (
  data: { uniqueId: string },
  gridRef: MutableRefObject<TypeComputedProps | null>
) => {
  await new Promise((resolve) => {
    // If the grid unmounts, this is a no-op.
    if (!gridRef?.current) {
      // eslint-disable-next-line no-promise-executor-return
      return resolve(undefined);
    }

    const itemIndex = gridRef.current.getItemIndexById(data?.uniqueId);
    const node = gridRef.current.getItemAt(itemIndex);

    // This uses an undocumented property on the grid which properly updates the tree's cache:
    // https://github.com/inovua/reactdatagrid/blob/0d2ef4a2da776692bc586517abe32ea93b508c02/enterprise-edition/plugins/tree/useTreeColumn/index.tsx#L61
    // Solution: https://github.com/inovua/reactdatagrid/issues/168

    // @ts-expect-error: this does exist, but it's not documented
    gridRef.current.loadNodeAsync?.(node, resolve);
  });
};
