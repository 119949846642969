import { gql } from '../../../__generated__';
import SelectDefinition from './SelectDefinition';

export default {
  query: gql(`
  query getProjectsWithActiveSpecsForTimecards {
    projectsWithActiveSpecsForTimecards( order: {id: DESC}) {
      items {
        id
        displayName
      }
    }
  }
`),
  valueField: 'id',
  labelField: 'displayName',
} as SelectDefinition;
