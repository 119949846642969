import { gql } from '../../../__generated__';
import SelectDefinition from './SelectDefinition';

export default {
  query: gql(`query getProjects {
                projects( order: {id: ASC}) {
                      items {
                        id
                        displayName
                      }
                }
              }`),
  valueField: 'id',
  labelField: 'displayName',
} as SelectDefinition;
