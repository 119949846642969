import {
  ETOSelectField,
  ETOSelectFieldProps,
  MessageContext,
} from '@teto/react-component-library-v2';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import getErrors from '../../../api/graphQL/getErrors';
import { getGraphQLClient } from '../../../api/graphQL/graphQLClient';

interface SelectOption {
  description?: string;
  displayName?: string;
  id: number;
}

export interface JobInputFieldProps
  extends Partial<ETOSelectFieldProps<SelectOption>> {
  projectId?: number;
  externalQuery?: string;
}

const jobsQuery = `query specs ($projectId: Int!) {
  specs(where: {projectId: {eq: $projectId}, active: {eq:true}}, order: {displayName:ASC}) {
    items {
      displayName
      id
      active
      masterQueue {
        closingRelease
      }
    }
  }
}`;

const JobInputField = (props: JobInputFieldProps) => {
  const {
    disabled,
    error,
    externalQuery,
    handleChange,
    label,
    name,
    projectId,
    value,
    ...rest
  } = props;
  const messageContext = useContext(MessageContext);
  const { t } = useTranslation();

  const jobs = useQuery(
    ['jobs', projectId],
    () =>
      getGraphQLClient()
        .performQuery(
          externalQuery || jobsQuery,
          { projectId },
          (err) => messageContext.setError(getErrors(err)),
          (err) => {
            const errors = getErrors(err);
            messageContext.setError(errors);
          }
        )
        .then((d) => d.specs.items as unknown as SelectOption[]),
    {
      enabled: Boolean(projectId && projectId > 0),
      refetchOnWindowFocus: false,
    }
  );

  return (
    <ETOSelectField
      data-testid="nci-specId"
      disabled={disabled}
      error={error}
      handleChange={handleChange}
      inputLabelProps={{ shrink: true }}
      itemNameSelector={(item: SelectOption) => item.displayName ?? ''}
      items={(jobs.data as []) ?? []}
      itemValueSelector={(item: SelectOption) => item.id}
      label={label || t('entities:NonConformance.SpecId')}
      name={name || 'specId'}
      size="small"
      value={value ?? null}
      {...rest}
    />
  );
};

export default JobInputField;
