import { TypePaginationProps } from '@inovua/reactdatagrid-community/types';
import FilterAltOffOutlinedIcon from '@mui/icons-material/FilterAltOffOutlined';
import RefreshIcon from '@mui/icons-material/Refresh';
import SaveAltIcon from '@mui/icons-material/SaveAlt';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import UnfoldLessRoundedIcon from '@mui/icons-material/UnfoldLessRounded';
import UnfoldMoreRoundedIcon from '@mui/icons-material/UnfoldMoreRounded';
import { Box, Button, SxProps, Theme, Typography } from '@mui/material';
import { ETOIconButton } from '@teto/react-component-library-v2';
import React, { useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import GridRecordLimitPopup from './GridRecordLimitPopup';
import { GridProps } from './types/TetoGridGraphqlProps';
import SettingsContext from '../../contexts/SettingsContext';

interface DefaultPaginationProps {
  gridProps: GridProps;
  disableMobileCols?: boolean;
  pageProps: TypePaginationProps;
  clearAllFilters: () => void;
  onFilterClick: () => void;
  onSortClick: () => void;
  onConfigureClick: () => void;
  onExportClick: () => void;
  alignRefreshIconToRight?: boolean;
  hasExpandableRows?: boolean;
  collapseAll?: () => void;
  expandAll?: () => void;
}

const leftIconsContainerSx: SxProps<Theme> = {
  alignItems: 'center',
  columnGap: 1,
  display: 'flex',
  justifyContent: 'flex-start',
};

const leftTightSx: SxProps<Theme> = {
  alignItems: 'center',
  display: 'flex',
  justifyContent: 'flex-start',
};

const rightIconsContainerSx: SxProps<Theme> = {
  '& .MuiIconButton-root': {
    marginLeft: (theme) => theme.spacing(1),
  },
};

const iconSx: SxProps<Theme> = {
  '& .MuiSvgIcon-root': {
    fontSize: (theme) => `${theme.spacing(3)}`,
  },
};

const rootSx: SxProps<Theme> = {
  alignItems: 'center',
  borderTop: (theme) => `1px solid ${theme.palette.divider} !important`,
  display: 'flex',
  justifyContent: 'space-between',
  pl: 2,
  pr: 2,
};

const textSx: SxProps<Theme> = {
  color: 'text.primary',
};

const paddingButtonFix = { pr: 0, pl: 0 };

const DefaultPagination = (props: DefaultPaginationProps) => {
  const {
    clearAllFilters,
    gridProps: {
      filters,
      refreshDataSource,
      dataSource,
      sort,
      mandatoryFilters,
    },
    disableMobileCols,
    onFilterClick,
    onSortClick,
    onConfigureClick,
    onExportClick,
    pageProps: { totalCount },
    alignRefreshIconToRight,
  } = props;

  const { t } = useTranslation();
  const settingsContext = useContext(SettingsContext);

  const filter = useMemo(
    () =>
      filters?.filter((item) => {
        let isMandatoryFilter = false;
        if (Array.isArray(mandatoryFilters)) {
          isMandatoryFilter = mandatoryFilters?.some(
            (mf) => mf.name === item.name
          );
        }

        if (item.type === 'boolean') {
          return item.value === null && !isMandatoryFilter;
        }

        if (
          item.operator === 'inrange' &&
          (item.type === 'date' ||
            item.type === 'datetime' ||
            item.type === 'time' ||
            item.type === 'number' ||
            item.type === 'hours' ||
            item.type === 'currency')
        ) {
          if (typeof item.value === 'object' && item.value !== null) {
            return (
              !isMandatoryFilter &&
              (item.value?.start !== '' || item.value?.end !== '')
            );
          }
          return !isMandatoryFilter && item.value !== '';
        }

        return item.value !== '' && !isMandatoryFilter;
      }),
    [filters, mandatoryFilters]
  );

  const filterLength = useMemo(() => filter?.length || 0, [filter?.length]);

  const sortingLength = useMemo(() => {
    if (Array.isArray(sort)) {
      return sort.length;
    }
    if (!Array.isArray(sort)) {
      return 1;
    }
    return 0;
  }, [sort]);

  return (
    <Box sx={rootSx}>
      <Box sx={leftIconsContainerSx}>
        <Typography sx={textSx} variant="body2">
          {`${t('generic.records')}: ${totalCount}`}
        </Typography>
        {totalCount >= (settingsContext.settings?.gridMaxRows ?? 10000) && (
          <GridRecordLimitPopup />
        )}
        {filterLength > 0 && (
          <Button
            color="primary"
            onClick={onFilterClick}
            size="small"
            sx={paddingButtonFix}
            variant="text"
          >{`(${t('generic.filtered')}: ${filterLength})`}</Button>
        )}
        {sortingLength > 0 && (
          <Button
            color="primary"
            onClick={onSortClick}
            size="small"
            sx={paddingButtonFix}
            variant="text"
          >{`(${t('generic.sorted')}: ${sortingLength})`}</Button>
        )}
        {!disableMobileCols && (
          <Box
            sx={leftTightSx}
            visibility={props.hasExpandableRows ? 'visible' : 'hidden'}
          >
            <ETOIconButton
              color="primary"
              onClick={() => props.collapseAll?.()}
              size="large"
              tooltipProps={{
                title: t('generic.collapseAll'),
              }}
            >
              <UnfoldLessRoundedIcon />
            </ETOIconButton>
            <ETOIconButton
              color="primary"
              onClick={() => props.expandAll?.()}
              size="large"
              tooltipProps={{
                title: t('generic.expandAll'),
              }}
            >
              <UnfoldMoreRoundedIcon />
            </ETOIconButton>
          </Box>
        )}
      </Box>
      {!disableMobileCols && (
        <Box sx={rightIconsContainerSx}>
          {filterLength > 0 && (
            <ETOIconButton
              color="primary"
              customSx={iconSx}
              onClick={clearAllFilters}
              size="small"
              tooltipProps={{
                title: t('generic.clearFilter'),
              }}
            >
              <FilterAltOffOutlinedIcon />
            </ETOIconButton>
          )}
          <ETOIconButton
            color="primary"
            customSx={iconSx}
            onClick={refreshDataSource}
            size="small"
            tooltipProps={{
              title: t('generic.refresh'),
            }}
          >
            <RefreshIcon />
          </ETOIconButton>
          <>
            <ETOIconButton
              color="primary"
              customSx={iconSx}
              disabled={dataSource.length === 0}
              onClick={onExportClick}
              size="small"
              tooltipProps={{
                title: t('generic.export'),
              }}
            >
              <SaveAltIcon />
            </ETOIconButton>
            <ETOIconButton
              color="primary"
              customSx={iconSx}
              onClick={onConfigureClick}
              size="small"
              tooltipProps={{
                title: t('generic.configure'),
              }}
            >
              <SettingsOutlinedIcon />
            </ETOIconButton>
          </>
        </Box>
      )}
      {disableMobileCols && !alignRefreshIconToRight && (
        <Box sx={{ visibility: 'hidden' }} />
      )}
    </Box>
  );
};

export default DefaultPagination;
