import { isArray } from 'lodash';
import React from 'react';
import { TFunction } from 'react-i18next';
import TETODataField from './TETODataField';
import { TETOFieldSourceType } from './types/FieldSourceType';
// eslint-disable-next-line import/no-cycle
import { ElementType } from '../../../typescriptHelpers';
import FieldOptions from '../FieldOptions';
import FormDefinition from '../FormDefinition';
import SelectSourceType from '../SelectSourceType';
import {
  CustomValidatorType,
  LengthValidatorType,
  REGEXValidatorType,
  RangeValidatorType,
  RequiredValidatorType,
  ValidatorPrefixes,
} from '../clientSideValidators';
import FormFieldHints from './types/FormFieldHints';
import TETOCurrencyConfig from './types/TETOCurrencyConfig';
// eslint-disable-next-line import/no-cycle
import IFormBuilderExtension from './types/IFormBuilderExtension';
import SupportedFieldTypes from './types/SupportedFieldTypes';

/** *
 * FormBuilder is responsible for maintaining a form definition.
 *
 * If using within a React component you probably want useFormBuilder for convenience
 */
// eslint-disable-next-line @typescript-eslint/ban-types
class SubFormBuilder<FT = {}> {
  fields: TETODataField[] = [];

  removeField(fieldName: string): SubFormBuilder<FT> {
    let fIndex = -1;
    fIndex = this.fields.findIndex((a) => a.name === fieldName);
    if (fIndex < 0) return this;

    this.fields = [...this.fields.filter((a) => a.name !== fieldName)];

    return this;
  }

  private _addGenericField(col: AddField<FT>) {
    const existing = this.findField(col.name);
    if (existing) throw new Error(`Duplicate field ${col.name} exists`);

    if (col.dependsOn) {
      if (col.dependsOn.includes(col.name)) {
        throw new Error(`Field ${col.name} cannot depend on itself`);
      }
    }

    const newField: TETODataField = {
      disabled: col.disabled,
      name: col.name,
      type: col.type,
      render: col.render,
      hidden: col.hidden,
      fieldSource: col.fieldSource ?? 'custom',
      defaultValue: col.defaultValue !== undefined ? col.defaultValue : '',
      hints: col.hints ?? [],
      title: col.title,
      required: false,
      dependsOn: col.dependsOn,
      persist: col.persist ?? false,
      selectSource: col.selectSource,
      onFieldChange: col?.onFieldChange,
    };

    if (Object.prototype.hasOwnProperty.call(col, 'objectBuilder')) {
      const subBuilder = new SubFormBuilder<FT>();
      (col as AddObject<FT>)?.objectBuilder?.(subBuilder);
      newField.subFields = subBuilder.fields;
    }

    this.fields.push(newField);

    return newField;
  }

  addField(col: AddField<FT>): this {
    if (col.type === 'currency') {
      return this.addCurrency(col as AddCurrencyField);
    }

    const field = this._addGenericField(col);
    field.required =
      col.required ||
      Boolean(
        col.validators?.find((a) => (a as ValidatorPrefixes) === 'REQUIRED')
      );

    field.validators = col.validators;
    return this;
  }

  addCurrency(col: Omit<AddCurrencyField, 'type'>): this {
    const field = this._addGenericField({ ...col, type: 'currency' });
    field.currency = col.currency;

    return this;
  }

  addString(col: Omit<AddField<FT>, 'type'>): this {
    return this.addField({ ...col, type: 'string' });
  }

  addEmail(col: Omit<AddField<FT>, 'type'>): this {
    return this.addField({ ...col, type: 'email' });
  }

  addPassword(col: Omit<AddField<FT>, 'type'>): this {
    return this.addField({ ...col, type: 'password' });
  }

  addDate(col: Omit<AddField<FT>, 'type'>): this {
    return this.addField({ ...col, type: 'date' });
  }

  addDateTime(col: Omit<AddField<FT>, 'type'>): this {
    return this.addField({ ...col, type: 'datetime' });
  }

  addTime(col: Omit<AddField<FT>, 'type'>): this {
    return this.addField({ ...col, type: 'time' });
  }

  addInteger(col: Omit<AddField<FT>, 'type'>): this {
    return this.addField({ ...col, type: 'integer' });
  }

  addDecimal(col: Omit<AddField<unknown>, 'type'>): this {
    return this.addField({ ...col, type: 'decimal' });
  }

  addBoolean(col: Omit<AddField<unknown>, 'type'>): this {
    return this.addField({ ...col, type: 'boolean' });
  }

  addComputed(col: AddComputedField): this {
    const field = this._addGenericField(col);
    field.computedValue = col.compute;

    return this;
  }

  addObject(col: Omit<AddObject<FT>, 'type'>): this {
    return this.addField({ ...col, type: 'object' });
  }

  addList(col: Omit<AddList<FT>, 'type'>): this {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const adjCol = { ...col, type: 'list' as any };
    const field = this._addGenericField(adjCol);
    field.required = col.required ?? false;
    field.defaultValue =
      col.defaultValue !== undefined && isArray(col.defaultValue)
        ? col.defaultValue
        : [];
    field.validators = col.validators;
    field.subType = 'object';
    field.subType = col.itemType;

    if (col.objectBuilder) {
      const subBuilder = new SubFormBuilder<FT>();
      col.objectBuilder(subBuilder);
      field.subFields = subBuilder.fields;
    }

    return this;
  }

  addFieldValidators(
    fieldName: string,
    validators: (
      | string
      // eslint-disable-next-line no-unused-vars, @typescript-eslint/no-explicit-any
      | ((values: any) => string | undefined)
      // eslint-disable-next-line no-unused-vars, @typescript-eslint/no-explicit-any
      | ((values: any) => Promise<string | undefined>)
    )[]
  ) {
    const field = this.findField(fieldName);
    if (!field) throw new Error(`Field ${fieldName} does not exist`);

    field.validators = [...(field.validators ?? []), ...validators];

    return field;
  }

  updateField(
    fieldName: string,
    data: Partial<
      Omit<TETODataField, 'name' | 'type' | 'validators'> & {
        position?: number;
      }
    >
  ) {
    let fIndex = -1;

    fIndex = this.fields.findIndex((a) => a.name === fieldName);
    if (fIndex < 0) throw new Error(`Field ${fieldName} does not exist`);

    const field = { ...this.fields[fIndex] };

    if (data.dependsOn) {
      if (data.dependsOn.includes(field.name)) {
        throw new Error(`Field ${field.name} cannot depend on itself`);
      }
    }

    this.fields[fIndex] = {
      ...this.fields[fIndex],
      ...data,
      name: this.fields[fIndex].name,
      type: this.fields[fIndex].type,
    };

    return this;
  }

  findField(fieldName: string): TETODataField | undefined {
    const pieces = fieldName.split('.');
    if (pieces.length === 1)
      return this.fields.find((a) => a.name === pieces[0]);

    let { fields } = this;
    for (let i = 0; i < pieces.length; i += 1) {
      const p = pieces[i];
      const pName = p.split('[')[0];
      const field = fields.find((a) => a.name === pName);
      if (i === pieces.length - 1)
        // at the end
        return field;

      // we are not at the end of the path but
      // the node we are on has no more subnodes so will fail
      if (!field?.subFields) return undefined;

      fields = field?.subFields;
    }
  }

  validate() {
    const allFieldNames = this.fields.map((a) => a.name);

    this.fields.forEach((f) => {
      if (f.dependsOn && f.dependsOn.length > 0) {
        f.dependsOn.forEach((d) => {
          if (!allFieldNames.includes(d))
            throw new Error(
              `Field ${f.name} depends on a field that does not exist ${d}`
            );
        });
      }
    });
  }

  extension(
    extension: IFormBuilderExtension,
    t: TFunction<'translation', undefined>
  ) {
    extension.execute(this, t);
    return this;
  }
}

export default SubFormBuilder;

interface CommonField {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  defaultValue?: any;
  // eslint-disable-next-line no-unused-vars, @typescript-eslint/no-explicit-any
  disabled?: boolean | ((values: any) => boolean);
  dependsOn?: string[];
  fieldSource?: TETOFieldSourceType;
  // eslint-disable-next-line no-unused-vars, @typescript-eslint/no-explicit-any
  hidden?: boolean | ((values: any) => boolean);
  hints?: FormFieldHints[];
  name: string;
  title: string;
  persist?: boolean;
  type: ElementType<typeof SupportedFieldTypes>;
  selectSource?: SelectSourceType;
  render?: (
    // eslint-disable-next-line no-unused-vars
    form: FormDefinition,
    // eslint-disable-next-line no-unused-vars
    formField: TETODataField,
    // eslint-disable-next-line no-unused-vars
    fieldOptions: FieldOptions
  ) => React.ReactNode | undefined;
  // eslint-disable-next-line no-unused-vars
  onFieldChange?: (form: FormDefinition) => void;
}

type Validators =
  // eslint-disable-next-line no-unused-vars, @typescript-eslint/no-explicit-any
  | ((values: any) => string | undefined)
  // eslint-disable-next-line no-unused-vars, @typescript-eslint/no-explicit-any
  | ((values: any) => Promise<string | undefined>)
  | RangeValidatorType
  | RequiredValidatorType
  | LengthValidatorType
  | REGEXValidatorType
  | CustomValidatorType;

// eslint-disable-next-line @typescript-eslint/ban-types
type AddField<FT> =
  | AddStandardField
  | AddCurrencyField
  | AddList<FT>
  | AddObject<FT>;

interface AddStandardField extends CommonField {
  required?: boolean;
  validators?: Validators[];
}

interface AddCurrencyField extends CommonField {
  required?: boolean;
  objectBuilder?: never;
  currency:
    | TETOCurrencyConfig
    // eslint-disable-next-line no-unused-vars, @typescript-eslint/no-explicit-any
    | ((values: any) => TETOCurrencyConfig | undefined)
    | undefined;
  validators?: (
    | RangeValidatorType
    | RequiredValidatorType
    | LengthValidatorType
    | REGEXValidatorType
    | CustomValidatorType
  )[];
}

interface AddComputedField extends CommonField {
  // eslint-disable-next-line no-unused-vars, @typescript-eslint/no-explicit-any
  compute: (data: any) => any;
  objectBuilder?: never;
}

type AddList<FT> = (AddPrimitiveList | AddObjectList<FT>) &
  Omit<CommonField, 'type'>;

type AddPrimitiveList = {
  name: string;
  itemType: Omit<ElementType<typeof SupportedFieldTypes>, 'list'>;
  required?: boolean;
  type: 'list';
  validators?: (RequiredValidatorType | CustomValidatorType)[];
  objectBuilder?: never;
};

type AddObjectList<FT> = {
  name: string;
  required?: boolean;
  type: 'list';
  validators?: (RequiredValidatorType | CustomValidatorType)[];
  itemType?: never;
  // eslint-disable-next-line no-undef, no-unused-vars
  objectBuilder?: (subBuilder: SubFormBuilder<FT>) => void;
};

type AddObject<FT> = {
  name: string;
  required?: boolean;
  type: 'object';
  validators?: (RequiredValidatorType | CustomValidatorType)[];
  // eslint-disable-next-line no-undef, no-unused-vars
  objectBuilder?: (subBuilder: SubFormBuilder<FT>) => void;
} & Omit<CommonField, 'type'>;
