import { Skeleton, Typography } from '@mui/material';
import React, { forwardRef } from 'react';
import { MainTetoGrid } from './TETOGridGraphQL';
import TETOGridRefType from './types/TETOGridRefType';
import { TetoGridGraphqlProps } from './types/TetoGridGraphqlProps';

const heightFixSx = {
  transform: 'unset',
};

const MainTetoGridGraphQL = forwardRef<
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  any,
  TetoGridGraphqlProps & {
    children?: React.ReactNode;
  }
>(
  (
    props: TetoGridGraphqlProps,
    ref: React.Ref<TETOGridRefType> | undefined | null
  ) => {
    const { builderReady, error, gridProps, hasError, ready } = props;
    const { metaDataLoading } = gridProps; // this is the metadata loading

    if (hasError) {
      return <>{error && <Typography>{String(error)}</Typography>}</>;
    }
    return (
      <>
        {metaDataLoading || !ready || !builderReady ? (
          <Skeleton height="100%" sx={heightFixSx} width="100%" />
        ) : (
          <>
            <MainTetoGrid {...props} ref={ref} />
          </>
        )}
      </>
    );
  }
);

export default MainTetoGridGraphQL;
