import {
  ETOSelectField,
  ETOSelectFieldProps,
  MessageContext,
} from '@teto/react-component-library-v2';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import getErrors from '../../../api/graphQL/getErrors';
import { getGraphQLClient } from '../../../api/graphQL/graphQLClient';

interface SelectOption {
  description?: string;
  displayName?: string;
  id: number;
}

export interface ProjectInputFieldProps
  extends Partial<ETOSelectFieldProps<SelectOption>> {
  externalQuery?: string;
}

const projectsQuery = `query projects{
  projects(order: {id: ASC}) {
    items {
      displayName
      id
    }
  }
}`;

const ProjectInputField = (props: ProjectInputFieldProps) => {
  const {
    disabled,
    error,
    externalQuery,
    handleChange,
    label,
    name,
    value,
    ...rest
  } = props;
  const messageContext = useContext(MessageContext);
  const { t } = useTranslation();

  const projects = useQuery(
    ['projects'],
    () =>
      getGraphQLClient()
        .performQuery(
          externalQuery || projectsQuery,
          {},
          (err) => messageContext.setError(err.messages[0]),
          (err) => {
            const errors = getErrors(err);
            messageContext.setError(errors);
          }
        )
        .then((d) => d.projects.items as unknown as SelectOption[]),
    {
      enabled: true,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
    }
  );

  return (
    <ETOSelectField
      data-testid="nci-project"
      disabled={disabled}
      error={error}
      handleChange={handleChange}
      inputLabelProps={{ shrink: true }}
      itemNameSelector={(item: SelectOption) =>
        item.displayName ?? item.id.toString()
      }
      items={(projects.data as []) ?? []}
      itemValueSelector={(item: SelectOption) => item.id}
      label={label || t('entities:NonConformance.Project')}
      name={name || 'projectId'}
      size="small"
      value={value ?? null}
      {...rest}
    />
  );
};

export default ProjectInputField;
