export const zendeskUrl = 'https://help.totaleto.com/hc/';

/**
 * Supported Help Doc languages
 */
const HelpLanguage = {
  English: 'en-us',
};

const defaultLanguageCode = HelpLanguage.English;

/**
 * Get root Help Center link, such as `https://help.totaleto.com/hc/en-us`
 *
 * @param languageCode String language code.
 * @returns string
 */
export const getHelpCenterLink = (languageCode: string = defaultLanguageCode) =>
  `${zendeskUrl}/${languageCode}`;

/**
 * Get an article link, such as `https://help.totaleto.com/hc/en-us/articles/4416536975892`
 *
 * @param articleId Id of article. such as `4416536975892` for the "Using the Help Center" article
 * @param languageCode String language code.
 * @returns string
 */
export const getArticleLink = (
  articleId: string | number,
  languageCode: string = defaultLanguageCode
) => `${getHelpCenterLink(languageCode)}/articles/${articleId}`;

/**
 * Get an article header link, such as `https://help.totaleto.com/hc/en-us/articles/4416536975892#search-the-help-center-0-3`
 *
 * @param articleId Id of article. such as `4416536975892` for the "Using the Help Center" article
 * @param headerId id of header in article. such as `search-the-help-center-0-3` for the "Search the Help Center" header
 * @param languageCode String language code.
 * @returns String
 */
export const getArticleHeaderLink = (
  articleId: string | number,
  headerId?: string | number,
  languageCode: string = defaultLanguageCode
) => `${getArticleLink(articleId, languageCode)}#${headerId && headerId}`;

/**
 * Get an section link, such as `https://help.totaleto.com/hc/en-us/sections/10771839580052-Receiving-Guides`
 *
 * @param sectionId Id of section. such as `4416536975892` for the "Using the Help Center" section
 * @param languageCode String language code.
 * @returns string
 */
export const getSectionLink = (
  sectionId: string | number,
  languageCode: string = defaultLanguageCode
) => `${getHelpCenterLink(languageCode)}/sections/${sectionId}`;
