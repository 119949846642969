import React from 'react';

const handleInspectorBarcodeScan = <T,>(
  prefix: string,
  val: string,
  quantity: number,
  initialQuantityKey: string,
  // eslint-disable-next-line no-unused-vars
  setInitialPart: (part: React.SetStateAction<T>) => void
) => {
  const prefixToUpper = prefix.toUpperCase();
  switch (prefixToUpper) {
    case 'ITM':
      setInitialPart((prev: T) => ({
        ...prev,
        itemId: parseInt(val, 10),
        id: parseInt(val, 10),
        [initialQuantityKey]: quantity,
      }));

      return false;

    default:
      return false;
  }
};
export default handleInspectorBarcodeScan;
