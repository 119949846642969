const itemMasterUsageInformationQuery = ` query itemMasterUsageInformationQuery ($itemId: Int!) {
  itemMasterUsageInformation(itemId: $itemId) {
    itemId
    bOMInstances
    bOMChildren
    rawMaterialInstances
    projectId
    specId
    projectSpecBOMInstances
    projectSpecBOMChildren
    partsOrderInstances
   }
  } `;

export default itemMasterUsageInformationQuery;
