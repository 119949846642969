/* eslint-disable consistent-return */
/* eslint-disable no-unused-vars */
/* eslint-disable no-undef */
import { MessageContext } from '@teto/react-component-library-v2';
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { Permission, getGraphQLClient } from 'teto-client-api';
import {
  StartMyClockInMutation,
  StopMyClockInMutation,
} from '../__generated__/graphql';
import cancelMyClockIn from '../components/TimeSheets/NavTimer/queries/clockIn/cancelMyClockIn';
import getMyCurrentClockInStatus from '../components/TimeSheets/NavTimer/queries/clockIn/getMyCurrentClockInStatus';
import startMyClockIn from '../components/TimeSheets/NavTimer/queries/clockIn/startMyClockIn';
import stopMyClockIn from '../components/TimeSheets/NavTimer/queries/clockIn/stopMyClockIn';
import AuthContext from './AuthContext';

export type TimerStatus = 'active' | 'inactive' | 'error' | 'paused';

export interface ClockInTimerContextState {
  status: TimerStatus;
  refreshToken: Date;
  activeTimerId?: number | null | undefined;
  errorMessage?: string;
  duration?: number;
  durationFormatted?: string;
  startTime?: string | null;
  refreshTimer: () => void;
  // eslint-disable-next-line no-unused-vars
  startMyClockIn: () =>
    | Promise<StartMyClockInMutation | undefined>
    | Promise<void>;
  // eslint-disable-next-line no-unused-vars
  stopMyClockIn: () =>
    | Promise<StopMyClockInMutation | undefined>
    | Promise<void>;
}

const ClockInTimerContext = React.createContext<ClockInTimerContextState>(
  null as never
);

const POLL_INTERVAL = 30_000;

interface ClockInTimerContextProviderProps {
  children: React.ReactNode;
}

interface IMyCurrentClockInStatus {
  myCurrentClockInStatus: {
    employeeId: number | null;
    clockedIn: boolean;
    startTime: string | null;
    endTime: string | null;
    timeElapsedInMinutes: number | null;
  };
}

const ClockInTimerContextProvider = (
  props: ClockInTimerContextProviderProps
) => {
  const { t } = useTranslation();

  const { children } = props;

  const authContext = useContext(AuthContext);
  const messageContext = useContext(MessageContext);

  const [activeTimerId, setActiveTimerId] = useState<
    number | undefined | null
  >();
  const [errorMessage, setErrorMessage] = useState<string | undefined>(
    undefined
  );
  const [elapsedMinutes, setElapsedMinutes] = useState<number | undefined>(0);
  const [refreshToken, setRefreshToken] = useState<Date>(new Date());
  const [startTime, setStartTime] = useState<string | null>();
  const [timerStatus, setTimerStatus] = useState<TimerStatus>('inactive');

  const _updateClockInStatus = useCallback(() => {
    setErrorMessage(undefined);

    getGraphQLClient()
      .performQuery(getMyCurrentClockInStatus, {})
      .then((data) => {
        if (data.hasError()) {
          data.showAllSystemErrors(messageContext.setError);
          if (data.hasValidationErrors()) {
            messageContext.setError(
              data.validationErrors.myCurrentClockInStatus
            );
          }
          return;
        }
        if (data && data.data.myCurrentClockInStatus?.clockedIn) {
          setTimerStatus('active');
          setActiveTimerId(data.data.myCurrentClockInStatus.employeeId);
          setStartTime(data.data.myCurrentClockInStatus.startTime);
          return setElapsedMinutes(
            data.data.myCurrentClockInStatus.timeElapsedInMinutes ?? 0
          );
        }
        setTimerStatus('inactive');
        setElapsedMinutes(0);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (
      authContext.hasEnterpriseLicense() &&
      authContext.authenticated &&
      authContext.hasPermission(Permission.View_Time_Client_Clockins)
    ) {
      _updateClockInStatus();
      const pollTimer = setInterval(() => {
        _updateClockInStatus();
      }, POLL_INTERVAL);
      return () => clearInterval(pollTimer);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [_updateClockInStatus, authContext.authenticated]);

  const hours = Math.floor((elapsedMinutes ?? 0) / 60);
  const minutes = (elapsedMinutes ?? 0) % 60;
  const time = minutes < 10 ? `${hours}:0${minutes}` : `${hours}:${minutes}`;

  const formattedDuration = time === undefined ? '0:00' : String(time);

  const _startMyClockIn = useCallback(
    () =>
      getGraphQLClient()
        .performQuery(startMyClockIn, {})
        .then((data) => {
          if (data.hasError()) {
            data.showAllSystemErrors(messageContext.setError);
            if (data.hasValidationErrors()) {
              messageContext.setError(data.validationErrors.startMyClockIn);
            }
            return;
          }
          if (data.data.startMyClockIn) {
            setRefreshToken(new Date());
            setTimerStatus('active');
            messageContext.setSuccess(
              `${t('entities:ClockIns.ClockIn')} ${t('generic.success')}`
            );
            return data.data;
          }
        }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [t]
  );

  const _stopMyClockIn = useCallback(
    () =>
      getGraphQLClient()
        .performQuery(stopMyClockIn, {})
        .then((data) => {
          if (data.hasError()) {
            data.showAllSystemErrors(messageContext.setError);
            if (data.hasValidationErrors()) {
              messageContext.setError(data.validationErrors.stopMyClockIn);
            }
            return;
          }
          if (data.data.stopMyClockIn) {
            setRefreshToken(new Date());
            setTimerStatus('inactive');
            setElapsedMinutes(0);
            messageContext.setSuccess(
              `${t('entities:ClockIns.ClockOut')} ${t('generic.success')}`
            );
            return data.data;
          }
        }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [t]
  );

  const timerValues = useMemo(
    () => ({
      status: timerStatus,
      duration: elapsedMinutes,
      durationFormatted: formattedDuration,
      errorMessage,
      refreshTimer: _updateClockInStatus,
      activeTimerId,
      refreshToken,
      startMyClockIn: _startMyClockIn,
      stopMyClockIn: _stopMyClockIn,
      startTime,
    }),
    [
      _startMyClockIn,
      _stopMyClockIn,
      _updateClockInStatus,
      activeTimerId,
      elapsedMinutes,
      errorMessage,
      formattedDuration,
      refreshToken,
      startTime,
      timerStatus,
    ]
  );

  return (
    <ClockInTimerContext.Provider value={timerValues}>
      {children}
    </ClockInTimerContext.Provider>
  );
};

export const ClockInTimerProvider = ClockInTimerContextProvider;
export const ClockInTimerConsumer = ClockInTimerContext.Consumer;
export default ClockInTimerContext;
