import { Button, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';

const SAMLForm: React.FC = () => {
  const [error, setError] = useState<string>('');
  useEffect(() => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const samlError = urlSearchParams.get('samlError');

    if (!samlError) {
      window.location.href = '/SamlAuth/Login';
    } else {
      setError(samlError);
    }
  }, []);

  return (
    <>
      <Typography align="center">
        {error
          ? 'Failed to connect to SAML provider'
          : 'Connecting to SAML provider'}
      </Typography>
      <Typography align="center" color="error">
        {error}
      </Typography>
      <br />
      <br />
      {error && (
        <Button
          color="error"
          onClick={() => {
            window.location.href = '/SamlAuth/Login';
          }}
          variant="contained"
        >
          Retry
        </Button>
      )}
    </>
  );
};

export default SAMLForm;
