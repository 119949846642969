import {
  TypeRowProps,
  TypeRowSelection,
} from '@inovua/reactdatagrid-community/types';
import {
  TypeComputedProps,
  TypeOnSelectionChangeArg,
} from '@inovua/reactdatagrid-community/types/TypeDataGridProps';
import ReactDataGrid from '@inovua/reactdatagrid-enterprise';
import '@inovua/reactdatagrid-enterprise/index.css';
import NoteAddRoundedIcon from '@mui/icons-material/NoteAddRounded';
import { Box, IconButton, useTheme } from '@mui/material';
import { MessageContext } from '@teto/react-component-library-v2';
import { uniqueId } from 'lodash';
import React, {
  MutableRefObject,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { getGraphQLClient } from 'teto-client-api';
import useGQLQuery from '../../../api/graphQL/useGQLQuery';
import useCurrentTheme from '../../../hooks/useCurrentTheme';
import { reactDataGridLicenseKey } from '../../TetoGrid/Licensing';
import { loadNodeAsync, loadNodes } from '../helperFunctions';
import { myFsl } from '../queries';
import {
  DataSourceType,
  FilePickerGridProps,
  FileType,
  TypeExpandedNodes,
} from '../types';
import FileTypeIcon from './FileTypeIcon';
import FolderTypeIcon from './FolderTypeIcon';

const columnSx = { display: 'flex', alignItems: 'end', pl: 0.2 };
const gridSx = { zIndex: 1 };

const columns = [
  {
    name: 'name',
    header: 'Folder',
    type: 'string',
    defaultFlex: 1,
    render: (col: { value: string | undefined }) => (
      <>
        <Box sx={columnSx}>
          <FolderTypeIcon />
          {col.value}
        </Box>
      </>
    ),
  },
];

const filterValue = [
  { name: 'name', operator: 'contains', type: 'string', value: '' },
];

const FilePickerGrid = (props: FilePickerGridProps) => {
  const { onPick } = props;

  const [expandedNodes, setExpandedNodes] = useState<TypeExpandedNodes>();
  const [dataSourceFirstGrid, setDataSourceFirstGrid] =
    useState<DataSourceType>([]);
  const [dataSourceSecondGrid, setDataSourceSecondGrid] =
    useState<DataSourceType>([]);
  const [selected, setSelected] = useState<TypeRowSelection>(null);
  const [selectedSecondGrid, setSelectedSecondGrid] =
    useState<TypeRowSelection>(null);
  const [currentRootFolder, setCurrentRootFolder] = useState<
    number | undefined
  >(undefined);
  const [file, setFile] = useState<FileType | undefined>();
  const [callOnPick, setCallOnPick] = useState(false);
  const [gridRef, setGridRef] = useState<
    MutableRefObject<TypeComputedProps | null>
  >(null as unknown as MutableRefObject<TypeComputedProps | null>);

  const messageContext = useContext(MessageContext);
  const client = getGraphQLClient();
  const currentTheme = useCurrentTheme();
  const theme = useTheme();
  const { t } = useTranslation();

  const columnsSecondGrid = useMemo(
    () => [
      {
        name: 'entryType',
        header: '',
        width: 42,
        showColumnMenuTool: false,
        render: (col: { data: FileType; value: string | undefined }) => (
          <Box sx={columnSx}>
            {col.data?.entryType === 'FOLDER' ? (
              <FolderTypeIcon />
            ) : (
              <FileTypeIcon />
            )}
          </Box>
        ),
      },
      {
        name: 'name',
        header: t('generic.name'),
        defaultFlex: 1,
        type: 'string',
      },
      {
        name: 'grid.teto.action',
        header: t('generic.action'),
        width: 85,
        textAlign: 'center' as const,
        showColumnMenuTool: false,
        render: (col: { data: FileType }) => (
          <>
            {col.data.entryType !== 'FOLDER' && (
              <IconButton
                onClick={() => {
                  setCallOnPick(true);
                }}
              >
                <NoteAddRoundedIcon color="primary" fontSize="medium" />
              </IconButton>
            )}
          </>
        ),
      },
    ],
    [t]
  );

  const onExpandedNodesChange = useCallback(
    async (expandedProps: {
      expandedNodes: TypeExpandedNodes;
      data: FileType;
    }) => {
      const { expandedNodes: _expandedNodes, data } = expandedProps;
      setExpandedNodes(_expandedNodes);
      await loadNodeAsync(data as { uniqueId: string }, gridRef);
    },
    [gridRef]
  );

  const onSelectionChange = useCallback(
    async (selectedProps: TypeOnSelectionChangeArg) => {
      const { selected: _selected, data } = selectedProps;

      setSelected(_selected);
      setExpandedNodes((prev) => ({
        ...prev,
        [_selected as string]: true,
      }));
      await loadNodeAsync(data as { uniqueId: string }, gridRef);
    },
    [gridRef]
  );

  const onSelectionChangeSecondGrid = useCallback(
    // eslint-disable-next-line no-shadow
    async (props: TypeOnSelectionChangeArg) => {
      setSelectedSecondGrid(props.selected);
      if ((props.data as { entryType: string })?.entryType === 'FOLDER') {
        const foundItemInFirstGrid = gridRef?.current?.data?.find(
          (item: FileType) => item.id === (props?.data as { id: string })?.id
        );

        if (!foundItemInFirstGrid) return;

        await loadNodeAsync(foundItemInFirstGrid, gridRef);
        setSelected(`${selected}/${foundItemInFirstGrid.uniqueId}`);
      }
    },
    [gridRef, selected]
  );

  const onRowClickSecondGrid = useCallback(
    // eslint-disable-next-line no-shadow
    async (props: TypeRowProps) => {
      if (props.data.entryType === 'FILE') {
        setFile(props.data);
        return;
      }
      const foundItemInFirstGrid = gridRef?.current?.data?.find(
        (item: FileType) => item.id === (props?.data as { id: string })?.id
      );

      if (!foundItemInFirstGrid) return;

      await loadNodeAsync(foundItemInFirstGrid, gridRef);

      setExpandedNodes((prev) => ({
        ...prev,
        [`${foundItemInFirstGrid?.uniqueId}`]: true,
      }));
    },
    [gridRef]
  );
  const onExpandedNodesSecondGridChange = useCallback(
    (expandedProps: { expandedNodes: TypeExpandedNodes; data: FileType }) => {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      onRowClickSecondGrid(expandedProps as any);
    },
    [onRowClickSecondGrid]
  );
  useGQLQuery(['filePicker'], {
    queryString: myFsl,
    callback: async (data) => {
      const initialMappedFolders = data.myFileSystemLocations.items.map(
        async (item: { id: number; path: string }) => ({
          ...item,
          nodes: null,
          uniqueId: uniqueId(),
        })
      );
      const res = await Promise.all(initialMappedFolders);

      setDataSourceFirstGrid(res);
    },
  });

  const renderNodeTool = (domProps: {
    style: { color: string; fill: string };
  }) => {
    // eslint-disable-next-line no-param-reassign
    domProps.style.color = `${theme.palette.primary.main}`;
    // eslint-disable-next-line no-param-reassign
    domProps.style.fill = `${theme.palette.primary.main}`;
  };

  useEffect(() => {
    if (callOnPick && file) {
      onPick(file);
      setCallOnPick(false);
    }
  }, [callOnPick, file, onPick]);

  const loadNode = async ({ node }: { node: Partial<FileType> }) => {
    if (typeof node.id === 'number') {
      setCurrentRootFolder(node.id);
      const result = await loadNodes(
        node.id as number,
        undefined,
        client,
        messageContext
      );
      setDataSourceSecondGrid(result?.combined ?? []);
      return result?.folders ?? [];
    }
    const newNestedFolder = await loadNodes(
      currentRootFolder,
      node.id, // the id becomes the path
      client,
      messageContext
    );
    setDataSourceSecondGrid(newNestedFolder?.combined ?? []);
    return newNestedFolder?.folders ?? [];
  };

  return (
    <>
      <ReactDataGrid
        columns={columns}
        dataSource={dataSourceFirstGrid}
        defaultFilterValue={filterValue}
        expandedNodes={expandedNodes}
        idProperty="uniqueId"
        licenseKey={reactDataGridLicenseKey}
        loadNode={loadNode}
        onExpandedNodesChange={onExpandedNodesChange}
        onReady={setGridRef}
        onSelectionChange={onSelectionChange}
        // @ts-expect-error: this exists?
        renderNodeTool={renderNodeTool}
        selected={selected}
        shareSpaceOnResize
        style={gridSx}
        theme={currentTheme}
        treeColumn="name"
      />
      <ReactDataGrid
        columns={columnsSecondGrid}
        dataSource={dataSourceSecondGrid}
        defaultFilterValue={filterValue}
        licenseKey={reactDataGridLicenseKey}
        onExpandedNodesChange={onExpandedNodesSecondGridChange}
        onRowClick={onRowClickSecondGrid}
        onSelectionChange={onSelectionChangeSecondGrid}
        // @ts-expect-error: this exists?
        renderNodeTool={renderNodeTool}
        selected={selectedSecondGrid}
        shareSpaceOnResize
        theme={currentTheme}
        treeColumn="name"
      />
    </>
  );
};

export default FilePickerGrid;
