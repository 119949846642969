import { useEffect, useMemo, useRef, useState } from 'react';
import FormBuilder from '../FormBuilder/FormBuilder';
import IHandlers from '../FormBuilder/types/IHandlers';

interface Status {
  builderReady: boolean;
  error?: string;
  hasError: boolean;
}

export interface FormBuilderState<FT> extends Status {
  formBuilder: FormBuilder<FT>;
}

/**
 * The useFormBuilder hook allows for creation/maintaing a form builder in a more react friendly manner. It will ensure
 * the form builder stays immutable and is not re-rendered on every parent component render.
 *
 * @param builder Function to be called to initialize the builder.  This can be synchronous and return void
 * or asynchronous and return a promise.  Once the builder function has been called the form builder
 * hook will be marked as ready and dependant hooks can proceed to use the definition.
 *
 * @param handlers Handlers let you inject logic within the form pipeline and respond to user
 * events that occur.  Please note handlers on a form builder exist solely for convenience and can
 * be overridden within the useForm hook.
 */
// eslint-disable-next-line @typescript-eslint/ban-types
export const useFormBuilder = <FT,>(
  name: string,
  builder?: // eslint-disable-next-line no-unused-vars
  | ((formBuilder: FormBuilder<FT>) => void)
    // eslint-disable-next-line no-unused-vars
    | ((formBuilder: FormBuilder<FT>) => Promise<void>),
  handlers?: IHandlers<FT>
): FormBuilderState<FT> => {
  const formBuilderFactoryRef = useRef<FormBuilder<FT>>(
    new FormBuilder<FT>(name, handlers)
  );

  const [status, setStatus] = useState<Status>({
    builderReady: false,
    hasError: false,
  });

  useEffect(() => {
    if (!builder || status.builderReady) return;

    formBuilderFactoryRef.current = new FormBuilder<FT>(name, handlers);

    const possiblyAPromise = builder(formBuilderFactoryRef.current);

    if (possiblyAPromise instanceof Promise) {
      possiblyAPromise
        .then(() => {
          try {
            formBuilderFactoryRef.current.validate();
            setStatus({
              builderReady: true,
              hasError: false,
              error: undefined,
            });
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
          } catch (err: any) {
            setStatus({
              builderReady: true,
              hasError: true,
              error: err?.message ? err.message : err,
            });
          }
        })
        .catch((e) => {
          setStatus({
            builderReady: true,
            hasError: true,
            error: e?.message ? e.message : e,
          });
        });
    } else {
      try {
        formBuilderFactoryRef.current.validate();
        setStatus({
          builderReady: true,
          hasError: false,
          error: undefined,
        });
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (err: any) {
        setStatus({
          builderReady: true,
          hasError: true,
          error: err?.message ? err.message : err,
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status.builderReady]);

  const result = useMemo(
    () => ({
      formBuilder: formBuilderFactoryRef.current,
      ...status,
    }),
    [status]
  );

  return { ...result } as const;
};
