import { MessageContext } from '@teto/react-component-library-v2';

import React, { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import SharedBreadCrumbModal from '../../components/SharedStateComponents/SharedBreadCrumbModal';
import { useHandleSharedState } from '../../components/SharedStateComponents/SharedStateLink';

const LinkDetailsPage = () => {
  const params = useParams();
  const messageContext = useContext(MessageContext);
  const { t } = useTranslation();

  const id = Number(params.id?.slice(1));
  const inspectorKey = params.inspectorKey?.slice(1);

  const loadPage = useHandleSharedState(inspectorKey, id);

  useEffect(() => {
    if (loadPage) {
      loadPage();
    } else {
      messageContext.setError(
        t('generic.message.error', {
          message: `${t('generic.loading')} ${t('generic.page')}`,
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadPage, t]);

  return <SharedBreadCrumbModal />;
};

export default LinkDetailsPage;
