import LooksThreeRoundedIcon from '@mui/icons-material/Looks3Rounded';
import LooksFourRoundedIcon from '@mui/icons-material/Looks4Rounded';
import LooksFiveRoundedIcon from '@mui/icons-material/Looks5Rounded';
import LooksSixRoundedIcon from '@mui/icons-material/Looks6Rounded';
import LooksOneRoundedIcon from '@mui/icons-material/LooksOneRounded';
import LooksTwoRoundedIcon from '@mui/icons-material/LooksTwoRounded';
import React from 'react';

interface NotesIconProps {
  numberOfNotes: number;
  error?: boolean;
}

const NotesIcon = (props: NotesIconProps) => {
  const { numberOfNotes, error } = props;
  const commonProps = {
    color: error ? ('error' as const) : ('primary' as const),
    sx: {
      p: 0,
    },
    size: 'small',
  };
  switch (numberOfNotes) {
    case 0:
      return null;
    case 1:
      return <LooksOneRoundedIcon {...commonProps} />;
    case 2:
      return <LooksTwoRoundedIcon {...commonProps} />;
    case 3:
      return <LooksThreeRoundedIcon {...commonProps} />;
    case 4:
      return <LooksFourRoundedIcon {...commonProps} />;
    case 5:
      return <LooksFiveRoundedIcon {...commonProps} />;
    default:
      return (
        <>
          <LooksSixRoundedIcon {...commonProps} />
          {numberOfNotes > 6 && '+ '}
        </>
      );
  }
};

export default NotesIcon;
