import { gql } from '../../../../__generated__';

export default gql(`query getSalesRelease($where: MasterQueueFilterInput) {
  masterQueue(where: $where) {
    items {
      closingRelease
      closingReleaseDate
      salesReleaseDate
    }
  }
}`);
