import { gql } from '../../../../__generated__';

const getLastRFQQuery = gql(`query getLastRFQ {
  rFQHeaders(order: { id: DESC }, take: 1) {
    items {
      id
    }
  }
}
`);

export default getLastRFQQuery;
