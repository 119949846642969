import { Box, Checkbox, FormControlLabel, Grid } from '@mui/material';
import {
  ButtonStrip,
  ConfirmDialogs,
  ETOSelectField,
  ETOTextField,
  MessageContext,
  OkCancelConfirmDialog,
} from '@teto/react-component-library-v2';
import { FormikHelpers, useFormik } from 'formik';
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import {
  Employee,
  Permission,
  getGraphQLClient as getNewGraphQLClient,
} from 'teto-client-api';
import * as Yup from 'yup';
import {
  AddNonConformanceAssignmentMutation,
  UpdateNonConformanceAssignmentMutation,
} from '../../../../../__generated__/graphql';
import { employeesQuery } from '../../../../../api/commonQueries';
import { getGraphQLClient } from '../../../../../api/graphQL/graphQLClient';

import AuthContext from '../../../../../contexts/AuthContext';
import AutoSave from '../../../components/AddEditInspector/AutoSave/AutoSave';
import { NonConformanceAssignment } from '../../interfaces/NonConformanceAssignment';
import { contentSx, rootSx } from '../components/commonPanelStyles';
import { addNonConformanceAssignmentMutation } from './queries/addNonConformanceTaskMutation';
import { deleteTaskAssignmentMutation } from './queries/deleteTaskAssignmentMutation';
import { nonConformanceTasksQuery } from './queries/taskAssignmentQueries';
import { updateNonConformanceAssignmentMutation } from './queries/updateNonConformanceAssignmentMutation';

const completeBoxSx = { pt: '0 !important', pb: 1 };

const maxChar = 100;
const REQUIRED_MESSAGE = 'Required';

const TasksAssignmentValidationSchema = Yup.object().shape({
  assignedToEmployeeId: Yup.number().required(REQUIRED_MESSAGE),
  assignedByEmployeeId: Yup.number().required(REQUIRED_MESSAGE),
  task: Yup.string().required(REQUIRED_MESSAGE).max(100),
  actionTaken: Yup.string().max(100),
  note: Yup.string(),
  complete: Yup.boolean(),
});

interface AddEditTaskProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  record?: any;
  nonConformanceId: number;
  onSaveSuccess: () => void;
  onRecordCountChanged: () => void;
  // eslint-disable-next-line no-unused-vars
  handleEditingState: (value: { isValid: boolean; isEditing: boolean }) => void;
  confirmAbandonForm: boolean;
  setConfirmAbandonForm: React.Dispatch<React.SetStateAction<boolean>>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setSelectedTaskItem: React.Dispatch<any>;
  setIsNewTaskOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const AddEditTask = (props: AddEditTaskProps) => {
  const {
    confirmAbandonForm,
    nonConformanceId,
    onRecordCountChanged,
    onSaveSuccess,
    record,
    setConfirmAbandonForm,
    setIsNewTaskOpen,
    handleEditingState,
    setSelectedTaskItem,
  } = props;

  const { t } = useTranslation();

  const authContext = useContext(AuthContext);
  const messageContext = useContext(MessageContext);

  const mode = record ? 'edit' : 'add';

  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState<boolean>(false);

  const employees = useQuery(
    ['employees'],
    () =>
      getGraphQLClient()
        .performQuery(
          employeesQuery,
          {},
          (err) => {
            messageContext.setError(err.messages[0]);
          },
          (_err) => {
            messageContext.setError(_err.employees);
          }
        )
        .then((e) => e.employees.items),
    {
      enabled: true,
      refetchOnWindowFocus: false,
    }
  );

  const nonConformanceTasks = useQuery(
    ['nonConformanceTasks'],
    () =>
      getNewGraphQLClient()
        .performQuery(nonConformanceTasksQuery, {})
        .then((e) => {
          if (e.hasError()) {
            e.showAllSystemErrors(messageContext.setError);
            if (e.hasValidationErrors()) {
              messageContext.setError(e.validationErrors.nonConformanceTasks);
            }
            return;
          }
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          return (e.data.nonConformanceTasks?.items as any).map(
            (i: { name: string }) => i.name
          );
        }),
    {
      refetchOnWindowFocus: false,
    }
  );
  const formik = useFormik<NonConformanceAssignment>({
    enableReinitialize: true,
    validationSchema: TasksAssignmentValidationSchema,
    initialValues: {
      id: record?.id ?? undefined,
      actionTaken: record?.actionTaken ?? '',
      assignedByEmployeeId: record?.assignedByEmployeeId ?? undefined,
      assignedToEmployeeId: record?.assignedToEmployeeId ?? undefined,
      complete: record?.complete ?? false,
      nonConformanceId: nonConformanceId ?? 0,
      note: record?.note ?? '',
      task: record?.task ?? undefined,
    },
    onSubmit: (values, actions) => {
      _submitForm(values, actions);
    },
  });

  const mutationParams = useMemo(
    () =>
      mode === 'add'
        ? {
            input: {
              actionTaken: formik.values.actionTaken,
              assignedByEmployeeId: formik.values.assignedByEmployeeId,
              assignedToEmployeeId: formik.values.assignedToEmployeeId,
              complete: formik.values.complete,
              nonConformanceId,
              note: formik.values.note,
              task: formik.values.task,
            },
          }
        : {
            input: {
              id: formik.values.id,
              actionTaken: formik.values.actionTaken,
              assignedByEmployeeId: formik.values.assignedByEmployeeId,
              assignedToEmployeeId: formik.values.assignedToEmployeeId,
              complete: formik.values.complete,
              nonConformanceId,
              note: formik.values.note,
              task: formik.values.task,
            },
          },
    [
      formik.values.actionTaken,
      formik.values.assignedByEmployeeId,
      formik.values.assignedToEmployeeId,
      formik.values.complete,
      formik.values.id,
      formik.values.note,
      formik.values.task,
      mode,
      nonConformanceId,
    ]
  );

  const _submitForm = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (
      values: NonConformanceAssignment,
      actions: FormikHelpers<NonConformanceAssignment>
    ) => {
      actions.setSubmitting(true);
      const res =
        mode === 'edit'
          ? getNewGraphQLClient().performMutation(
              updateNonConformanceAssignmentMutation,
              {
                input: {
                  id: formik.values.id,
                  actionTaken: formik.values.actionTaken,
                  assignedByEmployeeId: formik.values.assignedByEmployeeId,
                  assignedToEmployeeId: formik.values.assignedToEmployeeId,
                  complete: formik.values.complete,
                  nonConformanceId,
                  note: formik.values.note,
                  task: formik.values.task,
                },
              }
            )
          : getNewGraphQLClient().performMutation(
              addNonConformanceAssignmentMutation,
              {
                input: {
                  actionTaken: formik.values.actionTaken,
                  assignedByEmployeeId: formik.values.assignedByEmployeeId,
                  assignedToEmployeeId: formik.values.assignedToEmployeeId,
                  complete: formik.values.complete,
                  nonConformanceId,
                  note: formik.values.note,
                  task: formik.values.task,
                },
              }
            );

      res
        .then((e) => {
          if (e.hasError()) {
            e.showAllSystemErrors(messageContext.setError);
            if (e.hasValidationErrors()) {
              const { input } = e.validationErrors;
              const fieldErr = Object.values(input)[0] as string;
              messageContext.setError(fieldErr);
            }
            return;
          }
          if (mode === 'edit') {
            if (
              (e.data as UpdateNonConformanceAssignmentMutation)
                .updateNonConformanceAssignment?.success
            ) {
              onSaveSuccess();
              setSelectedTaskItem(values);
            }
          } else if (
            (e.data as AddNonConformanceAssignmentMutation)
              .addNonConformanceAssignment?.success
          ) {
            messageContext.setSuccess(
              t('generic.createdSuccess', { record: t('generic.task') })
            );
            actions.resetForm();
            onSaveSuccess();
            onRecordCountChanged();
          }
        })
        .finally(() => actions.setSubmitting(false));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [mode, mutationParams, formik, onRecordCountChanged, onSaveSuccess]
  );

  const canModifyTasks = useMemo(
    () =>
      authContext.hasPermission(
        Permission.Modify_Manufacturing_NonConformances_TaskAssignments
      ),
    [authContext]
  );

  const canDeleteTasks = useMemo(
    () =>
      authContext.hasPermission(
        Permission.Delete_Manufacturing_NonConformances_TaskAssignments
      ),
    [authContext]
  );

  const _onDeleteClicked = () => {
    getGraphQLClient()
      .performMutation(
        deleteTaskAssignmentMutation,
        {
          id: parseInt(record?.id, 10),
        },
        (err) => messageContext.setError(err.messages[0]),
        (err) => {
          const { input } = err;
          const fieldErr = Object.values(input)[0] as string;
          messageContext.setError(fieldErr);
        }
      )
      .then((e) => {
        if (e.deleteNonConformanceAssignment.success) {
          messageContext.setSuccess(
            t('generic.deletedSuccess', { record: t('generic.task') })
          );
          setIsDeleteDialogOpen(false);
          onRecordCountChanged();
        }
      });
  };

  useEffect(() => {
    handleEditingState({
      isValid: formik.isValid,
      isEditing: formik.dirty,
      // isEditing: formik.dirty, // cant use formik dirty because the when the form is expanded the initial values are never reset so the form is always dirty after one change
    });
  }, [formik.dirty, formik.isValid, handleEditingState]);

  return (
    <Box sx={rootSx}>
      {mode === 'edit' && <AutoSave debounceMs={1500} formik={formik} />}

      <Box sx={contentSx}>
        <Box>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Box m={0} mb={0}>
                <ETOSelectField
                  disabled={!canModifyTasks}
                  error={formik.errors.assignedByEmployeeId}
                  handleChange={formik.handleChange}
                  itemDisabledSelector={(item: Partial<Employee>) =>
                    !item.active
                  }
                  itemNameSelector={(item: Partial<Employee>) =>
                    `${!item.active ? '(inactive) ' : ''}${
                      item.employeeNumber
                    } - ${item.firstName} ${item.lastName}`
                  }
                  items={employees.data ?? []}
                  itemValueSelector={(item: Partial<Employee>) => item.id}
                  label={t('pages.nonConformance.assignedBy')}
                  name="assignedByEmployeeId"
                  size="small"
                  value={formik.values.assignedByEmployeeId ?? null}
                />
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Box m={0} mb={0}>
                <ETOSelectField
                  disabled={!canModifyTasks}
                  error={formik.errors.assignedToEmployeeId}
                  handleChange={formik.handleChange}
                  isOptionEqualToValue={(opt, val) => opt.id === val.id}
                  itemDisabledSelector={(item: Partial<Employee>) =>
                    !item.active
                  }
                  itemNameSelector={(item: Partial<Employee>) =>
                    `${!item.active ? '(inactive) ' : ''}${
                      item.employeeNumber
                    } - ${item.firstName} ${item.lastName}`
                  }
                  items={employees.data ?? []}
                  itemValueSelector={(item: Partial<Employee>) => item.id}
                  label={t('pages.nonConformance.assignedTo')}
                  name="assignedToEmployeeId"
                  size="small"
                  value={formik.values?.assignedToEmployeeId ?? null}
                />
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Box m={0} mb={0}>
                <ETOSelectField
                  disabled={!canModifyTasks}
                  error={formik.errors.task}
                  freeSolo
                  handleChange={(e) => formik.handleChange(e)}
                  helperText={
                    formik.dirty &&
                    formik.values.task !== formik.initialValues.task
                      ? `${formik.values.task?.length?.toString()} \/ ${maxChar}`
                      : undefined
                  }
                  itemNameSelector={(item: string) => item}
                  items={nonConformanceTasks.data ?? []}
                  itemValueSelector={(item: string) => item}
                  label={t('generic.task')}
                  name="task"
                  onInputChange={(event, value) =>
                    formik.setFieldValue('task', value)
                  }
                  size="small"
                  value={formik.values?.task ?? null}
                />
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Box m={0} mb={0} sx={{ maxHeight: 80, overFlow: 'scroll' }}>
                <ETOTextField
                  disabled={!canModifyTasks}
                  error={formik.errors.note}
                  handleChange={formik.handleChange}
                  label={t('generic.note')}
                  multiline
                  name="note"
                  size="small"
                  sxProps={{
                    '& textarea': {
                      maxHeight: 69,
                    },
                  }}
                  value={formik.values.note}
                  variant="outlined"
                />
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Box m={0} mb={0}>
                <ETOTextField
                  disabled={!canModifyTasks}
                  error={formik.errors.actionTaken}
                  handleChange={formik.handleChange}
                  helperText={`${formik.values.actionTaken?.length.toString()} \/ ${maxChar}`}
                  label={t('pages.nonConformance.actionTaken')}
                  multiline
                  name="actionTaken"
                  size="small"
                  value={formik.values.actionTaken}
                  variant="outlined"
                />
              </Box>
            </Grid>

            <Grid item sx={completeBoxSx} xs={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={formik.values.complete}
                    name="complete"
                    onChange={formik.handleChange}
                  />
                }
                disabled={!canModifyTasks}
                label={t('generic.complete')}
              />
            </Grid>
          </Grid>
        </Box>
        <Box>
          {mode === 'edit' && (
            <ButtonStrip
              rightButton={{
                text: t('generic.delete'),
                color: 'error',
                onClick: () => setIsDeleteDialogOpen(true),
                disabled: !canDeleteTasks,
              }}
              size="medium"
            />
          )}
          {mode === 'add' && (
            <ButtonStrip
              leftButton={{
                text: t('generic.reset'),
                onClick: () => formik.resetForm(),
                color: 'secondary',
              }}
              rightButton={{
                text: t('generic.add'),
                onClick: () => formik.submitForm(),
                color: 'primary',
              }}
              size="medium"
            />
          )}
        </Box>
      </Box>
      <ConfirmDialogs
        content={t('dialogs.deleteGenericItem.content')}
        leftButton={{
          onClick: () => setIsDeleteDialogOpen(false),
          label: t('generic.no'),
        }}
        open={isDeleteDialogOpen}
        rightButton={{
          onClick: () => _onDeleteClicked(),
          label: t('generic.yes'),
        }}
        title={t('dialogs.deleteGenericItem.title')}
      />
      {confirmAbandonForm && (
        <OkCancelConfirmDialog
          content={t('dialogs.closeUnsavedForm.content')}
          onCancel={() => {
            setConfirmAbandonForm(false);
          }}
          onOk={() => {
            setConfirmAbandonForm(false);
            if (mode === 'add') {
              formik.resetForm();
              setIsNewTaskOpen(false);
            }
            setSelectedTaskItem(undefined);
          }}
          open={confirmAbandonForm}
          title={t('dialogs.closeUnsavedForm.title')}
        />
      )}
    </Box>
  );
};

export default AddEditTask;
