import { ETOSelectField } from '@teto/react-component-library-v2';
import { FormikProps } from 'formik';
import { t } from 'i18next';
import React from 'react';
import { Contact } from '../../../../../__generated__/graphql';
import useBuildQuery from '../../../../../api/graphQL/useBuildQuery';

interface FanRatingsInputProps {
  formik: FormikProps<Partial<Contact>>;
}
interface FanRatingsApiResponse {
  fanRatings: {
    items: {
      id: number;
      description: string;
    }[];
  };
}
const fanRatingQuery = `
query fanRatings{
  fanRatings{
    items{
      id
      description
    }
  }
}`;
const FanRatingsInput = (props: FanRatingsInputProps) => {
  const { formik } = props;
  const fanRatings = useBuildQuery<FanRatingsApiResponse>(['fanRatings'], {
    queryString: fanRatingQuery,
    variables: {},
    options: { refetchOnMount: false, refetchOnWindowFocus: false },
  });
  return (
    <ETOSelectField
      handleChange={(e) => {
        formik.setFieldValue('fanRating', { id: e.target.value });
      }}
      itemNameSelector={(rating) => rating?.description}
      items={fanRatings.data?.fanRatings?.items ?? []}
      itemValueSelector={(rating) => rating?.id}
      label={t('entities:Contact.FanRating')}
      name="fanRating"
      size="small"
      value={formik.values.fanRating?.id || 0}
    />
  );
};

export default FanRatingsInput;
