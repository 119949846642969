import AddRoundedIcon from '@mui/icons-material/AddRounded';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import {
  Box,
  Chip,
  CircularProgress,
  Grid,
  Menu,
  MenuItem,
  SxProps,
  Theme,
} from '@mui/material';
import {
  ETOAccordion,
  ETOButton,
  ETOCheckBox,
  ETODateField,
  ETOSelectField,
  ETOTextField,
  MessageContext,
  YesNoConfirmDialog,
} from '@teto/react-component-library-v2';
import { FormikProps } from 'formik';
import React, {
  ChangeEvent,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { Licenses, Permission } from 'teto-client-api';
import { Company, Shipper } from '../../../../../__generated__/graphql';
import getErrors from '../../../../../api/graphQL/getErrors';
import { getGraphQLClient } from '../../../../../api/graphQL/graphQLClient';
import {
  MAX_100,
  MAX_255,
  MAX_50,
} from '../../../../../constants/numbers/numbers';
import AuthContext from '../../../../../contexts/AuthContext';
import SettingsContext from '../../../../../contexts/SettingsContext';
import formatCharCount from '../../../../../helpers/formatCharCount';
import isCustomCaption from '../../../../../helpers/isCustomCaption';
import CommonGridSupplierSelectField from '../../../../InputFields/CommonGridSupplierSelectField/CommonGridSupplierSelectField';
import PercentInput from '../../../../Modals/PurchaseOrderModal/panels/PurchaseOrderDetailsPanel/PurchaseOrderHeaderForm/PercentInput';
import { InspectorActions } from '../../../types/InspectorActionTypes';
import { PanelMode } from '../../../types/PanelState';
import handleNumberInputChange from '../../helpers/companyHelpers';
import CompanyType from '../../interfaces/CompanyType';
import Term from '../../interfaces/Term';
import addCompanyCompanyTypesMutation from '../../queries/addCompanyCompanyTypesMutation';
import deleteCompanyTypesMutation from '../../queries/deleteCompanyTypesMutation';
import getCompanyTermsQuery from '../../queries/getCompanyTermsQuery';
import {
  getAllCompanyTypesQuery,
  getCurrentCompanyTypesQuery,
} from '../../queries/getCompanyTypesQuery';
import getShippersQuery from '../../queries/getShippersQuery';
import getSimilarCompaniesQuery from '../../queries/getSimilarCompaniesQuery';

interface CompanyInfoPanelProps
  extends Pick<
    InspectorActions<Company>,
    'setCurrentValues' | 'setHasASaveOccurred'
  > {
  panelMode: PanelMode;
  setIsCompanyASupplier: React.Dispatch<React.SetStateAction<boolean>>;
  // eslint-disable-next-line no-unused-vars
  setPanelEditingState: (value: {
    isValid: boolean;
    isEditing: boolean;
  }) => void;
  formik: FormikProps<Company>;
  setConfirmedNameChange: React.Dispatch<React.SetStateAction<boolean>>;
  confirmedNameChange: boolean;
  currentValues: Company;
}

interface ConfirmTypeChange {
  dialog: {
    content: string;
    title: string;
  };
  data: CompanyType;
}

const chipSx = (theme: Theme) => ({
  marginRight: theme.spacing(1),
});

const createSx: SxProps<Theme> = {
  borderTop: (theme) => `2px solid ${theme.palette.divider}`,
  display: 'flex',
  flexGrow: 0,
  flexShrink: 0,
  justifyContent: 'flex-end',
  paddingTop: (theme) => theme.spacing(2),
  width: '100%',
};

const chipContainerSx = (theme: Theme) => ({
  display: 'flex',
  flexWrap: 'wrap',
  marginBottom: theme.spacing(2),
  rowGap: theme.spacing(1),
});

const containerSx = (theme: Theme) => ({
  display: 'flex',
  flexDirection: 'column',
  overflowY: 'auto',
  paddingTop: theme.spacing(1),
  width: '100%',
});

const accordionSx: SxProps<Theme> = {
  background: 'none',
  boxShadow: 0,
  width: '100%',
  '& .MuiAccordionSummary-root, .MuiAccordionDetails-root': {
    padding: 0,
  },
};

const CompanyInfoPanel = (props: CompanyInfoPanelProps) => {
  const {
    formik,
    currentValues,
    panelMode,
    setIsCompanyASupplier,
    setPanelEditingState,
    setConfirmedNameChange,
    confirmedNameChange,
  } = props;

  const { t } = useTranslation();

  const authContext = useContext(AuthContext);
  const messageContext = useContext(MessageContext);
  const settingsContext = useContext(SettingsContext);

  const [currentCompanyTypes, setCurrentCompanyTypes] = useState<CompanyType[]>(
    []
  );
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [confirmCompanyTypeChange, setConfirmCompanyTypeChange] =
    useState<ConfirmTypeChange>();

  const [openCompanyNameChangeDialog, setOpenCompanyNameChangeDialog] =
    useState<{ name: string; value: string }>();
  const [createSimilarCompany, setCreateSimilarCompany] = useState<{
    name: string;
    cities: string;
  }>();

  const {
    canAddSupplier,
    canDeleteSupplier,
    canViewCompanyTypes,
    canAddCompanyTypes,
    canDeleteCompanyTypes,
    canUpdateCompanyActive,
    canUpdateCompanyInfo,
  } = useMemo(
    () => ({
      canDeleteSupplier:
        authContext.hasLicense(Licenses.TotalETOProfessional) &&
        authContext.hasPermission(
          Permission.Delete_Sales_CompanyInformation_SupplierInformation
        ),
      canAddSupplier:
        authContext.hasLicense(Licenses.TotalETOProfessional) &&
        authContext.hasPermission(
          Permission.Add_Sales_CompanyInformation_SupplierInformation
        ),
      canViewCompanyTypes:
        authContext.hasLicense(Licenses.ReadOnlyProfessional) &&
        authContext.hasAnyPermission([
          Permission.View_Sales_CompanyInformation_CompanyTypeInformation,
          Permission.Add_Sales_CompanyInformation_CompanyTypeInformation,
          Permission.Delete_Sales_CompanyInformation_CompanyTypeInformation,
          Permission.Modify_Sales_CompanyInformation_CompanyTypeInformation,
        ]),
      canAddCompanyTypes:
        authContext.hasLicense(Licenses.TotalETOProfessional) &&
        authContext.hasPermission(
          Permission.Add_Sales_CompanyInformation_CompanyTypeInformation
        ),
      canDeleteCompanyTypes:
        authContext.hasLicense(Licenses.TotalETOProfessional) &&
        authContext.hasPermission(
          Permission.Delete_Sales_CompanyInformation_CompanyTypeInformation
        ),
      canUpdateCompanyActive:
        authContext.hasLicense(Licenses.TotalETOProfessional) &&
        authContext.hasPermission(
          Permission.Modify_Sales_CompanyInformation_Active
        ) &&
        currentValues.id !== 1,
      canUpdateCompanyInfo:
        authContext.hasLicense(Licenses.TotalETOProfessional) &&
        authContext.hasPermission(
          Permission.Modify_Sales_CompanyInformation_CompanyTypeInformation
        ) &&
        currentValues.id !== 1,
    }),
    [authContext, currentValues.id]
  );

  const currentCompanyTypeDescriptions = useMemo(
    () => currentCompanyTypes.map((i) => i.description),
    [currentCompanyTypes]
  );

  const companyTypes = useQuery(
    ['companyTypes'],
    () =>
      getGraphQLClient()
        .performQuery(
          getAllCompanyTypesQuery,
          {},
          (err) => messageContext.setError(getErrors(err)),
          (err) => messageContext.setError(getErrors(err))
        )
        .then((d) => d.companyTypes.items),
    {
      enabled: Boolean(currentValues?.id),
    }
  );

  const shippers = useQuery<Pick<Shipper, 'id' | 'shipperName'>[]>(
    ['shippers'],
    () =>
      getGraphQLClient()
        .performQuery(
          getShippersQuery,
          {},
          (err) => messageContext.setError(getErrors(err)),
          (err) => messageContext.setError(getErrors(err))
        )
        .then((d) => d.shippers.items),
    {
      enabled: Boolean(currentValues?.id),
    }
  );

  const terms = useQuery<Term[]>(
    ['terms'],
    () =>
      getGraphQLClient()
        .performQuery(
          getCompanyTermsQuery,
          {},
          (err) => messageContext.setError(getErrors(err)),
          (err) => messageContext.setError(getErrors(err))
        )
        .then((d) => d.terms.items),
    {
      enabled: Boolean(currentValues?.id),
    }
  );

  const checkCompanyIsASupplier = (arr: CompanyType[]) =>
    arr.some((ar: CompanyType) => ar.description === 'Supplier');

  useEffect(() => {
    if (currentValues?.id) {
      const companyTypesQuery = getGraphQLClient().performQuery(
        getCurrentCompanyTypesQuery,
        { companyId: currentValues?.id },
        (err) => messageContext.setError(getErrors(err)),
        (err) => messageContext.setError(getErrors(err))
      );

      companyTypesQuery.then((resp) => {
        setCurrentCompanyTypes(resp.companyCompanyTypes.items);
        if (checkCompanyIsASupplier(resp.companyCompanyTypes.items)) {
          setIsCompanyASupplier(true);
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setPanelEditingState({
      isEditing: formik.dirty,
      isValid: formik.isValid,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.dirty, formik.isValid]);

  const _handleDeleteCompanyType = useCallback(
    (cType: CompanyType) => {
      getGraphQLClient()
        .performMutation(
          deleteCompanyTypesMutation,
          {
            id: cType.id,
          },
          (err) => messageContext.setError(getErrors(err)),
          (err) => messageContext.setError(getErrors(err))
        )
        .then((d) => {
          if (d.deleteCompanyCompanyType.success) {
            setCurrentCompanyTypes((chips) =>
              chips.filter((chip: CompanyType) => chip.id !== cType.id)
            );
            if (cType.description === 'Supplier') {
              setIsCompanyASupplier(false);
            }
          }
        });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [setIsCompanyASupplier]
  );

  const _handleChipDeleteClick = useCallback(
    (cType: CompanyType) => {
      const typeDialog =
        cType.description === 'Supplier'
          ? 'deleteSupplier'
          : 'deleteCompanyType';

      const dialog = {
        content: t(`pages.companies.dialog.${typeDialog}.content`, {
          type: cType.description,
        }),
        title: t(`pages.companies.dialog.${typeDialog}.title`),
      };

      setConfirmCompanyTypeChange({
        dialog,
        data: cType,
      });
    },
    [t]
  );

  const _handleAddCompanyTypeClose = () => {
    setAnchorEl(null);
  };

  const _handleAddCompanyTypeClick = useCallback(
    (cType: string) => {
      getGraphQLClient()
        .performMutation(
          addCompanyCompanyTypesMutation,
          {
            input: {
              companyId: currentValues?.id,
              description: cType,
            },
          },
          (err) => messageContext.setError(getErrors(err)),
          (err) => messageContext.setError(getErrors(err))
        )
        .then((d) => {
          if (d.addCompanyCompanyType) {
            setCurrentCompanyTypes([
              ...currentCompanyTypes,
              d.addCompanyCompanyType,
            ]);
            if (d.addCompanyCompanyType.description === 'Supplier') {
              setIsCompanyASupplier(true);
            }
          }
        });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [currentCompanyTypes, currentValues?.id]
  );

  const _handleNameChange = useCallback(
    (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      if (panelMode === 'edit' && !confirmedNameChange) {
        const { name, value } = e.target;
        setOpenCompanyNameChangeDialog({ name, value });
      } else formik.handleChange(e);
    },
    [confirmedNameChange, formik, panelMode]
  );

  const _handleCheckboxChange = useCallback(
    (field: string, e: ChangeEvent<HTMLInputElement>) =>
      formik.setFieldValue(field, e.target.checked),
    [formik]
  );

  const _handleCreateCompany = useCallback(() => {
    formik.validateForm().then(() =>
      getGraphQLClient()
        .performQuery(
          getSimilarCompaniesQuery,
          { name: formik?.values?.name },
          (err) => messageContext.setError(err?.messages[0] ?? err.toString()),
          (err) => formik.setErrors(err?.input)
        )
        .then((d) => {
          if (d.companies.items.length > 0) {
            const cities = d.companies.items.reduce(
              (acc: string, i: Company, index: number) => {
                if (i.city) {
                  return index === 0 ? i.city : `${acc}, ${i.city}`;
                }
                return acc;
              },
              ''
            );

            setCreateSimilarCompany({
              name: formik?.values?.name as string,
              cities,
            });
          } else {
            formik.submitForm();
          }
        })
    );
  }, [formik, messageContext]);

  return (
    <Box sx={containerSx}>
      {panelMode === 'edit' && canViewCompanyTypes && (
        <>
          <Box sx={chipContainerSx}>
            {currentCompanyTypes.map((cType: CompanyType) => (
              <Chip
                deleteIcon={<CloseRoundedIcon color="error" />}
                disabled={
                  !canDeleteCompanyTypes ||
                  (!canDeleteSupplier && cType.description === 'Supplier')
                }
                key={cType.id}
                label={cType.description}
                onDelete={() => _handleChipDeleteClick(cType)}
                sx={chipSx}
                variant="outlined"
              />
            ))}
            {canAddCompanyTypes && (
              <Chip
                color="primary"
                icon={<AddRoundedIcon color="primary" />}
                label="Add Company Type"
                onClick={(e) => setAnchorEl(e.currentTarget)}
                variant="outlined"
              />
            )}
          </Box>
          <Menu
            anchorEl={anchorEl}
            onClose={_handleAddCompanyTypeClose}
            open={Boolean(anchorEl)}
            slotProps={{
              paper: {
                sx: (theme) => ({
                  maxHeight: theme.spacing(30),
                  mt: 1,
                }),
              },
            }}
          >
            {companyTypes.isLoading && (
              <MenuItem>
                <Box>
                  <CircularProgress />
                </Box>
              </MenuItem>
            )}
            {!companyTypes.isLoading &&
              companyTypes.data &&
              companyTypes.data.map((i: { ctype: string }) => (
                <MenuItem
                  disabled={
                    currentCompanyTypeDescriptions.indexOf(i.ctype) >= 0 ||
                    (!canAddSupplier && i.ctype === 'Supplier')
                  }
                  key={i.ctype}
                  onClick={() => {
                    _handleAddCompanyTypeClick(i.ctype);
                    setAnchorEl(null);
                  }}
                >
                  {i.ctype}
                </MenuItem>
              ))}
          </Menu>
        </>
      )}
      <Grid
        container
        mb={2}
        spacing={2}
        sx={{ ...(panelMode === 'create' && { flexGrow: 1 }) }}
      >
        <Grid item lg={4} md={6} xs={12}>
          <ETOTextField
            disabled={!canUpdateCompanyInfo}
            error={formik.errors?.name}
            handleChange={_handleNameChange}
            helperText={formatCharCount('name', formik.values?.name, MAX_100)}
            label={t('entities:Company.Name')}
            name="name"
            size="small"
            value={formik.values?.name}
          />
        </Grid>
        {panelMode === 'edit' && (
          <>
            <Grid item lg={4} md={6} xs={12}>
              <ETOTextField
                disabled={!canUpdateCompanyInfo}
                error={formik.errors?.phone}
                handleChange={formik.handleChange}
                helperText={formatCharCount(
                  'phone',
                  formik.values?.phone,
                  MAX_50
                )}
                label={t('entities:Company.Phone')}
                name="phone"
                size="small"
                value={formik.values?.phone}
              />
            </Grid>
            <Grid item lg={4} md={6} xs={12}>
              <ETOTextField
                disabled={!canUpdateCompanyInfo}
                error={formik.errors?.fax}
                handleChange={formik.handleChange}
                helperText={formatCharCount('fax', formik.values?.fax, MAX_50)}
                label={t('entities:Company.Fax')}
                name="fax"
                size="small"
                value={formik.values?.fax}
              />
            </Grid>
            <Grid item lg={4} md={6} xs={12}>
              <ETOTextField
                disabled={!canUpdateCompanyInfo}
                error={formik.errors?.tollFree}
                handleChange={formik.handleChange}
                helperText={formatCharCount(
                  'tollFree',
                  formik.values?.tollFree,
                  MAX_50
                )}
                label={t('entities:Company.TollFree')}
                name="tollFree"
                size="small"
                value={formik.values?.tollFree}
              />
            </Grid>
            <Grid item lg={4} md={6} xs={12}>
              <ETOTextField
                disabled={!canUpdateCompanyInfo}
                error={formik.errors?.defaultEmailAddress}
                handleChange={formik.handleChange}
                helperText={formatCharCount(
                  'defaultEmailAddress',
                  formik.values?.defaultEmailAddress,
                  MAX_255
                )}
                label={t('entities:Company.DefaultEmailAddress')}
                name="defaultEmailAddress"
                size="small"
                value={formik.values?.defaultEmailAddress}
              />
            </Grid>
            <Grid item lg={4} md={6} xs={12}>
              <ETOTextField
                disabled={!canUpdateCompanyInfo}
                error={formik.errors?.webSite}
                handleChange={formik.handleChange}
                helperText={formatCharCount(
                  'webSite',
                  formik.values?.webSite,
                  MAX_255
                )}
                label={t('entities:Company.WebSite')}
                name="webSite"
                size="small"
                value={formik.values?.webSite}
              />
            </Grid>
            <Grid item lg={4} md={6} xs={12}>
              <CommonGridSupplierSelectField
                disabled={!canUpdateCompanyInfo}
                error={formik.errors?.parentId}
                fieldName="parentId"
                handleChange={(val) => {
                  const { value } = val.target;
                  return formik.setFieldValue(
                    val.target.name,
                    handleNumberInputChange({ value, type: 'int' })
                  );
                }}
                label={t('entities:Company.Parent')}
                shouldHaveWarning={false}
                value={formik.values?.parentId ?? null}
              />
            </Grid>
            <Grid item lg={4} md={6} xs={12}>
              <ETODateField
                clearable
                disabled={!canUpdateCompanyInfo}
                error={formik.errors?.date as string}
                handleChange={(d) => formik.setFieldValue('date', d)}
                inputFormat={settingsContext.settings.dateFormat}
                inputProps={{ 'aria-label': 'Choose Company date' }}
                label={t('entities:Company.Date')}
                name="date"
                size="small"
                value={formik?.values?.date ?? ''}
              />
            </Grid>
            <Grid item lg={2} md={6} xs={12}>
              <ETOCheckBox
                disabled={!canUpdateCompanyInfo}
                handleChange={(e) => _handleCheckboxChange('hasAgent', e)}
                label="Uses Agent"
                name="hasAgent"
                size="small"
                value={formik.values?.hasAgent}
              />
            </Grid>
            <Grid item lg={2} md={6} xs={12}>
              <ETOCheckBox
                disabled={!canUpdateCompanyActive}
                handleChange={(e) => _handleCheckboxChange('active', e)}
                label="Active"
                name="active"
                size="small"
                value={formik.values?.active}
              />
            </Grid>
          </>
        )}
      </Grid>
      {panelMode === 'edit' && (
        <>
          <Grid container spacing={2}>
            {isCustomCaption(
              'custom1',
              t('entities:Company.CompanyCustom1'),
              'Company'
            ) && (
              <Grid item lg={4} md={6} xs={12}>
                <ETOTextField
                  disabled={!canUpdateCompanyInfo}
                  error={formik.errors?.custom1}
                  handleChange={formik.handleChange}
                  key="custom1"
                  label={t('entities:Company.CompanyCustom1')}
                  maxRows={3}
                  multiline
                  name="custom1"
                  size="small"
                  value={formik?.values?.custom1}
                />
              </Grid>
            )}

            {isCustomCaption(
              'custom2',
              t('entities:Company.CompanyCustom2'),
              'Company'
            ) && (
              <Grid item lg={4} md={6} xs={12}>
                <ETOTextField
                  disabled={!canUpdateCompanyInfo}
                  error={formik.errors?.custom2}
                  handleChange={formik.handleChange}
                  key="custom2"
                  label={t('entities:Company.CompanyCustom2')}
                  maxRows={3}
                  multiline
                  name="custom2"
                  size="small"
                  value={formik?.values?.custom2}
                />
              </Grid>
            )}

            {isCustomCaption(
              'custom3',
              t('entities:Company.CompanyCustom3'),
              'Company'
            ) && (
              <Grid item lg={4} md={6} xs={12}>
                <ETOTextField
                  disabled={!canUpdateCompanyInfo}
                  error={formik.errors?.custom3 as string}
                  handleChange={({ target: { name, value } }) =>
                    formik.setFieldValue(
                      name,
                      handleNumberInputChange({ value, type: 'decimal' })
                    )
                  }
                  key="custom3"
                  label={t('entities:Company.CompanyCustom3')}
                  name="custom3"
                  size="small"
                  type="number"
                  value={formik?.values?.custom3}
                />
              </Grid>
            )}

            {isCustomCaption(
              'custom4',
              t('entities:Company.CompanyCustom4'),
              'Company'
            ) && (
              <Grid item lg={4} md={6} xs={12}>
                <ETOTextField
                  disabled={!canUpdateCompanyInfo}
                  error={formik.errors?.custom4 as string}
                  handleChange={({ target: { name, value } }) =>
                    formik.setFieldValue(
                      name,
                      handleNumberInputChange({ value, type: 'decimal' })
                    )
                  }
                  key="custom4"
                  label={t('entities:Company.CompanyCustom4')}
                  name="custom4"
                  size="small"
                  type="number"
                  value={formik?.values?.custom4}
                />
              </Grid>
            )}

            {isCustomCaption(
              'custom5',
              t('entities:Company.CompanyCustom5'),
              'Company'
            ) && (
              <Grid item lg={4} md={6} xs={12}>
                <ETODateField
                  clearable
                  disabled={!canUpdateCompanyInfo}
                  error={formik.errors?.custom5 as string}
                  handleChange={(d) => formik.setFieldValue('custom5', d)}
                  inputFormat={settingsContext.settings.dateFormat}
                  inputProps={{ 'aria-label': 'Choose Custom5 date' }}
                  key="custom5"
                  label={t('entities:Company.CompanyCustom5')}
                  name="custom5"
                  size="small"
                  value={formik?.values?.custom5}
                />
              </Grid>
            )}

            {isCustomCaption(
              'custom6',
              t('entities:Company.CompanyCustom6'),
              'Company'
            ) && (
              <Grid item lg={4} md={6} xs={12}>
                <ETODateField
                  clearable
                  disabled={!canUpdateCompanyInfo}
                  error={formik.errors?.custom6 as string}
                  handleChange={(d) => formik.setFieldValue('custom6', d)}
                  inputFormat={settingsContext.settings.dateFormat}
                  inputProps={{ 'aria-label': 'Choose Custom6 date' }}
                  key="custom6"
                  label={t('entities:Company.CompanyCustom6')}
                  name="custom6"
                  size="small"
                  value={formik?.values?.custom6}
                />
              </Grid>
            )}
            {isCustomCaption(
              'custom7',
              t('entities:Company.CompanyCustom7'),
              'Company'
            ) && (
              <Grid item lg={4} md={6} xs={12}>
                <ETOCheckBox
                  disabled={!canUpdateCompanyInfo}
                  handleChange={(e) => _handleCheckboxChange('custom7', e)}
                  key="custom7"
                  label={t('entities:Company.CompanyCustom7')}
                  name="custom7"
                  size="small"
                  value={formik?.values?.custom7}
                />
              </Grid>
            )}
            {isCustomCaption(
              'custom8',
              t('entities:Company.CompanyCustom8'),
              'Company'
            ) && (
              <Grid item lg={4} md={6} xs={12}>
                <ETOCheckBox
                  disabled={!canUpdateCompanyInfo}
                  handleChange={(e) => _handleCheckboxChange('custom8', e)}
                  label={t('entities:Company.CompanyCustom8')}
                  name="custom8"
                  size="small"
                  value={formik?.values?.custom8}
                />
              </Grid>
            )}
          </Grid>
          <ETOAccordion
            defaultSummary="Misc."
            expanded
            hideExpandIcon
            sx={accordionSx}
          >
            <Grid container spacing={2}>
              <Grid item lg={4} md={6} xs={12}>
                <ETOSelectField
                  disabled={!canUpdateCompanyInfo}
                  error={formik.errors?.defaultShipViaId}
                  handleChange={({ target: { name, value } }) =>
                    formik.setFieldValue(
                      name,
                      handleNumberInputChange({ value, type: 'decimal' })
                    )
                  }
                  itemNameSelector={(item) => item.shipperName}
                  items={shippers.data ?? []}
                  itemValueSelector={(item) => item.id}
                  label={t('entities:Company.DefaultShipVia')}
                  name="defaultShipViaId"
                  size="small"
                  value={formik.values?.defaultShipViaId ?? null}
                />
              </Grid>
              <Grid item lg={4} md={6} xs={12}>
                <PercentInput
                  disabled={!canUpdateCompanyInfo}
                  error={formik.errors?.defaultPartDiscount as string}
                  handleChange={(e) => formik.setFieldValue(e.key, e.value)}
                  label={t('entities:Company.DefaultPartDiscount')}
                  name="defaultPartDiscount"
                  size="small"
                  value={formik.values?.defaultPartDiscount ?? 0}
                />
              </Grid>
              <Grid item lg={4} md={6} xs={12}>
                <PercentInput
                  disabled={!canUpdateCompanyInfo}
                  error={formik.errors?.defaultPartMargin as string}
                  handleChange={(e) => formik.setFieldValue(e.key, e.value)}
                  label={t('entities:Company.DefaultPartMargin')}
                  name="defaultPartMargin"
                  size="small"
                  value={formik.values?.defaultPartMargin ?? 0}
                />
              </Grid>
              <Grid item lg={4} md={6} xs={12}>
                <ETOSelectField
                  disabled={!canUpdateCompanyInfo}
                  error={formik.errors?.defaultTermsId}
                  handleChange={formik.handleChange}
                  itemNameSelector={(item) => item.description}
                  items={terms.data ?? []}
                  itemValueSelector={(item) => item.id}
                  label={t('entities:Company.DefaultTerms')}
                  name="defaultTermsId"
                  size="small"
                  value={formik.values?.defaultTermsId ?? null}
                />
              </Grid>
            </Grid>
          </ETOAccordion>
        </>
      )}
      {panelMode === 'create' && (
        <Box data-testid="create-btn-strip" sx={createSx}>
          <ETOButton
            color="primary"
            disabled={!formik.isValid}
            onClick={_handleCreateCompany}
            size="medium"
          >
            {t('generic.save')}
          </ETOButton>
        </Box>
      )}

      {createSimilarCompany && (
        <YesNoConfirmDialog
          content={t(
            'pages.companies.dialog.sameName.content',
            createSimilarCompany
          )}
          onNo={() => setCreateSimilarCompany(undefined)}
          onYes={() => {
            formik.submitForm();
            setCreateSimilarCompany(undefined);
          }}
          open={Boolean(createSimilarCompany)}
          title={t('pages.companies.dialog.sameName.title')}
        />
      )}

      {confirmCompanyTypeChange && (
        <YesNoConfirmDialog
          content={confirmCompanyTypeChange?.dialog.content}
          onNo={() => setConfirmCompanyTypeChange(undefined)}
          onYes={() => {
            _handleDeleteCompanyType(confirmCompanyTypeChange?.data);
            setConfirmCompanyTypeChange(undefined);
          }}
          open={Boolean(confirmCompanyTypeChange)}
          title={confirmCompanyTypeChange?.dialog.title}
        />
      )}
      {openCompanyNameChangeDialog && (
        <YesNoConfirmDialog
          content={t('pages.companies.dialog.nameChange.content')}
          onNo={() => setOpenCompanyNameChangeDialog?.(undefined)}
          onYes={() => {
            const { name, value } = openCompanyNameChangeDialog;
            formik.setFieldValue(name, value);
            setConfirmedNameChange(true);
            setOpenCompanyNameChangeDialog?.(undefined);
          }}
          open={Boolean(openCompanyNameChangeDialog)}
          title={t('pages.companies.dialog.nameChange.title')}
        />
      )}
    </Box>
  );
};

export default CompanyInfoPanel;
