import {
  ETOSelectField,
  MessageContext,
} from '@teto/react-component-library-v2';
import { useFormikContext } from 'formik';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import getErrors from '../../../api/graphQL/getErrors';
import { getGraphQLClient } from '../../../api/graphQL/graphQLClient';
import { companyId } from './companyReportConstants';

interface Company {
  id: number;
  name: string;
  active: boolean;
}

const companiesQuery = `query {
  companies {
    items {
      id
      name
      active
    }
  }
}`;

const CompanyInfoForm = () => {
  const formik = useFormikContext<{ [companyId]: string }>();
  const { t } = useTranslation();
  const messageContext = useContext(MessageContext);

  const companies = useQuery(
    ['companies-list'],
    () =>
      getGraphQLClient()
        .performQuery(
          companiesQuery,
          {},
          (err) => messageContext.setError(err.messages[0]),
          (err) => {
            const errors = getErrors(err);
            messageContext.setError(errors);
          }
        )
        .then((d) => d.companies.items as unknown as Company[]),
    {
      enabled: true,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
    }
  );

  return (
    <ETOSelectField
      error={formik.errors[companyId]}
      handleChange={formik.handleChange}
      itemNameSelector={(i) => i?.name ?? ''}
      items={companies.data ?? []}
      itemValueSelector={(i) => i?.id}
      label={t('entities:Company.Name')}
      name={companyId}
      size="small"
      value={formik.values[companyId] || null}
    />
  );
};

export default CompanyInfoForm;
