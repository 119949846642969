import { gql } from '../../../__generated__';
import SelectDefinition from './SelectDefinition';

export default {
  query: gql(`query getShipToCompanies {
    companies(where: {active: { eq: true } }, order: {name: ASC}) {
      items {
        id
        name
        active
        city
        phone
        zip
        state
      }
    }
  }`),
  valueField: 'id',
  labelField: 'name',
} as SelectDefinition;
