import { TypeFilterValue } from '@inovua/reactdatagrid-community/types';
import DeleteIcon from '@mui/icons-material/Delete';
import { Box, IconButton, List, SxProps, Theme, useTheme } from '@mui/material';

import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { useTranslation } from 'react-i18next';
import DraggableListItem from '../../../../ListItems/DraggableListItem';
import ListItem, { ListItemProps } from '../../../../ListItems/ListItem';
import { TETODataColumn } from '../../../GridBuilder/types/TETODataColumn';
import {
  NullableTypeSingleFilterValue,
  applyServerSideFilters,
} from '../../../hooks/useGrid';
import IColumnEditorNodeClass from '../../SelectColumnEditor/IColumnEditorNodeClass';
import SelectColumnEditor from '../../SelectColumnEditor/SelectColumnEditor';
import FilterField from './FilterField';

const altColorSx: SxProps<Theme> = {
  backgroundColor: (theme) => theme.palette.background.default,
  color: (theme) =>
    theme.palette.getContrastText(theme.palette.background.default),
};

const listItemSx: SxProps<Theme> = {
  p: 0,
  pb: (theme) => theme.spacing(1),
};

export interface FilterColumnsPanelProps {
  columns: TETODataColumn[];
  filters: TypeFilterValue;
  setFilters: React.Dispatch<React.SetStateAction<TypeFilterValue>>;
  customSelectColumnEditorSx?: SxProps<Theme>;
  customListItemSx?: SxProps<Theme>;
  customListDisplaySx?: SxProps<Theme>;
  disableAltColors?: boolean;
  selectColumnEditorRightChild?: ListItemProps['rightChild'];
  customListSx?: SxProps<Theme>;
  resetShownFilters?: boolean;
  setResetShownFilters?: React.Dispatch<React.SetStateAction<boolean>>;
}

const FilterColumnsPanel: React.FC<FilterColumnsPanelProps> = (props) => {
  const {
    customListSx,
    columns,
    customListDisplaySx,
    customSelectColumnEditorSx,
    disableAltColors,
    filters,
    resetShownFilters,
    setResetShownFilters,
    customListItemSx,
    selectColumnEditorRightChild,
    setFilters,
  } = props;

  const [showFilter, setShowFilter] = useState<string[]>([]);

  const { t } = useTranslation();

  const theme = useTheme();

  useEffect(() => {
    if (Array.isArray(filters) && filters.length > 0) {
      const initialVals = applyServerSideFilters(
        filters as NullableTypeSingleFilterValue[]
      );
      if (!initialVals || initialVals.length === 0) {
        return;
      }
      const filtersThatHaveValue = initialVals.filter((a) => {
        if (typeof a.value === 'object' && a.value !== null) {
          return a.value.start !== a.emptyValue || a.value.end !== a.emptyValue;
        }

        if (a.value !== a.emptyValue) {
          return a;
        }
        return false;
      });

      if (filtersThatHaveValue.length > 0) {
        const names = filtersThatHaveValue.map((a) => a.name);
        return setShowFilter((prevFilters) =>
          Array.from(new Set([...prevFilters, ...names]))
        );
      }
      if (showFilter.length === 0) {
        setShowFilter([]);
      }
    }
  }, [filters, showFilter.length]);

  const filterColumns = useMemo(
    () => columns.filter((col) => showFilter.includes(col.name)),
    [columns, showFilter]
  );

  const sortByAlphabeticalOrder = (
    children: IColumnEditorNodeClass[]
  ): IColumnEditorNodeClass[] => {
    const result = children.sort((a, b) => {
      if (a.column.title && b.column.title) {
        const nameA = a.column.title.toLowerCase();
        const nameB = b.column.title.toLowerCase();
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }
      }
      return 0;
    });
    return result;
  };

  const findValue = useCallback(
    (col: TETODataColumn) => {
      const filter = filters && filters.find((fv) => fv.name === col.name);
      if (filter) {
        filter.value = filter.value ?? '';
      }
      return filter?.value;
    },
    [filters]
  );

  const assignedListSx = () => ({
    flexGrow: 1,
    flexShrink: 1,
    overflowY: 'auto',
    paddingRight: 0.5,
    fontSize: '0.9rem',
    '& .smooth-dnd-draggable-wrapper': {
      borderRadius: `${theme.shape.borderRadius}px`,
    },
    '& .smooth-dnd-draggable-wrapper:nth-of-type(odd)': {
      backgroundColor: !disableAltColors
        ? theme.palette.background.default
        : 'inherit',
    },
  });

  const filterableColumns = useMemo(
    () => columns.filter((col) => col.filterable !== false),
    [columns]
  );

  useEffect(() => {
    if (resetShownFilters && setResetShownFilters) {
      setShowFilter([]);
      setResetShownFilters(false);
    }
  }, [resetShownFilters, setFilters, setResetShownFilters]);

  return (
    <List
      disablePadding
      sx={[
        assignedListSx,
        ...(Array.isArray(customListSx) ? customListSx : [customListSx]),
      ]}
    >
      <ListItem
        centerChild={
          <SelectColumnEditor
            columns={filterableColumns}
            isValueDisabled={(node) => {
              if (node.column.type === 'object') return false; // allow nested columns
              if (filters) {
                const found = Boolean(
                  filters.find((a) => a.name === node.column.name)
                );
                if (found && node.column.filterable === false) {
                  return true; // disable the field if it's not filterable
                }
                if (found && showFilter.includes(node.column.name)) {
                  return true; // disable the field if it's already in the list
                }
                return !found; // if not found, disable
              }
              return false;
            }}
            label={`${t('generic.pick')} ${t('generic.filter_other')}`}
            modifyChildren={sortByAlphabeticalOrder}
            onValueChanged={(value) =>
              setShowFilter((prevFilters) => [
                ...prevFilters,
                value.column.name,
              ])
            }
            sx={[
              ...(Array.isArray(customSelectColumnEditorSx)
                ? customSelectColumnEditorSx
                : [customSelectColumnEditorSx]),
            ]}
          />
        }
        customSx={[
          listItemSx,
          ...(Array.isArray(customListItemSx)
            ? customListItemSx
            : [customListItemSx]),
        ]}
        rightChild={selectColumnEditorRightChild}
        size="medium"
      />
      <Box sx={customListDisplaySx}>
        {filterColumns.length > 0 &&
          filterColumns.map((col, ind) => (
            <DraggableListItem
              centerChild={
                <FilterField
                  col={col}
                  filters={filters}
                  setFilters={setFilters}
                  value={findValue(col)}
                />
              }
              customSx={{
                ...(ind % 2 === 0 && !disableAltColors ? altColorSx : {}),
                marginBottom: theme.spacing(0.5),
                cursor: 'pointer',
              }}
              key={col.name}
              rightChild={
                <IconButton
                  onClick={() => {
                    setShowFilter((prevFilters) =>
                      prevFilters.filter((a) => a !== col.name)
                    );
                    setFilters((prevFilters) => {
                      if (prevFilters && Array.isArray(prevFilters)) {
                        const updatedFilters = prevFilters.map((d) => {
                          if (d.name === col.name) {
                            return { ...d, value: '' };
                          }
                          return d;
                        });
                        return updatedFilters;
                      }
                      return prevFilters;
                    });
                  }}
                >
                  <DeleteIcon color="error" />
                </IconButton>
              }
              size="small"
            />
          ))}
      </Box>
    </List>
  );
};

export default FilterColumnsPanel;
