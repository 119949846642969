import React from 'react';
import { ETOTextField } from '@teto/react-component-library-v2';
import { TETODataColumn } from '../GridBuilder/types/TETODataColumn';
import NumberFilterRange from './NumberFilterRange';

interface TETONumberFilterProps {
  operatorValue: string;
  // eslint-disable-next-line no-unused-vars, @typescript-eslint/no-explicit-any
  handleChange: (number: any) => void;
  col: TETODataColumn;
  fieldValue: string | NumberFilterRange;
}

const TETONumberFilter = (props: TETONumberFilterProps) => {
  const { operatorValue, handleChange, col, fieldValue } = props;
  const commonSize = 'small';

  const _renderField = () => {
    switch (col.filterType) {
      case 'number':
      case 'hours':
      case 'currency':
        return (
          <>
            {operatorValue === 'inrange' && (
              <>
                <ETOTextField
                  handleChange={(e) => {
                    const newStart = e.target.value ?? '';
                    handleChange({
                      start: newStart,
                      end: (fieldValue as NumberFilterRange).end,
                    });
                  }}
                  id={col.id}
                  label={`${col.title} Start`}
                  name={col.name}
                  size={commonSize}
                  sxProps={{ mr: 1, pl: 0 }}
                  type={col.type === 'number' ? 'number' : undefined}
                  value={(fieldValue as NumberFilterRange).start}
                />
                <ETOTextField
                  handleChange={(e) => {
                    const newEnd = e.target.value ?? '';
                    handleChange({
                      start: (fieldValue as NumberFilterRange).start,
                      end: newEnd,
                    });
                  }}
                  id={col.id}
                  label={`${col.title} End`}
                  name={col.name}
                  size={commonSize}
                  sxProps={{ mr: 1, pl: 0 }}
                  type={col.type === 'number' ? 'number' : undefined}
                  value={(fieldValue as NumberFilterRange).end}
                />
              </>
            )}
            {operatorValue !== 'inrange' && (
              <ETOTextField
                handleChange={(e) => {
                  handleChange(e.target.value);
                }}
                id={col.id}
                label={col.title}
                name={col.name}
                size={commonSize}
                sxProps={{ mr: 1, pl: 0 }}
                type={col.type === 'number' ? 'number' : undefined}
                value={fieldValue}
              />
            )}
          </>
        );
      default:
        return null;
    }
  };

  return <>{_renderField()}</>;
};

export default TETONumberFilter;
