import { gql } from '../../../../__generated__';

const getTaxSettingsQuery = gql(`query taxSettings($id: Int!) {
  purchaseOrderHeader(id: $id){
    buyerId
    compoundTax
    custom1
    custom2
    custom7
    custom8
    id
    purchaseActive
    purchaseCurrId
    purchaseCurrRate
    purchaseDate
    purchaseDateRequired
    purchaseDateRevised
    purchaseEmailed
    purchaseRev
    purchaseShipToId
    purchaseSupplierId
    purchaseTax1
    purchaseTax1GLAccountId
    purchaseTax1Name
    purchaseTax2
    purchaseTax2GLAccountId
    purchaseTax2Name
    taxes {
      tax1Amount
      tax2Amount
    }
    purchaseCurr {
      rate
      decimalSeparator
      thousandSeparator
    }
  }
}`);

export default getTaxSettingsQuery;
