import ChecklistRtlIcon from '@mui/icons-material/ChecklistRtl';
import ListIcon from '@mui/icons-material/List';
import { PageTabs, PageTabsProps } from '@teto/react-component-library-v2';
import React, { useContext, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { Licenses, Permission } from 'teto-client-api';
import TetoContainer from '../../components/TetoGrid/TetoContainer';
import AuthContext from '../../contexts/AuthContext';
import CompletedPSTab from './CompletedPSTab/CompletedPSTab';
import ProcessScheduleDetailsTab from './ProcessScheduleDetailsTab/ProcessScheduleDetailsTab';

const PAGE_LINK = '/manufacturing/processSchedules/';
const VIEW_PS_LINK = `${PAGE_LINK}viewProcessSchedules`;
const COMPLETED_PS_LINK = `${PAGE_LINK}completedProcessSchedules`;

const ProcessSchedulesPage = () => {
  const authContext = useContext(AuthContext);
  const location = useLocation();
  const navigate = useNavigate();
  const { ready, t } = useTranslation();

  const { canAccess } = useMemo(
    () => ({
      canAccess:
        authContext.hasLicense(Licenses.ReadOnlyProfessional) &&
        authContext.hasAnyPermission([
          Permission.View_Procurement_ProcessSchedules,
          Permission.Modify_Procurement_ProcessSchedules,
        ]),
    }),
    [authContext]
  );

  const { canAccessPsQueue } = useMemo(
    () => ({
      canAccessPsQueue:
        authContext.hasLicense(Licenses.ReadOnlyProfessional) &&
        authContext.hasAnyPermission([Permission.View_Queues_CompletedPSQueue]),
    }),
    [authContext]
  );

  const tabs: PageTabsProps['tabs'] = useMemo(
    () => ({
      viewProcessSchedules: {
        title: t('pages.processSchedule.psDetails'),
        component: <ProcessScheduleDetailsTab />,
        disabled: !canAccess,
        icon: <ListIcon />,
        priority: 1,
      },
      completedProcessSchedules: {
        title: t('pages.processSchedule.completedPSQueue'),
        component: <CompletedPSTab />,
        disabled: !canAccessPsQueue,
        icon: <ChecklistRtlIcon />,
        priority: 2,
      },
    }),
    [canAccess, canAccessPsQueue, t]
  );

  const { availableTabs, selectedTab } = useMemo(() => {
    const activeTabs = Object.keys(tabs).filter(
      (tab) => location.pathname.includes(tab) && !tabs[tab].disabled
    );
    return {
      availableTabs: activeTabs,
      selectedTab: activeTabs.length > 0 ? activeTabs[0] : undefined,
    };
  }, [location.pathname, tabs]);

  useEffect(() => {
    if (!canAccess) return navigate('/access-denied', { replace: true });
    if (availableTabs.length === 0 || location.pathname === VIEW_PS_LINK)
      return navigate(VIEW_PS_LINK, { replace: true });
    if (location.pathname === COMPLETED_PS_LINK)
      navigate(COMPLETED_PS_LINK, { replace: true });
  }, [availableTabs, canAccess, location.pathname, navigate]);

  return (
    <TetoContainer>
      {ready && selectedTab && (
        <PageTabs
          customTabPanelSx={{ overflow: 'hidden' }}
          handleTabChange={(e) => navigate(`${PAGE_LINK}${e}`)}
          selectedTab={selectedTab}
          tabs={tabs}
        />
      )}
    </TetoContainer>
  );
};

export default ProcessSchedulesPage;
