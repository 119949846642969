import { gql } from '../../../../__generated__';

const updatePurchaseOrderDate = gql(`
  mutation updatePurchaseOrderDetailDates($input: UpdatePurchaseOrderDetailDatesInput) {
    updatePurchaseOrderDetailDates(input: $input) {
      items {
        id
        dateRevised
        dateRequired
      }
    }
  }
`);

export default updatePurchaseOrderDate;
