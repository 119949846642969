/* eslint-disable react/jsx-props-no-spreading */
import { Box, SxProps, Theme } from '@mui/material';
import { ETOButton } from '@teto/react-component-library-v2';
// eslint-disable-next-line import/no-unresolved
import { ETOButtonProps } from '@teto/react-component-library-v2/dist/components/Button/ETOButtonProps';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { PanelMode } from '../../../types/PanelState';

export interface SaveButtonProps
  extends Omit<ETOButtonProps, 'color' | 'size'> {
  // eslint-disable-next-line no-unused-vars
  onSubmit: (values: unknown) => void;
  panelMode: PanelMode;
  text?: string;
}

const saveSx: SxProps<Theme> = {
  display: 'flex',
  flexGrow: 0,
  flexShrink: 0,
  justifyContent: 'flex-end',
  width: '100%',
};

const SaveButton = (props: SaveButtonProps) => {
  const { onSubmit, panelMode, disabled, text, ...otherProps } = props;

  const { t } = useTranslation();

  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {panelMode === 'edit' && (
        <Box data-testid="save-btn" sx={saveSx}>
          <ETOButton
            {...otherProps}
            color="primary"
            disabled={disabled}
            onClick={onSubmit}
            size="medium"
          >
            {text ?? t('generic.save')}
          </ETOButton>
        </Box>
      )}
    </>
  );
};

export default SaveButton;
