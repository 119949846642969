import Grid from '@mui/material/Grid';
import {
  ETOSelectField,
  ETOSelectFieldProps,
  ETOTextField,
  ETOTextFieldProps,
  MessageContext,
} from '@teto/react-component-library-v2';
import React, { useContext, useMemo } from 'react';
import { useQuery } from 'react-query';
import { getGraphQLClient } from '../../../../api/graphQL/graphQLClient';
import { MAX_255, MAX_50 } from '../../../../constants/numbers/numbers';
import formatCharCount from '../../../../helpers/formatCharCount';

type TextFieldProps = Omit<ETOTextFieldProps, 'size'>;

export interface Country {
  id: number;
  name: string;
}
export interface AddressFormProps {
  address1Props: TextFieldProps;
  address2Props: TextFieldProps;
  cityProps: TextFieldProps;
  countryProps: Omit<
    ETOSelectFieldProps<Country>,
    'items' | 'itemNameSelector' | 'itemValueSelector' | 'variant'
  >;
  stateProps: TextFieldProps;
  zipProps: TextFieldProps;
}

const countriesQuery = `query {
  countries {
    items {
      id
      name
    }
  }
}`;

const AddressForm = (props: AddressFormProps) => {
  const {
    address1Props,
    address2Props,
    cityProps,
    countryProps,
    stateProps,
    zipProps,
  } = props;

  const messageContext = useContext(MessageContext);

  const { value } = props.countryProps;
  const countries = useQuery(
    ['countries'],
    () =>
      getGraphQLClient()
        .performQuery(
          countriesQuery,
          {},
          (err) => {
            messageContext.setError(err.messages[0]);
          },
          (_err) => {
            messageContext.setError(_err.employees);
          }
        )
        .then((e) => e.countries.items),
    {
      enabled: true,
    }
  );

  const address1 = useMemo(
    () => ({
      ...address1Props,
      helperText: formatCharCount(
        address1Props.name,
        address1Props.value,
        MAX_255
      ),
    }),
    [address1Props]
  );

  const address2 = useMemo(
    () => ({
      ...address2Props,
      helperText: formatCharCount(
        address2Props.name,
        address2Props.value,
        MAX_255
      ),
    }),
    [address2Props]
  );

  const city = useMemo(
    () => ({
      ...cityProps,
      helperText: formatCharCount(cityProps.name, cityProps.value, MAX_50),
    }),
    [cityProps]
  );

  const state = useMemo(
    () => ({
      ...stateProps,
      helperText: formatCharCount(stateProps.name, stateProps.value, MAX_50),
    }),
    [stateProps]
  );

  const zip = useMemo(
    () => ({
      ...zipProps,
      helperText: formatCharCount(zipProps.name, zipProps.value, MAX_50),
    }),
    [zipProps]
  );

  return (
    <Grid container data-testid="address-form" spacing={2}>
      <Grid item md={4} xs={12}>
        <ETOTextField {...address1} multiline size="small" />
      </Grid>
      <Grid item md={4} xs={12}>
        <ETOTextField {...address2} multiline size="small" />
      </Grid>
      <Grid item md={4} xs={12}>
        <ETOTextField {...city} multiline size="small" />
      </Grid>
      <Grid item md={4} xs={12}>
        <ETOTextField {...state} multiline size="small" />
      </Grid>
      <Grid item md={4} xs={12}>
        <ETOTextField {...zip} multiline size="small" />
      </Grid>
      <Grid item md={4} xs={12}>
        <ETOSelectField
          {...countryProps}
          itemNameSelector={(i) => i.name}
          items={countries.data || []}
          itemValueSelector={(i) => i.name}
          size="small"
          value={value || null}
        />
      </Grid>
    </Grid>
  );
};

export default AddressForm;
