import {
  TypeFilterValue,
  TypeGroupBy,
  TypeSingleSortInfo,
  TypeSortInfo,
} from '@inovua/reactdatagrid-enterprise/types';
import ViewColumnRoundedIcon from '@mui/icons-material/ViewColumnRounded';
import { Box, Divider, Grid, SxProps, Theme } from '@mui/material';
import {
  ButtonStrip,
  ConfirmDialogs,
  ETOTab,
  ETOTabs,
  Inspector,
  arrayMoveImmutable,
} from '@teto/react-component-library-v2';
import { isArray } from 'lodash';
import React, { useCallback, useLayoutEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import TabPanel from '../../Inspectors/components/AddEditInspector/components/InspectorTabPanel';
import { TETODataColumn } from '../GridBuilder/types/TETODataColumn';
import { GridProps } from '../types/TetoGridGraphqlProps';
import ConfigureOption from './ConfigureOption';
import { ConfigureTab } from './ConfigureTab';
import FilterColumnsPanel from './Panels/FilterColumnsPanel/FilterColumnsPanel';
import GroupColumnsPanel from './Panels/GroupColumnsPanel';
import SortColumnsPanel from './Panels/SortColumnsPanel';
import VisibleColumnsPanel from './Panels/VisibleColumnsPanel';

export interface ConfigureInspectorProps {
  defaultConfigTab?: ConfigureOption;
  gridProps: GridProps;
  open: boolean;
  handleClose: () => void;
  customSx?: SxProps<Theme>;
  hideColumnsInConfigureInspector?: string[];
}

const containerSx: SxProps<Theme> = {
  display: 'flex',
  flexDirection: 'column',
  flexShrink: 0,
  height: '100%',
  rowGap: 2,
  width: '100%',
  '& .MuiTabs-root': {
    flexShrink: 0,
  },
};

const columnPanelSx: SxProps<Theme> = {
  display: 'flex',
  flexDirection: 'column',
  maxHeight: '100%',
  height: '50%',
  width: '100%',
  flexGrow: 1,
};

const panelContainer: SxProps<Theme> = {
  display: 'flex',
  flexGrow: 1,
  overflowY: 'auto',
};

const buttonContainer: SxProps<Theme> = (theme: Theme) => ({
  height: theme.spacing(7),
});

const ConfigureInspector: React.FC<ConfigureInspectorProps> = (props) => {
  const {
    defaultConfigTab,
    gridProps,
    handleClose,
    open,
    customSx,
    hideColumnsInConfigureInspector,
  } = props;

  const { t, ready } = useTranslation();

  const [activePanelIndex, setActivePanelIndex] = useState<number>(0);
  const [columns, setColumns] = useState<TETODataColumn[]>(
    gridProps.allColumns
      .filter(
        (a) =>
          a.name !== 'grid.teto.action' &&
          !hideColumnsInConfigureInspector?.includes(a.name)
      )
      .sort((a, b) => a.order - b.order)
  );

  const [resetDialog, setResetDialog] = useState<boolean>(false);

  const [filters, setFilters] = useState<TypeFilterValue>(gridProps.filters);

  const [sort, setSort] = useState<TypeSortInfo>(gridProps.sort);

  const [grouping, setGrouping] = useState<TypeGroupBy>(gridProps.groupBy);

  const visibleColumns = useMemo(
    () => columns.filter((a) => !a.hidden),
    [columns]
  );

  const _hideColumn = useCallback(
    (columnName: string) => {
      const columnIndex = columns.findIndex((a) => a.name === columnName);
      if (columnIndex >= 0) {
        const column = { ...columns[columnIndex], hidden: true };
        setColumns((oldVal) => {
          const modVal = [...oldVal];
          modVal.splice(columnIndex, 1, column);
          return modVal;
        });
      }
    },
    [columns]
  );

  const _showColumn = useCallback(
    (columnName: string) => {
      const columnIndex = columns.findIndex((a) => a.name === columnName);
      if (columnIndex >= 0) {
        setColumns((oldCols) => {
          const result = arrayMoveImmutable(
            oldCols,
            columnIndex,
            visibleColumns.length
          ).map((c, i) => {
            if (i === visibleColumns.length) {
              return { ...c, hidden: false };
            }

            return c;
          });
          return result;
        });
      }
    },
    [columns, visibleColumns]
  );

  const _setNewColumnPosition = useCallback(
    (columnName: string, newPos: number) => {
      setColumns((oldCols) => {
        const oldPos = oldCols.findIndex((a) => a.name === columnName);
        const result = arrayMoveImmutable(oldCols, oldPos, newPos).map(
          (c, i) => ({
            ...c,
            order: i,
          })
        );
        return result;
      });
    },
    []
  );

  const _addGroup = useCallback(
    (columnName: string) => {
      setGrouping((ov) => [...(ov as []), columnName]);
      // RDG does weird stuff if you group by a column that is not visible.
      // The grouping header won't show the group but the data will
      // so we need to make sure column is visible
      _showColumn(columnName);
    },
    [_showColumn]
  );

  const _removeGroup = useCallback((columnName: string) => {
    setGrouping((ov) => [...(ov?.filter((a) => a !== columnName) ?? [])]);
  }, []);

  const _changeGroupPosition = useCallback((oldPos: number, newPos: number) => {
    setGrouping((oldGroups) => {
      if (!oldGroups) return [];
      return arrayMoveImmutable(oldGroups, oldPos, newPos);
    });
  }, []);

  const _addSort = useCallback((columnName: string, dir: 0 | 1 | -1) => {
    setSort((ov) => [...(ov as []), { name: columnName, dir }]);
  }, []);

  const _removeSort = useCallback((columnName: string) => {
    setSort((ov) => [
      ...((ov as TypeSingleSortInfo[]).filter((a) => a.name !== columnName) ??
        []),
    ]);
  }, []);

  const _changeSortDirection = useCallback(
    (columnName: string, dir: 1 | 0 | -1) => {
      setSort((oldSort) => {
        if (!oldSort) return [{ dir, name: columnName, id: columnName }];

        const sortItems = (
          !isArray(oldSort)
            ? [oldSort as unknown as TypeSingleSortInfo]
            : oldSort
        ) as TypeSingleSortInfo[];

        const itemIndex = sortItems.findIndex((a) => a.name === columnName);
        if (itemIndex < 0) return sortItems;

        const modVal = [...sortItems] as TypeSingleSortInfo[];
        modVal.splice(itemIndex, 1, { ...sortItems[itemIndex], dir });
        return [...modVal] as TypeSingleSortInfo[];
      });
    },
    []
  );

  const _changeSortPosition = useCallback((oldPos: number, newPos: number) => {
    setSort((oldSrt) => {
      if (!oldSrt) return [];
      return arrayMoveImmutable(oldSrt as TypeSingleSortInfo[], oldPos, newPos);
    });
  }, []);

  const tabs: ConfigureTab[] = useMemo(
    () => [
      {
        tabIcon: <ViewColumnRoundedIcon />,
        tabLabel: 'Columns',
        tabIndex: 0,
        tabPanel: (
          <VisibleColumnsPanel
            columns={columns}
            hideColumn={_hideColumn}
            setNewColumnPosition={_setNewColumnPosition}
            showColumn={_showColumn}
          />
        ),
      },
      {
        tabIcon: <ViewColumnRoundedIcon />,
        tabLabel: 'Groups',
        tabIndex: 1,
        tabPanel: (
          <GroupColumnsPanel
            addGroup={_addGroup}
            changeGroupOrder={_changeGroupPosition}
            columns={columns}
            groups={grouping}
            removeGroup={_removeGroup}
          />
        ),
      },
      {
        tabIcon: <ViewColumnRoundedIcon />,
        tabLabel: 'Sorting',
        tabIndex: 2,
        tabPanel: (
          <SortColumnsPanel
            addSort={_addSort}
            changeSortDirection={_changeSortDirection}
            changeSortPosition={_changeSortPosition}
            columns={columns}
            removeSort={_removeSort}
            sort={sort as TypeSingleSortInfo[]}
          />
        ),
      },
      {
        tabIcon: <ViewColumnRoundedIcon />,
        tabLabel: 'Filters',
        tabIndex: 3,
        tabPanel: (
          <FilterColumnsPanel
            columns={columns}
            disableAltColors
            filters={filters}
            setFilters={setFilters}
          />
        ),
      },
    ],
    [
      columns,
      _hideColumn,
      _showColumn,
      _setNewColumnPosition,
      _addGroup,
      _changeGroupPosition,
      grouping,
      _removeGroup,
      _addSort,
      _changeSortDirection,
      sort,
      _changeSortPosition,
      _removeSort,
      filters,
    ]
  );

  useLayoutEffect(() => {
    if (defaultConfigTab) {
      const defaultIndex = tabs.find(
        (i) => i.tabLabel === defaultConfigTab
      )?.tabIndex;
      setActivePanelIndex(defaultIndex ?? 0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const _handleTabsChange = useCallback(
    (e: React.ChangeEvent<unknown>, newValue: number) => {
      setActivePanelIndex(newValue);
    },
    []
  );

  const _handleSave = useCallback(() => {
    gridProps.updateColumnConfiguration(
      columns.map((c, i) => ({
        columnName: c.name,
        hidden: c.hidden,
        locked: c.locked,
        order: i,
      }))
    );
    gridProps.setFilters(filters);
    gridProps.setSort(sort);
    gridProps.onGroupByChange(grouping);
    handleClose();
  }, [columns, filters, gridProps, grouping, handleClose, sort]);

  return ready ? (
    <Inspector
      // partHistoryModal is 1200 //We want the configure inspector to be on top of this
      containerSx={{ zIndex: 1201, ...customSx }}
      dataTestid="configureInspector"
      onClose={handleClose}
      open={open}
      title={{ text: t('generic.configureGrid') as string }}
    >
      <Box sx={containerSx}>
        <ETOTabs onChange={_handleTabsChange} value={activePanelIndex}>
          {tabs?.map((tb) => (
            <ETOTab
              key={`${tb.tabLabel}-${tb.tabIndex}`}
              label={tb.tabLabel}
              toolTipProps={{
                title: tb.tabLabel,
              }}
            />
          ))}
        </ETOTabs>

        <Divider />

        <Box sx={columnPanelSx}>
          <Grid container sx={panelContainer}>
            <Grid item xs={12}>
              {tabs?.map((tb: ConfigureTab) => (
                <TabPanel
                  index={tb.tabIndex}
                  key={`${tb.tabLabel}-${tb.tabIndex}`}
                  value={activePanelIndex}
                >
                  {tb.tabPanel}
                </TabPanel>
              ))}
            </Grid>
          </Grid>
          <Box sx={buttonContainer}>
            <ButtonStrip
              leftButton={{
                color: 'warning',
                onClick: () => setResetDialog(true),
                text: t('generic.resetGridSettings'),
              }}
              rightButton={{
                color: 'primary',
                onClick: () => _handleSave(),
                text: t('generic.save'),
              }}
              size="medium"
            />
          </Box>
        </Box>
      </Box>
      {resetDialog && (
        <ConfirmDialogs
          content={t('dialogs.resetGrid.content')}
          leftButton={{
            onClick: () => setResetDialog(false),
            label: t('generic.cancel'),
          }}
          open={resetDialog}
          rightButton={{
            onClick: () => {
              gridProps.setDefaults();
              setResetDialog(false);
              handleClose();
            },
            label: t('generic.confirm'),
          }}
          sx={{ zIndex: 6000 }}
          title={t('dialogs.resetGrid.title')}
        />
      )}
    </Inspector>
  ) : (
    <Box />
  );
};

export default ConfigureInspector;
