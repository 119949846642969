import KeyboardArrowDownRoundedIcon from '@mui/icons-material/KeyboardArrowDownRounded';
import MoreVertRoundedIcon from '@mui/icons-material/MoreVertRounded';
import { Box, ListItem, Paper, Typography, useTheme } from '@mui/material';
import {
  ETOIconButton,
  ETOSelectField,
} from '@teto/react-component-library-v2';
import React, { useCallback } from 'react';
import PartHistoryTab from '../PartHistoryTab';
import TabOptionsPopover from './TabOptionPopover/TabOptionsPopover';

interface MobileTabsProps {
  tabs: PartHistoryTab[];
  currentTab: number;
  // eslint-disable-next-line no-unused-vars
  onTabChanged: (index: number) => void;
  // eslint-disable-next-line no-unused-vars
  onInspectorOpen: (index: number) => void;
  // eslint-disable-next-line no-unused-vars
  onExportClicked: (index: number) => void;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const CustomPaper = (props: any) => {
  const theme = useTheme();
  return (
    <Paper
      elevation={2}
      sx={{
        backgroundColor: theme.palette.background.paper,
        [theme.breakpoints.down('md')]: {
          '& .MuiAutocomplete-option[aria-selected="true"].Mui-focused': {
            backgroundColor: `${theme.palette.background.default} !important`,
          },
        },
      }}
      {...props}
    />
  );
};

const MobileTabs = (props: MobileTabsProps) => {
  const { tabs, currentTab, onTabChanged, onExportClicked, onInspectorOpen } =
    props;

  const theme = useTheme();

  const _onSettingsClicked = useCallback(
    () => onInspectorOpen(currentTab),
    [currentTab, onInspectorOpen]
  );

  const _onExportClicked = useCallback(
    () => onExportClicked(currentTab),
    [currentTab, onExportClicked]
  );

  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );

  const _onMoreClicked = (e: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(e.currentTarget);
  };

  if (tabs.length === 0) return <Typography>&nbsp;</Typography>;

  return (
    <Box sx={{ display: 'flex', mb: 2, columnGap: 1 }}>
      <ETOSelectField
        customSx={{
          flex: 1,
          flexGrow: 1,
        }}
        data-testid={`tab-${currentTab}`}
        disableClearable
        handleChange={(e) => onTabChanged(parseInt(e.target.value, 10))}
        itemNameSelector={(a) => a.name}
        items={tabs}
        itemValueSelector={(a) => a.index}
        name="currentTab"
        PaperComponent={CustomPaper}
        popupIcon={<KeyboardArrowDownRoundedIcon />}
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        renderOption={(prps, option, { selected }: any) => (
          <ListItem
            {...prps}
            sx={{
              height: theme.spacing(5),
              width: '100%',
              padding: '0px !important',
              backgroundColor: theme.palette.background.paper,
              '& .Mui-focused': {
                backgroundColor: theme.palette.background.default,
                color: theme.palette.primary.main,
              },
            }}
          >
            <Typography
              sx={{
                display: 'flex',
                alignItems: 'center',
                color: theme.palette.text.secondary,
                width: '100%',
                height: theme.spacing(5),
                padding: `0px ${theme.spacing(2)}`,
                fontWeight: 600,
                ...(selected && { color: theme.palette.primary.main }),
              }}
              variant="subtitle2"
            >
              {option.name}
            </Typography>
          </ListItem>
        )}
        size="small"
        value={currentTab}
      />
      <ETOIconButton
        color="primary"
        customSx={{
          flex: 0,
          flexGrow: 0,
          // eslint-disable-next-line no-shadow
          mr: (theme) => theme.spacing(0.5),
        }}
        onClick={(e: React.MouseEvent<HTMLButtonElement> | undefined) => {
          if (e) {
            _onMoreClicked(e);
          }
        }}
        size="medium"
      >
        <MoreVertRoundedIcon />
      </ETOIconButton>
      <TabOptionsPopover
        anchorEl={anchorEl}
        onExportClicked={_onExportClicked}
        onSettingsClicked={_onSettingsClicked}
        setAnchorEl={setAnchorEl}
      />
    </Box>
  );
};

export default MobileTabs;
