import { TypeGroupBy } from '@inovua/reactdatagrid-enterprise/types';
import DeleteIcon from '@mui/icons-material/Delete';
import {
  Alert,
  Collapse,
  IconButton,
  List,
  SxProps,
  Theme,
  Typography,
} from '@mui/material';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Container } from 'react-smooth-dnd';
import DragItem from '../../../ListItems/DragItem';
import DraggableListItem from '../../../ListItems/DraggableListItem';
import ListItem from '../../../ListItems/ListItem';
import { TETODataColumn } from '../../GridBuilder/types/TETODataColumn';
import SelectColumnEditor from '../SelectColumnEditor/SelectColumnEditor';

const assignedListSx = (theme: Theme) => ({
  flexGrow: 1,
  flexShrink: 1,
  overflowY: 'auto',
  paddingRight: 0.5,
  fontSize: '0.9rem',
  '& .smooth-dnd-draggable-wrapper': {
    borderRadius: `${theme.shape.borderRadius}px`,
  },
  '& .smooth-dnd-draggable-wrapper:nth-of-type(even)': {
    backgroundColor: theme.palette.background.default,
  },
});

const altColorSx: SxProps<Theme> = {
  backgroundColor: (theme) => theme.palette.background.default,
  color: (theme) =>
    theme.palette.getContrastText(theme.palette.background.default),
};

interface GroupColumnsPanelProps {
  groups: TypeGroupBy;
  columns: TETODataColumn[];
  // eslint-disable-next-line no-unused-vars
  addGroup: (columnName: string) => void;
  // eslint-disable-next-line no-unused-vars
  removeGroup: (columnName: string) => void;
  // eslint-disable-next-line no-unused-vars
  changeGroupOrder: (oldIndex: number, newIndex: number) => void;
}

const GroupColumnsPanel: React.FC<GroupColumnsPanelProps> = (props) => {
  const { groups, addGroup, removeGroup, changeGroupOrder, columns } = props;
  const { t } = useTranslation();

  const _groupableColumns = useMemo(
    () => columns.filter((col) => col.disableGrouping !== true),
    [columns]
  );

  const groupLength = useMemo(() => (groups ? groups.length : 0), [groups]);

  return (
    <List disablePadding sx={assignedListSx}>
      <Container
        data-testid="configureInspectorListItemContainer"
        dragHandleSelector=".drag-handle"
        lockAxis="y"
        onDrop={(e: {
          removedIndex: number | null;
          addedIndex: number | null;
        }) => {
          if (e.removedIndex != null && e.addedIndex != null)
            // -1 due to the column selector being part of the list
            changeGroupOrder(e.removedIndex - 1, e.addedIndex - 1);
        }}
      >
        <ListItem
          centerChild={
            <SelectColumnEditor
              columns={_groupableColumns}
              disabled={groupLength >= 4}
              isValueDisabled={(node) =>
                Boolean(groups && groups.indexOf(node.column.name) >= 0)
              }
              onValueChanged={(value) => addGroup(value.column.name)}
            />
          }
          customSx={(theme) => ({
            p: 0,
            pb: theme.spacing(1),
          })}
          size="medium"
        />

        <div>
          <Collapse in={groupLength >= 4} sx={{ mb: 1 }}>
            <Alert severity="warning" variant="filled">
              {t('generic.warningMessage.maximumGroupLimit')}
            </Alert>
          </Collapse>
        </div>

        {(groups ?? []).map((col, ind) => (
          <DraggableListItem
            centerChild={
              <Typography
                color="textPrimary"
                noWrap
                textOverflow="ellipsis"
                variant="body1"
                width="100%"
              >
                {t(columns.find((a) => a.name === col)?.title ?? '')}
              </Typography>
            }
            customSx={{
              ...(ind % 2 === 0 ? altColorSx : {}),
              ...{
                cursor: 'pointer',
              },
            }}
            key={col}
            leftChild={
              <DragItem size="large" title={t('generic.changeOrder')} />
            }
            rightChild={
              <IconButton onClick={() => removeGroup(col)}>
                <DeleteIcon color="error" />
              </IconButton>
            }
            size="small"
            testId="configureInspectorListItem"
          />
        ))}
      </Container>
    </List>
  );
};

export default GroupColumnsPanel;
