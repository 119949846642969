import dayjs, { Dayjs } from 'dayjs';

export type CustomValidatorType =
  // eslint-disable-next-line no-unused-vars, @typescript-eslint/no-explicit-any
  | ((data: any) => string | undefined)
  // eslint-disable-next-line no-unused-vars, @typescript-eslint/no-explicit-any
  | ((data: any) => Promise<string | undefined>);
export type RequiredValidatorType = 'REQUIRED';
export type RangeValidatorType =
  | `RANGE:::${number}`
  | `RANGE:::${number}:::${number}`
  | `RANGE:::${number}:::${number};;;${string}`
  | `RANGE:::${string}`
  | `RANGE:::${string};;;${string}`
  | `RANGE:::${string}:::${string}`
  | `RANGE:::${string}:::${string};;;${string}`;
export type REGEXValidatorType =
  | `REGEX:::${string}`
  | `REGEX:::${string};;;${string}`;
export type LengthValidatorType =
  | `LENGTH:::${number}`
  | `LENGTH:::${number};;;${string}`
  | `LENGTH:::${number}:::${number}`
  | `LENGTH:::${number}:::${number};;;${string}`;

export const ValidationTypes = {
  REQUIRED: 'REQUIRED',
  RANGE: 'RANGE',
  REGEX: 'REGEX',
  LENGTH: 'LENGTH',
  CUSTOM: 'CUSTOM',
} as const;

export type ValidatorPrefixes = keyof typeof ValidationTypes;

export const REQUIRED = (): RequiredValidatorType => 'REQUIRED';
export const STRING_LENGTH = (
  max: number,
  min?: number,
  validationMessage?: string
): LengthValidatorType =>
  min
    ? `LENGTH:::${max}:::${min};;;${validationMessage ?? ''}`
    : `LENGTH:::${max};;;${validationMessage ?? ''}`;

const rangeValueFormatter = (rawVal: number | string | Dayjs | Date) => {
  if (dayjs.isDayjs(rawVal)) {
    const formatted = rawVal.format('YYYY-MM-DD HH:mm:ss');
    return formatted;
  }
  if (rawVal instanceof Date) {
    const formatted = dayjs(rawVal).format('YYYY-MM-DD HH:mm:ss');
    return formatted;
  }
  return rawVal.toString();
};

export const RANGE = (
  min: number | string | Dayjs | Date,
  max?: number | string | Dayjs | Date,
  validationMessage?: string
): RangeValidatorType => {
  if (max !== undefined) {
    const firstVal = rangeValueFormatter(min);
    const secondVal = rangeValueFormatter(max);

    return `RANGE:::${firstVal}:::${secondVal};;;${validationMessage ?? ''}`;
  }

  return `RANGE:::${rangeValueFormatter(min)};;;${validationMessage ?? ''}`;
};

export const REGEX = (
  regex: RegExp | string,
  validationMessage?: string
): REGEXValidatorType => `REGEX:::${regex};;;${validationMessage ?? ''}`;
