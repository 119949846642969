const updateCompanyDocumentMutation = `mutation updateCompanyDocumentMutation ($input: UpdateCompanyDocumentsInput!){
  updateCompanyDocuments (input: $input) {
    items {
      id
      documentLocation
    }
  }
}
`;

export default updateCompanyDocumentMutation;
