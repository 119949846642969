import { Box, CircularProgress } from '@mui/material';
import { FormikProps } from 'formik';
import { debounce } from 'lodash';
import React, { useCallback, useEffect } from 'react';

interface AutoSaveProps<T> {
  debounceMs?: number;
  formik: FormikProps<T>;
  top?: number;
}

// eslint-disable-next-line react/function-component-definition
function AutoSave<T>(props: AutoSaveProps<T>) {
  const { debounceMs = 3000, formik, top } = props;

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedSubmit = useCallback(
    debounce(() => formik.submitForm(), debounceMs),
    [debounceMs]
  );

  useEffect(() => {
    if (formik.dirty && formik.isValid && !formik.isValidating) {
      debouncedSubmit();
    }
  }, [
    debouncedSubmit,
    formik.dirty,
    formik.isValid,
    formik.isValidating,
    formik.values,
  ]);

  return (
    <>
      {formik.isSubmitting && (
        <Box
          data-testid="auto-save"
          sx={(theme) => ({
            position: 'absolute',
            top: theme.spacing(top ?? 3),
            right: theme.spacing(13.5),
          })}
        >
          <CircularProgress size={20} />
        </Box>
      )}
    </>
  );
}

export default AutoSave;
