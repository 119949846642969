/* eslint-disable no-shadow */

import { t } from 'i18next';
import { Dispatch, SetStateAction } from 'react';
import buildMutation, {
  QueryStringType,
} from '../../../../../api/graphQL/buildMutation';
import EditingState from '../../../types/EditingState';

const onAdd = <T>(
  /* eslint-disable no-unused-vars */
  value: unknown,
  variables: {
    [key: string]: unknown;
  },
  queryString: QueryStringType,
  formatValue: (value: unknown) => T | undefined | T[],
  setAccordionListItems: Dispatch<SetStateAction<T[]>>,
  setHasASaveOccurred: (value: boolean) => void,
  setSelectedItem: Dispatch<SetStateAction<T | undefined>>,
  setEditingState: (payload: EditingState) => void,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  messageContext: any,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  errorCallback: (error?: any) => void,
  setIsNewItemOpen: (value: boolean) => void
) => {
  buildMutation({
    messageContext,
    queryString,
    variables,
    callback: (newVal: unknown) => {
      const formattedResult = formatValue(newVal);
      if (!formattedResult) {
        messageContext.setError(t('generic.message.invalidQueryKey'));
        return setEditingState({
          isEditing: false,
          hasEdited: true,
          tabError: true,
        });
      }
      if (typeof formattedResult === 'object') {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        setAccordionListItems((d: any) => [...d, formattedResult]);
      }
      if (Array.isArray(formattedResult)) {
        setAccordionListItems(formattedResult);
      }
      setIsNewItemOpen(false);
      setHasASaveOccurred(true);
      setSelectedItem(undefined);
      setEditingState({
        isEditing: false,
        hasEdited: false,
        tabError: false,
      });
    },
    errorCallback: (e) => {
      errorCallback(e);
    },
  });
};
const onUpdate = <T>(
  /* eslint-disable no-unused-vars */
  value: unknown,
  variables: {
    [key: string]: unknown;
  },
  queryString: QueryStringType,
  formatValue: (value: unknown) => T | undefined | T[],
  setAccordionListItems: Dispatch<SetStateAction<T[]>>,
  setHasASaveOccurred: (value: boolean) => void,
  selectedItem: T | undefined,
  setSelectedItem: Dispatch<SetStateAction<T | undefined>>,
  setEditingState: (payload: EditingState) => void,
  accordionListItems: T[],
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  messageContext: any,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  errorCallback: (error?: any) => void
  /* eslint-enable no-unused-vars */
) => {
  buildMutation({
    messageContext,
    queryString,
    variables,
    callback: (newVal: unknown) => {
      const formattedResult = formatValue(newVal);
      if (!formattedResult) {
        messageContext.setError(t('generic.message.errorFormatting'));
        return setEditingState({
          isEditing: false,
          hasEdited: true,
          tabError: true,
        });
      }
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      let list: any[] = [];

      if (typeof formattedResult === 'object') {
        list = accordionListItems.map((item) => {
          if (
            typeof item === 'object' &&
            item &&
            'id' in item &&
            selectedItem &&
            typeof selectedItem === 'object' &&
            'id' in selectedItem
          ) {
            if (item?.id === selectedItem?.id) {
              return formattedResult;
            }
          }
          return item;
        });
      }
      if (Array.isArray(formattedResult)) {
        list = formattedResult;
      }
      const selected = list.find((item) => {
        if (
          typeof item === 'object' &&
          item &&
          'id' in item &&
          selectedItem &&
          typeof selectedItem === 'object' &&
          'id' in selectedItem
        ) {
          return item?.id === selectedItem?.id;
        }
        return false;
      });

      setAccordionListItems(list);
      if (selected) {
        setSelectedItem(selected); // fix for array
        // setSelectedItem(formattedResult); // fix for array
      }
      setHasASaveOccurred(true);
      setEditingState({
        isEditing: false,
        hasEdited: false,
        tabError: false,
      });
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    errorCallback: (e: any) => {
      errorCallback(e);
    },
  });
};

export { onAdd, onUpdate };
