import { gql } from '../../../../__generated__';

const preservePOOrderMutation = gql(`
  mutation updatePurchaseOrderDetailOrder($input: UpdatePurchaseOrderDetailOrderInput) {
    updatePurchaseOrderDetailOrder(input: $input) {
      items {
        id
        itemId
        purchaseOrderId
      }
    }
  }
`);

export default preservePOOrderMutation;
