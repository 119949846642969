import { TextField } from '@mui/material';
import {
  formatHoursAsHourMin,
  formatInputAshours,
} from '@teto/react-component-library-v2';
import React, { ChangeEvent, useCallback, useMemo, useState } from 'react';
import useIsMobile from '../../../hooks/useIsMobile';
import { CommonEditorProps } from './EditorProps';
import EditorWrapper from './EditorWrapper';
import TooltipWrapper from './TooltipWrapper';

const inputRegex = /^-?\d+(\d*|:[0-5]?[0-9]?|\.\d*|h|m)$|^-$/i;

const ETOHourEditor: React.FC<CommonEditorProps> = (props) => {
  const {
    cellProps,
    editorProps,
    errors,
    inputVariant,
    onCancel,
    onChange,
    onComplete,
    onTabNavigation,
    value,
  } = props;

  const isGridInMobile = useIsMobile();

  const error = useMemo(
    () => (errors && errors.length > 0 ? errors[0] : undefined),
    [errors]
  );

  const isDisabled = useMemo(() => {
    if (!editorProps?.disabled) return false;
    if (typeof editorProps?.disabled === 'function')
      return editorProps?.disabled(cellProps?.data);
    return editorProps?.disabled;
  }, [cellProps?.data, editorProps]);

  const formatStartValue = (val: string | number) =>
    formatHoursAsHourMin(typeof val === 'string' ? parseFloat(val) : val);

  const [input, setInput] = useState<string>(formatStartValue(value ?? 0));

  const _handleChange = useCallback(
    (e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
      const text = e.target.value;
      if (inputRegex.test(text)) {
        setInput(text);
        onChange(formatInputAshours(text));
      } else if (text === '') {
        setInput('');
        onChange(0);
      }
    },
    [onChange]
  );

  const _handleKeydown = useCallback(
    (e: React.KeyboardEvent<HTMLTextAreaElement | HTMLDivElement>) => {
      switch (e.key) {
        case 'Escape':
          onCancel?.(e);
          e.stopPropagation();
          break;
        case 'Enter':
          onComplete?.();
          e.stopPropagation();
          break;
        case 'Tab':
          // e.preventDefault(); // do not enable this, it breaks tab navigation for the add row, verified this doesnt break anything

          onTabNavigation?.(true, e.shiftKey ? -1 : 1);
          break;
        default:
          break;
      }
    },
    [onCancel, onComplete, onTabNavigation]
  );

  const editor = (
    <TextField
      autoComplete="off"
      autoFocus
      data-testid="ETOHourEditor"
      disabled={isDisabled}
      error={Boolean(error)}
      fullWidth
      helperText={error}
      id={cellProps?.name}
      inputProps={{
        autoComplete: 'new-password',
        onFocus: (e) => e.currentTarget.select(),
        onKeyDown: (e) => _handleKeydown(e),
        style: {
          minWidth: Math.max(0, (cellProps?.computedWidth as number) - 30),
          height: Math.max(0, (cellProps?.rowHeight as number) - 2),
        },
        tabIndex: 0,
      }}
      name={cellProps?.name}
      onBlur={(e) => {
        onComplete(e);
      }}
      onChange={(e) => _handleChange(e)}
      onFocus={(e) => e.currentTarget.select()}
      onKeyDown={_handleKeydown}
      size="small"
      tabIndex={0}
      value={input}
      variant={inputVariant}
    />
  );

  return cellProps?.data !== null ? (
    <EditorWrapper>
      {isGridInMobile && (
        <TooltipWrapper error={error}>{editor}</TooltipWrapper>
      )}
      {!isGridInMobile && !isDisabled && editor}
    </EditorWrapper>
  ) : null;
};

export default ETOHourEditor;
