import { gql } from '../../../../__generated__';

const employeesQuery = gql(`
  query poHeaderEmployees {
    employees (where: { }, order: {active: DESC, firstName: ASC, lastName: ASC}) {
      items {
        active
        employeeNumber
        id
        firstName
        lastName
        name
      }
    }
  }`);

export default employeesQuery;
