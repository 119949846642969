const getAPTaxCategoriesQuery = `
query getAPTaxCategoriesQuery {
  aPTaxCategories {
    items {
      id
      name
    }
  }
}
`;

export default getAPTaxCategoriesQuery;
