import { Box, SxProps, Theme } from '@mui/material';
import React from 'react';

interface InspectorTabPanelProps {
  children: React.ReactNode;
  customSx?: SxProps<Theme>;
  index: number;
  value: number;
}

const InspectorTabPanel = (props: InspectorTabPanelProps) => {
  const { children, customSx, value, index, ...other } = props;
  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {value === index && (
        <Box
          role="tabpanel"
          sx={{ flexGrow: 1, overflowY: 'hidden', ...customSx }}
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...other}
        >
          {children}
        </Box>
      )}
    </>
  );
};

export default InspectorTabPanel;
