import { InputBaseComponentProps } from '@mui/material/InputBase';
import {
  ETOTextField,
  ETOTextFieldProps,
} from '@teto/react-component-library-v2';
import React, { useEffect, useMemo, useState } from 'react';

export interface PercentInputProps
  extends Omit<ETOTextFieldProps, 'handleChange' | 'onBlur' | 'type'> {
  // eslint-disable-next-line no-unused-vars
  handleChange: (v: { key: string; value: number | null }) => void;
  shouldHandleValueAsPercentage?: boolean;
}

const NUMBER_REGEX =
  /^(99(?:\.0{0,6})?|0(?:\.\d{1,6})?|\d{0,2}(?:\.\d{0,6})?)?%?$/;

export const formatDisplayValue = (val: number | undefined) => {
  const percentValue = (val ?? 0) * 100;
  const displayValue = parseFloat(
    percentValue.toLocaleString('en', {
      maximumFractionDigits: percentValue < 1 ? 6 : 2,
    })
  );
  return displayValue === 100 ? 99.99 : displayValue;
};

const PercentInput = (props: PercentInputProps) => {
  const { handleChange, name, value, shouldHandleValueAsPercentage, ...rest } =
    props;

  const [input, setInput] = useState<{
    display: string | number;
    value: number;
  }>(() => ({
    display: `${formatDisplayValue(value)}`,
    value: value ?? 0,
  }));
  const inputPropsValues = useMemo(
    () =>
      ({
        step: '0.1',
        min: 0,
        pattern: NUMBER_REGEX,
        autoComplete: 'off',
      } as InputBaseComponentProps),
    []
  );

  useEffect(() => {
    if ((input.value === 0 || !input.value) && value !== 0) {
      setInput({
        display: `${formatDisplayValue(value)}`,
        value,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  return (
    <ETOTextField
      handleChange={({ target: { value: newValue } }) => {
        if (NUMBER_REGEX.test(newValue)) {
          const val = parseFloat(newValue);
          const formikValue = Number.isNaN(val) ? 0 : val;
          const formattedValue = parseFloat((formikValue / 100).toFixed(8));
          setInput({
            display: newValue,
            value: formattedValue,
          });
          handleChange({ key: name, value: formattedValue });
        }
      }}
      InputProps={{
        endAdornment: '%',
      }}
      // eslint-disable-next-line react/jsx-no-duplicate-props
      inputProps={inputPropsValues}
      name={name}
      onBlur={() => {
        setInput((prev) => ({
          ...prev,
          display: `${formatDisplayValue(prev.value)}`,
        }));
      }}
      onFocus={() => {
        setInput((prev) => ({
          ...prev,
          display: formatDisplayValue(prev.value),
        }));
      }}
      size="small"
      value={input.display}
      {...rest}
    />
  );
};

export default PercentInput;
