import { gql } from '../../../../__generated__';

const addPSDetailMutation =
  gql(`mutation addProcessScheduleDetail($input: AddProcessScheduleDetailRequestInput) {
    addProcessScheduleDetail(input: $input) {
      id
    }
}`);

export default addPSDetailMutation;
