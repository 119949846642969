import { Box, SxProps, Theme, Tooltip } from '@mui/material';
import React, { useMemo } from 'react';
import { EditedValue } from '../types/EditedValue';

interface FormatterErrorWrapperProps {
  value?: EditedValue;
  children: React.ReactNode;
}

const errorSx: SxProps<Theme> = (theme: Theme) => ({
  border: `2px solid ${theme.palette.error.main}`,
  color: theme.palette.error.main,
  padding: '6px',
  boxSizing: 'border-box',
  position: 'absolute',
  top: 0,
  left: 0,
  margin: 0,
  width: '100%',
  textOverflow: 'ellipsis',
  overflow: 'hidden',
  height: '100%',
});
const warningSx: SxProps<Theme> = (theme: Theme) => ({
  border: `2px solid ${theme.palette.warning.main}`,
  color: theme.palette.warning.main,
  padding: '6px',
  boxSizing: 'border-box',
  position: 'absolute',
  top: 0,
  left: 0,
  margin: 0,
  width: '100%',
  textOverflow: 'ellipsis',
  overflow: 'hidden',
  borderRadius: '2px',
  height: '100%',
});

const tooltipSx: SxProps<Theme> = {
  '& .MuiTooltip-tooltip': {
    fontSize: '0.9rem',
  },
};

const FormatterErrorWrapper = (props: FormatterErrorWrapperProps) => {
  const { children, value } = props;

  const hasError = value && !value?.isValid;

  const styleType = useMemo(() => {
    if (value?.validationMessages && value.validationMessages.length > 0)
      return errorSx;
    if (value?.warningMessages && value.warningMessages.length > 0)
      return warningSx;
    return undefined;
  }, [value?.validationMessages, value?.warningMessages]);

  const message = useMemo(() => {
    if (value?.validationMessages && value.validationMessages.length > 0)
      return value.validationMessages[0];
    if (value?.warningMessages && value.warningMessages.length > 0)
      return value.warningMessages[0];
    return undefined;
  }, [value?.validationMessages, value?.warningMessages]);

  return (
    <>
      {hasError && (
        <Tooltip arrow PopperProps={{ sx: tooltipSx }} title={message}>
          <Box sx={styleType}>{children}</Box>
        </Tooltip>
      )}
      {!hasError && children}
    </>
  );
};

export default React.memo(FormatterErrorWrapper);
