import { useAtom, useSetAtom } from 'jotai';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import PartHistory from '../../PartsHistory/PartsHistory';
import { BreadCrumbSharedState } from '../StateContainers/BreadCrumbModalState';
import { PartHistorySharedState } from '../StateContainers/PartHistoryState';

const useSharedPartHistory = () => {
  const { t } = useTranslation();
  const [partHistory] = useAtom(PartHistorySharedState);
  const setLevels = useSetAtom(BreadCrumbSharedState);

  useEffect(() => {
    if (partHistory.open) {
      const pHLevel = {
        name: partHistory.part?.label
          ? `${t('entities:Part.Part')} History: ${partHistory.part?.label}`
          : `${t('entities:Part.Part')} History`,
        data: partHistory.part,
        component: (
          <PartHistory part={partHistory.part} tab={partHistory.tab} />
        ),
      };

      setLevels((prev) => [...prev, pHLevel]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [partHistory.open, partHistory.part]);
};

export default useSharedPartHistory;
