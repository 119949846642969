import { gql } from '../../../../__generated__';

export default gql(`
    mutation updateRFQDetailOrder($input: UpdateRFQDetailOrderInput) {
      updateRFQDetailOrder(input: $input){
        items {
          id
        }
      }
    }
  `);
