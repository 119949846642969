import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import LanguageIcon from '@mui/icons-material/Language';
import { Link, Typography } from '@mui/material';
import { ETOButton, MessageContext } from '@teto/react-component-library-v2';
import React, {
  useCallback,
  useContext,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { getGraphQLClient } from 'teto-client-api';

import { TypedDocumentNode } from '../../../../api/graphQL/types/TypeDocumentNode';
import NoRecordsFound from '../../NonConformanceInspector/components/NoRecordsFound/NoRecordsFound';
import { InspectorActions } from '../../types/InspectorActionTypes';
import { InspectorState } from '../../types/InspectorState';
import AccordionList from '../AccordionList/AccordionList';
import PanelWithNewItemPopover from '../AddEditInspector/AddEditList/PanelWithNewItemPopover';
import CommonEditDocument from './CommonEditDocument';

interface GenericDocument {
  documentLocation: string;
  fileExtension?: string;
  fileName?: string;
  fileSize: number;
  id: number;
  pathType: string;
  [x: `${string}Id`]: number;
}
export type TDocumentsPanelProps = Pick<
  InspectorActions<GenericDocument>,
  'setEditingState' | 'setHasASaveOccurred' | 'setIsCreatingNewItem'
> &
  Pick<InspectorState<GenericDocument>, 'editingState'>;

export type DocumentsTabPanelProps = Omit<
  DocumentsPanelProps,
  | 'addNewMutation'
  | 'deleteMutation'
  | 'entityName'
  | 'entityQuery'
  | 'permissions'
  | 'updateMutation'
>;
export interface DocumentsPanelProps extends TDocumentsPanelProps {
  /* eslint-disable @typescript-eslint/no-explicit-any */
  addNewMutation: string | TypedDocumentNode<any, any>;
  deleteMutation: string | TypedDocumentNode<any, any>;
  entityIdValue: number | undefined;
  entityQuery: string | TypedDocumentNode<any, any>;
  /* eslint-enable */
  entityName: string;
  permissions: {
    canAddDocuments: boolean;
    canDeleteDocuments: boolean;
    canModifyDocuments: boolean;
  };
  updateMutation: string;
  shouldSupportBatch?: boolean;
  shouldSupportMultipleLocations?: boolean;
  includeEntityIdInBatchMutation?: boolean;
}

const FileTypeIcon: React.FC<{ file: GenericDocument }> = (props) => {
  const { file } = props;
  return (
    <div
      style={{
        position: 'relative',
        display: 'inline-flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      {(file.pathType === 'LOCAL' || file.pathType === 'PDM') && (
        <>
          <InsertDriveFileIcon style={{ fontSize: '2.5em' }} />
          <Typography
            component="span"
            sx={{
              position: 'absolute',
              lineHeight: 1,
              color: (theme) =>
                theme.palette.getContrastText(theme.palette.text.primary),
              top: '1.5em',
              fontSize: '0.8em',
            }}
          >
            {file.fileExtension?.substring(1, 5)}
          </Typography>
        </>
      )}
      {file.pathType === 'WEB' && (
        <LanguageIcon style={{ fontSize: '2.5em' }} />
      )}
    </div>
  );
};

const _formatFileSize = (sizeInBytes: number) => {
  if (sizeInBytes < 1024) return `${sizeInBytes.toFixed(2)} Bytes`;
  if (sizeInBytes < 1024 * 1024)
    return `${(sizeInBytes / 1024).toFixed(2)} Kilobytes`;
  return `${(sizeInBytes / 1024 / 1024).toFixed(2)} Megabytes`;
};

const _formatWebLink = (link: string) => {
  if (!link) return '';
  if (link.startsWith('https://') || link.startsWith('http://')) {
    return link;
  }
  return `https://${link}`;
};

const CommonDocumentsPanel = (props: DocumentsPanelProps) => {
  const {
    addNewMutation,
    deleteMutation,
    editingState,
    entityIdValue,
    entityName,
    entityQuery,
    includeEntityIdInBatchMutation,
    permissions,
    setEditingState,
    setHasASaveOccurred,
    setIsCreatingNewItem,
    shouldSupportBatch,
    shouldSupportMultipleLocations,
    updateMutation,
  } = props;

  const { t } = useTranslation();

  const messageContext = useContext(MessageContext);
  const buttonRef = useRef<HTMLButtonElement | null>(null);

  const [isNewDocumentOpen, setIsNewDocumentOpen] = useState<boolean>(false);
  const [selectedDocumentItem, setSelectedDocumentItem] =
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    useState<any | undefined>();
  const [documents, setDocuments] = useState<GenericDocument[]>([]);
  const [confirmAbandonForm, setConfirmAbandonForm] = useState<boolean>(false);

  React.useEffect(
    () => setIsCreatingNewItem(isNewDocumentOpen),
    [isNewDocumentOpen, setIsCreatingNewItem]
  );

  const _updateRecords = useCallback(() => {
    if (entityIdValue) {
      getGraphQLClient()
        .performQuery(entityQuery, {
          [`${entityName.charAt(0).toLowerCase() + entityName.slice(1)}Id`]:
            entityIdValue,
        })
        .then((e) => {
          if (e.hasData()) {
            const { data } = e;
            const findSelectedDocument = data[
              `${
                entityName.charAt(0).toLowerCase() + entityName.slice(1)
              }Documents` ||
                `${
                  entityName.charAt(0).toLowerCase() + entityName.slice(1)
                }Document`
            ].items.find(
              (d: GenericDocument) => d.id === selectedDocumentItem?.id
            );
            if (findSelectedDocument) {
              setSelectedDocumentItem(findSelectedDocument);
            }
            setDocuments(
              data[
                `${
                  entityName.charAt(0).toLowerCase() + entityName.slice(1)
                }Documents`
              ].items
            );
          }
          if (e.hasError()) {
            e.handleAllErrors(messageContext.setError);
          }
        });
    }
  }, [
    entityIdValue,
    entityName,
    entityQuery,
    messageContext.setError,
    selectedDocumentItem,
  ]);

  React.useEffect(() => {
    _updateRecords();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [entityIdValue]);

  const listItems = useMemo(
    () =>
      documents.map((a) => {
        const docSplit = a.documentLocation.split('#');
        return {
          ...a,
          id: a.id,
          title: docSplit[0],
          icon: <FileTypeIcon file={a} />,
          subTitles: [
            <>
              {(a.pathType === 'LOCAL' || a.pathType === 'PDM') && (
                <>
                  <b>{t('generic.download')} : </b>
                  <Link
                    href={`/api/sharedFiles/${entityName}Documents/${a.id}`}
                    sx={{ textOverflow: 'ellipsis' }}
                    title={docSplit[1]}
                  >
                    {a.fileName ?? docSplit[1]?.substring(0, 50)}
                  </Link>
                  {a.fileSize > 0 && (
                    <Typography component="span">
                      &nbsp;[{_formatFileSize(a.fileSize)}]
                    </Typography>
                  )}
                </>
              )}
              {a.pathType === 'WEB' && (
                <>
                  <b>{t('generic.open')} :</b>
                  <Link
                    href={_formatWebLink(docSplit[1])}
                    sx={{ textOverflow: 'ellipsis' }}
                    target="_blank"
                    title={docSplit[1]}
                  >
                    {docSplit[1]?.substring(0, 50)}
                  </Link>
                </>
              )}
            </>,
          ],
        };
      }),
    [documents, entityName, t]
  );

  const _onDocumentSaved = useCallback(() => {
    _updateRecords();
    setHasASaveOccurred(true);
    setIsNewDocumentOpen(false);
  }, [_updateRecords, setHasASaveOccurred]);

  const _handleAbandonForm = useCallback(
    (closeForm?: boolean) => {
      if (closeForm && isNewDocumentOpen) setIsNewDocumentOpen(false);
      setConfirmAbandonForm(false);
    },
    [isNewDocumentOpen]
  );

  return (
    <PanelWithNewItemPopover
      addButtonRef={buttonRef}
      addItemButton={
        <ETOButton
          buttonProps={{
            ref: buttonRef,
          }}
          color="primary"
          disabled={!permissions.canAddDocuments}
          onClick={() => setIsNewDocumentOpen(true)}
          size="medium"
        >
          {t('generic.add')} {t('generic.document')}
        </ETOButton>
      }
      editingState={editingState}
      isNewItemOpen={isNewDocumentOpen}
      newItemComponent={
        entityIdValue && (
          <CommonEditDocument
            addNewMutation={addNewMutation}
            confirmAbandonForm={confirmAbandonForm}
            deleteMutation={deleteMutation}
            entityIdValue={entityIdValue}
            entityName={entityName}
            includeEntityIdInBatchMutation={includeEntityIdInBatchMutation}
            onRecordCountChanged={_updateRecords}
            onSaveSuccess={_onDocumentSaved}
            permissions={permissions}
            record={undefined}
            setConfirmAbandonForm={_handleAbandonForm}
            setPanelEditingState={setEditingState}
            setSelectedDocumentItem={setSelectedDocumentItem}
            shouldSupportBatch={shouldSupportBatch}
            shouldSupportMultipleLocations={shouldSupportMultipleLocations}
            updateMutation={updateMutation}
          />
        )
      }
      setConfirmAbandonForm={setConfirmAbandonForm}
      setIsNewItemOpen={() => setIsNewDocumentOpen(false)}
      verticalPosition={230}
    >
      {documents.length === 0 && <NoRecordsFound />}
      {documents.length > 0 && entityIdValue && (
        <AccordionList
          editingState={editingState}
          expandedComponent={
            <CommonEditDocument
              addNewMutation={addNewMutation}
              confirmAbandonForm={confirmAbandonForm}
              deleteMutation={deleteMutation}
              entityIdValue={entityIdValue}
              entityName={entityName}
              includeEntityIdInBatchMutation={includeEntityIdInBatchMutation}
              onRecordCountChanged={_updateRecords}
              onSaveSuccess={_onDocumentSaved}
              permissions={permissions}
              record={selectedDocumentItem}
              setConfirmAbandonForm={_handleAbandonForm}
              setPanelEditingState={setEditingState}
              setSelectedDocumentItem={setSelectedDocumentItem}
              shouldSupportBatch={shouldSupportBatch}
              updateMutation={updateMutation}
            />
          }
          expandedItem={selectedDocumentItem}
          icon={<InsertDriveFileIcon />}
          listItems={listItems}
          onItemExpandStateChanged={(d) => setSelectedDocumentItem(d)}
          setConfirmAbandonForm={setConfirmAbandonForm}
        />
      )}
    </PanelWithNewItemPopover>
  );
};

export default CommonDocumentsPanel;
