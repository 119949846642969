import ContentCopyRoundedIcon from '@mui/icons-material/ContentCopyRounded';
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  TextField,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material';
import { MessageContext } from '@teto/react-component-library-v2';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getGraphQLClient } from 'teto-client-api';
import { gql } from '../../../../../__generated__';

const generateMFAMutation = gql(`mutation generateMyMFA {
  generateMyMFA {
    mFAEnabled
    qRCodeSetupImageUrl
    qRCodeManualKey
  }
}
`);

const enableMFAMutation = gql(`mutation enableMFA {
  enableMyMFA {
    success
  }
}`);

const generateMFAPublicMutation =
  gql(`mutation generateMyMFAPublic($userName: String!, $password: String!) {
  generateMyMFAPublic(input: { userName: $userName, password: $password }) {
    mFAEnabled
    qRCodeSetupImageUrl
    qRCodeManualKey
  }
}
`);

type MFADialogProps =
  | {
      required: boolean;
      mode: 'enable' | 'disable';
      employeeMFAEnabled: boolean;
      onSuccess: () => void;
      onClose: () => void;
    }
  | {
      mode: 'enable-public';
      userName: string;
      password: string;
      onSuccess: () => void;
    };

const MFADialog: React.FC<MFADialogProps> = (props) => {
  const { mode, onSuccess } = props;

  const { t } = useTranslation();

  const theme = useTheme();

  const [mfaQRCode, setMFAQRCode] = useState<string>('');
  const [mfaManualKey, setMFAManualKey] = useState<string>('');
  const [tooltip, setTooltip] = useState<boolean>(false);
  const messageContext = useContext(MessageContext);
  const { setError } = messageContext;

  useEffect(() => {
    getGraphQLClient()
      .performMutation(
        mode === 'enable-public'
          ? // eslint-disable-next-line @typescript-eslint/no-explicit-any
            (generateMFAPublicMutation as any)
          : generateMFAMutation,
        mode === 'enable-public'
          ? {
              userName: props.userName,
              password: props.password,
            }
          : {}
      )
      .then((d) => {
        if (d.hasError()) {
          d.handleAllErrors(setError);
        } else {
          const { qRCodeSetupImageUrl, qRCodeManualKey } =
            mode === 'enable-public'
              ? d.data.generateMyMFAPublic
              : d.data.generateMyMFA;
          setMFAQRCode(qRCodeSetupImageUrl);
          setMFAManualKey(qRCodeManualKey);
        }
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const _handleMFAEnableClick = () => {
    getGraphQLClient()
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      .performMutation(enableMFAMutation as any)
      .then((d) => {
        if (d.hasError()) {
          d.handleAllErrors(messageContext.setError);
        } else {
          const { success } = d.data.enableMyMFA;
          if (success) {
            if (onSuccess) onSuccess();
            messageContext.setSuccess(t('generic.message.mfaEnabled'));
          }
        }
      });
  };

  const _handleCopyText = () => {
    navigator.clipboard.writeText(mfaManualKey);
    messageContext.setInfo('Text copied');
  };

  const handleClose = () => {
    if (props.mode === 'enable-public' || props.required) {
      setTooltip(true);
    } else if (props.onClose) props.onClose();
  };

  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {mfaQRCode && mfaManualKey && (
        <Dialog onClose={handleClose} open>
          <DialogTitle>
            {mode === 'disable'
              ? 'Disable Multi-factor Authentication'
              : 'Enable Multi-factor Authentication'}
          </DialogTitle>
          <DialogContent dividers>
            <Box
              sx={{
                display: 'flex',
                width: '100%',
                [theme.breakpoints.down('md')]: {
                  flexDirection: 'column',
                },
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  minWidth: '50%',
                }}
              >
                <Typography sx={{ marginLeft: theme.spacing(2) }} variant="h6">
                  {t('pages.settings.scanQRCode')}
                </Typography>
                <img
                  alt="scannable-qr-code"
                  src={mfaQRCode}
                  style={{ maxWidth: '300px', maxHeight: '300px' }}
                />
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  minWidth: '50%',
                  paddingLeft: theme.spacing(1.5),
                }}
              >
                <Typography
                  sx={{ textAlign: 'center', marginBottom: theme.spacing(1) }}
                  variant="subtitle1"
                >
                  {t('pages.settings.enterManualCode')}
                </Typography>
                <TextField
                  InputProps={{
                    readOnly: true,
                    endAdornment: (
                      <IconButton onClick={() => _handleCopyText()}>
                        <ContentCopyRoundedIcon />
                      </IconButton>
                    ),
                  }}
                  multiline
                  value={mfaManualKey}
                  variant="outlined"
                />
              </Box>
            </Box>
          </DialogContent>
          <Box
            sx={{
              display: 'flex',
              width: '100%',
              justifyContent: 'space-between',
              padding: `${theme.spacing(2)} ${theme.spacing(3)}`,
            }}
          >
            {mode !== 'enable-public' && !props.required && (
              <Button
                color="secondary"
                onClick={() => handleClose()}
                size="medium"
                sx={{
                  textTransform: 'capitalize',
                }}
                variant="contained"
              >
                {t('generic.close')}
              </Button>
            )}
            <Box
              sx={{
                alignSelf: 'flex-end',
                marginLeft: 'auto',
              }}
            >
              {mode === 'enable-public' && (
                <Button
                  color="primary"
                  onClick={() => props.onSuccess()}
                  size="medium"
                  sx={{
                    marginLeft: theme.spacing(2),
                    textTransform: 'capitalize',
                  }}
                  variant="contained"
                >
                  {t('generic.ok')}
                </Button>
              )}
              {mode !== 'enable-public' && (
                <Tooltip
                  arrow
                  disableFocusListener
                  disableHoverListener
                  disableTouchListener
                  onClose={() => setTooltip(false)}
                  open={tooltip}
                  placement="top"
                  PopperProps={{
                    disablePortal: true,
                  }}
                  title={t('pages.settings.mFARequired')}
                >
                  <Button
                    color="primary"
                    onClick={() => _handleMFAEnableClick()}
                    size="medium"
                    sx={{
                      marginLeft: theme.spacing(2),
                      textTransform: 'capitalize',
                    }}
                    variant="contained"
                  >
                    {t('generic.enable')}
                  </Button>
                </Tooltip>
              )}
            </Box>
          </Box>
        </Dialog>
      )}
    </>
  );
};

export default MFADialog;
