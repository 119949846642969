import { useMediaQuery, useTheme } from '@mui/material';
import React, { useEffect, useState } from 'react';

const mapping = ['xs', 'sm', 'md', 'lg', 'xl'];

// eslint-disable-next-line @typescript-eslint/no-unnecessary-type-constraint
const useCurrentBreakpoint = <T extends unknown>(
  mode: 'up' | 'down',
  breakpointData: { [key: string]: T }
) => {
  const theme = useTheme();
  const [currentSelection, setCurrentSelection] = useState<T>();

  const xl = useMediaQuery(theme.breakpoints.only('xl'));
  const lg = useMediaQuery(theme.breakpoints.only('lg'));
  const md = useMediaQuery(theme.breakpoints.only('md'));
  const sm = useMediaQuery(theme.breakpoints.only('sm'));
  const xs = useMediaQuery(theme.breakpoints.only('xs'));

  const currentBreakpoint = React.useMemo(() => {
    if (xl) return 'xl';
    if (lg) return 'lg';
    if (md) return 'md';
    if (sm) return 'sm';
    if (xs) return 'xs';
  }, [xl, lg, md, sm, xs]);

  useEffect(() => {
    if (!currentBreakpoint) return;

    // if there is a matching entry, no work to do just return it
    if (
      Object.prototype.hasOwnProperty.call(breakpointData, currentBreakpoint)
    ) {
      setCurrentSelection(breakpointData[currentBreakpoint]);
      return;
    }

    const indexOfCurrent = mapping.indexOf(currentBreakpoint);

    // doesn't exist in our list, will never match
    if (
      indexOfCurrent < 0 ||
      (mode === 'down' && indexOfCurrent === 0) ||
      (mode === 'up' && indexOfCurrent === mapping.length - 1)
    )
      return;

    setCurrentSelection(undefined);
    const mapsToProcess =
      mode === 'down'
        ? mapping.slice(0, indexOfCurrent).reverse()
        : mapping.slice(indexOfCurrent + 1);

    for (let i = 0; i < mapsToProcess.length; i += 1) {
      if (
        Object.prototype.hasOwnProperty.call(breakpointData, mapsToProcess[i])
      ) {
        setCurrentSelection(breakpointData[mapsToProcess[i]]);
        break;
      }
    }
  }, [currentBreakpoint, breakpointData, mode]);

  return { breakpoint: currentBreakpoint, selection: currentSelection };
};

export default useCurrentBreakpoint;
