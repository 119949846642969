import {
  Box,
  Grid,
  Paper,
  SxProps,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Theme,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { ETOTextField, MessageContext } from '@teto/react-component-library-v2';
import { getGraphQLClient } from 'teto-client-api';
import { useQuery } from 'react-query';
import { useTranslation } from 'react-i18next';
import * as React from 'react';
import type { TypedDocumentNode } from '@graphql-typed-document-node/core';
import { useMemo } from 'react';
import deepPropertyHelper from '../../../TETOGridGraphQL/Formatters/deepPropertyHelpers';
import getErrors from '../../../../api/graphQL/getErrors';
import { PurchasingItemDetailSummary } from '../../../../__generated__/graphql';
import getPurchasingItemDetailsSummaryByItemQuery from '../../ProcBreakdownInspector/queries/getPurchasingItemDetailsSummaryByItemQuery';

interface PartSummaryProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  detail?: { [key: string]: unknown | any };
  ignoreLocation: boolean;
}

const containerSx: SxProps<Theme> = {
  display: 'flex',
  flexDirection: 'column',
  flexGrow: 1,
  flexShrink: 1,
  height: '100%',
  justifyContent: 'flex-start',
  overflow: 'auto',
  pt: 1,
  rowGap: 2,
};

const tableContainerSx = {
  mt: 1,
};

const PartSummary = (props: PartSummaryProps) => {
  const { detail, ignoreLocation } = props;
  const { t } = useTranslation();
  const messageContext = React.useContext(MessageContext);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const [purchasingItemsDetails, setPurchasingItemsDetails] =
    React.useState<PurchasingItemDetailSummary>();

  const getInventoryLocationId = useMemo(() => {
    if (typeof detail?.inventoryLocationId === 'number') {
      return detail?.inventoryLocationId;
    }

    if (typeof detail?.inventoryLocationId === 'object') {
      return deepPropertyHelper('id', detail?.inventoryLocationId);
    }
  }, [detail?.inventoryLocationId]);

  const variables = React.useMemo(
    () => ({
      projectId: detail?.projectId,
      specId: detail?.specId,
      itemId: detail?.itemId,
      inventoryLocationId: ignoreLocation ? 0 : getInventoryLocationId,
      destinationLocationId: detail?.destInventoryLocId,
      processScheduleId: detail?.processScheduleId,
    }),
    [
      detail?.destInventoryLocId,
      detail?.itemId,
      detail?.processScheduleId,
      detail?.projectId,
      detail?.specId,
      getInventoryLocationId,
      ignoreLocation,
    ]
  );

  const purchasingItemDetailsSummaryByItemQuery = async () => {
    await getGraphQLClient()
      .performQuery(
        getPurchasingItemDetailsSummaryByItemQuery as TypedDocumentNode,
        variables
      )
      .then((d) => {
        if (d.hasError()) {
          if (d.hasSystemErrors()) {
            messageContext.setError(getErrors(d.systemErrors));
          }
          if (d.hasValidationErrors()) {
            messageContext.setError(getErrors(d.validationErrors));
          }
          return;
        }

        return setPurchasingItemsDetails(
          d?.data?.purchasingItemDetailsSummaryByItem?.items?.[0]
        );
      });
  };

  useQuery(
    'purchasing-items-details',
    async () => purchasingItemDetailsSummaryByItemQuery(),
    {
      enabled: true,
    }
  );

  const reqTotal =
    (purchasingItemsDetails?.bOM ?? 0) + (purchasingItemsDetails?.nonBOM ?? 0);
  const procTotal =
    (purchasingItemsDetails?.procured ?? 0) +
    (purchasingItemsDetails?.inventory ?? 0) +
    (purchasingItemsDetails?.processScheduled ?? 0);
  const toBeProcuredTotal = reqTotal - procTotal;

  return (
    <Box sx={containerSx}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <ETOTextField
            disabled
            inputLabelProps={{ shrink: true }}
            label={t('entities:PurchasingItemDetailSummary.ItemDescription')}
            multiline
            name="part-description"
            size="small"
            value={purchasingItemsDetails?.itemDescription ?? ''}
          />
        </Grid>
        <Grid item sm={6} xs={12}>
          <ETOTextField
            disabled
            inputLabelProps={{ shrink: true }}
            label={t('entities:PurchasingItemDetailSummary.UOMDescription')}
            name="uom"
            size="small"
            value={purchasingItemsDetails?.uOMDescription ?? ''}
          />
        </Grid>
        <Grid item sm={6} xs={12}>
          <ETOTextField
            disabled
            inputLabelProps={{ shrink: true }}
            label={t(
              'entities:PurchasingItemDetailSummary.ItemCategoryDescription'
            )}
            name="category"
            size="small"
            value={purchasingItemsDetails?.itemCategoryDescription ?? ''}
          />
        </Grid>
      </Grid>

      <Box>
        <TableContainer component={Paper} elevation={0}>
          <Table
            aria-label="reference information table"
            padding={isMobile ? 'none' : 'normal'}
            size="small"
          >
            <TableHead>
              <TableRow>
                <TableCell colSpan={2}>
                  {t('pages.receiving.referenceInformation')}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell>{t('pages.receiving.inventoryOrder')}</TableCell>
                <TableCell align="right">
                  {purchasingItemsDetails?.orderToInventory ?? 0}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>{t('pages.receiving.openWorkOrders')}</TableCell>
                <TableCell align="right">
                  {purchasingItemsDetails?.openWorkOrders ?? 0}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>

        <TableContainer component={Paper} elevation={0} sx={tableContainerSx}>
          <Table
            aria-label="requested table"
            padding={isMobile ? 'none' : 'normal'}
            size="small"
          >
            <TableHead>
              <TableRow>
                <TableCell colSpan={2}>{t('generic.requested')}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell>{t('pages.receiving.bOMPartsOrder')}</TableCell>
                <TableCell align="right">
                  {purchasingItemsDetails?.bOM ?? 0}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>{t('pages.receiving.nonBoMPS')}</TableCell>
                <TableCell align="right">
                  {purchasingItemsDetails?.nonBOM ?? 0}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell sx={{ fontWeight: 700 }}>
                  {t('generic.total')}
                </TableCell>
                <TableCell align="right" sx={{ fontWeight: 700 }}>
                  {reqTotal}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>

        <TableContainer component={Paper} elevation={0} sx={tableContainerSx}>
          <Table
            aria-label="procured table"
            padding={isMobile ? 'none' : 'normal'}
            size="small"
          >
            <TableHead>
              <TableRow>
                <TableCell colSpan={1}>
                  {t('pages.receiving.procured')}
                </TableCell>
                <TableCell align="right">{t('generic.total')}</TableCell>
                <TableCell align="right">{t('generic.open')}</TableCell>
                <TableCell align="right">{t('generic.available')}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell>{t('generic.purchased')}</TableCell>
                <TableCell align="right">
                  {purchasingItemsDetails?.procured ?? 0}
                </TableCell>
                <TableCell align="right">
                  {purchasingItemsDetails?.unreceivedQty ?? 0}
                </TableCell>
                <TableCell align="right">
                  {purchasingItemsDetails?.receivedQty ?? 0}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  {t('pages.receiving.reservedPulledInventory')}
                </TableCell>
                <TableCell align="right">
                  {purchasingItemsDetails?.inventory ?? 0}
                </TableCell>
                <TableCell align="right">
                  {purchasingItemsDetails?.reservedQty ?? 0}
                </TableCell>
                <TableCell align="right">
                  {purchasingItemsDetails?.pulledQty ?? 0}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  {t('pages.receiving.issuedProcessSchedules')}
                </TableCell>
                <TableCell align="right">
                  {purchasingItemsDetails?.processScheduled ?? 0}
                </TableCell>
                <TableCell align="right">0</TableCell>
                <TableCell align="right">
                  {purchasingItemsDetails?.completedPSQty ?? 0}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell sx={{ fontWeight: '700' }}>
                  {t('generic.total')}
                </TableCell>
                <TableCell align="right" sx={{ fontWeight: '700' }}>
                  {Number.isNaN(procTotal) ? 'Error' : procTotal}
                </TableCell>
                <TableCell align="right" sx={{ fontWeight: '700' }}>
                  {purchasingItemsDetails?.openQty ?? 0}
                </TableCell>
                <TableCell align="right" sx={{ fontWeight: '700' }}>
                  {purchasingItemsDetails?.availableQty ?? 0}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>

        <TableContainer component={Paper} elevation={0} sx={tableContainerSx}>
          <Table
            aria-label="to be procured table"
            padding={isMobile ? 'none' : 'normal'}
            size="small"
          >
            <TableHead>
              <TableRow>
                <TableCell colSpan={2}>
                  {t('pages.receiving.toBeProcured')}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell>{t('pages.receiving.requestedTotal')}</TableCell>
                <TableCell align="right">{reqTotal}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>{t('pages.receiving.procuredTotal')}</TableCell>
                <TableCell align="right">{procTotal}</TableCell>
              </TableRow>
            </TableBody>
            <TableBody>
              <TableRow>
                <TableCell sx={{ fontWeight: '700' }}>
                  {t('generic.total')}
                </TableCell>
                <TableCell align="right" sx={{ fontWeight: '700' }}>
                  {Number.isNaN(toBeProcuredTotal)
                    ? 'Error'
                    : toBeProcuredTotal}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </Box>
  );
};

export default PartSummary;
