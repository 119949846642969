import { SxProps, Theme } from '@mui/material/styles';
import { OkCancelConfirmDialog } from '@teto/react-component-library-v2';
import { isEmpty } from 'lodash';
import React, { ReactNode, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { gql } from '../../../__generated__';
import { Company } from '../../../__generated__/graphql';
import { buildCellFormatter } from '../../TETOGridGraphQL/Formatters/cellFormatterHelper';
import ETOGridSelectField from '../ETOGridSelectField/ETOGridSelectField';
import { ETOGridSelectFieldProps } from '../ETOGridSelectField/ETOGridSelectFieldProps';
import shipToColumns from './purchaseShipToColumns';

export interface ShipToInputFieldProps
  extends Pick<
    ETOGridSelectFieldProps<Company>,
    'disabled' | 'error' | 'label' | 'customSx'
  > {
  confirm?: {
    title: string;
    content: string | ReactNode;
    // eslint-disable-next-line no-unused-vars
    onOk?: (name: string, value?: number) => void;
    // eslint-disable-next-line no-unused-vars
    onCancel?: (name: string, value?: number) => void;
  };
  fieldName: string;
  // eslint-disable-next-line no-unused-vars, @typescript-eslint/no-explicit-any
  handleChange: (e: Partial<React.ChangeEvent<any>>) => void;
  value?: number;
  hideOptionsOnClear?: boolean;
}

const gridFieldSx: SxProps<Theme> = { width: '100% !important' };

const NAME_KEY = 'name';
const VALUE_KEY = 'id';

const getShipTo = gql(`query getShipToInputField($filter:CompanyFilterInput) {
    companies(
      where: $filter
      order: { id: ASC, name: ASC }
      take: 500
    ) {
      items {
        id
        name
        active
        city
        phone
        zip
        state
      }
    }
  }
`);

const ShipToInputField = (props: ShipToInputFieldProps) => {
  const {
    disabled,
    error,
    fieldName,
    handleChange,
    label,
    confirm,
    customSx,
    value,
    hideOptionsOnClear,
  } = props;
  const { t } = useTranslation();

  const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);
  const [selectedCompany, setSelectedCompany] = useState<Company>();

  const _handleChange = (e: Company) => {
    if (e) {
      const supplierVal = e;
      if (confirm) {
        setIsConfirmationOpen(true);
        setSelectedCompany(supplierVal);
      } else {
        setSelectedCompany(supplierVal);
        handleChange({
          target: {
            name: fieldName,
            value: e?.id ?? null,
            otherInfo: { company: e },
          },
        });
      }
    }
    if (!e) {
      handleChange({
        target: {
          name: fieldName,
          value: undefined,
          otherInfo: { company: undefined },
        },
      });
    }
  };

  const _handleDisplayValue = useCallback(
    (shipToId?: number, data?: Company[]) => {
      if (shipToId && data) {
        const display = data?.find((i) => i.id === shipToId);
        const city = display?.city ? ` [${display?.city}]` : '';
        return `${display?.name}${city}`;
      }

      return '';
    },
    []
  );

  const convertedToRDGColumns = useMemo(
    () =>
      [...(shipToColumns(t) ?? [])].map((c) => ({
        ...c,
        render: c.render ?? buildCellFormatter(c),
      })),
    [t]
  );

  const _matchValue = useCallback(
    (text: string | undefined, item: Company) =>
      item.name.toUpperCase() === text?.toUpperCase(),
    []
  );

  const modifyFilterVariables = useCallback(
    (filter: Record<string, unknown>) => {
      if (isEmpty(filter)) {
        return {
          or: [
            { active: { eq: true } },
            { supplier: { id: { gte: value ?? 1 } } },
          ],
        };
      }
      return {
        and: [{ active: { eq: true } }, { ...filter }],
      };
    },
    [value]
  );
  return (
    <>
      <ETOGridSelectField
        customSx={{ ...gridFieldSx, ...customSx }}
        disabled={disabled}
        error={error}
        fieldName={fieldName}
        gridProps={{
          columns: convertedToRDGColumns,
        }}
        handleChange={(e) => _handleChange(e as Company)}
        handleRenderValue={(v, d) =>
          _handleDisplayValue(v as number, d as Company[])
        }
        hideOptionsOnClear={hideOptionsOnClear}
        label={label}
        search={{
          textValueMatcher: (
            search: string,
            item: { name: string; id: number } & Company
          ) => _matchValue(search, item),
          filterColumn: {
            name: 'name',
            operator: 'contains',
            type: 'string',
            value: '',
          },
        }}
        source={{
          name: NAME_KEY,
          value: VALUE_KEY,
          gqlSource: {
            query: getShipTo,
            useRemoteFiltering: true,
            modifyFilterVariables,
          },
        }}
        value={value}
      />

      {isConfirmationOpen && confirm && (
        <OkCancelConfirmDialog
          content={confirm?.content}
          onCancel={() => {
            confirm?.onCancel?.(fieldName, selectedCompany?.id ?? undefined);
            setIsConfirmationOpen(false);
          }}
          onOk={() => {
            const data = {
              name: fieldName,
              value: selectedCompany?.id ?? undefined,
            };
            confirm?.onOk?.(data.name, data?.value);
            setIsConfirmationOpen(false);
            handleChange({
              target: data,
            });
          }}
          open={isConfirmationOpen}
          title={confirm?.title}
        />
      )}
    </>
  );
};

export default ShipToInputField;
