import IndeterminateCheckBoxRounded from '@mui/icons-material/IndeterminateCheckBoxRounded';
import { Box, Checkbox } from '@mui/material';
import { MessageContext } from '@teto/react-component-library-v2';
import React, { useContext, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { hasAnyBitOn } from '../../TETOGridGraphQL/Formatters/BitwiseFormatter';
// eslint-disable-next-line import/no-cycle
import PartsHistoryBOM from '../../../views/PartsHistoryBOMView.yaml';
import MainTetoGridGraphQL from '../../TETOGridGraphQL/TETOMainGridGraphQL';
import useGrid from '../../TETOGridGraphQL/hooks/useGrid';
import { useGridBuilderFromView } from '../../TETOGridGraphQL/hooks/useGridBuilder';
import { TetoGridGraphqlRefProps } from '../../TETOGridGraphQL/types/TetoGridGraphqlProps';
import useExportData from '../helpers/useExportData';
import CommonTabProps from './CommonTabProps';

const PersistenceName = 'ph-PartsHistoryBOM';
const RootQueryPath = 'engProductStructures';

const itemHoldOptions = [0, 1, 2, 3];

const BillOfMaterialsTab: React.FC<CommonTabProps> = (props) => {
  const {
    part,
    configureInspectorOpen,
    shouldExportData,
    setShouldExportData,
    setConfigureInspector,
  } = props;

  const { t, ready } = useTranslation();

  const messageContext = useContext(MessageContext);

  const gridRef = useRef<TetoGridGraphqlRefProps | undefined>();

  const { gridBuilder, builderReady, error, hasError } = useGridBuilderFromView(
    PartsHistoryBOM,
    RootQueryPath,
    (gb) => {
      gb.updateDefinition('itemIsOnHold', {
        renderGroupTitle: (d) =>
          d === true || d?.toString().toLowerCase() === 'true'
            ? t('entities:BOM.ItemHold')
            : `${t('generic.not')} ${t('entities:BOM.ItemHold')}`,
      }).updateDefinition('itemHold', {
        render: (d) => {
          const value = hasAnyBitOn(itemHoldOptions, d.data.itemHold);
          return (
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                height: '100%',
                width: '100%',
              }}
            >
              <Checkbox
                checked={value ?? false}
                disabled
                indeterminate={value === undefined}
                indeterminateIcon={<IndeterminateCheckBoxRounded />}
                size="small"
                style={{ padding: 0, display: 'block', margin: '0 auto' }}
              />
            </Box>
          );
        },
      });
    }
  );

  const mandatoryFilters = useMemo(
    () => [
      {
        name: 'childId',
        operator: 'equal',
        value: part.id,
        type: 'number' as const,
      },
    ],
    [part.id]
  );

  const { gridProps } = useGrid(
    PersistenceName,
    `${RootQueryPath}.items`,
    (e) => messageContext.setError(e.message ?? e),
    t,
    ['parent.id'],
    gridBuilder,
    {
      filterAndSortMode: 'serverSide',
      mandatoryFilter: mandatoryFilters,
    }
  );

  useExportData(shouldExportData, setShouldExportData, gridRef);

  return (
    <>
      <MainTetoGridGraphQL
        builderReady={builderReady}
        configureInspector={configureInspectorOpen}
        error={error}
        externalQueryProps={[]}
        gridProps={gridProps}
        hasError={hasError}
        header={{ hidden: true }}
        mobileGridOptions
        ready={ready}
        ref={gridRef}
        setConfigureInspector={setConfigureInspector}
        showEmptyRows
        showGroupSummaryRow={false}
        t={t}
        tableIdentifier={PersistenceName}
        {...gridProps}
      />
    </>
  );
};

export default BillOfMaterialsTab;
