import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { Button, Theme, Tooltip, useTheme } from '@mui/material';
import React from 'react';
import {
  getArticleLink,
  getSectionLink,
} from '../../../helpers/zendeskHelpers';

interface NavHelpLinkProps {
  helpId?: string | number;
  title?: string;
  type?: 'section' | 'article';
}

const buttonSx = (theme: Theme) => ({
  minWidth: theme.spacing(3),
});

const NavHelpLink: React.FC<NavHelpLinkProps> = (props) => {
  const { helpId, title, type } = props;
  const theme = useTheme();
  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {helpId && (
        <Tooltip title={`${title as string} Help`}>
          <Button
            component="a"
            fullWidth
            href={
              type === 'section'
                ? getSectionLink(helpId)
                : getArticleLink(helpId)
            }
            rel="noreferrer"
            sx={buttonSx}
            target="_blank"
          >
            <HelpOutlineIcon sx={{ color: theme.palette.grey[600] }} />
          </Button>
        </Tooltip>
      )}
    </>
  );
};

export default NavHelpLink;
