import { useAtom, useSetAtom } from 'jotai';
import { useMolecule } from 'jotai-molecules';
import React, { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import RFQModal from '../../Modals/RFQModal/RFQModal';
import { BreadCrumbSharedState } from '../StateContainers/BreadCrumbModalState';
import { RFQSharedState, onRFQClose } from '../StateContainers/RFQState';

const useSharedRFQ = () => {
  const { t } = useTranslation();
  const [rFQ] = useAtom(RFQSharedState);
  const onCloseFn = useMolecule(onRFQClose);
  const defaultOnClose = useSetAtom(onCloseFn);
  const setLevels = useSetAtom(BreadCrumbSharedState);

  const rFQModal = useMemo(
    () => ({
      name: rFQ?.initialValues?.id
        ? `${t('entities:RFQ.RFQ')}: ${String(rFQ?.initialValues?.id)}`
        : t('entities:RFQ.RFQ'),
      data: rFQ?.initialValues,
      component: (
        <RFQModal
          initialValues={rFQ.initialValues}
          onClose={defaultOnClose}
          open={rFQ.open}
        />
      ),
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [rFQ.initialValues, rFQ.open]
  );

  useEffect(() => {
    if (rFQ.open && !rFQ.baseLevel) {
      setLevels((prev) => [...prev, rFQModal]);
    } else if (rFQ.open && rFQ.baseLevel) {
      setLevels(() => [rFQModal]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rFQ.open, rFQ.baseLevel, rFQModal]);
};

export default useSharedRFQ;
