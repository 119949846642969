import { Box, Typography } from '@mui/material';
import { SxProps, Theme } from '@mui/material/styles';
import {
  ETOButton,
  GenericDialog,
  MessageContext,
  YesNoConfirmDialog,
} from '@teto/react-component-library-v2';
import dayjs, { Dayjs } from 'dayjs';
import { useSetAtom } from 'jotai';
import React, {
  Dispatch,
  SetStateAction,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { Permission, getGraphQLClient } from 'teto-client-api';
import {
  RfqHeader,
  UpdateRfqDetailDateMode,
} from '../../../../../../__generated__/graphql';
import getErrors from '../../../../../../api/graphQL/getErrors';
import AuthContext from '../../../../../../contexts/AuthContext';
import SettingsContext from '../../../../../../contexts/SettingsContext';
import editRFQ from '../../../../../../forms/EditRFQForm.yaml';
import ShipToInputField from '../../../../../InputFields/ShipToInputField/ShipToInputField';
import { contentSx } from '../../../../../Inspectors/NonConformanceInspector/NCPanels/components/commonPanelStyles';
import { RFQSharedState } from '../../../../../SharedStateComponents/StateContainers/RFQState';
import SaveStatusState from '../../../../../SharedStateComponents/StateContainers/SaveStatusState';
import EditableCustomFieldFormBuilderExtension from '../../../../../TETOForms/FormBuilder/EditableCustomFieldFormBuilderExtension';
import TETOForm from '../../../../../TETOForms/TETOForm';
import useForm from '../../../../../TETOForms/hooks/useForm';
import useFormBuilderFromMutation from '../../../../../TETOForms/hooks/useFormBuilderFromMutation';
import getTerms from '../../../../../TETOForms/selects/getTerms';
import { getViaShippers } from '../../../../../TETOForms/selects/selects';
import FobSelectField from '../../../../PurchaseOrderModal/panels/PurchaseOrderDetailsPanel/PurchaseOrderHeaderForm/InputsFields/FobSelectField';
import SpecialNotesSelectField from '../../../../PurchaseOrderModal/panels/PurchaseOrderDetailsPanel/PurchaseOrderNotes/SpecialNotes/SpecialNotesSelectField';
import SupplierChipList from '../../../components/SupplierChipList';
import updateRFQDetailDates from '../../../queries/updateRFQDetailDates';

export interface RFQEditHeaderFormProps {
  disabled: boolean;
  rFQ: RfqHeader;
  setRFQ: Dispatch<SetStateAction<Partial<RfqHeader>>>;
  setSupplierCount: Dispatch<SetStateAction<number>>;
  // eslint-disable-next-line no-unused-vars
  setResetGrid: (value: boolean) => void;
}

interface DateField {
  name?: string; // 'dateRequired' | 'dateRevised';
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  value?: Dayjs | any;
}
const buttonLayoutSx: SxProps<Theme> = {
  alignItems: 'center',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-end',
  width: '100%',
  columnGap: 2,
  pt: 2,
};
const formWrapperSx = {
  margin: 0,
};

const gridInputSx: SxProps<Theme> = {
  pb: { xs: 2.5, md: 'unset' },
};

const RFQEditHeaderForm = (props: RFQEditHeaderFormProps) => {
  // eslint-disable-next-line no-unused-vars
  const { disabled, rFQ, setRFQ, setResetGrid, setSupplierCount } = props;
  const setSaveState = useSetAtom(SaveStatusState);
  const setRFQState = useSetAtom(RFQSharedState);

  const { t } = useTranslation();

  const authContext = useContext(AuthContext);
  const messageContext = useContext(MessageContext);
  const settingsContext = useContext(SettingsContext);

  const [openDateChangeDialog, setOpenDateChangeDialog] = useState<DateField>();
  const [openClearDateDialog, setOpenClearDateDialog] = useState<DateField>();
  const { canAddSupplier, canDeleteSupplier } = useMemo(
    () => ({
      canAddSupplier: authContext.hasAnyPermission([
        Permission.Add_Procurement_RFQs_RFQHeader_RFQSuppliers,
        Permission.Modify_Procurement_RFQs_RFQHeader_RFQSuppliers,
      ]),
      canDeleteSupplier: authContext.hasAnyPermission([
        Permission.Delete_Procurement_RFQs_RFQHeader_RFQSuppliers,
        Permission.Modify_Procurement_RFQs_RFQHeader_RFQSuppliers,
      ]),
    }),
    [authContext]
  );

  const formBuilder = useFormBuilderFromMutation(
    'updateRFQHeader',
    'UpdateRFQHeaderInput',
    [
      'custom1',
      'custom2',
      'custom3',
      'custom4',
      'custom5',
      'custom6',
      'custom7',
      'custom8',
      'details.id',
      'date',
      'due',
      'fOB',
      'contactName',
      'shipToId',
      'specialNotes',
      'topNotes',
      'bottomNotes',
      'terms',
      'via',
      'id',
    ],
    (fb) =>
      fb
        .extension(
          new EditableCustomFieldFormBuilderExtension(
            'rFQHeader',
            'forms:UpdateRFQHeaderInput.Custom',
            'RFQ Header Custom',
            disabled
          ),
          t
        )
        .updateField('incoterms', {
          disabled,
          // eslint-disable-next-line no-unused-vars
          render: (form, field, options) => (
            <FobSelectField
              disabled={options.disabled}
              error={options.error}
              handleChange={(val) => {
                form.updateField(field.name, val.target.value);
              }}
              sx={gridInputSx}
              value={options.value}
            />
          ),
        })
        .updateField('shipToId', {
          disabled,
          render: (form, field, options) => {
            const value = Number(options.value) || Number(field.defaultValue);
            return (
              <ShipToInputField
                customSx={gridInputSx}
                disabled={options.disabled}
                error={options.error}
                fieldName={field.name}
                handleChange={(val) =>
                  form.updateField(field.name, val.target.value)
                }
                label={options?.label}
                value={value}
              />
            );
          },
        })
        .updateField('specialNotes', {
          disabled,
          render: (form, field, options) => (
            <SpecialNotesSelectField
              disabled={options.disabled}
              error={options.error}
              onBlur={(e) => form.updateField(field.name, e.target.value)}
              onChange={(e) => form.updateField(field.name, e.target.value)}
              value={options.value}
            />
          ),
        })
        .updateField('date', { disabled })
        .updateField('due', { disabled })
        .updateField('terms', {
          disabled,
          selectSource: {
            allowUnknownEntry:
              !settingsContext.settings.limitAccountingTermsToList,
            fromGraphQLQuery: getTerms.query,
            label: getTerms.labelField,
            value: 'description', // because of the system setting
          },
        })
        .updateField('via', {
          disabled,
          selectSource: {
            allowUnknownEntry:
              !settingsContext.settings.limitShippingAgentsToList,
            fromGraphQLQuery: getViaShippers.query,
            label: getViaShippers.labelField,
            value: getViaShippers.valueField,
          },
        })
        .updateField('requestedById', {
          disabled,
          defaultValue: rFQ?.requestId || authContext.user?.id,
        })
        .updateField('contact', { disabled })
        .updateField('topNotes', { disabled })
        .updateField('bottomNotes', { disabled })
  );

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onErrors = useCallback((errors: any) => {
    messageContext.setError(getErrors(errors));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const editForm = useForm(
    formBuilder,
    {
      onErrors,
      onSubmitted: (submitContext) => {
        const updatedData = submitContext.output.updateRFQHeader;
        if (submitContext?.success && updatedData) {
          setRFQ((prev) => ({
            ...prev,
            ...updatedData,
            due: updatedData?.due ?? undefined,
          }));

          if (
            updatedData?.details.length > 0 &&
            !dayjs(rFQ.due).isSame(updatedData.due)
          ) {
            if (updatedData.due)
              setOpenDateChangeDialog({ name: 'due', value: updatedData.due });
            else setOpenClearDateDialog({ name: 'due', value: undefined });
          }
          setSaveState((prev) => ({
            ...prev,
            hasSavedOccurred: submitContext?.success,
          }));
          setRFQState((prev) => ({
            ...prev,
            hasSavedOccurred: submitContext?.success,
          }));
        }

        return Promise.resolve();
      },
    },
    {
      ...rFQ,
      rFQId: rFQ?.id,
      contact: rFQ?.contactName,
      custom7: rFQ?.custom7,
      custom8: rFQ?.custom8,
    },
    { autoSave: true }
  );

  const _handleDateChange = useCallback(
    (dateChange: DateField, option: string, callback?: () => void) => {
      let mode = UpdateRfqDetailDateMode.None;
      if (!option || option === 'none')
        return callback ? callback?.() : setOpenDateChangeDialog(undefined);
      if (option && option !== 'none') {
        switch (option) {
          case 'all':
            mode = UpdateRfqDetailDateMode.All;
            break;
          case 'empty':
            mode = UpdateRfqDetailDateMode.Empty;
            break;

          default:
            break;
        }

        getGraphQLClient()
          .performMutation(updateRFQDetailDates, {
            input: {
              rFQId: rFQ.id,
              date: dateChange.value,
              mode: mode as UpdateRfqDetailDateMode,
            },
          })
          .then((d) => {
            if (d.hasError()) {
              if (d.systemErrors) onErrors(d.systemErrors);
              if (d.validationErrors) onErrors(d.validationErrors);
              return;
            }
            if (
              d.hasData() &&
              (d.data.updateRFQDetailDates?.items?.length ?? 0) > 0
            ) {
              if (callback) callback?.();
              else setOpenDateChangeDialog(undefined);
              setResetGrid(true);
              setSaveState((prev) => ({
                ...prev,
                hasSavedOccurred: true,
              }));
            }
          });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [rFQ.id, setResetGrid]
  );

  const dateOptionButtons = useMemo(
    () => [
      {
        title: t('generic.all'),
        onClick: (n: DateField) => _handleDateChange(n, 'all'),
      },
      {
        title: t('pages.rFQ.rFQModal.emptyOnly'),
        onClick: (n: DateField) => _handleDateChange(n, 'empty'),
      },
      {
        title: t('generic.none'),
        onClick: (n: DateField) => _handleDateChange(n, 'none'),
      },
    ],
    [_handleDateChange, t]
  );
  return (
    <Box>
      <SupplierChipList
        canAdd={canAddSupplier}
        canDelete={canDeleteSupplier}
        rFQ={rFQ}
        setSupplierCount={setSupplierCount}
      />
      <TETOForm
        collapsible
        form={editForm}
        formLayout={editRFQ}
        formWrapperSx={formWrapperSx}
      />
      {openDateChangeDialog && (
        <GenericDialog
          isOpen={Boolean(openDateChangeDialog)}
          onClose={() => setOpenDateChangeDialog(undefined)}
          title={t('pages.rFQ.rFQModal.dateChangeTitle', {
            type: t('entities:RFQHeader.Due'),
          })}
        >
          <Box sx={contentSx}>
            <Typography>
              {t('pages.rFQ.rFQModal.dateChangeContent', {
                type: t('entities:RFQHeader.Due'),
              })}
            </Typography>
            <Box sx={buttonLayoutSx}>
              {dateOptionButtons.map((i) => (
                <ETOButton
                  color="primary"
                  key={i.title}
                  onClick={() => i.onClick(openDateChangeDialog)}
                  size="medium"
                >
                  {i.title}
                </ETOButton>
              ))}
            </Box>
          </Box>
        </GenericDialog>
      )}
      {Boolean(openClearDateDialog) && (
        <YesNoConfirmDialog
          content={t('pages.rFQ.rFQModal.dialogs.clearDueDateContent')}
          onNo={() => setOpenClearDateDialog(undefined)}
          onYes={() => {
            _handleDateChange(openClearDateDialog as DateField, 'all', () =>
              setOpenClearDateDialog(undefined)
            );
          }}
          open={Boolean(openClearDateDialog)}
          title={t('pages.rFQ.rFQModal.dateChangeTitle', {
            type: t('entities:RFQHeader.Due'),
          })}
        />
      )}
    </Box>
  );
};

export default RFQEditHeaderForm;
