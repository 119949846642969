/* eslint-disable @typescript-eslint/no-empty-function */
import React, { useContext, useMemo } from 'react';

import { MessageContext } from '@teto/react-component-library-v2';
import { useTranslation } from 'react-i18next';
import { Permission } from 'teto-client-api';
import { gql } from '../../../../../__generated__';
import AuthContext from '../../../../../contexts/AuthContext';
import AddEditDrawings, {
  TDrawingsInspectorProps,
} from '../../../components/AddEditInspector/AddEditDrawings/AddEditDrawings';
import { Drawing } from '../../../components/AddEditInspector/AddEditDrawings/DrawingType';

interface DrawingsProps extends TDrawingsInspectorProps {
  itemId: number | undefined;
}

interface AddEngItemMasterDrawingInput {
  itemId: number;
  drawing: string;
  mapToLegacy: boolean;
}
interface UpdateEngItemMasterDrawingInput {
  id: number;
  drawing: string;
  mapToLegacy: boolean;
}

const updateEngItemMasterDrawingMutation =
  gql(`mutation updateEngItemMasterDrawing(
  $input: UpdateEngItemMasterDrawingInput!
) {
  updateEngItemMasterDrawing(input: $input) {
    id
    drawing
    pathType
    fileExtension
    fileName
    fileSize
  }
}
`);
const addEngItemMasterDrawingMutation =
  gql(`mutation addEngItemMasterDrawing($input: AddEngItemMasterDrawingInput!) {
  addEngItemMasterDrawing(input: $input) {
    id
    drawing
    pathType
    fileExtension
    fileName
    fileSize
  }
}
`);

const EngItemMasterDrawings = `query engItemMasterDrawings($filter: EngItemMasterDrawingFilterInput!) {
  engItemMasterDrawings(where: $filter) {
    items {
      id
      drawing
      pathType
      fileExtension
      fileName
      fileSize
    }
  }
}`;

const deleteEngItemMasterDrawingMutation =
  gql(`mutation deleteEngItemMasterDrawing($id: Int!) {
  deleteEngItemMasterDrawing(id: $id) {
    id
    drawing
    pathType
    fileExtension
    fileName
    fileSize
  }
}`);

const DrawingsPanel = (props: DrawingsProps) => {
  const { editingState, itemId, setEditingState, setHasASaveOccurred } = props;
  const messageContext = useContext(MessageContext);
  const authContext = useContext(AuthContext);
  const { t } = useTranslation();

  const canModifyDrawing = useMemo(
    () =>
      authContext.hasPermission(
        Permission.Modify_Engineering_ItemMaster_ItemDetails
      ),
    [authContext]
  );

  const canDeleteDrawing = useMemo(
    () =>
      authContext.hasPermission(
        Permission.Delete_Engineering_ItemMaster_ItemDetails
      ),
    [authContext]
  );
  const canAddDrawing = useMemo(
    () =>
      authContext.hasPermission(
        Permission.Add_Engineering_ItemMaster_ItemDetails
      ),
    [authContext]
  );
  const canViewDrawing = useMemo(
    () =>
      authContext.hasPermission(
        Permission.View_Engineering_ItemMaster_ItemDetails
      ),
    [authContext]
  );
  if (!itemId) {
    // this should never happen
    messageContext.setError(t('generic.message.noItemFound'));
    return <></>;
  }

  const filter = {
    filter: {
      itemId: {
        eq: itemId,
      },
    },
  };
  const mutationVariables = (value: Drawing, mode: 'add' | 'edit') => {
    const common = {
      drawing: `${value.description}#${value.drawing}#`,
      mapToLegacy: false,
    };

    if (itemId) {
      if (mode === 'add') {
        const addVariables: AddEngItemMasterDrawingInput = {
          ...common,
          itemId,
        };
        return addVariables;
      }
    }
    const editVariables: UpdateEngItemMasterDrawingInput = {
      ...common,
      id: value.id,
    };
    return editVariables;
  };

  const drawingsUrl = '/api/sharedFiles/engitemmasterdrawings/';

  const formatOnAdd = (value: unknown) => {
    if (
      typeof value === 'object' &&
      value &&
      'addEngItemMasterDrawing' in value &&
      Array.isArray(value.addEngItemMasterDrawing)
    ) {
      return value.addEngItemMasterDrawing as unknown as Drawing[];
    }
    return undefined;
  };
  const formatOnEdit = (value: unknown) => {
    if (
      typeof value === 'object' &&
      value &&
      'updateEngItemMasterDrawing' in value &&
      Array.isArray(value.updateEngItemMasterDrawing)
    ) {
      return value.updateEngItemMasterDrawing as unknown as Drawing[];
    }
    return undefined;
  };

  return (
    <>
      <AddEditDrawings
        canAdd={canAddDrawing}
        canDelete={canDeleteDrawing}
        canModify={canModifyDrawing}
        canView={canViewDrawing}
        deleteMutationKey="deleteEngItemMasterDrawing"
        drawingsDownloadUrl={drawingsUrl}
        editingState={editingState}
        formatOnAdd={formatOnAdd}
        formatOnUpdate={formatOnEdit}
        mutationVariables={mutationVariables}
        onAddMutation={addEngItemMasterDrawingMutation}
        onDeleteMutation={deleteEngItemMasterDrawingMutation}
        onUpdateMutation={updateEngItemMasterDrawingMutation}
        queryKey="engItemMasterDrawings"
        queryVariables={filter}
        setEditingState={setEditingState}
        setHasASaveOccurred={setHasASaveOccurred}
        standardQuery={EngItemMasterDrawings}
      />
    </>
  );
};

export default DrawingsPanel;
