/* eslint-disable react/jsx-no-useless-fragment */
import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Popper,
  SxProps,
  Theme,
  useTheme,
} from '@mui/material';
import { YesNoConfirmDialogProps } from '@teto/react-component-library-v2';

import React, { useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Permission } from 'teto-client-api';
import AuthContext from '../../contexts/AuthContext';

type ClockInModalNotificationProps = Omit<
  YesNoConfirmDialogProps,
  'title' | 'content'
>;

interface Props extends ClockInModalNotificationProps {
  anchorEl: null | HTMLElement;
}
const sizeSx: SxProps<Theme> = {
  height: (theme) => theme.spacing(4.5),
  textTransform: 'capitalize',
};
const primarySx: SxProps<Theme> = {
  backgroundColor: 'primary.main',
  color: 'common.white',
  '&:hover': {
    backgroundColor: 'primary.light',
  },
};
const secondarySx: SxProps<Theme> = {
  backgroundColor: 'secondary.main',
  color: 'common.white',
  '&:hover': {
    backgroundColor: 'secondary.light',
  },
};
const ClockInModalNotification = (props: Props) => {
  const { onNo, onYes, open, anchorEl } = props;
  const { t, ready } = useTranslation();
  const theme = useTheme();
  const authContext = useContext(AuthContext);
  const { canClockIn } = useMemo(
    () => ({
      canClockIn:
        !authContext.disableClockIn &&
        authContext.hasEnterpriseLicense() &&
        authContext.hasPermission(Permission.Add_Time_Client_Clockins),
    }),
    [authContext]
  );

  return (
    <>
      {ready && anchorEl && canClockIn && (
        <Popper
          anchorEl={anchorEl}
          data-testid="clockInModalNotification"
          onResize={undefined}
          onResizeCapture={undefined}
          open={open}
          placement="bottom-end"
          sx={{
            zIndex: 1000,
          }}
        >
          <Box
            sx={{
              borderRadius: theme.shape.borderRadius,
              border: 1,
              p: 1,
              bgcolor: 'background.paper',
            }}
          >
            <DialogTitle>{t('dialogs.notClockedIn.title')}</DialogTitle>
            <DialogContent>
              <DialogContentText>
                {t('dialogs.notClockedIn.content')}
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button
                color="secondary"
                onClick={(e) => onNo(e)}
                size="medium"
                sx={[sizeSx, secondarySx]}
                variant="contained"
              >
                {t('generic.no')}
              </Button>
              <Button
                color="primary"
                disabled={!canClockIn}
                onClick={(e) => onYes(e)}
                size="medium"
                sx={[sizeSx, primarySx]}
                variant="contained"
              >
                {t('generic.yes')}
              </Button>
            </DialogActions>
          </Box>
        </Popper>
      )}
    </>
  );
};

export default React.memo(ClockInModalNotification);
