import DescriptionIcon from '@mui/icons-material/Description';
import { MessageContext } from '@teto/react-component-library-v2';

import { FormikHelpers } from 'formik';
import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import buildMutation from '../../../../../api/graphQL/buildMutation';
import useBuildQuery from '../../../../../api/graphQL/useBuildQuery';
import AuthContext from '../../../../../contexts/AuthContext';
import { InspectorActions } from '../../../types/InspectorActionTypes';
import { InspectorState } from '../../../types/InspectorState';
import AddEditList from '../AddEditList/AddEditList';
import { onAdd, onUpdate } from '../helpers/mutationHelpers';
import NotesForm, { INotesForm } from './NotesForm';

const REQUIRED_MESSAGE = 'Required';
export type TNotesInspectorProps = Pick<
  InspectorActions<INotesForm>,
  'setEditingState' | 'setHasASaveOccurred'
> &
  Pick<InspectorState<INotesForm>, 'editingState'>;

interface NotesProps extends TNotesInspectorProps {
  /* eslint-disable no-unused-vars */
  canAdd: boolean;
  canModify: boolean;
  canDelete: boolean;
  canView: boolean;
  formatOnAdd: (value: unknown) => INotesForm | undefined;
  formatOnUpdate: (value: unknown) => INotesForm | undefined;
  checkDeleteResult(result: unknown): boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onAddMutation: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onUpdateMutation: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onDeleteMutation: any;
  standardQuery: string;
  queryVariables: Record<string, unknown>;
  queryKey: string;
  mutationVariables: (value: INotesForm, mode: 'add' | 'edit') => void;
  /* eslint-enable no-unused-vars */
}
const NotesValidationSchema = Yup.object().shape({
  id: Yup.number(),
  noteEmployee: Yup.object().shape({
    id: Yup.number().required(REQUIRED_MESSAGE),
  }),
  noteDate: Yup.date().required(REQUIRED_MESSAGE),
  note: Yup.string().optional(),
});

const AddEditNotes = (props: NotesProps) => {
  const {
    canAdd,
    canDelete,
    canModify,
    canView,
    checkDeleteResult,
    editingState,
    formatOnAdd,
    formatOnUpdate,
    mutationVariables,
    onAddMutation,
    onDeleteMutation,
    onUpdateMutation,
    queryKey,
    queryVariables,
    setEditingState,
    setHasASaveOccurred,
    standardQuery,
  } = props;

  const authContext = useContext(AuthContext);
  const messageContext = useContext(MessageContext);

  const { t } = useTranslation();

  const [notesCollection, setNotesCollection] = useState<INotesForm[]>([]);
  const [selectedItem, setSelectedItem] = useState<INotesForm>();
  const [isNewItemOpen, setIsNewItemOpen] = useState(false);

  useBuildQuery(['AddEditNotes'], {
    queryString: standardQuery,
    variables: queryVariables,
    callback: (data) => {
      if (data && typeof data === 'object' && queryKey in data) {
        const result: Record<string, unknown> =
          data[queryKey as keyof typeof data];
        if ('items' in result && Array.isArray(result.items)) {
          setNotesCollection(result.items);
        }
      }
    },
    options: { enabled: canView, refetchOnWindowFocus: false, retry: false },
  });

  const _onAdd = (
    value: INotesForm,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    helpers: FormikHelpers<any>
  ) => {
    onAdd(
      value,
      {
        input: mutationVariables(value, 'add'),
      },
      onAddMutation,
      formatOnAdd,
      setNotesCollection,
      setHasASaveOccurred,
      setSelectedItem,
      setEditingState,
      messageContext,
      (err) => {
        const errFields: Record<string, string> = {};
        if ('input' in err) {
          Object.entries(err.input).forEach(([key, errMes]) => {
            errFields[key as keyof typeof errFields] = errMes as string;
          });
        }
        helpers.setErrors(errFields);
      },
      setIsNewItemOpen
    );
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const _onUpdate = (value: INotesForm, helpers: FormikHelpers<any>) => {
    onUpdate(
      value,
      {
        input: mutationVariables(value, 'edit'),
      },
      onUpdateMutation,
      formatOnUpdate,
      setNotesCollection,
      setHasASaveOccurred,
      selectedItem,
      setSelectedItem,
      setEditingState,
      notesCollection,
      messageContext,
      (err) => {
        const errFields: Record<string, string> = {};
        if ('input' in err) {
          Object.entries(err.input).forEach(([key, errMes]) => {
            errFields[key as keyof typeof errFields] = errMes as string;
          });
        }
        helpers.setErrors(errFields);
      }
    );
  };

  const handleDeleteMutation = (value: INotesForm) => {
    buildMutation({
      messageContext,
      queryString: onDeleteMutation,
      variables: { id: value.id },
      callback: (resp: unknown) => {
        const result = checkDeleteResult(resp);
        if (result) {
          setNotesCollection((d) => d.filter((item) => item?.id !== value.id));
          messageContext.setSuccess(
            `${t('generic.delete')}d ${t('generic.note')} Successfully`
          );
          return setSelectedItem(undefined);
        }
        return messageContext.setError(
          `Error ${t('generic.deleting')} ${t('generic.note')}`
        );
      },
    });
  };

  const formikInitialValues: Partial<INotesForm> = {
    noteEmployee: {
      id: authContext?.user?.id ?? -1,
      name: authContext?.user?.firstName ?? '',
    },
    noteDate: new Date(),
    note: '',
  };

  return (
    <>
      <AddEditList
        accordionListItems={notesCollection}
        accordionSubtitleKey="note"
        accordionTitleKey="noteEmployee.name"
        autoSave
        canAddItem={canAdd}
        canDeleteItem={canDelete}
        editingState={editingState}
        formComponent={
          <>
            <NotesForm
              canModifyNote={canModify}
              setEditingState={setEditingState}
            />
          </>
        }
        formikInitialValues={
          formikInitialValues as unknown as Record<string, unknown>
        }
        formikSchema={NotesValidationSchema}
        icon={<DescriptionIcon />}
        isNewItemOpen={isNewItemOpen}
        onAdd={_onAdd}
        onDelete={handleDeleteMutation}
        onUpdate={_onUpdate}
        selectedItem={selectedItem}
        setEditingState={setEditingState}
        setIsNewItemOpen={setIsNewItemOpen}
        setSelectedItem={setSelectedItem}
      />
    </>
  );
};

export default AddEditNotes;
