import { gql } from '../../../__generated__';

const jobsQuery = gql(`query specs ($projectId: Int!) {
  specs(where: {projectId: {eq: $projectId}, active: {eq:true}}, order: {displayName:ASC}) {
    items {
      displayName
      id
      active
      masterQueue {
        closingRelease
      }
    }
  }
}`);

const warningJobsQuery = gql(`query specsWithNonActive ($projectId: Int!) {
  specs(where: {projectId: {eq: $projectId}}, order: {displayName:ASC}) {
    items {
      displayName
      id
      active
      masterQueue {
        closingRelease
      }
    }
  }
}`);

export { jobsQuery, warningJobsQuery };
