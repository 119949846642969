import DeleteIcon from '@mui/icons-material/Delete';
import DynamicFeedIcon from '@mui/icons-material/DynamicFeed';
import PlaylistAddRoundedIcon from '@mui/icons-material/PlaylistAddRounded';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { MessageContext } from '@teto/react-component-library-v2';
import { useSetAtom } from 'jotai';
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { Permission } from 'teto-client-api';
import * as Yup from 'yup';
import buildMutation from '../../api/graphQL/buildMutation';
import AuthContext from '../../contexts/AuthContext';
import StandardAssemblyDetails from '../../views/StandardAssemblyDetailsView.yaml';
import ActionBar from '../ActionBar/ActionBar';
// eslint-disable-next-line import/no-cycle
import PartsSearchGrid from '../PartsSearch/PartsSearchGrid';
import { BreadCrumbSharedState } from '../SharedStateComponents/StateContainers/BreadCrumbModalState';
import ActionButtonGridBuilderExtension from '../TETOGridGraphQL/GridBuilder/ActionButtonGridBuilderExtension';
import MainTetoGridGraphQL from '../TETOGridGraphQL/TETOMainGridGraphQL';
import GridCommonMobileButtons from '../TETOGridGraphQL/components/GridCommonMobileButtons/GridCommonMobileButtons';
import useGrid from '../TETOGridGraphQL/hooks/useGrid';
import { useGridBuilderFromView } from '../TETOGridGraphQL/hooks/useGridBuilder';
import { CellChangingArgs } from '../TETOGridGraphQL/types/CellChangingArgs';
import { MandatoryFilters } from '../TETOGridGraphQL/types/MandatoryFilterTypes';
import TETOGridRefType from '../TETOGridGraphQL/types/TETOGridRefType';
import RDGSelectedType from '../TetoGrid/types/RDGSelectedType';
import SADetailTitle from './SADetailTitle';
import AddStandardAssemblyMutation from './queries/AddStandardAssemblyMutation';
import deleteStandardAssemblyMutation from './queries/deleteStandardAssemblyMutation';
import updateStandardAssemblyMutation from './queries/updateStandardAssemblyMutation';

export interface SADetailsProps {
  saPart: {
    itemId: number;
    itemCompanyId: string;
    description: string;
  };
}

const RootQueryPath = 'standardAssemblies';
const PersistenceName = 'standard-assembly-details';

const NOTE_MAX = 1024;
const BOM_REV_MAX = 25;
const ITEM_MAX = 50;
const ALWAYS_PROJECT_COLUMNS: string[] = [
  'id',
  'item.id',
  'itemCutLength',
  'itemFabrication',
  'itemNumber',
  'itemQty',
  'itemLastRevision',
  'item.uOM.itemUom',
  'note',
  'orEquals',
  'sparePart',
  'standardAssemblyItemId',
];
const EDITABLE_COLUMNS = {
  bomRev: 'itemLastRevision',
  fabrication: 'itemFabrication',
  item: 'itemNumber',
  note: 'note',
  orEquals: 'orEquals',
  quantity: 'itemQty',
  spareParts: 'sparePart',
};

const saDetailsSchema = Yup.object().shape({
  [EDITABLE_COLUMNS.bomRev]: Yup.string().max(BOM_REV_MAX).nullable(),
  [EDITABLE_COLUMNS.fabrication]: Yup.bool(),
  [EDITABLE_COLUMNS.item]: Yup.string().max(ITEM_MAX),
  [EDITABLE_COLUMNS.note]: Yup.string().max(NOTE_MAX),
  [EDITABLE_COLUMNS.orEquals]: Yup.bool(),
  [EDITABLE_COLUMNS.quantity]: Yup.number().min(0),
  [EDITABLE_COLUMNS.spareParts]: Yup.bool(),
});

const SADetails = (props: SADetailsProps) => {
  const { saPart } = props;
  const { ready, t } = useTranslation();
  const authContext = useContext(AuthContext);
  const messageContext = useContext(MessageContext);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const gridRef = useRef<TETOGridRefType | undefined>();

  const [isConfigureOpen, setIsConfigureOpen] = useState<boolean>(false);
  const [selectedRows, setSelectedRows] = useState<
    RDGSelectedType | undefined
  >();
  const [isRefreshToken, setIsRefreshToken] = useState<boolean>(false);

  const setLevels = useSetAtom(BreadCrumbSharedState);
  const saDetailsPermissions = useMemo(
    () => ({
      canDelete: authContext.hasPermission(
        Permission.Delete_Engineering_ItemMaster_StandardAssemblyStructure
      ),
      canEdit: authContext.hasPermission(
        Permission.Modify_Engineering_ItemMaster_StandardAssemblyStructure
      ),
      canView: authContext.hasPermission(
        Permission.View_Engineering_ItemMaster_StandardAssemblyStructure
      ),
    }),
    [authContext]
  );

  const editableColumns = useMemo(() => Object.values(EDITABLE_COLUMNS), []);

  const disabledBySelectedRow = useMemo(() => {
    const selectedPart = selectedRows ?? undefined;

    const disabled =
      !saDetailsPermissions.canView ||
      !selectedPart ||
      selectedPart.item?.uOM.itemUom !== -1;

    const saValues = selectedPart
      ? {
          itemId: selectedPart.id,
          itemCompanyId: selectedPart.item.itemCompanyId,
          description: selectedPart.item.description,
        }
      : { itemId: -1, itemCompanyId: '', description: '' };

    return { disabled, saPart: saValues };
  }, [saDetailsPermissions.canView, selectedRows]);

  const _handleDeleteItem = useCallback((saId: number) => {
    buildMutation({
      queryString: deleteStandardAssemblyMutation,
      variables: { id: saId },
      callback: (d) => {
        if (d.deleteStandardAssembly.success) {
          setIsRefreshToken(true);
        }
      },
      messageContext,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { gridBuilder, builderReady, error, hasError } = useGridBuilderFromView(
    StandardAssemblyDetails,
    RootQueryPath,
    (gb) =>
      gb
        .extension(
          new ActionButtonGridBuilderExtension([
            {
              componentName: t('pages.inventory.details.deletePart'),
              icon: <DeleteIcon color="error" />,
              title: t('generic.delete'),
              onClick: ({ data }) => _handleDeleteItem(data.id),
              color: 'error',
              disabled: !saDetailsPermissions.canDelete,
              confirm: {
                type: 'okCancel',
                title: t('dialogs.deleteGenericItem.title'),
                content: t('dialogs.deleteGenericItem.content'),
              },
            },
          ]),
          t
        )
        .updateDefinition(EDITABLE_COLUMNS.item, {
          editable: saDetailsPermissions.canEdit,
          resizable: true,
          flex: 1,
        })
        .updateDefinition(EDITABLE_COLUMNS.quantity, {
          editable: saDetailsPermissions.canEdit,
        })
        .updateDefinition(EDITABLE_COLUMNS.fabrication, {
          editable: saDetailsPermissions.canEdit,
        })
        .updateDefinition(EDITABLE_COLUMNS.bomRev, {
          editable: saDetailsPermissions.canEdit,
          resizable: true,
          flex: 1,
        })
        .updateDefinition(EDITABLE_COLUMNS.note, {
          editable: saDetailsPermissions.canEdit,
          resizable: true,
          flex: 1,
        })
        .updateDefinition(EDITABLE_COLUMNS.orEquals, {
          editable: saDetailsPermissions.canEdit,
        })
        .updateDefinition(EDITABLE_COLUMNS.spareParts, {
          editable: saDetailsPermissions.canEdit,
        })
  );

  const mandatoryFilters = useMemo(() => {
    const filters: MandatoryFilters = [
      {
        name: 'standardAssemblyItem.itemCompanyId',
        operator: 'equalTo',
        type: 'number' as const,
        value: saPart.itemCompanyId,
      },
    ];

    return filters;
  }, [saPart.itemCompanyId]);

  const { gridProps } = useGrid(
    PersistenceName,
    `${RootQueryPath}.items`,
    (e) => messageContext.setError(e.message ?? e),
    t,
    ALWAYS_PROJECT_COLUMNS,
    gridBuilder,
    {
      filterAndSortMode: 'serverSide',
      mandatoryFilter: mandatoryFilters,
    }
  );

  const _handlePickedPart = useCallback(
    (data: { [key: string]: unknown }[]) => {
      if (typeof data === 'object' && data !== null) {
        const selectedParts = data;
        if (selectedParts.length === 0) return;

        const mappedParts = selectedParts.map((i) => ({
          itemCutLength: i?.rawMaterialCutLength ?? 0,
          itemFabrication: false,
          itemId: i?.id as number,
          itemQty: 0,
          note: '',
          orEquals: false,
          sparePart: false,
          standardAssemblyItemId: saPart.itemId as number,
        }));

        const addSelectedParts = mappedParts.map(
          async (part) =>
            // eslint-disable-next-line no-return-await
            await buildMutation({
              queryString: AddStandardAssemblyMutation,
              variables: { input: part },
              messageContext,
            })
        );

        Promise.all(addSelectedParts).then(() => {
          messageContext.setSuccess(
            `${t('entities:Part:Part', { count: selectedParts.length })} ${t(
              'pages.inventory.standardAssembly.addedToSA'
            )}`
          );
        });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [saPart.itemId]
  );

  const pickPartBtn = useMemo(
    () => ({
      icon: <PlaylistAddRoundedIcon />,
      onclick: () =>
        setLevels((pv) => [
          ...pv,
          {
            name: 'Parts Search',
            component: (
              <PartsSearchGrid
                // eslint-disable-next-line prettier/prettier, @typescript-eslint/no-explicit-any
                addMandatoryFilters={[
                  {
                    name: 'and[0].uOM.description',
                    operator: 'notEqualTo',
                    type: 'string' as const,
                    value: 'Assembly',
                  },
                ]}
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                onPick={(vals: any) => _handlePickedPart(vals)}
              />
            ),
          },
        ]),
      title: t('pages.inventory.pickParts'),
    }),
    [_handlePickedPart, setLevels, t]
  );

  const saDetailsBtn = useMemo(
    () => ({
      disabled: disabledBySelectedRow.disabled,
      icon: <DynamicFeedIcon />,
      onclick: () =>
        setLevels((pv) => [
          ...pv,
          {
            name: `${t('inspectors.engItemMasterInspector.saDetails')}: ${
              disabledBySelectedRow.saPart?.itemCompanyId
            }`,
            component: <SADetails saPart={disabledBySelectedRow.saPart} />,
          },
        ]),
      title: t('inspectors.engItemMasterInspector.saDetails'),
      confirm: {
        title: t('inspectors.engItemMasterInspector.dialogs.saDetails.title'),
        content: t(
          'inspectors.engItemMasterInspector.dialogs.saDetails.content'
        ),
      },
    }),
    [disabledBySelectedRow.disabled, disabledBySelectedRow.saPart, setLevels, t]
  );

  const GridCommonBtns = GridCommonMobileButtons({
    refreshGrid: () => gridProps.refreshDataSource(),
    gridRef,
    setConfigureInspector: () => setIsConfigureOpen(true),
  });

  const rightChildren = useMemo(
    () => [saDetailsBtn, pickPartBtn, GridCommonBtns],
    [GridCommonBtns, pickPartBtn, saDetailsBtn]
  );

  const _handleCellChanging = useCallback(
    async (change: CellChangingArgs) => {
      const {
        changeContext: { cancelChange, setColumnValue },
        column: { name },
        editValue: { value, originalValue },
        newData,
      } = change;

      const parseValue =
        name === EDITABLE_COLUMNS.quantity ? parseInt(value, 10) : value;

      await saDetailsSchema
        .validate({ [name as string]: parseValue })
        .then(() => {
          const input = {
            id: newData.id,
            itemFabrication: newData.itemFabrication,
            itemId: newData.item.id,
            itemLastRevision: newData.itemLastRevision,
            itemQty: newData.itemQty,
            note: newData.note,
            orEquals: newData.orEquals,
            sparePart: newData.sparePart,
            [name as string]: parseValue,
          };

          buildMutation({
            queryString: updateStandardAssemblyMutation,
            variables: { input },
            messageContext,
            errorCallback: () => {
              cancelChange();
              setColumnValue(name as string, originalValue);
            },
          });
        });
    },
    [messageContext]
  );

  useEffect(() => {
    if (isRefreshToken) {
      gridProps.refreshDataSource();
      setIsRefreshToken(false);
    }
  }, [gridProps, isRefreshToken]);

  return (
    <>
      <ActionBar
        leftChildren={
          <SADetailTitle
            {...{
              description: saPart.description,
              itemCompanyId: saPart.itemCompanyId,
            }}
          />
        }
        rightChildren={rightChildren}
      />
      <MainTetoGridGraphQL
        builderReady={builderReady}
        configureInspector={isConfigureOpen}
        editMode={{
          editMode: 'byRow',
          validationSchema: saDetailsSchema,
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          onCellChanging: (c: any) => {
            if (editableColumns.indexOf(c.column.name as string) >= 0) {
              _handleCellChanging(c);
            }
          },
        }}
        error={error}
        externalQueryProps={[]}
        gridProps={gridProps}
        hasError={hasError}
        header={{ hidden: true }}
        mobileGridOptions
        pagination
        ready={ready}
        ref={gridRef}
        selectedRows={!isMobile ? selectedRows : undefined}
        setConfigureInspector={setIsConfigureOpen}
        setSelectedRows={
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          !isMobile ? (d: any) => setSelectedRows(d) : undefined
        }
        showEmptyRows
        showGroupSummaryRow={false}
        t={t}
        tableIdentifier={PersistenceName}
        {...gridProps}
      />
    </>
  );
};

export default SADetails;
