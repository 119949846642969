import type { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
import { SxProps, Theme, Typography } from '@mui/material';
import { MessageContext } from '@teto/react-component-library-v2';
import dayjs from 'dayjs';
import { useFormik } from 'formik';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Permission, getGraphQLClient } from 'teto-client-api';
import * as Yup from 'yup';
import { useSetAtom } from 'jotai';
import SaveStatusState from '../../../../SharedStateComponents/StateContainers/SaveStatusState';
import { gql } from '../../../../../__generated__';
import buildMutation from '../../../../../api/graphQL/buildMutation';
import updateContactMutation from '../../../CompanyDetailInspector/queries/updateContactMutation';

import {
  AddContactInput,
  Contact,
  UpdateContactInput,
} from '../../../../../__generated__/graphql';
import AuthContext from '../../../../../contexts/AuthContext';
import AddEditDetailsPanel from '../../../components/AddEditInspector/components/AddEditDetailsPanel';
import { InspectorActions } from '../../../types/InspectorActionTypes';
import { PanelMode, PanelState } from '../../../types/PanelState';
import ContactForm from './ContactsForm';

const REQUIRED_MESSAGE = 'Required';

type IPanelActions = Omit<InspectorActions<Contact>, 'setCurrentValues'> & {
  setPanelState: React.Dispatch<React.SetStateAction<PanelState>>;
};

interface IContactsInfoPanelProps extends IPanelActions {
  panelMode: PanelMode;
  initialValues: Partial<Contact> | undefined;
}

const contactInfoQuery = gql(`
query contactInfo($contactId: Int!) {
  contact( id: $contactId) {

      company {
        name
        id
      }
      id
      firstName
      lastName
      email
      fanRating {
        id
        description
      }
      active
      #   Work
      title
      phone
      ext
      fax
      #   Mobile
      carPhone
      pager
      cellPhone
      # Home
      homePhone
      homeFax
      custom1
      custom2
      custom3
      custom4
      custom5
      custom6
      custom7
      custom8

  }
}
`);

export const addItemMutation = gql(`
mutation addContact($values: AddContactInput!) {
  contact: addContact(input: $values) {
    id
    ext
    fanRating {
      id
      description
    }
    fax
    firstName
    lastName
    homeFax
    homePhone
    phone
    active
    companyId
    pager
    title
    carPhone
    cellPhone
    date
    email
    company {
      name
      id
    }
    custom1
    custom2
    custom3
    custom4
    custom5
    custom6
    custom7
    custom8
  }
}
  `);

export const AddContactSchema = Yup.object().shape({
  active: Yup.boolean().required(),
  carPhone: Yup.string().nullable().max(50),
  cellPhone: Yup.string().nullable().max(50),
  custom1: Yup.string().nullable(),
  custom2: Yup.string().nullable(),
  custom3: Yup.number().nullable(),
  custom4: Yup.number().nullable(),
  custom5: Yup.string().nullable(),
  custom6: Yup.string().nullable(),
  custom7: Yup.boolean().nullable(),
  custom8: Yup.boolean().nullable(),
  date: Yup.string().nullable(),
  email: Yup.string().email().nullable().max(50),
  ext: Yup.number().nullable(),
  fanRatingId: Yup.number().nullable(),
  fax: Yup.string().nullable().max(50),
  firstName: Yup.string().nullable().max(50),
  homeFax: Yup.string().nullable().max(50),
  homePhone: Yup.string().nullable().max(50),
  lastName: Yup.string().nullable().max(50),
  pager: Yup.string().nullable().max(50),
  phone: Yup.string().nullable().max(50),
  title: Yup.string().nullable().max(50),
});

export const UpdateContactSchema = Yup.object().shape({
  id: Yup.number().required(REQUIRED_MESSAGE),
  ...AddContactSchema.fields,
});
const headerSx: SxProps<Theme> = {
  marginBottom: (theme) => `-${theme.spacing(2)}px`,
};
export const formatContactOnAdd = (
  values: Partial<Contact>
): AddContactInput => {
  const formattedContact = {
    active: values.active ?? true,
    carPhone: values.carPhone || undefined,
    cellPhone: values.cellPhone || undefined,
    companyId: values.companyId as number,
    custom1: values.custom1 || undefined,
    custom2: values.custom2 || undefined,
    custom3: values.custom3 || undefined,
    custom4: values.custom4 || undefined,
    custom5: values.custom5 || undefined,
    custom6: values.custom6 || undefined,
    custom7: values.custom7 || undefined,
    custom8: values.custom8 || undefined,
    date: dayjs().toDate(),
    email: values.email || undefined,
    ext: values.ext || undefined,
    fanRatingId: values.fanRating?.id || values.fanRatingId || undefined,
    fax: values.fax || undefined,
    firstName: values.firstName || undefined,
    homeFax: values.homeFax || undefined,
    homePhone: values.homePhone || undefined,
    lastName: values.lastName || undefined,
    pager: values.pager || undefined,
    phone: values.phone || undefined,
    title: values.title || undefined,
  };

  return formattedContact;
};
const ContactsInfoPanel = (props: IContactsInfoPanelProps) => {
  const {
    initialValues,
    panelMode,
    setEditingState,
    setHasASaveOccurred,
    setIsCreatingNewItem,
    setPanelState,
  } = props;

  const { t } = useTranslation();

  const messageContext = useContext(MessageContext);
  const authContext = useContext(AuthContext);
  const setSaveState = useSetAtom(SaveStatusState);

  const [contact, setContact] = useState<Partial<Contact>>({ active: true });

  const formatOnUpdate = (values: Partial<Contact>): UpdateContactInput => {
    const formattedContact = formatContactOnAdd(values);

    return {
      ...formattedContact,
      companyId:
        (values.company?.id as number) ||
        (initialValues?.company?.id as number),
      id: values.id as number,
    };
  };
  const formik = useFormik({
    initialValues: contact,
    enableReinitialize: true,
    validateOnChange: true,
    validateOnMount: panelMode === 'create' && !initialValues?.id,
    validationSchema:
      panelMode === 'create' ? AddContactSchema : UpdateContactSchema,
    onSubmit: (values) => {
      // eslint-disable-next-line no-param-reassign
      values = { ...values, companyId: initialValues?.company?.id };
      formik.setSubmitting(true);
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const _handleSubmit = (resp: Record<string, any>) => {
        if ('contact' in resp) {
          setContact(resp.contact);
          if (panelMode === 'create') {
            setPanelState({
              id: resp.contact.id,
              mode: 'edit',
            });
            messageContext.setSuccess(t('generic.message.recordAdded'));
          }
          if (panelMode === 'edit') {
            setHasASaveOccurred(true);
            setSaveState((prev) => ({
              ...prev,
              hasSavedOccurred: true,
            }));
            messageContext.setSuccess(
              t('generic.updateSuccess', {
                record: t('entities:Contact.Contact'),
              })
            );
          }
        } else {
          messageContext.setError(t('generic.message.noDataFound'));
        }
      };
      if (panelMode === 'create') {
        buildMutation({
          queryString: addItemMutation,
          variables: {
            values: formatContactOnAdd(values),
          },
          messageContext,
          callback: _handleSubmit,
          formik,
        });
      } else if (values.id) {
        buildMutation({
          queryString: updateContactMutation as DocumentNode,
          variables: {
            input: formatOnUpdate(values),
          },
          messageContext,
          callback: _handleSubmit,
          formik,
        });
      } else {
        messageContext.setError(t('generic.message.noContactFound'));
      }
    },
  });

  const readOnly = authContext.hasReadOnlyLicense();

  const contactPermissions = useMemo(() => {
    const canModify = authContext.hasPermission(
      Permission.Modify_Sales_CompanyInformation_Contacts
    );
    const canAdd = authContext.hasPermission(
      Permission.Add_Sales_CompanyInformation_Contacts
    );
    const modifyPerm = () => {
      if (panelMode === 'create') {
        return canAdd;
      }
      if (readOnly) {
        return false;
      }
      if (panelMode === 'edit') {
        return canModify;
      }
      return true;
    };
    return {
      canAdd,
      canModify: modifyPerm(),
    };
  }, [authContext, panelMode, readOnly]);

  useEffect(() => {
    if (initialValues?.id) {
      getGraphQLClient()
        .performQuery(contactInfoQuery as DocumentNode, {
          contactId: initialValues?.id,
        })
        .then((d) => {
          if (d?.hasError()) {
            d.handleAllErrors(messageContext.setError, formik.setErrors);
            return;
          }
          setPanelState({
            id: initialValues?.id,
            mode: 'edit',
          });
          if (
            d &&
            typeof d === 'object' &&
            'data' in d &&
            d.data &&
            typeof d.data === 'object' &&
            'contact' in d.data
          ) {
            setContact(d?.data.contact as Partial<Contact>);
          } else {
            messageContext.setError(t('generic.message.noDataFound'));
          }
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialValues?.id]);

  return (
    <AddEditDetailsPanel
      canAdd={contactPermissions.canAdd}
      canEdit={contactPermissions.canModify}
      formik={formik}
      panelMode={panelMode}
      setEditingState={setEditingState}
      setIsCreatingNewItem={setIsCreatingNewItem}
    >
      {initialValues?.id &&
        formik.values.company?.name &&
        panelMode === 'edit' && (
          <Typography data-testid="title" sx={headerSx} variant="h6">
            {`${t('entities:Company.Company')}: ${formik.values.company?.name}`}
          </Typography>
        )}
      <ContactForm
        canModify={contactPermissions.canModify}
        formik={formik}
        panelMode={panelMode}
      />
    </AddEditDetailsPanel>
  );
};

export default ContactsInfoPanel;
