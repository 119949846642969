/* eslint-disable import/prefer-default-export */
// eslint-disable-next-line no-unused-vars
import BoolFilter from '@inovua/reactdatagrid-community/BoolFilter';
// eslint-disable-next-line no-unused-vars
import DateFilter from '@inovua/reactdatagrid-community/DateFilter';
import NumberFilter from '@inovua/reactdatagrid-community/NumberFilter';
import SelectFilter from '@inovua/reactdatagrid-community/SelectFilter';
import {
  TypeColumn,
  TypeFilterValue,
  TypeSingleFilterValue,
} from '@inovua/reactdatagrid-enterprise/types';
import {
  contains,
  endsWith,
  equalsNumber,
  equalsString,
  greaterThanNumber,
  lessThanNumber,
  notEqualString,
  notEqualsNumber,
  startsWith,
} from 'teto-client-api';
import filterTypes from './filterTypes';

const getStringFilterValue = (filterValue: TypeSingleFilterValue) => {
  if (!filterValue.value) {
    return null;
  }

  switch (filterValue.operator) {
    case 'equal':
      return equalsString(filterValue.value);
    case 'notEqual':
      return notEqualString(filterValue.value);
    case 'contains':
      return contains(filterValue.value);
    case 'startsWith':
      return startsWith(filterValue.value);
    case 'endsWith':
      return endsWith(filterValue.value);
    default:
      return null;
  }
};

const getNumberFilterValue = (filterValue: TypeSingleFilterValue) => {
  if (!filterValue.value) {
    return null;
  }

  switch (filterValue.operator) {
    case 'equal':
      return equalsNumber(filterValue.value);
    case 'notEqual':
      return notEqualsNumber(filterValue.value);
    case 'greaterThan':
      return greaterThanNumber(filterValue.value);
    case 'lessThan':
      return lessThanNumber(filterValue.value);
    default:
      return null;
  }
};

const getBooleanFilterValue = (filterValue: TypeSingleFilterValue) => {
  if (filterValue.value === null) {
    return null;
  }

  switch (filterValue.operator) {
    default:
      return filterValue.value;
  }
};

const getSelectFilterValue = (filterValue: TypeSingleFilterValue) => {
  if (filterValue.value === null) {
    return '';
  }

  switch (filterValue.operator) {
    default:
      return filterValue.value;
  }
};

const getFilterValue = (filterValue: TypeSingleFilterValue) => {
  switch (filterValue.type) {
    case 'string':
    case 'custom':
      return getStringFilterValue(filterValue);
    case 'number':
      return getNumberFilterValue(filterValue);
    case 'boolean':
    case 'bool':
      return getBooleanFilterValue(filterValue);
    case 'select':
      return getSelectFilterValue(filterValue);
    default:
      return getStringFilterValue(filterValue);
  }
};

export const buildFilterQuery = (filterValue: TypeFilterValue) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const output: { [key: string]: any } = {};

  filterValue?.forEach((f) => {
    output[f.name] = getFilterValue(f);
  });

  return output;
};

export const calculateFilterEditor = (col: TypeColumn) => {
  switch (col.type) {
    case 'bool':
    case 'boolean':
    case 'bitwise':
      return BoolFilter;
    case 'datetime':
    case 'date':
    case 'time':
      return DateFilter;
    case 'number':
    case 'hours':
    case 'currency':
      return NumberFilter;
    case 'select':
      return SelectFilter;
    default:
      return undefined;
  }
};

export const getOperatorTypes = (type: string) => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const result: { name: string; fn: any }[] = filterTypes[type]?.operators;

  if (result) {
    return result.map((op) => op.name);
  }
  return [];
};
