import React, { useContext, useMemo } from 'react';
import { Permission } from 'teto-client-api';
import AuthContext from '../../../../../contexts/AuthContext';
import CommonNotesPanel, {
  NotesPanelProps,
} from '../../../../Inspectors/components/CommonNotesPanel/CommonNotesPanel';
import { CustomInput } from '../../../../Inspectors/components/CommonNotesPanel/CommonAddEditNote';

type PurchaseOrderNotesPanelProps = Omit<
  NotesPanelProps & {
    onUpdated: () => void;
  },
  | 'addNewMutation'
  | 'deleteMutation'
  | 'entityName'
  | 'entityQuery'
  | 'permissions'
  | 'updateMutation'
>;

const addPurchaseOrderNoteMutation = `mutation addPurchaseOrderNotes($input: AddPurchaseOrderNoteInput) {
  addPurchaseOrderNote( input: $input) {
      id
      noteDate
      note
      purchaseOrderId
      employeeId
      employee {
        id
        name
      }
  }
}`;
const deletePurchaseOrderNoteMutation = `mutation deletePurchaseOrderNotes($id:Int!) {
  deletePurchaseOrderNote( id:$id) {
    success
  }
}`;
const getPurchaseOrderNotesQuery = `query purchaseOrderNotes($purchaseOrderId: Int!) {
  purchaseOrderNotes( where: { purchaseOrderId: { eq: $purchaseOrderId } }) {
    items {
      employee {
        id
        name
      }
      id
      note
      noteDate
    }
  }
}`;
const updatePurchaseOrderNoteMutation = `mutation updatePurchaseOrderNotes($input: UpdatePurchaseOrderNoteInput) {
  updatePurchaseOrderNote( input: $input) {
      id
      noteDate
      note
      purchaseOrderId
      employeeId
      employee {
        id
        name
      }
  }
}`;
const customInputs: CustomInput = {
  mode: 'edit',
  purchaseOrderId: '',
  id: 'purchaseOrderNoteId',
};

const PurchaseOrderNotesPanel = (props: PurchaseOrderNotesPanelProps) => {
  const authContext = useContext(AuthContext);

  const { onUpdated } = props;

  const notesPermissions = useMemo(
    () => ({
      canAdd: authContext.hasPermission(
        Permission.Add_Procurement_PurchaseOrders_PurchaseOrderHeader
      ),
      canDelete: authContext.hasPermission(
        Permission.Modify_Procurement_PurchaseOrders_PurchaseOrderHeader
      ),
      canEdit: authContext.hasPermission(
        Permission.Modify_Procurement_PurchaseOrders_PurchaseOrderHeader
      ),
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [authContext]
  );

  return (
    <CommonNotesPanel
      addNewMutation={addPurchaseOrderNoteMutation}
      customInputs={customInputs}
      deleteMutation={deletePurchaseOrderNoteMutation}
      entityName="PurchaseOrder"
      entityQuery={getPurchaseOrderNotesQuery}
      permissions={{
        canAddNotes: notesPermissions.canAdd,
        canDeleteNotes: notesPermissions.canDelete,
        canModifyNotes: notesPermissions.canEdit,
      }}
      updateMutation={updatePurchaseOrderNoteMutation}
      {...props}
      onUpdated={onUpdated}
    />
  );
};

export default PurchaseOrderNotesPanel;
