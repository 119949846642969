export default `query getPunchIn($id: Int!) {
    punchIn(id: $id) {
        id
        employee {
          id
          name
        }
        hourType {
          id
          displayName
        }
        spec {
          project {
            id
            displayName
          }
        }
        spec {
          id
          displayName
        }
        hourFactor
        punchInCoords
        comments
        nonConformanceId
        custom1
        custom2
        custom3
        custom4
        custom5
        custom6
        custom7
        custom8
        processScheduleDetailId
        processScheduleDetail {
          custom1
          custom2
          custom3
          custom4
          custom5
          custom6
          custom7
          custom8
        }
    }
  }`;
