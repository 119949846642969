import { SxProps } from '@mui/material/styles';
import { ETOTextField } from '@teto/react-component-library-v2';
import React, { useMemo } from 'react';
import { onKeyDown } from '../../TetoGrid/InlineEditing/editingHelpers';
import { CommonEditorProps } from './EditorProps';
import EditorWrapper from './EditorWrapper';
import TooltipWrapper from './TooltipWrapper';
import useIsMobile from '../../../hooks/useIsMobile';

const cellSx: SxProps = {
  '& .InovuaReactDataGrid__cell__editor InovuaReactDataGrid__cell__editor--number':
    {},
};

const ETONumberEditor: React.FC<CommonEditorProps> = (props) => {
  const {
    cellProps,
    onChange,
    onComplete,
    value,
    onFocus,
    onCancel,
    onTabNavigation,
    onKeyDown: onKeyDownProp,
    editorProps,
    errors,
    inputVariant,
  } = props;

  const editHandlers = {
    onCancel,
    onComplete,
    onTabNavigation,
  };

  const isMobile = useIsMobile();

  const error = useMemo(
    () => (errors && errors.length > 0 ? errors[0] : undefined),
    [errors]
  );

  const isDisabled = useMemo(() => {
    if (!editorProps?.disabled) return false;
    if (typeof editorProps?.disabled === 'function')
      return editorProps?.disabled(cellProps?.data);
    return editorProps?.disabled;
  }, [cellProps?.data, editorProps]);

  const step = useMemo(() => {
    if (editorProps && editorProps?.customStep) {
      return editorProps.customStep(cellProps.data);
    }
    return editorProps?.supportsDecimals ? 0.1 : 1;
  }, [cellProps.data, editorProps]);

  const editor = (
    <ETOTextField
      autoFocus
      disabled={isDisabled}
      error={error}
      handleChange={onChange}
      id={cellProps?.name as string}
      inputProps={{
        autoComplete: 'new-password',
        step,
        onFocus,
        onKeyDown: (e: React.KeyboardEvent<HTMLInputElement>) => {
          if (onKeyDownProp) onKeyDownProp?.(e);
          else onKeyDown(e, editHandlers);
        },
        style: {
          minWidth: Math.max(0, (cellProps?.computedWidth as number) - 30),
        },
        tabIndex: 0,
      }}
      name={cellProps?.name as string}
      onBlur={onComplete}
      onKeyDown={(e) => onKeyDown(e, editHandlers)}
      sxProps={cellSx}
      type="number"
      value={value}
      variant={inputVariant}
    />
  );

  return cellProps.data !== null ? (
    <EditorWrapper
      sx={{
        '& .MuiInputBase-root': {
          boxSizing: 'border-box !important',
          maxHeight: cellProps?.rowHeight
            ? (cellProps?.rowHeight as number) - 2
            : 'unset',
        },
      }}
    >
      {isMobile && <TooltipWrapper error={error}>{editor}</TooltipWrapper>}
      {!isMobile && !isDisabled && editor}
    </EditorWrapper>
  ) : null;
};

export default ETONumberEditor;
