import {
  Box,
  Checkbox,
  FormControlLabel,
  ListItem,
  SxProps,
  Theme,
} from '@mui/material';
import {
  ETOSelectField,
  OkCancelConfirmDialog,
} from '@teto/react-component-library-v2';
import { FormikProps } from 'formik';
import React, { useEffect, useState } from 'react';
import { TFunction, useTranslation } from 'react-i18next';
import useLocalStorage from 'use-local-storage';

import { Company } from '../../../__generated__/graphql';
import useGQLQuery from '../../../api/graphQL/useGQLQuery';
import getActiveSuppliers from '../../TETOForms/selects/getActiveSuppliers';

interface CommonSupplierSelectFieldProps<T> {
  formik: FormikProps<Partial<T>>;
  fieldName: string;
  label: string;
  disabled?: boolean;
  sx?: SxProps<Theme>;
  // eslint-disable-next-line no-unused-vars, @typescript-eslint/no-explicit-any
  handleChange: (e: Partial<React.ChangeEvent<any>>) => void;
  shouldHaveWarning: boolean;
  shouldFilterApproved?: boolean;
}

type SupplierApiResponse = {
  companies: {
    items: Partial<Company>[];
  };
};

const filterRoxSx: SxProps<Theme> = {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: (theme) => theme.spacing(0.5, 1),
  borderBottom: (theme) => `1px solid ${theme.palette.divider}`,
};
interface FilterRowProps {
  shouldFilter: boolean;
  // eslint-disable-next-line no-unused-vars
  setShouldFilter: (value: boolean) => void;
}
const FilterRow = (props: FilterRowProps) => {
  const { t } = useTranslation();
  const { shouldFilter, setShouldFilter } = props;

  return (
    <Box sx={filterRoxSx}>
      <span>{`${t('generic.filterBy')} ${t('generic.approved')}`}</span>

      <FormControlLabel
        control={
          <Checkbox
            checked={shouldFilter}
            color="primary"
            onMouseDown={(e) => {
              // Do not remove, this prevents the click event from bubbling up to the select field.
              // Otherwise, the select field will close.
              e.preventDefault();
              return setShouldFilter(!shouldFilter);
            }}
          />
        }
        label=""
      />
    </Box>
  );
};

export const supplierNameFormatter = (
  item: Company,
  t: TFunction<'translation', undefined>
) => {
  const name = item?.name;
  const city = item?.city;
  const approvalStatus = `(${
    item.supplier?.supQaapproved
      ? t('generic.approved')
      : t('generic.unapproved')
  })`;

  let formattedName = '';

  if (name) {
    formattedName = name;
  }
  if (city) {
    formattedName = `${formattedName} [${city}]`;
  }

  formattedName = `${formattedName} ${approvalStatus}`;

  return formattedName;
};
const CommonSupplierSelectField = <T,>(
  props: CommonSupplierSelectFieldProps<T>
) => {
  const {
    formik,
    fieldName,
    label,
    disabled,
    sx,
    handleChange,
    shouldFilterApproved,
    shouldHaveWarning,
  } = props;
  const { t } = useTranslation();

  const [shouldFilter, setShouldFilter] = useLocalStorage(
    'shouldFilterApprovedSuppliers',
    false
  );
  const [suppliers, setSuppliers] = useState<Company[]>([]);
  const [supplierConfirmation, setSupplierConfirmation] = useState(false);
  const [currSupplier, setCurrSupplier] = useState<Company>();

  const supplierQuery = useGQLQuery<SupplierApiResponse>(
    ['activeCommonSuppliers'],
    {
      queryString: getActiveSuppliers.query,
      options: { refetchOnMount: false, refetchOnWindowFocus: false },
    }
  );

  const _handleChange = (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    const supplierVal = suppliers.find(
      (item) => item?.supplier?.id === Number(e.target.value)
    );

    if (shouldHaveWarning && !supplierVal?.supplier?.supQaapproved) {
      setSupplierConfirmation(true);
      setCurrSupplier(supplierVal);
    } else {
      setCurrSupplier(supplierVal);
      handleChange({
        target: { name: fieldName, value: e.target.value ?? null },
      });
    }
  };

  useEffect(() => {
    if (shouldFilter) {
      if (suppliers.length === 0) {
        const filteredSuppliers = supplierQuery.data?.companies.items.filter(
          (i) => i.supplier?.supQaapproved ?? false
        );

        return setSuppliers((filteredSuppliers as Company[]) ?? []);
      }
      const filteredSuppliers = suppliers.filter(
        (i) => i.supplier?.supQaapproved ?? false
      );
      setSuppliers(filteredSuppliers);
    }
    if (!shouldFilter && supplierQuery?.data?.companies?.items) {
      setSuppliers(supplierQuery?.data?.companies?.items as Company[]);
    }
  }, [shouldFilter, supplierQuery.data?.companies?.items, suppliers]);

  useEffect(() => {
    if (shouldFilterApproved) {
      setShouldFilter(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldFilterApproved]);

  return (
    <>
      <ETOSelectField
        autoCompleteSx={sx}
        disabled={disabled}
        error={formik.errors[fieldName as keyof T] as string}
        handleChange={(e) => _handleChange(e)}
        inputLabelProps={{ shrink: true }}
        itemNameSelector={(item) => supplierNameFormatter(item, t)}
        items={suppliers ?? []}
        itemValueSelector={(item) => item?.supplier?.id}
        label={label}
        name={fieldName}
        renderOption={(opProps, option) => (
          <ListItem {...opProps}>
            <span
              style={{
                overflowX: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
              }}
            >
              {supplierNameFormatter(option, t)}
            </span>
          </ListItem>
        )}
        size="small"
        topListComponent={
          <FilterRow
            setShouldFilter={setShouldFilter}
            shouldFilter={shouldFilter}
          />
        }
        value={formik.values[fieldName as keyof T] ?? null}
      />

      <OkCancelConfirmDialog
        content={t(
          'inspectors.engItemMasterInspector.dialogs.preferredSupplier.content'
        )}
        onCancel={() => setSupplierConfirmation(false)}
        onOk={() => {
          setSupplierConfirmation(false);
          handleChange({
            target: {
              name: fieldName,
              value: currSupplier?.supplier?.id ?? null,
            },
          });
        }}
        open={supplierConfirmation}
        title={t(
          'inspectors.engItemMasterInspector.dialogs.preferredSupplier.title'
        )}
      />
    </>
  );
};

export default CommonSupplierSelectField;
