import { gql } from '../../../__generated__';
import SelectDefinition from './SelectDefinition';

const getDefaultFOBs = {
  query: gql(`query getDefaultFOBs {
  defaultFOBs {
    items {
      id
      fob
      standsFor
    }
  }
}`),
  valueField: 'id',
  labelField: 'fob',
} as SelectDefinition;

export default getDefaultFOBs;
