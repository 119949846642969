import { atom } from 'jotai';
import { createScope, molecule } from 'jotai-molecules';
import { atomWithReset } from 'jotai/utils';

import { GenericAddEditProps } from '../../Inspectors/components/AddEditInspector/GenericAddEditProps';
import { RFQ } from '../../Modals/RFQModal/types/RFQ';
import RDGSelectedType from '../../TetoGrid/types/RDGSelectedType';

type RFQState = Omit<GenericAddEditProps<RFQ>, 'onClose'> & {
  hasSavedOccurred?: boolean;
  itemsToAdd?: RDGSelectedType;
  baseLevel?: boolean;
};

export const RFQSharedState = atomWithReset<RFQState>({
  initialValues: undefined,
  open: false,
  hasSavedOccurred: false,
});

const _defaultOnClose = atom(null, (get, set) =>
  set(RFQSharedState, {
    initialValues: undefined,
    open: false,
    hasSavedOccurred: false,
    itemsToAdd: undefined,
    baseLevel: false,
  })
);

// Scope
export const initialRFQStateOnCloseScope = createScope(_defaultOnClose);

export const onRFQClose = molecule((getMol, getScope) => {
  const initialOnClose = getScope(initialRFQStateOnCloseScope);
  return initialOnClose;
});
