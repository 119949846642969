import { gql } from '../../../__generated__';
import SelectDefinition from './SelectDefinition';

export default {
  query: gql(`
query processes {
  processes(order: {id: ASC}) {
    items {
      id
      name
    }
  }
}
`),
  valueField: 'id',
  labelField: 'name',
} as SelectDefinition;
