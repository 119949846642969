import { InputAdornment } from '@mui/material';
import {
  CurrencyFormatter,
  ETOTextField,
  ETOTextFieldProps,
} from '@teto/react-component-library-v2';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

interface CurrencyFieldWrapperProps {
  format?: string;
  symbol?: string;
  inputProps: React.PropsWithChildren<ETOTextFieldProps>;
}

const CurrencyFieldWrapper: React.FC<CurrencyFieldWrapperProps> = (props) => {
  const { inputProps } = props;
  const currencyFormatter = useRef<CurrencyFormatter>(new CurrencyFormatter());
  const [hasFocus, setHasFocus] = useState<boolean>();
  const lastChangeEvent =
    useRef<React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>>();
  const [val, setVal] = useState<string>();

  const handleChange = useCallback(
    (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
      lastChangeEvent.current = e;
      setVal(e.currentTarget.value);
    },
    []
  );

  useEffect(() => {
    setVal(inputProps.value);
  }, [inputProps.value]);

  const currentValue = useMemo(() => {
    if (!val) return '';

    let result: string | number | undefined | null;

    if (hasFocus) {
      result = val;
    } else {
      result = currencyFormatter.current.formatValue(
        val,
        props.format,
        props.symbol
      );

      // formatted adds a starting symbol which unfortunately
      // cant change without breaking other things so this hacky approach is used for now.
      if (result) result = result.substring(props.symbol?.length ?? 1);
    }

    if (result === undefined || result === null) return '';

    return result;
  }, [hasFocus, props.format, props.symbol, val]);

  return (
    <ETOTextField
      {...inputProps}
      handleChange={(e) => handleChange(e)}
      inputProps={{
        min: 0,
        step: '0.1',
        style: { textAlign: 'right' },
        type: !hasFocus ? undefined : 'number',
      }}
      // eslint-disable-next-line react/jsx-no-duplicate-props
      InputProps={{
        startAdornment: (
          <InputAdornment position="end">{props.symbol ?? '$'}</InputAdornment>
        ),
        sx: {
          textAlign: 'right',
        },
      }}
      onBlur={() => {
        setHasFocus(false);
        if (lastChangeEvent.current) {
          inputProps.handleChange?.(lastChangeEvent.current);
        }
      }}
      onFocus={() => setHasFocus(true)}
      value={currentValue}
    />
  );
};

export default CurrencyFieldWrapper;
