import {
  IFormField,
  getMutationInputMetadata,
} from '../../../api/metadata/metadataManager';
import FormBuilder from '../FormBuilder/FormBuilder';
import {
  GraphQLSelectionType,
  graphQLOnSubmit,
  graphQLRequestFormatter,
  graphQLResponseFormatter,
} from '../FormBuilder/GraphQLExtensions';
import SubFormBuilder from '../FormBuilder/SubFormBuilder';
import IHandlers from '../FormBuilder/types/IHandlers';
import SelectSourceType from '../SelectSourceType';
import { ValidatorPrefixes } from '../clientSideValidators';
import { useFormBuilder } from './useFormBuilder';

function buildSelect(
  source: IFormField['select']
): SelectSourceType | undefined {
  if (!source) return undefined;

  switch (source.selectType) {
    case 'ENUM':
      return {
        fromEnum: source.source,
        label: source.labelField,
        value: source.valueField,
      };
    case 'GRAPH_QL_QUERY':
      return {
        fromGraphQLQuery: source.source,
        label: source.labelField,
        value: source.valueField,
      };
    case 'SAVED_QUERY':
      return {
        fromSavedGraphQLQuery: source.source,
        label: '',
        value: '',
      };
    default:
      return undefined;
  }
}

const buildField = (
  metadataField: IFormField
): Parameters<FormBuilder['addField']>[0] => {
  const newField: Parameters<FormBuilder['addField']>[0] = {
    name: metadataField.name,
    title: metadataField.title ?? '',
    hints: metadataField.hints,
    defaultValue: metadataField.defaultValue,
    type: metadataField.fieldType,
    fieldSource: 'metadata',
    persist: metadataField.persist,
    dependsOn: metadataField.dependsOn,
    selectSource: buildSelect(metadataField.select),
    objectBuilder: (metadataField.type?.kind === 'OBJECT' ||
    metadataField.type?.kind === 'LIST'
      ? (sb: SubFormBuilder) => {
          (metadataField.type?.ofType?.inputFields ?? []).forEach((inf) => {
            const field = buildField(inf);
            sb.addField(field);
          });
        }
      : // eslint-disable-next-line @typescript-eslint/no-explicit-any
        undefined) as any,
    required:
      metadataField.required ||
      Boolean(
        metadataField.validators?.find(
          (a) => (a as ValidatorPrefixes) === 'REQUIRED'
        )
      ),
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    validators: metadataField.validators as any,
  };

  return newField;
};

const createFromGraphQLForm = function _createFieldsFromMetaData<FT>(
  mutationName: string,
  fields: IFormField[],
  selectionFields: string[] | GraphQLSelectionType,
  formBuilder: FormBuilder<FT>
): void {
  // eslint-disable-next-line no-param-reassign
  formBuilder.handlers = {
    onFormatRequest: graphQLRequestFormatter(mutationName, selectionFields),
    onFormatResponse: graphQLResponseFormatter(),
    onSubmitting: graphQLOnSubmit(),
  };

  fields.forEach((c) => {
    // ignore custom fields, this will be added in
    // manually with the EditableCustomFieldFormBuilderExtension
    if (/custom\d/.test(c.name)) return;

    // ignore fields without a defined type
    // it would generally mean field has no metadata
    if (c.fieldType) {
      const newField = buildField(c);
      formBuilder.addField(newField);
    }
  });
};

const useFormBuilderFromMutation = <FT,>(
  mutationName: string,
  mutationInputName: string,
  selectionFields: string[] | GraphQLSelectionType,
  // eslint-disable-next-line no-unused-vars
  builder?: // eslint-disable-next-line no-unused-vars
  | ((formBuilder: FormBuilder<FT>) => void)
    // eslint-disable-next-line no-unused-vars
    | ((formBuilder: FormBuilder<FT>) => Promise<void>),
  handlers?: IHandlers<FT>
) => {
  const formBuilder = useFormBuilder<FT>(
    mutationName,
    (b) =>
      getMutationInputMetadata(mutationInputName)
        .then((mm) => {
          createFromGraphQLForm<FT>(mutationName, mm, selectionFields, b);
        })
        .then(() => Promise.resolve(builder?.(b))),
    handlers
  );

  return formBuilder;
};

export default useFormBuilderFromMutation;
