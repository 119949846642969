import Box from '@mui/material/Box';
import { SxProps, Theme } from '@mui/material/styles';
import { Inspector } from '@teto/react-component-library-v2';
import React, { useCallback } from 'react';
import { InspectorActions } from '../../types/InspectorActionTypes';
import { InspectorState } from '../../types/InspectorState';
import CloseInspectorConfirmation from './components/CloseInspectorConfirmation';
import EditableTabs, { TAddEditTabs } from './components/EditableTabs';

const containerSx: SxProps<Theme> = {
  display: 'flex',
  flexDirection: 'column',
  flexShrink: 0,
  height: '100%',
  rowGap: 2,
  width: '100%',
  '& .MuiTabs-root': {
    flexShrink: 0,
  },
  '& .MuiTabs-scrollButtons': {
    color: 'primary.main',
    '&:hover': {
      background: (theme) =>
        theme.palette.mode === 'light'
          ? 'rgba(0,0,0,0.04)'
          : 'rgba(255,255,255, 0.08)',
    },
    '& 	.MuiSvgIcon-root': {
      fill: 'currentcolor',
      fontSize: '1.5rem',
    },
  },
};

const customSx: SxProps<Theme> = {
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  flexGrow: 1,
  overflowY: 'hidden',
  position: 'relative',
  '& .MuiModal-root-MuiDrawer-root .MuiDrawer-paperAnchorRight': { rowGap: 0 },
};

interface AddEditInspectorProps<T>
  extends Omit<InspectorState<T>, 'currentValues'>,
    Pick<InspectorActions<T>, 'setEditingState'> {
  tabs: TAddEditTabs;
  // eslint-disable-next-line no-unused-vars
  onClose: (hasDataChanged: boolean) => void;
  open: boolean;
  // eslint-disable-next-line no-unused-vars
  setNeedsCloseConfirmation: (payload: boolean) => void;
  title?: string;
  // eslint-disable-next-line no-undef
  buttonStrip?: JSX.Element;
  renderAsInspector?: boolean;
}

const AddEditInspector = <GenericInitialValues,>(
  props: AddEditInspectorProps<GenericInitialValues>
) => {
  const {
    open,
    onClose,
    tabs,
    title,
    buttonStrip,
    setNeedsCloseConfirmation,
    editingState,
    isCreatingNewItem,
    needsCloseConfirmation,
    setEditingState,
    renderAsInspector,
  } = props;

  const _handleClose = useCallback(() => {
    if (editingState.isEditing || editingState.tabError || isCreatingNewItem) {
      setNeedsCloseConfirmation(true);
    } else {
      onClose(editingState.hasEdited);
    }
  }, [
    editingState.hasEdited,
    editingState.isEditing,
    editingState.tabError,
    isCreatingNewItem,
    onClose,
    setNeedsCloseConfirmation,
  ]);

  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      <Box sx={customSx}>
        {renderAsInspector && (
          <Inspector
            buttonStrip={buttonStrip}
            containerSx={{
              '& .MuiDrawer-paperAnchorRight': {
                rowGap: '0px',
              },
            }}
            dataTestid={`add-edit-inspector-${title}`}
            onClose={_handleClose}
            open={open}
            title={{ text: title ?? '' }}
          >
            <Box sx={containerSx}>
              <EditableTabs
                editingState={editingState}
                setEditingState={setEditingState}
                tabs={tabs}
              />
              <CloseInspectorConfirmation
                editingState={editingState}
                needsCloseConfirmation={needsCloseConfirmation}
                onClose={onClose}
                setNeedsCloseConfirmation={setNeedsCloseConfirmation}
              />
            </Box>
            {buttonStrip}
          </Inspector>
        )}
        {!renderAsInspector && (
          <>
            <Box sx={containerSx}>
              <EditableTabs
                editingState={editingState}
                setEditingState={setEditingState}
                tabs={tabs}
              />
              <CloseInspectorConfirmation
                editingState={editingState}
                needsCloseConfirmation={needsCloseConfirmation}
                onClose={onClose}
                setNeedsCloseConfirmation={setNeedsCloseConfirmation}
              />
            </Box>
            {buttonStrip}
          </>
        )}
      </Box>
    </>
  );
};

export default AddEditInspector;
