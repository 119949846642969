import { gql } from '../../../../__generated__';

// eslint-disable-next-line import/prefer-default-export
export const updatePOHeaderMutation =
  gql(`mutation updatePOHeaderMutation($input: UpdatePurchaseOrderHeaderInput){
  updatePurchaseOrderHeader(input: $input){
    purchaseDateRevised
    purchaseEmailed
    purchaseDateRequired
    purchaseOrderDetails {
      dateRevised
    }
    buyerId
    id
    compoundTax
    purchaseFob
    fobnotes
    pONotes {
      id
    }
    port
    purchaseActive
    purchaseCurrId
    purchaseCurrRate
    purchaseCurr {
      name
      symbol
      format
    }
    purchaseMisc
    purchaseDate
    purchaseNotes
    purchaseNotes2
    purchaseNotes3
    purchasePrinted
    purchaseRev
    purchaseShip
    purchaseShipTo {
      id
      name
    }
    purchaseSupplier {
      id
      name
    }
    purchaseSupplierId
    purchaseTax1
    purchaseTax1GLAccount {
      id
      displayName
    }
    purchaseTax1GLAccountId
    purchaseTax1Name
    purchaseTax2
    purchaseTax2GLAccount {
      id
      displayName
    }
    purchaseTax2GLAccountId
    purchaseTax2Name
    purchaseTerms
    purchaseShip
    taxes {
      tax1Amount
      tax2Amount
    }
    totals {
      detailTotal
      subtotal
    }
    custom1
    custom2
    custom3
    custom4
    custom5
    custom6
    custom7
    custom8
  }
}`);
