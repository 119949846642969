import { Box } from '@mui/material';
import { ETOTab, ETOTabs } from '@teto/react-component-library-v2';
import React from 'react';
import Tab from '../interfaces/Tab';

interface DesktopTabsProps {
  tabs: Tab[];
  currentTab: number;
  // eslint-disable-next-line no-unused-vars
  onTabChanged: (index: number) => void;
}

const DesktopTabs = (props: DesktopTabsProps) => {
  const { tabs, currentTab, onTabChanged } = props;

  return (
    <Box>
      <ETOTabs
        onChange={(_, nt) => onTabChanged(nt)}
        value={currentTab}
        variant="scrollable"
      >
        {tabs.map((v) => (
          <ETOTab
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            isSelected={currentTab === v.index}
            key={v.name}
            label={v.name}
            title={v.name}
            value={v.index}
          />
        ))}
      </ETOTabs>
    </Box>
  );
};

export default DesktopTabs;
