import { TypeComputedProps } from '@inovua/reactdatagrid-community/types';
import { MessageContext } from '@teto/react-component-library-v2';
import { useSetAtom } from 'jotai';
import { uniqueId } from 'lodash';
import React, {
  Dispatch,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { getGraphQLClient } from 'teto-client-api';
import getAllSuppliers from '../../../TETOForms/selects/getAllSuppliers';
import {
  Company,
  RfqCompaniesCollectionSegment,
  RfqCompany,
  RfqHeader,
} from '../../../../__generated__/graphql';
import getErrors from '../../../../api/graphQL/getErrors';
import useGQLQuery from '../../../../api/graphQL/useGQLQuery';
import ChipList from '../../../ChipList/ChipList';
import CommonGridSupplierSelectField from '../../../InputFields/CommonGridSupplierSelectField/CommonGridSupplierSelectField';
import { RFQSharedState } from '../../../SharedStateComponents/StateContainers/RFQState';
import SaveStatusState from '../../../SharedStateComponents/StateContainers/SaveStatusState';
import { getRFQCompanies } from '../../../TETOForms/selects/selects';
import addRFQSupplierMutation from '../queries/addRFQSupplierMutation';
import deleteRFQSupplierMutation from '../queries/deleteRFQSupplierMutation';

export interface SupplierChipListProps {
  canAdd: boolean;
  canDelete: boolean;
  rFQ: RfqHeader;
  setSupplierCount?: Dispatch<SetStateAction<number>>;
}

const supplierSx = {
  maxWidth: '300px',
  mr: 1,
};
const alignCenterSx = {
  alignItems: 'center',
};

type CustomSupplier = RfqCompany & { id: number };

const SupplierChipList = (props: SupplierChipListProps) => {
  const { canAdd, canDelete, rFQ, setSupplierCount } = props;
  const setSaveState = useSetAtom(SaveStatusState);
  const setRFQ = useSetAtom(RFQSharedState);

  const messageContext = useContext(MessageContext);
  const { t } = useTranslation();

  const gridRef = useRef<TypeComputedProps | null>(null);

  const [suppliers, setSuppliers] = useState<CustomSupplier[]>([]);

  const supplierIds = useMemo(
    () => suppliers.map((i) => i.companyId),
    [suppliers]
  );

  useGQLQuery<RfqCompaniesCollectionSegment>(['rfq-suppliers-query', rFQ.id], {
    queryString: getRFQCompanies.query,
    variables: { where: { rFQId: { eq: rFQ.id } } },
    callback: (d) => {
      if (d?.rFQCompanies?.items) {
        const rFQCompanyUIDData = d?.rFQCompanies?.items.map(
          (i: Partial<RfqCompany>) => ({
            ...i,
            id: uniqueId(),
          })
        );
        setSuppliers(rFQCompanyUIDData);
        if (setSupplierCount) {
          setSupplierCount(d?.rFQCompanies?.items?.length);
        }
      }
    },
    options: {
      refetchOnWindowFocus: false,
      enabled: Boolean(rFQ?.id),
    },
  });

  const onErrors = useCallback((errors: unknown) => {
    messageContext.setError(getErrors(errors));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const _handleSupplierDelete = useCallback(
    (d: CustomSupplier) => {
      getGraphQLClient()
        .performMutation(deleteRFQSupplierMutation, {
          input: {
            companyId: d?.companyId as number,
            rFQHeaderId: d?.rFQId as number,
          },
        })
        .then((r) => {
          if (r.hasError()) {
            if (r.systemErrors) onErrors(r.systemErrors);
            if (r.validationErrors) onErrors(r.validationErrors);
            return;
          }
          if (r.hasData() && r.data.deleteRFQCompany?.success) {
            setSuppliers((s) => s.filter((f) => f.id !== d.id));
            if (setSupplierCount) {
              setSupplierCount((prev) => prev - 1);
            }
            setSaveState((prev) => ({
              ...prev,
              hasSavedOccurred: r.hasData(),
            }));
            setRFQ((prev) => ({
              ...prev,
              hasSavedOccurred: r.hasData(),
            }));
          }
        });
    },
    [onErrors, setRFQ, setSaveState, setSupplierCount]
  );

  const _handleAddSupplier = useCallback(
    (d: CustomSupplier) => {
      getGraphQLClient()
        .performMutation(addRFQSupplierMutation, {
          input: {
            companyId: d?.id as number,
            rFQHeaderId: rFQ?.id as number,
          },
        })
        .then((r) => {
          if (r.hasError()) {
            if (r.systemErrors) onErrors(r.systemErrors);
            if (r.validationErrors) onErrors(r.validationErrors);
            return;
          }
          if (r.hasData() && r.data.addRFQCompany) {
            setSuppliers(
              (s) =>
                [
                  ...s,
                  { ...r.data.addRFQCompany, id: uniqueId() },
                ] as CustomSupplier[]
            );
            if (setSupplierCount) {
              setSupplierCount((prev) => prev + 1);
            }
            setSaveState((prev) => ({
              ...prev,
              hasSavedOccurred: r.hasData(),
            }));
            setRFQ((prev) => ({
              ...prev,
              hasSavedOccurred: r.hasData(),
            }));
          }
        });
    },
    [onErrors, rFQ.id, setRFQ, setSaveState, setSupplierCount]
  );

  const _FormatSupplerLabel = useCallback((d: CustomSupplier) => {
    const { name, city, supplier } = d?.company as Company;
    return `${name}${city ? ` [${city}]` : ''}${
      // eslint-disable-next-line no-nested-ternary
      supplier?.supQaapproved === false
        ? ' (Unapproved)'
        : supplier?.supQaapproved === true
        ? ' (Approved)'
        : ''
    }`;
  }, []);

  useEffect(() => {
    const availableWidth = gridRef?.current?.availableWidth ?? 0;
    const totalComputedWidth = gridRef?.current?.totalComputedWidth ?? 0;
    if (totalComputedWidth < availableWidth) {
      gridRef.current?.setColumnSizesToFit?.();
    }
  }, [gridRef?.current?.availableWidth, gridRef?.current?.totalComputedWidth]);

  return (
    <>
      <ChipList
        addButtonLabel={t('pages.rFQ.rFQModal.addSupplier')}
        canAddItems={canAdd}
        canDeleteItems={canDelete}
        confirm={{
          content: t('dialogs.genericDelete.content', {
            item: t('entities:Supplier.Supplier'),
          }),
          title: t('dialogs.genericDelete.title', {
            item: t('entities:Supplier.Supplier'),
          }),
        }}
        customAddButton={
          <CommonGridSupplierSelectField
            customQuery={getAllSuppliers.query}
            disabled={!canAdd}
            fieldName="purchaseSupplier"
            handleChange={(e) => {
              if (
                e.target.value &&
                supplierIds.indexOf(e.target.value) !== -1
              ) {
                return messageContext.setError(
                  t('pages.rFQ.rFQModal.duplicateSupplier')
                );
              }
              _handleAddSupplier(e.target.otherInfo.company);
            }}
            label={t('pages.rFQ.rFQModal.addSupplier')}
            shouldHaveWarning={false}
            sx={supplierSx}
          />
        }
        customSx={alignCenterSx}
        handleDeleteClick={_handleSupplierDelete}
        itemLabelSelector={_FormatSupplerLabel}
        listItems={suppliers ?? []}
      />
    </>
  );
};

export default SupplierChipList;
