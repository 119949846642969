import { gql } from '../../../../__generated__';

const exportReportQuery =
  gql(`query exportReportQuery($id: Int!, $input: ExportReportRequestInput!, $companyDetails: Boolean!) {
    exportReport(id: $id, input: $input, companyDetails: $companyDetails) {
        reportId
        parameters
        path
      }
}`);

export default exportReportQuery;
