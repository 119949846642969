import SearchIcon from '@mui/icons-material/Search';
import {
  Divider,
  Grid,
  IconButton,
  InputAdornment,
  SxProps,
  TextField,
  Theme,
  Tooltip,
} from '@mui/material';
import {
  ETOCheckBox,
  ETOTextField,
  GenericDialog,
  MessageContext,
  useDebounce,
} from '@teto/react-component-library-v2';
import { useSetAtom } from 'jotai';
import React, {
  Dispatch,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { getGraphQLClient } from '../../../api/graphQL/graphQLClient';
import PartsSearchGrid from '../../PartsSearch/PartsSearchGrid';
import { BreadCrumbSharedState } from '../../SharedStateComponents/StateContainers/BreadCrumbModalState';

interface PartsHistorySearchInputProps {
  part?: {
    id: number;
    label: string;
  };
  timeFrame: number;
  includeRelPS: boolean;
  sx?: SxProps<Theme>;
  mobile?: boolean;
  // eslint-disable-next-line no-unused-vars
  onPartChanged: (part?: { id: number; label: string }) => void;
  // eslint-disable-next-line no-unused-vars
  onTimeframeChanged: (timeFrame: number) => void;
  // eslint-disable-next-line no-unused-vars
  onIncludeRelPSChanged: (includeRelPS: boolean) => void;
  setPopoverOpen?: Dispatch<SetStateAction<HTMLButtonElement | null>>;
}

const titleSx = {
  color: 'primary.main',
  fontSize: (theme: Theme) => theme.typography.subtitle1,
  p: 0,
  pl: 1,
  pb: 0.5,
};
const contentSx = { p: 0, pl: 1, pr: 1 };
const topDividerSx = {
  width: '97%',
  position: 'absolute',
};

const PartsHistorySearchInput: React.FC<PartsHistorySearchInputProps> = (
  props
) => {
  const {
    includeRelPS,
    onIncludeRelPSChanged,
    onPartChanged,
    onTimeframeChanged,
    part,
    setPopoverOpen,
    sx,
    timeFrame,
    mobile,
  } = props;

  const [partSearchText, setPartSearchText] = useState(part?.label ?? '');
  const debouncedPartSearchText = useDebounce(partSearchText, 500);
  const messageContext = useContext(MessageContext);
  const [partError, setPartError] = useState('');
  const [timeFrameError, setTimeFrameError] = useState('');
  const { t } = useTranslation();
  const setLevels = useSetAtom(BreadCrumbSharedState);

  const [isPartSearchOpen, setIsPartSearchOpen] = useState<boolean>(false);

  const _doQuery = useCallback(
    (itemCompanyId: string) => {
      if (!timeFrame) return;
      // do graphql query for part info here
      const queryText = `query engItemMasters ($itemCompanyId: String!) {
        engItemMasters(where: { itemCompanyId: { eq: $itemCompanyId }}) {
          items {
            id
          }
        }
      }`;

      setPartError('');
      getGraphQLClient()
        .performQuery(queryText, {
          itemCompanyId,
        })
        .then((d) => {
          if (
            !d?.engItemMasters?.items ||
            d.engItemMasters.items.length === 0
          ) {
            setPartError(t('generic.message.partNotFound'));
            if (mobile)
              messageContext.setError(t('generic.message.partNotFound'));
            onPartChanged(undefined);
            return;
          }

          onPartChanged({
            id: d.engItemMasters.items[0].id,
            label: itemCompanyId,
          });
        })
        .catch((e) => {
          messageContext.setError(e?.message ?? e);
          setPartError(t('generic.message.partSearchFailed'));
          if (mobile)
            messageContext.setError(t('generic.message.partSearchFailed'));
          onPartChanged(undefined);
        });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [onPartChanged, t, timeFrame]
  );

  useEffect(() => {
    setTimeFrameError('');
    if (!debouncedPartSearchText) {
      setPartError('');
      onPartChanged(undefined);
      return;
    }

    if (!timeFrame) {
      setTimeFrameError(t('generic.required'));
      return;
    }

    _doQuery(debouncedPartSearchText);
  }, [_doQuery, debouncedPartSearchText, onPartChanged, t, timeFrame]);

  const _onPartPicked = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (selectedItems: any[]) => {
      const selectedPart = Object.values(selectedItems)[0];

      if (selectedItems && selectedPart) {
        if (mobile) {
          if (!timeFrame) {
            setTimeFrameError(t('generic.required'));
            messageContext.setError(t('generic.message.missingTimeframe'));
            return;
          }
          _doQuery(selectedPart.itemCompanyId);
        } else setPartSearchText(selectedPart.itemCompanyId);
      } else {
        setPartSearchText('');
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [_doQuery, mobile, setLevels, timeFrame]
  );

  const _onSearchClicked = useCallback(() => {
    setPopoverOpen?.(null);
    setIsPartSearchOpen(true);
  }, [setPopoverOpen]);

  return (
    <Grid columnSpacing={2} container sx={sx}>
      <Grid item md={4} xs={12}>
        <ETOTextField
          error={partError}
          handleChange={(e) => {
            setPartSearchText(e.target.value);
          }}
          InputProps={{
            endAdornment: (
              <Tooltip title={t('pages.partsHistory.partSearch')}>
                <InputAdornment position="end">
                  <IconButton
                    aria-label="Search"
                    color="primary"
                    edge="end"
                    onClick={_onSearchClicked}
                  >
                    <SearchIcon />
                  </IconButton>
                </InputAdornment>
              </Tooltip>
            ),
          }}
          label={t('pages.partsHistory.partNumber')}
          name="partLabel"
          size="small"
          value={partSearchText}
        />
      </Grid>
      <Grid item md={4} xs={12}>
        <TextField
          error={Boolean(timeFrameError)}
          fullWidth
          helperText={timeFrameError}
          inputProps={{
            min: 0,
          }}
          label={t('pages.partsHistory.timeframeDays')}
          name="timeFrame"
          onBlur={(e) => {
            onTimeframeChanged(parseInt(e.currentTarget.value, 10) || 0);
          }}
          onChange={(e) => {
            onTimeframeChanged(parseInt(e.currentTarget.value ?? 1, 10));
          }}
          size="small"
          type="number"
          value={timeFrame ?? 0}
          variant="outlined"
        />
      </Grid>
      <Grid item md={4} xs={12}>
        <ETOCheckBox
          handleChange={(e) => onIncludeRelPSChanged(e.currentTarget.checked)}
          label={t('pages.partsHistory.includeRelease')}
          name="includeRelPS"
          value={includeRelPS}
        />
      </Grid>
      {isPartSearchOpen && (
        <GenericDialog
          contentSx={contentSx}
          fullScreen
          isOpen={isPartSearchOpen}
          onClose={() => setIsPartSearchOpen(false)}
          title={t('inspectors.engItemMasterInspector.title')}
          titleSx={titleSx}
        >
          <Divider sx={topDividerSx} />
          <PartsSearchGrid
            // eslint-disable-next-line prettier/prettier, @typescript-eslint/no-explicit-any
            disableCheckBoxColumn
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            onPick={(vals: any) => {
              _onPartPicked(vals);
              setIsPartSearchOpen(false);
            }}
          />
        </GenericDialog>
      )}
    </Grid>
  );
};

export default PartsHistorySearchInput;
