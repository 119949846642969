/* eslint-disable import/no-cycle */
import LockOutlined from '@mui/icons-material/LockOutlined';
import { Box, Theme, useMediaQuery, useTheme } from '@mui/material';
import { ErrorPage } from '@teto/react-component-library-v2';
import dayjs from 'dayjs';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Licenses, Permission } from 'teto-client-api';
import useLocalStorage from 'use-local-storage';
import AuthContext from '../../contexts/AuthContext';
import TabPanel from '../Inspectors/components/AddEditInspector/components/InspectorTabPanel';
import { PartHistoryProps } from './PartHistoryProps';
import PartHistoryTab from './PartHistoryTab';
import BOMPartsOrderReleasesTab from './components/BOMPartsOrderReleasesTab';
import BillOfMaterialsTab from './components/BillOfMaterialsTab';
import DesktopTabs from './components/DesktopTabs';
import InventoryPullsReturnsTab from './components/InventoryPullsReturnsTab';
import MobileTabs from './components/MobileTabs';
import MobileTitleBar from './components/MobileTitleBar';
import NoPartNumber from './components/NoPartNumber';
import NonBomTab from './components/NonBomTab';
import NonConformancesTab from './components/NonConformancesTab';
import PartsHistorySearchInput from './components/PartsHistorySearchInput';
import PartsOrdersTab from './components/PartsOrdersTab';
import ProcessSchedulesTab from './components/ProcessSchedulesTab';
import PurchaseOrdersTab from './components/PurchaseOrdersTab';
import RFQsTab from './components/RFQsTab';
import ReceivingTab from './components/ReceivingTab';
import StandardAssembliesTab from './components/StandardAssembliesTab';
import WorkOrdersTab from './components/WorkOrdersTab';

const iconSx = (theme: Theme) => ({
  fontSize: '5.5rem',
  [theme.breakpoints.up('md')]: {
    fontSize: '7.5rem',
  },
});
const PartHistory = (props: PartHistoryProps) => {
  const { part, tab = 0 } = props;
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const authContext = React.useContext(AuthContext);

  const { t } = useTranslation();
  const [partNumber, setPartNumber] = useState<
    | {
        id: number;
        label: string;
      }
    | undefined
  >(part);

  const [numberOfDays, setNumberOfDays] = useLocalStorage(
    'part-history-number-of-days',
    180
  );
  const [includeRelPS, setIncludeRelPS] = useState(true);
  const [selectedTabIndex, setSelectedTabIndex] = useLocalStorage(
    'part-history-selected-tab',
    tab
  );
  const [openConfigureInspector, setOpenConfigureInspector] = useState<
    number | undefined
  >();
  const [shouldExportData, setShouldExportData] = useState<
    number | undefined
  >();

  const _onConfigureInspectorChanged = useCallback(
    (open: boolean | (() => boolean), tabIndex: number) => {
      const isOpen = typeof open === 'function' ? open() : open;
      return setOpenConfigureInspector(!isOpen ? undefined : tabIndex);
    },
    []
  );

  const canAccessPartHistory =
    authContext.hasLicense(Licenses.ReadOnlyProfessional) &&
    authContext.hasAnyPermission([Permission.View_General_PartHistory]);

  useEffect(() => {
    if (tab !== selectedTabIndex) {
      setSelectedTabIndex(tab);
    }
    // Only run on mount, otherwise it locks the user to the tab they were on
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tab]);

  const tabs = useMemo(() => {
    if (!partNumber || !numberOfDays) return [];

    const date = dayjs().startOf('date').subtract(numberOfDays, 'day');

    const commonProps = {
      part: partNumber,
      includeRelPS,
      date,
      shouldExportData,
      setShouldExportData,
    };

    return [
      {
        index: 0,
        name: 'Bill of Materials',
        component: (
          <BillOfMaterialsTab
            {...commonProps}
            configureInspectorOpen={openConfigureInspector === 0}
            setConfigureInspector={(open) =>
              _onConfigureInspectorChanged(open, 0)
            }
          />
        ),
      },
      {
        index: 1,
        name: 'Parts Orders',
        component: (
          <PartsOrdersTab
            {...commonProps}
            configureInspectorOpen={openConfigureInspector === 1}
            setConfigureInspector={(open) =>
              _onConfigureInspectorChanged(open, 1)
            }
          />
        ),
      },
      {
        index: 2,
        name: 'Standard Assemblies',
        component: (
          <StandardAssembliesTab
            {...commonProps}
            configureInspectorOpen={openConfigureInspector === 2}
            setConfigureInspector={(open) => {
              _onConfigureInspectorChanged(open, 2);
            }}
          />
        ),
      },
      {
        index: 3,
        name: 'Purchase Orders',
        component: (
          <PurchaseOrdersTab
            {...commonProps}
            configureInspectorOpen={openConfigureInspector === 3}
            setConfigureInspector={(open) =>
              _onConfigureInspectorChanged(open, 3)
            }
          />
        ),
      },
      {
        index: 4,
        name: 'RFQs',
        component: (
          <RFQsTab
            {...commonProps}
            configureInspectorOpen={openConfigureInspector === 4}
            setConfigureInspector={(open) =>
              _onConfigureInspectorChanged(open, 4)
            }
          />
        ),
      },
      {
        index: 5,
        name: 'Non-BOM',
        component: (
          <NonBomTab
            {...commonProps}
            configureInspectorOpen={openConfigureInspector === 5}
            setConfigureInspector={(open) =>
              _onConfigureInspectorChanged(open, 5)
            }
          />
        ),
      },
      {
        index: 6,
        name: 'Non-Conformances',
        component: (
          <NonConformancesTab
            {...commonProps}
            configureInspectorOpen={openConfigureInspector === 6}
            setConfigureInspector={(open) =>
              _onConfigureInspectorChanged(open, 6)
            }
          />
        ),
      },
      {
        index: 7,
        name: 'Inventory Pull\\Returns',
        component: (
          <InventoryPullsReturnsTab
            {...commonProps}
            configureInspectorOpen={openConfigureInspector === 7}
            setConfigureInspector={(open) =>
              _onConfigureInspectorChanged(open, 7)
            }
          />
        ),
      },
      {
        index: 8,
        name: 'Receiving',
        component: (
          <ReceivingTab
            {...commonProps}
            configureInspectorOpen={openConfigureInspector === 8}
            setConfigureInspector={(open) =>
              _onConfigureInspectorChanged(open, 8)
            }
          />
        ),
      },
      {
        index: 9,
        name: 'Process Schedules',
        component: (
          <ProcessSchedulesTab
            {...commonProps}
            configureInspectorOpen={openConfigureInspector === 9}
            setConfigureInspector={(open) =>
              _onConfigureInspectorChanged(open, 9)
            }
          />
        ),
      },
      {
        index: 10,
        name: 'Work Orders',
        component: (
          <WorkOrdersTab
            {...commonProps}
            configureInspectorOpen={openConfigureInspector === 10}
            setConfigureInspector={(open) =>
              _onConfigureInspectorChanged(open, 10)
            }
          />
        ),
      },
      {
        index: 11,
        name: 'BOM\\Parts Order Releases',
        component: (
          <BOMPartsOrderReleasesTab
            {...commonProps}
            configureInspectorOpen={openConfigureInspector === 11}
            setConfigureInspector={(open) =>
              _onConfigureInspectorChanged(open, 11)
            }
          />
        ),
      },
    ] as PartHistoryTab[];
  }, [
    _onConfigureInspectorChanged,
    includeRelPS,
    numberOfDays,
    openConfigureInspector,
    partNumber,
    shouldExportData,
  ]);

  const handlePartChanged = useCallback(
    (
      // eslint-disable-next-line no-shadow
      part?:
        | {
            id: number;
            label: string;
          }
        | undefined
    ) => {
      setPartNumber(part);
    },
    []
  );
  return (
    <>
      {canAccessPartHistory && (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            height: '100%',
          }}
        >
          {isMobile && (
            <>
              <MobileTitleBar
                includeRelPS={includeRelPS}
                onIncludeRelPSChanged={(v) => setIncludeRelPS(v)}
                onPartChanged={handlePartChanged}
                onTimeframeChanged={(v) => setNumberOfDays(v)}
                part={partNumber}
                timeFrame={numberOfDays}
              />
              <MobileTabs
                currentTab={selectedTabIndex}
                onExportClicked={(tabIndex) => setShouldExportData(tabIndex)}
                onInspectorOpen={(tabIndex) =>
                  setOpenConfigureInspector(tabIndex)
                }
                onTabChanged={(v) => setSelectedTabIndex(v)}
                tabs={tabs}
              />
            </>
          )}
          {!isMobile && (
            <>
              <PartsHistorySearchInput
                includeRelPS={includeRelPS}
                onIncludeRelPSChanged={(v) => setIncludeRelPS(v)}
                onPartChanged={handlePartChanged}
                onTimeframeChanged={(v) => setNumberOfDays(v)}
                part={partNumber}
                sx={{ mb: 2, pl: 2, mt: 1 }}
                timeFrame={numberOfDays}
              />
              <DesktopTabs
                currentTab={selectedTabIndex}
                onTabChanged={(v) => setSelectedTabIndex(v)}
                tabs={tabs}
              />
            </>
          )}
          {tabs && tabs.length === 0 ? (
            <NoPartNumber />
          ) : (
            tabs.map((v) => (
              <TabPanel index={v.index} key={v.name} value={selectedTabIndex}>
                {v.component}
              </TabPanel>
            ))
          )}
        </Box>
      )}
      {!canAccessPartHistory && (
        <ErrorPage
          icon={<LockOutlined color="action" data-testid="icon" sx={iconSx} />}
          reason={t('pages.permissionError.reason')}
          warning={t('pages.permissionError.warning')}
        />
      )}
    </>
  );
};

export default PartHistory;
