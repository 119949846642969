import { ETOSelectField } from '@teto/react-component-library-v2';
import { FormikProps } from 'formik';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import useBuildQuery from '../../../../../../../api/graphQL/useBuildQuery';
import { TEngItemMaster } from '../../../../types/TEngItemMaster';

interface ProcessScheduleTemplatesSelectFieldProps {
  disabled?: boolean;
  formik: FormikProps<Partial<TEngItemMaster>>;
  // eslint-disable-next-line no-unused-vars, @typescript-eslint/no-explicit-any
  handleChange: (e: Partial<React.ChangeEvent<any>>) => void;
}
const processesQuery = `query processScheduleTemplates {
  processScheduleTemplates{
   items {
     name
     id
   }
  }
 }`;

type ProcessScheduleTemplate = {
  name: string;
  id: number;
};
type ProcessScheduleTemplateApiResponse = {
  processScheduleTemplates: {
    items: ProcessScheduleTemplate[];
  };
};

const ProcessesSelectField = (
  props: ProcessScheduleTemplatesSelectFieldProps
) => {
  const { formik, disabled, handleChange } = props;
  const { t } = useTranslation();
  const processScheduleTemplates =
    useBuildQuery<ProcessScheduleTemplateApiResponse>(
      ['partProcessScheduleTemplates'],
      {
        queryString: processesQuery,
        variables: {},
        options: { refetchOnMount: false, refetchOnWindowFocus: false },
      }
    );

  const spanFullWidth = useMemo(() => ({ gridColumn: 'span 2' }), []);
  return (
    <ETOSelectField
      autoCompleteSx={spanFullWidth}
      disabled={disabled}
      error={formik.errors?.processScheduleTemplateId}
      handleChange={(e) =>
        handleChange({
          ...e,
          target: {
            ...e.target,
            name: 'processScheduleTemplateId',
          },
        })
      }
      inputLabelProps={{ shrink: true }}
      itemNameSelector={(item) => item.name}
      items={
        processScheduleTemplates.data?.processScheduleTemplates.items ?? []
      }
      itemValueSelector={(item) => item?.id}
      label={t('entities:Part.ProcessId')}
      name="processScheduleTemplateId"
      size="small"
      value={formik?.values.processScheduleTemplateId ?? ''}
    />
  );
};

export default ProcessesSelectField;
