const engItemMasterQuery = `query engItemMaster($id: Int!) {
  engItemMaster(id: $id) { 
    category {
      itemCategory
    }   
    countryOfOrigin {
      name
    }
    description
    estimatedLeadTime
    hasDrawing
    id
    itemCertifiedPrints
    itemCompanyId
    itemLastCost
    itemListCost
    itemMaintenance
    itemRelub
    itemRepairable
    itemReserved
    itemRevNumber
    itemWeight
    lastSupplierCompanyId
    manufacturer
    manufacturerPartNumber
    obsolete
    preferredSupplierCompanyId
    processScheduleTemplateId
    rawMaterialCutLength
    supplierPartNumber
    tariffCode
    rawMaterialItem {
      id
    }
    quantities{
      onHand
    }
    uOM {
      description
      itemUom
    } 
    custom1
    custom2
    custom3
    custom4
    custom5
    custom6
    custom7
    custom8
    custom9
    custom10
    custom11
    custom12
    custom13
    custom14
    custom15
    custom16
    custom17
    custom18
  }
}`;

export default engItemMasterQuery;
