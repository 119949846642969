import FormFieldHints from '../../components/TETOForms/FormBuilder/types/FormFieldHints';
import { getGraphQLClient } from '../graphQL/graphQLClient';

const query = `
  fragment fieldInfo on __Field {
    name
    fieldType
    title
    isPrimaryKey
    defaultWidth
    groupingAggregate
    isCollection
    isDisabled
    disableSort
    disableFilter
    linkTo {
      type
      column
    }
  }

  fragment typeInfo on __Type {
    name
    specifiedByURL
    kind
    ofType {
      name
    }
  }

  query getMetadata($typeName: String!) {
    __type(name: $typeName) {
      fields {
        ...fieldInfo
        type {
          ...typeInfo
          fields {
            ...fieldInfo
            type {
              ...typeInfo
              fields {
                ...fieldInfo
                type {
                  ...typeInfo
                  fields {
                    ...fieldInfo
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

const inputTypeQuery = `
fragment typeFields on __Type {
  name
  kind
}

fragment inputField on __InputValue {
  name
  dependsOn,
  validators,
  fieldType,
  defaultValue,
  hints,
  title,
  select {
    selectType,
    labelField,
    valueField,
    source
  },
  persist,
  required,
}

query getMutationMetadata($mutationInputName: String!) {
  __type(name: $mutationInputName) {
    ...typeFields,
    inputFields {
      ...inputField,
      type {
        ...typeFields
        ofType {
          ...typeFields
          inputFields {
            ...inputField
            type {
              ...typeFields
              ofType {
                ...typeFields
                inputFields {
                  ...inputField
                }
              }
            }
          }
        }
      }
    }
  }
}
  `;

const enumQuery = `
  query getEnum($enumName: String!) {
    __type(name: $enumName) {
      name,
      enumValues{
        name
      }
    }
  }
`;

interface IFieldType {
  kind: string;
  ofType: {
    name?: string;
    kind: string;
    inputFields?: IFormField[];
  };
}

export function getEnum(enumName: string): Promise<
  | {
      data: {
        __type?: {
          name: string;
          enumValues: { name: string }[];
        };
      };
    }
  | null
  | undefined
> {
  const client = getGraphQLClient();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return client.performQuery(enumQuery as any, { enumName });
}

export function getMetadata(typeName: string) {
  const client = getGraphQLClient();
  return client.performQuery(query, { typeName });
}

export interface IFormField {
  name: string;
  validators?: string[];
  kind?: string;
  hints?: FormFieldHints[];
  fieldType:
    | 'string'
    | 'password'
    | 'integer'
    | 'decimal'
    | 'date'
    | 'datetime'
    | 'time'
    | 'boolean'
    | 'currency'
    | 'object'
    | 'list'
    | 'unknown'
    | 'stringmultiline'
    | 'enum';
  title?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  defaultValue: any;
  type: IFieldType;
  dependsOn?: string[];
  persist?: boolean;
  required: boolean;
  select?: {
    selectType: 'ENUM' | 'GRAPH_QL_QUERY' | 'SAVED_QUERY';
    source: string;
    labelField: string;
    valueField: string;
  };
}

export function getMutationInputMetadata(
  mutationInputName: string
): Promise<IFormField[]> {
  const client = getGraphQLClient();
  return (
    client
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      .performQuery(inputTypeQuery as any, { mutationInputName })
      .then((r) => r.__type.inputFields)
  );
}

export default query;
