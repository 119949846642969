import { Box, Grid, Theme } from '@mui/material';
import { ETOButton, ETOTextField } from '@teto/react-component-library-v2';
import { useFormikContext } from 'formik';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import FilePicker from '../../../../FilePicker/FilePicker';
import { InspectorActions } from '../../../types/InspectorActionTypes';

export interface IDrawingsForm {
  description: string;
  drawing: string;
}

interface DrawingsFormProps
  extends Pick<InspectorActions<IDrawingsForm>, 'setEditingState'> {
  canModifyDrawing: boolean;
}
const inputSx = { display: 'flex', gap: 2 };

const heightSx = { height: (theme: Theme) => theme.spacing(5) };

const DrawingsForm = (props: DrawingsFormProps) => {
  const { canModifyDrawing, setEditingState } = props;

  const [isFilePickerOpen, setIsFilePickerOpen] = useState(false);

  const { t } = useTranslation();

  const formik = useFormikContext<IDrawingsForm>();

  useEffect(() => {
    setEditingState({
      isEditing: formik.dirty,
      hasEdited: formik.dirty,
      tabError: !formik.isValid,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.dirty, formik.isSubmitting, formik.isValid]);

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Box m={0} mb={0} sx={inputSx}>
            <ETOTextField
              disabled={!canModifyDrawing}
              error={formik.errors.drawing}
              handleChange={formik.handleChange}
              label={t('generic.location')}
              name="drawing"
              size="small"
              value={formik.values.drawing}
            />
            <ETOButton
              color="primary"
              customSx={heightSx}
              onClick={() => setIsFilePickerOpen(true)}
              size="medium"
            >
              {t('generic.select')}
            </ETOButton>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Box m={0} mb={0}>
            <ETOTextField
              disabled={!canModifyDrawing}
              error={formik.errors.description}
              handleChange={formik.handleChange}
              label={t('generic.description')}
              name="description"
              size="small"
              value={formik.values.description}
            />
          </Box>
        </Grid>
      </Grid>
      {isFilePickerOpen && (
        <FilePicker
          isOpen={isFilePickerOpen}
          onClose={() => {
            setIsFilePickerOpen(false);
          }}
          // eslint-disable-next-line no-shadow
          onPick={(file) => {
            formik.setValues(
              {
                ...formik.values,
                drawing: file.id as string,
                description: file.name as string,
              },
              false
            );
          }}
        />
      )}
    </>
  );
};

export default DrawingsForm;
