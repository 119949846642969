type CustomFields =
  | 'custom1'
  | 'custom2'
  | 'custom3'
  | 'custom4'
  | 'custom5'
  | 'custom6'
  | 'custom7'
  | 'custom8'
  | string; // added string type for custom types

type CustomFieldCaptions = {
  // eslint-disable-next-line no-unused-vars
  [key in CustomFields]: string;
};

export const createDefaultCustomFieldCaptions = (
  prefix: string | undefined,
  num: number
): CustomFieldCaptions => {
  // Initialize captions object and add default caption
  const captionsObject: CustomFieldCaptions = {};

  // Create an array of numbers from 1 to num using Array.from()
  const numbersArray = Array.from({ length: num }, (_, index) => index + 1);

  // Loop through each number in the numbersArray and add a custom caption to the captionsObject
  numbersArray.forEach((number) => {
    const customCaption = prefix
      ? `${prefix} Custom ${number}`
      : `Custom ${number}`; // Combine prefix and custom field number to create a custom caption
    captionsObject[`custom${number}`] = customCaption; // Add the custom caption to the captions object
  });

  return captionsObject; // Return the captions object
};

export const findDisabledCustomCaptions = (
  captions: string[],
  prefix?: string,
  amountOfCustomFields?: number
) => {
  const defaultCustomFieldCaptions = createDefaultCustomFieldCaptions(
    prefix,
    amountOfCustomFields ?? 8
  );
  const disabledFields = captions.filter((caption) => {
    if (Object.values(defaultCustomFieldCaptions).includes(caption)) {
      return caption;
    }
    return false;
  });
  return disabledFields;
};

const isCustomCaption = (
  field: CustomFields,
  caption: string,
  prefix: string,
  amountOfCustomFields?: number
): boolean => {
  const defaultCustomFieldCaptions = createDefaultCustomFieldCaptions(
    prefix,
    amountOfCustomFields ?? 8
  );
  // Make sure that the caption ****is translated*** when passing in
  //  return whether the default caption matches
  return defaultCustomFieldCaptions[field] !== caption;
};
// const customField = 'custom8';
// const caption = 't('entities:Part.PartCustom8')'; => if not changed in the agent it will be Part Custom 8 - if changed it will be the custom caption
// const isCustom = isCustomCaption(customField, caption, "Part", 15);
// console.log(isCustom); => true
//

export default isCustomCaption;
