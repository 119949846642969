import { EnumType, jsonToGraphQLQuery } from 'json-to-graphql-query';
import { Either, getGraphQLClient } from 'teto-client-api';
import FormDefinition from '../FormDefinition';
import FormBuilder from './FormBuilder';
import IHandlers from './types/IHandlers';

export type GraphQLSelectionType = {
  [key: string]: GraphQLSelectionType | boolean;
};

const _columnsToJson = (columns: string[]) => {
  const results: GraphQLSelectionType = {};

  columns.sort().forEach((c) => {
    const cSplit = c.split('.');
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    let lastObj: any = results;
    cSplit.forEach((a, i) => {
      if (i !== cSplit.length - 1) {
        if (!lastObj[a]) lastObj[a] = {};
        lastObj = lastObj[a];
      } else {
        lastObj[a] = true;
      }
    });
  });

  return results;
};

/** *
 * Build a set of handlers to perform a graphql mutation.
 * This includes request/response formatters and onSubmitting handler
 */
export const buildGraphQLMutationHandlers = <FT>(
  mutationPath: string,
  selection: string[] | GraphQLSelectionType
): IHandlers<FT> => ({
  onFormatRequest: graphQLRequestFormatter(mutationPath, selection),
  onFormatResponse: graphQLResponseFormatter(),
  onSubmitting: graphQLOnSubmit(),
});

export const graphQLRequestFormatter =
  (mutationPath: string, selection: string[] | GraphQLSelectionType) =>
  <FT>(
    // eslint-disable-next-line no-unused-vars
    _fb: FormBuilder<FT>,
    // eslint-disable-next-line no-unused-vars
    _fd: FormDefinition,
    // eslint-disable-next-line no-unused-vars, @typescript-eslint/no-explicit-any
    data: any
  ) => {
    _fd?.fields?.forEach((field) => {
      if (field.type === 'enum') {
        const enumData = data[field.name];
        if (enumData) {
          // eslint-disable-next-line no-param-reassign,
          data[field.name as keyof typeof data] = new EnumType(enumData);
        }
      }
    });

    const mutation = {
      mutation: {
        [mutationPath]: {
          __args: {
            input: {
              ...data,
            },
          },
          ...(Array.isArray(selection) ? _columnsToJson(selection) : selection),
        },
      },
    };

    return jsonToGraphQLQuery(mutation, { pretty: true });
  };

export const graphQLResponseFormatter =
  () =>
  <FT>(
    // eslint-disable-next-line no-unused-vars
    _fb: FormBuilder<FT>,
    // eslint-disable-next-line no-unused-vars
    fd: FormDefinition,
    // eslint-disable-next-line no-unused-vars, @typescript-eslint/no-explicit-any
    response: any
  ) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const resp = response as Either<any>;
    if (resp.hasError()) {
      if (resp.hasSystemErrors()) {
        fd.setErrors(resp.systemErrors);
      }
      if (resp.hasValidationErrors()) {
        let valErrors = resp.validationErrors;
        if (Object.prototype.hasOwnProperty.call(valErrors, 'input')) {
          valErrors = { ...valErrors, ...valErrors.input };
          delete valErrors.input;
        }

        fd.setValidationErrors(valErrors);
      }

      return undefined;
    }

    if (resp.hasData()) {
      return resp.data;
    }
  };

export const graphQLOnSubmit =
  () =>
  async <FT>(
    _fb: FormBuilder<FT>,
    _fd: FormDefinition,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    request: any
  ) =>
    // eslint-disable-next-line no-return-await
    await getGraphQLClient().performMutation(request);
