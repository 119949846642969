import React from 'react';
import { Box, SxProps, Theme } from '@mui/material';

interface TETOFormFieldProps {
  fieldSx?: SxProps<Theme>;
  children?: React.ReactNode;
  maxColumns: number;
  fieldColumns: number;
}

const TETOFormField: React.FC<TETOFormFieldProps> = (props) => {
  const { fieldSx, children, maxColumns, fieldColumns } = props;

  return (
    <Box
      sx={[
        ...(Array.isArray(fieldSx) ? fieldSx : [fieldSx]),
        {
          gridColumn: `span ${
            fieldColumns > maxColumns ? maxColumns : fieldColumns
          }`,
        },
      ]}
    >
      {children}
    </Box>
  );
};

export default React.memo(TETOFormField);
