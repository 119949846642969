import { Box, FormControlLabel, Radio, RadioGroup } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  ProcessScheduleTemplateOptions,
  ProcureRawMaterialOptions,
} from '../../../../../../../../__generated__/graphql';
import FormDefinition from '../../../../../../../TETOForms/FormDefinition';
import { radioContainerSx, radioSx } from '../styles';

interface CustomRadioGroupProps {
  form: FormDefinition;
  options: {
    value: string | undefined;
  };
}

const ProcureRawMaterialRadioGroup: React.FC<CustomRadioGroupProps> = ({
  form,
  options,
}) => {
  const { t } = useTranslation();

  return (
    <Box sx={radioContainerSx}>
      <RadioGroup
        name="procureRawMaterialOption"
        onChange={(e) => {
          form.updateField('procureRawMaterialOption', e.target.value);
        }}
        sx={radioSx}
        value={options.value}
      >
        <FormControlLabel
          control={<Radio />}
          disabled={!form.values.procureMaterial}
          label={`${t(
            'pages.processSchedule.processScheduleModal.rawMaterial.ownRawMaterial'
          )}`}
          value={ProcureRawMaterialOptions.ProcurePartsOwnRawMaterial}
        />

        <FormControlLabel
          control={<Radio />}
          disabled={!form.values.procureMaterial}
          label={`${t(
            'pages.processSchedule.processScheduleModal.rawMaterial.partAsRawMaterial'
          )}`}
          value={ProcureRawMaterialOptions.ProcurePartAsRawMaterial}
        />

        <FormControlLabel
          control={<Radio />}
          disabled={
            !form.values.procureMaterial ||
            form.values.addProcessScheduleTemplateOptions !==
              ProcessScheduleTemplateOptions.CopyFromExistingProcessSchedule
          }
          label={`${t(
            'pages.processSchedule.processScheduleModal.rawMaterial.copyRawMaterial'
          )}`}
          value={ProcureRawMaterialOptions.CopyRawMaterialFromExistingPs}
        />
      </RadioGroup>
    </Box>
  );
};

export default ProcureRawMaterialRadioGroup;
