import { gql } from '../../../../../../__generated__';

// eslint-disable-next-line import/prefer-default-export
export const nonConformanceAssignmentsQuery =
  gql(`query nonConformanceAssignmentsQuery  ($id: Int!) {
  nonConformanceAssignments (id: $id) {
    items {
      id
      assignedByEmployee {
        name
      }
      assignedToEmployee {
        name
      }
      assignedByEmployeeId
      assignedToEmployeeId
      task
      actionTaken
      note
      complete
    }
  }
}`);
