import { gql } from '../../../../__generated__';

const purchaseOrderReport = gql(`
  query getEmployeeReport($id: Int!){
    employeeReport(id: $id) {
      reportID
      reportDisplayName
      enabled
      allowView
      alternateId
    }
    reportAlternates(where: {reportId: {eq: $id}}) {
      items {
        id
        reportId
        reportDisplayName
        reportDescription
        enabled
      }
    }  
  }
`);

export default purchaseOrderReport;
