import { useCallback, useRef, useState } from 'react';

function useStateWhenChanged<S>(
  initialState: S,
  // eslint-disable-next-line no-unused-vars
  compareState?: (a: S, b: S) => boolean
) {
  const [state, setStateInternal] = useState<S>(initialState);
  const stateRef = useRef<S>(initialState);

  const setState = useCallback(
    (newState: S, byPassValueCheck = false) => {
      const compareFunc = compareState ?? ((a, b) => a === b);
      if (byPassValueCheck || !compareFunc(newState, stateRef.current)) {
        stateRef.current = newState;
        setStateInternal(newState);
      }
    },
    [compareState]
  );

  return [state, setState] as const;
}

export default useStateWhenChanged;
