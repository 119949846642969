import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';

import dayjs from 'dayjs';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getGraphQLClient, getTimeCardMigrationStatus } from 'teto-client-api';
import {
  ETOButton,
  ETOSelectField,
  MessageContext,
} from '@teto/react-component-library-v2';
import SettingsContext from '../../../contexts/SettingsContext';
import { gql } from '../../../__generated__';

const dayOfWeekFormats = () => {
  const newArr = [];

  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < 7; i++) {
    newArr.push({
      value: i,
      display: `${dayjs().day(i).format('dddd')}`,
    });
  }
  return newArr;
};

const TimecardMigrationDialog = () => {
  const [showTimecardMigrationModal, setShowTimecardMigrationModal] =
    useState(false);
  const [numOfRecords, setNumOfRecords] = useState(0);
  const settingsContext = useContext(SettingsContext);
  const messageContext = useContext(MessageContext);
  const [dayofWeek, setDayofWeek] = useState<number>(
    settingsContext.settings.startDayOfWeek
  );
  const { t, ready } = useTranslation();

  const _handleDayOfWeekChange = (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    setDayofWeek(parseInt(e.target.value, 10));
  };

  const _handleClose = () => {
    setShowTimecardMigrationModal(false);
  };

  const doTimecardMigrationMutation = gql(`
    mutation doTCMigration {
      doTimecardMigration {
        success
      }
    }`);

  const _startMigration = () => {
    settingsContext.updateSystemSettings({
      timeSheetStartDayOfWeek: dayofWeek as 0 | 1 | 2 | 3 | 4 | 5 | 6,
    });
    getGraphQLClient()
      .performMutation(doTimecardMigrationMutation, {})
      .then((d) => {
        if (d.hasError()) {
          if (d.hasSystemErrors()) {
            messageContext.setError(
              `${t('generic.message.failMigration')}. ${d.systemErrors[0]}`
            );
          }
          return;
        }
        messageContext.setSuccess(t('generic.message.startTimecardMigration'));
        setShowTimecardMigrationModal(false);
      });
  };

  useEffect(() => {
    getTimeCardMigrationStatus()
      .then((d) => {
        if (d.migrationRequired && d.allowedToMigrate) {
          setShowTimecardMigrationModal(true);
        }
        setNumOfRecords(d.timecardsToMigrate);
      })
      .catch(() => {
        // TODO : work item - 73801
      });
  }, []);

  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {ready && (
        <Dialog
          onClose={() => setShowTimecardMigrationModal(false)}
          open={showTimecardMigrationModal}
        >
          <DialogTitle>{t('dialogs.timecardMigration.title')}</DialogTitle>
          <DialogContent>
            <DialogContentText
              sx={{
                textAlign: 'center',
                color: (theme) => theme.palette.primary.main,
                mt: 1,
                mb: 4,
              }}
            >
              {`${numOfRecords} ${t('dialogs.timecardMigration.subtitle')}`}
            </DialogContentText>
            <DialogContentText>
              {t('dialogs.timecardMigration.content1')}
            </DialogContentText>
            <DialogContentText sx={{ my: 1 }}>
              {t('dialogs.timecardMigration.content2')}
            </DialogContentText>
            <Box sx={{ mx: { sm: 2, md: 4 }, my: 4 }}>
              <ETOSelectField
                defaultValue={settingsContext.settings.startDayOfWeek}
                handleChange={(e) => _handleDayOfWeekChange(e)}
                itemNameSelector={(i) => i.display}
                items={dayOfWeekFormats()}
                itemValueSelector={(i) => i.value}
                label={t('generic.startDayOfWeek')}
                name="startdayofweek"
                value={dayofWeek}
              />
            </Box>
            <DialogContentText>
              {t('dialogs.timecardMigration.warning')}
            </DialogContentText>
          </DialogContent>
          <DialogActions sx={{ m: 1 }}>
            <ETOButton color="secondary" onClick={_handleClose} size="medium">
              {t('dialogs.timecardMigration.pospone')}
            </ETOButton>
            <ETOButton color="primary" onClick={_startMigration} size="medium">
              {t('dialogs.timecardMigration.startMigration')}
            </ETOButton>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
};

export default TimecardMigrationDialog;
