import { SxProps, Theme } from '@mui/material';
import {
  ETOSelectField,
  ETOSelectFieldProps,
} from '@teto/react-component-library-v2';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { InventoryLocation } from '../../../pages/InventoryPage/types/inventoryLocation';
import useInventoryLocation from '../../../pages/InventoryPage/useInventoryLocation';

interface InventoryLocationInputProps
  extends Partial<ETOSelectFieldProps<InventoryLocation>> {
  excludeLocationId?: number;
  queryKey?: string;
  sx?: SxProps<Theme>;
}

const InventoryLocationInput = (props: InventoryLocationInputProps) => {
  const {
    disableClearable,
    excludeLocationId,
    label,
    name,
    queryKey,
    value,
    handleChange,
    sx,
    customSx,
    ...rest
  } = props;
  const { t } = useTranslation();
  const { data: locations } = useInventoryLocation(excludeLocationId, queryKey);

  return (
    <ETOSelectField
      {...rest}
      customSx={customSx}
      disableClearable={disableClearable || true}
      handleChange={handleChange}
      inputLabelProps={{ shrink: true }}
      itemNameSelector={(i: InventoryLocation) => i?.name ?? ''}
      items={locations ?? []}
      itemValueSelector={(i: InventoryLocation) => i?.id}
      label={label || t('pages.inventory.selectInventoryLocation')}
      name={name || 'inventoryLocationId'}
      size="small"
      value={value}
    />
  );
};

export default InventoryLocationInput;
