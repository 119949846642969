import { gql } from '../../../../__generated__';

const publicSystemSettingsQuery = gql(`query publicSystemSettings {
  publicSystemSettings {
    employeeStayLoggedInDuration
    allowEmployeesToUseRememberMeFor
  }
}`);

export default publicSystemSettingsQuery;
