import ErrorOutlineOutlined from '@mui/icons-material/ErrorOutlineOutlined';
import Box from '@mui/material/Box';
import { Theme } from '@mui/material/styles';
import { ErrorPage } from '@teto/react-component-library-v2';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

const routingErrorPageSx = (theme: Theme) => ({
  backgroundColor: theme.palette.background.paper,
  borderRadius: `${theme.shape.borderRadius}px`,
  height: '100%',
  width: '100%',
});

const iconSx = (theme: Theme) => ({
  fontSize: '5.5rem',
  [theme.breakpoints.up('md')]: {
    fontSize: '7.5rem',
  },
});

const RoutingErrorPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <Box sx={routingErrorPageSx}>
      <ErrorPage
        buttonText={t('pages.routingError.button')}
        icon={
          <ErrorOutlineOutlined color="action" data-testid="icon" sx={iconSx} />
        }
        onClick={() => {
          navigate('/');
        }}
        reason={t('pages.routingError.reason')}
        warning={t('pages.routingError.warning')}
      />
    </Box>
  );
};

export default RoutingErrorPage;
