import React, { useContext, useMemo } from 'react';
import { Permission } from 'teto-client-api';
import AuthContext from '../../../../../contexts/AuthContext';
import CommonDocumentsPanel, {
  DocumentsPanelProps,
} from '../../../../Inspectors/components/CommonDocumentsPanel/CommonDocumentsPanel';

type ProcessScheduleDocumentsPanelProps = Omit<
  DocumentsPanelProps,
  | 'addNewMutation'
  | 'deleteMutation'
  | 'entityName'
  | 'entityQuery'
  | 'permissions'
  | 'updateMutation'
>;

const addProcessScheduleDocumentMutation = `mutation addProcessScheduleDocuments($input: AddProcessScheduleDocumentsInput) {
  addProcessScheduleDocuments( input: $input) {
    items {
      id
      documentLocation
    }
  }
}`;
const deleteProcessScheduleDocumentMutation = `mutation deleteProcessScheduleDocument($id:Int!) {
  deleteProcessScheduleDocument( id:$id) {
    success
  }
}`;
const getProcessScheduleDocumentsQuery = `query processScheduleDocuments($processScheduleId: Int) {
  processScheduleDocuments( where: {processScheduleId: {eq: $processScheduleId}}) {
    items {
      id
      documentLocation
      pathType
      fileName
      fileExtension
      fileSize
      fileExists
      processScheduleId
    }
  }
}`;
const updateProcessScheduleDocumentMutation = `mutation updateProcessScheduleDocuments(
  $input: UpdateProcessScheduleDocumentsInput
) {
  updateProcessScheduleDocuments(input: $input) {
    items {
      id
      documentLocation
    }
  }
}
`;

const ProcessScheduleDocumentsPanel = (
  props: ProcessScheduleDocumentsPanelProps
) => {
  const authContext = useContext(AuthContext);
  const documentsPermissions = useMemo(
    () => ({
      canAdd: authContext.hasPermission(
        Permission.Add_Procurement_ProcessSchedules_Documents
      ),
      canDelete: authContext.hasPermission(
        Permission.Delete_Procurement_ProcessSchedules_Documents
      ),
      canEdit: authContext.hasPermission(
        Permission.Modify_Procurement_ProcessSchedules_Documents
      ),
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [authContext]
  );
  return (
    <CommonDocumentsPanel
      addNewMutation={addProcessScheduleDocumentMutation}
      deleteMutation={deleteProcessScheduleDocumentMutation}
      entityName="ProcessSchedule"
      entityQuery={getProcessScheduleDocumentsQuery}
      permissions={{
        canAddDocuments: documentsPermissions.canAdd,
        canDeleteDocuments: documentsPermissions.canDelete,
        canModifyDocuments: documentsPermissions.canEdit,
      }}
      shouldSupportMultipleLocations
      updateMutation={updateProcessScheduleDocumentMutation}
      {...props}
    />
  );
};

export default ProcessScheduleDocumentsPanel;
