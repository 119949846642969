const updateNonConformanceMutation = `mutation updateNonConformance($input: UpdateNonConformanceInput) {
  updateNonConformance(input: $input){    
    id
    project {        
      company {
        name  
      }
    }
    projectId
    specId      
    purchaseOrder {
      id
      purchaseSupplier {
        name
      }        
    }      
    purchaseOrderId
    sourceId
    creationDate
    title
    originId
    itemId
    item {
      id
      itemCompanyId
    }
    quantity
    quantityRejected
    description
    rootCause      
    recommendedInterim  
    correctivePreventiveAction
    nonConformanceAssignments {
      assignedToEmployee {
        name
      }
      assignedByEmployee {
        name
      }
      task
      actionTaken
      complete
      note
    }
    qualityFollowUp
    nonConformanceNotes {
      date
      noteEmployee {
        name
      }
      note        
    }
    nonConformanceDocuments {
      documentLocation
    }     
    resolved
   custom1
   custom2
   custom3
   custom4
   custom5
   custom6
   custom7
   resolved 
}
}`;

export default updateNonConformanceMutation;
