// eslint-disable-next-line import/no-cycle
import IHandlers from './types/IHandlers';
// eslint-disable-next-line import/no-cycle
import SubFormBuilder from './SubFormBuilder';

/** *
 * FormBuilder is responsible for maintaining a form definition.
 *
 * If using within a React component you probably want useFormBuilder for convenience
 */
// eslint-disable-next-line @typescript-eslint/ban-types
class FormBuilder<FT = {}> extends SubFormBuilder {
  name: string;

  handlers?: IHandlers<FT>;

  constructor(name: string, handlers?: IHandlers<FT>) {
    super();
    this.handlers = handlers;
    this.name = name;
  }
}

export default FormBuilder;
