export default `query standardAssemblies($id: String!) {
  standardAssemblies(
    order: [{ item: { itemCompanyId: ASC } }]
    where: { standardAssemblyItem: { itemCompanyId: { eq: $id } } }
  ) {
    items {
      id
      item {
        id
        uOM {
          itemUom
        }
      }
    }
  }
}`;
