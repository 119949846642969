import React from 'react';
import deepPropertyHelper from './deepPropertyHelpers';
import EmptyDisplay from './EmptyDisplay';
import FormatterErrorWrapper from './FormatterErrorWrapper';
import FormatterProps from './FormatterProps';

const StringFormatter = (props: FormatterProps) => {
  const {
    value,
    column: { name, emptyDisplay },
    data,
  } = props;
  const formattedVal = value?.value ?? deepPropertyHelper(name, data);

  const element = !formattedVal ? (
    <EmptyDisplay placeHolder={emptyDisplay} />
  ) : (
    <span>{formattedVal}</span>
  );
  return <FormatterErrorWrapper value={value}>{element}</FormatterErrorWrapper>;
};

export default React.memo(StringFormatter);
