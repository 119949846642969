/* eslint-disable dot-notation */
/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable react/jsx-props-no-spreading */
import PaginationToolbar from '@inovua/reactdatagrid-community/packages/PaginationToolbar';
import { TypeOnSelectionChangeArg } from '@inovua/reactdatagrid-community/types/TypeDataGridProps';
import ReactDataGrid from '@inovua/reactdatagrid-enterprise';
import '@inovua/reactdatagrid-enterprise/base.css';
import '@inovua/reactdatagrid-enterprise/theme/default-dark.css';
import '@inovua/reactdatagrid-enterprise/theme/default-light.css';
import {
  CellProps,
  TypeCellProps,
  TypeComputedProps,
  TypePaginationProps,
} from '@inovua/reactdatagrid-enterprise/types';
import ExpandLessRounded from '@mui/icons-material/ExpandLessRounded';
import ExpandMoreRounded from '@mui/icons-material/ExpandMoreRounded';
import {
  Box,
  ClickAwayListener,
  SxProps,
  Theme,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material';
import { MessageContext } from '@teto/react-component-library-v2';
import { useAtom, useSetAtom } from 'jotai';
import { isArray } from 'lodash';
import React, {
  forwardRef,
  useCallback,
  useContext,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react';
import * as yup from 'yup';
import SettingsContext from '../../contexts/SettingsContext';
import useCurrentTheme from '../../hooks/useCurrentTheme';
import SharedStateLink from '../SharedStateComponents/SharedStateLink';
import { DataSourceAtom } from '../SharedStateComponents/StateContainers/DataSourceState';
import ReloadState from '../SharedStateComponents/StateContainers/ReloadState';
import { exportCSV } from '../TetoGrid/Exporting/csvExporter';
import { calculateFilterEditor } from '../TetoGrid/Filtering/filteringHelpers';
import deepPropertyHelper from '../TetoGrid/Formatters/deepPropertyHelpers';
import GridCheckbox from '../TetoGrid/GridCheckbox/GridCheckbox';
import { buildGroupSummaryReducer } from '../TetoGrid/Grouping/GroupAggregateFunctions';
import { EditInfo } from '../TetoGrid/InlineEditing/types/IEditInfo';
import { reactDataGridLicenseKey } from '../TetoGrid/Licensing';
import TableColumnDefinition from '../TetoGrid/types/TableColumnDefinition';
import ConfigureInspector from './ConfigureInspector/ConfigureInspector';
import ConfigureOption from './ConfigureInspector/ConfigureOption';
import ConfirmSaveDialog from './ConfirmSaveDialog';
import DefaultPagination from './DefaultPagination';
import DiscardConfirmDialog from './DiscardConfirmDialog';
import AddNewDataRowEditor, {
  ColumnProps,
} from './Editors/AddNewDataRowEditor/AddNewDataRowEditor';
import SubmitNewDataBtn from './Editors/AddNewDataRowEditor/SubmitNewDataBtn';
import {
  addDataRowDark,
  addDataRowLight,
  addDataRowStyles,
} from './Editors/AddNewDataRowEditor/addDataRowStyles';
import FormatterProps from './Formatters/FormatterProps';
import { buildCellFormatter } from './Formatters/cellFormatterHelper';
import { TETODataColumn } from './GridBuilder/types/TETODataColumn';
import { GridResponsiveSettingsBuilder } from './GridResponsiveSettingsBuilder';
import getEditor from './InlineEditingProcessor';
import { MobileHeaderCell, MobileValueCell } from './MobileCells';
import MobileGroupTitle from './components/MobileGroupTitle/MobileGroupTitle';
import SortIndicator from './components/SortIndicator/SortIndicator';
import filterTypes from './configuration/filterTypes';
import isRowChangeValid from './helpers/editingHelpers';
import { groupNameFormatter } from './helpers/groupingHelpers';
import useCurrentBreakpoint from './hooks/useCurrentBreakpoint';
import { EditedValue } from './types/EditedValue';
import EditorType from './types/EditorTypes';
import { GridResponsiveSettingsSingle } from './types/GridResponsiveSettingsSingle';
import TETOGridRefType from './types/TETOGridRefType';
import { TetoGridGraphqlProps } from './types/TetoGridGraphqlProps';

const gridContainerSx = (theme: Theme) => ({
  height: '100%',
  display: 'grid',
  gridTemplateRows: `${theme.spacing(7)} 1fr`,
  [theme.breakpoints.down('md')]: {
    gridTemplateRows: `${theme.spacing(5)} 1fr`,
  },
});
const gridContainerWithSummarySx = (theme: Theme) => ({
  height: '100%',
  display: 'grid',
  gridTemplateRows: `${theme.spacing(7)} 1fr 10fr`,
  [theme.breakpoints.down('md')]: {
    gridTemplateRows: `${theme.spacing(5)} 1fr 10fr`,
  },
});

const gridContainerWithNoHeader = {
  height: '100%',
  display: 'grid',
  gridTemplateRows: '1fr',
};
const gridContainerWithNoHeaderWithSummary = {
  height: '100%',
  display: 'grid',
  gridTemplateRows: '0.6fr 6.5fr',
};

const gridMaxGroupWarning = (theme: Theme) => ({
  position: 'absolute',
  height: theme.spacing(6),
  lineHeight: theme.spacing(6),
  right: theme.spacing(1),
  color: theme.palette.grey[500],
  zIndex: 1,
});

export const defaultGridResponsiveSettings = GridResponsiveSettingsBuilder.up({
  xs: {
    disableGroupByToolbar: true,
    disableGroupColumn: true,
    disableInlineEdit: true,
    disableMobileCols: false,
    enableFiltering: false,
    filterRowHeight: 0,
    inputVariant: 'filled',
    renderAggregatesInGroupLabel: true,
    rowHeight: null,
    showEmptyRows: false,
    showHoverRows: false,
  },
  md: {
    disableGroupByToolbar: false,
    disableGroupColumn: false,
    disableInlineEdit: false,
    disableMobileCols: true,
    enableFiltering: true,
    inputVariant: 'standard',
    renderAggregatesInGroupLabel: false,
    rowHeight: 35,
    showEmptyRows: true,
    showHoverRows: true,
  },
});

export const MainTetoGrid = forwardRef<
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  any,
  TetoGridGraphqlProps & {
    children?: React.ReactNode;
  }
>(
  (
    props: TetoGridGraphqlProps,
    ref: React.Ref<TETOGridRefType> | undefined | null
  ) => {
    const currentTheme = useCurrentTheme();
    const settingsContext = useContext(SettingsContext);

    const isTest = process.env.NODE_ENV === 'test';

    const {
      alignRefreshIconToRight = true,
      checkboxColumn,
      children,
      checkboxOnlyRowSelect = false, // When set to true, only the checkbox will be used for row selection, you lose the ability to use shift/ctrl click to select multiple rows
      collapsedRows,
      configureInspector,
      configureInspectorSx,
      customGridWrapSx,
      CustomCheckbox,
      defaultConfigTab,
      disableActiveIndicator,
      disableGroupMaxWarning,
      editMode,
      enableGroupColumn,
      expandedRows,
      exportCustomCsv,
      filterDebounceTime,
      footerRows,
      gridProps,
      groups,
      gridStyles,
      handleAddNewDataRow,
      header,
      hasAddNewDataRow,
      hideColumnsInConfigureInspector,
      isDiscardConfirmDialog,
      multiRowExpand,
      onExpandedRowsChange,
      onRowCollapse,
      onRowExpand,
      onSelectionChange,
      pagination,
      renderDetailsGrid,
      renderCustomFooter,
      renderRowDetails,
      resetAddNewDataRow,
      responsiveSettings,
      rowClassName,
      rowDetailsWidth,
      rowExpandHeight,
      rowStyle,
      selectedRows,
      setConfigureInspector,
      setDefaultConfigTab,
      setIsDiscardConfirmDialog,
      setReactDataGridRef,
      setSelectedRows,
      showCellBorders,
      showEmptyRows,
      showGroupSummaryRow,
      stickyGroupRows,
      summary,
      t,
      tableIdentifier,
      viewportSize,
      virtualized = isTest ? false : undefined,
      validationErrors,
    } = props;

    const {
      visibleColumns,
      dataSource,
      editingState,
      filters,
      groupBy,
      idProperty,
      queryLoading,
      setDefaults,
      setFilters,
      onGroupByChange,
      setSort,
      sort,
      updateColumnConfiguration,
    } = gridProps;

    const messageContext = useContext(MessageContext);

    const [reload, setReload] = useAtom(ReloadState);
    const setDataSourceIsEmpty = useSetAtom(DataSourceAtom);
    const { changes, setChanges, updateOrCreateEditValue } = editingState;
    const [isAddNewDataRowEnabled, setIsAddNewDataRowEnabled] = useState(false);

    const gridRef = useRef<TypeComputedProps | null>(null);
    const theme = useTheme();
    const responsiveGrid = useCurrentBreakpoint<GridResponsiveSettingsSingle>(
      'down',
      responsiveSettings?.breakpoints ??
        defaultGridResponsiveSettings.breakpoints
    );

    const [isSaveConfirmDialog, setIsSaveConfirmDialog] = useState<{
      isOpen: boolean;
      data: EditInfo | undefined;
    }>({ isOpen: false, data: undefined });

    const [isPaginationFilter, setIsPaginationFilter] = useState<
      ConfigureOption | undefined
    >(undefined);
    const [enableFiltering, setEnableFiltering] = useState<boolean>(true);

    const [activeRowStyle, setActiveRowStyle] =
      useState<string>('active-row-border');
    const [activeIndex, setActiveIndex] = useState<number>();

    useEffect(() => {
      const activeRowId = gridRef?.current?.getActiveItem()?.[idProperty];

      const isValid = isRowChangeValid(
        gridProps?.editingState?.changes,
        activeRowId
      );
      setActiveRowStyle(
        !isValid ? 'active-row-border--error' : 'active-row-border'
      );
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [gridProps?.editingState?.changes, activeIndex]);

    const _handleEditComplete = useCallback(
      (editContext: EditInfo) => {
        const column = visibleColumns.find(
          (a) => a.name === editContext.columnId
        );

        if (!column) return;

        setChanges((oldVals) => {
          const newEntry: EditedValue = {
            isValid: true,
            value: editContext.value,
            originalValue: deepPropertyHelper(column.name, editContext.data),
            validationMessages: [],
            warningMessages: [],
          };

          const newData = { ...oldVals };

          // eslint-disable-next-line eqeqeq
          if (newEntry.value == newEntry.originalValue) {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            if (
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              newData[editContext.data[idProperty] as any] &&
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              newData[editContext.data[idProperty] as any][column.name]
            ) {
              const changedColumns = Object.keys(
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                newData[editContext.data[idProperty] as any]
              );
              if (
                changedColumns.length > 1 &&
                changedColumns.indexOf(editContext.columnId) >= 0
              ) {
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                delete newData[editContext.data[idProperty] as any][
                  editContext.columnId
                ];
              } else {
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                delete newData[editContext.data[idProperty] as any];
              }
            }

            return newData;
          }

          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          if (!newData[editContext.data[idProperty] as any]) {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            newData[editContext.data[idProperty] as any] = {};
          }

          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          newData[editContext.data[idProperty] as any][column.name] = newEntry;

          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          const item = newData[editContext.data[idProperty] as any];

          const existingRow = dataSource.find(
            (a) =>
              // eslint-disable-next-line eqeqeq
              a[idProperty] == (editContext.data[idProperty] as string)
          );

          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          const combinedChanges: { [key: string]: any } = { ...existingRow };
          Object.entries(item).forEach((a) => {
            combinedChanges[a[0]] = a[1].value;
          });

          if (editMode?.onCellChanging) {
            let cancel = false;

            const _handleValidationError = (error: string, path?: string) => {
              const data = dataSource.find(
                (a) => a[idProperty] === editContext.data[idProperty]
              );

              if (!data) return;

              if (path) {
                const val = deepPropertyHelper(path, editContext.data);

                updateOrCreateEditValue(
                  editContext.data[idProperty] as string,
                  path,
                  {
                    validationMessages: [error],
                    originalValue: val,
                    value: val,
                  }
                );
              } else {
                newEntry.validationMessages.push(error);
                newEntry.isValid = false;
              }
            };

            editMode?.onCellChanging({
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              primaryKey: editContext.data[idProperty] as string,
              column,
              newData: combinedChanges,
              editValue: newEntry,
              changeContext: {
                cancelChange: () => {
                  cancel = true;
                },
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                setColumnValue: (name: string, val: any) => {
                  // eslint-disable-next-line @typescript-eslint/no-explicit-any
                  newData[editContext.data[idProperty] as any][name] = {
                    isValid: true,
                    originalValue: item[column.name],
                    validationMessages: [],
                    warningMessages: [],
                    value: val,
                  };
                  combinedChanges[name] = val;
                },
                updateDependentColumns: (val: { [key: string]: unknown }) => {
                  gridRef.current?.setItemAt(editContext?.rowIndex, val);
                },
                setValidationWarning: (warning: string, path?: string) => {
                  const data = dataSource.find(
                    (a) => a[idProperty] === editContext.data[idProperty]
                  );

                  if (!data) return;

                  if (path) {
                    const val = deepPropertyHelper(path, editContext.data);
                    updateOrCreateEditValue(
                      editContext.data[idProperty] as string,
                      path,
                      {
                        warningMessages: [warning],
                        originalValue: val,
                        value: val,
                      }
                    );
                  } else {
                    newEntry.warningMessages.push(warning);
                    newEntry.isValid = false;
                  }
                },
                setValidationError: (error: string, path?: string) =>
                  _handleValidationError(error, path),
                setValidationErrors: (errors: { [key: string]: string }) =>
                  Object.entries(errors).forEach((i) =>
                    _handleValidationError(i[1], i[0])
                  ),
              },
            });

            if (cancel) return oldVals;
          }

          if (editMode?.validationSchema) {
            try {
              editMode?.validationSchema.validateSync(combinedChanges, {
                abortEarly: false,
              });
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
            } catch (ex: any) {
              if (isArray(ex.inner)) {
                (ex as yup.ValidationError).inner.forEach((c) => {
                  if (
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    newData[editContext.data[idProperty] as any][
                      c.path as string
                    ]
                  ) {
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    newData[editContext.data[idProperty] as any][
                      c.path as string
                    ].isValid = false;
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    newData[editContext.data[idProperty] as any][
                      c.path as string
                    ].validationMessages = [...c.errors];
                    // there is a validation error, but it doesnt exist in the original data
                  } else if (!newData[editContext.rowId][c?.path as string]) {
                    newData[editContext.rowId][c.path as string] = {
                      isValid: false,
                      validationMessages: [...c.errors],
                      value: null,
                      originalValue: null,
                      warningMessages: [],
                    };
                  }
                });
              }
            }
          }

          return newData;
        });
      },
      [
        visibleColumns,
        setChanges,
        idProperty,
        dataSource,
        editMode,
        updateOrCreateEditValue,
      ]
    );

    const setEditableField =
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (primaryKey: string, path: string, value: any) => {
        const column = visibleColumns.find((a) => a.name === path);
        if (!column) {
          throw new Error(`Column ${path} not found`);
        }

        const row = dataSource.find(
          // eslint-disable-next-line eqeqeq
          (a) => a[idProperty] == primaryKey
        );

        if (!row) {
          throw new Error(`Row not found with primary key ${primaryKey}`);
        }

        _handleEditComplete({
          columnId: column.id,
          columnIndex: visibleColumns.indexOf(column),
          data: row,
          rowId: row[idProperty],
          rowIndex: dataSource.indexOf(row),
          cellProps: undefined,
          value,
        });
      };

    const columnOrder = useMemo(
      () => visibleColumns.sort((a, b) => a.order - b.order).map((a) => a.name),
      [visibleColumns]
    );

    const exportGridData = useCallback(() => {
      if (!gridRef.current) {
        messageContext.setError(t('generic.message.failExportGrid'));
        return;
      }

      const ids = Object.entries(changes).map((a) => a[0]);
      const formattedData = dataSource.map((c) => {
        if (
          ids.indexOf(deepPropertyHelper(gridProps.idProperty, c)?.toString()) <
          0
        )
          return c;

        const change = changes[deepPropertyHelper(gridProps.idProperty, c)];
        if (!change) return c;

        const changeReduced = Object.entries(change).reduce(
          (pv, cv) => ({ ...pv, [cv[0]]: cv[1].value }),
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          {} as { [key: string]: any }
        );

        return {
          ...c,
          ...changeReduced,
        };
      });

      const relevantData = {
        columns: visibleColumns,
        dataSource: formattedData,
        visibleColumns: gridRef.current?.visibleColumns,
        groupBy: gridRef.current,
        tableIdentifier,
        settingsContext,
      };

      if (exportCustomCsv) return exportCSV(relevantData, exportCustomCsv);
      return exportCSV(relevantData);
    }, [
      changes,
      dataSource,
      visibleColumns,
      tableIdentifier,
      settingsContext,
      exportCustomCsv,
      messageContext,
      t,
      gridProps.idProperty,
    ]);

    useImperativeHandle(
      ref,
      () => ({
        setEditableField,
        exportData: () => {
          if (gridRef.current) {
            exportGridData();
          }
          return Promise.resolve();
        },
        getGrid: () => gridRef.current,
        resetGridSettings: () => {
          setDefaults();
        },
        refreshDataSource: () => {
          gridProps.refreshDataSource();
        },
        ...(gridRef.current as TypeComputedProps),
      }),
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [exportGridData, setDefaults, gridProps]
    );

    const _isGroupBarDisabled = useMemo(
      () => Boolean(!(visibleColumns ?? []).find((a) => !a.disableGrouping)),
      [visibleColumns]
    );

    const _handleAltOnChange = useCallback(
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (e: any, a: any) => {
        const { textContent, value } = e.target;

        setChanges((oldVals) => {
          const brandingEntry: EditedValue = {
            isValid: true,
            value,
            originalValue: deepPropertyHelper(
              a.cellProps.brandingName,
              a.cellProps?.data
            ),
            validationMessages: [],
            warningMessages: [],
          };
          const inputEntry: EditedValue = {
            isValid: true,
            value: textContent,
            originalValue: deepPropertyHelper(
              a.cellProps.name,
              a.cellProps?.data
            ),
            validationMessages: [],
            warningMessages: [],
          };

          return {
            ...oldVals,
            [a.cellProps.data[idProperty]]: {
              ...oldVals[a.cellProps.data[idProperty]],
              [a.cellProps.brandingName]: brandingEntry,
              [a.cellProps.name]: inputEntry,
            },
          };
        });

        a?.onChange({ ...e, target: { ...e.target, value: textContent } });
      },
      [idProperty, setChanges]
    );

    const convertTETOColumnToReactDataGridColumn = useCallback(
      (c: TETODataColumn): TableColumnDefinition =>
        ({
          ...c,
          groupSummaryReducer: buildGroupSummaryReducer(
            c.name,
            c.groupSummaryReducer
          ),
          dateFormat:
            // eslint-disable-next-line no-nested-ternary
            c.type === 'date'
              ? settingsContext?.settings.dateFormat
              : // eslint-disable-next-line no-nested-ternary
              c.type === 'datetime'
              ? settingsContext?.settings.dateTimeFormat
              : c.type === 'time'
              ? settingsContext.settings.timeFormat
              : undefined,
          groupToString:
            c.groupToString ??
            ((v, data) =>
              groupNameFormatter(
                {
                  dateFormat: settingsContext.settings.dateFormat,
                  dateTimeFormat: settingsContext.settings.dateTimeFormat,
                  timeFormat: settingsContext.settings.timeFormat,
                  currencyFormatter:
                    settingsContext.settings.baseCurrencyFormatter,
                },
                t,
                c,
                v,
                data.data
              )),
          hidden: c.hidden,
          header: t(c.header ?? c.title),
          hideable: !c.disableHideable,
          showInContextMenu: false,
          groupByName: c.title,
          isCheckboxColumn: c.type === 'boolean',
          minWidth: c.minWidth ?? 40,
          textAlign: c.align,
          required: Boolean(c.required),
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          getEditStartValue: (_editValue: any, cellProps: any) => {
            const cellData = cellProps.data;
            if (
              !changes[cellData?.[idProperty]] ||
              !changes[cellData?.[idProperty]][cellProps.name]
            )
              return deepPropertyHelper(cellProps.name, cellData);
            return changes[cellData?.[idProperty]][cellProps.name].value;
          },
          visible: !c.hidden,
          disableColumnFilterContextMenu: c.disableColumnFilterContextMenu,
          showColumnMenuTool: !c.disableColumnMenuTool ?? c.showColumnMenuTool,
          groupBy: !c.disableGrouping,
          filterable: c.filterable,
          filterEditor: c.filterEditor ?? calculateFilterEditor(c),
          filterEditorProps: {
            ...c.filterEditorProps,
          },
          renderHeader: (renderHeaderProps: CellProps) => {
            const { children: headerChildren } = renderHeaderProps;

            if (c.align === 'end') {
              headerChildren.reverse();
            }

            return (
              <Tooltip
                title={
                  c.disableGrouping
                    ? t('generic.message.noColumnGroup', { column: c.header })
                    : `${c.header}`
                }
              >
                <Box>{c.isHeaderNotVisible ? null : headerChildren}</Box>
              </Tooltip>
            );
          },
          render: (cell) =>
            c.render?.(cell) ?? (
              <SharedStateLink
                inspectorKey={c.linkTo?.type}
                value={
                  c.linkTo?.type
                    ? deepPropertyHelper(c.linkTo.column, cell.data)
                    : undefined
                }
              >
                {buildCellFormatter(
                  c,
                  changes?.[cell.data?.[idProperty]]?.[c.name]
                )(cell)}
              </SharedStateLink>
            ),
          filterDelay: filterDebounceTime ?? 500,
          renderEditor:
            c.renderEditor ??
            ((a) =>
              getEditor(
                c.type,
                c.editorType as EditorType,
                'standard'
              )(
                {
                  ...a,
                  onChange: c?.brandingName
                    ? // eslint-disable-next-line @typescript-eslint/no-explicit-any
                      (e: any) => _handleAltOnChange(e, a)
                    : a?.onChange,
                },
                changes?.[a.data?.[idProperty]]?.[c?.brandingName ?? c.name]
              )),
          editorProps: c.editorProps,
        } as TableColumnDefinition),
      [
        _handleAltOnChange,
        changes,
        filterDebounceTime,
        idProperty,
        settingsContext.settings.baseCurrencyFormatter,
        settingsContext.settings.dateFormat,
        settingsContext.settings.dateTimeFormat,
        settingsContext.settings.timeFormat,
        t,
      ]
    );

    const convertedToRDGColumns = useMemo<TableColumnDefinition[]>(
      () =>
        (visibleColumns ?? []).map((c) =>
          convertTETOColumnToReactDataGridColumn(c)
        ),
      [visibleColumns, convertTETOColumnToReactDataGridColumn]
    );

    const mobileCols: TableColumnDefinition[] = useMemo(() => {
      const mobileCellCommon = {
        disableGrouping: true,
        disableHideable: true,
        disableColumnMenuTool: false,
        showColumnMenuTool: false,
        minWidth: 144,
        align: 'start' as const,
        sortable: false,
        filterType: 'none',
        fixed: 'none' as const,
        hidden: false,
        flex: 1,
        type: 'custom',
        editable: false,
      } as const;

      const cols: TableColumnDefinition[] = [
        {
          ...mobileCellCommon,
          required: true,
          order: 0,
          id: 'mobileCustomGrouping',
          name: 'mobileCustomGrouping',
          title: 'Field',
          header: 'Field',
          sortable: false,
          render: (a: FormatterProps) => {
            const v = { ...a };
            return (
              <MobileHeaderCell
                cell={v}
                columns={visibleColumns}
                grouping={groupBy}
              />
            );
          },
        },
        {
          ...mobileCellCommon,
          required: true,
          order: 1,
          id: 'mobileCustomData',
          name: 'mobileCustomData',
          title: 'Value',
          header: 'Value',
          render: (a: FormatterProps) => {
            const v = { ...a };
            return (
              <MobileValueCell
                cell={v}
                columns={visibleColumns}
                editingEnabled={Boolean(editMode)}
                groupBy={groupBy}
                responsive={responsiveGrid.selection}
                setValueUpdated={(path, val) => {
                  setEditableField(v.data?.[idProperty], path, val);
                }}
              />
            );
          },
        },
      ];

      const additionalColumns = visibleColumns.filter((a) => a.includeInMobile);
      additionalColumns.forEach((c, i) => {
        const converted = convertTETOColumnToReactDataGridColumn(c);
        cols.push({
          ...mobileCellCommon,
          ...converted,
          groupSummaryReducer: undefined,
          order: cols.length + i,
          sortable: false,
          header: '',
          width: 40,
          flex: 0,
          locked: undefined,
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          render: c.render as any,
        });
      });

      const workerColumns = gridProps.allColumns.filter((a) =>
        Boolean(a.groupingAggregate)
      );

      workerColumns.forEach((c) => {
        cols.push({
          ...c,
          hidden: true,
          width: 0,
          flex: 0,
          visible: false,
          groupSummaryReducer: buildGroupSummaryReducer(
            c.name,
            c.groupSummaryReducer
          ),
        });
      });

      return cols;
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
      visibleColumns,
      gridProps.allColumns,
      groupBy,
      responsiveGrid.selection,
      idProperty,
      editMode,
      convertTETOColumnToReactDataGridColumn,
    ]);

    const mobileColumnOrder = useMemo(
      () => mobileCols.map((a) => a.name as string),
      [mobileCols]
    );

    const RenderCheckbox = useMemo(
      () => CustomCheckbox || GridCheckbox,
      [CustomCheckbox]
    );
    const gridWrapperSxProps = useMemo<SxProps<Theme>>(
      () => [
        // eslint-disable-next-line no-nested-ternary
        summary && header?.hidden
          ? gridContainerWithNoHeaderWithSummary
          : // eslint-disable-next-line no-nested-ternary
          header?.hidden
          ? gridContainerWithNoHeader
          : summary
          ? gridContainerWithSummarySx
          : gridContainerSx,
        ...(Array.isArray(customGridWrapSx)
          ? customGridWrapSx
          : [customGridWrapSx]),
      ],
      [customGridWrapSx, header?.hidden, summary]
    );

    const _onClickAway = useCallback(() => {
      // click away code goes here
    }, []);

    const _onSelectionChange = useCallback(
      (selectionProps: TypeOnSelectionChangeArg) => {
        // if !unselected && selected === true then all
        // if selected === true && unselected.keys.length > 0 then take all with unselected removed
        // if selected === object then selected
        const { selected, unselected, originalData } = selectionProps;

        let items = [];
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        if (!unselected && selected === true) items = originalData as any[];
        else if (!unselected && selected && typeof selected === 'object') {
          setSelectedRows?.(selected);
          return;
        } else if (unselected) {
          const unselectedKeys = Object.keys(unselected);
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          items = (originalData as any[]).filter(
            (a) =>
              unselectedKeys.indexOf(
                deepPropertyHelper(idProperty, a)?.toString()
              ) < 0
          );
        }

        const results = items.reduce(
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          (a: any, v: any) => ({ ...a, [v.id]: v }),
          {}
        );

        return setSelectedRows?.(results);
      },
      [idProperty, setSelectedRows]
    );

    const onRowClick = useCallback(
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (rowProps: any) => {
        const { data } = rowProps;

        if (!Array.isArray(data) && checkboxOnlyRowSelect === false) {
          return setSelectedRows?.(data);
        }
      },
      [checkboxOnlyRowSelect, setSelectedRows]
    );

    const onCellClick = useCallback((event: MouseEvent) => {
      event.stopPropagation();
    }, []);

    const _handleGroupChange = useCallback(
      (newGroupBy: string[]) => {
        if (groupBy) {
          if (newGroupBy.length < groupBy.length || newGroupBy.length <= 4) {
            onGroupByChange(newGroupBy);
          }
        }
      },
      [groupBy, onGroupByChange]
    );

    const _handlePaginationToolbar = useCallback(
      (d: TypePaginationProps) => {
        if (pagination === 'local') {
          return <PaginationToolbar {...d} bordered />;
        }

        const paginationProps = {
          gridProps,
          pageProps: d,
          disableMobileCols: !responsiveGrid.selection?.disableMobileCols,
          alignRefreshIconToRight,
          onFilterClick: () => setIsPaginationFilter('Filters'),
          onSortClick: () => setIsPaginationFilter('Sorting'),
          clearAllFilters: () => gridRef?.current?.clearAllFilters?.(),
          onConfigureClick: () => setConfigureInspector?.(true),
          onExportClick: () => exportGridData?.(),
          hasExpandableRows: (gridRef?.current?.groupBy?.length ?? 0) > 0,
          collapseAll: () => gridRef?.current?.collapseAllGroups?.(),
          expandAll: () => gridRef?.current?.expandAllGroups?.(),
          isAddNewDataRowEnabled,
        };

        if (renderCustomFooter) {
          return renderCustomFooter(paginationProps);
        }
        return <DefaultPagination {...paginationProps} />;
      },
      [
        pagination,
        gridProps,
        responsiveGrid.selection?.disableMobileCols,
        alignRefreshIconToRight,
        isAddNewDataRowEnabled,
        renderCustomFooter,
        setConfigureInspector,
        exportGridData,
      ]
    );

    useEffect(() => {
      const availableWidth = gridRef?.current?.availableWidth ?? 0;
      const totalComputedWidth = gridRef?.current?.totalComputedWidth ?? 0;
      if (totalComputedWidth < availableWidth) {
        gridRef.current?.setColumnSizesToFit?.();
        if (checkboxColumn)
          gridRef.current?.setColumnSizeAuto?.('__checkbox-column');
      }
    }, [
      gridRef?.current?.availableWidth,
      gridRef?.current?.totalComputedWidth,
      checkboxColumn,
    ]);

    useEffect(() => {
      if (dataSource.length === 0) {
        setDataSourceIsEmpty?.(() => true);
      }

      return () => setDataSourceIsEmpty?.(() => false);
    }, [dataSource, setDataSourceIsEmpty]);

    useEffect(() => {
      if (queryLoading) {
        setReload(true);
      }

      return () => {
        setReload(false);
      };
    }, [queryLoading, setReload]);

    const renderAddNewDataRowEditors = useCallback(() => {
      const _fieldsToRender = convertedToRDGColumns.filter((c) => c.editable);
      if (handleAddNewDataRow && resetAddNewDataRow !== undefined) {
        const convertToRDGObj = _fieldsToRender.reduce(
          (prevNames, cur) => ({
            ...prevNames,
            [cur.name]: (
              columnProps: ColumnProps,
              computedProps: TypeComputedProps
            ) =>
              AddNewDataRowEditor(
                columnProps,
                computedProps,
                handleAddNewDataRow,
                resetAddNewDataRow,
                editMode?.validationSchema,
                responsiveGrid?.selection?.inputVariant,
                validationErrors,
                gridRef
              ),
          }),
          {
            'grid.teto.action': () => SubmitNewDataBtn(),
            locked: 'end',
          }
        );

        return convertToRDGObj;
      }
    }, [
      convertedToRDGColumns,
      editMode?.validationSchema,
      handleAddNewDataRow,
      resetAddNewDataRow,
      responsiveGrid?.selection?.inputVariant,
      validationErrors,
    ]);

    const addNewDataRow = useMemo(() => {
      if (responsiveGrid.selection?.disableMobileCols && hasAddNewDataRow) {
        return [
          {
            isAddNewDataRowEnabled,
            setIsAddNewDataRowEnabled: (isEnabled: boolean) =>
              setIsAddNewDataRowEnabled(isEnabled),
            position: 'end' as const,
            render: renderAddNewDataRowEditors(), // ref RDG TypeLockedRow for functionality
          },
        ];
      }
      return undefined;
    }, [
      hasAddNewDataRow,
      isAddNewDataRowEnabled,
      renderAddNewDataRowEditors,
      responsiveGrid.selection?.disableMobileCols,
    ]);

    const groupingColumns = useMemo(() => {
      const result: Record<string, unknown> = {};
      if ((groupBy ?? [])?.length > 0) {
        groupBy?.forEach((i) => {
          result[i] = convertedToRDGColumns.find((c) => c.name === i) ?? {};
        });
      }
      return result;
    }, [convertedToRDGColumns, groupBy]);

    const _handleRenderColumnContextMenu = useCallback(
      // eslint-disable-next-line no-unused-vars, @typescript-eslint/no-explicit-any
      (menuProps: { items: any[]; onDismiss: () => void }, others: any) => {
        // eslint-disable-next-line no-param-reassign
        menuProps.items = menuProps?.items
          .map((i) => {
            if (i.itemId === 'hideFilteringRow') {
              return {
                ...i,
                onClick: () => {
                  setEnableFiltering(false);
                  menuProps.onDismiss();
                },
                disabled: !enableFiltering,
              };
            }
            return i;
          })
          .filter(
            (i: { label: string; itemId: string }) =>
              i.label !== 'Columns' ||
              (i.itemId !== 'columns' &&
                i.itemId !== 'hideFilteringRow' &&
                i.itemId !== 'showFilteringRow')
          )
          .filter(
            (i: string, j: number) =>
              typeof i !== 'string' ||
              (typeof i === 'string' &&
                i === '-' &&
                menuProps?.items[j + 1] !== '-')
          );
        if (!enableFiltering) {
          // eslint-disable-next-line no-param-reassign
          menuProps.items = menuProps?.items.concat([
            {
              label: 'Show filtering row',
              itemId: 'showFilteringRow',
              onClick: () => {
                setEnableFiltering(true);
                menuProps.onDismiss();
              },
              disabled: enableFiltering,
            },
            {
              label: 'Hide filtering row',
              itemId: 'hideFilteringRow',
              onClick: () => {
                setEnableFiltering(false);
                menuProps.onDismiss();
              },
              disabled: !enableFiltering,
            },
          ]);
        }
        return undefined;
      },
      [enableFiltering]
    );
    const renderColumnFilterContextMenu = useCallback(
      (
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        menuProps: any,
        {
          /* eslint-disable no-unused-vars */
          cellProps,
          grid,
          // eslint-disable-next-line no-shadow
          props,
        }: {
          /* eslint-enable no-unused-vars */
          cellProps: TypeCellProps;
          grid: React.MutableRefObject<TypeComputedProps | null>;
          props: TypeComputedProps;
        }
      ): // eslint-disable-next-line @typescript-eslint/no-explicit-any
      any => {
        if (
          // @ts-expect-error: This does exist, the type just wont be compatible, with the grid if added above
          cellProps?.nullable === false &&
          menuProps.items &&
          Array.isArray(menuProps.items) &&
          // @ts-expect-error: This does exist, the type just wont be compatible, with the grid if added above
          cellProps.type !== 'string'
        ) {
          const filteredItems = menuProps.items.filter(
            (item: { itemId: string }) => item.itemId !== 'operator-empty'
          );
          // eslint-disable-next-line no-param-reassign
          menuProps.items = filteredItems;
        }
      },
      []
    );
    return (
      <Box sx={gridWrapperSxProps}>
        {(Boolean(isPaginationFilter) || configureInspector) &&
          setConfigureInspector && (
            <ConfigureInspector
              customSx={configureInspectorSx}
              defaultConfigTab={defaultConfigTab || isPaginationFilter}
              gridProps={gridProps}
              handleClose={() => {
                setConfigureInspector(false);
                setDefaultConfigTab?.();
                if (isPaginationFilter) {
                  setIsPaginationFilter(undefined);
                }
              }}
              hideColumnsInConfigureInspector={hideColumnsInConfigureInspector}
              open={configureInspector || Boolean(isPaginationFilter)}
            />
          )}
        {summary}

        <ClickAwayListener
          mouseEvent="onMouseDown"
          onClickAway={() => _onClickAway()}
          touchEvent="onTouchEnd"
        >
          <Box
            data-testid="dataGrid"
            id="dataGrid-wrapper"
            sx={[
              {
                '& .row-strike-through': {
                  '& .InovuaReactDataGrid__cell': {
                    textDecoration: 'line-through',
                  },
                },
              },
              { height: '100%', position: 'relative' },
              ...(!enableFiltering
                ? [
                    {
                      '& .InovuaReactDataGrid__column-header__filter-wrapper': {
                        display: 'none !important',
                      },
                    },
                  ]
                : []),
              ...(hasAddNewDataRow
                ? [
                    currentTheme === 'default-dark'
                      ? addDataRowDark
                      : addDataRowLight,
                    addDataRowStyles,
                  ]
                : []),
              ...(Array.isArray(gridStyles) ? gridStyles : [gridStyles]),
            ]}
          >
            {!responsiveGrid.selection?.disableGroupColumn &&
              !disableGroupMaxWarning &&
              responsiveGrid?.breakpoint !== 'xs' &&
              responsiveGrid?.breakpoint !== 'sm' &&
              !responsiveGrid.selection?.disableGroupByToolbar && (
                <Box sx={gridMaxGroupWarning}>
                  <Typography variant="caption">
                    {t('generic.warningMessage.maximumGroupLimit')}
                  </Typography>
                </Box>
              )}
            <ReactDataGrid
              activateRowOnFocus={false}
              activeIndex={activeIndex}
              activeRowIndicatorClassName={activeRowStyle}
              autoFocusOnEditComplete={false} // Bug #73213
              checkboxColumn={checkboxColumn ? RenderCheckbox : undefined}
              checkboxOnlyRowSelect={checkboxOnlyRowSelect}
              collapsedRows={collapsedRows}
              columnOrder={
                responsiveGrid?.selection?.disableMobileCols ||
                !responsiveGrid.selection
                  ? columnOrder
                  : mobileColumnOrder
              }
              columns={
                responsiveGrid?.selection?.disableMobileCols ||
                !responsiveGrid.selection
                  ? convertedToRDGColumns
                  : mobileCols
              }
              columnUserSelect
              dataSource={dataSource}
              defaultSelected={selectedRows}
              disableGroupByToolbar={
                responsiveGrid.selection?.disableGroupByToolbar ||
                !responsiveGrid.selection?.disableMobileCols
                  ? true
                  : _isGroupBarDisabled
              }
              editable={
                editMode && !responsiveGrid.selection?.disableInlineEdit
              }
              editStartEvent="click"
              emptyText={t('generic.message.noRecordsFound')}
              enableColumnAutoSize
              enableFiltering={
                responsiveGrid.selection?.enableFiltering
                  ? enableFiltering
                  : false
              }
              expandedRows={expandedRows}
              expandGroupTitle
              filterRowHeight={responsiveGrid.selection?.filterRowHeight}
              filterTypes={filterTypes}
              filterValue={filters}
              footerRows={footerRows}
              groupBy={groupBy ?? []}
              groupColumn={
                !(
                  !enableGroupColumn ||
                  !responsiveGrid?.selection?.disableGroupColumn
                )
              }
              groups={groups ?? undefined}
              handle={(r) => {
                gridRef.current = r ? r.current : null;
              }}
              headerHeight={header?.height}
              idProperty={idProperty}
              licenseKey={reactDataGridLicenseKey}
              limit={gridProps.dataSource.length}
              loading={reload}
              lockedRows={addNewDataRow}
              multiRowExpand={multiRowExpand}
              onActiveIndexChange={setActiveIndex}
              onCellClick={
                responsiveGrid?.selection?.disableMobileCols
                  ? undefined
                  : onCellClick
              }
              onColumnLockedChange={(o) =>
                o.column.name &&
                updateColumnConfiguration([
                  { columnName: o.column.name, locked: o.locked },
                ])
              }
              onColumnOrderChange={(a) =>
                updateColumnConfiguration(
                  a.map((b, i) => ({
                    columnName: b,
                    order: i,
                  }))
                )
              }
              onColumnResize={(o) =>
                o.column.name &&
                updateColumnConfiguration([
                  { columnName: o.column.name, width: o.width },
                ])
              }
              onColumnVisibleChange={(o) =>
                o.column.name &&
                updateColumnConfiguration([
                  { columnName: o.column.name, hidden: !o.visible },
                ])
              }
              onEditComplete={(e) => _handleEditComplete({ ...e } as EditInfo)}
              onExpandedRowsChange={onExpandedRowsChange}
              onFilterValueChange={setFilters}
              onGroupByChange={_handleGroupChange}
              onReady={(d) => {
                setReactDataGridRef?.(d);
              }}
              // @ts-expect-error: This does exist
              onRenderRow={({ data, style }) => {
                const isValidRow = isRowChangeValid(
                  gridProps?.editingState?.changes,
                  data?.[idProperty]
                );

                if (!isValidRow)
                  // eslint-disable-next-line no-param-reassign
                  style.border = `1px solid ${theme.palette.error.main}`;
              }}
              onRowClick={onRowClick}
              onRowCollapse={onRowCollapse}
              onRowExpand={onRowExpand}
              onSelectionChange={onSelectionChange ?? _onSelectionChange}
              onSortInfoChange={setSort}
              pagination
              remotePagination={false}
              renderColumnContextMenu={_handleRenderColumnContextMenu}
              renderColumnFilterContextMenu={renderColumnFilterContextMenu}
              renderDetailsGrid={renderDetailsGrid}
              renderGroupTitle={
                responsiveGrid?.selection?.renderAggregatesInGroupLabel
                  ? (val, rowProps) => {
                      // @ts-expect-error: This does exist
                      if (rowProps?.data?.groupColumnSummary)
                        return (
                          <MobileGroupTitle
                            groupingColumns={groupingColumns}
                            rowProps={rowProps}
                            val={val}
                          />
                        );
                      return 'wasted';
                    }
                  : undefined
              }
              renderPaginationToolbar={_handlePaginationToolbar}
              renderRowDetails={renderRowDetails}
              renderRowDetailsCollapsedIcon={() => (
                <ExpandMoreRounded
                  data-testid="renderRowDetailsCollapsedIcon"
                  sx={{ fill: `${theme.palette.primary.main}` }}
                />
              )}
              renderRowDetailsExpandIcon={() => (
                <ExpandLessRounded
                  data-testid="renderRowDetailsExpandIcon"
                  sx={{ fill: `${theme.palette.primary.main}` }}
                />
              )}
              renderRowDetailsMoreIcon={() => <></>}
              renderSortTool={(dir, sortingProps) =>
                SortIndicator(
                  dir,
                  sortingProps,
                  gridRef.current?.computedSortInfo
                )
              }
              reserveViewportWidth={null}
              rowClassName={rowClassName}
              rowDetailsWidth={rowDetailsWidth}
              rowStyle={rowStyle}
              showActiveRowIndicator={!disableActiveIndicator}
              scrollProps={{
                scrollThumbStyle: {
                  backgroundColor: theme.palette.primary.main,
                },
              }}
              // https://github.com/inovua/reactdatagrid/issues/11 - use default selected instead to allow option to shift click
              // selected={selectedRows}
              showCellBorders={showCellBorders}
              showEmptyRows={
                responsiveGrid.selection?.showEmptyRows && showEmptyRows
              }
              showGroupSummaryRow={
                showGroupSummaryRow ??
                !!responsiveGrid.selection?.disableMobileCols
              }
              sortInfo={sort}
              stickyGroupRows={stickyGroupRows}
              theme={currentTheme}
              viewportSize={viewportSize}
              virtualized={
                responsiveGrid?.selection?.disableMobileCols
                  ? true
                  : virtualized
              }
              rowExpandHeight={rowExpandHeight ?? (null as unknown as number)}
              // @ts-expect-error: This does exist
              rowHeight={responsiveGrid.selection?.rowHeight}
            />
          </Box>
        </ClickAwayListener>

        {children}
        {isSaveConfirmDialog.isOpen && (
          <ConfirmSaveDialog
            onCancel={() => {
              setIsSaveConfirmDialog({ isOpen: false, data: undefined });
            }}
            onClose={() => {
              setIsSaveConfirmDialog({ isOpen: false, data: undefined });
            }}
            onSave={() => {
              setIsSaveConfirmDialog({ isOpen: false, data: undefined });
            }}
            open={isSaveConfirmDialog.isOpen}
          />
        )}
        {isDiscardConfirmDialog && Object.entries(changes).length > 0 && (
          <DiscardConfirmDialog
            cancelChanges={() => setChanges(() => ({}))}
            cancelDialog={() => setIsDiscardConfirmDialog?.(false)}
            open={isDiscardConfirmDialog}
          />
        )}
      </Box>
    );
  }
);

export default MainTetoGrid;
