import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import { SxProps, Theme } from '@mui/material/styles';
import {
  ButtonStrip,
  ETOButtonProps,
  ETOTextField,
  GenericDialog,
  Inspector,
  MessageContext,
  OkCancelConfirmDialog,
  useBarcodeScannerListener,
} from '@teto/react-component-library-v2';
import { FormikValues } from 'formik';
import { useAtom } from 'jotai';
import { useResetAtom } from 'jotai/utils';
import React, {
  useCallback,
  useContext,
  useEffect,
  useLayoutEffect,
  useMemo,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { getGraphQLClient } from 'teto-client-api';
import getErrors from '../../../api/graphQL/getErrors';
import BarcodeReader from '../../../components/BarcodeReader/BarcodeReader';
import BarcodeScannerStatusIcon from '../../../components/Custom Icons/BarcodeScannerStatusIcon/BarcodeScannerStatusIcon';
import EngItemMasterInspector from '../../../components/Inspectors/EngItemMasterInspector/EngItemMasterInspector';
import AddEditList from '../../../components/Inspectors/components/AddEditInspector/AddEditList/AddEditList';
import EditingState from '../../../components/Inspectors/types/EditingState';
import { BreadCrumbSharedState } from '../../../components/SharedStateComponents/StateContainers/BreadCrumbModalState';
import { ItemMasterSharedState } from '../../../components/SharedStateComponents/StateContainers/ItemMasterState';
import reduceSimilarPart, { Item } from './helpers/InventoryInspectorHelper';
import { InventoryInspectorProps } from './types/InventoryInspectorType';

const containerSx: SxProps<Theme> = {
  alignItems: 'center',
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  justifyContent: 'flex-start',
  overflowY: 'auto',
  overflowX: 'hidden',
  pt: 1,
  pb: '1px',
  rowGap: 2,
  width: '100%',
};

const stickyBtnSx: SxProps<Theme> = {
  top: (theme) => `${theme.spacing(-1)}`,
  position: 'sticky',
  zIndex: 12,
};
const addItemSx: SxProps<Theme> = {
  top: (theme) => `${theme.spacing(-1)}`,
};

const dividerSx: SxProps<Theme> = {
  zIndex: 12,
  borderColor: 'red',
  width: '100%',
  height: 0,

  alignItems: 'center',
  '& .MuiDivider-root::before': {
    width: '8px !important',
  },
};

const titleSx = {
  color: 'primary.main',
  fontSize: (theme: Theme) => theme.typography.subtitle1,
  p: 0,
  pl: 1,
  pb: 0.5,
};

const contentSx = { p: 0, pl: 1, pr: 1 };

const topDividerSx = {
  width: '97%',
  position: 'absolute',
};

const initialEditingState = {
  hasEdited: false,
  isEditing: false,
  tabError: false,
};

const InventoryInspector = <T extends FormikValues>(
  props: InventoryInspectorProps<T>
) => {
  const {
    barcodeScanCb,
    btnLayoutSx,
    deleteList,
    excludeCreatePart,
    formatPartListItem,
    overrideSubmit,
    formikInitialValues,
    listFormComponent,
    menuOptions,
    mutationProps,
    onClose,
    open,
    partsToAddToList,
    reducerKey,
    saveConfirmation,
    setHasAssignedParts,
    setItemCount,
    setPartsToAddToList,
    title,
    topFormComponent,
    topFormEditState,
  } = props;
  const { ready, t } = useTranslation();

  const messageContext = useContext(MessageContext);

  const resetItemMaster = useResetAtom(ItemMasterSharedState);
  const [itemMaster, setItemMaster] = useAtom(ItemMasterSharedState);
  const [levels, setLevels] = useAtom(BreadCrumbSharedState);

  const [initialValues, setInitialValues] = useState(() => formikInitialValues);
  const [newItemOpen, setNewItemOpen] = useState(false);
  const [partsList, setPartsList] = useState<Item[]>([]);
  const [editingState, setEditingState] =
    useState<EditingState>(initialEditingState);
  const [selectedItem, setSelectedItem] = useState<
    Record<string, unknown> | undefined
  >();
  const [quantity, setQuantity] = useState(0);
  const [needsCloseConfirmation, setNeedsCloseConfirmation] =
    useState<boolean>(false);
  const [barcodeEnabled, setBarcodeEnabled] = useState<boolean>(false);
  const [unSavedChanges, setUnSavedChanges] = useState<boolean>(false);
  const [isCreatePartOpen, setIsCreatePartOpen] = useState<boolean>(false);

  useEffect(() => {
    if (deleteList?.value) {
      setPartsList([]);
      deleteList?.setValue?.(false);
    }
  }, [deleteList, deleteList?.value]);

  useEffect(() => {
    if (partsList.length > 0 && setHasAssignedParts) {
      setHasAssignedParts(true);
    }
    if (partsList.length === 0 && setHasAssignedParts) {
      setHasAssignedParts(false);
    }
  }, [partsList.length, setHasAssignedParts]);

  const _handleSubmit = useCallback(
    (parts: Item[]) => {
      getGraphQLClient()
        .performMutation(mutationProps.mutationString, {
          ...mutationProps.formatVariables(parts),
        })
        .then((d) => {
          if (d.hasError()) {
            d.showAllSystemErrors(messageContext.setError);
            if (d.hasValidationErrors()) {
              const fieldErrors = getErrors(d.validationErrors);
              messageContext.setError(fieldErrors);
            }
            return;
          }
          if (d.data[mutationProps.responseKey].success) {
            messageContext.setSuccess(
              mutationProps.successMessage ||
                t('pages.inventory.mutationSuccess')
            );

            if (mutationProps.onSuccess) {
              mutationProps.onSuccess(d).then(() => onClose());
            } else onClose();
          }
        });
    },
    [partsList, reducerKey, mutationProps, messageContext, t, onClose]
  );

  useEffect(() => {
    setItemCount?.(partsList.length);
  }, [setItemCount, partsList.length]);

  useEffect(() => {
    if (partsToAddToList && partsToAddToList?.length > 0) {
      setPartsList((d) => [...d, ...partsToAddToList] as Item[]);
      setPartsToAddToList?.();
    }
  }, [partsToAddToList, setPartsToAddToList]);

  useEffect(() => {
    if (formikInitialValues) {
      setInitialValues(formikInitialValues);
    }
  }, [formikInitialValues]);

  useLayoutEffect(() => {
    if (itemMaster.createdPart) {
      setIsCreatePartOpen(false);
      setInitialValues((initVals: Record<string, unknown>) => ({
        ...initVals,
        ...itemMaster.createdPart,
        itemId: itemMaster?.createdPart?.id || initVals.itemId || undefined,
        availableQty: 0,
      }));
      resetItemMaster();
      setNewItemOpen(true);
    }
  }, [
    formikInitialValues,
    itemMaster.createdPart,
    levels,
    resetItemMaster,
    setItemMaster,
    setLevels,
  ]);

  const BarcodeButton = useMemo(() => {
    const handleClick = () => {
      setBarcodeEnabled((enabled) => !enabled);
    };

    return {
      name: 'barcode',
      icon: (
        <BarcodeScannerStatusIcon
          cameraEnabled={topFormEditState.isValid && barcodeEnabled}
        />
      ),
      onClick: handleClick,
      disabled: !topFormEditState.isValid,
    };
  }, [barcodeEnabled, topFormEditState.isValid]);

  const buttonList = useMemo(
    () => [
      {
        disabled:
          editingState.isEditing ||
          !topFormEditState.isValid ||
          Boolean(selectedItem),
        children: t('pages.inventory.details.createPart'),
        onClick: () => {
          setIsCreatePartOpen(true);
        },
      },
      ...(menuOptions || []),
    ],
    [
      editingState.isEditing,
      menuOptions,
      selectedItem,
      t,
      topFormEditState.isValid,
    ]
  );

  const _handlePartUpdate = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (value: any, mode: 'add' | 'edit') => {
      let items = [];
      if (mode === 'add') {
        const prevItem = partsList.find(
          (part) => part.id === (value.id || value.itemId)
        );

        if (prevItem) {
          const combinedLists = [...partsList, value];
          items = reduceSimilarPart(combinedLists, reducerKey);
        }
        if (!prevItem) {
          items = [...partsList, { ...value, id: value?.id || value?.itemId }];
        }
      }
      if (mode === 'edit') {
        items = partsList.map((i) => {
          if (
            (i as unknown as { id?: number })?.id ===
            (selectedItem as unknown as { id: number })?.id
          )
            return { ...i, ...value };
          return i;
        });
      }
      setInitialValues({
        ...formikInitialValues,
      });
      setNewItemOpen(false);
      setPartsList(items);
      setSelectedItem(undefined);
      setEditingState({
        isEditing: false,
        hasEdited: true,
        tabError: false,
      });
    },
    [formikInitialValues, partsList, reducerKey, selectedItem]
  );

  const _handleDeletePart = useCallback(() => {
    setPartsList((d) =>
      d.filter(
        (i) =>
          (i as unknown as { id?: number })?.id !==
          (selectedItem as unknown as { id: number })?.id
      )
    );
    setSelectedItem(undefined);
    setEditingState({
      isEditing: false,
      hasEdited: true,
      tabError: false,
    });
    return Promise.resolve();
  }, [selectedItem]);

  const [, setScan] = useBarcodeScannerListener(['ITM'], (prefix, val) => {
    setNewItemOpen(true);
    barcodeScanCb(prefix, val, quantity);
    return false;
  });

  const formattedSaveConfirmation = useMemo(() => {
    if (saveConfirmation) {
      const { confirm } = saveConfirmation;
      const saveContent =
        typeof confirm?.content === 'function'
          ? confirm?.content?.(partsList)
          : confirm?.content;

      return {
        ...confirm,
        content: saveContent,
        onYes: confirm?.onYes ?? _handleSubmit,
      } as unknown as ETOButtonProps['confirm'];
    }
    return undefined;
  }, [_handleSubmit, partsList, saveConfirmation]);

  return (
    <>
      {ready && (
        <Inspector
          buttonStrip={
            <>
              <ButtonStrip
                className={{
                  pt: 0,
                  borderTop: 0,
                }}
                leftButton={{
                  text: t('generic.cancel'),
                  disabled: false,
                  onClick: () => onClose(),
                  color: 'secondary',
                  btnTestId: 'inspector-btn-cancel',
                }}
                rightButton={{
                  text: t('generic.save'),
                  disabled: !topFormEditState.isValid || partsList.length === 0,
                  onClick: () =>
                    overrideSubmit
                      ? overrideSubmit(
                          partsList,
                          _handleSubmit,
                          (newPartValues) => setPartsList(newPartValues)
                        )
                      : _handleSubmit(reduceSimilarPart(partsList, reducerKey)),
                  color: 'primary',
                  btnTestId: 'btn-parts-save',
                  confirm: formattedSaveConfirmation,
                }}
                size="medium"
              />
              <Box sx={{ mt: 2 }}>
                <BarcodeReader
                  barcodeId={`${title.text}-barcode-scanner`}
                  enabled={barcodeEnabled}
                  onRead={setScan}
                />
                {barcodeEnabled && (
                  <Box sx={{ mt: 2 }}>
                    <ETOTextField
                      handleChange={(e) =>
                        setQuantity(parseInt(e.target.value, 10))
                      }
                      label={t('generic.quantity')}
                      name="QTY"
                      type="number"
                      value={quantity}
                    />
                  </Box>
                )}
              </Box>
            </>
          }
          onClose={() => {
            if (unSavedChanges) {
              setNeedsCloseConfirmation(true);
            } else {
              onClose();
            }
          }}
          open={open}
          options={[BarcodeButton]}
          title={title}
        >
          <Box sx={containerSx}>
            {React.cloneElement(topFormComponent, {
              setUnSavedChanges,
            })}

            <Divider
              sx={{
                ...dividerSx,
                color:
                  partsList.length > 0 && !editingState.tabError
                    ? 'text.primary'
                    : 'error.main',
              }}
              textAlign="left"
            >
              {t('pages.inventory.selectPart')}
            </Divider>

            <AddEditList
              accordionListItems={partsList}
              addItemSx={addItemSx}
              additionalButtons={
                excludeCreatePart ? [...(menuOptions || [])] : buttonList
              }
              addLabel={t('pages.inventory.details.addPart')}
              buttonDisabledCondition={!topFormEditState.isValid}
              buttonLayoutSx={{ ...stickyBtnSx, ...btnLayoutSx }}
              canAddItem
              canDeleteItem
              customListSx={{ overflowY: 'unset' }}
              customNoRecordsText={`${t('entities:Part.Part')} ${t(
                'generic.required'
              )}`}
              editingState={editingState}
              expandForm={listFormComponent.expandForm}
              formatToAccordionListItem={formatPartListItem}
              formComponent={listFormComponent.formComponent}
              formikInitialValues={initialValues}
              formikProps={{ enableReinitialize: true }}
              formikSchema={listFormComponent.formikSchema}
              isNewItemOpen={newItemOpen}
              listContainerSx={{ overflowY: 'unset' }}
              noRecordsSx={{
                color: 'error.main',
              }}
              onAdd={(val) => _handlePartUpdate(val, 'add')}
              onDelete={_handleDeletePart}
              onUpdate={(val) => _handlePartUpdate(val, 'edit')}
              selectedItem={selectedItem}
              setEditingState={setEditingState}
              setIsNewItemOpen={setNewItemOpen}
              setSelectedItem={setSelectedItem}
            />
          </Box>
        </Inspector>
      )}
      <GenericDialog
        contentSx={contentSx}
        fullScreen
        isOpen={isCreatePartOpen}
        onClose={() => setIsCreatePartOpen(false)}
        title={t('inspectors.engItemMasterInspector.title')}
        titleSx={titleSx}
      >
        <Divider sx={topDividerSx} />
        <EngItemMasterInspector
          initialValues={undefined}
          onClose={() => {
            setIsCreatePartOpen(false);
          }}
          open={isCreatePartOpen}
        />
      </GenericDialog>

      {needsCloseConfirmation && (
        <OkCancelConfirmDialog
          content={t('dialogs.closeUnsavedForm.content')}
          onCancel={() => {
            setNeedsCloseConfirmation(false);
          }}
          onOk={() => {
            setNeedsCloseConfirmation(false);
            onClose();
          }}
          open={needsCloseConfirmation}
          title={t('dialogs.closeUnsavedForm.title')}
        />
      )}
    </>
  );
};

export default InventoryInspector;
