import {
  AutocompleteRenderInputParams,
  Popper,
  SxProps,
  TextField,
  Theme,
} from '@mui/material';

import KeyboardArrowDownRoundedIcon from '@mui/icons-material/KeyboardArrowDownRounded';
import KeyboardArrowUpRoundedIcon from '@mui/icons-material/KeyboardArrowUpRounded';
import React, { useCallback, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { TETODataColumn } from '../../GridBuilder/types/TETODataColumn';
import IColumnEditorNodeClass from './IColumnEditorNodeClass';

// eslint-disable-next-line import/no-named-as-default
import TreeSelect from '../../../MUITreeSelect';
import SelectColumnEditorNodeClass from './SelectColumnEditorNodeClass';

interface SelectColumnEditorProps {
  columns: TETODataColumn[];
  disabled?: boolean;
  modifyChildren?: (
    // eslint-disable-next-line no-unused-vars
    children: IColumnEditorNodeClass[]
  ) => IColumnEditorNodeClass[];
  // eslint-disable-next-line no-unused-vars
  onValueChanged: (values: IColumnEditorNodeClass) => void;
  // eslint-disable-next-line no-unused-vars
  isValueDisabled: (value: IColumnEditorNodeClass) => boolean;
  sx?: SxProps<Theme>;
  label?: string;
}
// Separate popper to pass down styles, without this you cant access the text styling. And will create bug #74221
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const ConsistentStyledTextPopper = (props: any) => (
  <Popper
    {...props}
    sx={{
      '& *:not(svg) ': {
        fontSize: (theme) => `${theme.typography.body1.fontSize} !important`,
        fontWeight: (theme) =>
          `${theme.typography.body1.fontWeight} !important`,
        fontFamily: (theme) =>
          `${theme.typography.body1.fontFamily} !important`,
        letterSpacing: (theme) =>
          `${theme.typography.body1.letterSpacing} !important`,
      },
    }}
  />
);

const SelectColumnEditor: React.FC<SelectColumnEditorProps> = (props) => {
  const {
    columns,
    disabled,
    isValueDisabled,
    label,
    modifyChildren,
    onValueChanged,
    sx,
  } = props;
  const { t } = useTranslation();
  const [branch, setBranch] = useState<
    IColumnEditorNodeClass | null | undefined
  >(null);

  const _getChildren = useCallback(
    (node: IColumnEditorNodeClass | null) => {
      const children = node
        ? node.getChildren()
        : columns
            .filter((a) => !a.parentPath)
            .map((col) => new SelectColumnEditorNodeClass(columns, col));

      if (modifyChildren) {
        try {
          const modifiedChildren = modifyChildren(children);
          return modifiedChildren;
        } catch (error) {
          // eslint-disable-next-line no-console
          console.error(error);
        }
      }

      return children;
    },
    [columns, modifyChildren]
  );

  return (
    <TreeSelect
      branch={branch}
      disabled={disabled}
      enterIcon={
        <KeyboardArrowDownRoundedIcon color="primary" fontSize="large" />
      }
      exitIcon={<KeyboardArrowUpRoundedIcon color="primary" fontSize="large" />}
      getChildren={_getChildren}
      getOptionDisabled={(node) => isValueDisabled(node)}
      getOptionKey={(node) => node.column.name}
      getOptionLabel={(node) => t(node.column.header)}
      getParent={(node: IColumnEditorNodeClass) => node.getParent()}
      isBranch={(node) => node.isBranch()}
      ListboxProps={{ className: 'SelectColumnEditorStyles' }}
      onBranchChange={(_e, b) => setBranch(b)}
      onChange={(_e, v) => {
        // eslint-disable-next-line no-unused-expressions
        v && onValueChanged(v);
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore This code allows for blurring of the input field so that users don't have to double click it in order to close it.
        document.activeElement?.blur();
      }}
      placeholder="Select a Column"
      PopperComponent={ConsistentStyledTextPopper}
      renderInput={(params: AutocompleteRenderInputParams) => (
        <TextField
          {...params}
          fullWidth
          label={label ?? `${t('generic.columns')} ${t('generic.picker')}`}
          size="small"
          sx={{ typography: 'body1' }}
        />
      )}
      sx={[{ pt: 1 }, ...(Array.isArray(sx) ? sx : [sx])]}
      value={null}
    />
  );
};

export const SelectColumnEditorNode = SelectColumnEditorNodeClass;
export default SelectColumnEditor;
