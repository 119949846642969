import { TFunction } from 'i18next';
import { buildCellFormatter } from '../../TETOGridGraphQL/Formatters/cellFormatterHelper';
import TableColumnDefinition from '../../TetoGrid/types/TableColumnDefinition';

const formatRFQGridColumns = (t: TFunction) => {
  const columns: TableColumnDefinition[] = [
    {
      name: 'rFQId',
      header: t('entities:RFQ.RFQ'),
      id: 'rfq',
      align: 'start' as const,
      sortable: true,
      type: 'number',
      title: t('entities:RFQ.RFQ'),
      order: 1,
      fixed: 'none' as const,
      filterType: 'number',
      defaultWidth: 200,
      flex: 1,
    },
    {
      name: 'company.name',
      header: t('entities:Company.Name'),
      id: 'name',
      align: 'start' as const,
      sortable: true,
      type: 'string',
      title: t('entities:Company.Name'),
      order: 2,
      fixed: 'none' as const,
      filterType: 'string',
      defaultWidth: 200,
      flex: 1,
    },
    {
      name: 'company.phone',
      header: t('entities:Company.Phone'),
      align: 'start' as const,
      id: 'phone',
      sortable: true,
      type: 'string',
      title: t('entities:Company.Phone'),
      order: 3,
      fixed: 'none' as const,
      filterType: 'string',
      defaultWidth: 100,
      flex: 1,
    },
    {
      name: 'company.city',
      header: t('entities:Company.City'),
      id: 'city',
      align: 'start' as const,
      sortable: true,
      type: 'string',
      title: t('entities:Company.City'),
      order: 4,
      fixed: 'none' as const,
      filterType: 'string',
      defaultWidth: 100,
      flex: 1,
    },
    {
      name: 'company.state',
      header: t('entities:Company.State'),
      defaultWidth: 40,
      sortable: true,
      type: 'string',
      id: 'state',
      align: 'start' as const,
      title: t('entities:Company.State'),
      order: 5,
      fixed: 'none' as const,
      filterType: 'string',
    },
    {
      name: 'date',
      header: t('generic.date'),
      id: 'date',
      align: 'start' as const,
      sortable: true,
      type: 'date',
      title: t('generic.date'),
      order: 6,
      fixed: 'none' as const,
      filterType: 'date',
      defaultWidth: 200,
      flex: 1,
    },
  ];

  return columns.map((c) => ({
    ...c,
    render: c.render ?? buildCellFormatter(c),
    showColumnMenuTool: true,
  }));
};

export default formatRFQGridColumns;
