import { GridResponsiveSettings } from './types/GridResponsiveSettings';
import { GridResponsiveSettingsSingle } from './types/GridResponsiveSettingsSingle';

// eslint-disable-next-line import/prefer-default-export
export const GridResponsiveSettingsBuilder = {
  down: (breakpoints: { [key: string]: GridResponsiveSettingsSingle }) =>
    ({
      mode: 'down',
      breakpoints,
    } as GridResponsiveSettings),
  up: (breakpoints: { [key: string]: GridResponsiveSettingsSingle }) =>
    ({
      mode: 'up',
      breakpoints,
    } as GridResponsiveSettings),
};
