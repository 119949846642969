import { gql } from '../../../__generated__';

export default gql(`query inventoryRequisitionPart($filter: InventoryPullDetailFilterInput!) {
  inventoryPullDetails( where: $filter, take: 1) {
    items {     
      itemId
      item {
        itemCompanyId
        description
        uOMId
        uOM {
          uomtype
          itemUom
        }
      }      
    }
  }
}`);
