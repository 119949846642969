import { gql } from '../../../../__generated__';

const getGLAccountQuery = gql(`query getGLAccounts{
  gLAccounts (
    where: {
      gLAccountTypeId: {eq: 1},
      active: { eq: true }
    },
    order: {code: ASC}
    ) {
    items {
      code
      description
      displayName
      id
    }
  }
}`);

export default getGLAccountQuery;
