import { TFunction } from 'i18next';
import { Company } from '../../../__generated__/graphql';

const shipToColumns = (t: TFunction) => [
  {
    name: 'name',
    header: t('entities:Company.Name'),
    id: 'name',
    align: 'start' as const,
    sortable: true,
    type: 'string',
    title: t('entities:Company.Name'),
    order: 1,
    fixed: 'none' as const,
    filterType: 'string',
    filterable: true,
    defaultWidth: 200,
    showColumnMenuTool: true,
    defaultFlex: 2,
    render: ({ data }: { data: Partial<Company> }) => {
      const city = data?.city ? ` [${data?.city}]` : '';
      return `${data.name}${city}`;
    },
  },
  {
    name: 'phone',
    header: t('entities:Company.Phone'),
    align: 'start' as const,
    id: 'phone',
    sortable: true,
    showColumnMenuTool: true,
    type: 'string',
    title: t('entities:Company.Phone'),
    order: 2,
    fixed: 'none' as const,
    filterType: 'string',
    defaultWidth: 100,
    defaultFlex: 1,
  },
  {
    name: 'state',
    header: t('entities:Company.State'),
    defaultWidth: 40,
    sortable: true,
    showColumnMenuTool: true,
    type: 'string',
    id: 'state',
    align: 'start' as const,
    title: t('entities:Company.State'),
    order: 4,
    fixed: 'none' as const,
    filterType: 'string',
    defaultFlex: 1,
  },
  {
    name: 'zip',
    header: t('entities:Company.Zip'),
    sortable: true,
    id: 'zip',
    align: 'start' as const,
    showColumnMenuTool: true,
    type: 'string',
    title: t('entities:Company.Zip'),
    order: 5,
    fixed: 'none' as const,
    filterType: 'string',
    defaultWidth: 50,
    defaultFlex: 1,
  },
];

export default shipToColumns;
