import React, { useContext, useMemo } from 'react';
import { Dayjs } from 'dayjs';
import { Permission } from 'teto-client-api';
import AuthContext from '../../../../../contexts/AuthContext';
import CommonNotesPanel, {
  NotesPanelProps,
} from '../../../../Inspectors/components/CommonNotesPanel/CommonNotesPanel';
import { gql } from '../../../../../__generated__';
import { CustomInput } from '../../../../Inspectors/components/CommonNotesPanel/CommonAddEditNote';

type ProcessScheduleNotesPanelProps = Omit<
  NotesPanelProps,
  | 'addNewMutation'
  | 'deleteMutation'
  | 'entityName'
  | 'entityQuery'
  | 'permissions'
  | 'updateMutation'
>;

const addProcessScheduleNoteMutation =
  gql(`mutation addProcessScheduleNotes($input: AddProcessScheduleNoteInput) {
  addProcessScheduleNote( input: $input) {
      id
      date
      notes
      processScheduleId
      employeeId
      employee {
        id
        name
      }
  }
}`);
const deleteProcessScheduleNoteMutation =
  gql(`mutation deleteProcessScheduleNotes($id:Int!) {
  deleteProcessScheduleNote( id:$id) {
    success
  }
}`);
const getProcessScheduleNotesQuery =
  gql(`query processScheduleNotes($processScheduleId: Int!) {
  processScheduleNotes :processScheduleNotes( where: { processScheduleId: { eq: $processScheduleId } }) {
    items {
      employee {
        id
        name
      }
      id
      notes
      date
    }
  }
}`);
const updateProcessScheduleNoteMutation =
  gql(`mutation updateProcessScheduleNotes($input: UpdateProcessScheduleNoteInput) {
  updateProcessScheduleNote( input: $input) {
      id
      date
      notes
      processScheduleId
      employeeId
      employee {
        id
        name
      }
  }
}`);

const customInputs: CustomInput = {
  mode: 'both',
  noteDate: 'date',
  note: 'notes',
};

interface ProcessScheduleNote {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  employee: any;
  id: number;
  notes: string;
  date: Dayjs;
  [x: `${string}Id`]: number;
}

const transformData = (notes: ProcessScheduleNote[]) =>
  notes.map((note: ProcessScheduleNote) => ({
    ...note,
    noteDate: note.date,
    note: note.notes,
  }));

const ProcessScheduleNotesPanel = (props: ProcessScheduleNotesPanelProps) => {
  const authContext = useContext(AuthContext);
  const notesPermissions = useMemo(
    () => ({
      canAdd: authContext.hasPermission(
        Permission.Add_Procurement_ProcessSchedules_Notes
      ),
      canDelete: authContext.hasPermission(
        Permission.Delete_Procurement_ProcessSchedules_Notes
      ),
      canEdit: authContext.hasPermission(
        Permission.Modify_Procurement_ProcessSchedules_Notes
      ),
    }),
    [authContext]
  );

  return (
    <CommonNotesPanel
      addNewMutation={addProcessScheduleNoteMutation}
      customInputs={customInputs}
      deleteMutation={deleteProcessScheduleNoteMutation}
      entityName="ProcessSchedule"
      entityQuery={getProcessScheduleNotesQuery}
      permissions={{
        canAddNotes: notesPermissions.canAdd,
        canDeleteNotes: notesPermissions.canDelete,
        canModifyNotes: notesPermissions.canEdit,
      }}
      transformData={transformData}
      updateMutation={updateProcessScheduleNoteMutation}
      {...props}
    />
  );
};

export default ProcessScheduleNotesPanel;
