export default `
query getShippersQuery {
  shippers {
    items {
      id
      shipperName
    }
  }
}
`;
