import { gql } from '../__generated__';

export const employeesQuery = `query employees{
  employees (where: { }, order: {employeeNumber: ASC}) {
    items {
      active
      employeeNumber
      id
      firstName
      lastName
      name
    }
  }
}`;

export const getAllEmployeesQuery = gql(`query employees{
  employees (where: { }, order: {employeeNumber: ASC}) {
    items {
      active
      employeeNumber
      id
      firstName
      lastName
      name
    }
  }
}`);

export const salesReleasedQuery =
  gql(`query commonSalesReleased($projectId: Int!, $jobId: Float) {
    masterQueue(
      where: { specId: { eq: $jobId }, and: { projectId: { eq: $projectId } } }
    ) {
      items {
        salesReleaseDate
      }
    }
  }
  `);

export const commonTermsQuery = gql(`
query commonTermsQuery{
  terms {
    items {
      id
      description
    }
  }
}
`);
