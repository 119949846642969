import { gql } from '../../../../__generated__';

const getRFQReports = gql(`
  query getRFQReports {
    rFQReports{
      allowView
      alternateId
      enabled
      favorite
      isAlternate
      modified
      reportCategoryID
      reportCenter
      reportDescription
      reportDisplayName
      reportID 
      reportLayoutID
      reportName    
    }
  }
`);

export default getRFQReports;
