import { SxProps, Theme } from '@mui/material/styles';

const addDataRowStyles: SxProps<Theme> = {
  '& .InovuaReactDataGrid__locked-rows-container--position-end': {
    borderRadius: (theme) => `${theme.spacing(0.5)}`,
    borderTop: (theme) => `3px solid ${theme.palette.primary.light} !important`,
  },
};

const addDataRowLight: SxProps<Theme> = {
  '& .InovuaReactDataGrid__locked-rows-container--position-end': {
    border: (theme) => `2px solid ${theme.palette.grey[400]} !important`,
    '& .InovuaReactDataGrid__locked-row-group': {
      backgroundColor: (theme) => `${theme.palette.grey[200]} !important`,
      zIndex: 10100,
    },
  },
};

const addDataRowDark: SxProps<Theme> = {
  '& .InovuaReactDataGrid__locked-rows-container--position-end': {
    opacity: 0.9,
    border: (theme) => `2px solid ${theme.palette.grey[500]} !important`,
    '& .InovuaReactDataGrid__locked-row-group': {
      backgroundColor: (theme) => `${theme.palette.grey[800]} !important`,
      zIndex: 10100,
    },
  },
};

export { addDataRowDark, addDataRowLight, addDataRowStyles };
