import { Box, Theme } from '@mui/material';
import {
  MessageContext,
  OkCancelConfirmDialog,
} from '@teto/react-component-library-v2';
import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { Permission } from 'teto-client-api';
import useLocalStorage from 'use-local-storage';
import * as Yup from 'yup';
import { gql } from '../../../../__generated__';
import { SendSurplusOrderToInventoryItemInput } from '../../../../__generated__/graphql';
import buildMutation from '../../../../api/graphQL/buildMutation';
import AuthContext from '../../../../contexts/AuthContext';
import OrderAdditionalToInventoryView from '../../../../views/OrderAdditionalToInventoryView.yaml';
import ActionBar from '../../../ActionBar/ActionBar';
import InventoryLocationInput from '../../../InputFields/InventorySelectField/InventoryLocationInput';
import { defaultGridResponsiveSettings } from '../../../TETOGridGraphQL/TETOGridGraphQL';
import MainTetoGridGraphQL from '../../../TETOGridGraphQL/TETOMainGridGraphQL';
import GridCommonMobileButtons from '../../../TETOGridGraphQL/components/GridCommonMobileButtons/GridCommonMobileButtons';
import useGrid from '../../../TETOGridGraphQL/hooks/useGrid';
import { useGridBuilderFromView } from '../../../TETOGridGraphQL/hooks/useGridBuilder';
import { CellChangingArgs } from '../../../TETOGridGraphQL/types/CellChangingArgs';
import useStyles from '../../../TetoGrid/GridStyles';

const ROOT_QUERY_PATH = 'purchaseOrderHeader';
const PERSISTANCE_NAME = 'purchaseOrder-order-additional-details-grid';

const orderAdditionalToInventoryMutation =
  gql(`mutation orderAdditionalToInventory($input: SendSurplusOrderToInventoryInput!) {
    sendSurplusOrderToInventory(input: $input){
      items {
        id
      }
    }
}`);
interface OrderAdditionalToInventoryContentProps {
  purchaseOrderId: number;
  setValuesToBeSubmitted: React.Dispatch<
    React.SetStateAction<SendSurplusOrderToInventoryItemInput[]>
  >;
  valuesToBeSubmitted: SendSurplusOrderToInventoryItemInput[];
}

interface OrderAdditionalToInventoryProps {
  // eslint-disable-next-line no-unused-vars
  setOrderAdditionalToInventoryIsOpen: (isOpen: boolean) => void;
  orderAdditionalToInventoryIsOpen: boolean;
  purchaseOrderId: number;
  refreshGrid: () => void;
}

const EDITABLE_COLUMNS = [
  'dateRequired',
  'dateRevised',
  'addlQtyToOrder',
  'totalQty',
];

const containerSx = {
  display: 'flex',
  flexDirection: 'column',
  gap: 2,
  pt: 1,
  height: '100%',
  flexGrow: 1,
  width: '100%',
};
const paperPropsSx = {
  sx: (theme: Theme) => ({
    height: '100%',
    width: '100%',
    overflowY: 'hidden',
    [theme.breakpoints.down('sm')]: {
      maxWidth: 'unset',
      maxHeight: 'unset',
      margin: 0,
      p: 2,
      '& >h2': { pl: 1 },
      '& .MuiDialogContent-root': { p: 0 },
      '& .MuiDialogActions-root': { pr: 8 },
    },
  }),
};
const heightSx = { height: '100%' };
const inventorySx = {
  width: '300px',
};
const zIndexSx = { zIndex: 1301 };

const inlineEditSchema = Yup.object().shape({
  dateRequired: Yup.date(),
  dateRevised: Yup.date(),
  addlQtyToOrder: Yup.number().required().min(0, 'Must be greater than 0'),
  totalQty: Yup.number().required().min(0, 'Must be greater than 0'),
});

const customQuery = `query orderAdditionalToInv($id: Int!) {
  purchaseOrderHeader(id: $id) {
    id
    items: itemQuantities {
      itemId
      addlQtyToOrder
      dateRequired
      dateRevised
      itemCompanyId
      itemDescription
      purchaseOrderId
      qtyToInventory
      qtyToProjects
      totalQty
    }
  }
}
`;
const gridResponsiveSettings = {
  ...defaultGridResponsiveSettings,
  breakpoints: {
    xs: { ...defaultGridResponsiveSettings.breakpoints.xs },
    md: {
      ...defaultGridResponsiveSettings.breakpoints.md,
      filterRowHeight: 0,
      enableFiltering: false,
    },
  },
};
const OrderAdditionalToInventoryContent = (
  props: OrderAdditionalToInventoryContentProps
) => {
  const { purchaseOrderId, setValuesToBeSubmitted, valuesToBeSubmitted } =
    props;

  const { t, ready } = useTranslation();
  const ref = useRef(null);
  const messageContext = useContext(MessageContext);
  const authContext = useContext(AuthContext);
  const [configureInspectorOpen, setConfigureInspectorOpen] =
    useState<boolean>(false);

  const [inventoryLocationId, setInventoryLocationId] = useLocalStorage<
    undefined | number
  >('orderAdditionalInventory', undefined);

  const [locationError, setLocationError] = useState<string | undefined>(
    undefined
  );

  const canEdit = authContext.hasPermission(
    Permission.Add_Procurement_PurchaseOrders_PurchaseOrderHeader_PurchaseOrderDetails
  );

  const { gridBuilder, builderReady, error, hasError } = useGridBuilderFromView(
    OrderAdditionalToInventoryView,
    ROOT_QUERY_PATH,
    (gb) =>
      gb
        .updateDefinition('dateRequired', {
          editable: canEdit,
          defaultWidth: 50,
          filterable: false,
        })
        .updateDefinition('dateRevised', {
          editable: canEdit,
          defaultWidth: 50,
          filterable: false,
        })
        .updateDefinition('addlQtyToOrder', {
          editable: canEdit,
          defaultWidth: 50,
          filterable: false,
        })
        .updateDefinition('totalQty', {
          editable: canEdit,
          defaultWidth: 50,
          filterable: false,
        })
        .updateDefinition('qtyToProjects', {
          filterable: false,
        })
        .updateDefinition('qtyToInventory', {
          filterable: false,
        })
        .updateDefinition('itemDescription', {
          filterable: false,
        })
        .updateDefinition('itemCompanyId', {
          filterable: false,
        })
  );

  const { gridProps } = useGrid(
    PERSISTANCE_NAME,
    `${ROOT_QUERY_PATH}.items`,
    (e) => messageContext.setError(e.message ?? e),
    t,
    ['id'],
    gridBuilder,
    undefined,
    {
      id: {
        type: 'Int!',
        value: purchaseOrderId,
      },
    },
    undefined,
    undefined,
    customQuery
  );

  gridProps.idProperty = 'itemId';

  const _handleCellChanging = useCallback(
    (change: CellChangingArgs) => {
      const {
        column: { name },
        editValue: { value, originalValue },
        newData,
        changeContext,
      } = change;

      const handleError = (
        e: { validationErrors: { [x: string]: string } },
        _name: string | undefined
      ) => {
        if (e.validationErrors) {
          changeContext.setValidationError(
            e.validationErrors[_name as string],
            _name
          );
        }
        changeContext.cancelChange();
        changeContext.setColumnValue(name as string, originalValue);
      };

      const parseNumber = () => {
        const qty = parseInt(value, 10);
        if (Number.isNaN(qty)) {
          handleError(
            {
              validationErrors: {
                [name as string]: t('generic.invalidNumber'),
              },
            },
            name as string
          );
        }
        return qty;
      };

      const newValuesToBeSubmitted: SendSurplusOrderToInventoryItemInput = {
        dateRequired: newData.dateRequired ?? null,
        dateRevised: newData.dateRevised ?? null,
        additionalQuantityToOrder: newData.additionalToOrder - newData.totalQty,
        itemId: newData.itemId,
        destinationInventoryLocationId: inventoryLocationId as number,
      };
      const prevTotal = newData.qtyToInventory + newData.qtyToProjects;

      let totalQty;
      switch (name) {
        case 'totalQty':
          totalQty = parseNumber();
          newValuesToBeSubmitted.additionalQuantityToOrder =
            totalQty - prevTotal;

          // updates all the values in the row
          changeContext.updateDependentColumns({
            addlQtyToOrder: totalQty - prevTotal,
            totalQty,
          });

          break;
        case 'addlQtyToOrder':
          totalQty = parseNumber() + prevTotal;

          changeContext.updateDependentColumns({
            totalQty,
            addlQtyToOrder: value,
          });

          newValuesToBeSubmitted.additionalQuantityToOrder = parseNumber();
          break;
        default:
          changeContext.setColumnValue(name as string, value);
          break;
      }

      // remove duplicate from valuesToBeSubmitted if it exists, then set in state if it is valid
      const index = valuesToBeSubmitted.findIndex(
        (v) => v.itemId === newValuesToBeSubmitted.itemId
      );
      if (index >= 0) {
        valuesToBeSubmitted.splice(index, 1);
      }
      setValuesToBeSubmitted((prev: SendSurplusOrderToInventoryItemInput[]) => [
        ...prev,
        newValuesToBeSubmitted,
      ]);
    },

    [inventoryLocationId, setValuesToBeSubmitted, t, valuesToBeSubmitted]
  );
  useEffect(() => {
    if (inventoryLocationId) {
      setValuesToBeSubmitted((prev: SendSurplusOrderToInventoryItemInput[]) =>
        prev.map((v) => ({
          ...v,
          destinationInventoryLocationId: inventoryLocationId,
        }))
      );
      setLocationError(undefined);
    }
    if (!inventoryLocationId) {
      setLocationError('Please select an inventory location');
    }
  }, [inventoryLocationId, setValuesToBeSubmitted]);

  const GridCommonBtns = GridCommonMobileButtons({
    refreshGrid: () => gridProps.refreshDataSource(),
    gridRef: ref,
    setConfigureInspector: () => setConfigureInspectorOpen(true),
  });
  return (
    <Box sx={containerSx}>
      <ActionBar
        leftChildren={
          <InventoryLocationInput
            customSx={inventorySx}
            error={locationError}
            excludeLocationId={0}
            handleChange={(e) => {
              if (typeof e.target.value === 'number') {
                setInventoryLocationId(e.target.value);
              } else {
                const parsed = parseInt(e.target.value, 10);
                if (parsed) {
                  setInventoryLocationId(parsed);
                } else {
                  setInventoryLocationId(-1);
                }
              }
            }}
            value={inventoryLocationId}
          />
        }
        rightChildren={GridCommonBtns}
      />
      <Box sx={[useStyles, heightSx]}>
        <MainTetoGridGraphQL
          builderReady={builderReady}
          configureInspector={configureInspectorOpen}
          configureInspectorSx={zIndexSx}
          editMode={{
            editMode: 'byRow',
            validationSchema: inlineEditSchema,
            onCellChanging: (c: CellChangingArgs) => {
              if (EDITABLE_COLUMNS.indexOf(c.column.name as string) >= 0) {
                _handleCellChanging(c);
              }
            },
          }}
          error={error}
          gridProps={gridProps}
          hasError={hasError}
          header={{
            hidden: true,
          }}
          ready={ready}
          ref={ref}
          responsiveSettings={gridResponsiveSettings}
          setConfigureInspector={setConfigureInspectorOpen}
          showEmptyRows
          showGroupSummaryRow={false}
          t={t}
          tableIdentifier={PERSISTANCE_NAME}
        />
      </Box>
    </Box>
  );
};

const OrderAdditionalToInventory = (props: OrderAdditionalToInventoryProps) => {
  const {
    orderAdditionalToInventoryIsOpen,
    purchaseOrderId,
    refreshGrid,
    setOrderAdditionalToInventoryIsOpen,
  } = props;
  const messageContext = useContext(MessageContext);
  const { t } = useTranslation();

  const [valuesToBeSubmitted, setValuesToBeSubmitted] = useState<
    SendSurplusOrderToInventoryItemInput[]
  >([]);

  const handleOrderAdditionalSave = useCallback(() => {
    if (valuesToBeSubmitted.length === 0) {
      return messageContext.setError(t('generic.message.noChangesToSave'));
    }

    buildMutation({
      queryString: orderAdditionalToInventoryMutation,
      variables: {
        input: {
          purchaseOrderId,
          items: valuesToBeSubmitted,
        },
      },
      callback: (): void => {
        messageContext.setSuccess(
          t('generic.message.recordAddedTo', {
            record: t('entities:Inventory.Inventory'),
          })
        );
        refreshGrid();
        setOrderAdditionalToInventoryIsOpen(false);
      },
      errorCallback: (e) => {
        if (e.handleAllErrors) e.handleAllErrors(messageContext.setError);
      },
      messageContext,
    });
  }, [
    messageContext,
    purchaseOrderId,
    refreshGrid,
    setOrderAdditionalToInventoryIsOpen,
    t,
    valuesToBeSubmitted,
  ]);

  return (
    <OkCancelConfirmDialog
      content={
        <OrderAdditionalToInventoryContent
          purchaseOrderId={purchaseOrderId}
          setValuesToBeSubmitted={setValuesToBeSubmitted}
          valuesToBeSubmitted={valuesToBeSubmitted}
        />
      }
      fullWidth
      maxWidth={false}
      onCancel={() => {
        setOrderAdditionalToInventoryIsOpen(false);
      }}
      onOk={() => {
        handleOrderAdditionalSave();
      }}
      open={orderAdditionalToInventoryIsOpen}
      PaperProps={paperPropsSx}
      title={t('pages.purchaseOrders.purchaseOrderModal.orderAdditional')}
    />
  );
};

export default OrderAdditionalToInventory;
