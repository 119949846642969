import {
  TypeFilterValue,
  TypeSingleFilterValue,
  TypeSortInfo,
} from '@inovua/reactdatagrid-community/types';

import {
  EnumType,
  jsonToGraphQLQuery,
  VariableType,
} from 'json-to-graphql-query';
import { isArray } from 'lodash';

const _sortByName = (
  a: { name: string } | null,
  b: { name: string } | null
) => {
  if (!a) return -1;
  if (!b) return 1;

  if (a.name < b.name) {
    return -1;
  }
  if (a.name > b.name) {
    return 1;
  }

  return 0;
};

const _createNestedObjectWithCustomValue = <T>(
  keys: string[],
  lastKeyValue: T
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): Record<string, any> => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const result: Record<string, any> = {};
  let current = result;

  keys.forEach((key, index) => {
    if (index === keys.length - 1) {
      current[key] = lastKeyValue; // Assign the custom value to the last key
    } else {
      current[key] = {}; // Create a new object for the next key
      current = current[key]; // Move to the next level
    }
  });

  return result;
};

export const buildSortObject = (serverSideSort: TypeSortInfo) => {
  const sortables = isArray(serverSideSort) ? serverSideSort : [serverSideSort];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const resultObj: { [key: string]: any }[] = [];

  sortables.forEach((c) => {
    if (c == null) return;

    const split = c?.name.split('.');
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    let currentWorkingObject: { [key: string]: any } = {};
    resultObj.push(currentWorkingObject);

    split?.forEach((s, i) => {
      if (i === split.length - 1) {
        // at the end
        currentWorkingObject[s] = new EnumType(c.dir < 0 ? 'DESC' : 'ASC');
      } else if (
        !Object.prototype.hasOwnProperty.call(currentWorkingObject, s)
      ) {
        currentWorkingObject[s] = {};
        currentWorkingObject = currentWorkingObject[s];
      } else {
        currentWorkingObject = currentWorkingObject[s];
      }
    });
  });

  return resultObj;
};

export const getGraphQLFilterType = (fieldType: string) => {
  switch (fieldType) {
    case 'contains':
    case 'startsWith':
    case 'endsWidth':
    case 'endsWith':
    case 'eq':
    case 'neq':
    case 'gt':
    case 'gte':
    case 'lt':
    case 'lte':
    case 'notinbetween':
    case 'inbetween':
      return fieldType;
    case 'doesNotContain':
    case 'notContains':
      return 'ncontains';
    case 'inrange':
      return 'inbetween';
    // TODO: Work Item #78554
    /*  case 'notinrange':
      return 'notinbetween'; */
    case 'equal':
    case 'equals':
    case 'equalTo':
      return 'eq';
    case 'notEqual':
    case 'notEqualTo':
      return 'neq';
    case 'after':
    case 'greaterThan':
      return 'gt';
    case 'before':
    case 'lessThan':
      return 'lt';
    case 'afterOrOn':
    case 'greaterThanOrEqual':
    case 'greaterThanEqualTo':
      return 'gte';
    case 'beforeOrOn':
    case 'lessThanOrEqual':
    case 'lessThanEqualTo':
      return 'lte';
    case 'empty':
      return 'eq';
    case 'notEmpty':
      return 'neq';
    default:
      return undefined;
  }
};

const _setPropertyOrInsertRow = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  rootItem: any,
  pathName: string,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  val?: any,
  rowIndex?: number,
  skipDuplicateCheck = false
) => {
  if (!rootItem) throw new Error('rootItem is null, something went wrong.');

  if (isArray(rootItem)) {
    const existingObject =
      rowIndex && rowIndex >= 0
        ? rootItem.find(
            (a) => a[pathName] && a[pathName].__groupMarker === rowIndex
          )
        : rootItem.find((a) => a[pathName]);

    if (!skipDuplicateCheck && existingObject && existingObject[pathName])
      return existingObject[pathName];

    const toInsert = { [pathName]: val ?? {} };
    rootItem.push(toInsert);

    const toReturn = toInsert[pathName];

    if (!toReturn)
      throw new Error('toReturn is not set, something went very wrong');
    return toReturn;
  }

  // object already exists so nothing to do
  if (rootItem[pathName]) return rootItem[pathName];

  // eslint-disable-next-line no-param-reassign
  rootItem[pathName] = val ?? {};
  return rootItem[pathName];
};

export const buildFilterObject = (
  serverSideFilter: TypeSingleFilterValue[],
  mandatoryFilter?: TypeSingleFilterValue[]
) => {
  const conditionalRegex = /^(or|and)(\[([0-9])\]){0,1}$/;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const resultObj: any = { and: [], or: [] };

  // this will be our cursor holding the object or array at the
  // current path of the object.  This should never be undefined/null or
  // bad things may happen

  let currentObj = resultObj.and;

  let currentObjectHasSpecificIndexForInsert = -1;

  const filters = [...(mandatoryFilter ?? []), ...serverSideFilter].sort(
    _sortByName
  );

  filters.forEach((f) => {
    let filterVal = f?.value;
    const filterType = getGraphQLFilterType(f?.operator);

    // allows us to be able to filter by null values
    if (f.operator === 'empty') {
      filterVal = null;
    }

    if (f.operator === 'notEmpty' && filterVal === '') {
      filterVal = null;
    }

    if (!filterType) throw new Error(`Invalid Filter Type '${f.operator}'`);

    if (
      filterVal === undefined ||
      (filterVal === '' && f.operator !== 'empty' && f.operator !== 'notEmpty')
    ) {
      return;
    }

    if (
      filterType === 'inbetween' &&
      (filterVal.start === '' ||
        filterVal.start === undefined ||
        filterVal.start === null) &&
      (filterVal.end === '' ||
        filterVal.end === undefined ||
        filterVal.end === null)
    ) {
      // we ignore this because we can't do anything with it.
      return;
    }
    if (
      filterType === 'notinbetween' &&
      (filterVal.start === '' ||
        filterVal.start === undefined ||
        filterVal.start === null) &&
      (filterVal.end === '' ||
        filterVal.end === undefined ||
        filterVal.end === null)
    ) {
      // we ignore this because we can't do anything with it.
      return;
    }

    const nameSplit = f.name.split('.');

    if (
      (f.operator === 'empty' && f.type === 'string') ||
      filterType === 'notinbetween'
    ) {
      currentObj = resultObj.or;
    }
    nameSplit.forEach((ns, i) => {
      // is this the last item in the split name?
      // we treat that differently since we know it is going to be an object and we need
      // to include the filter value and type

      if (i === nameSplit.length - 1) {
        if (f.operator === 'empty' && f.type === 'string') {
          //  if the object is a array we have to insert a new object in the array
          if (isArray(currentObj)) {
            _setPropertyOrInsertRow(
              currentObj,
              ns,
              {
                eq: '',
              },
              undefined,
              true
            );
            _setPropertyOrInsertRow(
              currentObj,
              ns,
              {
                eq: null,
              },
              undefined,
              true
            );
          } else {
            const currentObjCopy = { ...currentObj };

            _setPropertyOrInsertRow(currentObj, ns, {
              eq: '',
            });

            const resultWithNullEq = _setPropertyOrInsertRow(
              currentObjCopy,
              ns,
              {
                eq: null,
              },
              1,
              true
            );

            const nestedObjectWithCustomValue =
              _createNestedObjectWithCustomValue(nameSplit, resultWithNullEq);

            resultObj.or.push(nestedObjectWithCustomValue);

            return;
          }
        }

        if (f.operator === 'notEmpty' && f.type === 'string') {
          _setPropertyOrInsertRow(currentObj, ns, {
            neq: '',
          });
          _setPropertyOrInsertRow(
            currentObj,
            ns,
            {
              neq: null,
            },
            undefined,
            true
          );
        }

        if (filterType === 'inbetween') {
          // this is handled differently because we must build an and
          // clause
          if (
            filterVal.start !== '' &&
            filterVal.start !== undefined &&
            filterVal.start !== null
          ) {
            _setPropertyOrInsertRow(currentObj, ns, {
              gte: filterVal.start,
              lte:
                filterVal.end !== '' &&
                filterVal.end !== undefined &&
                filterVal.end !== null
                  ? filterVal.end
                  : null,
            });
          }

          if (
            filterVal.end !== '' &&
            filterVal.end !== undefined &&
            filterVal.end !== null
          ) {
            _setPropertyOrInsertRow(
              currentObj,
              ns,
              {
                lte: filterVal.end,
              },
              undefined,
              false
            );
          }
        }
        if (filterType === 'notinbetween') {
          if (
            filterVal.start !== '' &&
            filterVal.start !== undefined &&
            filterVal.start !== null
          ) {
            _setPropertyOrInsertRow(currentObj, ns, {
              lt: filterVal.start,
            });
          }

          if (
            filterVal.end !== '' &&
            filterVal.end !== undefined &&
            filterVal.end !== null
          ) {
            _setPropertyOrInsertRow(
              currentObj,
              ns,
              {
                gt: filterVal.end,
              },
              undefined,
              true
            );
          }
        } else {
          _setPropertyOrInsertRow(currentObj, ns, {
            [filterType]: filterVal,
          });
        }
      } else {
        // operators like and and or are treated differently because their results will be an
        // array. So first we determine if we are dealing with an operator like or, and, or[0], and[1]
        const conditionMatches = ns.match(conditionalRegex);
        if (!conditionMatches) {
          // this is easy if the condition doesn't match then
          // the next object we create will be an object
          currentObj = _setPropertyOrInsertRow(
            currentObj,
            ns,
            undefined,
            currentObjectHasSpecificIndexForInsert
          );

          currentObjectHasSpecificIndexForInsert = -1;
        } else {
          const operator = conditionMatches[1];
          if (conditionMatches[3]) {
            // if this is true we are going to request the object below this insert
            // it self at a specific index
            currentObjectHasSpecificIndexForInsert = parseInt(
              conditionMatches[3],
              10
            );
          }

          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          const newArray: any[] = [];
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          (newArray as any).__groupMarker =
            currentObjectHasSpecificIndexForInsert &&
            currentObjectHasSpecificIndexForInsert >= 0
              ? currentObjectHasSpecificIndexForInsert
              : 0;

          currentObj = _setPropertyOrInsertRow(
            currentObj,
            operator,
            newArray,
            currentObjectHasSpecificIndexForInsert
          );
          currentObjectHasSpecificIndexForInsert = -1;

          if (conditionMatches[3]) {
            // if this is true we are going to request the object below this insert
            // it self at a specific index
            currentObjectHasSpecificIndexForInsert = parseInt(
              conditionMatches[3],
              10
            );
          }
        }
      }
    });

    currentObj = resultObj.and;
    currentObjectHasSpecificIndexForInsert = -1;
  });

  const orEntries = Object.entries(resultObj?.or);
  const andEntries = Object.entries(resultObj?.and);

  if (orEntries.length === 1 && andEntries.length === 0) {
    return resultObj.or[0];
  }
  if (andEntries.length === 1 && orEntries.length === 0) {
    return resultObj.and[0];
  }
  if (orEntries.length === 0 && andEntries.length === 0) {
    return {};
  }

  if (orEntries.length > 1 && andEntries.length === 0) {
    return { or: resultObj.or };
  }
  if (andEntries.length > 1 && orEntries.length === 0) {
    return { and: resultObj.and };
  }

  return resultObj;
};

const buildQuery = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  columns: string[],
  queryRoot: string,
  serverSideSort: TypeSortInfo,
  serverSideFilters: TypeFilterValue,
  mandatoryFilter?: TypeFilterValue,
  variables?: {
    [key: string]: {
      type: string;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      value: any;
    };
  },
  options?: {
    pageSize: number;
  }
) => {
  if (columns.length === 0) return null;

  const jq = _columnsToJson(columns);

  const qrSplit = queryRoot.split('.');
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const obj: any = {};
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  let curPart: any = obj;

  qrSplit.forEach((q, i) => {
    if (i === qrSplit.length - 1) {
      curPart[q] = jq;
      return;
    }

    if (!curPart[q] || typeof curPart[q] !== 'object') {
      curPart[q] = {};
    }
    curPart = curPart[q];
  });

  const q = {
    query: obj,
  };

  if (variables && Object.entries(variables).length > 0) {
    obj.__variables = Object.entries(variables).reduce(
      (pv, nv) => ({
        ...pv,
        [nv[0]]: nv[1].type,
      }),
      {}
    );
  }

  const filterAndSortPos = q.query[Object.keys(q.query)[0]];
  const orderObject = buildSortObject(serverSideSort);
  const filterObject = buildFilterObject(
    serverSideFilters ?? [],
    mandatoryFilter ?? []
  );

  filterAndSortPos.__args = {
    skip: 0,
    take: options?.pageSize ?? 10000,
  };

  if (orderObject) filterAndSortPos.__args.order = orderObject;
  if (filterObject) filterAndSortPos.__args.where = filterObject;

  if (variables && Object.entries(variables).length > 0) {
    const variablesFormatted = Object.entries(variables).reduce(
      (pv, cv) => ({
        ...pv,
        [cv[0]]: new VariableType(cv[0]),
      }),
      {}
    );
    filterAndSortPos.__args = {
      ...variablesFormatted,
      ...filterAndSortPos.__args,
    };
  }

  return jsonToGraphQLQuery(q, {
    pretty: true,
    ignoreFields: ['_groupMarker'],
  });
};

const _columnsToJson = (columns: string[]) => {
  const results: { [key: string]: unknown } = {};

  try {
    columns.sort().forEach((c) => {
      const cSplit = c.split('.');
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      let lastObj: any = results;
      cSplit.forEach((a, i) => {
        if (i !== cSplit.length - 1) {
          if (!lastObj[a]) {
            lastObj[a] = {};
            lastObj = lastObj[a];
          } else {
            lastObj = lastObj[a];
          }
        } else {
          lastObj[a] = true;
        }
      });
    });
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error(e);
  }
  return results;
};

export default buildQuery;
