import * as Yup from 'yup';

export default Yup.object().shape({
  itemDate: Yup.date().nullable(),
  itemCost: Yup.number(),
  externalQty: Yup.number().required(),
  rFQQty: Yup.number().required(),
  externalUOMId: Yup.string().required(),
  nonConformanceId: Yup.number(),
  rFQOrderNumber: Yup.number().nullable(),
  purchaseSupplierDescription: Yup.string().max(1000).nullable(),
  purchaseSupplierItem: Yup.string().max(255).nullable(),
  purchaseUom: Yup.string().max(50).nullable(),
  custom1: Yup.string().nullable(),
  custom2: Yup.string().nullable(),
  custom3: Yup.number().nullable(),
  custom4: Yup.number().nullable(),
  custom5: Yup.date().nullable(),
  custom6: Yup.date().nullable(),
  custom7: Yup.boolean().nullable(),
  custom8: Yup.boolean().nullable(),
});
