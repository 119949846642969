import { Typography } from '@mui/material';
import {
  TreeItem,
  TreeItemContentProps,
  TreeItemProps,
  useTreeItem,
} from '@mui/x-tree-view';
import React from 'react';

const CustomTreeItemContent = React.forwardRef(
  (props: TreeItemContentProps, ref) => {
    const {
      classes,
      className,
      label,
      nodeId,
      icon: iconProp,
      expansionIcon,
      displayIcon,
    } = props;
    const {
      disabled,
      expanded,
      selected,
      focused,
      handleExpansion,
      handleSelection,
      preventSelection,
    } = useTreeItem(nodeId);

    const icon = iconProp || expansionIcon || displayIcon;

    const handleMouseDown = (
      e: React.MouseEvent<HTMLDivElement, MouseEvent>
    ) => {
      preventSelection(e);
    };

    const handleExpansionClick = (
      event: React.MouseEvent<HTMLDivElement, MouseEvent>
    ) => {
      handleExpansion(event);
    };

    const handleSelectionClick = (
      event: React.MouseEvent<HTMLDivElement, MouseEvent>
    ) => {
      handleSelection(event);
    };

    const handleClassName = () => {
      if (expanded) return classes.expanded;
      if (selected) return classes.selected;
      if (focused) return classes.focused;
      if (disabled) return classes.disabled;
      return '';
    };

    return (
      // eslint-disable-next-line jsx-a11y/no-static-element-interactions
      <div
        className={`${className} ${classes.root} ${handleClassName()}`}
        onMouseDown={handleMouseDown}
        ref={ref as React.Ref<HTMLDivElement>}
      >
        {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */}
        <div className={classes.iconContainer} onClick={handleExpansionClick}>
          {icon}
        </div>
        <Typography
          className={classes.label}
          component="div"
          onClick={handleSelectionClick}
        >
          {label}
        </Typography>
      </div>
    );
  }
);

const CustomTreeItem = React.forwardRef(
  (props: TreeItemProps, ref: React.Ref<HTMLLIElement>) => (
    <TreeItem ContentComponent={CustomTreeItemContent} {...props} ref={ref} />
  )
);

export default CustomTreeItem;
