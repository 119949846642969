import { Box, SxProps, Theme, Typography } from '@mui/material';
import {
  ETOButton,
  GenericDialog,
  MessageContext,
  YesNoConfirmDialog,
  useDebounce,
} from '@teto/react-component-library-v2';
import dayjs, { Dayjs } from 'dayjs';
import { useAtom, useSetAtom } from 'jotai';
import React, {
  Dispatch,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { getGraphQLClient } from 'teto-client-api';
import {
  CurrencyRate,
  Maybe,
  PurchaseOrderDetail,
  PurchaseOrderHeader,
  ReceivePurchaseOrderDetailInput,
  UpdatePurchaseOrderHeaderInput,
} from '../../../../../../../__generated__/graphql';
import { commonTermsQuery } from '../../../../../../../api/commonQueries';
import buildMutation from '../../../../../../../api/graphQL/buildMutation';
import getErrors from '../../../../../../../api/graphQL/getErrors';
import AuthContext from '../../../../../../../contexts/AuthContext';
import SettingsContext from '../../../../../../../contexts/SettingsContext';
import CreatePOFormLayout from '../../../../../../../forms/CreatePurchaseOrderForm.yaml';
import CommonGridSupplierSelectField from '../../../../../../InputFields/CommonGridSupplierSelectField/CommonGridSupplierSelectField';
import ShipToInputField from '../../../../../../InputFields/ShipToInputField/ShipToInputField';
import { BreadCrumbSharedState } from '../../../../../../SharedStateComponents/StateContainers/BreadCrumbModalState';
import { PurchaseOrderSharedState } from '../../../../../../SharedStateComponents/StateContainers/PurchaseOrderState';
import SaveStatusState from '../../../../../../SharedStateComponents/StateContainers/SaveStatusState';
import EditableCustomFieldFormBuilderExtension from '../../../../../../TETOForms/FormBuilder/EditableCustomFieldFormBuilderExtension';
import FormBuilder from '../../../../../../TETOForms/FormBuilder/FormBuilder';
import { graphQLResponseFormatter } from '../../../../../../TETOForms/FormBuilder/GraphQLExtensions';
import FormDefinition from '../../../../../../TETOForms/FormDefinition';
import TETOForm from '../../../../../../TETOForms/TETOForm';
import {
  RANGE,
  STRING_LENGTH,
} from '../../../../../../TETOForms/clientSideValidators';
import useForm from '../../../../../../TETOForms/hooks/useForm';
import { useFormBuilder } from '../../../../../../TETOForms/hooks/useFormBuilder';
import {
  Tax1UpdateState,
  Tax2UpdateState,
} from '../../../../components/POTaxSettingUpdateState';
import convertPODetailPrice from '../../../../queries/convertPODetailPriceMutation';
import currenciesQuery from '../../../../queries/getPOCurrencyQuery';
import getPODetailCurrentRateQuery from '../../../../queries/getPODetailCurrentRateQuery';
import employeesQuery from '../../../../queries/getPOHeaderEmployeeQuery';
import shipViaQuery from '../../../../queries/getPOShipViasQuery';
import poDropShipmentsBypassReceivingMutation from '../../../../queries/poDropShipmentsBypassReceivingMutation';
import { updatePOHeaderMutation } from '../../../../queries/updatePOHeaderMutation';
import updatePurchaseOrderDate from '../../../../queries/updatePurchaseOrderDateMutation';
import { PurchaseOrder } from '../../../../types/PurchaseOrder';
import { TaxSettingsUpdate } from '../../../../types/TaxSettingsUpdate';
import SpecialNotesSelectField from '../../PurchaseOrderNotes/SpecialNotes/SpecialNotesSelectField';
import FobSelectField from '../InputsFields/FobSelectField';

const formWrapperSx = {
  margin: 0,
};
const notesAccordionLineFixSx: SxProps<Theme> = {
  mb: 1,
  '& .MuiPaper-root-MuiAccordion-root:before': {
    height: 0,
  },
  '& .MuiPaper-root': {
    backgroundColor: 'unset',
  },
  '& .MuiAccordionSummary-root, .MuiAccordionDetails-root': {
    minHeight: (theme) => `${theme.spacing(4)} !important`,
  },
};

const buttonLayoutSx: SxProps<Theme> = {
  alignItems: 'center',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-end',
  width: '100%',
  columnGap: 2,
};

const contentSx: SxProps<Theme> = {
  alignItems: 'center',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  width: '100%',
  rowGap: 2,
};

const gridInputSx: SxProps<Theme> = {
  pb: { xs: 2.5, md: 'unset' },
};

interface POEditHeaderFormProps {
  purchaseOrder: Partial<PurchaseOrder>;
  disabled: boolean;
  taxUpdate?: TaxSettingsUpdate;
  setRefreshTotal: Dispatch<SetStateAction<boolean>>;
  // eslint-disable-next-line no-unused-vars
  setResetGrid: (value: boolean) => void;
  // eslint-disable-next-line no-unused-vars
  setPurchaseOrder: Dispatch<SetStateAction<Partial<PurchaseOrder>>>;
  setTaxUpdate: Dispatch<SetStateAction<TaxSettingsUpdate | undefined>>;
  // eslint-disable-next-line no-unused-vars
  setHasASaveOccurred: (val: boolean) => void;
  hasInventoryItem: boolean;
  // eslint-disable-next-line no-unused-vars
  onAltReportSelected?: (val: number | undefined) => void;
}

interface DateField {
  name?: string; // 'dateRequired' | 'dateRevised';
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  value?: Dayjs | any;
}

export interface DialogProps {
  content: string;
  // eslint-disable-next-line no-unused-vars
  onYes?: (data: DateField) => void;
  // eslint-disable-next-line no-unused-vars
  onNo?: (data?: DateField) => void;
  title: string;
}

interface NewCurrencyRate {
  value: Partial<CurrencyRate>;
  updateCurrency?: boolean;
}

const POEditHeaderForm = (props: POEditHeaderFormProps) => {
  const {
    hasInventoryItem,
    disabled,
    purchaseOrder,
    setHasASaveOccurred,
    setPurchaseOrder,
    setRefreshTotal,
    setResetGrid,
    setTaxUpdate,
    taxUpdate,
    onAltReportSelected,
  } = props;

  const { t } = useTranslation();
  const authContext = useContext(AuthContext);
  const settingsContext = useContext(SettingsContext);
  const messageContext = useContext(MessageContext);
  const [tax1Title] = useAtom(Tax1UpdateState);
  const [tax2Title] = useAtom(Tax2UpdateState);

  const setTax1UpdateNames = useSetAtom(Tax1UpdateState);
  const setTax2UpdateNames = useSetAtom(Tax2UpdateState);
  const setSaveState = useSetAtom(SaveStatusState);
  const setLevels = useSetAtom(BreadCrumbSharedState);
  const [pOSharedState] = useAtom(PurchaseOrderSharedState);

  const [openDateChangeDialog, setOpenDateChangeDialog] = useState<DateField>();
  const [purchaseOrderDetails, setPurchaseOrderDetails] = useState<
    Maybe<PurchaseOrderDetail>[] | undefined
  >();
  const [reusableDialog, setReusableDialog] = useState<DateField>();
  const reusableDialogThrottled = useDebounce(reusableDialog, 2000);
  const [newCurrencyRate, setNewCurrencyRate] = useState<
    NewCurrencyRate | undefined
  >();

  const [oldRate, setOldRate] = useState<{
    shouldUpdate: boolean;
    value?: number;
  }>({
    shouldUpdate: true,
    value: undefined,
  });

  useEffect(() => {
    if (oldRate.shouldUpdate) {
      setOldRate({
        shouldUpdate: false,
        value: purchaseOrder.purchaseCurrRate,
      });
    }
  }, [oldRate.shouldUpdate, purchaseOrder.purchaseCurrRate]);

  useEffect(() => {
    if (
      (purchaseOrder.purchaseOrderDetails?.[0] &&
        Object.keys(purchaseOrder.purchaseOrderDetails[0]).length) ||
      (purchaseOrder.purchaseOrderDetails &&
        !purchaseOrder.purchaseOrderDetails.length)
    ) {
      setPurchaseOrderDetails(purchaseOrder.purchaseOrderDetails);
    }
  }, [purchaseOrder.purchaseOrderDetails]);

  const getCurrencyDetails = useCallback(
    (name: string) =>
      getGraphQLClient().performQuery(getPODetailCurrentRateQuery, { name }),
    []
  );

  useEffect(() => {
    if (purchaseOrder) {
      setTax1UpdateNames(purchaseOrder?.purchaseTax1Name as string);
      setTax2UpdateNames(purchaseOrder?.purchaseTax2Name as string);

      if (pOSharedState?.fromRFQ) {
        const { baseCurrencyName } = settingsContext.settings;
        if (
          baseCurrencyName !== purchaseOrder?.purchaseCurr?.name &&
          purchaseOrder?.totals?.detailTotal !== 0
        ) {
          getCurrencyDetails(baseCurrencyName as string).then((d) => {
            if (d.hasError()) return d.handleAllErrors(messageContext.setError);
            if (d.hasData() && d.data)
              setReusableDialog({
                name: 'rfQCurrency',
                value: d.data?.currencyRates?.items?.[0]?.rate,
              });
          });
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onErrors = useCallback((errors: any) => {
    messageContext.setError(getErrors(errors));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const poHeaderForm = useFormBuilder('edit-po-header', (fbi) =>
    fbi
      .addInteger({
        disabled,
        name: 'purchaseRev',
        title: t('entities:PurchaseOrderHeader.PurchaseRev'),
      })
      .addString({
        disabled,
        name: 'purchaseTerms',
        title: t('entities:PurchaseOrderHeader.PurchaseTerms'),
        selectSource: {
          allowUnknownEntry:
            !settingsContext.settings.limitAccountingTermsToList,
          fromGraphQLQuery: commonTermsQuery,
          label: 'description',
          value: 'description',
        },
        validators: [STRING_LENGTH(255)],
      })
      .addInteger({
        disabled,
        name: 'buyerId',
        title: t('entities:PurchaseOrderHeader.Buyer'),
        selectSource: {
          fromGraphQLQuery: employeesQuery,
          label: 'name',
          value: 'id',
        },
        required: true,
        defaultValue: authContext.user?.id,
      })
      .addInteger({
        hidden: true,
        name: 'purchaseTax2GLAccountId',
        title: 'na',
        defaultValue: '',
      })
      .addInteger({
        hidden: true,
        name: 'purchaseTax1GLAccountId',
        title: 'na',
        defaultValue: '',
      })
      .addInteger({
        disabled,
        name: 'purchaseSupplier',
        title: t('entities:PurchaseOrderHeader.PurchaseSupplier'),
        required: true,
        defaultValue: purchaseOrder.purchaseSupplierId,
        render: (form, field, options) => (
          <CommonGridSupplierSelectField
            disabled={options.disabled}
            error={options.error}
            fieldName="purchaseSupplier"
            handleChange={(val) => {
              if (onAltReportSelected)
                onAltReportSelected(
                  val?.target?.otherInfo?.company?.supplier?.poreportAlternateId
                );

              form.updateField(field.name, val.target.value);
              if (
                val.target.value &&
                val?.target?.otherInfo?.company?.supplier?.defaultCurrNameId !==
                  form.values?.purchaseCurrId
              ) {
                if (
                  !val.target?.otherInfo?.company?.supplier?.defaultCurrNameId
                ) {
                  return messageContext.setError(
                    t(
                      'pages.purchaseOrders.purchaseOrderModal.missingSupplierCurrRate'
                    )
                  );
                }
                setReusableDialog({
                  name: 'currencySupplier',
                  value:
                    val.target.otherInfo.company.supplier.defaultCurrNameId,
                });
              }
            }}
            label={t('entities:PurchaseOrderHeader.PurchaseSupplier')}
            shouldHaveWarning={
              settingsContext.settings.notApprovedSupplierWarningPO
            }
            sx={gridInputSx}
            value={options.value}
          />
        ),
      })
      .addString({
        disabled,
        name: 'purchaseShip',
        title: t('entities:PurchaseOrderHeader.PurchaseShip'),
        selectSource: {
          allowUnknownEntry:
            !settingsContext.settings.limitShippingAgentsToList,
          fromGraphQLQuery: shipViaQuery,
          label: 'shipperName',
          value: 'shipperName',
        },
        validators: [STRING_LENGTH(255)],
      })
      .addString({
        disabled,
        name: 'purchaseCurr',
        title: t('entities:PurchaseOrderHeader.PurchaseCurr'),
        selectSource: {
          fromGraphQLQuery: currenciesQuery,
          label: 'name',
          value: 'name',
        },
        required: true,
        defaultValue: settingsContext.settings.baseCurrencyName,
      })
      .addInteger({
        disabled:
          (!settingsContext.settings.dropShipmentsForInventory &&
            hasInventoryItem) ||
          disabled,
        name: 'purchaseShipTo',
        title: t('entities:PurchaseOrderHeader.PurchaseShipTo'),
        validators: [STRING_LENGTH(255)],
        defaultValue: 1, // should default to their own company id which is always 1
        render: (form, field, options) => (
          <ShipToInputField
            customSx={gridInputSx}
            disabled={options.disabled}
            error={options.error}
            fieldName="purchaseShipTo"
            handleChange={(val) => {
              if (
                settingsContext.settings?.dropShipmentsBypassReceiving === 'Ask'
              ) {
                setReusableDialog({
                  name: field.name,
                  value: val.target.value,
                });
              } else if (
                settingsContext.settings?.dropShipmentsBypassReceiving ===
                'Always'
              ) {
                form.updateField(field.name, val.target.value);
                _handleByPassReceivingOnClose();
              } else form.updateField(field.name, val.target.value);
            }}
            label={t('entities:PurchaseOrderHeader.PurchaseShipTo')}
            value={options.value}
          />
        ),
      })
      .addString({
        disabled,
        name: 'purchaseFob',
        title: t('entities:PurchaseOrderHeader.PurchaseFob'),
        // eslint-disable-next-line no-unused-vars
        render: (form, field, options) => (
          <FobSelectField
            disabled={options.disabled}
            error={options.error}
            handleChange={(val) =>
              form.updateField(field.name, val.target.value)
            }
            sx={gridInputSx}
            value={options.value}
          />
        ),
        validators: [STRING_LENGTH(255)],
        defaultValue: purchaseOrder.purchaseFob,
      })
      .addDecimal({
        disabled,
        name: 'purchaseCurrRate',
        title: t('entities:PurchaseOrderHeader.PurchaseCurrRate'),
        required: true,
      })
      .addDate({
        disabled,
        name: 'purchaseDate',
        title: t('entities:PurchaseOrderHeader.PurchaseDate'),
        required: true,
      })
      .addDate({
        disabled,
        name: 'purchaseDateRevised',
        title: t('entities:PurchaseOrderHeader.PurchaseRevDate'),
      })
      .addDate({
        disabled,
        name: 'purchaseDateRequired',
        title: t('entities:PurchaseOrderHeader.PurchaseDateRequired'),
      })
      .addString({
        disabled,
        name: 'fobnotes',
        title: t('entities:PurchaseOrderHeader.Fobnotes'),
        validators: [STRING_LENGTH(50)],
      })

      .addDecimal({
        disabled,
        name: 'purchaseTax1',
        title:
          (purchaseOrder?.purchaseTax1Name as string) ??
          t('entities:PurchaseOrderHeader.PurchaseTax1Name'),
        hints: ['PERCENTAGE'],
        required: true,
        defaultValue: 0,
        validators: [RANGE(0, 100)],
      })
      .addDecimal({
        disabled,
        name: 'purchaseTax2',
        title:
          (purchaseOrder?.purchaseTax2Name as string) ??
          t('entities:PurchaseOrderHeader.PurchaseTax2Name'),
        defaultValue: 0,
        validators: [RANGE(0, 100)],
        hints: ['PERCENTAGE'],
      })
      .addString({
        disabled,
        name: 'port',
        title: t('entities:PurchaseOrderHeader.Port'),
        validators: [STRING_LENGTH(50)],
      })
      .addDecimal({
        disabled,
        name: 'purchaseMisc',
        title: t('entities:PurchaseOrderHeader.PurchaseMisc'),
        defaultValue: 0,
        required: true,
      })
      .addBoolean({
        disabled,
        name: 'compoundTax',
        title: t('entities:PurchaseOrderHeader.CompoundTax'),
        required: true,
      })
      .extension(
        new EditableCustomFieldFormBuilderExtension(
          'Purchase Order Header',
          'entities:PurchaseOrderHeader.PurchaseOrderHeaderCustom',
          'Purchase Order Header Custom',
          disabled
        ),
        t
      )
      .addBoolean({
        disabled,
        name: 'purchasePrinted',
        title: t('entities:PurchaseOrderHeader.PurchasePrinted'),
        defaultValue: false,
        required: true,
      })
      .addBoolean({
        disabled,
        name: 'purchaseEmailed',
        title: t('entities:PurchaseOrderHeader.PurchaseEmailed'),
        defaultValue: false,
        required: true,
      })
      .addString({
        disabled,
        name: 'purchaseNotes',
        title: t('pages.purchaseOrders.purchaseOrderModal.topNotes'),
        hints: ['MULTI-LINE'],
      })
      .addString({
        disabled,
        name: 'purchaseNotes2',
        title: t('pages.purchaseOrders.purchaseOrderModal.bottomNotes'),
        hints: ['MULTI-LINE'],
      })
      .addString({
        disabled,
        name: 'purchaseNotes3',
        title: t('entities:PurchaseOrderHeader.PurchaseTerms2'),
        render: (form, field, options) => (
          <SpecialNotesSelectField
            disabled={options.disabled}
            error={options.error}
            onBlur={options.onBlur}
            onChange={(e) => form.updateField(field.name, e.target.value)}
            value={options.value}
          />
        ),
      })
  );

  const _handleRequest = useCallback(
    (
      // eslint-disable-next-line no-shadow
      fb: FormBuilder<Record<string, unknown>>,
      fd: FormDefinition,
      data: Record<string, unknown>
    ) => {
      const {
        purchaseCurr,
        purchaseShipTo,
        purchaseSupplier,
        buyerId,
        purchaseDate,
        purchaseEmailed,
        purchaseCurrRate,
        compoundTax,
        custom7,
        custom8,
        purchaseDateRevised,
        purchaseDateRequired,
        ...rest
      } = data;
      const formattedData: UpdatePurchaseOrderHeaderInput = {
        ...rest,
        purchaseCurrId: purchaseCurr as string,
        purchaseShipToId: purchaseShipTo as number,
        purchaseSupplierId: purchaseSupplier as number,
        buyerId: buyerId as number,
        compoundTax: compoundTax as boolean,
        custom7: (custom7 as boolean) ?? false,
        custom8: (custom8 as boolean) ?? false,
        id: purchaseOrder.id as number,
        purchaseCurrRate,
        purchaseDate,
        purchaseEmailed: false,
        purchaseDateRevised,
        purchaseDateRequired,
        tax1Name: tax1Title,
        tax2Name: tax2Title,
      };
      return formattedData;
    },
    [purchaseOrder.id, tax1Title, tax2Title]
  );

  const _handleSubmit = useCallback(
    async (
      fb: FormBuilder<PurchaseOrderHeader>,
      fd: FormDefinition,
      data: UpdatePurchaseOrderHeaderInput
    ) => {
      if (taxUpdate) {
        setTaxUpdate(undefined);
      }

      const formattedData = {
        ...data,
        purchaseCurrId: newCurrencyRate?.value?.name ?? data?.purchaseCurrId,
        purchaseCurrRate:
          newCurrencyRate?.value?.rate ?? data?.purchaseCurrRate,
        purchaseShipToId:
          data?.purchaseShipToId ?? purchaseOrder?.purchaseShipToId,
      };

      const respData = await getGraphQLClient()
        .performMutation(updatePOHeaderMutation, {
          input: formattedData,
        })
        .then((d) => {
          if (d.hasError()) {
            if (d.systemErrors) onErrors(d.systemErrors);
            if (d.validationErrors) onErrors(d.validationErrors);
            return;
          }

          if (d.hasData()) {
            setSaveState((prev) => ({
              ...prev,
              hasSavedOccurred: true,
            }));
            setNewCurrencyRate(undefined);
            setPurchaseOrder(
              d?.data?.updatePurchaseOrderHeader as PurchaseOrder
            );
            setResetGrid(true);
            setRefreshTotal(true);
            return d;
          }
        });

      return Promise.resolve(respData);
    },
    [
      newCurrencyRate?.value?.name,
      newCurrencyRate?.value?.rate,
      onErrors,
      purchaseOrder?.purchaseShipToId,
      setPurchaseOrder,
      setRefreshTotal,
      setResetGrid,
      setSaveState,
      setTaxUpdate,
      taxUpdate,
    ]
  );

  const _handleDateChange = useCallback(
    // eslint-disable-next-line no-unused-vars
    (dateChange: DateField, option: boolean | 'none') => {
      setOpenDateChangeDialog(undefined);

      if (option !== 'none') {
        getGraphQLClient()
          .performMutation(updatePurchaseOrderDate, {
            input: {
              purchaseOrderId: purchaseOrder.id as number,
              updateAllDetailRecords: option,
              useDateRequired: dateChange.name === 'dateRequired',
              useDateRevised: dateChange.name === 'dateRevised',
            },
          })
          .then((d) => {
            if (d.hasError()) {
              if (d.systemErrors) onErrors(d.systemErrors);
              if (d.validationErrors) onErrors(d.validationErrors);
              return;
            }
            if (
              d.hasData() &&
              (d.data.updatePurchaseOrderDetailDates?.items?.length ?? 0) > 0
            ) {
              setResetGrid(true);
              setRefreshTotal(true);
              setSaveState((prev) => ({
                ...prev,
                hasSavedOccurred: true,
              }));
            }
          });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [purchaseOrder.id, setResetGrid]
  );
  const editForm = useForm(
    poHeaderForm,
    {
      onErrors,
      onFormatRequest: (fb, fd, data) => _handleRequest(fb, fd, data),
      onFormatResponse: graphQLResponseFormatter(),
      onSubmitting: (fb, fd, data) => _handleSubmit(fb, fd, data),
      onSubmitted: (submitContext) => {
        if (submitContext.success) {
          const updatedData = submitContext.output.updatePurchaseOrderHeader;

          if (
            !dayjs(purchaseOrder.purchaseDateRequired).isSame(
              dayjs(updatedData?.purchaseDateRequired)
            )
          ) {
            const val = submitContext.values.purchaseDateRequired;
            const data = { name: 'dateRequired', value: val } as DateField;
            if (val) setOpenDateChangeDialog(data);
            else setReusableDialog(data);
            setResetGrid(true);
          }
          if (
            !dayjs(purchaseOrder.purchaseDateRevised).isSame(
              dayjs(updatedData?.purchaseDateRevised)
            )
          ) {
            const val = updatedData?.purchaseDateRevised;
            const data = { name: 'dateRevised', value: val } as DateField;
            if (val) setOpenDateChangeDialog(data);
            else setReusableDialog(data);
            setResetGrid(true);
          }

          if (
            purchaseOrder.compoundTax !== updatedData?.compoundTax ||
            purchaseOrder.purchaseTax1 !== updatedData?.purchaseTax1 ||
            purchaseOrder.purchaseTax2 !== updatedData?.purchaseTax2
          ) {
            setResetGrid(true);
            setRefreshTotal(true);
          }
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          setPurchaseOrder((prev: Partial<PurchaseOrder>) => ({
            ...prev,
            ...updatedData,
          }));
          setHasASaveOccurred(true);
          setSaveState((prev) => ({
            ...prev,
            hasSavedOccurred: true,
          }));
        }
        return Promise.resolve();
      },
      onFieldChange(field, value) {
        if (
          purchaseOrder.purchaseCurrId !== value &&
          field.name === 'purchaseCurr'
        ) {
          setReusableDialog({
            name: 'currency',
            value,
          });
          setResetGrid(true);
          setRefreshTotal(true);
        }
        if (
          purchaseOrder.purchaseCurrRate !== value &&
          field.name === 'purchaseCurrRate'
        ) {
          setReusableDialog({
            name: 'currencyRate',
            value,
          });
          setResetGrid(true);
          setRefreshTotal(true);
        }
      },
    },
    {
      ...purchaseOrder,
      purchaseShipTo: purchaseOrder.purchaseShipTo?.id ?? 1,
      purchaseSupplier: purchaseOrder.purchaseSupplierId,
      purchaseCurr: purchaseOrder.purchaseCurr?.name,
    },
    { autoSave: true }
  );

  const _handleCurrencyUpdate = useCallback(
    (data: DateField, updateCurrency?: boolean) => {
      const detail = { ...data };
      setOpenDateChangeDialog(undefined);

      getGraphQLClient()
        .performQuery(getPODetailCurrentRateQuery, { name: detail.value })
        .then((d) => {
          if (d.hasError()) {
            if (d.systemErrors) onErrors(d.systemErrors);
            if (d.validationErrors) onErrors(d.validationErrors);
            return;
          }
          if (d.hasData()) {
            const value = d.data?.currencyRates?.items?.[0];
            if (value) {
              setNewCurrencyRate({ value, updateCurrency });
              editForm.updateField('purchaseCurrRate', value?.rate);
            }
            setResetGrid(true);
            setSaveState((prev) => ({
              ...prev,
              hasSavedOccurred: true,
            }));
          }
        });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [editForm, onErrors, purchaseOrder.purchaseShipToId, setResetGrid]
  );

  const _handleConvertPORate = useCallback(
    (newRate: number, prevRate: number) => {
      getGraphQLClient()
        .performMutation(convertPODetailPrice, {
          purchaseOrderId: purchaseOrder.id as number,
          newRate,
          oldRate: prevRate,
        })
        .then((r) => {
          if (r.hasError()) {
            if (r.systemErrors) onErrors(r.systemErrors);
            if (r.validationErrors) onErrors(r.validationErrors);
            return;
          }
          if (r.hasData()) {
            setOldRate({ shouldUpdate: false, value: newRate });
            setResetGrid(true);
            setRefreshTotal(true);
            setSaveState((prev) => ({
              ...prev,
              hasSavedOccurred: true,
            }));
          }
        });
    },
    [onErrors, purchaseOrder.id, setRefreshTotal, setResetGrid, setSaveState]
  );

  const _handleCurrencyChange = useCallback(
    (data: DateField) => {
      switch (data.name) {
        case 'currency':
          _handleCurrencyUpdate(data);
          break;
        case 'currencySupplier':
          _handleCurrencyUpdate(data, true);
          break;
        case 'currencyRate':
          setOpenDateChangeDialog(undefined);
          _handleConvertPORate(data?.value, oldRate?.value as number);
          break;

        default:
          break;
      }
    },
    [_handleConvertPORate, _handleCurrencyUpdate, oldRate?.value]
  );

  const _handleCurrencyNo = useCallback(() => {
    setOldRate((d) => ({ ...d, shouldUpdate: true }));
    setResetGrid(true);
    setRefreshTotal(true);
  }, [setRefreshTotal, setResetGrid]);

  const _handleByPassReceiving = useCallback(
    (onClose: () => void) => {
      if (purchaseOrderDetails) {
        buildMutation({
          queryString: poDropShipmentsBypassReceivingMutation,
          variables: {
            purchaseOrderId: purchaseOrder.id as number,
            receiveDate: dayjs().format(settingsContext.settings.dateFormat),
            itemsToReceive: purchaseOrderDetails.map(
              (item): ReceivePurchaseOrderDetailInput => ({
                itemId: item?.itemId as number,
                toReceive: item?.purchaseQty as number,
                numberOfLabels: 0,
                purchaseOrderDetailId: item?.id as number,
              })
            ),
          },
          callback: () => {
            onClose();
          },
          errorCallback: (e) => {
            e.handleAllErrors(messageContext.setError);
            if (e.hasValidationErrors()) {
              messageContext.setError(getErrors(e.validationErrors));
            }
          },
          messageContext,
        });
      } else {
        messageContext.setError(
          `No ${t(
            'entities:PurchaseOrderDetail.PurchaseOrderDetail'
          )}s found for ${t('entities:PurchaseOrder.PurchaseOrder')} ${
            purchaseOrder.id
          }`
        );
      }
    },
    [
      messageContext,
      purchaseOrder.id,
      purchaseOrderDetails,
      settingsContext.settings.dateFormat,
      t,
    ]
  );
  const _handleByPassReceivingOnClose = useCallback(() => {
    setLevels((level) =>
      level.map((i) =>
        i.name === `Purchase Order: ${purchaseOrder.id}`
          ? {
              ...i,
              onLevelClose: (onClose) => {
                _handleByPassReceiving(onClose);
              },
            }
          : i
      )
    );
  }, [_handleByPassReceiving, purchaseOrder.id, setLevels]);

  const dialogs: { [key: string]: DialogProps } = useMemo(
    () => ({
      currency: {
        title: t(
          'pages.purchaseOrders.purchaseOrderModal.currencyChangeTitle',
          { currency: t('entities:PartsOrderHeader.CurrencyValue') }
        ),
        content: t(
          'pages.purchaseOrders.purchaseOrderModal.currencyChangeContent'
        ),
        onYes: (v: DateField) => _handleCurrencyChange(v), // currency change
        onNo: () => _handleCurrencyNo(),
      },
      currencySupplier: {
        title: t(
          'pages.purchaseOrders.purchaseOrderModal.currencySupplierChangeTitle'
        ),
        content: t(
          'pages.purchaseOrders.purchaseOrderModal.currencySupplierChangeContent'
        ),
        onYes: (v: DateField) => _handleCurrencyChange(v), // currency change
        onNo: () => _handleCurrencyNo(),
      },
      currencyRate: {
        title: t(
          'pages.purchaseOrders.purchaseOrderModal.currencyChangeTitle',
          { currency: t('entities:CurrencyRate.CurrencyRate') }
        ),
        content: t(
          'pages.purchaseOrders.purchaseOrderModal.currencyRateChangeContent'
        ),
        onYes: (v: DateField) => _handleCurrencyChange(v),
        onNo: () => editForm.updateField('', purchaseOrder?.purchaseCurrRate),
      },
      rfQCurrency: {
        title: t(
          'pages.purchaseOrders.purchaseOrderModal.currencyDiffAlertTitle'
        ),
        content: t(
          'pages.purchaseOrders.purchaseOrderModal.currencyDiffAlertContent',
          {
            currency: purchaseOrder?.purchaseCurr?.name,
            rate: purchaseOrder?.purchaseCurrRate,
          }
        ),
        onYes: (v: DateField) =>
          _handleConvertPORate(purchaseOrder?.purchaseCurrRate, v.value),
      },
      dateRequired: {
        content: t(
          'pages.purchaseOrders.purchaseOrderModal.dateEmptyChangeContent',
          {
            type: t('entities:PurchaseOrderHeader.PurchaseDateRequired'),
          }
        ),
        title: t('pages.purchaseOrders.purchaseOrderModal.dateChangeTitle', {
          type: t('entities:PurchaseOrderHeader.PurchaseDateRequired'),
        }),
        onYes: (v: DateField) => _handleDateChange(v, true),
      },
      dateRevised: {
        content: t(
          'pages.purchaseOrders.purchaseOrderModal.dateEmptyChangeContent',
          {
            type: t('entities:PurchaseOrderHeader.PurchaseRevDate'),
          }
        ),
        title: t('pages.purchaseOrders.purchaseOrderModal.dateChangeTitle', {
          type: t('entities:PurchaseOrderHeader.PurchaseRevDate'),
        }),
        onYes: (v: DateField) => _handleDateChange(v, true),
      },
      purchaseShipTo: {
        content: t(
          'pages.purchaseOrders.purchaseOrderModal.dropShipmentByPassContent'
        ),
        title: t(
          'pages.purchaseOrders.purchaseOrderModal.currencyChangeTitle',
          {
            currency: t('entities:PurchaseOrderHeader.PurchaseShipTo'),
          }
        ),
        onYes: (d) => {
          editForm.updateField(d.name as string, d.value);
          _handleByPassReceivingOnClose();
          setReusableDialog(undefined);
        },
      },
    }),
    [
      _handleByPassReceivingOnClose,
      _handleConvertPORate,
      _handleCurrencyChange,
      _handleCurrencyNo,
      _handleDateChange,
      editForm,
      purchaseOrder?.purchaseCurr?.name,
      purchaseOrder?.purchaseCurrRate,
      t,
    ]
  );

  const dateOptionButtons = useMemo(
    () => [
      {
        title: t('generic.all'),
        onClick: (n: DateField) => _handleDateChange(n, true),
      },
      {
        title: t('pages.purchaseOrders.purchaseOrderModal.emptyOnly'),
        onClick: (n: DateField) => _handleDateChange(n, false),
      },
      {
        title: t('generic.none'),
        onClick: (n: DateField) => _handleDateChange(n, 'none'),
      },
    ],
    [_handleDateChange, t]
  );

  const dialogProps = useMemo(() => {
    const name = reusableDialogThrottled?.name || '';

    return reusableDialogThrottled
      ? dialogs?.[name]
      : { content: '', title: '', onYes: () => ({}) };
  }, [dialogs, reusableDialogThrottled]);

  useEffect(() => {
    if (taxUpdate) {
      setTax1UpdateNames(taxUpdate?.purchaseTax1Name as string);
      setTax2UpdateNames(taxUpdate?.purchaseTax2Name as string);
      [
        'purchaseTax1',
        'purchaseTax2',
        'compoundTax',
        'purchaseTax1GLAccountId',
        'purchaseTax2GLAccountId',
        'purchaseTax1Name',
        'purchaseTax2Name',
      ].forEach((i) => {
        if (
          taxUpdate[i as keyof TaxSettingsUpdate] !==
          editForm.values[i as keyof TaxSettingsUpdate]
        )
          editForm.updateField(i, taxUpdate[i as keyof TaxSettingsUpdate]);
      });
    }
  }, [
    editForm,
    setHasASaveOccurred,
    setTax1UpdateNames,
    setTax2UpdateNames,
    setTaxUpdate,
    taxUpdate,
  ]);

  return (
    <Box sx={notesAccordionLineFixSx}>
      <TETOForm
        collapsible
        form={editForm}
        formLayout={CreatePOFormLayout}
        formWrapperSx={formWrapperSx}
      />

      {openDateChangeDialog && (
        <GenericDialog
          isOpen={Boolean(openDateChangeDialog)}
          onClose={() => setOpenDateChangeDialog(undefined)}
          title={t('pages.purchaseOrders.purchaseOrderModal.dateChangeTitle', {
            type: openDateChangeDialog?.name?.slice(4),
          })}
        >
          <Box sx={contentSx}>
            <Typography>
              {t('pages.purchaseOrders.purchaseOrderModal.dateChangeContent', {
                type: openDateChangeDialog?.name?.slice(4).toLowerCase(),
              })}
            </Typography>
            <Box sx={buttonLayoutSx}>
              {dateOptionButtons.map((i) => (
                <ETOButton
                  color="primary"
                  key={i.title}
                  onClick={() => i.onClick(openDateChangeDialog)}
                  size="medium"
                >
                  {i.title}
                </ETOButton>
              ))}
            </Box>
          </Box>
        </GenericDialog>
      )}
      {reusableDialogThrottled && (
        <YesNoConfirmDialog
          content={dialogProps?.content}
          onClose={() => {
            dialogProps?.onNo?.(reusableDialog);
            setReusableDialog(undefined);
          }}
          onNo={() => {
            dialogProps?.onNo?.(reusableDialogThrottled);
            setReusableDialog(undefined);
          }}
          onYes={() => {
            setReusableDialog(undefined);
            dialogProps?.onYes?.(reusableDialogThrottled);
          }}
          open={Boolean(reusableDialogThrottled)}
          title={dialogProps?.title}
        />
      )}
    </Box>
  );
};

export default POEditHeaderForm;
