import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import getView, { getMetadataForView } from '../../../api/metadata/viewManager';
import {
  GridBuilder,
  createViewFromViewDefinition,
  createViewFromMetadataView,
} from '../GridBuilder/GridBuilder';
import ViewDefinition from '../../../views/ViewDefinitions';

export const useGridBuilder = (
  rootQueryName: string,
  // eslint-disable-next-line no-unused-vars
  builder?: (gridBuilder: GridBuilder) => void,
  createFactory?: () => Promise<GridBuilder>
) => {
  const gridBuilderFactoryRef = useRef<GridBuilder | undefined>();
  const { ready } = useTranslation();
  const [status, setStatus] = useState<{
    builderReady: boolean;
    error?: string;
    hasError: boolean;
  }>({
    builderReady: false,
    hasError: false,
  });

  useEffect(() => {
    if (!ready) return;

    (!createFactory ? Promise.resolve(new GridBuilder()) : createFactory())
      .then((a) => {
        gridBuilderFactoryRef.current = a;
        if (builder) builder(a);
        gridBuilderFactoryRef.current.rootQueryPath = rootQueryName;
        setStatus({
          builderReady: true,
          hasError: false,
          error: undefined,
        });
      })
      .catch((e) => {
        setStatus({
          builderReady: true,
          hasError: true,
          error: e?.message ? e.message : e,
        });
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ready]);

  const result = {
    gridBuilder: gridBuilderFactoryRef.current,
    ...status,
  };

  return { ...result } as const;
};

export const useGridBuilderFromView = (
  viewName: ViewDefinition,
  rootQueryName: string,
  // eslint-disable-next-line no-unused-vars
  builder?: (gridBuilder: GridBuilder) => void
) => {
  const gridBuilder = useGridBuilder(rootQueryName, builder, () =>
    typeof viewName === 'string'
      ? getView(viewName).then((a) => createViewFromMetadataView(a))
      : getMetadataForView(viewName).then((a) =>
          createViewFromViewDefinition(viewName, a)
        )
  );

  return gridBuilder;
};
