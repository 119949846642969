import DragHandleIcon from '@mui/icons-material/DragHandle';
import { Box, IconButton } from '@mui/material';
import Tooltip from '@mui/material/Tooltip';
import React from 'react';
import { Size } from './DraggableListItem';

interface DragItemProps {
  title: string;
  size?: Size;
  disabled?: boolean;
}

const DragItem = ({ title, size, disabled }: DragItemProps) => (
  <Tooltip title={title}>
    <Box className="drag-handle">
      <IconButton disabled={disabled} size={size}>
        <DragHandleIcon color="primary" sx={{ cursor: 'grab' }} />
      </IconButton>
    </Box>
  </Tooltip>
);

export default DragItem;
