import dayjs from 'dayjs';
import { formatHoursAsHourMin } from './dateHelpers';
import { SettingsContextState } from '../contexts/SettingsContext';

// eslint-disable-next-line import/prefer-default-export
export const valueFormatHelper = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  col: any,
  settingsContext: SettingsContextState
) => {
  switch (col.type) {
    case 'boolean':
      return col.value ? 'True' : 'False';
    case 'date':
      return dayjs(col.value).format(settingsContext.settings.dateFormat);
    case 'datetime':
      return dayjs(col.value).format(settingsContext.settings.dateTimeFormat);
    case 'time':
      return dayjs(col.value).format(settingsContext.settings.timeFormat);
    case 'hours':
      return formatHoursAsHourMin(col.value);
    case 'currency':
      return `${settingsContext.settings.baseCurrencyFormatter.formatValue(
        col.value
      )}`;
    case 'number':
    case 'foreignKey':
    case 'string':
    case 'custom':
    default:
      return col.value;
  }
};
