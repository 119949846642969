import { Permission } from 'teto-client-api';

export const REQUIRED_MESSAGE = 'Required';
export const MIN_ONE_MESSAGE = 'must be greater or equal to 1';
export const MAX_QTY_AVAILABLE_MESSAGE =
  'must not be greater than Qty. Available';
export const MAXREASON = 2048;
export const REPORT_PERMISSIONS = [
  Permission.View_Legacy_Reports_Inventory_Cycle_Count_By_Bin_Label,
  Permission.View_Legacy_Reports_Inventory_Cycle_Count_By_Part_Number,
  Permission.View_Legacy_Reports_Inventory_QOH_Discrepancy,
  Permission.View_Legacy_Reports_Inventory_Stock_Reorder_Requirements,
  Permission.View_Legacy_Reports_Inventory_Stock_Summary,
  Permission.View_Legacy_Reports_Inventory_PullsReturns,
  Permission.View_Legacy_Reports_Inventory_Valuation,
  Permission.View_Legacy_Reports_Inventory_Inactivity,
  Permission.View_Legacy_Reports_Inventory_Valuation_By_Category,
  Permission.View_Legacy_Reports_Inventory_Items_To_Pull,
  Permission.View_Legacy_Reports_Inventory_Valuation_By_Part_Number,
  Permission.View_Legacy_Reports_InventorySnapshotDelta,
];

export const INVENTORY_METHODS = [
  {
    name: 'FIFO',
    id: 0,
  },
  {
    name: 'Last Purchase Price',
    id: 1,
  },
];
