import { YesNoConfirmDialog } from '@teto/react-component-library-v2';
import { useAtom } from 'jotai';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { gql } from '../../../__generated__';
import useGQLQuery from '../../../api/graphQL/useGQLQuery';
import AddEditInspector from '../../Inspectors/components/AddEditInspector/AddEditInspector';
import { TAddEditTabs } from '../../Inspectors/components/AddEditInspector/components/EditableTabs';
import useInspectorState from '../../Inspectors/hooks/useInspectorState';
import EditingState from '../../Inspectors/types/EditingState';
import { InspectorState } from '../../Inspectors/types/InspectorState';
import { PanelState } from '../../Inspectors/types/PanelState';
import { PurchaseOrderSharedState } from '../../SharedStateComponents/StateContainers/PurchaseOrderState';
import NotesIcon from './components/NotesIcon';
import PurchaseOrderDetailsPanel from './panels/PurchaseOrderDetailsPanel/PurchaseOrderDetailsPanel';
import PurchaseOrdersDocumentsPanel from './panels/PurchaseOrderDocumentsPanel/PurchaseOrderDocumentsPanel';
import PurchaseOrderNotesPanel from './panels/PurchaseOrderNotesPanel/PurchaseOrderNotesPanel';
import { PurchaseOrder } from './types/PurchaseOrder';
import { PurchaseOrderModalProps } from './types/PurchaseOrderModalProps';

type PurchaseOrderNotesType = {
  purchaseOrderNotes:
    | {
        items: ({ id: number } | null)[] | null | undefined;
      }
    | null
    | undefined;
};

const initialEditingState: EditingState = {
  isEditing: false,
  hasEdited: false,
  tabError: false,
};

const notesQuery = gql(`
query purchaseOrderNotesLength($id: Int!) {
  purchaseOrderNotes(where: { purchaseOrderId: { eq: $id } }) {
    items {
      id
    }
  }
}
`);

const PurchaseOrderModal = (props: PurchaseOrderModalProps) => {
  const { initialValues, open, onClose } = props;
  const [panelState, setPanelState] = useState<PanelState>({
    mode: initialValues?.id ? 'edit' : 'create',
    id: initialValues?.id,
  });
  const { t } = useTranslation();
  const [purchaseOrder, setPurchaseOrder] = useAtom(PurchaseOrderSharedState);
  const [confirmMissingDate, setConfirmMissingDate] = useState<boolean>(false);

  const initialState: InspectorState<PurchaseOrder | undefined> = useMemo(
    () => ({
      hasASaveOccurred: false,
      isCreatingNewItem: false,
      needsCloseConfirmation: false,
      editingState: initialEditingState,
      currentValues: undefined,
    }),
    []
  );

  const {
    state,
    setEditingState,
    setHasASaveOccurred,
    setNeedsCloseConfirmation,
    setIsCreatingNewItem,
  } = useInspectorState<Partial<PurchaseOrder> | undefined>(initialState);

  const {
    editingState,
    hasASaveOccurred,
    isCreatingNewItem,
    needsCloseConfirmation,
  } = state;

  useEffect(() => {
    if (
      !initialValues &&
      purchaseOrder?.initialValues?.id &&
      panelState.mode === 'create'
    ) {
      setPanelState({ mode: 'edit', id: purchaseOrder?.initialValues?.id });
    }
  }, [initialValues, panelState.mode, purchaseOrder?.initialValues?.id]);

  const _handleSaveOccurred = useCallback(
    (data: boolean) => {
      setHasASaveOccurred(data);
      setPurchaseOrder(() => ({
        ...purchaseOrder,
        hasSavedOccurred: data,
      }));
    },
    [purchaseOrder, setHasASaveOccurred, setPurchaseOrder]
  );

  const notes = useGQLQuery<PurchaseOrderNotesType>(
    ['purchaseOrderTotalNotes'],
    {
      queryString: notesQuery,
      variables: { id: panelState.id ?? -1 },
      options: {
        refetchOnWindowFocus: false,
        enabled: Boolean(panelState.id),
      },
    }
  );

  const tabs: TAddEditTabs = useMemo(
    () => [
      {
        tabName: 'details',
        tabLabel: t('generic.details'),
        tabIndex: 0,
        tabPanel: (
          <PurchaseOrderDetailsPanel
            editingState={editingState}
            initialValues={initialValues ?? purchaseOrder?.initialValues}
            panelMode={panelState.mode}
            setEditingState={setEditingState}
            setHasASaveOccurred={_handleSaveOccurred}
            setIsCreatingNewItem={setIsCreatingNewItem}
            setNeedsCloseConfirmation={setNeedsCloseConfirmation}
            setPanelState={setPanelState}
          />
        ),
        tabTitle: `${'generic.error'} In ${t('generic.details')} Form`,
      },
      ...(panelState.mode === 'edit'
        ? [
            {
              tabName: 'documents',
              tabLabel: `${t('generic.document')}s`,
              tabIndex: 1,
              tabPanel: (
                <PurchaseOrdersDocumentsPanel
                  editingState={editingState}
                  entityIdValue={panelState?.id}
                  setEditingState={setEditingState}
                  setHasASaveOccurred={_handleSaveOccurred}
                  setIsCreatingNewItem={setIsCreatingNewItem}
                />
              ),
              tabTitle: `${'generic.error'} in ${t('generic.document')}s`,
            },
            {
              tabName: 'notes',
              tabLabel: t(
                'pages.purchaseOrders.purchaseOrderModal.internalNotes'
              ),
              tabIndex: 2,
              tabPanel: (
                <PurchaseOrderNotesPanel
                  editingState={editingState}
                  entityIdValue={panelState?.id}
                  onUpdated={() => notes.refetch()}
                  setEditingState={setEditingState}
                  setHasASaveOccurred={_handleSaveOccurred}
                  setIsCreatingNewItem={setIsCreatingNewItem}
                />
              ),
              tabIcon: (
                <NotesIcon
                  error={editingState.tabError}
                  numberOfNotes={
                    notes.data?.purchaseOrderNotes?.items
                      ? notes.data?.purchaseOrderNotes?.items?.length
                      : 0
                  }
                />
              ),
              tabTitle: `${'generic.error'} in ${t(
                'pages.purchaseOrders.purchaseOrderModal.internalNotes'
              )}`,
            },
          ]
        : []),
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      t,
      editingState,
      initialValues,
      panelState.mode,
      panelState?.id,
      notes.data?.purchaseOrderNotes?.items,
    ]
  );

  return (
    <>
      <AddEditInspector
        editingState={editingState}
        hasASaveOccurred={hasASaveOccurred}
        isCreatingNewItem={isCreatingNewItem}
        needsCloseConfirmation={needsCloseConfirmation}
        onClose={onClose}
        open={open}
        setEditingState={setEditingState}
        setNeedsCloseConfirmation={setNeedsCloseConfirmation}
        tabs={tabs}
      />
      {confirmMissingDate && (
        <YesNoConfirmDialog
          content={t(
            'pages.purchaseOrders.purchaseOrderModal.missingDateContent'
          )}
          onNo={() => {
            setConfirmMissingDate(false);
            onClose();
          }}
          onYes={() => setConfirmMissingDate(false)}
          open={confirmMissingDate}
          title={t('pages.purchaseOrders.purchaseOrderModal.missingDateTitle')}
        />
      )}
    </>
  );
};

export default PurchaseOrderModal;
