import { gql } from '../../../../__generated__';

const addPOFromRFQMutation =
  gql(`mutation addPOFromRFQMutation($input: AddPOFromRFQInput) {
    purchaseOrder: addPOFromRFQ(input: $input) {
      id
      purchaseTerms
      buyerId
      purchaseShip
      purchaseRev
      purchaseCurr {
        name
      }
      purchaseDate
      purchaseCurrRate
      purchaseDateRevised
      purchaseDateRequired
      purchaseShipTo {
        id
      }
      fobnotes
      purchaseFob
      compoundTax
      port
      purchaseTax1
      purchaseTax2
      purchaseTax1Name
      purchaseTax2Name
      purchasePrinted
      purchaseEmailed
      purchaseMisc
      purchaseNotes
      purchaseNotes2
      purchaseNotes3
      purchaseSupplierId
      purchaseSupplier{
        supplier{
          defaultCurrNameId
          defaultCurrName {
            name
            rate
          }
        }
      }
      totals{
        detailTotal
      }
    }
}`);

export default addPOFromRFQMutation;
