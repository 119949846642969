/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable import/prefer-default-export */
import { capitalize } from '@mui/material';
import {
  CurrencyFormatter,
  formatHoursAsHourMin,
} from '@teto/react-component-library-v2';
import dayjs from 'dayjs';
import { TFunction } from 'react-i18next';
import TableColumnDefinition from '../../TetoGrid/types/TableColumnDefinition';
import deepPropertyHelper from '../Formatters/deepPropertyHelpers';

export interface SettingsFormats {
  dateFormat: string;
  timeFormat: string;
  dateTimeFormat: string;
  currencyFormatter: CurrencyFormatter;
}

const _boolGroupNameFormatter = (
  t: TFunction<'translation', undefined>,
  v: any
) => {
  if (typeof v === 'boolean') return capitalize(v.toString());
  if (typeof v === 'string') return capitalize(v.toLowerCase());
  return `(${t('generic.none')})`;
};

export const groupNameFormatter = (
  formats: SettingsFormats,
  t: TFunction<'translation', undefined>,
  col: TableColumnDefinition,
  value: any,
  data?: any
) => {
  let propVal = value;
  if ((propVal === undefined || propVal === null) && col?.name)
    propVal = deepPropertyHelper(col?.name, data);
  if (propVal === undefined || propVal === null) {
    propVal = `(${t('generic.none')})`;
    return propVal;
  }

  switch (col?.type) {
    case 'boolean':
      return _boolGroupNameFormatter(t, propVal);
    case 'date':
      return dayjs(propVal)?.format(formats.dateFormat);
    case 'datetime':
      return dayjs(propVal)?.format(formats.dateTimeFormat);
    case 'time':
      return dayjs(propVal)?.format(formats.timeFormat);
    case 'hours':
      if (typeof propVal === 'string') return propVal;
      return formatHoursAsHourMin(propVal);
    case 'currency':
      if (typeof propVal === 'string') return propVal;
      return formats.currencyFormatter.formatValue(propVal);
    default:
      return propVal;
  }
};
