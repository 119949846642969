import { Box, Typography } from '@mui/material';
import * as React from 'react';
import { useTranslation } from 'react-i18next';

const NoPartNumber = () => {
  const { t } = useTranslation();
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: 'calc(100% - 32px)',
        position: 'absolute',
        bottom: '50%',
      }}
    >
      <Typography sx={{ color: 'text.disabled' }} variant="h6">
        {t('pages.partsHistory.noActivePartNumber')}
      </Typography>
    </Box>
  );
};

export default NoPartNumber;
