/* eslint-disable no-unused-vars */

import { atom } from 'jotai';
import { createScope, molecule } from 'jotai-molecules';
import { atomWithReset } from 'jotai/utils';
import { NonConformanceInspectorProps } from '../../Inspectors/NonConformanceInspector/NonConformanceProps';
import { INonConformance } from '../../Inspectors/NonConformanceInspector/interfaces/NonConformanceAssignment';

type TNCGlobalState = Omit<NonConformanceInspectorProps, 'onClose'> & {
  hasASaveOccurred?: boolean;
};

// this is a read/write atom
export const ncSharedState = atomWithReset<TNCGlobalState>({
  initialValues: undefined as unknown as INonConformance,
  open: false,
  hasASaveOccurred: false,
  renderAsInspector: false,
});

// this is a write only atom
// think of this as a function that changes the state for the ncSharedState atom

const _defaultOnClose = atom(null, (get, set, newVal?: boolean) =>
  set(ncSharedState, {
    initialValues: undefined as unknown as INonConformance,
    open: false,
    hasASaveOccurred: false,
    renderAsInspector: false,
  })
);

// this sets up the scope for the onClose molecule, that can be modified on a per scope basis
export const initialNCOnCloseScope = createScope(_defaultOnClose);

// this defaults to the initialOnCloseScope, but when within a different scope, it will use that scope instead
export const onNCClose = molecule((getMol, getScope) => {
  const initialOnClose = getScope(initialNCOnCloseScope);
  return initialOnClose;
});
