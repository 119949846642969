import { gql } from '../../../__generated__';

const getReportEmailDefaults =
  gql(`query getReportEmailDefaults($entityId: Int, $entityType: String!) {
  reportEmailDefaults(entityId: $entityId, entityType: $entityType) {
      emailAddress
      emailSubject
  }
}`);

export default getReportEmailDefaults;
