import { debounce } from '@mui/material';
import {
  ETOSelectField,
  MessageContext,
} from '@teto/react-component-library-v2';
import { FormikProps } from 'formik';
import React, {
  ChangeEvent,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { getGraphQLClient } from '../../api/graphQL/graphQLClient';
import useBuildQuery from '../../api/graphQL/useBuildQuery';
import { partsNumberQuery } from '../Inspectors/NonConformanceInspector/NCPanels/InfoPanel/queries/InfoPanelQueries';

interface PartNumberSearchInputProps {
  autoFocus?: boolean;
  disabled?: boolean;
  error?: string | undefined;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  formik?: FormikProps<any>;
  // eslint-disable-next-line no-unused-vars
  handleChange: (value: number | null | undefined) => void;
  label?: string;
  name: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  value: any;
}
const commonQuery = `
query engItemMasters($id: Int!) {
  engItemMasters(
    where: {
      or: [
        { id: { eq: $id } }
      ]
    }
  ) {
    items {
      id
      itemCompanyId
      description
      quantities {
        onHand
      }
    }
  }
}`;

const PartSearchInput = (props: PartNumberSearchInputProps) => {
  const {
    autoFocus,
    disabled,
    error,
    formik,
    handleChange,
    label,
    name,
    value,
  } = props;

  const { t } = useTranslation();

  const messageContext = useContext(MessageContext);

  const [isPartsLoading, setIsPartsLoading] = useState<boolean>(false);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [partsValue, setPartsValue] = useState<number | null | undefined>(null);
  const [inputValue, setInputValue] = useState('');
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [partNumbers, setPartNumbers] = useState<any[]>([]);

  const debouncedSubmit = useMemo(
    () =>
      debounce(
        () =>
          getGraphQLClient()
            .performQuery(
              partsNumberQuery,
              {
                itemCompanyId: inputValue,
              },
              (err) => messageContext.setError(err.messages[0]),
              (err) => {
                const { input } = err;

                messageContext.setError(Object.values(input)[0] as string);
              }
            )
            .then((d) => {
              if (d.engItemMasters) {
                if (d.engItemMasters.items.length > 0) {
                  setPartNumbers(d.engItemMasters.items);
                  if (formik) {
                    formik.setFieldError(name, undefined);
                  }
                } else {
                  if (formik) {
                    return formik.setFieldError(
                      name,
                      `Invalid ${t('entities:Part.Part')}`
                    );
                  }
                  messageContext.setError(
                    `Invalid ${t('entities:Part.Part')} `
                  );
                }
                setIsPartsLoading(false);
              }
            }),
        1000
      ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [inputValue, partsNumberQuery]
  );

  useBuildQuery(['partSearchInput', value], {
    queryString: commonQuery,
    variables: { id: value },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    callback: (data: any) => {
      if (data) {
        const key = 'engItemMasters';
        const { items } = data[key];
        if (items.length > 0) {
          setInputValue(items[0].itemCompanyId);
        }
      }
    },
    options: {
      enabled: Boolean(value && inputValue === ''),
    },
  });

  useEffect(() => {
    setIsPartsLoading(true);
    debouncedSubmit();
    return () => {
      debouncedSubmit.clear();
    };
  }, [partsValue, inputValue, debouncedSubmit]);

  const _handleChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const val = parseInt(e.target.value, 10);
      const validPart = Number.isNaN(val) ? undefined : val;
      setPartNumbers((prevPartNumbers) =>
        validPart ? [validPart, ...prevPartNumbers] : prevPartNumbers
      );
      setPartsValue(validPart);
      handleChange(validPart);
    },
    [handleChange]
  );

  return (
    <ETOSelectField
      autoComplete
      autoFocus={autoFocus}
      disabled={disabled}
      error={error}
      forcePopupIcon
      freeSolo
      fullWidth
      getOptionLabel={(item) => item.itemCompanyId}
      handleChange={_handleChange}
      id={name}
      includeInputInList
      inputLabelProps={{ shrink: true }}
      itemNameSelector={(item) => item?.itemCompanyId}
      items={partNumbers}
      itemValueSelector={(item) => item?.id}
      label={label ?? `${t('entities:Part.ItemCompanyId')}`}
      loading={isPartsLoading}
      name={name}
      noOptionsText={`${t('generic.no')} ${t('generic.results')}`}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue);
      }}
      placeholder={`Search by ${t('entities:Part.ItemCompanyId')}`}
      size="small"
      value={value ?? null}
    />
  );
};

export default PartSearchInput;
