/* eslint-disable import/no-cycle */
import { Box } from '@mui/material';
import { ETOButton, MessageContext } from '@teto/react-component-library-v2';
import { useAtom, useSetAtom } from 'jotai';
import React, {
  useCallback,
  useContext,
  useEffect,
  useLayoutEffect,
  useMemo,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { Licenses, Permission } from 'teto-client-api';
import SaveStatusState from '../../SharedStateComponents/StateContainers/SaveStatusState';
import useBuildQuery from '../../../api/graphQL/useBuildQuery';
import AuthContext from '../../../contexts/AuthContext';
import ensureKeysExist from '../../../helpers/ensureKeysExists';
import SADetails, { SADetailsProps } from '../../SADetails/SADetails';
import { BreadCrumbSharedState } from '../../SharedStateComponents/StateContainers/BreadCrumbModalState';
import { ItemMasterSharedState } from '../../SharedStateComponents/StateContainers/ItemMasterState';
import { PartHistorySharedState } from '../../SharedStateComponents/StateContainers/PartHistoryState';
import { TAddEditTabs } from '../components/AddEditInspector/components/EditableTabs';
import getInitialState from '../components/AddEditInspector/helpers/getInitialState';
import useInspectorState from '../hooks/useInspectorState';
import EditingState from '../types/EditingState';
import { InspectorState } from '../types/InspectorState';
import { PanelState } from '../types/PanelState';
import AddEditEngItemMaster from './AddEditEngItemMaster';
import { TItemMaster } from './itemMaster';
import InventoryDetailsPanel from './panels/InventoryDetails/InventoryDetailsPanel';
import NotesPanel from './panels/NotesPanel/NotesPanel';
import PartDetailsPanel from './panels/PartDetailsPanel/PartDetailsPanel';
import PartDrawingsPanel from './panels/PartDrawingsPanel/PartDrawingsPanel';
import engItemMasterQuery from './queries/engItemMasterQuery';
import {
  TEngItemMaster,
  TEngItemMasterApiResponse,
} from './types/TEngItemMaster';

const initialEditingState: EditingState = {
  isEditing: false,
  hasEdited: false,
  tabError: false,
};

const buttonSx = (renderAsInspector: boolean) => ({
  display: 'flex',
  justifyContent: 'space-between',
  width: '100%',
  pt: !renderAsInspector ? 2 : 0,
});

const TETOLicenses = [
  Licenses.ReadOnlyEnterprise,
  Licenses.ReadOnlyProfessional,
  Licenses.TotalETOEnterprise,
  Licenses.TotalETOProfessional,
];

const EngItemMasterInspector = (props: TItemMaster) => {
  const { renderAsInspector, initialValues, onClose } = props;

  const { ready, t } = useTranslation();

  const setCreatedPart = useSetAtom(ItemMasterSharedState);
  const setLevels = useSetAtom(BreadCrumbSharedState);
  const setSaveState = useSetAtom(SaveStatusState);
  const [, setItemAtom] = useAtom(ItemMasterSharedState);

  const authContext = useContext(AuthContext);
  const messageContext = useContext(MessageContext);

  const [panelState, setPanelState] = useState<PanelState>({
    mode: 'create',
    id: undefined,
  });

  const [part, setPart] = useAtom(PartHistorySharedState);

  const canViewPartHistory = useMemo(
    () => authContext.hasPermission(Permission.View_General_PartHistory),
    [authContext]
  );

  const requiredKeys = useMemo(
    () => [
      'id',
      'description',
      'itemRevNumber',
      'uOM',
      'category',
      'itemWeight',
      'itemListCost',
      'manufacturerPartNumber',
      'manufacturer',
      'countryOfOrigin',
      'tariffCode',
      'estimatedLeadTime',
      'preferredSupplierCompanyId',
      'supplierPartNumber',
      'itemLastCost',
      'lastSupplierCompanyId',
      'rawMaterialItem',
      'rawMaterialCutLength',
      'processScheduleTemplateId',
      'itemRelub',
      'itemCertifiedPrints',
      'itemMaintenance',
      'hasDrawing',
      'itemReserved',
      'obsolete',
    ],
    []
  );

  const initialState: InspectorState<TEngItemMaster> = useMemo(
    () => ({
      hasASaveOccurred: false,
      isCreatingNewItem: false,
      needsCloseConfirmation: false,
      editingState: initialEditingState,
      currentValues: ensureKeysExist<TEngItemMaster>(
        initialValues,
        requiredKeys
      ),
    }),
    [initialValues, requiredKeys]
  );
  const {
    state,
    setCurrentValues,
    setEditingState,
    setHasASaveOccurred,
    setIsCreatingNewItem,
    setNeedsCloseConfirmation,
  } = useInspectorState<Partial<TEngItemMaster> | undefined>(initialState);

  const {
    currentValues,
    editingState,
    hasASaveOccurred,
    isCreatingNewItem,
    needsCloseConfirmation,
  } = state;

  useLayoutEffect(() => {
    setPanelState(getInitialState(initialValues));
  }, [initialValues]);

  useEffect(() => {
    if (panelState.mode === 'create') {
      setCreatedPart((prev) => ({ ...prev, createdPart: undefined }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [panelState.mode]);

  useBuildQuery<TEngItemMasterApiResponse>(
    ['engItemMasterQuery', panelState?.id],
    {
      queryString: engItemMasterQuery,
      variables: { id: panelState?.id },
      options: {
        enabled: Boolean(panelState?.id && panelState.mode === 'edit'),
        refetchOnWindowFocus: false,
      },

      callback: (item) => {
        if (item && Object.hasOwn(item, 'engItemMaster')) {
          const { engItemMaster } = item;
          setCurrentValues(engItemMaster);
        }
      },
    }
  );

  useEffect(() => {
    if (state.hasASaveOccurred) {
      setItemAtom((prev) => ({
        ...prev,
        hasSavedOccurred: state.hasASaveOccurred,
      }));
    }
  }, [state, setItemAtom]);

  const _handleSaveOccurred = useCallback(
    (data: boolean) => {
      setHasASaveOccurred(data);
      setCreatedPart((prev) => ({ ...prev, hasSavedOccurred: data }));
      setPart(() => ({
        ...part,
        hasSavedOccurred: data,
      }));
      setSaveState((prev) => ({
        ...prev,
        hasSavedOccurred: data,
      }));
    },
    [part, setCreatedPart, setHasASaveOccurred, setPart, setSaveState]
  );

  const tabs: TAddEditTabs = useMemo(() => {
    const tabItems = [];

    tabItems.push({
      tabName: 'partDetails',
      tabLabel: `${t('entities:Part.PartDetails')}`,
      tabIndex: 0,
      tabPanel: (
        <PartDetailsPanel
          editingState={editingState}
          initialValues={currentValues}
          panelMode={panelState.mode}
          setCurrentValues={setCurrentValues}
          setEditingState={setEditingState}
          setHasASaveOccurred={_handleSaveOccurred}
          setIsCreatingNewItem={setIsCreatingNewItem}
          setNeedsCloseConfirmation={setNeedsCloseConfirmation}
          setPanelState={setPanelState}
        />
      ),
      tabTitle: `Error In ${t('entities:Part.PartDetails')} Form`,
    });

    if (
      panelState.mode === 'edit' &&
      panelState.id &&
      TETOLicenses.some((license) => authContext.hasLicense(license))
    ) {
      tabItems.push(
        {
          tabName: 'partDrawings',
          tabLabel: `${t('entities:Part.Part')} ${t('entities:Part.Drawings')}`,
          tabIndex: 1,
          tabPanel: (
            <PartDrawingsPanel
              editingState={editingState}
              itemId={panelState.id}
              setEditingState={setEditingState}
              setHasASaveOccurred={_handleSaveOccurred}
            />
          ),
          tabTitle: `Error In ${t('entities:Part.Part')} ${t(
            'entities:Part.Drawings'
          )} Form`,
        },
        {
          tabName: 'notes',
          tabLabel: `${t('inspectors.engItemMasterInspector.notes')}`,
          tabIndex: 2,
          tabPanel: (
            <NotesPanel
              editingState={editingState}
              itemId={panelState.id}
              setEditingState={setEditingState}
              setHasASaveOccurred={_handleSaveOccurred}
            />
          ),
          tabTitle: `Error In ${t(
            'inspectors.engItemMasterInspector.notes'
          )} Form`,
        },
        {
          tabName: 'inventoryDetails',
          tabLabel: `${t('pages.inventory.details.title')}`,
          tabIndex: 3,
          tabPanel: <InventoryDetailsPanel itemCompanyId={panelState.id} />,
          tabTitle: `Error In ${t('pages.inventory.details.title')} Form`,
        }
      );
    }

    return tabItems;
  }, [
    _handleSaveOccurred,
    authContext,
    currentValues,
    editingState,
    panelState.id,
    panelState.mode,
    setCurrentValues,
    setEditingState,
    setIsCreatingNewItem,
    setNeedsCloseConfirmation,
    t,
  ]);

  return (
    <>
      {ready && (
        <>
          <AddEditEngItemMaster
            buttonStrip={
              panelState.mode === 'edit' ? (
                <Box
                  sx={[
                    buttonSx(!!renderAsInspector),
                    {
                      borderTop: (theme) =>
                        `2px solid ${theme.palette.divider}`,
                    },
                  ]}
                >
                  <ETOButton
                    color="primary"
                    confirm={{
                      title: t(
                        'inspectors.engItemMasterInspector.dialogs.saDetails.title'
                      ),
                      content: t(
                        'inspectors.engItemMasterInspector.dialogs.saDetails.content'
                      ),
                      type: 'okCancel',
                    }}
                    disabled={currentValues?.uOM?.itemUom !== -1}
                    onClick={() =>
                      setLevels((pv) => [
                        ...pv,
                        {
                          name: `${t(
                            'inspectors.engItemMasterInspector.saDetails'
                          )}: ${currentValues?.itemCompanyId}`,
                          component: (
                            <SADetails
                              {...({
                                saPart: {
                                  itemId: currentValues?.id,
                                  description: currentValues?.description,
                                  itemCompanyId: currentValues?.itemCompanyId,
                                },
                              } as SADetailsProps)}
                            />
                          ),
                        },
                      ])
                    }
                    size="medium"
                  >
                    {`${t('inspectors.engItemMasterInspector.saDetails')}`}
                  </ETOButton>
                  <ETOButton
                    color="primary"
                    disabled={!canViewPartHistory || !currentValues}
                    onClick={() => {
                      if (
                        currentValues &&
                        currentValues?.id &&
                        currentValues?.itemCompanyId
                      ) {
                        return setPart({
                          open: true,
                          part: {
                            id: currentValues?.id,
                            label: currentValues?.itemCompanyId,
                          },
                          tab: 0,
                        });
                      }

                      messageContext.setError(
                        `Id is missing for the ${t(
                          'entities:Part.Part'
                        )} History.`
                      );
                    }}
                    size="medium"
                  >
                    {`${t('entities:Part.Part')} History`}
                  </ETOButton>
                </Box>
              ) : undefined
            }
            editingState={editingState}
            hasASaveOccurred={hasASaveOccurred}
            isCreatingNewItem={isCreatingNewItem}
            needsCloseConfirmation={needsCloseConfirmation}
            onClose={onClose}
            setEditingState={setEditingState}
            setNeedsCloseConfirmation={setNeedsCloseConfirmation}
            tabs={tabs}
          />
        </>
      )}
    </>
  );
};

export default EngItemMasterInspector;
