import { gql } from '../../../__generated__';

const pODQuery = gql(`query pODQuery ($id: Int) {
  purchaseOrderDetails (order: [], where: {purchaseOrderId: {eq: $id}}) {
    items {
      itemId
    }
  }
}`);

export default pODQuery;
