import { gql } from '../../../../__generated__';

const poDropShipmentsBypassReceivingMutation =
  gql(`mutation poDropShipmentsBypassReceiving(
    $purchaseOrderId: Int!
    $receiveDate: DateTime!
    $itemsToReceive: [ReceivePurchaseOrderDetailInput]!
  ) {
    receivePurchaseOrder(
      input: {
        purchaseOrderId: $purchaseOrderId
        receiveDate: $receiveDate
        itemsToReceive: $itemsToReceive
      }
    ) {
      success
    }
  }
  `);

export default poDropShipmentsBypassReceivingMutation;
