import { useAtom } from 'jotai';
import React, { useCallback, useContext, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Permission } from 'teto-client-api';
import AuthContext from '../../../contexts/AuthContext';
import { ProcessScheduleHeader } from '../../../__generated__/graphql';
import AddEditInspector from '../../Inspectors/components/AddEditInspector/AddEditInspector';
import { TAddEditTabs } from '../../Inspectors/components/AddEditInspector/components/EditableTabs';
import useInspectorState from '../../Inspectors/hooks/useInspectorState';
import EditingState from '../../Inspectors/types/EditingState';
import { InspectorState } from '../../Inspectors/types/InspectorState';
import { PanelState } from '../../Inspectors/types/PanelState';
import { ProcessScheduleSharedState } from '../../SharedStateComponents/StateContainers/ProcessScheduleState';

import ProcessScheduleDetailsPanel from './panels/ProcessScheduleDetailsPanel/ProcessScheduleDetailsPanel';
import ProcessSchedulesDocumentsPanel from './panels/ProcessScheduleDocumentsPanel/ProcessScheduleDocumentsPanel';
import ProcessScheduleNotesPanel from './panels/ProcessScheduleNotesPanel/ProcessScheduleNotesPanel';
import { ProcessScheduleModalProps } from './types/ProcessScheduleModalProps';

const initialEditingState: EditingState = {
  isEditing: false,
  hasEdited: false,
  tabError: false,
};

const ProcessScheduleModal = (props: ProcessScheduleModalProps) => {
  const { initialValues, open, onClose } = props;
  const [panelState, setPanelState] = useState<PanelState>({
    mode: initialValues?.id ? 'edit' : 'create',
    id: initialValues?.id,
  });
  const authContext = useContext(AuthContext);
  const { t } = useTranslation();
  const [processSchedule, setProcessSchedule] = useAtom(
    ProcessScheduleSharedState
  );

  const initialState: InspectorState<ProcessScheduleHeader | undefined> =
    useMemo(
      () => ({
        hasASaveOccurred: false,
        isCreatingNewItem: false,
        needsCloseConfirmation: false,
        editingState: initialEditingState,
        currentValues: undefined,
      }),
      []
    );

  const { canAccessNotesPanel } = useMemo(
    () => ({
      canAccessNotesPanel: authContext.hasPermission(
        Permission.View_Procurement_ProcessSchedules_Notes
      ),
    }),
    [authContext]
  );

  const {
    state,
    setEditingState,
    setHasASaveOccurred,
    setNeedsCloseConfirmation,
    setIsCreatingNewItem,
  } = useInspectorState<Partial<ProcessScheduleHeader> | undefined>(
    initialState
  );

  const {
    editingState,
    hasASaveOccurred,
    isCreatingNewItem,
    needsCloseConfirmation,
  } = state;

  const _handleSaveOccurred = useCallback(
    (data: boolean) => {
      setHasASaveOccurred(data);
      setProcessSchedule(() => ({
        ...processSchedule,
        hasSavedOccurred: data,
      }));
    },
    [processSchedule, setHasASaveOccurred, setProcessSchedule]
  );

  const tabs: TAddEditTabs = useMemo(
    () => [
      {
        tabName: t('generic.details'),
        tabLabel: t('generic.details'),
        tabIndex: 0,
        tabPanel: (
          <ProcessScheduleDetailsPanel
            editingState={editingState}
            panelState={panelState}
            setEditingState={setEditingState}
            setHasASaveOccurred={_handleSaveOccurred}
            setIsCreatingNewItem={setIsCreatingNewItem}
            setNeedsCloseConfirmation={setNeedsCloseConfirmation}
            setPanelState={setPanelState}
          />
        ),
        tabTitle: `${'generic.error'} In ${t('generic.details')} Form`,
      },
      ...(panelState.mode === 'edit'
        ? [
            {
              disabled: !canAccessNotesPanel,
              tabName: t('generic.notes'),
              tabLabel: t('generic.notes'),
              tabIndex: 1,
              tabPanel: (
                <ProcessScheduleNotesPanel
                  editingState={editingState}
                  entityIdValue={panelState?.id}
                  setEditingState={setEditingState}
                  setHasASaveOccurred={_handleSaveOccurred}
                  setIsCreatingNewItem={setIsCreatingNewItem}
                />
              ),
              tabTitle: `${'generic.error'} in ${t(
                'pages.processSchedules.processScheduleModal.internalNotes'
              )}`,
            },
            {
              tabName: 'documents',
              tabLabel: `${t('generic.document')}s`,
              tabIndex: 2,
              tabPanel: (
                <ProcessSchedulesDocumentsPanel
                  editingState={editingState}
                  entityIdValue={panelState?.id}
                  setEditingState={setEditingState}
                  setHasASaveOccurred={_handleSaveOccurred}
                  setIsCreatingNewItem={setIsCreatingNewItem}
                />
              ),
              tabTitle: `${'generic.error'} in ${t('generic.document')}s`,
            },
          ]
        : []),
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [editingState, initialValues, panelState.mode]
  );

  return (
    <>
      <AddEditInspector
        editingState={editingState}
        hasASaveOccurred={hasASaveOccurred}
        isCreatingNewItem={isCreatingNewItem}
        needsCloseConfirmation={needsCloseConfirmation}
        onClose={onClose}
        open={open}
        setEditingState={setEditingState}
        setNeedsCloseConfirmation={setNeedsCloseConfirmation}
        tabs={tabs}
      />
    </>
  );
};

export default ProcessScheduleModal;
