export default `query companyReports{
  companyReports{
    allowView
    alternateId
    enabled
    favorite
    isAlternate
    modified
    reportCategoryID
    reportDescription
    reportDisplayName,
    reportID
    reportLayoutID
    reportName
  }
}`;
