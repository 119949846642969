import {
  BarcodeReader as BarcodeReaderComponent,
  BarcodeReaderProps,
} from '@teto/react-component-library-v2';
import { t } from 'i18next';
import React, { Suspense } from 'react';
import InlineHelpLink from '../HelpLinks/InlineHelpLink/InlineHelpLink';

const BarcodeReader = (props: BarcodeReaderProps) => (
  <>
    <Suspense>
      <BarcodeReaderComponent
        {...props}
        helpLinks={
          <>
            <InlineHelpLink
              helpId="5743329873940"
              subSectionId="Allow-Browser-to-Access-Your-Camera#allow-camera-access-upon-first-visit-0-1"
              title={t('generic.browserPermissions.cameraAccess')}
            />
          </>
        }
      />
    </Suspense>
  </>
);

export default BarcodeReader;
