import { atom } from 'jotai';
import { createScope, molecule } from 'jotai-molecules';
import { atomWithReset } from 'jotai/utils';
import { PurchaseOrderModalProps } from '../../Modals/PurchaseOrderModal/types/PurchaseOrderModalProps';
import RDGSelectedType from '../../TetoGrid/types/RDGSelectedType';

type PurchaseOrderState = Omit<PurchaseOrderModalProps, 'onClose'> & {
  hasSavedOccurred?: boolean;
  itemsToAdd?: RDGSelectedType;
  fromRFQ?: boolean;
};

export const PurchaseOrderSharedState = atomWithReset<PurchaseOrderState>({
  initialValues: undefined,
  open: false,
  hasSavedOccurred: false,
});

const _defaultOnClose = atom(null, (get, set) =>
  set(PurchaseOrderSharedState, {
    initialValues: undefined,
    open: false,
    hasSavedOccurred: false,
    itemsToAdd: undefined,
    fromRFQ: false,
  })
);

// Scope
export const initialPurchaseOrderStateOnCloseScope =
  createScope(_defaultOnClose);

export const onPurchaseOrderClose = molecule((getMol, getScope) => {
  const initialOnClose = getScope(initialPurchaseOrderStateOnCloseScope);
  return initialOnClose;
});
