import { SvgIcon } from '@mui/material';
import React from 'react';

const CRMIcon = () => (
  <SvgIcon>
    <svg
      height={24}
      viewBox="0 0 16 16"
      width={20}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6 4.5a.5.5 0 1 1-1 0a.5.5 0 0 1 1 0m-.5 3a.5.5 0 1 0 0-1a.5.5 0 0 0 0 1m.5 2a.5.5 0 1 1-1 0a.5.5 0 0 1 1 0M7.5 5a.5.5 0 1 0 0-1a.5.5 0 0 0 0 1M8 7a.5.5 0 1 1-1 0a.5.5 0 0 1 1 0m1 0V3.5a.5.5 0 0 0-.5-.5h-4a.5.5 0 0 0-.5.5V13h2.146a2.33 2.33 0 0 0-.14 1H3.5a.5.5 0 0 1-.5-.5v-10A1.5 1.5 0 0 1 4.5 2h4A1.5 1.5 0 0 1 10 3.5V7zm2.5 2.75a1.75 1.75 0 1 1-3.5 0a1.75 1.75 0 0 1 3.5 0m1.5 4.063c0 1.09-.857 2.187-3 2.187s-3-1.094-3-2.187c0-.725.576-1.313 1.286-1.313h3.428c.71 0 1.286.588 1.286 1.313m.477 1.687h.023c1.786 0 2.5-.941 2.5-1.875c0-.621-.48-1.125-1.071-1.125h-1.333c.256.375.404.829.404 1.313c0 .582-.166 1.173-.523 1.687M15 10.25a1.25 1.25 0 1 1-2.5 0a1.25 1.25 0 0 1 2.5 0"
        fill="currentColor"
      />
    </svg>
  </SvgIcon>
);

export default CRMIcon;
