import {
  TypeComputedProps,
  TypeFooterRow,
} from '@inovua/reactdatagrid-community/types';
import { Box, Tooltip, Typography } from '@mui/material';
import {
  ButtonStrip,
  GenericDialog,
  MessageContext,
} from '@teto/react-component-library-v2';
import { Dayjs } from 'dayjs';
import { useAtom } from 'jotai';
import { debounce } from 'lodash';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import getErrors from '../../../../api/graphQL/getErrors';
import { TETODataColumn } from '../../GridBuilder/types/TETODataColumn';
import getEditor from '../../InlineEditingProcessor';
import EditorType from '../../types/EditorTypes';
import currentFocusedCell from './atoms/currentFocusedCell';
import hasErrorAtom from './atoms/hasErrorAtom';
import isDialogOpenAtom from './atoms/isDialogOpenAtom';
import shouldResetAtom from './atoms/resetAtom';
import shouldSubmitAtom from './atoms/shouldSubmitAtom';

type Row = TypeFooterRow & {
  isAddNewDataRowEnabled: boolean;
  // eslint-disable-next-line no-unused-vars
  setIsAddNewDataRowEnabled: (value: boolean) => void;
};

export interface ColumnProps {
  summary?: unknown;
  row: Row;
  rowIndex: number;
  render?: unknown;
  rowPosition: 'start' | 'end';
  column: TETODataColumn;
  columnIndex: number;
  computedVisibleIndex: number;
  computedWidth: number;
}

export type AddNewRowValue =
  | string
  | number
  | boolean
  | null
  | undefined
  | Dayjs;

const containerSx = {
  width: '100%',
  height: '100%',
  '& .MuiOutlinedInput-notchedOutline': { border: 0 },
};

const AddNewDataRowEditor = (
  /* eslint-disable no-unused-vars */
  columnProps: ColumnProps,
  computedProps: TypeComputedProps,
  setNewData: (value: AddNewRowValue, column: TETODataColumn) => void,
  /* eslint-enable */
  reset: boolean,
  validationSchema?: yup.AnySchema,
  inputVariant?: 'standard' | 'filled',
  validationErrors?:
    | {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        [key: string]: any;
      }
    | undefined,
  gridRef?: React.MutableRefObject<
    | (TypeComputedProps & {
        scrollLeft?: number;
        smoothScrollTo?: (
          // eslint-disable-next-line no-unused-vars
          x: number,
          // eslint-disable-next-line no-unused-vars
          options: { orientation: 'horizontal' }
        ) => void;
      })
    | null
  >
) => {
  const {
    column,
    columnIndex: curColIndex,
    row,
    computedVisibleIndex,
    computedWidth,
  } = columnProps;
  const { isAddNewDataRowEnabled, setIsAddNewDataRowEnabled } = row;

  const messageContext = useContext(MessageContext);
  const { t } = useTranslation();

  const [open, setOpen] = useState(false);
  const [editedValue, setEditedValue] = useState<AddNewRowValue>();
  const [shouldRenderEditor, setShouldRenderEditor] = useState(
    isAddNewDataRowEnabled
  );
  const [_validationError, setValidationError] = useState<string[]>([]);
  const [needsCloseConfirmation, setNeedsCloseConfirmation] = useState(false);
  const [_reset, setReset] = useState(false);
  const [, setShouldReset] = useAtom(shouldResetAtom);
  const [shouldSubmit, setShouldSubmit] = useAtom(shouldSubmitAtom);
  const [cell, setCurrentFocusedCell] = useAtom(currentFocusedCell);
  const [isDialogOpen, setIsDialogOpen] = useAtom(isDialogOpenAtom);
  const [hasError, setHasError] = useAtom(hasErrorAtom);

  const debouncedSetNewData = debounce(setNewData, 1000);

  useEffect(() => {
    if (isAddNewDataRowEnabled) {
      setShouldRenderEditor(true);
    }
  }, [isAddNewDataRowEnabled]);

  useEffect(() => {
    if (
      cell.currentFocusedCell === null &&
      isAddNewDataRowEnabled &&
      editedValue &&
      !shouldSubmit &&
      !isDialogOpen &&
      !hasError
    ) {
      //  debounce to prevent the dialog from opening and closing.
      const timerId = setTimeout(() => {
        setNeedsCloseConfirmation(true);
        setIsDialogOpen(true);
      }, 4_000);

      return () => {
        clearTimeout(timerId);
      };
    }
  }, [
    cell.currentFocusedCell,
    editedValue,
    hasError,
    isAddNewDataRowEnabled,
    isDialogOpen,
    setIsDialogOpen,
    shouldSubmit,
    validationErrors,
  ]);

  useEffect(() => {
    if (validationErrors) {
      const { input } = validationErrors;
      if (input) {
        const result = input[column?.path as string];
        // error is in another column if the result is undefined
        if (result) {
          setValidationError([result]);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [column?.path, validationErrors]);

  useEffect(() => {
    // make sure to set the reset back to false after calling a mutation function, otherwise the editor will not open
    if (reset || _reset) {
      setReset(false);
      setEditedValue(null);
      setShouldRenderEditor(false);
      setIsAddNewDataRowEnabled(false);
      setValidationError([]);
      setCurrentFocusedCell({
        currentFocusedCell: null,
      });
      setShouldSubmit(false);
      setOpen(false);
      setIsDialogOpen(false);
      setHasError(false);
      setShouldReset(false);
      setNewData(undefined, column);
    }
  }, [
    _reset,
    column,
    isAddNewDataRowEnabled,
    reset,
    setCurrentFocusedCell,
    setHasError,
    setIsAddNewDataRowEnabled,
    setIsDialogOpen,
    setNewData,
    setShouldReset,
    setShouldSubmit,
  ]);

  useEffect(() => {
    if (_validationError.length > 0) {
      const checkIfValidationMatchesCurrentCol = Object.keys(
        validationErrors?.input ?? {}
      ).some((key) => key === column?.path);
      // two conditions to open the tooltip:
      // 1. if a validation error is passed in ex: from a mutation
      // 2. if the validation schema triggers an error

      if (checkIfValidationMatchesCurrentCol) {
        setOpen(true);
      } else if (!validationErrors) {
        setOpen(true);
      }
    }
  }, [
    _validationError.length,
    column?.path,
    validationErrors,
    validationErrors?.input,
  ]);

  const editingMethods = useMemo(
    () => ({
      onFocus: (e: React.FocusEvent<HTMLInputElement>) => {
        setCurrentFocusedCell({
          currentFocusedCell: column,
        });
        return e.target.select();
      },
      onCancel: () => {
        setEditedValue(null);
      },
      onKeyDown: (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === 'Tab') {
          if (
            gridRef?.current &&
            computedVisibleIndex > 4 &&
            gridRef?.current?.scrollLeft &&
            gridRef?.current?.smoothScrollTo
          ) {
            gridRef?.current?.smoothScrollTo(
              computedWidth + gridRef.current.scrollLeft,
              {
                orientation: 'horizontal',
              }
            );
          }
        }
        if (e.key === 'Enter' && editedValue && isAddNewDataRowEnabled) {
          if (shouldSubmit) {
            setShouldSubmit(false);
            return;
          }
          setShouldSubmit(true);
        }

        if (e.key === 'Escape') {
          setEditedValue(null);
        }
      },
      onComplete: async () => {
        if (setNewData && editedValue) {
          if (validationSchema) {
            try {
              await validationSchema?.validateAt(column?.path as string, {
                [column?.path as string]: editedValue,
              });
            } catch (error) {
              if (error && typeof error === 'object' && 'message' in error) {
                setHasError(true);
                return setValidationError([error?.message as string]);
              }
              return messageContext.setError(getErrors(error));
            }
          }

          setNewData(editedValue, column);
        }
        setCurrentFocusedCell({
          currentFocusedCell: null,
        });
        setValidationError([]);
      },

      onChange: (e: React.ChangeEvent<HTMLInputElement> | boolean) => {
        setHasError(false);
        if (typeof e === 'boolean' || typeof e === 'number') {
          setNewData(e, column);
          return setEditedValue(e);
        }
        if (column.type === 'date' || column.type === 'time') {
          setNewData(e as unknown as Dayjs, column);
          return setEditedValue(e as unknown as Dayjs);
        }

        debouncedSetNewData(e.target.value, column);
        return setEditedValue(e.target.value);
      },
    }),
    [
      column,
      computedVisibleIndex,
      computedWidth,
      debouncedSetNewData,
      editedValue,
      gridRef,
      isAddNewDataRowEnabled,
      messageContext,
      setCurrentFocusedCell,
      setHasError,
      setNewData,
      setShouldSubmit,
      shouldSubmit,
      validationSchema,
    ]
  );

  const Editor = getEditor(
    column?.type ?? 'string',
    column?.editorType as EditorType,
    inputVariant ?? 'standard'
  )(
    {
      value: editedValue,
      cellProps: {
        name: column?.name,
        width: column?.width,
        rowHeight: computedProps?.rowHeight,
      },
      editorProps: column?.editorProps,
      ...editingMethods,
    },
    {
      validationMessages: _validationError,
      value: editedValue,
      isValid: _validationError.length === 0,
      originalValue: undefined,
      warningMessages: [],
    }
  );

  const shouldRenderSx = useMemo(
    () => [
      shouldRenderEditor || editedValue
        ? { display: 'block' }
        : { display: 'none' },
    ],
    [shouldRenderEditor, editedValue]
  );

  return (
    <>
      <Tooltip
        arrow
        onClose={() => setOpen(false)}
        onOpen={() => setOpen(true)}
        open={open}
        title={_validationError[0] ?? `Click To ${t('generic.enable')}`}
      >
        <Box
          data-testid="add-new-data-row-editor"
          onFocus={() => {
            setIsAddNewDataRowEnabled(true);
            setShouldRenderEditor(true);
          }}
          sx={[containerSx]}
          tabIndex={curColIndex}
        >
          <Box data-testid={column.type} sx={shouldRenderSx}>
            {Editor}
          </Box>
        </Box>
      </Tooltip>
      <GenericDialog
        isOpen={needsCloseConfirmation}
        onClose={() => {
          setNeedsCloseConfirmation(false);
        }}
        title={t('dialogs.saveOrDiscard.title')}
      >
        <Typography variant="body1">
          {t('dialogs.saveOrDiscard.content')}
        </Typography>
        <ButtonStrip
          className={{
            mt: 2,
          }}
          leftButton={{
            text: t('generic.discard'),
            color: 'secondary',
            onClick: () => {
              setNeedsCloseConfirmation(false);
              setReset(true);
            },
          }}
          rightButton={{
            text: t('generic.save'),
            color: 'primary',
            onClick: () => {
              setShouldSubmit(true);
              setNeedsCloseConfirmation(false);
            },
          }}
          size="small"
        />
      </GenericDialog>
    </>
  );
};

export default AddNewDataRowEditor;
