import { atom } from 'jotai';
import { createScope, molecule } from 'jotai-molecules';
import { atomWithReset } from 'jotai/utils';
import { AddEditContactInspectorProps } from '../../Inspectors/AddEditContactInspector/types/AddEditContactInspectorProps';

type ContactState = Omit<AddEditContactInspectorProps, 'onClose'> & {
  hasSavedOccurred?: boolean;
};

export const ContactSharedState = atomWithReset<ContactState>({
  initialValues: undefined,
  open: false,
  hasSavedOccurred: false,
});

const _defaultOnClose = atom(null, (get, set) =>
  set(ContactSharedState, {
    initialValues: undefined,
    open: false,
    hasSavedOccurred: false,
  })
);

// Scope
export const initialContactOnCloseScope = createScope(_defaultOnClose);

export const onContactClose = molecule((getMol, getScope) => {
  const initialOnClose = getScope(initialContactOnCloseScope);
  return initialOnClose;
});
