import { gql } from '../../../__generated__';
import SelectDefinition from './SelectDefinition';

export default {
  query:
    gql(`query getProcessSchedulesBySpecAndProject($projectId: Int!, $specId: Float!) {
    processScheduleHeaders(
      where: { projectId: { eq: $projectId }, specId: { eq: $specId} }
    ) {
      items {
          id
          ownerEmployee {
            name
          }

      }
    }
  }`),
  valueField: 'id',
  labelField: 'ownerEmployee.name',
} as SelectDefinition;
