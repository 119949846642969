import dayjs from 'dayjs';
import { useAtom, useSetAtom } from 'jotai';
import { useMolecule } from 'jotai-molecules';
import React, { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import SettingsContext from '../../../contexts/SettingsContext';
import ProcessScheduleModal from '../../Modals/ProcessScheduleModal/ProcessScheduleModal';
import { BreadCrumbSharedState } from '../StateContainers/BreadCrumbModalState';
import {
  ProcessScheduleSharedState,
  onProcessScheduleClose,
} from '../StateContainers/ProcessScheduleState';

const useSharedProcessSchedule = () => {
  const { t } = useTranslation();
  const {
    settings: { dateFormat },
  } = useContext(SettingsContext);

  const [processSchedule] = useAtom(ProcessScheduleSharedState);
  const onCloseFn = useMolecule(onProcessScheduleClose);
  const defaultOnClose = useSetAtom(onCloseFn);
  const setLevels = useSetAtom(BreadCrumbSharedState);

  useEffect(() => {
    if (processSchedule.open) {
      const { open, initialValues } = processSchedule;

      const createdDate = initialValues?.createdDate
        ? `(Created Date: ${dayjs(initialValues?.createdDate).format(
            dateFormat
          )})`
        : '';

      const psNumber = initialValues?.number
        ? initialValues?.number
        : initialValues?.id?.toString();

      setLevels((prev) => [
        ...prev,
        {
          name: initialValues?.id
            ? `${t(
                'entities:ProcessSchedule.ProcessSchedule'
              )}: ${psNumber} ${createdDate}`
            : t('entities:ProcessSchedule.ProcessSchedule'),
          data: initialValues,
          component: (
            <ProcessScheduleModal
              initialValues={initialValues}
              onClose={defaultOnClose}
              open={open}
            />
          ),
        },
      ]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [processSchedule?.initialValues, processSchedule.open]);
};

export default useSharedProcessSchedule;
