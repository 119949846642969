import { Box, Typography, useMediaQuery, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import React from 'react';

const BOMWarning = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const warningSx = {
    color: theme.palette.warning.main,
  };

  const warningContainer = {
    padding: theme.spacing(1),
    marginTop: theme.spacing(1),
    marginLeft: !isMobile ? theme.spacing(1) : 0,
  };

  return (
    <Box sx={warningContainer}>
      <Typography sx={warningSx}>
        {t('pages.procurementBreakDown.tabs.bomDistributionWarning')}
      </Typography>
    </Box>
  );
};

export default BOMWarning;
