import { SxProps, Theme } from '@mui/material';

export const altColorSx: SxProps<Theme> = {
  backgroundColor: (theme) => theme.palette.background.default,
  color: (theme) =>
    theme.palette.getContrastText(theme.palette.background.default),
};

export const contentSx: SxProps<Theme> = {
  display: 'flex',
  flexDirection: 'column',
  overflowY: 'unset',
};

export const deleteSx: SxProps<Theme> = {
  display: 'flex',
  justifyContent: 'flex-end',
  width: '100%',
  position: 'sticky',
  top: 0,
  zIndex: 12,
  backgroundColor: (theme) => `${theme.palette.background.paper} !important`,
  backgroundImage:
    'linear-gradient(rgba(255,255,255,0.15), rgba(255,255,255,0.15)) !important',
  pb: 0.5,
};

export const listSx: SxProps<Theme> = {
  display: 'flex',
  flexDirection: 'column',
  paddingTop: 0,
  rowGap: 2,
  width: '100%',
};

export const paperSx: SxProps<Theme> = {
  alignItems: 'center',
  display: 'flex',
  px: 2,
  py: 1,
  width: '100%',
};

export const rootSx: SxProps<Theme> = {
  border: (theme) => `1px solid ${theme.palette.grey[400]}`,
  borderRadius: (theme) => theme.spacing(0.5),
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  width: '100%',
  paddingTop: 2,
  paddingBottom: 2,
  paddingLeft: 2,
  paddingRight: 2,
};
