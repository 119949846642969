import { gql } from '../../../__generated__';

const rFQDQuery = gql(`
  query rFQDQuery($id: Int) {
    rFQDetails(order: [{ rFQOrderNumber: ASC }], where: { rFQId: { eq: $id } }) {
      items {
        itemId
      }
    }
  }
  
`);

export default rFQDQuery;
