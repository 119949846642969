import { useAtom } from 'jotai';
import React, { useCallback, useContext, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Permission } from 'teto-client-api';
import AuthContext from '../../../contexts/AuthContext';
import AddEditInspector from '../../Inspectors/components/AddEditInspector/AddEditInspector';
import { GenericAddEditProps } from '../../Inspectors/components/AddEditInspector/GenericAddEditProps';
import { TAddEditTabs } from '../../Inspectors/components/AddEditInspector/components/EditableTabs';
import useInspectorState from '../../Inspectors/hooks/useInspectorState';
import EditingState from '../../Inspectors/types/EditingState';
import { InspectorState } from '../../Inspectors/types/InspectorState';
import { PanelState } from '../../Inspectors/types/PanelState';
import { RFQSharedState } from '../../SharedStateComponents/StateContainers/RFQState';
import RFQDetailsPanel from './panels/RFQDetailsPanel/RFQDetailsPanel';
import RFQDocumentsPanel from './panels/RFQDocumentsPanel/RFQDocumentsPanel';
import { RFQ } from './types/RFQ';

const initialEditingState: EditingState = {
  isEditing: false,
  hasEdited: false,
  tabError: false,
};

const RFQModal = (props: GenericAddEditProps<RFQ>) => {
  const { initialValues, open, onClose } = props;
  const [panelState, setPanelState] = useState<PanelState>({
    mode: initialValues?.id ? 'edit' : 'create',
    id: initialValues?.id,
  });
  const { t } = useTranslation();
  const authContext = useContext(AuthContext);
  const [rFQ, setRFQ] = useAtom(RFQSharedState);

  const permissions = useMemo(
    () => ({
      canViewDocuments: authContext.hasAnyPermission([
        Permission.Add_Procurement_RFQs_RFQHeader_Documents,
        Permission.Modify_Procurement_RFQs_RFQHeader_Documents,
        Permission.Delete_Procurement_RFQs_RFQHeader_Documents,
      ]),
    }),
    [authContext]
  );

  const initialState: InspectorState<RFQ | undefined> = useMemo(
    () => ({
      hasASaveOccurred: false,
      isCreatingNewItem: false,
      needsCloseConfirmation: false,
      editingState: initialEditingState,
      currentValues: undefined,
    }),
    []
  );

  const {
    state,
    setEditingState,
    setHasASaveOccurred,
    setNeedsCloseConfirmation,
    setIsCreatingNewItem,
  } = useInspectorState<Partial<RFQ> | undefined>(initialState);

  const {
    editingState,
    hasASaveOccurred,
    isCreatingNewItem,
    needsCloseConfirmation,
  } = state;

  const _handleSaveOccurred = useCallback(
    (data: boolean) => {
      setHasASaveOccurred(data);
      setRFQ(() => ({
        ...rFQ,
        hasSavedOccurred: data,
      }));
    },
    [rFQ, setHasASaveOccurred, setRFQ]
  );

  const tabs: TAddEditTabs = useMemo(
    () => [
      {
        tabName: 'details',
        tabLabel: t('generic.details'),
        tabIndex: 0,
        tabPanel: (
          <RFQDetailsPanel
            editingState={editingState}
            initialValues={initialValues}
            panelMode={panelState.mode}
            setEditingState={setEditingState}
            setHasASaveOccurred={_handleSaveOccurred}
            setIsCreatingNewItem={setIsCreatingNewItem}
            setNeedsCloseConfirmation={setNeedsCloseConfirmation}
            setPanelState={setPanelState}
          />
        ),
        tabTitle: `${'generic.error'} In ${t('generic.details')} Form`,
      },
      ...(panelState.mode === 'edit'
        ? [
            {
              tabName: 'documents',
              tabLabel: `${t('generic.document')}s`,
              tabIndex: 1,
              disabled: !permissions.canViewDocuments,
              tabPanel: (
                <RFQDocumentsPanel
                  editingState={editingState}
                  entityIdValue={panelState?.id}
                  setEditingState={setEditingState}
                  setHasASaveOccurred={_handleSaveOccurred}
                  setIsCreatingNewItem={setIsCreatingNewItem}
                />
              ),
              tabTitle: `${'generic.error'} in ${t('generic.document')}s`,
            },
          ]
        : []),
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [editingState, initialValues, panelState.mode]
  );

  return (
    <>
      <AddEditInspector
        editingState={editingState}
        hasASaveOccurred={hasASaveOccurred}
        isCreatingNewItem={isCreatingNewItem}
        needsCloseConfirmation={needsCloseConfirmation}
        onClose={onClose}
        open={open}
        setEditingState={setEditingState}
        setNeedsCloseConfirmation={setNeedsCloseConfirmation}
        tabs={tabs}
      />
    </>
  );
};

export default RFQModal;
