const addNonConformanceMutation = `mutation addNonConformance($input: AddNonConformanceInput!) {
  addNonConformance(input: $input) {    
      id
      project {        
        company {
          name  
        }
      }
      projectId
      specId      
      purchaseOrder {
        id
        purchaseSupplier {
          name
        }    
        purchaseOrderDetails {
         purchaseSupplierItem
       }    
      }      
      purchaseOrderId
      sourceId
      creationDate
      title
      originId
      itemId 
      item {
        id
        itemCompanyId
      }
      viewDetails {
        partNumber
      } 
      quantity
      quantityRejected
      description
      rootCause      
      recommendedInterim  
      correctivePreventiveAction
      nonConformanceAssignments {
        assignedToEmployee {
          name
        }
        assignedByEmployee {
          name
        }
        task
        actionTaken
        complete
        note
      }
      qualityFollowUp
      nonConformanceNotes {
        date
        noteEmployee {
          name
        }
        note        
      }
      nonConformanceDocuments {
        documentLocation
      }     
      resolved
     custom1
     custom2
     custom3
     custom4
     custom5
     custom6
     custom7  
     resolved  
  }
}`;

export default addNonConformanceMutation;
