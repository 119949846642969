interface InputError {
  input: {
    [key: string]: unknown;
  };
}

interface ErrorMessage {
  message: string;
}

const getUniqueStrings = (arr: unknown[]): string[] => {
  const uniqueStrings = [...new Set(arr)];
  return uniqueStrings.map((value) => {
    if (value && typeof value === 'object' && 'message' in value) {
      return (value as ErrorMessage).message;
    }
    return String(value);
  });
};

const getErrorsFromInput = (err: InputError): string => {
  const inputKeys = Object.keys(err.input);
  return inputKeys
    .map((key, index) => {
      const isLastKey = index === inputKeys.length - 1;
      const error = String(err.input[key]);
      return isLastKey ? error : `${error}\n \n`;
    })
    .join('');
};

const getErrors = (err: unknown): string => {
  if (typeof err === 'string') {
    return err;
  }
  if (
    err &&
    typeof err === 'object' &&
    'errors' in err &&
    Array.isArray(err.errors)
  ) {
    const uniqueStrings = getUniqueStrings(err.errors);
    return uniqueStrings.join('\n \n');
  }
  if (!err || typeof err !== 'object') {
    return 'Unknown error';
  }
  if (err instanceof Error) {
    return err.message;
  }

  if ('input' in err) {
    return getErrorsFromInput(err as InputError);
  }

  if (err && typeof err === 'object') {
    const errorKeys = Object.keys(err);
    return errorKeys
      .map((key, index) => {
        const isLastKey = index === errorKeys.length - 1;
        const error = String(err[key as keyof typeof err]);
        return isLastKey ? error : `${error}\n \n`;
      })
      .join('');
  }

  return 'Unknown error';
};
export default getErrors;
