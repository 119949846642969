import { gql } from '../../../__generated__';

export const inventoryLocationsFiltered =
  gql(`query inventoryLocationsFiltered($id: Int){
  inventoryLocations(order: { id: ASC }, where: {id: {neq: $id}}) {
    items {
      name
      id
    }
  }
}`);

export const inventoryLocations = gql(`query inventoryLocations{
  inventoryLocations(order: { id: ASC }) {
    items {
      name
      id
    }
  }
}`);
