import { gql } from '../../__generated__';

const getActiveJobs = gql(`query getActiveJobs($projectId: Int!) {
  specs(
    where: { and: { projectId: { eq: $projectId }, active: { eq: true } } }
    order: { displayName: ASC }
  ) {
    items {
      active
      autoId
      buildLocationId
      custom1
      custom2
      custom3
      custom4
      custom5
      custom6
      custom7
      custom8
      date
      description
      displayName
      id
      machineTypeId
      mfgBegin
      projectId
    }
  }
}
`);
export default getActiveJobs;
