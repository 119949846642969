import { MessageContext } from '@teto/react-component-library-v2';
import { useAtom, useSetAtom } from 'jotai';
import React, {
  Dispatch,
  useCallback,
  useContext,
  useEffect,
  useMemo,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { Permission, getGraphQLClient } from 'teto-client-api';
import useLocalStorage from 'use-local-storage';
import {
  AddRfqHeaderAndDetailsInput,
  AddRfqHeaderInput,
  RequiredDateMethod,
  RfqHeader,
} from '../../../../../../__generated__/graphql';
import getErrors from '../../../../../../api/graphQL/getErrors';
import AuthContext from '../../../../../../contexts/AuthContext';
import ItemsToProcureRowItem from '../../../../../../pages/ProcurementPage/types/ItemsToProcureRowItem';
import EditingState from '../../../../../Inspectors/types/EditingState';
import { PanelState } from '../../../../../Inspectors/types/PanelState';
import { BreadCrumbSharedState } from '../../../../../SharedStateComponents/StateContainers/BreadCrumbModalState';
import { RFQSharedState } from '../../../../../SharedStateComponents/StateContainers/RFQState';
import FormBuilder from '../../../../../TETOForms/FormBuilder/FormBuilder';
import FormDefinition from '../../../../../TETOForms/FormDefinition';
import { simpleLayout } from '../../../../../TETOForms/FormLayoutHelpers';
import TETOForm from '../../../../../TETOForms/TETOForm';
import useForm from '../../../../../TETOForms/hooks/useForm';
import { useFormBuilder } from '../../../../../TETOForms/hooks/useFormBuilder';
import {
  addRFQHeaderAndDetailsMutation,
  addRFQHeaderMutation,
} from '../../../queries/addRFQMutation';
import getLastRFQQuery from '../../../queries/getLastRFQQuery';

interface RFQCreateHeaderFormProps {
  submitForm: boolean;
  // eslint-disable-next-line no-unused-vars
  setEditingState: (editingState: EditingState) => void;
  // eslint-disable-next-line react/no-unused-prop-types
  setPanelState: Dispatch<React.SetStateAction<PanelState>>;
  // eslint-disable-next-line react/no-unused-prop-types
  setRFQ: Dispatch<React.SetStateAction<Partial<RfqHeader>>>;
  // eslint-disable-next-line no-unused-vars, react/no-unused-prop-types
  setHasASaveOccurred: (val: boolean) => void;
  setSubmitForm: Dispatch<React.SetStateAction<boolean>>;
  setIsSubmitting: Dispatch<React.SetStateAction<boolean>>;
}

const RFQCreateHeaderForm = (props: RFQCreateHeaderFormProps) => {
  const {
    setEditingState,
    setSubmitForm,
    setHasASaveOccurred,
    setPanelState,
    submitForm,
    setIsSubmitting,
  } = props;

  const messageContext = useContext(MessageContext);
  const authContext = useContext(AuthContext);

  const { t } = useTranslation();

  const [rfqNumber, setRfqNumber] = useLocalStorage<number | null>(
    'createNewRfqNumber',
    0
  );
  const setLevels = useSetAtom(BreadCrumbSharedState);

  const [rFQ, setRFQ] = useAtom(RFQSharedState);

  const { canAdd, canManuallyOverwriteRFQ } = useMemo(
    () => ({
      canAdd: authContext.hasPermission(
        Permission.Add_Procurement_RFQs_RFQHeader
      ),
      canManuallyOverwriteRFQ: authContext.hasPermission(
        Permission.Modify_Procurement_RFQs_RFQHeader_ManualOverwriteOfRFQNumberAtCreation
      ),
    }),
    [authContext]
  );

  const requiredDateOptions = Object.keys(RequiredDateMethod)
    .map((key) => ({
      name: t(`forms:AddRFQDetailBatchInput.requiredDateOptions.${key}`),
      value: RequiredDateMethod[key as keyof typeof RequiredDateMethod],
    }))
    .slice(0, 4);

  const itemsToAddLength = useMemo(
    () => Object.keys(rFQ?.itemsToAdd ?? {}).length ?? 0,
    [rFQ]
  );

  const formBuilder = useFormBuilder('create-rfq-header', (fb) => {
    const commonRFQNumber = {
      disabled: !canAdd || !canManuallyOverwriteRFQ,
      name: 'id',
      required: true,
      title: t('pages.rFQ.rFQNumber'),
      defaultValue: rfqNumber,
    };

    if (itemsToAddLength > 0) {
      return fb
        .addInteger(commonRFQNumber)
        .addString({
          defaultValue: requiredDateOptions[0].value,
          disabled: !canAdd,
          name: 'requiredDateMethod',
          selectSource: {
            label: 'name',
            value: 'value',
            fromArray: requiredDateOptions,
          },
          required: true,
          title: t('pages.rFQ.rFQModal.populateDates'),
        })
        .addInteger({
          defaultValue: itemsToAddLength,
          disabled: true,
          name: 'count',
          title: t('pages.rFQ.rFQModal.totalItems'),
        })
        .addBoolean({
          defaultValue: false,
          disabled: !canAdd,
          name: 'preferredSuppliers',
          title: t('pages.rFQ.rFQModal.preferredSuppliers'),
          required: true,
          persist: true,
        });
    }
    return fb.addInteger(commonRFQNumber).addInteger({
      defaultValue: itemsToAddLength,
      disabled: true,
      name: 'count',
      title: t('pages.rFQ.rFQModal.totalItems'),
    });
  });

  const _handleRequest = useCallback(
    (fb: FormBuilder, fd: FormDefinition, data: Record<string, unknown>) => {
      const rFQHeader = {
        id: data.id,
        requestedById: authContext.user?.id,
      };
      if (!rFQ.itemsToAdd) {
        return { ...rFQHeader };
      }
      const items = Object.values(rFQ.itemsToAdd).map(
        (item: ItemsToProcureRowItem) => {
          const summary = item.purchasingSummary;
          return {
            destinationInventoryLocationId:
              summary?.destInventoryLocation?.id ?? null,
            itemId: summary?.childId,
            processScheduleDetailId: summary?.processScheduleDetailId ?? null,
            processScheduleId: summary?.processScheduleId ?? null,
            projectId: summary?.projectId ?? undefined,
            itemQty: summary?.toPurchase ?? 1,
            specId: summary?.specId ?? undefined,
            externalUOMId: summary?.child?.uOMId ?? undefined,
            requiredDate: summary?.requiredDate ?? null,
          };
        }
      );
      return {
        rFQHeader,
        requiredDateMethod: data.requiredDateMethod,
        items,
        includeSuppliers: data.preferredSuppliers,
      };
    },
    [authContext, rFQ]
  );

  const _handleResponse = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (fb: FormBuilder, fd: FormDefinition, resp: any) => {
      setSubmitForm(false);
      fd.setSubmitting?.(false);
      if (resp.hasError()) {
        setEditingState({
          isEditing: true,
          hasEdited: true,
          tabError: true,
        });
        if (resp.hasValidationErrors()) {
          fd.setValidationErrors(resp.validationErrors.input);
        }
        return;
      }
      setHasASaveOccurred(true);
      setEditingState({
        isEditing: false,
        hasEdited: true,
        tabError: false,
      });

      setRFQ((r) => ({
        ...r,
        baseLevel: true,
        initialValues: { id: resp.data.rfq.id },
      }));

      messageContext.setSuccess(
        t('generic.createdSuccess', {
          record: t('entities:RFQ.RFQ'),
        })
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      setEditingState,
      setHasASaveOccurred,
      setLevels,
      setPanelState,
      setRFQ,
      setSubmitForm,
      t,
    ]
  );

  const _handleSubmit = useCallback(
    (fb: FormBuilder, fd: FormDefinition, data: AddRfqHeaderInput) => {
      if (itemsToAddLength < 1) {
        return getGraphQLClient().performMutation(addRFQHeaderMutation, {
          input: data as AddRfqHeaderInput,
        });
      }
      return getGraphQLClient().performMutation(
        addRFQHeaderAndDetailsMutation,
        {
          input: data as AddRfqHeaderAndDetailsInput,
        }
      );
    },
    [itemsToAddLength]
  );

  const createRFQForm = useForm(
    formBuilder,
    {
      onFormatRequest: (fb, fd, data) => _handleRequest(fb, fd, data),
      onFormatResponse: (fb, fd, resp) => _handleResponse(fb, fd, resp),
      onSubmitting: (fb, fd, data) => {
        setIsSubmitting(true);
        return _handleSubmit(fb, fd, data);
      },
      onSubmitted: () => Promise.resolve(setIsSubmitting(false)),
    },
    {
      id: rfqNumber,
    }
  );
  useQuery(['lastSelectedRfqNumber'], () =>
    getGraphQLClient()
      .performQuery(getLastRFQQuery, {})
      .then((d) => {
        if (d.hasError()) {
          if (d.hasValidationErrors()) {
            messageContext.setError(getErrors(d.validationErrors));
          }

          if (d.hasSystemErrors()) {
            messageContext.setError(getErrors(d.systemErrors));
          }
          return;
        }

        const lastRFQNumber = d?.data?.rFQHeaders?.items?.[0]?.id;
        if (lastRFQNumber) {
          setRfqNumber(lastRFQNumber + 1);
        } else if (rfqNumber) {
          setRfqNumber(rfqNumber + 1);
        } else {
          setRfqNumber(0);
        }
      })
  );
  useEffect(() => {
    setEditingState({
      isEditing: createRFQForm.dirty,
      hasEdited: createRFQForm.dirty,
      tabError: false,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createRFQForm.dirty]);

  useEffect(() => {
    if (submitForm) createRFQForm.submit();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [submitForm]);

  useEffect(() => {
    if (itemsToAddLength > 0) {
      createRFQForm.updateField('count', itemsToAddLength);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [itemsToAddLength]);

  useEffect(() => {
    if (rfqNumber) {
      createRFQForm.updateField('id', rfqNumber);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rfqNumber]);

  const formLayout = useMemo(
    () => simpleLayout(formBuilder.formBuilder, 2),
    [formBuilder]
  );

  useEffect(() => {
    if (!createRFQForm.isValid) {
      setSubmitForm(false);
    }
  }, [createRFQForm.isValid, setSubmitForm]);

  return (
    <>
      <TETOForm form={createRFQForm} formLayout={formLayout} />
    </>
  );
};

export default RFQCreateHeaderForm;
