import { Box, SxProps, Theme } from '@mui/material';
import {
  Inspector,
  InspectorButtonOptionsI,
} from '@teto/react-component-library-v2';
import { LatLngExpression, Map } from 'leaflet';
import React, {
  Suspense,
  lazy,
  startTransition,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';
import useLocalStorage from 'use-local-storage';
import SettingsContext from '../../../contexts/SettingsContext';
import BarcodeScannerStatusIcon from '../../Custom Icons/BarcodeScannerStatusIcon/BarcodeScannerStatusIcon';
import LoadingIcon from '../../Custom Icons/LoadingIcon/LoadingIcon';
import MapViewInspectorIcon from '../../Custom Icons/MapViewInspectorIcon/MapViewInspectorIcon';
import { LocationT } from '../../MapWrapper/LocationT';

const MapWrapper = lazy(() => import('../../MapWrapper/MapWrapper'));

interface TimeCardInspectorProps {
  title: string;
  barcodeEnabled: boolean;
  toggleBarcode: () => void;
  open: boolean;
  onClose: () => void;
  headerIcon?: React.ReactElement;
  children: React.ReactNode;
  additionalButtons?: InspectorButtonOptionsI;
  data?: LocationT[] | undefined;
  lockedInspector?: boolean;
  disableMap?: boolean;
}

const contentSx: SxProps<Theme> = {
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  justifyContent: 'flex-start',
  flexGrow: 1,
  flexShrink: 1,
};

const mapSx: SxProps<Theme> = {
  flexGrow: 0,
  flexShrink: 0,
  flexBasis: (theme) => theme.spacing(41),
};

const TimeCardInspector = (props: TimeCardInspectorProps) => {
  const {
    title,
    open,
    onClose,
    children,
    barcodeEnabled,
    toggleBarcode,
    headerIcon,
    additionalButtons,
    data,
    lockedInspector,
    disableMap,
  } = props;

  const settingsContext = useContext(SettingsContext);

  const [mapEnabled, setMapEnabled] = useLocalStorage('map-enabled', false);

  const [map, setMap] = useState<Map>();

  const _toggleMap = useCallback(() => {
    setMapEnabled((bc) => !bc);
  }, [setMapEnabled]);

  const coordinates = useMemo(() => {
    if (data && data.length > 0) {
      const value = data[0]?.timecardCoords || data[0]?.punchInCoords;
      return value?.split(', ').map((d: string) => parseFloat(d));
    }
  }, [data]);

  const BarcodeButtonIcon = useMemo(() => {
    const handleClick = () => {
      startTransition(() => {
        toggleBarcode();
      });
    };

    return {
      name: 'barcode',
      icon: (
        <BarcodeScannerStatusIcon
          cameraEnabled={barcodeEnabled && !lockedInspector}
        />
      ),
      onClick: handleClick,
      disabled: lockedInspector,
    };
  }, [toggleBarcode, barcodeEnabled, lockedInspector]);

  const MapButtonIcon = useMemo(() => {
    const handleClick = () => {
      startTransition(() => {
        _toggleMap();
      });
    };

    return {
      name: 'map',
      icon: (
        <MapViewInspectorIcon mapEnabled={mapEnabled && !lockedInspector} />
      ),
      onClick: handleClick,
      disabled: lockedInspector,
    };
  }, [_toggleMap, mapEnabled, lockedInspector]);

  const returnButtonOptions = useCallback(() => {
    const isGeotaggingEnabled =
      settingsContext.settings.geotaggingMode !== 'Disabled';
    const mapButton =
      isGeotaggingEnabled && !disableMap && coordinates ? [MapButtonIcon] : [];
    const buttons = [BarcodeButtonIcon, ...mapButton];

    return additionalButtons ? [...buttons, additionalButtons] : buttons;
  }, [
    BarcodeButtonIcon,
    MapButtonIcon,
    additionalButtons,
    coordinates,
    disableMap,
    settingsContext.settings.geotaggingMode,
  ]);

  const buttonOptions = returnButtonOptions();

  return (
    <Inspector
      headerIcon={headerIcon}
      onClose={onClose}
      open={open}
      options={buttonOptions}
      title={{ text: title }}
    >
      <Box sx={contentSx}>
        {children}

        {mapEnabled &&
          data &&
          !disableMap &&
          !lockedInspector &&
          coordinates && (
            <Box sx={mapSx}>
              <Suspense fallback={<LoadingIcon />}>
                <MapWrapper
                  coords={coordinates as LatLngExpression}
                  data={data}
                  map={map}
                  setMap={setMap}
                  zoom={12}
                />
              </Suspense>
            </Box>
          )}
      </Box>
    </Inspector>
  );
};

export default TimeCardInspector;
