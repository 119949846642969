import {
  Box,
  Popover,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Theme,
  useTheme,
} from '@mui/material';
import React, { useContext, useMemo } from 'react';
import { valueFormatHelper } from '../../../../helpers/valueFormatHelper';
import SettingsContext from '../../../../contexts/SettingsContext';

// eslint-disable-next-line no-unused-vars
const totalTableSx = (theme: Theme) => ({
  '.MuiTableCell-root': {
    borderBottom: 'none',
  },
});

interface GroupingTotalsMobilePopoverProps {
  isMobile: boolean;
  anchorEl: HTMLButtonElement | null;
  // eslint-disable-next-line no-unused-vars
  setAnchorEl: (value: React.SetStateAction<HTMLButtonElement | null>) => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  rowProps: any;
}

const GroupingTotalsMobilePopover = (
  props: GroupingTotalsMobilePopoverProps
) => {
  // eslint-disable-next-line no-unused-vars
  const { isMobile, anchorEl, setAnchorEl, rowProps } = props;
  const theme = useTheme();
  const settingsContext = useContext(SettingsContext);

  const { groupColumnSummary } = rowProps.data;

  const columns = useMemo(() => {
    const toProcess: string[] = [];

    if (!groupColumnSummary) return toProcess;

    Object.keys(groupColumnSummary).forEach((k) => {
      if (groupColumnSummary[k] !== undefined) toProcess.push(k);
    });

    return (
      Object.values(rowProps.columnsMap)
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        .filter((d: any) => toProcess.includes(d.name))
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        .map((col: any) => ({
          ...col,
          value: groupColumnSummary[col.name],
        }))
    );
  }, [groupColumnSummary, rowProps.columnsMap]);

  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {isMobile && (
        <Popover
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          onClose={() => setAnchorEl(null)}
          open={Boolean(anchorEl)}
        >
          <Box
            data-testid="GroupingTotalsMobilePopover"
            sx={{
              padding: theme.spacing(1),
            }}
          >
            <TableContainer component={Box} sx={totalTableSx}>
              <Table>
                <TableBody>
                  {columns.map((col) => (
                    <TableRow key={col.id}>
                      <TableCell size="small">
                        <b>{col.title}:</b>
                      </TableCell>
                      <TableCell align="right" size="small">
                        {valueFormatHelper(col, settingsContext)}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </Popover>
      )}
    </>
  );
};

export default GroupingTotalsMobilePopover;
