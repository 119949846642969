import AddRoundedIcon from '@mui/icons-material/AddRounded';
import ContentPasteGoRoundedIcon from '@mui/icons-material/ContentPasteGoRounded';
import InsertChartRoundedIcon from '@mui/icons-material/InsertChartRounded';
import PlaylistAddRoundedIcon from '@mui/icons-material/PlaylistAddRounded';
import PlaylistRemoveRoundedIcon from '@mui/icons-material/PlaylistRemoveRounded';
import { Popover } from '@mui/material';
import {
  ETOButton,
  ETOMenu,
  MenuOption,
  MessageContext,
  YesNoConfirmDialog,
} from '@teto/react-component-library-v2';
import React, {
  RefObject,
  SyntheticEvent,
  useCallback,
  useContext,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { Licenses, Permission, getGraphQLClient } from 'teto-client-api';
import {
  ProcessScheduleHeader,
  ProcessScheduleTemplateOptions,
} from '../../../../../../__generated__/graphql';
import getErrors from '../../../../../../api/graphQL/getErrors';
import AuthContext from '../../../../../../contexts/AuthContext';
import useIsMobile from '../../../../../../hooks/useIsMobile';
import ActionBar from '../../../../../ActionBar/ActionBar';
import ProcessScheduleTemplateTree from '../../../../../ProcessScheduleTemplate/ProcessScheduleTemplateTree';
import GridCommonMobileButtons from '../../../../../TETOGridGraphQL/components/GridCommonMobileButtons/GridCommonMobileButtons';
import TETOGridRefType from '../../../../../TETOGridGraphQL/types/TETOGridRefType';
import applyTemplateMutation from '../../../queries/applyTemplateMutation';
import exportReportQuery from '../../../queries/exportReportQuery';
import getPSReports from '../../../queries/getPSReports';
import initializePSMutation from '../../../queries/initializePSMutation';
import unInitializePSMutation from '../../../queries/unInitializePSMutation';
import AddDetailItem from './AddDetailItemForm';

export interface ProcessScheduleActionBarProps {
  setConfigureInspectorOpen: React.Dispatch<React.SetStateAction<boolean>>;
  isInitialized: boolean;
  gridRef: RefObject<TETOGridRefType>;
  processSchedule: ProcessScheduleHeader;
  setIsInitialized: React.Dispatch<React.SetStateAction<boolean>>;
  // eslint-disable-next-line no-unused-vars
  setResetGrid: (reset: boolean) => void;
}

const actionBarBottomFixSx = { mb: 2 };

const ProcessScheduleActionBar = (props: ProcessScheduleActionBarProps) => {
  const {
    setResetGrid,
    setConfigureInspectorOpen,
    isInitialized,
    gridRef,
    processSchedule,
    setIsInitialized,
  } = props;

  const { t } = useTranslation();
  const messageContext = useContext(MessageContext);
  const authContext = useContext(AuthContext);
  const reportBtnRef = useRef(null);

  const [reportMenu, setReportMenu] = useState<MenuOption[]>();
  const [selectedPST, setSelectedPST] = useState<number>();
  const [isApplyTemplateWarningOpen, setIsApplyTemplateWarningOpen] =
    useState<boolean>(false);
  const [isUninitializeDialogOpen, setIsUninitializeDialogOpen] =
    useState<boolean>(false);
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null | undefined>(
    undefined
  );
  const [isAddPSItemDialogOpen, setIsAddPSItemDialogOpen] = useState(false);

  const open = Boolean(anchorEl);
  const isMobile = useIsMobile();
  const processScheduleHeaderId = processSchedule?.id;

  const { canAddDetails } = useMemo(
    () => ({
      canAddDetails:
        authContext.hasLicense(Licenses.TotalETOProfessional) &&
        authContext.hasPermission(
          Permission.Add_Procurement_ProcessSchedules_ProcessScheduleDetails
        ),
    }),
    [authContext]
  );

  const _applyTemplate = (val?: number) => {
    getGraphQLClient()
      .performMutation(applyTemplateMutation, {
        input: {
          processScheduleId: processScheduleHeaderId,
          existingProcessScheduleId: processScheduleHeaderId,
          processScheduleTemplateId: selectedPST ?? val,
          applyProcessScheduleTemplateOptions:
            ProcessScheduleTemplateOptions.UseSpecific,
        },
      })
      .then((res) => {
        if (res.hasError()) {
          res.showAllSystemErrors(messageContext.setError);
          if (res.hasValidationErrors()) {
            messageContext.setError(getErrors(res.validationErrors));
          }
          return;
        }
        messageContext.setSuccess(
          `${t('generic.message.applyTemplateSuccess')}`
        );
        gridRef.current?.refreshDataSource();
      });
  };

  const _handleApplyTemplateClick = (
    e: SyntheticEvent<Element, Event> | undefined
  ) => {
    setAnchorEl(e?.currentTarget.parentElement);
  };

  const _handlePSTClose = () => {
    setAnchorEl(undefined);
  };

  const _handlePSTSelect = (val: number) => {
    setAnchorEl(undefined);
    setSelectedPST(val);
    const data = gridRef.current?.getGrid()?.dataSource as unknown[];
    if (data.length !== 0) {
      setIsApplyTemplateWarningOpen(true);
      return;
    }
    _applyTemplate(val);
  };

  const _handleReportExport = useCallback(
    (reportId: number, alternateId: number) =>
      getGraphQLClient()
        .performQuery(exportReportQuery, {
          id: reportId,
          input: {
            alternateId,
            parameters: {
              nvcProcessScheduleID: `|${processScheduleHeaderId}|`,
              MachineCaption: 'Spec',
            },
          },
          companyDetails: true,
        })
        .then((d) => {
          if (d.hasError()) {
            d.showAllSystemErrors(messageContext.setError);
            if (d.hasValidationErrors()) {
              const { input } = d.validationErrors;
              messageContext.setError(Object.values(input)[0] as string);
            }
            return;
          }

          if (d.hasData()) {
            if (d.data.exportReport.path) {
              const data = {
                companyDetails: true.toString(),
                alternateId: alternateId.toString(),
                entityId: d.data.exportReport.reportId.toString(),
                reportId: d.data.exportReport.reportId.toString(),
                type: 'generic',
              };
              const convertParamsToObj =
                d?.data?.exportReport?.parameters?.reduce((acc, current) => {
                  acc[current.key] = current.value;
                  return acc;
                }, {});

              const reportParams = encodeURIComponent(
                JSON.stringify(convertParamsToObj)
              );
              const queryString = new URLSearchParams(data).toString();
              window.open(
                `/reportViewer?url=${encodeURIComponent(
                  d.data.exportReport.path
                )}&${queryString}&reportParams=${reportParams}`,
                '_blank'
              );
            }
          }
        }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [processScheduleHeaderId]
  );

  const _handleReportButtonClick = useCallback(() => {
    getGraphQLClient()
      .performQuery(getPSReports, {
        id: 230,
      })
      .then((d) => {
        if (d.hasError()) {
          if (d.systemErrors)
            messageContext.setError(getErrors(d.systemErrors));
          if (d.validationErrors)
            messageContext.setError(getErrors(d.validationErrors));
          return;
        }

        if (d.hasData()) {
          const processScheduleDetailedReport = {
            label: `${d.data.employeeReport?.reportDisplayName} (Default)`,
            disabled: !d.data.employeeReport?.allowView,
            onClick: () =>
              _handleReportExport(
                d.data.employeeReport?.reportID,
                d.data.employeeReport?.alternateId
              ),
          };

          const processScheduleTravelerReport = {
            label: d.data.reportAlternates?.items?.[0]
              ?.reportDisplayName as string,
            disabled: !d.data.reportAlternates?.items?.[0]?.enabled,
            onClick: () =>
              _handleReportExport(
                d.data.reportAlternates?.items?.[0]?.reportId as number,
                d.data.reportAlternates?.items?.[0]?.id as number
              ),
          };

          const reports = [
            processScheduleDetailedReport,
            processScheduleTravelerReport,
          ];

          setReportMenu(reports);
        }
        return d;
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [_handleReportExport]);

  const _handleInitializePS = useCallback(() => {
    getGraphQLClient()
      .performMutation(initializePSMutation, {
        input: {
          items: [{ processScheduleHeaderId }],
        },
      })
      .then((res) => {
        if (res.hasError()) {
          res.showAllSystemErrors(messageContext.setError);
          if (res.hasValidationErrors()) {
            messageContext.setError(getErrors(res.validationErrors));
          }
          return;
        }
        messageContext.setSuccess(
          `${t('generic.message.initializePSSuccess')}`
        );
        gridRef.current?.refreshDataSource();
        setIsInitialized(true);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [processScheduleHeaderId]);

  const _handleUninitializePS = () => {
    getGraphQLClient()
      .performMutation(unInitializePSMutation, {
        input: {
          processScheduleHeaderId,
        },
      })
      .then((res) => {
        if (res.hasError()) {
          res.showAllSystemErrors(messageContext.setError);
          if (res.hasValidationErrors()) {
            messageContext.setError(getErrors(res.validationErrors));
          }
          return;
        }
        messageContext.setSuccess(
          `${t('generic.message.unInitializePSSuccess')}`
        );
        gridRef.current?.refreshDataSource();
        setIsInitialized(false);
      });
  };
  const GridCommonBtns = GridCommonMobileButtons({
    refreshGrid: () => setResetGrid(true),
    gridRef,
    setConfigureInspector: () => setConfigureInspectorOpen(true),
  });

  const ReportsBtn = useMemo(
    () => ({
      title: t('generic.reports'),
      icon: <InsertChartRoundedIcon />,
      onclick: _handleReportButtonClick,
      customComponent: (
        <ETOButton
          buttonProps={{
            ref: reportBtnRef,
          }}
          color="primary"
          icon={<InsertChartRoundedIcon />}
          key={t('generic.reports')}
          onClick={_handleReportButtonClick}
          size="medium"
        >
          {t('generic.reports')}
        </ETOButton>
      ),
    }),
    [_handleReportButtonClick, t]
  );

  const InitializeBtn = useMemo(
    () => ({
      title: `${t('generic.initialize')} ${t('pages.processSchedule.PS')}`,
      icon: <PlaylistAddRoundedIcon />,
      onclick: () => _handleInitializePS(),
      disabled: Boolean(processSchedule?.startDate),
    }),
    [_handleInitializePS, t, processSchedule?.startDate]
  );
  const UninitializeBtn = useMemo(
    () => ({
      title: `${t('generic.uninitialize')} ${t('pages.processSchedule.PS')}`,
      icon: <PlaylistRemoveRoundedIcon />,
      onclick: () => setIsUninitializeDialogOpen(true),
      disabled:
        processSchedule?.startDate === null ||
        processSchedule?.completionDate === null,
    }),
    [t, processSchedule?.startDate, processSchedule?.completionDate]
  );
  const ApplyTemplateBtn = useMemo(
    () => ({
      title: `${t('generic.apply')} ${t('generic.template')}`,
      icon: <ContentPasteGoRoundedIcon />,
      onclick: (e: SyntheticEvent<Element, Event> | undefined) =>
        _handleApplyTemplateClick(e),
      disabled: Boolean(processSchedule?.startDate),
    }),
    [t, processSchedule?.startDate]
  );
  const AddDetailItemBtn = useMemo(
    () => ({
      title: `${t('generic.add')} ${t('generic.item')} to ${t('generic.grid')}`,
      icon: <AddRoundedIcon />,
      onclick: () => setIsAddPSItemDialogOpen(true),
    }),
    [t]
  );

  const rightChildren = useMemo(() => {
    const _btns = [];

    if (isInitialized) {
      _btns.push(UninitializeBtn);
    } else {
      _btns.push(InitializeBtn);
      _btns.push(ApplyTemplateBtn);
    }
    if (isMobile) {
      _btns.push(AddDetailItemBtn);
    }

    return [..._btns, ReportsBtn, GridCommonBtns];
  }, [
    AddDetailItemBtn,
    ApplyTemplateBtn,
    GridCommonBtns,
    InitializeBtn,
    ReportsBtn,
    UninitializeBtn,
    isInitialized,
    isMobile,
  ]);

  return (
    <>
      <ActionBar
        headerStyles={actionBarBottomFixSx}
        rightChildren={rightChildren}
      />
      {reportMenu && (
        <ETOMenu
          anchorEl={reportBtnRef?.current}
          menuOptions={reportMenu as MenuOption[]}
          open={Boolean(reportMenu)}
          setClose={() => setReportMenu(undefined)}
          sx={{
            zIndex: 2,
          }}
        />
      )}
      {anchorEl && (
        <Popover
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: isMobile ? 40 : 'bottom',
            horizontal: 'right',
          }}
          id="pst-btn"
          onClose={() => _handlePSTClose()}
          open={open}
          PaperProps={{
            style: { minWidth: 400 },
          }}
          sx={{ marginTop: 1, minWidth: 400 }}
          transformOrigin={{
            vertical: isMobile ? 40 : 'top',
            horizontal: isMobile ? 'left' : 'right',
          }}
        >
          <ProcessScheduleTemplateTree
            onPSTClicked={(val) => _handlePSTSelect(val)}
          />
        </Popover>
      )}

      <YesNoConfirmDialog
        content={t('dialogs.processSchedules.applyTemplateWarning')}
        onNo={() => setIsApplyTemplateWarningOpen(false)}
        onYes={() => {
          _applyTemplate();
          setIsApplyTemplateWarningOpen(false);
        }}
        open={isApplyTemplateWarningOpen}
        title={`${t('generic.apply')} ${t('generic.template')}`}
      />
      <YesNoConfirmDialog
        content={`${t('dialogs.processSchedules.unInitializePS')}`}
        onNo={() => setIsUninitializeDialogOpen(false)}
        onYes={() => {
          _handleUninitializePS();
          setIsUninitializeDialogOpen(false);
        }}
        open={isUninitializeDialogOpen}
        title={`${t('generic.uninitialize')} ${t(
          'entities:ProcessScheduleHeader.ProcessSchedule'
        )}`}
      />

      {isAddPSItemDialogOpen && (
        <AddDetailItem
          canAddDetails={canAddDetails}
          isAddPSItemDialogOpen={isAddPSItemDialogOpen}
          pSHId={processScheduleHeaderId}
          setIsAddPSItemDialogOpen={setIsAddPSItemDialogOpen}
        />
      )}
    </>
  );
};

export default ProcessScheduleActionBar;
