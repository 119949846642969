import { gql } from '../../../__generated__';
import SelectDefinition from './SelectDefinition';

export default {
  query: gql(`query getActiveSuppliers {
                companies(
                  order: { name: ASC},
                  where: {
                    active: { eq: true }
                    or: [{ supplier: { id: { gte: 0 } } }, { supplier: { id: { lt: 0 } } }]
                  }
                ) {
                  items {
                    id
                    name
                    phone
                    city
                    state
                    zip
                    supplier {
                      supQaapproved
                      id
                      defaultCurrNameId
                      poreportAlternateId
                    }
                  }
                }
              }`),
  valueField: 'supplier.id',
  labelField: 'name',
} as SelectDefinition;
