import {
  ETOSelectField,
  MessageContext,
} from '@teto/react-component-library-v2';
import { useFormikContext } from 'formik';
import React, { ChangeEvent, useCallback, useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import getErrors from '../../../api/graphQL/getErrors';
import { getGraphQLClient } from '../../../api/graphQL/graphQLClient';
import { companyTypes } from './companyReportConstants';

const companyTypesQuery = `query {
  companyTypes {
    items {
      ctype
    }
  }
}`;

const PhoneBookTypeForm = () => {
  const formik = useFormikContext<{ [companyTypes]: string }>();
  const { t } = useTranslation();
  const messageContext = useContext(MessageContext);

  const cTypes = useQuery(
    ['company-types'],
    () =>
      getGraphQLClient()
        .performQuery(
          companyTypesQuery,
          {},
          (err) => messageContext.setError(err.messages[0]),
          (err) => {
            const errors = getErrors(err);
            messageContext.setError(errors);
          }
        )
        .then((d) =>
          d.companyTypes.items.map((i: { ctype: string }) => i.ctype)
        ),
    {
      enabled: true,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
    }
  );

  const localValue = useMemo(() => {
    const value = formik.values[companyTypes] ?? [];
    return typeof value === 'string' ? value.split(',') : value;
  }, [formik.values]);

  const _handleChange = useCallback(
    (e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
      const { name, value } = e.target;
      const newValue = (value as unknown as string[]).join(',');
      formik.setFieldValue(name, newValue === '' ? null : newValue);
    },
    [formik]
  );

  return (
    <ETOSelectField
      defaultValue={[]}
      error={formik.errors[companyTypes]}
      handleChange={_handleChange}
      itemNameSelector={(i) => i as string}
      items={cTypes.data ?? []}
      itemValueSelector={(i) => i}
      label={t('entities:CompanyType.CompanyType')}
      multiple
      name={companyTypes}
      size="small"
      value={localValue ?? []}
    />
  );
};

export default PhoneBookTypeForm;
