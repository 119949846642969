import {
  TypeFilterValue,
  TypeGroupBy,
  TypeSingleFilterValue,
  TypeSortInfo,
} from '@inovua/reactdatagrid-community/types';
import { isArray } from 'lodash';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { TFunction } from 'react-i18next';
import { getGridSettings, updateGridSettings } from 'teto-client-api';
import useLocalStorage from 'use-local-storage';
import getErrors from '../../../api/graphQL/getErrors';
import { getGraphQLClient } from '../../../api/graphQL/graphQLClient';
import AuthContext from '../../../contexts/AuthContext';
import SettingsContext from '../../../contexts/SettingsContext';
import useDebounce from '../../../hooks/useDebounce';
import useStateWhenChanged from '../../../hooks/useStateWhenChanged';
import {
  ColumnSizing,
  GridPersistence,
} from '../../TetoGrid/GridPersistence/GridPersistenceOptions';
import deepPropertyHelper from '../Formatters/deepPropertyHelpers';
import { GridBuilder } from '../GridBuilder/GridBuilder';
import { TETODataColumn } from '../GridBuilder/types/TETODataColumn';
import buildQuery from '../QueryBuilder/queryBuilder';
import { EditedValue } from '../types/EditedValue';
import { MandatoryFilters } from '../types/MandatoryFilterTypes';
import {
  ChangeTracker,
  GridEditingProps,
  GridProps,
} from '../types/TetoGridGraphqlProps';
import { UpdateColumnConfig, UpdateColumnHeader } from './UpdateColumnConfig';

const calculateFilterDetails = (col: TETODataColumn) => {
  if (col.type === 'string' || col.type === 'foreignKey') {
    return {
      operator: 'contains',
      type: 'string',
    };
  }
  if (col.type === 'number' || col.type === 'hours') {
    return {
      operator: 'eq',
      type: 'number',
    };
  }
  if (col.type === 'bool' || col.type === 'boolean') {
    return {
      operator: 'eq',
      type: 'boolean',
    };
  }
  if (col.type === 'custom') {
    return {
      operator: 'contains',
      type: 'string',
    };
  }
  if (col.type === 'time') {
    return {
      operator: 'eq',
      type: 'time',
    };
  }
  if (col.type === 'datetime') {
    return {
      operator: 'eq',
      type: 'datetime',
    };
  }
  if (col.type === 'date') {
    return {
      operator: 'eq',
      type: 'date',
    };
  }

  return {
    operator: 'eq',
    type: 'string',
  };
};

const handlePostFilterOperations = (
  columns: TETODataColumn[],
  filters: TypeFilterValue
) => {
  if (!filters) return filters;

  let newFilters = [...filters];

  columns
    .filter((a) => a.customFilterFormatter)
    .forEach((c) => {
      newFilters = c.customFilterFormatter?.(c, newFilters) ?? newFilters;
    });

  return newFilters;
};

const handlePostSortOperations = (
  columns: TETODataColumn[],
  sort: TypeSortInfo
) => {
  if (!sort) return sort;

  let newSort: TypeSortInfo = [...(isArray(sort) ? sort : [sort])];

  columns
    .filter((a) => a.customSortFormatter)
    .forEach((c) => {
      newSort = c.customSortFormatter?.(c, newSort) ?? newSort;
    });

  return newSort;
};

const saveSettings = (
  persistenceName: string,
  settings: GridPersistence
): Promise<void> =>
  updateGridSettings(persistenceName, JSON.stringify(settings));

const loadSettings = (persistenceName: string): Promise<GridPersistence> =>
  getGridSettings(persistenceName).then(
    (a) => JSON.parse(a.gridContent) as GridPersistence
  );

export type NullableTypeSingleFilterValue =
  | (TypeSingleFilterValue & {
      nullable: boolean;
    })
  | null;

export function applyServerSideFilters(
  filters: NullableTypeSingleFilterValue[] | null
): TypeFilterValue {
  if (!filters) return [];

  const newFilters = filters.filter((filter) => {
    if (filter?.nullable && filter.value !== undefined) {
      return true; // Keep the filter if it's nullable and the value is not undefined
    }
    if (!filter?.nullable) {
      return filter?.value !== null && filter?.value !== undefined; // Keep the filter if it's not nullable and the value is neither null nor undefined
    }
    return false;
  });
  if (!newFilters || newFilters.length === 0) {
    return [];
  }
  return newFilters as TypeFilterValue;
}

export function getCommonFilters(
  columns: TETODataColumn[]
): TypeSingleFilterValue[] {
  const allowedTypes = [
    'string',
    'foreignKey',
    'number',
    'hours',
    'currency',
    'custom',
    'datetime',
    'date',
    'time',
  ];
  return columns
    .filter(
      (column) => column.filterOptions && allowedTypes.includes(column.type)
    )
    .map((column) => ({
      nullable: column.nullable,
      name: column.name,
      ...calculateFilterDetails(column),
      value: '',
      emptyValue: '',
    }));
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const useGrid = (
  persistenceName: string,
  queryRoot: string,
  // eslint-disable-next-line no-unused-vars
  onError: (err: Error) => void,
  t: TFunction<'translation', undefined>,
  alwaysProjectColumns: string[] = [],
  // eslint-disable-next-line no-unused-vars
  gridBuilder: GridBuilder | undefined = undefined,
  {
    filterAndSortMode = 'serverSide',
    mandatoryFilter,
    clientSideFilter,
    usePersistentFilters,
  }: {
    filterAndSortMode: 'serverSide';
    mandatoryFilter?: MandatoryFilters;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any, no-unused-vars
    clientSideFilter?: (data: any) => boolean;
    usePersistentFilters?: boolean;
  } = { filterAndSortMode: 'serverSide' },
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  queryVariables: {
    [key: string]: {
      type: string;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      value: any;
    };
  } = {},
  disabled: boolean | undefined = undefined,
  disableQuery: boolean | undefined = undefined,
  customQuery: string | undefined = undefined
) => {
  const authContext = useContext(AuthContext);
  const settingsContext = useContext(SettingsContext);

  const [columns, setColumns] = useState<TETODataColumn[]>([]);
  const [grouping, setGrouping] = useState<TypeGroupBy>([]);
  let [serverSideFilters, setServerSideFilters] = useState<TypeFilterValue>([]);

  if (usePersistentFilters) {
    [serverSideFilters, setServerSideFilters] =
      // eslint-disable-next-line react-hooks/rules-of-hooks, @typescript-eslint/no-explicit-any
      useLocalStorage<TypeFilterValue | any>('server-side-filters', []); // Use useLocalStorage
  }

  const [serverSideSort, setServerSideSort] = useState<TypeSortInfo | null>([]);

  const [queryLoading, setQueryLoading] = useState(false);
  const [metaDataLoading, setMetaDataLoading] = useState(false);
  const [metadataLoaded, setMetadataLoaded] = useState(false);

  const [query, setQuery] = useStateWhenChanged<string | null>(null);
  const [queryToken, setQueryToken] = useState<Date | null>(null);

  const debouncedColumns = useDebounce(columns, 250);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [dataSourceRaw, setDataSourceRaw] = useState<any[]>([]);

  const [changes, setChanges] = useState<ChangeTracker>({});

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const dataSource = useMemo<any[]>(() => {
    if (!clientSideFilter) return dataSourceRaw;
    return dataSourceRaw.filter(clientSideFilter);
  }, [dataSourceRaw, clientSideFilter]);

  const metaDataLoadingStatus = useMemo(() => {
    if (!metadataLoaded) return true;
    return metaDataLoading;
  }, [metaDataLoading, metadataLoaded]);

  const queryLoadingStatus = useMemo(() => {
    if (!metadataLoaded) return true;
    return queryLoading;
  }, [metadataLoaded, queryLoading]);

  const visibleColumns = useMemo(
    () =>
      debouncedColumns.filter(
        (a: { hidden?: boolean; type: string }) => !a.hidden
      ),
    [debouncedColumns]
  );

  const sort = useMemo(
    () => (filterAndSortMode === 'serverSide' ? serverSideSort : []),
    [filterAndSortMode, serverSideSort]
  );

  const settings = useMemo(() => {
    const cols = visibleColumns.sort((a, b) => a.order - b.order);
    return {
      columnOrder: cols.map((a) => a.name),
      columnSizesNew: visibleColumns.map((a) => ({
        width: a.width ?? a.defaultWidth,
        flex: a.flex,
        columnName: a.name,
      })),
      columnSizes: [],
      filters: [],
      grouping,
      hidden: [],
      visible: visibleColumns.map((a) => a.name),
      leftLockedColumns: visibleColumns
        .filter((a) => a.locked === 'start')
        .map((a) => a.name),
      rightLockedColumns: visibleColumns
        .filter((a) => a.locked === 'end')
        .map((a) => a.name),
      sort,
    };
  }, [grouping, sort, visibleColumns]);

  const debouncedSettings = useDebounce(settings, 1000);

  useEffect(() => {
    if (metadataLoaded && debouncedSettings.visible.length > 0)
      saveSettings(persistenceName, {
        ...debouncedSettings,
      });
  }, [persistenceName, debouncedSettings, metadataLoaded]);

  const primaryKey = useMemo(
    () => debouncedColumns.find((a) => a.isPrimaryKey && !a.parentName)?.name,
    [debouncedColumns]
  );

  const getChangedDataSourceEntries = useCallback(() => {
    if (!primaryKey) return [];

    const ids = Object.entries(changes).map((a) => a[0]);
    return dataSource
      .filter(
        (a) => ids.indexOf(deepPropertyHelper(primaryKey, a)?.toString()) >= 0
      )
      .map((c) => {
        const change = changes[deepPropertyHelper(primaryKey, c)];
        if (!change) return c;

        const changeReduced = Object.entries(change).reduce(
          (pv, cv) => ({ ...pv, [cv[0]]: cv[1].value }),
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          {} as { [key: string]: any }
        );

        return {
          ...c,
          ...changeReduced,
        };
      });
  }, [changes, dataSource, primaryKey]);

  // no matter what lint tells you do not wrap this in a callback
  // it will create a circular reference and everyone will have a bad time
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const _updateDataSource = (byPassValueCheck = false) => {
    // the query should only fire off if there is visible columns,
    // otherwise it will cause the query to fire off twice because of the alwaysProjectColumns bug #75541
    if (visibleColumns.length === 0) {
      return;
    }

    const projectedColumns = [...alwaysProjectColumns];

    visibleColumns.forEach((visCols) => {
      if (visCols.linkTo) {
        const linkedColumn = columns.find((col) => {
          if (
            col.linkTo?.type === visCols.linkTo?.type &&
            col.name !== visCols.name
          ) {
            if (col.type !== 'object') {
              return col;
            }
          }
          return null;
        });
        if (linkedColumn) {
          projectedColumns.push(linkedColumn.name);
        }
      }
    });

    const cols = [
      ...visibleColumns
        .filter((a) => a.columnType === 'metadata')
        .map((a) => a.name),
      ...projectedColumns,
      ...(isArray(grouping) ? grouping.filter((a) => a) : [grouping ?? '']),
    ];

    if (primaryKey) cols.push(primaryKey);

    setQuery(
      customQuery ??
        buildQuery(
          cols,
          queryRoot,
          handlePostSortOperations(visibleColumns, serverSideSort),
          handlePostFilterOperations(
            visibleColumns,
            applyServerSideFilters(
              serverSideFilters as NullableTypeSingleFilterValue[]
            )
          ),
          mandatoryFilter,
          queryVariables,
          {
            pageSize: settingsContext.settings?.gridMaxRows ?? 10000,
          }
        ),
      byPassValueCheck
    );
  };

  const manuallyUpdateDataSource = () => {
    setQueryToken(new Date());
    _updateDataSource(true);
  };

  useEffect(() => {
    if (!metadataLoaded || !gridBuilder || disabled) return;
    _updateDataSource();
  }, [metadataLoaded, gridBuilder, _updateDataSource, query, disabled]);

  const _calculateLocked = useCallback(
    (colName: string, leftLocked: string[], rightLocked: string[]) => {
      if (leftLocked.indexOf(colName) >= 0) return 'start';
      if (rightLocked.indexOf(colName) >= 0) return 'end';
      return undefined;
    },
    []
  );

  /*
    Using gridBuilder to configure the overall state of the grid
    If no arguments are passed default configuration will be used
  */
  const _configureGrid = useCallback(
    (overrides?: {
      sort?: TypeSortInfo;
      grouping?: TypeGroupBy;
      visible?: string[];
      leftLocked?: string[];
      rightLocked?: string[];
      columnSizes?: (Omit<ColumnSizing, 'column'> & {
        columnName: string;
      })[];
    }) => {
      if (!gridBuilder) return;

      if (filterAndSortMode === 'serverSide') {
        setServerSideSort(
          overrides?.sort ?? gridBuilder?.defaultSort ?? ([] as TypeSortInfo)
        );
      }

      setGrouping(overrides?.grouping ?? gridBuilder?.defaultGrouping ?? []);

      const cols = (gridBuilder?.columns ?? [])
        .sort((a, b) => {
          const aVisible = overrides?.visible
            ? overrides?.visible.indexOf(a.name) >= 0
            : a.showByDefault;

          const bVisible = overrides?.visible
            ? overrides?.visible.indexOf(b.name) >= 0
            : b.showByDefault;

          if (!aVisible && !bVisible) return 0;
          if (bVisible && !aVisible) return 1;
          if (aVisible && !bVisible) return -1;

          return overrides?.visible
            ? overrides.visible.indexOf(a.name) -
                overrides.visible.indexOf(b.name)
            : a.order - b.order;
        })
        .map((c, i) => ({
          ...c,
          // if the col is included in the mandatoryFilter then the property `filterOptions` is removed because the mandatory filter supersedes the col, otherwise it just defaults it to c.filterable
          filterOptions:
            mandatoryFilter?.find((a) => a.name === c.name) === undefined
              ? c.filterOptions
              : undefined,
          order: i,
          hidden: overrides?.visible
            ? overrides?.visible.indexOf(c.name) === -1
            : !c.showByDefault,
          title: t(c.title),
          header: t(c.title),
          width:
            overrides?.columnSizes?.find((a) => a.columnName === c.name)
              ?.width ?? c.defaultWidth,
          locked: _calculateLocked(
            c.name,
            (overrides?.leftLocked &&
              overrides?.leftLocked.indexOf(c.name) >= 0) ??
              c.locked === 'start'
              ? [c.name]
              : [],
            (overrides?.rightLocked &&
              overrides?.rightLocked.indexOf(c.name) >= 0) ??
              c.locked === 'end'
              ? [c.name]
              : []
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
          ) as any,
        }));
      setColumns(cols);
    },
    [_calculateLocked, filterAndSortMode, gridBuilder, mandatoryFilter, t]
  );

  useEffect(() => {
    if (
      authContext.authenticated &&
      gridBuilder &&
      persistenceName &&
      !metadataLoaded
    ) {
      setMetaDataLoading(true);
      loadSettings(persistenceName)
        .then((s) => {
          _configureGrid({
            columnSizes: s.columnSizesNew,
            grouping: s.grouping,
            leftLocked: s.leftLockedColumns,
            rightLocked: s.rightLockedColumns,
            sort: s.sort,
            visible: s.visible,
          });
          setMetadataLoaded(true);
          setMetaDataLoading(false);
        })
        .catch(() => {
          setMetadataLoaded(true);
          setMetaDataLoading(false);
          _configureGrid();
        });
    }
  }, [
    authContext.authenticated,
    authContext,
    persistenceName,
    gridBuilder,
    _configureGrid,
    metadataLoaded,
  ]);

  const queryResultSelector = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (queryResults: any) => {
      if (!queryResults) {
        return [];
      }

      let currentPos = queryResults;
      const split = queryRoot.split('.');
      split.forEach((s) => {
        currentPos = currentPos[s];
      });

      if (!currentPos) return [];

      if (!isArray(currentPos))
        throw new Error(`Query failed invalid path ${queryRoot}`);

      return currentPos;
    },
    [queryRoot]
  );

  useEffect(() => {
    if (disableQuery) return;
    if (!query || !queryResultSelector) return;
    setQueryLoading(true);

    getGraphQLClient()
      .performQuery(
        query,
        queryVariables && Object.entries(queryVariables)?.length > 0
          ? Object.entries(queryVariables).reduce(
              (pv, nv) => ({
                ...pv,
                [nv[0]]: nv[1].value,
              }),
              {}
            )
          : undefined
      )
      .then((r) => {
        // if you are updating the dataSource the changes are no longer valid
        if (Object.keys(changes).length > 0) setChanges({});

        const dataSet = queryResultSelector(r);
        setDataSourceRaw(dataSet);
        setQueryLoading(false);
      })
      .catch((e) => {
        setQueryLoading(false);
        const errors = getErrors(e);

        if (e?.message) onError(e);
        else if (e?.errors?.[0]) {
          if (errors && errors !== '') {
            onError(new Error(errors));
          }
          if (!errors || errors === '') {
            onError(
              new Error(
                'Failed to build query. Please try resetting your grid settings.'
              )
            );
          }
        } else onError(e ?? 'Unknown error');
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query, queryResultSelector, setDataSourceRaw, queryToken]);

  const updateColumnConfiguration = useCallback(
    (updatedSettings: UpdateColumnConfig[]) => {
      if (!metadataLoaded || !gridBuilder) return;

      setColumns((oldVals) => {
        const columnObj = Object.fromEntries(
          updatedSettings.map((a) => [a.columnName, a])
        );
        return oldVals.map((a) => {
          if (Object.prototype.hasOwnProperty.call(columnObj, a.name)) {
            const colUpdate = columnObj[a.name];
            const item = { ...a };
            if (item && colUpdate.hidden !== undefined) {
              item.hidden = colUpdate.hidden;
              item.visible = !item.hidden;
            }
            if (colUpdate.order !== undefined) {
              item.order = colUpdate.order;
            }
            if (item.lockable) {
              item.locked = item.lockable ? true : colUpdate.locked;
            }
            if (colUpdate.width !== undefined) {
              item.width = colUpdate.width;
            }

            return item;
          }

          return { ...a };
        });
      });
    },
    [gridBuilder, metadataLoaded]
  );

  const updateColumnHeader = useCallback(
    (updatedHeaders: UpdateColumnHeader[]) => {
      if (!metadataLoaded || !gridBuilder) return;
      setColumns((prev) => {
        const columnObj = Object.fromEntries(
          updatedHeaders.map((h) => [h.columnName, h])
        );
        return prev.map((p) => {
          if (Object.prototype.hasOwnProperty.call(columnObj, p.name)) {
            const colUpdate = columnObj[p.name];
            const newCol = { ...p };
            if (colUpdate.title !== undefined) {
              newCol.title = colUpdate.title;
            }
            if (colUpdate.header !== undefined) {
              newCol.header = colUpdate.header;
            }
            return newCol;
          }
          return { ...p };
        });
      });
    },
    [gridBuilder, metadataLoaded]
  );

  const setFilters = useCallback(
    (filters: TypeFilterValue) => {
      if (filterAndSortMode === 'serverSide') {
        const mandatoryNames = mandatoryFilter?.map((a) => a.name);
        let newFilters = (
          filters ? [...(isArray(filters) ? filters : [filters])] : []
        ).filter((a) => !mandatoryNames || mandatoryNames.indexOf(a.name) < 0);

        if (mandatoryFilter && mandatoryNames) {
          newFilters = [...newFilters, ...mandatoryFilter];
        }

        setServerSideFilters(newFilters);
      }
    },
    [filterAndSortMode, setServerSideFilters, mandatoryFilter]
  );

  const setSort = useCallback(
    (sorting: TypeSortInfo) => {
      if (filterAndSortMode === 'serverSide') {
        setServerSideSort(
          sorting ? [...(isArray(sorting) ? sorting : [sorting])] : []
        );
      }
    },
    [filterAndSortMode]
  );

  const filters = useMemo(() => {
    if (filterAndSortMode !== 'serverSide') return [];

    /**
     * This is complicated because we need to ensure a filter object gets added
     * if columns are changed. Otherwise filtering will break on ReactDataGrid.
     * So we build a default filter list first and then apply the current filter settings over top.
     * This ensures this will update as columns update and keep filtering working
     */

    const commonFilters = getCommonFilters(columns);

    const bools: TypeSingleFilterValue[] = columns
      .filter(
        (a) => a.filterOptions && ['bool', 'boolean'].indexOf(a.type) >= 0
      )
      .map((c) => ({
        name: c.name,
        nullable: c.nullable,
        ...calculateFilterDetails(c),
        value: '',
        emptyValue: '',
      }));

    // merge boolean and other filters
    // then map them and overwrite with user defined filters as necessary
    return commonFilters.concat(bools).map((fi) => {
      const matching = serverSideFilters?.find((a) => a.name === fi.name);
      if (matching) return matching;
      return fi;
    });
  }, [columns, filterAndSortMode, serverSideFilters]);

  const _clearValidation = useCallback((pKey: string) => {
    setChanges((prevVal) => {
      const currentValues = { ...prevVal };
      if (currentValues[pKey]) {
        Object.keys(currentValues[pKey]).forEach((c1) => {
          currentValues[pKey][c1].validationMessages = [];
        });
      }
      return currentValues;
    });
  }, []);

  const _clearAllValidation = useCallback(() => {
    setChanges((prevVal) => {
      const currentValues = { ...prevVal };
      Object.keys(currentValues).forEach((c) => {
        Object.keys(currentValues[c]).forEach((c1) => {
          currentValues[c][c1].validationMessages = [];
        });
      });

      return currentValues;
    });
  }, []);

  const _updateOrCreateEditValue = useCallback(
    (primaryKeyValue: string, path: string, updates: Partial<EditedValue>) => {
      const pKeySafe =
        typeof primaryKeyValue !== 'string'
          ? // eslint-disable-next-line @typescript-eslint/no-explicit-any
            String(primaryKeyValue as any)
          : primaryKeyValue;

      setChanges((prevVal) => {
        const currentValues = { ...prevVal };

        if (currentValues?.[pKeySafe]?.[path]) {
          // edit mode, this is easy
          // eslint-disable-next-line no-param-reassign
          currentValues[pKeySafe][path] = {
            ...currentValues[pKeySafe][path],
            value: currentValues[pKeySafe][path].value,
            ...updates,
          };
        } else {
          // eslint-disable-next-line no-param-reassign
          if (!currentValues[pKeySafe]) currentValues[pKeySafe] = {};

          // eslint-disable-next-line no-param-reassign
          currentValues[pKeySafe][path] = {
            isValid:
              !updates.validationMessages ||
              updates.validationMessages.length === 0,
            validationMessages: updates.validationMessages ?? [],
            originalValue: updates.originalValue,
            value: updates.value,
            warningMessages: updates.warningMessages ?? [],
          } as EditedValue;
        }

        return currentValues;
      });
    },
    []
  );

  const _setValidationMessage = useCallback(
    (
      primaryKeyValue: string,
      path: string,
      error: string,
      isWarning = false
    ) => {
      _updateOrCreateEditValue(primaryKeyValue, path, {
        ...(isWarning
          ? { warningMessages: [error] }
          : { validationMessages: [error] }),
      });
    },
    [_updateOrCreateEditValue]
  );

  const _clearChange = useCallback((pKeyValue: string) => {
    setChanges((oldItems) => {
      const newObj = { ...oldItems };
      if (newObj[pKeyValue]) delete newObj.primaryKey;
      return newObj;
    });
  }, []);

  const _clearChanges = useCallback(() => {
    setChanges(() => ({}));
  }, []);

  const _hasChanges = useMemo(
    () => Object.entries(changes).length > 0,
    [changes]
  );

  return {
    gridProps: {
      allColumns: columns ?? [],
      dataSource,
      editingState: {
        changes,
        clearChange: _clearChange,
        clearChanges: _clearChanges,
        clearValidation: _clearValidation,
        clearAllValidation: _clearAllValidation,
        getChangedDataSourceEntries,
        hasChanges: _hasChanges,
        setChanges,
        setValidationMessage: _setValidationMessage,
        updateOrCreateEditValue: _updateOrCreateEditValue,
      } as GridEditingProps,
      filters,
      mandatoryFilters: mandatoryFilter,
      groupBy: grouping,
      idProperty: primaryKey,
      metaDataLoading: metaDataLoadingStatus,
      queryLoading: queryLoadingStatus,
      onGroupByChange: setGrouping,
      query,
      queryResultSelector,
      refreshDataSource: manuallyUpdateDataSource,
      setDefaults: _configureGrid,
      setFilters,
      setSort,
      sort,
      updateColumnConfiguration,
      updateColumnHeader,
      visibleColumns: visibleColumns ?? [],
    } as GridProps,
  } as const;
};

export default useGrid;
