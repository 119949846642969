import {
  TypeColumn,
  TypeSingleFilterValue,
  TypeSortInfo,
} from '@inovua/reactdatagrid-community/types';
import ReactDataGrid from '@inovua/reactdatagrid-enterprise';
import Box from '@mui/material/Box';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import Popper from '@mui/material/Popper';
import TextField from '@mui/material/TextField';
import { SxProps, Theme, useTheme } from '@mui/material/styles';
import { MessageContext } from '@teto/react-component-library-v2';
import { debounce } from 'lodash';
import React, {
  useCallback,
  useContext,
  useEffect,
  useLayoutEffect,
  useMemo,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { getGraphQLClient } from 'teto-client-api';
import getErrors from '../../../api/graphQL/getErrors';
import isEmptyOrWhitespace from '../../../helpers/isEmptyOrWhitespace';
import useCurrentTheme from '../../../hooks/useCurrentTheme';
import deepPropertyHelper from '../../TETOGridGraphQL/Formatters/deepPropertyHelpers';
import { buildFilterObject } from '../../TETOGridGraphQL/QueryBuilder/queryBuilder';
import useStyles from '../../TetoGrid/GridStyles';
import { reactDataGridLicenseKey } from '../../TetoGrid/Licensing';
import { ETOGridSelectFieldProps } from './ETOGridSelectFieldProps';
import GridInputAdornment from './GridInputAdorment';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type GridRecord = Record<string, any>;

const rootSx: SxProps<Theme> = {
  gridColumn: 'span 2',
  '& .MuiPaper-root': {
    left: 1,
    width: '100%',
  },
};

const popperSx = () => ({
  zIndex: (theme: Theme) => theme.zIndex.modal + 1,
  '.InovuaReactDataGrid': {
    minWidth: '350px',
    width: '100%',
  },
});

const getScrollStyle = (theme: Theme) => ({
  scrollThumbStyle: { backgroundColor: theme.palette.primary.main },
});

const HEADER_HEIGHT = 30;
const ROW_HEIGHT = 25;
const DEFAULT_SORT: TypeSortInfo = [];

const ETOGridSelectField = <T extends GridRecord>(
  props: ETOGridSelectFieldProps<T>
) => {
  const {
    clearable = true,
    customSx,
    disabled,
    error,
    fieldName,
    formatInputValue,
    gridProps,
    handleChange,
    handleRenderValue,
    label,
    search,
    source,
    testId,
    handleInputClear,
    testVirtualized,
    value,
    hideOptionsOnClear,
  } = props;

  const messageContext = useContext(MessageContext);
  const currentTheme = useCurrentTheme();
  const theme = useTheme();
  const gridStyles = useStyles(theme);
  const { t } = useTranslation();

  const [isCleared, setIsCleared] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const [loading, setLoading] = useState(true);
  const [freeSolo, setFreeSolo] = useState<boolean>(false);
  const [anchorEl, setAnchorEl] = useState<
    (EventTarget & HTMLDivElement) | null
  >(null);
  const [focused, setFocused] = useState(false);
  const [filter, setFilter] = useState<Partial<TypeSingleFilterValue>[]>([
    (search?.filterColumn as TypeSingleFilterValue) ?? {
      name: 'name',
      operator: 'contains',
      type: 'string',
      value: '',
    },
  ]);
  const [localDataSource, setLocalDataSource] = useState<T[]>([]);
  const [originalDataSource, setOriginalDataSource] = useState<T[]>([]);

  const updatedColumns: TypeColumn[] = useMemo(
    () =>
      gridProps.columns.map((c) => ({
        ...c,
        showColumnMenuTool: c.showColumnMenuTool || false,
        sortable: c.sortable || true,
        groupColumn: false,
      })),
    [gridProps.columns]
  );

  const tableStyle = useMemo(
    () => ({
      display: anchorEl ? 'block' : 'none',
      position: 'relative',
      top: 0,
      zIndex: theme.zIndex.modal + 1,
      width: anchorEl?.clientWidth as number,
    }),
    [anchorEl, theme.zIndex.modal]
  );

  const _handleQueryResponse = useCallback(
    (d: { data: T }) => {
      const { data: dData } = d;
      if (!dData) return [];
      const propNames = Object.getOwnPropertyNames(dData);
      if (propNames.length === 0) {
        return [];
      }
      const queryKey = propNames[0];

      if ('items' in dData[queryKey]) {
        let fetchedData = dData[queryKey].items;
        if (source.gqlSource?.customMapping) {
          fetchedData = fetchedData.map(source.gqlSource?.customMapping);
        }
        return fetchedData;
      }
      messageContext.setError(t('generic.message.invalidQueryKey'));
      return [];
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [source.gqlSource?.customMapping]
  );

  const buildFilters = useCallback(() => {
    let _filter = {};

    if (filter[0]) {
      _filter = buildFilterObject(filter as TypeSingleFilterValue[]);
    }
    const newFilters = source.gqlSource?.modifyFilterVariables
      ? source.gqlSource?.modifyFilterVariables(_filter)
      : _filter;

    return newFilters;
  }, [filter, source.gqlSource]);

  const loadData =
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    async () => {
      if (source.items) {
        setLocalDataSource(source.items);
        return Promise.resolve(source.items);
      }

      setLoading(true);

      const _variables = source.gqlSource?.useRemoteFiltering
        ? {
            filter: buildFilters(),
            ...source.gqlSource?.variables,
          }
        : { ...source.gqlSource?.variables };

      const result = await getGraphQLClient()
        .performQuery(source.gqlSource?.query as string, _variables)
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        .then((d) => {
          setLoading(false);
          if (d.hasError()) {
            if (d.hasSystemErrors()) {
              messageContext.setError(getErrors(d.systemErrors));
            }
            if (d.hasValidationErrors()) {
              messageContext.setError(getErrors(d.validationErrors));
            }
            return [];
          }
          if (d.hasData()) {
            const vals = _handleQueryResponse(d);
            if (vals) {
              setLocalDataSource(vals);
              return {
                data: vals,
                count: Array.isArray(vals) ? vals?.length : 0,
              };
            }
            setLocalDataSource([]);
            return { data: [], count: 0 };
          }
        });
      if (result) {
        setLoading(false);
        return Promise.resolve(result);
      }
      setLoading(false);
      return Promise.resolve([]);
    };

  const _handleRenderValue = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any, no-shadow
    (value: any, dataSource?: any[]): string => {
      const _dataSource = dataSource ?? localDataSource;
      if (!value) return '';

      let option = null;

      if (_dataSource && _dataSource?.length > 0) {
        if (handleRenderValue) {
          return handleRenderValue(value, _dataSource);
        }
        option = _dataSource?.find(
          (o) => deepPropertyHelper(source.value, o) === value
        );
      }

      if (option) {
        return deepPropertyHelper(source.name, option) as string;
      }

      return '';
    },
    [handleRenderValue, localDataSource, source.name, source.value]
  );

  const valueMatcher = useCallback(
    (inputFieldText: string) => {
      if (localDataSource.length === 0) return;
      if (localDataSource.length === 1) return localDataSource[0];

      const result = localDataSource.find((i) => {
        if (search && search.textValueMatcher) {
          return search.textValueMatcher(inputFieldText, i);
        }

        return (
          String(
            deepPropertyHelper(source.value as string, i)
          )?.toLowerCase() === inputValue?.toLowerCase()
        );
      });
      return result;
    },

    [localDataSource, search, inputValue, source.value]
  );

  // this is responsible for the displaying the correct text in the input field
  const _handleBlur = useCallback(
    (
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      e: any
    ) => {
      // grid is open dont do anything
      if (anchorEl) return;
      // Get the matching record based on the current input value
      const matchingRecord = valueMatcher(e.target.value);

      // If a matching record is found
      if (matchingRecord) {
        // If the input must match the search, update the search value
        if (search?.inputMustMatchSearch) {
          setInputValue(_handleRenderValue(matchingRecord));
        }

        // In any case, handle the change with the matching record
        handleChange(matchingRecord);
      } else {
        // If no matching record is found

        // If the input must match the search, clear the search value
        if (search?.inputMustMatchSearch) {
          setInputValue('');
        }

        // If the input doesn't have to match the search, handle the change with the current search value
        if (search?.inputMustMatchSearch === false) {
          return handleChange(inputValue);
        }

        // If none of the above conditions are met, handle the change with an empty string
        handleChange('');
      }

      setFilter([]);
    },
    [
      _handleRenderValue,
      anchorEl,
      handleChange,
      inputValue,
      search?.inputMustMatchSearch,
      valueMatcher,
    ]
  );

  useEffect(() => {
    if (!freeSolo && !isCleared) {
      setInputValue(_handleRenderValue(value, localDataSource));
    }
  }, [_handleRenderValue, localDataSource, freeSolo, value, isCleared]);

  // load data on mount and set the input field value
  useEffect(() => {
    if (source.items) {
      if (source.items.length === 0) {
        return;
      }
      setOriginalDataSource(source.items);
      setLocalDataSource(source.items);
      setLoading(false);
      return;
    }
    loadData()?.then((vals) => {
      if (vals && 'data' in vals && vals.data.length > 0) {
        setLocalDataSource(vals.data);
        setOriginalDataSource(vals.data);
      }
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value, source.items]);

  const debouncedSetFilter = useMemo(
    () =>
      debounce((filterValue) => {
        const _filter: Partial<TypeSingleFilterValue> = {
          ...(search?.filterColumn ?? filter[0]),
          value: filterValue ?? '',
        };
        setFilter([_filter]);
      }, 500),
    [filter, search?.filterColumn]
  );

  useLayoutEffect(() => {
    const noAnchor = !anchorEl;
    // We dont want this to run on the first mount, only when the filter changes
    if (localDataSource.length === 0 || noAnchor) return;

    if (source.gqlSource?.useRemoteFiltering) {
      loadData();
    }

    if (!source.gqlSource?.useRemoteFiltering) {
      // If the filter is empty, reset the local data source
      if (isEmptyOrWhitespace(filter[0]?.value)) {
        if (source.items) {
          return setLocalDataSource(source.items);
        }
        loadData(); // Dont put the return here, it will break. You can't return a promise from a useEffect.
        return;
      }
      // if the input value length increases ex: 123 -> 1234, we want to filter the local data source, but if the input value length decreases ex: 1234 -> 123, we want to compare it to the original data source, and filter it again
      const predicate = (item: T) =>
        String(deepPropertyHelper(source.name as string, item))
          ?.toLowerCase()
          .includes(inputValue?.toLowerCase());

      const filteredItems = localDataSource.filter(predicate);

      const checkAgainstOriginalSource = originalDataSource?.filter(predicate);

      if (checkAgainstOriginalSource?.length === 0) {
        setLocalDataSource([]);
        return;
      }

      if (checkAgainstOriginalSource?.length > filteredItems.length) {
        return setLocalDataSource(checkAgainstOriginalSource);
      }

      setLocalDataSource(filteredItems);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter, originalDataSource, anchorEl]);

  return (
    <Box sx={{ ...rootSx, ...customSx }}>
      <>
        <FormControl error={Boolean(error)} fullWidth>
          <TextField
            autoComplete="cc-csc"
            data-testid={testId ?? 'eto-grid-select-input'}
            disabled={disabled}
            error={Boolean(error)}
            fullWidth
            id={fieldName}
            InputLabelProps={{ shrink: true }}
            inputProps={{ name: fieldName, id: fieldName }}
            // eslint-disable-next-line react/jsx-no-duplicate-props
            InputProps={{
              endAdornment: (
                <GridInputAdornment
                  clearable={clearable && !disabled}
                  disabled={disabled}
                  expanded={Boolean(anchorEl)}
                  hideOptionsOnClear={hideOptionsOnClear}
                  isFocused={focused}
                  onClear={() => {
                    setIsCleared(true);
                    setFilter([]);
                    setInputValue('');
                    handleChange(undefined);
                    loadData();
                    if (handleInputClear) handleInputClear();
                  }}
                />
              ),
            }}
            label={label}
            name={fieldName}
            onBlur={_handleBlur}
            onChange={(e) => {
              if (isCleared) setIsCleared(false);
              let _text = e.target.value;
              if (formatInputValue) {
                _text = formatInputValue(_text);
              }
              debouncedSetFilter(_text);
              setInputValue(_text);
              setFreeSolo(true);
            }}
            onClick={(e) => setAnchorEl(e.currentTarget)}
            onKeyDown={(e) => {
              if (e.code === 'Enter' && anchorEl && localDataSource[0]) {
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                (e.target as any)?.blur();
                setFocused(false);
                if (search) {
                  setInputValue('');
                  setFreeSolo(false);
                }
                handleChange(localDataSource[0]);
                setAnchorEl(null);
                setFilter([]);
              }
            }}
            onMouseLeave={() => setFocused(false)}
            onMouseOver={() => setFocused(true)}
            size="small"
            value={inputValue}
          />

          {error && <FormHelperText>{error}</FormHelperText>}
        </FormControl>
        <ClickAwayListener
          mouseEvent="onMouseDown"
          onClickAway={() => setAnchorEl(null)}
          touchEvent="onTouchEnd"
        >
          <Popper
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            placement="bottom-start"
            sx={popperSx}
          >
            <Box sx={gridStyles}>
              <ReactDataGrid
                activeRowIndicatorClassName="active-row-border"
                columns={updatedColumns}
                dataSource={localDataSource}
                defaultLimit={20}
                defaultSortInfo={gridProps?.defaultSortInfo || DEFAULT_SORT}
                filterRowHeight={0}
                headerHeight={gridProps?.headerHeight || HEADER_HEIGHT}
                idProperty="id"
                licenseKey={reactDataGridLicenseKey}
                livePagination
                loading={loading}
                lockedRows={gridProps?.lockedRows}
                onRowClick={(r) => {
                  if (search) {
                    setInputValue('');
                    setFreeSolo(false);
                  }
                  setIsCleared(false);
                  handleChange(r.data);
                  setAnchorEl(null);
                  setFilter([]);
                }}
                pagination={gridProps?.pagination ?? true}
                rowHeight={gridProps?.rowHeight || ROW_HEIGHT}
                scrollProps={getScrollStyle(theme)}
                scrollThreshold={0.6}
                stickyTreeNodes
                style={tableStyle}
                theme={currentTheme}
                virtualized={testVirtualized ?? true} // do not set this to false, otherwise the grid will render potentially 1000s of rows when and it will be very slow
              />
            </Box>
          </Popper>
        </ClickAwayListener>
      </>
    </Box>
  );
};

export default ETOGridSelectField;
