import { ETOTextField } from '@teto/react-component-library-v2';
import React, { useEffect, useState } from 'react';

export interface DiscountInputProps {
  disabled?: boolean;
  value: number;
  error: string;
  name: string;
  title: string;
  handleChange: (
    // eslint-disable-next-line no-unused-vars
    name: string,
    // eslint-disable-next-line no-unused-vars
    value: number
  ) => void;
}

const decimal = /^(100(?:\.0{0,6})?|0(?:\.\d{1,6})?|\d{0,2}(?:\.\d{0,6})?)?%?$/;

const DiscountInput = (props: DiscountInputProps) => {
  const { disabled, value, error, name, handleChange, title } = props;
  const formatDisplayValue = (val: number) => {
    const percentValue = val * 100;
    const displayValue = parseFloat(
      percentValue.toLocaleString('en', {
        maximumFractionDigits: percentValue < 1 ? 6 : 2,
      })
    );
    return displayValue;
  };

  const [input, setInput] = useState<{
    display: string | number;
    value: number;
  }>(() => ({
    display: `${formatDisplayValue(value)}%`,
    value: value ?? 0,
  }));

  useEffect(() => {
    if (input.value === 0 && value !== 0) {
      setInput({
        display: `${formatDisplayValue(value)}%`,
        value,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  return (
    <ETOTextField
      disabled={disabled}
      error={error}
      handleChange={(e) => {
        if (decimal.test(e.target.value)) {
          const val = parseFloat(e.target.value);
          const formikValue = Number.isNaN(val) ? 0 : val;
          const formattedValue = parseFloat((formikValue / 100).toFixed(8));
          setInput({
            display: e.target.value,
            value: formattedValue,
          });
          handleChange(e.target.name, formattedValue);
        }
      }}
      label={title}
      name={name}
      onBlur={() => {
        setInput((prev) => ({
          ...prev,
          display: `${formatDisplayValue(prev.value)}%`,
        }));
      }}
      onFocus={() => {
        setInput((prev) => ({
          ...prev,
          display: formatDisplayValue(prev.value),
        }));
      }}
      size="small"
      value={input.display}
    />
  );
};

export default DiscountInput;
