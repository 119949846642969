import { ETOSelectField } from '@teto/react-component-library-v2';
import { FormikProps } from 'formik';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import useBuildQuery from '../../../../../../../api/graphQL/useBuildQuery';
import { TEngItemMaster } from '../../../../types/TEngItemMaster';

interface ManufacturersSelectFieldProps {
  formik: FormikProps<Partial<TEngItemMaster>>;
  disabled?: boolean;
  // eslint-disable-next-line no-unused-vars, @typescript-eslint/no-explicit-any
  handleChange: (e: Partial<React.ChangeEvent<any>>) => void;
}
const manufacturerQuery = `query engItemMasterManufacturers {
  engItemMasterManufacturers{
    items
  }
}`;

type ManufacturersApiResponse = {
  engItemMasterManufacturers: {
    items: string[];
  };
};

const ManufacturersSelectField = (props: ManufacturersSelectFieldProps) => {
  const { formik, disabled, handleChange } = props;
  const { t } = useTranslation();
  const manufacturers = useBuildQuery<ManufacturersApiResponse>(
    ['partManufacturer'],
    {
      queryString: manufacturerQuery,
      variables: {},
      options: { refetchOnMount: false, refetchOnWindowFocus: false },
    }
  );
  const formikError = formik.errors.manufacturer;

  const spanFullWidth = useMemo(() => ({ gridColumn: 'span 2' }), []);
  return (
    <ETOSelectField
      autoCompleteSx={spanFullWidth}
      clearOnBlur
      disabled={disabled}
      error={formikError}
      freeSolo
      handleChange={handleChange}
      helperText={
        formik.values.manufacturer
          ? `${formik.values.manufacturer?.length.toString()} \/ 255`
          : '0 / 255'
      }
      inputLabelProps={{ shrink: true }}
      itemNameSelector={(item) => item}
      items={manufacturers.data?.engItemMasterManufacturers?.items ?? []}
      itemValueSelector={(item) => item}
      label={`${t('entities:Part.Manufacturer')}`}
      name="manufacturer"
      selectOnFocus
      size="small"
      value={formik.values.manufacturer ?? null}
    />
  );
};

export default ManufacturersSelectField;
