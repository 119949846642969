import { gql } from '../../../../__generated__';

const getPSReports = gql(` query getEmployeeReports($id: Int!){
  employeeReport(id: $id) {
    reportID
    reportDisplayName
    enabled
    allowView
    alternateId
  }
  reportAlternates(where: {reportId: {eq: $id}}) {
    items {
      id
      reportId
      reportDisplayName
      reportDescription
      enabled

    }
  }
}`);
export default getPSReports;
