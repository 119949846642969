import { gql } from '../../../../__generated__';

const updatePSDetailMutation = gql(`
  mutation updateProcessScheduleDetail($input: UpdateProcessScheduleDetailRequestInput){
    updateProcessScheduleDetail(input: $input) {
      id
      unitEstimate
      setupHours
      estimateHours
      extendedEstimateHours
      process {
        name
      }
    }
  }
`);

export default updatePSDetailMutation;
