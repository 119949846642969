import AddRoundedIcon from '@mui/icons-material/AddRounded';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import { SxProps, Theme } from '@mui/material';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import CircularProgress from '@mui/material/CircularProgress';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { YesNoConfirmDialog } from '@teto/react-component-library-v2';
import React, { ReactNode, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TypedDocumentNode } from '../../api/graphQL/types/TypeDocumentNode';
import useGQLQuery from '../../api/graphQL/useGQLQuery';

const chipSx = (theme: Theme) => ({
  marginRight: theme.spacing(1),
});

const chipContainerSx = (theme: Theme) => ({
  display: 'flex',
  flexWrap: 'wrap',
  marginBottom: theme.spacing(2),
  rowGap: theme.spacing(1),
});

type Identifiable = {
  id: number;
};

export interface ChipListComponentProps<T extends object & Identifiable> {
  addButtonLabel?: string;
  canAddItems: boolean;
  canDeleteItems: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  chipListItemsQuery?: TypedDocumentNode<unknown, any>;
  idProperty?: string;
  isLoading?: boolean;
  listItems: T[];
  queryKey?: string;
  customSx?: SxProps<Theme>;
  confirm?: {
    title: string;
    content: string | ReactNode;
  };
  externalMenu?: {
    buttonRef: React.RefObject<HTMLDivElement> | null | undefined;
    setIsMenuOpen: React.Dispatch<React.SetStateAction<boolean>>;
  };
  menu?: {
    list: T[];
    // eslint-disable-next-line no-unused-vars
    itemLabelSelector?: (item: T) => string;
  };
  // eslint-disable-next-line no-unused-vars,
  handleDeleteClick: (i: T) => void;
  // eslint-disable-next-line no-unused-vars,
  handleAddClick?: (i: T) => void;
  // eslint-disable-next-line no-unused-vars
  itemDisableSelector?: (item: T, data: T[]) => boolean;
  // eslint-disable-next-line no-unused-vars,
  itemLabelSelector: (item: T) => string;
  customAddButton?: ReactNode;
}

const ChipList = <T extends Identifiable>(props: ChipListComponentProps<T>) => {
  const {
    addButtonLabel,
    canAddItems,
    canDeleteItems,
    chipListItemsQuery,
    confirm,
    customSx,
    customAddButton,
    externalMenu,
    handleAddClick,
    handleDeleteClick,
    idProperty,
    isLoading,
    itemDisableSelector,
    itemLabelSelector,
    listItems,
    menu,
    queryKey,
  } = props;

  const { t } = useTranslation();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [chipItems, setChipItems] = useState<T[]>([]);
  const [isDeleteConfirmOpen, setIsDeleteConfirmOpen] = useState<T>();

  useEffect(() => {
    if (menu?.list && menu?.list?.length > 0) setChipItems(menu?.list as T[]);
  }, [menu?.list]);

  const chipListItems = useGQLQuery(['chipListQuery'], {
    queryString: chipListItemsQuery,
    variables: {},
    callback: (d) => {
      if (queryKey) {
        setChipItems(d[queryKey].items);
      }
    },
    options: {
      refetchOnMount: true,
      refetchOnWindowFocus: false,
      enabled: !!chipListItemsQuery,
    },
  });

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const _isDisabled = (item: any) => {
    if (!canAddItems) return true;
    if (itemDisableSelector) return itemDisableSelector(item, listItems);

    return listItems.some((i) => {
      const { id } = i;

      return id === item[idProperty ?? 'id'];
    });
  };

  return (
    <Box
      sx={[
        chipContainerSx,
        ...(Array.isArray(customSx) ? customSx : [customSx]),
      ]}
    >
      {customAddButton && customAddButton}
      {listItems.map((li) => (
        <Chip
          deleteIcon={<CloseRoundedIcon color="error" />}
          disabled={!canDeleteItems}
          key={li.id}
          label={itemLabelSelector(li)}
          onDelete={() => setIsDeleteConfirmOpen(li)}
          sx={chipSx}
          variant="outlined"
        />
      ))}

      {(chipListItemsQuery || menu?.list || externalMenu) &&
        !customAddButton && (
          <Chip
            color="primary"
            disabled={!canAddItems}
            icon={<AddRoundedIcon color="primary" />}
            label={addButtonLabel ?? t('generic.add')}
            onClick={(e) => {
              if (externalMenu) externalMenu.setIsMenuOpen(true);
              else setAnchorEl(e.currentTarget);
            }}
            ref={externalMenu?.buttonRef}
            variant="outlined"
          />
        )}

      {!externalMenu && (chipListItemsQuery || menu?.list) && (
        <Menu
          anchorEl={anchorEl}
          onClose={() => setAnchorEl(null)}
          open={Boolean(anchorEl)}
          slotProps={{
            paper: {
              sx: (theme) => ({
                maxHeight: theme.spacing(30),
                mt: 1,
              }),
            },
          }}
        >
          {isLoading && (
            <MenuItem>
              <Box>
                <CircularProgress />
              </Box>
            </MenuItem>
          )}
          {!chipListItems.isLoading &&
            Array.isArray(chipItems) &&
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            chipItems.map((i: any) => (
              <MenuItem
                disabled={_isDisabled(i)}
                key={idProperty ? i[idProperty] : i.id}
                onClick={() => {
                  handleAddClick?.(i);
                  setAnchorEl(null);
                }}
              >
                {menu?.itemLabelSelector
                  ? menu?.itemLabelSelector?.(i)
                  : itemLabelSelector(i)}
              </MenuItem>
            ))}
        </Menu>
      )}

      {isDeleteConfirmOpen && (
        <YesNoConfirmDialog
          content={confirm?.content ?? t('dialogs.deleteRecord.content')}
          onNo={() => setIsDeleteConfirmOpen(undefined)}
          onYes={() => {
            handleDeleteClick(isDeleteConfirmOpen);
            setIsDeleteConfirmOpen(undefined);
          }}
          open={Boolean(isDeleteConfirmOpen)}
          title={confirm?.title ?? t('dialogs.deleteRecord.title')}
        />
      )}
    </Box>
  );
};

export default ChipList;
