/* eslint-disable @typescript-eslint/no-explicit-any */
import dayjs from 'dayjs';
import { formatHoursAsHourMin } from '@teto/react-component-library-v2';
import deepPropertyHelper from '../Formatters/deepPropertyHelpers';

const parseValue = (column: any, data: any) => {
  const { name } = column;
  return data[name] ?? deepPropertyHelper(name, data);
};

const stringFormatter = (column: any, data: any) => {
  const value =
    typeof parseValue(column, data) === 'string'
      ? parseValue(column, data)
      : '';
  return value.toString().replace(/(\r\n|\n|\r|,)/gm, ' ');
};

const dateFormatter = (column: any, data: any, settings: any) => {
  const { dateFormat } = settings;
  const value = parseValue(column, data);
  return value ? dayjs(value).format(dateFormat) : '';
};

const dateTimeFormatter = (column: any, data: any, settings: any) => {
  const { dateTimeFormat } = settings;
  const value = parseValue(column, data);
  return value ? dayjs(value).format(dateTimeFormat) : '';
};

const timeFormatter = (column: any, data: any, settings: any) => {
  const { timeFormat } = settings;
  const value = parseValue(column, data);
  return value ? dayjs(value).format(timeFormat) : '';
};

const numberFormatter = (column: any, data: any) => {
  const value = parseValue(column, data);
  if (typeof value === 'number') return value;
  if (typeof value === 'string') {
    const asStr = value;
    let numberVal;
    if (asStr.indexOf('.') >= 0) {
      numberVal = parseFloat(asStr);
    } else {
      numberVal = parseInt(asStr, 10);
    }

    if (Number.isNaN(numberVal)) return '';
    return numberVal;
  }
  return '';
};

const booleanFormatter = (column: any, data: any) => {
  const value = parseValue(column, data);
  switch (value) {
    case false: {
      return false;
    }
    case true: {
      return true;
    }
    default: {
      return '';
    }
  }
};

const hoursFormatter = (column: any, data: any) => {
  const value = parseValue(column, data);
  return typeof value === 'number' ? formatHoursAsHourMin(value) : '';
};

const customFormatter = (column: any, data: any, settings: any) => {
  const { dateFormat } = settings;
  const { name } = column;
  const { startDate, endDate } = data;

  if (name === 'dateRange') {
    return startDate && endDate
      ? `${dayjs(startDate).format(dateFormat)} - ${dayjs(endDate).format(
          dateFormat
        )}`
      : '';
  }

  return parseValue(column, data) ?? '';
};

const defaultFormatter = (column: any, data: any) =>
  parseValue(column, data) ?? '';

const defaultSettings = {
  dateFormat: 'YYYY-MM-DD',
  dateTimeFormat: 'YYYY-MM-DD HH:mm',
  timeFormat: 'HH:mm',
};

const valueFormatter = (column: any, data: any, settings: any) => {
  const appSettings = settings?.settings ?? defaultSettings;
  switch (column.type) {
    case 'string':
      return stringFormatter(column, data);
    case 'foreignKey':
      return stringFormatter(column, data);
    case 'date':
      return dateFormatter(column, data, appSettings);
    case 'hours':
      return hoursFormatter(column, data);
    case 'time':
      return timeFormatter(column, data, appSettings);
    case 'datetime':
      return dateTimeFormatter(column, data, appSettings);
    case 'number':
      return numberFormatter(column, data);
    case 'boolean':
      return booleanFormatter(column, data);
    case 'custom':
      return customFormatter(column, data, appSettings);
    default:
      return defaultFormatter(column, data);
  }
};

export default valueFormatter;
