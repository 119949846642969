import { useAtomValue, useSetAtom } from 'jotai';
import { useMolecule } from 'jotai-molecules';
import React, { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import ProcBreakdownInspector from '../../Inspectors/ProcBreakdownInspector/ProcBreakdownInspector';
import { BreadCrumbSharedState } from '../StateContainers/BreadCrumbModalState';
import {
  ProcBreakdownSharedState,
  onProcBreakdownClose,
} from '../StateContainers/ProcBreakdownState';

const SharedProcBreakdown = () => {
  const { t } = useTranslation();
  const state = useAtomValue(ProcBreakdownSharedState);
  const onClose = useMolecule(onProcBreakdownClose);
  const defaultOnClose = useSetAtom(onClose);
  const setLevels = useSetAtom(BreadCrumbSharedState);

  const getItemCompanyId = useMemo(
    () =>
      state?.detail?.child?.itemCompanyId ??
      state?.detail?.item?.itemCompanyId ??
      state?.detail?.itemCompanyId ??
      '',
    [
      state?.detail?.child?.itemCompanyId,
      state?.detail?.item?.itemCompanyId,
      state?.detail?.itemCompanyId,
    ]
  );

  const isDestInventoryLocation = useMemo(
    () =>
      state?.detail?.destInventoryLocId > 0 ||
      state?.detail?.destInventoryLoc > 0,
    [state?.detail?.destInventoryLoc, state?.detail?.destInventoryLocId]
  );

  const renderTitle = useMemo(() => {
    const specID = `${t('entities:Machine.Job')}: ${
      state?.detail?.specId ?? ''
    }`;

    const part = `${t('entities:Part.Part')}: ${getItemCompanyId}`;

    const projID = `${t('entities:Project.Project')}: ${
      state?.detail?.projectId ?? ''
    }`;

    const destInvLocation = `${t(
      'entities:ProcurementPurchasingSummaryDetail.DestInventoryLoc'
    )}: ${
      state?.detail?.destInventoryLoc?.name ??
      state?.detail?.destInventoryLocation?.name ??
      ''
    }`;

    const processScheduleID = state?.detail?.processScheduleId
      ? `${t('entities:Process.Number')}: ${state?.detail?.processScheduleId}`
      : '';

    if (isDestInventoryLocation) {
      return `${destInvLocation} ${projID} ${specID} ${part}`;
    }

    if (state?.detail?.processScheduleId) {
      return `${projID} ${specID} ${processScheduleID} ${part}`;
    }

    return `${projID} ${specID} ${part}`;
  }, [
    getItemCompanyId,
    isDestInventoryLocation,
    state?.detail?.destInventoryLoc?.name,
    state?.detail?.destInventoryLocation?.name,
    state?.detail?.processScheduleId,
    state?.detail?.projectId,
    state?.detail?.specId,
    t,
  ]);

  useEffect(() => {
    if (state.open) {
      setLevels((prev) => [
        ...prev,
        {
          name: renderTitle,
          data: state?.detail,
          component: (
            <ProcBreakdownInspector
              detail={state?.detail}
              ignoreLocation={state?.ignoreLocation}
              onClose={defaultOnClose}
              open={state?.open}
              renderAsInspector={false}
            />
          ),
        },
      ]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state?.detail, state.open]);
  return <div />;
};

export default SharedProcBreakdown;
