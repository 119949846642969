import React, { useCallback, useContext } from 'react';
import { Dayjs } from 'dayjs';
import {
  ETODateField,
  ETODateTimeField,
  ETOTimeField,
} from '@teto/react-component-library-v2';
import { TETODataColumn } from '../GridBuilder/types/TETODataColumn';
import DateFilterRange from './DateFilterRange';
import SettingsContext from '../../../contexts/SettingsContext';

interface TETODateTimeFilterProps {
  operatorValue: string;
  // eslint-disable-next-line no-unused-vars
  handleChange: (date: Dayjs | null | DateFilterRange) => void;
  col: TETODataColumn;
  fieldValue: string | DateFilterRange;
}

const TETODateTimeFilter = (props: TETODateTimeFilterProps) => {
  const { operatorValue, handleChange, col, fieldValue } = props;
  const settingsContext = useContext(SettingsContext);
  const commonSize = 'small';

  const _renderField = useCallback(() => {
    switch (col.filterType) {
      case 'time':
        return (
          <>
            {operatorValue === 'inrange' && (
              <>
                <ETOTimeField
                  handleChange={(v) => {
                    const newStart = v ?? '';
                    handleChange({
                      start: newStart,
                      end: (fieldValue as DateFilterRange).end,
                    });
                  }}
                  id={col.id}
                  inputFormat={settingsContext.settings.timeFormat}
                  inputSx={{ mr: 1, pl: 0 }}
                  label={`${col.title} Start`}
                  name={col.name}
                  size={commonSize}
                  value={(fieldValue as DateFilterRange).start ?? null}
                />
                <ETOTimeField
                  handleChange={(v) => {
                    const newEnd = v ?? '';
                    handleChange({
                      start: (fieldValue as DateFilterRange).start,
                      end: newEnd,
                    });
                  }}
                  id={col.id}
                  inputFormat={settingsContext.settings.timeFormat}
                  inputSx={{ mr: 1, pl: 0 }}
                  label={`${col.title} End`}
                  name={col.name}
                  size={commonSize}
                  value={(fieldValue as DateFilterRange).end ?? null}
                />
              </>
            )}
            {operatorValue !== 'inrange' && (
              <ETOTimeField
                handleChange={(v) => handleChange(v ?? null)}
                id={col.id}
                inputFormat={settingsContext.settings.timeFormat}
                inputSx={{ mr: 1, pl: 0 }}
                label={col.title}
                name={col.name}
                size={commonSize}
                value={fieldValue}
              />
            )}
          </>
        );
      case 'datetime':
        return (
          <>
            {operatorValue === 'inrange' && (
              <>
                <ETODateTimeField
                  handleChange={(v) => {
                    const newStart = v ?? '';
                    handleChange({
                      start: newStart,
                      end: (fieldValue as DateFilterRange).end,
                    });
                  }}
                  id={col.id}
                  inputFormat={settingsContext.settings.dateTimeFormat}
                  inputSx={{ mr: 1, pl: 0 }}
                  label={`${col.title} Start`}
                  name={col.name}
                  size={commonSize}
                  value={(fieldValue as DateFilterRange).start ?? null}
                />
                <ETODateTimeField
                  handleChange={(v) => {
                    const newEnd = v ?? '';
                    handleChange({
                      start: (fieldValue as DateFilterRange).start,
                      end: newEnd,
                    });
                  }}
                  id={col.id}
                  inputFormat={settingsContext.settings.dateTimeFormat}
                  inputSx={{ mr: 1, pl: 0 }}
                  label={`${col.title} End`}
                  name={col.name}
                  size={commonSize}
                  value={(fieldValue as DateFilterRange).end ?? null}
                />
              </>
            )}
            {operatorValue !== 'inrange' && (
              <ETODateTimeField
                handleChange={(v) => handleChange(v ?? null)}
                id={col.id}
                inputFormat={settingsContext.settings.dateTimeFormat}
                inputSx={{ mr: 1, pl: 0 }}
                label={col.title}
                name={col.name}
                size={commonSize}
                value={fieldValue}
              />
            )}
          </>
        );
      case 'date':
        return (
          <>
            {operatorValue === 'inrange' && (
              <>
                <ETODateField
                  handleChange={(v) => {
                    const newStart = v ?? '';
                    handleChange({
                      start: newStart,
                      end: (fieldValue as DateFilterRange).end,
                    });
                  }}
                  id={col.id}
                  inputFormat={settingsContext.settings.dateFormat}
                  inputSx={{ mr: 1, pl: 0 }}
                  label={`${col.title} Start`}
                  name={col.name}
                  size={commonSize}
                  value={(fieldValue as DateFilterRange).start ?? null}
                />
                <ETODateField
                  handleChange={(v) => {
                    const newEnd = v ?? '';
                    handleChange({
                      start: (fieldValue as DateFilterRange).start,
                      end: newEnd,
                    });
                  }}
                  id={col.id}
                  inputFormat={settingsContext.settings.dateFormat}
                  inputSx={{ mr: 1, pl: 0 }}
                  label={`${col.title} End`}
                  name={col.name}
                  size={commonSize}
                  value={(fieldValue as DateFilterRange).end ?? null}
                />
              </>
            )}
            {operatorValue !== 'inrange' && (
              <ETODateField
                handleChange={(v) => handleChange(v ?? null)}
                id={col.id}
                inputFormat={settingsContext.settings.dateFormat}
                inputSx={{ mr: 1, pl: 0 }}
                label={col.title}
                name={col.name}
                size={commonSize}
                value={fieldValue}
              />
            )}
          </>
        );
      default:
        return null;
    }
  }, [
    col.filterType,
    col.id,
    col.name,
    col.title,
    fieldValue,
    handleChange,
    operatorValue,
    settingsContext.settings.dateFormat,
    settingsContext.settings.dateTimeFormat,
    settingsContext.settings.timeFormat,
  ]);
  return <>{_renderField()}</>;
};

export default TETODateTimeFilter;
