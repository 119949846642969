import { useAtom, useSetAtom } from 'jotai';
import { useMolecule } from 'jotai-molecules';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { BreadCrumbSharedState } from '../StateContainers/BreadCrumbModalState';
import CompanyDetailInspector from '../../Inspectors/CompanyDetailInspector/CompanyDetailInspector';
import {
  companyDetailSharedState,
  onCompanyDetailClose,
} from '../StateContainers/CompanyState';

const useSharedCompanyDetail = () => {
  const { t } = useTranslation();
  const [companyDetail] = useAtom(companyDetailSharedState);
  const onCloseFn = useMolecule(onCompanyDetailClose);
  const defaultOnClose = useSetAtom(onCloseFn);
  const setLevels = useSetAtom(BreadCrumbSharedState);

  useEffect(() => {
    if (companyDetail.open) {
      setLevels((prev) => [
        ...prev,
        {
          name: companyDetail?.initialValues?.name
            ? `${t('entities:Company.Company')}: ${String(
                companyDetail?.initialValues?.name
              )}`
            : `${t('pages.companies.newCompany')}`,
          data: companyDetail?.initialValues,
          component: (
            <CompanyDetailInspector
              initialValues={companyDetail.initialValues}
              onClose={defaultOnClose}
              open={companyDetail.open}
            />
          ),
        },
      ]);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    // defaultOnClose,
    // setLevels, // don't add this to the dependency array, it will cause an infinite loop bug #75462
    companyDetail?.initialValues,
    companyDetail.open,
  ]);
};

export default useSharedCompanyDetail;
