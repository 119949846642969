import React, { useContext, useMemo } from 'react';
import { Permission } from 'teto-client-api';
import AuthContext from '../../../../../contexts/AuthContext';
import CommonDocumentsPanel, {
  DocumentsPanelProps,
} from '../../../../Inspectors/components/CommonDocumentsPanel/CommonDocumentsPanel';

type RFQDocumentsPanelProps = Omit<
  DocumentsPanelProps,
  | 'addNewMutation'
  | 'deleteMutation'
  | 'entityName'
  | 'entityQuery'
  | 'permissions'
  | 'updateMutation'
>;

const addRFQDocumentMutation = `mutation addRFQDocuments($input: AddRFQDocumentsInput) {
  addRFQDocuments( input: $input) {
    items {
      id
      documentLocation
    }
  }
}`;
const deleteRFQDocumentMutation = `mutation deleteRFQDocuments($id:Int!) {
  deleteRFQDocument( id:$id) {
    success
  }
}`;
const getRFQDocumentsQuery = `query rFQDocuments($rFQId: Int!) {
  rFQDocuments( where: { rFQId: {eq: $rFQId } }) {
    items {
      id
      documentLocation
      pathType
      fileName
      fileExtension
      fileSize
      fileExists
      rFQId
    }
  }
}`;
const updateRFQDocumentMutation = `mutation updateRFQDocument(
  $input: UpdateRFQDocumentInput
) {
  updateRFQDocument(input: $input) {
    id
    documentLocation
  }
}
`;

const RFQDocumentsPanel = (props: RFQDocumentsPanelProps) => {
  const authContext = useContext(AuthContext);
  const documentsPermissions = useMemo(
    () => ({
      canAdd: authContext.hasPermission(
        Permission.Add_Procurement_RFQs_RFQHeader_Documents
      ),
      canDelete: authContext.hasPermission(
        Permission.Delete_Procurement_RFQs_RFQHeader_Documents
      ),
      canEdit: authContext.hasPermission(
        Permission.Modify_Procurement_RFQs_RFQHeader_Documents
      ),
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [authContext]
  );

  return (
    <CommonDocumentsPanel
      addNewMutation={addRFQDocumentMutation}
      deleteMutation={deleteRFQDocumentMutation}
      entityName="RFQ"
      entityQuery={getRFQDocumentsQuery}
      permissions={{
        canAddDocuments: documentsPermissions.canAdd,
        canDeleteDocuments: documentsPermissions.canDelete,
        canModifyDocuments: documentsPermissions.canEdit,
      }}
      shouldSupportMultipleLocations
      updateMutation={updateRFQDocumentMutation}
      {...props}
    />
  );
};

export default RFQDocumentsPanel;
