export default `query inventories($inventoryLocationId: Int!, $itemId: Int!) {
  inventories(
    where: {
      and: [
        { inventoryLocationId: { eq: $inventoryLocationId } }
        { itemId: { eq: $itemId } }
      ]
    }
  ) {
    items {
      item {
        id
        itemCompanyId
        description
      }
      otherQuantities {
        available
      }
    }
  }
}
`;
