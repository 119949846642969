import React, { useContext, useMemo } from 'react';
import { Permission } from 'teto-client-api';
import AuthContext from '../../../../../contexts/AuthContext';
import CommonNotesPanel, {
  NotesPanelProps,
} from '../../../components/CommonNotesPanel/CommonNotesPanel';

type ContactsNotesPanelProps = Omit<
  NotesPanelProps,
  | 'addNewMutation'
  | 'deleteMutation'
  | 'entityName'
  | 'entityQuery'
  | 'permissions'
  | 'updateMutation'
>;

const addContactNoteMutation = `mutation addContactNotes($input: AddContactNoteInput) {
  addContactNote( input: $input) {
     id
      noteDate
      note
      contactId
      employee{
        id
        name
      }
  }
}`;
const deleteContactNoteMutation = `mutation deleteContactNotes($id:Int!) {
  deleteContactNote( id:$id) {
    success
  }
}`;
const getContactNotesQuery = `query contactNotes($contactId: Int!) {
  contactNotes( contactId: $contactId) {
    items {
      employee {
        id
        name
      }
      id
      note
      noteDate
    }
  }
}`;
const updateContactNoteMutation = `mutation updateContactNotes($input: UpdateContactNoteInput) {
  updateContactNote( input: $input) {
     id
      noteDate
      note
      contactId
      employee{
        id
        name
      }
  }
}`;

const ContactsNotesPanel = (props: ContactsNotesPanelProps) => {
  const authContext = useContext(AuthContext);
  const notesPermissions = useMemo(
    () => ({
      canAdd: authContext.hasPermission(
        Permission.Add_Sales_CompanyInformation_Contacts_ContactNotes
      ),
      canDelete: authContext.hasPermission(
        Permission.Delete_Sales_CompanyInformation_Contacts_ContactNotes
      ),
      canEdit: authContext.hasPermission(
        Permission.Modify_Sales_CompanyInformation_Contacts_ContactNotes
      ),
    }),
    [authContext]
  );

  return (
    <CommonNotesPanel
      addNewMutation={addContactNoteMutation}
      deleteMutation={deleteContactNoteMutation}
      entityName="Contact"
      entityQuery={getContactNotesQuery}
      permissions={{
        canAddNotes: notesPermissions.canAdd,
        canDeleteNotes: notesPermissions.canDelete,
        canModifyNotes: notesPermissions.canEdit,
      }}
      updateMutation={updateContactNoteMutation}
      {...props}
    />
  );
};

export default ContactsNotesPanel;
