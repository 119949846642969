import { Box, SxProps, Theme } from '@mui/material';
import { FormikProps } from 'formik';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { InspectorActions } from '../../../types/InspectorActionTypes';
import { PanelMode } from '../../../types/PanelState';
import TabPanelWithButtonStrip from '../../TabPanel/TabPanelWithButtonStrip';
import AutoSave from '../AutoSave/AutoSave';
import CreateButton from './CreateButton';
import SaveButton from './SaveButton';

interface IAddEditDetailsPanelProps<T>
  extends Pick<
    InspectorActions<T>,
    'setEditingState' | 'setIsCreatingNewItem'
  > {
  disableAutoSave?: boolean;
  canAdd: boolean;
  canEdit?: boolean;
  panelMode: PanelMode;
  createButton?: React.ReactNode;
  formik: FormikProps<Partial<T>>;
  children: React.ReactNode;
  sx?: SxProps<Theme>;
  saveButton?: boolean;
}
const buttonStripSx: SxProps<Theme> = {
  paddingTop: (theme) => theme.spacing(2),
  borderTop: (theme) => `1px solid ${theme.palette.divider}`,
};
const AddEditDetailsPanel = <T,>(props: IAddEditDetailsPanelProps<T>) => {
  const {
    canAdd,
    children,
    createButton,
    disableAutoSave,
    saveButton,
    formik,
    panelMode,
    setEditingState,
    setIsCreatingNewItem,
    sx,
    canEdit,
  } = props;

  const { t } = useTranslation();

  useEffect(() => {
    if (panelMode === 'create' && formik.dirty) {
      setIsCreatingNewItem(true);
    } else {
      setIsCreatingNewItem(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.dirty, panelMode]);

  useEffect(() => {
    setEditingState({
      isEditing: formik.dirty,
      hasEdited: formik.dirty,
      tabError: !formik.isValid,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.dirty, formik.isSubmitting, formik.isValid]);

  return (
    <>
      <TabPanelWithButtonStrip
        buttonStrip={
          <>
            {createButton || (
              <Box sx={panelMode === 'create' ? [buttonStripSx] : undefined}>
                <CreateButton
                  disabled={!canAdd || !formik.isValid}
                  loading={formik.isSubmitting}
                  onSubmit={formik.submitForm}
                  panelMode={panelMode}
                  tooltip={canAdd ? t('generic.create') : t('auth.permission')}
                />
              </Box>
            )}
            {saveButton && panelMode === 'edit' && (
              <Box sx={buttonStripSx}>
                <SaveButton
                  disabled={!canEdit || !formik.isValid || !formik.dirty}
                  loading={formik.isSubmitting}
                  onSubmit={formik.submitForm}
                  panelMode={panelMode}
                  tooltip={canEdit ? t('generic.save') : t('auth.permission')}
                />
              </Box>
            )}
          </>
        }
        sx={sx}
      >
        {panelMode === 'edit' && !disableAutoSave && (
          <AutoSave debounceMs={3000} formik={formik} />
        )}
        {children}
      </TabPanelWithButtonStrip>
    </>
  );
};

export default AddEditDetailsPanel;
