import Box from '@mui/material/Box';
import { SxProps, Theme } from '@mui/material/styles';
import React, { useCallback } from 'react';
import CloseInspectorConfirmation from '../components/AddEditInspector/components/CloseInspectorConfirmation';
import EditableTabs, {
  TAddEditTabs,
} from '../components/AddEditInspector/components/EditableTabs';

import { InspectorActions } from '../types/InspectorActionTypes';
import { InspectorState } from '../types/InspectorState';

const containerSx: SxProps<Theme> = {
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  rowGap: 2,
  width: '100%',

  flexGrow: 1,
  overflowY: 'hidden',
  '& .MuiTabs-root': {
    flexShrink: 0,
  },
  '& .MuiTabs-scrollButtons': {
    color: 'primary.main',
    '&:hover': {
      background: (theme) =>
        theme.palette.mode === 'light'
          ? 'rgba(0,0,0,0.04)'
          : 'rgba(255,255,255, 0.08)',
    },
    '& 	.MuiSvgIcon-root': {
      fill: 'currentcolor',
      fontSize: '1.5rem',
    },
  },
};

const customSx: SxProps<Theme> = {
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  flexGrow: 1,
  overflowY: 'hidden',
  '& .MuiModal-root-MuiDrawer-root .MuiDrawer-paperAnchorRight': { rowGap: 0 },
};

interface IAddEditEngItemMasterProps<GenericInitialValues>
  extends Omit<InspectorState<GenericInitialValues>, 'currentValues'>,
    Pick<InspectorActions<GenericInitialValues>, 'setEditingState'> {
  tabs: TAddEditTabs;
  // eslint-disable-next-line no-unused-vars
  onClose: (hasDataChanged: boolean) => void;
  // open: boolean;
  // eslint-disable-next-line no-unused-vars
  setNeedsCloseConfirmation: (payload: boolean) => void;

  // eslint-disable-next-line no-undef
  buttonStrip?: JSX.Element;
}

const AddEditEngItemMaster = <GenericInitialValues,>(
  props: IAddEditEngItemMasterProps<GenericInitialValues>
) => {
  const {
    onClose,
    tabs,
    buttonStrip,

    setNeedsCloseConfirmation,
    editingState,

    isCreatingNewItem,
    needsCloseConfirmation,
    setEditingState,
  } = props;

  const _handleClose = useCallback(() => {
    if (editingState.tabError || isCreatingNewItem) {
      setNeedsCloseConfirmation(true);
    } else {
      onClose(editingState.hasEdited);
    }
  }, [
    editingState.hasEdited,
    editingState.tabError,
    isCreatingNewItem,
    onClose,
    setNeedsCloseConfirmation,
  ]);

  return (
    <Box sx={customSx}>
      <Box sx={containerSx}>
        <EditableTabs
          editingState={editingState}
          setEditingState={setEditingState}
          tabs={tabs}
        />
        <CloseInspectorConfirmation
          editingState={editingState}
          needsCloseConfirmation={needsCloseConfirmation}
          onClose={_handleClose}
          setNeedsCloseConfirmation={setNeedsCloseConfirmation}
        />
      </Box>

      {buttonStrip}
    </Box>
  );
};

export default AddEditEngItemMaster;
