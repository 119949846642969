import { Box } from '@mui/material';
import Typography from '@mui/material/Typography';
import { SxProps, Theme } from '@mui/material/styles';
import {
  MessageContext,
  OkCancelConfirmDialog,
} from '@teto/react-component-library-v2';
import dayjs from 'dayjs';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  getEmployee,
  getEmployees,
  getGraphQLClient,
  getHourTypesForTimecard,
  getNonConformances,
  Permission,
} from 'teto-client-api';
import useLocalStorage from 'use-local-storage';
import getActiveJobs from '../../getActiveJobs';
import { ProcessScheduleDetail } from '../../../../__generated__/graphql';
import getErrors from '../../../../api/graphQL/getErrors';
import AuthContext from '../../../../contexts/AuthContext';
import ClockInTimerContext from '../../../../contexts/ClockInContext';
import TimerContext from '../../../../contexts/PunchInTimerContext';
import InlineHelpLink from '../../../HelpLinks/InlineHelpLink/InlineHelpLink';
import { getTimecardProjects } from '../../../TETOForms/selects/selects';
import RDGSelectedType from '../../../TetoGrid/types/RDGSelectedType';
import TimeCardInspector from '../../TimeCardInspector/TimeCardInspector';
import StartTimerForm from '../../TimeSheetForms/Timer/StartTimerForm';
import { getProcessScheduleDetailsQuery } from '../../TimeSheetForms/queries/getProcessScheduleDetailsQuery';

interface StartTimeInspectorProps {
  open: boolean;
  onClose: () => void;
  onUpdate: () => void;
  canUseTimer: boolean;
  gridRowData?: RDGSelectedType;
}

const geoErrorSx: SxProps = {
  color: 'error.main',
};

const errorSx = (theme: Theme) => ({
  display: 'flex',
  columnGap: theme.spacing(2),
  alignItems: 'center',
});

const StartTimeInspector = (props: StartTimeInspectorProps) => {
  const authContext = useContext(AuthContext);
  const messageContext = useContext(MessageContext);
  const timerContext = useContext(TimerContext);
  const { geoInfo } = timerContext;
  const { refreshTimer } = useContext(ClockInTimerContext);

  const { t } = useTranslation();
  const { open, onClose, onUpdate, canUseTimer, gridRowData } = props;

  const [defaultHourType, setDefaultHourType] = useState<number | undefined>();
  const [defaultEmployee, setDefaultEmployee] = useState<number | undefined>();
  const [formHasChanges, setFormHasChanges] = useState(false);
  const [needsCloseConfirmation, setNeedsCloseConfirmation] = useState(false);
  const [barcodeEnabled, setBarcodeEnabled] = useLocalStorage(
    'barcode-scanner-enabled',
    false
  );

  const _onError = (err: string | Error) => {
    messageContext.setMessage(
      typeof err === 'string' ? err : err.message,
      'error'
    );
  };

  useEffect(() => {
    if (authContext.user) {
      getEmployee(authContext.user?.id)
        .then((a) => {
          setDefaultHourType(a.hourTypeId);
          setDefaultEmployee(a.id);
        })
        .catch((err) => {
          // eslint-disable-next-line no-console
          console.warn(err);
        });
    }
  }, [authContext.user]);

  const _toggleBarcode = useCallback(() => {
    setBarcodeEnabled((bc) => !bc);
  }, [setBarcodeEnabled]);

  const getTimecardProject = useCallback(
    () =>
      getGraphQLClient()
        .performQuery(getTimecardProjects.query, {})
        .then((d) => {
          if (d.hasError()) {
            d.handleAllErrors(messageContext.setError);
            return;
          }
          if (d.hasData() && d.data?.projectsWithActiveSpecsForTimecards) {
            return d.data?.projectsWithActiveSpecsForTimecards.items ?? [];
          }
        }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const getJobs = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (projectId: number): Promise<any> =>
      getGraphQLClient()
        .performQuery(getActiveJobs, { projectId })
        .then((d) => {
          if (d.hasError()) {
            d.handleAllErrors(messageContext.setError);
            return;
          }
          if (d.hasData() && d.data?.specs) {
            return d.data.specs.items ?? [];
          }
        }),

    [messageContext.setError]
  );

  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {authContext.user && (
        <TimeCardInspector
          barcodeEnabled={barcodeEnabled && !geoInfo.geoError}
          data={[
            {
              employeeFullName: `${authContext.user.firstName} ${authContext.user?.lastName}`,
              hourTypeName: defaultHourType?.toString() ?? '',
              projectName: '',
              specName: '',
              punchInCoords: geoInfo.location,
              id: authContext.user.id,
            },
          ]}
          disableMap={geoInfo.geoError}
          lockedInspector={geoInfo.geoError}
          onClose={() => {
            if (formHasChanges) {
              setNeedsCloseConfirmation(true);
            } else {
              onClose();
            }
          }}
          open={open}
          title={`${t('generic.start')} ${t('entities:PunchIn.PunchIn')}`}
          toggleBarcode={_toggleBarcode}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              flexGrow: 0,
              flexShrink: 0,
            }}
          >
            {geoInfo.geoError && (
              <Box sx={errorSx}>
                <Typography align="left" sx={geoErrorSx}>
                  {t('forms.geoLocation.startTimerError')}
                </Typography>
                <InlineHelpLink
                  helpId="8637679060756"
                  subSectionId="allow-access-to-your-physical-location-upon-first-visit-0-1"
                  title={t('forms.geoLocation.access')}
                />
              </Box>
            )}
          </Box>
          <StartTimerForm
            allowEmployeeSelection={false}
            buttons={{
              right: {
                title: 'Start Timer',
              },
            }}
            canUseTimer={canUseTimer && !geoInfo.geoError}
            employee={authContext.user}
            fieldCaptions={{
              employeeId: t('entities:Employee.Employee'),
              comments: t('forms.fields.comments'),
              hourRate: t('entities:Timecard.HourRate'),
              hourFactor: t('entities:Timecard.HourFactor'),
              custom1: t('entities:Timecard.TimecardCustom1'),
              custom2: t('entities:Timecard.TimecardCustom2'),
              custom3: t('entities:Timecard.TimecardCustom3'),
              custom4: t('entities:Timecard.TimecardCustom4'),
              custom5: t('entities:Timecard.TimecardCustom5'),
              custom6: t('entities:Timecard.TimecardCustom6'),
              custom7: t('entities:Timecard.TimecardCustom7'),
              custom8: t('entities:Timecard.TimecardCustom8'),
              hourTypeId: t('entities:HourType.HourType'),
              hour: t('forms.fields.hour'),
              specId: t('entities:Machine.Job'),
              nonConformanceId: t('entities:NonConformance.NonConformance'),
              processScheduleDetailId: t(
                'entities:ProcessScheduleDetail.ProcessScheduleDetail'
              ),
              projectId: t('entities:Project.Project'),
              quantity: t(
                'entities:ProcessScheduleInHouseTransaction.Quantity'
              ),
              pSCCustom1: t(
                'entities:ProcessScheduleInHouseTransaction.CompletedLogCustom1'
              ),
              pSCCustom2: t(
                'entities:ProcessScheduleInHouseTransaction.CompletedLogCustom2'
              ),
              pSCCustom3: t(
                'entities:ProcessScheduleInHouseTransaction.CompletedLogCustom3'
              ),
              pSCCustom4: t(
                'entities:ProcessScheduleInHouseTransaction.CompletedLogCustom4'
              ),
              pSCCustom5: t(
                'entities:ProcessScheduleInHouseTransaction.CompletedLogCustom5'
              ),
              pSCCustom6: t(
                'entities:ProcessScheduleInHouseTransaction.CompletedLogCustom6'
              ),
              pSCCustom7: t(
                'entities:ProcessScheduleInHouseTransaction.CompletedLogCustom7'
              ),
              pSCCustom8: t(
                'entities:ProcessScheduleInHouseTransaction.CompletedLogCustom8'
              ),
            }}
            fieldsDisableEditable={
              geoInfo.geoError
                ? true
                : {
                    employeeId: typeof getEmployees === 'function',
                    nonConformanceId: false,
                    processScheduleDetailId: false,
                    quantity: true,
                    comments: false,
                    custom1: false,
                    custom2: false,
                    custom3: false,
                    custom4: false,
                    custom5: false,
                    custom6: false,
                    custom7: false,
                    custom8: false,
                    hourTypeId: false,
                    hour: true,
                    specId: false,
                    projectId: false,
                    pSCCustom1: false,
                    pSCCustom2: false,
                    pSCCustom3: false,
                    pSCCustom4: false,
                    pSCCustom5: false,
                    pSCCustom6: false,
                    pSCCustom7: false,
                    pSCCustom8: false,
                  }
            }
            getHourTypes={(
              projectId,
              specId,
              processScheduleDetailId,
              nonConformanceId,
              employeeId
            ) =>
              getHourTypesForTimecard({
                disablePaging: true,
                filter: {
                  active: true,
                  projectId: projectId ?? null,
                  specId: specId ?? null,
                  processScheduleDetailId: processScheduleDetailId ?? null,
                  nonConformanceId: nonConformanceId ?? null,
                  employeeId: employeeId ?? null,
                },
              }).then((e) => e.records)
            }
            getJobs={(projectId: number) => getJobs(projectId)}
            getNonConformances={(projectId: number, specId: number) =>
              getNonConformances({
                disablePaging: true,
                filter: { projectId, specId },
                orderBy: { creationDate: true },
              }).then((e) => e.records)
            }
            getProcessSchedules={(projectId: number, specId: number) =>
              getGraphQLClient()
                .performQuery(getProcessScheduleDetailsQuery, {
                  date: dayjs(),
                  projectId,
                  specId,
                })
                .then((d) => {
                  if (d.hasError()) {
                    if (d.hasValidationErrors()) {
                      messageContext.setError(getErrors(d.validationErrors));
                    }

                    if (d.hasSystemErrors()) {
                      messageContext.setError(getErrors(d.systemErrors));
                    }
                  }
                  if (d.hasData()) {
                    return d.data.timecardProcessScheduleDetails?.items;
                  }
                  return [];
                }) as Promise<ProcessScheduleDetail[]>
            }
            getProjects={() => getTimecardProject()}
            initialValues={{
              employeeId: defaultEmployee || -2,
              comments: '',
              custom1: '',
              custom2: '',
              custom7: false,
              custom8: false,
              hourTypeId: defaultHourType || -1,
              hourFactor: 1,
            }}
            onError={_onError}
            onSubmit={async (request) => {
              timerContext
                .startMyPunchIn({
                  hourTypeId: request.hourTypeId,
                  specId: request.specId,
                  projectId: request.projectId,
                  location: geoInfo.location,
                  custom1: request.custom1,
                  custom2: request.custom2,
                  custom3: request.custom3,
                  custom4: request.custom4,
                  custom5: request.custom5,
                  custom6: request.custom6,
                  custom7: request.custom7,
                  custom8: request.custom8,
                  nonConformanceId: request.nonConformanceId ?? undefined,
                  processScheduleDetailId:
                    request.processScheduleDetailId ?? undefined,
                  comments: request.comments,
                  pSCCustom1: request.pSCCustom1,
                  pSCCustom2: request.pSCCustom2,
                  pSCCustom3: request.pSCCustom3,
                  pSCCustom4: request.pSCCustom4,
                  pSCCustom5: request.pSCCustom5,
                  pSCCustom6: request.pSCCustom6,
                  pSCCustom7: request.pSCCustom7,
                  pSCCustom8: request.pSCCustom8,
                  hourFactor: request.hourFactor,
                  hourRate: request.hourRate,
                  // eslint-disable-next-line @typescript-eslint/no-explicit-any
                } as any)
                .then(() => {
                  if (
                    !authContext.disableClockIn &&
                    authContext.hasPermission(
                      Permission.View_Time_Client_Clockins
                    )
                  ) {
                    refreshTimer();
                  }
                  onUpdate();
                });
            }}
            qrEnabled={barcodeEnabled && !geoInfo.geoError}
            rowData={gridRowData}
            setFormDirty={(e) => setFormHasChanges(e)}
          />
          <OkCancelConfirmDialog
            content={t('dialogs.closeUnsavedForm.content')}
            onCancel={() => {
              setNeedsCloseConfirmation(false);
            }}
            onOk={() => {
              setNeedsCloseConfirmation(false);
              onClose();
            }}
            open={needsCloseConfirmation}
            title={t('dialogs.closeUnsavedForm.title')}
          />
        </TimeCardInspector>
      )}
    </>
  );
};

export default StartTimeInspector;
