import * as Yup from 'yup';
import { TFunction } from 'react-i18next';
import {
  MAX_100,
  MAX_255,
  MAX_50,
} from '../../../../../constants/numbers/numbers';
import { REQUIRED_MESSAGE } from '../../../../../constants/strings/strings';

export const createSchema = Yup.object().shape({
  name: Yup.string().required(REQUIRED_MESSAGE).max(MAX_100),
});

export const editSchema = (t: TFunction<'translation', undefined>) =>
  Yup.object().shape({
    active: Yup.bool().required(REQUIRED_MESSAGE),
    businessAddressSameAsMailing: Yup.boolean().required(REQUIRED_MESSAGE),
    custom1: Yup.string().nullable(),
    custom2: Yup.string().nullable(),
    custom3: Yup.number().nullable(),
    custom4: Yup.number().nullable(),
    custom5: Yup.date().nullable(),
    custom6: Yup.date().nullable(),
    custom7: Yup.boolean().required(REQUIRED_MESSAGE),
    custom8: Yup.boolean().required(REQUIRED_MESSAGE),
    date: Yup.date().nullable(),
    defaultEmailAddress: Yup.string().max(MAX_255),
    defaultPartDiscount: Yup.number()
      .nullable()
      .lessThan(
        1,
        `${t('entities:Company.DefaultPartDiscount')} must be less than 100%.`
      ),
    defaultPartMargin: Yup.number()
      .nullable()
      .lessThan(
        1,
        `${t('entities:Company.DefaultPartMargin')} must be less than 100%.`
      ),
    defaultShipViaId: Yup.number().nullable(),
    defaultTermsId: Yup.number().nullable(),
    fax: Yup.string().max(MAX_50).nullable(),
    hasAgent: Yup.bool().required(REQUIRED_MESSAGE),
    name: Yup.string().required(REQUIRED_MESSAGE).max(MAX_100),
    parentId: Yup.number().nullable(),
    phone: Yup.string().max(MAX_50).nullable(),
    shippingAddressSameAsMailing: Yup.boolean().required(REQUIRED_MESSAGE),
    toolFree: Yup.string().max(MAX_50).nullable(),
    webSite: Yup.string().max(MAX_255).nullable(),
  });
