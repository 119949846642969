import { gql } from '../../../../../../__generated__';

// eslint-disable-next-line import/prefer-default-export
export const nonConformanceNotesQuery =
  gql(`query nonConformanceNotes ($id: Int!) {
  nonConformanceNotes (id: $id) {
    items {
      date
      employeeId
      id
      nonConformanceId
      note
      noteEmployee {
        id
        name
      }
    }
  }
}`);
