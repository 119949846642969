function ensureKeysExist<T>(
  input: Record<string, unknown> | undefined | null,
  keysToEnsure: string[]
) {
  const result = input ? { ...input } : {};
  keysToEnsure.forEach((key) => {
    if (!(key in result)) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (result as any)[key] = undefined;
    }
  });
  return result as unknown as T;
}

export default ensureKeysExist;

//  example usage
//  const data = { a: 1, b: 2 };
//  const result = ensureKeysExist(data, ['a', 'b', 'c', 'd']);
//  console.log(result);
// {
//   a: 1,
//   b: 2,
//   c: undefined,
//   d: undefined,
// };
