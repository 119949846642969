import { MessageContext } from '@teto/react-component-library-v2';
import { useContext } from 'react';
import { useQuery } from 'react-query';
import { getGraphQLClient } from 'teto-client-api';
import getErrors from '../../api/graphQL/getErrors';
import {
  inventoryLocations,
  inventoryLocationsFiltered,
} from './queries/inventoryLocation';
import { InventoryLocation } from './types/inventoryLocation';

const useInventoryLocation = (filterId?: number, queryKey?: string) => {
  const messageContext = useContext(MessageContext);
  const locations = useQuery(
    queryKey || 'inventoryLocations',
    () =>
      filterId !== undefined && filterId != null
        ? getGraphQLClient()
            .performQuery(inventoryLocationsFiltered, {
              id: filterId,
            })
            .then((d) => {
              if (d.hasError()) {
                d.showAllSystemErrors(messageContext.setError);
                if (d.hasValidationErrors()) {
                  const errors = getErrors(d.validationErrors);
                  messageContext.setError(errors);
                }
                return;
              }
              return d.data.inventoryLocations?.items as InventoryLocation[];
            })
        : getGraphQLClient()
            .performQuery(inventoryLocations)
            .then((d) => {
              if (d.hasError()) {
                d.showAllSystemErrors(messageContext.setError);
                if (d.hasValidationErrors()) {
                  const errors = getErrors(d.validationErrors);
                  messageContext.setError(errors);
                }
                return;
              }
              return d.data.inventoryLocations?.items as InventoryLocation[];
            }),
    {
      refetchOnWindowFocus: false,
    }
  );
  return locations;
};

export default useInventoryLocation;
