import { Box, Grid, SxProps, Theme } from '@mui/material';
import {
  ButtonStrip,
  ConfirmDialogs,
  ETOButton,
  ETOTextField,
  MessageContext,
  OkCancelConfirmDialog,
} from '@teto/react-component-library-v2';
import { useFormik } from 'formik';
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { getGraphQLClient } from 'teto-client-api';
import { TypedDocumentNode } from '../../../../api/graphQL/types/TypeDocumentNode';
import FilePicker from '../../../FilePicker/FilePicker';
import { FileType } from '../../../FilePicker/types';
import EditingState from '../../types/EditingState';
import AutoSave from '../AddEditInspector/AutoSave/AutoSave';

const contentSx: SxProps<Theme> = {
  display: 'flex',
  flexDirection: 'column',
  flexGrow: 1,
  flexShrink: 1,
  flexWrap: 'nowrap',
  justifyContent: 'flex-start',
  overflowY: 'unset',
  padding: 2,
  rowGap: 1,
};

const rootSx: SxProps<Theme> = {
  border: (theme) => `1px solid ${theme.palette.grey[400]}`,
  borderRadius: (theme) => theme.spacing(0.5),
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  width: '100%',
};

const inputSx = { display: 'flex', gap: 2, height: '100%' };

const heightSx = { height: (theme: Theme) => theme.spacing(5) };

const REQUIRED_MESSAGE = 'Required';

const DocumentValidationSchema = Yup.object().shape({
  id: Yup.number(),
  description: Yup.string(),
  documentLocation: Yup.string().required(REQUIRED_MESSAGE),
});

interface CommonEditDocumentProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  addNewMutation: string | TypedDocumentNode<any, any>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  deleteMutation: string | TypedDocumentNode<any, any>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  record?: any;
  entityIdValue: number;
  entityName: string;
  permissions: {
    canDeleteDocuments: boolean;
    canModifyDocuments: boolean;
  };
  onSaveSuccess: () => void;
  onRecordCountChanged: () => void;
  // eslint-disable-next-line no-unused-vars
  setPanelEditingState: (editingState: EditingState) => void;
  confirmAbandonForm: boolean;
  // eslint-disable-next-line no-unused-vars
  setConfirmAbandonForm: (closeForm?: boolean) => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setSelectedDocumentItem: React.Dispatch<any>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  updateMutation: string | TypedDocumentNode<any, any>;
  shouldSupportBatch?: boolean;
  shouldSupportMultipleLocations?: boolean;
  includeEntityIdInBatchMutation?: boolean;
}

interface Document {
  documentLocation: string;
  description?: string;
}

const CommonEditDocument = (props: CommonEditDocumentProps) => {
  const {
    addNewMutation,
    confirmAbandonForm,
    deleteMutation,
    entityIdValue,
    entityName,
    includeEntityIdInBatchMutation,
    onRecordCountChanged,
    onSaveSuccess,
    permissions,
    record,
    setConfirmAbandonForm,
    setPanelEditingState,
    setSelectedDocumentItem,
    shouldSupportBatch,
    shouldSupportMultipleLocations,
    updateMutation,
  } = props;

  const { t } = useTranslation();

  const messageContext = useContext(MessageContext);

  const mode = record ? 'edit' : 'add';

  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState<boolean>(false);
  const [isFilePickerOpen, setIsFilePickerOpen] = useState<boolean>(false);

  const formik = useFormik<Document>({
    enableReinitialize: true,
    validationSchema: DocumentValidationSchema,
    initialValues: {
      description: record?.documentLocation?.split('#')[0] ?? '',
      documentLocation: record?.documentLocation?.split('#')[1] ?? '',
    },
    onSubmit: (values, actions) => {
      actions.setSubmitting(true);
      if (mode === 'edit') {
        _submitEditForm(values, actions);
      } else {
        _onAdd();
      }
    },
  });

  const editMutationParams = useMemo(() => {
    if (mode === 'add') return;
    if (mode === 'edit' && !record) return;
    if (shouldSupportBatch && includeEntityIdInBatchMutation) {
      return {
        input: {
          [`${entityName.charAt(0).toLowerCase() + entityName.slice(1)}Id`]:
            entityIdValue,
          batch: [
            {
              [`${
                entityName.charAt(0).toLowerCase() + entityName.slice(1)
              }DocumentId`]: parseInt(record?.id, 10),
              documentLocation: `${formik.values.description}#${formik.values.documentLocation}#${formik.values.description}`,
            },
          ],
        },
      };
    }
    if (shouldSupportBatch) {
      // #76667
      return {
        input: {
          batch: [
            {
              [`${
                entityName.charAt(0).toLowerCase() + entityName.slice(1)
              }DocumentId`]: parseInt(record?.id, 10),
              documentLocation: `${formik.values.description}#${formik.values.documentLocation}#${formik.values.description}`,
            },
          ],
        },
      };
    }

    if (mode === 'edit') {
      return {
        input: {
          id: parseInt(record?.id, 10),
          documentLocation: `${formik.values.description}#${formik.values.documentLocation}#${formik.values.description}`,
        },
      };
    }
    messageContext.setError(t('generic.message.invalidMutationParams'));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    mode,
    record,
    shouldSupportBatch,
    shouldSupportMultipleLocations,
    t,
    entityName,
    formik.values.description,
    formik.values.documentLocation,
    entityIdValue,
  ]);

  const handleSuccess = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (message: string, actions: any) => {
      messageContext.setSuccess(message);
      actions.resetForm();
      onSaveSuccess();
    },
    [messageContext, onSaveSuccess]
  );
  const addMutationParams = useMemo(() => {
    if (shouldSupportMultipleLocations) {
      return {
        input: {
          [`${entityName.charAt(0).toLowerCase() + entityName.slice(1)}Id`]:
            entityIdValue,
          // has a `s` at the end of documentLocations #76668
          documentLocations: `${formik.values.description}#${formik.values.documentLocation}#${formik.values.description}`,
        },
      };
    }

    return {
      input: {
        [`${entityName.charAt(0).toLowerCase() + entityName.slice(1)}Id`]:
          entityIdValue,
        documentLocation: `${formik.values.description}#${formik.values.documentLocation}#${formik.values.description}`,
      },
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    shouldSupportMultipleLocations,
    entityName,
    entityIdValue,
    formik.values.description,
    formik.values.documentLocation,
  ]);

  const _submitEditForm = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (values: Document, actions: any) =>
      getGraphQLClient()
        .performMutation(updateMutation, {
          ...editMutationParams,
        })
        .then((e) => {
          if (e.hasData()) {
            handleSuccess(t('generic.message.documentUpdate'), actions);
          }
          if (e.hasError()) {
            e.handleAllErrors(messageContext.setError, formik.setErrors);
          }
        })
        .finally(() => actions.setSubmitting(false)),

    [
      updateMutation,
      editMutationParams,
      handleSuccess,
      t,
      messageContext.setError,
      formik.setErrors,
    ]
  );

  const _onDeleteClicked = () => {
    getGraphQLClient()
      .performMutation(deleteMutation, {
        id: parseInt(record?.id, 10),
      })
      .then((e) => {
        if (e.hasData()) {
          if (e.data[`delete${entityName}Document`].success) {
            messageContext.setSuccess(
              t('generic.deletedSuccess', { record: 'Document' })
            );
            setIsDeleteDialogOpen(false);
            onRecordCountChanged();
          }
        }
        if (e.hasError()) {
          e.handleAllErrors(messageContext.setError, formik.setErrors);
        }
      });
  };

  const _onAdd = useCallback(() => {
    getGraphQLClient()
      .performMutation(addNewMutation, { ...addMutationParams })
      .then((d) => {
        if (d.hasData()) {
          const { data } = d;
          const addEntity =
            data[`add${entityName}Documents`] ||
            data[`add${entityName}Document`];
          if (addEntity) {
            const successMessage = t('generic.createdSuccess', {
              record: 'Document',
            });
            onSaveSuccess();
            messageContext.setSuccess(successMessage);
          } else {
            messageContext.setError(t('generic.message.documentAddError'));
          }
        }

        if (d.hasError()) {
          d.handleAllErrors(messageContext.setError);
        }
      });
  }, [
    addNewMutation,
    addMutationParams,
    entityName,
    t,
    onSaveSuccess,
    messageContext,
  ]);

  useEffect(() => {
    if (!formik.isValid) {
      setPanelEditingState({
        isEditing: formik.dirty,
        hasEdited: formik.dirty,
        tabError: true,
      });
    } else {
      setPanelEditingState({
        isEditing: formik.isSubmitting,
        hasEdited: formik.dirty,
        tabError: false,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.dirty, formik.isSubmitting, formik.isValid, mode]);

  return (
    <Box sx={rootSx}>
      {mode === 'edit' && <AutoSave debounceMs={1500} formik={formik} />}
      <Box sx={contentSx}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Box m={0} mb={0} sx={inputSx}>
              <ETOTextField
                disabled={!permissions.canModifyDocuments}
                error={formik.errors.documentLocation}
                handleChange={formik.handleChange}
                label={t('generic.location')}
                name="documentLocation"
                size="small"
                value={formik.values.documentLocation}
              />
              <ETOButton
                color="primary"
                customSx={heightSx}
                onClick={() => setIsFilePickerOpen(true)}
                size="medium"
              >
                {t('generic.select')}
              </ETOButton>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Box m={0} mb={0}>
              <ETOTextField
                disabled={!permissions.canModifyDocuments}
                error={formik.errors.description}
                handleChange={formik.handleChange}
                label={t('generic.description')}
                name="description"
                size="small"
                value={formik.values.description}
              />
            </Box>
          </Grid>
        </Grid>
      </Box>
      <Box>
        {mode === 'edit' && (
          <ButtonStrip
            rightButton={{
              text: t('generic.delete'),
              color: 'error',
              onClick: () => setIsDeleteDialogOpen(true),
              disabled: !permissions.canDeleteDocuments,
              customSx: (theme) => ({
                ml: theme.spacing(1),
                mb: theme.spacing(1),
              }),
            }}
            size="medium"
          />
        )}
        {mode === 'add' && (
          <ButtonStrip
            leftButton={{
              text: t('generic.reset'),
              onClick: () => formik.resetForm(),
              color: 'secondary',
              customSx: (theme) => ({
                ml: theme.spacing(1),
                mb: theme.spacing(1),
              }),
            }}
            rightButton={{
              text: t('generic.add'),
              onClick: () => formik.submitForm(),
              color: 'primary',
              customSx: (theme) => ({
                mr: theme.spacing(1),
                mb: theme.spacing(1),
              }),
            }}
            size="medium"
          />
        )}
      </Box>
      <ConfirmDialogs
        content={t('dialogs.deleteGenericItem.content')}
        leftButton={{
          onClick: () => setIsDeleteDialogOpen(false),
          label: t('generic.no'),
        }}
        open={isDeleteDialogOpen}
        rightButton={{
          onClick: () => _onDeleteClicked(),
          label: t('generic.yes'),
        }}
        title={t('dialogs.deleteGenericItem.title')}
      />
      {confirmAbandonForm && (
        <OkCancelConfirmDialog
          content={t('dialogs.closeUnsavedForm.content')}
          onCancel={() => {
            setConfirmAbandonForm(false);
          }}
          onOk={() => {
            setConfirmAbandonForm(true);
            setPanelEditingState({
              isEditing: false,
              hasEdited: false,
              tabError: false,
            });
            setSelectedDocumentItem(undefined);
          }}
          open={confirmAbandonForm}
          title={t('dialogs.closeUnsavedForm.title')}
        />
      )}
      {isFilePickerOpen && (
        <FilePicker
          isOpen={isFilePickerOpen}
          onClose={() => {
            setIsFilePickerOpen(false);
          }}
          // eslint-disable-next-line no-shadow
          onPick={(file: FileType) => {
            formik.setValues(
              {
                ...formik.values,
                documentLocation: file.id as string,
                description: file.name,
              },
              false
            );
          }}
        />
      )}
    </Box>
  );
};

export default CommonEditDocument;
