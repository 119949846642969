import { gql } from '../../../__generated__';

export default gql(`query inventoryStockReorder($filter: InventoryFilterInput){
  inventories(where: $filter, order: [{ item: { itemCompanyId: ASC } }]) {
   items {
     inventoryId
      maximumQuantity
      qtyMinRequired
      recommendedQuantity
      qtyOnHand
      otherQuantities {
        available
      }
      onCalculations {
        onOrder
        onOrder
        onOpenWOP
        onPurchasingScreen
      },
      item {
        id
        itemCompanyId
        description
        uOMId
        uOM {
          description
          itemUom
          uomtype
        }
      }
   }
  }
}`);
