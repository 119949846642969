import { atom } from 'jotai';
import type { PrimitiveAtom } from 'jotai';

export const DataSourceAtom = atom(false);

export const setDataSourceAtomTrue = (boolAtom: PrimitiveAtom<boolean>) =>
  atom(null, (_, set) => set(boolAtom, true));

export const setDataSourceAtomFalse = (boolAtom: PrimitiveAtom<boolean>) =>
  atom(null, (_, set) => set(boolAtom, false));
