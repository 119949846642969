export default `
query getSimilarCompanies($name: String!){
  companies(where: {name: {eq: $name}}, order: {city: ASC}){
    items {
      id
      name
      active
      city
    }
  }
}`;
