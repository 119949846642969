import { SvgIcon, SvgIconProps } from '@mui/material';
import React from 'react';

const ReleaseIcon = (props: SvgIconProps) => (
  <SvgIcon
    {...props}
    height="1em"
    viewBox="0 0 24 24"
    width="1em"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="m12 16l1.4-1.4l-1.575-1.6H16v-2h-4.175L13.4 9.4L12 8l-4 4Zm-7 5q-.825 0-1.413-.587Q3 19.825 3 19V5q0-.825.587-1.413Q4.175 3 5 3h4.2q.325-.9 1.088-1.45Q11.05 1 12 1t1.713.55Q14.475 2.1 14.8 3H19q.825 0 1.413.587Q21 4.175 21 5v14q0 .825-.587 1.413Q19.825 21 19 21Zm0-2h14V5H5v14Zm7-14.75q.325 0 .538-.213q.212-.212.212-.537q0-.325-.212-.538q-.213-.212-.538-.212q-.325 0-.537.212q-.213.213-.213.538q0 .325.213.537q.212.213.537.213ZM5 19V5v14Z" />
  </SvgIcon>
);

export default ReleaseIcon;
