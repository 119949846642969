import { useSetAtom } from 'jotai';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Contact } from '../../../__generated__/graphql';
import { ContactSharedState } from '../../SharedStateComponents/StateContainers/ContactState';
import AddEditInspector from '../components/AddEditInspector/AddEditInspector';
import { TAddEditTabs } from '../components/AddEditInspector/components/EditableTabs';
import useInspectorState from '../hooks/useInspectorState';
import EditingState from '../types/EditingState';
import { InspectorState } from '../types/InspectorState';
import { PanelState } from '../types/PanelState';
import ContactsInfoPanel from './panels/ContactsInfoPanel/ContactsInfoPanel';
import ContactsNotesPanel from './panels/NotePanel/ContactsNotesPanel';
import { AddEditContactInspectorProps } from './types/AddEditContactInspectorProps';

const initialEditingState: EditingState = {
  isEditing: false,
  hasEdited: false,
  tabError: false,
};

const AddEditContactInspector = (props: AddEditContactInspectorProps) => {
  // eslint-disable-next-line no-unused-vars
  const { initialValues, open, onClose } = props;
  const { ready, t } = useTranslation();

  const [panelState, setPanelState] = useState<PanelState>({
    mode: 'create',
    id: undefined,
  });

  const initialState: InspectorState<Contact | undefined> = useMemo(
    () => ({
      hasASaveOccurred: false,
      isCreatingNewItem: false,
      needsCloseConfirmation: false,
      editingState: initialEditingState,
      currentValues: undefined,
    }),
    []
  );

  const setContactState = useSetAtom(ContactSharedState);

  const {
    state,
    setEditingState,
    setHasASaveOccurred,
    // eslint-disable-next-line no-unused-vars
    setIsCreatingNewItem,
    setNeedsCloseConfirmation,
  } = useInspectorState<Partial<Contact> | undefined>(initialState);

  const {
    // eslint-disable-next-line no-unused-vars
    currentValues,
    editingState,
    hasASaveOccurred,
    isCreatingNewItem,
    needsCloseConfirmation,
  } = state;

  useEffect(() => {
    if (state.hasASaveOccurred) {
      setContactState((prev) => ({
        ...prev,
        hasSavedOccurred: state.hasASaveOccurred,
      }));
    }
  }, [setContactState, state]);

  const tabs: TAddEditTabs = useMemo(
    () => [
      {
        tabName: 'info',
        tabLabel: `${t('generic.info')}`,
        tabIndex: 0,
        tabPanel: (
          <ContactsInfoPanel
            initialValues={initialValues}
            panelMode={panelState.mode}
            setEditingState={setEditingState}
            setHasASaveOccurred={setHasASaveOccurred}
            setIsCreatingNewItem={setIsCreatingNewItem}
            setNeedsCloseConfirmation={setNeedsCloseConfirmation}
            setPanelState={setPanelState}
          />
        ),
        tabTitle: `Error In ${t('generic.info')} Form`,
      },
      ...(panelState.mode === 'edit'
        ? [
            {
              tabName: 'notes',
              tabLabel: t('generic.notes'),
              tabIndex: 1,
              tabPanel: (
                <ContactsNotesPanel
                  editingState={editingState}
                  entityIdValue={panelState?.id}
                  setEditingState={setEditingState}
                  setHasASaveOccurred={setHasASaveOccurred}
                  setIsCreatingNewItem={setIsCreatingNewItem}
                />
              ),
              tabTitle: `${'generic.error'} in ${t('generic.notes')}`,
            },
          ]
        : []),
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [t, editingState, initialValues, panelState.mode]
  );

  return (
    <>
      {ready && (
        <>
          <AddEditInspector
            editingState={editingState}
            hasASaveOccurred={hasASaveOccurred}
            isCreatingNewItem={isCreatingNewItem}
            needsCloseConfirmation={needsCloseConfirmation}
            onClose={onClose}
            open={open}
            setEditingState={setEditingState}
            setNeedsCloseConfirmation={setNeedsCloseConfirmation}
            tabs={tabs}
            title={t('entities:Contact.Contact')}
          />
        </>
      )}
    </>
  );
};

export default AddEditContactInspector;
