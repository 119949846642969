// import { DocumentNode } from 'graphql';
import { gql } from '../../../../__generated__';

const pODetailSplitQuery = gql(`query getPurchaseOrderDetailSplit($id: Int!) {
  purchaseOrderDetail(id: $id) {
      id
      item {
        itemCompanyId
      }
    purchaseOrderId
     receivingTotals {
      purchaseOrderDetailId
      purchaseQuantity
      received
      unreceived
     }
     spec{
      displayName
      project {
        displayName
      }
     }
    
  }
}`);

export default pODetailSplitQuery;
