import { SxProps } from '@mui/material/styles';
import { ETOTextField } from '@teto/react-component-library-v2';
import React, { useMemo } from 'react';
import useIsMobile from '../../../hooks/useIsMobile';
import { onKeyDown } from '../../TetoGrid/InlineEditing/editingHelpers';
import { CommonEditorProps } from './EditorProps';
import EditorWrapper from './EditorWrapper';
import TooltipWrapper from './TooltipWrapper';

const cellSx: SxProps = {
  '& .InovuaReactDataGrid__cell__editor InovuaReactDataGrid__cell__editor--string':
    {},
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const ETOStringEditor: React.FC<CommonEditorProps> = (props) => {
  const {
    cellProps,
    editorProps,
    errors,
    inputVariant,
    onCancel,
    onChange,
    onComplete,
    onFocus,
    onKeyDown: onKeyDownProp,
    onTabNavigation,
    value,
  } = props;

  const isGridInMobile = useIsMobile();

  const editHandlers = {
    onCancel,
    onComplete,
    onTabNavigation,
  };

  const error = useMemo(
    () => (errors && errors.length > 0 ? errors[0] : undefined),
    [errors]
  );

  const isDisabled = useMemo(() => {
    if (!editorProps?.disabled) return false;
    if (typeof editorProps?.disabled === 'function')
      return editorProps?.disabled(cellProps?.data);
    return editorProps?.disabled;
  }, [cellProps?.data, editorProps]);

  const editor = (
    <ETOTextField
      autoFocus
      disabled={isDisabled}
      error={error}
      handleChange={onChange}
      id={cellProps?.name as string}
      inputProps={{
        autoComplete: 'new-password',
        onFocus,
        onKeyDown: (e: React.KeyboardEvent<HTMLInputElement>) => {
          if (onKeyDownProp) onKeyDownProp?.(e);
          else onKeyDown(e, editHandlers);
        },
        style: {
          minWidth: Math.max(0, (cellProps?.computedWidth as number) - 30),
        },
        tabIndex: 0,
      }}
      maxRows={editorProps?.maxRows ?? 1}
      multiline={editorProps?.multiLine ?? false}
      name={cellProps?.name as string}
      onBlur={onComplete}
      onKeyDown={(e) => onKeyDown(e, editHandlers)}
      sxProps={cellSx}
      value={value}
      variant={inputVariant}
    />
  );

  return cellProps.data !== null ? (
    <EditorWrapper
      sx={{
        '& .MuiInputBase-root': {
          boxSizing: 'border-box !important',
          maxHeight: cellProps.rowHeight
            ? (cellProps?.rowHeight as number) - 2
            : 'unset',
        },
      }}
    >
      {isGridInMobile && (
        <TooltipWrapper error={error}>{editor}</TooltipWrapper>
      )}
      {!isGridInMobile && !isDisabled && editor}
    </EditorWrapper>
  ) : null;
};

export default ETOStringEditor;
