import React from 'react';
import { useTranslation } from 'react-i18next';
import TetoGridDialog from '../TetoGrid/components/TetoGridDialog';

interface ConfirmSaveDialogProps {
  open: boolean;
  onSave: () => void;
  onCancel: () => void;
  onClose: () => void;
}

const ConfirmSaveDialog: React.FC<ConfirmSaveDialogProps> = (props) => {
  const { t } = useTranslation();
  const { open, onSave, onCancel, onClose } = props;

  return (
    <TetoGridDialog
      content={t('dialogs.saveDiscardDialog.content')}
      handleClose={() => onClose()}
      leftButton={{
        label: t('generic.discard'),
        onClick: () => onCancel(),
      }}
      open={open}
      rightButton={{
        label: t('generic.save'),
        onClick: () => onSave(),
      }}
      title={t('dialogs.saveDiscardDialog.title')}
    />
  );
};

export default ConfirmSaveDialog;
