import { gql } from '../../../__generated__';
import SelectDefinition from './SelectDefinition';

export default {
  query: gql(`query getEngItemMasters {
    engItemMasters( order: { itemCompanyId: ASC } ) {
      items {
        itemCompanyId
        id
      }
    }
  }`),
  valueField: 'id',
  labelField: 'itemCompanyId',
} as SelectDefinition;
