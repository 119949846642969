import { Box, SxProps, Theme } from '@mui/material';
import React, { Dispatch, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import EditingState from '../../../types/EditingState';
import { InspectorActions } from '../../../types/InspectorActionTypes';
import { PanelMode } from '../../../types/PanelState';
import TabPanelWithButtonStrip from '../../TabPanel/TabPanelWithButtonStrip';
import CreateButton from './CreateButton';
import SaveButton from './SaveButton';

interface AddEditFormDetailsPanelProps<T>
  extends Pick<InspectorActions<T>, 'setIsCreatingNewItem'> {
  canAdd?: boolean;
  canEdit?: boolean;
  editingState: EditingState;
  panelMode: PanelMode;
  createButton?: React.ReactNode;
  children: React.ReactNode;
  sx?: SxProps<Theme>;
  saveButton?: boolean;
  isSubmitting?: boolean;
  setSubmitForm?: Dispatch<React.SetStateAction<boolean>>;
  customButtonStrip?: React.ReactNode;
  disableButtonStrip?: boolean;
}
const buttonStripSx: SxProps<Theme> = {
  paddingTop: (theme) => theme.spacing(2),
  borderTop: (theme) => `1px solid ${theme.palette.divider}`,
};

const panelSx: SxProps<Theme> = {
  rowGap: 0,
  paddingTop: 0,
};

const AddEditFormDetailsPanel = <T,>(
  props: AddEditFormDetailsPanelProps<T>
) => {
  const {
    canAdd,
    canEdit,
    children,
    createButton,
    customButtonStrip,
    editingState,
    isSubmitting,
    panelMode,
    saveButton,
    setIsCreatingNewItem,
    setSubmitForm,
    disableButtonStrip,
    sx,
  } = props;

  const { t } = useTranslation();

  useEffect(() => {
    if (panelMode === 'create' && editingState.isEditing) {
      setIsCreatingNewItem(true);
    } else {
      setIsCreatingNewItem(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editingState.isEditing, panelMode]);

  return (
    <>
      <TabPanelWithButtonStrip
        buttonStrip={
          <>
            {disableButtonStrip ? undefined : (
              <>
                {customButtonStrip || (
                  <>
                    {createButton || (
                      <Box
                        sx={
                          panelMode === 'create' ? [buttonStripSx] : undefined
                        }
                      >
                        <CreateButton
                          disabled={!canAdd}
                          loading={isSubmitting}
                          onSubmit={() => {
                            if (setSubmitForm) setSubmitForm(true);
                            else {
                              // eslint-disable-next-line no-console
                              console.error('setSubmitForm is undefined');
                            }
                          }}
                          panelMode={panelMode}
                          tooltip={
                            canAdd ? t('generic.create') : t('auth.permission')
                          }
                        />
                      </Box>
                    )}
                    {saveButton && panelMode === 'edit' && (
                      <Box sx={buttonStripSx}>
                        <SaveButton
                          disabled={!canEdit}
                          loading={isSubmitting}
                          onSubmit={() => {
                            if (setSubmitForm) setSubmitForm(true);
                            else {
                              // eslint-disable-next-line no-console
                              console.error('setSubmitForm is undefined');
                            }
                          }}
                          panelMode={panelMode}
                          tooltip={
                            canEdit ? t('generic.save') : t('auth.permission')
                          }
                        />
                      </Box>
                    )}
                  </>
                )}
              </>
            )}
          </>
        }
        sx={{ ...panelSx, ...sx }}
      >
        {children}
      </TabPanelWithButtonStrip>
    </>
  );
};

export default AddEditFormDetailsPanel;
