import { gql } from '../../../__generated__';
import SelectDefinition from './SelectDefinition';

export default {
  query: gql(`
    query getTerms {
      terms {
        items {
          id
          description
        }
      }
    }
  `),
  valueField: 'id',
  labelField: 'description',
} as SelectDefinition;
