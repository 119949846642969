import { Box, Skeleton, SxProps, Theme } from '@mui/material';
import { MessageContext } from '@teto/react-component-library-v2';
import { isEmpty } from 'lodash';
import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Permission } from 'teto-client-api';
import * as Yup from 'yup';
import { PurchaseOrderDetail } from '../../../../../__generated__/graphql';
import useGQLQuery from '../../../../../api/graphQL/useGQLQuery';
import { REQUIRED_MESSAGE } from '../../../../../constants/strings/strings';
import AuthContext from '../../../../../contexts/AuthContext';
import POAddEditDetailsPanel from '../../../../Inspectors/components/AddEditInspector/components/AddEditFormDetailsPanel';
import EditingState from '../../../../Inspectors/types/EditingState';
import { InspectorActions } from '../../../../Inspectors/types/InspectorActionTypes';
import { PanelMode, PanelState } from '../../../../Inspectors/types/PanelState';
import TETOGridRefType from '../../../../TETOGridGraphQL/types/TETOGridRefType';
import purchaseOrderDetailsQuery from '../../queries/purchaseOrderDetailsQuery';
import { PurchaseOrder } from '../../types/PurchaseOrder';
import { TaxSettingsUpdate } from '../../types/TaxSettingsUpdate';
import PurchaseOrderActionBar from './PurchaseOrderActionBar/PurchaseOrderActionBar';
import PurchaseOrderDetailsGrid from './PurchaseOrderDetailsGrid/PurchaseOrderDetailsGrid';
import POCreateHeaderForm, {
  POFormRefType,
} from './PurchaseOrderHeaderForm/PurchaseOrderCreateHeaderForm/POCreateHeaderForm';
import POEditHeaderForm from './PurchaseOrderHeaderForm/PurchaseOrderEditHeaderForm/POEditHeaderForm';
import RDGSelectedType from '../../../../TetoGrid/types/RDGSelectedType';

type IPanelActions = Omit<
  InspectorActions<PurchaseOrder>,
  'setCurrentValues'
> & {
  setPanelState: React.Dispatch<React.SetStateAction<PanelState>>;
};

interface PurchaseOrdersDetailsPanelProps extends IPanelActions {
  editingState: EditingState;
  panelMode: PanelMode;
  initialValues: Partial<PurchaseOrder> | undefined;
  // eslint-disable-next-line no-unused-vars
  setMissingDateCount?: (count: number) => void;
}

type PurchaseOrderWithRequiredDate = Partial<PurchaseOrder> & {
  requiredDateMethod: number;
};

const contentSx: SxProps<Theme> = {
  display: 'flex',
  flexDirection: 'column',
  overflowY: 'auto',
  pt: 1,
};

export const AddPurchaseOrderSchema = Yup.object().shape({
  requiredDateMethod: Yup.date().required(),
  id: Yup.number().required(REQUIRED_MESSAGE),
  purchaseSupplierId: Yup.number().required(REQUIRED_MESSAGE),
});

const panelSx: SxProps<Theme> = {
  rowGap: 0,
  paddingTop: 0,
};

const PurchaseOrdersDetailsPanel = (props: PurchaseOrdersDetailsPanelProps) => {
  const {
    editingState,
    initialValues,
    panelMode,
    setEditingState,
    setHasASaveOccurred,
    setIsCreatingNewItem,
    setMissingDateCount,
    setPanelState,
  } = props;
  const { t } = useTranslation();
  const messageContext = useContext(MessageContext);
  const authContext = useContext(AuthContext);

  const gridRef = useRef<TETOGridRefType>(null);

  const [purchaseOrder, setPurchaseOrder] = useState<
    Partial<PurchaseOrderWithRequiredDate>
  >({});
  const [refreshGridTotal, setRefreshGridTotal] = useState<boolean>(false);

  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [resetGrid, setResetGrid] = useState<boolean>(false);
  const [selectedRows, setSelectedRows] = useState<
    RDGSelectedType | undefined
  >();
  const [taxSettingsUpdate, setTaxSettingsUpdate] =
    useState<TaxSettingsUpdate>();
  const [configureInspectorOpen, setConfigureInspectorOpen] =
    useState<boolean>(false);
  const [hasInventoryItem, setHasInventoryItem] = useState<boolean>(false);

  const readOnly = authContext.hasReadOnlyLicense();

  const [supplierDefaultReportAlt, setSupplierDefaultReportAlt] =
    useState<number>();

  const purchaseOrderPermissions = useMemo(() => {
    const canModify = authContext.hasPermission(
      Permission.Modify_Procurement_PurchaseOrders_PurchaseOrderHeader
    );
    const canAdd = authContext.hasPermission(
      Permission.Add_Procurement_PurchaseOrders_PurchaseOrderHeader
    );
    const modifyPerm = () => {
      if (panelMode === 'create') {
        return canAdd;
      }
      if (readOnly) {
        return false;
      }
      if (panelMode === 'edit') {
        return canModify;
      }
      return true;
    };
    return {
      canAdd,
      canModify: modifyPerm(),
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authContext, panelMode, readOnly]);

  const [refreshQuery, setRefreshQuery] = useState<boolean>(false);

  useGQLQuery(['purchaseOrderDetails', initialValues?.id, refreshQuery], {
    queryString: purchaseOrderDetailsQuery,
    variables: {
      purchaseOrderId: initialValues?.id,
    },
    callback: (resp) => {
      if ('purchaseOrder' in resp) {
        setPurchaseOrder(resp.purchaseOrder);
        setRefreshQuery(false);
        resp.purchaseOrder?.purchaseOrderDetails?.forEach(
          (item: { destInventoryLoc: { location: number } }) => {
            if (item.destInventoryLoc?.location > 0) {
              setHasInventoryItem(true);
            }
          }
        );

        if (panelMode !== 'edit') {
          setPanelState({
            id: initialValues?.id,
            mode: 'edit',
          });
        }
      } else {
        messageContext.setError(t('generic.message.noDataFound'));
      }
    },
    options: {
      refetchOnWindowFocus: false,
      enabled: Boolean(initialValues?.id),
    },
  });

  useEffect(() => {
    if (gridRef?.current?.getGrid()?.dataSource) {
      const dataSource = gridRef?.current?.getGrid()?.dataSource;
      const missingDate =
        (dataSource as PurchaseOrderDetail[])?.filter(
          (i) => !i.dateRequired || !i.dateRevised
        ) || [];
      setMissingDateCount?.(missingDate.length);
    }
  }, [setMissingDateCount]);

  const createPOFormRef = useRef<POFormRefType>();

  const purchaseOrderDetailItems = useMemo(
    () =>
      purchaseOrder?.purchaseOrderDetails?.map(
        (detail: PurchaseOrderDetail | null | undefined) => ({
          id: detail?.id ?? 0,
          itemMasterDescription: detail?.item?.description ?? '',
          entityDescription: detail?.purchaseSupplierDescription ?? '',
        })
      ),
    [purchaseOrder?.purchaseOrderDetails]
  );

  return (
    <POAddEditDetailsPanel
      canAdd={purchaseOrderPermissions.canAdd}
      canEdit={purchaseOrderPermissions.canModify}
      editingState={editingState}
      isSubmitting={isSubmitting}
      panelMode={panelMode}
      setIsCreatingNewItem={setIsCreatingNewItem}
      setSubmitForm={() => createPOFormRef.current?.form?.submit()}
      sx={panelSx}
    >
      {panelMode === 'create' && (
        <POCreateHeaderForm
          ref={(r) => {
            createPOFormRef.current = r ?? undefined;
          }}
          setEditingState={setEditingState}
          setHasASaveOccurred={setHasASaveOccurred}
          setIsSubmitting={setIsSubmitting}
          setPanelState={setPanelState}
          setPurchaseOrder={setPurchaseOrder}
        />
      )}
      {panelMode === 'edit' && (
        <>
          <PurchaseOrderActionBar
            currentShipToId={purchaseOrder?.purchaseShipTo?.id as number}
            defaultSupplierReportId={supplierDefaultReportAlt}
            gridRef={gridRef}
            hasInventoryItem={hasInventoryItem}
            pODetailItems={purchaseOrderDetailItems ?? []}
            purchaseOrderId={purchaseOrder?.id as number}
            selectedRows={selectedRows}
            setConfigureInspectorOpen={setConfigureInspectorOpen}
            setRefreshQuery={setRefreshQuery}
            setRefreshTotal={setRefreshGridTotal}
            setTaxUpdate={setTaxSettingsUpdate}
          />
          <Box sx={contentSx}>
            {!isEmpty(purchaseOrder) ? (
              <POEditHeaderForm
                disabled={!purchaseOrderPermissions.canModify}
                hasInventoryItem={hasInventoryItem}
                onAltReportSelected={(v) => setSupplierDefaultReportAlt(v)}
                purchaseOrder={purchaseOrder}
                setHasASaveOccurred={setHasASaveOccurred}
                setPurchaseOrder={setPurchaseOrder}
                setRefreshTotal={setRefreshGridTotal}
                setResetGrid={setResetGrid}
                setTaxUpdate={setTaxSettingsUpdate}
                taxUpdate={taxSettingsUpdate}
              />
            ) : (
              <Skeleton height={600} width="100%" />
            )}

            <PurchaseOrderDetailsGrid
              configureInspector={configureInspectorOpen}
              purchaseOrderId={purchaseOrder?.id}
              ref={gridRef}
              refreshTotal={refreshGridTotal}
              resetGrid={resetGrid}
              selectedRows={selectedRows}
              setConfigureInspector={setConfigureInspectorOpen}
              setHasASaveOccurred={setHasASaveOccurred}
              setRefreshQuery={setRefreshQuery}
              setRefreshTotal={setRefreshGridTotal}
              setResetGrid={setResetGrid}
              setSelectedRows={setSelectedRows}
            />
          </Box>
        </>
      )}
    </POAddEditDetailsPanel>
  );
};

export default PurchaseOrdersDetailsPanel;
