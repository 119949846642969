/* eslint-disable no-unused-vars */
import { useReducer } from 'react';
import EditingState from '../types/EditingState';
import {
  InspectorActionTypes,
  InspectorActions,
  InspectorDispatch,
} from '../types/InspectorActionTypes';
import { InspectorState } from '../types/InspectorState';

function reducer<GenericInitialVal>(
  state: InspectorState<GenericInitialVal>,
  action: InspectorDispatch<GenericInitialVal>
) {
  switch (action.type) {
    case InspectorActionTypes.SET_HAS_A_SAVE_OCCURRED:
      return { ...state, hasASaveOccurred: action.payload };
    case InspectorActionTypes.SET_IS_CREATING_NEW_ITEM:
      return { ...state, isCreatingNewItem: action.payload };
    case InspectorActionTypes.SET_NEEDS_CLOSE_CONFIRMATION:
      return { ...state, needsCloseConfirmation: action.payload };
    case InspectorActionTypes.SET_EDITING_STATE:
      return { ...state, editingState: action.payload };
    case InspectorActionTypes.SET_CURRENT_VALUES:
      return { ...state, currentValues: action.payload };
    default:
      return state;
  }
}
export default function useInspectorState<GenericInitialVal>(
  initialState: InspectorState<GenericInitialVal>
): {
  state: InspectorState<GenericInitialVal>;
} & InspectorActions<GenericInitialVal> {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [state, dispatch] = useReducer(reducer, initialState) as unknown as any;

  const setHasASaveOccurred = (payload: boolean) =>
    dispatch({ type: InspectorActionTypes.SET_HAS_A_SAVE_OCCURRED, payload });

  const setIsCreatingNewItem = (payload: boolean) =>
    dispatch({ type: InspectorActionTypes.SET_IS_CREATING_NEW_ITEM, payload });

  const setNeedsCloseConfirmation = (payload: boolean) =>
    dispatch({
      type: InspectorActionTypes.SET_NEEDS_CLOSE_CONFIRMATION,
      payload,
    });

  const setEditingState = (payload: EditingState) =>
    dispatch({ type: InspectorActionTypes.SET_EDITING_STATE, payload });

  const setCurrentValues = (payload: GenericInitialVal) =>
    dispatch({
      type: InspectorActionTypes.SET_CURRENT_VALUES,
      payload,
    });

  return {
    state,
    setHasASaveOccurred,
    setIsCreatingNewItem,
    setNeedsCloseConfirmation,
    setEditingState,
    setCurrentValues,
  };
}
