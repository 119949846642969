import React from 'react';
import { Draggable } from 'react-smooth-dnd';
import ListItem, { ListItemProps } from './ListItem';

export type Size = 'small' | 'medium' | 'large';
// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface DraggableListItemProps extends ListItemProps {}

const DraggableListItem = (props: DraggableListItemProps) => (
  <Draggable>
    <ListItem {...props} />
  </Draggable>
);

export default DraggableListItem;
