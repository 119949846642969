import { useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import AuthContext from '../contexts/AuthContext';
import MenuBuilder from './MenuBuilder';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const useMenuBuilder = (menuObject: any) => {
  const location = useLocation();
  const { t, ready } = useTranslation();
  const authContext = useContext(AuthContext);

  const menuBuilder = useMemo(
    () => new MenuBuilder(JSON.stringify(menuObject), t, authContext, location),
    [authContext, location, menuObject, t]
  );

  return {
    menu: menuBuilder.getMenu(),
    isReady: ready,
    menuBuilder,
    errors: menuBuilder.getErrors(),
  };
};

export default useMenuBuilder;
