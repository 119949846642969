import { gql } from '../../../../../../__generated__';

// eslint-disable-next-line import/prefer-default-export
export const nonConformanceDocumentsQuery =
  gql(`query nonConformanceDocuments ($nonConformanceId: Int!) {
  nonConformanceDocuments (id: $nonConformanceId) {
    items {
      documentLocation
      id
      pathType
      fileExtension
      fileName
      fileSize
    }
  }
}`);
