/* eslint-disable import/prefer-default-export */
import { FormLayout } from '../../forms/FormLayoutDefinitions';
import FormBuilder from './FormBuilder/FormBuilder';

export const simpleLayout = <FT>(
  formBuilder: FormBuilder<FT>,
  numberOfColumns: number
): FormLayout => {
  const result: FormLayout = {
    form: {
      columns: numberOfColumns,
      fields: formBuilder.fields.map((a) => a.name),
    },
  };

  return result;
};
