export const getAllCompanyTypesQuery = `query {
  companyTypes {
    items {
     ctype
    }
  }
}`;

export const getCurrentCompanyTypesQuery = `query getAllCompanyTypesQuery ($companyId: Int) {
  companyCompanyTypes (order: [{ description: ASC}], where: { companyId: { eq: $companyId}}) {
    items {
      id
      description
    }
  }
}`;
