import { gql } from '../../../__generated__';

export default gql(`mutation updateStandardAssembly($input: UpdateStandardAssemblyInput!){
  updateStandardAssembly (input: $input){
    id
    itemCutLength
    itemFabrication
    itemId
    itemQty
    note
    orEquals
    sparePart
    standardAssemblyItemId
  }
}`);
