import {
  ClickAwayListener,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Popover,
  SxProps,
  Theme,
  useTheme,
} from '@mui/material';
import { ActionItem } from '@teto/react-component-library-v2';
import { uniqueId } from 'lodash';
import React from 'react';

interface TabOptionsPopoverProps {
  buttons: ActionItem[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data: any;
  // eslint-disable-next-line no-unused-vars, @typescript-eslint/no-explicit-any
  setRowData: (val: any) => void;
  anchorEl: SVGSVGElement | null;
  // eslint-disable-next-line no-unused-vars
  setAnchorEl: (val: SVGSVGElement | null) => void;
}

const hoverFix: SxProps<Theme> = {
  height: (theme) => theme.spacing(4),
  '&:hover svg, &:hover p': {
    color: (theme) => theme.palette.getContrastText(theme.palette.primary.main),
  },
  '& .MuiListItemIcon-root': {
    minWidth: (theme) => theme.spacing(4),
  },
};

const MoreOptionsPopover = (props: TabOptionsPopoverProps) => {
  const { buttons, data, setRowData, anchorEl, setAnchorEl } = props;
  const theme = useTheme();

  const _isBtnDisabled = (
    // eslint-disable-next-line no-unused-vars, @typescript-eslint/no-explicit-any
    disabled: boolean | ((col?: any) => boolean) | undefined
  ) => {
    if (typeof disabled === 'function') {
      return disabled(data);
    }
    return disabled;
  };
  return (
    <>
      <ClickAwayListener onClickAway={() => setAnchorEl(null)}>
        <>
          <Popover
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            onClose={() => setAnchorEl(null)}
            open={Boolean(anchorEl)}
            slotProps={{
              paper: {
                elevation: 1,
                sx: { zIndex: theme.zIndex.modal + 1 },
              },
            }}
          >
            <List>
              {buttons.map((button) => (
                <ListItemButton
                  disabled={_isBtnDisabled(button.disabled)}
                  key={uniqueId()}
                  onClick={() => {
                    button?.handleClick(data);
                    setAnchorEl(null);
                    setRowData(undefined);
                  }}
                  sx={hoverFix}
                >
                  <ListItemIcon color="primary">{button.icon}</ListItemIcon>
                  <ListItemText
                    primary={button.title}
                    primaryTypographyProps={{
                      variant: 'body2',
                    }}
                  />
                </ListItemButton>
              ))}
            </List>
          </Popover>
        </>
      </ClickAwayListener>
    </>
  );
};

export default MoreOptionsPopover;
