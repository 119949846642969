import { gql } from '../../../../__generated__';

export default gql(`
    mutation updateRFQDetail($input: UpdateRFQDetailInput) {
      updateRFQDetail(input: $input){
        custom1
        custom2
        custom3
        custom4
        custom5
        custom6
        custom7
        custom8
        externalQty
        externalUOMId
        externalUOM {
          uomtype
        }
        id
        itemCost
        itemDate
        itemId
        nonConformanceId
        projectId
        rFQId
        rFQOrderNumber
        rFQQty
        rFQSupplierDescription
        rFQSupplierItem
        rFQUOM
        specId        
      }
    }
  `);
