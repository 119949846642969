import React from 'react';
import * as Yup from 'yup';
import { ReportInspectorProps } from '../../../components/Inspectors/ReportInspector/ReportInspector';
import CompanyInfoForm from '../components/CompanyInfoForm';
import PhoneBookNameForm from '../components/PhoneBookNameForm';
import PhoneBookTypeForm from '../components/PhoneBookTypeForm';
import {
  companyId,
  companyLettersIn,
  companyTypes,
} from '../components/companyReportConstants';

interface CompanyReports {
  [key: number]: Omit<
    ReportInspectorProps<{ [key: string]: unknown }>['report'],
    'reportId' | 'defaultValues'
  >;
}

const REQUIRED_COMPANY_ID = 'Name is a required field';

const companyReportObject: CompanyReports = {
  // company Info report
  28: {
    reportForm: <CompanyInfoForm />,
    localStorageKey: 'companyInfo',
    schema: Yup.object().shape({
      [companyId]: Yup.number().required(REQUIRED_COMPANY_ID),
    }),
  },
  // Phone book by name Info report
  29: {
    reportForm: <PhoneBookNameForm />,
    localStorageKey: 'phone-name',
    schema: Yup.object().shape({
      [companyLettersIn]: Yup.string().nullable(),
    }),
  },

  // Phone book by type Info report
  30: {
    reportForm: <PhoneBookTypeForm />,
    localStorageKey: 'phone-types',
    schema: Yup.object().shape({
      [companyTypes]: Yup.string().nullable(),
    }),
  },
};

export default companyReportObject;
