import { gql } from '../../../__generated__';
import SelectDefinition from './SelectDefinition';

export default {
  query: gql(`query reportAlternateList($reportId: Int!) {
    reportAlternateList(reportId: $reportId) {
      items {
        reportDisplayName
        reportAlternateId
      }
    }
  }`),
  valueField: 'reportAlternateId',
  labelField: 'reportDisplayName',
} as SelectDefinition;
