import { TypeGroupBy } from '@inovua/reactdatagrid-enterprise/types';
import { Box, SxProps, Theme } from '@mui/material';
import React, { useEffect, useMemo, useState } from 'react';

// eslint-disable-next-line import/no-cycle
import SharedStateLink from '../SharedStateComponents/SharedStateLink';
import MobileCellWrapper from '../TetoGrid/components/MobileCellWrapper';
import FormatterProps from './Formatters/FormatterProps';
import { buildCellFormatter } from './Formatters/cellFormatterHelper';
import deepPropertyHelper from './Formatters/deepPropertyHelpers';
import { TETODataColumn } from './GridBuilder/types/TETODataColumn';
import getEditor from './InlineEditingProcessor';
import { EditedValue } from './types/EditedValue';
import EditorType from './types/EditorTypes';
import { GridResponsiveSettingsSingle } from './types/GridResponsiveSettingsSingle';

interface MobileHeaderCellComponentProps {
  columns: TETODataColumn[];
  grouping?: TypeGroupBy;
  cell: FormatterProps;
}

interface MobileValueCellComponentProps {
  columns: TETODataColumn[];
  cell: FormatterProps;
  editingEnabled: boolean;
  groupBy: TypeGroupBy;
  responsive?: GridResponsiveSettingsSingle;
  // eslint-disable-next-line no-unused-vars
  setValueUpdated?: (path: string, val: EditedValue) => void;
}

interface MobileValueCellItemProps {
  column: TETODataColumn;
  responsive?: GridResponsiveSettingsSingle;
  editingEnabled: boolean;
  initialValue?: EditedValue;
  cell: FormatterProps;
  // eslint-disable-next-line no-unused-vars
  setValueUpdated?: (path: string, val: EditedValue) => void;
}

const MOBILE_CELL_HEIGHT = 36;

const headerCellSx: SxProps<Theme> = {
  height: MOBILE_CELL_HEIGHT,
  lineHeight: `${MOBILE_CELL_HEIGHT}px`,
  textOverflow: 'ellipsis',
  overflow: 'hidden',
};

const valueCellSx: SxProps<Theme> = {
  height: MOBILE_CELL_HEIGHT,
  textOverflow: 'ellipsis',
  overflow: 'hidden',
  lineHeight: `${MOBILE_CELL_HEIGHT}px`,
  pl: 1,
  pr: 1,
};
const MobileHeaderCellComponent: React.FC<MobileHeaderCellComponentProps> = (
  props
) => {
  const { columns, grouping, cell } = props;
  return (
    <MobileCellWrapper dataTestId="mobile-header-cell" mobileRowHeight={-1}>
      {columns
        .filter((a) => !a.includeInMobile)
        // eslint-disable-next-line array-callback-return
        .map((col) => {
          if (!grouping?.includes(col.name))
            return (
              <Box key={col.name} sx={headerCellSx}>
                <SharedStateLink
                  inspectorKey={col.linkTo?.type}
                  value={
                    col.linkTo?.type
                      ? deepPropertyHelper(col.linkTo.column, cell.data)
                      : undefined
                  }
                >
                  {col.title}
                </SharedStateLink>
              </Box>
            );
        })}
    </MobileCellWrapper>
  );
};

export const MobileValueCellItem: React.FC<MobileValueCellItemProps> = (
  props
) => {
  const {
    column,
    responsive,
    editingEnabled,
    initialValue,
    cell,
    setValueUpdated,
  } = props;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [editedValue, setEditedValue] = useState<any>(initialValue);

  useEffect(() => {
    setEditedValue(initialValue);
  }, [cell.data, initialValue]);

  const editingMethods = useMemo(
    () => ({
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      onCancel: () => {},
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      onChange: (val: any) => {
        setEditedValue(val?.target?.value ?? val);
      },
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      onTabNavigation: () => {},
      onComplete: () => {
        if (setValueUpdated) setValueUpdated(column.name, editedValue);
      },
    }),
    [column.name, editedValue, setValueUpdated]
  );

  const isEditMode = editingEnabled && column.editable;

  return (
    <>
      {isEditMode &&
        getEditor(
          column.type,
          column.editorType as EditorType,
          responsive?.inputVariant ?? 'standard'
        )({ ...cell, ...editingMethods, value: editedValue }, initialValue)}
      {!isEditMode && buildCellFormatter({ ...column })(cell)}
    </>
  );
};

const MobileValueCellComponent: React.FC<MobileValueCellComponentProps> = (
  props
) => {
  const {
    columns,
    cell,
    groupBy,
    responsive,
    editingEnabled,
    setValueUpdated,
  } = props;

  return (
    <MobileCellWrapper dataTestId="mobile-value-cell" mobileRowHeight={-1}>
      {columns
        .filter(
          (a) =>
            !a.includeInMobile && (!groupBy || groupBy.indexOf(a.name) === -1)
        )
        .map((col) => (
          <Box key={col.id + col.name} sx={valueCellSx}>
            <MobileValueCellItem
              cell={{
                ...cell,
                editorProps: col.editorProps,
                cellProps: { ...cell.cellProps, name: col.name },
              }}
              column={col}
              editingEnabled={editingEnabled}
              initialValue={cell.data[col.name]}
              key={col.name}
              responsive={responsive}
              setValueUpdated={setValueUpdated}
            />
          </Box>
        ))}
    </MobileCellWrapper>
  );
};

export const MobileHeaderCell = React.memo(MobileHeaderCellComponent);
export const MobileValueCell = MobileValueCellComponent;
