import { gql } from '../../../../__generated__';

const deleteRFQDetailMutation = gql(`
  mutation deleteRFQDetail($id: Int!) {
    deleteRFQDetail(id: $id) {
        success
      }
    }
`);

export default deleteRFQDetailMutation;
