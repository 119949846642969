import { Box, SxProps, Theme, Typography } from '@mui/material';
import { ETOAccordion } from '@teto/react-component-library-v2';
import React, { useCallback, useMemo } from 'react';

interface FormGroupProps {
  title: string;
  groupWrapperSx?: SxProps<Theme>;
  columnCount: number;
  children: NonNullable<React.ReactNode> & React.ReactNode;
  collapsible: boolean;
}

const FormGroup: React.FC<FormGroupProps> = (props) => {
  const { children, collapsible, columnCount, groupWrapperSx, title } = props;

  const _columnCalculator = useCallback(
    (theme: Theme, cc: number, gap: number) => {
      const innerStatement = `calc((100% / ${cc}) - ${theme.spacing(gap)})`;

      return `repeat(${cc}, ${innerStatement})`;
    },
    []
  );

  const _groupColumns = useMemo(() => {
    if (Array.isArray(children) && children.length <= columnCount) {
      return children.length;
    }
    return columnCount;
  }, [children, columnCount]);

  return (
    <Box
      sx={[
        ...(Array.isArray(groupWrapperSx) ? groupWrapperSx : [groupWrapperSx]),
      ]}
    >
      {!collapsible && (
        <Box
          sx={[
            (theme) => ({
              paddingRight: 0,
              display: 'grid',
              gap: theme.spacing(1),
              gridTemplateColumns: _columnCalculator(theme, columnCount, 1),
            }),
            ...(Array.isArray(groupWrapperSx)
              ? groupWrapperSx
              : [groupWrapperSx]),
          ]}
        >
          <Typography
            sx={{
              gridColumn: `1 / span ${columnCount}`,
            }}
          >
            {title}
          </Typography>
          {children}
        </Box>
      )}
      {collapsible && (
        <ETOAccordion
          accordionDetails={{
            sx: [
              (theme) => ({
                marginBottom: 1,
                paddingRight: 0,
                display: 'grid',
                gap: theme.spacing(1),
                gridTemplateColumns: _columnCalculator(theme, _groupColumns, 1),
              }),
              ...(Array.isArray(groupWrapperSx)
                ? groupWrapperSx
                : [groupWrapperSx]),
            ],
          }}
          defaultExpanded
          defaultSummary={title}
          disableBackground
        >
          {children}
        </ETOAccordion>
      )}
    </Box>
  );
};

export default React.memo(FormGroup);
