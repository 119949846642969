import { TFunction } from 'i18next';
import { Company } from '../../../__generated__/graphql';
import { buildCellFormatter } from '../../TETOGridGraphQL/Formatters/cellFormatterHelper';
import TableColumnDefinition from '../../TetoGrid/types/TableColumnDefinition';

const formatSupplierGridColumns = (t: TFunction) => {
  const columns: TableColumnDefinition[] = [
    {
      name: 'name',
      header: t('entities:Company.Name'),
      id: 'name',
      align: 'start' as const,
      sortable: true,
      type: 'string',
      title: t('entities:Company.Name'),
      order: 1,
      fixed: 'none' as const,
      filterType: 'string',
      defaultWidth: 200,
      defaultFlex: 1,
    },
    {
      name: 'phone',
      header: t('entities:Company.Phone'),
      align: 'start' as const,
      id: 'phone',
      sortable: true,
      type: 'string',
      title: t('entities:Company.Phone'),
      order: 2,
      fixed: 'none' as const,
      filterType: 'string',
      defaultWidth: 100,
      defaultFlex: 1,
    },
    {
      name: 'city',
      header: t('entities:Company.City'),
      id: 'city',
      align: 'start' as const,
      sortable: true,
      type: 'string',
      title: t('entities:Company.City'),
      order: 3,
      fixed: 'none' as const,
      filterType: 'string',
      defaultWidth: 100,
      defaultFlex: 1,
    },
    {
      name: 'state',
      header: t('entities:Company.State'),
      defaultWidth: 40,
      sortable: true,
      type: 'string',
      id: 'state',
      align: 'start' as const,
      title: t('entities:Company.State'),
      order: 4,
      fixed: 'none' as const,
      filterType: 'string',
    },
    {
      name: 'zip',
      header: t('entities:Company.Zip'),
      sortable: true,
      id: 'zip',
      align: 'start' as const,
      type: 'string',
      title: 'Title for  Zip',
      order: 5,
      fixed: 'none' as const,
      filterType: 'string',
      defaultWidth: 50,
    },
    {
      name: 'supplier.supQaapproved',
      header: t('entities:Part.ApprovedSupplier'),
      type: 'boolean',
      sortable: true,
      id: 'supplier.supQaapproved',
      align: 'center' as const,
      title: t('entities:Company.Zip'),
      order: 6,
      fixed: 'none' as const,
      filterType: 'checkbox',
      defaultWidth: 50,
    },
  ];

  return columns.map((c) => ({
    ...c,
    render: c.render ?? buildCellFormatter(c),
    showColumnMenuTool: true,
  }));
};

const formSupplierDisplayValue = (val?: number, data?: Company[]) => {
  if (val && data && data?.length > 0) {
    const display = data?.find((i) => i.supplier?.id === val);
    if (!display) return '';
    const city = display?.city ? ` [${display?.city}]` : '';
    const approved =
      // eslint-disable-next-line no-nested-ternary
      display?.supplier?.supQaapproved === false
        ? ' (Unapproved)'
        : display?.supplier?.supQaapproved === true
        ? ' (Approved)'
        : '';
    return `${display?.name}${city}${approved}`;
  }

  return '';
};

export { formSupplierDisplayValue, formatSupplierGridColumns };
