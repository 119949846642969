/* eslint-disable no-bitwise */
import { MessageContext } from '@teto/react-component-library-v2';
import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import {
  Licenses,
  TestAuthResponse,
  containUserLicense,
  // containUserLicense,
  getIsEmployeeATimesheetApprover,
  getMyApproverDetails,
  hasAllPermissions,
  hasAnyPermission,
  hasLicense,
  hasPermission,
  testAuth,
  getGraphQLClient,
} from 'teto-client-api';
import type { TypedDocumentNode } from '@graphql-typed-document-node/core';
import { License } from '../__generated__/graphql';
import { gql } from '../__generated__';
import getErrors from '../api/graphQL/getErrors';

export type PersistenceTypes =
  | 'db'
  | 'localStorage'
  | 'sessionStorage'
  | 'none';

export interface Database {
  name: string;
  server: string;
}
export interface AuthenticatedUser {
  firstName: string;
  lastName: string;
  email: string;
  id: number;
  permissions: string[];
  otherPrivileges: string[];
  license?: number | undefined;
  effectiveLicense?: number | undefined;
  isAdmin: boolean;
  isApprover: boolean;
  mFA: 'ENABLED' | 'DISABLED';
  needsMFASetup: boolean;
}

export interface AuthContextState {
  licenseExpiration?: License;
  authenticated: boolean;
  user?: AuthenticatedUser;
  authenticationType?: string;
  isAdmin?: boolean;
  isLoaded: boolean;
  appVersion: string;
  databaseName: string;
  databaseServer: string;
  // eslint-disable-next-line no-unused-vars
  hasLicense: (license: Licenses) => boolean;
  // eslint-disable-next-line no-unused-vars
  hasEnterpriseLicense: () => boolean;
  // eslint-disable-next-line no-unused-vars
  hasProfessionalLicense: () => boolean;
  // eslint-disable-next-line no-unused-vars
  hasReadOnlyLicense: () => boolean;
  hasAnyLicense: () => boolean;
  changePassword: (
    // eslint-disable-next-line no-unused-vars
    oldPassword: string,
    // eslint-disable-next-line no-unused-vars
    newPassword: string,
    // eslint-disable-next-line no-unused-vars
    newPasswordConfirmation: string
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ) => void;
  // eslint-disable-next-line no-unused-vars
  hasPermission: (permission: string) => boolean;
  // eslint-disable-next-line no-unused-vars
  hasAnyPermission: (permissions: string[]) => boolean;
  // eslint-disable-next-line no-unused-vars
  hasAllPermissions: (permissions: string[]) => boolean;
  // eslint-disable-next-line no-unused-vars
  // eslint-disable-next-line no-unused-vars
  login: (userName: string, password: string) => Promise<void>;
  logout: () => Promise<void>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  testAuthentication: () => Promise<TestAuthResponse>;
  getUserLicensesList: () => string[];
  // eslint-disable-next-line no-unused-vars
  canApprove: (employeeId: number) => boolean;
  sessionTimeout: boolean;
  disableClockIn: boolean;
}

const PERMISSION_TEST_TIMEOUT = 2000;

const AuthContext = React.createContext<AuthContextState>(null as never);

const AuthContextProvider: React.FC<{
  children: React.ReactNode;
}> = (props) => {
  const [licenseExpiration, setLicenceExpiration] = useState<
    License | undefined
  >(undefined);
  const [hasAuthenticated, setHasAuthenticated] = useState(false);
  const [user, setUser] = useState<AuthenticatedUser | undefined>(undefined);
  const [isLoaded, setIsLoaded] = useState(false);
  const [disableClockIn, setDisableClockIn] = useState(false);
  const [appVersion, setAppVersion] = useState('');
  const [approvableIds, setApprovableIds] = useState<number[]>([]);
  const [sessionTimeout, setSessionTimeout] = useState<boolean>(false);
  const [authenticationType, setAuthenticationType] = useState<
    string | undefined
  >();
  const [database, setDatabase] = useState<Database>({
    name: '',
    server: '',
  });

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const testAuthTimer = useRef(undefined as any);

  const messageContext = useContext(MessageContext);

  useEffect(() => {
    if (user?.id) {
      getMyApproverDetails().then((d) => {
        setApprovableIds(d.employees);
      });
    } else {
      setApprovableIds([]);
    }
  }, [user?.id]);

  const _isApprover = async (id: number | undefined): Promise<boolean> => {
    if (!id) {
      // eslint-disable-next-line no-console
      console.error('User id not defined');
      return false;
    }
    const result = await getIsEmployeeATimesheetApprover();

    return result;
  };

  const isAuthenticatedQuery = gql(`query authenticatedUser{
    authenticatedUser {
      administrator {
        license {
          hasRecentlyExpired
          hasSoonExpiring
          expiringSoonMessage
          recentlyExpiredMessage
        }
      }
      appVersion
      authenticationType
      databaseName
      databaseServer
      deviceType
      disableClockIn
      isAuthenticated
      user {
        id
        firstName
        lastName
        fullName
        email
        permissions
        department
        userId
        subDepartment
        license
        effectiveLicense
        otherPrivileges
        isAdmin
        needsMFASetup
        mFA
      }
    }
  }
  `);

  const _contextTestAuthentication = useCallback(
    (): Promise<void> =>
      getGraphQLClient()
        .performQuery(isAuthenticatedQuery as TypedDocumentNode, {})
        .then(async (e) => {
          if (e.hasError()) {
            e.showAllSystemErrors(messageContext.setError);
            if (e.hasSystemErrors()) {
              setIsLoaded(true);
              const errors = getErrors(e);
              messageContext.setError(errors);
              if (errors.includes('Session expired')) {
                setSessionTimeout(true);
              }
            }
            return;
          }
          const { authenticatedUser } = e.data;
          setLicenceExpiration(authenticatedUser?.administrator?.license);
          setDisableClockIn(authenticatedUser?.disableClockIn as boolean);
          setAppVersion(authenticatedUser?.appVersion as string);
          setAuthenticationType(
            authenticatedUser?.authenticationType as string | undefined
          );
          setDatabase({
            name: authenticatedUser?.databaseName as string,
            server: authenticatedUser?.databaseServer as string,
          });
          if (
            authenticatedUser?.isAuthenticated &&
            authenticatedUser?.user &&
            authenticatedUser?.deviceType !== 'Kiosk'
          ) {
            setHasAuthenticated(true);
            const approverResult = await _isApprover(authenticatedUser.user.id);
            const updatedUser = {
              ...authenticatedUser.user,
              isApprover: approverResult,
            };
            setUser(updatedUser as AuthenticatedUser | undefined);
            setIsLoaded(true);
          } else {
            setHasAuthenticated(false);
            setUser(undefined);
            setIsLoaded(true);
          }
        }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isAuthenticatedQuery]
  );

  useEffect(() => {
    if (testAuthTimer.current) {
      clearTimeout(testAuthTimer.current);
    }

    if (isLoaded && hasAuthenticated) {
      testAuthTimer.current = setTimeout(
        _contextTestAuthentication,
        PERMISSION_TEST_TIMEOUT
      );
    }
    return () => {
      if (testAuthTimer.current) {
        clearTimeout(testAuthTimer.current);
      }
    };
  }, [_contextTestAuthentication, hasAuthenticated, isLoaded]);

  useEffect(() => {
    _contextTestAuthentication();
  }, [_contextTestAuthentication]);

  const loginMutation = gql(`mutation login($input: LoginRequestInput){
    login( input: $input){
      isSuccess
      failReason
      disableClockIn
      user {
        email
        department
        firstName
        lastName
        fullName
        id
        isAdmin
        license
        mFA
        needsMFASetup
        otherPrivileges
        permissions
        subDepartment
        userId
      }
    }
  }`);

  const _login = (userName: string, password: string) =>
    getGraphQLClient()
      .performMutation(loginMutation, {
        input: {
          userName,
          password,
        },
      })
      .then(async (e) => {
        if (e.hasError()) {
          e.showAllSystemErrors(messageContext.setError);
          if (e.hasSystemErrors()) {
            setHasAuthenticated(false);
            setUser(undefined);
            throw new Error(`${e.systemErrors[0]}`);
          }
          return;
        }
        setHasAuthenticated(true);
        setSessionTimeout(false);
        setDisableClockIn(e.data.login?.disableClockIn as boolean);
        const approverResult = await _isApprover(e.data.login?.user?.id);
        const updatedUser = {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          ...(e.data.login?.user as any),
          isApprover: approverResult,
        };
        setUser(updatedUser as AuthenticatedUser);
      });

  const _clearAuthDetails = useCallback(() => {
    setHasAuthenticated(false);
    setSessionTimeout(false);
    setUser(undefined);
    if (testAuthTimer.current) {
      clearTimeout(testAuthTimer.current);
    }
  }, []);

  const logoutMutation = gql(`mutation logout {
    logout{
      success
    }
  }`);

  const _logout = () =>
    getGraphQLClient()
      .performMutation(logoutMutation, {})
      .then((d) => {
        if (d.hasError()) {
          d.showAllSystemErrors(messageContext.setError);
          if (d.hasSystemErrors()) {
            _clearAuthDetails();
          }
          return;
        }
        _clearAuthDetails();
        if (authenticationType === 'SAML2') {
          window.location.href = "/?SamlError='User has logged out'";
        }
      });

  const changePasswordMutation =
    gql(`mutation changeMyPassword($oldPassword: String!, $newPassword: String!, $newPasswordConfirmation: String!){
    changeMyPassword( input: { oldPassword: $oldPassword, newPassword: $newPassword, newPasswordConfirmation: $newPasswordConfirmation}){
      success
    }
  }`);

  const _changePassword = (
    oldPassword: string,
    newPassword: string,
    newPasswordConfirmation: string
  ) => {
    getGraphQLClient()
      .performMutation(changePasswordMutation, {
        oldPassword,
        newPassword,
        newPasswordConfirmation,
      })
      .then((d) => {
        if (d.hasError()) {
          d.showAllSystemErrors(messageContext.setError);
        }
      });
  };

  const _hasPermission = useCallback(
    (permission: string) =>
      user ? user.id === -1 || hasPermission(user, permission) : false,
    [user]
  );

  const _hasAnyPermission = (permissions: string[]) =>
    user ? user.id === -1 || hasAnyPermission(user, permissions) : false;

  const _hasAllPermissions = (permissions: string[]) =>
    user ? user.id === -1 || hasAllPermissions(user, permissions) : false;

  const _hasLicense = (license: Licenses) =>
    user && license && user.license ? hasLicense(user, license) : false;

  const _hasEnterpriseLicense = useCallback(
    () =>
      user && user.license
        ? containUserLicense(user, [
            Licenses.TimeEnterprise,
            Licenses.ReadOnlyEnterprise,
            Licenses.TotalETOEnterprise,
          ])
        : false,
    [user]
  );

  const _hasProfessionalLicense = () =>
    user && user.license
      ? containUserLicense(user, [
          Licenses.TotalETOProfessional,
          Licenses.ReadOnlyProfessional,
          Licenses.TimeProfessional,
        ])
      : false;
  const findLicenseMatch = (
    // eslint-disable-next-line no-shadow
    user: AuthenticatedUser,
    licenses: Licenses[]
  ) => {
    let index = 0;
    let result = false;
    while (index < licenses.length) {
      if (licenses[index] === user.license) {
        result = true;
        break;
      }
      index += 1;
    }
    return result;
  };
  const _hasReadOnlyLicense = () =>
    user && user.license
      ? findLicenseMatch(user, [
          Licenses.ReadOnlyProfessional,
          Licenses.ReadOnlyEnterprise,
        ])
      : false;

  const _getUserLicensesList = () => {
    const results: (string | Licenses)[] = [];

    Object.entries(Licenses).forEach((e) => {
      const value = parseInt(e[0], 10);
      if (
        Number.isInteger(value) &&
        e[1] !== 'None' &&
        user?.license &&
        // eslint-disable-next-line no-bitwise
        (user?.license & value) === value
      ) {
        results.push(e[1]);
      }
    });

    if (results.length === 0) {
      results.push('None');
    }
    return results as string[];
  };

  const _canApprove = useCallback(
    (employeeId: number) =>
      (user?.isApprover && approvableIds.indexOf(employeeId) >= 0) ?? false,
    [approvableIds, user?.isApprover]
  );

  const _hasAnyLicense = () => {
    if (!user || !user.license || !user.effectiveLicense) {
      return false;
    }
    return user.license > 0 || user.effectiveLicense > 0;
  };

  const { children } = props;

  return (
    <AuthContext.Provider
      // eslint-disable-next-line react/jsx-no-constructed-context-values
      value={{
        appVersion,
        authenticated: hasAuthenticated,
        authenticationType,
        canApprove: _canApprove,
        changePassword: _changePassword,
        databaseName: database.name,
        databaseServer: database.server,
        disableClockIn,
        getUserLicensesList: _getUserLicensesList,
        hasAllPermissions: _hasAllPermissions,
        hasAnyLicense: _hasAnyLicense,
        hasAnyPermission: _hasAnyPermission,
        hasEnterpriseLicense: _hasEnterpriseLicense,
        hasLicense: _hasLicense,
        hasPermission: _hasPermission,
        hasProfessionalLicense: _hasProfessionalLicense,
        hasReadOnlyLicense: _hasReadOnlyLicense,
        isAdmin: Boolean(user?.isAdmin),
        isLoaded,
        licenseExpiration,
        login: _login,
        logout: _logout,
        sessionTimeout,
        testAuthentication: testAuth,
        user,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const AuthProvider = AuthContextProvider;
export const AuthConsumer = AuthContext.Consumer;
export default AuthContext;
