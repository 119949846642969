import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded';
import ManageHistoryIcon from '@mui/icons-material/ManageHistory';
import SettingsRoundedIcon from '@mui/icons-material/SettingsRounded';
import { MessageContext } from '@teto/react-component-library-v2';
import { useSetAtom } from 'jotai';
import React, {
  Dispatch,
  SetStateAction,
  forwardRef,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
} from 'react';
import { useTranslation } from 'react-i18next';
import { Permission, getGraphQLClient } from 'teto-client-api';
import {
  NonConformance,
  RfqDetail,
  UomDescription,
  UpdateRfqDetailInput,
} from '../../../../../../__generated__/graphql';
import buildMutation from '../../../../../../api/graphQL/buildMutation';
import AuthContext from '../../../../../../contexts/AuthContext';
import SettingsContext from '../../../../../../contexts/SettingsContext';
import useIsMobile from '../../../../../../hooks/useIsMobile';
import rFQDetails from '../../../../../../views/RFQDetailsView.yaml';
import handleNumberInputChange from '../../../../../Inspectors/CompanyDetailInspector/helpers/companyHelpers';
import { ItemMasterSharedState } from '../../../../../SharedStateComponents/StateContainers/ItemMasterState';
import { PartHistorySharedState } from '../../../../../SharedStateComponents/StateContainers/PartHistoryState';
import {
  getNonConformancesBySpecAndProject,
  getUOMDescriptions,
} from '../../../../../TETOForms/selects/selects';
import ActionButtonGridBuilderExtension from '../../../../../TETOGridGraphQL/GridBuilder/ActionButtonGridBuilderExtension';
import { defaultGridResponsiveSettings } from '../../../../../TETOGridGraphQL/TETOGridGraphQL';
import MainTetoGridGraphQL from '../../../../../TETOGridGraphQL/TETOMainGridGraphQL';
import useDynamicGridHeight from '../../../../../TETOGridGraphQL/hooks/useDynamicGridHeight';
import useGrid from '../../../../../TETOGridGraphQL/hooks/useGrid';
import { useGridBuilderFromView } from '../../../../../TETOGridGraphQL/hooks/useGridBuilder';
import { CellChangingArgs } from '../../../../../TETOGridGraphQL/types/CellChangingArgs';
import TETOGridRefType from '../../../../../TETOGridGraphQL/types/TETOGridRefType';
import { TetoGridGraphqlProps } from '../../../../../TETOGridGraphQL/types/TetoGridGraphqlProps';
import TetoContainer from '../../../../../TetoGrid/TetoContainer';
import deleteRFQDetailMutation from '../../../queries/deleteRFQDetailMutation';
import updateRFQDetailMutation from '../../../queries/updateRFQDetailMutation';
import rFQInlineEditSchema from './rFQInlineEditSchema';

export interface RFQDetailGridProps extends Partial<TetoGridGraphqlProps> {
  refreshGrid: boolean;
  rFQId?: number;
  setRefreshQuery: Dispatch<SetStateAction<boolean>>;
  setRefreshGrid: Dispatch<SetStateAction<boolean>>;
  // eslint-disable-next-line no-unused-vars
  setHasASaveOccurred: (val: boolean) => void;
  setDataLoading: Dispatch<SetStateAction<boolean>>;
  setDataLength: Dispatch<SetStateAction<number>>;
}

const ROOT_QUERY_PATH = 'rFQDetails';
const PERSISTANCE_NAME = 'rfq-details-grid';
const DECIMAL_FIELDS = [
  'externalQty',
  'itemCost',
  'rFQQty',
  'custom3',
  'custom4',
];
const INTEGER_FIELDS = ['externalUOMId', 'nonConformanceId', 'rFQOrderNumber'];

const EDITABLE_COLUMNS = [
  'custom1',
  'custom2',
  'custom5',
  'custom6',
  'custom7',
  'custom8',
  'itemDate',
  'rFQSupplierDescription',
  'rFQSupplierItem',
  'nonConformance.displayName',
  'externalUOM.uomtype',
  ...DECIMAL_FIELDS,
  ...INTEGER_FIELDS,
];
const ALWAYS_PROJECT_COLUMNS = [
  'destInventoryLoc.id',
  'destInventoryLocId',
  'id',
  'item.itemCompanyId',
  'item.obsolete',
  'itemId',
  'item.uOM.itemUom',
  'rFQId',
  'rFQUOM',
  'projectId',
  'specId',
  'nonConformanceId',
  'externalUOM.uomtype',
  'project.displayName',
  'spec.displayName',
  ...EDITABLE_COLUMNS,
];
const gridResponsiveSettings = {
  ...defaultGridResponsiveSettings,
};
const RFQDetailGrid = (
  _props: RFQDetailGridProps,
  ref: React.Ref<TETOGridRefType> | undefined | null
) => {
  const {
    rFQId,
    refreshGrid,
    setRefreshGrid,
    setHasASaveOccurred,
    setDataLoading,
    setDataLength,
    selectedRows,
    setSelectedRows,
  } = _props;
  const { ready, t } = useTranslation();

  const messageContext = useContext(MessageContext);
  const authContext = useContext(AuthContext);
  const {
    settings: { baseCurrencyName },
  } = useContext(SettingsContext);

  const isMobile = useIsMobile();
  const isMobileRef = useRef(false);

  const setItemMaster = useSetAtom(ItemMasterSharedState);
  const setPartHistory = useSetAtom(PartHistorySharedState);

  const { canEdit, canDelete, canViewPartHistory, canViewPartDetail } = useMemo(
    () => ({
      canEdit: authContext.hasPermission(
        Permission.Modify_Procurement_RFQs_RFQHeader_RFQDetails
      ),
      canDelete: authContext.hasPermission(
        Permission.Delete_Procurement_RFQs_RFQHeader_RFQDetails
      ),
      canViewPartDetail: authContext.hasPermission(
        Permission.View_Engineering_ItemMaster
      ),
      canViewPartHistory: authContext.hasPermission(
        Permission.View_General_PartHistory
      ),
    }),
    [authContext]
  );

  const _handleDelete = useCallback(
    (data: RfqDetail) =>
      getGraphQLClient()
        .performMutation(deleteRFQDetailMutation, {
          id: data.id,
        })
        .then((d) => {
          if (d.hasError()) {
            if (d.hasSystemErrors())
              d.showAllSystemErrors((msg) => messageContext?.setError(msg));
            return;
          }
          if (d?.hasData() && d?.data.deleteRFQDetail?.success) {
            setRefreshGrid(true);
            messageContext.setSuccess(
              t('generic.deletedSuccess', {
                record: t('entities:RFQDetail.RFQDetail'),
              })
            );
          }
        }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [setRefreshGrid, t]
  );

  const { gridBuilder, builderReady, error, hasError } = useGridBuilderFromView(
    rFQDetails,
    ROOT_QUERY_PATH,
    (gb) =>
      gb
        .extension(
          new ActionButtonGridBuilderExtension([
            {
              color: canDelete ? 'error' : 'grey',
              componentName: t('generic.removePart'),
              confirm: {
                type: 'okCancel',
                title: t('dialogs.deleteRecord.title'),
                content: t('dialogs.deleteRecord.content'),
              },
              disabled: !canDelete,
              icon: <DeleteRoundedIcon />,
              onClick: ({ data }) => _handleDelete(data),
              title: t('generic.removePart'),
            },
            {
              color: canViewPartHistory ? 'primary' : 'grey',
              componentName: t('pages.partsHistory.title'),
              disabled: !canViewPartHistory,
              icon: <ManageHistoryIcon />,
              onClick: ({ data }) => {
                setPartHistory({
                  open: true,
                  part: {
                    label: data?.item.itemCompanyId as string,
                    id: data?.itemId as number,
                  },
                  tab: 4,
                });
              },
              title: t('pages.partsHistory.title'),
            },
            {
              color: canViewPartDetail ? 'primary' : 'grey',
              componentName: t('generic.itemDetails'),
              disabled: !canViewPartDetail,
              icon: <SettingsRoundedIcon />,
              onClick: ({ data }) => {
                setItemMaster({
                  open: true,
                  initialValues: {
                    id: data.itemId as number,
                    itemCompanyId: data.item?.itemCompanyId,
                  },
                  hasSavedOccurred: false,
                });
              },
              title: t('generic.itemDetails'),
            },
          ]),
          t
        )
        .updateDefinition('rFQOrderNumber', {
          editable: canEdit,
          order: 0,
        })
        .updateDefinition('rFQQty', {
          editable: canEdit,
          editorProps: {
            supportsDecimals: true,
          },
        })
        .updateDefinition('externalQty', {
          editable: canEdit,
          editorProps: {
            supportsDecimals: true,
          },
        })
        .updateDefinition('nonConformance.displayName', {
          brandingName: 'nonConformanceId',
          editable: canEdit,
          editorType: 'select',
          resizable: true,
          editorProps: {
            queryString: getNonConformancesBySpecAndProject.query,
            itemNameSelector: (i: NonConformance) =>
              i[
                getNonConformancesBySpecAndProject.labelField as keyof NonConformance
              ],
            itemValueSelector: (i: NonConformance) =>
              i[
                getNonConformancesBySpecAndProject.valueField as keyof NonConformance
              ],
            queryResultPath: 'nonConformances',
            variables: (d: RfqDetail) => ({
              projectId: d?.projectId,
              specId: d?.specId,
            }),
          },
        })
        .updateDefinition('rFQSupplierItem', {
          editable: canEdit,
          resizable: true,
          defaultFlex: 1,
        })
        .updateDefinition('rFQSupplierDescription', {
          editable: canEdit,
          resizable: true,
          defaultFlex: 1,
        })
        .updateDefinition('externalUOM.uomtype', {
          brandingName: 'externalUOMId',
          editable: canEdit,
          editorType: 'select',
          editorProps: {
            queryString: getUOMDescriptions.query,
            itemNameSelector: (i: UomDescription) =>
              i[getUOMDescriptions.labelField as keyof UomDescription],
            itemValueSelector: (i: UomDescription) =>
              i[getUOMDescriptions.valueField as keyof UomDescription],
            queryResultPath: 'uOMDescriptions',
          },
        })
        .updateDefinition('itemCost', {
          editable: canEdit,
          editorProps: {
            supportsDecimals: true,
          },
        })
        .updateDefinition('item.itemLastCost', {
          renderHeader: (d) => `${d.header} (${baseCurrencyName})`,
        })
        .updateDefinition('item.itemListCost', {
          renderHeader: (d) => `${d.header} (${baseCurrencyName})`,
        })
        .updateDefinition('itemDate', { editable: canEdit })
        .updateDefinition('rFQUom', { editable: canEdit })
        .updateDefinition('custom1', { editable: canEdit })
        .updateDefinition('custom2', { editable: canEdit })
        .updateDefinition('custom3', { editable: canEdit })
        .updateDefinition('custom4', { editable: canEdit })
        .updateDefinition('custom5', { editable: canEdit })
        .updateDefinition('custom6', { editable: canEdit })
        .updateDefinition('custom7', {
          editable: canEdit,
          editorProps: { allowUnset: true },
        })
        .updateDefinition('custom8', { editable: canEdit })
  );

  const mandatoryFilters = useMemo(() => {
    const filters = [
      {
        name: 'rFQId',
        operator: 'equal',
        value: rFQId,
        type: 'number' as const,
      },
    ];
    return filters;
  }, [rFQId]);

  const { gridProps } = useGrid(
    PERSISTANCE_NAME,
    `${ROOT_QUERY_PATH}.items`,
    (e) => messageContext.setError(e.message ?? e),
    t,
    ALWAYS_PROJECT_COLUMNS,
    gridBuilder,
    {
      filterAndSortMode: 'serverSide',
      mandatoryFilter: mandatoryFilters,
    }
  );

  const gridHeight = useDynamicGridHeight(
    gridProps?.dataSource?.length,
    gridResponsiveSettings?.breakpoints?.md?.rowHeight
  );

  const _handleCellChanging = useCallback(
    async (change: CellChangingArgs) => {
      const {
        column: { name },
        editValue: { originalValue },
        newData,
        changeContext,
      } = change;

      const handleError = (e: {
        validationErrors: { input: Record<string, string> };
        hasSystemErrors: () => boolean;
        showAllSystemErrors: () => void;
      }) => {
        const visibleColumnsList = gridProps?.visibleColumns.map((i) => i.name);

        if (e.validationErrors) {
          const { input: validationErrors } = e.validationErrors;
          const visibleValidations: { [key: string]: string } = {};
          let hiddenValidationMessage = '';

          Object.entries(validationErrors).forEach(([key, message]) => {
            if (visibleColumnsList.includes(key)) {
              visibleValidations[key] = message;
            } else {
              hiddenValidationMessage += `${key}: ${message}\n`;
            }
          });

          if (hiddenValidationMessage.length > 0) {
            messageContext.setError(hiddenValidationMessage);
          }
          if (Object.keys(visibleValidations).length > 0) {
            changeContext.setValidationErrors(visibleValidations);
          }
        }

        changeContext.cancelChange();
        changeContext.setColumnValue(name as string, originalValue);
      };

      let tempData = { ...newData };
      DECIMAL_FIELDS.forEach((i) => {
        tempData[i] = handleNumberInputChange({
          value: tempData[i],
          type: 'decimal',
        });
      });

      INTEGER_FIELDS.forEach((i) => {
        if (tempData[i]) {
          tempData = {
            ...tempData,
            [i]: handleNumberInputChange({
              value: tempData[i],
              type: 'int',
            }),
          };
        }
      });

      const input: UpdateRfqDetailInput = {
        custom1: tempData.custom1,
        custom2: tempData.custom2,
        custom3: tempData.custom3,
        custom4: tempData.custom4,
        custom5: tempData.custom5,
        custom6: tempData.custom6,
        custom7: tempData.custom7,
        custom8: tempData.custom8,
        externalQty: tempData.externalQty,
        externalUOMId: tempData.externalUOMId,
        id: tempData.id,
        itemCost: tempData?.itemCost,
        itemDate: tempData?.itemDate,
        itemQty: tempData?.rFQQty,
        nonConformanceId: tempData.nonConformanceId,
        orderNumber:
          tempData?.rFQOrderNumber !== ''
            ? tempData?.rFQOrderNumber ?? null
            : null,
        supplierDescription: tempData.rFQSupplierDescription,
        supplierItem: tempData.rFQSupplierItem,
        uOM: tempData?.rFQUOM,
      };

      buildMutation({
        queryString: updateRFQDetailMutation,
        variables: {
          input,
        },
        callback: () => {
          gridProps.refreshDataSource();
        },
        messageContext,
        errorCallback: (e) => handleError(e),
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [gridProps, isMobile, setHasASaveOccurred]
  );

  useEffect(() => {
    if (refreshGrid) {
      gridProps?.refreshDataSource();
      setRefreshGrid(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshGrid, setRefreshGrid, setDataLoading]);

  useEffect(() => {
    if (isMobile) {
      isMobileRef.current = true;
    }

    return () => {
      isMobileRef.current = false;
    };
  }, [isMobile]);

  useEffect(() => {
    if (gridProps?.dataSource.length > 0) {
      setDataLoading(false);
    }
    setDataLength(gridProps?.dataSource.length);
  }, [gridProps?.dataSource.length, setDataLength, setDataLoading]);

  const _handleRowClassName = useCallback(
    ({ data }: { data: Record<string, unknown> }) => {
      if (!data) return '';
      if ((data as RfqDetail).item?.obsolete) {
        return 'row-strike-through';
      }
      return '';
    },
    []
  );

  return (
    <TetoContainer customSx={{ padding: 0 }}>
      <MainTetoGridGraphQL
        alignRefreshIconToRight
        builderReady={builderReady}
        checkboxColumn={!isMobile}
        configureInspector={_props.configureInspector}
        customGridWrapSx={{
          minHeight: gridHeight,
        }}
        editMode={{
          editMode: 'byRow',
          validationSchema: rFQInlineEditSchema,
          onCellChanging: (c: CellChangingArgs) => {
            if (EDITABLE_COLUMNS.indexOf(c.column.name as string) >= 0) {
              _handleCellChanging(c);
            }
          },
        }}
        error={error}
        externalQueryProps={[]}
        gridProps={gridProps}
        hasError={hasError}
        header={{
          hidden: true,
        }}
        ready={ready}
        ref={ref}
        rowClassName={_handleRowClassName}
        selectedRows={!isMobile ? selectedRows : undefined}
        setConfigureInspector={_props.setConfigureInspector}
        setSelectedRows={
          !isMobile
            ? // eslint-disable-next-line @typescript-eslint/no-explicit-any
              (d: any) => {
                if (setSelectedRows) setSelectedRows(d);
              }
            : undefined
        }
        showEmptyRows
        showGroupSummaryRow={false}
        t={t}
        tableIdentifier={PERSISTANCE_NAME}
      />
    </TetoContainer>
  );
};

export default forwardRef(RFQDetailGrid);
