export const getSupplierDefaultReportQuery = `query getDefaultReport{
  employeeReport(id: 112) {
    reportID
    reportName
    reportDisplayName
    reportCategoryID
    isAlternate
    alternateId
    reportCenter
    reportLayoutID
    enabled
    favorite
    modified
  }
}`;

export const getSupplierAlternateReportQuery = `query getReportAlternates {
  reportAlternates(where: {reportId: {eq: 112}}) {
    items {
      id
      reportId
      reportDisplayName
      reportDescription
    }
  }
}`;
