import DeleteIcon from '@mui/icons-material/Delete';
import { IconButton, List, SxProps, Theme, Typography } from '@mui/material';
import { t } from 'i18next';
import React, { useCallback, useMemo } from 'react';
import { Container } from 'react-smooth-dnd';
import DragItem from '../../../ListItems/DragItem';
import DraggableListItem from '../../../ListItems/DraggableListItem';
import ListItem from '../../../ListItems/ListItem';
import { TETODataColumn } from '../../GridBuilder/types/TETODataColumn';
import IColumnEditorNodeClass from '../SelectColumnEditor/IColumnEditorNodeClass';
import SelectColumnEditor from '../SelectColumnEditor/SelectColumnEditor';

const assignedListSx = (theme: Theme) => ({
  flexGrow: 1,
  flexShrink: 1,
  overflowY: 'auto',
  paddingRight: 0.5,
  fontSize: '0.9rem',
  '& .smooth-dnd-draggable-wrapper': {
    borderRadius: `${theme.shape.borderRadius}px`,
  },
  '& .smooth-dnd-draggable-wrapper:nth-of-type(odd)': {
    backgroundColor: theme.palette.background.default,
  },
});

const altColorSx: SxProps<Theme> = {
  backgroundColor: (theme) => theme.palette.background.default,
  color: (theme) =>
    theme.palette.getContrastText(theme.palette.background.default),
};

interface VisibleColumnsPanelProps {
  columns: TETODataColumn[];
  // eslint-disable-next-line no-unused-vars
  showColumn: (columnName: string) => void;
  // eslint-disable-next-line no-unused-vars
  hideColumn: (columnName: string) => void;
  setNewColumnPosition: (
    // eslint-disable-next-line no-unused-vars
    columnName: string,
    // eslint-disable-next-line no-unused-vars
    newPos: number
  ) => void;
}

const VisibleColumnsPanel: React.FC<VisibleColumnsPanelProps> = (props) => {
  const { showColumn, hideColumn, setNewColumnPosition, columns } = props;

  const visibleColumns = useMemo(
    () => columns.filter((a) => !a.hidden),
    [columns]
  );

  const _handleColumnDrag = useCallback(
    ({
      removedIndex,
      addedIndex,
    }: {
      removedIndex: number | null;
      addedIndex: number | null;
    }) => {
      if (removedIndex !== null && addedIndex !== null) {
        setNewColumnPosition(visibleColumns[removedIndex].name, addedIndex);
      }
    },
    [setNewColumnPosition, visibleColumns]
  );
  const sortByAlphabeticalOrder = (
    children: IColumnEditorNodeClass[]
  ): IColumnEditorNodeClass[] => {
    const result = children.sort((a, b) => {
      if (a.column.title && b.column.title) {
        const nameA = a.column.title.toLowerCase();
        const nameB = b.column.title.toLowerCase();
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }
      }
      return 0;
    });
    return result;
  };
  return (
    <List disablePadding sx={assignedListSx}>
      <Container
        data-testid="configureInspectorListItemContainer"
        dragHandleSelector=".drag-handle"
        lockAxis="y"
        onDrop={(e: {
          removedIndex: number | null;
          addedIndex: number | null;
          // -1 due to the column selector being part of the list
        }) =>
          e.addedIndex != null &&
          e.removedIndex != null &&
          _handleColumnDrag({
            addedIndex: e.addedIndex - 1,
            removedIndex: e.removedIndex - 1,
          })
        }
      >
        <ListItem
          centerChild={
            <SelectColumnEditor
              columns={columns}
              isValueDisabled={(node) => !node.column.hidden}
              modifyChildren={sortByAlphabeticalOrder}
              onValueChanged={(value) => showColumn(value.column.name)}
            />
          }
          customSx={(theme) => ({
            p: 0,
            pb: theme.spacing(1),
          })}
          size="medium"
        />
        {visibleColumns.map((col, ind) => (
          <DraggableListItem
            centerChild={
              <Typography
                color="textPrimary"
                noWrap
                textOverflow="ellipsis"
                variant="body1"
                width="100%"
              >
                {col.required || col.disableHideable
                  ? `${col.title} (${t('generic.required')})`
                  : col.title}
              </Typography>
            }
            customSx={{
              ...(ind % 2 === 0 ? altColorSx : {}),
              ...{
                cursor:
                  col.required || col.disableHideable ? 'default' : 'pointer',
              },
            }}
            key={col.name}
            leftChild={
              col.name !== 'grid.teto.action' ? (
                <DragItem size="large" title={t('generic.changeOrder')} />
              ) : (
                <IconButton
                  disabled
                  sx={(theme) => ({
                    width: theme.spacing(6),
                  })}
                >
                  &nbsp;
                </IconButton>
              )
            }
            rightChild={
              !col.required &&
              !col.disableHideable && (
                <IconButton onClick={() => hideColumn(col.name)}>
                  <DeleteIcon color="error" />
                </IconButton>
              )
            }
            size="small"
            testId="configureInspectorListItem"
          />
        ))}
      </Container>
    </List>
  );
};

export default VisibleColumnsPanel;
