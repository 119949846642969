import { TypeOnSelectionChangeArg } from '@inovua/reactdatagrid-community/types/TypeDataGridProps';
import { Box, SxProps, Theme } from '@mui/material';
import {
  ETOButton,
  ETOButtonProps,
  MessageContext,
} from '@teto/react-component-library-v2';
import React, {
  useCallback,
  useContext,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { Licenses, Permission } from 'teto-client-api';
import * as Yup from 'yup';
import { gql } from '../../../__generated__';
import {
  CompleteProcessScheduleInput,
  CompletedProcessScheduleQueueItem,
} from '../../../__generated__/graphql';
import buildMutation from '../../../api/graphQL/buildMutation';
import ActionBar from '../../../components/ActionBar/ActionBar';
import ReleaseIcon from '../../../components/Custom Icons/ReleaseIcon/ReleaseIcon';
import handleNumberInputChange from '../../../components/Inspectors/CompanyDetailInspector/helpers/companyHelpers';
import ActionButtonGridBuilderExtension from '../../../components/TETOGridGraphQL/GridBuilder/ActionButtonGridBuilderExtension';
import MainTetoGridGraphQL from '../../../components/TETOGridGraphQL/TETOMainGridGraphQL';
import GridCommonMobileButtons from '../../../components/TETOGridGraphQL/components/GridCommonMobileButtons/GridCommonMobileButtons';
import useGrid from '../../../components/TETOGridGraphQL/hooks/useGrid';
import { useGridBuilderFromView } from '../../../components/TETOGridGraphQL/hooks/useGridBuilder';
import { CellChangingArgs } from '../../../components/TETOGridGraphQL/types/CellChangingArgs';
import TETOGridRefType from '../../../components/TETOGridGraphQL/types/TETOGridRefType';
import RDGSelectedType from '../../../components/TetoGrid/types/RDGSelectedType';
import AuthContext from '../../../contexts/AuthContext';
import useIsMobile from '../../../hooks/useIsMobile';
import CompletedPSQueueView from '../../../views/CompletedPSQueueView.yaml';
import SharedBreadCrumbModal from '../../../components/SharedStateComponents/SharedBreadCrumbModal';

type ValidationErrorResponse = {
  validationErrors: { input: Record<string, string> };
};

const RootQueryPath = 'completedPSQueue';
const PersistenceName = 'completed-ps-queue-grid';
const EDITABLE_COLUMN = 'itemRevisedLastCost';
const ALWAYS_PROJECT_COLUMNS: Array<string> = [
  'destinationInventoryLocationId',
  'itemId',
  'itemLastCost',
  'number',
  'processScheduleId',
  'processScheduleId',
  'quantity',
  EDITABLE_COLUMN,
];

const releaseCompletedPSQueueMutation = gql(`
  mutation releaseCompletedPSQueue($input: CompleteProcessScheduleInput) {
    completeProcessSchedule(input: $input) {
      success
    }
  }
`);

const completedPSQueueBatchReleaseMutation = gql(`
  mutation completedPSQueueBatchRelease($input: CompleteProcessScheduleBatchInput) {
    completeProcessScheduleBatch(input: $input) {
      success
    }
  }
`);

const schema = Yup.object().shape({
  [EDITABLE_COLUMN]: Yup.number().nullable(),
});

const containerSx: SxProps<Theme> = (theme: Theme) => ({
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  [theme.breakpoints.down('md')]: {
    height: 'calc(100% - 3em)',
  },
});

const CompletedPSTab = () => {
  const authContext = useContext(AuthContext);
  const gridRef = useRef<TETOGridRefType | undefined>();
  const messageContext = useContext(MessageContext);
  const { ready, t } = useTranslation();
  const isMobile = useIsMobile();

  const [configureInspectorOpen, setConfigureInspectorOpen] =
    useState<boolean>(false);

  const [selectedRows, setSelectedRows] = useState<
    RDGSelectedType | undefined
  >();

  const releaseStatus = useMemo(() => {
    const itemCount = Object.keys(selectedRows ?? {}).length;

    return {
      confirm: {
        content: t('pages.processSchedule.releaseConfirmContent', {
          item: itemCount === 1 ? 'item' : 'items',
        }) as string,
        title: t('pages.processSchedule.releaseConfirmTitle'),
        type: 'yesNo',
        onNo: () => ({}),
      },
      count: itemCount,
    };
  }, [selectedRows, t]);

  const { canModify } = useMemo(
    () => ({
      canModify:
        authContext.hasLicense(Licenses.TotalETOProfessional) &&
        authContext.hasPermission(Permission.Modify_Queues_CompletedPSQueue),
    }),
    [authContext]
  );

  const _handleRowReleaseBtnClick = useCallback(
    (
      data: CompletedProcessScheduleQueueItem,
      editContext?: {
        updateLPP: boolean;
        successCallback: () => void;
        // eslint-disable-next-line no-unused-vars
        errorCallback: (e: Record<string, unknown>) => void;
      }
    ) => {
      const input: CompleteProcessScheduleInput = {
        inventoryLocationId: data?.destinationInventoryLocationId,
        itemId: data?.itemId,
        lPP: data?.itemLastCost,
        processScheduleId: data?.processScheduleId,
        quantity: data?.quantity,
        revisedLPP: data?.itemRevisedLastCost,
        updateLPP: editContext?.updateLPP ?? false,
      };

      buildMutation({
        queryString: releaseCompletedPSQueueMutation,
        variables: { input },
        callback: (d) => {
          if (d.completeProcessSchedule?.success) {
            if (editContext?.successCallback) editContext?.successCallback();
            else {
              messageContext.setSuccess(
                t('pages.processSchedule.releaseSuccess')
              );
              gridProps.refreshDataSource();
            }
          }
        },
        messageContext,
        errorCallback: editContext?.errorCallback,
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [t]
  );

  const _handleCellChange = useCallback(
    (change: CellChangingArgs) => {
      const {
        column: { name },
        editValue: { value, originalValue },
        newData,
        changeContext,
      } = change;

      const _handleError = (e: ValidationErrorResponse) => {
        if (e?.validationErrors)
          changeContext.setValidationErrors(e?.validationErrors?.input);
        changeContext.cancelChange();
        changeContext.setColumnValue(name as string, originalValue);
      };

      const itemRevisedLastCost = handleNumberInputChange({
        value,
        type: 'decimal',
      });

      _handleRowReleaseBtnClick(
        { ...newData, itemRevisedLastCost },
        {
          updateLPP: true,
          successCallback: () =>
            changeContext.setColumnValue(name as string, value),
          errorCallback: (e) => _handleError(e as ValidationErrorResponse),
        }
      );
    },
    [_handleRowReleaseBtnClick]
  );

  const { gridBuilder, builderReady, error, hasError } = useGridBuilderFromView(
    CompletedPSQueueView,
    RootQueryPath,
    (gb) =>
      gb
        .extension(
          new ActionButtonGridBuilderExtension([
            {
              componentName: t('pages.processSchedule.releaseCompletedPSQueue'),
              icon: <ReleaseIcon data-testid="release-btn" />,
              title: t('pages.processSchedule.releaseCompletedPSQueue'),
              onClick: ({ data }) => _handleRowReleaseBtnClick(data),
              color: 'primary',
              disabled: !canModify,
              confirm: {
                ...releaseStatus.confirm,
                content: t('pages.processSchedule.releaseConfirmContent', {
                  item: 'item',
                }),
              } as ETOButtonProps['confirm'],
            },
          ]),
          t
        )
        .updateDefinition(EDITABLE_COLUMN, { editable: canModify })
        .updateDefinition('itemCompanyId', { defaultFlex: 1 })
        .updateDefinition('itemDescription', { defaultFlex: 1 })
        .updateDefinition('materialItemCompanyId', { defaultFlex: 1 })
  );

  const { gridProps } = useGrid(
    PersistenceName,
    `${RootQueryPath}.items`,
    (e) => messageContext.setError(e.message ?? e),
    t,
    ALWAYS_PROJECT_COLUMNS,
    gridBuilder,
    {
      filterAndSortMode: 'serverSide',
      mandatoryFilter: [],
    }
  );

  const _handleReleaseBtnClick = useCallback(() => {
    const batch: Array<CompleteProcessScheduleInput> = Object.values(
      selectedRows as Record<string, Partial<CompletedProcessScheduleQueueItem>>
    ).map((i) => ({
      inventoryLocationId: i?.destinationInventoryLocationId,
      itemId: i?.itemId,
      lPP: i?.itemLastCost,
      processScheduleId: i?.processScheduleId,
      quantity: i?.quantity,
      revisedLPP: i?.itemRevisedLastCost,
      updateLPP: false,
    }));

    buildMutation({
      queryString: completedPSQueueBatchReleaseMutation,
      variables: { input: { batch } },
      callback: (d) => {
        if (d.completeProcessScheduleBatch?.success) {
          gridProps.refreshDataSource();
        }
      },
      messageContext,
      errorCallback: (e) => {
        if (e.hasError()) e.handleAllErrors(messageContext.setError);
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gridProps, selectedRows]);

  const GridCommonBtns = GridCommonMobileButtons({
    refreshGrid: () => gridProps.refreshDataSource(),
    gridRef,
    setConfigureInspector: () => setConfigureInspectorOpen(true),
  });

  const ReleaseBtn = useMemo(
    () => ({
      disabled: !canModify || releaseStatus.count === 0,
      icon: <ReleaseIcon />,
      onclick: () => _handleReleaseBtnClick(),
      title: t('pages.processSchedule.releaseCompletedPSQueue'),
      customComponent: (
        <ETOButton
          color="primary"
          confirm={releaseStatus.confirm as ETOButtonProps['confirm']}
          disabled={!canModify || releaseStatus.count === 0}
          icon={<ReleaseIcon data-testid="action-bar-release-btn" />}
          key={t('generic.reports')}
          onClick={() => _handleReleaseBtnClick()}
          size="medium"
        >
          {t('pages.processSchedule.releaseCompletedPSQueue')}
        </ETOButton>
      ),
    }),
    [
      _handleReleaseBtnClick,
      canModify,
      releaseStatus.confirm,
      releaseStatus.count,
      t,
    ]
  );

  const rightChildren = useMemo(
    () => [ReleaseBtn, GridCommonBtns],
    [GridCommonBtns, ReleaseBtn]
  );

  return (
    <Box sx={containerSx}>
      <ActionBar rightChildren={rightChildren} withBottomNav />
      <MainTetoGridGraphQL
        builderReady={builderReady}
        checkboxColumn={!isMobile}
        checkboxOnlyRowSelect={false}
        configureInspector={configureInspectorOpen}
        customGridWrapSx={{ gridTemplateRows: 'unset' }}
        editMode={{
          editMode: 'byRow',
          validationSchema: schema,
          onCellChanging: (c: CellChangingArgs) => {
            if (c.column.name === EDITABLE_COLUMN) {
              _handleCellChange(c);
            }
          },
        }}
        error={error}
        externalQueryProps={[]}
        gridProps={{ ...gridProps, idProperty: 'processScheduleId' }}
        hasError={hasError}
        header={{ hidden: true }}
        mobileGridOptions
        onSelectionChange={
          !isMobile
            ? (d: TypeOnSelectionChangeArg) =>
                setSelectedRows(d.selected as RDGSelectedType)
            : undefined
        }
        ready={ready}
        ref={gridRef}
        selectedRows={!isMobile ? selectedRows : undefined}
        setConfigureInspector={setConfigureInspectorOpen}
        showEmptyRows
        showGroupSummaryRow={false}
        t={t}
        tableIdentifier={PersistenceName}
      />
      <SharedBreadCrumbModal />
    </Box>
  );
};

export default CompletedPSTab;
