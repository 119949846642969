import { Divider, Grid, SxProps, Theme } from '@mui/material';
import {
  ETOAccordion,
  ETOCheckBox,
  ETODateField,
  ETOTextField,
} from '@teto/react-component-library-v2';
import { FormikProps } from 'formik';
import React, { useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Contact } from '../../../../../__generated__/graphql';
import SettingsContext from '../../../../../contexts/SettingsContext';
import formatCharCount from '../../../../../helpers/formatCharCount';
import isCustomCaption from '../../../../../helpers/isCustomCaption';
import isEmptyOrWhitespace from '../../../../../helpers/isEmptyOrWhitespace';
import { PanelMode } from '../../../types/PanelState';
import FanRatingsInput from './FanRatingsInput';

const GRID_SPACING = 2;
interface IContactProps {
  formik: FormikProps<Partial<Contact>>;
  panelMode: PanelMode;
  canModify: boolean;
}

const accordionSx: SxProps<Theme> = {
  boxShadow: 0,
  backgroundColor: (theme) => `${theme.palette.background.paper}`,
  width: '100%',

  '& .MuiAccordionSummary-root, .MuiAccordionDetails-root': {
    pr: 0,
    pt: 0,
    pl: 0,
    pb: 0,
  },
};
const bottomMargin = { pb: 2 };
const fixColSpacing = { columnGap: '0px' };
const paddingFix = { paddingTop: '0px !important' };
interface AccordionProps {
  children: React.ReactNode;
  title: string;
}
const ContactFormAccordion = (props: AccordionProps) => {
  const { title, children } = props;

  return (
    <Grid item lg={12} md={12} sx={paddingFix} xs={12}>
      <ETOAccordion defaultExpanded defaultSummary={title} sx={accordionSx}>
        <Grid container spacing={GRID_SPACING} sx={fixColSpacing}>
          {children}
        </Grid>
      </ETOAccordion>
    </Grid>
  );
};

const ContactForm = (props: IContactProps) => {
  // eslint-disable-next-line no-unused-vars
  const { formik, panelMode, canModify } = props;
  const { t } = useTranslation();
  const {
    settings: { dateFormat },
  } = useContext(SettingsContext);

  const disableInputs = useMemo(() => {
    if (!canModify) return { common: true }; // disable all inputs
    return { common: false };
  }, [canModify]);

  const hasCustomCaptions = useMemo(() => {
    let result = false;
    Array.from({ length: 8 }).forEach((_, i) => {
      if (
        isCustomCaption(
          `custom${i + 1}`,
          t(`entities:Contact.ContactCustom${i + 1}`),
          'Contact'
        )
      ) {
        result = true;
      }
    });
    return result;
  }, [t]);

  const _handleNumberChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    field: string
  ) => {
    if (isEmptyOrWhitespace(e.target.value)) {
      formik.setFieldValue(field, null);
    } else {
      const parsedNum = Number.parseInt(e.target.value, 10);

      if (Number.isNaN(parsedNum)) return;

      formik.setFieldValue(field, parsedNum);
    }
  };

  return (
    <>
      <Grid container spacing={GRID_SPACING}>
        <ContactFormAccordion title={`${t('pages.contacts.details')}`}>
          <Grid item md={3} xs={12}>
            <ETOTextField
              disabled={disableInputs.common}
              error={formik.errors.firstName}
              handleChange={formik.handleChange}
              helperText={formatCharCount(
                'firstName',
                formik.values.firstName,
                50
              )}
              label={t('entities:Contact.FirstName')}
              multiline
              name="firstName"
              size="small"
              value={formik?.values.firstName || ''}
            />
          </Grid>
          <Grid item md={3} xs={12}>
            <ETOTextField
              disabled={disableInputs.common}
              error={formik.errors.lastName}
              handleChange={formik.handleChange}
              helperText={formatCharCount(
                'lastName',
                formik.values.lastName,
                50
              )}
              label={t('entities:Contact.LastName')}
              multiline
              name="lastName"
              size="small"
              value={formik?.values.lastName || ''}
            />
          </Grid>
          <Grid item md={3} xs={12}>
            <ETOTextField
              disabled={disableInputs.common}
              error={formik.errors.email}
              handleChange={formik.handleChange}
              helperText={formatCharCount('email', formik.values.email, 50)}
              label={t('entities:Contact.Email')}
              multiline
              name="email"
              size="small"
              value={formik?.values.email || ''}
            />
          </Grid>
          <Grid item md={3} xs={12}>
            <FanRatingsInput formik={formik} />
          </Grid>
        </ContactFormAccordion>
        <ContactFormAccordion title={t('entities:Contact.Work')}>
          <Grid item md={3} xs={12}>
            <ETOTextField
              disabled={disableInputs.common}
              error={formik.errors.title}
              handleChange={formik.handleChange}
              helperText={formatCharCount('title', formik.values.title, 50)}
              label={t('entities:Contact.Title')}
              multiline
              name="title"
              size="small"
              value={formik?.values.title || ''}
            />
          </Grid>
          <Grid item md={3} xs={12}>
            <ETOTextField
              disabled={disableInputs.common}
              error={formik.errors.phone}
              handleChange={formik.handleChange}
              helperText={formatCharCount('phone', formik.values.phone, 50)}
              label={t('entities:Contact.Phone')}
              multiline
              name="phone"
              size="small"
              value={formik?.values.phone || ''}
            />
          </Grid>
          <Grid item md={3} xs={12}>
            <ETOTextField
              disabled={disableInputs.common}
              error={formik.errors.ext}
              handleChange={(e) => _handleNumberChange(e, 'ext')}
              label={t('entities:Contact.Ext')}
              multiline
              name="ext"
              size="small"
              type="number"
              value={formik?.values.ext || ''}
            />
          </Grid>
          <Grid item md={3} xs={12}>
            <ETOTextField
              disabled={disableInputs.common}
              error={formik.errors.fax}
              handleChange={formik.handleChange}
              helperText={formatCharCount('fax', formik.values.fax, 50)}
              label={t('entities:Contact.Fax')}
              multiline
              name="fax"
              size="small"
              value={formik?.values.fax || ''}
            />
          </Grid>
        </ContactFormAccordion>
        <ContactFormAccordion title={t('entities:Contact.Mobile')}>
          <Grid item md={3} xs={12}>
            <ETOTextField
              disabled={disableInputs.common}
              error={formik.errors.carPhone}
              handleChange={formik.handleChange}
              helperText={formatCharCount(
                'carPhone',
                formik.values.carPhone,
                50
              )}
              label={t('entities:Contact.CarPhone')}
              multiline
              name="carPhone"
              size="small"
              value={formik?.values.carPhone || ''}
            />
          </Grid>
          <Grid item md={3} xs={12}>
            <ETOTextField
              disabled={disableInputs.common}
              error={formik.errors.pager}
              handleChange={formik.handleChange}
              helperText={formatCharCount('pager', formik.values.pager, 50)}
              label={t('entities:Contact.Pager')}
              multiline
              name="pager"
              size="small"
              value={formik?.values.pager || ''}
            />
          </Grid>
          <Grid item md={3} xs={12}>
            <ETOTextField
              disabled={disableInputs.common}
              error={formik.errors.cellPhone}
              handleChange={formik.handleChange}
              helperText={formatCharCount(
                'cellPhone',
                formik.values.cellPhone,
                50
              )}
              label={t('entities:Contact.CellPhone')}
              multiline
              name="cellPhone"
              size="small"
              value={formik?.values.cellPhone || ''}
            />
          </Grid>
        </ContactFormAccordion>
        <ContactFormAccordion title={t('entities:Contact.Home')}>
          <Grid item md={3} xs={12}>
            <ETOTextField
              disabled={disableInputs.common}
              error={formik.errors.homePhone}
              handleChange={formik.handleChange}
              helperText={formatCharCount(
                'homePhone',
                formik.values.homePhone,
                50
              )}
              label={t('entities:Contact.HomePhone')}
              multiline
              name="homePhone"
              size="small"
              value={formik?.values.homePhone || ''}
            />
          </Grid>
          <Grid item md={3} xs={12}>
            <ETOTextField
              disabled={disableInputs.common}
              error={formik.errors.homeFax}
              handleChange={formik.handleChange}
              helperText={formatCharCount('homeFax', formik.values.homeFax, 50)}
              label={t('entities:Contact.HomeFax')}
              multiline
              name="homeFax"
              size="small"
              value={formik?.values.homeFax || ''}
            />
          </Grid>
        </ContactFormAccordion>
        <Divider />
        {hasCustomCaptions && (
          <ContactFormAccordion title={t('generic.misc')}>
            {isCustomCaption(
              'custom1',
              t('entities:Contact.ContactCustom1'),
              'Contact'
            ) && (
              <Grid item md={3} xs={12}>
                <ETOTextField
                  disabled={disableInputs.common}
                  error={formik.errors.custom1}
                  handleChange={formik.handleChange}
                  key="custom1"
                  label={t('entities:Contact.ContactCustom1')}
                  maxRows={3}
                  multiline
                  name="custom1"
                  size="small"
                  value={formik?.values.custom1 ?? ''}
                />
              </Grid>
            )}

            {isCustomCaption(
              'custom2',
              t('entities:Contact.ContactCustom2'),
              'Contact'
            ) && (
              <Grid item md={3} xs={12}>
                <ETOTextField
                  disabled={disableInputs.common}
                  error={formik.errors.custom2}
                  handleChange={formik.handleChange}
                  key="custom2"
                  label={t('entities:Contact.ContactCustom2')}
                  maxRows={3}
                  multiline
                  name="custom2"
                  size="small"
                  value={formik?.values.custom2 ?? ''}
                />
              </Grid>
            )}

            {isCustomCaption(
              'custom3',
              t('entities:Contact.ContactCustom3'),
              'Contact'
            ) && (
              <Grid item md={3} xs={12}>
                <ETOTextField
                  disabled={disableInputs.common}
                  error={formik.errors.custom3 as string}
                  handleChange={(e) => _handleNumberChange(e, 'custom3')}
                  key="custom3"
                  label={t('entities:Contact.ContactCustom3')}
                  name="custom3"
                  size="small"
                  type="number"
                  value={formik?.values.custom3 ?? ''}
                />
              </Grid>
            )}

            {isCustomCaption(
              'custom4',
              t('entities:Contact.ContactCustom4'),
              'Contact'
            ) && (
              <Grid item md={3} xs={12}>
                <ETOTextField
                  disabled={disableInputs.common}
                  error={formik.errors.custom4 as string}
                  handleChange={(e) => _handleNumberChange(e, 'custom4')}
                  key="custom4"
                  label={t('entities:Contact.ContactCustom4')}
                  name="custom4"
                  size="small"
                  type="number"
                  value={formik?.values.custom4 ?? ''}
                />
              </Grid>
            )}

            {isCustomCaption(
              'custom5',
              t('entities:Contact.ContactCustom5'),
              'Contact'
            ) && (
              <Grid item md={3} xs={12}>
                <ETODateField
                  clearable
                  disabled={disableInputs.common}
                  error={formik.errors.custom5 as string}
                  handleChange={(v) => formik.setFieldValue('custom5', v)}
                  inputFormat={dateFormat}
                  key="custom5"
                  label={t('entities:Contact.ContactCustom5')}
                  name="custom5"
                  size="small"
                  value={formik?.values.custom5 ?? null}
                />
              </Grid>
            )}

            {isCustomCaption(
              'custom6',
              t('entities:Contact.ContactCustom6'),
              'Contact'
            ) && (
              <Grid item md={3} xs={12}>
                <ETODateField
                  clearable
                  disabled={disableInputs.common}
                  error={formik.errors.custom6 as string}
                  handleChange={(v) => formik.setFieldValue('custom6', v)}
                  inputFormat={dateFormat}
                  key="custom6"
                  label={t('entities:Contact.ContactCustom6')}
                  name="custom6"
                  size="small"
                  value={formik?.values.custom6 ?? null}
                />
              </Grid>
            )}
          </ContactFormAccordion>
        )}
      </Grid>
      <Divider sx={{ mt: hasCustomCaptions ? 2 : undefined }} />
      <Grid container spacing={2} sx={bottomMargin}>
        <Grid item md={3} xs={12}>
          <ETOCheckBox
            data-testid="active-checkbox"
            disabled={disableInputs.common}
            handleChange={(e) =>
              formik.setFieldValue('active', e.target.checked)
            }
            label={
              formik.errors.active
                ? `${t('entities:Contact.Active')} (${t('generic.required')})`
                : t('entities:Contact.Active')
            }
            name="active"
            size="small"
            sxProps={{
              '&  .MuiFormControlLabel-label': {
                color: formik.errors.active ? 'error.main' : 'inherit',
              },
            }}
            value={formik.values.active ?? true}
          />
        </Grid>

        {isCustomCaption(
          'custom7',
          t('entities:Contact.ContactCustom7'),
          'Contact'
        ) && (
          <Grid item md={3} xs={12}>
            <ETOCheckBox
              disabled={disableInputs.common}
              handleChange={(e) =>
                formik.setFieldValue('custom7', e.target.checked)
              }
              key="custom7"
              label={t('entities:Contact.ContactCustom7')}
              name="custom7"
              size="small"
              value={formik?.values.custom7 ?? false}
            />
          </Grid>
        )}
        {isCustomCaption(
          'custom8',
          t('entities:Contact.ContactCustom8'),
          'Contact'
        ) && (
          <Grid item md={3} xs={12}>
            <ETOCheckBox
              disabled={disableInputs.common}
              handleChange={(e) =>
                formik.setFieldValue('custom8', e.target.checked)
              }
              key="custom8"
              label={t('entities:Contact.ContactCustom8')}
              name="custom8"
              size="small"
              value={formik?.values.custom8 ?? false}
            />
          </Grid>
        )}
      </Grid>
    </>
  );
};

export default ContactForm;
