import { gql } from '../../../../__generated__';

export default gql(`
  query getPODetailCurrentRate($name: String) {
    currencyRates(where: {name: {eq: $name}}) {
      items {
        rate
        name
      }
    }
  }
`);
