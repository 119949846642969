/* eslint-disable import/prefer-default-export */
import { Box, InputAdornment } from '@mui/material';
import {
  ETOCheckBox,
  ETODateField,
  ETODateTimeField,
  ETOTextField,
  ETOTimeField,
} from '@teto/react-component-library-v2';
import React from 'react';
import { TFunction } from 'react-i18next';
import {
  EmployeeSettings,
  ServerSystemSettings,
} from '../../contexts/SettingsContext';
import CurrencyFieldWrapper from './CurrencyFieldWrapper';
import FieldOptions from './FieldOptions';
import TETODataField from './FormBuilder/TETODataField';
import FormDefinition from './FormDefinition';
import SelectWithDataWrapper from './SelectWithDataWrapper';
import ETOEmailField from './ETOEmailField';

export const _calculateDisabledValue = (
  formField: TETODataField,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  values: any,
  formFields?: string[]
) => {
  if (formFields?.includes(formField.name)) {
    return true;
  }

  if (!formField.disabled) return false;

  if (typeof formField.disabled === 'boolean') return formField.disabled;

  if (typeof formField.disabled === 'function') {
    return formField.disabled(values);
  }

  return false;
};

export const _calculateHiddenValue = (
  formField: TETODataField,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  values: any
) => {
  if (!formField.hidden) return false;

  if (typeof formField.hidden === 'boolean') return formField.hidden;

  if (typeof formField.hidden === 'function') {
    return formField.hidden(values);
  }

  return false;
};

export const renderText = (
  t: TFunction<'translation', undefined>,
  label: string
) => t(label);

export const renderField = (
  form: FormDefinition,
  formField: TETODataField,
  t: TFunction<'translation', undefined>,
  settings:
    | (ServerSystemSettings &
        EmployeeSettings & {
          dateTimeFormat: string;
        })
    | null
    | undefined
) => {
  const commonWithError: FieldOptions = {
    name: formField.name,
    onBlur: form.handleBlur,
    label: `${renderText(t, renderText(t, formField.title))} ${
      formField.required ? '*' : ''
    }`,
    value: formField.computedValue
      ? formField.computedValue(form.values)
      : form.values[formField.name],
    disabled: _calculateDisabledValue(
      formField,
      form.values,
      form.disabledFields
    ),
    error:
      form.hasSubmitted || form.touched?.[formField.name]
        ? renderText(t, form.validationErrors?.[formField.name] as string)
        : '',
  };

  if (formField.computedValue || formField.fieldSource === 'computed')
    commonWithError.disabled = true;

  if (
    !commonWithError.disabled &&
    formField.dependsOn &&
    formField.dependsOn.length > 0
  ) {
    formField.dependsOn.forEach((d) => {
      // if the dependant fields change
      if (!form.values?.[d]) {
        commonWithError.disabled = true;
      }
    });
  }

  if (_calculateHiddenValue(formField, form.values)) {
    return <div style={{ display: 'none' }} />;
  }

  if (formField.render) {
    const result = formField.render(form, formField, commonWithError);
    if (result) return result;
  }

  if (formField.selectSource) {
    return (
      <SelectWithDataWrapper
        field={formField}
        form={form}
        selectProps={{
          ...commonWithError,
          helperText: ' ',
        }}
      />
    );
  }

  switch (formField.type) {
    case 'email': {
      return (
        <ETOEmailField
          {...commonWithError}
          error={
            commonWithError.value.length === 0 && formField.required
              ? 'Required'
              : commonWithError.error
          }
          handleChange={(v) => {
            form.updateField(formField.name, v);
            if (formField.onFieldChange) formField?.onFieldChange(form);
          }}
          helperText={
            commonWithError.error === '' ? ' ' : commonWithError.error
          }
          items={[form.values[formField.name]]}
        />
      );
    }
    case 'boolean':
      // eslint-disable-next-line no-case-declarations
      let value = form.values[formField.name];
      if (value === undefined || value === null || value === '') {
        value = formField.required ? false : null;
      } else {
        value =
          form.values[formField.name] === 'true' ||
          form.values[formField.name] === true;
      }

      return (
        <ETOCheckBox
          disabled={commonWithError.disabled}
          handleChange={(v) => {
            if (formField.required) {
              form.updateField(formField.name, v.currentTarget.checked);
            } else if (value === null) {
              form.updateField(formField.name, true);
            } else if (value === true) {
              form.updateField(formField.name, false);
            } else {
              form.updateField(formField.name, null);
            }
            if (formField.onFieldChange) formField?.onFieldChange(form);
          }}
          label={renderText(t, formField.title)}
          name={formField.name}
          onBlur={form.handleBlur}
          value={value}
        />
      );
    case 'string':
    case 'stringmultiline':
      // eslint-disable-next-line no-case-declarations
      let helperText = ' ';
      if (formField.hints.indexOf('CHAR-COUNT') >= 0) {
        const validator = formField.validators?.find(
          (a) => typeof a === 'string' && a.startsWith('LENGTH:::')
        ) as string;

        if (validator) {
          const maxChar = parseInt(
            validator.split(';;;')[0].split(':::')[1],
            10
          );

          helperText = `${commonWithError.value?.length.toString()} \/ ${maxChar}`;
        }
      }

      return (
        <ETOTextField
          {...commonWithError}
          handleChange={(v) => {
            form.updateField(formField.name, v.currentTarget.value);
            if (formField.onFieldChange) formField?.onFieldChange(form);
          }}
          helperText={helperText}
          inputLabelProps={{ shrink: true }}
          minRows={
            formField.hints.indexOf('MULTI-LINE') >= 0 ||
            formField.type === 'stringmultiline'
              ? 3
              : undefined
          }
          multiline={
            formField.hints.indexOf('MULTI-LINE') >= 0 ||
            formField.type === 'stringmultiline'
          }
          size="small"
          value={commonWithError.value ?? ''}
        />
      );
    case 'integer':
      return (
        <ETOTextField
          {...commonWithError}
          handleChange={(v) => {
            form.updateField(formField.name, v.currentTarget.value);
            if (formField.onFieldChange) formField?.onFieldChange(form);
          }}
          helperText=" "
          inputLabelProps={{ shrink: true }}
          inputProps={{ style: { textAlign: 'right' } }}
          size="small"
          type="number"
          value={commonWithError.value ?? ''}
        />
      );
    case 'currency':
      // eslint-disable-next-line no-case-declarations
      let format: string | undefined;
      // eslint-disable-next-line no-case-declarations
      let symbol: string | undefined;

      if (formField.currency) {
        format =
          typeof formField.currency === 'function'
            ? formField.currency(form.values)?.format
            : formField.currency.format;
        symbol =
          typeof formField.currency === 'function'
            ? formField.currency(form.values)?.symbol
            : formField.currency.symbol;
      }

      return (
        <CurrencyFieldWrapper
          format={format}
          inputProps={{
            ...commonWithError,
            size: 'small',
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            handleChange: (e) => {
              form.updateField(formField.name, e.target.value);
              if (formField.onFieldChange) formField?.onFieldChange(form);
            },
            helperText: ' ',
            inputLabelProps: { shrink: true },
            value: commonWithError.value ?? 0,
          }}
          symbol={symbol}
        />
      );
    case 'decimal':
      if (formField.hints.includes('PERCENTAGE')) {
        return (
          <ETOTextField
            {...commonWithError}
            handleChange={(v) => {
              const inputVal = v.currentTarget.value;
              const numericValue =
                inputVal === '' ? '' : parseFloat(inputVal) / 100;
              form.updateField(formField.name, numericValue.toString());
              if (formField.onFieldChange) formField?.onFieldChange(form);
            }}
            helperText=" "
            inputLabelProps={{ shrink: true }}
            inputProps={{ min: 0, step: '0.1', style: { textAlign: 'right' } }}
            // eslint-disable-next-line react/jsx-no-duplicate-props
            InputProps={{
              endAdornment: <InputAdornment position="end">%</InputAdornment>,
              sx: {
                textAlign: 'right',
              },
            }}
            size="small"
            type="number"
            value={
              commonWithError.value || commonWithError.value === 0
                ? Math.round(commonWithError.value * 100 * 100) / 100
                : ''
            }
          />
        );
      }
      return (
        <ETOTextField
          {...commonWithError}
          handleChange={(v) => {
            const updatedVal =
              v.currentTarget.value === ''
                ? null
                : parseFloat(v.currentTarget.value);
            form.updateField(formField.name, updatedVal);
            if (formField.onFieldChange) formField?.onFieldChange(form);
          }}
          helperText=" "
          inputLabelProps={{ shrink: true }}
          inputProps={{ min: 0, step: '0.1', style: { textAlign: 'right' } }}
          size="small"
          type="number"
          value={commonWithError.value ?? ''}
        />
      );

    case 'password':
      return (
        <ETOTextField
          {...commonWithError}
          handleChange={(v) => {
            form.updateField(formField.name, v.currentTarget.value);
            if (formField.onFieldChange) formField?.onFieldChange(form);
          }}
          helperText=" "
          inputLabelProps={{ shrink: true }}
          size="small"
          type="password"
          value={commonWithError.value ?? ''}
        />
      );
    case 'date':
      return (
        <ETODateField
          {...commonWithError}
          handleChange={(v) => {
            form.updateField(formField.name, v);
            if (formField.onFieldChange) formField?.onFieldChange(form);
          }}
          helperText=" "
          inputFormat={settings?.dateFormat}
          inputLabelProps={{ shrink: true }}
          size="small"
          value={commonWithError.value === '' ? null : commonWithError.value}
        />
      );
    case 'datetime':
      return (
        <ETODateTimeField
          {...commonWithError}
          handleChange={(v) => {
            form.updateField(formField.name, v);
            if (formField.onFieldChange) formField?.onFieldChange(form);
          }}
          helperText=" "
          inputFormat={settings?.dateTimeFormat}
          inputLabelProps={{ shrink: true }}
          size="small"
          value={commonWithError.value === '' ? null : commonWithError.value}
        />
      );
    case 'time':
      return (
        <ETOTimeField
          {...commonWithError}
          handleChange={(v) => {
            form.updateField(formField.name, v);
            if (formField.onFieldChange) formField?.onFieldChange(form);
          }}
          helperText=" "
          inputFormat={settings?.timeFormat}
          inputLabelProps={{ shrink: true }}
          size="small"
          value={commonWithError.value === '' ? null : commonWithError.value}
        />
      );
    default:
      return <Box>Unknown control type {formField.type}</Box>;
  }
};
