import { atom } from 'jotai';
import { createScope, molecule } from 'jotai-molecules';
import { atomWithReset } from 'jotai/utils';
import { PartHistoryProps } from '../../PartsHistory/PartHistoryProps';

type PartHistoryState = PartHistoryProps & {
  open: boolean;
};

export const PartHistorySharedState = atomWithReset<PartHistoryState>({
  part: undefined,
  open: false,
  tab: 0,
});

const _defaultOnClose = atom(null, (get, set) =>
  set(PartHistorySharedState, {
    open: false,
    part: undefined,
    tab: 0,
  })
);

// Scope
export const initialPartHistoryStateOnCloseScope = createScope(_defaultOnClose);

export const onPartHistoryClose = molecule((getMol, getScope) => {
  const initialOnClose = getScope(initialPartHistoryStateOnCloseScope);
  return initialOnClose;
});
