import { Box, SxProps, Theme, useMediaQuery, useTheme } from '@mui/material';
import React, { useCallback, useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import SettingsContext, {
  EmployeeSettings,
  ServerSystemSettings,
} from '../../contexts/SettingsContext';
import {
  Field,
  FormAndFieldSizing,
  FormLayout,
} from '../../forms/FormLayoutDefinitions';
import TETODataField from './FormBuilder/TETODataField';
import FormDefinition from './FormDefinition';
import FormGroup from './FormGroup';
import TETOFormField from './TETOFormField';
import { renderField } from './fieldRenderer';

const formWrapper = (theme: Theme) => ({
  display: 'grid',
  margin: 1,
  gap: theme.spacing(1),
});

type TETOFormProps = {
  form: FormDefinition;
  formWrapperSx?: SxProps<Theme>;
  formGroupSx?: SxProps<Theme>;
  formLayout?: FormLayout;
  collapsible?: boolean;
  // eslint-disable-next-line no-unused-vars
  groupRenderer?: (groupName: string) => React.ReactNode | false;
  fieldRenderer?: (
    // eslint-disable-next-line no-unused-vars
    form: FormDefinition,
    // eslint-disable-next-line no-unused-vars
    formField: TETODataField,
    // eslint-disable-next-line no-unused-vars
    fieldLayout: Field,
    // eslint-disable-next-line no-unused-vars
    settings: ServerSystemSettings &
      EmployeeSettings & {
        dateTimeFormat: string;
      },
    // eslint-disable-next-line no-unused-vars
    input: React.ReactNode
  ) => React.ReactNode | false;
};

const mapping = ['xs', 'sm', 'md', 'lg', 'xl'] as const;

const calculateCurrentMapping = (
  breakpointData: FormAndFieldSizing,
  currentBreakpoint: 'xs' | 'sm' | 'md' | 'lg' | 'xl'
) => {
  if (typeof breakpointData === 'number') return breakpointData;

  // if there is a matching entry, no work to do just return it
  if (Object.prototype.hasOwnProperty.call(breakpointData, currentBreakpoint)) {
    return breakpointData[currentBreakpoint] as number;
  }

  const indexOfCurrent = mapping.indexOf(currentBreakpoint);
  if (indexOfCurrent < 0) return 1;

  const mapsToProcess = mapping.slice(0, indexOfCurrent).reverse();

  for (let i = 0; i < mapsToProcess.length; i += 1) {
    if (
      Object.prototype.hasOwnProperty.call(breakpointData, mapsToProcess[i])
    ) {
      return breakpointData[mapsToProcess[i]] as number;
    }
  }

  return 1;
};

const TETOForm: React.FC<TETOFormProps> = (props) => {
  const settingsContext = useContext(SettingsContext);

  const theme = useTheme();
  const oneColLimit = useMediaQuery(theme.breakpoints.down('sm'));
  const { t } = useTranslation();

  const {
    formLayout,
    form,
    formWrapperSx,
    formGroupSx,
    groupRenderer,
    fieldRenderer,
    collapsible,
  } = props;

  const xl = useMediaQuery(theme.breakpoints.only('xl'));
  const lg = useMediaQuery(theme.breakpoints.only('lg'));
  const md = useMediaQuery(theme.breakpoints.only('md'));
  const sm = useMediaQuery(theme.breakpoints.only('sm'));
  const xs = useMediaQuery(theme.breakpoints.only('xs'));

  const currentBreakpoint = useMemo(() => {
    if (xl) return 'xl';
    if (lg) return 'lg';
    if (md) return 'md';
    if (sm) return 'sm';
    if (xs) return 'xs';
  }, [lg, md, sm, xl, xs]);

  const activeFormLayout = useMemo((): FormLayout => {
    if (formLayout) return formLayout;

    return {
      form: {
        columns: 1,
        fields: form.fields.map((f) => f.name),
      },
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formLayout]);

  const currentColumnCount = useMemo((): number => {
    if (oneColLimit || !activeFormLayout.form) return 1;

    if (typeof activeFormLayout.form.columns === 'number')
      return activeFormLayout.form.columns;

    if (!currentBreakpoint) return 1;

    const breakpointData = activeFormLayout.form.columns;

    const result = calculateCurrentMapping(breakpointData, currentBreakpoint);

    return result;
  }, [currentBreakpoint, oneColLimit, activeFormLayout]);

  const _renderGroup = useCallback(
    (
      groupName: string,
      children: React.ReactNode & NonNullable<React.ReactNode>
    ) => {
      if (groupRenderer) {
        const grp = groupRenderer(groupName);
        if (grp !== false) return grp;
      }

      return (
        <FormGroup
          collapsible={collapsible ? groupName !== '' : false}
          columnCount={currentColumnCount}
          groupWrapperSx={formGroupSx}
          key={`group-${groupName}`}
          title={t(groupName)}
        >
          {children}
        </FormGroup>
      );
    },
    [currentColumnCount, formGroupSx, groupRenderer, collapsible, t]
  );

  const _renderField = useCallback(
    (
      formField: TETODataField,
      fieldLayout: Field,
      settings: ServerSystemSettings &
        EmployeeSettings & {
          dateTimeFormat: string;
        }
    ) => {
      let numberOfFieldColumns = 1;

      if (currentBreakpoint) {
        numberOfFieldColumns =
          typeof fieldLayout === 'string'
            ? 1
            : calculateCurrentMapping(fieldLayout.units, currentBreakpoint);
      }

      if (fieldRenderer) {
        const field = fieldRenderer(
          form,
          formField,
          fieldLayout,
          settings,
          renderField(form, formField, t, settings)
        );

        if (field !== false) return field;
      }
      return (
        <TETOFormField
          fieldColumns={numberOfFieldColumns}
          key={`field-${formField.name}`}
          maxColumns={currentColumnCount}
        >
          {renderField(form, formField, t, settingsContext.settings)}
        </TETOFormField>
      );
    },
    [
      currentBreakpoint,
      currentColumnCount,
      fieldRenderer,
      form,
      settingsContext.settings,
      t,
    ]
  );

  const fieldCache = useMemo(
    (): { [key: string]: TETODataField } =>
      form.fields.reduce((pv, cv) => ({ ...pv, [cv.name]: cv }), {}),
    [form]
  );

  return (
    <Box
      sx={[
        formWrapper,
        ...(Array.isArray(formWrapperSx) ? formWrapperSx : [formWrapperSx]),
      ]}
    >
      {activeFormLayout.form && activeFormLayout.form.groups && (
        <>
          {activeFormLayout.form.groups.map((fg) =>
            _renderGroup(
              fg.name,
              fg.fields
                .filter((a) => fieldCache[typeof a === 'string' ? a : a.name])
                .map((a) =>
                  _renderField(
                    fieldCache[typeof a === 'string' ? a : a.name],
                    a,
                    settingsContext.settings
                  )
                )
            )
          )}
        </>
      )}
      {activeFormLayout.form &&
        !activeFormLayout.form.groups &&
        activeFormLayout.form.fields &&
        _renderGroup(
          '',
          activeFormLayout.form.fields
            .filter((a) => fieldCache[typeof a === 'string' ? a : a.name])
            .map((a) =>
              _renderField(
                fieldCache[typeof a === 'string' ? a : a.name],
                a,
                settingsContext.settings
              )
            )
        )}
    </Box>
  );
};

export default TETOForm;
