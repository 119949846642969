import Edit from '@mui/icons-material/Edit';
import VisibilityRoundedIcon from '@mui/icons-material/VisibilityRounded';
import { Box, SxProps, Theme } from '@mui/material';
import { MessageContext } from '@teto/react-component-library-v2';
import { useAtom } from 'jotai';
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { Licenses, Permission } from 'teto-client-api';
import { ProcessScheduleHeader } from '../../../__generated__/graphql';
import ActionBar from '../../../components/ActionBar/ActionBar';
import SharedBreadCrumbModal from '../../../components/SharedStateComponents/SharedBreadCrumbModal';
import { ProcessScheduleSharedState } from '../../../components/SharedStateComponents/StateContainers/ProcessScheduleState';
import ActionButtonGridBuilderExtension from '../../../components/TETOGridGraphQL/GridBuilder/ActionButtonGridBuilderExtension';
import MainTetoGridGraphQL from '../../../components/TETOGridGraphQL/TETOMainGridGraphQL';
import GridCommonMobileButtons from '../../../components/TETOGridGraphQL/components/GridCommonMobileButtons/GridCommonMobileButtons';
import useGrid from '../../../components/TETOGridGraphQL/hooks/useGrid';
import { useGridBuilderFromView } from '../../../components/TETOGridGraphQL/hooks/useGridBuilder';
import TETOGridRefType from '../../../components/TETOGridGraphQL/types/TETOGridRefType';
import AuthContext from '../../../contexts/AuthContext';
import processScheduleSearchView from '../../../views/ProcessScheduleSearchView.yaml';

const RootQueryPath = 'processScheduleHeaders';
const PersistenceName = 'process-schedule-Search-grid';
const ALWAYS_PROJECT_COLUMNS: Array<string> = [
  'createdDate',
  'id',
  'item.itemCompanyId',
  'number',
  'project.displayName',
  'projectId',
  'spec.displayName',
  'specId',
];

const containerSx: SxProps<Theme> = (theme: Theme) => ({
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  [theme.breakpoints.down('md')]: {
    height: 'calc(100% - 3em)',
  },
});

const ProcessScheduleDetailsTab = () => {
  const authContext = useContext(AuthContext);
  const { ready, t } = useTranslation();
  const gridRef = useRef<TETOGridRefType | undefined>();
  const messageContext = useContext(MessageContext);
  const [processSchedule, setProcessSchedule] = useAtom(
    ProcessScheduleSharedState
  );

  const [configureInspectorOpen, setConfigureInspectorOpen] =
    useState<boolean>(false);

  const { canModify, canView } = useMemo(
    () => ({
      canModify:
        authContext.hasLicense(Licenses.TotalETOProfessional) &&
        authContext.hasPermission(
          Permission.Modify_Procurement_ProcessSchedules
        ),
      canView:
        authContext.hasLicense(Licenses.ReadOnlyProfessional) &&
        authContext.hasPermission(Permission.View_Procurement_ProcessSchedules),
    }),
    [authContext]
  );

  const _handleEdit = useCallback(
    (data: ProcessScheduleHeader) => {
      setProcessSchedule((prev) => ({
        ...prev,
        initialValues: {
          id: data?.id,
          number: data?.number,
          createdDate: data?.createdDate,
        },
        openFrom: 'other',
        open: true,
      }));
    },
    [setProcessSchedule]
  );

  const { gridBuilder, builderReady, error, hasError } = useGridBuilderFromView(
    processScheduleSearchView,
    RootQueryPath,
    (gb) =>
      gb.extension(
        new ActionButtonGridBuilderExtension([
          {
            componentName: canModify
              ? t('pages.processSchedule.editPS')
              : t('generic.viewDetail'),
            icon: canModify ? <Edit /> : <VisibilityRoundedIcon />,
            title: canModify
              ? t('pages.processSchedule.editPS')
              : t('generic.viewDetail'),
            onClick: ({ data }) => _handleEdit(data),
            color: 'primary',
            disabled: !canView,
          },
        ]),
        t
      )
  );

  const { gridProps } = useGrid(
    PersistenceName,
    `${RootQueryPath}.items`,
    (e) => messageContext.setError(e.message ?? e),
    t,
    ALWAYS_PROJECT_COLUMNS,
    gridBuilder,
    {
      filterAndSortMode: 'serverSide',
    }
  );

  const GridCommonBtns = GridCommonMobileButtons({
    refreshGrid: () => gridProps.refreshDataSource(),
    gridRef,
    setConfigureInspector: () => setConfigureInspectorOpen(true),
  });

  const rightChildren = useMemo(() => GridCommonBtns, [GridCommonBtns]);

  useEffect(() => {
    if (processSchedule.hasSavedOccurred) {
      gridProps.refreshDataSource();
      setProcessSchedule((prev) => ({
        ...prev,
        hasSavedOccurred: false,
      }));
    }
  }, [gridProps, processSchedule.hasSavedOccurred, setProcessSchedule]);

  return (
    <Box sx={containerSx}>
      <ActionBar rightChildren={rightChildren} withBottomNav />
      <MainTetoGridGraphQL
        builderReady={builderReady}
        configureInspector={configureInspectorOpen}
        customGridWrapSx={{ gridTemplateRows: 'unset' }}
        error={error}
        externalQueryProps={[]}
        gridProps={gridProps}
        hasError={hasError}
        header={{ hidden: true }}
        mobileGridOptions
        ready={ready}
        ref={gridRef}
        setConfigureInspector={setConfigureInspectorOpen}
        showEmptyRows
        showGroupSummaryRow={false}
        t={t}
        tableIdentifier={PersistenceName}
      />
      <SharedBreadCrumbModal />
    </Box>
  );
};

export default ProcessScheduleDetailsTab;
