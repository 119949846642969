import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import React, { useContext, useMemo } from 'react';
import SettingsContext from '../../../contexts/SettingsContext';
import EmptyDisplay from './EmptyDisplay';
import FormatterErrorWrapper from './FormatterErrorWrapper';
import FormatterProps from './FormatterProps';
import deepPropertyHelper from './deepPropertyHelpers';

dayjs.extend(utc);

const TimeFormatter = (props: FormatterProps) => {
  const settingsContext = useContext(SettingsContext);
  const {
    value,
    column: { name, emptyDisplay },
    data,
  } = props;

  const formattedValue = useMemo(() => {
    if (!settingsContext.settingsLoaded) return undefined;

    const prelim = value?.value ?? deepPropertyHelper(name, data);
    if (!prelim) return;

    return dayjs(prelim).format(settingsContext.settings.timeFormat);
  }, [data, name, settingsContext, value?.value]);

  const element = (
    <span>
      {!formattedValue ? (
        <EmptyDisplay placeHolder={emptyDisplay} />
      ) : (
        formattedValue
      )}
    </span>
  );

  return <FormatterErrorWrapper value={value}>{element}</FormatterErrorWrapper>;
};

export default TimeFormatter;
