import { Theme } from '@mui/material';
import {
  ETOTab,
  ETOTabs,
  OkCancelConfirmDialog,
} from '@teto/react-component-library-v2';

import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAtomValue } from 'jotai';
import useLocalStorage from 'use-local-storage';
import { ContactSharedState } from '../../../../SharedStateComponents/StateContainers/ContactState';
import { BreadCrumbSharedState } from '../../../../SharedStateComponents/StateContainers/BreadCrumbModalState';
import EditingState from '../../../types/EditingState';
import { InspectorActions } from '../../../types/InspectorActionTypes';
import InspectorTabPanel from './InspectorTabPanel';

type JSX = JSX.Element | null;
export interface IAddEditTab {
  tabName: string;
  tabLabel: string;
  tabIndex: number;
  tabPanel: JSX.Element;
  tabTitle: string;
  tabIcon?: JSX.Element;
  disabled?: boolean;
}

export type TAddEditTabs = IAddEditTab[];

const tabPanelSx = {
  borderTop: (theme: Theme) => `1px solid ${theme.palette.divider}`,
  pt: 1,
  pb: 1,
  display: 'flex',
};

interface EditableTabsProps<T>
  extends Pick<InspectorActions<T>, 'setEditingState'> {
  tabs: TAddEditTabs;
  editingState: EditingState;
  disableAutoSave?: boolean;
}

const EditableTabs = <T,>(props: EditableTabsProps<T>) => {
  const { tabs, editingState, setEditingState, disableAutoSave } = props;

  const { t } = useTranslation();
  const [activeIndex, setActiveIndex] = useLocalStorage(
    'custom-active-index',
    0
  );
  const [activePanelIndex, setActivePanelIndex] = useState(0);
  const [warnTabChanges, setWarnTabChanges] = useState(false);
  const [trackValue, setTrackValue] = useState<number>();
  const levels = useAtomValue(BreadCrumbSharedState);
  const contacts = useAtomValue(ContactSharedState);

  useEffect(() => {
    if (Array.isArray(levels) && levels.length > 1) {
      if (contacts.hasSavedOccurred) {
        setActiveIndex(2);
      }
    }
  }, [contacts.hasSavedOccurred, levels, setActiveIndex]);

  useEffect(() => {
    if (Array.isArray(levels) && levels.length === 1) {
      if (activeIndex === 2) {
        setActivePanelIndex(activeIndex);
      }
    }
  }, [activeIndex, contacts.hasSavedOccurred, levels, setActiveIndex]);

  const _changeTab = useCallback(
    (newValue: number) => {
      setActivePanelIndex(newValue);
      setEditingState({
        hasEdited: false,
        isEditing: false,
        tabError: false,
      });
    },
    [setEditingState]
  );

  const _handleTabsChange = useCallback(
    (e: React.ChangeEvent<unknown>, newValue: number) => {
      if (newValue === activePanelIndex) return;

      if (editingState.tabError) {
        setTrackValue(newValue);
        return setWarnTabChanges(true);
      }
      _changeTab(newValue);
      setActiveIndex(0);
    },

    [_changeTab, activePanelIndex, editingState.tabError, setActiveIndex]
  );

  return (
    <>
      <ETOTabs
        error={editingState.tabError}
        onChange={_handleTabsChange}
        TabIndicatorProps={{
          ...(editingState.tabError && {
            sx: {
              display: 'none',
            },
          }),
        }}
        value={activePanelIndex}
      >
        {tabs?.map((tb) => (
          <ETOTab
            disabled={tb.disabled}
            error={editingState.tabError}
            icon={tb.tabIcon}
            key={`${tb.tabLabel}-${tb.tabIndex}`}
            label={tb.tabLabel}
            sx={tb.tabIcon ? { flexDirection: 'row' } : {}}
            toolTipProps={{
              title: tb.disabled ? t('auth.viewPermission') : tb.tabTitle,
            }}
            value={tb.tabIndex}
          />
        ))}
      </ETOTabs>

      {tabs?.map((tb) => (
        <InspectorTabPanel
          customSx={tabPanelSx}
          index={tb.tabIndex}
          key={`${tb.tabLabel}-${tb.tabIndex}-panel`}
          value={activePanelIndex}
        >
          {tb.tabPanel}
        </InspectorTabPanel>
      ))}
      {warnTabChanges && disableAutoSave && (
        <OkCancelConfirmDialog
          content={t('dialogs.closeUnsavedForm.content')}
          onCancel={() => setWarnTabChanges(false)}
          onOk={() => {
            if (trackValue) {
              _changeTab(trackValue);
            } else {
              _changeTab(0);
            }
            setWarnTabChanges(false);
          }}
          open={warnTabChanges}
          title={t('dialogs.closeUnsavedForm.title')}
        />
      )}
    </>
  );
};

export default EditableTabs;
