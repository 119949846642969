import DescriptionIcon from '@mui/icons-material/Description';
import { ETOButton, MessageContext } from '@teto/react-component-library-v2';
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { Permission, getGraphQLClient } from 'teto-client-api';
import getErrors from '../../../../../api/graphQL/getErrors';

import AuthContext from '../../../../../contexts/AuthContext';
import AccordionList from '../../../components/AccordionList/AccordionList';
import PanelWithNewItemPopover from '../../../components/AddEditInspector/AddEditList/PanelWithNewItemPopover';
import EditingState from '../../../types/EditingState';
import NoRecordsFound from '../../components/NoRecordsFound/NoRecordsFound';
import AddEditNotes from './AddEditNotes';
import { nonConformanceNotesQuery } from './queries/nonConformanceNoteQuery';

interface NotesPanelProps {
  nonConformanceId: number | undefined;
  setHasASaveOccurred: React.Dispatch<React.SetStateAction<boolean>>;
  setIsCreatingNewItem: React.Dispatch<React.SetStateAction<boolean>>;
  // eslint-disable-next-line no-unused-vars
  handleEditingState: (value: { isValid: boolean; isEditing: boolean }) => void;
  editingState: EditingState;
}

const NotesPanel = (props: NotesPanelProps) => {
  const {
    nonConformanceId,
    setIsCreatingNewItem,
    setHasASaveOccurred,
    handleEditingState,
    editingState,
  } = props;

  const { t } = useTranslation();

  const authContext = useContext(AuthContext);
  const messageContext = useContext(MessageContext);
  const buttonRef = useRef<HTMLButtonElement | null>(null);

  const canAddNotes = useMemo(
    () =>
      authContext.hasPermission(
        Permission.Add_Manufacturing_NonConformances_Notes
      ),
    [authContext]
  );

  const [isNewNoteOpen, setIsNewNoteOpen] = useState<boolean>(false);
  const [selectedNoteItem, setSelectedNoteItem] =
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    useState<any | undefined>();
  const [nonConformanceNotes, setNonConformanceNotes] = useState<
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    { [key: string]: any }[]
  >([]);
  const [confirmAbandonForm, setConfirmAbandonForm] = useState<boolean>(false);

  useEffect(
    () => setIsCreatingNewItem(isNewNoteOpen),
    [isNewNoteOpen, setIsCreatingNewItem]
  );

  const _updateRecords = useCallback(() => {
    if (nonConformanceId) {
      getGraphQLClient()
        .performQuery(nonConformanceNotesQuery, {
          id: nonConformanceId,
        })
        .then((e) => {
          if (e.hasError()) {
            e.showAllSystemErrors(messageContext.setError);
            if (e.hasValidationErrors()) {
              const errors = getErrors(e.validationErrors);
              messageContext.setError(errors);
            }
            return;
          }
          setNonConformanceNotes(
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            e.data.nonConformanceNotes?.items as { [key: string]: any }[]
          );
        });
    }
  }, [messageContext, nonConformanceId]);

  useEffect(() => {
    _updateRecords();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nonConformanceId]);

  const listItems = useMemo(
    () =>
      nonConformanceNotes.map((nonConformanceNote) => ({
        ...nonConformanceNote,
        id: nonConformanceNote?.id,
        title: nonConformanceNote?.noteEmployee?.name ?? '-',
        subTitles: [`${t('generic.note')}: ${nonConformanceNote?.note}`],
      })),
    [nonConformanceNotes, t]
  );

  const _onNotesSaved = useCallback(() => {
    _updateRecords();
    setHasASaveOccurred(true);
    setIsNewNoteOpen(false);
  }, [_updateRecords, setHasASaveOccurred]);

  return (
    <PanelWithNewItemPopover
      addButtonRef={buttonRef}
      addItemButton={
        <ETOButton
          buttonProps={{
            ref: buttonRef,
          }}
          color="primary"
          disabled={!canAddNotes}
          onClick={() => setIsNewNoteOpen(true)}
          size="medium"
        >
          {t('pages.nonConformance.addNote')}
        </ETOButton>
      }
      editingState={editingState}
      isNewItemOpen={isNewNoteOpen}
      newItemComponent={
        nonConformanceId && (
          <AddEditNotes
            confirmAbandonForm={confirmAbandonForm}
            handleEditingState={handleEditingState}
            nonConformanceId={nonConformanceId}
            onRecordCountChanged={_updateRecords}
            onSaveSuccess={_onNotesSaved}
            record={undefined}
            setConfirmAbandonForm={setConfirmAbandonForm}
            setIsNewNoteOpen={setIsNewNoteOpen}
            setSelectedNoteItem={setSelectedNoteItem}
          />
        )
      }
      setConfirmAbandonForm={setConfirmAbandonForm}
      setIsNewItemOpen={() => setIsNewNoteOpen(false)}
      verticalPosition={254}
    >
      {nonConformanceNotes.length === 0 && <NoRecordsFound />}
      {nonConformanceNotes.length > 0 && nonConformanceId && (
        <AccordionList
          editingState={editingState}
          expandedComponent={
            <AddEditNotes
              confirmAbandonForm={confirmAbandonForm}
              handleEditingState={handleEditingState}
              nonConformanceId={nonConformanceId}
              onRecordCountChanged={_updateRecords}
              onSaveSuccess={_onNotesSaved}
              record={selectedNoteItem}
              setConfirmAbandonForm={setConfirmAbandonForm}
              setIsNewNoteOpen={setIsNewNoteOpen}
              setSelectedNoteItem={setSelectedNoteItem}
            />
          }
          expandedItem={selectedNoteItem}
          icon={<DescriptionIcon />}
          listItems={listItems}
          onItemExpandStateChanged={(noteItem) => setSelectedNoteItem(noteItem)}
          setConfirmAbandonForm={setConfirmAbandonForm}
        />
      )}
    </PanelWithNewItemPopover>
  );
};

export default NotesPanel;
