import SearchRoundedIcon from '@mui/icons-material/SearchRounded';
import {
  Box,
  Button,
  FormControlLabel,
  Popover,
  Radio,
  RadioGroup,
} from '@mui/material';
import { ETOCheckBox, ETOSelectField } from '@teto/react-component-library-v2';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  ProcessScheduleTemplate,
  ProcessScheduleTemplateOptions,
} from '../../../../../../../../__generated__/graphql';
import useGQLQuery from '../../../../../../../../api/graphQL/useGQLQuery';
import ProcessScheduleTemplateTree from '../../../../../../../ProcessScheduleTemplate/ProcessScheduleTemplateTree';
import FormDefinition from '../../../../../../../TETOForms/FormDefinition';
import { getProcessScheduleTemplates } from '../../../../../../../TETOForms/selects/selects';
import { radioContainerSx, radioSx } from '../styles';
import ExistingProcessScheduleSelectField from './ExistingProcessScheduleSelectField';

interface TemplateRadioGroupProps {
  form: FormDefinition;
  options: {
    value: string | undefined;
  };
}

const widthSx = {
  minWidth: '350px',
};

const TemplateRadioGroup: React.FC<TemplateRadioGroupProps> = ({
  form,
  options,
}) => {
  const { t } = useTranslation();

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [selectedPST, setSelectedPST] = useState<number | undefined>(
    form.values?.processScheduleTemplateID ?? null
  );

  const processScheduleTemplates = useGQLQuery<{
    processScheduleTemplates: { items: ProcessScheduleTemplate[] };
  }>(['processScheduleTemplates'], {
    queryString: getProcessScheduleTemplates.query,
    options: { refetchOnWindowFocus: false },
  });

  const _handlePSTClicked = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    setAnchorEl(e.currentTarget);
  };

  const _handlePSTClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  const _handlePSTSelect = (selected: number) => {
    setSelectedPST(selected);
    form.updateField('processScheduleTemplateId', selected);
    setAnchorEl(null);
  };
  return (
    <>
      <Box sx={radioContainerSx}>
        <RadioGroup
          name="addProcessScheduleTemplateOptions"
          onChange={(e) => {
            form.updateField(
              'addProcessScheduleTemplateOptions',
              e.target.value
            );
          }}
          sx={radioSx}
          value={options.value ?? ProcessScheduleTemplateOptions.UseParts}
        >
          <FormControlLabel
            control={<Radio />}
            label={`${t(
              'pages.processSchedule.processScheduleModal.options.usePartsTemplate'
            )}`}
            value={ProcessScheduleTemplateOptions.UseParts}
          />

          <Box>
            <FormControlLabel
              control={<Radio />}
              label={`${t(
                'pages.processSchedule.processScheduleModal.options.useSpecificTemplate'
              )}`}
              value={ProcessScheduleTemplateOptions.UseSpecific}
            />
            {options.value === ProcessScheduleTemplateOptions.UseSpecific && (
              <>
                <Box
                  sx={{
                    display: 'flex',
                  }}
                >
                  <ETOSelectField
                    disabled={
                      form.values.addProcessScheduleTemplateOptions !==
                      ProcessScheduleTemplateOptions.UseSpecific
                    }
                    error={
                      form?.validationErrors
                        ?.processScheduleTemplateId as string
                    }
                    handleChange={(e) => {
                      _handlePSTSelect(e.target.value as unknown as number);
                    }}
                    itemNameSelector={(item) => item.name}
                    items={
                      processScheduleTemplates.data?.processScheduleTemplates
                        ?.items ?? []
                    }
                    itemValueSelector={(item) => item.id}
                    label={t(
                      'entities:ProcessScheduleTemplate.ProcessScheduleTemplate'
                    )}
                    name="processScheduleTemplate"
                    size="small"
                    sx={widthSx}
                    value={selectedPST}
                  />
                  <Button
                    color="primary"
                    disabled={
                      form.values.addProcessScheduleTemplateOptions !==
                      ProcessScheduleTemplateOptions.UseSpecific
                    }
                    onClick={(e) => _handlePSTClicked(e)}
                    size="small"
                    variant="text"
                  >
                    <SearchRoundedIcon />
                  </Button>
                </Box>
              </>
            )}
          </Box>

          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <FormControlLabel
              control={<Radio />}
              label={`${t(
                'pages.processSchedule.processScheduleModal.options.copyFromExistingPS'
              )}`}
              value={
                ProcessScheduleTemplateOptions.CopyFromExistingProcessSchedule
              }
            />
            {options.value ===
              ProcessScheduleTemplateOptions.CopyFromExistingProcessSchedule && (
              <>
                <ExistingProcessScheduleSelectField
                  error={
                    form.validationErrors?.existingProcessScheduleId as string
                  }
                  handleChange={(e) => {
                    form.updateField(
                      'existingProcessScheduleId',
                      e.target.value
                    );
                  }}
                />
                <ETOCheckBox
                  disabled={
                    form.values.addProcessScheduleTemplateOptions !==
                    ProcessScheduleTemplateOptions.CopyFromExistingProcessSchedule
                  }
                  handleChange={(e) => {
                    form.updateField('copyAllDates', e.target.checked);
                  }}
                  label={`${t(
                    'pages.processSchedule.processScheduleModal.options.copyDates'
                  )}`}
                  name="copyAllDates"
                  value={form.values.copyAllDates}
                />
              </>
            )}
          </Box>
        </RadioGroup>
      </Box>
      <Popover
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        id="pst-btn"
        onClose={() => _handlePSTClose()}
        open={open}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <ProcessScheduleTemplateTree
          onPSTClicked={(val) => _handlePSTSelect(val)}
        />
      </Popover>
    </>
  );
};

export default TemplateRadioGroup;
