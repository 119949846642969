const getSystemAndEmployeeSettingsQuery = `
query getSystemAndEmployeeSettings {
  employeeSettings(includeSystemSettings: true) {
    webDateFormat
    webTimeFormat
    webTimeFormatPrecise
  }
  systemSettings {
    correctDatesonPO
    addItemsPriorToSalesRelease
    allowImmediateStockTransfer
    approversCanManageTime
    baseCurrencyFormat
    baseCurrencyName
    baseCurrencySymbol
    dropShipmentsForInventory
    dropShipmentsBypassReceiving
    employeeMFAMode
    employeeStayLoggedInDuration
    geotaggingMode
    inventoryOrderLevel
    inventoryProject
    inventoryReOrderCriteria
    inventorySpecID
    kioskAuthTimeout
    kioskEmployeeSessionTimeout
    limitAccountingTermsToList
    limitFOB
    limitShippingAgentsToList
    gridMaxRows
    notApprovedSupplierWarning
    payPeriodsCreateAutomatically
    pOItemsForClosedMachines
    pOUpdatePrices
    punchInEndRoundingInterval
    punchInEndRoundingMode
    punchInStartRoundingInterval
    punchInStartRoundingMode
    punchInUseRoundedHours
    reasonWhenSuppressing
    tEHideHourFactorField
    timeSheetStartDayOfWeek
    webDateFormat
    webTimeFormat
    webTimeFormatPrecise
    procureIfRawMaterialMatchesPart
    customProcessNumber
    controlTimecardHourFactor
    notificationSMTPUseForEmployeeEmails
  }
}`;

export default getSystemAndEmployeeSettingsQuery;
