import { Box, Grid } from '@mui/material';
import {
  ETODateField,
  ETOSelectField,
  ETOTextField,
} from '@teto/react-component-library-v2';
import { t } from 'i18next';
import React, { useContext, useEffect } from 'react';

import { useFormikContext } from 'formik';
import { Employee } from 'teto-client-api';

import { employeesQuery } from '../../../../../api/commonQueries';
import useBuildQuery from '../../../../../api/graphQL/useBuildQuery';
import SettingsContext from '../../../../../contexts/SettingsContext';
import { InspectorActions } from '../../../types/InspectorActionTypes';

export interface INotesForm {
  id: number;
  note: string;
  noteDate: Date;
  noteEmployee: {
    id: number;
    name?: string;
  };
}

interface NotesFormProps
  extends Pick<InspectorActions<INotesForm>, 'setEditingState'> {
  canModifyNote: boolean;
}

interface EmployeeApiResponse {
  employees: {
    items: Employee[];
  };
}

const NotesForm = (props: NotesFormProps) => {
  const { canModifyNote, setEditingState } = props;

  const formik = useFormikContext<INotesForm>();
  const settingsContext = useContext(SettingsContext);

  const query = useBuildQuery<EmployeeApiResponse>(['employees'], {
    queryString: employeesQuery,
    variables: {},
  });

  useEffect(() => {
    setEditingState({
      isEditing: formik.dirty,
      hasEdited: formik.dirty,
      tabError: !formik.isValid,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.dirty, formik.isSubmitting, formik.isValid]);

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Box m={0} mb={0}>
            <ETODateField
              disabled={!canModifyNote}
              error={formik.errors?.noteDate as unknown as string}
              handleChange={(val) => formik.setFieldValue('noteDate', val)}
              inputFormat={settingsContext.settings.dateFormat}
              label={t('generic.date')}
              name="date"
              size="small"
              value={formik.values?.noteDate}
            />
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Box m={0} mb={0}>
            <ETOSelectField
              disableClearable
              disabled={!canModifyNote}
              error={formik.errors?.noteEmployee?.id}
              handleChange={(e) =>
                formik.setFieldValue('noteEmployee.id', e.target.value)
              }
              // handleChange={formik.handleChange}
              itemDisabledSelector={(item: Partial<Employee>) => !item?.active}
              itemNameSelector={(item: Partial<Employee>) =>
                `${!item?.active ? '(inactive) ' : ''}${
                  item?.employeeNumber
                } - ${item?.firstName} ${item?.lastName}`
              }
              items={query?.data?.employees?.items ?? []}
              itemValueSelector={(item: Partial<Employee>) => item?.id}
              label={t('entities:Employee.Employee')}
              name="noteEmployee.id"
              size="small"
              value={formik.values?.noteEmployee.id}
            />
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Box m={0} mb={0}>
            <ETOTextField
              disabled={!canModifyNote}
              error={formik.errors.note}
              handleChange={formik.handleChange}
              label={t('generic.note')}
              multiline
              name="note"
              rows={2}
              size="small"
              value={formik.values?.note}
            />
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

export default NotesForm;
