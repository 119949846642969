import { MessageContext } from '@teto/react-component-library-v2';
import React, { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { TetoGridGraphqlRefProps } from '../../TETOGridGraphQL/types/TetoGridGraphqlProps';

const useExportData = (
  shouldExportData: number | undefined,
  setShouldExportData: React.Dispatch<React.SetStateAction<number | undefined>>,
  gridRef: React.MutableRefObject<TetoGridGraphqlRefProps | undefined>
) => {
  const { t } = useTranslation();
  const messageContext = useContext(MessageContext);

  useEffect(() => {
    if (shouldExportData?.toString()) {
      const gr = gridRef.current;
      // eslint-disable-next-line no-unused-expressions
      gr
        ? gr.exportData()
        : messageContext.setError(
            t('generic.message.failExportGrid') as string
          );
      setShouldExportData(undefined);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [t, shouldExportData]);
};

export default useExportData;
