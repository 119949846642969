import { gql } from '../../../../__generated__';

const purchaseOrderDetailsQuery = gql(`

  query purchaseOrderDetailQuery($purchaseOrderId: Int!) {
    purchaseOrder: purchaseOrderHeader(id: $purchaseOrderId) {
      purchaseDateRevised
      purchaseEmailed
      purchaseDateRequired
      purchaseOrderDetails {
        dateRevised
        itemId
        item {
          description
        }
        purchaseSupplierDescription
        purchaseQty
        id
        destInventoryLoc {
          location
        }
      }
      buyerId
      id
      compoundTax
      purchaseFob
      fobnotes
      pONotes {
        id
      }
      port
      purchaseActive
      purchaseCurrRate
      purchaseCurrId
      purchaseCurr {
        name
        symbol
        format
      }
      purchaseMisc
      purchaseDate
      purchaseNotes
      purchaseNotes2
      purchaseNotes3
      purchasePrinted
      purchaseRev
      purchaseShip
      purchaseShipTo {
        id
        name
      }
      purchaseSupplier {
        id
        name
        defaultEmailAddress
      }
      purchaseSupplierId
      purchaseTax1
      purchaseTax1GLAccount {
        id
        displayName
      }
      purchaseTax1GLAccountId
      purchaseTax1Name
      purchaseTax2
      purchaseTax2GLAccount {
        id
        displayName
      }
      purchaseTax2GLAccountId
      purchaseTax2Name
      purchaseTerms
      purchaseShip

      taxes {
        tax1Amount
        tax2Amount
      }
      totals {
        detailTotal
        subtotal
      }
      custom1
      custom2
      custom3
      custom4
      custom5
      custom6
      custom7
      custom8
    }
  }

`);

export default purchaseOrderDetailsQuery;
