import { MessageContext } from '@teto/react-component-library-v2';
import React, { useContext, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import NonConformanceQueue from '../../views/NonConformanceQueueView.yaml';
import ActionBar from '../ActionBar/ActionBar';
import { GridResponsiveSettingsBuilder } from '../TETOGridGraphQL/GridResponsiveSettingsBuilder';
import MainTetoGridGraphQL from '../TETOGridGraphQL/TETOMainGridGraphQL';
import GridCommonMobileButtons from '../TETOGridGraphQL/components/GridCommonMobileButtons/GridCommonMobileButtons';
import useGrid from '../TETOGridGraphQL/hooks/useGrid';
import { useGridBuilderFromView } from '../TETOGridGraphQL/hooks/useGridBuilder';
import { MandatoryFilters } from '../TETOGridGraphQL/types/MandatoryFilterTypes';
import TETOGridRefType from '../TETOGridGraphQL/types/TETOGridRefType';

const PersistenceName = 'open-nonconformances';
const RootQueryPath = 'nonConformances';

const defaultGridResponsiveSettings = GridResponsiveSettingsBuilder.up({
  xs: {
    disableGroupByToolbar: true,
    disableGroupColumn: false,
    disableInlineEdit: false,
    disableMobileCols: false,
    enableFiltering: false,
    inputVariant: 'filled',
    renderAggregatesInGroupLabel: false,
    rowHeight: null,
    showEmptyRows: false,
    showHoverRows: false,
  },
  md: {
    disableGroupByToolbar: false,
    disableGroupColumn: false,
    disableInlineEdit: false,
    disableMobileCols: true,
    enableFiltering: true,
    inputVariant: 'standard',
    renderAggregatesInGroupLabel: false,
    rowHeight: 35,
    showEmptyRows: true,
    showHoverRows: true,
  },
});

interface OpenNCGridProps {
  nCFilters?: MandatoryFilters;
}

const OpenNCGrid = (props: OpenNCGridProps) => {
  const { nCFilters } = props;
  const { t, ready } = useTranslation();

  const messageContext = useContext(MessageContext);

  const gridRef = useRef<TETOGridRefType>();

  const [configureInspectorOpen, setConfigureInspectorOpen] =
    useState<boolean>(false);

  const mandatoryFilters = useMemo(() => {
    const filters: MandatoryFilters = [...(nCFilters ?? [])];

    return filters;
  }, [nCFilters]);

  const { gridBuilder, builderReady, error, hasError } = useGridBuilderFromView(
    NonConformanceQueue,
    RootQueryPath
  );

  const { gridProps } = useGrid(
    PersistenceName,
    `${RootQueryPath}.items`,
    (e) => messageContext.setError(e.message ?? e),
    t,
    [],
    gridBuilder,
    {
      filterAndSortMode: 'serverSide',
      mandatoryFilter: mandatoryFilters,
    }
  );

  const GridCommonBtns = GridCommonMobileButtons({
    refreshGrid: () => gridProps.refreshDataSource(),
    gridRef,
    setConfigureInspector: () => setConfigureInspectorOpen(true),
  });

  const rightChildren = useMemo(() => GridCommonBtns, [GridCommonBtns]);

  return (
    <>
      <ActionBar rightChildren={rightChildren} />
      <MainTetoGridGraphQL
        builderReady={builderReady}
        configureInspector={configureInspectorOpen}
        error={error}
        externalQueryProps={[]}
        gridProps={gridProps}
        hasError={hasError}
        header={{
          hidden: true,
        }}
        mobileGridOptions
        ready={ready}
        ref={gridRef}
        responsiveSettings={defaultGridResponsiveSettings}
        setConfigureInspector={setConfigureInspectorOpen}
        showEmptyRows
        showGroupSummaryRow={false}
        t={t}
        tableIdentifier={PersistenceName}
        {...gridProps}
      />
    </>
  );
};

export default OpenNCGrid;
