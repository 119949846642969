import { gql } from '../../../../../../__generated__';

const projectsQuery = `query projects{
  projects(order: {id: ASC}) {
    items {
      displayName
      id
    }
  }
}`;

const jobsQuery = `query specs ($projectId: Int!) {
  specs(where: {projectId: {eq: $projectId}, active: {eq:true}}, order: {displayName:ASC}) {
    items {
      displayName
      id
      active
      masterQueue {
        closingRelease
      }
    }
  }
}`;

const nonConformanceSourcesQuery = `query nonConformanceSources {
  nonConformanceSources (order: {description: ASC}) {
    items {
      id
      description      
    }
  }
}`;

const nonConformanceOriginsQuery = `query nonConformanceOrigins {
  nonConformanceOrigins (order: {description: ASC}){
    items {
      id
      description
    }
  }
}`;

const nonConformanceQuery = gql(`query nonConformanceQuery($id: Int!) {
  nonConformance(id: $id) {    
    id
    creationDate
    title
    description
    sourceId
    originId
    projectId
    specId
    project {        
      company {
        name  # customer
      }
    }
    itemId
    viewDetails {
      partNumber
    }
    item {
      id
      itemCompanyId
    }
    purchaseOrderId
    purchaseOrder {
      id
      purchaseSupplier {
        name
      }
    }
    correctivePreventiveAction
    qualityFollowUp
    recommendedInterim
    quantity
    quantityRejected  
    rootCause 
    custom1
    custom2
    custom3
    custom4
    custom5
    custom6
    custom7
    custom8
    resolved    
  }
}`);

const searchPurchaseOrderHeadersByPONumberQuery = `query searchPurchaseOrderHeadersByPONumber ($searchTerm: String) {
  searchPurchaseOrderHeadersByPONumber (take: 5, order: { id: DESC } searchTerm: $searchTerm) {
    items {
      id
      purchaseSupplier {
        id
        name
      }
    }
  }
}`;

const partsNumberQuery = `query engItemMasters($itemCompanyId: String) {
  engItemMasters(take: 10, where: { itemCompanyId: { startsWith: $itemCompanyId } }) {
    items {
      id
      itemCompanyId
    }
  }
}`;

const purchaseOrderQuery = `query searchPurchaseOrderHeadersByPONumber($searchTerm: String!) {
  searchPurchaseOrderHeadersByPONumber(take: 10, searchTerm: $searchTerm) {
   items {
     id
       purchaseSupplier {
         name
       }
       buyer {
        name
       }
       purchaseNotes
       purchaseNotes2
       purchaseNotes3
       purchaseOrderDetails {
         purchaseSupplierItem
       }
     }
   }
}`;

// eslint-disable-next-line object-curly-newline
export {
  jobsQuery,
  nonConformanceOriginsQuery,
  nonConformanceQuery,
  nonConformanceSourcesQuery,
  partsNumberQuery,
  projectsQuery,
  purchaseOrderQuery,
  searchPurchaseOrderHeadersByPONumberQuery,
};
