import { Box, SxProps, Theme, useMediaQuery, useTheme } from '@mui/material';
import { ETOButton } from '@teto/react-component-library-v2';
import React, { useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import InventoryDetailsGrid from '../../../../../pages/InventoryPage/InventoryDetailsTab/InventoryDetailsGrid';
import { GridResponsiveSettingsBuilder } from '../../../../TETOGridGraphQL/GridResponsiveSettingsBuilder';
import { MandatoryFilters } from '../../../../TETOGridGraphQL/types/MandatoryFilterTypes';
import TETOGridRefType from '../../../../TETOGridGraphQL/types/TETOGridRefType';
import useGridStyles from '../../../../TetoGrid/GridStyles';
import { tetoContainerSx } from '../../../../TetoGrid/TetoContainer';

const rootSx: SxProps<Theme> = {
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  justifyContent: 'flex-start',
  pt: 2,
  flex: 1,
};

const containerSx: SxProps<Theme> = {
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
};

interface InventoryDetailsPanelProps {
  itemCompanyId: number | string;
}

const PERSISTANCE_NAME = 'inventory-Details-panel';
const InventoryDetailsPanel = (props: InventoryDetailsPanelProps) => {
  const { itemCompanyId } = props;
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const [configureInspector, setConfigureInspector] = useState(false);
  const gridRef = useRef<TETOGridRefType>(null);
  const { t } = useTranslation();
  const filters = useMemo(() => {
    const _filters: MandatoryFilters = [
      {
        name: 'and[1].itemId',
        operator: 'equalTo',
        type: 'number' as const,
        value: itemCompanyId,
      },
    ];

    return _filters;
  }, [itemCompanyId]);
  const defaultGridResponsiveSettings = GridResponsiveSettingsBuilder.up({
    xs: {
      disableInlineEdit: false,
      enableFiltering: false,
      disableGroupByToolbar: true,
      disableMobileCols: false,
      rowHeight: null,
      disableGroupColumn: true,
      showEmptyRows: true,
      filterRowHeight: 0,
      inputVariant: 'filled',
      showHoverRows: false,
      renderAggregatesInGroupLabel: true,
    },
    md: {
      disableGroupByToolbar: true,
      disableGroupColumn: false,
      disableInlineEdit: false,
      disableMobileCols: true,
      enableFiltering: true,
      filterRowHeight: 40,
      inputVariant: 'standard',
      renderAggregatesInGroupLabel: false,
      rowHeight: 35,
      showEmptyRows: true,
      showHoverRows: true,
    },
  });

  return (
    <Box sx={containerSx}>
      {isMobile && (
        <ETOButton
          color="primary"
          customSx={{ mb: 2, alignSelf: 'end', width: 'max-content' }}
          onClick={() => setConfigureInspector(true)}
          size="medium"
        >
          {t('generic.configure')}
        </ETOButton>
      )}

      <Box sx={[rootSx, tetoContainerSx, useGridStyles, { p: 0 }]}>
        <InventoryDetailsGrid
          configureInspector={configureInspector}
          filters={filters}
          persistanceName={PERSISTANCE_NAME}
          ref={gridRef}
          responsiveSettings={defaultGridResponsiveSettings}
          setConfigureInspector={setConfigureInspector}
        />
      </Box>
    </Box>
  );
};

export default InventoryDetailsPanel;
