import { Box, Skeleton, SxProps, Theme } from '@mui/material';
import { MessageContext } from '@teto/react-component-library-v2';
import { isEmpty } from 'lodash';
import React, { useContext, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Permission } from 'teto-client-api';
import { RfqDetail, RfqHeader } from '../../../../../__generated__/graphql';
import useGQLQuery from '../../../../../api/graphQL/useGQLQuery';
import AuthContext from '../../../../../contexts/AuthContext';
import AddEditFormDetailsPanel from '../../../../Inspectors/components/AddEditInspector/components/AddEditFormDetailsPanel';
import EditingState from '../../../../Inspectors/types/EditingState';
import { InspectorActions } from '../../../../Inspectors/types/InspectorActionTypes';
import { PanelMode, PanelState } from '../../../../Inspectors/types/PanelState';
import TETOGridRefType from '../../../../TETOGridGraphQL/types/TETOGridRefType';
import getRFQHeaderQuery from '../../queries/getRFQHeaderQuery';
import { RFQ } from '../../types/RFQ';
import RFQCreateHeaderForm from '../RFQHeaderForm/RFQCreateHeaderForm/RFQCreateHeaderForm';
import RFQEditHeaderForm from '../RFQHeaderForm/RFQEditHeaderForm/RFQEditHeaderForm';
import RFQActionBar from './RFQActionBar/RFQActionBar';
import RFQDetailGrid from './RFQDetailGrid/RFQDetailGrid';
import RDGSelectedType from '../../../../TetoGrid/types/RDGSelectedType';

type IPanelActions = Omit<InspectorActions<RFQ>, 'setCurrentValues'> & {
  setPanelState: React.Dispatch<React.SetStateAction<PanelState>>;
};

type RFQWithRequiredDate = Partial<RFQ> & {
  requiredDateMethod: number;
};

interface RFQsDetailsPanelProps extends IPanelActions {
  editingState: EditingState;
  panelMode: PanelMode;
  initialValues: Partial<RFQ> | undefined;
}

const contentSx: SxProps<Theme> = {
  display: 'flex',
  flexDirection: 'column',
  overflowY: 'auto',
  pt: 1,
  height: '100%',
};

const panelSx: SxProps<Theme> = {
  rowGap: 0,
  paddingTop: 0,
};

const RFQsDetailsPanel = (props: RFQsDetailsPanelProps) => {
  const {
    editingState,
    initialValues,
    panelMode,
    setEditingState,
    setHasASaveOccurred,
    setIsCreatingNewItem,
    setPanelState,
  } = props;

  const { t } = useTranslation();
  const messageContext = useContext(MessageContext);
  const authContext = useContext(AuthContext);
  const gridRef = useRef<TETOGridRefType>(null);

  const [dataLoading, setDataLoading] = useState<boolean>(true);
  const [dataLength, setDataLength] = useState<number>(0);
  const [rFQ, setRFQ] = useState<Partial<RFQWithRequiredDate>>({});
  const [supplierCount, setSupplierCount] = useState<number>(0);
  const [refreshGrid, setRefreshGrid] = useState<boolean>(false);
  const [selectedRows, setSelectedRows] = useState<
    RDGSelectedType | undefined
  >();
  const [submitCreateForm, setSubmitCreateForm] = useState<boolean>(false);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [configureInspectorOpen, setConfigureInspectorOpen] =
    useState<boolean>(false);

  const rFQPermissions = useMemo(() => {
    const readOnly = authContext.hasReadOnlyLicense();
    const canModify = authContext.hasPermission(
      Permission.Modify_Procurement_RFQs_RFQHeader
    );
    const canAdd = authContext.hasPermission(
      Permission.Add_Procurement_RFQs_RFQHeader
    );
    const modifyPerm = () => {
      if (panelMode === 'create') {
        return canAdd;
      }
      if (readOnly) {
        return false;
      }
      if (panelMode === 'edit') {
        return canModify;
      }
      return true;
    };
    return {
      canAdd,
      canModify: modifyPerm(),
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authContext, panelMode]);

  const [refreshQuery, setRefreshQuery] = useState<boolean>(false);

  useGQLQuery(['rFQHeader', initialValues?.id, refreshQuery], {
    queryString: getRFQHeaderQuery,
    variables: {
      id: initialValues?.id,
    },
    callback: (resp) => {
      if ('rFQHeader' in resp) {
        setRFQ(resp.rFQHeader);
        setRefreshQuery(false);

        if (panelMode !== 'edit') {
          setPanelState({
            id: initialValues?.id,
            mode: 'edit',
          });
        }
      } else {
        messageContext.setError(t('generic.message.noDataFound'));
      }
    },
    options: {
      enabled: Boolean(initialValues?.id),
    },
  });

  const rFQDetailItems = useMemo(
    () =>
      rFQ?.details?.map((detail: RfqDetail) => ({
        id: detail.id,
        itemMasterDescription: detail?.item?.description,
        entityDescription: detail?.rFQSupplierDescription,
      })),
    [rFQ?.details]
  );

  return (
    <AddEditFormDetailsPanel
      canAdd={rFQPermissions.canAdd}
      canEdit={rFQPermissions.canModify}
      editingState={editingState}
      isSubmitting={isSubmitting}
      panelMode={panelMode}
      setIsCreatingNewItem={setIsCreatingNewItem}
      setSubmitForm={setSubmitCreateForm}
      sx={panelSx}
    >
      {panelMode === 'create' && (
        <RFQCreateHeaderForm
          setEditingState={setEditingState}
          setHasASaveOccurred={setHasASaveOccurred}
          setIsSubmitting={setIsSubmitting}
          setPanelState={setPanelState}
          setRFQ={setRFQ}
          setSubmitForm={setSubmitCreateForm}
          submitForm={panelMode === 'create' && submitCreateForm}
        />
      )}
      {panelMode === 'edit' && (
        <>
          <RFQActionBar
            dataLength={dataLength}
            dataLoading={dataLoading}
            gridRef={gridRef}
            hasItems={rFQ?.details?.length > 0}
            rFQDetails={rFQDetailItems}
            rFQId={rFQ?.id as number}
            selectedRows={selectedRows}
            setConfigureInspectorOpen={setConfigureInspectorOpen}
            setRefreshGrid={setRefreshGrid}
            setRefreshQuery={setRefreshQuery}
            supplierCount={supplierCount}
          />

          <Box sx={contentSx}>
            <Box>
              {!isEmpty(rFQ) ? (
                <RFQEditHeaderForm
                  disabled={!rFQPermissions.canModify}
                  rFQ={rFQ as RfqHeader}
                  setResetGrid={setRefreshGrid}
                  setRFQ={setRFQ}
                  setSupplierCount={setSupplierCount}
                />
              ) : (
                <Skeleton height={600} width="100%" />
              )}
            </Box>
            <Box
              sx={{
                flexGrow: 1,
                flexShrink: 1,
              }}
            >
              <RFQDetailGrid
                configureInspector={configureInspectorOpen}
                ref={gridRef}
                refreshGrid={refreshGrid}
                rFQId={initialValues?.id ?? rFQ.id}
                selectedRows={selectedRows}
                setConfigureInspector={setConfigureInspectorOpen}
                setDataLength={setDataLength}
                setDataLoading={setDataLoading}
                setHasASaveOccurred={setHasASaveOccurred}
                setRefreshGrid={setRefreshGrid}
                setRefreshQuery={setRefreshQuery}
                setSelectedRows={setSelectedRows}
              />
            </Box>
          </Box>
        </>
      )}
    </AddEditFormDetailsPanel>
  );
};

export default RFQsDetailsPanel;
