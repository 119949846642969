import { OkCancelConfirmDialog } from '@teto/react-component-library-v2';
import React from 'react';
import { useTranslation } from 'react-i18next';
import EditingState from '../../../types/EditingState';

interface CloseInspectorConfirmationProps {
  editingState: EditingState;
  // eslint-disable-next-line no-unused-vars
  onClose?: (hasEdited: boolean) => void;
  needsCloseConfirmation: boolean;
  // eslint-disable-next-line no-unused-vars
  setNeedsCloseConfirmation: (payload: boolean) => void;
}

const CloseInspectorConfirmation = (props: CloseInspectorConfirmationProps) => {
  const {
    editingState,
    onClose,
    needsCloseConfirmation,
    setNeedsCloseConfirmation,
  } = props;

  const { t } = useTranslation();
  const handleCancel = () => {
    setNeedsCloseConfirmation(false);
  };

  const handleOk = () => {
    setNeedsCloseConfirmation(false);
    if (onClose) {
      onClose(editingState.hasEdited);
    }
  };

  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {needsCloseConfirmation && (
        <OkCancelConfirmDialog
          content={t('dialogs.closeUnsavedForm.content')}
          onCancel={handleCancel}
          onOk={handleOk}
          open={needsCloseConfirmation}
          title={t('dialogs.closeUnsavedForm.title')}
        />
      )}
    </>
  );
};

export default CloseInspectorConfirmation;
