import { Box, Grid, SxProps, Theme } from '@mui/material';
import {
  ButtonStrip,
  ConfirmDialogs,
  ETODateField,
  ETOSelectField,
  ETOTextField,
  MessageContext,
  OkCancelConfirmDialog,
} from '@teto/react-component-library-v2';
import dayjs, { Dayjs } from 'dayjs';
import { useFormik } from 'formik';
import React, { useCallback, useContext, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { getGraphQLClient } from 'teto-client-api';
import * as Yup from 'yup';
// eslint-disable-next-line import/no-unresolved
import { TypedDocumentNode } from '@graphql-typed-document-node/core';
import { Employee } from '../../../../__generated__/graphql';
import { getAllEmployeesQuery } from '../../../../api/commonQueries';
import getErrors from '../../../../api/graphQL/getErrors';

import useGQLQuery from '../../../../api/graphQL/useGQLQuery';
import { REQUIRED_MESSAGE } from '../../../../constants/strings/strings';
import AuthContext from '../../../../contexts/AuthContext';
import SettingsContext from '../../../../contexts/SettingsContext';
import EditingState from '../../types/EditingState';
import AutoSave from '../AddEditInspector/AutoSave/AutoSave';

export interface CustomInput {
  mode: 'edit' | 'add' | 'both';
  [key: string]: string;
}

const contentSx: SxProps<Theme> = {
  display: 'flex',
  flexDirection: 'column',
  flexGrow: 1,
  flexShrink: 1,
  flexWrap: 'nowrap',
  justifyContent: 'flex-start',
  overflowY: 'unset',
  padding: 2,
  rowGap: 1,
};

const rootSx: SxProps<Theme> = {
  border: (theme) => `1px solid ${theme.palette.grey[400]}`,
  borderRadius: (theme) => theme.spacing(0.5),
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  width: '100%',
};

const NoteValidationSchema = Yup.object().shape({
  id: Yup.number(),
  employeeId: Yup.number().required(REQUIRED_MESSAGE),
  noteDate: Yup.date().typeError('Invalid date').required(REQUIRED_MESSAGE),
  note: Yup.string().optional(),
});

interface CommonAddEditNoteProps {
  addMutation: string | TypedDocumentNode;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  record?: any;
  permissions: {
    canAddNotes: boolean;
    canDeleteNotes: boolean;
    canModifyNotes: boolean;
  };
  deleteMutation: string | TypedDocumentNode;
  entityIdValue: number;
  entityName: string;
  onSaveSuccess: () => void;
  onRecordCountChanged: () => void;
  // eslint-disable-next-line no-unused-vars
  setPanelEditingState: (editingState: EditingState) => void;
  confirmAbandonForm: boolean;
  setConfirmAbandonForm: React.Dispatch<React.SetStateAction<boolean>>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setSelectedNoteItem: React.Dispatch<any>;
  setIsNewNoteOpen: React.Dispatch<React.SetStateAction<boolean>>;
  updateMutation: string | TypedDocumentNode;
  customInputs?: CustomInput;
}

interface Note {
  employee?: Employee;
  id: number;
  note: string;
  noteDate: Dayjs;
  [x: `${string}Id`]: number;
}

const CommonAddEditNote = (props: CommonAddEditNoteProps) => {
  const {
    addMutation,
    confirmAbandonForm,
    deleteMutation,
    entityIdValue,
    entityName,
    onRecordCountChanged,
    onSaveSuccess,
    permissions,
    record,
    setConfirmAbandonForm,
    setIsNewNoteOpen,
    setPanelEditingState,
    setSelectedNoteItem,
    updateMutation,
    customInputs,
  } = props;
  const { t } = useTranslation();

  const authContext = useContext(AuthContext);
  const messageContext = useContext(MessageContext);
  const settingsContext = useContext(SettingsContext);

  const mode = record ? 'edit' : 'add';

  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState<boolean>(false);

  const employees = useGQLQuery<{
    employees: {
      items: Employee[];
    };
  }>(['employees'], {
    queryString: getAllEmployeesQuery,
    options: { refetchOnMount: false, refetchOnWindowFocus: false },
  });

  const formik = useFormik<Note>({
    enableReinitialize: false,
    validationSchema: NoteValidationSchema,
    initialValues: {
      employeeId: record?.employee.id ?? authContext.user?.id,
      id: record?.id ?? 0,
      note: record?.note ?? '',
      noteDate: record?.noteDate ?? dayjs(),
    },
    onSubmit: (values, actions) => {
      _submitForm(values, actions);
    },
  });

  const mutationParams = useMemo(() => {
    const input =
      mode === 'add'
        ? {
            [`${entityName.charAt(0).toLowerCase() + entityName.slice(1)}Id`]:
              entityIdValue,
            noteDate: formik.values.noteDate,
            employeeId: formik.values.employeeId,
            note: formik.values.note,
          }
        : {
            [`${entityName.charAt(0).toLowerCase() + entityName.slice(1)}Id`]:
              entityIdValue,
            noteDate: formik.values.noteDate,
            employeeId: formik.values.employeeId,
            id: formik.values.id,
            note: formik.values.note,
          };
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const mappedInputs: any = {};
    if (customInputs?.mode === mode || customInputs?.mode === 'both') {
      Object.keys(input).forEach((key) => {
        if (customInputs[key] === '') return;
        const customKey = customInputs![key] || key;
        mappedInputs[customKey] = input[key];
      });
    }
    return Object.keys(mappedInputs).length ? mappedInputs : input;
  }, [
    customInputs,
    entityIdValue,
    entityName,
    formik.values.employeeId,
    formik.values.id,
    formik.values.note,
    formik.values.noteDate,
    mode,
  ]);

  const _submitForm = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (values: Note, actions: any) =>
      getGraphQLClient()
        .performMutation(mode === 'edit' ? updateMutation : addMutation, {
          input: { ...mutationParams },
        })
        .then((d) => {
          if (d.hasError()) {
            if (d.hasSystemErrors()) {
              messageContext.setError(getErrors(d.systemErrors));
            }
            if (d.hasValidationErrors()) {
              const { input } = d.validationErrors;
              formik.setErrors(input);
            }
            return;
          }
          const { data } = d;
          if (mode === 'edit') {
            if (data[`update${entityName}Note`].id) {
              onSaveSuccess();
              messageContext.setSuccess(t('generic.message.noteUpdate'));
            }
          } else if (data[`add${entityName}Note`].id) {
            messageContext.setSuccess(
              t('generic.createdSuccess', {
                record: t('generic.note'),
              })
            );
            actions.resetForm();
            onSaveSuccess();
            onRecordCountChanged();
          }
        })
        .finally(() => actions.setSubmitting(false)),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      addMutation,
      entityName,
      mode,
      mutationParams,
      onRecordCountChanged,
      onSaveSuccess,
      updateMutation,
    ]
  );

  const _onDeleteClicked = () => {
    getGraphQLClient()
      .performMutation(deleteMutation, {
        id: parseInt(record?.id, 10),
      })
      .then((e) => {
        if (e.hasError()) {
          if (e.hasSystemErrors()) {
            messageContext.setError(getErrors(e.systemErrors));
          }
          if (e.hasValidationErrors()) {
            const { input } = e.validationErrors;
            formik.setErrors(input);
          }
          return;
        }
        const { data } = e;
        if (data[`delete${entityName}Note`].success) {
          messageContext.setSuccess(
            t('generic.deletedSuccess', { record: 'Note' })
          );
          setIsDeleteDialogOpen(false);
          onRecordCountChanged();
        }
      });
  };

  React.useEffect(() => {
    if (!formik.isValid) {
      setPanelEditingState({
        isEditing: formik.dirty,
        hasEdited: formik.dirty,
        tabError: true,
      });
    } else {
      setPanelEditingState({
        isEditing: formik.isSubmitting,
        hasEdited: formik.dirty,
        tabError: false,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.dirty, formik.isSubmitting, formik.isValid, mode]);

  const _handleDateChange = (val: Dayjs | null | undefined) => {
    if (val === null) {
      formik.setFieldValue('noteDate', dayjs());
    } else {
      formik.setFieldValue('noteDate', val);
    }
  };
  return (
    <Box sx={rootSx}>
      {mode === 'edit' && <AutoSave debounceMs={1500} formik={formik} />}
      <Box sx={contentSx}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Box m={0} mb={0}>
              <ETODateField
                disabled={!permissions.canModifyNotes}
                error={formik.errors.noteDate as string}
                handleChange={(v) => _handleDateChange(v)}
                inputFormat={settingsContext.settings.dateFormat}
                label={t('generic.date')}
                name="noteDate"
                size="small"
                value={formik.values.noteDate}
              />
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Box m={0} mb={0}>
              <ETOSelectField
                disableClearable
                disabled={!permissions.canModifyNotes}
                error={formik.errors.employeeId}
                handleChange={formik.handleChange}
                itemDisabledSelector={(item: Partial<Employee>) => !item.active}
                itemNameSelector={(item: Partial<Employee>) =>
                  `${!item.active ? '(inactive) ' : ''}${
                    item.employeeNumber
                  } - ${item.firstName} ${item.lastName}`
                }
                items={employees?.data?.employees.items ?? []}
                itemValueSelector={(item: Partial<Employee>) => item.id}
                label={t('entities:Employee.Employee')}
                name="employeeId"
                size="small"
                value={formik.values.employeeId}
              />
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Box m={0} mb={0}>
              <ETOTextField
                disabled={!permissions.canModifyNotes}
                error={formik.errors.note}
                handleChange={formik.handleChange}
                label={t('generic.note')}
                multiline
                name="note"
                size="small"
                value={formik.values.note}
              />
            </Box>
          </Grid>
        </Grid>
      </Box>
      <Box>
        {mode === 'edit' && (
          <ButtonStrip
            rightButton={{
              text: t('generic.delete'),
              color: 'error',
              onClick: () => setIsDeleteDialogOpen(true),
              disabled: !permissions.canDeleteNotes,
              customSx: (theme) => ({
                ml: theme.spacing(1),
                mb: theme.spacing(1),
              }),
            }}
            size="medium"
          />
        )}
        {mode === 'add' && (
          <ButtonStrip
            leftButton={{
              text: t('generic.reset'),
              onClick: () => formik.resetForm(),
              color: 'secondary',
              customSx: (theme) => ({
                ml: theme.spacing(1),
                mb: theme.spacing(1),
              }),
            }}
            rightButton={{
              text: t('generic.add'),
              onClick: () => formik.submitForm(),
              color: 'primary',
              customSx: (theme) => ({
                mr: theme.spacing(1),
                mb: theme.spacing(1),
              }),
            }}
            size="medium"
          />
        )}
      </Box>
      <ConfirmDialogs
        content={t('dialogs.deleteGenericItem.content')}
        leftButton={{
          onClick: () => setIsDeleteDialogOpen(false),
          label: t('generic.no'),
        }}
        open={isDeleteDialogOpen}
        rightButton={{
          onClick: () => _onDeleteClicked(),
          label: t('generic.yes'),
        }}
        title={t('dialogs.deleteGenericItem.title')}
      />
      {confirmAbandonForm && (
        <OkCancelConfirmDialog
          content={t('dialogs.closeUnsavedForm.content')}
          onCancel={() => {
            setConfirmAbandonForm(false);
          }}
          onOk={() => {
            setConfirmAbandonForm(false);
            if (mode === 'add') {
              formik.resetForm();
              setIsNewNoteOpen(false);
            }
            setSelectedNoteItem(undefined);
          }}
          open={confirmAbandonForm}
          title={t('dialogs.closeUnsavedForm.title')}
        />
      )}
    </Box>
  );
};

export default CommonAddEditNote;
