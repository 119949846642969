import { gql } from '../../../../../../__generated__';

// eslint-disable-next-line import/prefer-default-export
export const nonConformanceTasksQuery = gql(`query nonConformanceTasks {
  nonConformanceTasks {
    items {
     name
    }
  }
}`);
