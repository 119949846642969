import AddShoppingCartRoundedIcon from '@mui/icons-material/AddShoppingCartRounded';
import FindReplaceRoundedIcon from '@mui/icons-material/FindReplaceRounded';
import FormatListNumberedRoundedIcon from '@mui/icons-material/FormatListNumberedRounded';
import InsertChartRoundedIcon from '@mui/icons-material/InsertChartRounded';
import PercentRoundedIcon from '@mui/icons-material/PercentRounded';
import {
  Dialog,
  LinearProgress,
  SxProps,
  Theme,
  Typography,
} from '@mui/material';
import {
  ETOButton,
  ETOMenu,
  GenericDialog,
  MenuOption,
  MessageContext,
} from '@teto/react-component-library-v2';
import React, {
  Dispatch,
  RefObject,
  SetStateAction,
  useCallback,
  useContext,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { Permission, getGraphQLClient } from 'teto-client-api';
import { gql } from '../../../../../../__generated__';
import { PurchaseOrderDetail } from '../../../../../../__generated__/graphql';
import buildMutation from '../../../../../../api/graphQL/buildMutation';
import getErrors from '../../../../../../api/graphQL/getErrors';
import AuthContext from '../../../../../../contexts/AuthContext';
import SettingsContext from '../../../../../../contexts/SettingsContext';
import useShouldActionBarCollapse from '../../../../../../helpers/shouldActionBarCollapse';
import { RequisitionHeader } from '../../../../../../pages/InventoryPage/ItemsToPullTab/Inspectors/types/RequisitionTypes';
import { Item } from '../../../../../../pages/InventoryPage/components/helpers/InventoryInspectorHelper';
import { Confirmation } from '../../../../../../pages/InventoryPage/components/types/InventoryInspectorType';
import ActionBar from '../../../../../ActionBar/ActionBar';
import RequestIcon from '../../../../../Custom Icons/RequestIcon/RequestIcon';
import RequisitionInspector from '../../../../../Inspectors/RequisitionInspector/RequisitionInspector';
import ReplacePartNumbersModal from '../../../../../ReplacePartNumbersModal/ReplacePartNumbersModal';
import GridCommonMobileButtons from '../../../../../TETOGridGraphQL/components/GridCommonMobileButtons/GridCommonMobileButtons';
import TETOGridRefType from '../../../../../TETOGridGraphQL/types/TETOGridRefType';
import RDGSelectedType from '../../../../../TetoGrid/types/RDGSelectedType';
import OrderAdditionalToInventory from '../../../components/OrderAdditionalToInventory';
import POTaxSettings from '../../../components/POTaxSettings';
import purchaseOrderReport from '../../../queries/getPODetailReportQuery';
import poExportReportQuery from '../../../queries/poExportReportQuery';
import preservePOOrderMutation from '../../../queries/preservePOOrderMutation';
import { TaxSettingsUpdate } from '../../../types/TaxSettingsUpdate';

const paperPropsSx: SxProps<Theme> = {
  minWidth: (theme) => theme.spacing(20),
  textAlign: 'center',
  margin: 'auto',
  p: 2,
  width: { sm: '100%' },
};

export interface PurchaseOrderActionBarProps {
  gridRef: RefObject<TETOGridRefType>;
  selectedRows: RDGSelectedType | undefined;
  pODetailItems?: {
    id: number;
    itemMasterDescription: string;
    entityDescription?: string | null;
  }[];
  purchaseOrderId: number;
  setRefreshTotal: Dispatch<SetStateAction<boolean>>;
  setTaxUpdate: Dispatch<SetStateAction<TaxSettingsUpdate | undefined>>;
  setRefreshQuery?: React.Dispatch<React.SetStateAction<boolean>>;
  currentShipToId: number; // required for OrderAdditionalToInventory
  setConfigureInspectorOpen: React.Dispatch<React.SetStateAction<boolean>>;
  hasInventoryItem: boolean;
  defaultSupplierReportId?: number;
}

const actionBarBottomFixSx = { mb: 2 };
const pORequisitionMutation =
  gql(`mutation addItemsToPO ($input: AddItemsInput) {
  addItems(input: $input) {
      success
  }
}`);

const PURCHASE_ORDER_REPORT_ID = 112;

const PurchaseOrderActionBar = (props: PurchaseOrderActionBarProps) => {
  const {
    gridRef,
    purchaseOrderId,
    selectedRows,
    pODetailItems,
    setRefreshTotal,
    setTaxUpdate,
    setRefreshQuery,
    currentShipToId,
    setConfigureInspectorOpen,
    hasInventoryItem,
    defaultSupplierReportId,
  } = props;
  const { t } = useTranslation();
  const authContext = useContext(AuthContext);
  const messageContext = useContext(MessageContext);
  const settingsContext = useContext(SettingsContext);

  const reportBtnRef = useRef(null);

  const [reportMenu, setReportMenu] = useState<MenuOption[]>();
  const [isReportLoading, setIsReportLoading] = useState(false);
  const [isReplacePartNumbersOpen, setIsReplacePartNumbersOpen] =
    useState<boolean>(false);
  const [isTaxSettingOpen, setIsTaxSettingOpen] = useState<boolean>(false);
  const [isRequisitionInspectorOpen, setIsRequisitionInspectorOpen] =
    useState<boolean>(false);
  const [
    orderAdditionalToInventoryIsOpen,
    setOrderAdditionalToInventoryIsOpen,
  ] = useState(false);
  const [isInventoryWarningOpen, setIsInventoryWarningOpen] = useState(false);

  const { canViewReport, canPreserveOrder } = useMemo(
    () => ({
      canViewReport: authContext.hasPermission(
        Permission.View_Legacy_Reports_Purchasing_Purchase_Order
      ),
      canPreserveOrder: authContext.hasPermission(
        Permission.Modify_Procurement_PurchaseOrders_PurchaseOrderHeader_PurchaseOrderDetails
      ),
    }),
    [authContext]
  );

  const purchaseOrderDetailItems = useMemo(() => {
    if (!pODetailItems) return [];

    return pODetailItems.filter((a) => a);
  }, [pODetailItems]);

  const _refreshGrid = useCallback(() => {
    gridRef?.current?.refreshDataSource();
    setRefreshTotal(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gridRef]);

  const GridCommonBtns = GridCommonMobileButtons({
    refreshGrid: () => _refreshGrid(),
    gridRef,
    setConfigureInspector: () => setConfigureInspectorOpen(true),
  });

  const _handlePreservePO = useCallback(() => {
    const data = gridRef?.current?.getGrid();
    const dataSource = data?.dataSource ?? [];
    const newOrder = (dataSource as PurchaseOrderDetail[])?.map((d, i) => ({
      id: d.id,
      orderNumber: i + 1,
    }));

    const results = {
      input: {
        purchaseOrderDetailOrder: newOrder,
      },
      where: {
        purchaseOrderId: {
          eq: purchaseOrderId,
        },
      },
    };

    buildMutation({
      queryString: preservePOOrderMutation,
      variables: results,
      callback: (d) => {
        if (d?.updatePurchaseOrderDetailOrder?.items) {
          _refreshGrid();
        }
      },
      messageContext,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [_refreshGrid, gridRef, purchaseOrderId]);

  const _handleReportExport = useCallback(
    (reportId: number, alternateId: number) => {
      setIsReportLoading(true);
      getGraphQLClient()
        .performQuery(poExportReportQuery, {
          id: reportId,
          companyDetails: true,
          input: {
            alternateId,
            parameters: {
              intPurchaseOrderID: purchaseOrderId,
              intPurchaseOrderReportAlternateID: alternateId,
            },
          },
        })
        .then((d) => {
          if (d.hasError()) {
            d.showAllSystemErrors(messageContext.setError);
            if (d.hasValidationErrors()) {
              const { input } = d.validationErrors;
              messageContext.setError(Object.values(input)[0] as string);
            }
            setIsReportLoading(false);
            return;
          }
          setIsReportLoading(false);

          if (d.data?.exportReport?.path) {
            const data = {
              alternateId: d.data?.exportReport?.parameters?.find(
                (r) => r.key === 'intPurchaseOrderReportAlternateID'
              ).value,
              reportId: d.data?.exportReport?.reportId?.toString(),
              entityId: d.data?.exportReport?.parameters?.find(
                (r) => r.key === 'intPurchaseOrderID'
              ).value,
              type: 'purchaseOrder',
            };
            const queryString = new URLSearchParams(data).toString();
            window.open(
              `/reportViewer?url=${encodeURIComponent(
                d.data.exportReport.path
              )}&${queryString}`,
              '_blank'
            );
          }
        });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [purchaseOrderId]
  );

  const _handleReportBtnClick = useCallback(() => {
    getGraphQLClient()
      .performQuery(purchaseOrderReport, { id: PURCHASE_ORDER_REPORT_ID })
      .then((d) => {
        if (d.hasError()) {
          if (d.systemErrors)
            messageContext.setError(getErrors(d.systemErrors));
          if (d.validationErrors)
            messageContext.setError(getErrors(d.validationErrors));
          return;
        }
        if (d.hasData() && d.data?.employeeReport) {
          const reports = [];
          const { allowView, alternateId, reportDisplayName, reportID } =
            d.data.employeeReport;

          reports.push({
            label: reportDisplayName,
            disabled: !allowView,
            onClick: () => _handleReportExport(reportID, alternateId),
          });

          if (d.data.reportAlternates?.items) {
            d.data.reportAlternates?.items.forEach((i) => {
              if (i?.id === defaultSupplierReportId) {
                reports.unshift({
                  label: `* ${i?.reportDisplayName}`,
                  disabled: !i?.enabled,
                  onClick: () =>
                    _handleReportExport(i?.reportId as number, i?.id as number),
                });
              } else {
                reports.push({
                  label: i?.reportDisplayName,
                  disabled: !i?.enabled,
                  onClick: () =>
                    _handleReportExport(i?.reportId as number, i?.id as number),
                });
              }
            });
          }

          setReportMenu(reports);
        }
        return d;
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [_handleReportExport, defaultSupplierReportId]);

  const AddItemsBtn = useMemo(
    () => ({
      title: t('pages.purchaseOrders.purchaseOrderModal.addItems'),
      icon: <RequestIcon />,
      onclick: () => setIsRequisitionInspectorOpen(true),
    }),
    [t]
  );

  const PreserveOrderBtn = useMemo(
    () => ({
      title: t('pages.purchaseOrders.purchaseOrderModal.preserveOrder'),
      icon: <FormatListNumberedRoundedIcon />,
      onclick: () => _handlePreservePO(),
      disabled:
        !pODetailItems || pODetailItems?.length === 0 || !canPreserveOrder,
    }),
    [_handlePreservePO, canPreserveOrder, pODetailItems, t]
  );
  const OrderAdditionalBtn = useMemo(
    () => ({
      title: t('pages.purchaseOrders.purchaseOrderModal.orderAdditional'),
      icon: <AddShoppingCartRoundedIcon />,
      onclick: () => {
        if (
          currentShipToId !== 1 &&
          !settingsContext.settings.dropShipmentsForInventory &&
          hasInventoryItem
        ) {
          setIsInventoryWarningOpen(true);
        } else {
          setOrderAdditionalToInventoryIsOpen(true);
        }
      },
    }),
    [
      currentShipToId,
      hasInventoryItem,
      settingsContext.settings.dropShipmentsForInventory,
      t,
    ]
  );
  const TaxSettingsBtn = useMemo(
    () => ({
      title: t('pages.purchaseOrders.purchaseOrderModal.taxSettings'),
      icon: <PercentRoundedIcon />,
      onclick: () => setIsTaxSettingOpen(true),
    }),
    [t]
  );

  const ReplacePartNumbersBtn = useMemo(
    () => ({
      title: t('pages.procurement.replacePartNumbers'),
      icon: <FindReplaceRoundedIcon />,
      onclick: () => setIsReplacePartNumbersOpen(true),
      disabled: !pODetailItems || pODetailItems?.length === 0,
    }),
    [pODetailItems, t]
  );

  const ReportBtn = useMemo(
    () => ({
      title: t('generic.reports'),
      icon: <InsertChartRoundedIcon />,
      onclick: () => _handleReportBtnClick(),
      disabled: !canViewReport,
      customComponent: (
        <ETOButton
          buttonProps={{
            ref: reportBtnRef,
          }}
          color="primary"
          disabled={!canViewReport}
          icon={<InsertChartRoundedIcon />}
          key={t('generic.reports')}
          onClick={() => _handleReportBtnClick()}
          size="medium"
        >
          {t('generic.reports')}
        </ETOButton>
      ),
    }),
    [canViewReport, _handleReportBtnClick, t]
  );

  const rightChildren = useMemo(
    () => [
      AddItemsBtn,
      ReplacePartNumbersBtn,
      OrderAdditionalBtn,
      PreserveOrderBtn,
      ReportBtn,
      TaxSettingsBtn,
      GridCommonBtns,
    ],
    [
      AddItemsBtn,
      ReplacePartNumbersBtn,
      GridCommonBtns,
      OrderAdditionalBtn,
      PreserveOrderBtn,
      ReportBtn,
      TaxSettingsBtn,
    ]
  );

  const formatMutationVariables = useCallback(
    (data: Item[], headerValues: RequisitionHeader) => {
      const { destInventoryLocationId, projectId, specId } = headerValues;

      const details = data.map((i) => ({
        addReason: i.addReason,
        assemblyPurchase: i.assemblyPurchase ?? null,
        inventoryLocationId: destInventoryLocationId,
        itemId: i.itemId,
        projectId,
        qty: i.purchaseQty,
        requiredDate: i.requiredDate,
        specId,
      }));

      return {
        input: {
          employeeId: authContext?.user?.id,
          itemsToInsert: details,
          purchaseOrderId,
        },
      };
    },
    [authContext?.user?.id, purchaseOrderId]
  );

  const _handleConfirmContent = useCallback(
    (d: Item[]) => {
      const input = { count: d.length, amount: d.length > 1 ? 's' : '' };
      const containsAssemblyType =
        d.filter((i) => Math.abs(i?.uOMId as number) === 1).length > 0;

      return !containsAssemblyType
        ? t('pages.purchaseOrders.purchaseOrderModal.addItemsConfirmation', {
            ...input,
          })
        : t('pages.purchaseOrders.purchaseOrderModal.altAddItemsConfirmation', {
            ...input,
          });
    },
    [t]
  );

  return (
    <>
      <ActionBar
        excludeBtnFromCollapsedMenu={[
          t('pages.purchaseOrders.purchaseOrderModal.orderAdditional'),
          t('pages.purchaseOrders.purchaseOrderModal.addItems'),
          t('generic.reports'),
        ]}
        headerStyles={actionBarBottomFixSx}
        rightChildren={rightChildren}
        shouldCollapse={useShouldActionBarCollapse(rightChildren)}
        withBottomNav={false}
      />

      {isTaxSettingOpen && (
        <POTaxSettings
          onClose={(update) => {
            if (update) {
              _refreshGrid();
              setTaxUpdate(update);
            }
            setIsTaxSettingOpen(false);
          }}
          open={isTaxSettingOpen}
          purchaseOrderId={purchaseOrderId}
        />
      )}

      {isReplacePartNumbersOpen && (
        <ReplacePartNumbersModal
          entityItems={purchaseOrderDetailItems ?? []}
          entityName="PurchaseOrder"
          onClose={(update) => {
            if (update) {
              _refreshGrid();
              gridRef.current?.deselectAll();
            }
            setIsReplacePartNumbersOpen(false);
          }}
          open={isReplacePartNumbersOpen}
          selected={selectedRows}
        />
      )}

      {isRequisitionInspectorOpen && (
        <RequisitionInspector
          mutationProps={{
            responseKey: 'addItems',
            mutationString: pORequisitionMutation,
            formatVariables: (d, h) => formatMutationVariables(d, h),
            successMessage: t('pages.inventory.itemsToPull.requisitionSuccess'),
            onSuccess: () => {
              _refreshGrid();
              if (setRefreshQuery) setRefreshQuery(true);
              return Promise.resolve();
            },
          }}
          onClose={() => {
            setIsRequisitionInspectorOpen(false);
          }}
          open={isRequisitionInspectorOpen}
          requireReasonNDate={false}
          saveConfirmation={
            {
              confirm: {
                content: (d: Item[]) => _handleConfirmContent(d),
                title: t('pages.purchaseOrders.purchaseOrderModal.addItems'),
                type: 'yesNo',
              },
            } as unknown as Confirmation
          }
          shouldWarnAboutClosedJobs
        />
      )}

      <OrderAdditionalToInventory
        orderAdditionalToInventoryIsOpen={orderAdditionalToInventoryIsOpen}
        purchaseOrderId={purchaseOrderId}
        refreshGrid={_refreshGrid}
        setOrderAdditionalToInventoryIsOpen={
          setOrderAdditionalToInventoryIsOpen
        }
      />
      <GenericDialog
        isOpen={isInventoryWarningOpen}
        onClose={() => setIsInventoryWarningOpen(false)}
        title={t('generic.warning')}
      >
        {t('pages.purchaseOrders.purchaseOrderModal.inventoryWarning')}
      </GenericDialog>
      <Dialog
        onClose={() => {}}
        open={isReportLoading}
        PaperProps={{
          sx: paperPropsSx,
        }}
      >
        <Typography sx={{ marginBottom: 1 }} variant="h6">
          {t('generic.generatingReport')}
        </Typography>
        <LinearProgress />
      </Dialog>
      {reportMenu && (
        <ETOMenu
          anchorEl={reportBtnRef?.current}
          menuOptions={reportMenu as MenuOption[]}
          open={Boolean(reportMenu)}
          setClose={() => setReportMenu(undefined)}
          sx={{
            zIndex: 2,
          }}
        />
      )}
    </>
  );
};

export default PurchaseOrderActionBar;
