/* eslint-disable dot-notation */
/* eslint-disable @typescript-eslint/no-empty-function */
import React, { useContext, useMemo } from 'react';

import dayjs from 'dayjs';

import { Permission } from 'teto-client-api';

import { gql } from '../../../../../__generated__';
import AuthContext from '../../../../../contexts/AuthContext';
import AddEditNotes, {
  TNotesInspectorProps,
} from '../../../components/AddEditInspector/AddEditNotes/AddEditNotes';
import { INotesForm } from '../../../components/AddEditInspector/AddEditNotes/NotesForm';

interface NotesProps extends TNotesInspectorProps {
  itemId: number | undefined;
}

interface AddEngItemMasterNoteInput {
  itemId: number;
  date: Date;
  employeeId: number;
  note: string;
}
interface UpdateEngItemMasterNoteInput {
  id: number;
  date: Date;
  employeeId: number;
  note: string;
}
const deleteEngItemMasterNoteMutation =
  gql(`mutation deleteEngItemMasterNote($id:Int!) {
  deleteEngItemMasterNote(id: $id) {
    success
  }
}`);

const updateEngItemMasterNoteMutation = gql(`mutation updateEngItemMasterNote(
  $input: UpdateEngItemMasterNoteInput!
) {
  updateEngItemMasterNote(input: $input) {
    record {
      id
      noteDate
      note
      noteEmployee {
        name
        id
      }
    }
  }
}
`);
const addEngItemMasterNoteMutation =
  gql(`mutation addEngItemMasterNote($input: AddEngItemMasterNoteInput!) {
  addEngItemMasterNote(input: $input) {
    record {
      id
      noteDate
      note
      itemId
      noteEmployee {
        name
        id
      }
    }
  }
}
`);

const EngItemMasterNotes = `
query engItemMasterNotes($filter: EngItemMasterNoteFilterInput!) {
  engItemMasterNotes(where: $filter) {
    items {
      id
      note
      noteDate
      noteEmployee {
        id
        name
      }
    }
  }
}`;
const NotesPanel = (props: NotesProps) => {
  const { itemId } = props;
  const authContext = useContext(AuthContext);
  const filter = {
    filter: {
      itemId: {
        eq: itemId,
      },
    },
  };
  const mutationVariables = (value: INotesForm, mode: 'add' | 'edit') => {
    if (mode === 'add') {
      const inputVariables: AddEngItemMasterNoteInput = {
        note: value.note,
        date: value.noteDate,
        employeeId: value.noteEmployee?.id,
        itemId: itemId || value.id,
      };
      return inputVariables;
    }
    const inputVariables: UpdateEngItemMasterNoteInput = {
      note: value.note,
      date: value.noteDate,
      employeeId: value.noteEmployee?.id,
      id: value.id,
    };
    return inputVariables;
  };
  const canModifyNote = useMemo(
    () =>
      authContext.hasPermission(Permission.Modify_Engineering_ItemMaster_Notes),
    [authContext]
  );

  const canDeleteNote = useMemo(
    () =>
      authContext.hasPermission(Permission.Delete_Engineering_ItemMaster_Notes),
    [authContext]
  );
  const canAddNote = useMemo(
    () =>
      authContext.hasPermission(Permission.Add_Engineering_ItemMaster_Notes),
    [authContext]
  );

  const canView = useMemo(
    () =>
      authContext.hasPermission(Permission.View_Engineering_ItemMaster_Notes),
    [authContext]
  );

  const formatOnAdd = (value: unknown): INotesForm | undefined => {
    const key = 'addEngItemMasterNote' as keyof typeof value;

    if (
      value &&
      typeof value === 'object' &&
      key in value &&
      typeof value[key] === 'object' &&
      value[key] &&
      'record' in value[key] &&
      value[key]['record'] &&
      typeof value[key]['record'] === 'object' &&
      'note' in value[key]['record'] &&
      'noteDate' in value[key]['record'] &&
      'noteEmployee' in value[key]['record'] &&
      typeof value[key]['record']['note'] === 'string' &&
      typeof value[key]['record']['noteDate'] === 'string' &&
      dayjs(value[key]['record']['noteDate']).isValid() &&
      typeof value[key]['record']['noteEmployee'] === 'object' &&
      value[key]['record']['noteEmployee'] &&
      'id' in value[key]['record']['noteEmployee'] &&
      'name' in value[key]['record']['noteEmployee'] &&
      typeof value[key]['record']['noteEmployee']['id'] === 'number' &&
      typeof value[key]['record']['noteEmployee']['name'] === 'string'
    ) {
      const item = value[key]['record'];
      const { note, noteDate } = item;

      const formattedItem: INotesForm = {
        id: value[key]['record']['id'],
        note,
        noteDate: dayjs(noteDate).toDate(),
        noteEmployee: {
          id: item['noteEmployee']['id'],
          name: item['noteEmployee']['name'],
        },
      };
      return formattedItem;
    }

    // eslint-disable-next-line no-console
    console.error('Invalid formatOnAdd value', value);
    return undefined;
  };
  const formatOnUpdate = (value: unknown): INotesForm | undefined => {
    const key = 'updateEngItemMasterNote' as keyof typeof value;
    // 288 / 285
    if (value) {
      const item = value[key]['record'];
      const { note, noteDate } = item;

      const formattedItem: INotesForm = {
        id: value[key]['record']['id'],
        note,
        noteDate: dayjs(noteDate).toDate(),
        noteEmployee: {
          id: item['noteEmployee']['id'],
          name: item['noteEmployee']['name'],
        },
      };
      return formattedItem;
    }

    // eslint-disable-next-line no-console
    console.error('Invalid formatOnUpdate value', value);
    return undefined;
  };
  const checkDeleteResult = (value: unknown): boolean => {
    const key = 'deleteEngItemMasterNote';
    if (
      value &&
      typeof value === 'object' &&
      key in value &&
      typeof value[key] === 'object' &&
      value[key] &&
      'success' in value[key]
    ) {
      if (typeof value[key]['success'] === 'boolean') {
        return value[key]['success'];
      }
      if (typeof value[key]['success'] === 'string') {
        return Boolean(value[key]['success']);
      }
    }
    return false;
  };
  return (
    <>
      <AddEditNotes
        {...props}
        canAdd={canAddNote}
        canDelete={canDeleteNote}
        canModify={canModifyNote}
        canView={canView}
        checkDeleteResult={checkDeleteResult}
        formatOnAdd={formatOnAdd}
        formatOnUpdate={formatOnUpdate}
        mutationVariables={mutationVariables}
        onAddMutation={addEngItemMasterNoteMutation}
        onDeleteMutation={deleteEngItemMasterNoteMutation}
        onUpdateMutation={updateEngItemMasterNoteMutation}
        queryKey="engItemMasterNotes"
        queryVariables={filter}
        standardQuery={EngItemMasterNotes}
      />
    </>
  );
};

export default NotesPanel;
