import { Divider, Grid } from '@mui/material';
import {
  ETOCheckBox,
  ETOCurrencyField,
  ETODateField,
  ETOTextField,
  MessageContext,
} from '@teto/react-component-library-v2';
import { FormikProps } from 'formik';
import React, {
  ChangeEvent,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import getErrors from '../../../../../../api/graphQL/getErrors';
import { getGraphQLClient } from '../../../../../../api/graphQL/graphQLClient';
import SettingsContext from '../../../../../../contexts/SettingsContext';
import formatCharCount from '../../../../../../helpers/formatCharCount';
import isCustomCaption from '../../../../../../helpers/isCustomCaption';
import RawMaterialSelectField from '../../../../../InputFields/RawMaterialSelectField/RawMaterialSelectField';
import { PanelMode } from '../../../../types/PanelState';
import { TEngItemMaster } from '../../../types/TEngItemMaster';
import CountryOfOriginSelectField from './InputFields/CountryOfOrginSelectField';
import ItemCategorySelectField from './InputFields/ItemCategorySelectField';
import LastSupplierSelectField from './InputFields/LastSupplierSelectField';
import ManufacturersSelectField from './InputFields/ManufacturersSelectField';
import ProcessesSelectField from './InputFields/ProcessScheduleTemplatesSelectField';
import SupplierSelectField from './InputFields/SupplierSelectField';
import UOMSelectField from './InputFields/UOMSelectField';
import standardAssemblyItemsQuery from './standardAssemblyItemsQuery';

const inputRegex = /^[0-9\b]+$/;
const cutLengthRegex = /^\d{1,12}(.\d{1,6})?$/;
interface IPartProps {
  formik: FormikProps<Partial<TEngItemMaster>>;
  // eslint-disable-next-line no-unused-vars, @typescript-eslint/no-explicit-any
  handleChange: (e: Partial<React.ChangeEvent<any>>) => void;
  // permissions?: any;
  panelMode: PanelMode;
  canModify: boolean;
}

const PartForm = (props: IPartProps) => {
  const { formik, panelMode, canModify, handleChange } = props;
  const { t } = useTranslation();
  const {
    settings: { dateFormat },
  } = useContext(SettingsContext);
  const messageContext = useContext(MessageContext);

  const [isUomDisabled, setIsUomDisabled] = useState<boolean>(false);

  const spanFullWidth = useMemo(() => ({ gridColumn: 'span 2' }), []);

  const _handleNumberChange = (
    fieldName: string,
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    if (
      e.target.value === '' ||
      (fieldName === 'rawMaterialCutLength' &&
        cutLengthRegex.test(e.target.value)) ||
      (fieldName !== 'rawMaterialCutLength' && inputRegex.test(e.target.value))
    ) {
      handleChange({
        target: {
          name: fieldName,
          value: e.target.value,
        },
      });
    }
  };

  const disableInputs = useMemo(() => {
    if (!canModify || formik?.initialValues?.itemReserved)
      return { common: true, lock: true }; // disable all inputs
    return { common: false, lock: true }; // disable only locked and obsolete inputs
  }, [canModify, formik?.initialValues?.itemReserved]);

  useEffect(() => {
    if (canModify && formik.values?.uOM?.itemUom === -1) {
      getGraphQLClient()
        .performQuery(
          standardAssemblyItemsQuery,
          {
            id: formik.values?.itemCompanyId,
          },
          (err) => messageContext.setError(getErrors(err)),
          (err) => messageContext.setError(getErrors(err))
        )
        .then((d) => {
          setIsUomDisabled(d.standardAssemblies.items.length > 0);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [canModify, formik.values?.itemCompanyId, formik.values?.uOM?.itemUom]);

  const _handleCheckBoxChange = useCallback(
    (e: ChangeEvent<HTMLInputElement> | undefined) => {
      handleChange({
        ...e,
        target: {
          ...e?.target,
          name: e?.target?.name,
          value: e?.target?.checked,
        },
      });
    },
    [handleChange]
  );

  return (
    <>
      <Grid container spacing={2}>
        <Grid item md={3} xs={12}>
          <ETOTextField
            disabled={disableInputs.common}
            error={formik.errors.itemCompanyId}
            handleChange={handleChange}
            helperText={formatCharCount(
              'itemCompanyId',
              formik.values.itemCompanyId,
              100
            )}
            inputLabelProps={{ shrink: true }}
            label={t('entities:Part.ItemCompanyId')}
            multiline
            name="itemCompanyId"
            size="small"
            value={formik?.values.itemCompanyId || ''}
          />
        </Grid>
        <Grid item md={3} xs={12}>
          <ETOTextField
            disabled={disableInputs.common}
            error={formik.errors.description}
            handleChange={handleChange}
            helperText={formatCharCount(
              'description',
              formik.values.description,
              1000
            )}
            inputLabelProps={{ shrink: true }}
            label={t('entities:Part.ItemDescription')}
            multiline
            name="description"
            size="small"
            value={formik?.values.description}
          />
        </Grid>
        {panelMode === 'edit' && (
          <Grid item md={3} xs={12}>
            <ETOTextField
              disabled={disableInputs.common}
              error={formik.errors.itemRevNumber}
              handleChange={handleChange}
              helperText={formatCharCount(
                'itemRevNumber',
                formik.values.itemRevNumber,
                25
              )}
              inputLabelProps={{ shrink: true }}
              label={t('entities:Part.ItemRevNumber')}
              multiline
              name="itemRevNumber"
              size="small"
              value={formik?.values.itemRevNumber}
            />
          </Grid>
        )}
        <Grid item md={3} xs={12}>
          <UOMSelectField
            disabled={disableInputs.common || isUomDisabled}
            error={formik.errors.uOM}
            handleChange={(d) =>
              handleChange({
                target: {
                  name: 'uOM',
                  value: {
                    itemUom: d === undefined ? '' : d.itemUom,
                  },
                },
              })
            }
            value={formik.values.uOM?.itemUom}
          />
        </Grid>

        <Grid item md={3} xs={12}>
          <ItemCategorySelectField
            disabled={disableInputs.common}
            error={
              formik.errors?.category?.[
                'itemCategory' as keyof TEngItemMaster['category']
              ]
            }
            handleChange={(e) =>
              handleChange({
                target: {
                  name: 'category',
                  value: {
                    itemCategory: e?.target.value,
                  },
                },
              })
            }
            sx={spanFullWidth}
            value={
              (formik.values?.category?.[
                'itemCategory' as keyof TEngItemMaster['category']
              ] as unknown as number) ?? ''
            }
          />
        </Grid>

        <Grid item md={3} xs={12}>
          <ETOTextField
            disabled={disableInputs.common}
            error={formik.errors.itemWeight}
            handleChange={(e) => _handleNumberChange('itemWeight', e)}
            inputLabelProps={{ shrink: true }}
            label={t('entities:Part.ItemWeight')}
            name="itemWeight"
            size="small"
            type="number"
            value={formik?.values.itemWeight || ''}
          />
        </Grid>

        <Grid item md={3} xs={12}>
          <ETOCurrencyField
            disabled={disableInputs.common}
            error={formik.errors.itemListCost}
            handleChange={(v) =>
              handleChange({
                target: {
                  name: v.key,
                  value: v.value,
                },
              })
            }
            label={t('entities:Part.ItemListCost')}
            name="itemListCost"
            size="small"
            value={formik?.values.itemListCost || 0}
          />
        </Grid>
        <Grid item md={3} xs={12}>
          <ETOCurrencyField
            disabled={disableInputs.common}
            error={formik.errors.itemLastCost}
            handleChange={(v) =>
              handleChange({
                target: {
                  name: v.key,
                  value: v.value,
                },
              })
            }
            label={t('entities:Part.ItemLastCost')}
            name="itemLastCost"
            size="small"
            value={formik?.values.itemLastCost || 0}
          />
        </Grid>
        <Grid item md={3} xs={12}>
          <ManufacturersSelectField
            disabled={disableInputs.common}
            formik={formik}
            handleChange={handleChange}
          />
        </Grid>
        <Grid item md={3} xs={12}>
          <ETOTextField
            disabled={disableInputs.common}
            error={formik.errors.manufacturerPartNumber}
            handleChange={handleChange}
            helperText={formatCharCount(
              'manufacturerPartNumber',
              formik.values.manufacturerPartNumber,
              100
            )}
            inputLabelProps={{ shrink: true }}
            label={t('entities:Part.ManufacturerPartNumber')}
            multiline
            name="manufacturerPartNumber"
            size="small"
            sxProps={spanFullWidth}
            value={formik?.values.manufacturerPartNumber || ''}
          />
        </Grid>
        <Grid item md={3} xs={12}>
          <CountryOfOriginSelectField
            disabled={disableInputs.common}
            formik={formik}
            handleChange={handleChange}
          />
        </Grid>
        <Grid item md={3} xs={12}>
          <ETOTextField
            disabled={disableInputs.common}
            error={formik.errors.tariffCode}
            handleChange={handleChange}
            helperText={formatCharCount(
              'tariffCode',
              formik.values.tariffCode,
              25
            )}
            inputLabelProps={{ shrink: true }}
            label={t('entities:Part.TariffCode')}
            multiline
            name="tariffCode"
            size="small"
            value={formik?.values.tariffCode}
          />
        </Grid>
        <Grid item md={3} xs={12}>
          <SupplierSelectField
            disabled={disableInputs.common}
            formik={formik}
            handleChange={handleChange}
            shouldHaveWarning
            sx={spanFullWidth}
          />
        </Grid>
        <Grid item md={3} xs={12}>
          <ETOTextField
            disabled={disableInputs.common}
            error={formik.errors.supplierPartNumber}
            handleChange={handleChange}
            helperText={formatCharCount(
              'supplierPartNumber',
              formik.values.supplierPartNumber,
              100
            )}
            inputLabelProps={{ shrink: true }}
            label={t('entities:Part.SupplierPartNumber')}
            multiline
            name="supplierPartNumber"
            size="small"
            sxProps={spanFullWidth}
            value={formik?.values.supplierPartNumber || ''}
          />
        </Grid>

        {panelMode === 'edit' && (
          <Grid item md={3} xs={12}>
            <LastSupplierSelectField
              disabled={!canModify || panelMode === 'edit'}
              formik={formik}
              handleChange={handleChange}
              shouldHaveWarning
              sx={spanFullWidth}
            />
          </Grid>
        )}
        <Grid item md={3} xs={12}>
          <ETOTextField
            disabled={disableInputs.common}
            error={formik.errors.estimatedLeadTime}
            handleChange={(e) => _handleNumberChange('estimatedLeadTime', e)}
            inputLabelProps={{ shrink: true }}
            label={t('entities:Part.EstimatedLeadTime')}
            name="estimatedLeadTime"
            size="small"
            sxProps={spanFullWidth}
            type="number"
            value={formik?.values.estimatedLeadTime || ''}
          />
        </Grid>

        {panelMode === 'edit' && (
          <>
            <Grid item md={3} xs={12}>
              <RawMaterialSelectField
                disabled={
                  disableInputs.common || formik.values.uOM?.itemUom !== 2
                }
                error={formik.errors.rawMaterialItem}
                handleChange={handleChange}
                sx={spanFullWidth}
                value={formik?.values.rawMaterialItem?.id}
              />
            </Grid>
            <Grid item md={3} xs={12}>
              <ETOTextField
                disabled={
                  disableInputs.common ||
                  formik.values.uOM?.itemUom !== 2 ||
                  !formik.values.rawMaterialItem
                }
                error={formik.errors.rawMaterialCutLength}
                handleChange={(e) =>
                  _handleNumberChange('rawMaterialCutLength', e)
                }
                inputLabelProps={{ shrink: true }}
                label={t('entities:Part.RawMaterialCutLength')}
                name="rawMaterialCutLength"
                size="small"
                sxProps={spanFullWidth}
                type="number"
                value={formik?.values.rawMaterialCutLength || ''}
              />
            </Grid>
          </>
        )}

        <Grid item md={3} xs={12}>
          <ProcessesSelectField
            disabled={disableInputs.common}
            formik={formik}
            handleChange={handleChange}
          />
        </Grid>

        {isCustomCaption(
          'custom1',
          t('entities:Part.PartCustom1'),
          'Part',
          18
        ) && (
          <Grid item md={3} xs={12}>
            <ETOTextField
              disabled={disableInputs.common}
              handleChange={handleChange}
              inputLabelProps={{ shrink: true }}
              key="custom1"
              label={t('entities:Part.PartCustom1')}
              maxRows={3}
              multiline
              name="custom1"
              size="small"
              sxProps={spanFullWidth}
              value={formik?.values.custom1}
            />
          </Grid>
        )}

        {isCustomCaption(
          'custom2',
          t('entities:Part.PartCustom2'),
          'Part',
          18
        ) && (
          <Grid item md={3} xs={12}>
            <ETOTextField
              disabled={disableInputs.common}
              handleChange={handleChange}
              inputLabelProps={{ shrink: true }}
              key="custom2"
              label={t('entities:Part.PartCustom2')}
              maxRows={3}
              multiline
              name="custom2"
              size="small"
              sxProps={spanFullWidth}
              value={formik?.values.custom2}
            />
          </Grid>
        )}

        {isCustomCaption(
          'custom3',
          t('entities:Part.PartCustom3'),
          'Part',
          18
        ) && (
          <Grid item md={3} xs={12}>
            <ETOTextField
              disabled={disableInputs.common}
              handleChange={handleChange}
              inputLabelProps={{ shrink: true }}
              key="custom3"
              label={t('entities:Part.PartCustom3')}
              maxRows={3}
              multiline
              name="custom3"
              size="small"
              sxProps={spanFullWidth}
              type="number"
              value={formik?.values.custom3}
            />
          </Grid>
        )}

        {isCustomCaption(
          'custom4',
          t('entities:Part.PartCustom4'),
          'Part',
          18
        ) && (
          <Grid item md={3} xs={12}>
            <ETOTextField
              disabled={disableInputs.common}
              handleChange={handleChange}
              inputLabelProps={{ shrink: true }}
              key="custom4"
              label={t('entities:Part.PartCustom4')}
              maxRows={3}
              multiline
              name="custom4"
              size="small"
              sxProps={spanFullWidth}
              type="number"
              value={formik?.values.custom4}
            />
          </Grid>
        )}

        {isCustomCaption(
          'custom5',
          t('entities:Part.PartCustom5'),
          'Part',
          18
        ) && (
          <Grid item md={3} xs={12}>
            <ETODateField
              clearable
              disabled={disableInputs.common}
              error={formik.errors.custom5}
              handleChange={(v) =>
                handleChange({
                  target: {
                    name: 'custom5',
                    value: v,
                  },
                })
              }
              inputFormat={dateFormat}
              inputLabelProps={{ shrink: true }}
              inputSx={spanFullWidth}
              key="custom5"
              label={t('entities:Part.PartCustom5')}
              name="custom5"
              size="small"
              value={formik?.values.custom5}
            />
          </Grid>
        )}

        {isCustomCaption(
          'custom6',
          t('entities:Part.PartCustom6'),
          'Part',
          18
        ) && (
          <Grid item md={3} xs={12}>
            <ETODateField
              clearable
              disabled={disableInputs.common}
              error={formik.errors.custom6}
              handleChange={(v) =>
                handleChange({
                  target: {
                    name: 'custom6',
                    value: v,
                  },
                })
              }
              inputFormat={dateFormat}
              inputLabelProps={{ shrink: true }}
              inputSx={spanFullWidth}
              key="custom6"
              label={t('entities:Part.PartCustom5')}
              name="custom6"
              size="small"
              value={formik?.values.custom6}
            />
          </Grid>
        )}

        {isCustomCaption(
          'custom9',
          t('entities:Part.PartCustom9'),
          'Part',
          18
        ) && (
          <Grid item md={3} xs={12}>
            <ETOTextField
              disabled={disableInputs.common}
              handleChange={handleChange}
              inputLabelProps={{ shrink: true }}
              key="custom9"
              label={t('entities:Part.PartCustom9')}
              maxRows={3}
              multiline
              name="custom9"
              size="small"
              sxProps={spanFullWidth}
              value={formik?.values.custom9}
            />
          </Grid>
        )}

        {isCustomCaption(
          'custom10',
          t('entities:Part.PartCustom10'),
          'Part',
          18
        ) && (
          <Grid item md={3} xs={12}>
            <ETOTextField
              disabled={disableInputs.common}
              handleChange={handleChange}
              inputLabelProps={{ shrink: true }}
              key="custom10"
              label={t('entities:Part.PartCustom10')}
              maxRows={3}
              multiline
              name="custom10"
              size="small"
              sxProps={spanFullWidth}
              value={formik?.values.custom10}
            />
          </Grid>
        )}

        {isCustomCaption(
          'custom11',
          t('entities:Part.PartCustom11'),
          'Part',
          18
        ) && (
          <Grid item md={3} xs={12}>
            <ETOTextField
              disabled={disableInputs.common}
              handleChange={handleChange}
              inputLabelProps={{ shrink: true }}
              key="custom11"
              label={t('entities:Part.PartCustom11')}
              maxRows={3}
              multiline
              name="custom11"
              size="small"
              sxProps={spanFullWidth}
              value={formik?.values.custom11}
            />
          </Grid>
        )}

        {isCustomCaption(
          'custom12',
          t('entities:Part.PartCustom12'),
          'Part',
          18
        ) && (
          <Grid item md={3} xs={12}>
            <ETOTextField
              disabled={disableInputs.common}
              handleChange={handleChange}
              inputLabelProps={{ shrink: true }}
              key="custom12"
              label={t('entities:Part.PartCustom12')}
              maxRows={3}
              multiline
              name="custom12"
              size="small"
              sxProps={spanFullWidth}
              value={formik?.values.custom12}
            />
          </Grid>
        )}

        {isCustomCaption(
          'custom13',
          t('entities:Part.PartCustom13'),
          'Part',
          18
        ) && (
          <Grid item md={3} xs={12}>
            <ETOTextField
              disabled={disableInputs.common}
              handleChange={handleChange}
              inputLabelProps={{ shrink: true }}
              key="custom13"
              label={t('entities:Part.PartCustom13')}
              maxRows={3}
              multiline
              name="custom13"
              size="small"
              sxProps={spanFullWidth}
              value={formik?.values.custom13}
            />
          </Grid>
        )}
      </Grid>
      <Divider />
      <Grid container spacing={2}>
        <Grid item md={3} xs={12}>
          <ETOCheckBox
            disabled={disableInputs.common}
            handleChange={_handleCheckBoxChange}
            label={t('entities:Part.ItemRepairable')}
            name="itemRepairable"
            size="small"
            value={formik.values.itemRepairable ?? false}
          />
        </Grid>
        <Grid item md={3} xs={12}>
          <ETOCheckBox
            disabled={disableInputs.common}
            handleChange={_handleCheckBoxChange}
            label={t('entities:Part.ItemRelub')}
            name="itemRelub"
            size="small"
            value={formik.values.itemRelub ?? false}
          />
        </Grid>
        <Grid item md={3} xs={12}>
          <ETOCheckBox
            disabled={disableInputs.common}
            handleChange={_handleCheckBoxChange}
            label={t('entities:Part.ItemCertifiedPrints')}
            name="itemCertifiedPrints"
            size="small"
            value={formik.values.itemCertifiedPrints ?? false}
          />
        </Grid>
        <Grid item md={3} xs={12}>
          <ETOCheckBox
            disabled={disableInputs.common}
            handleChange={_handleCheckBoxChange}
            label={t('entities:Part.ItemMaintenance')}
            name="itemMaintenance"
            size="small"
            value={formik.values.itemMaintenance ?? false}
          />
        </Grid>

        {panelMode === 'edit' && (
          <>
            <Grid item md={3} xs={12}>
              <ETOCheckBox
                disabled // this is always disabled
                handleChange={_handleCheckBoxChange}
                label={t('entities:Part.HasDrawing')}
                name="hasDrawing"
                size="small"
                value={Boolean(formik.values.hasDrawing)}
              />
            </Grid>
            <Grid item md={3} xs={12}>
              <ETOCheckBox
                disabled={disableInputs.lock}
                handleChange={_handleCheckBoxChange}
                label={t('entities:Part.ItemReserved')}
                name="itemReserved"
                size="small"
                value={formik.values.itemReserved ?? false}
              />
            </Grid>
            <Grid item md={3} xs={12}>
              <ETOCheckBox
                disabled={disableInputs.lock}
                handleChange={_handleCheckBoxChange}
                label={t('entities:Part.Obsolete')}
                name="obsolete"
                size="small"
                value={formik.values.obsolete ?? false}
              />
            </Grid>
          </>
        )}

        {isCustomCaption(
          'custom7',
          t('entities:Part.PartCustom7'),
          'Part',
          18
        ) && (
          <Grid item md={3} xs={12}>
            <ETOCheckBox
              disabled={disableInputs.common}
              handleChange={_handleCheckBoxChange}
              key="custom7"
              label={t('entities:Part.PartCustom7')}
              name="custom7"
              size="small"
              value={formik?.values.custom7 ?? false}
            />
          </Grid>
        )}
        {isCustomCaption(
          'custom8',
          t('entities:Part.PartCustom8'),
          'Part',
          18
        ) && (
          <Grid item md={3} xs={12}>
            <ETOCheckBox
              disabled={disableInputs.common}
              handleChange={_handleCheckBoxChange}
              key="custom8"
              label={t('entities:Part.PartCustom8')}
              name="custom8"
              size="small"
              value={formik?.values.custom8 ?? false}
            />
          </Grid>
        )}
        {isCustomCaption(
          'custom14',
          t('entities:Part.PartCustom14'),
          'Part',
          18
        ) && (
          <Grid item md={3} xs={12}>
            <ETOCheckBox
              disabled={disableInputs.common}
              handleChange={_handleCheckBoxChange}
              key="custom14"
              label={t('entities:Part.PartCustom14')}
              name="custom14"
              size="small"
              value={formik?.values.custom14 ?? false}
            />
          </Grid>
        )}
        {isCustomCaption(
          'custom15',
          t('entities:Part.PartCustom15'),
          'Part',
          18
        ) && (
          <Grid item md={3} xs={12}>
            <ETOCheckBox
              disabled={disableInputs.common}
              handleChange={_handleCheckBoxChange}
              key="custom15"
              label={t('entities:Part.PartCustom15')}
              name="custom15"
              size="small"
              value={formik?.values.custom15 ?? false}
            />
          </Grid>
        )}
        {isCustomCaption(
          'custom16',
          t('entities:Part.PartCustom16'),
          'Part',
          18
        ) && (
          <Grid item md={3} xs={12}>
            <ETOCheckBox
              disabled={disableInputs.common}
              handleChange={_handleCheckBoxChange}
              key="custom16"
              label={t('entities:Part.PartCustom16')}
              name="custom16"
              size="small"
              value={formik?.values.custom16 ?? false}
            />
          </Grid>
        )}
        {isCustomCaption(
          'custom16',
          t('entities:Part.PartCustom17'),
          'Part',
          18
        ) && (
          <Grid item md={3} xs={12}>
            <ETOCheckBox
              disabled={disableInputs.common}
              handleChange={_handleCheckBoxChange}
              key="custom16"
              label={t('entities:Part.PartCustom17')}
              name="custom16"
              size="small"
              value={formik?.values.custom16 ?? false}
            />
          </Grid>
        )}
        {isCustomCaption(
          'custom18',
          t('entities:Part.PartCustom18'),
          'Part',
          18
        ) && (
          <Grid item md={3} xs={12}>
            <ETOCheckBox
              disabled={disableInputs.common}
              handleChange={_handleCheckBoxChange}
              key="custom18"
              label={t('entities:Part.PartCustom18')}
              name="custom18"
              size="small"
              value={formik?.values.custom18 ?? false}
            />
          </Grid>
        )}
      </Grid>
    </>
  );
};

export default PartForm;
