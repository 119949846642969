import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import DoNotDisturbOnRoundedIcon from '@mui/icons-material/DoNotDisturbOnRounded';
import { ETOButton, MessageContext } from '@teto/react-component-library-v2';
import React, {
  useCallback,
  useContext,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { Permission, getGraphQLClient } from 'teto-client-api';
import getErrors from '../../../../../api/graphQL/getErrors';

import AuthContext from '../../../../../contexts/AuthContext';
import AccordionList from '../../../components/AccordionList/AccordionList';
import PanelWithNewItemPopover from '../../../components/AddEditInspector/AddEditList/PanelWithNewItemPopover';
import EditingState from '../../../types/EditingState';
import NoRecordsFound from '../../components/NoRecordsFound/NoRecordsFound';
import { NonConformanceAssignment } from '../../interfaces/NonConformanceAssignment';
import AddEditTask from './AddEditTask';
import { nonConformanceAssignmentsQuery } from './queries/nonConformanceAssignmentsQuery';

interface TaskAssignmentsPanelProps {
  nonConformanceId: number | undefined;
  setHasASaveOccurred: React.Dispatch<React.SetStateAction<boolean>>;
  setIsCreatingNewItem: React.Dispatch<React.SetStateAction<boolean>>;
  // eslint-disable-next-line no-unused-vars
  handleEditingState: (value: { isValid: boolean; isEditing: boolean }) => void;
  editingState: EditingState;
}

const TaskAssignmentsPanel = (props: TaskAssignmentsPanelProps) => {
  const {
    nonConformanceId,
    setIsCreatingNewItem,
    setHasASaveOccurred,
    handleEditingState,
    editingState,
  } = props;

  const { t } = useTranslation();

  const authContext = useContext(AuthContext);
  const messageContext = useContext(MessageContext);
  const buttonRef = useRef<HTMLButtonElement | null>(null);

  const canAddTasks = useMemo(
    () =>
      authContext.hasPermission(
        Permission.Add_Manufacturing_NonConformances_TaskAssignments
      ),
    [authContext]
  );

  const [isNewTaskOpen, setIsNewTaskOpen] = useState<boolean>(false);
  const [selectedTaskItem, setSelectedTaskItem] =
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    useState<any | undefined>();
  const [taskAssignments, setTaskAssignments] = useState<
    NonConformanceAssignment[]
  >([]);
  const [confirmAbandonForm, setConfirmAbandonForm] = useState<boolean>(false);

  const _onAddTaskClicked = () => {
    setIsNewTaskOpen(true);
  };

  React.useEffect(
    () => setIsCreatingNewItem(isNewTaskOpen),
    [isNewTaskOpen, setIsCreatingNewItem]
  );

  const _updateRecords = useCallback(() => {
    if (nonConformanceId) {
      getGraphQLClient()
        .performQuery(nonConformanceAssignmentsQuery, {
          id: nonConformanceId,
        })
        .then((e) => {
          if (e.hasError()) {
            e.showAllSystemErrors(messageContext.setError);
            if (e.hasValidationErrors()) {
              const errors = getErrors(e.validationErrors);
              messageContext.setError(errors);
            }
            return;
          }
          setTaskAssignments(
            e.data.nonConformanceAssignments
              ?.items as NonConformanceAssignment[]
          );
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nonConformanceId]);

  React.useEffect(() => {
    _updateRecords();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nonConformanceId]);

  const listItems = useMemo(
    () =>
      taskAssignments.map((a) => ({
        ...a,
        id: a.id,
        title: a.task ?? '-',
        subTitles: [
          `${t('pages.nonConformance.assignedBy')}: ${
            a.assignedByEmployee?.name ?? '(none)'
          } -
          ${t('pages.nonConformance.assignedTo')}: ${
            a.assignedToEmployee?.name ?? '(none)'
          }`,
        ],
        icon: a.complete ? (
          <CheckCircleRoundedIcon color="success" />
        ) : (
          <DoNotDisturbOnRoundedIcon />
        ),
      })),
    [t, taskAssignments]
  );

  const _onTaskSaved = useCallback(() => {
    _updateRecords();
    setHasASaveOccurred(true);
    setIsNewTaskOpen(false);
  }, [_updateRecords, setHasASaveOccurred]);

  return (
    <PanelWithNewItemPopover
      addButtonRef={buttonRef}
      addItemButton={
        <ETOButton
          buttonProps={{
            ref: buttonRef,
          }}
          color="primary"
          disabled={!canAddTasks}
          onClick={() => _onAddTaskClicked()}
          size="medium"
        >
          {t('pages.nonConformance.addTask')}
        </ETOButton>
      }
      editingState={editingState}
      isNewItemOpen={isNewTaskOpen}
      newItemComponent={
        nonConformanceId && (
          <AddEditTask
            confirmAbandonForm={confirmAbandonForm}
            handleEditingState={handleEditingState}
            nonConformanceId={nonConformanceId}
            onRecordCountChanged={_updateRecords}
            onSaveSuccess={_onTaskSaved}
            record={undefined}
            setConfirmAbandonForm={setConfirmAbandonForm}
            setIsNewTaskOpen={setIsNewTaskOpen}
            setSelectedTaskItem={setSelectedTaskItem}
          />
        )
      }
      setConfirmAbandonForm={setConfirmAbandonForm}
      setIsNewItemOpen={setIsNewTaskOpen}
    >
      {taskAssignments.length === 0 && <NoRecordsFound />}
      {taskAssignments.length > 0 && nonConformanceId && (
        <AccordionList
          editingState={editingState}
          expandedComponent={
            <AddEditTask
              confirmAbandonForm={confirmAbandonForm}
              handleEditingState={handleEditingState}
              nonConformanceId={nonConformanceId}
              onRecordCountChanged={_updateRecords}
              onSaveSuccess={_onTaskSaved}
              record={selectedTaskItem}
              setConfirmAbandonForm={setConfirmAbandonForm}
              setIsNewTaskOpen={setIsNewTaskOpen}
              setSelectedTaskItem={setSelectedTaskItem}
            />
          }
          expandedItem={selectedTaskItem}
          listItems={listItems}
          onItemExpandStateChanged={(ta) => setSelectedTaskItem(ta)}
          setConfirmAbandonForm={setConfirmAbandonForm}
        />
      )}
    </PanelWithNewItemPopover>
  );
};

export default TaskAssignmentsPanel;
