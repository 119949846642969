import type { TypedDocumentNode } from '@graphql-typed-document-node/core';
import DeleteIcon from '@mui/icons-material/Delete';
import {
  ConfirmDialogs,
  formatHoursAsHourMin,
  MessageContext,
} from '@teto/react-component-library-v2';
import { Dayjs } from 'dayjs';
import React, {
  Dispatch,
  SetStateAction,
  forwardRef,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import {
  Licenses,
  Permission,
  QueryValidationError,
  getGraphQLClient,
} from 'teto-client-api';
import {
  AddProcessScheduleDetailRequestInput,
  Process,
  ProcessScheduleDetail,
  UpdateProcessScheduleDetailRequestInput,
} from '../../../../../../__generated__/graphql';
import buildMutation from '../../../../../../api/graphQL/buildMutation';
import AuthContext from '../../../../../../contexts/AuthContext';
import ProcessScheduleDetailsView from '../../../../../../views/ProcessScheduleDetailsView.yaml';
import handleNumberInputChange from '../../../../../Inspectors/CompanyDetailInspector/helpers/companyHelpers';
import { getProcesses } from '../../../../../TETOForms/selects/selects';
import { AddNewRowValue } from '../../../../../TETOGridGraphQL/Editors/AddNewDataRowEditor/AddNewDataRowEditor';
import ActionButtonGridBuilderExtension from '../../../../../TETOGridGraphQL/GridBuilder/ActionButtonGridBuilderExtension';
import deletePSDetailMutation from '../../../queries/deletePSDetailMutation';

import getErrors from '../../../../../../api/graphQL/getErrors';
import useSubmitNewRow from '../../../../../TETOGridGraphQL/Editors/AddNewDataRowEditor/useSubmitNewRow';
import { TETODataColumn } from '../../../../../TETOGridGraphQL/GridBuilder/types/TETODataColumn';
import MainTetoGridGraphQL from '../../../../../TETOGridGraphQL/TETOMainGridGraphQL';
import useGrid from '../../../../../TETOGridGraphQL/hooks/useGrid';
import { useGridBuilderFromView } from '../../../../../TETOGridGraphQL/hooks/useGridBuilder';
import { CellChangingArgs } from '../../../../../TETOGridGraphQL/types/CellChangingArgs';
import TETOGridRefType from '../../../../../TETOGridGraphQL/types/TETOGridRefType';
import { TetoGridGraphqlProps } from '../../../../../TETOGridGraphQL/types/TetoGridGraphqlProps';
import addPSDetailMutation from '../../../queries/addPSDetailMutation';
import updatePSDetailMutation from '../../../queries/updatePSDetailMutation';
import inlineEditSchema from './inlineEditSchema';

interface ProcessScheduleDetailsGridProps
  extends Partial<TetoGridGraphqlProps> {
  completionDate?: Dayjs;
  processScheduleId?: number;
  // eslint-disable-next-line no-unused-vars
  setHasASaveOccurred: (val: boolean) => void;
  setResetGrid: Dispatch<SetStateAction<boolean>>;
  resetGrid: boolean;
  status: string;
}

const ROOT_QUERY_PATH = 'processScheduleDetails';
const PERSISTANCE_NAME = 'process-schedule-details-grid';
const UI_ADD_EDITABLE_FIELDS = ['extHours', 'extOSCost'];
const CO_DEPENDENT_FIELDS = [
  'unitEstimate',
  'setupHours',
  'estimateHours',
  'processId',
  'extendedEstimateHours',
  ...UI_ADD_EDITABLE_FIELDS,
];
const EDITABLE_INTEGER = ['processId', 'sequence'];
const EDITABLE_DECIMAL = [
  'custom3',
  'custom4',
  'inHouseRate',
  'setupHours',
  'totalHours',
  'unitEstimate',
];
const EDITABLE_COLUMNS = [
  'custom1',
  'custom2',
  'custom5',
  'custom6',
  'custom7',
  'custom8',
  'inHouse',
  'notes',
  'requiredDate',
  'estimateHours',
  'extendedEstimateHours',
  ...EDITABLE_INTEGER,
  ...EDITABLE_DECIMAL,
];

const ALWAYS_PROJECT_COLUMNS = [
  'ordinal',
  'process.name',
  'processSchedule.quantity',
  'processScheduleId',
  'quantityIssued',
  'quantityOrdered',
  'quantityReceived',
  ...EDITABLE_COLUMNS,
];

const ProcessScheduleDetailsGrid = (
  _props: ProcessScheduleDetailsGridProps,
  ref: React.Ref<TETOGridRefType> | undefined | null
) => {
  const {
    setHasASaveOccurred,
    processScheduleId,
    status,
    completionDate,
    resetGrid,
    setResetGrid,
  } = _props;
  const { ready, t } = useTranslation();
  const messageContext = useContext(MessageContext);
  const authContext = useContext(AuthContext);

  const [newRowData, setNewRowData] = useState({});
  const [isConfirmDeleteProcess, setIsConfirmDeleteProcess] = useState(false);
  const [processIdNumber, setProcessIdNumber] = useState<number | undefined>();

  const { canModify, canDelete } = useMemo(
    () => ({
      canModify:
        authContext.hasLicense(Licenses.TotalETOProfessional) &&
        authContext.hasPermission(
          Permission.Modify_Procurement_ProcessSchedules_ProcessScheduleDetails
        ),
      canDelete:
        authContext.hasLicense(Licenses.TotalETOProfessional) &&
        authContext.hasPermission(
          Permission.Delete_Procurement_ProcessSchedules_ProcessScheduleDetails
        ),
    }),
    [authContext]
  );

  const _handleShouldDisableCell = useCallback(
    (columnName: string, data: Partial<ProcessScheduleDetail>): boolean => {
      if (data?.lastReceivedCompletedDate) return true;

      switch (columnName) {
        case 'sequence':
        case 'processId':
          return (data?.quantityIssued ?? 0) > 0;
        case 'inHouse':
          if (data?.inHouse) return (data?.quantityReceived ?? 0) > 0;
          return (data?.quantityOrdered ?? 0) > 0;
        case 'totalHours':
        case 'estimateHours':
        case 'extendedEstimateHours':
          return !data?.inHouse;
        case 'inHouseRate':
        case 'setupHours':
          return data?.inHouse ?? false;
        case 'unitEstimate':
          return data?.inHouse ?? false;
        case 'extHours':
          return !data?.inHouse || (data?.processSchedule?.quantity ?? 0) === 0;
        case 'extOSCost':
          return data?.inHouse || (data?.processSchedule?.quantity ?? 0) === 0;
        case 'requiredDate':
          return Boolean(completionDate);
        default:
          return false;
      }
    },
    [completionDate]
  );

  const mandatoryFilters = useMemo(() => {
    const filters = [
      {
        name: 'processScheduleId',
        operator: 'equal',
        value: processScheduleId,
        type: 'number' as const,
      },
    ];
    return filters;
  }, [processScheduleId]);

  const { gridBuilder, builderReady, error, hasError } = useGridBuilderFromView(
    ProcessScheduleDetailsView,
    ROOT_QUERY_PATH,
    (gb) =>
      gb
        .addColumn({
          name: 'extHours',
          title: t('pages.processSchedule.extHours'),
          type: 'hours',
          align: 'end',
          sortable: true,
          filterType: 'hours',
          filterable: undefined,
          fixed: 'none',
          disableGrouping: true,
          groupSummaryReducer: undefined,
          showByDefault: false,
          disableHideable: false,
          disableColumnMenuTool: false,
          includeInMobile: false,
          width: 144,
          minWidth: 70,
          maxWidth: 144,
          editable: canModify,
          editorProps: {
            supportsDecimals: true,
            disabled: (data: Partial<ProcessScheduleDetail>) =>
              _handleShouldDisableCell('extHours', data),
          },
          locked: undefined,
        })
        .addColumn({
          name: 'extOSCost',
          title: t('pages.processSchedule.extOSCost'),
          type: 'currency',
          align: undefined,
          sortable: true,
          isPrimaryKey: false,
          filterType: 'number',
          filterable: undefined,
          fixed: 'none',
          disableGrouping: true,
          groupSummaryReducer: undefined,
          showByDefault: false,
          disableHideable: false,
          disableColumnMenuTool: false,
          includeInMobile: false,
          resizable: true,
          width: 144,
          minWidth: 70,
          maxWidth: 144,
          editable: canModify,
          editorProps: {
            supportsDecimals: true,
            disabled: (data: Partial<ProcessScheduleDetail>) =>
              _handleShouldDisableCell('extOSCost', data),
          },
          locked: undefined,
        })
        .updateDefinition('processId', {
          editable: canModify,
          type: 'select',
          title: t('entities:Process.Name'),
          editorProps: {
            disabled: (data: Partial<ProcessScheduleDetail>) =>
              _handleShouldDisableCell('processId', data),
            queryString: getProcesses.query,
            itemNameSelector: (i: Process) =>
              i[getProcesses.labelField as keyof Process],
            itemValueSelector: (i: Process) =>
              i[getProcesses.valueField as keyof Process],
            queryResultPath: 'processes',
          },
          render: ({ data }: { data: Partial<ProcessScheduleDetail> }) =>
            data.process?.name,
        })
        .updateDefinition('process.name', {
          hidden: true,
        })
        .updateDefinition('sequence', {
          editable: canModify,
          editorProps: {
            disabled: (data: Partial<ProcessScheduleDetail>) =>
              _handleShouldDisableCell('sequence', data),
            // sequence value must be unique in throughout the dataset
          },
        })
        .updateDefinition('estimateHours', {
          editable: canModify,
          editorProps: {
            supportsDecimals: true,
            disabled: (data: Partial<ProcessScheduleDetail>) =>
              _handleShouldDisableCell('estimateHours', data),
          },
        })
        .updateDefinition('extendedEstimateHours', {
          editable: canModify,
          editorProps: {
            supportsDecimals: true,
            disabled: (data: Partial<ProcessScheduleDetail>) =>
              _handleShouldDisableCell('extendedEstimateHours', data),
          },
        })
        .updateDefinition('inHouseRate', {
          editable: canModify,
          editorProps: {
            supportsDecimals: true,
            disabled: (data: Partial<ProcessScheduleDetail>) =>
              _handleShouldDisableCell('inHouseRate', data),
          },
          render: ({ data }) =>
            formatHoursAsHourMin(formatSetupEstimateHours(data)),
        })
        .updateDefinition('setupHours', {
          editable: canModify,
          editorProps: {
            supportsDecimals: true,
            disabled: (data: Partial<ProcessScheduleDetail>) =>
              _handleShouldDisableCell('setupHours', data),
          },
        })
        .updateDefinition('totalHours', {
          editable: canModify,
          editorProps: {
            supportsDecimals: true,
            disabled: (data: Partial<ProcessScheduleDetail>) =>
              _handleShouldDisableCell('totalHours', data),
          },
        })
        .updateDefinition('unitEstimate', {
          editable: canModify,
          editorProps: {
            supportsDecimals: true,
            disabled: (data: Partial<ProcessScheduleDetail>) =>
              _handleShouldDisableCell('unitEstimate', data),
          },
        })
        .updateDefinition('inHouse', {
          editable: canModify,
          editorProps: {
            disabled: (data: Partial<ProcessScheduleDetail>) =>
              _handleShouldDisableCell('inHouse', data),
          },
        })
        .updateDefinition('notes', {
          editable: canModify,
          editorProps: {
            disabled: (data: Partial<ProcessScheduleDetail>) =>
              _handleShouldDisableCell('notes', data),
          },
        })
        .updateDefinition('requiredDate', {
          editable: canModify,
          editorProps: {
            disabled: Boolean(completionDate),
          },
        })
        .updateDefinition('custom1', {
          editable: canModify,
          editorProps: {
            disabled: (data: Partial<ProcessScheduleDetail>) =>
              _handleShouldDisableCell('custom1', data),
          },
        })
        .updateDefinition('custom2', {
          editable: canModify,
          editorProps: {
            disabled: (data: Partial<ProcessScheduleDetail>) =>
              _handleShouldDisableCell('custom2', data),
          },
        })
        .updateDefinition('custom3', {
          editable: canModify,
          editorProps: {
            supportsDecimals: true,
            disabled: (data: Partial<ProcessScheduleDetail>) =>
              _handleShouldDisableCell('custom3', data),
          },
        })
        .updateDefinition('custom4', {
          editable: canModify,
          editorProps: {
            supportsDecimals: true,
            disabled: (data: Partial<ProcessScheduleDetail>) =>
              _handleShouldDisableCell('custom4', data),
          },
        })
        .updateDefinition('custom5', {
          editable: canModify,
          editorProps: {
            disabled: (data: Partial<ProcessScheduleDetail>) =>
              _handleShouldDisableCell('custom5', data),
          },
        })
        .updateDefinition('custom6', {
          editable: canModify,
          editorProps: {
            disabled: (data: Partial<ProcessScheduleDetail>) =>
              _handleShouldDisableCell('custom6', data),
          },
        })
        .updateDefinition('custom7', {
          editable: canModify,
          editorProps: {
            allowUnset: true,
            editorProps: {
              disabled: (data: Partial<ProcessScheduleDetail>) =>
                _handleShouldDisableCell('custom7', data),
            },
          },
        })
        .updateDefinition('custom8', {
          editable: canModify,
          editorProps: {
            disabled: (data: Partial<ProcessScheduleDetail>) =>
              _handleShouldDisableCell('custom8', data),
          },
        })
        .updateDefinition('processScheduleId', {
          linkTo: undefined,
        })
        .extension(
          new ActionButtonGridBuilderExtension([
            {
              disabled: (data) =>
                data?.processSchedule?.completionDate === null || !canDelete,
              componentName: t('pages.processSchedule.deleteProcess'),
              icon: <DeleteIcon />,
              title: t('pages.processSchedule.deleteProcess'),
              onClick: ({ data }) => _handleDeleteProcess(data),
              color: 'error',
            },
          ]),
          t
        )
  );

  const { gridProps } = useGrid(
    PERSISTANCE_NAME,
    `${ROOT_QUERY_PATH}.items`,
    (e) => messageContext.setError(e.message ?? e),
    t,
    ALWAYS_PROJECT_COLUMNS,
    gridBuilder,
    {
      filterAndSortMode: 'serverSide',
      mandatoryFilter: mandatoryFilters,
    }
  );

  const formatEstimateHours = (data: Partial<ProcessScheduleDetail>) => {
    if (data && data.processSchedule && data.processSchedule.quantity) {
      return (
        data.processSchedule.quantity * data.estimateHours + data.setupHours
      );
    }
    return 0;
  };

  const formatSetupEstimateHours = (data: Partial<ProcessScheduleDetail>) => {
    if (data && data.processSchedule && data.processSchedule.quantity) {
      return (
        data.processSchedule.quantity * data.estimateHours + data.setupHours
      );
    }
    return 0;
  };

  const formatExtHours = (
    data: Partial<ProcessScheduleDetail & { extHours?: number }>
  ) => {
    if (
      data &&
      data.extHours &&
      data.processSchedule &&
      data.processSchedule.quantity
    ) {
      return (data.extHours - data.setupHours) / data.processSchedule.quantity;
    }
    return 0;
  };

  const formatExtOSCost = (
    data: Partial<ProcessScheduleDetail & { extOSCost?: number }>
  ) => {
    if (data && data.processSchedule && data.processSchedule.quantity) {
      return (data.extOSCost ?? 0) / data.processSchedule.quantity;
    }
    return 0;
  };

  const _handleNewDataSwitchLogic = (
    data: Record<string, unknown>,
    name: string
  ) => {
    switch (name) {
      case 'unitEstimate':
        // eslint-disable-next-line no-param-reassign
        data.estimateHours = formatEstimateHours(data);
        break;
      case 'setupHours':
      case 'estimateHours':
        // eslint-disable-next-line no-param-reassign
        data.extHours = formatSetupEstimateHours(data);
        // eslint-disable-next-line no-param-reassign
        data.extendedEstimateHours = formatSetupEstimateHours(data);
        break;
      case 'extOSCost':
        // eslint-disable-next-line no-param-reassign
        data.unitEstimate = formatExtOSCost(data);
        break;
      case 'extHours':
        // eslint-disable-next-line no-param-reassign
        data.estimateHours = formatExtHours(data);
        break;

      default:
        break;
    }
  };
  const _handleCellChanging = useCallback(
    (change: CellChangingArgs) => {
      const {
        column: { name },
        editValue: { value, originalValue },
        newData,
        changeContext,
      } = change;

      const handleError = (e: {
        validationErrors: { input: Record<string, string> };
        hasSystemErrors: () => boolean;
        showAllSystemErrors: () => void;
      }) => {
        const visibleColumnsList = gridProps?.visibleColumns.map((i) => i.name);

        if (e.validationErrors) {
          const { input: validationErrors } = e.validationErrors;
          const visibleValidations: { [key: string]: string } = {};
          let hiddenValidationMessage = '';

          Object.entries(validationErrors).forEach(([key, message]) => {
            if (visibleColumnsList.includes(key)) {
              visibleValidations[key] = message;
            } else {
              hiddenValidationMessage += `${key}: ${message}\n`;
            }
          });

          if (hiddenValidationMessage.length > 0) {
            messageContext.setError(hiddenValidationMessage);
          }
          if (Object.keys(visibleValidations).length > 0) {
            changeContext.setValidationErrors(visibleValidations);
          }
        }

        changeContext.cancelChange();
        changeContext.setColumnValue(name as string, originalValue);
      };

      const tempData = { ...newData };
      if (name === 'extendedEstimateHours') {
        if (tempData.processSchedule?.quantity) {
          tempData.estimateHours =
            (value - tempData.setupHours) / tempData.processSchedule.quantity;
        }
      }
      [...EDITABLE_DECIMAL, ...UI_ADD_EDITABLE_FIELDS].forEach((i) => {
        tempData[i] = handleNumberInputChange({
          value: tempData[i],
          type: 'decimal',
        });
      });

      EDITABLE_INTEGER.forEach((i) => {
        if (tempData[i]) {
          tempData[i] = handleNumberInputChange({
            value: tempData[i],
            type: 'int',
          });
        }
      });

      _handleNewDataSwitchLogic(tempData, name as string);

      const input: UpdateProcessScheduleDetailRequestInput = {
        custom1: tempData?.custom1 ?? '',
        custom2: tempData?.custom2 ?? '',
        custom3: tempData?.custom3,
        custom4: tempData?.custom4,
        custom5: tempData?.custom5,
        custom6: tempData?.custom6,
        custom7: tempData?.custom7,
        custom8: tempData?.custom8,
        estimateHours: tempData?.estimateHours ?? 0,
        id: tempData?.id,
        inHouse: tempData?.inHouse,
        inHouseRate: tempData?.inHouseRate ?? 0,
        notes: tempData?.notes ?? '',
        ordinal: tempData?.ordinal,
        processId: tempData?.processId,
        processScheduleId: tempData?.processScheduleId,
        quantityIssued: tempData?.quantityIssued,
        quantityOrdered: tempData?.quantityOrdered,
        quantityReceived: tempData?.quantityReceived ?? 0,
        requiredDate: tempData?.requiredDate,
        sequence: tempData?.sequence,
        setupHours: tempData?.setupHours ?? 0,
        totalHours: tempData?.totalHours,
        unitEstimate: tempData?.unitEstimate ?? 0,
      };

      buildMutation({
        queryString: updatePSDetailMutation as TypedDocumentNode,
        variables: { input },
        callback: (d) => {
          if (d.updateProcessScheduleDetail.id) {
            setHasASaveOccurred(true);
            if (CO_DEPENDENT_FIELDS.indexOf(name as string) >= 0) {
              changeContext.updateDependentColumns({
                ...tempData,
                ...d.updateProcessScheduleDetail,
              });
              changeContext.setColumnValue(name as string, value);
            } else {
              changeContext.setColumnValue(name as string, value);
            }
          }
        },
        messageContext,
        errorCallback: (e) => handleError(e),
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [gridProps?.visibleColumns, setHasASaveOccurred]
  );
  const formatValidationErrors = (
    validationErrors: QueryValidationError
  ): QueryValidationError => {
    const formattedErrors: QueryValidationError = {
      input: {},
    };

    if (validationErrors.request) {
      Object.entries(validationErrors.request).forEach(([key, value]) => {
        formattedErrors.input[key] = value;
      });
    } else if (validationErrors.input) {
      formattedErrors.input = validationErrors?.input;
    } else {
      messageContext.setError(getErrors(validationErrors));
    }

    return formattedErrors;
  };

  const formatOnAdd = (
    values: Partial<ProcessScheduleDetail>
  ): AddProcessScheduleDetailRequestInput => {
    const formattedVal = {
      processScheduleId: values.processScheduleId,
      processId: values.processId ?? 0,
      ordinal: values.ordinal ?? 0,
      sequence: values.sequence ?? 0,
      inHouse: values.inHouse ?? false,
      quantityIssued: values.quantityIssued ?? 0,
      quantityOrdered: values.quantityOrdered ?? 0,
      totalHours: values.totalHours ?? 0,
      requiredDate: values.requiredDate,
      notes: values.notes,
      quantityReceived: values.quantityReceived ?? 0,
      lastWorkedOnDate: values.lastWorkedOnDate,
      lastReceivedCompletedDate: values.lastReceivedCompletedDate,
      unitEstimate: parseFloat(values.unitEstimate ?? 0),
      estimateHours: values.estimateHours,
      inHouseRate: values.inHouseRate ?? 0,
      setupHours: values.setupHours,
      custom1: values.custom1 || undefined,
      custom2: values.custom2 || undefined,
      custom3: values.custom3 || undefined,
      custom4: values.custom4 || undefined,
      custom5: values.custom5 || undefined,
      custom6: values.custom6 || undefined,
      custom7: values.custom7 || undefined,
      custom8: values.custom8 || undefined,
    };

    return formattedVal;
  };

  const mutationVariables = useMemo(
    () => ({
      input: formatOnAdd(newRowData),
    }),
    [newRowData]
  );

  const { shouldResetAddNewRowData, validationErrors } = useSubmitNewRow(
    newRowData,
    gridProps,
    addPSDetailMutation,
    mutationVariables,
    formatValidationErrors
  );

  useEffect(() => {
    if (shouldResetAddNewRowData) {
      setNewRowData({});
    }
  }, [shouldResetAddNewRowData]);

  const handleAddNewDataRow = useCallback(
    (val: AddNewRowValue, col: TETODataColumn) => {
      let _val = val;

      switch (col.type) {
        case 'number': {
          const _v = parseInt(val as string, 10);
          _val = Number.isNaN(_v) ? 0 : _v;
          break;
        }
        default:
          break;
      }
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const tempData = { ...newRowData, [col?.path as string]: _val } as any;

      _handleNewDataSwitchLogic(tempData, col.name);

      setNewRowData({
        ...tempData,
        processScheduleId,
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [newRowData]
  );

  const _handleDeleteProcess = useCallback(
    (data: ProcessScheduleDetail) => {
      if (data?.quantityOrdered > 0 || data?.quantityReceived > 0) {
        messageContext.setError(
          t('pages.processSchedule.deleteQtyOrderedWarning')
        );
      } else if (data?.quantityIssued > 0 && data?.sequence > 0) {
        messageContext.setError(
          t('pages.processSchedule.deleteQtyIssuedWarning')
        );
      } else {
        setIsConfirmDeleteProcess(true);
        setProcessIdNumber(data?.id);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [t]
  );

  const _onConfirmDeleteProcess = useCallback(
    (id: number) => {
      if (!id) return;

      getGraphQLClient()
        .performMutation(deletePSDetailMutation as TypedDocumentNode, {
          id,
        })
        .then((resp) => {
          if (resp.hasError()) {
            resp.showAllSystemErrors(messageContext.setError);
            if (resp.hasValidationErrors()) {
              const { input } = resp.validationErrors;
              messageContext.setError(Object.values(input)[0] as string);
            }
            return;
          }

          if (resp.hasData()) {
            if (resp?.data?.deleteProcessScheduleDetail?.success) {
              messageContext.setSuccess(
                `${t('entities:Process.Process')} deleted successfully`
              );
              gridProps.refreshDataSource();
            }
          }
        });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [gridProps, t]
  );

  useEffect(() => {
    if (resetGrid) {
      gridProps?.refreshDataSource();
      setResetGrid(false);
    }
  }, [_props, gridProps, resetGrid, setResetGrid]);

  const updatedDataSource = useMemo(
    () =>
      gridProps.dataSource.length > 0
        ? gridProps.dataSource.map((d) => {
            const { estimateHours, processSchedule, setupHours, unitEstimate } =
              d;
            const extHours =
              estimateHours === 0 && setupHours === 0
                ? 0
                : (processSchedule?.quantity ?? 0) * estimateHours + setupHours;
            const extOSCost = unitEstimate
              ? (processSchedule?.quantity ?? 0) * unitEstimate
              : 0;

            return {
              ...d,
              extHours,
              extOSCost,
            };
          })
        : gridProps.dataSource,
    [gridProps.dataSource]
  );

  return (
    <>
      <MainTetoGridGraphQL
        alignRefreshIconToRight
        builderReady={builderReady}
        configureInspector={_props.configureInspector}
        editMode={{
          editMode: 'byRow',
          validationSchema: inlineEditSchema,
          onCellChanging: (c: CellChangingArgs) => {
            if (
              [...EDITABLE_COLUMNS, ...UI_ADD_EDITABLE_FIELDS].indexOf(
                c.column.name as string
              ) >= 0
            ) {
              _handleCellChanging(c);
            }
          },
        }}
        error={error}
        externalQueryProps={[]}
        gridProps={{ ...gridProps, dataSource: updatedDataSource }}
        handleAddNewDataRow={handleAddNewDataRow}
        hasAddNewDataRow={status !== 'Completed'}
        hasError={hasError}
        header={{
          hidden: true,
        }}
        ready={ready}
        ref={ref}
        resetAddNewDataRow={shouldResetAddNewRowData}
        setConfigureInspector={_props.setConfigureInspector}
        showEmptyRows
        showGroupSummaryRow={false}
        t={t}
        tableIdentifier={PERSISTANCE_NAME}
        validationErrors={validationErrors}
        {...gridProps}
      />
      {isConfirmDeleteProcess && (
        <ConfirmDialogs
          content={t('dialogs.deleteProcess.content')}
          leftButton={{
            onClick: () => setIsConfirmDeleteProcess(false),
            label: t('generic.no'),
          }}
          open={isConfirmDeleteProcess}
          rightButton={{
            onClick: () => {
              if (processIdNumber) {
                _onConfirmDeleteProcess(processIdNumber);
              }
              setIsConfirmDeleteProcess(false);
            },
            label: t('generic.yes'),
          }}
          title={t('dialogs.deleteProcess.title')}
        />
      )}
    </>
  );
};

export default forwardRef(ProcessScheduleDetailsGrid);
