import { Box, Skeleton, SxProps, Theme } from '@mui/material';
import { MessageContext } from '@teto/react-component-library-v2';
import { isEmpty } from 'lodash';
import React, { useContext, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Licenses, Permission } from 'teto-client-api';
import { useAtom } from 'jotai';
import { ProcessScheduleSharedState } from '../../../../SharedStateComponents/StateContainers/ProcessScheduleState';
import { gql } from '../../../../../__generated__/gql';
import { ProcessScheduleHeader } from '../../../../../__generated__/graphql';
import useGQLQuery from '../../../../../api/graphQL/useGQLQuery';
import AuthContext from '../../../../../contexts/AuthContext';
import AddEditFormDetailsPanel from '../../../../Inspectors/components/AddEditInspector/components/AddEditFormDetailsPanel';
import EditingState from '../../../../Inspectors/types/EditingState';
import { InspectorActions } from '../../../../Inspectors/types/InspectorActionTypes';
import { PanelState } from '../../../../Inspectors/types/PanelState';
import TETOGridRefType from '../../../../TETOGridGraphQL/types/TETOGridRefType';
import processScheduleHeaderQuery from '../../queries/processScheduleHeaderQuery ';
import ProcessScheduleActionBar from './ProcessScheduleActionBar/ProcessScheduleActionBar';
import ProcessScheduleDetailsGrid from './ProcessScheduleDetailsGrid/ProcessScheduleDetailsGrid';
import ProcessScheduleCreateHeaderForm from './ProcessScheduleHeaderForm/ProcessScheduleCreateHeaderForm/ProcessScheduleCreateHeaderForm';
import ProcessScheduleEditHeaderForm from './ProcessScheduleHeaderForm/ProcessScheduleEditHeaderForm/ProcessScheduleEditHeaderForm';

const lastPSQuery =
  gql(`query getNextPSNumber($projectId: Int!, $specId: Float!) {
nextProcessScheduleNumber(projectId: $projectId, specId: $specId) {
  number
}
}`);

type IPanelActions = Omit<
  InspectorActions<ProcessScheduleHeader>,
  'setCurrentValues'
> & {
  setPanelState: React.Dispatch<React.SetStateAction<PanelState>>;
};

interface ProcessScheduleDetailsPanelProps extends IPanelActions {
  editingState: EditingState;
  panelState: PanelState;
}

const contentSx: SxProps<Theme> = {
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  overflow: 'auto',
};

const ProcessScheduleDetailsPanel = (
  props: ProcessScheduleDetailsPanelProps
) => {
  const {
    editingState,
    panelState,
    setEditingState,
    setHasASaveOccurred,
    setIsCreatingNewItem,
    setPanelState,
  } = props;

  const { t } = useTranslation();
  const messageContext = useContext(MessageContext);
  const authContext = useContext(AuthContext);
  const gridRef = useRef<TETOGridRefType>(null);

  const [creatingPS] = useAtom(ProcessScheduleSharedState);
  const [formDataLoading, setFormDataLoading] = useState<boolean>(true);
  const [processSchedule, setProcessSchedule] =
    useState<ProcessScheduleHeader>();
  const [lastPSNumber, setLastPSNumber] = useState('');

  const [resetGrid, setResetGrid] = useState<boolean>(false);
  const [configureInspectorOpen, setConfigureInspectorOpen] =
    useState<boolean>(false);
  const [isInitialized, setIsInitialized] = useState<boolean>(false);

  const readOnly = authContext.hasReadOnlyLicense();

  const processSchedulePermissions = useMemo(() => {
    const canModify =
      authContext.hasLicense(Licenses.TotalETOProfessional) &&
      authContext.hasPermission(Permission.Modify_Procurement_ProcessSchedules);
    const canAdd =
      authContext.hasLicense(Licenses.TotalETOProfessional) &&
      authContext.hasPermission(Permission.Add_Procurement_ProcessSchedules);
    const modifyPerm = () => {
      if (panelState.mode === 'create') {
        return canAdd;
      }
      if (readOnly) {
        return false;
      }
      if (panelState.mode === 'edit') {
        return canModify;
      }
      return true;
    };
    return {
      canAdd,
      canModify: modifyPerm(),
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authContext, panelState.mode, readOnly]);

  useGQLQuery(['processScheduleHeaderQuery', panelState.id], {
    queryString: processScheduleHeaderQuery,
    variables: {
      id: panelState.id,
    },
    callback: (resp) => {
      const propNames = Object.getOwnPropertyNames(resp);
      if (propNames.length === 0) {
        return messageContext.setError(t('generic.messages.noRecordsFound'));
      }
      const queryKey = propNames[0];

      setProcessSchedule(resp[queryKey]);
      if (panelState.mode !== 'edit') {
        setPanelState({
          id: panelState.id,
          mode: 'edit',
        });
      }

      if (resp[queryKey].status.description === 'Initialized') {
        setIsInitialized(true);
      } else {
        setIsInitialized(false);
      }
    },
    options: {
      refetchOnWindowFocus: false,
      enabled: Boolean(panelState.id),
    },
  });

  useGQLQuery<{
    processScheduleHeaders: { items: ProcessScheduleHeader[] };
  }>(['createProcessScheduleHeaders'], {
    queryString: lastPSQuery,
    variables: {
      projectId: creatingPS.itemsToAdd?.[0]?.purchasingSummary?.projectId ?? 0,
      specId: creatingPS.itemsToAdd?.[0]?.purchasingSummary?.specId ?? 0,
    },
    options: { refetchOnWindowFocus: true },
    callback: (d) => {
      const nextNumber = d?.nextProcessScheduleNumber?.number;
      setLastPSNumber(nextNumber);
      setFormDataLoading(false);
    },
    finallyCallback: () => {
      setFormDataLoading(false);
    },
  });

  return (
    <AddEditFormDetailsPanel
      disableButtonStrip
      editingState={editingState}
      panelMode={panelState.mode}
      setIsCreatingNewItem={setIsCreatingNewItem}
    >
      {panelState.mode === 'create' && !formDataLoading && (
        <ProcessScheduleCreateHeaderForm
          lastPSNumber={lastPSNumber}
          setEditingState={setEditingState}
          setHasASaveOccurred={setHasASaveOccurred}
          setPanelState={setPanelState}
        />
      )}

      {panelState.mode === 'edit' && processSchedule && (
        <>
          <ProcessScheduleActionBar
            gridRef={gridRef}
            isInitialized={isInitialized}
            processSchedule={processSchedule}
            setConfigureInspectorOpen={setConfigureInspectorOpen}
            setIsInitialized={setIsInitialized}
            setResetGrid={setResetGrid}
          />
          <Box sx={contentSx}>
            {!isEmpty(processSchedule) ? (
              <ProcessScheduleEditHeaderForm
                disabled={!processSchedulePermissions.canModify}
                processSchedule={processSchedule}
                setProcessSchedule={setProcessSchedule}
                setResetGrid={setResetGrid}
              />
            ) : (
              <Skeleton height={600} width="100%" />
            )}

            <ProcessScheduleDetailsGrid
              completionDate={processSchedule?.completionDate}
              configureInspector={configureInspectorOpen}
              processScheduleId={processSchedule?.id}
              ref={gridRef}
              resetGrid={resetGrid}
              setConfigureInspector={setConfigureInspectorOpen}
              setHasASaveOccurred={setHasASaveOccurred}
              setResetGrid={setResetGrid}
              status={processSchedule?.status?.description || ''}
            />
          </Box>
        </>
      )}
    </AddEditFormDetailsPanel>
  );
};

export default ProcessScheduleDetailsPanel;
