const getCompanyNotesQuery = `query companyNotes($companyId: Int!) {
  companyNotes(where: { companyId: {eq: $companyId}}) {
    items {
      employee {
        id
        name
      }
      id
      note
      noteDate
    }
  }
}`;

export default getCompanyNotesQuery;
