import { CellProps } from '@inovua/reactdatagrid-community/types';
import CallSplitRoundedIcon from '@mui/icons-material/CallSplitRounded';
import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded';
import LanIcon from '@mui/icons-material/Lan';
import ManageHistoryIcon from '@mui/icons-material/ManageHistory';
import MoreVertRoundedIcon from '@mui/icons-material/MoreVertRounded';
import ReportGmailerrorredIcon from '@mui/icons-material/ReportGmailerrorred';
import SettingsRoundedIcon from '@mui/icons-material/SettingsRounded';
import {
  ActionButton,
  CurrencyFormatter,
  MessageContext,
  OkCancelConfirmDialog,
} from '@teto/react-component-library-v2';
import { useSetAtom } from 'jotai';
import React, {
  Dispatch,
  SetStateAction,
  forwardRef,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { Permission } from 'teto-client-api';
import {
  NonConformance,
  PurchaseOrderDetail,
  UomDescription,
  UpdatePurchaseOrderDetailInput,
  UpdatePurchaseOrderDetailOrderInput,
} from '../../../../../../__generated__/graphql';
import buildMutation from '../../../../../../api/graphQL/buildMutation';
import AuthContext from '../../../../../../contexts/AuthContext';
import SettingsContext from '../../../../../../contexts/SettingsContext';
import useIsMobile from '../../../../../../hooks/useIsMobile';
import PurchaseOrderDetailsInterface from '../../../../../../views/PurchaseOrderDetailsInterfaceView.yaml';
import handleNumberInputChange from '../../../../../Inspectors/CompanyDetailInspector/helpers/companyHelpers';
import OpenNCGrid from '../../../../../OpenNCGrid/OpenNCGrid';
import LinkButtonWithHoverIcon from '../../../../../SharedStateComponents/LinkButtonWithHoverIcon';
import { BreadCrumbSharedState } from '../../../../../SharedStateComponents/StateContainers/BreadCrumbModalState';
import { ItemMasterSharedState } from '../../../../../SharedStateComponents/StateContainers/ItemMasterState';
import { PartHistorySharedState } from '../../../../../SharedStateComponents/StateContainers/PartHistoryState';
import { ProcBreakdownSharedState } from '../../../../../SharedStateComponents/StateContainers/ProcBreakdownState';
import {
  getNonConformancesBySpecAndProject,
  getUOMDescriptions,
} from '../../../../../TETOForms/selects/selects';
import { defaultGridResponsiveSettings } from '../../../../../TETOGridGraphQL/TETOGridGraphQL';
import MainTetoGridGraphQL from '../../../../../TETOGridGraphQL/TETOMainGridGraphQL';
import MoreOptionsPopover from '../../../../../TETOGridGraphQL/components/MoreOptionsPopover/MoreOptionsPopover';
import useDynamicGridHeight from '../../../../../TETOGridGraphQL/hooks/useDynamicGridHeight';
import useGrid from '../../../../../TETOGridGraphQL/hooks/useGrid';
import { useGridBuilderFromView } from '../../../../../TETOGridGraphQL/hooks/useGridBuilder';
import { CellChangingArgs } from '../../../../../TETOGridGraphQL/types/CellChangingArgs';
import TETOGridRefType from '../../../../../TETOGridGraphQL/types/TETOGridRefType';
import {
  CustomPaginationProps,
  TetoGridGraphqlProps,
} from '../../../../../TETOGridGraphQL/types/TetoGridGraphqlProps';
import PODetailFooter from '../../../components/PODetailFooter';
import SplitPODialog from '../../../components/SplitPODialog';
import deletePODetailMutation from '../../../queries/deletePODetailMutation';
import preservePOOrderMutation from '../../../queries/preservePOOrderMutation';
import updatePODetailMutation from '../../../queries/updatePurchaseOrderDetailMutation';
import inlineEditSchema from './inlineEditSchema';

interface PurchaseOrderDetailsGridProps extends Partial<TetoGridGraphqlProps> {
  purchaseOrderId?: number;
  refreshTotal: boolean;
  setRefreshQuery: Dispatch<SetStateAction<boolean>>;
  // eslint-disable-next-line no-unused-vars
  setHasASaveOccurred: (val: boolean) => void;
  setRefreshTotal: Dispatch<SetStateAction<boolean>>;
  setResetGrid: Dispatch<SetStateAction<boolean>>;
  resetGrid: boolean;
}
type RowType = { itemId: number; item: { itemCompanyId: string }; id: number };

const ROOT_QUERY_PATH = 'purchaseOrderDetails';
const PERSISTANCE_NAME = 'purchase-order-details-grid';
const CO_DEPENDENT_FIELDS = [
  'extendedPrice',
  'externalQty',
  'purchasePrice',
  'purchaseQty',
];
const DECIMAL_FIELDS = [
  'custom3',
  'custom4',
  'orderNumber',
  ...CO_DEPENDENT_FIELDS,
];
const INTEGER_FIELDS = [
  'externalUOMId',
  'item.uOM.itemUom',
  'nonConformanceId',
];
const EDITABLE_COLUMNS = [
  'custom1',
  'custom2',
  'custom5',
  'custom6',
  'custom7',
  'custom8',
  'dateRequired',
  'dateRevised',
  'purchaseSupplierDescription',
  'purchaseSupplierItem',
  'purchaseUom',
  'nonConformance.displayName',
  'externalUOM.uomtype',
  ...DECIMAL_FIELDS,
  ...INTEGER_FIELDS,
];

const alwaysProjectColumns = [
  'externalUOM.itemUom',
  'externalUOM.uomtype',
  'id',
  'destInventoryLocId',
  'destInventoryLoc.name',
  'inventoryDetails.inventoryLocationId',
  'itemId',
  'item.itemCompanyId',
  'item.itemLastCost',
  'item.itemListCost',
  'item.obsolete',
  'projectId',
  'processScheduleId',
  'purchaseOrder.purchaseCurr.format',
  'purchaseOrder.purchaseCurr.name',
  'purchaseOrder.purchaseCurr.symbol',
  'purchaseOrderId',
  'specId',
  'destInventoryLocId',
  'spec.masterQueue.closingReleaseDate',
  ...EDITABLE_COLUMNS,
];

const gridResponsiveSettings = {
  ...defaultGridResponsiveSettings,
};
const PurchaseOrderDetailsGrid = (
  _props: PurchaseOrderDetailsGridProps,
  ref: React.Ref<TETOGridRefType> | undefined | null
) => {
  const { selectedRows, setSelectedRows, setRefreshQuery } = _props;
  const { ready, t } = useTranslation();

  const messageContext = useContext(MessageContext);
  const authContext = useContext(AuthContext);

  const {
    settings: {
      baseCurrencyFormat,
      baseCurrencySymbol,
      correctDatesonPO,
      pOItemsForClosedMachines,
      pOUpdatePrices,
    },
  } = useContext(SettingsContext);
  const isMobileRef = useRef(false);

  const setLevels = useSetAtom(BreadCrumbSharedState);

  const [isSplitPODialogOpen, setIsSplitPODialogOpen] =
    useState<boolean>(false);
  const [splitPOValues, setSplitPOValues] = useState<
    { id: number } | undefined
  >(undefined);

  const [rowData, setRowData] = useState<unknown>();
  const setProcBreakdown = useSetAtom(ProcBreakdownSharedState);
  const [anchorEl, setAnchorEl] = React.useState<SVGSVGElement | null>(null);
  const [askToUpdatePrices, setAskToUpdatePrices] = useState<boolean>(false);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [poUpdatePricesData, setPoUpdatePricesData] = useState<any>();
  const setItemMaster = useSetAtom(ItemMasterSharedState);
  const setPartHistory = useSetAtom(PartHistorySharedState);
  const isMobile = useIsMobile();

  const { canDelete, canEdit, canViewNC, canViewPH, canViewPODetail } = useMemo(
    () => ({
      canEdit: authContext.hasPermission(
        Permission.Modify_Procurement_PurchaseOrders_PurchaseOrderHeader_PurchaseOrderDetails
      ),
      canDelete: authContext.hasPermission(
        Permission.Delete_Procurement_PurchaseOrders_PurchaseOrderHeader_PurchaseOrderDetails
      ),
      canViewNC: authContext.hasAnyPermission([
        Permission.View_Manufacturing_NonConformances,
        Permission.Add_Manufacturing_NonConformances,
        Permission.Modify_Manufacturing_NonConformances,
      ]),
      canViewPH: authContext.hasPermission(Permission.View_General_PartHistory),
      canViewPODetail: authContext.hasPermission(
        Permission.View_Engineering_ItemMaster
      ),
    }),
    [authContext]
  );

  const _commonError = useMemo(
    () => `Row data doesn't contain ${t('entities:Part.Part')} information`,
    [t]
  );

  const buttonMenuOptions = useMemo(
    () => [
      {
        componentName: t('pages.partsHistory.title'),
        icon: <ManageHistoryIcon color="primary" fontSize="small" />,
        title: t('pages.partsHistory.title'),
        handleClick: (rData: RowType) => {
          if (rData) {
            return setPartHistory({
              open: true,
              part: {
                label: rData?.item.itemCompanyId as string,
                id: rData?.id as number,
              },
              tab: 3,
            });
          }
          messageContext.setError(_commonError);
        },
        disabled: !canViewPH,
      },
      {
        componentName: t('pages.purchaseOrders.purchaseOrderModal.openNCs'),
        icon: <ReportGmailerrorredIcon color="primary" fontSize="small" />,
        title: t('pages.purchaseOrders.purchaseOrderModal.openNCs'),
        handleClick: (data: RowType) =>
          _handleOpenNCGrid(data as PurchaseOrderDetail),
        disabled: !canViewNC,
      },
      {
        componentName: t('pages.purchaseOrders.purchaseOrderModal.itemDetails'),
        icon: <SettingsRoundedIcon color="primary" fontSize="small" />,
        title: t('pages.purchaseOrders.purchaseOrderModal.itemDetails'),
        handleClick: (rData: RowType) => {
          if (rData) {
            return setItemMaster({
              open: true,
              initialValues: {
                id: rData.itemId as number,
                itemCompanyId: rData.item?.itemCompanyId,
              },
              hasSavedOccurred: false,
            });
          }
          messageContext.setError(_commonError);
        },
        disabled: !canViewPODetail,
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [_commonError, setItemMaster, setPartHistory]
  );

  const _handleOpenNCGrid = useCallback(
    (data: PurchaseOrderDetail) => {
      setLevels((pv) => [
        ...pv,
        {
          name: `${t('entities:NonConformance.NonConformance')} ${t(
            'generic.search'
          )} ${t('generic.results')}`,
          component: (
            <OpenNCGrid
              nCFilters={[
                {
                  name: 'specId',
                  operator: 'equal',
                  value: data.specId ?? undefined,
                  type: 'number' as const,
                },
                {
                  name: 'projectId',
                  operator: 'equal',
                  value: data.projectId ?? undefined,
                  type: 'number' as const,
                },
              ]}
            />
          ),
        },
      ]);
    },
    [setLevels, t]
  );

  const { gridBuilder, builderReady, error, hasError } = useGridBuilderFromView(
    PurchaseOrderDetailsInterface,
    ROOT_QUERY_PATH,
    (gb) =>
      gb
        .addColumn({
          name: 'grid.teto.action',
          title: t('generic.action'),
          type: 'button',
          align: 'center',
          sortable: false,
          filterType: 'none',
          filterable: undefined,
          fixed: 'right',
          disableGrouping: true,
          groupSummaryReducer: undefined,
          showByDefault: true,
          disableHideable: true,
          disableColumnMenuTool: true,
          includeInMobile: true,
          width: 144,
          minWidth: 70,
          maxWidth: 144,
          editable: false,
          locked: 'end',
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          render: (data: any) => {
            // eslint-disable-next-line no-shadow
            const rowData = { ...data };
            if (rowData?.cellProps?.summaryProps?.groupProps?.__group)
              return <></>;
            return (
              <ActionButton
                actionItems={
                  isMobileRef.current
                    ? [
                        {
                          buttonProps: {
                            disableFocusRipple: true,
                          },
                          componentName: t('generic.removePart'),
                          icon: <DeleteRoundedIcon />,
                          title: t('generic.removePart'),
                          // eslint-disable-next-line no-shadow, @typescript-eslint/no-explicit-any
                          handleClick: () => _handleDeleteItem(rowData.data.id),
                          color: canDelete ? 'error' : 'grey',
                          disabled: !canDelete,
                          confirm: {
                            type: 'okCancel',
                            title: t('dialogs.deleteGenericItem.title'),
                            content: t('dialogs.deleteGenericItem.content'),
                          },
                        },
                        {
                          buttonProps: {
                            disableFocusRipple: true,
                          },
                          componentName: t(
                            'pages.purchaseOrders.purchaseOrderModal.splitPOLineItem'
                          ),
                          icon: <CallSplitRoundedIcon />,
                          color:
                            !pOItemsForClosedMachines &&
                            Boolean(
                              rowData?.data?.spec?.masterQueue
                                ?.closingReleaseDate
                            )
                              ? 'grey'
                              : 'primary',
                          title: t(
                            'pages.purchaseOrders.purchaseOrderModal.splitPOLineItem'
                          ),
                          // eslint-disable-next-line @typescript-eslint/no-explicit-any
                          handleClick: () => {
                            // eslint-disable-next-line no-shadow
                            const { id } = rowData.data;

                            setSplitPOValues({ id: id as number });
                            return setIsSplitPODialogOpen(true);
                          },
                          disabled:
                            (!pOItemsForClosedMachines &&
                              Boolean(
                                rowData?.data?.spec?.masterQueue
                                  ?.closingReleaseDate
                              )) ||
                            !canEdit,
                        },
                        {
                          buttonProps: {
                            disableFocusRipple: true,
                          },
                          componentName: t('pages.partsHistory.title'),
                          icon: (
                            <ManageHistoryIcon
                              color="primary"
                              fontSize="small"
                            />
                          ),
                          title: t('pages.partsHistory.title'),
                          handleClick: () => {
                            if (rowData) {
                              return setPartHistory({
                                open: true,
                                part: {
                                  label: rowData?.item.itemCompanyId as string,
                                  id: rowData?.id as number,
                                },
                                tab: 3,
                              });
                            }
                            messageContext.setError(_commonError);
                          },
                          disabled: !canViewPH,
                        },
                        {
                          buttonProps: {
                            disableFocusRipple: true,
                          },
                          componentName: t(
                            'pages.purchaseOrders.purchaseOrderModal.openNCs'
                          ),
                          icon: (
                            <ReportGmailerrorredIcon
                              color="primary"
                              fontSize="small"
                            />
                          ),
                          title: t(
                            'pages.purchaseOrders.purchaseOrderModal.openNCs'
                          ),
                          // eslint-disable-next-line no-shadow
                          handleClick: () =>
                            _handleOpenNCGrid(
                              rowData.data as PurchaseOrderDetail
                            ),
                          disabled: !canViewNC,
                        },
                        {
                          buttonProps: {
                            disableFocusRipple: true,
                          },
                          componentName: t(
                            'pages.purchaseOrders.purchaseOrderModal.itemDetails'
                          ),
                          icon: (
                            <SettingsRoundedIcon
                              color="primary"
                              fontSize="small"
                            />
                          ),
                          title: t(
                            'pages.purchaseOrders.purchaseOrderModal.itemDetails'
                          ),
                          handleClick: () => {
                            if (rowData) {
                              return setItemMaster({
                                open: true,
                                initialValues: {
                                  id: rowData.id as number,
                                  itemCompanyId: rowData.item?.itemCompanyId,
                                },
                                hasSavedOccurred: false,
                              });
                            }
                            messageContext.setError(_commonError);
                          },
                          disabled: !canViewPODetail,
                        },
                      ]
                    : [
                        {
                          buttonProps: {
                            disableFocusRipple: true,
                          },
                          componentName: t('generic.removePart'),
                          icon: <DeleteRoundedIcon />,
                          title: t('generic.removePart'),
                          // eslint-disable-next-line no-shadow, @typescript-eslint/no-explicit-any
                          handleClick: () => _handleDeleteItem(rowData.data.id),
                          color: 'error',
                          disabled: !canDelete,
                          confirm: {
                            type: 'okCancel',
                            title: t('dialogs.deleteGenericItem.title'),
                            content: t('dialogs.deleteGenericItem.content'),
                          },
                        },

                        {
                          buttonProps: {
                            disableFocusRipple: true,
                          },
                          componentName: t(
                            'pages.purchaseOrders.purchaseOrderModal.splitPOLineItem'
                          ),
                          icon: <CallSplitRoundedIcon />,
                          color:
                            !pOItemsForClosedMachines &&
                            Boolean(
                              rowData?.data?.spec?.masterQueue
                                ?.closingReleaseDate
                            )
                              ? 'grey'
                              : 'primary',
                          title: t(
                            'pages.purchaseOrders.purchaseOrderModal.splitPOLineItem'
                          ),
                          // eslint-disable-next-line @typescript-eslint/no-explicit-any
                          handleClick: () => {
                            // eslint-disable-next-line no-shadow
                            const { data } = rowData;
                            const { id } = data;
                            setSplitPOValues({ id: id as number });
                            return setIsSplitPODialogOpen(true);
                          },
                          disabled:
                            (!pOItemsForClosedMachines &&
                              Boolean(
                                rowData?.data?.spec?.masterQueue
                                  ?.closingReleaseDate
                              )) ||
                            !canEdit,
                        },
                        {
                          buttonProps: {
                            disableFocusRipple: true,
                          },
                          componentName: t(
                            'pages.purchaseOrders.purchaseOrderModal.moreOptions'
                          ),
                          icon: <MoreVertRoundedIcon />,
                          title: t(
                            'pages.purchaseOrders.purchaseOrderModal.moreOptions'
                          ),
                          handleClick: (e: {
                            currentTarget: React.SetStateAction<SVGSVGElement | null>;
                          }) => {
                            setRowData(rowData?.data);
                            setAnchorEl(e?.currentTarget);
                          },
                          disabled: false,
                        },
                      ]
                }
                modalProps={{
                  placement: 'left-start',
                  disablePortal: true,
                }}
                rowHeight={
                  (ref as React.MutableRefObject<TETOGridRefType | undefined>)
                    ?.current?.rowHeightManager?.map?.[0]
                }
              />
            );
          },
        })
        .updateDefinition('orderNumber', {
          editable: canEdit,
          editorProps: {
            supportsDecimals: false,
          },
        })
        .updateDefinition('nonConformance.displayName', {
          brandingName: 'nonConformanceId',
          editable: canEdit,
          editorType: 'select',
          resizable: true,
          editorProps: {
            queryString: getNonConformancesBySpecAndProject.query,
            itemNameSelector: (i: NonConformance) =>
              i[
                getNonConformancesBySpecAndProject.labelField as keyof NonConformance
              ],
            itemValueSelector: (i: NonConformance) =>
              i[
                getNonConformancesBySpecAndProject.valueField as keyof NonConformance
              ],
            queryResultPath: 'nonConformances',
            variables: (d: PurchaseOrderDetail) => ({
              projectId: d?.projectId,
              specId: d?.specId,
            }),
          },
        })
        .updateDefinition('purchaseSupplierItem', {
          editable: canEdit,
          resizable: true,
          flex: 1,
        })
        .updateDefinition('purchaseSupplierDescription', {
          editable: canEdit,
          resizable: true,
          flex: 1,
        })
        .updateDefinition('dateRequired', { editable: canEdit })
        .updateDefinition('dateRevised', { editable: canEdit })
        .updateDefinition('purchasePrice', {
          editable: canEdit,
          editorProps: {
            supportsDecimals: true,
          },
          render: (d) => _formatCurrencyValue({ ...d }),
        })
        .updateDefinition('extendedPrice', {
          editable: canEdit,
          editorProps: {
            supportsDecimals: true,
          },
          render: (d) => _formatCurrencyValue({ ...d }),
        })
        .updateDefinition('externalQty', {
          editable: canEdit,
          editorProps: {
            supportsDecimals: true,
          },
        })
        .updateDefinition('purchaseQty', {
          editable: canEdit,
          editorProps: {
            supportsDecimals: true,
          },
          render: (props) => {
            const { data } = props;
            return (
              <LinkButtonWithHoverIcon
                align={props.cellProps?.align}
                icon={<LanIcon fontSize="small" />}
                onClick={() => {
                  setProcBreakdown({
                    detail: {
                      ...data,
                      inventoryLocationId: data?.destInventoryLocId ?? 0,
                    },
                    open: true,
                    ignoreLocation: false,
                  });
                }}
                value={props.value}
              />
            );
          },
        })
        .updateDefinition('externalUOM.uomtype', {
          editable: canEdit,
          editorType: 'select',
          brandingName: 'externalUOMId',
          editorProps: {
            queryString: getUOMDescriptions.query,
            itemNameSelector: (i: UomDescription) => i.uomtype,
            itemValueSelector: (i: UomDescription) => i.itemUom,
            queryResultPath: 'uOMDescriptions',
          },
        })
        .updateDefinition('purchaseUom', { editable: canEdit })
        .updateDefinition('custom1', { editable: canEdit })
        .updateDefinition('custom2', { editable: canEdit })
        .updateDefinition('custom3', { editable: canEdit })
        .updateDefinition('custom4', { editable: canEdit })
        .updateDefinition('custom5', { editable: canEdit })
        .updateDefinition('custom6', { editable: canEdit })
        .updateDefinition('custom7', { editable: canEdit })
        .updateDefinition('custom8', { editable: canEdit })
        .updateDefinition('item.itemLastCost', {
          renderHeader: (d) => _formatCurrencyHeader(d),
          render: (d) => _formatCurrencyValue(d),
        })
        .updateDefinition('item.itemListCost', {
          renderHeader: (d) => _formatCurrencyHeader(d),
          render: (d) => _formatCurrencyValue(d),
        })
        .updateDefinition('processSchedule.materialAmount', {
          render: (d) => _formatCurrencyValue(d),
        })
        .updateDefinition('processScheduleDetail.unitEstimate', {
          render: (d) => _formatCurrencyValue(d),
        })
        .updateDefinition('processScheduleDetail.totalCosts', {
          render: (d) => _formatCurrencyValue(d),
        })
        .updateDefinition('purchaseOrder.CurrencyRate', {
          render: (d) => _formatCurrencyValue(d),
        })
        .updateDefinition('purchaseOrder.purchaseTax1', {
          render: (d) => _formatCurrencyValue(d),
        })
        .updateDefinition('purchaseOrder.purchaseTax2', {
          render: (d) => _formatCurrencyValue(d),
        })
  );

  const _formatCurrencyHeader = useCallback(
    (d: CellProps) => {
      if (!d.data[0]) return d.header;
      const currency =
        d.data[0]?.purchaseOrder?.purchaseCurr?.name ?? baseCurrencySymbol;
      return `${d.header} (${currency})`;
    },
    [baseCurrencySymbol]
  );

  const mandatoryFilters = useMemo(() => {
    const filters = [
      {
        name: 'purchaseOrderId',
        operator: 'equal',
        value: _props.purchaseOrderId,
        type: 'number' as const,
      },
    ];

    return filters;
  }, [_props.purchaseOrderId]);

  const { gridProps } = useGrid(
    PERSISTANCE_NAME,
    `${ROOT_QUERY_PATH}.items`,
    (e) => messageContext.setError(e.message ?? e),
    t,
    alwaysProjectColumns,
    gridBuilder,
    {
      filterAndSortMode: 'serverSide',
      mandatoryFilter: mandatoryFilters,
    }
  );

  const _formatCurrencyValue = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (d: any) => {
      const { data, value } = d;
      const currency = data?.purchaseOrder?.purchaseCurr;
      const { format, symbol } = {
        format: (currency?.format ?? baseCurrencyFormat) as string,
        symbol: (currency?.symbol ?? baseCurrencySymbol) as string,
      };

      const formatter = new CurrencyFormatter(format, symbol);
      return value !== null && value !== undefined
        ? formatter.formatValue(value)
        : value;
    },
    [baseCurrencyFormat, baseCurrencySymbol]
  );

  const gridHeight = useDynamicGridHeight(
    gridProps?.dataSource?.length,
    gridResponsiveSettings?.breakpoints?.md?.rowHeight
  );

  const _handleDeleteItem = useCallback(
    (poId: number) => {
      buildMutation({
        queryString: deletePODetailMutation,
        variables: { id: poId },
        callback: (d): void => {
          if (d.deletePurchaseOrderDetail.success) {
            messageContext.setSuccess(
              t('generic.deletedSuccess', { record: t('generic.records') })
            );
            gridProps.refreshDataSource();
            (ref as TETOGridRefType | null)?.getGrid()?.deselectAll();
            if (setSelectedRows) setSelectedRows({});
            _props.setRefreshTotal(true);
            setRefreshQuery(true);
          }
        },
        messageContext,
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [_props, gridProps]
  );

  const _handleUpdatePODetail = useCallback(() => {
    const _input = poUpdatePricesData;
    _input.updatePurchasePriceForOtherPurchaseOrderDetails = true;
    return buildMutation({
      queryString: updatePODetailMutation,
      variables: { input: _input },
      callback: (d): void => {
        if (d.updatePurchaseOrderDetail.id) {
          _props.setHasASaveOccurred(true);
          setRefreshQuery(true);
          gridProps?.refreshDataSource();
          _props.setRefreshTotal(true);
        }
      },
      messageContext,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [poUpdatePricesData]);

  const _handleCellChanging = useCallback(
    async (change: CellChangingArgs) => {
      const {
        column: { name },
        editValue: { value, originalValue },
        newData,
        changeContext,
      } = change;

      const handleError = (
        e: { validationErrors: { [x: string]: string } },
        _name: string | undefined
      ) => {
        if (e.validationErrors) {
          changeContext.setValidationError(
            e.validationErrors[_name as string],
            _name
          );
        }
        changeContext.cancelChange();
        changeContext.setColumnValue(name as string, originalValue);
      };

      if (name === 'orderNumber') {
        buildMutation({
          queryString: preservePOOrderMutation,
          variables: {
            input: {
              purchaseOrderDetailOrder: {
                id: newData.id,
                orderNumber:
                  handleNumberInputChange({
                    value: newData.orderNumber,
                    type: 'int',
                  }) ?? 1,
              },
            } as unknown as UpdatePurchaseOrderDetailOrderInput,
          },
          callback: (d) => {
            if (d?.updatePurchaseOrderDetailOrder?.items) {
              _props.setHasASaveOccurred(true);

              if (isMobile) {
                changeContext.updateDependentColumns({
                  [name]: value,
                });
              } else {
                changeContext.setColumnValue(name as string, value);
              }
            }
          },
          messageContext,
          errorCallback: (e) => handleError(e, name as string),
        });
      } else {
        let tempData = { ...newData };

        DECIMAL_FIELDS.forEach((i) => {
          tempData[i] = handleNumberInputChange({
            value: tempData[i],
            type: 'decimal',
          });
        });

        INTEGER_FIELDS.forEach((i) => {
          if (tempData[i]) {
            tempData = {
              ...tempData,
              [i]: handleNumberInputChange({
                value: tempData[i],
                type: 'int',
              }),
            };
          }
        });

        const input: UpdatePurchaseOrderDetailInput = {
          custom1: tempData.custom1 ?? '',
          custom2: tempData.custom2 ?? '',
          custom3: tempData.custom3,
          custom4: tempData.custom4,
          custom5: tempData.custom5,
          custom6: tempData.custom6,
          custom7: tempData.custom7 ?? false,
          custom8: tempData.custom8 ?? false,
          dateRequired: tempData?.dateRequired,
          dateRevised: tempData?.dateRevised,
          externalQuantity: tempData.externalQty,
          externalUOMId: tempData.externalUOMId,
          id: tempData.id,
          nonConformanceId: tempData.nonConformanceId,
          purchasePrice: tempData.purchasePrice,
          purchaseQuantity: tempData.purchaseQty,
          purchaseSupplierDescription: tempData.purchaseSupplierDescription,
          purchaseSupplierItem: tempData.purchaseSupplierItem,
          purchaseUOM: tempData.purchaseUOM,
          extendedPrice: tempData.extendedPrice,
          updatePurchasePriceForOtherPurchaseOrderDetails:
            pOUpdatePrices === 'Always',
        };

        buildMutation({
          queryString: updatePODetailMutation,
          variables: { input },
          callback: (d): void => {
            if (d.updatePurchaseOrderDetail.id) {
              setRefreshQuery(true);
              _props.setHasASaveOccurred(true);
              if (
                [...CO_DEPENDENT_FIELDS, 'externalUOMId'].indexOf(
                  name as string
                ) >= 0
              ) {
                changeContext.updateDependentColumns({
                  ...d?.updatePurchaseOrderDetail,
                });
              } else changeContext.setColumnValue(name as string, value);
              if (
                (name === 'purchasePrice' || name === 'extendedPrice') &&
                pOUpdatePrices === 'Ask' &&
                gridProps.dataSource.filter(
                  (a) => a.itemId === d.updatePurchaseOrderDetail.itemId
                ).length > 1
              ) {
                setAskToUpdatePrices(true);
                setPoUpdatePricesData(input);
                return;
              }

              gridProps?.refreshDataSource();
              _props.setRefreshTotal(true);
            }
          },
          messageContext,
          errorCallback: (e) => handleError(e, name as string),
        });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [_props]
  );

  useEffect(() => {
    if (correctDatesonPO && canEdit) {
      if (gridProps.dataSource.length > 0) {
        const missingRequiredDate = gridProps.dataSource.filter(
          (i) => !i.dateRequired && !i.dateRevised
        );

        // Sets the breadcrumb modal close dialog warning
        setLevels((d) =>
          d.map((i) =>
            i.name === `Purchase Order: ${_props.purchaseOrderId}`
              ? {
                  ...i,
                  ...(missingRequiredDate.length > 0
                    ? {
                        closeConfirmation: {
                          title: t(
                            'pages.purchaseOrders.purchaseOrderModal.missingDateTitle'
                          ),
                          content: t(
                            'pages.purchaseOrders.purchaseOrderModal.missingDateContent'
                          ),
                        },
                      }
                    : { closeConfirmation: undefined }),
                }
              : i
          )
        );
      }
    }
  }, [
    _props.purchaseOrderId,
    canEdit,
    correctDatesonPO,
    gridProps.dataSource,
    setLevels,
    t,
  ]);

  useEffect(() => {
    if (_props.resetGrid) {
      gridProps?.refreshDataSource();
      _props.setRefreshTotal(true);
      _props.setResetGrid(false);
    }
  }, [_props, gridProps]);

  useEffect(() => {
    if (isMobile) {
      isMobileRef.current = true;
    }

    return () => {
      isMobileRef.current = false;
    };
  }, [isMobile]);

  const _handleRowClassName = useCallback(
    ({ data }: { data: Record<string, unknown> }) => {
      if (!data) return '';
      if ((data as PurchaseOrderDetail).item?.obsolete) {
        return 'row-strike-through';
      }
      return '';
    },
    []
  );

  return (
    <>
      <MainTetoGridGraphQL
        alignRefreshIconToRight
        builderReady={builderReady}
        checkboxColumn={!isMobile}
        configureInspector={_props.configureInspector}
        customGridWrapSx={{
          minHeight: gridHeight,
        }}
        editMode={{
          editMode: 'byRow',
          validationSchema: inlineEditSchema,
          onCellChanging: (c: CellChangingArgs) => {
            if (EDITABLE_COLUMNS.indexOf(c.column.name as string) >= 0) {
              _handleCellChanging(c);
            }
          },
        }}
        error={error}
        externalQueryProps={[]}
        gridProps={gridProps}
        hasError={hasError}
        header={{
          hidden: true,
        }}
        ready={ready}
        ref={ref}
        renderCustomFooter={(fProps: CustomPaginationProps) => (
          <PODetailFooter
            {...fProps}
            purchaseOrderId={_props?.purchaseOrderId as number}
            refreshTotal={_props.refreshTotal}
            setRefreshTotal={_props.setRefreshTotal}
          />
        )}
        rowClassName={_handleRowClassName}
        selectedRows={!isMobile ? selectedRows : undefined}
        setConfigureInspector={_props.setConfigureInspector}
        setSelectedRows={
          !isMobile
            ? // eslint-disable-next-line @typescript-eslint/no-explicit-any
              (d: any) => {
                if (setSelectedRows) setSelectedRows(d);
              }
            : undefined
        }
        showEmptyRows
        showGroupSummaryRow={false}
        t={t}
        tableIdentifier={PERSISTANCE_NAME}
      />

      {isSplitPODialogOpen && (
        <SplitPODialog
          initialValue={splitPOValues}
          onClose={(action) => {
            if (action) {
              gridProps?.refreshDataSource();
            }
            setIsSplitPODialogOpen(false);
          }}
          open={isSplitPODialogOpen}
        />
      )}
      <MoreOptionsPopover
        anchorEl={anchorEl}
        buttons={buttonMenuOptions}
        data={rowData}
        setAnchorEl={setAnchorEl}
        setRowData={setRowData}
      />
      <OkCancelConfirmDialog
        content={t('pages.purchaseOrders.purchaseOrderModal.updateOtherPrices')}
        onCancel={() => setAskToUpdatePrices(false)}
        onOk={() => {
          _handleUpdatePODetail();
          setAskToUpdatePrices(false);
          setPoUpdatePricesData(undefined);
        }}
        open={askToUpdatePrices}
        title={t('pages.purchaseOrders.purchaseOrderModal.updatePricesTitle')}
      />
    </>
  );
};

export default forwardRef(PurchaseOrderDetailsGrid);
