import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material';
import { ETOButton, MessageContext } from '@teto/react-component-library-v2';
import React, { useCallback, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { getGraphQLClient } from '../../api/graphQL/graphQLClient';

interface MFASetupDialogProps {
  mFASetupDialog: boolean;
  setMFASetupDialog: React.Dispatch<React.SetStateAction<boolean>>;
}

const MFASetupDialog = (props: MFASetupDialogProps) => {
  const { mFASetupDialog, setMFASetupDialog } = props;

  const { t } = useTranslation();

  const messageContext = useContext(MessageContext);

  const location = useLocation();
  const navigate = useNavigate();

  const isSettingsPage = location.pathname.includes('/settings/account');

  const logoutMutation = ` mutation logout(){
    logout(){
      success
    }
  }
  `;

  const _logout = useCallback(() => {
    getGraphQLClient()
      .performMutation(
        logoutMutation,
        {},
        (err) => {
          messageContext.setError(err.messages[0]);
        },
        (err) => {
          messageContext.setError(err.messages[0]);
        }
      )
      .then(() => {
        window.location.reload();
      })
      .catch((e) => {
        messageContext.setError(e.message);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [logoutMutation]);

  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {!isSettingsPage && (
        <Dialog open={mFASetupDialog}>
          <DialogTitle>{`${t('dialogs.mFASetupDialog.title')}`}</DialogTitle>
          <DialogContent>{`${t(
            'dialogs.mFASetupDialog.content'
          )}`}</DialogContent>
          <DialogActions>
            <ETOButton
              color="secondary"
              onClick={() => _logout()}
              size="medium"
            >
              {t('generic.logout')}
            </ETOButton>
            <ETOButton
              color="primary"
              onClick={() => {
                navigate('/settings/account');
                setMFASetupDialog(false);
              }}
              size="medium"
            >
              {`${t('dialogs.mFASetupDialog.navigateTo')}`}
            </ETOButton>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
};

export default MFASetupDialog;
