import { ExpandLessRounded, ExpandMoreRounded } from '@mui/icons-material';

import {
  Box,
  Collapse,
  Container,
  IconButton,
  Typography,
  useTheme,
} from '@mui/material';

import { ETOButton } from '@teto/react-component-library-v2';

import { uniqueId } from 'lodash';
import React, { useState } from 'react';

import {
  CustomRightActionType,
  RightMenuActionItems,
} from '../../ActionBar/ActionBar';

export interface ITimerDetailItem {
  title: string;
  description: string;
}

interface Props {
  buttons: RightMenuActionItems;
  title: string;
  startTime: string;
  duration: string;
  timerDetailItems?: ITimerDetailItem[];
  setIsModalOpen?: React.Dispatch<React.SetStateAction<boolean>>;
}

const TimerDetailItem = (props: ITimerDetailItem) => {
  const { title, description } = props;

  return (
    <Box
      sx={{
        alignItems: 'center',
        display: 'grid',
        gridTemplateColumns: ' 2fr 2fr 3fr',
        gridTemplateAreas:
          '"      detailsTitle        detailsDesc       detailsDesc"',
      }}
    >
      <Typography sx={{ mr: 1, gridArea: 'detailsTitle' }}>{title}:</Typography>
      <Typography sx={{ gridArea: 'detailsDesc', pl: 1 }}>
        {description}
      </Typography>
    </Box>
  );
};

const TimerDrawer = (props: Props) => {
  const {
    buttons,
    title,
    startTime,
    duration,
    timerDetailItems,
    setIsModalOpen,
  } = props;

  const theme = useTheme();

  const [isOpen, setIsOpen] = useState(false);

  const rightButtons = [] as CustomRightActionType[];
  buttons?.forEach((obj) => {
    if (Array.isArray(obj)) {
      obj.forEach((item) => {
        if (item) {
          rightButtons.push(item);
        }
      });
    }
    if (obj && !Array.isArray(obj)) {
      rightButtons.push(obj as CustomRightActionType);
    }
  });

  return (
    <Container
      key={uniqueId()}
      maxWidth="xs"
      sx={{
        [theme.breakpoints.up('lg')]: {
          paddingLeft: theme.spacing(2),
        },
        [theme.breakpoints.down('sm')]: {
          paddingLeft: theme.spacing(1),
          paddingRight: theme.spacing(1),
        },

        alignItems: 'center',
        paddingTop: '12px',
        paddingBottom: '12px',
        display: 'grid',
        gridTemplateColumns: '40px 2fr 2fr 3fr',
        gridTemplateRows: rightButtons.length === 1 ? '1fr' : '1fr 1fr',
        gap: '10px 0',
        gridTemplateAreas:
          rightButtons.length === 1
            ? `
        "toggle  title          totalDuration topButton"
        ".       details        details       details"
        `
            : `
        "toggle  title          totalDuration topButton"
        "toggle  startTimeTitle startTime     bottomButton"
        ".       details        details       details"
        `,
      }}
    >
      {timerDetailItems ? (
        <IconButton
          onClick={() => setIsOpen(!isOpen)}
          sx={{
            gridArea: 'toggle',
            p: 0,
            width: theme.spacing(3.5),
            height: theme.spacing(3.5),
          }}
        >
          {isOpen ? (
            <ExpandLessRounded color="primary" />
          ) : (
            <ExpandMoreRounded color="primary" />
          )}
        </IconButton>
      ) : null}
      <Typography sx={{ gridArea: 'title', fontWeight: 600 }}>
        {title}
      </Typography>
      <Typography sx={{ gridArea: 'totalDuration' }}>{duration}</Typography>
      <Box sx={{ gridArea: 'topButton', justifySelf: 'end' }}>
        <ETOButton
          color="primary"
          confirm={
            rightButtons[0].confirm
              ? {
                  type: 'okCancel',
                  title: rightButtons[0].confirm.title,
                  content: rightButtons[0].confirm.content,
                }
              : undefined
          }
          count={rightButtons[0].count ?? undefined}
          customSx={{
            backgroundColor: 'background.paper',
            color: 'text.primary',
            border: `1px solid ${theme.palette.divider}`,
            lineHeight: '1em',
            width: '142px',
          }}
          disabled={rightButtons[0].disabled}
          icon={rightButtons[0].icon}
          key={`${rightButtons[0].title}-${rightButtons[0].icon}`}
          onClick={(e) => {
            if (rightButtons[0] && rightButtons[0].onclick) {
              rightButtons[0].onclick(e);
              if (setIsModalOpen) {
                setIsModalOpen(false);
              }
            }
          }}
          size="small"
        >
          {rightButtons[0].title}
        </ETOButton>
      </Box>
      {/* Second Row */}
      {rightButtons[1] && (
        <>
          <Typography sx={{ gridArea: 'startTimeTitle' }} variant="subtitle1">
            Start Time:
            {/* {t('generic.duration')}: */}
          </Typography>
          <Typography sx={{ gridArea: 'startTime' }}>{startTime}</Typography>

          <Box sx={{ gridArea: 'bottomButton', justifySelf: 'end' }}>
            <ETOButton
              color="primary"
              confirm={
                rightButtons[1].confirm
                  ? {
                      type: 'okCancel',
                      title: rightButtons[1].confirm.title,
                      content: rightButtons[1].confirm.content,
                    }
                  : undefined
              }
              count={rightButtons[1].count ?? undefined}
              customSx={{
                backgroundColor: 'background.paper',
                color: 'text.primary',
                border: `1px solid ${theme.palette.divider}`,
                lineHeight: '1em',
                width: '142px',
              }}
              disabled={rightButtons[1].disabled}
              icon={rightButtons[1].icon}
              key={`${rightButtons[1].title}-${rightButtons[0].icon}`}
              onClick={(e) => {
                if (rightButtons[1] && rightButtons[1].onclick) {
                  rightButtons[1].onclick(e);
                  if (setIsModalOpen) {
                    setIsModalOpen(false);
                  }
                }
              }}
              size="small"
            >
              {rightButtons[1].title}
            </ETOButton>
          </Box>
        </>
      )}
      {/* expanded details */}
      <Collapse
        collapsedSize={0}
        in={isOpen}
        sx={{ gridArea: 'details', maxWidth: 'inherit', justifySelf: 'start' }}
        timeout={0}
        unmountOnExit
      >
        {timerDetailItems ? (
          <>
            {timerDetailItems.map((item) => (
              <TimerDetailItem
                description={item.description}
                key={uniqueId(item.title)}
                title={item.title}
              />
            ))}
          </>
        ) : null}
      </Collapse>
    </Container>
  );
};

export default TimerDrawer;
