import FilterAltRoundedIcon from '@mui/icons-material/FilterAltRounded';
import { IconButton, Tooltip } from '@mui/material';
import React from 'react';

interface FilterIconProps {
  title: string;
  onClick?: () => void;
}
const FilterIcon = (props: FilterIconProps) => {
  const { title, onClick } = props;

  return (
    <Tooltip arrow disableInteractive enterDelay={1000} title={title}>
      <IconButton
        onClick={onClick}
        sx={{
          padding: 0,
        }}
      >
        <FilterAltRoundedIcon color="primary" />
      </IconButton>
    </Tooltip>
  );
};

export default FilterIcon;
