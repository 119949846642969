import { gql } from '../../../__generated__';

const nonConformanceReportQuery = gql(`
query exportNCReport(
  $reportId: Int!
  $input: ExportReportRequestInput!
) {
  exportReport(id: $reportId, input: $input) {
    path
    parameters
    reportId
  }
}
`);
export default nonConformanceReportQuery;
