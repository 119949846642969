/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable import/prefer-default-export */
import { TypeColumn } from '@inovua/reactdatagrid-enterprise/types';
import { downloadBlob } from './downloader';
import valueFormatter from './valueFormatter';

const SEPARATOR = ',';
const buildCSVExport = (
  gridData: any,
  buildCustomCsv?: (
    columns: TypeColumn[],
    datasource: any,
    settings: any
  ) => string
) => {
  const { columns, dataSource, visibleColumns, groupBy, settingsContext } =
    gridData;
  const finalColumns: TypeColumn[] = [];
  const groupHeaders: string[] = [];

  const isMobile = visibleColumns.some(
    (r: { name: string }) =>
      r.name === 'mobileCustomGrouping' || r.name === 'mobileCustomData'
  );

  const _buildCSV = () => {
    const _formattedHeader = (header: string) => header ?? '';
    const uniqColumns = [...new Set([...finalColumns])];

    const headers = uniqColumns
      .map((c: any) => _formattedHeader(c.header ?? c.title))
      .join(SEPARATOR);

    const rows = (dataSource as []).map((data: any) =>
      uniqColumns
        .map((c: any) => valueFormatter(c, data, settingsContext))
        .join(SEPARATOR)
    );

    return [headers].concat(rows).join('\n');
  };

  if (groupBy && groupBy.length > 0) {
    groupBy.forEach((gc: any) => {
      const col = columns.find((a: { name: any }) => a.name === gc);
      if (!col) {
        throw new Error(`CSV Export failed finding grouped column ${gc}`);
      }

      groupHeaders.push(col.groupByName ?? gc);
      finalColumns.push(col);
    });

    if (isMobile) {
      columns.forEach((c: TypeColumn) => {
        if (
          c.groupColumn ||
          c.type === 'button' ||
          c.checkboxColumn ||
          !c.header ||
          c.id === '__row-expand-column'
        ) {
          return;
        }

        finalColumns.push(c);
      });
    } else {
      visibleColumns.forEach((c: TypeColumn) => {
        if (
          c.groupColumn ||
          c.type === 'button' ||
          c.checkboxColumn ||
          !c.header ||
          c.id === '__row-expand-column'
        ) {
          return;
        }

        finalColumns.push(c);
      });
    }

    return buildCustomCsv
      ? buildCustomCsv(finalColumns, dataSource, settingsContext)
      : _buildCSV();
  }

  if (isMobile) {
    columns.forEach((c: TypeColumn) => {
      if (
        c.groupColumn ||
        c.type === 'button' ||
        c.checkboxColumn ||
        c.id === '__row-expand-column'
      ) {
        return;
      }

      finalColumns.push(c);
    });

    return buildCustomCsv
      ? buildCustomCsv(finalColumns, dataSource, settingsContext)
      : _buildCSV();
  }
  visibleColumns.forEach((c: TypeColumn) => {
    if (
      c.groupColumn ||
      c.type === 'button' ||
      c.checkboxColumn ||
      c.id === '__row-expand-column'
    ) {
      return;
    }

    finalColumns.push(c);
  });

  return buildCustomCsv
    ? buildCustomCsv(finalColumns, dataSource, settingsContext)
    : _buildCSV();
};

export const exportCSV = (
  data: { [key: string]: any },
  buildCustomCsv?: (
    // eslint-disable-next-line no-unused-vars
    columns: TypeColumn[],
    // eslint-disable-next-line no-unused-vars
    datasource: any,
    // eslint-disable-next-line no-unused-vars
    settings: any
  ) => string
) => {
  const contents = buildCSVExport(data, buildCustomCsv);
  const blob = new Blob([contents], { type: 'text/csv;charset=utf-8;' });
  downloadBlob(blob, `export-${data.tableIdentifier}.csv`);
};
