import { Box, SxProps, Theme, Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

const noRecordsSx = (theme: Theme) => ({
  // padding: theme.spacing(6),
  width: '100%',
  textAlign: 'center',
  color: theme.palette.text.primary,
  height: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
});

interface NoRecordsProps {
  customSx?: SxProps<Theme>;
  customText?: string;
}

const NoRecords = (props: NoRecordsProps) => {
  const { customSx, customText } = props;
  const { t } = useTranslation();

  return (
    <Box
      sx={[noRecordsSx, ...(Array.isArray(customSx) ? customSx : [customSx])]}
    >
      <Typography variant="body1">
        {customText || t('generic.message.noRecordsFound')}
      </Typography>
    </Box>
  );
};

export default NoRecords;
