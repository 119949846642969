import { Box, Theme } from '@mui/material';
import { ETOButton } from '@teto/react-component-library-v2';
import { useSetAtom } from 'jotai';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { BreadCrumbSharedState } from '../../../SharedStateComponents/StateContainers/BreadCrumbModalState';
import { ProcessScheduleSharedState } from '../../../SharedStateComponents/StateContainers/ProcessScheduleState';

interface ProcessScheduleCreateButtonStripProps {
  // eslint-disable-next-line no-unused-vars
  handleSubmit: (initialize: boolean) => void;
  disableInitialize: boolean;
}

const buttonStripSx = (theme: Theme) => ({
  display: 'flex',
  borderTop: `1px solid ${theme.palette.divider}`,
  paddingTop: theme.spacing(2),
  justifyContent: 'space-between',
});

const ProcessScheduleCreateButtonStrip = (
  props: ProcessScheduleCreateButtonStripProps
) => {
  const { disableInitialize, handleSubmit } = props;
  const { t } = useTranslation();

  const setLevels = useSetAtom(BreadCrumbSharedState);
  const setPS = useSetAtom(ProcessScheduleSharedState);

  const _handleClose = () => {
    setLevels([]);
    setPS({
      openFrom: 'other',
      open: false,
      hasSavedOccurred: false,
      initialValues: undefined,
      itemsToAdd: undefined,
    });
  };

  return (
    <Box sx={buttonStripSx}>
      <ETOButton color="secondary" onClick={() => _handleClose()} size="medium">
        {t('generic.cancel')}
      </ETOButton>
      <Box
        sx={{
          display: 'flex',
          columnGap: (theme) => theme.spacing(1.5),
        }}
      >
        <ETOButton
          color="primary"
          disabled={disableInitialize}
          onClick={() => handleSubmit(true)}
          size="medium"
        >
          {t('generic.create')} & {t('generic.initialize')}
        </ETOButton>
        <ETOButton
          color="primary"
          onClick={() => handleSubmit(false)}
          size="medium"
        >
          {t('generic.create')}
        </ETOButton>
      </Box>
    </Box>
  );
};

export default ProcessScheduleCreateButtonStrip;
