import { ActionButton, ActionItem } from '@teto/react-component-library-v2';
import React from 'react';
import { TFunction } from 'react-i18next';
import { GridBuilder } from './GridBuilder';
import IGridBuilderExtension from './types/IGridBuilderExtension';

type WidthCalculator = (() => number) | null;
export type ActionItemSubset = Omit<ActionItem, 'handleClick'> & {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any, no-unused-vars
  onClick: (data: any, e?: React.MouseEvent<any, MouseEvent>) => void;
};

const standardWidth = 144;

class ActionButtonGridBuilderExtension implements IGridBuilderExtension {
  widthCalculator: WidthCalculator = null;

  actionItems: ActionItemSubset[] = [];

  constructor(
    actionItems: ActionItemSubset[],
    widthCalculator: WidthCalculator = null
  ) {
    this.actionItems = actionItems;

    if (widthCalculator == null) {
      if (!actionItems || actionItems.length === 0) {
        this.widthCalculator = () => standardWidth;
      } else {
        let tempWidth = actionItems.length * 42;

        if (tempWidth < standardWidth) {
          tempWidth = standardWidth;
        }
        this.widthCalculator = () => tempWidth;
      }
    } else {
      this.widthCalculator = widthCalculator;
    }
  }

  // eslint-disable-next-line class-methods-use-this, no-unused-vars
  execute(gridBuilder: GridBuilder, t: TFunction<'translation', undefined>) {
    gridBuilder.addColumn({
      name: 'grid.teto.action',
      title: t('generic.action'),
      type: 'button',
      align: 'center',
      sortable: false,
      filterType: 'none',
      filterable: undefined,
      fixed: 'right',
      disableGrouping: true,
      groupSummaryReducer: undefined,
      showByDefault: true,
      disableHideable: true,
      showInContextMenu: false,
      disableColumnMenuTool: true,
      disableColumnFilterContextMenu: true,
      includeInMobile: true,
      width: this.widthCalculator
        ? this.widthCalculator() ?? standardWidth
        : standardWidth,
      minWidth: 70,
      maxWidth: this.widthCalculator
        ? this.widthCalculator() ?? standardWidth
        : standardWidth,
      editable: false,
      locked: 'end',
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      render: (data: any) => {
        const rowData = { ...data };

        if (rowData?.cellProps?.summaryProps?.groupProps?.__group) return <></>;

        const visibilityTypesThatHide = ['hidden', 'collapse'];

        return (
          <ActionButton
            actionItems={[
              ...this.actionItems
                .filter(
                  (i) =>
                    i.hideButton === undefined ||
                    !visibilityTypesThatHide.includes(
                      typeof i.hideButton === 'function'
                        ? i.hideButton({ ...rowData })
                        : i.hideButton
                    )
                )
                .map((a) => ({
                  componentName: a.componentName,
                  icon: a.icon,
                  title: a.title,
                  buttonProps: {
                    ...a.buttonProps,
                    disableFocusRipple: true,
                  },
                  handleClick: () => a.onClick({ ...rowData }),
                  color: a.color,
                  disabled:
                    typeof a.disabled === 'function'
                      ? a.disabled({ ...rowData })
                      : a.disabled,
                  hideButton:
                    typeof a.hideButton === 'function'
                      ? a.hideButton({ ...rowData })
                      : a.hideButton,
                  confirm: a.confirm,
                })),
            ]}
            modalProps={{
              placement: 'left-start',
              disablePortal: true,
            }}
            rowHeight={rowData?.cellProps?.instance?.props?.rowHeight}
          />
        );
      },
    });

    return gridBuilder;
  }
}

export default ActionButtonGridBuilderExtension;
