import { SxProps, Theme } from '@mui/material';
import { MessageContext } from '@teto/react-component-library-v2';

import { useAtom, useSetAtom } from 'jotai';
import React, {
  Dispatch,
  useCallback,
  useContext,
  useEffect,
  useImperativeHandle,
  useMemo,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { Permission, getGraphQLClient } from 'teto-client-api';
import useLocalStorage from 'use-local-storage';
import { gql } from '../../../../../../../__generated__';
import {
  AddPurchaseOrderHeaderAndDetailInput,
  Company,
  PurchaseOrderHeader,
} from '../../../../../../../__generated__/graphql';
import getErrors from '../../../../../../../api/graphQL/getErrors';
import AuthContext from '../../../../../../../contexts/AuthContext';
import SettingsContext from '../../../../../../../contexts/SettingsContext';
import CommonGridSupplierSelectField from '../../../../../../InputFields/CommonGridSupplierSelectField/CommonGridSupplierSelectField';
import EditingState from '../../../../../../Inspectors/types/EditingState';
import { PanelState } from '../../../../../../Inspectors/types/PanelState';
import { BreadCrumbSharedState } from '../../../../../../SharedStateComponents/StateContainers/BreadCrumbModalState';
import { PurchaseOrderSharedState } from '../../../../../../SharedStateComponents/StateContainers/PurchaseOrderState';
import { RFQSharedState } from '../../../../../../SharedStateComponents/StateContainers/RFQState';
import FormBuilder from '../../../../../../TETOForms/FormBuilder/FormBuilder';
import FormDefinition from '../../../../../../TETOForms/FormDefinition';
import { simpleLayout } from '../../../../../../TETOForms/FormLayoutHelpers';
import TETOForm from '../../../../../../TETOForms/TETOForm';
import useForm from '../../../../../../TETOForms/hooks/useForm';
import { useFormBuilder } from '../../../../../../TETOForms/hooks/useFormBuilder';
import { getRFQCompanies } from '../../../../../../TETOForms/selects/selects';
import addPurchaseOrderMutation from '../../../../queries/addNewPOMutation';
import addPOFromRFQMutation from '../../../../queries/addPOFromRFQMutation';

export interface POFormRefType {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  form: FormDefinition<any>;
}

interface POCreateHeaderFormProps {
  // eslint-disable-next-line no-unused-vars
  setEditingState: (editingState: EditingState) => void;
  setPurchaseOrder: Dispatch<
    React.SetStateAction<Partial<PurchaseOrderHeader>>
  >;
  setPanelState: Dispatch<React.SetStateAction<PanelState>>;
  // eslint-disable-next-line no-unused-vars
  setHasASaveOccurred: (val: boolean) => void;
  setIsSubmitting: Dispatch<React.SetStateAction<boolean>>;
}

const purchaseOrderHeaderQuery = gql(`query getLastPurchaseOrder {
  purchaseOrderHeaders(order: { id: DESC }, take: 1) {
    items {
      id
    }
  }
}
`);
type ProcurementSupplier = {
  child: {
    preferredSupplierCompany: {
      supplier: {
        id: number;
      };
    };
  };
};

type DefaultSuppler = {
  supplier: {
    id: number;
  };
};
type Supplier = ProcurementSupplier | DefaultSuppler;

const formWrapperSx: SxProps<Theme> = {};

const REQUIRED_DATE_OPTIONS = [
  'none',
  'requiredDate',
  'manufacturingBeginDate',
  'custom',
];

const POCreateHeaderForm = React.forwardRef<
  POFormRefType,
  POCreateHeaderFormProps
>((props, ref) => {
  const {
    setEditingState,
    setHasASaveOccurred,
    setPanelState,
    setPurchaseOrder,
    setIsSubmitting,
  } = props;

  const authContext = useContext(AuthContext);
  const messageContext = useContext(MessageContext);
  const settingsContext = useContext(SettingsContext);

  const { t } = useTranslation();

  const [poNumber, setPoNumber] = useLocalStorage<number | null>(
    'createNewPoNumber',
    null
  );

  const [purchaseOrder, setPO] = useAtom(PurchaseOrderSharedState);
  const [rFQ] = useAtom(RFQSharedState);
  const [commonSupplierId, setCommonSupplierId] = useState<
    number | undefined
  >();
  const setLevels = useSetAtom(BreadCrumbSharedState);

  const { canAdd, canManuallyOverwritePO } = useMemo(
    () => ({
      canAdd: authContext.hasPermission(
        Permission.Add_Procurement_PurchaseOrders_PurchaseOrderHeader
      ),
      canManuallyOverwritePO: authContext.hasPermission(
        Permission.Modify_Procurement_PurchaseOrders_PurchaseOrderHeader_ManualOverwriteOfPONumberAtCreation
      ),
    }),
    [authContext]
  );

  useEffect(() => {
    if (purchaseOrder.itemsToAdd) {
      const items: Supplier[] = Object.values(purchaseOrder?.itemsToAdd ?? {});

      let supplierId: number | null = null;
      let foundSupplierId = false;

      const isAllSameSupplier = items.every((_item: Supplier) => {
        let currentSupplierId: null | number = null;

        if ('child' in _item) {
          currentSupplierId =
            _item.child?.preferredSupplierCompany?.supplier?.id;
        }
        if ('supplier' in _item) {
          currentSupplierId = _item.supplier?.id;
        }

        if ('preferredSupplier' in _item) {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          currentSupplierId = (_item.preferredSupplier as any)?.id;
        }

        if (currentSupplierId) {
          if (!foundSupplierId) {
            supplierId = currentSupplierId;
            foundSupplierId = true;
          }

          return currentSupplierId === supplierId;
        }

        return true; // Skip the undefined/null suppliers
      });

      return isAllSameSupplier && supplierId
        ? setCommonSupplierId(supplierId)
        : undefined;
    }
    return undefined;
  }, [purchaseOrder.itemsToAdd]);

  const requiredDateOptions = useMemo(
    () =>
      REQUIRED_DATE_OPTIONS.map((i, ind) => ({
        name: t(
          `pages.purchaseOrders.purchaseOrderModal.requiredDateOptions.${i}`
        ),
        value: ind,
      })),
    [t]
  );

  useEffect(() => {
    if (commonSupplierId) {
      createPOForm.updateField('supplierCompanyId', commonSupplierId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [commonSupplierId]);

  const itemsToAddLength = useMemo(
    () => Object.keys(purchaseOrder?.itemsToAdd ?? {}).length ?? 0,
    [purchaseOrder]
  );

  const formattedCreateItems = useMemo(
    () =>
      Object.values(purchaseOrder?.itemsToAdd ?? {}).map((i) => ({
        destinationInventoryLocationId: i.destInventoryLocId,
        itemId: i.itemId ?? i.id,
        processScheduleDetailId: i.processScheduleDetailId,
        processScheduleId: i.processScheduleId,
        projectId: i.projectId,
        qty: i.purchasingSummary?.toPurchase,
        specId: i.specId,
        requiredDate: i.requiredDate ?? null,
      })),
    [purchaseOrder?.itemsToAdd]
  );

  const _handleFormatRequest = useCallback(
    (
      fb: FormBuilder<Record<string, unknown>>,
      fd: FormDefinition,
      data: Record<string, unknown>
    ) =>
      !purchaseOrder.fromRFQ
        ? ({
            buyerEmployeeId: authContext?.user?.id ?? 0,
            changeCurrency: true,
            items:
              formattedCreateItems.length > 0 ? formattedCreateItems : null,
            purchaseOrderId: data.purchaseOrderId,
            requiredDateMethod: data.requiredDateMethod ?? 0,
            supplierCompanyId: data.supplierCompanyId ?? 0,
          } as AddPurchaseOrderHeaderAndDetailInput)
        : {
            rFQId: rFQ.initialValues?.id ?? 0,
            rFQCompanyId: data.supplierCompanyId ?? 0,
            purchaseOrderId: data.purchaseOrderId,
          },
    [
      authContext?.user?.id,
      formattedCreateItems,
      purchaseOrder.fromRFQ,
      rFQ.initialValues?.id,
    ]
  );

  const _handleSubmitting = useCallback(
    (
      fb: FormBuilder<PurchaseOrderHeader>,
      fd: FormDefinition,
      data: AddPurchaseOrderHeaderAndDetailInput
    ) =>
      !purchaseOrder.fromRFQ
        ? getGraphQLClient().performMutation(addPurchaseOrderMutation, {
            input: data,
          })
        : getGraphQLClient().performMutation(addPOFromRFQMutation, {
            input: data,
          }),
    [purchaseOrder.fromRFQ]
  );

  const _handleFormatResponse = useCallback(
    (
      fb: FormBuilder<PurchaseOrderHeader>,
      fd: FormDefinition,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      response: any
    ) => {
      fd.setSubmitting?.(false);
      if (response.hasError()) {
        setEditingState({
          isEditing: true,
          hasEdited: true,
          tabError: true,
        });

        let hiddenErrors = response?.message ?? '';

        if (response.hasValidationErrors()) {
          const errors = response?.validationErrors.input;
          const validationErrors: Record<string, string> = {};

          Object.keys(errors).forEach((i) => {
            if (/items/.test(i)) {
              hiddenErrors += `\n\t${i}: ${errors[i]}`;
            } else {
              validationErrors[i] = errors[i];
            }
          });
          fd.setValidationErrors(validationErrors);
        }
        if (hiddenErrors.length > 0) messageContext.setError(hiddenErrors);
        if (response.hasSystemErrors())
          response.showAllSystemErrors(messageContext.setError);
        return;
      }

      if (response.hasData()) {
        setPurchaseOrder(response.data.purchaseOrder);
        setHasASaveOccurred(true);
        setPanelState({
          id: response.data.purchaseOrder.id,
          mode: 'edit',
        });
        setEditingState({
          isEditing: false,
          hasEdited: true,
          tabError: false,
        });

        setPO((d) => ({
          ...d,
          initialValues: response.data.purchaseOrder,
        }));

        setLevels((d) =>
          d.map((i) =>
            i.name === 'Purchase Order'
              ? {
                  ...i,
                  name: `${t('entities:PurchaseOrder.PurchaseOrder')}: ${
                    response.data.purchaseOrder.id
                  }`,
                  data: response.data.purchaseOrder,
                }
              : i
          )
        );

        messageContext.setSuccess(
          t('generic.createdSuccess', {
            record: t('entities:PurchaseOrder.PurchaseOrder'),
          })
        );
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );
  const formBuilder = useFormBuilder('create-po-header', (fb) =>
    fb
      .addInteger({
        disabled: !canAdd || !canManuallyOverwritePO,
        name: 'purchaseOrderId',
        required: true,
        title: t('pages.purchaseOrders.purchaseOrderNumber'),
        defaultValue: poNumber,
      })
      .addInteger({
        disabled: !canAdd,
        defaultValue: commonSupplierId,
        name: 'supplierCompanyId',
        title: t('entities:PurchaseOrderHeader.PurchaseSupplier'),
        required: true,
        render: (form, field, options) => (
          <CommonGridSupplierSelectField
            customFilter={
              purchaseOrder.fromRFQ ? rFQSuppliersFilter : undefined
            }
            customQuery={
              purchaseOrder.fromRFQ ? getRFQCompanies.query : undefined
            }
            disabled={options.disabled}
            error={options.error}
            fieldName={options.name}
            handleChange={({ target }) =>
              form.updateField(field.name as string, target.value as string)
            }
            label={options.label}
            nameKey={
              purchaseOrder.fromRFQ
                ? (getRFQCompanies.labelField as string)
                : undefined
            }
            shouldHaveWarning={
              settingsContext.settings.notApprovedSupplierWarningPO
            }
            value={options.value}
            valueKey={
              purchaseOrder.fromRFQ
                ? (getRFQCompanies.valueField as string)
                : undefined
            }
            variables={
              purchaseOrder.fromRFQ
                ? { where: { rFQId: { eq: rFQ.initialValues?.id } } }
                : undefined
            }
          />
        ),
      })
      .addInteger({
        defaultValue: purchaseOrder.fromRFQ ? 1 : 3,
        disabled: !canAdd || purchaseOrder.fromRFQ,
        name: 'requiredDateMethod',
        selectSource: {
          label: 'name',
          value: 'value',
          fromArray: requiredDateOptions,
        },
        required: true,
        title: t('pages.purchaseOrders.purchaseOrderModal.populateDates'),
      })
      .addInteger({
        defaultValue: itemsToAddLength,
        disabled: true,
        name: 'count',
        title: t('pages.purchaseOrders.purchaseOrderModal.totalItems'),
      })
  );

  const createPOForm = useForm(
    formBuilder,
    {
      onFormatRequest: (fb, fd, data) => _handleFormatRequest(fb, fd, data),
      onFormatResponse: (fb, fd, resp) => _handleFormatResponse(fb, fd, resp),
      onSubmitting: (fb, fd, data) => {
        setIsSubmitting(true);
        return _handleSubmitting(fb, fd, data);
      },
      onSubmitted: () => Promise.resolve(setIsSubmitting(false)),
    },
    {
      purchaseOrderId: poNumber,
      supplierCompanyId: commonSupplierId,
    }
  );

  useEffect(() => {
    createPOForm.updateField('supplierCompanyId', commonSupplierId);
    // do not add form values to this or you will end up
    // resetting the supplier selection when a preferred supplier exists
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [commonSupplierId]);

  useImperativeHandle(
    ref,
    () => ({
      form: createPOForm,
    }),
    [createPOForm]
  );

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const rFQSuppliersFilter = (data: any) => {
    if (Array.isArray(data?.rFQCompanies?.items)) {
      const itemsLength = data.rFQCompanies.items.length;
      setCommonSupplierId(
        itemsLength === 1 ? data.rFQCompanies.items[0]?.companyId : undefined
      );
    } else {
      setCommonSupplierId(undefined);
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return (data.rFQCompanies.items.map((r: any) => r.company) ??
      []) as Company[];
  };

  useQuery(['lastSelectedPoNumber'], () =>
    getGraphQLClient()
      .performQuery(purchaseOrderHeaderQuery, {})
      .then((d) => {
        if (d.hasError()) {
          if (d.hasValidationErrors()) {
            messageContext.setError(getErrors(d.validationErrors));
          }

          if (d.hasSystemErrors()) {
            messageContext.setError(getErrors(d.systemErrors));
          }
          return;
        }

        const lastPONumber = d?.data?.purchaseOrderHeaders?.items?.[0]?.id;
        if (lastPONumber) {
          setPoNumber(lastPONumber + 1);
        } else if (poNumber) {
          setPoNumber(poNumber + 1);
        } else {
          setPoNumber(0);
        }
      })
  );

  useEffect(() => {
    setEditingState({
      isEditing: createPOForm.dirty,
      hasEdited: createPOForm.dirty,
      tabError: false,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createPOForm.dirty]);

  useEffect(() => {
    if (itemsToAddLength > 0) {
      createPOForm.updateField('count', itemsToAddLength);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [itemsToAddLength]);

  useEffect(() => {
    createPOForm.updateField('purchaseOrderId', poNumber);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [poNumber]);

  const formLayout = useMemo(
    () => simpleLayout(formBuilder.formBuilder, 2),
    [formBuilder]
  );

  return (
    <TETOForm
      form={createPOForm}
      formLayout={formLayout}
      formWrapperSx={formWrapperSx}
    />
  );
});

export default POCreateHeaderForm;
