import { ETOSelectField } from '@teto/react-component-library-v2';
import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import useBuildQuery from '../../../api/graphQL/useBuildQuery';

interface InvPartNumberSearchInputProps {
  disabled?: boolean;
  error?: string;
  id?: number;
  // eslint-disable-next-line no-unused-vars, @typescript-eslint/no-explicit-any
  handleChange: (value: any | null) => void;
  name: string;
  location: number;
  autoFocus?: boolean;
  queryOverride?: {
    queryString?: string;
    variables?: {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      [key: string]: any;
    };
    // eslint-disable-next-line @typescript-eslint/no-explicit-any, no-unused-vars
    callbackResolver?: (data: any[]) => any[];
  };
}

type TEngMasterItem = {
  itemCompanyId: string;
  id: number;
  description: string;
};

type InvResponse = {
  inventories: {
    items: TEngMasterItem[];
  };
};

type InvState = { item: TEngMasterItem }[];

const inventoryQuery = `query inventories($inventoryLocationId: Int!) {
  inventories(
    where: {
      and: [
        { inventoryLocationId: { eq: $inventoryLocationId } }
        { qtyOnHand: { gt: 0 } }
      ]
    }
  ) {
    items {
      item {
        id
        itemCompanyId
        description
      }
    }
  }
}
`;

const InvPartNumberSearchInput = (props: InvPartNumberSearchInputProps) => {
  const {
    disabled,
    error,
    id,
    queryOverride,
    handleChange,
    name,
    location,
    autoFocus,
  } = props;

  const { t } = useTranslation();

  const partQueryVariable = useMemo(
    () => ({
      inventoryLocationId: location,
    }),
    [location]
  );

  const [partNumbers, setPartNumbers] = useState<InvState>([]);

  const query = useBuildQuery<InvResponse>(['InvPartNumberSearchInput', id], {
    queryString: queryOverride?.queryString ?? inventoryQuery,
    variables: queryOverride?.variables ?? partQueryVariable,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    callback: (data: any) => {
      if (data) {
        const key = 'inventories';
        const { items } = data[key];
        if (items.length > 0) {
          if (queryOverride?.callbackResolver)
            setPartNumbers(queryOverride?.callbackResolver(items));
          else setPartNumbers(items);
        }
      }
    },
  });

  return (
    <ETOSelectField
      autoComplete
      autoFocus={autoFocus}
      disabled={disabled}
      error={error}
      forcePopupIcon
      fullWidth
      handleChange={(e) => {
        const value = parseInt(e.target.value, 10) || null;
        const part = partNumbers.find(
          (i: { item: { id: number | null } }) => i.item.id === value
        );
        handleChange(part);
      }}
      includeInputInList
      inputLabelProps={{ shrink: true }}
      itemNameSelector={(obj: { item: TEngMasterItem }) =>
        obj?.item?.itemCompanyId || ''
      }
      items={partNumbers}
      itemValueSelector={(item) => {
        if (item?.item?.id) {
          return item?.item?.id;
        }
        return null;
      }}
      label={`${t('entities:Part.ItemCompanyId')}`}
      loading={query.isLoading}
      name={name}
      noOptionsText={`${t('generic.no')} ${t('generic.results')}`}
      placeholder={`Search by ${t('entities:Part.ItemCompanyId')}`}
      size="small"
      value={id || undefined}
    />
  );
};

export default InvPartNumberSearchInput;
