import { gql } from '../../../__generated__';
import SelectDefinition from './SelectDefinition';

const getNonConformancesBySpecAndProject = {
  query:
    gql(`query getNonConformancesBySpecAndProject($projectId: Int!, $specId: Float!) {
    nonConformances(where: { projectId: {eq: $projectId}, specId: {eq: $specId}}){
      items{
        id
        description
        displayName
        title
      }
    }
  }`),
  valueField: 'id',
  labelField: 'displayName',
} as SelectDefinition;

export default getNonConformancesBySpecAndProject;
