import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { TreeView } from '@mui/x-tree-view/TreeView';
import {
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Theme,
} from '@mui/material';
import { ETOCheckBox } from '@teto/react-component-library-v2';
import { useTranslation } from 'react-i18next';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';
import useGQLQuery from '../../api/graphQL/useGQLQuery';
import { ProcessScheduleTemplate } from '../../__generated__/graphql';
import getProcessScheduleTemplatesQuery from '../TETOForms/selects/getProcessScheduleTemplates';
import CustomTreeItem from './CustomTreeItem';

const MinusSquare = (props: SvgIconProps) => (
  <SvgIcon fontSize="inherit" style={{ width: 14, height: 14 }} {...props}>
    {/* tslint:disable-next-line: max-line-length */}
    <path d="M22.047 22.074v0 0-20.147 0h-20.12v0 20.147 0h20.12zM22.047 24h-20.12q-.803 0-1.365-.562t-.562-1.365v-20.147q0-.776.562-1.351t1.365-.575h20.147q.776 0 1.351.575t.575 1.351v20.147q0 .803-.575 1.365t-1.378.562v0zM17.873 11.023h-11.826q-.375 0-.669.281t-.294.682v0q0 .401.294 .682t.669.281h11.826q.375 0 .669-.281t.294-.682v0q0-.401-.294-.682t-.669-.281z" />
  </SvgIcon>
);

const PlusSquare = (props: SvgIconProps) => (
  <SvgIcon fontSize="inherit" style={{ width: 14, height: 14 }} {...props}>
    {/* tslint:disable-next-line: max-line-length */}
    <path d="M22.047 22.074v0 0-20.147 0h-20.12v0 20.147 0h20.12zM22.047 24h-20.12q-.803 0-1.365-.562t-.562-1.365v-20.147q0-.776.562-1.351t1.365-.575h20.147q.776 0 1.351.575t.575 1.351v20.147q0 .803-.575 1.365t-1.378.562v0zM17.873 12.977h-4.923v4.896q0 .401-.281.682t-.682.281v0q-.375 0-.669-.281t-.294-.682v-4.896h-4.923q-.401 0-.682-.294t-.281-.669v0q0-.401.281-.682t.682-.281h4.923v-4.896q0-.401.294-.682t.669-.281v0q.401 0 .682.281t.281.682v4.896h4.923q.401 0 .682.281t.281.682v0q0 .375-.281.669t-.682.294z" />
  </SvgIcon>
);

const tableHeadRowSx = (theme: Theme) => ({
  '& .MuiTableCell-root': {
    padding: `0 ${theme.spacing(2)}`,
  },
});

const treeItemSx = (theme: Theme) => ({
  '& .MuiTreeItem-content': {
    backgroundColor: theme.palette.background.paper,
    color: theme.palette.getContrastText(theme.palette.background.paper),
    '&:hover': {
      backgroundColor: theme.palette.background.paper,
      color: theme.palette.getContrastText(theme.palette.background.paper),
    },
  },
  '& .MuiTreeItem-content.Mui-expanded': {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.getContrastText(theme.palette.primary.main),
  },
  '& .MuiTreeItem-content.Mui-selected': {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.getContrastText(theme.palette.primary.main),
  },
  '& .MuiTreeItem-content.Mui-focused': {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.getContrastText(theme.palette.primary.main),
  },
  '& .Mui-expanded': {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.getContrastText(theme.palette.primary.main),
  },
  '& .Mui-selected': {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.getContrastText(theme.palette.primary.main),
  },
  '& .Mui-focused': {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.getContrastText(theme.palette.primary.main),
  },
});

const wrapperSx = (theme: Theme) => ({
  flexGrow: 1,
  maxHeight: theme.spacing(40),
  padding: theme.spacing(2),
  position: 'relative',
  width: theme.spacing(50),
});

const buttonWrapSx = (theme: Theme) => ({
  mt: 1,
  position: 'sticky',
  bottom: 0,
  backgroundColor: theme.palette.background.paper,
});

interface ProcessScheduleTemplateTreeProps {
  // eslint-disable-next-line no-unused-vars
  onPSTClicked: (selectedPST: number) => void;
}

const ProcessScheduleTemplateTree = (
  props: ProcessScheduleTemplateTreeProps
) => {
  const { onPSTClicked } = props;

  const { t } = useTranslation();

  const [expanded, setExpanded] = React.useState<string[]>([]);
  const [selected, setSelected] = React.useState<string[]>([]);
  const [selectedPST, setSelectedPST] = React.useState<number>();

  const _handleToggle = (event: React.SyntheticEvent, nodeIds: string[]) => {
    setExpanded(nodeIds);
  };

  const _handleSelect = (event: React.SyntheticEvent, nodeIds: string[]) => {
    setSelected(nodeIds);
    setSelectedPST(parseInt(nodeIds[0], 10));
  };

  const _handleExpandClick = () => {
    setExpanded((oldExpanded) =>
      oldExpanded.length === 0 ? AllNodeIds ?? [] : []
    );
  };

  const processScheduleTemplates = useGQLQuery<{
    processScheduleTemplates: { items: ProcessScheduleTemplate[] };
  }>(['processScheduleTemplates'], {
    queryString: getProcessScheduleTemplatesQuery.query,
    options: { refetchOnMount: false, refetchOnWindowFocus: false },
  });

  const AllNodeIds = React.useMemo(
    () =>
      processScheduleTemplates.data?.processScheduleTemplates.items.map((pst) =>
        pst.id.toString()
      ),
    [processScheduleTemplates.data?.processScheduleTemplates.items]
  );

  return (
    <Box sx={wrapperSx}>
      {processScheduleTemplates.isLoading && (
        <Box>
          <CircularProgress />
        </Box>
      )}
      {!processScheduleTemplates.isLoading &&
        processScheduleTemplates.data?.processScheduleTemplates?.items && (
          <TreeView
            aria-label="processScheduleTemplateTree"
            defaultCollapseIcon={<MinusSquare />}
            defaultExpandIcon={<PlusSquare />}
            expanded={expanded}
            multiSelect
            onNodeSelect={_handleSelect}
            onNodeToggle={_handleToggle}
            selected={selected}
          >
            {processScheduleTemplates.data?.processScheduleTemplates?.items.map(
              (pst: ProcessScheduleTemplate) => (
                <CustomTreeItem
                  key={`${pst.name}-${pst.id}`}
                  label={pst.name}
                  nodeId={pst.id.toString()}
                  sx={treeItemSx}
                >
                  <TableContainer>
                    <Table size="small">
                      <TableHead>
                        <TableRow sx={tableHeadRowSx}>
                          <TableCell>
                            {t('entities:ProcessScheduleDetail.Sequence')}
                          </TableCell>
                          <TableCell>{t('entities:Process.Process')}</TableCell>
                          <TableCell>
                            {t('entities:ProcessScheduleDetail.InHouse')}
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {pst.processScheduleTemplateDetails?.map((pstD) => (
                          <TableRow
                            key={`${pst.name}-${pstD?.sequence}`}
                            sx={tableHeadRowSx}
                          >
                            <TableCell align="right">
                              {pstD?.sequence}
                            </TableCell>
                            <TableCell>{pstD?.process?.name}</TableCell>
                            <TableCell align="center">
                              <ETOCheckBox
                                disabled
                                name="inHouse"
                                size="small"
                                sxProps={{
                                  marginRight: 0,
                                }}
                                value={pstD?.inHouse}
                              />
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </CustomTreeItem>
              )
            )}
          </TreeView>
        )}
      <Box sx={buttonWrapSx}>
        <Button
          disabled={!selectedPST}
          onClick={() => onPSTClicked(selectedPST ?? -1)}
          sx={{ textTransform: 'capitalize' }}
        >
          {t('generic.select')}
        </Button>
        <Button
          onClick={_handleExpandClick}
          sx={{ textTransform: 'capitalize' }}
        >
          {expanded.length === 0
            ? `${t('generic.expandAll')}`
            : `${t('generic.collapseAll')}`}
        </Button>
      </Box>
    </Box>
  );
};

export default ProcessScheduleTemplateTree;
