import CheckBox from '@inovua/reactdatagrid-community/packages/CheckBox';
import { IColumn } from '@inovua/reactdatagrid-enterprise/types';
import React from 'react';

const GridCustomCheckbox = {
  locked: 'start',
  minWidth: 50,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  renderCheckbox: (checkboxProps, cellProps) => {
    const {
      checked,
      isIndeterminate,
      onChange,
      onClick,
      className,
      theme,
      supportIndeterminate,
    } = checkboxProps;

    const { data, summaryProps } = cellProps;
    const value = isIndeterminate ? String(isIndeterminate) : 'false';
    // this is checkbox is responsible so that it isn't seen in the grouped rows
    return (
      data &&
      !data?.__group &&
      !summaryProps?.groupProps?.__group && (
        <CheckBox
          aria-checked={checked}
          checked={checked}
          className={className}
          data-testid="GridCustomCheckbox"
          isindeterminate={value}
          onChange={onChange}
          onClick={onClick}
          role="checkbox"
          supportIndeterminate={supportIndeterminate}
          theme={theme}
        />
      )
    );
  },
} as IColumn;

export default GridCustomCheckbox;
