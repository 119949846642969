import { Box, SxProps, Theme } from '@mui/material';
import { ETOButton, MessageContext } from '@teto/react-component-library-v2';
// eslint-disable-next-line import/no-unresolved
import { ETOButtonProps } from '@teto/react-component-library-v2/dist/components/Button/ETOButtonProps';
import { FormikHelpers, FormikProps, FormikValues } from 'formik';
import { uniqueId } from 'lodash';
import React, {
  useCallback,
  useContext,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { ObjectShape } from 'yup/lib/object';
import NoRecords from '../../../../NoRecords/NoRecords';
import deepPropertyHelper from '../../../../TETOGridGraphQL/Formatters/deepPropertyHelpers';
import { InspectorActions } from '../../../types/InspectorActionTypes';
import { InspectorState } from '../../../types/InspectorState';
import AccordionList, {
  TAccordionListItem,
} from '../../AccordionList/AccordionList';
import CommonAddEditContainer, {
  AddEditFormComponent,
  AddEditFormikSchema,
} from './CommonAddEditContainer';
import PanelWithNewItemPopover from './PanelWithNewItemPopover';

type TStandardProps<T> = Pick<InspectorActions<T>, 'setEditingState'> &
  Pick<InspectorState<T>, 'editingState'>;

export interface AddEditListProps<T> extends TStandardProps<T> {
  /* eslint-disable no-unused-vars */
  accordionListItems: Record<string, unknown>[] | T[];
  additionalButtons?: (Omit<ETOButtonProps, 'color' | 'size'> | undefined)[];
  addLabel?: string;
  addItemSx?: SxProps<Theme>;
  autoSave?: boolean;
  buttonDisabledCondition?: boolean;
  canAddItem: boolean;
  canDeleteItem: boolean;
  customListSx?: SxProps<Theme>;
  isNewItemOpen: boolean;
  setIsNewItemOpen: React.Dispatch<React.SetStateAction<boolean>>;
  listContainerSx?: SxProps<Theme>;
  customNoRecordsText?: string;
  newItemComponent?: React.ReactNode;
  noRecordsSx?: SxProps<Theme>;
  formComponent: AddEditFormComponent;
  expandForm?: boolean;
  formikInitialValues: Record<string, unknown>;
  formikSchema: AddEditFormikSchema<ObjectShape>;
  formikProps?: Partial<FormikProps<T>>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  formatToAccordionListItem?: (a: any) => TAccordionListItem<T>;
  icon?: React.ReactNode;
  selectedItem?: Record<string, unknown> | T;
  setSelectedItem:
    | React.Dispatch<React.SetStateAction<Record<string, unknown> | undefined>>
    | React.Dispatch<React.SetStateAction<T | undefined>>;
  accordionTitleKey?: string;
  accordionSubtitleKey?: string;
  onDelete: (
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    values: any
  ) => void;
  onUpdate: (
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    values: any,
    actions: FormikHelpers<T>
  ) => void;
  onAdd: (
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    values: any,
    actions: FormikHelpers<T>
  ) => void;
  buttonLayoutSx?: SxProps<Theme>;
  /* eslint-enable */
}
const defaultBtnLayoutSx: SxProps = {
  display: 'flex',
  justifyContent: 'end',
  width: '100%',
  ml: 1,
};
const AddEditList = <T extends FormikValues>(props: AddEditListProps<T>) => {
  const {
    accordionListItems,
    accordionSubtitleKey,
    accordionTitleKey,
    addItemSx,
    additionalButtons,
    addLabel,
    autoSave,
    buttonDisabledCondition,
    buttonLayoutSx,
    canAddItem,
    canDeleteItem,
    customListSx,
    customNoRecordsText,
    editingState,
    formatToAccordionListItem,
    formComponent,
    formikInitialValues,
    formikProps,
    formikSchema,
    icon,
    isNewItemOpen,
    listContainerSx,
    newItemComponent,
    noRecordsSx,
    onAdd,
    onDelete,
    onUpdate,
    selectedItem,
    setEditingState,
    setIsNewItemOpen,
    setSelectedItem,
    expandForm,
  } = props;

  const [confirmAbandonForm, setConfirmAbandonForm] = useState(false);
  const { t } = useTranslation();
  const messageContext = useContext(MessageContext);
  const buttonRef = useRef<HTMLButtonElement | null>(null);

  const _handleAddNewClick = useCallback(() => {
    setIsNewItemOpen(true);
    setEditingState({
      isEditing: true,
      hasEdited: true,
      tabError: false,
    });
  }, [setEditingState, setIsNewItemOpen]);

  const _handleAddNewFormClick = useCallback(
    (
      value: Record<string, unknown>,
      mode: 'add' | 'edit',
      actions: FormikHelpers<T>
    ) => {
      try {
        onAdd(value, actions);
        actions.setSubmitting(false);
      } catch (error) {
        setEditingState({
          isEditing: true,
          hasEdited: true,
          tabError: true,
        });
        if (error instanceof Error) {
          messageContext.setError(error.message);
        }
      }
    },
    [messageContext, onAdd, setEditingState]
  );
  const _handleEditForm = useCallback(
    (
      value: Record<string, unknown>,
      mode: 'add' | 'edit',
      actions: FormikHelpers<T>
    ) => {
      try {
        onUpdate(value, actions);
      } catch (error) {
        setEditingState({
          isEditing: true,
          hasEdited: true,
          tabError: true,
        });
        if (error instanceof Error) {
          messageContext.setError(error.message);
        }
      } finally {
        actions.setSubmitting(false);
      }
    },
    [messageContext, onUpdate, setEditingState]
  );

  const _onDelete = useCallback(() => {
    try {
      onDelete(selectedItem);
    } catch (error) {
      setEditingState({
        isEditing: true,
        hasEdited: true,
        tabError: true,
      });
      if (error instanceof Error) {
        messageContext.setError(error.message);
      }
    }
  }, [messageContext, onDelete, selectedItem, setEditingState]);

  const buttonList = useMemo(
    () => [
      ...(additionalButtons || []),
      {
        children: addLabel || t('generic.add'),
        onClick: () => _handleAddNewClick(),
        disabled: !canAddItem || isNewItemOpen || buttonDisabledCondition,
      },
    ],
    [
      _handleAddNewClick,
      addLabel,
      additionalButtons,
      buttonDisabledCondition,
      canAddItem,
      isNewItemOpen,
      t,
    ]
  );
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  function isNumberOrString(value: any): value is number | string {
    return typeof value === 'number' || typeof value === 'string';
  }

  const formatItem = useCallback(
    (a: Record<string, unknown> | T) => {
      if (formatToAccordionListItem) {
        return formatToAccordionListItem(a);
      }

      if (!accordionTitleKey || !accordionSubtitleKey) {
        throw new Error(
          'accordionTitleKey and accordionSubtitleKey must be defined'
        );
      }
      const item: TAccordionListItem<T> = {
        data: a as unknown as T,
        title: String(deepPropertyHelper(accordionTitleKey, a)),
        subTitles: [String(deepPropertyHelper(accordionSubtitleKey, a))],
        id:
          a && typeof a === 'object' && 'id' in a && isNumberOrString(a.id)
            ? a.id
            : uniqueId(),
      };
      return item;
    },
    [accordionSubtitleKey, accordionTitleKey, formatToAccordionListItem]
  );

  const accordionListItemsFormatted = useMemo(
    (): TAccordionListItem<T>[] =>
      accordionListItems?.map((a) => formatItem(a)),
    [accordionListItems, formatItem]
  );
  return (
    <PanelWithNewItemPopover
      addButtonRef={buttonRef}
      addItemButton={
        <>
          <Box sx={{ ...defaultBtnLayoutSx, ...buttonLayoutSx }}>
            {buttonList.map((button, i) => (
              <ETOButton
                buttonProps={{
                  ref: buttonRef,
                }}
                color="primary"
                customSx={i === buttonList.length ? {} : { ml: 2 }} // no margin if there is only 1 button
                disabled={button?.disabled}
                key={uniqueId()}
                onClick={button?.onClick}
                size="medium"
                {...{ button }}
              >
                {button?.children}
              </ETOButton>
            ))}
          </Box>
        </>
      }
      addItemSx={addItemSx}
      editingState={editingState}
      isNewItemOpen={isNewItemOpen}
      listContainerSx={listContainerSx}
      newItemComponent={
        newItemComponent ?? (
          <CommonAddEditContainer
            confirmAbandonForm={confirmAbandonForm}
            formComponent={formComponent}
            formikProps={formikProps}
            formikSchema={formikSchema}
            initialValues={formikInitialValues}
            mode="add"
            onSubmit={_handleAddNewFormClick}
            setConfirmAbandonForm={setConfirmAbandonForm}
            setEditingState={setEditingState}
            setIsNewItemOpen={setIsNewItemOpen}
            setSelectedItem={() => setSelectedItem(undefined)}
          />
        )
      }
      reposition={expandForm}
      setConfirmAbandonForm={setConfirmAbandonForm}
      setIsNewItemOpen={setIsNewItemOpen}
    >
      {accordionListItemsFormatted.length === 0 && (
        <NoRecords customSx={noRecordsSx} customText={customNoRecordsText} />
      )}
      {accordionListItemsFormatted.length > 0 && (
        <AccordionList
          customListSx={customListSx}
          editingState={editingState}
          expandedComponent={
            selectedItem && (
              <CommonAddEditContainer
                autoSave={autoSave}
                canDeleteItem={canDeleteItem}
                confirmAbandonForm={confirmAbandonForm}
                formComponent={formComponent}
                formikProps={formikProps}
                formikSchema={formikSchema}
                initialValues={selectedItem}
                mode="edit"
                onDelete={_onDelete}
                onSubmit={_handleEditForm}
                setConfirmAbandonForm={setConfirmAbandonForm}
                setEditingState={setEditingState}
                setIsNewItemOpen={setIsNewItemOpen}
                setSelectedItem={setSelectedItem}
              />
            )
          }
          expandedItem={selectedItem ? formatItem(selectedItem) : undefined}
          icon={icon}
          listItems={accordionListItemsFormatted}
          onItemExpandStateChanged={(accordionListItem) => {
            if (accordionListItem?.data) {
              return setSelectedItem(accordionListItem?.data);
            }
            return setSelectedItem(accordionListItem as undefined);
          }}
          setConfirmAbandonForm={setConfirmAbandonForm}
        />
      )}
    </PanelWithNewItemPopover>
  );
};

export default AddEditList;
