import React, { useContext, useMemo } from 'react';

import { Permission } from 'teto-client-api';
import AuthContext from '../../../../../contexts/AuthContext';
import CommonDocumentsPanel, {
  DocumentsPanelProps,
} from '../../../../Inspectors/components/CommonDocumentsPanel/CommonDocumentsPanel';

type PurchaseOrderDocumentsPanelProps = Omit<
  DocumentsPanelProps,
  | 'addNewMutation'
  | 'deleteMutation'
  | 'entityName'
  | 'entityQuery'
  | 'permissions'
  | 'updateMutation'
>;

const addPurchaseOrderDocumentMutation = `mutation addPurchaseOrderDocuments($input: AddPurchaseOrderDocumentInput) {
  addPurchaseOrderDocument( input: $input) {
    items {
      id
      documentLocation
    }
  }
}`;
const deletePurchaseOrderDocumentMutation = `mutation deletePurchaseOrderDocuments($id:Int!) {
  deletePurchaseOrderDocument( id:$id) {
    success
  }
}`;
const getPurchaseOrderDocumentsQuery = `query purchaseOrderDocuments($purchaseOrderId: Int!) {
  purchaseOrderDocuments( purchaseOrderId: $purchaseOrderId) {
    items {
      id
      documentLocation
      pathType
      fileName
      fileExtension
      fileSize
      fileExists
      purchaseOrderId
    }
  }
}`;
const updatePurchaseOrderDocumentMutation = `mutation updatePurchaseOrderDocuments(
  $input: UpdatePurchaseOrderDocumentInput
) {
  updatePurchaseOrderDocument(input: $input) {
    items {
      id
      documentLocation
    }
  }
}
`;

const PurchaseOrderDocumentsPanel = (
  props: PurchaseOrderDocumentsPanelProps
) => {
  const authContext = useContext(AuthContext);
  const documentsPermissions = useMemo(
    () => ({
      canAdd: authContext.hasPermission(
        Permission.Add_Procurement_PurchaseOrders_PurchaseOrderHeader_Documents
      ),
      canDelete: authContext.hasPermission(
        Permission.Delete_Procurement_PurchaseOrders_PurchaseOrderHeader_Documents
      ),
      canEdit: authContext.hasPermission(
        Permission.Modify_Procurement_PurchaseOrders_PurchaseOrderHeader_Documents
      ),
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [authContext]
  );

  return (
    <CommonDocumentsPanel
      addNewMutation={addPurchaseOrderDocumentMutation}
      deleteMutation={deletePurchaseOrderDocumentMutation}
      entityName="PurchaseOrder"
      entityQuery={getPurchaseOrderDocumentsQuery}
      permissions={{
        canAddDocuments: documentsPermissions.canAdd,
        canDeleteDocuments: documentsPermissions.canDelete,
        canModifyDocuments: documentsPermissions.canEdit,
      }}
      updateMutation={updatePurchaseOrderDocumentMutation}
      {...props}
    />
  );
};

export default PurchaseOrderDocumentsPanel;
