import { gql } from '../../__generated__';

const myFsl = gql(`query myFslLocations {
  myFileSystemLocations {
    items {
      name
      id
      path
    }
  }
}
`);

const browse = gql(`query browse($id: Int!, $path: String) {
  browseFileSystemLocation(id: $id, path: $path) {
    status
    entries {
      entryType
      id
      name
    }
  }
}`);

export { browse, myFsl };
