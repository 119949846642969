const getCompanyQuery = `
query getCompany($id: Int!) {
  company(id: $id) {
    accCustomerDisc
    accCustomerGroupCode
    accCustomerId
    accVendorId
    accVendorTaxClass1
    active
    address1
    address2
    aRTaxCategoryId
    businessAddress1
    businessAddress2
    businessAddressSameAsMailing
    businessCity
    businessCountryId
    businessState
    businessZip
    city
    countryId
    custom1
    custom2
    custom3
    custom4
    custom5
    custom6
    custom7
    custom8
    date
    defaultEmailAddress
    defaultPartDiscount
    defaultPartMargin
    defaultShipViaId
    defaultTermsId
    fax
    hasAgent
    id
    name
    parentId
    phone
    shippingAddress1
    shippingAddress2
    shippingAddressSameAsMailing
    shippingCity
    shippingCountryId
    shippingState
    shippingZip
    state
    tollFree
    webSite
    zip
  }
}
`;

export default getCompanyQuery;
