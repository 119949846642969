import { gql } from '../../../__generated__';

const closedJobsQuery = gql(`query closedJobs($jobId: Float, $projectId: Int!) {
  masterQueue(
    where: { specId: { eq: $jobId }, and: { projectId: { eq: $projectId } } }
  ) {
    items {
      closingRelease
      closingReleaseDate
      salesReleaseDate
    }
  }
}`);
export default closedJobsQuery;
