import { gql } from '../../../../../__generated__';

const startMyClockIn = gql(`
mutation startMyClockIn {
  startMyClockIn {
    success
    id
    date
    employeeId
    startTime
  }
}`);
export default startMyClockIn;
