import { gql } from '../../../../__generated__';

export const addRFQHeaderMutation =
  gql(`mutation addRFQHeaderMutation($input: AddRFQHeaderInput) {
    rfq: addRFQHeader(input: $input) {
        id
    }
}`);

export const addRFQHeaderAndDetailsMutation =
  gql(`mutation addRFQHeaderAndDetails($input: AddRFQHeaderAndDetailsInput) {
  rfq: addRFQHeaderAndDetails(input: $input) {
    id
  }  
}`);
