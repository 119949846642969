import {
  Box,
  Collapse,
  IconButton,
  ListItem,
  ListItemIcon,
  ListItemText,
  SxProps,
  Theme,
} from '@mui/material';
import { isArray, uniqueId } from 'lodash';
import React, { useCallback } from 'react';
import ExpandLessRounded from '@mui/icons-material/ExpandLessRounded';
import ExpandMoreRounded from '@mui/icons-material/ExpandMoreRounded';
import EditingState from '../../types/EditingState';

interface AccordionListItemProps {
  dataTestId?: string;
  icon?: React.ReactNode;
  sx?: SxProps<Theme>;
  expanded?: boolean;
  setConfirmAbandonForm: React.Dispatch<React.SetStateAction<boolean>>;
  editingState: EditingState;
  expandedComponent?: React.ReactNode;
  subTitles?: (string | string[] | React.ReactNode | React.ReactNode[])[];
  title: string;
  // eslint-disable-next-line no-unused-vars
  onExpandStateChanged: (expanded: boolean) => void;
}

export const subTitleListSx: SxProps<Theme> = {
  m: 0,
  p: 0,
  display: 'block',
  '& > li': {
    m: 0,
    p: 0,
    listStyle: 'none',
  },
};

const AccordionListItem: React.FC<AccordionListItemProps> = (props) => {
  const {
    dataTestId,
    editingState,
    expanded,
    expandedComponent,
    icon,
    onExpandStateChanged,
    setConfirmAbandonForm,
    subTitles,
    sx,
    title,
  } = props;

  const _renderTitle = useCallback(
    (
      t: AccordionListItemProps['title'],
      st: AccordionListItemProps['subTitles']
    ) => {
      const subTitleComponent = (
        <Box component="span" sx={subTitleListSx}>
          {st &&
            st.map((s) =>
              isArray(s) ? (
                <Box component="span" key={uniqueId()}>
                  <b>{s[0]}:</b>
                  &nbsp;
                  {s[1]}
                </Box>
              ) : (
                <Box component="span" key={uniqueId()}>
                  {s}
                </Box>
              )
            )}
        </Box>
      );

      return (
        <ListItemText
          data-testid={dataTestId}
          primary={t}
          primaryTypographyProps={{
            sx: {
              display: 'inline !important',
            },
          }}
          secondary={subTitleComponent}
          sx={{
            overflowX: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
          }}
        />
      );
    },
    [dataTestId]
  );

  const _handleCloseItem = () => {
    if (editingState.tabError) {
      setConfirmAbandonForm(true);
    } else {
      onExpandStateChanged(false);
    }
  };

  return (
    <>
      <ListItem sx={sx}>
        {icon && <ListItemIcon>{icon}</ListItemIcon>}
        {_renderTitle(title, subTitles)}
        <>
          {expanded && (
            <IconButton color="primary" onClick={() => _handleCloseItem()}>
              <ExpandLessRounded />
            </IconButton>
          )}
          {!expanded && (
            <IconButton
              color="primary"
              disabled={editingState.tabError}
              onClick={() => onExpandStateChanged(true)}
            >
              <ExpandMoreRounded />
            </IconButton>
          )}
        </>
      </ListItem>
      {expandedComponent && (
        <Collapse in={expanded} timeout="auto" unmountOnExit>
          {expanded && expandedComponent}
        </Collapse>
      )}
    </>
  );
};

export default AccordionListItem;
