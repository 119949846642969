/* eslint-disable @typescript-eslint/no-explicit-any */
import { FormControlLabel, Radio, RadioGroup } from '@mui/material';
import Box from '@mui/material/Box';
import { SxProps, Theme } from '@mui/material/styles';
import {
  ConfirmDialogs,
  ETOSelectField,
  MessageContext,
} from '@teto/react-component-library-v2';
import { FormikProps, useFormik } from 'formik';
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import useLocalStorage from 'use-local-storage';
import * as Yup from 'yup';

import { salesReleasedQuery } from '../../../api/commonQueries';
import useGQLQuery from '../../../api/graphQL/useGQLQuery';
import SettingsContext from '../../../contexts/SettingsContext';
import ReasonRequiredDateForm from '../../../pages/InventoryPage/ItemsToPullTab/Inspectors/ReasonRequiredDateForm';
import {
  RequisitionHeader,
  RequisitionHeaderFormProps,
  RequisitionTo,
} from '../../../pages/InventoryPage/ItemsToPullTab/Inspectors/types/RequisitionTypes';
import InventoryPullForm from '../../../pages/InventoryPage/components/Reports/InventoryPullForm';
import {
  MAXREASON,
  REQUIRED_MESSAGE,
} from '../../../pages/InventoryPage/components/helpers/inventoryConstants';
import { JobProject } from '../../../pages/InventoryPage/types/JobProject';
import { InventoryLocation } from '../../../pages/InventoryPage/types/inventoryLocation';
import useInventoryLocation from '../../../pages/InventoryPage/useInventoryLocation';
import closedJobsQuery from '../../../pages/ProcurementPage/queries/closedJobsQuery';

const fieldSetSx: SxProps<Theme> = {
  display: 'flex',
  borderColor: 'divider',
  borderRadius: 1,
  borderWidth: 'thin',
  width: '100%',
  rowGap: 2,
  flexDirection: 'column',
};

const containerSx: SxProps<Theme> = {
  alignItems: 'center',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  rowGap: 2,
  width: '100%',
};

const defaultRequisition = 'project';

const headerSchema = Yup.object().shape({
  addReason: Yup.string().max(MAXREASON),
  destInventoryLocationId: Yup.number().required(REQUIRED_MESSAGE),
  projectId: Yup.number().required(REQUIRED_MESSAGE),
  requiredDate: Yup.date(),
  specId: Yup.number().required(REQUIRED_MESSAGE),
});

const requiredDateNReasonSchema = Yup.object().shape({
  addReason: Yup.string().max(MAXREASON),
  requiredDate: Yup.date().required(REQUIRED_MESSAGE),
});

const formikInitialValues: RequisitionHeader = {
  addReason: '',
  destInventoryLocationId: -1,
  projectId: undefined,
  requiredDate: '',
  requisitionTo: 'project',
  specId: undefined,
};

const RequisitionHeaderForm = (props: RequisitionHeaderFormProps) => {
  const {
    isStockReOrder,
    setHeadFormValues,
    setPanelEditingState,
    hasOptionChange,
    requireReasonNDate,
    shouldWarnAboutClosedJobs,
    disableReorder,
    setDisableReorder,
  } = props;
  const {
    settings: {
      inventoryProject,
      inventorySpecID,
      addItemsPriorToSalesRelease,
      pOItemsForClosedMachines,
    },
  } = useContext(SettingsContext);

  const { data: locations } = useInventoryLocation();
  const [inventoryLocation] = useLocalStorage('currentInventoryLocation', 1);

  const { t } = useTranslation();
  const messageContext = useContext(MessageContext);

  const [
    allowAddItemsPriorToSalesReleaseWarning,
    setAllowAddItemsPriorToSalesReleaseWarning,
  ] = useState(false);
  const [
    allowAddItemsWithClosedJobsWarning,
    setAllowAddItemsWithClosedJobsWarning,
  ] = useState(false);

  const _locations = useMemo(
    // should not include `all`
    () => locations?.filter((loc: { id: number }) => loc.id !== 0) ?? [],
    [locations]
  );

  const formik = useFormik<RequisitionHeader>({
    initialValues: {
      ...formikInitialValues,
      destInventoryLocationId: inventoryLocation,
    },
    enableReinitialize: true,
    validationSchema: !requireReasonNDate
      ? headerSchema
      : headerSchema.concat(requiredDateNReasonSchema),
    validateOnMount: true,
    // eslint-disable-next-line no-alert, no-unused-vars
    onSubmit: (
      values: any,
      // eslint-disable-next-line no-unused-vars
      actions: { setSubmitting: (arg0: boolean) => void }
    ) => {
      setHeadFormValues((ov: any) => ({ ...ov, values }));
      actions.setSubmitting(false);
    },
  });

  const _handleOptionChange = useCallback(
    (e: RequisitionTo) => {
      const affectedValues =
        e === 'inventory'
          ? { projectId: inventoryProject, specId: inventorySpecID }
          : { projectId: undefined, specId: undefined };

      const updates = {
        ...formik.values,
        requisitionTo: e,
        ...affectedValues,
      };

      formik.setValues(updates);
      setHeadFormValues((ov: any) => ({ ...ov, ...updates }));
      hasOptionChange(true);
    },
    [
      inventoryProject,
      inventorySpecID,
      formik,
      setHeadFormValues,
      hasOptionChange,
    ]
  );

  const _handlePullFormChange = useCallback(
    (value: JobProject) => {
      setHeadFormValues((ov: any) => ({
        ...ov,
        ...formik.values,
        ...value,
      }));
    },
    [formik.values, setHeadFormValues]
  );

  const _handleReasonRequiredDateChange = useCallback(
    (value: Record<string, unknown>) => {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      setHeadFormValues((ov: any) => ({
        ...ov,
        ...formik.values,
        ...value,
      }));
    },
    [formik, setHeadFormValues]
  );

  useEffect(() => {
    if (!formik.isValid) {
      setPanelEditingState({
        isEditing: formik.dirty,
        isValid: false,
      });
    } else {
      setPanelEditingState({
        isEditing: formik.dirty,
        isValid: true,
      });
    }
  }, [formik.dirty, formik.isValid, setPanelEditingState]);

  useGQLQuery(
    [
      formik.values.projectId,
      formik.values.specId,
      addItemsPriorToSalesRelease,
    ],
    {
      queryString: salesReleasedQuery,
      variables: {
        projectId: formik.values.projectId,
        jobId: formik.values.specId,
      },
      callback: (resp) => {
        if (
          !addItemsPriorToSalesRelease &&
          resp?.masterQueue?.items?.length === 0
        ) {
          setAllowAddItemsPriorToSalesReleaseWarning(true);
          formik.setFieldValue('projectId', undefined);
          formik.setFieldValue('specId', undefined);
        }
      },
      options: {
        retry: false,
        enabled: Boolean(
          formik.values.projectId &&
            !addItemsPriorToSalesRelease &&
            formik.values.specId
        ),
      },
    }
  );

  useGQLQuery(
    [formik.values.projectId, formik.values.specId, shouldWarnAboutClosedJobs],
    {
      queryString: closedJobsQuery,
      variables: {
        projectId: formik.values.projectId,
        jobId: formik.values.specId,
      },
      callback: (resp) => {
        if (
          !pOItemsForClosedMachines &&
          resp?.masterQueue?.items?.[0]?.closingReleaseDate // if a job is closed it should have a date
        ) {
          setAllowAddItemsWithClosedJobsWarning(true);
          formik.setFieldValue('specId', undefined);
        }
      },
      options: {
        retry: false,
        enabled: Boolean(
          shouldWarnAboutClosedJobs &&
            !pOItemsForClosedMachines &&
            formik.values.projectId &&
            formik.values.specId
        ),
      },
    }
  );

  useEffect(() => {
    if (isStockReOrder && !formik.values?.addReason) {
      formik.setFieldValue(
        'addReason',
        t('pages.inventory.requisition.stockReorder')
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isStockReOrder]);

  return (
    <Box sx={containerSx}>
      <ETOSelectField
        disabled={formik.values.requisitionTo === 'project'}
        error={formik.errors.destInventoryLocationId}
        handleChange={(e) => {
          formik.setFieldValue('destInventoryLocationId', e.target.value);
          setHeadFormValues((ov: any) => ({
            ...ov,
            ...formik.values,
            destInventoryLocationId: e.target.value,
          }));
          if (disableReorder) {
            setDisableReorder(false);
            messageContext.setWarning(
              t('pages.inventory.requisition.reorderWarning')
            );
          }
        }}
        itemNameSelector={(i: InventoryLocation) => i?.name ?? ''}
        items={_locations}
        itemValueSelector={(i: InventoryLocation) => i?.id}
        label={t('pages.inventory.itemsToPull.inventoryLocation')}
        name="destInventoryLocationId"
        size="small"
        value={formik.values.destInventoryLocationId}
      />

      <Box component="fieldset" sx={fieldSetSx}>
        <legend>{t('pages.inventory.itemsToPull.requisitionTo')}</legend>
        <RadioGroup
          defaultValue={defaultRequisition}
          name="requisitionTo"
          onChange={(e) => _handleOptionChange(e.target.value as RequisitionTo)}
          sx={{ flexDirection: 'row', justifyContent: 'space-between' }}
          value={formik.values.requisitionTo}
        >
          <FormControlLabel
            control={<Radio />}
            label={t('entities:Project.Project')}
            sx={{ flexGrow: 1 }}
            value="project"
          />

          <FormControlLabel
            control={<Radio />}
            label={t('entities:Inventory.Inventory')}
            sx={{ flexGrow: 1 }}
            value="inventory"
          />
        </RadioGroup>
      </Box>

      <InventoryPullForm
        disableInputs={
          formik.values.requisitionTo === 'inventory'
            ? { projectId: true, specId: true }
            : {}
        }
        formik={formik}
        handleChange={_handlePullFormChange}
        shouldWarnAboutClosedJobs={shouldWarnAboutClosedJobs}
      />

      <ReasonRequiredDateForm
        formik={formik as unknown as FormikProps<Record<string, unknown>>}
        handleChange={_handleReasonRequiredDateChange}
      />

      <ConfirmDialogs
        content={t('dialogs.salesReleased', {
          location: t('pages.inventory.itemsToPull.requisitionInspector'),
        })}
        open={allowAddItemsPriorToSalesReleaseWarning}
        rightButton={{
          label: t('generic.ok'),
          onClick: () => setAllowAddItemsPriorToSalesReleaseWarning(false),
        }}
        title={t('generic.error')}
      />
      <ConfirmDialogs
        content={t('dialogs.closedJob', {
          location: t('entities:PurchaseOrder.PurchaseOrder'),
        })}
        open={allowAddItemsWithClosedJobsWarning}
        rightButton={{
          label: t('generic.ok'),
          onClick: () => setAllowAddItemsWithClosedJobsWarning(false),
        }}
        title={t('generic.error')}
      />
    </Box>
  );
};

export default RequisitionHeaderForm;
