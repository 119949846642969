import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import {
  cancelMyPunchIn,
  getMyPunchIns,
  Permission,
  PunchIn,
  startMyPunchIn,
  StartPunchInRequest,
  stopMyPunchIn,
  StopPunchInRequest,
} from 'teto-client-api';
import { MessageContext } from '@teto/react-component-library-v2';
import useLocationFormatted from '../helpers/useLocationFormatted';
import { parseServerResponse } from '../helpers/validationHelperREST';
import AuthContext from './AuthContext';
import GeolocationInfo from '../helpers/IGeolocationInfo';

export type TimerStatus = 'active' | 'inactive' | 'error' | 'paused';

export interface PunchInTimerContextState {
  status: TimerStatus;
  refreshToken: Date;
  activeTimerId?: number;
  errorMessage?: string;
  duration?: number;
  durationFormatted?: string;
  details?: PunchIn;
  geoInfo: GeolocationInfo;
  refreshTimer: () => Promise<void>;
  startMyPunchIn: (
    // eslint-disable-next-line no-unused-vars
    request: StartPunchInRequest
  ) => Promise<void | { id: number }>;

  // eslint-disable-next-line no-unused-vars
  stopMyPunchIn: (id: number, request: StopPunchInRequest) => Promise<void>;
  // eslint-disable-next-line no-unused-vars
  cancelMyPunchIn: (id: number) => Promise<void>;
  setTimerStatus: React.Dispatch<React.SetStateAction<TimerStatus>>;
  inspector: 'closed' | 'startTimer' | 'stopTimer';
  setInspector: (
    // eslint-disable-next-line no-unused-vars
    inspector: 'closed' | 'startTimer' | 'stopTimer',
    // eslint-disable-next-line no-unused-vars
    id?: number
  ) => void;
}

const PunchInTimerContext = React.createContext<PunchInTimerContextState>(
  null as never
);

const POLL_INTERVAL = 30000;

interface PunchInTimerContextProviderProps {
  children: React.ReactNode;
}

const PunchInTimerContextProvider = (
  props: PunchInTimerContextProviderProps
) => {
  const { children } = props;
  const [geoInfo] = useLocationFormatted();

  const { t } = useTranslation();

  const authContext = useContext(AuthContext);
  const messageContext = useContext(MessageContext);

  const [timerStatus, setTimerStatus] = useState<TimerStatus>('inactive');
  const [activeTimerId, setActiveTimerId] = useState<number | undefined>();

  const [errorMessage, setErrorMessage] = useState<string | undefined>(
    undefined
  );

  const [elapsedMinutes, setElapsedMinutes] = useState<number>(0);
  const [refreshToken, setRefreshToken] = useState<Date>(new Date());

  const [inspector, setInspector] = useState<
    'closed' | 'startTimer' | 'stopTimer'
  >('closed');
  const [punchInDetails, setPunchInDetails] = useState<PunchIn | undefined>(
    undefined
  );

  const _updateTimerStatus = useCallback(() => {
    setErrorMessage(undefined);

    return getMyPunchIns({ filter: { active: true }, disablePaging: true })
      .then((res) => {
        if (!res || res.records.length === 0) {
          setTimerStatus('inactive');
          setElapsedMinutes(0);
          return;
        }

        const record = res.records[0];
        setTimerStatus('active');
        setActiveTimerId(record.id);
        setElapsedMinutes(record.elapsedMinutes ?? 0);
        setPunchInDetails(record);
      })
      .catch((e) => {
        setErrorMessage(e.message);
        setTimerStatus('error');
        setElapsedMinutes(0);
        setPunchInDetails(undefined);
        parseServerResponse(
          e,
          (errors) => messageContext.setError(Object.values(errors)[0]),
          (error) => {
            let errString;
            if (typeof error === 'object') {
              errString = error?.message;
            } else {
              errString = error.toString();
            }
            messageContext.setError(errString);
          }
        );
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (
      authContext.hasEnterpriseLicense() &&
      authContext.hasPermission(Permission.Add_Time_Client_Punchcards)
    ) {
      _updateTimerStatus();
      const pollTimer = setInterval(() => {
        _updateTimerStatus();
      }, POLL_INTERVAL);

      return () => clearInterval(pollTimer);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [_updateTimerStatus]);

  const hours = Math.floor(elapsedMinutes / 60);
  const minutes = elapsedMinutes % 60;
  const time = minutes < 10 ? `${hours}:0${minutes}` : `${hours}:${minutes}`;

  const formattedDuration = time === undefined ? '0:00' : String(time);

  const _startMyPunchIn = useCallback(
    (request: StartPunchInRequest) =>
      startMyPunchIn(request)
        .then((a) => {
          setRefreshToken(new Date());
          setTimerStatus('active');
          messageContext.setSuccess(t('entities:PunchIn.StartTimerSuccess'));
          return a;
        })
        .catch((e) => {
          parseServerResponse(
            e,
            (errors) => messageContext.setError(Object.values(errors)[0]),
            (error) => messageContext.setError(error)
          );
          setElapsedMinutes(0);
          setPunchInDetails(undefined);
        }),
    [messageContext, t]
  );

  const _stopMyPunchIn = useCallback(
    (id: number, request: StopPunchInRequest) =>
      stopMyPunchIn(id, request)
        .then((a) => {
          setActiveTimerId(undefined);
          setTimerStatus('inactive');
          setRefreshToken(new Date());
          messageContext.setSuccess(t('entities:PunchIn.StopTimerSuccess'));
          return a;
        })
        .catch((e) => {
          parseServerResponse(
            e,
            (errors) => messageContext.setError(Object.values(errors)[0]),
            (error) => messageContext.setError(error)
          );
        }),
    [messageContext, t]
  );

  const _cancelMyPunchIn = useCallback(
    (id: number) =>
      cancelMyPunchIn(id)
        .then((a) => {
          setActiveTimerId(undefined);
          setTimerStatus('inactive');
          setRefreshToken(new Date());
          return a;
        })
        .catch((e) => {
          parseServerResponse(
            e,
            (errors) => messageContext.setError(Object.values(errors)[0]),
            (error) => messageContext.setError(error)
          );
          setTimerStatus('error');
        }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const _setInspector = useCallback(
    (ins: 'closed' | 'startTimer' | 'stopTimer') => {
      setInspector(ins);
    },
    []
  );

  const timerValues = useMemo(
    () => ({
      status: timerStatus,
      duration: elapsedMinutes,
      durationFormatted: formattedDuration,
      errorMessage,
      details: punchInDetails,
      refreshTimer: _updateTimerStatus,
      activeTimerId,
      refreshToken,
      cancelMyPunchIn: _cancelMyPunchIn,
      startMyPunchIn: _startMyPunchIn,
      stopMyPunchIn: _stopMyPunchIn,
      inspector,
      setInspector: _setInspector,
      setTimerStatus,
      geoInfo,
    }),
    [
      _cancelMyPunchIn,
      _setInspector,
      _startMyPunchIn,
      _stopMyPunchIn,
      _updateTimerStatus,
      activeTimerId,
      elapsedMinutes,
      errorMessage,
      formattedDuration,
      inspector,
      punchInDetails,
      refreshToken,
      timerStatus,
      geoInfo,
    ]
  );

  return (
    <PunchInTimerContext.Provider value={timerValues}>
      {children}
    </PunchInTimerContext.Provider>
  );
};

export const PunchInTimerProvider = PunchInTimerContextProvider;
export const PunchInTimerConsumer = PunchInTimerContext.Consumer;
export default PunchInTimerContext;
