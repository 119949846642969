/* eslint-disable no-unused-vars */
import { Box, Checkbox, ListItemText } from '@mui/material';
import { uniqueId } from 'lodash';
import React, { useEffect, useState } from 'react';

import { use } from 'i18next';
import { TETODataColumn } from '../../../GridBuilder/types/TETODataColumn';

interface FilterCheckboxProps {
  column: TETODataColumn;
  handleChange: (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    curCol: TETODataColumn
  ) => void | undefined | null;
  disabled?: boolean;
  value: string | boolean | null;
}

const FilterCheckbox = (props: FilterCheckboxProps) => {
  const { column, handleChange, disabled, value } = props;

  const [checked, setChecked] = useState<boolean>(() => {
    if (typeof value === 'boolean') {
      return value;
    }
    if (typeof value === 'string') {
      return value === 'true';
    }

    return false;
  });

  const _handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    handleChange(e, column);
    setChecked(!checked); // this improves how snappy it feels;
  };

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyItems: 'space-between',
        flexGrow: 1,
        pl: 2,
        pr: 1,
      }}
    >
      <ListItemText
        key={uniqueId(column.name)}
        primary={`${column.title} (${
          value === 'null' ? 'Null' : String(checked)
        })`}
      />

      <Checkbox
        checked={checked}
        color="primary"
        data-testid="filterListItemCheckBox"
        disabled={disabled || value === 'null'}
        indeterminate={value === 'null'}
        inputProps={{
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          'aria-checked': `${checked}`,
        }}
        key={column.name}
        name={column.name}
        onChange={(e) => _handleChange(e)}
        size="medium"
        value={checked}
      />
    </Box>
  );
};

export default FilterCheckbox;
