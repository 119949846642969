import {
  TypeSingleSortInfo,
  TypeSortInfo,
} from '@inovua/reactdatagrid-community/types';
import ArrowDropDownRoundedIcon from '@mui/icons-material/ArrowDropDownRounded';
import ArrowDropUpRoundedIcon from '@mui/icons-material/ArrowDropUpRounded';
import { Box, SxProps, Theme, Typography } from '@mui/material';
import React from 'react';

const defaultStyle: SxProps<Theme> = {
  display: 'inline-block',
  verticalAlign: 'middle',
  fontSize: (theme) => theme.spacing(3),
};

const textDefaultStyle: SxProps<Theme> = {
  fontSize: (theme) => theme.spacing(1.5),
  display: 'inline-block',
};

const boxSx = {
  display: 'inline-block',
  pr: 1,
};

const SortIndicator = (
  direction: number | undefined,
  gridProps: {
    name: string;
    sortedColumnsInfo: TypeSingleSortInfo[];
    columnIndex: number;
    groupToolbarItem: boolean;
  },
  sortedColumnsInfo: TypeSortInfo | undefined
) => {
  const index = () => {
    if (sortedColumnsInfo && Array.isArray(sortedColumnsInfo)) {
      return (
        sortedColumnsInfo.findIndex(
          (item: TypeSingleSortInfo) => item?.name === gridProps?.name
        ) + 1
      );
    }
  };

  return (
    <Box key={gridProps.name + gridProps.columnIndex} sx={boxSx}>
      {direction === 1 && (
        <>
          <ArrowDropUpRoundedIcon sx={defaultStyle} />
          <Typography sx={textDefaultStyle} variant="body2">
            {index() ?? ''}
          </Typography>
        </>
      )}
      {direction === -1 && (
        <>
          <ArrowDropDownRoundedIcon sx={defaultStyle} />
          <Typography sx={textDefaultStyle} variant="body2">
            {index() ?? ''}
          </Typography>
        </>
      )}
    </Box>
  );
};
export default SortIndicator;
