import { MessageContext } from '@teto/react-component-library-v2';
import dayjs from 'dayjs';
import { FormikHelpers, useFormik } from 'formik';
import { debounce, isArray } from 'lodash';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import * as Yup from 'yup';
import FormBuilder from '../FormBuilder/FormBuilder';
import TETODataField from '../FormBuilder/TETODataField';
import IHandlers from '../FormBuilder/types/IHandlers';
import FormDefinition from '../FormDefinition';
import { buildValidationSchema } from '../YupBuilder';
import { FormBuilderState } from './useFormBuilder';

const _calculateLocalStorageName = <FT,>(
  formBuilder: FormBuilder<FT>,
  field: TETODataField
): string => `${formBuilder.name}::${field.name}`;

const _buildInitialValues = <FT,>(formBuilder: FormBuilder<FT>) => {
  const { fields } = formBuilder;

  const obj = Object.fromEntries(
    fields.map((field: TETODataField) => [field.name, field.defaultValue])
  );

  fields
    .filter((a) => a.persist)
    .forEach((f) => {
      const localStorageValue = localStorage.getItem(
        _calculateLocalStorageName(formBuilder, f)
      );

      if (localStorageValue != null && localStorageValue !== undefined) {
        obj[f.name] = localStorageValue;
      }
    });

  return obj;
};

interface UseFormOptions {
  /**
   * If true the form will automatically save after x ms since last form change
   */
  autoSave?: boolean;
  /**
   * The interval (in ms) after the last change is made before we automatically save
   */
  autoSaveInterval?: number;

  /** Rather than only clear dependents if the parent field changes to a null/defined/empty value this
   * will clear dependents everytime the parent field value changes
   */
  alwaysClearDependantsOnChange?: boolean;
}

/**
 * useForm is responsible for mantaining the state of a form
 * generated from a formbuilder instance.  The formbuilder instance being
 * passed in should be considered immutable and should not be edited after
 * formbuilde is marked as ready
 * @param formBuilderState Form Builder State
 * @param formHandlers Handlers to affect form behavior
 * @param initialFormValues Initial values of the form, will be overlayed on top of the fields default values
 * @param options Form options
 */
const useForm = <FT,>(
  formBuilderState: FormBuilderState<FT>,
  formHandlers: IHandlers<FT>,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  initialFormValues: any = {},
  options: UseFormOptions | undefined = undefined
): FormDefinition => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [initialValues, setInitialValues] = useState<any>({});
  const [validationSchema, setValidationSchema] = useState<
    Yup.AnyObjectSchema | undefined
  >();
  const [hasSubmitted, setHasSubmitted] = useState(false);
  const [disabledFields, setDisabledFields] = useState<string[]>([]);
  const { setError } = useContext(MessageContext);

  const handlers = useMemo(
    () => ({
      ...formBuilderState.formBuilder.handlers,
      ...formHandlers,
    }),
    [formBuilderState.formBuilder.handlers, formHandlers]
  );

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const _parseFieldValue = useCallback((field: TETODataField, value: any) => {
    if (dayjs.isDayjs(value)) {
      switch (field.type) {
        case 'date':
          return dayjs(value).format('YYYY-MM-DD');
        case 'datetime':
        case 'time':
          return dayjs(value).format('YYYY-MM-DD hh:mm:ss');
        default:
          return value;
      }
    }

    if (typeof value !== 'string') {
      return value;
    }

    switch (field.type) {
      case 'boolean':
        return value === 'True' || value === 'true';
      case 'currency':
      case 'decimal':
        return parseFloat(value);
      case 'date':
        return dayjs(value).format('YYYY-MM-DD');
      case 'datetime':
      case 'time':
        return dayjs(value).format('YYYY-MM-DD hh:mm:ss');
      case 'integer':
        return parseInt(value, 10);
      default:
        return value;
    }
  }, []);

  const { builderReady, formBuilder, error: builderError } = formBuilderState;

  const _cleanValues = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (values: any) => {
      if (!formBuilder) return values;

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const output: any = {};
      const objAsArray = Object.entries(values);

      objAsArray.forEach((val) => {
        // eslint-disable-next-line eqeqeq
        if (val[1] !== undefined && val[1] !== null && val[1] !== '') {
          const field = formBuilder.findField(val[0]);

          if (field) {
            let value = val[1];
            if (handlers.onBeforeValueFormat)
              value = handlers.onBeforeValueFormat(field, value);

            let formattedValue = _parseFieldValue(field, value);

            if (handlers?.onAfterValueFormat)
              formattedValue = handlers.onAfterValueFormat(field, value);

            output[val[0]] = formattedValue;
          }
        }
      });

      return output;
    },
    [_parseFieldValue, formBuilder, handlers]
  );

  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema,
    validateOnBlur: true,
    onSubmit: (values, helpers) => {
      setHasSubmitted(true);
      _onSubmit(values, helpers);
    },
  });

  useEffect(() => {
    if (builderReady) {
      setInitialValues({
        ..._buildInitialValues(formBuilder),
        ...initialFormValues,
      });

      setValidationSchema(buildValidationSchema(formBuilder));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [builderReady, formBuilder]);

  const _enableField = useCallback((fieldName: string | string[]) => {
    if (isArray(fieldName)) {
      setDisabledFields((ov) => [
        ...ov.filter((a) => fieldName.indexOf(a) < 0),
      ]);
    } else {
      setDisabledFields((ov) => [...ov.filter((a) => a !== fieldName)]);
    }
  }, []);

  const _disableField = useCallback((fieldName: string | string[]) => {
    if (isArray(fieldName)) {
      setDisabledFields((ov) => [
        ...ov.filter((a) => fieldName.indexOf(a) < 0),
        ...fieldName,
      ]);
    } else {
      setDisabledFields((ov) => [
        ...ov.filter((a) => a !== fieldName),
        fieldName,
      ]);
    }
  }, []);

  const _setMultiFieldEnabled = useCallback(
    (obj: { [key: string]: boolean }) => {
      const results: { enable: string[]; disable: string[] } = Object.entries(
        obj
      ).reduce(
        (acc, cv) => {
          if (cv[1]) return { ...acc, enabled: [...acc.enable, cv[0]] };
          return { ...acc, disable: [...acc.disable, cv[0]] };
        },
        { enable: [] as string[], disable: [] as string[] }
      );

      if (results.enable.length > 0) _enableField(results.enable);
      if (results.disable.length > 0) _disableField(results.disable);
    },
    [_disableField, _enableField]
  );

  const _clearDependents = useCallback(
    (fieldName: string) => {
      formBuilder?.fields.forEach((f) => {
        if (f.dependsOn && f.dependsOn.indexOf(fieldName) >= 0) {
          formik.setFieldValue(f.name, '');
          if (handlers?.onFieldChange) {
            handlers.onFieldChange(f, '');
          }
        }
      });
    },
    [formBuilder?.fields, formik, handlers]
  );

  const _updateField = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (fieldName: string, value: any) => {
      const field = formBuilder?.findField(fieldName);
      if (field) {
        let newValue = value;
        if (handlers?.onBeforeValueFormat)
          newValue = handlers.onBeforeValueFormat(field, newValue);

        let formattedValue = _parseFieldValue(field, newValue);

        if (handlers?.onAfterValueFormat)
          formattedValue = handlers.onAfterValueFormat(field, value);

        formik.setFieldValue(fieldName, formattedValue);
        if (handlers?.onFieldChange) {
          handlers.onFieldChange(field, formattedValue);
        }

        if (
          formattedValue === undefined ||
          formattedValue === null ||
          formattedValue === '' ||
          options?.alwaysClearDependantsOnChange
        ) {
          _clearDependents(fieldName);
        }

        if (field.persist) {
          if (
            formattedValue === undefined ||
            formattedValue === null ||
            formattedValue === ''
          ) {
            localStorage.removeItem(
              _calculateLocalStorageName(formBuilder, field)
            );
          } else {
            localStorage.setItem(
              _calculateLocalStorageName(formBuilder, field),
              formattedValue
            );
          }
        }
      }
    },
    [
      _clearDependents,
      _parseFieldValue,
      formBuilder,
      formik,
      handlers,
      options?.alwaysClearDependantsOnChange,
    ]
  );

  const _submit = useCallback(() => formik.submitForm(), [formik]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedSubmit = useCallback(
    // eslint-disable-next-line no-return-await
    debounce(() => _submit(), options?.autoSaveInterval ?? 3000),
    [options?.autoSaveInterval]
  );
  useEffect(() => {
    if (options?.autoSave && formik.dirty && formik.values && formik.isValid)
      debouncedSubmit();
  }, [
    debouncedSubmit,
    formik.values,
    formik.dirty,
    formik.isValid,
    options?.autoSave,
  ]);

  const _reset = useCallback(() => formik.resetForm(), [formik]);

  const _setValidationErrors = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (errors: any) => {
      formik.setErrors(errors);
    },
    [formik]
  );

  const _setErrors = useCallback(
    (errors: string[]) => {
      if (handlers?.onErrors) handlers?.onErrors(errors);
      else {
        errors.forEach((f) => setError(f));
      }
    },
    [handlers, setError]
  );

  useEffect(() => {
    if (builderError) {
      _setErrors([builderError]);
    }
  }, [_setErrors, builderError]);

  const _findField = useCallback(
    (fieldName: string) => formBuilder?.findField(fieldName),
    [formBuilder]
  );
  const _removeField = useCallback(
    (fieldName: string) => formBuilder?.removeField(fieldName),
    [formBuilder]
  );

  const formDefinition = useMemo(
    (): FormDefinition => ({
      removeField: _removeField,
      getFieldByName: _findField,
      disableField: _disableField,
      disabledFields,
      enableField: _enableField,
      fields: formBuilder?.fields ?? [],
      handleBlur: formik.handleBlur,
      hasSubmitted,
      initialValues,
      dirty: formik.dirty,
      isValid: formik.isValid,
      isSubmitting: formik.isSubmitting,
      reset: _reset,
      setErrors: _setErrors,
      setMultiFieldEnabled: _setMultiFieldEnabled,
      setValidationErrors: _setValidationErrors,
      setSubmitting: formik.setSubmitting,
      submit: _submit,
      touched: formik.touched,
      updateField: _updateField,
      values: formik.values,
      validationErrors: formik.errors,
      validationSchema,
    }),
    [
      _disableField,
      _enableField,
      _findField,
      _removeField,
      _reset,
      _setErrors,
      _setMultiFieldEnabled,
      _setValidationErrors,
      _submit,
      _updateField,
      disabledFields,
      formBuilder?.fields,
      formik.dirty,
      formik.errors,
      formik.handleBlur,
      formik.isSubmitting,
      formik.isValid,
      formik.setSubmitting,
      formik.touched,
      formik.values,
      hasSubmitted,
      initialValues,
      validationSchema,
    ]
  );

  const _onSubmit = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    async (values: any, helpers: FormikHelpers<any>) => {
      if (!handlers.onSubmitting) {
        _setErrors(['Well this is embarrassing, nothing is wired to submit']);
        return undefined;
      }

      helpers.setSubmitting(true);

      const cleanedValues = _cleanValues(values);

      const formattedValues = handlers?.onFormatRequest
        ? handlers.onFormatRequest(formBuilder, formDefinition, cleanedValues)
        : cleanedValues;

      try {
        let response = await handlers.onSubmitting(
          formBuilder,
          formDefinition,
          formattedValues
        );

        if (!response) return;

        if (handlers.onFormatResponse) {
          response = handlers.onFormatResponse(
            formBuilder,
            formDefinition,
            response
          );
        }

        if (handlers.onSubmitted) {
          if (response) {
            await handlers.onSubmitted({
              success: true,
              values: formattedValues,
              output: response,
            });
          } else {
            await handlers.onSubmitted({
              output: response,
              success: false,
              values: formattedValues,
            });
          }
        }
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (e: any) {
        if (handlers?.onErrors) {
          handlers?.onErrors([e?.message ?? e.toString()]);
          return;
        }

        setError(e?.message ?? e.toString());
      } finally {
        helpers.setSubmitting(false);
      }
    },
    [_cleanValues, _setErrors, formBuilder, formDefinition, handlers, setError]
  );

  return {
    ...formDefinition,
  } as const;
};

export default useForm;
