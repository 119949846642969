/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import deepPropertyHelper from './deepPropertyHelpers';
import FormatterProps from './FormatterProps';

const CustomFormatter = (props: FormatterProps) => {
  const { data, column, disableInGroup, value } = props;
  const formattedVal = value?.value ?? deepPropertyHelper(column.name, data);

  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {(!disableInGroup || !data?.__group === true) &&
        column.renderFunction &&
        column.renderFunction(data, { ...props, value: formattedVal })}
    </>
  );
};

export default React.memo(CustomFormatter);
