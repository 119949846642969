/* eslint-disable no-unused-vars */

import EditingState from './EditingState';
import { InspectorState } from './InspectorState';

/* eslint-disable import/prefer-default-export */
// eslint-disable-next-line no-shadow
export const enum InspectorActionTypes {
  SET_HAS_A_SAVE_OCCURRED = 'SET_HAS_A_SAVE_OCCURRED',
  SET_IS_CREATING_NEW_ITEM = 'SET_IS_CREATING_NEW_ITEM',
  SET_NEEDS_CLOSE_CONFIRMATION = 'SET_NEEDS_CLOSE_CONFIRMATION',
  SET_EDITING_STATE = 'SET_EDITING_STATE',
  SET_CURRENT_VALUES = 'SET_CURRENT_VALUES',
}

export interface SetHasASaveOccurredAction {
  type: InspectorActionTypes.SET_HAS_A_SAVE_OCCURRED;
  payload: boolean;
}

export interface SetIsCreatingNewItemAction {
  type: InspectorActionTypes.SET_IS_CREATING_NEW_ITEM;
  payload: boolean;
}

export interface SetNeedsCloseConfirmationAction {
  type: InspectorActionTypes.SET_NEEDS_CLOSE_CONFIRMATION;
  payload: boolean;
}

export interface SetEditingStateAction {
  type: InspectorActionTypes.SET_EDITING_STATE;
  payload: EditingState;
}

export interface SetCurrentValuesAction<T> {
  type: InspectorActionTypes.SET_CURRENT_VALUES;
  payload: T; // Change 'any' to the appropriate type
}

export type InspectorDispatch<T> =
  | SetHasASaveOccurredAction
  | SetIsCreatingNewItemAction
  | SetNeedsCloseConfirmationAction
  | SetEditingStateAction
  | SetCurrentValuesAction<T>;

export interface InspectorActions<GenericInitialVal> {
  setHasASaveOccurred: (hasSaveOccurred: boolean) => void;
  setIsCreatingNewItem: (isCreating: boolean) => void;
  setNeedsCloseConfirmation: (needsCloseConfirmations: boolean) => void;
  setEditingState: (editingState: EditingState) => void;
  setCurrentValues: (currentVals: GenericInitialVal) => void;
}
