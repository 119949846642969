import { gql } from '../../../../__generated__';

const addCompanyMutation =
  gql(`mutation addCompanyMutation ($input: AddCompanyInput) {
  addCompany (input: $input) {
    accCustomerDisc
    active
    aRTaxCategoryId 
    businessAddressSameAsMailing
    custom1
    custom2
    custom3
    custom4
    custom5
    custom6
    custom7
    custom8
    date
    defaultTermsId
    defaultShipViaId
    defaultPartDiscount
    defaultPartMargin
    hasAgent
    id
    name
    phone
    fax
    tollFree
    defaultEmailAddress
    webSite
    parentId
    shippingAddressSameAsMailing
  }
}`);

export default addCompanyMutation;
