import CheckBox from '@inovua/reactdatagrid-community/packages/CheckBox';
import { IColumn } from '@inovua/reactdatagrid-enterprise/types';
import React from 'react';

const GridCheckbox = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  renderCheckbox: (checkboxProps) => {
    const {
      checked,
      isIndeterminate,
      onChange,
      onClick,
      className,
      theme,
      supportIndeterminate,
    } = checkboxProps;
    const string = isIndeterminate ? String(isIndeterminate) : 'false';
    return (
      <CheckBox
        aria-checked={checked}
        checked={checked}
        className={className}
        data-testid="GridCheckbox"
        isindeterminate={string}
        onChange={onChange}
        onClick={onClick}
        role="checkbox"
        supportIndeterminate={supportIndeterminate}
        theme={theme}
      />
    );
  },
} as IColumn;

export default GridCheckbox;
