import { MessageContext } from '@teto/react-component-library-v2';
import { useContext } from 'react';
import { QueryKey, UseQueryOptions, useQuery } from 'react-query';
import { getGraphQLClient } from './graphQLClient';

const useBuildQuery = <T,>(
  queryKey: unknown[],
  query: {
    queryString: string;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    variables: { [key: string]: any };
    // eslint-disable-next-line no-unused-vars
    callback?: (d: T) => void;
    options?: UseQueryOptions<T, unknown, T, QueryKey>;
    errorField?: string;
  }
) => {
  const messageContext = useContext(MessageContext);

  return useQuery<T>(
    queryKey,
    () =>
      getGraphQLClient()
        .performQuery(
          query.queryString,
          query.variables,
          (err) => {
            messageContext.setError(err.messages[0]);
          },
          (err) => {
            if (query.errorField) {
              return messageContext.setError(err[query.errorField]);
            }
            if (Object.hasOwn(err, 'input')) {
              let errors = '';

              const inputKeys = Object.keys(err.input);
              inputKeys.forEach((key, index) => {
                const isLastKey = index === inputKeys.length - 1;

                if (isLastKey) {
                  errors += `${err.input[key as keyof typeof err.input]}`;
                } else {
                  errors += `${err.input[key as keyof typeof err.input]}\n \n`;
                }
              });

              messageContext.setError(errors);
            } else {
              messageContext.setError(err.message);
            }
          }
        )
        .then((d) => {
          if (query.callback) return query.callback(d);
          return d;
        }),
    query?.options
  );
};

export default useBuildQuery;
