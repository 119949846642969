import DescriptionIcon from '@mui/icons-material/Description';
import { ETOButton, MessageContext } from '@teto/react-component-library-v2';
import { Dayjs } from 'dayjs';
import React, {
  useCallback,
  useContext,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { getGraphQLClient } from 'teto-client-api';
// eslint-disable-next-line import/no-unresolved
import { TypedDocumentNode } from '@graphql-typed-document-node/core';
import getErrors from '../../../../api/graphQL/getErrors';
// import { getGraphQLClient } from '../../../../api/graphQL/graphQLClient';
import NoRecordsFound from '../../NonConformanceInspector/components/NoRecordsFound/NoRecordsFound';

import { InspectorActions } from '../../types/InspectorActionTypes';
import { InspectorState } from '../../types/InspectorState';
import AccordionList from '../AccordionList/AccordionList';
import PanelWithNewItemPopover from '../AddEditInspector/AddEditList/PanelWithNewItemPopover';
import CommonAddEditNote, { CustomInput } from './CommonAddEditNote';

export interface GenericNote {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  employee: any;
  id: number;
  note: string;
  noteDate: Dayjs;
  [x: `${string}Id`]: number;
}

export type TNotesInspectorProps = Pick<
  InspectorActions<GenericNote>,
  'setEditingState' | 'setHasASaveOccurred' | 'setIsCreatingNewItem'
> &
  Pick<InspectorState<GenericNote>, 'editingState'>;

export interface NotesPanelProps extends TNotesInspectorProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  addNewMutation: string | TypedDocumentNode<any, any>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  deleteMutation: string | TypedDocumentNode<any, any>;
  entityIdValue: number | undefined;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  entityQuery: string | TypedDocumentNode<any, any>;
  entityName: string;
  permissions: {
    canAddNotes: boolean;
    canDeleteNotes: boolean;
    canModifyNotes: boolean;
  };
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  updateMutation: string | TypedDocumentNode<any, any>;
  customInputs?: CustomInput;
  // eslint-disable-next-line no-unused-vars, @typescript-eslint/no-explicit-any
  transformData?: (data: any) => GenericNote[];
  onUpdated?: () => void;
}

const CommonNotesPanel = (props: NotesPanelProps) => {
  const {
    addNewMutation,
    deleteMutation,
    entityIdValue,
    entityName,
    entityQuery,
    permissions,
    setHasASaveOccurred,
    setIsCreatingNewItem,
    editingState,
    updateMutation,
    setEditingState,
    customInputs,
    transformData,
    onUpdated,
  } = props;

  const { t } = useTranslation();

  const messageContext = useContext(MessageContext);

  const buttonRef = useRef<HTMLButtonElement | null>(null);

  const [isNewNoteOpen, setIsNewNoteOpen] = useState<boolean>(false);
  const [selectedNoteItem, setSelectedNoteItem] =
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    useState<any | undefined>();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [notes, setNotes] = useState<GenericNote[]>([]);
  const [confirmAbandonForm, setConfirmAbandonForm] = useState<boolean>(false);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  React.useEffect(() => setIsCreatingNewItem(isNewNoteOpen), [isNewNoteOpen]);

  const _updateRecords = useCallback(() => {
    if (entityIdValue) {
      if (onUpdated) onUpdated();
      getGraphQLClient()
        .performQuery(entityQuery, {
          [`${entityName.charAt(0).toLowerCase() + entityName.slice(1)}Id`]:
            entityIdValue,
        })
        .then((e) => {
          if (e.hasError()) {
            if (e.hasSystemErrors()) {
              messageContext.setError(getErrors(e.systemErrors));
            }
            return;
          }
          const { data } = e;
          const notesData =
            data[
              `${entityName.charAt(0).toLowerCase() + entityName.slice(1)}Notes`
            ].items;
          setNotes(!transformData ? notesData : transformData(notesData));
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [entityIdValue, entityName, onUpdated]);

  React.useEffect(() => {
    _updateRecords();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [entityIdValue]);
  const listItems = useMemo(
    () =>
      notes.map((note) => ({
        ...note,
        noteDate: note?.noteDate,
        id: note?.id,
        title: note?.employee?.name ?? '-',
        subTitles: [`${t('generic.note')}: ${note?.note}`],
      })),
    [notes, t]
  );

  const _onNoteSaved = useCallback(() => {
    _updateRecords();
    setHasASaveOccurred(true);
    setIsNewNoteOpen(false);
  }, [_updateRecords, setHasASaveOccurred]);

  return (
    <PanelWithNewItemPopover
      addButtonRef={buttonRef}
      addItemButton={
        <ETOButton
          buttonProps={{
            ref: buttonRef,
          }}
          color="primary"
          disabled={!permissions.canAddNotes}
          onClick={() => setIsNewNoteOpen(true)}
          size="medium"
        >
          {t('generic.add')} {t('generic.note')}
        </ETOButton>
      }
      editingState={editingState}
      isNewItemOpen={isNewNoteOpen}
      newItemComponent={
        entityIdValue && (
          <CommonAddEditNote
            addMutation={addNewMutation}
            confirmAbandonForm={confirmAbandonForm}
            customInputs={customInputs}
            deleteMutation={deleteMutation}
            entityIdValue={entityIdValue}
            entityName={entityName}
            onRecordCountChanged={_updateRecords}
            onSaveSuccess={_onNoteSaved}
            permissions={permissions}
            record={undefined}
            setConfirmAbandonForm={setConfirmAbandonForm}
            setIsNewNoteOpen={setIsNewNoteOpen}
            setPanelEditingState={setEditingState}
            setSelectedNoteItem={setSelectedNoteItem}
            updateMutation={updateMutation}
          />
        )
      }
      setConfirmAbandonForm={setConfirmAbandonForm}
      setIsNewItemOpen={() => setIsNewNoteOpen(false)}
    >
      {notes.length === 0 && <NoRecordsFound />}
      {notes.length > 0 && entityIdValue && (
        <AccordionList
          editingState={editingState}
          expandedComponent={
            <CommonAddEditNote
              addMutation={addNewMutation}
              confirmAbandonForm={confirmAbandonForm}
              customInputs={customInputs}
              deleteMutation={deleteMutation}
              entityIdValue={entityIdValue}
              entityName={entityName}
              onRecordCountChanged={_updateRecords}
              onSaveSuccess={_onNoteSaved}
              permissions={permissions}
              record={selectedNoteItem}
              setConfirmAbandonForm={setConfirmAbandonForm}
              setIsNewNoteOpen={setIsNewNoteOpen}
              setPanelEditingState={setEditingState}
              setSelectedNoteItem={setSelectedNoteItem}
              updateMutation={updateMutation}
            />
          }
          expandedItem={selectedNoteItem}
          icon={<DescriptionIcon />}
          listItems={listItems}
          onItemExpandStateChanged={(d) => setSelectedNoteItem(d)}
          setConfirmAbandonForm={setConfirmAbandonForm}
        />
      )}
    </PanelWithNewItemPopover>
  );
};

export default CommonNotesPanel;
