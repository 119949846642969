import { TFunction } from 'react-i18next';
import { ElementType } from '../../../typescriptHelpers';
import SubFormBuilder from './SubFormBuilder';
import IFormBuilderExtension from './types/IFormBuilderExtension';
import SupportedFieldTypes from './types/SupportedFieldTypes';

class EditableCustomFieldFormBuilderExtension implements IFormBuilderExtension {
  fieldPattern: ElementType<typeof SupportedFieldTypes>[] = [];

  defaultLocalizationPrefix: string | null = null;

  defaultLocalizationValuePrefix: string | null = null;

  root: string | null = null;

  disabled?: boolean = false;

  // eslint-disable-next-line no-unused-vars
  builderFunc?: ((root: string | null, index: number) => string) | null = null;

  /**
   * @param root Prefix for translation ex: "Example"
   * @param defaultLocalizationPrefix The entities translation ex: "entities:Example.ExampleCustom"
   * @param defaultLocalizationValuePrefix default translation ex: "Example Custom"
   * @param fieldPattern Change the default field pattern from [
      'string',
      'string',
      'decimal',
      'decimal',
      'date',
      'date',
      'boolean',
      'boolean',
    ]
   */
  constructor(
    root: string,
    defaultLocalizationPrefix: string,
    defaultLocalizationValuePrefix: string,
    disabled: boolean,
    fieldPattern: ElementType<typeof SupportedFieldTypes>[] = [
      'string',
      'string',
      'decimal',
      'decimal',
      'date',
      'date',
      'boolean',
      'boolean',
    ],
    // eslint-disable-next-line no-unused-vars
    builderFunc: ((rt: string | null, index: number) => string) | null = null
  ) {
    this.defaultLocalizationPrefix = defaultLocalizationPrefix;
    this.defaultLocalizationValuePrefix = defaultLocalizationValuePrefix;
    this.root = root;
    this.fieldPattern = fieldPattern;
    this.disabled = disabled;
    this.builderFunc = builderFunc;
  }

  // eslint-disable-next-line @typescript-eslint/ban-types
  execute<FB extends SubFormBuilder<FT>, FT = {}>(
    gridBuilder: FB,
    t: TFunction<'translation', undefined>
  ) {
    for (let i = 1; i <= this.fieldPattern.length; i += 1) {
      const enabled =
        t(`${this.defaultLocalizationPrefix}${i}`) !==
        `${this.defaultLocalizationValuePrefix} ${i}`;

      if (enabled) {
        gridBuilder.addField({
          name: this.builderFunc
            ? this.builderFunc(this.root, i)
            : `custom${i}`,
          title: t(`${this.defaultLocalizationPrefix}${i}`),
          type: this.fieldPattern[i - 1],
          disabled: this.disabled,
          required: this.fieldPattern[i - 1] === 'boolean' ? true : undefined,
          defaultValue:
            this.fieldPattern[i - 1] === 'boolean' ? false : undefined,
        });
      }
    }

    return gridBuilder;
  }
}

export default EditableCustomFieldFormBuilderExtension;
