import InsertChartRoundedIcon from '@mui/icons-material/InsertChartRounded';
import { Box } from '@mui/material';
import {
  ETOButton,
  ETOMenu,
  MenuOption,
  MessageContext,
} from '@teto/react-component-library-v2';
import { useFormik } from 'formik';
import { useAtom, useSetAtom } from 'jotai';
import React, {
  Dispatch,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import {
  Licenses,
  Permission,
  getGraphQLClient as getNewGraphQLClient,
} from 'teto-client-api';
import SaveStatusState from '../../SharedStateComponents/StateContainers/SaveStatusState';
import {
  AddCompanyInput,
  Company,
  EmployeeReport,
  EmployeeReportParameter,
  UpdateCompanyInput,
} from '../../../__generated__/graphql';
import buildMutation from '../../../api/graphQL/buildMutation';
import getErrors from '../../../api/graphQL/getErrors';
import { getGraphQLClient } from '../../../api/graphQL/graphQLClient';
import AuthContext from '../../../contexts/AuthContext';
import ensureKeysExist from '../../../helpers/ensureKeysExists';
import companyReportListQuery from '../../../pages/CompanyPage/queries/companyReportListQuery';
import companyReportObjects from '../../../pages/CompanyPage/queries/companyReportObjects';
import reportParametersQuery from '../../../pages/InventoryPage/queries/reportParametersQuery';
import { BreadCrumbSharedState } from '../../SharedStateComponents/StateContainers/BreadCrumbModalState';
import { companyDetailSharedState } from '../../SharedStateComponents/StateContainers/CompanyState';
import ReportInspector, {
  ReportInspectorProps,
} from '../ReportInspector/ReportInspector';
import AddEditInspector from '../components/AddEditInspector/AddEditInspector';
import AutoSave from '../components/AddEditInspector/AutoSave/AutoSave';
import { TAddEditTabs } from '../components/AddEditInspector/components/EditableTabs';
import CommonDocumentsPanel from '../components/CommonDocumentsPanel/CommonDocumentsPanel';
import CommonNotesPanel from '../components/CommonNotesPanel/CommonNotesPanel';
import useInspectorState from '../hooks/useInspectorState';
import EditingState from '../types/EditingState';
import { InspectorState } from '../types/InspectorState';
import { CompanyDetailInspectorProps } from './CompanyDetailInspectorProps';
import ICompanyDetailState from './ICompanyDetailState';
import CompanyAccountingPanel from './panels/CompanyAccountingPanel/CompanyAccountingPanel';
import AddressPanel from './panels/CompanyAddressPanel/AddressPanel';
import CompanyContactsPanel from './panels/CompanyContactsPanel/CompanyContactsPanel';
import CompanyInfoPanel from './panels/CompanyInfoPanel/CompanyInfoPanel';
import {
  createSchema,
  editSchema,
} from './panels/CompanyInfoPanel/companyInfoSchema';
import CompanyProjectsPanel from './panels/CompanyProjectsPanel/CompanyProjectsPanel';
import CompanySupplierInfoPanel from './panels/CompanySupplierInfoPanel/CompanySupplierInfoPanel';
import addCompanyDocumentMutation from './queries/addCompanyDocumentMutation';
import addCompanyMutation from './queries/addCompanyMutation';
import addCompanyNoteMutation from './queries/addCompanyNoteMutation';
import deleteCompanyDocumentMutation from './queries/deleteCompanyDocumentMutation';
import deleteCompanyNoteMutation from './queries/deleteCompanyNoteMutation';
import getCompanyDocumentsQuery from './queries/getCompanyDocumentsQuery';
import getCompanyNotesQuery from './queries/getCompanyNotesQuery';
import getCompanyQuery from './queries/getCompanyQuery';
import updateCompanyDocumentMutation from './queries/updateCompanyDocumentMutation';
import updateCompanyMutation from './queries/updateCompanyMutation';
import updateCompanyNoteMutation from './queries/updateCompanyNoteMutation';

type ReportProps = Pick<
  ReportInspectorProps<{ [key: string]: unknown }>,
  'report' | 'title'
>;

interface ReportOption {
  label: string;
  disabled: boolean;
  onClick: () => void;
}

const initialEditingState: EditingState = {
  isEditing: false,
  hasEdited: false,
  tabError: false,
};

const getInitialState = (value: Company): ICompanyDetailState =>
  value && value.id
    ? {
        name: value.name,
        id: value.id,
        mode: 'edit',
      }
    : {
        name: undefined,
        id: undefined,
        mode: 'create',
      };

const CompanyDetailInspector = (props: CompanyDetailInspectorProps) => {
  const { initialValues, open, onClose } = props;
  const { ready, t } = useTranslation();
  const [levels, setLevels] = useAtom(BreadCrumbSharedState);
  const setCompanyDetails = useSetAtom(companyDetailSharedState);
  const setSaveState = useSetAtom(SaveStatusState);

  const authContext = useContext(AuthContext);
  const messageContext = useContext(MessageContext);

  const [inspectorState, setInspectorState] = useState<ICompanyDetailState>(
    () => getInitialState(initialValues as Company)
  );
  const reportButtonRef = useRef<HTMLButtonElement | null>(null);

  const [isCompanyASupplier, setIsCompanyASupplier] = useState<boolean>(false);
  const [report, setReport] = useState<ReportProps | undefined>(undefined);
  const [reportMenu, setReportMenu] = useState<HTMLButtonElement | null>();
  const [reportOptionList, setReportOptionList] = useState<
    ReportOption[] | undefined
  >(undefined);
  const [confirmedNameChange, setConfirmedNameChange] =
    useState<boolean>(false);

  useEffect(() => {
    if (inspectorState.id && inspectorState.mode === 'edit') {
      getGraphQLClient()
        .performQuery(
          getCompanyQuery,
          { id: inspectorState.id },
          (err) => messageContext.setError(err.messages[0]),
          (err) => {
            const { input } = err;

            messageContext.setError(Object.values(input)[0] as string);
          }
        )
        .then((d) => {
          setCurrentValues(d.company);
          setInspectorState({
            name: d.company.name,
            id: d.company.id,
            mode: 'edit',
          });
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inspectorState.id, inspectorState.mode, messageContext]);

  const requiredKeys = useMemo(() => ['id', 'aRTaxCategoryId', 'name'], []);

  const initialState: InspectorState<Company> = useMemo(
    () => ({
      hasASaveOccurred: false,
      isCreatingNewItem: false,
      needsCloseConfirmation: false,
      editingState: initialEditingState,
      currentValues: ensureKeysExist<Company>(initialValues, requiredKeys),
    }),
    [initialValues, requiredKeys]
  );

  const {
    state,
    setCurrentValues,
    setEditingState,
    setHasASaveOccurred,
    setIsCreatingNewItem,
    setNeedsCloseConfirmation,
  } = useInspectorState<Company>(initialState);

  const {
    currentValues,
    editingState,
    hasASaveOccurred,
    isCreatingNewItem,
    needsCloseConfirmation,
  } = state;

  useEffect(() => {
    if (state.hasASaveOccurred) {
      setCompanyDetails((prev) => ({
        ...prev,
        hasSavedOccurred: state.hasASaveOccurred,
      }));
    }
  }, [setCompanyDetails, state.hasASaveOccurred]);

  const {
    canAddDocuments,
    canAddNotes,
    canDeleteDocuments,
    canDeleteNotes,
    canModifyDocuments,
    canModifyNotes,
    canViewAccounting,
    canViewCompanyInfo,
    canViewContacts,
    canViewDocuments,
    canViewNotes,
    canViewProjects,
    canViewSupplierInfo,
  } = useMemo(
    () => ({
      canViewCompanyInfo:
        authContext.hasLicense(Licenses.ReadOnlyProfessional) &&
        authContext.hasAnyPermission([
          Permission.View_Sales_CompanyInformation,
          Permission.Modify_Sales_CompanyInformation,
        ]),
      canViewAccounting:
        authContext.hasLicense(Licenses.ReadOnlyProfessional) &&
        authContext.hasAnyPermission([
          Permission.View_Sales_CompanyInformation_AccountingInformation,
          Permission.Modify_Sales_CompanyInformation_AccountingInformation,
        ]),
      canViewContacts:
        authContext.hasLicense(Licenses.ReadOnlyProfessional) &&
        authContext.hasAnyPermission([
          Permission.View_Sales_CompanyInformation_Contacts,
          Permission.Modify_Sales_CompanyInformation_Contacts,
        ]),
      canViewProjects:
        authContext.hasLicense(Licenses.ReadOnlyProfessional) &&
        authContext.hasAnyPermission([
          Permission.View_Sales_CompanyInformation_ProjectsList,
          Permission.Modify_Sales_CompanyInformation_ProjectsList,
        ]),
      canViewSupplierInfo:
        authContext.hasLicense(Licenses.ReadOnlyProfessional) &&
        authContext.hasAnyPermission([
          Permission.View_Sales_CompanyInformation_SupplierInformation,
          Permission.Modify_Sales_CompanyInformation_SupplierInformation,
        ]),
      // Notes
      canViewNotes:
        authContext.hasLicense(Licenses.ReadOnlyProfessional) &&
        authContext.hasAnyPermission([
          Permission.View_Sales_CompanyInformation_CompanyNotes,
          Permission.Modify_Sales_CompanyInformation_CompanyNotes,
        ]),
      canAddNotes:
        authContext.hasLicense(Licenses.TotalETOProfessional) &&
        authContext.hasPermission(
          Permission.Add_Sales_CompanyInformation_CompanyNotes
        ),
      canDeleteNotes:
        authContext.hasLicense(Licenses.TotalETOProfessional) &&
        authContext.hasPermission(
          Permission.Delete_Sales_CompanyInformation_CompanyNotes
        ),
      canModifyNotes:
        authContext.hasLicense(Licenses.TotalETOProfessional) &&
        authContext.hasPermission(
          Permission.Modify_Sales_CompanyInformation_CompanyNotes
        ),
      // Documents
      canAddDocuments:
        authContext.hasLicense(Licenses.TotalETOProfessional) &&
        authContext.hasPermission(
          Permission.Add_Sales_CompanyInformation_Documents
        ),
      canDeleteDocuments:
        authContext.hasLicense(Licenses.TotalETOProfessional) &&
        authContext.hasPermission(
          Permission.Delete_Sales_CompanyInformation_Documents
        ),
      canModifyDocuments:
        authContext.hasLicense(Licenses.TotalETOProfessional) &&
        authContext.hasPermission(
          Permission.Modify_Sales_CompanyInformation_Documents
        ),
      canViewDocuments:
        authContext.hasLicense(Licenses.ReadOnlyProfessional) &&
        authContext.hasAnyPermission([
          Permission.View_Sales_CompanyInformation_Documents,
          Permission.Modify_Sales_CompanyInformation_Documents,
        ]),
    }),
    [authContext]
  );

  const _handleEditingState = useCallback(
    (e: { isValid: boolean; isEditing: boolean }) => {
      setEditingState({
        isEditing: e.isEditing,
        hasEdited:
          !editingState.hasEdited && e.isEditing
            ? true
            : editingState.hasEdited,
        tabError: !e.isValid,
      });
    },
    [editingState.hasEdited, setEditingState]
  );

  const getTabLabel = useCallback(
    (text: string) => {
      const translationText = `pages.companies.tabs.${text}`;
      return `${t(translationText)}`;
    },
    [t]
  );

  const getTabError = useCallback(
    (text: string) => {
      const label = getTabLabel(text);
      return `Error In ${label} Form`;
    },
    [getTabLabel]
  );

  const formatReportForm = useCallback((reportId: number, title: string) => {
    const { localStorageKey, reportForm, schema } =
      companyReportObjects[reportId];

    getNewGraphQLClient()
      .performQuery(reportParametersQuery, { id: reportId })
      .then((d) => {
        if (d.hasError()) {
          d.showAllSystemErrors(messageContext.setError);
          if (d.hasValidationErrors()) {
            const { input } = d.validationErrors;
            messageContext.setError(Object.values(input)[0] as string);
          }
          return;
        }

        if (d.data.employeeReportParameters) {
          const defaultValues: { [key: string]: unknown } = {};
          (
            d.data.employeeReportParameters as EmployeeReportParameter[]
          ).forEach((i: EmployeeReportParameter) => {
            if (i.defaultValue === null) defaultValues[i.parameter.name] = '';
            else defaultValues[i.parameter.name] = i.defaultValue;
          });

          defaultValues['@intCompanyID'] = inspectorState.id;

          setReport({
            report: {
              defaultValues,
              localStorageKey,
              reportForm,
              reportId,
              schema,
            },
            title,
          });
        }
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const _handleReportButtonClick = useCallback(() => {
    if (reportOptionList) setReportMenu(reportButtonRef?.current);
    else {
      getGraphQLClient()
        .performQuery(companyReportListQuery, {})
        .then((d) => {
          const companyReports = (
            (d as { companyReports: EmployeeReport[] })?.companyReports ?? []
          ).map((r) => ({
            label: r.reportDisplayName,
            disabled: !r.allowView,
            onClick: () => formatReportForm(r.reportID, r.reportDisplayName),
          }));

          setReportOptionList(companyReports);
          setReportMenu(reportButtonRef?.current);
        })
        .catch((e) => messageContext.setError(getErrors(e)));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formatReportForm, reportOptionList]);

  const formik = useFormik<Company>({
    enableReinitialize: true,
    initialValues: currentValues,
    validateOnMount: inspectorState.mode === 'create' && !currentValues?.id,
    validationSchema:
      inspectorState.mode === 'create' ? createSchema : editSchema(t),
    onSubmit: (values, helpers) => {
      if (inspectorState.mode === 'create') {
        buildMutation({
          queryString: addCompanyMutation,
          variables: {
            input: {
              ...(inspectorState.mode === 'create' && {
                name: values.name,
              }),
            } as AddCompanyInput,
          },
          callback: (resp) => {
            if (typeof resp === 'object') {
              if ('addCompany' in resp) {
                setCurrentValues(resp.addCompany);
                const { name, id } = resp.addCompany;

                setLevels((d) =>
                  d.map((i) =>
                    i.name === 'New Company'
                      ? {
                          ...i,
                          name: `${t('entities:Company.Company')}: ${name}`,
                          data: { name, id },
                        }
                      : i
                  )
                );

                _handleEditingState({
                  isEditing: false,
                  isValid: true,
                });
                setInspectorState({
                  id: resp.addCompany.id,
                  name: resp.addCompany.name,
                  mode: 'edit',
                });
                setHasASaveOccurred(true);
                setSaveState((prev) => ({
                  ...prev,
                  hasSavedOccurred: true,
                }));

                messageContext.setSuccess(
                  t('generic.createdSuccess', {
                    record: t('entities:Company.Company'),
                  })
                );
              }

              if (confirmedNameChange) setConfirmedNameChange(false);

              helpers.setSubmitting(false);
            }
          },
          messageContext,
          formik,
        }).finally(() => helpers.setSubmitting(false));
      } else {
        buildMutation({
          queryString: updateCompanyMutation,
          variables: {
            input: {
              ...formik.values,
              id: currentValues?.id as number,
            } as unknown as UpdateCompanyInput,
          },
          callback: (resp) => {
            if (typeof resp === 'object') {
              if (confirmedNameChange) setConfirmedNameChange(false);
              if ('updateCompany' in resp) {
                setHasASaveOccurred(true);
                setCurrentValues(resp.updateCompany);
                setConfirmedNameChange(false);
                _handleEditingState({
                  isEditing: false,
                  isValid: true,
                });
                setInspectorState({
                  id: resp.updateCompany.id,
                  mode: 'edit',
                });
                if (inspectorState.mode === 'edit') {
                  messageContext.setSuccess(
                    t('generic.updateSuccess', {
                      record: t('entities:Company.Company'),
                    })
                  );
                }
                setSaveState((prev) => ({
                  ...prev,
                  hasSavedOccurred: true,
                }));
              }
              helpers.setSubmitting(false);
            }
          },
          messageContext,
          formik,
        }).finally(() => helpers.setSubmitting(false));
      }
    },
  });

  const tabs: TAddEditTabs = useMemo(() => {
    const tabsArray = [];
    tabsArray.push(
      {
        disabled: !canViewCompanyInfo,
        tabName: 'info',
        tabLabel: `${t('generic.info')}`,
        tabIndex: 0,
        tabPanel: (
          <CompanyInfoPanel
            confirmedNameChange={confirmedNameChange}
            currentValues={currentValues}
            formik={formik}
            panelMode={inspectorState.mode}
            setConfirmedNameChange={setConfirmedNameChange}
            setCurrentValues={setCurrentValues}
            setHasASaveOccurred={setHasASaveOccurred}
            setIsCompanyASupplier={setIsCompanyASupplier}
            setPanelEditingState={_handleEditingState}
          />
        ),
        tabTitle: `Error In ${t('generic.info')} Form`,
      },
      ...(inspectorState.mode === 'edit'
        ? [
            {
              tabName: 'address',
              tabLabel: getTabLabel('addresses'),
              tabIndex: 1,
              tabPanel: (
                <AddressPanel
                  companyId={currentValues?.id as number}
                  formik={formik}
                  setHasASaveOccurred={setHasASaveOccurred}
                />
              ),
              tabTitle: getTabError('addresses'),
              disabled: !authContext.hasLicense(Licenses.ReadOnlyProfessional),
            },
          ]
        : [])
    );

    if (inspectorState.mode === 'edit' && currentValues?.id) {
      tabsArray.push({
        disabled: !canViewContacts,
        tabName: 'contacts',
        tabLabel: getTabLabel('contacts'),
        tabIndex: 2,
        tabPanel: <CompanyContactsPanel companyId={currentValues?.id} />,
        tabTitle: getTabError('contacts'),
      });

      tabsArray.push({
        disabled: !canViewSupplierInfo || !isCompanyASupplier,
        tabName: 'supplierInfo',
        tabLabel: getTabLabel('supplierInfo'),
        tabIndex: 3,
        tabPanel: (
          <CompanySupplierInfoPanel
            companyId={currentValues.id}
            initialValues={initialState?.currentValues}
            setHasASaveOccurred={setHasASaveOccurred}
            setPanelEditingState={_handleEditingState}
          />
        ),
        tabTitle: getTabError('supplierInfo'),
      });

      tabsArray.push({
        disabled: !canViewAccounting,
        tabName: 'accounting',
        tabLabel: getTabLabel('accounting'),
        tabIndex: 4,
        tabPanel: (
          <CompanyAccountingPanel
            currentValues={currentValues}
            formik={formik}
            setHasASaveOccurred={setHasASaveOccurred}
          />
        ),
        tabTitle: getTabError('accounting'),
      });

      tabsArray.push({
        disabled: !canViewProjects,
        tabName: 'projects',
        tabLabel: getTabLabel('projects'),
        tabIndex: 5,
        tabPanel: <CompanyProjectsPanel companyId={inspectorState.id} />,
        tabTitle: getTabError('projects'),
      });

      tabsArray.push({
        disabled: !canViewNotes,
        tabName: 'notes',
        tabLabel: getTabLabel('notes'),
        tabIndex: 6,
        tabPanel: (
          <CommonNotesPanel
            addNewMutation={addCompanyNoteMutation}
            deleteMutation={deleteCompanyNoteMutation}
            editingState={editingState}
            entityIdValue={currentValues?.id}
            entityName="Company"
            entityQuery={getCompanyNotesQuery}
            permissions={{ canAddNotes, canDeleteNotes, canModifyNotes }}
            setEditingState={setEditingState}
            setHasASaveOccurred={setHasASaveOccurred}
            setIsCreatingNewItem={setIsCreatingNewItem}
            updateMutation={updateCompanyNoteMutation}
          />
        ),
        tabTitle: getTabError('notes'),
      });

      tabsArray.push({
        disabled: !canViewDocuments,
        tabName: 'documents',
        tabLabel: getTabLabel('documents'),
        tabIndex: 7,
        tabPanel: (
          <CommonDocumentsPanel
            addNewMutation={addCompanyDocumentMutation}
            deleteMutation={deleteCompanyDocumentMutation}
            editingState={editingState}
            entityIdValue={currentValues?.id}
            entityName="Company"
            entityQuery={getCompanyDocumentsQuery}
            permissions={{
              canModifyDocuments,
              canAddDocuments,
              canDeleteDocuments,
            }}
            setEditingState={setEditingState}
            setHasASaveOccurred={
              setHasASaveOccurred as Dispatch<SetStateAction<boolean>>
            }
            setIsCreatingNewItem={
              setIsCreatingNewItem as Dispatch<SetStateAction<boolean>>
            }
            shouldSupportBatch
            shouldSupportMultipleLocations
            updateMutation={updateCompanyDocumentMutation}
          />
        ),
        tabTitle: getTabError('documents'),
      });
    }

    return tabsArray.map((tab, i) => ({ ...tab, tabIndex: i }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    _handleEditingState,
    canAddDocuments,
    canAddNotes,
    canDeleteDocuments,
    canDeleteNotes,
    canModifyDocuments,
    canModifyNotes,
    canViewAccounting,
    canViewCompanyInfo,
    canViewContacts,
    canViewDocuments,
    canViewNotes,
    canViewProjects,
    canViewSupplierInfo,
    currentValues?.id,
    editingState,
    getTabError,
    getTabLabel,
    initialState.currentValues,
    initialValues,
    inspectorState.id,
    inspectorState.mode,
    setCurrentValues,
    setEditingState,
    setHasASaveOccurred,
    setIsCreatingNewItem,
  ]);

  const formatParameters = useCallback((data: { [key: string]: unknown }) => {
    const results: { [key: string]: unknown } = {};
    Object.keys(data).forEach((i) => {
      if (/^@bit*/.test(i)) results[i] = data[i] !== '0';
      else if (['@nvcCompanyLettersIn', '@nvcCompanyTypesIn'].indexOf(i) >= 0) {
        results[i] = `|${(data[i] as string).replace(/\,/g, '|')}|`;
      } else results[i] = data[i];
    });
    return results;
  }, []);

  return (
    <>
      {ready && (
        <>
          <AddEditInspector
            editingState={editingState}
            hasASaveOccurred={hasASaveOccurred}
            isCreatingNewItem={isCreatingNewItem}
            needsCloseConfirmation={needsCloseConfirmation}
            onClose={onClose}
            open={open}
            setEditingState={setEditingState}
            setNeedsCloseConfirmation={setNeedsCloseConfirmation}
            tabs={tabs}
            title={levels[0]?.name ?? ''}
          />
          {inspectorState.mode === 'edit' && (
            <AutoSave debounceMs={3000} formik={formik} />
          )}
          {inspectorState.mode === 'edit' && (
            <>
              <Box>
                <ETOButton
                  buttonProps={{ ref: reportButtonRef }}
                  color="primary"
                  icon={<InsertChartRoundedIcon />}
                  key={t('generic.reports')}
                  onClick={_handleReportButtonClick}
                  size="small"
                >
                  {t('generic.reports')}
                </ETOButton>
              </Box>
              {report && (
                <ReportInspector
                  {...report}
                  formatParameters={(d) => formatParameters(d)}
                  onClose={() => setReport(undefined)}
                  open={Boolean(report)}
                />
              )}
              {reportMenu && reportOptionList && (
                <ETOMenu
                  anchorEl={reportMenu}
                  menuOptions={reportOptionList as MenuOption[]}
                  open={Boolean(reportMenu)}
                  setClose={() => setReportMenu(null)}
                  sx={{
                    zIndex: 2,
                  }}
                />
              )}
            </>
          )}
        </>
      )}
    </>
  );
};

export default CompanyDetailInspector;
