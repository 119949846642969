import { gql } from '../../../../__generated__';

const getRFQDetailsQuery =
  gql(`query getRFQDetailsQuery($projectId: Int, $specId: Float, $itemId: Int) {
      rFQDetails(
        where: {
          projectId: { eq: $projectId },
          specId: {eq: $specId},
          itemId: { eq: $itemId} },
          order: {id: DESC}) {
        items {
          id
          rFQSupplierDescription
            rFQ {
              id
              date
            }
        }
      }
}`);

export default getRFQDetailsQuery;
