import { SvgIcon } from '@mui/material';
import React from 'react';

const ProcurementIcon = () => (
  <SvgIcon>
    <svg
      height={24}
      viewBox="0 0 24 24"
      width={20}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
      >
        <path d="M4 19a2 2 0 1 0 4 0a2 2 0 0 0-4 0" />
        <path d="M12 17H6V3H4" />
        <path d="m6 5l14 1l-.79 5.526M16 13H6m11.001 6a2 2 0 1 0 4 0a2 2 0 1 0-4 0m2-3.5V17m0 4v1.5m3.031-5.25l-1.299.75m-3.463 2l-1.3.75m0-3.5l1.3.75m3.463 2l1.3.75" />
      </g>
    </svg>
  </SvgIcon>
);

export default ProcurementIcon;
