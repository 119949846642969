import { gql } from '../../../../__generated__';

export default gql(`
  mutation addRFQSupplier($input:  AddRFQCompanyInput){
    addRFQCompany(input: $input) {
      company{
        city
        supplier{
          supQaapproved
        }
        name
        id
      }
      companyId
      rFQId
    }
  }
`);
