import { gql } from '../../../__generated__';
import SelectDefinition from './SelectDefinition';

export default {
  query: gql(`query getHourTypes {
    hourTypes(order: { displayName: ASC }) {
      items {
        id
        displayName
      }
    }
  }`),
  valueField: 'id',
  labelField: 'displayName',
} as SelectDefinition;
