import { Box } from '@mui/material';
import { GenericDialog } from '@teto/react-component-library-v2';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import useIsMobile from '../../hooks/useIsMobile';
import useGridStyles from '../TetoGrid/GridStyles';
import FilePickerGrid from './components/FilePickerGrid';
import { FileType } from './types';

interface FilePickerProps {
  isOpen: boolean;
  onClose: () => void;
  // eslint-disable-next-line no-unused-vars
  onPick: (file: FileType) => void;
}

const heightSx = {
  display: 'flex',
  height: '100%',
};

const FilePicker = (props: FilePickerProps) => {
  const { isOpen, onClose, onPick } = props;
  const { t } = useTranslation();
  const isMobile = useIsMobile();

  const gridContainerSx = {
    width: '100%',
    display: 'grid',
    gridTemplateColumns: isMobile ? undefined : '1fr 2fr',
    gridTemplateRows: isMobile ? '1fr 1.25fr' : undefined,
    gridAutoFlow: isMobile ? 'row' : 'column',
    flexGrow: 1,
  };

  const _onPick = useCallback(
    (file: FileType) => {
      onPick(file);
      onClose();
    },
    [onClose, onPick]
  );

  return (
    <GenericDialog
      contentSx={isMobile ? { padding: 0 } : undefined}
      fullScreen
      isOpen={isOpen}
      onClose={onClose}
      title={t('generic.filePicker')}
    >
      <Box sx={heightSx}>
        <Box sx={[gridContainerSx, useGridStyles]}>
          <FilePickerGrid onPick={_onPick} />
        </Box>
      </Box>
    </GenericDialog>
  );
};

export default FilePicker;
