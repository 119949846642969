export default `mutation updateSupplier($input: UpdateSupplierInput!) {
  updateSupplier(input: $input) {
    aptaxCategoryId
    defaultCurrNameId
    fobnotes
    id
    poreportAlternateId
    supFob
    port
    supNetTerms
    supQaapproved
    supSpecial
    supVia
  }
}`;
