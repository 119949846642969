import { formatHoursAsHourMin } from '@teto/react-component-library-v2';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import SettingsContext from '../../../contexts/SettingsContext';
import EmptyDisplay from './EmptyDisplay';
import FormatterErrorWrapper from './FormatterErrorWrapper';
import FormatterProps from './FormatterProps';
import deepPropertyHelper from './deepPropertyHelpers';

type NumberFormats = 'number' | 'currency' | 'hours';

const NumberFormatter = (
  props: FormatterProps & { numberFormat: NumberFormats }
) => {
  const {
    value,
    column: { name, emptyDisplay },
    data,
    numberFormat,
  } = props;

  let element;
  const { t } = useTranslation();
  const {
    settings: { baseCurrencyFormatter },
  } = useContext(SettingsContext);
  const formattedVal = value?.value ?? deepPropertyHelper(name, data);

  // eslint-disable-next-line react/jsx-no-useless-fragment
  let prefix = <></>;
  if (props.cellProps.summaryProps && props.column?.groupingAggregate) {
    if (props.column?.groupingAggregate === 'sum')
      prefix = <b>{t('generic.total')}:&nbsp;</b>;
    else if (props.column?.groupingAggregate === 'avg')
      prefix = <b>{t('generic.average')}:&nbsp;</b>;
    else if (props.column?.groupingAggregate === 'min')
      prefix = <b>{t('generic.min')}:&nbsp;</b>;
    else if (props.column?.groupingAggregate === 'max')
      prefix = <b>{t('generic.max')}:&nbsp;</b>;
  }

  if (formattedVal == null || formattedVal === undefined) {
    element = <EmptyDisplay placeHolder={emptyDisplay} />;
  } else if (numberFormat === 'currency') {
    element = (
      <span data-testid="numbers">
        {prefix} {baseCurrencyFormatter.formatValue(formattedVal)}
      </span>
    );
  } else if (numberFormat === 'hours') {
    element = (
      <span>
        {prefix}
        {formatHoursAsHourMin(formattedVal)}
      </span>
    );
  } else {
    element = (
      <span>
        {prefix}
        {formattedVal}
      </span>
    );
  }

  return <FormatterErrorWrapper value={value}>{element}</FormatterErrorWrapper>;
};

export default React.memo(NumberFormatter);
