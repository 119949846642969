import * as React from 'react';
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import WarningAmberRoundedIcon from '@mui/icons-material/WarningAmberRounded';
import { IconButton, Theme } from '@mui/material';
import { useTranslation } from 'react-i18next';

const warningSx = (theme: Theme) => ({
  padding: 2,
  color: theme.palette.warning.main,
});

const GridRecordLimitPopup = () => {
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <>
      <IconButton
        aria-label="record capacity"
        color="warning"
        onClick={handleClick}
        size="small"
      >
        <WarningAmberRoundedIcon fontSize="inherit" />
      </IconButton>
      <Popover
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        id={id}
        onClose={handleClose}
        open={open}
        transformOrigin={{ horizontal: 'center', vertical: 60 }}
      >
        <Typography sx={warningSx} variant="body2">
          {t('generic.message.recordMaxCapacity')}
        </Typography>
      </Popover>
    </>
  );
};

export default GridRecordLimitPopup;
