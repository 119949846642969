import AddRoundedIcon from '@mui/icons-material/AddRounded';
import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded';
import { Box, SxProps, Theme } from '@mui/material';
import {
  ETOIconButton,
  ETOTextField,
  GenericDialog,
  MessageContext,
} from '@teto/react-component-library-v2';
import { FormikProps, useFormik } from 'formik';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Permission } from 'teto-client-api';
import * as Yup from 'yup';
import buildMutation from '../../../../../../../../api/graphQL/buildMutation';
import useGQLQuery from '../../../../../../../../api/graphQL/useGQLQuery';
import { REQUIRED_MESSAGE } from '../../../../../../../../constants/strings/strings';
import AuthContext from '../../../../../../../../contexts/AuthContext';
import useIsMobile from '../../../../../../../../hooks/useIsMobile';
import AutoSave from '../../../../../../../Inspectors/components/AddEditInspector/AutoSave/AutoSave';
import {
  AddNoteItemProps,
  AddSpecialNote,
  ModifyNoteItemProps,
  SpecialNote,
  SpecialNotesLookupProps,
  SpecialNotesLookupQueryResponse,
} from './SpecialNoteLookupTypes';
import {
  addSpecialNote,
  deleteSpecialNote,
  specialNotesLookupQuery,
  updateSpecialNote,
} from './SpecialNotesLookupQueries';

const noteItemSx: SxProps<Theme> = {
  padding: (theme) => theme.spacing(1),
  display: 'flex',
  gap: 2,
};

const modalHeightSx: SxProps<Theme> = {
  height: (theme) => theme.spacing(62.5),
};
const dialogSx = { '& .MuiPaper-root': { paddingBottom: 3 } };

const validationSchema = Yup.object().shape({
  purchaseNote: Yup.string().required(REQUIRED_MESSAGE),
  purchaseNoteDesc: Yup.string().required(REQUIRED_MESSAGE),
});

const AddNoteItem = (props: AddNoteItemProps) => {
  const { onAdd, permissions, t } = props;

  const formik = useFormik<AddSpecialNote>({
    initialValues: {
      purchaseNote: '',
      purchaseNoteDesc: '',
    },
    validationSchema,
    validateOnBlur: true,
    onSubmit: (values) => {
      onAdd(values, formik);
      formik.resetForm();
    },
  });

  return (
    <>
      <Box sx={noteItemSx}>
        <ETOTextField
          dataTestId="short-note-input-add"
          disabled={!permissions.canAdd}
          error={formik.errors.purchaseNoteDesc}
          handleChange={formik.handleChange}
          label={t(
            'pages.purchaseOrders.purchaseOrderModal.specialNotesShortNote'
          )}
          name="purchaseNoteDesc"
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              formik.submitForm();
            }
          }}
          size="small"
          value={formik.values.purchaseNoteDesc ?? ''}
        />

        <ETOTextField
          dataTestId="long-note-input-add"
          disabled={!permissions.canAdd}
          error={formik.errors.purchaseNote}
          handleChange={formik.handleChange}
          label={t(
            'pages.purchaseOrders.purchaseOrderModal.specialNotesLongNote'
          )}
          name="purchaseNote"
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              formik.submitForm();
            }
          }}
          size="small"
          value={formik.values.purchaseNote ?? ''}
        />

        <ETOIconButton
          color="primary"
          dataTestId="add-note-button"
          disabled={!permissions.canAdd}
          loading={formik.isSubmitting}
          onClick={formik.submitForm}
          size="large"
          tooltipProps={{
            title: `${t('generic.add')} ${t('generic.note')}`,
            open: formik.isValid && formik.dirty,
          }}
        >
          <AddRoundedIcon color="primary" />
        </ETOIconButton>
      </Box>
    </>
  );
};
const ModifyNoteItem = (props: ModifyNoteItemProps) => {
  const { note, onDelete, onUpdate, permissions, t } = props;

  const formik = useFormik<SpecialNote>({
    initialValues: {
      purchaseNote: note.purchaseNote ?? '',
      purchaseNoteDesc: note.purchaseNoteDesc ?? '',
      id: note.id,
    },
    validationSchema,
    validateOnBlur: true,
    onSubmit: (values) => {
      onUpdate(values, formik);
    },
  });

  return (
    <Box sx={noteItemSx}>
      <ETOTextField
        dataTestId="short-note-input-modify"
        disabled={!permissions.canEdit}
        error={formik.errors.purchaseNoteDesc}
        handleChange={(e) => {
          formik.setFieldValue('purchaseNoteDesc', e.target.value);
        }}
        label={t(
          'pages.purchaseOrders.purchaseOrderModal.specialNotesShortNote'
        )}
        name="purchaseNoteDesc"
        size="small"
        value={formik.values.purchaseNoteDesc ?? ''}
      />
      <ETOTextField
        dataTestId="long-note-input-modify"
        disabled={!permissions.canEdit}
        error={formik.errors.purchaseNote}
        handleChange={(e) => {
          formik.setFieldValue('purchaseNote', e.target.value);
        }}
        label={t(
          'pages.purchaseOrders.purchaseOrderModal.specialNotesLongNote'
        )}
        name="purchaseNote"
        size="small"
        value={formik.values.purchaseNote ?? ''}
      />
      <ETOIconButton
        color="secondary"
        dataTestId="delete-note-button"
        disabled={!permissions.canDelete}
        loading={formik.isSubmitting}
        onClick={() => onDelete(note.id, formik)}
        size="large"
        tooltipProps={{
          title: t('generic.delete') as string,
        }}
      >
        <DeleteRoundedIcon color="error" />
      </ETOIconButton>
      <AutoSave formik={formik} />
    </Box>
  );
};

const SpecialNotesLookup = (props: SpecialNotesLookupProps) => {
  const { isOpen, onClose, setIsOpen } = props;
  const { t } = useTranslation();

  const authContext = useContext(AuthContext);
  const messageContext = useContext(MessageContext);

  const [notes, setNotes] = useState<SpecialNote[]>([]);
  const [refreshToken, setRefreshToken] = useState<boolean>(false);
  const [hasModifiedData, setHasModifiedData] = useState(false);

  const isMobile = useIsMobile();
  const modalSx = useMemo(
    () => (isMobile ? undefined : modalHeightSx),
    [isMobile]
  );
  const onAdd = (note: AddSpecialNote, formik: FormikProps<AddSpecialNote>) => {
    buildMutation({
      queryString: addSpecialNote,
      variables: { input: note },
      messageContext,
      callback: () => {
        setRefreshToken(true);
        setHasModifiedData(true);
      },
      formik,
    });
  };

  const onDelete = (id: number, formik: FormikProps<SpecialNote>) => {
    buildMutation({
      queryString: deleteSpecialNote,
      variables: { id },
      messageContext,
      callback: () => {
        setRefreshToken(true);
        setHasModifiedData(true);
      },
      formik,
    });
  };

  const onUpdate = (
    updatedNote: SpecialNote,
    formik: FormikProps<SpecialNote>
  ) => {
    buildMutation({
      queryString: updateSpecialNote,
      variables: { input: updatedNote },
      messageContext,
      callback: () => {
        setRefreshToken(true);
        setHasModifiedData(true);
      },
      formik,
    });
  };
  const permissions = useMemo(
    () => ({
      canAdd: authContext.hasPermission(
        Permission.Add_Admin_Lookups_SpecialNotes
      ),
      canDelete: authContext.hasPermission(
        Permission.Delete_Admin_Lookups_SpecialNotes
      ),
      canEdit: authContext.hasPermission(
        Permission.Modify_Admin_Lookups_SpecialNotes
      ),
      canView: authContext.hasPermission(
        Permission.View_Admin_Lookups_SpecialNotes
      ),
    }),
    [authContext]
  );

  const specialNotes = useGQLQuery<SpecialNotesLookupQueryResponse>(
    ['specialNotesLookup', refreshToken],
    {
      queryString: specialNotesLookupQuery,
      options: {
        refetchOnWindowFocus: false,
        enabled: Boolean(
          authContext.hasAnyPermission([
            Permission.View_Admin_Lookups_SpecialNotes,
            Permission.Modify_Admin_Lookups_SpecialNotes,
            Permission.Delete_Admin_Lookups_SpecialNotes,
          ])
        ),
      },
    }
  );

  useEffect(() => {
    if (specialNotes.data?.purchasingNotes?.items) {
      setNotes(specialNotes.data?.purchasingNotes?.items as SpecialNote[]);
      setRefreshToken(false);
    }
  }, [specialNotes.data?.purchasingNotes?.items]);

  return (
    <GenericDialog
      contentSx={modalSx}
      isOpen={isOpen}
      onClose={() => {
        onClose(hasModifiedData);
        setIsOpen(false);
      }}
      sx={dialogSx}
      title={t('pages.purchaseOrders.purchaseOrderModal.specialNotesLookup')}
    >
      <AddNoteItem onAdd={onAdd} permissions={permissions} t={t} />
      {notes.map((note) => (
        <ModifyNoteItem
          key={note.id}
          note={note}
          onDelete={onDelete}
          onUpdate={onUpdate}
          permissions={permissions}
          t={t}
        />
      ))}
    </GenericDialog>
  );
};

export default SpecialNotesLookup;
