import Box from '@mui/material/Box';
import { useAtom, useSetAtom } from 'jotai';
import React, { useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { getGraphQLClient, Permission } from 'teto-client-api';
import { MessageContext } from '@teto/react-component-library-v2';
import {
  InventoryLocationsCollectionSegment,
  ProcessScheduleHeader,
} from '../../../../../../../__generated__/graphql';
import useGQLQuery from '../../../../../../../api/graphQL/useGQLQuery';
import AuthContext from '../../../../../../../contexts/AuthContext';
import SettingsContext from '../../../../../../../contexts/SettingsContext';
import EditProcessScheduleForm from '../../../../../../../forms/EditProcessScheduleForm.yaml';
import RawMaterialSelectField from '../../../../../../InputFields/RawMaterialSelectField/RawMaterialSelectField';
import { ProcessScheduleSharedState } from '../../../../../../SharedStateComponents/StateContainers/ProcessScheduleState';
import SaveStatusState from '../../../../../../SharedStateComponents/StateContainers/SaveStatusState';
import EditableCustomFieldFormBuilderExtension from '../../../../../../TETOForms/FormBuilder/EditableCustomFieldFormBuilderExtension';
import TETOForm from '../../../../../../TETOForms/TETOForm';
import useForm from '../../../../../../TETOForms/hooks/useForm';
import { getInventoryLocations } from '../../../../../../TETOForms/selects/selects';
import { useFormBuilder } from '../../../../../../TETOForms/hooks/useFormBuilder';
import { gql } from '../../../../../../../__generated__';
import FormBuilder from '../../../../../../TETOForms/FormBuilder/FormBuilder';
import FormDefinition from '../../../../../../TETOForms/FormDefinition';

const updateProcessScheduleHeaderMutation = gql(
  `mutation updateProcessScheduleHeader($input: UpdateProcessScheduleHeaderInput!) {
    updateProcessScheduleHeader(input: $input) {
      id
      number
      custom1
      custom2
      custom3
      custom4
      custom5
      custom6
      custom7
      custom8
      completionDate
      quantity
      startDate
      finalRequiredDate
      materialItemId
      materialAmount
      materialTotalEstimate
      active
      procureMaterial
      materialFromFirstSupplier
    }
  }`
);
interface ProcessScheduleEditHeaderFormProps {
  processSchedule: ProcessScheduleHeader;
  disabled: boolean;
  // eslint-disable-next-line no-unused-vars
  setResetGrid: (value: boolean) => void;
  // eslint-disable-next-line no-unused-vars
  setProcessSchedule: (value: ProcessScheduleHeader) => void;
}

const ProcessScheduleEditHeaderForm = (
  props: ProcessScheduleEditHeaderFormProps
) => {
  // eslint-disable-next-line no-unused-vars
  const { disabled, processSchedule, setProcessSchedule, setResetGrid } = props;
  const { t } = useTranslation();
  const authContext = useContext(AuthContext);
  const messageContext = useContext(MessageContext);

  const {
    settings: {
      baseCurrencyFormat,
      baseCurrencySymbol,
      procureIfRawMaterialMatchesPart,
    },
  } = useContext(SettingsContext);

  const [processScheduleState, setProcessScheduleState] = useAtom(
    ProcessScheduleSharedState
  );
  const setSaveState = useSetAtom(SaveStatusState);

  const { canEdit } = useMemo(
    () => ({
      canEdit: authContext.hasPermission(
        Permission.Modify_Procurement_ProcessSchedules_ProcessScheduleDetails
      ),
    }),
    [authContext]
  );

  const {
    canEditNumber,
    canEditQuantity,
    canEditProcureMaterial,
    canEditMFFSupplier,
  } = useMemo(
    () => ({
      canEditQuantity:
        canEdit &&
        processScheduleState.openFrom === 'other' &&
        !processSchedule?.completionDate,
      canEditNumber:
        authContext.hasAllPermissions([
          Permission.Modify_Procurement_ProcessSchedules_ProcessSchedules_Number,
          Permission.Modify_Procurement_ProcessSchedules_ProcessScheduleDetails,
        ]) &&
        (!processSchedule.completionDate || !processSchedule.startDate),
      canEditProcureMaterial: canEdit && !processSchedule.startDate,
      canEditMFFSupplier:
        canEdit &&
        !processSchedule.startDate &&
        Boolean(processSchedule.materialItemId) &&
        processSchedule.procureMaterial &&
        procureIfRawMaterialMatchesPart,
    }),
    [
      authContext,
      canEdit,
      processSchedule.completionDate,
      processSchedule.materialItemId,
      processSchedule.procureMaterial,
      processSchedule.startDate,
      processScheduleState.openFrom,
      procureIfRawMaterialMatchesPart,
    ]
  );

  const inventoryLocation = useGQLQuery<{
    inventoryLocations: InventoryLocationsCollectionSegment;
  }>(['inventoryLocation'], {
    queryString: getInventoryLocations.query,
    options: { refetchOnMount: false, refetchOnWindowFocus: false },
  });

  const fb = useFormBuilder('update-psh-form', (fbi) =>
    fbi
      .addString({
        disabled: !canEditNumber,
        required: true,
        name: 'number',
        title: 'entities:ProcessScheduleHeader.Number',
      })
      .addString({
        defaultValue: '',
        disabled: true,
        name: 'item.CompanyId',
        title: t('entities:Part.ItemCompanyId'),
      })
      .addString({
        disabled: true,
        name: 'project.displayName',
        title: t('entities:Project.Project'),
      })
      .addString({
        disabled: true,
        name: 'spec.displayName',
        title: 'entities:Machine.Job',
      })
      .addInteger({
        disabled: !canEditQuantity,
        required: true,
        name: 'quantity',
        title: 'entities:ProcessScheduleHeader.Quantity',
      })
      .addString({
        disabled: true,
        name: 'ownerEmployee.name',
        title: 'entities:ProcessScheduleHeader.OwnerEmployee',
      })
      .addDate({
        disabled: !canEdit || Boolean(processSchedule?.startDate),
        title: 'entities:ProcessScheduleHeader.StartDate',
        name: 'startDate',
      })
      .addDate({
        disabled: true,
        name: 'completionDate',
        title: 'entities:ProcessScheduleHeader.CompletionDate',
      })
      .addDate({
        disabled: !canEdit || Boolean(processSchedule?.completionDate),
        name: 'finalRequiredDate',
        title: 'entities:ProcessScheduleHeader.FinalRequiredDate',
      })
      .addInteger({
        defaultValue: -1,
        disabled: true,
        name: 'dest.InventoryLoc',
        selectSource: {
          fromArray: [
            {
              id: -1,
              name: '(None)',
            },
            ...(inventoryLocation.data?.inventoryLocations?.items ?? []),
          ],
          label: getInventoryLocations.labelField,
          value: getInventoryLocations.valueField,
        },
        title: 'entities:ProcessScheduleHeader.DestInventoryLoc',
      })
      .addField({
        name: 'materialItemId',
        type: 'integer',
        title: 'entities:ProcessScheduleHeader.MaterialItem',
        render: (form, field, options) => (
          <RawMaterialSelectField
            disabled={
              !canEdit ||
              Boolean(processSchedule?.startDate) ||
              form.values.materialItemId === processSchedule?.itemId
            }
            error={options.error}
            handleChange={(val) => {
              form.updateField(field.name, val.target.value.id);
              if (!form.values.procureMaterial && val.target.value.id)
                form.enableField('procureMaterial');
            }}
            handleInputClear={() => {
              form.updateField(field.name, null);
            }}
            label={t('entities:ProcessScheduleHeader.MaterialItem')}
            value={form.values.materialItemId}
          />
        ),
      })
      .addDecimal({
        name: 'materialAmount',
        disabled: !canEdit || Boolean(processSchedule.startDate),
        title: 'entities:ProcessScheduleHeader.MaterialAmount',
      })
      .addCurrency({
        currency: {
          format: baseCurrencyFormat as string,
          symbol: baseCurrencySymbol as string,
        },
        name: 'materialTotalEstimate',
        defaultValue: 0,
        disabled: !canEdit || Boolean(processSchedule.completionDate),
        title: 'entities:ProcessScheduleHeader.MaterialTotalEstimate',
      })
      .addBoolean({
        disabled: !canEdit || Boolean(processSchedule.startDate),
        required: true,
        name: 'active',
        title: 'entities:ProcessScheduleHeader.Active',
      })
      .addBoolean({
        disabled: !canEditProcureMaterial,
        defaultValue:
          procureIfRawMaterialMatchesPart &&
          processSchedule?.itemId === processSchedule?.materialItemId,
        required: true,
        name: 'procureMaterial',
        title: 'entities:ProcessScheduleHeader.ProcureMaterial',
      })
      .addBoolean({
        defaultValue:
          processSchedule?.itemId === processSchedule?.materialItemId,
        disabled: !canEdit || Boolean(processSchedule.startDate),
        name: 'procurePartRawMaterial',
        required: true,
        title: 'pages.processSchedule.procureAsMaterial',
        onFieldChange: (form) => {
          if (form.values.procurePartRawMaterial) {
            form.updateField('materialItemId', null);
            form.enableField('materialItemId');
            form.updateField('procureMaterial', false);
            form.disableField('procureMaterial');

            form.updateField('materialFromFirstSupplier', false);
            form.disableField('materialFromFirstSupplier');
          } else {
            form.updateField('materialItemId', processSchedule?.itemId);
            form.disableField('materialItemId');
            if (form.disabledFields.includes('procureMaterial')) {
              form.enableField('procureMaterial');
            }

            if (procureIfRawMaterialMatchesPart) {
              form.updateField(
                'procureMaterial',
                procureIfRawMaterialMatchesPart
              );
            }

            if (procureIfRawMaterialMatchesPart) {
              form.enableField('materialFromFirstSupplier');
            } else {
              form.disableField('materialFromFirstSupplier');
            }
          }
        },
      })
      .addBoolean({
        disabled: !canEditMFFSupplier,
        required: true,
        name: 'materialFromFirstSupplier',
        title: 'entities:ProcessScheduleHeader.MaterialFromFirstSupplier',
      })
      .extension(
        new EditableCustomFieldFormBuilderExtension(
          'ProcessSchedule',
          'forms:UpdateProcessScheduleHeaderInput.Custom',
          'PS Header Custom',
          !canEdit || Boolean(processSchedule?.completionDate)
        ),
        t
      )
  );

  const _handleSubmit = async (
    fob: FormBuilder,
    fd: FormDefinition,
    data: Record<string, unknown>
  ) => {
    getGraphQLClient()
      .performMutation(updateProcessScheduleHeaderMutation, {
        input: data,
      })
      .then((resp) => {
        if (resp.hasError()) {
          if (resp.hasValidationErrors()) {
            let valErrors = resp.validationErrors;
            if (Object.prototype.hasOwnProperty.call(valErrors, 'input')) {
              valErrors = { ...valErrors, ...valErrors.input };
              delete valErrors.input;
            }

            fd.setValidationErrors(valErrors);
          }
          if (resp.hasSystemErrors()) {
            resp.showAllSystemErrors(messageContext.setError);
          }
          return;
        }

        if (resp.hasData()) {
          if (resp?.data?.updateProcessScheduleHeader?.id) {
            messageContext.setSuccess(
              t('generic.updateSuccessPS', {
                record: resp?.data?.updateProcessScheduleHeader?.number,
              })
            );
            setResetGrid(true);
          }
        }
      });
  };

  const editForm = useForm(
    fb,
    {
      onFormatRequest: (formBuilder, formDefinition, data) => {
        const formattedData = {
          ...data,
          processScheduleId: processSchedule?.id,
        };
        if ('procurePartRawMaterial' in data) {
          delete formattedData.procurePartRawMaterial;
        }
        return formattedData;
      },
      onSubmitting: (fob, fd, data) => _handleSubmit(fob, fd, data),
      onSubmitted: (submitContext) => {
        if (submitContext?.success) {
          setProcessSchedule({
            ...processSchedule,
            ...submitContext?.output?.updateProcessScheduleHeader,
          });
          setSaveState((prev) => ({
            ...prev,
            hasSavedOccurred: submitContext?.success,
          }));
          setProcessScheduleState((prev) => ({
            ...prev,
            hasSavedOccurred: submitContext?.success,
          }));
        } else {
          submitContext.output?.handleAllErrors(
            messageContext.setError,
            editForm.setErrors
          );
        }
        return Promise.resolve();
      },
    },
    {
      ...processSchedule,
      'project.displayName': processSchedule?.project?.displayName,
      'spec.displayName': processSchedule?.spec?.displayName,
      'item.CompanyId': processSchedule?.item?.itemCompanyId,
      'ownerEmployee.name': processSchedule?.ownerEmployee?.name,
      'dest.InventoryLoc': processSchedule?.destInventoryLoc ?? -1,
      processScheduleId: processSchedule?.id,
      custom1: processSchedule?.custom1 ?? '',
    },
    { autoSave: true }
  );

  return (
    <Box>
      <TETOForm form={editForm} formLayout={EditProcessScheduleForm} />
    </Box>
  );
};

export default ProcessScheduleEditHeaderForm;
