import {
  CurrencyFormatter,
  MessageContext,
} from '@teto/react-component-library-v2';
import dayjs from 'dayjs';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { getGraphQLClient } from 'teto-client-api';
import { useTranslation } from 'react-i18next';
import updateEmployeeSettingsMutation from './queries/updateEmployeeSettingsMutation';
import updateSystemSettingsMutation from './queries/updateSystemSettingsMutation';
import getSystemAndEmployeeSettingsQuery from './queries/getSystemAndEmployeeSettingsQuery';

import { SystemSettingsInput } from '../__generated__/graphql';

export const employeeMFAModes = ['DISABLED', 'AVAILABLE', 'REQUIRED'] as const;
export const reasonWhenSuppressingOptions = [
  'Always',
  'Optional',
  'Never',
] as const;

export const geotaggingModeOptions = [
  'Require on All',
  'Require on Punch Ins',
  'Optional',
  'Disabled',
] as const;
export type GeotaggingMode = (typeof geotaggingModeOptions)[number];
type DropShipmentsBypassReceiving = 'Never' | 'Always' | 'Ask' | '';
type PoUpdatePrices = 'Never' | 'Always' | 'Ask' | '';
type HourFactorControl = 'Always' | 'Warn' | 'Prevent' | '';

export type DayOfWeekFormat = 0 | 1 | 2 | 3 | 4 | 5 | 6;
const dateFormatStrings = ['YYYY-MM-DD', 'DD/MM/YYYY', 'MM/DD/YYYY'];
const timeFormatStrings = ['hh:mm A', 'HH:mm', 'HH:mm'];
const defaultDate = dayjs('2022-07-01 5:04:16 PM');

export const timeFormats = timeFormatStrings.map((i) => ({
  value: i,
  display: `${defaultDate.format(i)} (${i})`,
}));

export const dateFormats = dateFormatStrings.map((i) => ({
  value: i,
  display: `${defaultDate.format(i)} (${i})`,
}));
/* eslint-disable no-unused-vars */
export interface SettingsContextState {
  settingsLoaded: boolean;
  settings: ServerSystemSettings &
    EmployeeSettings & { dateTimeFormat: string };
  refresh: () => Promise<void>;
  updateSystemSettings: (settings: SystemSettingsInput) => void;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  updateEmployeeSettings: (settings: any) => void;
}
/* eslint-enable no-unused-vars */
export interface ServerSystemSettings {
  addItemsPriorToSalesRelease: boolean;
  allowImmediateStockTransfer: boolean;
  approverCanManageTime: boolean;
  baseCurrencyFormat?: string;
  baseCurrencyFormatter: CurrencyFormatter;
  baseCurrencyName?: string;
  baseCurrencySymbol?: string;
  correctDatesonPO: boolean;
  dateFormat: string;
  dateTimeFormat: string;
  dropShipmentsBypassReceiving: DropShipmentsBypassReceiving;
  dropShipmentsForInventory: boolean;
  employeeMFAMode: string;
  employeeStayLoggedInDuration: string;
  enableHourFactorInMyTimecards?: boolean;
  geotaggingMode: string;
  gridMaxRows?: number;
  inventoryOrderLevel: string;
  inventoryProject: number; // overhead project
  inventoryReOrderCriteria: string;
  inventorySpecID: number; // overhead spec
  kioskAuthTimeout: number;
  kioskEmployeeSessionTimeout: number;
  limitAccountingTermsToList: boolean;
  limitFOBToList: boolean;
  limitShippingAgentsToList: boolean;
  notApprovedSupplierWarningPO: boolean;
  notificationSMTPUseForEmployeeEmails: boolean;
  payPeriodsCreateAutomatically: boolean;
  pOItemsForClosedMachines: boolean;
  pOUpdatePrices: PoUpdatePrices;
  procureIfRawMaterialMatchesPart: boolean;
  reasonWhenSuppressing?: string;
  startDayOfWeek: DayOfWeekFormat;
  timeFormat: string;
  timeFormatPrecise: string;
  canAddCustomProcessNumber: boolean;
  controlTimecardHourFactor: HourFactorControl;
}

export interface EmployeeSettings {
  dateFormat: string;
  timeFormat: string;
  timeFormatPrecise: string;
  dateTimeFormat: string;
}

const DATE_FORMAT = 'YYYY-MM-DD';
const TIME_FORMAT = 'HH:mm';
const TIME_PRECISE_FORMAT = 'HH:mm:ss';

const SettingsContext = React.createContext<SettingsContextState>(
  null as never
);

const SettingsContextProvider: React.FC<
  React.PropsWithChildren<{
    children: React.ReactNode;
  }>
> = (props) => {
  const { children } = props;

  const { t } = useTranslation();

  const [settingsLoaded, setSettingsLoaded] = useState(false);

  // server settings
  const [startDateOfWeek, setStartDayOfWeek] = useState<DayOfWeekFormat>(0);
  const [dateTimeFormat, setDateTimeFormat] = useState<string>('');
  const [dateFormat, setDateFormat] = useState<string>('');
  const [timeFormat, setTimeFormat] = useState<string>('');
  const [timeFormatPrecise, setTimeFormatPrecise] = useState<string>('');
  const [geotaggingMode, setGeotaggingMode] =
    useState<GeotaggingMode>('Optional');
  const [payPeriodsCreateAutomatically, setPayPeriodsCreateAutomatically] =
    useState<boolean>(false);
  const [enableHourFactorInMyTimecards, setEnableHourFactorInMyTimecards] =
    useState(false);
  const [approverCanManageTime, setApproverCanManageTime] =
    useState<boolean>(false);
  const [mfaSetting, setMFASetting] = useState<string>('Available');
  const [employeeLoggedInDuration, setEmployeeStayLoggedInDuration] =
    useState<string>('1');
  const [inventoryProject, setInventoryProject] = useState<number>(-1);
  const [inventorySpecID, setInventorySpecID] = useState<number>(-1);
  const [allowImmediateStockTransfer, setAllowImmediateStockTransfer] =
    useState<boolean>(true);
  const [inventoryReOrderCriteria, setInventoryReOrderCriteria] =
    useState<string>('');
  const [inventoryOrderLevel, setInventoryOrderLevel] = useState<string>('');

  const [baseCurrencyName, setBaseCurrencyName] = useState<string>('');
  const [baseCurrencySymbol, setBaseCurrencySymbol] = useState<string>('');
  const [baseCurrencyFormat, setBaseCurrencyFormat] = useState<string>('');

  const [notApprovedSupplierWarningPO, setNotApprovedSupplierWarningPO] =
    useState<boolean>(true);

  const [kioskAuthTimeout, setKioskAuthTimeout] = useState<number>(-1);
  const [kioskEmployeeSessionTimeout, setKioskEmployeeSessionTimeout] =
    useState<number>(-1);

  const [currencyFormatter, setCurrencyFormatter] = useState<CurrencyFormatter>(
    new CurrencyFormatter()
  );

  const [reasonWhenSuppressing, setReasonWhenSuppressing] =
    useState<string>('');
  const [correctDatesonPO, setCorrectDatesonPO] = useState<boolean>(false);
  const [addItemsPriorToSalesRelease, setAddItemsPriorToSalesRelease] =
    useState(false);
  const [pOItemsForClosedMachines, setPOItemsForClosedMachines] =
    useState(false);
  const [dropShipmentsForInventory, setDropShipmentsForInventory] =
    useState(false);
  const [dropShipmentsBypassReceiving, setDropShipmentsBypassReceiving] =
    useState<DropShipmentsBypassReceiving>('');
  const [limitShippingAgentsToList, setLimitShippingAgentsToList] =
    useState(false);
  const [limitAccountingTermsToList, setLimitAccountingTermsToList] =
    useState(false);
  const [limitFOBToList, setLimitFOBToList] = useState(false);

  const [pOUpdatePrices, setPOUpdatePrices] = useState<PoUpdatePrices>('');
  const [procureIfRawMaterialMatchesPart, setProcureIfRawMaterialMatchesPart] =
    useState<boolean>(false);
  const [canAddCustomProcessNumber, setCanAddCustomProcessNumber] =
    useState<boolean>(false);
  const [controlTimecardHourFactor, setControlTimecardHourFactor] =
    useState<HourFactorControl>('');
  const [
    notificationSMTPUseForEmployeeEmails,
    setNotificationSMTPUseForEmployeeEmails,
  ] = useState<boolean>(false);

  const [gridMaxRows, setGridMaxRows] = useState<number>(10000);

  const messageContext = useContext(MessageContext);

  const _getFormat = (
    empFormat: string,
    sysFormat: string,
    defaultFormat: string
  ) => {
    if (empFormat === '') {
      return sysFormat;
    }
    return empFormat || defaultFormat;
  };

  const _refresh = useCallback(
    () =>
      getGraphQLClient()
        .performQuery(getSystemAndEmployeeSettingsQuery, {})
        .then((data) => {
          if (data.hasError()) {
            if (data.hasSystemErrors()) {
              setSettingsLoaded(true);
              messageContext.setError(
                `Failed to load system settings. ${data.systemErrors[0]}`
              );
            }
            return;
          }
          const sysSettings = data.data.systemSettings;
          const empSettings = data.data.employeeSettings;

          setGridMaxRows(sysSettings?.gridPageSize ?? 10000);

          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          const sysSettingsUnprotected = sysSettings as any;
          setEnableHourFactorInMyTimecards(
            !(
              sysSettingsUnprotected.tEHideHourFactorField === 1 ||
              sysSettingsUnprotected.tEHideHourFactorField === true
            )
          );
          setMFASetting(sysSettings?.employeeMFAMode as string);
          setEmployeeStayLoggedInDuration(
            String(sysSettings?.employeeStayLoggedInDuration)
          );
          setReasonWhenSuppressing(
            sysSettings?.reasonWhenSuppressing as string
          );
          setNotificationSMTPUseForEmployeeEmails(
            sysSettings?.notificationSMTPUseForEmployeeEmails ?? false
          );
          setNotApprovedSupplierWarningPO(
            sysSettings?.notApprovedSupplierWarning as boolean
          );
          setInventoryProject(sysSettings?.inventoryProject as number);
          setInventorySpecID(sysSettings?.inventorySpecID as number);
          setAllowImmediateStockTransfer(
            sysSettings?.allowImmediateStockTransfer as boolean
          );
          setNotificationSMTPUseForEmployeeEmails(
            sysSettings?.notificationSMTPUseForEmployeeEmails as boolean
          );
          setInventoryOrderLevel(sysSettings?.inventoryOrderLevel as string);
          setInventoryReOrderCriteria(
            sysSettings?.inventoryReOrderCriteria as string
          );
          setBaseCurrencyFormat(sysSettings?.baseCurrencyFormat as string);
          setBaseCurrencyName(sysSettings?.baseCurrencyName as string);
          setBaseCurrencySymbol(sysSettings?.baseCurrencySymbol as string);
          setKioskAuthTimeout(sysSettings?.kioskAuthTimeout as number);
          setKioskEmployeeSessionTimeout(
            sysSettings?.kioskEmployeeSessionTimeout as number
          );
          setCurrencyFormatter(
            new CurrencyFormatter(
              sysSettings?.baseCurrencyFormat as string,
              sysSettings?.baseCurrencySymbol as string
            )
          );
          setAddItemsPriorToSalesRelease(
            sysSettings?.addItemsPriorToSalesRelease as boolean
          );
          setPOItemsForClosedMachines(
            sysSettings?.pOItemsForClosedMachines as boolean
          );
          setProcureIfRawMaterialMatchesPart(
            sysSettings?.procureIfRawMaterialMatchesPart as boolean
          );
          setDropShipmentsForInventory(
            sysSettings?.dropShipmentsForInventory as boolean
          );
          setLimitShippingAgentsToList(
            sysSettings?.limitShippingAgentsToList as boolean
          );
          setLimitAccountingTermsToList(
            sysSettings?.limitAccountingTermsToList as boolean
          );
          setCorrectDatesonPO(sysSettings?.correctDatesonPO as boolean);
          setLimitFOBToList(sysSettings?.limitFOB as boolean);
          setDropShipmentsBypassReceiving(
            sysSettings?.dropShipmentsBypassReceiving as DropShipmentsBypassReceiving
          );
          setPOUpdatePrices(sysSettings?.pOUpdatePrices as PoUpdatePrices);
          setCanAddCustomProcessNumber(
            sysSettings?.customProcessNumber as boolean
          );
          setControlTimecardHourFactor(
            sysSettings?.controlTimecardHourFactor as HourFactorControl
          );

          setStartDayOfWeek(
            sysSettings?.timeSheetStartDayOfWeek as DayOfWeekFormat
          );

          setDateFormat(
            _getFormat(
              empSettings.webDateFormat,
              sysSettings.webDateFormat,
              DATE_FORMAT
            )
          );
          setTimeFormat(
            _getFormat(
              empSettings.webTimeFormat,
              sysSettings.webTimeFormat,
              TIME_FORMAT
            )
          );
          setTimeFormatPrecise(
            _getFormat(
              empSettings.webTimeFormatPrecise,
              sysSettings.webTimeFormatPrecise,
              TIME_PRECISE_FORMAT
            )
          );
          setDateTimeFormat(
            `${_getFormat(
              empSettings.webDateFormat,
              sysSettings.webDateFormat,
              DATE_FORMAT
            )} ${_getFormat(
              empSettings.webTimeFormat,
              sysSettings.webTimeFormat,
              TIME_FORMAT
            )}`
          );
          setGeotaggingMode(sysSettings?.geotaggingMode as GeotaggingMode);
          setPayPeriodsCreateAutomatically(
            sysSettings?.payPeriodsCreateAutomatically as boolean
          );
          setSettingsLoaded(true);
          setApproverCanManageTime(
            sysSettings?.approversCanManageTime as boolean
          );
          setGridMaxRows(sysSettings?.gridMaxRows);
        }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [getSystemAndEmployeeSettingsQuery]
  );

  const _updateSystemSettings = (settings: SystemSettingsInput) => {
    getGraphQLClient()
      .performMutation(updateSystemSettingsMutation, { input: settings })
      .then((data) => {
        if (data.hasError()) {
          if (data.hasSystemErrors()) {
            setSettingsLoaded(true);
            messageContext.setError(
              `${t('generic.message.failSystemSettings')}. ${
                data.systemErrors[0]
              }`
            );
          }
          return;
        }

        _refresh();
        messageContext.setSuccess(t('generic.message.settingsUpdate'));
      });
  };
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const _updateEmployeeSettings = (settings: any) => {
    getGraphQLClient()
      .performMutation(updateEmployeeSettingsMutation, { input: settings })
      .then((data) => {
        if (data.hasError()) {
          if (data.hasSystemErrors()) {
            setSettingsLoaded(true);
            messageContext.setError(
              `${t('generic.message.failEmployeeSettings')}. ${
                data.systemErrors[0]
              }`
            );
          }
          return;
        }

        _refresh();
        messageContext.setSuccess(t('generic.message.settingsUpdate'));
      });
  };

  useEffect(() => {
    _refresh();
  }, [_refresh]);

  return (
    <SettingsContext.Provider
      // eslint-disable-next-line react/jsx-no-constructed-context-values
      value={{
        settingsLoaded,
        settings: {
          addItemsPriorToSalesRelease,
          allowImmediateStockTransfer,
          approverCanManageTime,
          baseCurrencyFormat,
          baseCurrencyFormatter: currencyFormatter,
          baseCurrencyName,
          baseCurrencySymbol,
          canAddCustomProcessNumber,
          correctDatesonPO,
          dateFormat,
          dateTimeFormat,
          dropShipmentsBypassReceiving,
          dropShipmentsForInventory,
          employeeMFAMode: mfaSetting,
          employeeStayLoggedInDuration: employeeLoggedInDuration,
          enableHourFactorInMyTimecards,
          geotaggingMode,
          gridMaxRows,
          inventoryOrderLevel,
          inventoryProject,
          inventoryReOrderCriteria,
          inventorySpecID,
          kioskAuthTimeout,
          kioskEmployeeSessionTimeout,
          limitAccountingTermsToList,
          limitFOBToList,
          limitShippingAgentsToList,
          notApprovedSupplierWarningPO,
          notificationSMTPUseForEmployeeEmails,
          payPeriodsCreateAutomatically,
          pOItemsForClosedMachines,
          pOUpdatePrices,
          procureIfRawMaterialMatchesPart,
          reasonWhenSuppressing,
          startDayOfWeek: startDateOfWeek,
          timeFormat,
          timeFormatPrecise,
          controlTimecardHourFactor,
        },
        updateEmployeeSettings: _updateEmployeeSettings,
        updateSystemSettings: _updateSystemSettings,
        refresh: _refresh,
      }}
    >
      {children}
    </SettingsContext.Provider>
  );
};

export default SettingsContext;
export const SettingsProvider = SettingsContextProvider;
export const SettingsConsumer = SettingsContext;
