import { gql } from '../../../../__generated__';

const shipViaQuery = gql(`
  query poHeaderShipVia {
    shippers {
      items {
        id
        shipperName
      }
    }
  }
  `);

export default shipViaQuery;
