import * as Yup from 'yup';

const inlineEditSchema = Yup.object().shape({
  custom1: Yup.string(),
  custom2: Yup.string(),
  custom3: Yup.number().nullable(),
  custom4: Yup.number().nullable(),
  custom5: Yup.date().nullable(),
  custom6: Yup.date().nullable(),
  custom7: Yup.boolean().required(),
  custom8: Yup.boolean().required(),
  estimateHours: Yup.number().required(),
  extendedEstimateHours: Yup.number(),
  inHouse: Yup.boolean().required(),
  inHouseRate: Yup.number().required(),
  notes: Yup.string(),
  processId: Yup.number().required(),
  requiredDate: Yup.date().nullable(),
  sequence: Yup.number().required().min(1),
  setupHours: Yup.number().required(),
  totalHours: Yup.number().required(),
  unitEstimate: Yup.number().required(),
});

export default inlineEditSchema;
