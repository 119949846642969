/* eslint-disable @typescript-eslint/no-explicit-any */
import ReactDataGrid from '@inovua/reactdatagrid-enterprise';

type BoolFilterOptions = 'equal';
type StringFilterOptions =
  | 'equal'
  | 'notEqual'
  | 'contains'
  | 'startsWith'
  | 'endsWith';
type DateFilterOptions =
  | 'equal'
  | 'notEqual'
  | 'lessThan'
  | 'lessThanEqualTo'
  | 'greaterThan'
  | 'greaterThanEqualTo';

type FilterOptions = {
  bool: BoolFilterOptions;
  string: StringFilterOptions;
  date: DateFilterOptions;
};

// eslint-disable-next-line no-unused-vars
type FilterTypes = keyof FilterOptions;

const commonDateOperations: {
  name: DateFilterOptions;
  // eslint-disable-next-line no-unused-vars
  fn: (val: any) => boolean;
}[] = [
  {
    name: 'equal',
    // eslint-disable-next-line eqeqeq
    fn: ({ value, filterValue }: any) => value == filterValue,
  },
  {
    name: 'notEqual',
    // eslint-disable-next-line eqeqeq
    fn: ({ value, filterValue }: any) => value != filterValue,
  },
  {
    name: 'lessThan',
    // eslint-disable-next-line eqeqeq
    fn: ({ value, filterValue }: any) => value < filterValue,
  },
  {
    name: 'lessThanEqualTo',
    // eslint-disable-next-line eqeqeq
    fn: ({ value, filterValue }: any) => value <= filterValue,
  },
  {
    name: 'greaterThan',
    // eslint-disable-next-line eqeqeq
    fn: ({ value, filterValue }: any) => value > filterValue,
  },
  {
    name: 'greaterThanEqualTo',
    // eslint-disable-next-line eqeqeq
    fn: ({ value, filterValue }: any) => value >= filterValue,
  },
];

const filterTypes = {
  select: { ...ReactDataGrid.defaultProps.filterTypes.select, emptyValue: '' },
  date: {
    type: 'date',
    emptyValue: '',
    operators: commonDateOperations,
  },
  datetime: {
    type: 'datetime',
    emptyValue: '',
    operators: commonDateOperations,
  },
  time: {
    type: 'time',
    emptyValue: '',
    operators: commonDateOperations,
  },
  none: {
    type: '',
    emptyValue: '',
    operators: [],
  },
  bool: {
    type: 'bool',
    emptyValue: '',
    operators: [
      {
        name: 'equal',
        fn: ({ value, filterValue }: any) => value === filterValue,
      },
    ],
  },
  boolean: {
    type: 'boolean',
    emptyValue: '',
    operators: [
      {
        name: 'equal',
        fn: ({ value, filterValue }: any) => value === filterValue,
      },
    ],
  },
  number: {
    type: 'number',
    emptyValue: '',
    min: 0,
    operators: [
      {
        name: 'equal',
        fn: ({ value, filterValue }: any) => value === filterValue,
      },
      {
        name: 'notEqual',
        fn: ({ value, filterValue }: any) => value !== filterValue,
      },
      {
        name: 'greaterThan',
        fn: ({ value, filterValue }: any) =>
          !value ? false : value > filterValue,
      },
      {
        name: 'lessThan',
        fn: ({ value, filterValue }: any) =>
          !filterValue ? false : value < filterValue,
      },
    ],
  },
  currency: {
    type: 'currency',
    emptyValue: '',
    min: 0,
    operators: [
      {
        name: 'equal',
        fn: ({ value, filterValue }: any) => value === filterValue,
      },
      {
        name: 'notEqual',
        fn: ({ value, filterValue }: any) => value !== filterValue,
      },
      {
        name: 'greaterThan',
        fn: ({ value, filterValue }: any) =>
          !value ? false : value > filterValue,
      },
      {
        name: 'lessThan',
        fn: ({ value, filterValue }: any) =>
          !filterValue ? false : value < filterValue,
      },
    ],
  },
  string: {
    type: 'string',
    emptyValue: '',
    operators: [
      {
        name: 'contains',
        fn: ({
          value,
          filterValue,
        }: {
          value?: string | { [key: string]: any };
          filterValue?: string;
        }) => {
          if (!value || !filterValue) {
            return false;
          }
          if (
            typeof value === 'object' &&
            !Array.isArray(value) &&
            value !== null
          ) {
            const isValueFound =
              value.name.toLowerCase().indexOf(filterValue.toLowerCase()) >= 0;
            return isValueFound;
          }

          if (typeof value === 'string') {
            const wasValueFound =
              value?.toLowerCase().indexOf(filterValue.toLowerCase()) >= 0;
            return wasValueFound;
          }
        },
      },
      {
        name: 'equal',
        fn: ({ value, filterValue }: any) => value === filterValue,
      },
      {
        name: 'empty',
        fn: ({ value }: any) => value === '' || value === null,
      },
      {
        name: 'notEmpty',
        fn: ({ value }: any) => value !== '' || value !== null,
      },
      {
        name: 'notEqual',
        fn: ({ value, filterValue }: any) => value !== filterValue,
      },
      {
        name: 'startsWith',
        fn: ({
          value,
          filterValue,
        }: {
          value?: string;
          filterValue?: string;
        }) =>
          !value
            ? false
            : !filterValue ||
              value.toLowerCase().startsWith(filterValue.toLowerCase()),
      },
      {
        name: 'endsWith',
        fn: ({
          value,
          filterValue,
        }: {
          value?: string;
          filterValue?: string;
        }) =>
          !value
            ? false
            : !filterValue ||
              value.toLowerCase().endsWith(filterValue.toLowerCase()),
      },
    ],
  },
};

export default filterTypes;
