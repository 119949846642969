import { Typography } from '@mui/material';
import Box from '@mui/material/Box';
import { SxProps, Theme } from '@mui/material/styles';
import { CurrencyFormatter } from '@teto/react-component-library-v2';
import React, { Dispatch, SetStateAction, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { PurchaseOrderHeadersCollectionSegment } from '../../../../__generated__/graphql';
import useGQLQuery from '../../../../api/graphQL/useGQLQuery';
import DefaultPagination from '../../../TETOGridGraphQL/DefaultPagination';
import { CustomPaginationProps } from '../../../TETOGridGraphQL/types/TetoGridGraphqlProps';
import getPODTotals from '../queries/getPurchaseOrderTotalsQuery';

export interface PODetailFooterProps extends Partial<CustomPaginationProps> {
  purchaseOrderId: number;
  refreshTotal: boolean;
  setRefreshTotal: Dispatch<SetStateAction<boolean>>;
}

const totalSx: SxProps<Theme> = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  height: 'auto',
  pl: 2,
  pr: 2,
  overflowX: 'auto',
  columnGap: 2,
  width: '100%',
};

const contentSx: SxProps<Theme> = {
  display: 'flex',
  flexDirection: { xs: 'column', md: 'row' },
  alignItems: 'center',
  justifyContent: { xs: 'center', md: 'space-between' },
  pt: 1,
  pb: 1,
  columnGap: 1,
};

const statusBarSx: SxProps<Theme> = {
  borderTop: (theme) => `2px solid ${theme.palette.divider} !important`,
  height: 'auto',
  overflowX: 'hidden',
};

const ValueDisplay = ({
  title,
  value,
  format,
  symbol,
}: {
  title: string | number;
  value: number;
  format: string;
  symbol: string;
}) => {
  const formatter = new CurrencyFormatter(format, symbol);
  return (
    <Box sx={contentSx}>
      <Typography
        sx={{ color: 'text.secondary' }}
        variant="body2"
      >{`${title}: `}</Typography>
      <Typography sx={{ color: 'text.primary' }} variant="body2">
        {formatter.formatValue(value as number)}
      </Typography>
    </Box>
  );
};

const PODetailFooter = (props: PODetailFooterProps) => {
  const { purchaseOrderId, refreshTotal, setRefreshTotal, ...rest } = props;
  const { t } = useTranslation();

  const totalValues = useGQLQuery(['purchaseOrderDetailTotals'], {
    queryString: getPODTotals,
    variables: {
      purchaseOrderId,
    },
    options: {
      enabled: Boolean(purchaseOrderId),
    },
  });

  const formattedValues = useMemo(() => {
    const data = (
      totalValues?.data as {
        purchaseOrderHeaders: PurchaseOrderHeadersCollectionSegment;
      }
    )?.purchaseOrderHeaders?.items?.[0];

    const assignedValues = {
      subtotal: data?.totals?.subtotal || 0,
      currencyName: data?.purchaseCurr?.name || 0,
      tax1: data?.purchaseTax1 || 0,
      tax2: data?.purchaseTax2 || 0,
      tax1Name: data?.purchaseTax1Name || '',
      tax2Name: data?.purchaseTax2Name || '',
      tax1Amount: data?.taxes?.tax1Amount || 0,
      tax2Amount: data?.taxes?.tax2Amount || 0,
      symbol: data?.purchaseCurr?.symbol,
      format: data?.purchaseCurr?.format,
    };

    return {
      ...assignedValues,
      total:
        assignedValues.subtotal +
          assignedValues.tax1Amount +
          assignedValues.tax2Amount || 0,
    };
  }, [totalValues?.data]);

  const commonProps = useMemo(
    () => ({
      format: formattedValues?.format as string,
      symbol: formattedValues?.symbol as string,
    }),
    [formattedValues]
  );

  useEffect(() => {
    if (refreshTotal) {
      totalValues.refetch();
      setRefreshTotal(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshTotal, totalValues]);

  return (
    <Box sx={statusBarSx}>
      <Box sx={totalSx}>
        <ValueDisplay
          title={t('generic.subTotal')}
          value={formattedValues?.subtotal}
          {...commonProps}
        />
        <ValueDisplay
          title={`${formattedValues.tax1Name} (${(
            100 * formattedValues.tax1 || 0
          ).toFixed(2)}%)`}
          value={formattedValues.tax1Amount}
          {...commonProps}
        />
        <ValueDisplay
          title={`${formattedValues.tax2Name} (${(
            100 * formattedValues.tax2 || 0
          ).toFixed(2)}%)`}
          value={formattedValues.tax2Amount}
          {...commonProps}
        />
        <ValueDisplay
          title={`${t('generic.total')} (${formattedValues?.currencyName})`}
          value={formattedValues?.total}
          {...commonProps}
        />
      </Box>
      <DefaultPagination {...({ ...rest } as CustomPaginationProps)} />
    </Box>
  );
};

export default PODetailFooter;
