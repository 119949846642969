/* eslint-disable consistent-return */
import { TypeFilterValue } from '@inovua/reactdatagrid-enterprise/types';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import { Popover } from '@mui/material';
import Box from '@mui/material/Box';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import FormControlLabel from '@mui/material/FormControlLabel';
import List from '@mui/material/List';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import { ETOIconButton } from '@teto/react-component-library-v2';
import * as React from 'react';
import { useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TETODataColumn } from '../../../GridBuilder/types/TETODataColumn';
import filterTypes from '../../../configuration/filterTypes';
import RenderFilterFieldType from './RenderFilterFieldType';

export interface ConfirmationDialogRawProps {
  id: string;
  value: string;
  open: boolean;
  // eslint-disable-next-line no-unused-vars
  onClose: (value?: string, colName?: string) => void;
  options: string[];
  anchorEl: Element | null;
}

const headerSx = {
  alignItems: 'center',
  display: 'flex',
  justifyContent: 'space-between',
  width: '100%',
};
export const ConfirmationDialogRaw = (props: ConfirmationDialogRawProps) => {
  const { onClose, value, open, options, anchorEl, ...other } = props;

  const { t } = useTranslation();

  const radioGroupRef = React.useRef<HTMLElement>(null);

  const handleEntering = () => {
    if (radioGroupRef.current != null) {
      radioGroupRef.current.focus();
    }
  };

  return (
    <Popover
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'center',
        horizontal: 'center',
      }}
      data-testid="test-modal"
      onClose={() => onClose()}
      open={open}
      TransitionProps={{ onEntering: handleEntering }}
      {...other}
    >
      <Box sx={headerSx}>
        <DialogTitle>{t('forms.filterOperators')}</DialogTitle>
        <ETOIconButton
          color="primary"
          customSx={{
            mr: 2,
          }}
          data-testid="filterCloseButton"
          onClick={() => onClose()}
          size="medium"
          tooltipProps={{ title: 'Close' }}
        >
          <CloseRoundedIcon />
        </ETOIconButton>
      </Box>
      <DialogContent sx={{ pt: 0 }}>
        <RadioGroup
          aria-label="Filter Operators"
          name="Filter Operators"
          onChange={({ target: { value: eVal } }) => onClose(eVal)}
          ref={radioGroupRef}
          value={value}
        >
          {options.map((option) => {
            const firstChar = option.charAt(0).toUpperCase();
            const parts = (firstChar + option.slice(1)).split(/(?=[A-Z])/);
            const readableOption = parts.join(' ');
            return (
              <FormControlLabel
                control={<Radio />}
                key={option}
                label={readableOption}
                value={option}
              />
            );
          })}
        </RadioGroup>
      </DialogContent>
    </Popover>
  );
};

interface FilterProps {
  col: TETODataColumn;
  // eslint-disable-next-line no-unused-vars
  setFilters: (value: React.SetStateAction<TypeFilterValue>) => void;
  filters: TypeFilterValue;
  value: string;
}
interface IOperators {
  name: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  fn: any;
}

export const getOperatorTypes = (type: string) => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const result: IOperators[] = filterTypes[type as string]?.operators;

  if (result) {
    return result.map((op) => op.name);
  }
  return [];
};

const getInitialOperatorValue = (
  col: TETODataColumn,
  filters: TypeFilterValue
) => {
  const filter = filters?.find((f) => f.name === col.name);
  if (filter) {
    return filter.operator;
  }
};

const FilterField = (props: FilterProps) => {
  const { col, filters, setFilters, value } = props;
  const [open, setOpen] = React.useState(false);

  const filterValues = useMemo(
    () => getOperatorTypes(col.filterType),
    [col.filterType]
  );

  const [operatorValue, setOperatorValue] = useState(
    () => getInitialOperatorValue(col, filters) ?? filterValues[0]
  );

  const handleClickListItem = () => {
    setOpen(true);
  };

  const handleClose = React.useCallback(
    (newValue?: string, colName?: string) => {
      setOpen(false);

      if (newValue) {
        setOperatorValue(newValue);
        if (!filters || filters.length < 0) return;
        const updatedFilters = filters?.map((f) => {
          if (f.name === colName) {
            if (newValue === 'inrange' && f.operator !== 'inrange') {
              return {
                ...f,
                value: { start: '', end: '' },
                operator: newValue,
              };
            }
            if (newValue !== 'inrange' && f.operator === 'inrange') {
              return {
                ...f,
                value: '',
                operator: newValue,
              };
            }
            return {
              ...f,
              operator: newValue,
            };
          }
          return f;
        });
        setFilters(updatedFilters);
      }
    },
    [filters, setFilters]
  );

  const _updateFilter = React.useCallback(
    (
      e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
      curCol: TETODataColumn
    ) => {
      if (!filters || filters.length < 0) return null;
      if (
        curCol?.filterType === 'date' ||
        curCol?.filterType === 'datetime' ||
        curCol?.filterType === 'time' ||
        curCol?.filterType === 'number' ||
        curCol?.filterType === 'hours' ||
        curCol?.filterType === 'currency'
      ) {
        const newFilters = filters.map((f) => {
          if (f.name === curCol.name) {
            const result = {
              ...f,
              operator: operatorValue,
              value: e,
            };
            return result;
          }
          return f;
        });
        setFilters(newFilters);
      } else {
        if (e.target.value === '') return;
        const newFilters = filters.map((f) => {
          if (f.name === curCol.name) {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            let val: any = e.target.value;
            if (curCol?.filterType === 'number') {
              val = parseInt(e.target.value, 10);
            }
            if (curCol?.filterType === ('boolean' || 'bool' || 'bitwise')) {
              if (typeof e.target.value === 'string') {
                val = e.target.value === 'true';
              } else {
                val = Boolean(e.target.value);
              }
            }
            const result = {
              ...f,
              operator: operatorValue,
              value: val,
            };
            return result;
          }
          return f;
        });
        setFilters(newFilters);
      }
    },
    [filters, operatorValue, setFilters]
  );
  const ref = useRef(null);

  return (
    <Box sx={{ width: '100%' }}>
      <List component="div" role="group">
        <Box ref={ref}>
          <Box
            sx={{ display: 'flex', alignItems: 'center', '& > div': { pl: 0 } }}
          >
            <RenderFilterFieldType
              col={col}
              handleChange={_updateFilter}
              handleClickListItem={handleClickListItem}
              initialValue={value}
              operatorValue={operatorValue}
            />

            <ConfirmationDialogRaw
              anchorEl={ref.current}
              id="filter-operator-menu"
              onClose={(e) => handleClose(e, col.name)}
              open={open}
              options={filterValues ?? []}
              value={operatorValue}
            />
          </Box>
        </Box>
      </List>
    </Box>
  );
};
export default FilterField;
