import { gql } from '../../../__generated__';
import SelectDefinition from './SelectDefinition';

export default {
  query: gql(`
    query getUOMDescriptions {
      uOMDescriptions {
        items {
          description
          itemUom
          uomtype
        }
      }
    }
  `),
  valueField: 'itemUom',
  labelField: 'uomtype',
} as SelectDefinition;
